import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({ rowId, editValue, deleteValue, canDelete }) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editValue(rowId)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      visible: canDelete,
      onClick() {
        deleteValue(rowId)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
