export const GENERAL_ATTRIBUTES = [
  'matter_short_name',
  'matter_description',
  'matter_group',
  'dates_worked',
  'hours',
  'total',
  'client_matter_id',
  'po',
  'legal_entity',
  'cost_code',
  'accruals_enabled'
]

export const CODE_MAP = {
  matter_short_name: 'code',
  matter_description: 'desc',
  matter_group: 'group',
  legal_entity: 'matter_entity',
  cost_code: 'mater_costcode'
}
