import { Routes, Route } from 'react-router-dom'

import { Styleguide, DevRoute, coreThemes } from 'simple-core-ui'

import { ScrollTopContainer } from 'containers'

import Main from '../containers/Main'
import DjangoPageContainer from '../containers/DjangoPageContainer'
import ReactPageContainer from '../containers/ReactPageContainer'
import TabProvider from '../containers/TabProvider'
import ThemeContainer from '../containers/ThemeContainer'
import Header from 'app/header'
import Aside from 'app/aside'
import Footer from 'app/footer'
import SiteWideBanner from './SiteWideBanner'

import ThemeContext from 'context/ThemeContext'
import s from '../styles/Body.scss'

const Body = () => (
  <Routes>
    <Route path="/v2/styleguide" element={<DevRoute />}>
      <Route index element={<Styleguide theme={coreThemes.EB} />} />
    </Route>
    <Route
      path="*"
      element={
        <ThemeContainer>
          <div className={s.container}>
            <ThemeContext.Consumer>
              {({ state, triggerMenu }) => (
                <>
                  <Header {...state} triggerMenu={triggerMenu} />
                  <Main
                    className={
                      state.isMenuExpanded ? s.innerContainerMenuExpanded : s.innerContainer
                    }
                  >
                    {window.credentials.isAuthenticated ? (
                      <Aside
                        {...state}
                        triggerMenu={triggerMenu}
                        mainLinks={window.credentials.navigationUrls}
                      />
                    ) : null}
                    <ScrollTopContainer className={s.innerContentContainer} el={'main'}>
                      <Routes>
                        <Route index element={<SiteWideBanner />} />
                        <Route path="/accounts/login" element={<SiteWideBanner />} />
                      </Routes>
                      <TabProvider>
                        <Routes>
                          <Route path="v2/*" element={<ReactPageContainer />} />
                          <Route path="new/*" element={<ReactPageContainer />} />
                          <Route path="*" element={<DjangoPageContainer />} />
                        </Routes>
                      </TabProvider>
                    </ScrollTopContainer>
                  </Main>
                  {!state.isMobile && <Footer isMenuExpanded={state.isMenuExpanded} />}
                </>
              )}
            </ThemeContext.Consumer>
          </div>
        </ThemeContainer>
      }
    />
  </Routes>
)

export default Body
