import { Fragment } from 'react'
import { RequestsTableContainer } from 'matters/requests/common/RequestsTableContainer'
import s from './RequestsList.scss'

const RequestsList = ({ requestsAvailable, columns }) => (
  <div className={s.container}>
    <Fragment>
      <RequestsTableContainer
        columns={columns}
        requestsAvailable={requestsAvailable}
        title="Legal Requests"
      />
    </Fragment>
  </div>
)

export default RequestsList
