import { createReducer } from 'redux-create-reducer'

import ACT from './actions'

export const initialState = {
  teams: [],
  showForUpdate: null,
  showForCreate: false,
  canEditTeams: false,
  teamLimit: 0,
  teamsIsLoading: true
}

const teamsReducer = createReducer(initialState, {
  [ACT.TEAMS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      teams: action.payload,
      teamsIsLoading: false
    }
  },
  [ACT.TEAM_CREATE_SUCCESS](state, action) {
    return {
      ...state,
      teams: [...state.teams, action.payload],
      teamsIsLoading: false
    }
  },
  [ACT.TEAM_UPDATE_SUCCESS](state, action) {
    const updatedTeam = action.payload

    return {
      ...state,
      teams: state.teams.map(team => (team.id === updatedTeam.id ? updatedTeam : team)),
      teamsIsLoading: false
    }
  },
  [ACT.TEAM_DELETE_SUCCESS](state, action) {
    return {
      ...state,
      teams: state.teams.filter(({ id }) => id !== action.payload)
    }
  },
  [ACT.TEAM_SHOW_FOR_CREATE](state, action) {
    return {
      ...state,
      showForCreate: true
    }
  },
  [ACT.TEAM_SHOW_FOR_UPDATE_SUCCESS](state, action) {
    const teamExists = state.teams.find(({ id }) => action.payload.id)

    return {
      ...state,
      teams: teamExists ? state.teams : [...state.teams, action.payload],
      showForUpdate: action.payload
    }
  },
  [ACT.TEAM_MODAL_CLOSE](state, action) {
    return {
      ...state,
      showForCreate: false,
      showForUpdate: null
    }
  },
  [ACT.TEAMS_MANAGE_META_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload
    }
  },
  [ACT.TEAMS_IS_LOADING](state, action) {
    return {
      ...state,
      teamsIsLoading: true
    }
  },
  [ACT.TEAMS_IS_NOT_LOADING](state, action) {
    return {
      ...state,
      teamsIsLoading: false
    }
  },
  [ACT.TEAMS_XLSX_DOWNLOADING](state, action) {
    return {
      ...state,
      downloadingXLSX: action.payload
    }
  }
})

export default teamsReducer
