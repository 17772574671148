import { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Panel, Button } from 'simple-core-ui'
import update from 'immutability-helper'
import { Labels } from './Labels'
import { LabelModal } from './LabelModal'
import ACT from 'doc_management/settings/actions'
import { checkLabelInLabels } from 'doc_management/settings/utils'
import { FILTER_TYPE } from 'doc_management/settings/constants'
import { FaCircle } from 'react-icons/fa'
import s from './LabelsContainer.scss'
import swal from 'sweetalert'

const initialState = {
  label: {
    name: '',
    color: '#BBDEFB'
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: FILTER_TYPE.ALL.toLowerCase()
  }
}

const reducer = (state, action) => {
  const { value, property, label, labels, params } = action.payload
  switch (action.type) {
    case ACT.CHANGE_LABEL:
      return update(state, {
        label: {
          [property]: {
            $set: value
          }
        }
      })
    case ACT.RESET_LABEL:
      return update(state, {
        label: {
          $set: initialState.label
        }
      })
    case ACT.SELECT_LABEL:
      return update(state, {
        label: {
          $set: labels.find(l => l.id === label.id)
        }
      })
    case ACT.UPDATE_TABLE_PARAMS:
      return { ...state, params }
    default:
      throw new Error('Action not found')
  }
}

const selectLabels = createSelector([state => state.labels], labels => {
  return labels
})

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  {
    columnKey: 'related_documents',
    content: 'Related documents',
    isSortable: true
  }
]

const LabelsContainer = () => {
  const [showLabelModal, setShowLabelModal] = useState(false)
  const [showDuplicateError, setShowDuplicateError] = useState(false)

  const [localState, dispatchState] = useReducer(reducer, initialState)
  const [isEdit, setIsEdit] = useState(false)
  const { label, params } = localState

  const labels = useSelector(state => selectLabels(state.documentManagementSettings))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: ACT.LABELS_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleLabelModal = (isEdit = false) => {
    setShowLabelModal(!showLabelModal)
    setShowDuplicateError(false)
    setIsEdit(isEdit)
    dispatchState({
      type: ACT.RESET_LABEL,
      payload: {}
    })
  }

  const updateLabel = (value, property) => {
    dispatchState({
      type: ACT.CHANGE_LABEL,
      payload: {
        value,
        property
      }
    })

    setShowDuplicateError(checkLabelInLabels(value, labels))
  }

  const addLabel = () => {
    dispatch({
      type: ACT.ADD_LABEL_REQUESTED,
      payload: {
        label
      }
    })
    toggleLabelModal()
  }

  const updateTable = params => {
    dispatchState({
      type: ACT.UPDATE_TABLE_PARAMS,
      payload: {
        params
      }
    })
  }

  const deleteLabel = async labelRow => {
    const label = labels.find(l => l.id === labelRow.id)
    if (!label.related_documents) {
      const willDelete = await swal({
        title: 'Delete Label',
        text: 'Are you sure you want to delete this label?',
        buttons: ['Cancel', 'Yes'],
        icon: 'warning'
      })

      if (willDelete) {
        dispatch({
          type: ACT.DELETE_LABEL_REQUESTED,
          payload: {
            id: label.id
          }
        })
      }
    } else {
      const willDelete = await swal({
        title: 'You Cannot Delete this Label',
        text: 'It is being used in other related documents.',
        button: 'Ok',
        icon: 'warning'
      })
    }
  }

  const editLabel = () => {
    dispatch({
      type: ACT.EDIT_LABEL_REQUESTED,
      payload: {
        label
      }
    })
    toggleLabelModal()
  }

  const showEditModal = label => {
    toggleLabelModal(true)
    dispatchState({
      type: ACT.SELECT_LABEL,
      payload: {
        label,
        labels
      }
    })
  }

  const getColor = labelId => {
    const label = labels.find(l => l.id === labelId)
    return label ? label.color : ''
  }

  const renderCell = ({ rowId, columnKey, content }) => {
    if (!content && content !== 0) {
      return '--'
    }

    if (columnKey === 'name') {
      return (
        <div className={s.nameContainer}>
          <FaCircle style={{ color: getColor(rowId) }} />
          {content}
        </div>
      )
    } else {
      return content
    }
  }

  return (
    <Panel
      title="Manage Document Labels"
      rightActions={[
        <Button onClick={() => toggleLabelModal()} isPrimary key="labels">
          Add Label
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.labelsContainer}
    >
      <Labels
        deleteLabel={deleteLabel}
        params={params}
        rows={labels}
        columns={columns}
        updateTable={updateTable}
        showEditModal={showEditModal}
        renderCell={renderCell}
      />
      {showLabelModal && (
        <LabelModal
          label={label}
          toggleLabelModal={toggleLabelModal}
          updateLabel={updateLabel}
          addLabel={addLabel}
          editLabel={editLabel}
          showDuplicateError={showDuplicateError}
          isEdit={isEdit}
        />
      )}
    </Panel>
  )
}

export default LabelsContainer
