import get from 'lodash/get'
import moment from 'moment'

export const getFileNameExtension = (fileName: string) => {
  const [, name, extension] = /^(.*?)(\.[^.]*)?$/.exec(fileName) || []
  return {
    fileName: name,
    extension: extension
  }
}

export const getNormalizedCellContent = (columnKey: string, row: any) => {
  const { cells } = row
  const cellContent = get(
    cells.find((cell: { columnKey: string }) => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }
  switch (columnKey) {
    case 'fileType':
      return (cellContent as string).toLowerCase()
    case 'fileName':
      return (cellContent as string).toLowerCase()
    case 'userFullName':
      return (cellContent as string).toLowerCase()
    case 'fileSize':
      return cellContent as string
    case 'fileStatus':
      return (cellContent as string).toLowerCase()
    case 'updated':
      return moment(cellContent).valueOf()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
