import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { call, put, takeLatest } from 'redux-saga/effects'
import { makePatchRequest, getRequest } from 'utils/api'
import { FORM_STATUSES } from 'matters/requestForms/constants'

import ACT from './actions'

import { NotificationList } from 'components'

function* fetchLegalRequestFormList() {
  try {
    const url = '/manage/matters/requests/request_forms/'
    const list = yield call(getRequest, url)

    const forms = list.request_forms.filter(form => form.status === FORM_STATUSES.PUBLISHED)

    if (!forms.length) {
      yield put({ type: ACT.SHOW_REDIRECT })
    }

    yield put({
      type: ACT.LEGAL_REQUEST_FORM_LIST_FETCH_SUCCESS,
      payload: { list },
      loadingLock: 'off'
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Request Form list',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      },
      loadingLock: 'off'
    })
  }
}

function* updateLegalRequestStatus(action) {
  const { id, form, onSuccessActionType } = action.payload

  try {
    const url = `/manage/matters/requests/request_form/${id}/`
    yield makePatchRequest(url, form)

    yield put({ type: ACT[onSuccessActionType], payload: { id } })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue updating the Request Form status',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

const matterRequestFormsSagas = [
  takeLatest(ACT.LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED, fetchLegalRequestFormList),
  takeLatest(ACT.LEGAL_REQUEST_FORMS_UPDATE_STATUS_REQUESTED, updateLegalRequestStatus)
]

export default matterRequestFormsSagas
