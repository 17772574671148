import s from '../AddTask.scss'
import { KeyValuePairs, SwitchToggle, Ellipsis } from 'simple-core-ui'
import Select, { StylesConfig } from 'react-select'
import { hex2rgba, isBasicTaskManagementPlan } from 'utils/helpers'
import { LocalTask, InitialTask, Subtask, APIAttachment, Task } from '../../types'
import { toTask } from '../serializers'
import cn from 'classnames'

interface Option {
  value: number
  label: string
  color?: string
}

interface Props {
  priorities: Option[]
  statuses: Option[]
  taskTypes: Option[]
  task: LocalTask | InitialTask
  scopeName: string
  scopeId: string
  saveTask?: (task: Task, isEdit: boolean | undefined, resetEditedProperties?: () => void) => void
  editedProperties?: Partial<LocalTask>
  resetEditedProperties?: () => void
  updateTask: (
    value:
      | string
      | null
      | Option
      | Option[]
      | boolean
      | Date
      | undefined
      | Subtask[]
      | APIAttachment[],
    property: string
  ) => void
  isEdit?: boolean
  readOnly?: boolean
  context?: 'matter' | 'workbench'
}

const customStyle: StylesConfig<Option, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const color = state.data.color
    if (!color) return provided
    return {
      ...provided,
      borderRadius: '4px',
      color: '#000000',
      padding: '2px 12px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      minWidth: '100px',
      maxWidth: 'fit-content',
      backgroundColor: hex2rgba(color, 0.15),
      border: `1px solid ${color}`,
      position: 'relative',
      bottom: '1px'
    }
  },
  option: provided => {
    return {
      ...provided,
      wordWrap: 'break-word'
    }
  }
}

const noneOption = { value: -1, label: 'None' }

const handleRelatedClick = (id: string) => {
  if (id) {
    window.location.href = `/v2/matters/${id}`
  }
}

const Overview = ({
  priorities,
  statuses,
  taskTypes,
  task,
  scopeName,
  updateTask,
  saveTask,
  isEdit,
  editedProperties,
  resetEditedProperties,
  readOnly,
  context,
  scopeId
}: Props) => {
  return (
    <KeyValuePairs
      style={{ margin: '20px 0 0 0' }}
      pairs={[
        {
          key: 'Priority',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: cn(s.section, {
            [s.readOnly]: readOnly
          }),
          value: (
            <Select
              options={[noneOption, ...priorities]}
              value={task.priority}
              onChange={item => {
                if (item?.value === -1) {
                  item = null
                }
                updateTask(item, 'priority')
                if (isEdit) {
                  saveTask &&
                    saveTask(
                      toTask({ id: editedProperties?.id, priority: item } as LocalTask),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
              isDisabled={readOnly}
            />
          )
        },
        ...(context === 'matter' || isEdit
          ? [
              {
                key: 'Related To',
                style: { marginBottom: '20px', height: 40 },
                keyClass: s.key,
                value: (
                  <span className={s.href} onClick={() => handleRelatedClick(scopeId)}>
                    <Ellipsis width={400} lines={1} className={s.scopeName}>
                      {scopeName}
                    </Ellipsis>
                  </span>
                )
              }
            ]
          : []),
        {
          key: 'Status',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: cn(s.section, {
            [s.readOnly]: readOnly
          }),
          value: (
            <Select
              options={statuses}
              value={task.status}
              onChange={item => {
                updateTask(item, 'status')
                if (isEdit) {
                  saveTask &&
                    saveTask(
                      toTask({ id: editedProperties?.id, status: item } as LocalTask),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
              isDisabled={readOnly}
            />
          )
        },
        {
          key: 'Type',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: cn(s.section, {
            [s.readOnly]: readOnly
          }),
          value: (
            <Select
              options={[noneOption, ...taskTypes]}
              value={task.taskType}
              onChange={item => {
                if (item?.value === -1) {
                  item = null
                }
                updateTask(item, 'taskType')
                if (isEdit) {
                  saveTask &&
                    saveTask(
                      toTask({ id: editedProperties?.id, taskType: item } as LocalTask),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              isDisabled={readOnly}
            />
          )
        },
        {
          key: 'Private',
          keyClass: s.key,
          valueClass: s.flex,
          value: (
            <>
              <SwitchToggle
                id="upgradeTier"
                onToggle={
                  isBasicTaskManagementPlan()
                    ? undefined
                    : checked => {
                        updateTask(checked, 'isPrivate')
                        if (isEdit) {
                          saveTask &&
                            saveTask(
                              toTask({ id: editedProperties?.id, isPrivate: checked } as LocalTask),
                              isEdit,
                              resetEditedProperties
                            )
                        }
                      }
                }
                checked={task.isPrivate}
                readOnly={readOnly}
                isPremium={!isBasicTaskManagementPlan()}
                tooltipContent="Contact customer success to upgrade."
              />
              Restrict visibility to task assignees and followers only
            </>
          )
        }
      ]}
    />
  )
}

export default Overview
