import PropTypes from 'prop-types'
import classNames from 'classnames'
import { If } from 'simple-core-ui'
import { IoIosArrowDown } from 'react-icons/io'

import TYPES from './types'

import s from './ComboBox.scss'

const Select = ({ isOpen, triggerCb, label, disabled }) => (
  <span
    className={classNames(s.value, { [s.disabled]: disabled })}
    {...(typeof label === 'string' ? { title: label } : {})}
    {...(disabled ? {} : { onClick: triggerCb })}
    data-testid="combobox-select"
  >
    <span className={s.label} data-testid="combobox-select-label">
      {typeof label === 'function'
        ? label(isOpen)
        : label
        ? label
        : disabled
        ? '--'
        : 'Select a value'}
    </span>
    <If condition={!disabled}>
      <IoIosArrowDown
        className={classNames(s.right, { [s.flipped]: isOpen })}
        data-testid="combobox-select-arrow-icon"
      />
    </If>
  </span>
)

Select.propTypes = {
  isOpen: TYPES.isOpen,
  triggerCb: TYPES.triggerCb.isRequired,
  label: TYPES.label,
  disabled: PropTypes.bool
}

export default Select
