import * as React from 'react'
import partial from 'lodash/partial'
import { If } from 'simple-core-ui'

import { generateNewReviewerTier } from '../utils/utils'

import { ConfirmableContainer } from 'containers'

import ReviewerTier from './ReviewerTier'

import s from '../styles/ReviewerConfig.scss'

const ReviewerTiers = ({
  newReviewerTier,
  savedReviewerTiers,
  editableReviewerTiers,
  editTier,
  addNewTier,
  removeTier,
  updateReviewerTiers,
  revertReviewerTiers,
  readOnly
}) => (
  <section className={s.container}>
    {editableReviewerTiers.length === savedReviewerTiers.length &&
      editableReviewerTiers.map((editableTier, idx) => (
        <React.Fragment key={idx}>
          <ConfirmableContainer
            promptText="Clear these approvers?"
            confirmCallback={partial(removeTier, idx)}
            cancelCallback={revertReviewerTiers}
            render={openPrompt => (
              <ReviewerTier
                savedTier={savedReviewerTiers[idx]}
                editableTier={editableTier}
                editTier={partial(editTier, idx)}
                removeTier={openPrompt}
                addNewTier={
                  editableTier.reviewers && editableTier.reviewers.length
                    ? partial(updateReviewerTiers, idx)
                    : openPrompt
                }
                revertReviewerTiers={revertReviewerTiers}
                readOnly={readOnly}
                tierIndex={idx + ''}
              />
            )}
          />
          <If condition={editableReviewerTiers.length > 1}>
            <hr className={s.separator} />
          </If>
        </React.Fragment>
      ))}
    <ReviewerTier
      savedTier={generateNewReviewerTier()}
      editableTier={newReviewerTier}
      editTier={partial(editTier, -1)}
      revertReviewerTiers={revertReviewerTiers}
      addNewTier={addNewTier}
      isEmptyTier={savedReviewerTiers.length === 0}
      isNewTier
      readOnly={readOnly}
      tierIndex="new_level"
    />
  </section>
)

export default ReviewerTiers
