import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from './Tabs'
import ACT from '../actions'
import APP_ACT from 'app/actions'
import { DataTableWrapper, coreThemes, ModalContainer, ButtonDropdown } from 'simple-core-ui'
import { VendorListFilters } from './Filters'
import { fetchColumns } from './tableDefinitions'
import VendorListHeader from './Header/VendorListHeader'
import { ActionsPopover } from './ActionsPopover'
import { getVendorProperty } from '../utils'
import { EditVendorModal } from './EditVendorModal'
import { fes } from 'scui/utils/formatting'
import { serializeVendorBulkData } from './serializers'
import swal from 'sweetalert'
import axios from 'axios'

const FIRST_PAGE = 1

const VendorsListContainer = () => {
  const dispatch = useDispatch()
  const vendors = useSelector(state => state.vendors.vendorList)
  const params = useSelector(state => state.vendors.vendorListParams)
  const permissions = useSelector(state => state.vendors.vendorPermissions)
  const isLoading = useSelector(state => state.app.loading.VENDOR_LIST_FETCH)
  const fragment = useSelector(state => state.vendors.vendorListFragment)
  const pageTitle = useSelector(state => state.vendors.pageTitle)
  const attributeName = useSelector(state => state.vendors.attributeName)
  const backLink = useSelector(state => state.vendors.backLink)

  const [selectedTab, setSelectedTab] = useState(params.category)
  const [editedVendorId, setEditedVendorId] = useState('')
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [isBulkEdit, setIsBulkEdit] = useState(false)
  const [formValues, setFormValues] = useState(null)

  const selectedRowsLength = selectedRows.length

  useEffect(() => {
    var baseUrl = window.location.href.split('#')[0]
    if (fragment) {
      window.location.replace(`${baseUrl}#${fragment}`)
      try {
        window.localStorage.setItem('savedVendorList', fragment)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Local Storage is full. Please empty data')
      }
    }
  }, [fragment])

  const allRequests = async (action, cb) => {
    try {
      const formParams =
        action !== 'edit' ? { status: action } : serializeVendorBulkData(formValues)
      const response = await axios.put('/invoices/v2/vendors/vendor_bulk_update/', {
        vendor_ids: selectedRows,
        update: formParams
      })
      const toRemove = response.data.errors.map(err => err.vendor_id)
      const finalArr = selectedRows.filter(r => !toRemove.includes(r))

      cb && cb(finalArr)

      dispatch({
        type: ACT.VENDOR_LIST_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: {
          params: {
            ...params,
            page: calculatePageOnBulkReq()
          }
        }
      })

      dispatch({
        type: ACT.VENDOR_SUMMARY_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: { params }
      })

      if (toRemove.length) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: `One or more vendors failed to update. Please try again or contact Support if the issue persists.`,
            level: 'error'
          }
        })
      } else {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            message: `${fes(selectedRows.length, 'records')} modified`,
            level: 'success'
          }
        })
      }
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: `There was an issue updating the selected ${
            selectedRows.length === 1 ? 'vendor' : 'vendors'
          }`,
          level: 'error'
        }
      })
    }
    resetRowSelection()
  }

  const updateTable = updatedParams => {
    resetRowSelection()
    dispatch({
      type: ACT.VENDOR_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...updatedParams,
          filters: params.filters,
          category: selectedTab
        }
      }
    })
  }

  useEffect(() => {
    dispatch({
      type: ACT.VENDOR_SUMMARY_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params
        }
      }
    })

    dispatch({
      type: ACT.VENDOR_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = fetchColumns(selectedTab, permissions)

  const rows = vendors.results

  const renderCustomAction = row => (
    <ActionsPopover
      rowId={row.id}
      status={row.status}
      updateVendorCb={updateVendor}
      setEditedVendorId={setEditedVendorId}
      setIsEditModalVisible={setIsEditModalVisible}
      enableEditVendor={permissions.enableEditVendor}
    />
  )

  const closeEditModal = () => {
    setIsEditModalVisible(false)
    setEditedVendorId('')
    setIsBulkEdit(false)
    setFormValues(null)
  }

  const updateVendor = (id, status) => {
    dispatch({
      type: ACT.UPDATE_VENDOR_REQUESTED,
      payload: {
        id: id ?? editedVendorId,
        params: status ? { status } : formValues
      }
    })
    if (!status) {
      closeEditModal()
    }
  }

  const onSearch = ({ filterParams: updatedFilterParams }) => {
    resetRowSelection()
    dispatch({
      type: ACT.VENDOR_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params,
          page: FIRST_PAGE,
          filters: updatedFilterParams,
          category: selectedTab
        }
      }
    })
  }

  const selectRow = ({ id }) => {
    setSelectedRows(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id)
      if (index !== -1) {
        const newSelectedRows = [...prevSelectedRows]
        newSelectedRows.splice(index, 1)
        return newSelectedRows
      } else {
        return [...prevSelectedRows, id]
      }
    })
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    if (allRowsSelected) {
      setSelectedRows([])
    } else {
      setSelectedRows(vendors.results.map(m => m.id))
    }
  }

  const resetRowSelection = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const showEditVendorsModal = () => {
    setIsEditModalVisible(true)
    setIsBulkEdit(true)
  }

  const updateVendorsStatus = async ({ value }) => {
    const willApprove = await swal({
      title: `${fes(selectedRowsLength, 'vendors')} will be moved to ${
        value === 'active' ? 'Active' : 'Inactive'
      }.`,
      buttons: ['Cancel', 'Update']
    })

    if (willApprove) {
      allRequests(value, finalArr => {
        dispatch({
          type: ACT.UPDATE_VENDORS_SUCCESS,
          payload: {
            vendor_ids: finalArr,
            update: {
              status: value
            }
          }
        })
      })
    }
  }

  const updateVendors = async () => {
    const willApprove = await swal({
      title: `You are updating ${selectedRowsLength} selected ${
        selectedRowsLength === 1 ? 'vendor' : 'vendors'
      }.`,
      text: 'Are you sure you want to update?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willApprove) {
      allRequests('edit', finalArr => {
        dispatch({
          type: ACT.UPDATE_VENDORS_SUCCESS,
          payload: {
            vendor_ids: finalArr,
            update: serializeVendorBulkData(formValues)
          }
        })
      })
    }
    closeEditModal()
  }

  const triggerBulkAction = option => {
    option.value === 'edit' ? showEditVendorsModal() : updateVendorsStatus(option)
  }

  const setBulkOptions = () => {
    const options = [
      { label: selectedTab === 'Deactivated' ? 'Reactivate' : 'Activate', value: 'active' },
      { label: 'Inactivate', value: 'deactivated' },
      { label: 'Edit', value: 'edit' }
    ]
    return options.filter(option => {
      if (selectedTab === 'Deactivated')
        return option.value !== selectedTab.toLowerCase() && option.value !== 'edit'
      return option.value !== selectedTab.toLowerCase()
    })
  }

  const bulkButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRowsLength})`}
      options={setBulkOptions()}
      onSelect={triggerBulkAction}
      listStyles={{ width: '120px' }}
      listItemStyles={{ fontSize: '14px', lineHeight: '15px' }}
      isLoading={isLoading}
    />
  )

  const calculatePageOnBulkReq = () => {
    return vendors.total - selectedRowsLength <= (params.page - 1) * params.pageSize
      ? params.page !== 1
        ? params.page - 1
        : params.page
      : params.page
  }

  const changeTab = tab => {
    dispatch({ type: ACT.VENDOR_LIST_RESET })
    setSelectedTab(tab)
  }

  return (
    <div>
      {backLink ? (
        <div style={{ marginBottom: 20 }}>
          <a href={backLink}>{`<< Back To ${attributeName}`}</a>
        </div>
      ) : null}
      <div className="box" style={{ marginBottom: 280 }}>
        <div className="box-content" style={{ minHeight: '80vh' }}>
          <VendorListHeader title={pageTitle} selectedTab={selectedTab} />
          <Tabs
            hasConfirmation={selectedRows.length}
            selectedTab={selectedTab}
            setSelectedTab={changeTab}
            resetRowSelection={resetRowSelection}
          />
          <DataTableWrapper
            params={params}
            customSearchOptions={{
              styles: { width: 270, fontStyle: 'italic' }
            }}
            remotePagination
            tableHeight="500px"
            categories={[]}
            rows={rows}
            columns={columns}
            totalEntries={vendors.total}
            filteredTotal={vendors.filteredTotal}
            updateTable={updateTable}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            hasActions
            hasTooltip
            isLoading={isLoading}
            theme={coreThemes.EB}
            customAction={renderCustomAction}
            filters={
              <VendorListFilters
                filterParams={params.filters}
                onSearch={onSearch}
                selectedTab={selectedTab}
                permissions={permissions}
              />
            }
            selectRow={permissions.enableEditVendor ? selectRow : null}
            selectAllRows={permissions.enableEditVendor ? selectAllRows : null}
            selectedRows={new Set(selectedRows)}
            allRowsSelected={allRowsSelected}
            checkboxSize="md"
            bulkActions={bulkButton}
          />
          {isEditModalVisible && (
            <ModalContainer
              title={`Edit ${
                isBulkEdit
                  ? `${fes(selectedRowsLength, 'vendors')}`
                  : getVendorProperty(vendors.results, editedVendorId, 'vendor_name')
              }`}
              size="md"
              cancelText="Cancel"
              cancelCb={() => {
                closeEditModal()
              }}
              remotePagination
              tableHeight="500px"
              categories={[]}
              rows={rows}
              columns={columns}
              totalEntries={vendors.total}
              filteredTotal={0}
              updateTable={updateTable}
              panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
              hasActions
              hasTooltip
              isLoading={isLoading}
              theme={coreThemes.EB}
              customAction={renderCustomAction}
              filters={
                <VendorListFilters
                  filterParams={params.filters}
                  onSearch={onSearch}
                  selectedTab={selectedTab}
                  permissions={permissions}
                />
              }
              selectRow={permissions.enableEditVendor ? selectRow : null}
              selectAllRows={permissions.enableEditVendor ? selectAllRows : null}
              selectedRows={new Set(selectedRows)}
              allRowsSelected={allRowsSelected}
              checkboxSize="md"
              bulkActions={bulkButton}
            />
          )}
          {isEditModalVisible && (
            <ModalContainer
              title={`Edit ${
                isBulkEdit
                  ? `${fes(selectedRowsLength, 'vendors')}`
                  : getVendorProperty(vendors.results, editedVendorId, 'vendor_name')
              }`}
              size="md"
              cancelText="Cancel"
              cancelCb={() => {
                closeEditModal()
              }}
              confirmText="Update"
              confirmCb={() => {
                isBulkEdit ? updateVendors() : updateVendor()
              }}
              hasCloseIcon
              content={
                <EditVendorModal
                  isBulkEdit={isBulkEdit}
                  permissions={permissions}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  totalRowsSelected={selectedRowsLength}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default VendorsListContainer
