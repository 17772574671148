const filterCb = (el, query) => {
  return (
    el.text?.toLowerCase().includes(query.toLowerCase()) ||
    el.description?.toLowerCase().includes(query.toLowerCase())
  )
}

export const toReports = (reports, query) => {
  return Object.keys(reports).reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = reports[key].filter(el => filterCb(el, query))
    }
    return acc
  }, {})
}
