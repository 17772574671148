import { Task, LocalTask, Attachment, APIAttachment } from '../types'
import shortid from 'shortid'

export const toLocalTask = (task: Task | null): LocalTask | null => {
  if (!task) {
    return null
  }
  const {
    id,
    name,
    taskId,
    status,
    dueDate,
    priority,
    taskType,
    description,
    isPrivate,
    assignees,
    followers,
    subtasks,
    fileAttachments,
    parent,
    createdDate,
    createdBy,
    relatedMatter
  } = task

  return {
    id,
    name,
    taskId,
    status: status
      ? {
          value: status.id,
          label: status.name,
          color: status.color,
          occurredDate: status.occurredDate,
          phase: status.phase
        }
      : null,
    dueDate: dueDate ?? undefined,
    priority: priority
      ? {
          value: priority.id,
          label: priority.name,
          color: priority.color
        }
      : null,
    taskType: taskType
      ? {
          value: taskType.id,
          label: taskType.name
        }
      : null,
    description,
    createdBy,
    createdDate,
    isPrivate,
    assignees,
    followers,
    subtasks,
    fileAttachments,
    parent,
    ...(relatedMatter !== undefined ? { relatedMatter } : {})
  }
}

export const toTask = (task: LocalTask): Task => {
  const {
    id,
    name,
    taskId,
    status,
    dueDate,
    priority,
    taskType,
    description,
    isPrivate,
    assignees,
    followers,
    subtasks,
    fileAttachments,
    createdDate,
    createdBy
  } = task

  return {
    id,
    name,
    taskId,
    ...(status !== undefined
      ? status === null
        ? { status: null }
        : {
            status: {
              id: status.value,
              name: status.label,
              color: status.color,
              occurredDate: status.occurredDate,
              phase: status.phase
            }
          }
      : {}),
    dueDate,
    ...(priority !== undefined
      ? priority === null
        ? { priority: null }
        : {
            priority: {
              id: priority.value,
              name: priority.label,
              color: priority.color
            }
          }
      : {}),
    ...(taskType !== undefined
      ? taskType === null
        ? { taskType: null }
        : {
            taskType: {
              id: taskType.value,
              name: taskType.label
            }
          }
      : {}),
    description,
    isPrivate,
    assignees,
    followers,
    comments: [],
    commentsCount: 0,
    fileAttachments,
    fileAttachmentsCount: fileAttachments?.length ?? 0,
    children: 0,
    parent: null,
    subtasks,
    createdBy,
    createdDate
  }
}

export const toAPIAttachments = (attachments: Attachment): APIAttachment[] => {
  return Object.keys(attachments).reduce((acc: APIAttachment[], key: string) => {
    const attachment = attachments[key]
    const { name, size, type, will_overwrite_file } = attachment

    acc.push({
      id: shortid.generate(),
      file_name: name,
      file_url: key,
      file_size: size,
      file_type: type,
      s3_file_url: null,
      source: 'ebill',
      fileStatus: 'Temporary',
      will_overwrite_file
    })

    return acc
  }, [])
}
