import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { Action, ActionParam, AvailableAction } from 'simple_review/@types/api'
import { Condition, Operand, Rule } from 'simple_review/@types/editor'
import { getFlatOperands } from 'simple_review/utils/helpers'

export const validateBetweenDates = (op: Operand['op'], rhs: Operand['rhs']) => {
  if (op?.rhs_is_array && rhs && 'constant' in rhs && rhs.type === 'date') {
    if (!Array.isArray(rhs.constant)) return false
    if (rhs.constant.filter(x => x && moment(x.value).isValid()).length !== 2) return false
    if (moment(rhs.constant[1].value).isBefore(rhs.constant[0].value)) return false
    return true
  }
  return false
}

export const getConditionErrors = (rule: Rule): string[] => {
  const errors: string[] = []
  const operands = getFlatOperands(rule.condition)
  for (const { op, rhs, lhs } of operands) {
    if (op?.symbol.includes('between')) {
      const isValid = validateBetweenDates(op, rhs)
      if (!isValid) {
        errors.push(
          `${lhs?.lhsPath?.[0]?.display_name} - ${lhs?.display_name} must have valid start and end date`
        )
      }
    }
  }
  return errors
}

export const validateOperand = (operand: Operand) => {
  if (operand.lhs === null || operand.op === null) return false
  if (operand.rhs === null && !operand.op.unary) return false
  // if an empty array for multi select
  if (operand.rhs && 'constant' in operand.rhs && isEqual(operand.rhs?.constant, [])) {
    return false
  }

  if (operand.rhs && !('constant' in operand.rhs || 'attr_id' in operand.rhs)) {
    return false
  }

  // @ts-expect-error
  const constant = operand.rhs?.constant
  // if a blank string for text/numeric inputs
  if (!Array.isArray(constant) && isEqual(constant?.value, '')) return false
  // validate list of dates
  if (operand.lhs.type === 'date' && operand.op.rhs_is_array)
    return validateBetweenDates(operand.op, operand.rhs)
  // validate currency
  if (operand.rhs && 'constant' in operand.rhs && operand.rhs.type === 'currency') {
    const codePattern = new RegExp(/^[A-Z]{3}$/)
    if (!operand.rhs.sub_type || !codePattern.test(operand.rhs.sub_type)) return false
  }
  // validate invoice age
  if (operand.lhs.name === 'Age' && get(constant, 'value') <= 0) return false

  return true
}

/**
 *
 * @param condition Condition to validate
 * @returns true/false for valid/invalid
 */
export const validateCondition = (condition: Condition): boolean => {
  return condition.operands.every(item => {
    if ('operands' in item) {
      return validateCondition(item as Condition)
    }
    return validateOperand(item as Operand)
  })
}

export const validateActions = (
  actions: Array<Action> | null,
  availableActions: Array<AvailableAction>
): boolean => {
  if (!actions?.length) return false
  for (const action of actions) {
    if (!action.name) return false
    const currentAction = availableActions.find(avAction => avAction.name === action.name)
    if (!currentAction) return false
    for (const param of currentAction.params) {
      const matchingParams = action.params.filter(p => p.param_name === param.name) // Keeping this as an array because some params can happen more than once
      if (param.is_required && !matchingParams.length) return false

      const isNumber = (n: unknown) => {
        return !isNaN(parseFloat(n as string)) && !isNaN((n as number) - 0)
      }

      const isParamInvalid = (p: ActionParam): boolean => {
        if (param.is_required && !Boolean(p.value.value)) return true
        if (p.type === 'number') {
          return !isNumber(p.value.value) || Number(p.value.value) < 0
        }
        return false
      }
      if (matchingParams.some(isParamInvalid)) return false
    }
  }
  return true
}

export const validateRule = ({
  action,
  availableActions,
  rule
}: {
  action: 'save' | 'toggle'
  availableActions: Array<AvailableAction>
  rule: Rule
}): {
  isValid: boolean
  invalidFields: Array<string>
  shouldDeactivate: boolean
} => {
  const invalidFields = []
  let shouldDeactivate = false

  /* Name */
  if (!rule.name) {
    invalidFields.push('Name')
    shouldDeactivate = true
  }
  /* Category */
  if (!rule.category && (action === 'toggle' || rule.isActive)) {
    invalidFields.push('Category')
    shouldDeactivate = true
  }
  /* Condition */
  if (rule.condition.operands.length === 0) {
    if (action === 'toggle') invalidFields.push('Conditions')
    shouldDeactivate = true
  } else {
    if (!validateCondition(rule.condition)) {
      invalidFields.push('Conditions')
      shouldDeactivate = true
    }
  }
  /* Actions */
  if (!validateActions(rule.actions, availableActions)) {
    if (action === 'toggle' || rule.actions.length > 0) invalidFields.push('Actions')
    shouldDeactivate = true
  }

  return {
    isValid: invalidFields.length === 0,
    invalidFields,
    shouldDeactivate
  }
}
