export const toCustomAttributes = attributes =>
  attributes.map(a => ({
    value: a.value,
    label: a.label
  }))

export const toGroupedAttributes = (attributes, sectionName) => [
  {
    label: sectionName,
    options: toCustomAttributes(attributes)
  }
]

const toCustomAttribute = a => ({
  value: a.value,
  label: a.label
})
