import { Component } from 'react'
import { ContentContainer } from './Content'
import { SidebarContainer } from './Sidebar'
import * as ACT from '../actions'
import { TemplatePreview } from 'matters/templates/common/TemplatePreview'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { TEMPLATE_STATUSES } from '../constants'
import { withRouter } from 'simple-core-ui/hocs'

@connect(
  ({ matterTemplates }) => {
    return {
      template: { ...matterTemplates }
    }
  },
  null,
  null,
  { forwardRef: true }
)
@withRouter
class MatterTemplatesContainer extends Component {
  state = {
    opened: true
  }
  unarchiveTemplate = () => {
    const templateId = this.props.router.params.id
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED,
      payload: {
        id: templateId,
        template: {
          status: 'published'
        },
        onSuccessActionType: ACT.MATTER_TEMPLATE_RESTORE_TEMPLATE
      }
    })
  }
  renderContent = () => {
    const { template } = this.props

    if (template.status === TEMPLATE_STATUSES.ARCHIVED) {
      return (
        <TemplatePreview
          archived
          unarchiveTemplate={this.unarchiveTemplate}
          selectedTemplate={template}
          template={template}
        />
      )
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <SidebarContainer opened={this.state.opened} toggleDrawer={this.toggleDrawer} />
        <ContentContainer opened={this.state.opened} toggleDrawer={this.toggleDrawer} />
      </DndProvider>
    )
  }
  toggleDrawer = () => {
    this.setState({
      opened: !this.state.opened
    })
  }
  render() {
    return this.renderContent()
  }
}

export default MatterTemplatesContainer
