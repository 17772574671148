import { useEffect, useState } from 'react'
import { ActivityLogBody } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { ACTIVITY_LOG_URL } from 'logbook/urls'
import { fromActivity } from 'logbook/serializers'
import { useDispatch } from 'react-redux'

const ActivityLogContainer = ({ url, modelId, modelName }) => {
  const [logs, setLogs] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      let mounted = true

      try {
        const response = await makeGetRequest(ACTIVITY_LOG_URL, {
          params: { model_id: modelId, model_name: modelName }
        })

        if (mounted) {
          setLogs(fromActivity(response))
        }
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
      }
      return () => (mounted = false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ActivityLogBody logs={logs} loadMoreCb={() => null} />
}

export default ActivityLogContainer
