import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import { fromFragment } from './serializers'

export const initialState = {
  filters: {
    vendorComparison: fromFragment(window.location.hash).vendorComparisonSelector,
    practiceArea: fromFragment(window.location.hash).practiceAreaSelector,
    choice: fromFragment(window.location.hash).choiceSelector,
    tab: 'percent_headcount',
    vendor: fromFragment(window.location.hash).vendorSelector,
    date: fromFragment(window.location.hash).dateSelector
  },
  barChart: {
    labels: [],
    data: []
  },
  doughnutChart: {
    labels: [],
    data: []
  },
  veteranStatus: {
    value: 0,
    preferNotToSay: 0,
    noData: 0
  },
  lgbtqStatus: {
    value: 0,
    preferNotToSay: 0,
    noData: 0
  },
  disabilityStatus: {
    value: 0,
    preferNotToSay: 0,
    noData: 0
  },
  rawData: {},
  vendorComparison: []
}

const diversityReducer = createReducer(initialState, {
  [ACT.DIVERSITY_REQUESTED](state, action) {
    return {
      ...state,
      filters: action.payload.filters
    }
  },

  [ACT.DIVERSITY_SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      filters: action.payload.filters
    }
  }
})

export default diversityReducer
