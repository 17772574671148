import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, SearchableTreeView, If, Alert } from 'simple-core-ui'
import { makeGetRequest, makePatchRequest, makePutRequest } from 'utils/api'
import {
  isFolder,
  getFolderId,
  preventDefaultCallback
} from 'matters/detail/files/MatterAttachmentsTab/helpers'
import { toTreeViewData } from 'matters/detail/files/MatterAttachmentsTab/serializers'
import APP_ACT from 'app/actions'

const hasVendorCollaboration = window.serverContext.get('data_for_react')
  ?.matter_file_sharing_permissions

const MoveItem = ({
  matterId,
  currentItem,
  currentFolder,
  toggleMoveItem,
  showMoveItem,
  getFolderItems
}) => {
  const [data, setData] = useState(null)
  const [newParent, setNewParent] = useState(null)
  const [parentHasDuplicateFolder, setParentHasDuplicateFolder] = useState(false)
  const [parentHasDuplicateFile, setParentHasDuplicateFile] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (showMoveItem) {
      const url = `/doc_management/native_docs/directories/matters/${matterId}`
      ;(async () => {
        const result = await makeGetRequest(url, {
          params: {
            tree: 1,
            ...(itemIsFolder ? { directory: currentItem.path } : {})
          }
        })
        setData(toTreeViewData(result.data))
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoveItem])

  const itemIsFolder = useMemo(() => isFolder(currentItem.id), [currentItem.id])

  const duplicateFolderName = useMemo(() => {
    let isDuplicated = false
    if (newParent && newParent.children) {
      for (let f of newParent.children) {
        if (f.name.toLowerCase() === currentItem.name.toLowerCase()) {
          isDuplicated = true
          break
        }
      }
    }
    return isDuplicated
  }, [currentItem, newParent])

  const moveItem = async e => {
    preventDefaultCallback(e, async () => {
      if (itemIsFolder) {
        if (duplicateFolderName) {
          setParentHasDuplicateFolder(duplicateFolderName)
        } else {
          moveFolder()
        }
      } else {
        moveFile()
      }
    })
  }

  const moveFolder = async () => {
    await makePatchRequest(`/doc_management/native_docs/directories/matters/${matterId}/`, {
      directory_id: +getFolderId(currentItem.id),
      directory_path: currentItem.path,
      parent_id: newParent.id,
      parent_path: newParent.path
    })
    refreshItems(true)
  }

  const moveFile = async () => {
    try {
      await makePutRequest(`/doc_management/native_docs/update_upload/${currentItem.id}/`, {
        folder_id: newParent.id,
        folder_path: newParent.path,
        label_ids: currentItem.labels ? currentItem.labels.map(l => l.value) : [],
        category_id: currentItem.category ? currentItem.category.value : -1
      })
      refreshItems(false)
      setParentHasDuplicateFile(false)
    } catch (error) {
      if (error.response.status === 400) {
        setParentHasDuplicateFile(true)
      }
    }
  }

  const refreshItems = (isFolder = false) => {
    getFolderItems(currentFolder.path)
    toggleMoveItem()
    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Success',
        message: `${isFolder ? 'Folder' : 'File'}  successfully moved`,
        level: 'success'
      }
    })
  }

  return (
    <If
      condition={parentHasDuplicateFolder || parentHasDuplicateFile}
      fallback={
        <Modal
          title={`Move ${itemIsFolder ? 'Folder' : 'Document'}`}
          size="sm"
          isVisible={showMoveItem}
          confirmText="Move"
          isDisabled={!newParent}
          confirmCb={e => moveItem(e)}
          cancelCb={toggleMoveItem}
          content={
            <div
              onClick={e => {
                preventDefaultCallback(e)
              }}
            >
              <SearchableTreeView
                inputData={data}
                inputLabel="Search and select new folder"
                onNodeSelect={node => {
                  setNewParent(node)
                }}
                hasNewDesign={hasVendorCollaboration}
              />
            </div>
          }
          hasNewButtons={hasVendorCollaboration}
        />
      }
    >
      <If
        condition={itemIsFolder}
        fallback={
          <Modal
            title="Move Document"
            size="sm"
            isVisible={showMoveItem}
            confirmText="Ok"
            confirmCb={e => {
              preventDefaultCallback(e, () => {
                toggleMoveItem()
                setParentHasDuplicateFile(false)
              })
            }}
            cancelCb={toggleMoveItem}
            hideCancelBtn
            content={
              <Alert
                message={`${currentItem.name} already exists in this location. Rename the file before moving it.`}
                status="error"
              />
            }
          />
        }
      >
        <Modal
          title="Move Folder"
          size="sm"
          isVisible={showMoveItem}
          confirmText="Ok"
          confirmCb={e => {
            preventDefaultCallback(e, () => {
              setParentHasDuplicateFolder(false)
              toggleMoveItem()
            })
          }}
          cancelCb={toggleMoveItem}
          hideCancelBtn
          content={
            <div
              onClick={e => {
                preventDefaultCallback(e)
              }}
            >
              <Alert
                message={`A folder with the name ${currentItem.name} already exists at this location. Rename it and try again.`}
                status="error"
              />
            </div>
          }
        />
      </If>
    </If>
  )
}

export default MoveItem
