import QuickNavSettings from './QuickNavSettings'
import s from '../styles/QuickNav.scss'
import { IoMdAdd } from 'react-icons/io'

const QuickNavDropdown = ({ isExpanded, openDropdown }) => {
  return (
    <section className={isExpanded ? s.open : s.container} onClick={openDropdown}>
      <span className={s.quickAddButton}>
        <IoMdAdd />
      </span>
      {isExpanded && <QuickNavSettings accountLinks={window.credentials.quickNavUrls} />}
    </section>
  )
}

export default QuickNavDropdown
