import s from '../Diversity.scss'
import HorizontalChart from './HorizontalChart'

function TkMultiReportCard({ hasMargin, label, title, data, color }) {
  const getMappedData = data =>
    data.map(d => ({
      ...d,
      data: { ...d.data, value: d.data.value / 100 }
    }))

  return (
    <div className={`${s.multiCardContainer} ${s.cardContainer} ${hasMargin ? s.mr16 : ''}`}>
      <div>
        <h3 className={s.cardTitle}>{title}</h3>
        <p className={s.dateLabel}>{label}</p>
        <div className={s.chartList}>
          {getMappedData(data).map(({ data, year, width }, i) => (
            <HorizontalChart
              key={i}
              color={color}
              percentage={data.value}
              label={year}
              width="100%"
              breakLabel={false}
            />
          ))}
        </div>
      </div>
      <div>
        {data.map(({ data, year }, i) => (
          <p key={i} className={s.cardSmallLetters}>
            {year}: {data?.no}% no, {data?.preferNotToSay}% prefer to not say, {data?.noData}% no
            data
          </p>
        ))}
      </div>
    </div>
  )
}

export default TkMultiReportCard
