import APP_ACT from 'app/actions'
import cn from 'classnames'
import { BiSubdirectoryRight } from 'react-icons/bi'
import { hex2rgba, openLink, sortAlphabeticallyByProperty } from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { buildAdjacencyList, getNormalizedCellContent } from './utils'
import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  AvatarList,
  Button,
  DataTableWrapper,
  Ellipsis,
  Panel,
  useLoading,
  Page404,
  ModalContainer
} from 'simple-core-ui'
import { useImmer } from 'use-immer'
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { ActionsPopover } from './ActionsPopover'
import { AddTask } from './AddTask'
import { ConfirmationDialog } from './ConfirmationDialog'
import { CopyMethods } from './CopyMethods'
import differenceWith from 'lodash/differenceWith'
import { fromTask, toTasks, toTemplate } from './serializers'
import s from './Template.scss'
import { APITask, Subtask, Task, Template } from './types'
import { ASSIGNMENT_DATE, RELATIVE_DATE } from 'common/RelativeDueDatePicker/constants'
import { AxiosError } from 'axios'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 10,
    ordering: { columnKey: 'taskId', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Task Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '400px', position: 'relative' }
  },
  {
    columnKey: 'taskId',
    content: 'Task ID',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'assignees',
    content: 'Assignee',
    isSortable: false,
    isFilterable: true,
    filterableBy: 'label'
  },
  {
    columnKey: 'dueDate',
    content: 'Due Date',
    isSortable: false,
    isFilterable: true
  },
  {
    columnKey: 'priority',
    content: 'Priority',
    isSortable: true,
    isFilterable: true,
    filterableBy: 'name'
  },
  {
    columnKey: 'taskType',
    content: 'Task Type',
    isSortable: true,
    isFilterable: true,
    filterableBy: 'name'
  }
]

const Templates = () => {
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [template, setTemplate] = useState<Template | null>(null)
  const { id } = useParams<{ id: string }>()
  const [isAddTaskVisible, setIsAddTaskVisible] = useState(false)
  const [editedTask, setEditedTask] = useState<Task | null>(null)
  const [tasks, setTasks] = useImmer<Task[]>([])
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [isLoading, withLoadingLocksGetTemplate] = useLoading()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showDeleteNotAllowedWarning, setShowDeleteNotAllowedWarning] = useState(false)
  const [subTaskToDelete, setSubTaskToDelete] = useState<Task | undefined>(undefined)
  const [showCopyMethods, setShowCopyMethods] = useState(false)
  const [oldTask, setOldTask] = useState<Task | null>(null)
  const [templateNotFound, setTemplateNotFound] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const filteredTasks = useMemo(() => {
    return tasks.filter(t => !t.hidden)
  }, [tasks])

  const fetchTemplate = async () => {
    try {
      const response = await withLoadingLocksGetTemplate(
        makeGetRequest(`/task-management/task-templates/${id}/`)
      )
      setTemplate(toTemplate(response))
      setTasks(toTasks(response.tasks))
      setTemplateNotFound(false)
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        setTemplateNotFound(true)
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
  }

  useEffect(() => {
    fetchTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // toggle subtasks visibility if parent is collapsed/expanded
    for (const task of tasks) {
      if (!task.parent && task.subtasks.length) {
        const subtasksArr = task.subtasks.map(s => s.id)
        setTasks(draft => {
          for (const i of subtasksArr) {
            const index = draft.findIndex(t => t.id === +i)
            if (draft[index]) {
              draft[index].hidden = !task.expanded
            }
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks])

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const editTask = async (task: Task | Subtask) => {
    setIsAddTaskVisible(true)
    try {
      if (task.id) {
        const tsk = await withLoadingLocks(
          makeGetRequest(`/task-management/task-templates/${id}/tasks/${task.id}`)
        )
        setEditedTask(toTasks([tsk])[0])
        setOldTask(toTasks([tsk])[0])
      } else {
        setEditedTask(task as Task)
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const duplicateTask = async (
    task: Task | Subtask,
    parentId?: number | string | null,
    includeSubtasks?: boolean
  ) => {
    try {
      await withLoadingLocks(
        makePostRequest(`/task-management/tasks/clone/`, {
          object_id: task.id,
          ...(includeSubtasks ? { include_subtasks: true } : {})
        })
      )

      if (parentId) {
        editTask({ ...task, id: +parentId })
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task.name} successfully copied.`,
          level: 'success'
        }
      })

      fetchTemplate()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const showCopyMethodsModal = (task: Task) => {
    setShowCopyMethods(true)
    setEditedTask(task)
  }

  const adjacencyList = useMemo(() => buildAdjacencyList(tasks), [tasks])

  const showDeleteModal = (task: Task) => {
    setEditedTask(task)
    const taskAncestors = adjacencyList[task.id]
    taskAncestors.length ? setShowDeleteNotAllowedWarning(true) : setShowDeleteConfirmation(true)
  }

  const renderCustomAction = (row: Task) => {
    return (
      <ActionsPopover
        task={row}
        editTask={() => editTask(row)}
        deleteTask={showDeleteModal}
        duplicateTask={row.children > 0 ? showCopyMethodsModal : duplicateTask}
      />
    )
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering

    const url = `/task-management/tasks/export/?template_id=${id}&columnKey=${columnKey}&isDesc=${+isDesc}`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const toggleAddTaskSidebar = () => {
    setIsAddTaskVisible(!isAddTaskVisible)
    setEditedTask(null)
  }

  const saveTask = async (
    task: Task,
    isEdit: boolean | undefined,
    callback?: (task?: Task) => void
  ) => {
    try {
      if (task.id) {
        const index = tasks.findIndex(p => p.id === task.id)

        const response: APITask = await withLoadingLocks(
          makePatchRequest(
            `/task-management/task-templates/${id}/tasks/${task.id}`,
            fromTask(task, isEdit)
          )
        )
        const formattedResponse = toTasks([response])[0]
        setTasks(draft => {
          draft[index] = formattedResponse
        })
        if (editedTask && response.children > editedTask.children) {
          const newTask = differenceWith(
            response.subtasks,
            editedTask.subtasks,
            (r: APITask, e: Subtask) => +r.id === +e.id
          )
          setTasks(draft => {
            draft.push(toTasks(newTask)[0])
          })
        }
        callback && callback(formattedResponse)
        setOldTask(formattedResponse)
        setEditedTask(formattedResponse)
      } else {
        const newTask: APITask = await withLoadingLocks(
          makePostRequest(`/task-management/task-templates/${id}/tasks/`, fromTask(task))
        )

        setTasks(draft => {
          if (newTask.subtasks.length) {
            return tasks.concat(toTasks([newTask, ...newTask.subtasks]))
          }

          draft.push(toTasks([newTask])[0])
        })

        // fetch newly created task
        editTask(toTasks([newTask])[0])
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task ${task?.name ?? editedTask?.name} successfully ${
            isEdit ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const getDueDateCellContent = (rowId: number) => {
    const { dateType, relativeDateParams } =
      tasks.find(task => task.id === rowId)?.relativeDueDate || {}
    if (!dateType) return '--'
    const { amount, timeUnit, timeCondition } = relativeDateParams || {}
    const isRelativeDueDate = dateType === RELATIVE_DATE.key
    const getRelativeDependency = () =>
      relativeDateParams?.task ? relativeDateParams?.task.label : ASSIGNMENT_DATE.title
    const dependency = isRelativeDueDate ? getRelativeDependency() : ASSIGNMENT_DATE.title
    const timeUnitLabel = (amount === 1
      ? timeUnit?.label.replace(/s$/, ' ')
      : timeUnit?.label
    )?.toLowerCase()
    const dependencyDetails = isRelativeDueDate
      ? `${amount} ${timeUnitLabel} ${timeCondition?.label.toLowerCase()} `
      : 'On '
    const maxCharsInLine = 40
    const isLongDependency = dependencyDetails.length + dependency.length > maxCharsInLine
    return (
      <>
        {dependencyDetails}
        {isLongDependency ? (
          <Ellipsis className={s.dueDateCellDependency} lines={1}>
            {dependency}
          </Ellipsis>
        ) : (
          <span className={s.dueDateCellDependency}>{dependency}</span>
        )}
      </>
    )
  }

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (!content && content !== 0) {
      return '--'
    }
    if (columnKey === 'name') {
      const task = tasks.find(p => p.id === rowId)
      return (
        <>
          {task?.parent && <BiSubdirectoryRight className={s.subTaskBigIcon} />}
          <div style={{ float: 'left' }} className={cn({ [s.nameWrapper]: task?.parent })}>
            {task?.parent && (
              <div className={s.parentName}>
                <Ellipsis className={s.nameCell} lines={1}>
                  {task.parent.name}
                </Ellipsis>
              </div>
            )}
            <a style={{ cursor: 'pointer' }} onClick={() => task && editTask(task)}>
              <Ellipsis className={s.nameCell}>{content}</Ellipsis>
            </a>
          </div>
          {!!task?.children &&
            tasks.some(t => {
              return task?.subtasks.map(s => +s.id).includes(t.id)
            }) && (
              <div className={s.subTasksCountWrapper}>
                <BiSubdirectoryRight className={s.subTaskIcon} />
                <span className={s.subTasksCount}>{task?.children}</span>
                <span
                  onClick={() =>
                    setTasks(draft => {
                      const index = draft.findIndex(t => t.id === task?.id)
                      draft[index].expanded = !draft[index].expanded
                    })
                  }
                  className={s.plusSign}
                >
                  {task.expanded ? '-' : '+'}
                </span>
              </div>
            )}
        </>
      )
    }
    if (columnKey === 'assignees') {
      const task = tasks.find(p => p.id === rowId)
      return !content.length ? (
        task ? (
          <span onClick={() => editTask(task)} className={s.noAssignee}>
            <BsPersonPlus />
          </span>
        ) : (
          '--'
        )
      ) : (
        <AvatarList
          size={content.length > 1 ? 'sm' : 'md'}
          limit={2}
          avatarStyles={{ marginLeft: 0 }}
          entries={sortAlphabeticallyByProperty(content, 'label')}
        />
      )
    }
    if (columnKey === 'dueDate') {
      return getDueDateCellContent(rowId)
    }
    if (columnKey === 'priority') {
      if (content === '----') {
        return '--'
      }
      const color = tasks.find(p => p.id === rowId)?.priority?.color
      return (
        <div
          className={s.level}
          style={{
            backgroundColor: color && hex2rgba(color, 0.15),
            border: `1px solid ${color}`
          }}
        >
          {content.name}
        </div>
      )
    }
    if (columnKey === 'taskType') {
      return content === '----' ? '--' : content.name
    }
    return content
  }

  const updateTableRowsWithNewValues = (rows: Task[], subtaskId?: number) => {
    if (editedTask?.parent || subtaskId) {
      const subtask = subtaskId ? tasks.find(t => t.id === subtaskId) : editedTask
      rows = rows.map(row =>
        row.id === subtask?.parent?.id
          ? {
              ...row,
              subtasks: row.subtasks.filter(({ id }) => +id !== subtask.id),
              children: row.children - 1
            }
          : row
      )
    }
    if (editedTask?.children && !subtaskId) {
      const children = rows.filter(t => t.parent?.id === editedTask?.id)
      children.forEach(c => {
        const index = rows.findIndex(t => t.id === c.id)
        rows.splice(index, 1)
      })
    }

    return rows
  }

  const deleteSubTask = (id: number) => {
    setSubTaskToDelete(tasks.find(t => t.id === id))
    const taskAncestors = adjacencyList[id]
    taskAncestors.length ? setShowDeleteNotAllowedWarning(true) : setShowDeleteConfirmation(true)
  }

  const getDeleteNotAllowedContent = () => {
    const taskToDelete = subTaskToDelete ? subTaskToDelete : editedTask
    const taskAncestors = (taskToDelete && adjacencyList[taskToDelete.id]) || []
    const ancestorsList = tasks.filter(task => taskAncestors.includes(task.id))
    return (
      <div className={s.deleteNotAllowedContent}>
        <div>
          {`Cannot delete task `}
          <span className={s.highlightName}>{`${taskToDelete?.taskId} ${taskToDelete?.name}`}</span>
          {` due to a related due date. To proceed, remove the due date dependency on the following tasks:`}
        </div>
        <div className={s.taskList}>
          {ancestorsList.map(({ id, name, taskId }) => (
            <div key={id} className={cn(s.listItem, s.highlightName)}>
              <div className={s.bullet}>&bull;</div>
              <div className={s.taskName}>{`${taskId} ${name}`}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const onDeleteTask = async (subtaskId?: number) => {
    try {
      await makeDeleteRequest(
        `/task-management/task-templates/${id}/tasks/${subtaskId ?? editedTask?.id}`
      )

      let rows = cloneDeep(tasks)

      rows = updateTableRowsWithNewValues(rows, subtaskId)

      setTasks(rows.filter(e => e.id !== (subtaskId ?? editedTask?.id)))
      subtaskId && setEditedTask(rows.filter(e => e.id === editedTask?.id)[0])

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Task successfully deleted',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setShowDeleteConfirmation(false)
    subTaskToDelete && setSubTaskToDelete(undefined)
  }

  if (templateNotFound) {
    return <Page404 flag="Template" />
  }

  return (
    <>
      <Panel
        breadcrumbs={
          <>
            <Link to="../">Templates</Link> &gt; {template?.name}
          </>
        }
        title={template?.name || ''}
        subtitle={
          template?.description ? (
            <Ellipsis className={s.description} lines={1}>
              {template?.description}
            </Ellipsis>
          ) : (
            ''
          )
        }
        rightActions={[
          <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary isOutline>
            Download
          </Button>,
          <Button hasNewDesign key="add" onClick={toggleAddTaskSidebar} isPrimary>
            Add Task
          </Button>
        ]}
        styles={{ boxShadow: 'none' }}
        className={s.tasks}
      >
        <DataTableWrapper
          isLoading={isLoading}
          params={params}
          categories={[]}
          rows={filteredTasks}
          columns={columns}
          updateTable={updateTable}
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          className={s.itemsTable}
          customAction={renderCustomAction}
          hasActions
          alwaysShowActions
          categoryKey="task"
          getNormalizedCellContent={getNormalizedCellContent}
          renderCell={renderCell}
          hasTooltip
          checkboxSize="md"
          borderPosition="left"
          multiSort
          hasNestedRows
          customStatusText={
            !params.search && filteredTasks.length === 0
              ? 'There are currently none, click Add to get started.'
              : undefined
          }
        />
      </Panel>
      {isAddTaskVisible && (
        <AddTask
          toggleAddTaskSidebar={toggleAddTaskSidebar}
          tasks={tasks}
          fetchTemplate={fetchTemplate}
          task={editedTask}
          saveTask={saveTask}
          onDeleteTask={deleteSubTask}
          setTasks={setTasks}
          duplicateTask={duplicateTask}
          oldTask={oldTask}
          onEditSubtask={editTask}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title={`Delete this ${!!editedTask?.parent || subTaskToDelete ? 'subtask' : 'task'}?`}
          confirmText="Delete"
          content={
            editedTask?.children && !subTaskToDelete
              ? "This will also delete all of it's subtasks."
              : ''
          }
          onConfirm={() => (subTaskToDelete ? onDeleteTask(subTaskToDelete.id) : onDeleteTask())}
          onCancel={() => {
            setShowDeleteConfirmation(false)
            subTaskToDelete && setSubTaskToDelete(undefined)
          }}
        />
      )}
      {showDeleteNotAllowedWarning && (
        <ModalContainer
          title="Unable to delete this task"
          content={getDeleteNotAllowedContent()}
          size="sm"
          hideButtons
          cancelCb={() => setShowDeleteNotAllowedWarning(false)}
        />
      )}
      {showCopyMethods && (
        <CopyMethods
          onConfirm={(includeSubtasks: boolean) => {
            editedTask && duplicateTask(editedTask, null, includeSubtasks)
            setShowCopyMethods(false)
          }}
          onCancel={() => setShowCopyMethods(false)}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </>
  )
}

export default Templates
