import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { SlCloudUpload } from 'react-icons/sl'
import pluralize from 'pluralize'
import s from './Dropzone.scss'
import * as ACT from '../actions'
import { Input, HoverAction, Button } from 'simple-core-ui'
import { CategorySelect, LabelsSelect } from 'common/Selects'
import { v4 as uuidv4 } from 'uuid'
import { IoIosClose } from 'react-icons/io'
import cn from 'classnames'
import { FaExclamationTriangle } from 'react-icons/fa'
import { MAX_FILE_SIZE_ALLOWED, MAX_FILE_SIZE_ALLOWED_IN_GB } from '../constants'
import { bitesToMb } from '../helpers'
import { getFileNameExtension } from '../utils'
import allowedExtensions from 'simple-core-ui/data/allowedExtensions'
import { hasModule, alphabeticObjectCompare } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import { makeGetRequest } from 'utils/api'
import { DOC_LABELS_URL, DOC_CATEGORIES_URL } from 'common/Selects/urls'

const isNativeDMS = hasModule(MODULE.NATIVE_DMS)

const Dropzone = ({ files, dispatch, duplicatedFileNames, scanningInfectedFileNames }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [rejectedFiles, setRejectedFiles] = useState([])
  const [labels, setLabels] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    ;(async () => {
      const labels = await makeGetRequest(DOC_LABELS_URL)
      setLabels(labels.results)

      const categories = await makeGetRequest(DOC_CATEGORIES_URL)
      setCategories(
        categories.results.sort((first, second) => alphabeticObjectCompare(first, second, 'name'))
      )
    })()
  }, [])

  const onDrop = acceptedFiles => {
    setIsDragging(false)
    dispatch({
      type: ACT.ADD_FILES,
      payload: {
        files: acceptedFiles.map(file => {
          file.id = uuidv4()
          return file
        })
      }
    })
  }

  const onDropRejected = rejectedFiles => {
    setRejectedFiles(rejectedFiles)
  }

  const removeFile = index => {
    dispatch({
      type: ACT.REMOVE_FILE,
      payload: {
        index
      }
    })
  }

  const addCategory = (index, category) => {
    dispatch({
      type: ACT.ADD_CATEGORY,
      payload: {
        selectedCategory: category,
        index
      }
    })
  }

  const addLabels = (index, labels) => {
    dispatch({
      type: ACT.ADD_LABELS,
      payload: {
        selectedLabels: labels,
        index
      }
    })
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    onDropRejected,
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    validator: file => {
      const { extension } = getFileNameExtension(file.name)
      if (!allowedExtensions().includes(extension.split('.')[1].toLowerCase())) {
        return {
          code: 'not-allowed',
          message: `${extension} extension is not allowed`
        }
      }
      return null
    }
  })

  return (
    <>
      <div className={s.dropzoneWrapper}>
        <div
          data-testid="dropzone"
          {...getRootProps({ className: cn(s.dropzone, { [s.onDrag]: isDragging }) })}
        >
          <input {...getInputProps()} />
          <div className={s.dropzoneText}>
            <SlCloudUpload className={s.uploadIcon} />
            <span>Drag and drop files</span>
            <span>or</span>
            <Button
              style={{ padding: '10px 15px' }}
              key="browseFiles"
              isPrimary
              isOutline
              hasNewDesign
            >
              Browse Files
            </Button>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <div className={s.filesCounter}>{`${files.length} ${pluralize(
            'file',
            files.length
          )} added`}</div>
          <div className={s.filesWrapper}>
            <div className={s.flex}>
              <label className={s.label}>File Name</label>
              {isNativeDMS && (
                <>
                  <label className={s.label}>Category (optional)</label>
                  <label className={s.label}>Label(s) (optional)</label>
                </>
              )}
            </div>
            {files.map((file, i) => {
              const isDupe = duplicatedFileNames.includes(file.name)
              const isScanningOrInfected = scanningInfectedFileNames.includes(file.name)
              return (
                <div key={file.id} className={s.file}>
                  <div className={s.flex}>
                    <Input
                      type="text"
                      text={file.name}
                      placeholder="File Name"
                      isDisabled
                      cssClass={cn(s.fileName, {
                        error:
                          isDupe ||
                          bitesToMb(file.size) > MAX_FILE_SIZE_ALLOWED ||
                          isScanningOrInfected
                      })}
                    />
                    {isNativeDMS && (
                      <>
                        <CategorySelect
                          value={file.category}
                          onChange={value => addCategory(i, value)}
                          options={categories}
                        />
                        <LabelsSelect
                          value={file.labels}
                          onChange={values => addLabels(i, values)}
                          options={labels}
                        />
                      </>
                    )}
                    <HoverAction onClick={() => removeFile(i)} icon={<IoIosClose />} />
                  </div>
                  {isDupe && (
                    <p className={s.error}>
                      <span title="This name already exist">
                        <FaExclamationTriangle className={s.warningIcon} />
                      </span>
                      Cannot have duplicate file name
                    </p>
                  )}
                  {isScanningOrInfected && (
                    <p className={s.error}>
                      <span title="This file is in scanning or infected status">
                        <FaExclamationTriangle className={s.warningIcon} />
                      </span>
                      This file is in scanning or infected status
                    </p>
                  )}
                  {bitesToMb(file.size) > MAX_FILE_SIZE_ALLOWED && (
                    <p className={s.error}>
                      <span title={`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}>
                        <FaExclamationTriangle className={s.warningIcon} />
                      </span>
                      {`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}
                    </p>
                  )}
                </div>
              )
            })}
          </div>
        </>
      )}
      {!!rejectedFiles.length &&
        rejectedFiles.map(({ errors }) => {
          return errors.map(e => (
            <p key={e.code} className={s.error}>
              <span title={e.message}>
                <FaExclamationTriangle className={s.warningIcon} />
              </span>
              {e.message}
            </p>
          ))
        })}
    </>
  )
}

export default Dropzone
