import groupBy from 'lodash/groupBy'
import { SUPERSET_CATEGORY_DRAFT } from './constants'
import partition from 'lodash/partition'

export const toCustomReportsV2 = reports => {
  return reports.reduce((acc, report) => {
    if (!acc[report.group_name]) {
      acc[report.group_name] = report.grouped_reports.map(r => ({
        url: r.url,
        text: r.display_name,
        description: r.description
      }))
    }
    return acc
  }, {})
}

export const toExportedReports = reports => {
  return {
    'Export Results': reports.map(r => ({
      url: r.export_url.url,
      status: r.export_url.status,
      date: r.formatted_created_date,
      description: r.report_description,
      text: r.report_name
    }))
  }
}

export const toSavedAdhocReports = groupedReports => {
  const mappedGroupedReports = groupedReports.map(report => ({ ...report, type: 'live' }))
  return groupBy(mappedGroupedReports, report => report.group_name)
}

const groupSupersetReports = reports =>
  reports.reduce((acc, dashboard) => {
    const { category } = dashboard
    return {
      ...acc,
      [category]: acc[category]
        ? { ...acc[category], reports: [...acc[category].reports, dashboard] }
        : { reports: [dashboard], title: dashboard.categoryTitle }
    }
  }, {})

export const toSupersetList = supersetDashboards => {
  const mappedReports = supersetDashboards.map(dashboard => {
    const {
      category,
      dashboard_title: title,
      changed_on_utc: changed,
      standard,
      published,
      kind,
      reason
    } = dashboard
    return {
      ...dashboard,
      text: title,
      categoryTitle: category,
      category: !published && !kind ? SUPERSET_CATEGORY_DRAFT : category,
      ...(!standard ? { date: changed } : {}),
      ...(kind ? { kind } : {}),
      ...(reason ? { reason } : {})
    }
  })
  const [standardReports, savedReports] = partition(mappedReports, 'standard')
  return {
    standard: groupSupersetReports(standardReports),
    saved: groupSupersetReports(savedReports)
  }
}
