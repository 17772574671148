import { MatterSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { formatMultiSelectLabel } from '../helpers'

const MatterFilter = ({
  title = 'Matter',
  onSelect,
  reset,
  isMulti,
  initialValue,
  limit,
  hasConfirmation,
  filterOption,
  paginated,
  withNoneOption
}) => {
  return (
    <SelectFilter
      title={title}
      initialValue={initialValue}
      onSelect={onSelect}
      reset={reset}
      customLabel={formatMultiSelectLabel(isMulti, title)}
      hasConfirmation={hasConfirmation}
      isMulti={isMulti}
      render={(matter, setMatter) => {
        return (
          <MatterSelect
            value={matter}
            onChange={item => {
              setMatter(item)
            }}
            isPortal={false}
            isClearable
            isMulti={isMulti}
            limit={limit}
            filterOption={filterOption}
            paginated={paginated}
            withNoneOption={withNoneOption}
          />
        )
      }}
    />
  )
}

export default MatterFilter
