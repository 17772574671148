import s from '../styles/AccountSettings.scss'

const AccountSettingsItem = ({ text, title, icon, url, openInNewTab, id, onClick }) => (
  <li>
    <a
      {...(onClick
        ? {
            href: '#',
            onClick: e => {
              e.preventDefault()
              onClick(e)
            }
          }
        : {
            href: url,
            target: openInNewTab && '_blank'
          })}
      className={s.link}
      id={id && id}
    >
      <span className={s.icon}>{icon}</span>
      {text}
    </a>
  </li>
)

export default AccountSettingsItem
