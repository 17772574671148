import { BaseSerializer } from './base.serializer'

export function RuleStatusSerializer(oldValue: boolean, newValue: boolean): BaseSerializer {
  return {
    toString() {
      if (oldValue && !newValue) {
        return 'Activated Rule'
      }
      return 'Deactivated Rule'
    }
  }
}
