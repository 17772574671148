import { Constant } from 'simple_review/@types/common'
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react'

import { useAutosizeField } from 'simple-core-ui'
import s from './Percentage.scss'

const MIN_VALUE = 0
const MAX_VALUE = 100

interface Props {
  value: Constant<number> | Constant | null
  onChange(newValue: Constant): void
  autoWidth?: boolean
}

export const Percentage = ({ value, onChange, autoWidth = true }: Props) => {
  const onChangePercentage = (newValue: string) => {
    const [whole, floating] = newValue.split('.')
    let cappedValue = String(Math.max(MIN_VALUE, Math.min(MAX_VALUE, Number(whole))))
    if (Number(whole) < 100 && floating !== undefined) {
      cappedValue += `.${floating}`
    }
    onChange({
      value: cappedValue,
      label: cappedValue
    })
  }

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.target.value.replace(/[^\d\.]/g, '')
    onChangePercentage(newValue)
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Backspace') {
      let numeric = e.currentTarget.value.replace(/[^\d\.]/g, '')
      numeric = numeric.slice(0, numeric.length - 1)
      onChangePercentage(numeric)
      e.preventDefault()
    } else if (new RegExp(/^.*\..{3}$/).test(e.currentTarget.value)) {
      e.preventDefault()
    }
  }

  const onBlur: FocusEventHandler<HTMLInputElement> = e => {
    let numeric = e.currentTarget.value.replace(/[^\d\.]/g, '')
    if (numeric.endsWith('.')) {
      numeric = numeric.slice(0, numeric.length - 1)
      onChangePercentage(numeric)
    }
  }

  const percentageValue = value ? `${value.value}%` : ''

  const placeholder = `Enter value ${MIN_VALUE}-${MAX_VALUE}`

  const { width } = useAutosizeField({
    autoWidth,
    value: String(value?.value),
    placeholder: 'Enter numeric value',
    paddingLeft: 12,
    paddingRight: 12
  })

  return (
    <input
      type="text"
      className={s.input}
      placeholder={placeholder}
      value={percentageValue}
      onChange={onChangeInput}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      style={{ width }}
    />
  )
}
