import BaseAsyncSelect from './BaseAsyncSelect'
import { loadOptionsSelect2Json } from '../helpers'
import { PRACTICE_AREA_URL } from '../urls'
import { PaginatedSelect } from 'simple-core-ui'
import { toReactSelect } from '../serializers'

const PracticeAreaSelect = ({
  value,
  style,
  isClearable,
  onChange,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  placeholder = 'Search practice area...',
  isDisabled,
  paginated = false,
  serializer,
  defaultOptions,
  defaultAction,
  hasNone = true,
  id,
  className
}) => {
  return paginated ? (
    <PaginatedSelect
      url={PRACTICE_AREA_URL}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toReactSelect}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      styles={style}
      isClearable={isClearable}
      id={id}
      isDisabled={isDisabled}
      placeholder={placeholder}
      limit={limit}
      className={className}
    />
  ) : (
    <BaseAsyncSelect
      placeholder={placeholder}
      limit={limit}
      onChange={onChange}
      value={value}
      styles={style}
      isMulti={isMulti}
      loadOptions={loadOptionsSelect2Json(PRACTICE_AREA_URL, {}, hasNone)}
      isClearable={isClearable}
      isPortal={isPortal}
      isDisabled={isDisabled}
    />
  )
}

export default PracticeAreaSelect
