import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({
  rowId,
  isExternal,
  areLocked,
  matterGroupLabels,
  category,
  canEdit,
  ...handlers
}) => {
  const {
    approveBudget,
    archiveBudget,
    matterBudget,
    vendorBudget,
    costcodeBudget,
    mattergroupBudget,
    deleteBudget,
    draftBudget,
    revertBudget
  } = handlers

  const actions = {
    draft: [
      {
        name: 'APPROVE',
        text: 'Approve',
        onClick() {
          approveBudget(rowId)
        }
      },
      {
        name: 'ARCHIVE',
        text: 'Archive',
        onClick() {
          archiveBudget(rowId)
        }
      },
      {
        name: 'MATTER',
        text: 'Edit Matter',
        visible: !isExternal,
        onClick() {
          matterBudget(rowId)
        }
      },
      {
        name: 'VENDOR',
        text: 'Edit Vendor',
        visible: !isExternal,
        onClick() {
          vendorBudget(rowId)
        }
      },
      {
        name: 'MATTERGROUP',
        text: `Edit ${matterGroupLabels[0]}`,
        visible: !isExternal,
        onClick() {
          mattergroupBudget(rowId)
        }
      },
      {
        name: 'COSTCODE',
        text: 'Edit Cost Code',
        visible: !isExternal,
        onClick() {
          costcodeBudget(rowId)
        }
      },
      {
        name: 'DELETE',
        text: 'Delete',
        visible: !isExternal,
        onClick() {
          deleteBudget(rowId)
        }
      }
    ],
    approved: [
      {
        name: 'ARCHIVE',
        text: 'Archive',
        onClick() {
          archiveBudget(rowId)
        }
      },
      {
        name: 'DRAFT',
        text: 'Draft',
        visible: !isExternal && !areLocked,
        onClick() {
          draftBudget(rowId)
        }
      }
    ],
    archived: [
      {
        name: 'REVERT',
        text: 'Revert Status',
        onClick() {
          revertBudget(rowId)
        }
      }
    ]
  }

  if (!canEdit) return null

  return (
    <Popover
      testId={`item-${rowId}`}
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions[category]}
      border
    />
  )
}

export default ActionsPopover
