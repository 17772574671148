import get from 'lodash/get'
import { useEffect, useState } from 'react'

import { Modal, useLoading } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import APP_ACT from 'app/actions'
import { useDispatch } from 'react-redux'
import ReviewModalForm from './ReviewModalForm'
import { formatReviewResponse } from '../serializers'
import { isApprovalFlow } from '../utils/identifyReviewAction'
import { Fields, PreferenceSetting } from 'invoices/types'

interface ReviewFields extends Fields {
  subject: string
}

interface Props {
  scope: string
  fields: ReviewFields
  updateFields: () => void
  onConfirm: () => void
  onConfirmWithNotify: () => void
  onCancel: () => void
  title: string
  primaryLabel: string
  secondaryLabel: string
  showAddAttachments: boolean
  showAddPDF: boolean
  isLoading: boolean
  vendorId: number
}

const REJECTION_SETTINGS_URL = '/invoices/rejection/settings/'

const ReviewModal = ({
  scope,
  fields,
  updateFields,
  onConfirm,
  onConfirmWithNotify,
  onCancel,
  title,
  primaryLabel,
  secondaryLabel,
  showAddAttachments,
  showAddPDF,
  isLoading,
  vendorId
}: Props) => {
  const [preferenceSetting, setPreferenceSetting] = useState<PreferenceSetting | null>(null)
  const [isLoadingSettings, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const isRejection = title.toLowerCase() === 'reject'
  const isApproval = isApprovalFlow(title)
  const loading = isLoading || isLoadingSettings

  useEffect(() => {
    const isRejectionOrApproval = isRejection || isApproval
    const fetchPreferenceSetting = async () => {
      try {
        const { settings } = await withLoadingLocks(makeGetRequest(REJECTION_SETTINGS_URL))
        setPreferenceSetting(formatReviewResponse(settings))
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching Preference Settings.',
            level: 'error'
          }
        })
      }
    }
    isRejectionOrApproval && fetchPreferenceSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRejection, isApproval])

  const disablePrimaryAction = () => {
    let disabled = false
    if (isRejection || isApproval) {
      const notify = get(fields, 'notifyViaEmail')
      const reasonsRequired =
        isRejection && preferenceSetting?.invoice_rejection_reason_preference?.value
      disabled =
        (reasonsRequired && !get(fields, 'reasons.length')) ||
        (notify && (!get(fields, 'emailTo.length') || !get(fields, 'body.length')))
    } else {
      disabled = !get(fields, 'emailTo.length') || !get(fields, 'body.length')
    }

    return disabled || loading
  }

  return (
    <Modal
      content={
        <ReviewModalForm
          {...{
            scope,
            vendorId,
            fields,
            updateFields,
            showAddAttachments,
            showAddPDF,
            preferenceSetting,
            isRejection,
            isApproval,
            isLoading: loading
          }}
        />
      }
      title={title}
      {...(primaryLabel ? { confirmCb: onConfirmWithNotify, confirmText: primaryLabel } : {})}
      {...(secondaryLabel
        ? { secondaryConfirmCb: onConfirm, secondaryConfirmText: secondaryLabel }
        : {})}
      cancelCb={onCancel}
      cancelText="Cancel"
      size={isRejection || isApproval ? 'lg' : 'md'}
      isDisabled={disablePrimaryAction()}
      isLoading={loading}
      isVisible
    />
  )
}

export default ReviewModal
