import keyMirror from 'keymirror'

export default keyMirror({
  INITIALIZE_REVIEWER_CONFIG_REQUESTED: null,
  INITIALIZE_REVIEWER_CONFIG_SUCCESS: null,
  UPDATE_REVIEWER_CONFIG_REQUESTED: null,
  UPDATE_REVIEWER_CONFIG_SUCCESS: null,
  SAVE_REVIEWER_CONFIG_REQUESTED: null,
  CREATE_REVIEWER_CONFIG_REQUESTED: null,
  CREATE_REVIEWER_CONFIG_SUCCESS: null,
  RESET_REVIEWER_CONFIG: null,
  NOOP: null
})
