import { RadioFilter } from 'common/Filters'
import Select from 'react-select'
import { removeNoneOption } from 'bulk/serializer'
import { nullOptions, naOptions } from 'bulk/helpers'
import { selectStyles } from 'bulk/styles/styleSettings'

const ListFilter = ({
  reset,
  getFilterLabel,
  curField,
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  handleDisableMultiSelects,
  hasConfirmation,
  optWrapperStyle,
  width,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      customLabelFn={s =>
        getFilterLabel(curField?.filterOpLabel, filter.display_name, curField?.val1)
      }
      width={500}
      initialValue={curField ? { value: curField?.field } : undefined}
      title={filter.display_name}
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt)
      }}
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onClear={() => onClear(filter.field)}
      onCancelCb={() => onCancel(filter.field)}
      isDisabled={handleDisableMultiSelects(filter.field)}
      hasConfirmation={hasConfirmation}
      options={[
        {
          value: 'is',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>is</p>{' '}
              <div style={{ width: '100%' }}>
                <Select
                  options={removeNoneOption(filter.options)}
                  isMulti
                  value={
                    curSelection === 'is' &&
                    curInputSelection?.op === 'is' &&
                    getInputValue(filter.field, 'is')
                  }
                  isPortal={false}
                  onChange={(value, action) =>
                    onFilterInputChange(filter.field, 'is', value, undefined, action)
                  }
                  isDisabled={curSelection !== 'is'}
                  placeholder={`Search ${filter.display_name}`}
                  styles={selectStyles}
                />
              </div>
            </div>
          )
        },
        {
          value: 'is not',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>is not</p>{' '}
              <div style={{ width: '100%' }}>
                <Select
                  options={removeNoneOption(filter.options)}
                  isMulti
                  value={
                    curSelection === 'is not' &&
                    curInputSelection?.op === 'is not' &&
                    getInputValue(filter.field, 'is not')
                  }
                  isPortal={false}
                  onChange={(value, action) =>
                    onFilterInputChange(filter.field, 'is not', value, undefined, action)
                  }
                  isDisabled={curSelection !== 'is not'}
                  placeholder={`Search ${filter.display_name}`}
                  styles={selectStyles}
                />
              </div>
            </div>
          )
        },
        ...(filter.field !== 'accruals_enabled' ? nullOptions : []),
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default ListFilter
