import { Component } from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from './actions'
import { ProcessRequests } from './ProcessRequests'
import { RequestStatusTooltip } from '../common/RequestStatusTooltip'

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  { columnKey: 'createdDate', content: 'Date Received', isSortable: true, isFilterable: false },
  { columnKey: 'lastModified', content: 'Last Modified', isSortable: true, isFilterable: false },
  { columnKey: 'requested_by_user', content: 'Requested by', isSortable: true, isFilterable: true },
  { columnKey: 'request_form_name', content: 'Type', isSortable: true, isFilterable: true },
  { columnKey: 'reviewed_by_user', content: 'Reviewed by', isSortable: true, isFilterable: true },
  {
    columnKey: 'status',
    content: (
      <span>
        Legal Request Status <RequestStatusTooltip />
      </span>
    ),
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastStatusUpdated',
    content: 'Status Last Updated',
    isSortable: true,
    isFilterable: false
  },
  { columnKey: 'comment', content: 'Reviewer Comments', isSortable: true, isFilterable: true }
]

@connect()
@withFetchFlow({
  flag: 'RequestList',
  getFetchAction: props => ({
    type: ACT.LEGAL_REQUEST_LIST_FETCH_REQUESTED,
    payload: {}
  })
})
class ProcessRequestsContainer extends Component {
  processRequest = request => {
    const { id, requestFormId } = request
    const queryString = `?matterRequestId=${id}&formId=${requestFormId}`
    this.props.router.navigate(`/v2/matters/templates/select/${queryString}`, {
      state: { fromProcessPage: true }
    })
  }
  rejectRequest = request => {
    const { id, comments } = request
    this.props.dispatch({
      type: ACT.REQUEST_REJECT_REQUESTED,
      payload: {
        id,
        comment: comments
      }
    })
  }
  render() {
    return (
      <ProcessRequests
        processRequest={this.processRequest}
        rejectRequest={this.rejectRequest}
        getNormalizedCellContent={this.getNormalizedCellContent}
        columns={columns}
      />
    )
  }
}

export default ProcessRequestsContainer
