import { Component } from 'react'
import Select from 'react-select'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

const defaultPortalStyles = { menuPortal: base => ({ ...base, zIndex: 9999 }) }

class SearchableSelect extends Component {
  static defaultProps = {
    isPortal: true,
    alphabeticallySorted: false,
    optionLabel: 'label',
    dropdownMenuPosition: 'fixed'
  }
  handleChange = selectedOption => {
    const { onChange } = this.props
    onChange(selectedOption)
  }
  render() {
    const { options, selectedOption = null, className, id, testid, isPortal, styles } = this.props
    const portalStyles = isPortal ? defaultPortalStyles : {}
    return (
      <Select
        {...this.props}
        id={id}
        name="form-field-name"
        value={selectedOption}
        onChange={this.handleChange}
        options={
          this.props.alphabeticallySorted
            ? sortAlphabeticallyByProperty(options, this.props.optionLabel)
            : options
        }
        className={className}
        data-testid={testid}
        menuPortalTarget={this.props.isPortal ? document.body : null}
        styles={{ ...portalStyles, ...styles }}
        menuPosition={this.props.isPortal && this.props.dropdownMenuPosition}
        maxMenuHeight={this.props.maxMenuHeight}
      />
    )
  }
}

export default SearchableSelect
