import { IoIosInformationCircle } from 'react-icons/io'
import { Button } from 'simple-core-ui'
import s from './AiCondition.scss'

const AiCondition = ({ ruleName }: { ruleName: string }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <a
          className={s.info}
          href="/help/?article_id=8906422767127"
          target="_blank"
          rel="noreferrer"
        >
          <IoIosInformationCircle size={16} />
          <span>How to set up rule conditions</span>
        </a>
        <Button className={s.button}>AI Powered : {ruleName}</Button>
      </div>
    </div>
  )
}

export default AiCondition
