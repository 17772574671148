import s from '../styles/VendorEval.scss'

const SurveyDescription = ({ userName }) => {
  return (
    <div className={s.description}>
      <p className={s.bold}>
        {userName}, your feedback is important to us so we continue to get the best outcomes on our
        matters.
      </p>
      <p>Note: Answers are not anonymous and will be visible to your peers on this matter.</p>
    </div>
  )
}

export default SurveyDescription
