import find from 'lodash/find'
import get from 'lodash/get'

export const OPERATOR = {
  LESS: '<',
  LESS_OR_EQUAL: '<=',
  GREATER: '>',
  GREATER_OR_EQUAL: '>=',
  IN: 'in',
  IS: 'is',
  CONTAINS: 'contains',
  ON: 'on',
  BEFORE: 'before',
  AFTER: 'after',
  NULL: 'is null',
  NOT_NULL: 'is not null',
  NOT_IN: 'not in',
  NOT_IS: 'is not',
  NOT_CONTAINS: 'does not contain',
  NOT_ON: 'not on',
  NOT_BEFORE: 'not before',
  NOT_AFTER: 'not after'
}

export const UNARY_OPERATORS = [OPERATOR.NULL, OPERATOR.NOT_NULL]

export const TYPE = {
  NUMBER: 'number',
  PERCENTAGE: 'percentage',
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATE: 'date',
  CURRENCY: 'currency',
  REFERENCE: 'reference',
  LIST: 'list'
}

export const INPUT = {
  TAG: 'TAG',
  ASYNC: 'ASYNC',
  BOOLEAN: 'BOOLEAN',
  ASYNC_MULTI: 'ASYNC_MULTI',
  MULTI: 'MULTI',
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  CURRENCY: 'CURRENCY',
  LIST: 'LIST',
  INVALID: 'INVALID',
  STRING_COMBO_STATIC: 'STRING_COMBO_STATIC',
  STRING_COMBO_ASYNC: 'STRING_COMBO_ASYNC',
  NUMBER_COMBO_STATIC: 'NUMBER_COMBO_STATIC',
  NUMBER_COMBO_ASYNC: 'NUMBER_COMBO_ASYNC',
  CURRENCY_COMBO_STATIC: 'CURRENCY_COMBO_STATIC',
  CURRENCY_COMBO_ASYNC: 'CURRENCY_COMBO_ASYNC'
}

const noChoices = (choices = []) => !get(choices, 'length')
const choicesIsUrl = choices => typeof choices === 'string'
const choicesIsList = choices => Array.isArray(choices)
const anyChoices = () => true

export const INPUT_MAPPER = {
  [INPUT.MULTI]: {
    op: [OPERATOR.IN, OPERATOR.NOT_IN],
    lhsType: [TYPE.LIST],
    choices: choicesIsList
  },

  [INPUT.TAG]: {
    op: [OPERATOR.IN, OPERATOR.NOT_IN],
    lhsType: [TYPE.STRING, TYPE.NUMBER, TYPE.LIST],
    choices: anyChoices
  },

  [INPUT.ASYNC_MULTI]: {
    op: [OPERATOR.IN, OPERATOR.NOT_IN],
    lhsType: [TYPE.STRING, TYPE.REFERENCE, TYPE.LIST],
    choices: choicesIsUrl
  },

  [INPUT.ASYNC]: {
    op: [OPERATOR.IS, OPERATOR.NOT_IS],
    lhsType: [TYPE.REFERENCE],
    choices: choicesIsUrl
  },

  [INPUT.BOOLEAN]: {
    op: [OPERATOR.IS, OPERATOR.NOT_IS],
    lhsType: [TYPE.BOOLEAN],
    choices: anyChoices
  },
  [INPUT.STRING]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.CONTAINS,
      OPERATOR.NOT_CONTAINS,
      OPERATOR.NULL,
      OPERATOR.NOT_NULL
    ],
    lhsType: [TYPE.STRING, TYPE.LIST],
    choices: noChoices
  },

  [INPUT.LIST]: {
    op: [OPERATOR.IS, OPERATOR.NOT_IS, OPERATOR.NULL, OPERATOR.NOT_NULL],
    lhsType: [TYPE.LIST],
    choices: choicesIsList
  },

  [INPUT.STRING_COMBO_STATIC]: {
    op: [OPERATOR.IS, OPERATOR.NOT_IS],
    lhsType: [TYPE.STRING],
    choices: choicesIsList
  },

  [INPUT.STRING_COMBO_ASYNC]: {
    op: [OPERATOR.IS, OPERATOR.NOT_IS],
    lhsType: [TYPE.STRING, TYPE.LIST],
    choices: choicesIsUrl
  },

  [INPUT.NUMBER]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.NUMBER],
    choices: noChoices
  },
  [INPUT.NUMBER_COMBO_STATIC]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.NUMBER],
    choices: choicesIsList
  },

  [INPUT.NUMBER_COMBO_ASYNC]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.NUMBER],
    choices: choicesIsUrl
  },

  [INPUT.CURRENCY]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.CURRENCY],
    choices: noChoices
  },
  [INPUT.CURRENCY_COMBO_STATIC]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.CURRENCY],
    choices: choicesIsList
  },
  [INPUT.CURRENCY_COMBO_ASYNC]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.GREATER,
      OPERATOR.GREATER_OR_EQUAL,
      OPERATOR.LESS,
      OPERATOR.LESS_OR_EQUAL
    ],
    lhsType: [TYPE.CURRENCY],
    choices: choicesIsUrl
  },

  [INPUT.DATE]: {
    op: [
      OPERATOR.IS,
      OPERATOR.NOT_IS,
      OPERATOR.ON,
      OPERATOR.NOT_ON,
      OPERATOR.BEFORE,
      OPERATOR.NOT_BEFORE,
      OPERATOR.AFTER,
      OPERATOR.NOT_AFTER
    ],
    lhsType: [TYPE.DATE],
    choices: anyChoices
  }
}

export const getRhsInputType = (lhsType, choices, operator) => {
  for (let key in INPUT_MAPPER) {
    const map = INPUT_MAPPER[key]
    const isInput =
      map.choices(choices) &&
      find(map.lhsType, type => type === lhsType) &&
      find(map.op, o => operator === o)

    if (isInput) {
      return key
    }
  }

  return INPUT.INVALID
}

export const DATE_OPTIONS = [
  {
    label: 'Current Month',
    value: 'CURRENT_MONTH',
    display_name: 'Current Month',
    type: 'DATETIME'
  },
  { label: 'Current Year', value: 'CURRENT_YEAR', display_name: 'Current Year', type: 'DATETIME' },
  { label: 'Last Month', value: 'LAST_MONTH', display_name: 'Last Month', type: 'DATETIME' },
  {
    label: 'Last 3 Months',
    value: 'LAST_3_MONTHS',
    display_name: 'Last 3 Months',
    type: 'DATETIME'
  },
  {
    label: 'Last 6 Months',
    value: 'LAST_6_MONTHS',
    display_name: 'Last 6 Months',
    type: 'DATETIME'
  },
  { label: 'Next Month', value: 'NEXT_MONTH', display_name: 'Next Month', type: 'DATETIME' }
]
