import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Toggle from 'react-toggle'
import cn from 'classnames'

import { Button, Modal, useLoading, withConfirmation } from 'simple-core-ui'
import APP_ACT from 'app/actions'
import { IVR_PAGE_TITLE } from 'rules/constants'
import { makePostRequest } from 'utils/api'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { encodeFilterSearch } from 'simple_review/serializers'
import { VersionHistory } from './VersionHistory'
import { ActionsPopover } from './actions'
import s from './EditorHeader.scss'

interface Props {
  ruleId?: string
  ruleName?: string
  isActive: boolean
  isAI: boolean
  onClone(): void
  onToggleStatus(): void
}

const EditorHeader = ({ ruleId, ruleName, isActive, isAI, onClone, onToggleStatus }: Props) => {
  const [scroll, setScroll] = useState(0)
  const [showVersionHistory, setShowVersionHistory] = useState(false)
  const { state } = useSimpleReviewContext()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [, withLoadingLocks] = useLoading()

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleDelete = withConfirmation(
    async () => {
      try {
        await withLoadingLocks(
          makePostRequest('/rules/invoice_validation/delete/', {
            ruleIds: [ruleId]
          })
        )
      } catch (ex) {
        dispatch({
          type: 'API_ERROR',
          error: {
            ...(ex as object),
            errorTitle: 'Error'
          }
        })
        return
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `"${ruleName}" rule successfully deleted`,
          level: 'success'
        }
      })

      navigate('/v2/rules/simplereview/list')
    },
    {
      title: 'Delete this rule?',
      text: 'Deleting this rule will permanently remove it from your rules list',
      acceptText: 'Delete',
      className: 'iiw-swal-design'
    }
  )

  const onToggleVersionHistory = () => {
    setShowVersionHistory(!showVersionHistory)
  }

  return (
    <div className={cn(s.container, { [s.sticky]: scroll > 30 })}>
      <Link
        to={{
          pathname: '/v2/rules/simplereview/list',
          hash: encodeFilterSearch(state.filterSearch)
        }}
      >
        &lt; {IVR_PAGE_TITLE}
      </Link>
      <div className={s.header}>
        <div className={s.title}>Rule Editor</div>
        <div id="iiw-header-actions" className={s.actions}>
          <Toggle
            data-testid="rule-status-toggle"
            checked={isActive}
            icons={{ checked: 'ACTIVE', unchecked: 'INACTIVE' }}
            onChange={onToggleStatus}
          />
          {isAI ? (
            <Button
              isPrimary
              isOutline
              hasNewDesign
              style={{
                padding: '8px 16px'
              }}
              onClick={onToggleVersionHistory}
            >
              Version History
            </Button>
          ) : (
            <ActionsPopover
              ruleId={ruleId}
              onClone={onClone}
              onDelete={handleDelete}
              onToggleVersionHistory={onToggleVersionHistory}
            />
          )}
        </div>
      </div>
      <Modal
        size="xl"
        title="Version History"
        isVisible={showVersionHistory}
        cancelCb={onToggleVersionHistory}
        hideButtons
        content={<VersionHistory ruleId={ruleId} />}
        contentClassName={s.modalContent}
        testid="version-history-modal"
      />
    </div>
  )
}

export default EditorHeader
