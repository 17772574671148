import keyMirror from 'keymirror'

export default keyMirror({
  PUSH_NOTIFICATION: null,
  LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED: null,
  REQUEST_FORM_FETCH_REQUESTED: null,
  REQUEST_FORM_FETCH_SUCCESS: null,
  LEGAL_REQUEST_SET_SELECTED_FORM: null,
  LEGAL_REQUEST_SET_ITEM_VALUE: null,
  LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_REQUESTED: null,
  LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_SUCCESS: null,
  LEGAL_REQUEST_RESET_STATE: null,
  LEGAL_REQUEST_CREATE_REQUESTED: null,
  SET_LOADING_STATE: null,
  LEGAL_REQUEST_FORM_LIST_CHANGE_FILTER_TERM: null,
  CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED: null,
  CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS: null,
  LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_REQUESTED: null,
  LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_SUCCESS: null
})
