import { useState } from 'react'
import s from './MatterLeadContactUnassignWarning.scss'

const MatterLeadContactUnassignWarning = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }

  return isOpen ? (
    <div className={s.container}>
      <div className={s.modal}>
        <span className={s.closeIcon} onClick={handleClose}>
          ×
        </span>
        <p className={s.title}>Matter Lead cannot be removed.</p>
        <p className={s.subtitle}>
          Another contact can be assigned as a matter lead or
          <br /> existing matter lead must remain.
        </p>
      </div>
    </div>
  ) : null
}

export default MatterLeadContactUnassignWarning
