import { Component } from 'react'
import { TemplateSelection } from '../common/TemplateSelection'
import { Loading } from 'components'
import * as ACT from '../actions'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

class SelectionPageContainer extends Component {
  resetSelectedValuesState = () => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_RESET_SELECTED_VALUES
    })
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_RESET_ADDITIONAL_ATTRIBUTES
    })
  }

  getTemplate = async selectedTemplate => {
    const { userFlowState } = this.props
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_PREVIEW,
      payload: {
        selectedTemplate
      }
    })
    this.resetSelectedValuesState()

    await this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        id: selectedTemplate.id,
        navigate: this.props.router.navigate,
        userFlowState
      }
    })

    this.getAllocations(selectedTemplate.id)

    if (hasModule(MODULE.NATIVE_DMS)) {
      this.getDefaultFolders(selectedTemplate.id)
    }
  }

  getAllocations = templateId => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_ALLOCATIONS_FETCH_REQUESTED,
      payload: {
        templateId
      }
    })
  }

  getDefaultFolders = templateId => {
    this.props.dispatch({
      type: ACT.DEFAULT_FOLDERS_FETCH_REQUESTED,
      payload: { templateId }
    })
  }

  render() {
    if (this.props.loading) {
      return <Loading />
    }

    return (
      <TemplateSelection
        list={this.props.list}
        getTemplate={this.getTemplate}
        showSidebar={this.props.showSidebar}
        toggleTemplateSidebar={this.toggleTemplateSidebar}
        title="Preview Matter"
        description={this.props.description}
        processedDescription={this.props.processedDescription}
        pageKey={this.props.pageDescription.pageKey}
      />
    )
  }
}

export default SelectionPageContainer
