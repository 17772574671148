import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import Select from 'react-select'

import {
  KeyValuePairs,
  XeditableContainer,
  If,
  Panel,
  SwitchToggle,
  Input,
  PaginatedSelect
} from 'simple-core-ui'
import { BaseAsyncSelect, LegalEntitySelect } from 'common/Selects'

import { ENHANCED_REPORTING_ACCESS_MAPPING, INVOICE_EMAIL_PREFERENCE } from 'utils/constants'
import { TeamSelect } from 'teams'

import { makeGetRequest } from 'utils/api'
import { select2ToReactSelect, timezoneUtils, hasModule } from 'utils/helpers'
import { toReactSelect } from 'common/Selects/serializers'

import s from './ContactUserSettings.scss'

const emptyValue = '----'

const ReadOnly = ({ settings, settingKey }) => (
  <span className={s.readOnly}>{get(settings, settingKey, emptyValue)}</span>
)

const fetchOptions = async (url, search, noneOption) => {
  const response = await makeGetRequest(url, { params: { search_term: search, page_size: '25' } })
  const { options } = select2ToReactSelect(response)
  return noneOption ? [noneOption, ...options] : options
}

const isMatterUpdateSharingEnabled = window.serverContext.get('data_for_react')
  ?.allow_vendor_collaboration_matter_updates

const timezonesList = timezoneUtils.getTimezonesOptions().map(t => ({
  label: t.label + ' (' + t.offSet + ')',
  value: t.value
}))

const getApprovalAuthorityInitState = settings => ({
  amount: settings.approvalAuthority,
  escalateTo: settings.invoiceEscalation,
  error: false
})

const isDigit = value => /^\d+$/.test(value)

const ContactUserSettings = ({
  settings,
  updateSetting,
  connectWithGoogle,
  disconnectWithGoogle,
  isRequestingUser,
  canEdit,
  isAdmin,
  hasTableauModule,
  isLoading
}) => {
  const [customTimezone, setCustomTimezone] = useState(settings.useBrowserTimezone)
  const [oldCustomTimezone, setOldCustomTimezone] = useState(customTimezone)
  const [oldSettings, setOldSettings] = useState(settings)
  const [userRole, setUserRole] = useState(settings.userRole)
  const [approvalAuthority, setApprovalAuthority] = useState(
    getApprovalAuthorityInitState(settings)
  )
  const [userTeams, setUserTeams] = useState(settings.userTeams || [])
  const [teamSettings, setTeamSettings] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchTeamsSetting = async () => {
      try {
        const { can_edit_teams, max_team_count } = await makeGetRequest('/teams/manage/')
        setTeamSettings({
          canEditTeams: can_edit_teams,
          teamLimit: +max_team_count
        })
      } catch (error) {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    fetchTeamsSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (settings.useBrowserTimezone !== oldCustomTimezone) {
    setCustomTimezone(settings.useBrowserTimezone)
    setOldCustomTimezone(settings.useBrowserTimezone)
  }

  if (!isEqual(settings, oldSettings)) {
    setUserRole(settings.userRole)
    setApprovalAuthority(getApprovalAuthorityInitState(settings))
    setUserTeams(settings.userTeams || [])
    setOldSettings(settings)
  }

  const timeZoneTitle = isMatterUpdateSharingEnabled ? 'Time Zone' : 'Event Time zone'

  const ssoLink =
    isRequestingUser && !settings.clientUsingSSO
      ? [
          {
            key: 'Single Sign On',
            value: (
              <div
                className={s.googleAuthButton}
                onClick={settings.connectedToGoogle ? disconnectWithGoogle : connectWithGoogle}
              >
                <span className={s.googleGIcon} />
                <If condition={!settings.connectedToGoogle} fallback="Disconnect from Google">
                  Sign In with Google
                </If>
              </div>
            )
          }
        ]
      : []

  const defaultTemplate =
    isRequestingUser || canEdit
      ? [
          {
            key: 'Default Template',
            value: (
              <XeditableContainer
                type="list"
                title="Update Default Template"
                initialValue={settings.defaultTemplate}
                setValueCb={option =>
                  updateSetting({ defaultTemplate: get(option, 'value', option) })
                }
                isPortal
                position="right"
                renderForm={(_, updateFormValue, formValue) => {
                  return (
                    <PaginatedSelect
                      url={'/templates/matter_templates_json/'}
                      value={formValue}
                      onChange={updateFormValue}
                      isClearable={false}
                      serializer={toReactSelect}
                      withNoneOption={{
                        value: null,
                        label: 'None'
                      }}
                    />
                  )
                }}
              />
            )
          }
        ]
      : []

  const emailPreference = isRequestingUser
    ? [
        {
          key: 'Email Preference',
          value: (
            <XeditableContainer
              type="list"
              title="Update Email Preference"
              initialValue={settings.emailPreference}
              setValueCb={({ value }) => updateSetting({ emailPreference: value })}
              position="right"
              options={[
                {
                  label: 'One Per Invoice',
                  value: INVOICE_EMAIL_PREFERENCE.PER_INVOICE
                },
                {
                  label: 'One Per Vendor',
                  value: INVOICE_EMAIL_PREFERENCE.PER_VENDOR
                },
                {
                  label: 'Combined',
                  value: INVOICE_EMAIL_PREFERENCE.COMBINED
                }
              ]}
              validation={value => (!value ? 'An email preference is required.' : null)}
            />
          )
        }
      ]
    : []

  const tabAccess = hasTableauModule
    ? [
        {
          key: 'Tableau Access',
          value: (
            <XeditableContainer
              type="list"
              title="Update Tableau Access"
              initialValue={
                settings.tabUserActive
                  ? {
                      label: 'Yes',
                      value: true
                    }
                  : {
                      label: 'No',
                      value: false
                    }
              }
              setValueCb={({ value }) => {
                if (value) {
                  updateSetting({ tabAccessCreate: settings.userId })
                } else {
                  updateSetting({ tabAccessDelete: settings.tabUserId })
                }
              }}
              position="right"
              options={[
                {
                  label: 'Yes',
                  value: true
                },
                {
                  label: 'No',
                  value: false
                }
              ]}
              isClearable={false}
            />
          )
        }
      ]
    : []

  const loginPreferenceElement =
    settings.canEditLoginPreference && canEdit ? (
      <XeditableContainer
        type="list"
        title="Update Login Preference"
        initialValue={settings.loginPreference}
        position="right"
        setValueCb={value => updateSetting({ loginPreference: value.value })}
        options={[
          {
            label: 'Password',
            value: 'password'
          },
          {
            label: 'SSO',
            value: 'SSO'
          }
        ]}
        isClearable={false}
      />
    ) : (
      <ReadOnly settings={settings} settingKey="loginPreference.label" />
    )

  const getApprovalAuthorityLabel = () => {
    const { approvalAuthorityString, invoiceEscalation } = settings
    return !approvalAuthorityString && (!invoiceEscalation || invoiceEscalation.value === -1)
      ? 'N/A'
      : `${approvalAuthorityString || 'N/A'}, ${
          invoiceEscalation?.value !== -1 ? invoiceEscalation?.label || 'N/A' : 'N/A'
        }`
  }

  const getNotificationsValue = () => {
    const { invoiceNotifications, invoiceNotificationOptions } = settings
    return invoiceNotifications?.length
      ? invoiceNotifications.map(item =>
          invoiceNotificationOptions.find(({ value }) => item === value)
        )
      : null
  }

  const onChangeApprovalAuthority = (key, value) => {
    const isAmountUpdate = key === 'amount'
    setApprovalAuthority(prevState => {
      return {
        ...prevState,
        [key]: value,
        ...(isAmountUpdate && { error: !!value.length && !isDigit(value) })
      }
    })
  }

  const updateApprovalAuthoritySetting = () => {
    const { amount, escalateTo, error } = approvalAuthority
    const isAmountUpdated = amount !== settings.approvalAuthority
    const isEscalateToUpdated = escalateTo?.value !== settings.invoiceEscalation?.value
    if (!error && (isAmountUpdated || isEscalateToUpdated)) {
      isAmountUpdated &&
        updateSetting({
          approvalAuthority: amount,
          approvalAuthorityString:
            isDigit(amount) && isDigit(settings.approvalAuthorityString)
              ? settings.approvalAuthorityString.replace(/\d+$/, amount)
              : amount
        })
      isEscalateToUpdated && updateSetting({ invoiceEscalation: escalateTo })
    }
    resetApprovalAuthorityForm()
  }

  const resetApprovalAuthorityForm = () => {
    setApprovalAuthority(getApprovalAuthorityInitState(settings))
  }

  return (
    <section className={s.container}>
      <Panel title="Settings">
        <KeyValuePairs
          pairs={[
            ...ssoLink,
            {
              key: 'Legal Entity',
              value: (
                <If
                  condition={canEdit}
                  fallback={<ReadOnly settings={settings} settingKey="legalEntity.label" />}
                >
                  <XeditableContainer
                    type="list"
                    title="Update Legal Entity"
                    position="right"
                    initialValue={settings.legalEntity}
                    setValueCb={option =>
                      updateSetting({
                        legalEntity: get(option, 'value', -1)
                      })
                    }
                    isPortal
                    renderForm={(_, updateFormValue, formValue) => {
                      return (
                        <LegalEntitySelect
                          value={formValue}
                          onChange={updateFormValue}
                          isClearable={false}
                          paginated
                        />
                      )
                    }}
                  />
                </If>
              )
            },
            {
              key: 'User Role',
              value: isAdmin ? (
                <XeditableContainer
                  type="list"
                  title="Update User Role"
                  initialValue={settings.userRole}
                  setValueCb={() =>
                    userRole.value !== settings.userRole.value && updateSetting({ userRole })
                  }
                  cancelCb={() => setUserRole(settings.userRole)}
                  position="right"
                  controlled
                  renderForm={() => (
                    <BaseAsyncSelect
                      value={userRole}
                      url={'/company/user/roles_json/'}
                      serializer={response =>
                        response.map(({ text, value }) => ({ value, label: text }))
                      }
                      onChange={option => setUserRole(option)}
                      withMenuSearch
                    />
                  )}
                />
              ) : (
                <ReadOnly settings={settings} settingKey="userRole.label" />
              )
            },
            {
              key: 'User Team(s)',
              value:
                teamSettings.canEditTeams && teamSettings.teamLimit ? (
                  <XeditableContainer
                    title="Update User Team(s)"
                    initialValue={settings.userTeams?.map(({ label }) => label).join(', ')}
                    setValueCb={() =>
                      updateSetting({
                        userTeams: userTeams.map(
                          ({ id, name, value, label, description, memberCount }) => ({
                            id,
                            name,
                            value,
                            label,
                            description,
                            memberCount
                          })
                        )
                      })
                    }
                    cancelCb={() => setUserTeams(settings.userTeams || [])}
                    position="bottom"
                    controlled
                    renderForm={() => (
                      <TeamSelect
                        id="teams"
                        value={userTeams}
                        url="/teams/manage/"
                        multi
                        placeholder="Select teams..."
                        resultsProperty="teams"
                        withSearchBar
                        onChange={teams => {
                          setUserTeams(teams)
                        }}
                        styles={{
                          valueContainer: base => ({
                            ...base,
                            maxHeight: 120,
                            overflow: 'auto'
                          })
                        }}
                      />
                    )}
                  />
                ) : (
                  <span className={s.readOnly}>
                    {settings.userTeams?.map(({ label }) => label).join(', ') || emptyValue}
                  </span>
                )
            },
            ...(settings.enhancedReportingAccess
              ? [
                  {
                    key: 'Enhanced Reporting Access',
                    value: isAdmin ? (
                      <XeditableContainer
                        type="list"
                        title="Update Enhanced Reporting Access"
                        initialValue={ENHANCED_REPORTING_ACCESS_MAPPING.find(
                          ({ value }) => value === settings.enhancedReportingAccess.value
                        )}
                        setValueCb={option =>
                          updateSetting({ enhancedReportingAccess: get(option, 'value') })
                        }
                        position="right"
                        options={ENHANCED_REPORTING_ACCESS_MAPPING}
                      />
                    ) : (
                      <ReadOnly settings={settings} settingKey="enhancedReportingAccess.label" />
                    )
                  }
                ]
              : []),
            {
              key: 'Last Logged In',
              value: <ReadOnly settings={settings} settingKey="lastLogin" />
            },
            {
              key: 'Forced Logout On',
              value: <ReadOnly settings={settings} settingKey="forcedLogout" />
            },
            {
              key: 'Login Preference',
              value: loginPreferenceElement
            },
            {
              key: 'Invoice Approval Authority',
              value: isAdmin ? (
                <XeditableContainer
                  title="Update Invoice Approval Authority"
                  initialValue={getApprovalAuthorityLabel()}
                  setValueCb={updateApprovalAuthoritySetting}
                  cancelCb={() => resetApprovalAuthorityForm()}
                  isDisabled={approvalAuthority.error}
                  position="right"
                  controlled
                  renderForm={() => (
                    <div className={s.approvalAuthorityForm}>
                      <div className={s.label}>Max Invoice Approval Authority</div>
                      <Input
                        text={approvalAuthority.amount}
                        placeholder="Enter amount"
                        type="text"
                        style={{
                          padding: '8px 6px',
                          borderRadius: '4px',
                          ...(approvalAuthority.error ? { borderColor: 'red' } : {})
                        }}
                        onChangeCb={e => onChangeApprovalAuthority('amount', e.target.value)}
                      />
                      {approvalAuthority.error && (
                        <div className={s.errorLabel}>Use numbers only.</div>
                      )}
                      <div className={s.label}>Escalates To</div>
                      <BaseAsyncSelect
                        dbWait={500}
                        value={approvalAuthority.escalateTo}
                        url={'/rules/select2_json/'}
                        withMenuSearch
                        serializer={({ results }) => {
                          const options = results.map(({ text, id }) => ({
                            label: text,
                            value: id
                          }))
                          return [{ value: -1, label: 'None' }, ...options]
                        }}
                        requestParamsSerializer={search => ({
                          search_term: search,
                          page_size: '50'
                        })}
                        onChange={value => onChangeApprovalAuthority('escalateTo', value)}
                      />
                    </div>
                  )}
                />
              ) : (
                <span className={s.readOnly}>{getApprovalAuthorityLabel()}</span>
              )
            },
            {
              key: 'Invoice Notifications',
              value: (
                <XeditableContainer
                  type="list"
                  title="Update Invoice Notifications"
                  initialValue={getNotificationsValue()}
                  setValueCb={value => {
                    updateSetting({
                      invoiceNotifications: value?.length ? value.map(({ value }) => value) : []
                    })
                  }}
                  position="top"
                  isMulti
                  controlled
                  options={settings.invoiceNotificationOptions}
                />
              )
            },
            ...emailPreference,
            ...defaultTemplate,
            ...tabAccess,
            ...(hasModule('has_event_management')
              ? [
                  {
                    key: timeZoneTitle,
                    value: (
                      <>
                        <p className={s.timezoneSubtitle}>
                          Set Time zone automatically using browser settings
                        </p>
                        <div style={{ display: 'flex' }}>
                          <SwitchToggle
                            onToggle={checked => {
                              if (checked && settings.timezone) {
                                updateSetting({ timezone: null })
                              }
                              setCustomTimezone(checked)
                            }}
                            className={s.timezoneToggle}
                            checked={customTimezone}
                          />
                          <Select
                            isDisabled={customTimezone}
                            value={
                              settings.timezone
                                ? timezonesList.find(t => t.value === settings.timezone)
                                : null
                            }
                            onChange={value => updateSetting({ timezone: value.value })}
                            placeholder="Select Time zone"
                            options={timezonesList}
                            className={s.timezoneSelect}
                          />
                        </div>
                      </>
                    )
                  }
                ]
              : [])
          ]}
          style={{ padding: '0 1.5em' }}
          stackedKeys
          isLoading={isLoading}
        />
      </Panel>
    </section>
  )
}

export default ContactUserSettings
