import { useState, useMemo } from 'react'
import {
  Title,
  DESIGN,
  Button,
  ModalContainer,
  DataTableContainer,
  DataTableService
} from 'simple-core-ui'
import s from './AccrualsVsActualsCard.scss'
import InnerTable from './InnerTable/InnerTable'
import MatterListInnerTable from './MatterListInnerTable/MatterListInnerTable'

import {
  tableLabels,
  tooltipText,
  modalTableLabels,
  innerMatterTableColumns,
  NO_CATEGORIES,
  PAGE_SIZE
} from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import ACT from '../actions'

const {
  color: { blue800 }
} = DESIGN

const AccrualsVsActualsCard = ({
  vendorUrl,
  startDate,
  endDate,
  vendorTotal,
  vendorName,
  vendorId,
  vendorCurrency
}) => {
  const vendorMatterList = useSelector(state => state.accrualsVsActuals.selectedVendorMatterList)
  const { matterTotalResults } = useSelector(state => state.accrualsVsActuals)
  const { loading } = useSelector(state => state.app)
  const isLoading = loading.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH

  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  const { getSurroundingPages } = DataTableService
  const { previousPage, nextPage } = getSurroundingPages(page, PAGE_SIZE, matterTotalResults)

  //TODO: Update DataTable to have an option to disable sortable for all columns

  const handleShowDetails = openModal => () => {
    openModal()
    dispatch({
      type: ACT.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_REQUESTED,
      payload: {
        page: 1,
        pageSize: PAGE_SIZE,
        loadingLock: 'on',
        vendor_id: vendorId,
        vendor_currency: vendorCurrency,
        start_date: startDate ? startDate : null,
        end_date: endDate ? endDate : null
      }
    })
  }

  const updateTable = params => {
    if (previousPage !== params.page || nextPage !== params.page) {
      setPage(params.page)
      dispatch({
        type: ACT.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_REQUESTED,
        payload: {
          loadingLock: 'on',
          page: params.page,
          pageSize: PAGE_SIZE,
          vendor_id: vendorId,
          vendor_currency: vendorCurrency,
          start_date: startDate ? startDate : null,
          end_date: null
        }
      })
    }
  }

  const handleCancel = () => {
    setPage(1)
    dispatch({
      type: ACT.ACCRUALS_MATTER_LIST_RESET
    })
  }

  const rows = useMemo(
    () =>
      vendorMatterList.map(
        ({ matter_id, matter_name, entity_name, table_data }, matterListIndex) => ({
          id: matter_id,
          cells: [
            {
              columnKey: 'entityName',
              content: <span className={s.matterTableText}>{entity_name}</span>
            },
            {
              columnKey: 'matterName',
              content: <span className={s.matterTableText}>{matter_name}</span>
            },
            {
              columnKey: 'acrrualsTable',
              content: (
                <div className={s.tableContainer}>
                  <div className={`${s.tableHeader} ${s.mattersTableHeader}`}>
                    {modalTableLabels.map((label, index) => (
                      <div
                        key={label}
                        className={`${s.modalCardText} ${s.bold} ${s.rightAlign} ${s.gray} ${
                          index === 3 ? s.deltaRowLabel : ''
                        }`}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                  <div className={`${s.tableColumnsContainer} ${s.matterTableColumnsContainer}`}>
                    {table_data.map((props, idx) => (
                      <MatterListInnerTable
                        {...props}
                        idx={idx}
                        key={idx}
                        startDate={startDate}
                        matterListIndex={matterListIndex}
                      />
                    ))}
                  </div>
                </div>
              )
            }
          ]
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorMatterList]
  )

  return (
    <div className={s.card}>
      <a href={`${vendorUrl}?fromAccrualsVsActuals=true`}>
        <Title
          text={`${vendorName} (${vendorCurrency})`}
          rank={3}
          style={{
            color: blue800,
            fontWeight: 700,
            marginBottom: 28,
            display: 'inline'
          }}
        />
      </a>
      <ModalContainer
        title={
          <>
            <p className={s.modalHeader}>
              {vendorName} Details ({vendorCurrency})
            </p>
            <div className={s.titleDeltaLine}></div>
          </>
        }
        content={
          <div className={s.tableWrapper}>
            {/*//TODO: Replace DataTableContainer with DataTableWrapper*/}
            <DataTableContainer
              initialValues={{
                pageSize: PAGE_SIZE,
                ordering: { columnKey: 'matterName', isDesc: true },
                page: page,
                search: '',
                category: ''
              }}
              className={s.modalTableContainer}
              page={page}
              panelStyles={{ boxShadow: 'none' }}
              previousPage={previousPage}
              nextPage={nextPage}
              rows={rows}
              columns={innerMatterTableColumns}
              categories={NO_CATEGORIES}
              hasSearch={false}
              updateTable={updateTable}
              totalEntries={matterTotalResults}
              isLoading={isLoading}
              filteredTotal={matterTotalResults}
              shouldShowPageSizeOptions={false}
              striped
            />
          </div>
        }
        cancelCb={() => handleCancel()}
        cancelText={`Close`}
        size="xlg"
        hideCancelBtn
      >
        {openModal => (
          <Button
            isSecondary
            onClick={handleShowDetails(openModal)}
            style={{ float: 'right', marginBottom: '20px' }}
          >
            Details
          </Button>
        )}
      </ModalContainer>
      <div className={s.tableContainer}>
        <div className={s.tableHeader}>
          <p className={`${s.cardText} ${s.bold} ${s.gray}`} style={{ height: 22 }}></p>
          {tableLabels.map((label, index) => (
            <div key={label} className={`${s.cardText} ${s.bold} ${s.gray}`}>
              {label}
              <div className={s.circle}>
                <i data-tip={tooltipText[index]} className="simple-line-icon-info" />
              </div>
            </div>
          ))}
          <div className={s.deltaLine}></div>
          <p className={`${s.cardText} ${s.bold}`}>Delta</p>
        </div>
        <div className={s.tableColumnsContainer}>
          {vendorTotal.map((props, idx) => (
            <InnerTable {...props} idx={idx} key={idx} startDate={startDate} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default AccrualsVsActualsCard
