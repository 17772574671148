import get from 'lodash/get'
import { Row, Task } from './types'

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
      return typeof cellContent === 'string' ? cellContent.toLowerCase() : ''
    case 'taskId':
      return typeof cellContent === 'string' ? +cellContent.replace(/\D/g, '') : ''
    case 'priority':
    case 'taskType':
    case 'status':
      return typeof cellContent === 'object' ? cellContent.name.toLowerCase() : ''
    case 'dueDate':
      return typeof cellContent === 'string' ? '' : ''
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

interface TaskDependencyGraph {
  [id: string]: number[]
}

export const buildAdjacencyList = (tasks: Task[]): TaskDependencyGraph => {
  const adjacencyList = {} as TaskDependencyGraph
  for (const t of tasks) {
    if (!adjacencyList[t.id]) {
      adjacencyList[t.id] = tasks
        .filter(task => task.relativeDueDate?.relativeDateParams?.task?.value === t.id)
        .map(task => task.id)
    }
  }
  return adjacencyList
}

const deepFirstSearch = (tasks: TaskDependencyGraph, id: number): { [id: string]: boolean } => {
  const dependencies = tasks[id] || []
  let visited: { [id: string]: boolean } = {}
  for (const dependencyId of dependencies) {
    if (!visited[dependencyId]) {
      visited[dependencyId] = true
      visited = { ...visited, ...deepFirstSearch(tasks, dependencyId) }
    }
  }
  return visited
}

export const ancestorsOf = (tasks: Task[], id: number): number[] => {
  const graph = buildAdjacencyList(tasks)
  const visited = deepFirstSearch(graph, id)
  return Object.keys(visited).map(id => +id)
}
