import update from 'immutability-helper'
import findIndex from 'lodash/findIndex'
import sortedUniqBy from 'lodash/sortedUniqBy'
import uniqBy from 'lodash/uniqBy'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import intersectionWith from 'lodash/intersectionWith'
import intersection from 'lodash/intersection'
import differenceWith from 'lodash/differenceWith'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import defaultTo from 'lodash/defaultTo'
import difference from 'lodash/difference'
import union from 'lodash/union'

import ACT from './actions'
import { SCOPE } from 'utils/constants'
import { WARNING_MESSAGE_TYPE } from 'contacts/constants'
import { MATTER_STATUS } from 'matters/constants'

export const getAssignedRoles = assignedContacts =>
  sortedUniqBy(
    sortBy(
      assignedContacts.reduce((acc, { roles }) => acc.concat(roles), []),
      'label'
    ),
    'id'
  )

export const filterContactsByRoleId = (contacts, roleId) =>
  roleId === null
    ? contacts
    : contacts.filter(contact => contact.roles.some(role => role.id === roleId))

export const searchContactFields = (contact, search) => {
  const normalizedSearch = search.toLowerCase()

  const normalizedFieldValues = [
    `${contact.firstName} ${contact.lastName}`,
    `${contact.title} - ${get(contact, 'org.name', '')}`,
    contact.phone,
    contact.email
  ]
    .join('')
    .toLowerCase()

  return normalizedFieldValues.includes(normalizedSearch)
}

export const searchRoleFields = (role, search) => {
  const normalizedSearch = search.toLowerCase()

  const normalizedFieldValue = role.label.toLowerCase()

  return normalizedFieldValue.includes(normalizedSearch)
}

export const onlyAssignedContacts = contacts => contacts.filter(({ roles }) => roles.length)

export const filterAssignedContactsByValue = (assignedContacts, search) => {
  const normalizedSearch = search.toLowerCase()

  return assignedContacts.filter(assignedContact =>
    searchContactFields(assignedContact, normalizedSearch)
  )
}

export const filterAssignedContactsByIndex = (assignedContacts, roleId, contactId) => {
  if (isEmpty(assignedContacts)) {
    return []
  }

  const contactIndex = findIndex(assignedContacts, contact => contact.id === contactId)

  const roleIndex = findIndex(assignedContacts[contactIndex].roles, role => role.id === roleId)

  return update(assignedContacts, {
    [contactIndex]: {
      roles: {
        $splice: [[roleIndex, 1]]
      }
    }
  }).filter(assignedContact => assignedContact.roles.length)
}

export const sortContacts = contacts =>
  sortBy(contacts, ['firstName', 'lastName', 'title', 'phone', 'email'])

export const sortRoles = roles => sortBy(roles, ['label'])

export const updateExistingContact = (contacts, contact, contactIndex, roles, overwrite) => {
  const roleSet = overwrite ? roles : [...contact.roles, ...roles]

  const updatedContact = {
    ...contact,
    roles: sortedUniqBy(sortRoles(roleSet), role => role.id)
  }

  return update(contacts, {
    [contactIndex]: { $set: updatedContact }
  })
}

export const appendNewContact = (contacts, newContact, roles) => {
  const contactToAppend = {
    ...newContact,
    roles: sortRoles(roles)
  }

  return sortContacts(update(contacts, { $push: [contactToAppend] }))
}

export const dedupeAssignedSingularRoles = (assignedContacts, singularRoles) =>
  assignedContacts
    .map(contact => ({
      ...contact,
      roles: differenceWith(
        contact.roles,
        singularRoles,
        (contactRole, singularRole) => contactRole.id === singularRole.id
      )
    }))
    .filter(({ roles }) => !isEmpty(roles))

export const updateAssignedContacts = (
  assignedContacts,
  contactToUpdate,
  roles,
  overwrite = false
) => {
  const singularRoles = roles.filter(role => role.singular)
  const filteredAssignedContacts = dedupeAssignedSingularRoles(assignedContacts, singularRoles)

  const contactIndex = findIndex(
    filteredAssignedContacts,
    contact => contact.id === contactToUpdate.id
  )

  const contactAlreadyExists = contactIndex > -1

  const updatedAssignedContacts = contactAlreadyExists
    ? updateExistingContact(
        filteredAssignedContacts,
        contactToUpdate,
        contactIndex,
        roles,
        overwrite
      )
    : appendNewContact(filteredAssignedContacts, contactToUpdate, roles)

  return updatedAssignedContacts
}

export const isCounselGo = role => isCounselGoUser(role) || isCounselGoAdmin(role)

export const contactsWithSingularRoles = contacts =>
  contacts.filter(contact => contactHasSingularRole(contact))

export const contactHasSingularRole = contact => contact.roles.some(role => role.singular)

export const getSingularRoles = contacts =>
  uniqBy(
    flatten(
      contactsWithSingularRoles(contacts).map(({ roles }) =>
        roles.filter(({ singular }) => singular)
      )
    ),
    'id'
  )

export const getSingularRoleIntersect = (contacts, roles) => {
  const singularContacts = contactsWithSingularRoles(contacts)
  const contactSingularRoles = getSingularRoles(contacts)

  const roleIntersection = intersectionWith(
    contactSingularRoles,
    roles,
    (roleA, roleB) => roleA.id === roleB.id
  )

  return roleIntersection.map(role => ({
    contact: singularContacts.find(({ roles }) => roles.includes(role)),
    role
  }))
}

export const containsCounselGo = roles =>
  roles && roles.reduce((acc, role) => acc || isCounselGo(role), false)

export const containsCounselGoAdmin = roles => roles.some(role => isCounselGoAdmin(role))

export const isCounselGoUser = role => role.systemName === '_cg'

export const isCounselGoAdmin = role => role.systemName === '_cg_admin'

export const isMatterLead = role => role.systemName === '_ml'

export const hasMatterLead = roles => roles && roles.some(isMatterLead)

export const isMatterClosed = status => status?.slug === MATTER_STATUS.CLOSED

export const isMatterOpen = status => status?.slug === MATTER_STATUS.OPEN

export const orgTypeRoleMap = roles => groupBy(roles, 'type')

export const getSystemNames = roles => roles.map(({ systemName }) => systemName)

export const contactIsCompatibleWithRole = (contact, role, specialAccessRoles) => {
  const specialAccessSystemNames = getSystemNames(specialAccessRoles)
  const isSpecialAccessRole = specialAccessSystemNames.includes(role.systemName)
  const orgTypeMap = orgTypeRoleMap(specialAccessRoles)

  const compatibleSpecialRoles = get(orgTypeMap, contact.type, []).map(
    ({ systemName }) => systemName
  )

  return !isSpecialAccessRole || compatibleSpecialRoles.includes(role.systemName)
}

export const rolesAreCompatible = (roleSystemNameA, roleSystemNameB, specialAccessRoles) => {
  const specialAccessSystemNames = getSystemNames(specialAccessRoles)
  const bothAreSpecial =
    intersection(specialAccessSystemNames, [roleSystemNameA, roleSystemNameB]).length === 2

  const orgTypeMap = orgTypeRoleMap(specialAccessRoles)

  const rolesShareGroupedType = Object.keys(orgTypeMap).some(
    roleType =>
      intersection(getSystemNames(orgTypeMap[roleType]), [roleSystemNameA, roleSystemNameB])
        .length === 2
  )

  return !bothAreSpecial || rolesShareGroupedType
}

export const filterContactsByRoles = (contact, roles, specialAccessRoles) => {
  return !isEmpty(roles) ? contactIsCompatibleWithRole(contact, roles[0], specialAccessRoles) : true
}

export const filterRolesByContactType = (contact, roles, specialAccessRoles) =>
  contact && roles
    ? roles.filter(role => contactIsCompatibleWithRole(contact, role, specialAccessRoles))
    : roles

export const filterRoleOptions = (
  contact,
  roles,
  selectedRoles,
  specialAccessRoles = [],
  userCanEditContactCG
) => {
  const roleOptions =
    Array.isArray(selectedRoles) && !isEmpty(selectedRoles)
      ? filterRolesByContactType(contact, roles, specialAccessRoles).filter(role =>
          selectedRoles.every(selectedRole =>
            rolesAreCompatible(selectedRole.systemName, role.systemName, specialAccessRoles)
          )
        )
      : filterRolesByContactType(contact, roles, specialAccessRoles)

  return roleOptions.filter(role => {
    const specialAccessRole = specialAccessRoles.find(r => r.systemName === role.systemName)
    const isCGRole = specialAccessRole && containsCounselGo([specialAccessRole])
    return !isCGRole || userCanEditContactCG
  })
}

export const getValuesWithEditable = (value, specialAccessRoles = [], userCanEditContactCG) => {
  return value
    ? value.map(v => {
        const specialAccessRole = specialAccessRoles.find(r => r.systemName === v.systemName)
        return specialAccessRole && containsCounselGo([specialAccessRole]) && !userCanEditContactCG
          ? { ...v, clearableValue: false }
          : v
      })
    : []
}

export const nullifyContactIfIncompatible = (contact, roles, specialAccessRoles) =>
  contact &&
  roles &&
  roles.every(role => contactIsCompatibleWithRole(contact, role, specialAccessRoles))
    ? contact
    : null

export const isClientContact = contact => ['client', 'legal_entity'].includes(get(contact, 'type'))

export const isVendorContact = contact => get(contact, 'type') === 'vendor'

export const inferContactType = (contact, fields) => {
  let inferredContact = {
    firstName: '',
    lastName: '',
    title: '',
    company: '',
    vendor: null,
    phone: '',
    email: '',
    type: null,
    roles: []
  }

  const vendorField = get(fields, 'vendor', null)
  if (vendorField && get(vendorField, 'value', -1) !== -1) {
    inferredContact = {
      ...inferredContact,
      vendor: vendorField,
      type: 'vendor'
    }
  } else if (contact) {
    inferredContact = contact
  }

  const rolesField = get(fields, 'roles', [])
  if (rolesField) {
    inferredContact['roles'] = rolesField
  }

  return inferredContact
}

export const patchRoleTimestamp = (selectedContact, assignedContacts) => {
  const activelyAssignedContact = assignedContacts.find(
    assignedContact => assignedContact.id === selectedContact.id
  )

  return activelyAssignedContact || selectedContact
}

export const CONTACTS_SEARCH_URL = '/manage/contacts/search/'

export const CONTACTS_MATTER_ROLES_URL = '/manage/contacts/matterroles/'

export const CONTACTS_VENDOR_ROLES_URL = '/manage/contacts/vendorroles/'

export const CONTACTS_MATTER_BASE_URL = '/manage/contacts/matter/'

export const CONTACTS_VENDOR_BASE_URL = '/manage/contacts/vendor/'

export const scopedRequestConfig = (actionType, scope, payload) => {
  const urlMap = {
    [ACT.CONTACTS_FETCH_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: CONTACTS_SEARCH_URL,
        // v --> Vendor Id
        // l --> Include Login Contacts
        // f --> Include Floating Contacts
        params: { v: payload, l: 'true', f: 'true' }
      },
      [SCOPE.MATTER]: {
        url: CONTACTS_SEARCH_URL,
        // l --> Include Login Contacts
        // f --> Include Floating Contacts
        // (Vendors are implicity included with matter id specified)
        params: { m: payload, l: 'true', f: 'true' }
      }
    },
    [ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: CONTACTS_SEARCH_URL,
        // v --> Vendor Id
        // l --> Include Login Contacts
        // f --> Include Floating Contacts
        // a --> Only Assigned Contacts
        params: { v: payload, l: 'true', f: 'true', a: 'true' }
      },
      [SCOPE.MATTER]: {
        url: CONTACTS_SEARCH_URL,
        // m --> Matter Id
        // l --> Include Login Contacts
        // f --> Include Floating Contacts
        // (Vendors are implicity included with matter id specified)
        // a --> Only Assigned Contacts
        params: { m: payload, l: 'true', f: 'true', a: 'true' }
      }
    },
    [ACT.CONTACT_ROLES_FETCH_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: CONTACTS_VENDOR_ROLES_URL
      },
      [SCOPE.MATTER]: {
        url: CONTACTS_MATTER_ROLES_URL
      }
    },
    [ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: `${CONTACTS_VENDOR_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: get(payload, 'roles'), role_timestamp: get(payload, 'roleTimestamp') }
      },
      [SCOPE.MATTER]: {
        url: `${CONTACTS_MATTER_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: get(payload, 'roles'), role_timestamp: get(payload, 'roleTimestamp') }
      }
    },
    [ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: `${CONTACTS_VENDOR_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: payload.roles ?? [], role_timestamp: get(payload, 'roleTimestamp') }
      },
      [SCOPE.MATTER]: {
        url: `${CONTACTS_MATTER_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: payload.roles ?? [], role_timestamp: get(payload, 'roleTimestamp') }
      }
    },
    [ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_REQUESTED]: {
      [SCOPE.VENDOR]: {
        url: `${CONTACTS_VENDOR_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: get(payload, 'roles'), role_timestamp: get(payload, 'roleTimestamp') }
      },
      [SCOPE.MATTER]: {
        url: `${CONTACTS_MATTER_BASE_URL}${get(payload, 'scopeId')}/${get(
          payload,
          'contactId'
        )}/assign/`,
        params: { roles: get(payload, 'roles'), role_timestamp: get(payload, 'roleTimestamp') }
      }
    }
  }

  return urlMap[actionType][scope]
}

export const INITIAL_AFFILIATION_PARAMS = {
  pageSize: 10,
  ordering: { columnKey: 'name', isDesc: false },
  search: '',
  page: 1,
  category: SCOPE.MATTER
}

export const INITIAL_ADDRESS_BOOK_PARAMS = {
  pageSize: 25,
  ordering: { columnKey: 'firstName', isDesc: false },
  search: '',
  page: 1
}

export const AFFILIATION_NOT_IMPLEMENTED = {
  rows: [],
  totalEntries: 0,
  filteredEntries: 0
}

export const AFFILIATION_URLS = contactId => ({
  [SCOPE.MATTER]: `/manage/contacts/info_by_matter/${contactId}/`,
  [SCOPE.VENDOR]: `/manage/contacts/info_by_vendor/${contactId}/`,
  [SCOPE.PRACTICE_AREA]: `/manage/contacts/info_by_mattergroup/${contactId}/`,
  [SCOPE.LEGAL_ENTITY]: `/manage/contacts/info_by_legalentity/${contactId}/`
})

export const OPEN_AFFILIATION_CATEGORIES = [SCOPE.MATTER, SCOPE.VENDOR]

export const colorArrayByOrgType = type =>
  ({
    client: [54, 169, 225],
    legal_entity: [54, 169, 225],
    vendor: [239, 98, 98],
    company: [114, 116, 121]
  }[type])

export const formatContactFullName = contactNameFields => {
  const { prefix, firstName, middleName, lastName, suffix } = contactNameFields
  return `${defaultTo(prefix, '')} ${defaultTo(firstName, '')} ${defaultTo(
    middleName,
    ''
  )} ${defaultTo(lastName, '')} ${defaultTo(suffix, '')}`
    .replace(/\s{2,}/g, ' ')
    .trim()
}

export const emptyContact = {
  firstName: '',
  lastName: '',
  title: '',
  company: '',
  org: {
    id: -1,
    name: '',
    type: 'company'
  },
  phone: '',
  email: '',
  url: '',
  notes: '',
  type: '',
  createdDate: '',
  address: {
    city: '',
    state: ''
  }
}

const removeRolesWithDependency = (roles, deletedRole) => {
  return roles
    ? roles.filter(role => !role.dependency || role.dependency !== deletedRole[0].systemName)
    : []
}

const getRolesWithDependency = (roles, availableRoles) => {
  const dependencyArray = roles?.filter(role => role.dependency).map(role => role.dependency) || []
  const possibleRolesToAdd = availableRoles.filter(role =>
    dependencyArray.includes(role.systemName)
  )
  return union(roles, possibleRolesToAdd)
}

export const getDependentRoles = (selectedRoles, prevRoles, availableRoles) => {
  const deletedRole = difference(prevRoles, selectedRoles)
  if (deletedRole.length) {
    return removeRolesWithDependency(selectedRoles, deletedRole)
  } else {
    return getRolesWithDependency(selectedRoles, availableRoles)
  }
}

export const getWarningMessageType = (
  deletedRole,
  selectedRoles,
  currentRoles,
  isExistingContact
) => {
  const {
    CONTAINS_CG_USER,
    CONTAINS_CG_ADMIN,
    CONTACT_REMOVE_CG_USER,
    EXISTING_CONTACT_REMOVE_CG_USER
  } = WARNING_MESSAGE_TYPE

  const showRemoveExistingAdminWarning =
    deletedRole &&
    isCounselGoUser(deletedRole) &&
    isExistingContact &&
    containsCounselGoAdmin(selectedRoles)
  const showRemoveUserWarning = deletedRole && isCounselGoUser(deletedRole) && !isExistingContact
  const showAddUserWarning =
    containsCounselGo(currentRoles) && !containsCounselGoAdmin(currentRoles)
  const showAddAdminWarning =
    containsCounselGo(currentRoles) && containsCounselGoAdmin(currentRoles)

  if (showRemoveExistingAdminWarning) {
    return EXISTING_CONTACT_REMOVE_CG_USER
  } else if (showRemoveUserWarning) {
    return CONTACT_REMOVE_CG_USER
  } else if (showAddUserWarning) {
    return CONTAINS_CG_USER
  } else if (showAddAdminWarning) {
    return CONTAINS_CG_ADMIN
  } else {
    return ''
  }
}

export const maxCGAdminsWarningMessageType = WARNING_MESSAGE_TYPE.MAX_CG_ADMINS_REACHED

export const getCGAdminsNo = (addedRole, deletedRole, currentCGAdminsNo) => {
  return containsCounselGoAdmin(addedRole)
    ? currentCGAdminsNo + 1
    : containsCounselGoAdmin(deletedRole)
    ? currentCGAdminsNo - 1
    : currentCGAdminsNo
}
export const getCGAdminOffWarning = () => WARNING_MESSAGE_TYPE.CG_ADMIN_OFF

export const setDataWithMessage = (roles, currentCGAdminsNo, cb) => {
  let warningMessageType = ''
  if (containsCounselGo(roles)) {
    warningMessageType = getCGAdminOffWarning()
  }
  cb(roles, currentCGAdminsNo, warningMessageType)
}
