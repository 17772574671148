import { Fragment } from 'react'
import s from './AssociatedEmail.scss'
import moment from 'moment'
import cn from 'classnames'

const AssociatedEmail = ({ email }) => {
  const {
    email_archives__subject: subject,
    email_archives__date: date,
    email_archives__body: body,
    email_archives__to_email: to,
    email_archives__from_email: from
  } = email
  return (
    <Fragment>
      <header className={cn(s.header, s.flex)}>
        <label className={s.label}>Email Subject: </label>
        <span className={s.info}>{subject}</span>
      </header>
      <content className={s.content}>
        <div className={s.flex}>
          <label className={s.label}>Date: </label>
          <span className={s.info}>{moment(date).format('MM/DD/YYYY')}</span>
        </div>
        <div className={s.flex}>
          <label className={s.label}>To: </label>
          <span className={s.info}>{to}</span>
        </div>
        <div className={s.flex}>
          <label className={s.label}>From: </label>
          <span className={s.info}>{from}</span>
        </div>
        <div className={s.flex}>
          <label className={s.label}>Message: </label>
        </div>
        <div className={s.message}>{body}</div>
      </content>
    </Fragment>
  )
}

export default AssociatedEmail
