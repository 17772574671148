import get from 'lodash/get'
import AccountSettingsItem from './AccountSettingsItem'
import APP_PAGES_MAP from 'data/app_pages_map'
import s from '../styles/AccountSettings.scss'
import { SYSTEM_EMAIL } from '../constants'

const AccountSettings = ({ userInfo, accountLinks }) => {
  const nameText =
    userInfo && userInfo.firstName ? `${userInfo.firstName} ${userInfo.lastName}` : userInfo.email

  const [firstLink, ...restLinks] = accountLinks

  const renderLink = link => (
    <AccountSettingsItem
      {...APP_PAGES_MAP[link.name]}
      icon={
        <span className={s.icon}>
          <div className={`simple-line-icon-${APP_PAGES_MAP[link.name].icon}`} />
        </span>
      }
      url={link.url}
      key={link.name}
      id={`slm_${link.name}_link`}
    />
  )

  return (
    <ul className={s.container}>
      <li className={s.userContainer} data-testid="user_info">
        <div>{nameText}</div>
        <div>
          {userInfo.clientName}
          {userInfo.email === SYSTEM_EMAIL && ` · ${userInfo.clientId}`}
        </div>
      </li>
      <div className={s.accountRule} />
      {firstLink && renderLink(firstLink)}
      {get(window, 'credentials.user.isCSM', false) && (
        <AccountSettingsItem
          text="Debug Snapshot"
          icon={
            <span className={s.icon}>
              <div className="simple-line-icon-camera" />
            </span>
          }
          key="debugSnapshot"
          onClick={window.debugSnapshot}
          id="slm_debug_snapshot_link"
        />
      )}
      {restLinks.map(renderLink)}
    </ul>
  )
}

export default AccountSettings
