import * as ACT from './actions'
import update from 'immutability-helper'
import { ROOT_PATH } from './constants'

export const initialState = {
  params: {
    pageSize: 25,
    ordering: { columnKey: 'created', isDesc: true },
    search: '',
    page: 1,
    category: 'all'
  },
  attachments: [],
  files: [],
  filesToUpload: [],
  isLoading: false,
  folder: {
    name: ''
  },
  file: {},
  folders: [],
  categories: [],
  labels: [],
  currentFolder: {
    id: null,
    path: ROOT_PATH,
    type: '',
    canDownload: false
  },
  versionFile: {},
  email: ''
}

const updateFileBlob = (state, arr, index, property, value) => {
  const file = state.files[index]
  const fileClone = new Blob([file], { type: file.type })
  fileClone.name = file.name
  fileClone.path = file.path
  fileClone.id = file.id
  fileClone.category = file.category || ''
  fileClone.labels = file.labels || []

  fileClone[property] = value

  arr.splice(index, 1, fileClone)
  return { ...state, files: arr }
}

export const reducer = (state, action) => {
  let {
    attachments,
    params,
    id,
    file,
    files,
    index,
    name,
    extension,
    fileName,
    folderName,
    folder,
    folders,
    previewEnabled,
    currentFolder,
    selectedCategory,
    selectedLabels,
    versionFile,
    email,
    rootFileStructure,
    sharedWithUsers,
    isShared
  } = action.payload
  let arr = [...state.files]

  switch (action.type) {
    case ACT.START_LOADING:
      return { ...state, isLoading: true }
    case ACT.STOP_LOADING:
      return { ...state, isLoading: false }
    case ACT.UPDATE_TABLE_PARAMS:
      return { ...state, params }
    case ACT.ADD_MATTER_ATTACHMENTS:
      if (files.length === 1) {
        const i = state.attachments.findIndex(f => f.name === files[0].name)

        if (~i) {
          return update(state, {
            attachments: {
              [i]: { $set: files[0] }
            }
          })
        } else {
          return { ...state, attachments: [...state.attachments, ...files] }
        }
      }
      return { ...state, attachments: [...state.attachments, ...files] }
    case ACT.DELETE_MATTER_ATTACHMENT:
      const newAttachments = state.attachments.filter(a => a.id !== +id)
      return { ...state, attachments: newAttachments }
    case ACT.DELETE_MATTER_FOLDER:
      folders = state.folders.filter(a => a.id !== id)
      return { ...state, folders }
    case ACT.ADD_FILES:
      return { ...state, files: [...arr, ...files] }
    case ACT.ADD_DUPLICATED_FILES:
      return { ...state, duplicatedFiles: [...files] }
    case ACT.CLEAR_DUPLICATED_FILES:
      return { ...state, duplicatedFiles: [] }
    case ACT.ADD_OTHER_DIR_DUP_FILES:
      return { ...state, otherDirDuplicatedFiles: [...files] }
    case ACT.CLEAR_FILES:
      return { ...state, files: [] }
    case ACT.CLEAR_FILES_TO_UPLOAD:
      return { ...state, filesToUpload: [] }
    case ACT.ADD_FILES_TO_UPLOAD:
      return { ...state, filesToUpload: [...state.filesToUpload, ...files] }
    case ACT.REMOVE_FILE:
      arr.splice(index, 1)
      return { ...state, files: arr }
    case ACT.CHANGE_FILE_NAME:
      return updateFileBlob(state, arr, index, 'name', `${name}.${extension}`)
    case ACT.RENAME_FILE:
      if (id) {
        index = state.attachments.findIndex(f => f.id === id)
        return update(state, {
          attachments: {
            [index]: { $set: file }
          }
        })
      }
      return { ...state, file: { ...state.file, name: fileName, edited: true } }
    case ACT.RENAME_FOLDER:
      if (id) {
        index = state.folders.findIndex(f => f.id === id)
        return update(state, {
          folders: {
            [index]: { $set: folder }
          }
        })
      }
      return { ...state, folder: { ...state.folder, name: folderName, edited: true } }
    case ACT.CLEAR_FILE_NAME:
      return {
        ...state,
        file: {
          name: ''
        }
      }
    case ACT.CLEAR_FOLDER_NAME:
      return {
        ...state,
        folder: {
          name: ''
        }
      }
    case ACT.ADD_FOLDER:
      return { ...state, folders: [...state.folders, folder] }
    case ACT.SELECT_FOLDER:
      const selectedFolder = state.folders.find(f => f.id === id)
      return { ...state, folder: selectedFolder }
    case ACT.FETCH_MATTER_FOLDER_ITEMS:
      return { ...state, folders, attachments, previewEnabled, currentFolder, rootFileStructure }
    case ACT.SET_CURRENT_FOLDER:
      return { ...state, currentFolder }
    case ACT.ADD_LABELS:
      return updateFileBlob(state, arr, index, 'labels', selectedLabels)
    case ACT.ADD_CATEGORY:
      return updateFileBlob(state, arr, index, 'category', selectedCategory)
    case ACT.CHANGE_CATEGORY_FOR_SELECTED_FILE:
      return {
        ...state,
        file: {
          ...state.file,
          category: selectedCategory
        }
      }
    case ACT.CHANGE_LABELS_FOR_SELECTED_FILE:
      return {
        ...state,
        file: {
          ...state.file,
          labels: selectedLabels
        }
      }
    case ACT.SELECT_FILE:
      const selectedFile = state.attachments.find(f => f.id === id)
      return { ...state, file: selectedFile }
    case ACT.EDIT_CATEGORY:
      index = state.attachments.findIndex(f => f.id === id)
      return update(state, {
        attachments: {
          [index]: {
            category: { $set: selectedCategory }
          }
        }
      })
    case ACT.EDIT_LABELS:
      index = state.attachments.findIndex(f => f.id === id)
      return update(state, {
        attachments: {
          [index]: {
            labels: { $set: selectedLabels }
          }
        }
      })
    case ACT.SELECT_VERSION_FILE:
      return { ...state, versionFile }
    case ACT.CLEAR_VERSION_FILE:
      return { ...state, versionFile: {} }
    case ACT.REPLACE_MATTER_ATTACHMENT:
      index = state.attachments.findIndex(f => f.id === id)
      return update(state, {
        attachments: {
          [index]: { $set: file }
        }
      })
    case ACT.CANCEL_FILE_UPLOAD: {
      const newfilesToUpload = state.filesToUpload.filter(f => f.id !== id)
      return { ...state, filesToUpload: newfilesToUpload }
    }
    case ACT.ADD_EMAIL:
      return { ...state, email }
    case ACT.CLEAR_EMAIL:
      return { ...state, email: '' }
    case ACT.EDIT_SHARED_WITH_USERS:
      index = state.attachments.findIndex(f => f.id === id)
      return update(state, {
        attachments: {
          [index]: {
            shared_with_users: { $set: sharedWithUsers },
            is_shared: { $set: isShared }
          }
        }
      })
    default:
      throw new Error('Invalid Action Type')
  }
}
