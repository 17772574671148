import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { call, put, takeLatest } from 'redux-saga/effects'
import { makePostRequest, makePutRequest, getRequest } from 'utils/api'
import { toRequestFormJson, toAdminsSelect } from './serializers'

import ACT from './actions'

import { NotificationList } from 'components'

function* fetchRequestFromAttributes(action) {
  try {
    const { templates } = action.payload
    let url = '/manage/matters/requests/request_form/attributes/'
    url = templates.length > 0 ? `${url}?templates=${templates.join(',')}` : url

    const { attributes, fieldTypes, relationships } = yield call(getRequest, url)

    yield put({
      type: ACT.LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_SUCCESS,
      payload: { attributes, fieldTypes, relationships }
    })
    yield put({ type: ACT.REMOVE_LOADING_STATE })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Request Form attributes',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchRequestFrom(action) {
  const { navigate } = action.payload
  try {
    const { id } = action.payload
    const url = `/manage/matters/requests/request_form/${id}/`
    const form = yield call(getRequest, url)

    yield put({ type: ACT.REQUEST_FORM_FETCH_SUCCESS, payload: { form: form } })
    const templates = form.selectedTemplates.map(template => template.id)
    yield call(fetchRequestFromAttributes, {
      type: ACT.MATTER_ATTRIBUTES_FETCH_REQUESTED,
      payload: {
        templates
      }
    })
  } catch (error) {
    if (error.response.status === 404) {
      navigate && navigate('/v2/matters/templates/not_found')
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was an issue fetching Request Form',
          message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
          level: 'error'
        }
      })
    }
  }
}

function* createRequestForm(action) {
  const { form, navigate, redirect, location } = action.payload

  try {
    const url = '/manage/matters/requests/request_forms/'
    const requestFormJson = toRequestFormJson(form)
    const response = yield makePostRequest(url, requestFormJson)

    yield put({
      type: redirect
        ? ACT.REQUEST_FORM_CREATE_SUCCESS_AND_REDIRECT
        : ACT.REQUEST_FORM_CREATE_SUCCESS,
      payload: {
        requestFormJson,
        id: response.id
      }
    })

    if (redirect) {
      yield put({
        type: ACT.REQUEST_FORM_REMOVE_REDIRECT_FLAG
      })
      navigate('/v2/matters/requests/forms', { replace: true })
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'The request form was created successfully! You can continue editing',
          level: 'success'
        }
      })
      if (!location.pathname.includes(response.id)) {
        navigate(`/v2/matters/requests/form/${response.id}`, { replace: true })
      }
    }
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue creating the Request Form',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* updateRequestForm(action) {
  const { form, id, redirect, navigate, location } = action.payload

  try {
    const url = `/manage/matters/requests/request_form/${id}/`
    const requestFormJson = toRequestFormJson(form)
    const response = yield makePutRequest(url, requestFormJson)

    yield put({
      type: redirect
        ? ACT.REQUEST_FORM_CREATE_SUCCESS_AND_REDIRECT
        : ACT.REQUEST_FORM_CREATE_SUCCESS,
      payload: {
        requestFormJson,
        id: response.id
      }
    })

    if (redirect) {
      yield put({
        type: ACT.REQUEST_FORM_REMOVE_REDIRECT_FLAG
      })
      navigate('/v2/matters/requests/forms', { replace: true })
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'The request form was updated successfully!',
          level: 'success'
        }
      })
      if (!location.pathname.includes(response.id)) {
        navigate(`/v2/matters/requests/form/${response.id}`, { replace: true })
      }
    }
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue updating the Request Form',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* cloneRequestForm(action) {
  const { id, navigate, location } = action.payload
  try {
    const url = '/manage/matters/requests/request_form/clone/'
    const response = yield makePostRequest(url, { id })

    if (!location.pathname.includes(response.id)) {
      navigate(`/v2/matters/requests/form/${response.id}`, { replace: true })
    }
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'The form was cloned successfully! You can continue editing',
        level: 'success'
      }
    })
    yield put({
      type: ACT.REQUEST_FORM_RESTORE_TO_DRAFT,
      payload: {
        name: response.name
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue cloning request form',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchRequestFromUsers(action) {
  try {
    let url = '/company/user/admin_users_json/'
    const response = yield call(getRequest, url)

    yield put({
      type: ACT.LEGAL_REQUEST_FORM_USERS_FETCH_SUCCESS,
      payload: { users: toAdminsSelect(response.admin_users) }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Request Form attributes',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

const matterRequestFormSagas = [
  takeLatest(ACT.LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_REQUESTED, fetchRequestFromAttributes),
  takeLatest(ACT.REQUEST_FORM_FETCH_REQUESTED, fetchRequestFrom),
  takeLatest(ACT.LEGAL_REQUEST_FORM_CREATE_REQUESTED, createRequestForm),
  takeLatest(ACT.LEGAL_REQUEST_FORM_UPDATE_REQUESTED, updateRequestForm),
  takeLatest(ACT.REQUEST_FORM_CLONE_REQUESTED, cloneRequestForm),
  takeLatest(ACT.LEGAL_REQUEST_FORM_USERS_FETCH_REQUESTED, fetchRequestFromUsers)
]

export default matterRequestFormSagas
