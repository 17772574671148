import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AttributeDetails } from './AttributeDetails'
import { AttributeValues } from './AttributeValues'
import { makeGetRequest } from 'utils/api'
import { useLoading } from 'simple-core-ui'
import { useParams } from 'react-router-dom'

const CustomAttributeContainer = () => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [attribute, setAttribute] = useState(null)

  const { legal_entity_management, matter_group_management } = useSelector(
    state => state.app.credentials.custom.names
  )

  const fetchAttribute = useCallback(async () => {
    try {
      const result = await withLoadingLocks(makeGetRequest(`/manage/api/matter_attributes/${id}/`))
      setAttribute(result)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAttribute()
  }, [fetchAttribute])

  return (
    <>
      <AttributeDetails
        legalEntityManagement={legal_entity_management}
        attribute={attribute}
        fetchAttribute={fetchAttribute}
      />
      {attribute?.field_type === 'list' && (
        <AttributeValues
          attribute={attribute}
          legalEntityManagement={legal_entity_management}
          matterGroupManagement={matter_group_management}
          fetchAttribute={fetchAttribute}
        />
      )}
    </>
  )
}

export default CustomAttributeContainer
