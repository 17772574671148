import { Component } from 'react'
import { withRouter } from 'simple-core-ui/hocs'

@withRouter
class ScrollTopContainer extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    // scroll to top only when changing routes
    if (this.props.router.location.pathname !== nextProps.router.location.pathname) {
      // disable scroll when creating a new invoice draft
      if (!this.props.router.location.pathname.endsWith('/invoices/new' || '/invoices/new/')) {
        window.scrollTo(0, 0)
      }
    }
  }
  setWrapper = el => {
    this.wrapper = el
  }
  render() {
    const El = this.props.el || 'section'
    return (
      <El ref={this.setWrapper} className={this.props.className} style={this.props.style}>
        {this.props.children}
      </El>
    )
  }
}

export default ScrollTopContainer
