import ACT from './actions'
import { FORM_STATUSES } from 'matters/requestForms/constants'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  name: '',
  description: '',
  users: [],
  availableUsers: [],
  status: FORM_STATUSES.DRAFT,
  items: [
    {
      label: 'Request Name',
      model: 'MatterName',
      modelLabel: 'Matter Name',
      fieldType: 'text',
      info: '',
      filteredFieldTypes: [],
      required: true,
      fieldVisible: true,
      filterOptions: [],
      isValid: true
    },
    {
      label: '',
      model: 'None',
      modelLabel: '',
      fieldType: 'text',
      info: '',
      filteredFieldTypes: [],
      required: false,
      fieldVisible: true,
      filterOptions: [],
      isValid: true
    }
  ],
  attributes: [],
  fieldTypes: [],
  chosenAttributes: [],
  tmpSelectedTemplates: [],
  selectedTemplates: [],
  loading: true,
  relationships: []
}

const requestFormReducer = createReducer(initialState, {
  [ACT.LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { attributes, fieldTypes, relationships } = action.payload
    const items = cloneDeep(state.items).map(item => {
      if (item.fieldType === 'relationship') {
        item.isValid = true
      } else {
        item.isValid =
          item.model === 'MatterName' || attributes.some(attr => attr.value === item.model)
      }

      return item
    })

    const newState = {
      ...state,
      attributes,
      relationships,
      fieldTypes,
      items
    }

    return newState
  },

  [ACT.REQUEST_FORM_CHANGE_ATTRIBUTE_VALUE](state, action) {
    const { attrIndex, value } = action.payload
    let selectedAttribute = state.attributes.find(attr => attr.value === value)
    let type
    let filteredFieldTypes
    // look in relationships
    if (!selectedAttribute) {
      selectedAttribute = state.relationships.find(attr => attr.value === value)
      type = 'relationship'
      filteredFieldTypes = [{ value: 'select', label: 'List' }]
    } else {
      type = selectedAttribute.type || 'text'
      filteredFieldTypes = state.fieldTypes.filter(fieldType => fieldType.value.includes(type))
    }

    const modelLabel = selectedAttribute.label
    const oldAttributeValue = state.items[attrIndex]['model']
    const chosenAttributes = state.chosenAttributes.filter(attr => attr !== oldAttributeValue)
    chosenAttributes.push(value)

    let newState = update(state, {
      chosenAttributes: { $set: chosenAttributes }
    })

    if (type === 'relationship') {
      newState = update(newState, {
        items: {
          [attrIndex]: {
            label: { $set: 'relationship' },
            attributes: {
              $set: selectedAttribute.attributes.map(a => ({
                value: a.value,
                label: '',
                info: ''
              }))
            }
          }
        }
      })
    }

    return update(newState, {
      items: {
        [attrIndex]: {
          model: { $set: value },
          modelLabel: { $set: modelLabel },
          fieldType: { $set: type },
          filteredFieldTypes: { $set: filteredFieldTypes },
          isValid: { $set: true }
        }
      }
    })
  },

  [ACT.REQUEST_FORM_CHANGE_LABEL_VALUE](state, action) {
    const { attrIndex, value, relAttrIndex, relId } = action.payload

    if (relId !== undefined) {
      const { attributes } = state.relationships.find(r => +r.id === +relId)
      const existingAttr = state.items[attrIndex].attributes.find(
        a => a.value === attributes[relAttrIndex].value
      )

      const attr = {
        ...existingAttr,
        label: value
      }

      return update(state, {
        items: {
          [attrIndex]: {
            hasInvalidLabel: { $set: !value },
            attributes: {
              [relAttrIndex]: { $set: attr }
            }
          }
        }
      })
    }

    return update(state, {
      items: {
        [attrIndex]: {
          label: { $set: value },
          hasInvalidLabel: { $set: !value }
        }
      }
    })
  },

  [ACT.REQUEST_FORM_CHANGE_TYPE_VALUE](state, action) {
    const { attrIndex, value } = action.payload

    return update(state, {
      items: {
        [attrIndex]: {
          fieldType: { $set: value }
        }
      }
    })
  },

  [ACT.REQUEST_FORM_CHANGE_INFO_VALUE](state, action) {
    const { attrIndex, value, relAttrIndex, relId } = action.payload

    if (relId !== undefined) {
      const { attributes } = state.relationships.find(r => +r.id === +relId)
      const existingAttr = state.items[attrIndex].attributes.find(
        a => a.value === attributes[relAttrIndex].value
      )

      const attr = {
        ...existingAttr,
        info: value
      }

      return update(state, {
        items: {
          [attrIndex]: {
            attributes: {
              [relAttrIndex]: { $set: attr }
            }
          }
        }
      })
    }

    return update(state, {
      items: {
        [attrIndex]: {
          info: { $set: value }
        }
      }
    })
  },

  [ACT.LEGAL_REQUEST_FORM_ADD_NEW_ITEM](state, action) {
    return update(state, {
      items: {
        $push: [
          {
            label: '',
            model: 'None',
            fieldType: 'text',
            required: false,
            fieldVisible: true,
            filterOptions: [],
            isValid: true,
            filteredFieldTypes: []
          }
        ]
      }
    })
  },

  [ACT.LEGAL_REQUEST_CHANGE_FORM_NAME](state, action) {
    const { name } = action.payload
    return {
      ...state,
      name
    }
  },

  [ACT.LEGAL_REQUEST_CHANGE_FORM_DESCRIPTION](state, action) {
    const { description } = action.payload
    return {
      ...state,
      description
    }
  },

  [ACT.REQUEST_FORM_RESET_STATE](state, action) {
    return {
      ...initialState
    }
  },

  [ACT.NEW_REQUEST_FORM_REQUESTED](state, action) {
    return {
      ...initialState,
      selectedTemplates: state.selectedTemplates,
      loading: false
    }
  },

  [ACT.REQUEST_FORM_FETCH_SUCCESS](state, action) {
    const { form } = action.payload

    return {
      ...state,
      name: form.name,
      description: form.description,
      status: form.status,
      items: form.items.map(item => {
        if (item.attributes) {
          return {
            ...item,
            filteredFieldTypes: [{ value: 'select', label: 'List' }],
            attributes: item.attributes.map(attr => ({
              ...attr,
              value: attr.model
            }))
          }
        }
        return item
      }),
      selectedTemplates: form.selectedTemplates,
      loading: true,
      users: form.notify_users
    }
  },

  [ACT.REQUEST_FORM_CREATE_SUCCESS](state, action) {
    const { id } = action.payload

    return {
      ...state,
      id
    }
  },

  [ACT.REQUEST_FORM_CREATE_SUCCESS_AND_REDIRECT](state, action) {
    return {
      ...state,
      shouldRedirect: true
    }
  },

  [ACT.REQUEST_FORM_REMOVE_REDIRECT_FLAG](state, action) {
    return update(state, { $unset: ['shouldRedirect'] })
  },

  [ACT.REQUEST_FORM_CHANGE_FORM_FIELD_VALUE](state, action) {
    const { attrIndex, value, property } = action.payload

    return update(state, {
      items: {
        [attrIndex]: {
          [property]: { $set: value }
        }
      }
    })
  },

  [ACT.REQUEST_FORM_REMOVE_ITEM](state, action) {
    const { attrIndex } = action.payload
    const itemValue = state.items[attrIndex].model
    const chosenAttributes = state.chosenAttributes.filter(attr => attr !== itemValue)

    const newState = {
      ...state,
      chosenAttributes
    }

    return update(newState, {
      items: { $splice: [[attrIndex, 1]] }
    })
  },

  [ACT.REQUEST_FORM_CHANGE_ATTRIBUTE_FILTER_OPTIONS](state, action) {
    const { attrIndex, value } = action.payload
    const filterOptions = value.map(opt => opt.value.toString())

    return update(state, {
      items: {
        [attrIndex]: {
          filterOptions: { $set: filterOptions }
        }
      }
    })
  },

  [ACT.LEGAL_REQUEST_FORM_UPDATE_STATUS](state, action) {
    const { status } = action.payload

    return {
      ...state,
      status
    }
  },

  [ACT.MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED](state, action) {
    const { id, name, description } = action.payload
    const index = state.tmpSelectedTemplates.findIndex(value => value.id === id)

    if (~index) {
      return update(state, {
        tmpSelectedTemplates: { $splice: [[index, 1]] }
      })
    } else {
      return update(state, {
        tmpSelectedTemplates: {
          $push: [
            {
              id: id,
              name: name,
              description: description
            }
          ]
        }
      })
    }
  },

  [ACT.REQUEST_FORM_SET_TEMPLATES_LIST](state, action) {
    const tmpSelectedTemplates = state.tmpSelectedTemplates
    return update(state, {
      selectedTemplates: { $set: tmpSelectedTemplates },
      tmpSelectedTemplates: { $set: [] }
    })
  },

  [ACT.FORM_SET_TMP_TEMPLATES](state, action) {
    const selectedTemplates = state.selectedTemplates
    return update(state, {
      tmpSelectedTemplates: { $set: selectedTemplates }
    })
  },

  [ACT.REQUEST_FORM_REMOVE_TEMPLATE](state, action) {
    const { attrIndex } = action.payload

    return update(state, {
      selectedTemplates: { $splice: [[attrIndex, 1]] }
    })
  },

  [ACT.REQUEST_FORM_RESTORE_TO_DRAFT](state, action) {
    const { name } = action.payload
    return {
      ...state,
      status: FORM_STATUSES.DRAFT,
      name
    }
  },

  [ACT.SET_LOADING_STATE](state, action) {
    return {
      ...state,
      loading: true
    }
  },

  [ACT.REMOVE_LOADING_STATE](state, action) {
    return {
      ...state,
      loading: false
    }
  },

  [ACT.LEGAL_REQUEST_FORM_SET_INVALID_ITEMS](state, action) {
    const items = cloneDeep(state.items).map(item => ({ ...item, hasInvalidLabel: !item.label }))
    return update(state, {
      items: { $set: items }
    })
  },

  [ACT.LEGAL_REQUEST_FORM_USERS_FETCH_SUCCESS](state, action) {
    const { users } = action.payload
    return {
      ...state,
      availableUsers: users
    }
  },

  [ACT.FORM_SET_NOTIFY_USERS](state, action) {
    const { users } = action.payload
    return {
      ...state,
      users
    }
  },

  [ACT.REQUEST_FORM_REORDER_ATTRIBUTE_ITEMS](state, action) {
    const { fromIndex, toIndex, item } = action.payload

    const removedState = update(state, {
      items: { $splice: [[fromIndex, 1]] }
    })

    const newState = update(removedState, {
      items: { $splice: [[toIndex, 0, item]] }
    })

    return newState
  }
})

export default requestFormReducer
