import { Params } from '@common_types/common'
import { Column, SerializedResultItem } from 'files/types'
import { getNormalizedCellContent } from 'files/utils'
import { useState } from 'react'
import { DataTableWrapper } from 'simple-core-ui'
import s from './FilesList.scss'

const initialParams: Params = {
  pageSize: 10,
  ordering: { columnKey: 'updated', isDesc: true },
  search: '',
  page: 1,
  category: 'all'
}

interface Props {
  files: SerializedResultItem[]
  columns: Column[]
  rowActions: (arg: SerializedResultItem) => JSX.Element
  globalActions: Array<JSX.Element>
  isLoading: boolean
  statusText?: string
}

const FilesList = ({ files, columns, globalActions, rowActions, isLoading, statusText }: Props) => {
  const [params, setParams] = useState(initialParams)

  const updateTable = (updatedParams: Params) => {
    setParams(updatedParams)
  }

  return (
    <div id="react-files-list">
      <div className={s.globalActions}>
        {globalActions.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <DataTableWrapper
        params={params}
        getNormalizedCellContent={getNormalizedCellContent}
        customSearchOptions={{
          placeholder: 'Start typing to search',
          styles: { width: 270 }
        }}
        isLoading={isLoading}
        tableHeight="500px"
        categories={[]}
        //@ts-expect-error
        rows={files}
        columns={columns}
        totalEntries={files.length}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        alwaysShowActions
        customAction={rowActions}
        hasActions
        hasTooltip
        customActionsMemoizationDeps={[files]}
        statusText={statusText}
      />
    </div>
  )
}

export default FilesList
