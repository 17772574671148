export const toReactSelect = options => {
  return options.map(o => ({
    value: o.id,
    label: o.text
  }))
}

export const toPurchaseOrderSelect = options => {
  return options.map(o => ({
    value: o.id,
    label: `${o.name}: ${o.description} (${o.amount})`
  }))
}

export const toUsers = options => {
  return options.map(o => ({
    value: o.user_id || o.id,
    label: o.full_name,
    email: o.primary_email
  }))
}

export const toCategories = categories =>
  categories.map(category => ({
    value: category.id,
    label: category.name
  }))

export const toLabels = labels =>
  labels.map(label => ({
    value: label.id,
    label: label.name,
    color: label.color
  }))

export const toBudgetIdsOptions = options => options.map(({ value, label }) => ({ value, label }))
