export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

export const defaultMatterOptions = [
  {
    label: 'All matters by ID',
    value: 'id',
    model_field: 'id',
    model_name: 'Matter',
    arg_type: 'field'
  }
]

export const defaultVendorOptions = [
  {
    label: 'All Vendors',
    value: 'id',
    model_field: 'id',
    model_name: 'Vendor',
    arg_type: 'field'
  }
]
