import s from './Thermometer.scss'

const ColorBar = ({ background, width, left = '0', opacity = '1' }) => {
  return (
    <div
      data-testid="color-bar"
      className={s.colorBar}
      style={{
        background,
        opacity,
        left,
        width
      }}
    />
  )
}

export default ColorBar
