export const comments2Messages = comments => {
  return comments.map(c => {
    return {
      user: {
        full_name: c.author,
        id: c.author_id
      },
      timestamp: new Date(c.created_date).getTime(),
      id: c.id,
      text: c.text
    }
  })
}
