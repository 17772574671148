import * as React from 'react'

import { Grid } from 'simple-core-ui'

import ClientKeysListContainer from '../ClientKeysList/ClientKeysListContainer'
import ClientKeyModalContainer from '../ClientKeyModal/ClientKeyModalContainer'

const { Row, Column } = Grid

const ClientKeysManagement = () => (
  <React.Fragment>
    <ClientKeyModalContainer />
    <Row>
      <Column span={8} breakpoint="monitor">
        <ClientKeysListContainer />
      </Column>
    </Row>
  </React.Fragment>
)

export default ClientKeysManagement
