import { reorderFields } from 'bulk/serializer'
import { Field } from 'bulk/types'
import { CondensedCell } from './TableFormats/CondensedCell'
import { Link } from 'react-router-dom'
import s from 'bulk/builder/BulkActions.scss'
import { checkSpaces } from 'bulk/utils'
import { HelpText } from 'simple-core-ui'

export const serializeColumns = (columns: Field[], recType: string) => {
  const reorderedColumns = reorderFields(columns, recType)

  return reorderedColumns.map((col, index) => ({
    columnKey: col.column_name,
    content: col.display_name,
    isSortable: col.orderable,
    //TODO: Request config from BE to generalize for separated-string type cell content
    ...(['matter_vendors', 'matter_subscribers'].includes(col.column_name)
      ? {
          render: (
            cell: { content: string | number | null; columnKey: string },
            row: Record<string, unknown>
          ) => {
            return cell.content && typeof cell.content === 'string' ? (
              <CondensedCell
                cellContent={cell.content}
                columnKey={cell.columnKey}
                threshold={3}
                rowData={row}
                listSeparator=";"
              />
            ) : (
              <span>&#8212;</span>
            )
          }
        }
      : col.column_name.startsWith('approval_level')
      ? {
          render: (
            cell: { content: string | number | null; columnKey: string },
            row: Record<string, unknown>
          ) => {
            return typeof cell.content === 'string' ? (
              <CondensedCell
                cellContent={cell.content}
                columnKey={cell.columnKey}
                threshold={3}
                rowData={row}
                listSeparator=";"
              />
            ) : null
          }
        }
      : col.column_name === 'matter_short_name'
      ? {
          render: (cell: { content: string | null }, row: Record<string, unknown>) => {
            const config = window.location.href.split('#')[1]
            const cellHasContent = cell.content && typeof cell.content === 'string'

            return (
              <Link
                to={`/v2/matters/${row.matter_id}?bulkConfig=${config}`}
                className={s.linkedCell}
              >
                {cellHasContent ? (
                  checkSpaces(cell.content) ? (
                    <pre className={s.formattedBlankCell}>{cell.content}</pre>
                  ) : (
                    cell.content
                  )
                ) : (
                  <span>&#8212;</span>
                )}
              </Link>
            )
          }
        }
      : {
          render: (cell: { content: string }) => {
            return cell.content === 'n/a' ? (
              <HelpText
                position="top"
                message={
                  <p className={s.tooltipText}>
                    <span>{'Not applicable means this attribute'}</span>
                    <span>{"is not on the matter's template, and"}</span>
                    <span>{"can't be assigned a value"}</span>
                  </p>
                }
                hasUnderline={false}
                messageStyles={{ fontSize: '12px' }}
              >
                <div>{cell.content}</div>
              </HelpText>
            ) : (
              <div>{cell.content}</div>
            )
          }
        })
  }))
}
