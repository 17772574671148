export const resultsSerializer = (results = []) => {
  const columns = [
    { columnKey: 'fileName', content: 'File Name', isSortable: true, isFilterable: true },
    { columnKey: 'version', content: 'Version', isSortable: true, isFilterable: true },
    { columnKey: 'matterName', content: 'Matter', isSortable: true, isFilterable: true },
    { columnKey: 'vendorName', content: 'Vendor', isSortable: true, isFilterable: true },
    { columnKey: 'invoiceName', content: 'Invoice', isSortable: true, isFilterable: true },
    {
      columnKey: 'matterRequestName',
      content: 'Legal Request',
      isSortable: true,
      isFilterable: true
    },
    { columnKey: 'uploadedBy', content: 'Modified By', isSortable: true, isFilterable: true },
    { columnKey: 'fileUploadDate', content: 'Modified Date', isSortable: true, isFilterable: true }
  ]

  const rows = results.map(result => ({
    id: result.id,
    cells: [
      { columnKey: 'fileName', content: result.fileName },
      { columnKey: 'version', content: 'V' + result.version },
      {
        columnKey: 'matterName',
        content: result.objectType === 'Matters' ? result.objectName : '---'
      },
      {
        columnKey: 'vendorName',
        content: result.objectType === 'Vendor' ? result.objectName : '---'
      },
      {
        columnKey: 'invoiceName',
        content: result.objectType === 'Invoice' ? result.objectName : '---'
      },
      {
        columnKey: 'matterRequestName',
        content: result.objectType === 'MatterRequest' ? result.objectName : '---'
      },
      { columnKey: 'uploadedBy', content: result.uploadedBy },
      { columnKey: 'fileUploadDate', content: result.fileUploadDate }
    ]
  }))

  return {
    columns,
    rows
  }
}
