import { ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: () => void
  onCancel: () => void
  userName: string
  view: string
}

const DeleteConfirmation = ({ onConfirm, onCancel, userName, view }: Props) => {
  const isCourtRules = view === 'courtRules'
  const isFileSharing = view === 'fileSharing'

  return (
    <ModalContainer
      title="Delete User"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Confirm"
      confirmCb={onConfirm}
      content={
        <>
          {`Are you sure you want to remove ${userName} from receiving notifications ${
            isCourtRules
              ? 'when Events with Court Rules are updated'
              : isFileSharing
              ? 'for shared files'
              : 'for updates from vendors'
          }?`}
        </>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default DeleteConfirmation
