import APP_ACT from 'app/actions'
import {
  DatePickerSelect,
  EventReminderSelect,
  TimePickerSelect,
  UsersCheckboxSelect
} from 'common/Selects'
import { useEffect, useState } from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import pluralize from 'pluralize'
import cn from 'classnames'
import { ModalContainer, useLoading } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { Location } from '../Location'
import { BULK_EDIT_INITIAL_VALUES, EVENT_OPTIONS_ENDPOINTS } from '../constants'
import { onlyActiveOptions, serializeBulkEditOptions, toEventTypesOptions } from '../serializers'
import { BulkEditValues, Location as LocationType, Option, Time } from '../types'
import s from './BulkEditModal.scss'

interface Props {
  eventsNumber: number
  matterId: number
  onUpdate: (values: BulkEditValues) => void
  onCancel: () => void
  context?: 'matter' | 'workbench'
}

const SELECT_PLACEHOLDER = 'Select...'

const BulkEditModal = ({ eventsNumber, matterId, onUpdate, onCancel, context }: Props) => {
  const dispatch = useDispatch()
  const [, withLoadingLocks, resetLoadingLocks] = useLoading()
  const [eventTypes, setEventTypes] = useState<Option[]>([])
  const [valuesToUpdate, setValuesToUpdate] = useState(BULK_EDIT_INITIAL_VALUES)

  const validateForm = (): boolean => {
    const updatePayload = serializeBulkEditOptions(valuesToUpdate, [])
    return Object.keys(updatePayload).length > 1 //there are some value(s) to update other than eventIds
  }

  useEffect(() => {
    let isMounted = true
    const fetchOptions = async () => {
      try {
        const [eventTypes] = await withLoadingLocks(
          Promise.allSettled([makeGetRequest(EVENT_OPTIONS_ENDPOINTS.EVENT_TYPES)])
        )

        if (isMounted) {
          eventTypes.status === 'fulfilled'
            ? setEventTypes(onlyActiveOptions(toEventTypesOptions(eventTypes.value.rows)))
            : dispatch({ type: APP_ACT.API_ERROR, error: eventTypes.reason })
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: APP_ACT.API_ERROR, error })
        }
      }
    }

    fetchOptions()

    return () => {
      isMounted = false
      resetLoadingLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const attendeesRequestParams = { active: true, matterId, canEdit: true }

  return (
    <ModalContainer
      title={`Edit ${pluralize('event', eventsNumber, true)}`}
      size="sm"
      hasNewButtons
      cancelText="Cancel"
      confirmText="Update"
      isDisabled={!validateForm()}
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
      content={
        <>
          <div className={s.infoLabel}>{`Update the ${eventsNumber} selected ${pluralize(
            'event',
            eventsNumber
          )} attributes to:`}</div>
          {context === 'matter' && (
            <>
              <div className={s.label}>Add Attendees:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.attendeesToAdd}
                requestParams={attendeesRequestParams}
                updateSelectedUsers={attendeesToAdd => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, attendeesToAdd }))
                }}
              />

              <div className={s.label}>Remove Attendees:</div>
              <UsersCheckboxSelect
                value={valuesToUpdate.attendeesToRemove}
                requestParams={{ ...attendeesRequestParams, active: false }}
                updateSelectedUsers={attendeesToRemove => {
                  setValuesToUpdate(currentValue => ({ ...currentValue, attendeesToRemove }))
                }}
              />
            </>
          )}

          <div className={cn(s.label, s.addTopMargin)}>Date:</div>
          <DatePickerSelect
            value={valuesToUpdate.date}
            selectDate={(date: Date | undefined) => {
              setValuesToUpdate(currentValue => ({ ...currentValue, date }))
            }}
          />

          <div className={cn(s.label, s.addTopMargin)}>Time:</div>
          <TimePickerSelect
            value={{
              startTime: valuesToUpdate.time.startTime,
              endTime: valuesToUpdate.time.endTime
            }}
            onConfirm={(time: Time) => {
              setValuesToUpdate(currentValue => ({ ...currentValue, time }))
            }}
          />

          <div className={cn(s.label, s.addTopMargin)}>Location:</div>
          <Location
            value={valuesToUpdate.location}
            classNames={{
              wrapper: s.locationWrapper,
              locationLabel: s.locationLabel,
              locationDraft: s.location
            }}
            onSave={(location: LocationType | null) => {
              setValuesToUpdate(currentValue => ({ ...currentValue, location }))
            }}
          />

          <div className={cn(s.label, s.addTopMargin)}>Reminder:</div>
          <EventReminderSelect
            reminder={valuesToUpdate.reminder}
            onConfirm={reminder => {
              setValuesToUpdate(currentValue => ({ ...currentValue, reminder }))
            }}
          />

          <div className={cn(s.label, s.addTopMargin)}>Type:</div>
          <Select
            value={valuesToUpdate.eventType}
            options={eventTypes}
            placeholder={SELECT_PLACEHOLDER}
            className={s.select}
            isClearable
            onChange={eventType => {
              setValuesToUpdate(currentValue => ({ ...currentValue, eventType }))
            }}
          />

          {context === 'matter' && (
            <div className={s.warningNote}>
              <IoWarningOutline className={s.warningTriangle} />
              <div className={s.warningLabel}>
                Bulk adding attendees or removing attendees will not trigger email notifications
              </div>
            </div>
          )}
        </>
      }
      confirmCb={() => onUpdate(valuesToUpdate)}
      cancelCb={onCancel}
    />
  )
}

export default BulkEditModal
