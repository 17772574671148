import moment from 'moment'

import {
  CheckboxList,
  DateRangePopper,
  DropdownPopper,
  DueDatePicker,
  TextInput,
  useAutosizeField
} from 'simple-core-ui'
import { Operand } from 'simple_review/@types/editor'
import { Constant } from 'simple_review/@types/common'
import { Numeric } from './numeric'
import { Slider } from './slider'
import { Select } from './select'
import { CustomAttr } from './custom_attr'
import { Currency } from './currency'
import s from './Rhs.scss'
import { CustomAttrRhs } from 'simple_review/@types/api'
import { RhsValue } from './custom_attr/CustomAttr'

const NUM_OF_UNITS_MAX_DECIMAL_PLACES = 6

// eslint-disable-next-line prettier/prettier
type DateFormat = `${number | ''}${number}/${number}${number}/${number}${number}${number}${number}`

interface Props {
  lhs: NonNullable<Operand['lhs']>
  op: NonNullable<Operand['op']>
  value: Operand['rhs']
  isReadOnly: boolean
  onChangeRhs(newRhsValue: Operand['rhs']): void
}

type NewValue = Constant & Partial<CustomAttrRhs>

const helpTextMap: Record<string, string> = {
  Age: 'days before the received date'
}

const RhsHelpText = ({ lhsName }: { lhsName: string }) => {
  return <>{helpTextMap[lhsName]}</>
}

export const Rhs = ({ lhs, op, value, isReadOnly, onChangeRhs }: Props) => {
  if (op.unary || !lhs.type) return null

  const onChangeValue = (newValue: NewValue | Array<Constant>, newSubType?: string) => {
    const constant: Constant | Array<Constant> = Array.isArray(newValue)
      ? newValue.filter(el => !!el).map(el => ({ value: el.value, label: el.label }))
      : { value: newValue.value, label: newValue.label }
    const newRhs: Operand['rhs'] = {
      type: lhs.type as string,
      sub_type: newSubType ?? lhs.sub_type ?? '',
      constant
    }
    onChangeRhs(newRhs)
  }

  const onChangeCustomAttrValue = (newRhs: CustomAttrRhs) => {
    onChangeRhs(newRhs)
  }

  const constant = value && 'constant' in value ? value.constant : null

  if (lhs.sub_type === 'VendorGroup' && op.rhs_is_array) {
    return (
      <DropdownPopper
        value={(constant as Constant[]) || []}
        placeholder="Enter Vendor Group"
        label="Vendor Group"
        pillClass={s.drodpdownPoppperPill}
        noActiveStyles
      >
        <CheckboxList
          url={lhs.choices as string}
          value={(constant as Constant[]) || []}
          onConfirm={newValue => {
            onChangeValue(newValue as Constant[])
          }}
          firstPageIndex={1}
          serialiser={data => data}
          classes={{
            scrollableDiv: s.scrollableDiv
          }}
        />
      </DropdownPopper>
    )
  }

  if (
    ['boolean', 'list', 'reference'].includes(lhs.type) ||
    (op.rhs_is_array && lhs.type !== 'date') ||
    typeof lhs.choices === 'string'
  ) {
    let placeholder = ''
    if (typeof lhs.choices === 'string') placeholder = `Search ${lhs.sub_type}`
    if (lhs.sub_type === 'VendorGroup') placeholder = 'Enter Vendor Group'
    return (
      <Select
        value={constant}
        onChange={onChangeValue}
        choices={lhs.choices || []}
        isMulti={op.rhs_is_array}
        isDisabled={isReadOnly}
        placeholder={placeholder}
      />
    )
  }

  // custom attributes
  if (lhs.isCustomAttrEnabled) {
    const rhsValue = value as RhsValue
    return (
      <>
        <CustomAttr
          value={rhsValue}
          onChangeValue={onChangeValue}
          onChangeCaValue={onChangeCustomAttrValue}
          isPercentage={lhs.rhsType === 'slider'}
          isCurrency={lhs.type === 'currency'}
          isReadOnly={isReadOnly}
        />
        {lhs.rhsType === 'slider' && value && 'constant' in value && (
          <Slider
            hideInput
            value={constant as Constant}
            onChange={onChangeValue}
            isReadOnly={isReadOnly}
          />
        )}
      </>
    )
  }

  if (lhs.rhsType === 'slider') {
    return <Slider value={constant as Constant} onChange={onChangeValue} isReadOnly={isReadOnly} />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { width } = useAutosizeField({
    autoWidth: true,
    value: (constant as Constant)?.value,
    placeholder: 'Type in value',
    paddingLeft: 12,
    paddingRight: 12
  })

  switch (lhs.type) {
    case 'number':
      let maxDecimalPlaces = 2
      if (lhs.name === 'line_item_number_of_units')
        maxDecimalPlaces = NUM_OF_UNITS_MAX_DECIMAL_PLACES
      else if (lhs.name === 'Age') maxDecimalPlaces = 0
      return (
        <>
          <Numeric
            value={constant as Constant}
            onChange={onChangeValue}
            maxDecimalPlaces={maxDecimalPlaces}
            isReadOnly={isReadOnly}
          />
          <RhsHelpText lhsName={lhs.name} />
        </>
      )
    case 'string':
      return (
        <TextInput
          value={(constant as Constant)?.value ?? ''}
          placeholder="Type in value"
          onChange={newValue => onChangeValue({ value: newValue, label: newValue })}
          containerClassName={s.textInputContainer}
          className={s.textInput}
          style={{ width }}
          isDisabled={isReadOnly}
        />
      )
    case 'currency':
      const code = value && 'sub_type' in value ? value?.sub_type : null
      return (
        <Currency
          code={code}
          amount={constant as Constant}
          onChange={onChangeValue}
          isPortal={false}
          isReadOnly={isReadOnly}
        />
      )
    case 'date':
      const popperValue = (constant || []) as Constant<DateFormat>[]
      return (
        <DateRangePopper
          value={popperValue}
          classNameMap={{ pill: s.dateRangePopperPill }}
          isDisabled={isReadOnly}
        >
          <DueDatePicker
            value={popperValue[0]?.value}
            onConfirm={value => {
              const stringified = moment(value).format('L')
              onChangeValue([{ value: stringified, label: stringified }, popperValue[1]])
            }}
          />
          {op.rhs_is_array ? (
            <DueDatePicker
              value={popperValue[1]?.value}
              onConfirm={value => {
                const stringified = moment(value).format('L')
                onChangeValue([popperValue[0], { value: stringified, label: stringified }])
              }}
            />
          ) : null}
        </DateRangePopper>
      )
  }

  return null
}
