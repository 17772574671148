import { useState } from 'react'
import s from './AllocationsModal.scss'
import { Button, Alert, TextInput, PaginatedSelect } from 'simple-core-ui'
import cn from 'classnames'
import { FaTrash, FaPlus } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

const SELECT_LIST_SIZE = 500

const AllocationsModal = ({
  allocations,
  setAllocations,
  percentageAlert,
  onChangeLegalEntity,
  isPortal,
  limitAllocations
}) => {
  const [legalEntityAlert, setLegalEntityAlert] = useState(false)
  return (
    <div className={s.container}>
      {percentageAlert && (
        <Alert
          style={{ width: 'calc(100% - 36px)' }}
          message="Allocations need to add up to 100%"
          status="error"
        />
      )}
      {legalEntityAlert && (
        <Alert
          style={{ width: 'calc(100% - 36px)' }}
          message="Changing the Entity resets all attributes. Click cancel to undo the changes."
          status="warning"
        />
      )}
      <div className={s.rows}>
        <div className={s.row}>
          <div className={cn(s.col, s.inputWrapper)}>Percentage</div>
          {allocations[0].attributes.map((attribute, index) => (
            <div key={attribute.id || index} className={s.col}>
              {attribute.name}
            </div>
          ))}
        </div>
        {allocations.map((allocation, index) => (
          <div aria-label="row" key={allocation.id} className={s.rowWrapper}>
            {allocations.length > 1 && (
              <FaTrash
                aria-label={`remove-${index}`}
                className={s.remove}
                onClick={() =>
                  setAllocations(draft => {
                    draft.splice(index, 1)
                  })
                }
              />
            )}
            <div className={s.row}>
              <div className={cn(s.col, s.inputWrapper)}>
                <TextInput
                  ariaLabel="percentage"
                  value={allocation.percentage}
                  type="number"
                  step="0.01"
                  className={s.input}
                  onChange={value =>
                    setAllocations(draft => {
                      draft[index].percentage = value
                    })
                  }
                />
              </div>
              {allocation.attributes.map((attribute, i) => (
                <div key={attribute.id || i} className={s.col}>
                  <PaginatedSelect
                    ariaLabel={attribute.type}
                    placeholder="Select..."
                    noOptionsMessage={() => 'No results...'}
                    value={attribute.value}
                    onChange={value => {
                      setAllocations(draft => {
                        draft[index].attributes[i].value = value
                      })
                      if (attribute.type === 'legal_entities') {
                        setLegalEntityAlert(true)
                        onChangeLegalEntity(value?.value, index)
                      }
                    }}
                    options={attribute.options}
                    isClearable
                    className={s.select}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={isPortal ? document.body : null}
                    isAsync={false}
                    size={SELECT_LIST_SIZE}
                    isPortal={isPortal}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {!limitAllocations ? (
        <Button
          className={s.addBtn}
          ariaLabel="addAllocationsBtn"
          onClick={() =>
            setAllocations(draft => {
              draft.push({ ...draft[draft.length - 1], id: uuidv4() })
            })
          }
        >
          <FaPlus /> Allocations
        </Button>
      ) : null}
    </div>
  )
}

export default AllocationsModal
