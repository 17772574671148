import moment from 'moment'
import { safeFormatDate } from 'utils/dateUtils'
import { fromContact } from 'contacts/serializer'

export const fromTodo = ({
  title,
  description,
  is_private,
  due_date,
  id,
  scope,
  assigned_to_contact,
  completed_date,
  read_only
}) => ({
  title,
  description,
  isPrivate: is_private,
  dueDate: safeFormatDate(due_date, 'MM/DD/YYYY'),
  id,
  completedDate: safeFormatDate(completed_date, 'MM/DD/YYYY'),
  isOverdue: moment().diff(due_date) > 0,
  scope,
  assignedToContact: assigned_to_contact ? fromContact(assigned_to_contact) : assigned_to_contact,
  readOnly: read_only
})

export const toTodo = ({ title, dueDate, description, assignedToContact }) => ({
  title,
  due_date: safeFormatDate(dueDate, 'YYYY-MM-DD'),
  description: description || '',
  assigned_to_contact: assignedToContact ? assignedToContact.id : window.credentials.user.contactId
})

export const toTodos = todos => todos.map(toTodo)

export const fromTodos = apiTodos => apiTodos.map(fromTodo)
