import * as React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'

import ACT from '../actions'

import withErrorBoundary from 'simple-core-ui/hocs/withErrorBoundary'

import Hydrating from '../components/Hydrating'
import ReviewLadder from '../components/ReviewLadder'
import { extractRejectedResponse } from '../utils/extractEntry'
import { SCOPE } from '../utils/constants'
import { withRouter } from 'simple-core-ui/hocs'
import ScopeContext from 'context/ScopeContext'

const getScopeData = (location, params, props) => {
  const scope =
    props.scope || (location.pathname.includes('matters') ? SCOPE.MATTER : SCOPE.INVOICE)
  let scopeId = props.scopeId || params.matterId || params.invoiceId || params.id
  if (scope === SCOPE.INVOICE) scopeId = window.serverContext.get('object_id')
  return { scope, scopeId }
}

@withErrorBoundary
@withFetchFlow({
  render: ({ withPanel }) => <Hydrating isVisible isInitial withPanel={withPanel} />,
  flag: 'ReviewLadder',
  getFetchAction: props => {
    const { reviewId, router } = props
    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params, props)

    return {
      type: ACT.HYDRATE_REQUESTED,
      loadingLock: 'on',
      payload: { scope, scopeId, reviewId }
    }
  }
})
@connect(({ reviews: { reviewLadder } }) => ({ ...reviewLadder }))
@withRouter
class ReviewLadderContainer extends React.Component {
  state = {
    isResponseExpanded: false,
    expandedGroupId: null,
    reasonsFor: null,
    promptedFor: null,
    promptAction: null,
    modalAction: null
  }

  static defaultProps = {
    showModalWhen: (modalAction, review) =>
      new Promise(resolve => resolve(modalAction === ACT.REJECTION_REQUESTED)),
    modalMessageFetcher: () =>
      new Promise(resolve => resolve({ subject: '', body: '', emailTo: [] }))
  }

  toggleEntryGroup = newExpandedGroupId => {
    this.setState(prevState => ({
      expandedGroupId: prevState.expandedGroupId === newExpandedGroupId ? null : newExpandedGroupId
    }))
  }

  toggleResponse = () =>
    this.setState(prevState => ({ isResponseExpanded: !prevState.isResponseExpanded }))

  onAddReviewers = teamIds => {
    const { dispatch, router } = this.props
    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params, this.props)

    dispatch({
      type: ACT.ADD_REVIEWERS_REQUESTED,
      payload: { teamIds, scope, scopeId }
    })
  }

  dispatchReviewAction = (actionType, reviewFormFields) => {
    const { dispatch, reviewId, router, reviewActionEffects } = this.props
    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params, this.props)

    const payload = {
      reviewId,
      scope,
      scopeId,
      reviewFormFields,
      reviewActionEffects
    }

    dispatch({ type: actionType, payload })

    this.onCloseModal()
  }

  getRejectedResponse = () => {
    const {
      review: {
        ladder: { tiers }
      }
    } = this.props

    return extractRejectedResponse(tiers)
  }

  onFinalize = () => this.dispatchReviewAction(ACT.FINALIZE_REQUESTED)

  onFinalizeAndNotify = reviewFormFields =>
    this.dispatchReviewAction(ACT.FINALIZE_REQUESTED, reviewFormFields)

  onApprove = () => this.dispatchReviewAction(ACT.APPROVAL_REQUESTED)

  onApproveAndNotify = reviewFormFields =>
    this.dispatchReviewAction(ACT.APPROVAL_REQUESTED, reviewFormFields)

  onReject = () => this.dispatchReviewAction(ACT.REJECTION_REQUESTED)

  onRejectAndNotify = reviewFormFields =>
    this.dispatchReviewAction(ACT.REJECTION_REQUESTED, reviewFormFields)

  onUnapprove = () => this.dispatchReviewAction(ACT.UNAPPROVAL_REQUESTED)

  onUnreject = () => {
    const rejectingUserId = Number(get(this.getRejectedResponse(), 'soloUserId', -1))
    const viewingUserId = Number(window.credentials.user.id)

    rejectingUserId === viewingUserId
      ? this.dispatchReviewAction(ACT.UNREJECTION_REQUESTED)
      : this.sendBackToRejectingUser()
  }

  sendBackToRejectingUser = () => {
    const { dispatch, router } = this.props
    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params, this.props)

    dispatch({
      type: ACT.SEND_BACK_TO_REQUESTED,
      payload: {
        scope,
        scopeId,
        soloUserId: get(this.getRejectedResponse(), 'soloUserId')
      }
    })
  }

  onSendBackTo = entry => {
    this.setState({
      promptedFor: entry,
      promptAction: ACT.SEND_BACK_TO_REQUESTED
    })
  }

  onRemove = entry => {
    this.setState({
      promptedFor: entry,
      promptAction: ACT.REMOVE_REVIEWER_REQUESTED
    })
  }

  onConfirmPrompt = () => {
    const { dispatch, router } = this.props
    const { promptAction, promptedFor } = this.state

    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params, this.props)

    const additionalPayload = get(
      {
        [ACT.REMOVE_REVIEWER_REQUESTED]: {
          teamId: get(promptedFor, 'teamId')
        },
        [ACT.SEND_BACK_TO_REQUESTED]: {
          soloUserId: get(promptedFor, 'responses[0].soloUserId')
        }
      },
      promptAction
    )

    dispatch({
      type: promptAction,
      payload: { scope, scopeId, ...additionalPayload }
    })

    this.onCancelPrompt()
  }

  onCancelPrompt = () => {
    this.setState({ promptedFor: null, promptAction: null })
  }

  onShowReasons = entry => {
    this.setState({ reasonsFor: entry })
  }

  onCloseReasons = () => {
    this.setState({ reasonsFor: null })
  }

  onOpenModalForFinalize = modalAction => {
    this.setState({ modalAction: ACT.FINALIZE_REQUESTED })
  }

  onOpenModalForApproval = modalAction => {
    this.setState({ modalAction: ACT.APPROVAL_REQUESTED })
  }

  onOpenModalForRejection = modalAction => {
    this.setState({ modalAction: ACT.REJECTION_REQUESTED })
  }

  onCloseModal = () => {
    this.setState({ modalAction: null })
  }

  modalHandlers = () => {
    return {
      [ACT.FINALIZE_REQUESTED]: {
        onConfirm: SCOPE.INVOICE ? this.onFinalizeAndNotify : this.onFinalize,
        onConfirmWithNotify: this.onFinalizeAndNotify,
        onCancel: this.onCloseModal
      },
      [ACT.APPROVAL_REQUESTED]: {
        onConfirm: SCOPE.INVOICE ? this.onApproveAndNotify : this.onApprove,
        onConfirmWithNotify: this.onApproveAndNotify,
        onCancel: this.onCloseModal
      },
      [ACT.REJECTION_REQUESTED]: {
        onConfirm: SCOPE.INVOICE ? this.onRejectAndNotify : this.onReject,
        onConfirmWithNotify: this.onRejectAndNotify,
        onCancel: this.onCloseModal
      }
    }
  }

  reviewActionHandlers = () => {
    const { showModalWhen, review, dispatch, router } = this.props

    const { location, params } = router
    const scope =
      this.props.scope || (location.pathname.includes('matters') ? SCOPE.MATTER : SCOPE.INVOICE)

    const {
      canFinalize,
      canApprove,
      canReject,
      canUnapprove,
      canUnreject
    } = review.actionPermissions

    const constructHandler = (actionType, openModal, performAction) => () => {
      dispatch({ type: ACT.IS_HYDRATING })

      showModalWhen(actionType, review).then(shouldShowModal => {
        shouldShowModal ? openModal() : performAction()
      })
    }

    const onFinalize = constructHandler(
      ACT.FINALIZE_REQUESTED,
      this.onOpenModalForFinalize,
      this.onFinalize
    )

    const onApprove = constructHandler(
      ACT.APPROVAL_REQUESTED,
      this.onOpenModalForApproval,
      this.onApprove
    )

    const onReject = constructHandler(
      ACT.REJECTION_REQUESTED,
      this.onOpenModalForRejection,
      this.onReject
    )

    const anyoneCanUnreject = scope === SCOPE.MATTER && this.getRejectedResponse()

    return {
      ...(canFinalize ? { onFinalize } : {}),
      ...(canApprove ? { onApprove } : {}),
      ...(canReject ? { onReject } : {}),
      ...(canUnapprove ? { onUnapprove: this.onUnapprove } : {}),
      ...(canUnreject || anyoneCanUnreject ? { onUnreject: this.onUnreject } : {})
    }
  }

  entryActionHandlers = () => {
    const { canRemove, canSendBack } = this.props.review.actionPermissions

    return {
      ...(canSendBack ? { onSendBackTo: this.onSendBackTo } : {}),
      ...(canRemove ? { onRemove: this.onRemove } : {}),
      onShowReasons: this.onShowReasons
    }
  }

  render() {
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params, this.props)

    return (
      <ScopeContext.Provider value={{ scope, scopeId }}>
        <ReviewLadder
          {...this.props}
          {...this.state}
          scope={this.props.scope || scope}
          scopeId={this.props.scopeId || scopeId}
          reviewActionHandlers={this.reviewActionHandlers()}
          entryActionHandlers={this.entryActionHandlers()}
          modalHandlers={this.modalHandlers()}
          onCloseReasons={this.onCloseReasons}
          onCancelPrompt={this.onCancelPrompt}
          onConfirmPrompt={this.onConfirmPrompt}
          onAddReviewers={this.onAddReviewers}
          toggleEntryGroup={this.toggleEntryGroup}
          toggleResponse={this.toggleResponse}
        />
      </ScopeContext.Provider>
    )
  }
}

export default ReviewLadderContainer
