import { formatResponse } from 'utils/formatting'
import { put, takeLatest, call } from 'redux-saga/effects'
import { makePutRequest, makeGetRequest } from 'utils/api'
import { fromDB, toDB } from './serializers'
import ACT from 'vendors/survey/actions'
import APP_ACT from 'app/actions'

import { NotificationList } from 'components'

function* fetchVendorEval(action) {
  const url = `/manage/matters/evaluations/api/eval-notify/${action.payload.evalId}/`
  try {
    const evaluation = yield call(makeGetRequest, url)
    yield put({
      type: ACT.FETCH_VENDOR_EVAL_SUCCESS,
      payload: {
        evaluation: fromDB(evaluation)
      }
    })
  } catch (e) {
    if (e.response.status === 404) {
      const { navigate } = action.payload
      navigate('/v2/matters/templates/not_found')
    } else if (e.response.status === 403) {
      yield put({
        type: ACT.VENDOR_EVAL_PERMISSION_DENIED,
        payload: {
          permissionDenied: true
        }
      })
    } else {
      yield put({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was an issue fetching vendor survey.',
          message: <NotificationList lines={formatResponse(e.response.data)} />,
          level: 'error'
        }
      })
    }
  }
}

function* saveVendorEval(action) {
  const url = `/manage/matters/evaluations/api/eval/${action.payload.evalId}/`
  const body = toDB(action.payload.evaluation)

  try {
    yield call(makePutRequest, url, body)

    yield put({
      type: ACT.SAVE_VENDOR_EVAL_SUCCESS,
      payload: {}
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue saving vendor survey.',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* submitVendorEval(action) {
  const url = `/manage/matters/evaluations/api/eval/${action.payload.evalId}/`
  const body = toDB(action.payload.evaluation)

  try {
    yield call(makePutRequest, url, body)

    yield put({
      type: ACT.SUBMIT_VENDOR_EVAL_SUCCESS,
      payload: {}
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue submitting vendor survey.',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

const vendorEvalsSagas = [
  takeLatest(ACT.FETCH_VENDOR_EVAL_REQUESTED, fetchVendorEval),
  takeLatest(ACT.SUBMIT_VENDOR_EVAL_REQUESTED, submitVendorEval),
  takeLatest(ACT.SAVE_VENDOR_EVAL_REQUESTED, saveVendorEval)
]

export default vendorEvalsSagas
