import { Calendar, CalendarAPI } from './types'

export const toCalendars = (calendars: CalendarAPI[]): Calendar[] => {
  return calendars.map((calendar: CalendarAPI) => ({
    id: calendar.user.id,
    fullName: calendar.user.full_name,
    createdAt: new Date(calendar.created_at),
    lastSyncAt: calendar.last_sync !== null ? new Date(calendar.last_sync) : calendar.last_sync
  }))
}
