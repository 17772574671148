import { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import cn from 'classnames'
import 'react-day-picker/lib/style.css'
import 'styles/overrides/react-day-picker.css'
import s from './DatePicker.scss'

import { formatDate, parseDate } from 'react-day-picker/moment'

class ControlledDatePicker extends Component {
  handleChange = value => {
    const { onChange } = this.props
    const selectedDate = value ? moment(value).format('L') : null
    onChange(selectedDate)
  }

  render() {
    const { disabled, className, classNames, isPortal, value, dayPickerProps } = this.props

    return (
      <div className={cn(s.dayPickerWrapper, className)}>
        <DayPickerInput
          isPortal={isPortal}
          value={value}
          formatDate={formatDate}
          parseDate={parseDate}
          classNames={classNames}
          placeholder={value ? value : 'MM/DD/YYYY'}
          onDayChange={this.handleChange}
          inputProps={{ disabled }}
          dayPickerProps={dayPickerProps || {}}
        />
      </div>
    )
  }
}

export default ControlledDatePicker
