import { formatResponse } from 'utils/formatting'
import { put, takeLatest } from 'redux-saga/effects'
import { makeGetRequest } from 'utils/api'
import ACT from './actions'
import APP_ACT from 'app/actions'
import moment from 'moment'

import { NotificationList } from 'components'

function* fetchCurrentVendor(action) {
  const { id } = action.payload
  try {
    const url = `/manage/matters/evaluations/api/scorecard/${id}/`
    const response = yield makeGetRequest(url)
    const { current_vendor, practice_areas, vendors } = response

    yield put({
      type: ACT.CURRENT_VENDOR_FETCH_SUCCESS,
      payload: {
        currentVendor: current_vendor,
        practiceAreas: practice_areas,
        vendors
      }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Dashboard data',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchQuestionAnswers(action) {
  const { vendorId, questionId, questionIndex, filters } = action.payload
  const { dateRange, practiceArea } = filters
  const { start, end } = dateRange

  try {
    const practiceAreaValue = practiceArea && practiceArea.value
    const url = `/manage/matters/evaluations/api/scorecard/${vendorId}/answers/${questionId}/`
    const params = {
      date_start: start ? moment(start).toISOString() : '',
      date_end: end ? moment(end).toISOString() : '',
      practice_area: practiceAreaValue || ''
    }
    const response = yield makeGetRequest(url, { params })
    const { answers, permissions_warning } = response

    yield put({
      type: ACT.QUESTION_ANSWERS_FETCH_SUCCESS,
      payload: { answers, index: questionIndex, permissions_warning }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Answers for the question',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchAdditionalComments(action) {
  const { vendorId, filters } = action.payload
  const { dateRange, practiceArea } = filters
  const { start, end } = dateRange

  try {
    const practiceAreaValue = practiceArea && practiceArea.value
    const url = `/manage/matters/evaluations/api/scorecard/${vendorId}/answers/comment/`
    const params = {
      date_start: start ? moment(start).toISOString() : '',
      date_end: end ? moment(end).toISOString() : '',
      practice_area: practiceAreaValue || ''
    }
    const response = yield makeGetRequest(url, { params })
    const { answers, permissions_warning } = response

    yield put({
      type: ACT.ADDITIONAL_COMMENTS_FETCH_SUCCESS,
      payload: { answers, permissions_warning }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Comments',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchFilteredQuestions(action) {
  const { vendorId, filters } = action.payload
  const { dateRange, practiceArea } = filters
  const { start, end } = dateRange

  try {
    const practiceAreaValue = practiceArea && practiceArea.value
    const url = `/manage/matters/evaluations/api/scorecard/${vendorId}/questions/`
    const params = {
      date_start: start ? moment(start).toISOString() : '',
      date_end: end ? moment(end).toISOString() : '',
      practice_area: practiceAreaValue || ''
    }
    const response = yield makeGetRequest(url, { params })
    const { questions, additional_comments_responses } = response

    yield put({
      type: ACT.FILTERED_QUESTIONS_SUCCESS,
      payload: { questions, additionalCommentsResponses: additional_comments_responses }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Questions',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchCompareQuestions(action) {
  const { vendorId, comparisons } = action.payload
  const { dateRange, practiceArea, vendor } = comparisons
  const { start, end } = dateRange
  let response

  try {
    const practiceAreaValue = practiceArea && practiceArea.value
    let url = `/manage/matters/evaluations/api/scorecard/${vendorId}/questions/`
    if (vendor) {
      url = `/manage/matters/evaluations/api/scorecard/${vendor.value}/questions/`
      response = yield makeGetRequest(url)
    } else {
      const params = {
        date_start: start ? moment(start).toISOString() : '',
        date_end: end ? moment(end).toISOString() : '',
        practice_area: practiceAreaValue || ''
      }
      response = yield makeGetRequest(url, { params })
    }

    const { questions } = response

    yield put({ type: ACT.COMPARE_QUESTIONS_SUCCESS, payload: { questions } })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Questions',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchQuestions(action) {
  const { id } = action.payload

  try {
    const url = `/manage/matters/evaluations/api/scorecard/${id}/questions/`

    const response = yield makeGetRequest(url)
    const { questions, additional_comments_responses } = response

    yield put({
      type: ACT.FILTERED_QUESTIONS_SUCCESS,
      payload: { questions, additionalCommentsResponses: additional_comments_responses }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Questions',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

const scorecardSagas = [
  takeLatest(ACT.CURRENT_VENDOR_FETCH_REQUESTED, fetchCurrentVendor),
  takeLatest(ACT.QUESTION_ANSWERS_FETCH_REQUESTED, fetchQuestionAnswers),
  takeLatest(ACT.ADDITIONAL_COMMENTS_FETCH_REQUESTED, fetchAdditionalComments),
  takeLatest(ACT.FILTERED_QUESTIONS_REQUESTED, fetchFilteredQuestions),
  takeLatest(ACT.COMPARE_QUESTIONS_REQUESTED, fetchCompareQuestions),
  takeLatest(ACT.QUESTIONS_FETCH_REQUESTED, fetchQuestions)
]

export default scorecardSagas
