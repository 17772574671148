import * as React from 'react'
import swal from 'sweetalert'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import get from 'lodash/get'

import ACT from '../actions'

import { DataTableSerializer, DataTableService } from 'simple-core-ui'
import { setPageTitle } from 'hocs'
import ClientKeysList from './ClientKeysList'

const COLUMNS = [
  {
    columnKey: 'type',
    content: 'Type',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%' }
  },
  {
    columnKey: 'other_key',
    content: 'Other Key',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%' }
  },
  {
    columnKey: 'bucket',
    content: 'Bucket',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%' }
  },
  {
    columnKey: 'modified_date',
    content: 'Modified Date',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%' }
  }
]

const INITIAL_PARAMS = {
  pageSize: 25,
  ordering: { columnKey: 'type', isDesc: false },
  search: '',
  page: 1,
  category: 'clientKeys'
}

@setPageTitle(({ credentials }) => {
  return {
    client: get(credentials, 'user.clientName', ''),
    view: 'ClientKeys',
    detail: 'Management'
  }
})
@withFetchFlow({
  flag: 'InitializeClientKeyList',
  getFetchAction: props => ({
    type: ACT.CLIENT_KEYS_FETCH_REQUESTED,
    payload: null,
    loadingStateDisabled: true
  })
})
@connect(({ clientKeysApp }) => {
  const { clientKeys, clientKeysIsLoading, clientKeyLimit } = clientKeysApp
  return { clientKeys, isUpdating: clientKeysIsLoading, clientKeyLimit }
})
class ClientKeysListContainer extends React.Component {
  state = {
    updateParams: INITIAL_PARAMS
  }

  getTableContents = () => {
    const { clientKeys } = this.props
    const { updateParams } = this.state

    const clientKeysWithActions = clientKeys.map(clientKey => ({
      ...clientKey,
      actions: { useTrashcan: true }
    }))

    const rowsByCategory = DataTableSerializer.getRowsByCategory(
      { clientKeys: clientKeysWithActions },
      COLUMNS
    )
    const clientKeysService = new DataTableService(rowsByCategory, COLUMNS)

    return clientKeysService.getUpdatedResults(updateParams)
  }

  updateTable = updateParams => this.setState({ updateParams })

  deleteClientKey = async clientKeyId => {
    const { clientKeys, dispatch } = this.props

    const keyId = get(
      clientKeys.find(({ id }) => id === clientKeyId),
      'id'
    )
    const willDelete = await swal({
      title: `Delete ClientKey ID:${keyId}?`,
      text: 'ClientKey will be deleted.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Delete ClientKey']
    })

    if (willDelete) {
      dispatch({ type: ACT.CLIENT_KEY_DELETE_REQUESTED, payload: clientKeyId })
    }
  }

  showModal = clientKeyId => {
    const { dispatch } = this.props

    clientKeyId !== undefined
      ? dispatch({
          type: ACT.CLIENT_KEY_SHOW_FOR_UPDATE_REQUESTED,
          payload: clientKeyId
        })
      : dispatch({ type: ACT.CLIENT_KEY_SHOW_FOR_CREATE })
  }

  render() {
    return (
      <ClientKeysList
        {...this.props}
        {...this.state}
        initialParams={INITIAL_PARAMS}
        getTableContents={this.getTableContents}
        showModal={this.showModal}
        deleteClientKey={this.deleteClientKey}
        updateTable={this.updateTable}
        clientKeyCount={this.props.clientKeys.length}
      />
    )
  }
}

export default ClientKeysListContainer
