import { Component } from 'react'
import { Checkbox } from 'components'

class CheckboxContainer extends Component {
  handleChange = value => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const { disabled, defaultChecked } = this.props
    return (
      <Checkbox defaultChecked={defaultChecked} onChange={this.handleChange} disabled={disabled} />
    )
  }
}

export default CheckboxContainer
