import { Accordion } from 'simple-core-ui'
import { Attribute } from './AttributeItem'
import s from './AttributeGroup.scss'
import { useDrag } from 'react-dnd'

const AttributeGroup = ({ attributes, groupSelected, name, sectionIndex }) => {
  const [, drag] = useDrag(() => ({
    type: 'AttributeGroup',
    item: {
      name,
      attributes,
      action: 'addGroup'
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      }
    }
  }))

  return (
    <div>
      <Accordion
        name={name}
        draggable={!groupSelected}
        refProp={node => (!groupSelected ? drag(node) : node)}
      >
        {attributes.map((attribute, index) => {
          const selected = attribute.selected ? s.selected : ''
          const requiredByClient =
            attribute.requiredByClient && !attribute.selected ? s.requiredByClient : ''
          const classes = `${selected} ${s.attribute} ${requiredByClient}`

          return (
            <Attribute
              attr={attribute}
              key={attribute.id}
              draggable={!selected}
              classes={classes}
              label={attribute.label}
              title={attribute.requiredByClient ? 'Client required attribute !' : ''}
            />
          )
        })}
      </Accordion>
    </div>
  )
}

export default AttributeGroup
