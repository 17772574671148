import { combineReducers } from 'redux'

import reviewLadderReducer from './review_ladder/reducer'
import reviewerConfigReducer from './reviewer_config/reducer'

const reviewsReducer = combineReducers({
  reviewLadder: reviewLadderReducer,
  reviewerConfig: reviewerConfigReducer
})

export default reviewsReducer
