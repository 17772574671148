import { DataTooltip } from '../DataTooltip'
import s from './RequestStatusTooltip.scss'

const RequestStatusTooltip = () => {
  return (
    <div className={s.wrapper}>
      <DataTooltip
        content={
          <ul>
            <li>
              <span>Pending - Received and pending review</span>
            </li>
            <li>
              <span>Rejected - Rejected and closed</span>
            </li>
            <li>
              <span>Approved - Approved and will become a matter</span>
            </li>
          </ul>
        }
      />
    </div>
  )
}

export default RequestStatusTooltip
