import { useEffect, useState, useMemo } from 'react'
import { ModalContainer, Button, useLoading, Tooltip, DataTableWrapper } from 'simple-core-ui'
import s from './ViewCourtRuleModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { APICourtRule, Event, Cell } from '../types'
import { format } from 'date-fns'
import { timezoneDate, timezoneUtils } from 'utils/helpers'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import cn from 'classnames'
import qs from 'query-string'

interface Props {
  toggleModal: () => void
  scopeName: string
  selectedCourtRuleId: number | null
  toggleEditCourtRuleModal: () => void
}

interface CourtRuleEvent extends Event {
  expanded?: boolean
  [key: string]: any
}

interface CourtRule extends Omit<APICourtRule, 'needs_review' | 'events_count'> {
  events: CourtRuleEvent[]
}

const ViewCourtRuleModal = ({
  toggleModal,
  scopeName,
  selectedCourtRuleId,
  toggleEditCourtRuleModal
}: Props) => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [courtRule, setCourtRule] = useState({} as CourtRule)

  const fetchRule = async () => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`
        )
      )
      setCourtRule(response)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchRule()

    return () => {
      const parsedQuery = qs.parse(window.location.search)
      const parsedHash = qs.parse(window.location.hash, { decode: false })
      delete parsedQuery.courtRuleId
      const queryString = qs.stringify(parsedQuery)
      const hashString = qs.stringify(parsedHash, { encode: false })

      window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleExpand = (id: number) => {
    const updatedEvents = courtRule.events.map(event => {
      if (event.id === id) {
        return {
          ...event,
          expanded: !event.expanded
        }
      }
      return event
    })
    setCourtRule({
      ...courtRule,
      events: updatedEvents
    })
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'start_date',
        content: <b>Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell) => {
          return cell.content !== '----'
            ? format(timezoneDate(cell.content as string), 'MM/dd/yyyy')
            : ''
        }
      },
      {
        columnKey: 'description',
        content: <b>Events</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: CourtRuleEvent) => {
          return (
            <div
              className={cn(s.description, {
                [s.descriptionCollapsed]: !row.expanded
              })}
            >
              {cell.content as string}
            </div>
          )
        }
      },
      {
        columnKey: 'expanded',
        isSortable: false,
        isFilterable: false,
        content: '',
        render: (_cell: Cell, row: CourtRuleEvent) => {
          return row.expanded ? (
            <FaChevronUp className={s.arrow} onClick={() => toggleExpand(row.id)} />
          ) : (
            <FaChevronDown className={s.arrow} onClick={() => toggleExpand(row.id)} />
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courtRule])

  if (!courtRule.id) return null

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={toggleModal}
      content={
        <div className={s.container}>
          <div className={s.content}>
            <h2 className={s.title}>View Court Rules</h2>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application
            <div className={s.item}>
              <p className={s.label}>Court Rule Name</p>
              <p>{courtRule.name}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Matter</p>
              <p className={s.matterName}>{scopeName}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Jurisdiction</p>
              <p>{courtRule.jurisdiction?.name}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Trigger Event</p>
              <p>{courtRule.trigger_event?.name}</p>
            </div>
            <div className={s.flex}>
              <div className={s.item}>
                <p className={s.label}>Trigger Date</p>
                <p>
                  {courtRule.trigger_date
                    ? format(
                        timezoneDate(
                          courtRule.trigger_date as string,
                          courtRule.jurisdiction.time_zone
                        ),
                        'MM/dd/yyyy'
                      )
                    : ''}
                </p>
              </div>
              <div className={s.item}>
                <div className={s.label}>
                  Trigger Time{' '}
                  <Tooltip
                    trigger={<AiOutlineInfoCircle className={s.info} />}
                    content="Time zone is from the Jurisdiction"
                    placement="top"
                  />
                </div>
                <p>
                  {courtRule.trigger_date
                    ? `${format(
                        timezoneDate(
                          courtRule.trigger_date as string,
                          courtRule.jurisdiction.time_zone
                        ),
                        'hh:mm a'
                      )} ${courtRule.jurisdiction.time_zone || timezoneUtils.getUserTimezone()}`
                    : ''}
                </p>
              </div>
              <div className={s.item}>
                <div className={s.label}>
                  Prefix{' '}
                  <Tooltip
                    trigger={<AiOutlineInfoCircle className={s.info} />}
                    content="The prefix is prepended to the event name"
                    placement="top"
                  />
                </div>
                <p>[{courtRule.prefix}]</p>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <p style={{ fontWeight: 600, fontSize: 16, position: 'relative', top: 15, left: 10 }}>
                {courtRule.events?.length ?? 0} Court Rule Event(s)
              </p>
              <DataTableWrapper
                params={{
                  pageSize: Infinity,
                  ordering: { columnKey: 'start_date', isDesc: false },
                  search: '',
                  page: 1,
                  category: 'all'
                }}
                categories={[]}
                rows={courtRule.events}
                columns={columns}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                hasTooltip
                categoryKey="events"
                hasActionsHeader={false}
                hasPagination={false}
              />
            </div>
          </div>
          <div className={s.footer}>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={toggleModal}
              isPrimary
              isOutline
              hasNewDesign
            >
              Cancel
            </Button>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={() => {
                toggleModal()
                toggleEditCourtRuleModal()
              }}
              isPrimary
              hasNewDesign
            >
              Edit
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default ViewCourtRuleModal
