export const nullOptions = [
  {
    value: 'is null',
    label: 'is blank'
  },
  {
    value: 'is not null',
    label: 'is not blank'
  }
]

export const naOptions = [
  {
    value: 'is n/a',
    label: 'is n/a'
  },
  {
    value: 'is not n/a',
    label: 'is not n/a'
  }
]
