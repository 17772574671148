import moment from 'moment'
import qs from 'query-string'
import { Task, TaskFilters, TaskLike } from './types'
import { OPERATORS } from './constants'
import { hasModule } from 'utils/helpers'

const {
  IS_SET,
  IS_NOT_SET,
  WITHOUT_ATTACHMENTS,
  WITH_ATTACHMENTS,
  WITHOUT_COMMENTS,
  WITH_COMMENTS
} = OPERATORS

export const canEditPrivateTask = <T extends TaskLike>(task: T): boolean => {
  if (!task.isPrivate) return true

  const loggedInUser = window.credentials.user

  if (
    task.createdBy?.value === +loggedInUser.id ||
    task.assignees?.some(a => a.value === +loggedInUser.id) ||
    task.followers?.some(a => a.value === +loggedInUser.id) ||
    loggedInUser.role === 'admin'
  ) {
    return true
  }

  return false
}

export const canEditTask = <T extends TaskLike>(
  task: T,
  context: 'matter' | 'workbench'
): boolean => {
  if (
    context === 'workbench' &&
    (task?.relatedMatter?.status === 'closed' || !task?.relatedMatter?.canEdit)
  ) {
    return false
  }

  return canEditPrivateTask(task)
}

export const getSubtaskIds = (tasks: Task[], parentTaskId: Task['id']) =>
  tasks.filter(task => task.parent?.id === parentTaskId).map(t => t.id)

export const createQueryParamsUrl = (taskFilters: TaskFilters, withLabels?: boolean) => {
  const queryParams: string[] = []
  const taskFiltersMap = {
    taskType: 'task_type',
    assignees: 'assignees',
    followers: 'followers',
    status: 'status',
    priority: 'priority',
    id: 'task_names',
    createdBy: 'created_by',
    comments: 'comments',
    fileAttachments: 'attachments',
    dueDate: 'due_date',
    createdDate: 'created_date',
    taskId: 'task_id',
    relatedMatter: 'matter_id'
  }

  const addQueryParam = (key: string, operator: string, values: string) => {
    const queryString = values
      ? `${taskFiltersMap[key as keyof TaskFilters]}=${operator}:${values}`
      : `${taskFiltersMap[key as keyof TaskFilters]}=${operator}`
    queryParams.push(queryString)
  }

  for (const key in taskFilters) {
    const filter = taskFilters[key as keyof TaskFilters]
    if (filter) {
      const { operator, values } = filter
      if (['fileAttachments', 'comments'].includes(key)) {
        if (values?.length) {
          if ([WITH_ATTACHMENTS, WITH_COMMENTS].includes(String(values[0].value))) {
            addQueryParam(key, 'is_set', '')
          } else if ([WITHOUT_ATTACHMENTS, WITHOUT_COMMENTS].includes(String(values[0].value))) {
            addQueryParam(key, 'is_not_set', '')
          }
        }
      } else {
        if (values?.length) {
          const valuesString = values
            .map(val => {
              if (withLabels && !key.includes('Date')) {
                if (key === 'id') {
                  return `${encodeURIComponent(val.label)};${val.value}`
                }

                return `${val.value};${encodeURIComponent(val.label)}`
              }

              return key.includes('Date')
                ? moment(val.value).format('YYYY-MM-DD')
                : key === 'id'
                ? encodeURIComponent(val.label)
                : val.value
            })
            .join(',')
          addQueryParam(key, String(operator?.value).toLowerCase() ?? 'is_set', valuesString)
        } else {
          if (operator?.value === IS_SET) {
            addQueryParam(key, 'is_set', '')
          } else if (operator?.value === IS_NOT_SET) {
            addQueryParam(key, 'is_not_set', '')
          }
        }
      }
    }
  }

  return `${queryParams.join('&')}`
}

export const isTaskCalendarSyncEnabled = () => {
  return hasModule('has_tasks') && window.credentials.externalCalendarSync === 'True'
}

export const createFiltersObjectFromQueryString = (queryString: string, context: string) => {
  const filters: TaskFilters = {
    taskType: null,
    assignees: null,
    followers: null,
    status: null,
    priority: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    dueDate: null,
    createdDate: null,
    taskId: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const parseQueryString = (queryString: string, k: string) => {
    const result: {
      operator: string | null
      values: { value: number | string; label: string }[]
    } = {
      operator: '',
      values: []
    }

    const [operator, pairsString] = queryString.split(':')

    const pairs = pairsString?.split(',') ?? []

    for (const pair of pairs) {
      const [valueNumber, label] = pair.split(';')

      if (k === 'id') {
        result.values.push({ value: label, label: decodeURIComponent(valueNumber) })
      } else if (k.includes('Date')) {
        result.values.push({ value: new Date(valueNumber).toString(), label: 'Date' })
      } else {
        result.values.push({
          value: valueNumber,
          label: decodeURIComponent(label)
        })
      }
    }

    return { ...result, operator: operator ? operator.toUpperCase() : operator }
  }

  const taskFiltersMap = {
    task_type: 'taskType',
    assignees: 'assignees',
    followers: 'followers',
    status: 'status',
    priority: 'priority',
    task_names: 'id',
    created_by: 'createdBy',
    comments: 'comments',
    attachments: 'fileAttachments',
    due_date: 'dueDate',
    created_date: 'createdDate',
    task_id: 'taskId',
    matter_id: 'relatedMatter'
  }

  const operatorsList = [
    { value: 'IS_SET', label: 'Is set' },
    { value: 'IS_NOT_SET', label: 'Is not set' },
    { value: 'IS', label: 'Is' },
    { value: 'IS_NOT', label: 'Is not' },
    { value: 'IS_BEFORE', label: 'Is before' },
    { value: 'IS_AFTER', label: 'Is after' },
    { value: 'IS_BETWEEN', label: 'Is between' }
  ]

  const queryParams = queryString.split('&')

  for (const paramValue of queryParams) {
    const [param, value] = paramValue.split('=')

    const key = Object.entries(taskFiltersMap).find(([k, v]) => {
      return k === param
    })?.[1]

    if (key) {
      const k = key as keyof typeof filters

      if (value.toUpperCase() === 'IS_SET') {
        if (k === 'comments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITH_COMMENTS', label: 'With comments' }]
          }
        } else if (k === 'fileAttachments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITH_ATTACHMENTS', label: 'With attached files' }]
          }
        } else {
          filters[k] = { operator: { value: 'IS_SET', label: 'Is set' }, values: null }
        }
      } else if (value.toUpperCase() === 'IS_NOT_SET') {
        if (k === 'comments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITHOUT_COMMENTS', label: 'Without comments' }]
          }
        } else if (k === 'fileAttachments') {
          filters[k] = {
            operator: null,
            values: [{ value: 'WITHOUT_ATTACHMENTS', label: 'Without attached files' }]
          }
        } else {
          filters[k] = { operator: { value: 'IS_NOT_SET', label: 'Is not set' }, values: null }
        }
      } else {
        const filter = parseQueryString(value, k)

        filters[k] = {
          operator: operatorsList.find(o => o.value === filter.operator) ?? null,
          values: filter.values
        }
      }
    }
  }

  return filters
}

export const getCategoryFromUrl = () => {
  const parsedHash = qs.parse(location.hash)
  return parsedHash.category && typeof parsedHash.category === 'string'
    ? parsedHash.category
    : undefined
}
