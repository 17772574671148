import { isCounselGoUser, isCounselGoAdmin } from 'contacts/utils'
import { Contact, AssignedContact, CreateContactFormType, AssignRoleOnCreate, Role } from './types'
import { extractCgRoles, extractLabels } from './utils'

export const serializeAssignedContacts = (contactsList: Contact[]): AssignedContact[] =>
  contactsList.map(contact => {
    const { roles, org } = contact
    return {
      ...contact,
      isCounselGoAdmin: roles.find(isCounselGoAdmin) ? 'Yes' : 'No',
      isCounselGoUser: roles.find(isCounselGoUser) ? 'Yes' : 'No',
      company: org.name,
      roleIds: extractCgRoles(roles).map(item => item.id),
      rolesString: extractCgRoles(roles)
        .map(item => item.label)
        .join(', '),
      labelIds: extractLabels(roles).map(item => item.id),
      labelsString: extractLabels(roles)
        .map(item => item.label)
        .join(', ')
    }
  })

export const toContact = (
  { firstName, lastName, title, company, companyName, email, phone }: CreateContactFormType,
  isEditMode: boolean
) => ({
  given_name: firstName,
  family_name: lastName,
  title: title,
  primary_email: email,
  primary_phone: phone,
  ...(!isEditMode && company?.value !== -1 ? { vendor: company?.value } : {}),
  ...(companyName ? { company: companyName } : {})
})

export const toRoles = (
  { isCgUser, isCgAdmin, cgRoles, labels }: AssignRoleOnCreate,
  roles: Role[],
  isOtherCompany: boolean
) => {
  const filteredRoles = !isOtherCompany
    ? roles.filter(
        role =>
          (isCgUser && role.systemName === '_cg') || (isCgAdmin && role.systemName === '_cg_admin')
      )
    : []

  return [...filteredRoles, ...(cgRoles || []), ...(labels || [])]
}
