import s from './ChartInfo.scss'
import cn from 'classnames'
import { fes } from 'scui/utils/formatting'

const ChartInfo = ({ percent, total, action }) => {
  return (
    <div className={s.chartInfo}>
      <div
        className={cn(s.progressInfo, {
          [s.completed]: percent === total
        })}
      >
        {percent === total
          ? 'Completed'
          : `${
              action === 'approve'
                ? 'Approving'
                : action === 'reject'
                ? 'Rejecting'
                : 'Marking as paid'
            } ${percent} of ${fes(total, 'invoices')}`}
      </div>
    </div>
  )
}

export default ChartInfo
