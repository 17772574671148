import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, LoadingSkeleton, useLoading } from 'simple-core-ui'

import { createFolder } from './api'
import { unlinkFolder } from '../api'
import { BOX } from '../constants'
import s from './Box.scss'
import UnlinkModal from '../UnlinkModal/UnlinkModal'

const Box = ({ config, matterId, loading, updateConfig }) => {
  const dispatch = useDispatch()

  const [isConfirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false)
  const [isFolderPickerOpen, setFolderPickerOpen] = useState(false)
  const [isLoading, withLoadingLocks] = useLoading()

  const handleApiActions = async callback => {
    try {
      return await callback()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const createNewFolder = () => {
    handleApiActions(async () => {
      await withLoadingLocks(createFolder(matterId))
      updateConfig()
    })
  }

  const unlinkBoxFolder = () => {
    setConfirmUnlinkOpen(false)
    handleApiActions(async () => {
      await withLoadingLocks(unlinkFolder(matterId))
      updateConfig()
    })
  }

  return (
    <>
      {config?.auth_url && !config.connected && (
        <Button>
          <a className={s.linkBtn} href={config.auth_url}>
            Connect to Box
          </a>
        </Button>
      )}
      {config?.connected && !config.folder_info.folder_url && config.can_create_folder && (
        <>
          <div className={s.flexContainer}>
            <Button onClick={createNewFolder} isDisabled={isFolderPickerOpen}>
              Create New Folder
            </Button>
            <Button onClick={() => setFolderPickerOpen(true)} isDisabled={isFolderPickerOpen}>
              Add to Existing Folder
            </Button>
          </div>
          {isFolderPickerOpen && (
            <iframe
              data-testid="box-folder-picker"
              src={`/manage/box_folder_picker/${matterId}/`}
              width="100%"
              height="400"
              frameBorder="0"
            />
          )}
        </>
      )}
      {config?.folder_info?.folder_url && (
        <>
          <iframe
            data-testid="box-container"
            src={`${config.folder_info.folder_url}?view=list&sort=name&direction=ASC&theme=blue`}
            width="100%"
            height="400"
            frameBorder="0"
            allowFullScreen
          />
          <div className={s.flexContainer}>
            <Button isSecondary onClick={() => setConfirmUnlinkOpen(true)}>
              Unlink from Box
            </Button>
            <Button>
              <a
                className={s.linkBtn}
                href={config.folder_info.folder_url}
                target="_blank"
                rel="noreferrer"
              >
                Open Box
              </a>
            </Button>
          </div>
        </>
      )}
      {config.connected && !config.can_access_folder && config.folder_info?.folder_id && (
        <div> You do not have permission to view this folder in Box </div>
      )}
      {(isLoading || loading) && <LoadingSkeleton height={8} style={{ marginTop: 12 }} />}
      <UnlinkModal
        title={BOX}
        isVisible={isConfirmUnlinkOpen}
        confirm={unlinkBoxFolder}
        cancel={() => setConfirmUnlinkOpen(false)}
      />
    </>
  )
}

export default Box
