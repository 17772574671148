import { useEffect, useReducer, useMemo } from 'react'
import s from './IManageTemplates.scss'
import { Panel } from 'simple-core-ui'
import cn from 'classnames'
import { CheckboxContainer } from 'containers'
import Select from 'react-select'
import { makeGetRequest } from 'utils/api'
import * as ACT from './actions'
import { fromTemplates } from './serializers'
import { reducer } from './reducer'
import { IMANAGE } from './constants'

const configUrl = '/doc_management/config'
const templatesUrl = '/doc_management/imanage_templates/'

const initialState = {
  config: {
    configured: false,
    connected: false,
    enabled: false,
    type: '',
    auth_url: ''
  },
  templates: []
}

const connectToIManage = (e, url) => {
  e.preventDefault()
  window.location.href = url
}

const IManageTemplates = ({ setImanageTemplate, template, matterTemplateId, readOnly }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { config, templates } = state
  const { configured, connected, enabled, type, auth_url } = config

  useMemo(() => {
    const selectedTemplate = templates.find(t => t.id === template.details.value)
    const name = selectedTemplate && selectedTemplate.name
    if (!template.details.label && name) template.details.label = name
  }, [template, templates])

  useEffect(() => {
    let isSubscribed = true
    const fetchConfig = async () => {
      const config = await makeGetRequest(configUrl, {
        params: { template_id: matterTemplateId || 'new' }
      })
      if (isSubscribed) {
        dispatch({
          type: ACT.FETCH_CONFIG,
          payload: {
            config
          }
        })
      }
    }
    fetchConfig()
    return () => (isSubscribed = false)
  }, [matterTemplateId])

  useEffect(() => {
    let isSubscribed = true
    const fetchTemplates = async () => {
      const response = await makeGetRequest(templatesUrl)
      if (isSubscribed) {
        dispatch({
          type: ACT.FETCH_TEMPLATES,
          payload: {
            templates: response.templates
          }
        })
      }
    }
    if (type === IMANAGE && configured && enabled && connected) {
      fetchTemplates()
      return () => (isSubscribed = false)
    }
  }, [configured, connected, enabled, type, matterTemplateId])

  if (type !== IMANAGE || !configured || !enabled) return null
  return (
    <Panel title="iManage Templates" className={cn(s.noFlex, s.panel)}>
      {connected ? (
        <div className={s.container}>
          <div className={s.attributeWrapper}>
            <label>Template</label>
            <Select
              value={template.details.value ? template.details : ''}
              onChange={template =>
                setImanageTemplate({ value: template.value, label: template.label }, 'details')
              }
              options={[
                {
                  value: -1,
                  label: 'None'
                },
                ...fromTemplates(templates)
              ]}
              isDisabled={readOnly}
            />
          </div>
          <div className={s.checkBoxesWrapper}>
            <div className={s.readOnlyWrapper}>
              <label>Read Only</label>
              <CheckboxContainer
                property="readOnly"
                onChange={value => setImanageTemplate(value, 'readOnly')}
                defaultChecked={template.readOnly}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      ) : (
        <p>
          To select or create a template you must be logged into{' '}
          <a className={s.link} onClick={e => connectToIManage(e, auth_url)}>
            iManage
          </a>
        </p>
      )}
    </Panel>
  )
}

export default IManageTemplates
