import keyMirror from 'keymirror'

export default keyMirror({
  PUSH_NOTIFICATION: null,
  LEGAL_REQUEST_LIST_FETCH_REQUESTED: null,
  LEGAL_REQUEST_LIST_FETCH_SUCCESS: null,
  LEGAL_REQUEST_DETAIL_FETCH_REQUESTED: null,
  LEGAL_REQUEST_DETAIL_FETCH_SUCCESS: null,
  LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED: null,
  REQUEST_REJECTED_SUCCESS: null,
  LEGAL_REQUEST_DETAIL_CHANGE_COMMENTS: null,
  REQUEST_FORM_FETCH_REQUESTED: null,
  CLEAR_CURRENT_REQUEST_DETAILS: null
})
