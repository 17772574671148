import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'simple-core-ui'
import AddressBookContainer from './AddressBook/AddressBookContainer'
import ContactProfileContainer from './ContactProfile/ContactProfileContainer'

const Contacts = () => (
  <section>
    <Routes>
      <Route path="addressbook" element={<AddressBookContainer />} />
      <Route path="profile/:id" element={<ContactProfileContainer />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </section>
)

export default Contacts
