import { loadingReducer } from 'simple-core-ui/fetchFlow'

/*  Matter Templates */
import matterTemplatesReducer from 'matters/templates/detail/reducer'
import matterTemplatesAttributesReducer from 'matters/templates/detail/attributesReducer'
import matterTemplatesListReducer from 'matters/templates/list/reducer'
import matterTemplatesSelectionReducer from 'matters/templates/common/TemplateSelection/reducer'
import templateAllocationsReducer from 'matters/templates/common/TemplateAllocations/reducer'
import legalRequestReducer from 'matters/templates/legalRequestReducer'
import mattersReducer from 'matters/list/reducer'
import templateFoldersReducer from 'matters/templates/common/DefaultFolderStructure/reducer'

import matterReducer from './matters/matter/reducer'
import requestFormsListReducer from './matters/requestForms/list/reducer'
import requestFormReducer from './matters/requestForms/detail/reducer'
import requestsListReducer from './matters/requests/common/RequestsTableContainer/reducer'
import requestReducer from './matters/requests/create/reducer'

import vendorEvalsReducer from './vendors/survey/reducer'
import scorecardReducer from './vendors/scorecard/reducer'

import appReducer from 'app/reducer'
import accrualsVsActualsReducer from 'accruals_vs_actuals/reducer'
import budgetsReducer from 'budgets/reducer'
import rulesReducer from 'rules/reducer'
import reviewsReducer from 'reviews/reducer'
import clientKeysReducer from 'client_keys/reducer'
import teamsReducer from 'teams/reducer'
import contactsReducer from 'contacts/reducer'
import invoicesReducer from 'invoices/reducer'
import vendorsReducer from './vendors/reducer'
import eventsReducer from 'matter_events/reducer'
import dashboardReducer from 'dashboard/reducer'
import diversityReducer from 'diversity/reducer'

import bulkReducer from './bulk/reducer'

import surveyConfigReducer from './matters/detail/vendors/Surveys/SurveyConfigTab/reducer'
import todosReducer from './todos/reducer'
import documentManagementSettingsReducer from './doc_management/settings/reducer'
import tableauReducer from './tableau/reducer'
import supersetReducer from './reports/Superset/reducer'

const reducers = {
  app: appReducer,
  accrualsVsActuals: accrualsVsActualsReducer,
  dashboard: dashboardReducer,
  matterTemplates: matterTemplatesReducer,
  matterAttributes: matterTemplatesAttributesReducer,
  rules: rulesReducer,
  matter: matterReducer,
  matters: mattersReducer,
  matterTemplatesList: matterTemplatesListReducer,
  requestFormsList: requestFormsListReducer,
  requestForm: requestFormReducer,
  requestsList: requestsListReducer,
  diversityReport: diversityReducer,
  request: requestReducer,
  loading: loadingReducer,
  matterTemplatesSelection: matterTemplatesSelectionReducer,
  contacts: contactsReducer,
  legalRequest: legalRequestReducer,
  budgets: budgetsReducer,
  templateAllocations: templateAllocationsReducer,
  templateFolders: templateFoldersReducer,
  invoices: invoicesReducer,
  vendors: vendorsReducer,
  vendorEvals: vendorEvalsReducer,
  todos: todosReducer,
  reviews: reviewsReducer,
  surveyConfig: surveyConfigReducer,
  scorecard: scorecardReducer,
  teamsApp: teamsReducer,
  clientKeysApp: clientKeysReducer,
  events: eventsReducer,
  documentManagementSettings: documentManagementSettingsReducer,
  tableau: tableauReducer,
  bulk: bulkReducer,
  superset: supersetReducer
}

export default reducers
