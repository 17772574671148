import * as React from 'react'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { XeditableCoreContainer, XeditableLink, If } from 'simple-core-ui'

import ContactFullNameForm from './ContactFullNameForm/ContactFullNameForm'
import { formatContactFullName } from '../utils.js'

const emptyFormFields = {
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: ''
}

class ContactFullNameXeditable extends React.Component {
  static defaultProps = { initialValues: emptyFormFields }

  state = (() => {
    const initialState = this.props.initialValues
    return { savedFields: initialState, formFields: initialState }
  })()

  updateFormFields = formFields => {
    this.setState({ formFields })
  }

  saveFields = () => {
    this.props.setValueCb(this.state.formFields)
    this.setState(({ formFields }) => ({ savedFields: formFields }))
  }

  formatContent = () => {
    const { prefix, firstName, middleName, lastName, suffix } = this.state.savedFields

    return formatContactFullName({ prefix, firstName, middleName, lastName, suffix })
  }

  renderForm = setValueCb => (
    <ContactFullNameForm
      fields={this.state.formFields}
      changeCb={this.updateFormFields}
      setValueCb={setValueCb}
      key={0}
    />
  )

  renderContent = () => {
    const isEmpty = isEqual(emptyFormFields, this.state.savedFields)

    return (
      <XeditableLink asPlaceholder={isEmpty} readOnly={this.props.readOnly}>
        <If condition={!isEmpty} fallback={'add...'}>
          {this.formatContent()}
        </If>
      </XeditableLink>
    )
  }

  render() {
    const { position, title, isDisabled, readOnly } = this.props
    const { savedFields } = this.state

    return (
      <XeditableCoreContainer
        position={position}
        value={savedFields}
        setValueCb={this.saveFields}
        cancelCb={noop}
        title={title}
        renderForm={this.renderForm}
        renderContent={this.renderContent}
        isDisabled={isDisabled}
        readOnly={readOnly}
      />
    )
  }
}

export default ContactFullNameXeditable
