import MobileAccountSettings from './MobileAccountSettings'
import TriggerMenu from './TriggerMenu'

import { createBgImg } from 'simple-core-ui'
import { checkRootPage, checkChildPages } from 'utils/navigation'
import APP_PAGES_MAP from 'data/app_pages_map'
import s from '../styles/Aside.scss'

const Aside = ({ mainLinks, isMenuExpanded, isMobile, triggerMenu }) => (
  <aside
    className={`${isMobile ? s.containerMobile : s.container} ${
      isMenuExpanded ? s.containerExpanded : ''
    }`}
  >
    <nav className={s.mainNav}>
      <ul className={s.mainNavList}>
        {mainLinks.map(link => {
          let Icon
          const metaPage = APP_PAGES_MAP[link.name]

          if (link.name === 'vendor_list' || link.name === 'budget_list') {
            const styles = { ...createBgImg({ path: `icons/${metaPage.icon}.svg` }) }
            Icon = <span style={styles} />

            const map = {
              vendor_list: 'vendor_list_uirefresh',
              budget_list: 'budget_list_uirefresh'
            }

            Icon = APP_PAGES_MAP[map[link.name]].icon
          } else {
            Icon = <span className={`simple-line-icon-${metaPage.icon}`} />
          }

          const isActive = Boolean(
            window.location.pathname === link.url ||
              (window.location.pathname !== link.url && checkRootPage(link)) ||
              (link.name !== 'support' &&
                checkChildPages(link.urls) &&
                window.location.pathname !== '/reports/v2/custom/management/')
          )
          const listItemClasses = `${s.mainNavItem} ${isActive ? s.activePage : ''}`

          return (
            <li key={link.name} className={listItemClasses}>
              <a
                href={link.url}
                title={link.title}
                className={s.mainNavLink}
                id={`slm_${link.name}_nav`}
                target={metaPage.openInNewTab && '_blank'}
              >
                <span className={s.linkIcon}>{Icon}</span>
                <span className={s.linkText}>{metaPage.text}</span>
              </a>
            </li>
          )
        })}
        {isMobile && isMenuExpanded && (
          <MobileAccountSettings accountLinks={window.credentials.accountUrls} />
        )}
      </ul>
    </nav>
    {!isMobile && <TriggerMenu triggerMenu={triggerMenu} isMenuExpanded={isMenuExpanded} />}
  </aside>
)

export default Aside
