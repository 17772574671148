import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store, sagaMiddleware, history } from './configureStore'
import rootSaga from './rootSaga'

import App, { LoadingContainer } from 'app'

import { SimpleContext, coreThemes } from 'simple-core-ui'
import { FileUpload } from 'common/FileUpload'

import 'entry.scss'

import {
  BudgetAmountsContainer,
  BudgetUnconvertedAlert,
  BudgetSummaryPanel,
  BudgetDetailsPanel,
  BudgetToolbarContainer
} from './budgets'

import { BudgetSnapshot, BudgetAmounts } from './budgets/detail_v2'
import { SimpleBudgetList } from './budgets/list_v2'

import {
  ScopedReviewLadder,
  ScopedAdminActions,
  ScopedReviewableStatus,
  IntegratedReviewerConfig
} from './reviews'

import { IntegratedAssignedContacts } from './contacts'
import { FilesListContainer, NotificationsContainer } from './files'
import InvoicePdfPreview from 'invoices/detail/InvoicePdfPreview'
import { DashboardContainer } from './vendors/scorecard/Dashboard'
import HomeDashboard from 'dashboard'

import '!style-loader!css-loader!react-day-picker/lib/style.css' // eslint-disable-line
import '!style-loader!css-loader!react-toggle/style.css' // eslint-disable-line

import '!style-loader!css-loader!styles/overrides/react-day-picker.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/notification-system.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-toggle.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-trello.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-month-picker.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/sweetalert.css' // eslint-disable-line

import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css' // eslint-disable-line

window.store = store

import initJqueryUtils from './utils/jqueryUtils'
import ResubmissionHistory from 'invoices/ResubmissionHistory/ResubmissionHistory'
import AddUser from 'user_management/AddUser'
import { SSOConfigContainer } from 'sso_config'

initJqueryUtils()

window.store.dispatch({
  type: 'EBILL_INITIALIZE',
  payload: {
    credentials: window.credentials,
    history,
    serverContext: window.serverContext.all()
  }
})

sagaMiddleware.run(rootSaga)

// Render React App
const rootEl = document.getElementById('react-app')
const root = createRoot(rootEl)

export const mountNodes = [
  {
    element: rootEl,
    component: App,
    entry: true
  },
  {
    element: document.getElementById('react-contacts'),
    component: IntegratedAssignedContacts
  },
  {
    element: document.getElementById('vendor-details-files'),
    component: FilesListContainer
  },
  {
    element: document.getElementById('vendor-details-files-notifications'),
    component: NotificationsContainer
  },
  {
    element: document.getElementById('react-reviewer-config'),
    component: IntegratedReviewerConfig
  },
  {
    element: document.getElementById('react-budget-request-table'),
    component: BudgetAmountsContainer
  },
  {
    element: document.getElementById('react-budget-request-unconverted-changes'),
    component: BudgetUnconvertedAlert
  },
  {
    element: document.getElementById('react-budget-request-toolbar'),
    component: BudgetToolbarContainer
  },
  {
    element: document.getElementById('react-budget-summary-panel'),
    component: BudgetSummaryPanel
  },
  {
    element: document.getElementById('react-budget-details-panel'),
    component: BudgetDetailsPanel
  },
  {
    element: document.getElementById('react-review-ladder'),
    component: ScopedReviewLadder
  },
  {
    element: document.getElementById('react-review-status-dropdown'),
    component: ScopedAdminActions
  },
  {
    element: document.getElementById('react-reviewable-status'),
    component: ScopedReviewableStatus
  },
  {
    element: document.getElementById('react-loading'),
    component: LoadingContainer
  },
  {
    element: document.getElementById('react-home-dashboard'),
    component: HomeDashboard
  },
  {
    element: document.getElementById('vendor-scorecard-dashboard'),
    component: DashboardContainer
  },
  {
    element: document.getElementById('react-budget-snapshot-panel'),
    component: BudgetSnapshot
  },
  {
    element: document.getElementById('react-budget-amounts'),
    component: BudgetAmounts
  },
  {
    element: document.getElementById('react-simple-budget-list'),
    component: () => {
      const scope = window.serverContext.get('scope')
      const scopeId = window.serverContext.get('scope_id')
      const scopeName = window.serverContext.get('matter_short_name')

      return <SimpleBudgetList scope={scope} scopeId={scopeId} scopeName={scopeName} />
    }
  },
  {
    element: document.getElementById('react-invoice-file-upload'),
    component: () => {
      const objectId = window.serverContext.get('object_id')
      const isInvoiceEditable = window.serverContext.get('invoice_editable')

      const onChange = attachments => {
        window.store.dispatch({ type: 'SET_ATTACHMENTS', payload: attachments })
      }

      return (
        <FileUpload
          scopeId={objectId}
          isEditable={isInvoiceEditable}
          scope="invoice"
          onChangeAttachments={onChange}
        />
      )
    }
  },
  {
    element: document.getElementById('react-vendor-file-upload'),
    component: () => {
      const objectId = window.serverContext.get('object_id')
      const isVendorEditable = window.serverContext.get('has_edit_capability')

      const onChange = attachments => {
        window.store.dispatch({ type: 'SET_ATTACHMENTS', payload: attachments })
      }

      return (
        <FileUpload
          scopeId={objectId}
          isEditable={isVendorEditable}
          scope="vendor"
          onChangeAttachments={onChange}
        />
      )
    }
  },
  {
    element: document.getElementById('react-invoice-pdf-preview'),
    component: () => {
      return <InvoicePdfPreview />
    }
  },
  {
    element: document.getElementById('react-resubmission-history'),
    component: () => {
      return <ResubmissionHistory />
    }
  },
  {
    element: document.getElementById('react-add-user-button'),
    component: AddUser
  },
  {
    element: document.getElementById('react-sso-configuration'),
    component: () => <SSOConfigContainer />
  }
]

const resetLocalStorage = () => {
  // Case to invalidate localStorage for saving invoice list filters.
  if (
    window.localStorage.getItem('savedInvoiceList') &&
    !window.location.href.includes('invoices')
  ) {
    window.localStorage.removeItem('savedInvoiceList')
  } else if (
    window.localStorage.getItem('savedBudgetList') &&
    !window.location.href.includes('budgets')
  ) {
    window.localStorage.removeItem('savedBudgetList')
  } else if (
    window.localStorage.getItem('savedVendorList') &&
    !window.location.href.includes('vendor')
  ) {
    window.localStorage.removeItem('savedVendorList')
  } else if (
    window.localStorage.getItem('savedMatterList') &&
    !window.location.href.includes('matters')
  ) {
    window.localStorage.removeItem('savedMatterList')
  }
}

resetLocalStorage()

const renderApp = Component => {
  root.render(
    <Provider store={window.store}>
      <SimpleContext.Provider value={{ theme: coreThemes.EB }}>
        <Router history={history}>
          <Component />
        </Router>
      </SimpleContext.Provider>
    </Provider>
  )
}

const renderPartial = node => {
  const Component = node.component
  const props = node.props
  const root = createRoot(node.element)
  root.render(
    <Provider store={window.store}>
      <SimpleContext.Provider value={{ theme: coreThemes.EB }}>
        <Router history={history}>
          <Component domProps={props} />
        </Router>
      </SimpleContext.Provider>
    </Provider>
  )
}

mountNodes.forEach(node => {
  if (node.element) {
    node.entry ? renderApp(node.component) : renderPartial(node)
  }
})

if (__DEV__) {
  document.head.querySelector('link[rel="shortcut icon"]').href =
    '/static/assets/ico/favicon-dev.png'
  window.toJSON = str => '\n' + JSON.stringify(str, null, 2) + '\n'
  window.toHTML = obj => Object.keys(obj).forEach(key => <h1>{`${key}: ${obj[key]}`}</h1>)
}

export default renderApp
