import { useContext } from 'react'
import InvoiceAI from './InvoiceAI'
import {
  Panel,
  ButtonDropdown,
  Anchor,
  Input,
  Tabs,
  Tab,
  PageSizeOptions,
  Pagination,
  DataTableService
} from 'simple-core-ui'
import { useAppTabs } from 'hooks'
import { useSelector } from 'react-redux'
import qs from 'query-string'

import RuleCardContainer from 'rules/card'
import s from '../styles/RulesList.scss'
import { CATEGORY, EMPTY_TEXT, ENGINE_LABEL, ENABLED_ENGINES, ENGINE } from 'rules/constants'
import { sortRules } from 'rules/utils'
import { EngineContext } from 'rules/context'
import { Navigate } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

const ENGINE_TABS = ENABLED_ENGINES.map(engine => ({
  label: ENGINE_LABEL[engine],
  value: engine
}))

const RulesList = ({
  engine,
  isSaving,
  rulesList,
  ruleIndexMap,
  ruleNameMap,
  rulePriorityMap,
  newRulePending,
  category,
  search,
  onAddRule,
  onChangeCategory,
  onChangeEngine,
  onChangePage,
  onChangePageSize,
  onSearch
}) => {
  const rules = useSelector(state => state.rules)

  const { canEdit } = useContext(EngineContext)

  const params = qs.parse(window.location.search)

  useAppTabs({
    tabs: ENGINE_TABS,
    selected: engine,
    onClick: onChangeEngine
  })

  const { filters, filteredEntries, totalEntries } = rules[engine]
  const pageWindow = DataTableService.getPageWindow(5, filters.pageSize, totalEntries, filters.page)
  const { firstPage, lastPage } = DataTableService.getPageBoundaries(
    filters.pageSize,
    filteredEntries || totalEntries
  )
  const { previousPage, nextPage } = DataTableService.getSurroundingPages(
    filters.page,
    filters.pageSize,
    filteredEntries || totalEntries
  )

  if (engine === ENGINE.INVOICE_AI) {
    return (
      <section className={s.container}>
        <InvoiceAI />
      </section>
    )
  }

  if (
    engine === ENGINE.SIMPLE_REVIEW &&
    (!params.engine || params.engine === ENGINE.SIMPLE_REVIEW)
  ) {
    return <Navigate to="/v2/rules/simplereview/list" />
  }

  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <header className={s.header}>
        {window.credentials.user.isCSM && (
          <Anchor url={`/v2/rules/dsl?engine=${engine}`}>Navigate to Rules Text Editor</Anchor>
        )}
        <div className={s.filter}>
          <Input
            placeholder="Search Rules..."
            text={search || ''}
            onChangeCb={({ target: { value } }) => onSearch(value)}
          />
        </div>
      </header>
      <Panel
        title={`${ENGINE_LABEL[engine]} Rules`}
        className={s.panel}
        rightActions={[
          canEdit && (
            <ButtonDropdown
              options={[
                { label: 'Standard Rule', value: false },
                { label: 'Unconditional Rule', value: true }
              ]}
              displayText="Create New"
              onSelect={({ value }) => onAddRule(value)}
              style={{ marginRight: 0 }}
              isDisabled={isSaving || newRulePending}
              isPrimary
              alignRight
              key="a"
            />
          )
        ]}
      >
        <Tabs alignRight>
          <Tab
            selected={category === CATEGORY.ALL}
            header="All"
            onClick={() => onChangeCategory(CATEGORY.ALL)}
          />
          <Tab
            selected={category === CATEGORY.ACTIVE}
            header="Active"
            onClick={() => onChangeCategory(CATEGORY.ACTIVE)}
          />
          <Tab
            selected={category === CATEGORY.INACTIVE}
            header="Inactive"
            onClick={() => onChangeCategory(CATEGORY.INACTIVE)}
          />
        </Tabs>
        {rulesList.length ? (
          <div className={s.listWrapper}>
            <PageSizeOptions
              pageSize={filters.pageSize}
              classNames={{ wrapper: s.pageSizeOptionsWrapper }}
              onChange={onChangePageSize}
            />
            <ul className={s.list}>
              {sortRules(rulesList, rulePriorityMap, ruleNameMap).map(rule => {
                const ruleIndex = ruleIndexMap[rule.id]
                return (
                  <RuleCardContainer
                    rule={rule}
                    key={`rule_index_${ruleIndex}`}
                    ruleIndex={ruleIndex}
                  />
                )
              })}
            </ul>
            <Pagination
              previousPage={previousPage}
              currentPage={filters.page}
              nextPage={nextPage}
              updateCurrentPage={onChangePage}
              pageWindow={pageWindow}
              firstPage={firstPage + 1}
              lastPage={lastPage}
            />
          </div>
        ) : (
          <p className={s.empty}>
            {!search ? (
              EMPTY_TEXT[category]
            ) : (
              <>
                <span>
                  No {category === CATEGORY.ALL ? '' : category.toLowerCase()} rules found matching:
                </span>
                <strong className={s.searchValue}>{search}</strong>
              </>
            )}
          </p>
        )}
      </Panel>
    </section>
  )
}

export default RulesList
