import { DataTooltip } from '../DataTooltip'
import s from './MatterStatusTooltip.scss'

const MatterStatusTooltip = () => {
  return (
    <div className={s.wrapper}>
      <DataTooltip
        content={
          <ul>
            <li>
              <span>Draft - Accepted legal request is being processed as a draft matter</span>
            </li>
            <li>
              <span>In Progress - Your matter is open and being worked on</span>
            </li>
            <li>
              <span>Complete - Work has been completed and closed</span>
            </li>
            <li>
              <span>Voided - Your matter is no longer being worked on</span>
            </li>
          </ul>
        }
      />
    </div>
  )
}

export default MatterStatusTooltip
