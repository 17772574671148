import Filter from './Filter'
import OptionList from './OptionList'
import Header from './Header'
import Empty from './Empty'

import s from './ComboBox.scss'

const Box = ({
  heading,
  navigateBackCb,
  filterValue,
  filterCb,
  options,
  selectionCb,
  isEmpty,
  isLoading,
  custom,
  style = {}
}) => (
  <section
    className={s.box}
    style={style}
    onClick={e => e.stopPropagation()}
    data-testid="box-component"
  >
    {heading && <Header heading={heading} navigateBackCb={navigateBackCb} />}
    {custom && options && <h4 className={s.subHeading}>{'Custom Value:'}</h4>}
    {custom && custom(selectionCb)}
    {custom && options && <h4 className={s.subHeading}>{'Defined Values:'}</h4>}
    {options && (
      <>
        <Filter filterValue={filterValue} filterCb={filterCb} label={'Choose...'} />
        {!isLoading ? (
          <OptionList
            options={options}
            selectionCb={selectionCb}
            emptyText={isEmpty || filterValue.length ? 'No results...' : 'Type to search...'}
          />
        ) : (
          <Empty text="Loading..." />
        )}
      </>
    )}
  </section>
)

export default Box
