import keyMirror from 'keymirror'

export default keyMirror({
  ADD_CATEGORY_REQUESTED: null,
  ADD_CATEGORY_SUCCESS: null,
  CHANGE_CATEGORY_NAME: null,
  RESET_CATEGORY: null,
  UPDATE_TABLE_PARAMS: null,
  CATEGORIES_FETCH_REQUESTED: null,
  CATEGORIES_FETCH_SUCCESS: null,
  DELETE_CATEGORY_REQUESTED: null,
  DELETE_CATEGORY_SUCCESS: null,
  SELECT_CATEGORY: null,
  EDIT_CATEGORY_REQUESTED: null,
  EDIT_CATEGORY_SUCCESS: null,
  LABELS_FETCH_REQUESTED: null,
  LABELS_FETCH_SUCCESS: null,
  CHANGE_LABEL_NAME: null,
  RESET_LABEL: null,
  ADD_LABEL_REQUESTED: null,
  ADD_LABEL_SUCCESS: null,
  DELETE_LABEL_REQUESTED: null,
  DELETE_LABEL_SUCCESS: null,
  EDIT_LABEL_REQUESTED: null,
  EDIT_LABEL_SUCCESS: null,
  SELECT_LABEL: null,
  PREVIEW_SETTINGS_FETCH_REQUESTED: null,
  PREVIEW_SETTINGS_FETCH_SUCCESS: null,
  EDIT_PREVIEW_SETTING_REQUESTED: null,
  EDIT_PREVIEW_SETTING_SUCCESS: null,
  ADMINS_FETCH_REQUESTED: null,
  ADMINS_FETCH_SUCCESS: null,
  NOTIFICATIONS_FETCH_REQUESTED: null,
  NOTIFICATIONS_FETCH_SUCCESS: null,
  ADD_ADMIN_REQUESTED: null,
  ADD_ADMIN_SUCCESS: null,
  DELETE_ADMIN_REQUESTED: null,
  DELETE_ADMIN_SUCCESS: null,
  INFECTED_FILES_FETCH_REQUESTED: null,
  INFECTED_FILES_FETCH_SUCCESS: null,
  SELECT_INFECTED_FILE: null,
  DELETE_INFECTED_FILE_REQUESTED: null,
  DELETE_INFECTED_FILE_SUCCESS: null
})
