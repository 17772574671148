import { useContext } from 'react'
import s from './DraftingSidebar.scss'
import { DraftingContextForm } from 'matters/detail/files/MatterAttachmentsTab/DocumentDrafting/DraftingContextForm'
import { INITIAL_DRAFTING_CONTEXT } from '../constants'
import DocumentDraftingContext from 'matters/detail/context'

interface Props {
  primaryActionCb: () => void
  secondaryActionCb: () => void
}

const DraftingSidebar = ({ primaryActionCb, secondaryActionCb }: Props) => {
  const { draftingContext, setDraftingContext, setShowDraftingSidebar } = useContext(
    DocumentDraftingContext
  )

  if (!draftingContext.text) setDraftingContext(INITIAL_DRAFTING_CONTEXT)

  return (
    <div className={s.container} data-testid="drafting-container">
      <div
        className={s.close}
        onClick={() => setShowDraftingSidebar(false)}
        data-testid="drafting-close"
      >
        &times;
      </div>
      <DraftingContextForm
        primaryActionCb={primaryActionCb}
        secondaryActionCb={secondaryActionCb}
      />
    </div>
  )
}

export default DraftingSidebar
