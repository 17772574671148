// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit'
import { fetchFlowMiddleware, isRequest, isSuccess } from 'simple-core-ui/fetchFlow'
import createSagaMiddleware from 'redux-saga'

import { createBrowserHistory } from 'history'
import { parseErrorMessage } from './utils/helpers'
import reducers from './rootReducer'
import { createReduxHistoryContext } from 'redux-first-history'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
})

export const sagaMiddleware = createSagaMiddleware()

const stripActionType = (actionType, str) => {
  const idx = actionType.indexOf(str)
  return actionType.slice(0, idx)
}

export const handleStatusCodes = (error, response, dispatch) => {
  const msgIsNotDisplayable = !Array.isArray(response.data) && typeof response.data === 'object'
  const validResponseMessage = parseErrorMessage(response.data)

  const pushNotification = (title, message, level = 'error') => {
    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: { title, message, level }
    })
  }

  switch (response.status) {
    case 403:
      const DEFAULT_403 = 'You do not have permission to make this request'
      const message403 = msgIsNotDisplayable
        ? validResponseMessage || response.data.errors || response.data || DEFAULT_403
        : DEFAULT_403
      pushNotification(error.errorTitle || response.statusText || '403 Forbidden', message403)
      break

    case 404:
      const DEFAULT_404 = 'The requested entity does not exist.'
      const message404 = msgIsNotDisplayable ? DEFAULT_404 : response.data
      pushNotification(error.errorTitle || response.statusText || '404 Not Found', message404)
      break

    case 500:
      const DEFAULT_500 = 'A server error occurred. Please contact the administrator.'
      const message500 = msgIsNotDisplayable ? DEFAULT_500 : response.data
      pushNotification(
        error.errorTitle || response.statusText || '500 Internal Server Error',
        message500
      )
      break

    case 422:
      const DEFAULT_422 =
        "Make sure you have all required fields. If that doesn't work, reload the page and try again."
      const { data: data422 } = response
      const notDisplayable422 = data422.errors
        ? !Array.isArray(data422.errors)
        : msgIsNotDisplayable
      const message422 = data422.errors || data422
      pushNotification('Validation Error', notDisplayable422 ? DEFAULT_422 : message422)
      break

    case 400:
      const DEFAULT_400 =
        "Make sure you have all required fields. If that doesn't work, reload the page and try again."
      const { data: data400 } = response
      const message400 = validResponseMessage || data400.errors || data400 || DEFAULT_400
      pushNotification(error.errorTitle || response.statusText || '400 Bad Request', message400)
      break

    case 409:
      const DEFAULT_409 =
        'A similar request is already being processed. Please wait some time before you try again, or refresh the page to check the status.'
      const message409 = msgIsNotDisplayable ? validResponseMessage || DEFAULT_409 : response.data
      pushNotification(error.errorTitle || response.statusText || '409 Conflict', message409)
      break

    default:
      // Handle other status codes if needed
      break
  }
}

const dispatchErrorNotification = (dispatch, title, message) => {
  dispatch({
    type: 'PUSH_NOTIFICATION',
    payload: {
      title,
      message,
      level: 'error'
    }
  })
}

export const errorDispatch = (error, dispatch) => {
  let response = error.response
  // jquery response objects are different from axios
  if (error.responseText) {
    response = {
      statusText: error.statusText,
      data: error.responseText,
      status: error.status
    }
    handleStatusCodes(error, response, dispatch)
  } else {
    if (error.isAxiosError || error.name === 'AxiosError') {
      if (response) {
        // Server was able to send us a response, so this is an API Error.
        response = {
          statusText: response.statusText,
          data: response.data?.message ?? response.data,
          status: response.status
        }
        handleStatusCodes(error, response, dispatch)
      } else {
        // Axios was not able to get a response at all. This is a Network-Level Error.
        dispatchErrorNotification(dispatch, 'Network Error', 'No Response Received From Server')
      }
    } else {
      // Non-HTTP Error, Standard JS Error (Syntax, etc...)
      dispatchErrorNotification(dispatch, 'Runtime Error', error.message)

      const e = new Error(JSON.stringify(error))
      e.stack = error.stack
      throw e
    }
  }
}

const loadingLockMiddleware = ({ dispatch }) => next => action => {
  if (!action.type) {
    next(action)
  }

  if (isRequest(action)) {
    const actionLoading = { [stripActionType(action.type, '_REQUESTED')]: true }
    dispatch({ type: 'SET_LOADING', payload: actionLoading })
  }

  if (isSuccess(action)) {
    const actionType = stripActionType(action.type, '_SUCCESS')
    const actionLoaded = {
      [actionType]: false
    }
    const initialLoaded = {
      [actionType]: true
    }

    if (action.success)
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          message: action.success,
          level: 'success'
        }
      })

    dispatch({ type: 'SET_LOADING', payload: actionLoaded })
    dispatch({ type: 'SET_INITIAL_LOAD', payload: initialLoaded })
  }

  if (!document || !document.getElementById('react-app')) {
    next(action)
    return
  }

  if (action.type === 'API_ERROR') {
    document.body.style.cursor = 'default'
    document.getElementById('react-app').style.pointerEvents = 'auto'
    errorDispatch(action.error, dispatch)
  }

  if (action.loadingLock === 'on' && !action.disableLoadingCursor) {
    document.body.style.cursor = 'wait'
    document.getElementById('react-app').style.pointerEvents = 'none'
  } else if (action.loadingLock === 'off') {
    document.body.style.cursor = 'default'
    document.getElementById('react-app').style.pointerEvents = 'auto'
    if (action.loadingType) {
      const actionLoading = { [stripActionType(action.loadingType, '_REQUESTED')]: false }
      dispatch({ type: 'SET_LOADING', payload: actionLoading })
    }
  }

  next(action)
}

const middleware = [loadingLockMiddleware, routerMiddleware, sagaMiddleware, fetchFlowMiddleware]

const __DEMO__ = window.location.host === 'demo.simplelegal.com'
window.__DEMO__ = __DEMO__
window.__DEV__ = __DEV__

if (__DEV__ || __DEMO__) {
  const logger = require('redux-logger').default
  middleware.push(logger)
}

export const setupStore = (initialState = {}) => {
  return configureStore({
    reducer: {
      ...reducers,
      router: routerReducer
    },
    middleware,
    preloadedState: initialState
  })
}

export const store = setupStore()

export const history = createReduxHistory(store)
