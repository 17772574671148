import Select, { components, OptionProps, StylesConfig } from 'react-select'

import { TextInput } from 'simple-core-ui'
import { TIME_CONDITION_OPTIONS, TIME_LIMITS, TIME_UNIT_OPTIONS } from '../constants'
import { RelativeDateParams, Option } from '../types'
import s from './RelativeDateContent.scss'

interface Props {
  value: Partial<RelativeDateParams>
  tasks: Option[]
  isAmountError: boolean
  onChange: (value: Partial<RelativeDateParams>, validateAmount?: boolean) => void
}

type SelectOption = Option | Option<string>

const selectCustomStyle: StylesConfig<SelectOption, false> = {
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}

const TaskOption = (props: OptionProps<SelectOption>) => (
  <components.Option {...props}>
    <div className={s.taskOption}>
      <div className={s.taskName}>{props.data.label}</div>
      <div>{props.data.taskId}</div>
    </div>
  </components.Option>
)

const numberInputStyle = { borderRadius: '4px', maxWidth: '140px', width: 'unset' }
const MAX_NUMBER_LENGTH = 10

const RelativeDateContent = ({ value, tasks, isAmountError, onChange }: Props) => {
  const handleAmountChange = (value: string) => {
    let updatedAmount = +value
    if (value.length > MAX_NUMBER_LENGTH) updatedAmount = +value.substring(0, MAX_NUMBER_LENGTH)
    onChange({ amount: updatedAmount }, true)
  }

  return (
    <>
      <div className={s.timeSection}>
        <TextInput
          containerClassName={s.amount}
          placeholder="Number of"
          style={{ ...numberInputStyle, ...(isAmountError && { borderColor: '#D6506B' }) }}
          pattern={value => value.replace(/[^0-9]/g, '')}
          value={value.amount !== undefined ? `${value.amount}` : ''}
          onChange={handleAmountChange}
        />
        <Select
          className={s.timeUnits}
          value={value.timeUnit}
          options={TIME_UNIT_OPTIONS}
          styles={selectCustomStyle}
          onChange={timeUnit => onChange({ timeUnit } as Partial<RelativeDateParams>, true)}
        />
        <Select
          className={s.timeCondition}
          value={value.timeCondition}
          options={TIME_CONDITION_OPTIONS}
          styles={selectCustomStyle}
          onChange={timeCondition => onChange({ timeCondition } as Partial<RelativeDateParams>)}
        />
      </div>
      {isAmountError && (
        <div className={s.amountError}>{`Max value allowed: ${value.timeUnit?.value &&
          TIME_LIMITS[value.timeUnit.value]}`}</div>
      )}
      <div className={s.taskSection}>
        <div className={s.label}>Choose a task from Parent Application</div>
        <Select
          placeholder="Find a task..."
          value={value.task}
          className={s.operators}
          options={tasks}
          components={{ Option: TaskOption }}
          styles={selectCustomStyle}
          onChange={task => onChange({ task } as Partial<RelativeDateParams>)}
        />
      </div>
    </>
  )
}

export default RelativeDateContent
