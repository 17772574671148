import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'simple-core-ui'

import ClientKeysManagement from '../ClientKeysManagement/ClientKeysManagement'

const ClientKeysRouter = () => (
  <Routes>
    <Route path="/" element={<ClientKeysManagement />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default ClientKeysRouter
