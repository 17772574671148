import get from 'lodash/get'

import { REVIEW_STATUS } from '../utils/constants'
import ACT from '../actions'

const revertLabelFor = entry => {
  const reviewStatus = get(
    entry.responses.find(({ reviewStatus }) =>
      [REVIEW_STATUS.APPROVED, REVIEW_STATUS.REJECTED].includes(reviewStatus)
    ),
    'reviewStatus'
  )

  return get(
    {
      [REVIEW_STATUS.APPROVED]: 'approval',
      [REVIEW_STATUS.REJECTED]: 'rejection'
    },
    reviewStatus,
    'review'
  )
}

const PromptText = ({ name, promptAction, promptedFor }) =>
  get(
    {
      [ACT.REMOVE_REVIEWER_REQUESTED]: (
        <span>
          Remove <strong>{get(promptedFor, 'name')}</strong> from reviewers?
        </span>
      ),
      [ACT.SEND_BACK_TO_REQUESTED]: (
        <span>
          This will revert <strong>{get(promptedFor, 'name')}</strong>
          {"'"}s {promptedFor && revertLabelFor(promptedFor)}.
        </span>
      )
    },
    promptAction
  )

export default PromptText
