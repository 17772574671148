import moment from 'moment'
import { curMom } from 'utils/dateUtils'
import filter from 'lodash/filter'
import { WARNING_THRESHOLD, OVER_BUDGET_THRESHOLD } from './constants'

export const getTotalDaysInQuarter = () => {
  const start = curMom()
    .startOf('quarter')
    .month()

  const end = curMom()
    .endOf('quarter')
    .month()

  let daysInQuarter = 0
  for (let i = start; i <= end; i++) {
    daysInQuarter += curMom()
      .month(i)
      .daysInMonth()
  }
  return daysInQuarter
}

export const getDayInQuarter = () => {
  const start = curMom()
    .startOf('quarter')
    .month()
  const end = curMom()
    .endOf('quarter')
    .month()
  let curDay = curMom().date()
  let curMonth = curMom().month()
  let dayInQuarter = 0

  for (let i = start; i < curMonth; i++) {
    dayInQuarter += curMom()
      .month(i)
      .daysInMonth()
  }

  dayInQuarter += curDay

  return dayInQuarter
}

export const isBudgetOngoing = budget => {
  return (
    moment().isBefore(moment(budget.endDate, 'MM/YYYY')) &&
    moment().isAfter(moment(budget.startDate, 'MM/YYYY'))
  )
}

const isOverbudget = budget => {
  return budget.combinedPercentage >= OVER_BUDGET_THRESHOLD
}

export const isAlmostOverbudget = budget => {
  const percentage = budget.combinedPercentage
  return (
    percentage > WARNING_THRESHOLD && percentage < OVER_BUDGET_THRESHOLD && isBudgetOngoing(budget)
  )
}

export const getInBudgets = budgets => {
  return filter(budgets, b => !isOverbudget(b) && !isAlmostOverbudget(b))
}

export const getAlmostOverbudgets = budgets => {
  return filter(budgets, b => isAlmostOverbudget(b))
}

export const getOverbudgets = budgets => {
  return filter(budgets, b => isOverbudget(b))
}

export const lastAction2Num = la => {
  if (!la) {
    return Number()
  }

  return Number(la.slice(0, la.length - 1))
}

export const getLastAction = date => {
  const now = moment()

  if (!date) {
    return '--'
  }

  return now.diff(moment(date), 'days') + 'd'
}
