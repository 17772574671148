import { useState } from 'react'
import s from './MessageBubble.scss'
import cn from 'classnames'
import { FaExpandAlt, FaCompressAlt } from 'react-icons/fa'

const MessageBubble = ({
  position,
  isEditable = true,
  onDelete,
  onEdit,
  children,
  fullWidth = false
}) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <figure
      className={cn(position === 'left' ? s.left : s.right, {
        [s.fullWidth]: fullWidth,
        [s.expanded]: expanded
      })}
    >
      {!expanded ? (
        <FaExpandAlt className={s.changeSize} onClick={() => setExpanded(true)} />
      ) : (
        <FaCompressAlt className={s.changeSize} onClick={() => setExpanded(false)} />
      )}
      <span>{children}</span>
    </figure>
  )
}

export default MessageBubble
