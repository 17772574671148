import { Operator as ApiOperator } from 'simple_review/@types/api'
import { ListConstant, NonListConstant } from './constant-mapper/constant-model'
import { FuncFieldModel, ModelFieldModel } from './model-field/model-field-model'

export type ComparisonOperator = '<' | '>' | '<=' | '>='
export type EqualsOperator = 'is' | 'is not'
export type InOperator = 'in' | 'not in'
export type ContainsOperator = 'contains' | 'does not contain'
export type BooleanOperator = 'AND' | 'OR'
export type UnaryOperator = 'is null' | 'is not null'

export const COMPARISON_OPERATORS: Array<ComparisonOperator> = ['<', '>', '<=', '>=']
export const EQUALS_OPERATORS: Array<EqualsOperator> = ['is', 'is not']
export const UNARY_OPERATORS: Array<UnaryOperator> = ['is null', 'is not null']
export const IN_OPERATORS: Array<InOperator> = ['in', 'not in']
export const CONTAINS_OPERATORS: Array<ContainsOperator> = ['contains', 'does not contain']

export type Operator =
  | UnaryOperator
  | ComparisonOperator
  | EqualsOperator
  | InOperator
  | ContainsOperator
  | BooleanOperator

export interface ExpressionModel {
  lhs: object
  op: Operator
  rhs?: object
}

export interface ExpressionForUI {
  id?: string
  lhs: ModelFieldModel | FuncFieldModel
  op: ApiOperator
  rhs?: ListConstant | NonListConstant
}

export const VALID_OPERATORS: Array<Operator> = [
  ...COMPARISON_OPERATORS,
  ...EQUALS_OPERATORS,
  ...UNARY_OPERATORS,
  ...IN_OPERATORS,
  ...CONTAINS_OPERATORS
]
