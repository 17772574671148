import ACT from './actions'
import { put, takeLatest } from 'redux-saga/effects'
import { makeGetRequest } from 'utils/api'
import { fromINMA, fromFollowing, fromRecent } from './serializers'

function* itemsNeedingMyActionFetch() {
  try {
    const items = yield makeGetRequest('/items_needing_my_action/')

    yield put({
      type: ACT.ITEMS_NEEDING_MY_ACTION_FETCH_SUCCESS,
      payload: fromINMA(items)
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* recentItemsFetch() {
  try {
    const response = yield makeGetRequest('/logbook/dashboard')

    yield put({
      type: ACT.RECENT_ITEMS_FETCH_SUCCESS,
      payload: fromRecent(response)
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* followingFetch() {
  try {
    const response = yield makeGetRequest('/following/')

    yield put({
      type: ACT.FOLLOWING_FETCH_SUCCESS,
      payload: fromFollowing(response)
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

function* dashboardKpisFetch() {
  try {
    const response = yield makeGetRequest('/dashboard_kpis/')

    yield put({
      type: ACT.DASHBOARD_KPIS_FETCH_SUCCESS,
      payload: {
        kpis: response
      }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

const dashboardSagas = [
  takeLatest(ACT.ITEMS_NEEDING_MY_ACTION_FETCH_REQUESTED, itemsNeedingMyActionFetch),
  takeLatest(ACT.RECENT_ITEMS_FETCH_REQUESTED, recentItemsFetch),
  takeLatest(ACT.FOLLOWING_FETCH_REQUESTED, followingFetch),
  takeLatest(ACT.DASHBOARD_KPIS_FETCH_REQUESTED, dashboardKpisFetch)
]

export default dashboardSagas
