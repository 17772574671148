import { Fragment } from 'react'
import FiltersContainer, {
  DateFilter,
  MatterFilter,
  VendorFilter,
  UserFilter,
  PracticeAreaFilter,
  LegalEntityFilter,
  CheckboxFilter,
  RadioFilter
} from 'common/Filters'

const Filters = ({
  searchParams,
  onSearch,
  onClear,
  hasConfirmation,
  entityLabels,
  matterGroupLabels
}) => {
  const {
    matter_id,
    open_date,
    matter_group_ids,
    matter_lead,
    vendor_ids,
    last_billed,
    legal_entity_ids,
    status,
    template_update_available
  } = searchParams.search
  return (
    <FiltersContainer
      onSearch={onSearch}
      onClear={onClear}
      filterParams={searchParams.search}
      render={(reset, filterSearch) => {
        return (
          <Fragment>
            <MatterFilter
              initialValue={matter_id}
              title="Matter Name"
              reset={reset}
              onSelect={item => filterSearch('matter_id', item)}
              hasConfirmation={hasConfirmation}
              isMulti
              paginated
            />
            <DateFilter
              initialValue={open_date}
              title="Date Opened"
              reset={reset}
              onSelect={item => filterSearch('open_date', item)}
              hasConfirmation={hasConfirmation}
              customLabel={selected => {
                if (!selected) return 'Date Opened'
                return `Opened ${selected.label}`
              }}
            />
            <PracticeAreaFilter
              initialValue={matter_group_ids}
              reset={reset}
              onSelect={item => filterSearch('matter_group_ids', item)}
              hasConfirmation={hasConfirmation}
              title={matterGroupLabels[0]}
              isMulti
              paginated
            />
            <UserFilter
              initialValue={matter_lead}
              title="Matter Lead"
              reset={reset}
              onSelect={item => filterSearch('matter_lead', item)}
              hasConfirmation={hasConfirmation}
              isMulti
              paginated
              withNoneOption
            />
            <VendorFilter
              initialValue={vendor_ids}
              reset={reset}
              isMulti
              onSelect={item => filterSearch('vendor_ids', item)}
              hasConfirmation={hasConfirmation}
              paginated
              withNoneOption
            />
            <LegalEntityFilter
              initialValue={legal_entity_ids}
              reset={reset}
              onSelect={item => filterSearch('legal_entity_ids', item)}
              hasConfirmation={hasConfirmation}
              title={entityLabels[0]}
              isMulti
              paginated
            />
            <CheckboxFilter
              initialValue={status}
              title="Status"
              reset={reset}
              onSelect={item => filterSearch('status', item)}
              hasConfirmation={hasConfirmation}
              defaultValue={[{ value: 'open', label: 'Open' }]}
              options={[
                {
                  value: 'draft',
                  label: 'Draft'
                },
                {
                  value: 'open',
                  label: 'Open'
                },
                {
                  value: 'closed',
                  label: 'Closed'
                }
              ]}
            />
            <RadioFilter
              initialValue={template_update_available}
              title="Template Update"
              reset={reset}
              onSelect={item => filterSearch('template_update_available', item)}
              hasConfirmation={hasConfirmation}
              options={[
                {
                  value: 'true',
                  label: 'Yes',
                  alternativeLabel: 'Template w/ updates'
                },
                {
                  value: 'false',
                  label: 'No',
                  alternativeLabel: 'Template w/o updates'
                }
              ]}
            />
            <DateFilter
              initialValue={last_billed}
              title="Last Worked"
              reset={reset}
              onSelect={item => filterSearch('last_billed', item)}
              hasConfirmation={hasConfirmation}
              customLabel={selected => {
                if (!selected) return 'Last Worked'
                return `Last Worked ${selected.label}`
              }}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default Filters
