import { Fragment } from 'react'
import s from './EditBudgetModal.scss'
import { PracticeAreaSelect, VendorSelect, MatterSelect, CostCodeSelect } from 'common/Selects'

const EditBudgetModal = ({
  setMatterGroupValue,
  matterGroupValue,
  vendorValue,
  setVendorValue,
  setMatterValue,
  costCodeValue,
  setCostCodeValueValue,
  matterValue,
  modalType,
  matterGroupLabels
}) => {
  return (
    <Fragment>
      {modalType === 'vendor' && (
        <div className={s.row}>
          <label>Vendor</label>
          <h4 className={s.subtitle}>Please, choose the vendor</h4>
          <VendorSelect
            value={vendorValue}
            onChange={setVendorValue}
            isPortal
            isClearable
            placeholder="Search Matter Lead"
          />
        </div>
      )}
      {modalType === 'matter' && (
        <div className={s.row}>
          <label>Matter</label>
          <h4 className={s.subtitle}>Please, choose the matter</h4>
          <MatterSelect
            value={matterValue}
            onChange={setMatterValue}
            isPortal
            isClearable
            placeholder="Search Matter Lead"
          />
        </div>
      )}
      {modalType === 'mattergroup' && (
        <div className={s.row}>
          <label>{matterGroupLabels[0]}</label>
          <h4 className={s.subtitle}>Please, choose the {matterGroupLabels[0]}</h4>
          <PracticeAreaSelect
            value={matterGroupValue}
            onChange={setMatterGroupValue}
            isPortal
            isClearable
            placeholder={`Search ${matterGroupLabels[0]}`}
          />
        </div>
      )}
      {modalType === 'costcode' && (
        <div className={s.row}>
          <label>Cost Code</label>
          <h4 className={s.subtitle}>Please, choose the cost code</h4>
          <CostCodeSelect
            value={costCodeValue}
            onChange={setCostCodeValueValue}
            isPortal
            isClearable
            placeholder="Search cost code"
          />
        </div>
      )}
    </Fragment>
  )
}

export default EditBudgetModal
