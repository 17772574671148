export const OPERATORS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  IS_BEFORE: 'IS_BEFORE',
  IS_AFTER: 'IS_AFTER',
  IS_BETWEEN: 'IS_BETWEEN'
}

export const DEFAULT_OPERATOR_LIST = [
  {
    label: 'Is',
    value: 'IS'
  },
  {
    label: 'Is not',
    value: 'IS_NOT'
  }
]
