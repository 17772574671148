export const fromReview = ({
  id,
  admin_actions,
  reviewable_status,
  ladder,
  state, // TODO: Possibly not necessary...
  is_final_approval,
  subscribers,
  user_message,
  ...actionPermissions
}) => ({
  id,
  adminActions: admin_actions,
  reviewableStatus: reviewable_status,
  state,
  isFinalApproval: is_final_approval,
  userMessage: user_message || '',
  actionPermissions: fromActionPermissions(actionPermissions),
  ladder: fromReviewLadder({ subscribers, tiers: ladder.tiers })
})

export const fromActionPermissions = ({
  can_add,
  can_remove,
  can_finalize,
  can_approve,
  can_unapprove,
  can_reject,
  can_unreject,
  can_send_back
}) => ({
  canFinalize: can_finalize,
  canAdd: can_add,
  canRemove: can_remove,
  canApprove: can_approve,
  canUnapprove: can_unapprove,
  canReject: can_reject,
  canUnreject: can_unreject,
  canSendBack: can_send_back
})

export const fromReviewLadder = ({ subscribers, tiers }) => ({
  subscribers: fromSubscribers(subscribers),
  tiers: fromTiers(tiers)
})

export const fromSubscribers = subscribers => subscribers.map(fromSubscriber)

export const fromSubscriber = ({ team_id, name, solo_user_id }) => ({
  teamId: team_id,
  name,
  soloUserId: solo_user_id
})

export const fromTiers = tiers => tiers.map(fromTier)

export const fromTier = ({ entries, status, summary_response, is_current }) => ({
  status,
  isCurrent: is_current,
  tierResponse: summary_response && fromResponse(summary_response),
  entries: fromEntries(entries)
})

export const fromEntries = entries => entries.map(fromEntryGroup)

export const fromEntryGroup = entryGroup => entryGroup.map(fromEntry)

export const fromEntry = ({
  approved, // TODO: Possibly not necessary...
  name,
  reasons,
  responses,
  solo_user_id,
  team_id
}) => ({
  approved,
  teamId: team_id,
  name,
  soloUserId: solo_user_id,
  reasons: fromReasons(reasons),
  responses: fromResponses(responses)
})

export const fromReasons = reasons => reasons.map(fromReason)

export const fromReason = ({
  reason_type,
  rule_label,
  rule_url,
  rc_owner_label,
  rc_owner_url,
  report_user_label,
  report_user_url,
  editing_user_label,
  editing_user_url,
  message
}) => ({
  reasonType: reason_type,
  ...(rule_label ? { ruleLabel: rule_label } : {}),
  ...(rule_url ? { ruleUrl: rule_url } : {}),
  ...(rc_owner_label ? { configLabel: rc_owner_label } : {}),
  ...(rc_owner_url ? { configUrl: rc_owner_url } : {}),
  ...(report_user_label ? { reportUserLabel: report_user_label } : {}),
  ...(report_user_url ? { reportUserUrl: report_user_url } : {}),
  ...(editing_user_label ? { editingUserLabel: editing_user_label } : {}),
  ...(editing_user_url ? { editingUserUrl: editing_user_url } : {}),
  ...(message ? { message } : {})
})

export const fromResponses = responses => responses.map(fromResponse)

export const fromResponse = ({
  team_id,
  solo_user_id,
  reviewer_name,
  reviewed_on,
  review_status,
  response_text
}) => ({
  teamId: team_id,
  soloUserId: solo_user_id,
  reviewerName: reviewer_name,
  reviewedOn: reviewed_on,
  reviewStatus: review_status,
  responseText: response_text
})

export const toMailFormFields = ({ emailTo, subject, body, addAttachments, addPdfCopy }) => ({
  email: {
    contact_emails: emailTo.map(({ email }) => email),
    subject,
    body,
    with_attachments: addAttachments,
    with_pdf: addPdfCopy
  }
})

export const toApproveFormFields = ({
  emailTo,
  subject,
  body,
  addAttachments,
  addPdfCopy,
  additionalDetails,
  notifyViaEmail
}) => ({
  ...(notifyViaEmail
    ? toMailFormFields({ emailTo, subject, body, addAttachments, addPdfCopy })
    : {}),
  is_invoice_adjusted: window.serverContext.get('invoice_is_adjusted'),
  additional_details: additionalDetails
})

export const toRejectFormFields = ({
  emailTo,
  subject,
  body,
  addAttachments,
  addPdfCopy,
  reasons,
  additionalDetails,
  notifyViaEmail
}) => ({
  ...(notifyViaEmail
    ? toMailFormFields({ emailTo, subject, body, addAttachments, addPdfCopy })
    : {}),
  reasons: reasons.map(r => r.value),
  additional_details: additionalDetails
})

export const toReasonsList = response =>
  response.results
    .filter(r => r.active)
    .map(r => ({
      label: r.name,
      value: r.id,
      hideOption: false
    }))

export const formatReviewResponse = response =>
  response.reduce((acc, val) => {
    acc[val.name] = val
    return acc
  }, {})
