import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Tabs, Tab, Panel } from 'simple-core-ui'
import { CategoryContainer } from '../common/Category'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import s from './AdhocReportsContainer.scss'
import { Legend } from '../common/Legend'
import { getAllSavedReports } from './serializers'
import { SIMPLESET_USER_ROLES } from '../constants'

const tabPath = { builders: 'builders', savedReports: 'savedReports' }

const AdhocReportsContainer = ({ savedReports = {}, savedSupersetReports = {} }) => {
  const navigate = useNavigate()
  const { activeTab, adhocActiveTab } = useParams()
  const { superset_url: supersetUrl } = useSelector(state => state.superset.supersetDashboards)
  const { simplesetUserAccess, simplesetUserRole } = useSelector(
    state => state.app.credentials.user
  )
  const [currentActiveTab, setCurrentActiveTab] = useState(tabPath.builders)
  const isSimplsetAccessByRole = useMemo(
    () =>
      simplesetUserRole !== SIMPLESET_USER_ROLES.view &&
      simplesetUserRole !== SIMPLESET_USER_ROLES.none,
    [simplesetUserRole]
  )

  const allSavedReports = useMemo(() => {
    return getAllSavedReports(savedReports, savedSupersetReports)
  }, [savedReports, savedSupersetReports])
  const isSuperset = useMemo(
    () => hasModule(MODULE.SIMPLESET_REPORTING) && simplesetUserAccess && isSimplsetAccessByRole,
    [simplesetUserAccess, isSimplsetAccessByRole]
  )

  useEffect(() => {
    setCurrentActiveTab(adhocActiveTab || tabPath.builders)
  }, [adhocActiveTab])

  const changeTab = adhocTab => {
    setCurrentActiveTab(adhocTab)
    navigate(`/v2/reports/${activeTab}/${adhocTab}`)
  }

  const getSelectedTabIndex = () => Object.values(tabPath).indexOf(currentActiveTab)

  return (
    <Panel title="Build Your Own Reports" styles={{ boxShadow: 'none' }} className={s.reports}>
      <Tabs alignRight selectedIndex={getSelectedTabIndex()}>
        <Tab
          header="Builders"
          selected={currentActiveTab === tabPath.builders}
          onClick={() => changeTab(tabPath.builders)}
        >
          {isSuperset && (
            <div className={s.reportWrapper}>
              <a className={s.name} href={supersetUrl} target="_blank" rel="noreferrer">
                Enhanced Reports Builder
              </a>
              <div className={s.legendContainer}>
                <Legend keys={['daily']} />
              </div>
              <p className={s.description}>
                Build and save custom reports with spend or matter data refreshed every 24 hours.
                Customize existing standard reports to better fit your use cases or create your own
                reports with a wide array of visualization options.
              </p>
            </div>
          )}
          <div className={s.reportWrapper}>
            <a className={s.name} href="/reports/adhoc/v3" target="_blank">
              Adhoc Reports Builder
            </a>
            <div className={s.legendContainer}>
              <Legend keys={['live']} />
            </div>
            <p className={s.description}>
              Build and save custom reports with realtime spend or matter data.
            </p>
          </div>
        </Tab>
        <Tab
          header="Saved Reports"
          selected={currentActiveTab === tabPath.savedReports}
          onClick={() => changeTab(tabPath.savedReports)}
        >
          {Object.keys(allSavedReports).length ? (
            <CategoryContainer reports={allSavedReports} showLegend />
          ) : (
            <p className="m-v-sp200">No saved reports</p>
          )}
        </Tab>
      </Tabs>
    </Panel>
  )
}

export default AdhocReportsContainer
