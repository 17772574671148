import { Input, KeyValuePairs } from 'simple-core-ui'

const fieldLabelMap = {
  prefix: 'Prefix',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  suffix: 'Suffix'
}

const changeThunk = (key, fields, changeCb) => {
  return event => {
    changeCb({
      ...fields,
      [key]: event.currentTarget.value
    })
  }
}

const ContactFullNameForm = ({ fields, changeCb, setValueCb }) => (
  <section>
    <KeyValuePairs
      pairs={[
        {
          key: fieldLabelMap.prefix,
          value: (
            <Input
              text={fields.prefix}
              onChangeCb={changeThunk('prefix', fields, changeCb)}
              onEnterCb={setValueCb}
            />
          ),
          style: { marginRight: '0.5em', width: '25%' }
        },
        {
          key: fieldLabelMap.firstName,
          value: (
            <Input
              text={fields.firstName}
              onChangeCb={changeThunk('firstName', fields, changeCb)}
              onEnterCb={setValueCb}
            />
          )
        }
      ]}
      stackedKeys
      pairsInline
      key={0}
    />
    <KeyValuePairs
      pairs={[
        {
          key: fieldLabelMap.middleName,
          value: (
            <Input
              text={fields.middleName}
              onChangeCb={changeThunk('middleName', fields, changeCb)}
              onEnterCb={setValueCb}
            />
          )
        }
      ]}
      stackedKeys
      key={1}
    />
    <KeyValuePairs
      pairs={[
        {
          key: fieldLabelMap.lastName,
          value: (
            <Input
              text={fields.lastName}
              onChangeCb={changeThunk('lastName', fields, changeCb)}
              onEnterCb={setValueCb}
            />
          ),
          style: { marginRight: '0.5em' }
        },
        {
          key: fieldLabelMap.suffix,
          value: (
            <Input
              text={fields.suffix}
              onChangeCb={changeThunk('suffix', fields, changeCb)}
              onEnterCb={setValueCb}
            />
          ),
          style: { width: '25%' }
        }
      ]}
      stackedKeys
      pairsInline
      key={2}
    />
  </section>
)

ContactFullNameForm.defaultProps = {
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: ''
}

export default ContactFullNameForm
