import { Component } from 'react'
import { Loading } from 'simple-core-ui'

class LoadingContainer extends Component {
  render() {
    return <Loading />
  }
}

export default LoadingContainer
