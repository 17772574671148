import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

type Props = {
  rowId: number
  deleteReason(id: number): void
  editReason(value: boolean, id: number): void
}

const ActionsPopover = ({ rowId, deleteReason, editReason }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editReason(true, rowId)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteReason(rowId)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
