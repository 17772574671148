import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { attachmentUploaderFactory } from 'utils/fileUploadUtils'
import { put, takeLatest } from 'redux-saga/effects'
import { makePostRequest, makeGetRequest } from 'utils/api'
import { toRequestJson } from './serializers'

import ACT from './actions'

import { NotificationList } from 'components'

function* createRequest(action) {
  const { request, navigate, files } = action.payload

  try {
    const url = '/manage/matters/requests/request_list/'

    const requestJson = toRequestJson(request)
    const response = yield makePostRequest(url, requestJson)

    if (files) {
      const attachmentUploader = attachmentUploaderFactory(
        files,
        response.id,
        '/upload/matter/matter_request/attachment/'
      )
      attachmentUploader.uploadAttachments()
    }

    navigate('/v2/matters/requests/list', { replace: true })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue creating the Request',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchLegalRequestDynamicAttributesPairedValues(action) {
  const { value, relIndex, relId, relAttributes } = action.payload
  try {
    const url = `/templates/related/${relId}/vals/${value.value}/paired`
    const { options } = yield makeGetRequest(url)

    yield put({
      type: ACT.CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
      payload: {
        relId,
        relIndex,
        options
      }
    })
  } catch (e) {
    yield put({
      type: 'API_ERROR',
      error: { ...e, errorTitle: 'Error' }
    })
  }
}

function* fetchLegalRequestFilteredAttributes(action) {
  const { matterGroupId } = action.payload
  try {
    const url = `/templates/attributes/-1/${matterGroupId}/-1?noLegalEntityFilter=true`
    const groups = yield makeGetRequest(url)

    yield put({ type: ACT.LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_SUCCESS, payload: { groups } })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

const matterRequestSagas = [
  takeLatest(ACT.LEGAL_REQUEST_CREATE_REQUESTED, createRequest),
  takeLatest(
    ACT.LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
    fetchLegalRequestFilteredAttributes
  ),
  takeLatest(
    ACT.CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
    fetchLegalRequestDynamicAttributesPairedValues
  )
]

export default matterRequestSagas
