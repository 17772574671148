import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'simple-core-ui'
import {
  withSelectTemplateFlowSwitcher,
  withSelectTemplatePageDescription
} from 'matters/templates/hocs'

import { LegalRequestFormsListContainer } from 'matters/requestForms/list'
import { LegalRequestFormContainer } from 'matters/requestForms/detail'
import MattersListContainer from 'matters/list/MattersListContainer'
import { CustomAttributeContainer } from 'matters/customAttributes/detail'

import MatterDetails from 'matters/detail'
import { MatterTemplatesContainer, MatterTemplatesListContainer } from 'matters/templates'
import { PrivateRoute } from 'components'
import { RoleGuard } from 'components/Utils/PrivateRoute/guards'

import SelectionPageContainer from 'matters/templates/select/SelectionPageContainer'
import SelectPreferredPageContainer from 'matters/templates/form/SelectPreferredPageContainer'

import RequestsListContainer from 'matters/requests/list/RequestsListContainer'
import RequestContainer from 'matters/requests/create/RequestContainer'
import ProcessRequestsContainer from 'matters/requests/process/ProcessRequestsContainer'

const SelectionPageContainerHOC = withSelectTemplateFlowSwitcher(
  withSelectTemplatePageDescription(SelectionPageContainer)
)
const SelectPreferredPageContainerHOC = withSelectTemplateFlowSwitcher(
  withSelectTemplatePageDescription(SelectPreferredPageContainer)
)

const Matters = () => (
  <Routes>
    <Route path="list" element={<MattersListContainer />} />
    <Route
      path="templates/details"
      element={
        <PrivateRoute
          component={MatterTemplatesContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="templates/details/:id"
      element={
        <PrivateRoute
          component={MatterTemplatesContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="templates/list"
      element={
        <PrivateRoute
          component={MatterTemplatesListContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route path=":id/*" element={<MatterDetails />} />
    <Route path="templates/select" element={<SelectionPageContainerHOC />} />
    <Route
      path="templates/form/select"
      element={
        <PrivateRoute
          component={SelectPreferredPageContainerHOC}
          guards={[RoleGuard('admin', 'poweruser')]}
          forLegalRequestFormTemplateSelection
        />
      }
    />
    <Route
      path="requests/form"
      element={
        <PrivateRoute
          component={LegalRequestFormContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="requests/form/:id"
      element={
        <PrivateRoute
          component={LegalRequestFormContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="requests/forms"
      element={
        <PrivateRoute
          component={LegalRequestFormsListContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route path="requests/list" element={<RequestsListContainer />} />
    <Route path="requests/list/:id" element={<RequestsListContainer />} />
    <Route path="requests/create" element={<RequestContainer />} />
    <Route
      path="requests/process"
      element={
        <PrivateRoute
          component={ProcessRequestsContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="requests/process/:id"
      element={
        <PrivateRoute
          component={ProcessRequestsContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="matter_attributes/:id"
      element={
        <PrivateRoute
          component={CustomAttributeContainer}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default Matters
