import { Button } from 'simple-core-ui'

import { CANCEL_BTN_STYLE, SAVE_BTN_STYLE } from './styles'
import s from './EditorFooter.scss'

interface Props {
  isPristine: boolean
  hideSave?: boolean
  onSave: () => void
  onCancel: () => void
}

const EditorFooter = ({ isPristine, hideSave, onSave, onCancel }: Props) => {
  return (
    <div className={s.footer}>
      <Button isPrimary isOutline hasNewDesign style={CANCEL_BTN_STYLE} onClick={onCancel}>
        Cancel
      </Button>
      {!hideSave && (
        <Button
          isPrimary
          isDisabled={isPristine}
          hasNewDesign
          onClick={onSave}
          style={SAVE_BTN_STYLE}
        >
          Save Rule
        </Button>
      )}
    </div>
  )
}

export default EditorFooter
