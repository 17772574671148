import { EditableField } from 'components'
import s from './Filters.scss'

const Filters = ({
  practiceAreas,
  filters,
  onDatesChange,
  onClearFilters,
  onPracticeAreaChange
}) => (
  <div className={s.container}>
    <span className={s.label}>Filter</span>
    <div className={s.dateRange}>
      <EditableField
        type="controlled-daterange"
        value={filters.dateRange}
        placeholderStart="Surveyed Start Date"
        placeholderEnd="Surveyed End Date"
        onChange={value => onDatesChange(value, 'filters')}
      />
    </div>
    <div className={s.practiceAreaWrapper}>
      <EditableField
        type="select"
        options={practiceAreas}
        placeholder="Practice Area"
        className={s.practiceArea}
        value={filters.practiceArea}
        onChange={value => onPracticeAreaChange(value, 'filters')}
      />
    </div>
    <p onClick={onClearFilters} className={s.clearFilters}>
      clear filters
    </p>
  </div>
)

export default Filters
