import { FileContainer } from './FileContainer'

const UploadProgress = ({
  file,
  dispatch,
  successCb,
  selectedRelationship,
  setIsUploadFinished,
  setFileSource
}) => {
  if (!file) {
    return <p>No files are uploading</p>
  }
  return (
    <FileContainer
      dispatch={dispatch}
      file={file}
      key={file.id}
      successCb={successCb}
      selectedRelationship={selectedRelationship}
      setIsUploadFinished={setIsUploadFinished}
      setFileSource={setFileSource}
    />
  )
}

export default UploadProgress
