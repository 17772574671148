import moment from 'moment'
import get from 'lodash/get'

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }
  switch (columnKey) {
    case 'created_date':
      return moment(cellContent, 'MM/DD/YYYY').unix()
    case 'last_billed':
      return moment(cellContent, 'MM/DD/YYYY').unix()
    case 'short_name':
      return cellContent.toLowerCase()
    case 'lead':
      return cellContent.toLowerCase()
    case 'status':
      return cellContent.toLowerCase()
    default:
      return cellContent
  }
}

export const getMatterProperty = (list, id, property) => {
  const matter = list.find(l => l.id === id)
  return matter ? matter[property] : ''
}
