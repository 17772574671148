import { useState, useEffect } from 'react'
import { Loading } from 'simple-core-ui'
import { useSelector } from 'react-redux'
import { makeGetRequest, handleError, HTTP_NOT_FOUND } from 'utils/api'
import { FILE_STATUS_SCANNING, FILE_STATUS_INFECTED } from 'common/FileUpload/constants'
import cn from 'classnames'
import s from './InvoicePdfPreview.scss'
import { openLink } from 'utils/helpers'
import PreviewLoadingModal from './PreviewLoadingModal'
import axios, { AxiosError } from 'axios'

interface PDF {
  id: number
  file_name: string
  download_url: string
  file_size: number
  file_type: string
  source: 'ebill' | 'counselgo'
  file_upload_date: Date
  s3_file_url: string
}

type Cache = Record<string, string>

const s3UrlCache: Cache = {}

const fetchS3Url = async (pdfId: number) => {
  let result
  try {
    const response = await makeGetRequest(`/upload/attachment/s3_url/${pdfId}/`)
    result = response.s3_file_url
  } catch (e) {
    const err = e as Error | AxiosError

    if (!axios.isAxiosError(err) || !err.response) {
      return handleError(e, 'Could not get preview url.')
    }

    if (err.response.status === HTTP_NOT_FOUND) {
      window.store.dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          level: 'warning',
          message: err.response.data
        }
      })
    }
  }

  return result
}

const InvoiceEmbed = ({ pdf }: { pdf: PDF }) => {
  const [s3Url, setS3Url] = useState<string | null>(null)
  const [isPreviewWaiting, setIsPreviewWaiting] = useState<boolean>(false)

  useEffect(() => {
    const getPreviewUrl = async () => {
      if (s3UrlCache[pdf.id]) {
        setS3Url(s3UrlCache[pdf.id])
        setIsPreviewWaiting(false)
        return
      }

      setIsPreviewWaiting(true)

      const s3Url = await fetchS3Url(pdf.id)
      if (s3Url) {
        setS3Url(s3Url)
        s3UrlCache[pdf.id] = s3Url
      }

      setIsPreviewWaiting(false)
    }

    getPreviewUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isPreviewWaiting && s3Url ? (
    <iframe style={{ width: '100%', height: '100%' }} src={s3Url} allowFullScreen />
  ) : (
    <Loading style={{ marginTop: '20%' }} />
  )
}

const InvoicePdfPreview = () => {
  const pdfs = useSelector(({ invoices }: any) =>
    invoices.attachments.filter(
      (a: any) =>
        a.file_type === 'application/pdf' &&
        ![FILE_STATUS_SCANNING, FILE_STATUS_INFECTED].includes(a.fileStatus)
    )
  )
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState<boolean>(false)
  const [selectedPdf, setSelectedPdf] = useState<PDF | undefined>()

  useEffect(() => {
    if (!selectedPdf && pdfs.length) {
      setSelectedPdf(pdfs[0])
    }
  }, [pdfs])

  if (!pdfs.length) {
    return null
  }

  return (
    <div>
      <ul className={`nav nav-tabs ${s.pdfTabUl}`}>
        {pdfs.map((p: PDF) => {
          return (
            <li
              className={cn({ active: selectedPdf?.id === p.id }, s.pdfTabLi)}
              key={p.id}
              onClick={() => {
                setSelectedPdf(p)
              }}
            >
              <a
                onClick={async () => {
                  if (s3UrlCache[p.id]) {
                    openLink(s3UrlCache[p.id])
                    return
                  }
                  setIsLoadingModalOpen(true)
                  const s3Url = await fetchS3Url(p.id)

                  if (s3Url) {
                    openLink(s3Url)
                  }
                  setIsLoadingModalOpen(false)
                }}
                target="_blank"
                rel="oopener noreferrer"
                className={s.pdfTabLink}
              >
                {p.file_name}
              </a>
            </li>
          )
        })}
      </ul>

      {selectedPdf && (
        <div className={s.pdfEmbedContainer}>
          <InvoiceEmbed pdf={selectedPdf} />
        </div>
      )}

      <PreviewLoadingModal isVisible={isLoadingModalOpen} />
    </div>
  )
}

export default InvoicePdfPreview
