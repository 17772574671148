import { Folder, Row } from './types'

export const standardRow = (
  row: Folder,
  depth = -1,
  parentRowId: null | number | string = null,
  isExpanded = false
): Row => {
  const { id, name, children = [] } = row
  return {
    id: id,
    cells: [
      {
        columnKey: 'name',
        content: name,
        style: { marginLeft: `${depth * 2}rem`, display: 'flex', alignItems: 'baseline' }
      },
      {
        columnKey: 'subfolders',
        content: children.length
      }
    ],
    children,
    ...(children.length ? { expanded: isExpanded } : {}),
    depth,
    name,
    parentRowId
  }
}

export const cleanFolderName = (name: string) => {
  return name.trim().replace(/ +/g, ' ')
}
