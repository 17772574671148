import qs from 'query-string'
import moment from 'moment'
import {
  DateFilterSerializer,
  MultiSelectFilterSerializer,
  SingleSelectFilterSerializer,
  BulkMultiSelectFilterSerializer
} from 'common/Filters/serializers'
import { sortObjectsList, getInclusiveFields, getNonInclusiveFields } from './utils'
import { fromContact, fromContacts } from 'contacts/serializer'
import { CA_LIST_VALUES, MULTI_FIELD_RECORD_TYPES } from 'bulk/record_types'
import {
  Field,
  BulkParams,
  HistoryParams,
  Option,
  UserOption,
  RSelectOption,
  GeneralBulkEditParams,
  MultiFieldBulkEditParams,
  RecordType,
  BulkFilters,
  SerializedGeneralParams,
  SerializedRecordType,
  Fragment
} from 'bulk/types'

export const serializeJobHistoryParams = (params: HistoryParams) => {
  const { filters, pageSize, ordering, page } = params

  return {
    size: pageSize,
    page: page,
    ordering: ordering.isDesc ? `-${ordering.columnKey}` : ordering.columnKey,
    filters: filters
      ? {
          ...(filters.submitted_at
            ? { submitted_at: DateFilterSerializer.toServer(filters.submitted_at) }
            : {}),
          ...(filters.submitted_by
            ? { submitted_by: MultiSelectFilterSerializer.toServer(filters.submitted_by) }
            : {}),
          ...(filters.record_type
            ? { record_type: MultiSelectFilterSerializer.toServer(filters.record_type) }
            : {}),
          ...(filters.action_type ? { action_type: filters.action_type.map(s => s.value) } : {}),
          ...(filters.option_type ? { option_type: filters.option_type.map(s => s.value) } : {}),
          ...(filters.status ? { status: filters.status.map(s => s.value) } : {})
        }
      : {}
  }
}

export const fromFragment = (hash: string = window.location.hash): Fragment => {
  const {
    fields = [],
    record,
    step,
    filters,
    category,
    activeJob,
    attrType,
    preSelected,
    templateType
  } = qs.parse(hash) as {
    record?: string
    step?: string | number
    filters?: string[] | string
    activeTab?: string
    activeJob?: string
    attrType?: string
    preSelected?: string
    templateType?: string
    fields?: string[] | string
    category?: string
  }

  return {
    ...(record
      ? {
          record: {
            label: record.split('::')[1],
            value: record.split('::')[0]
          }
        }
      : {}),
    ...(templateType
      ? {
          templateType: {
            label: templateType.split('::')[1],
            value: Number(templateType.split('::')[0])
          }
        }
      : {}),
    step: Number(step),
    fields: fields
      ? Array.isArray(fields)
        ? fields.map(field => {
            const [value, label, type] = field.split('::')
            return { label, value, isSelected: true, type }
          })
        : [
            {
              type: fields?.split('::')[2],
              label: fields?.split('::')[1],
              value: fields?.split('::')[0],
              isSelected: true
            }
          ]
      : [],
    filters: filters
      ? Array.isArray(filters)
        ? filters.map(filter => {
            const [value, label, type] = filter.split('::')
            return { label, value, isSelected: true, type }
          })
        : [
            {
              type: filters?.split('::')[2],
              label: filters?.split('::')[1],
              value: filters?.split('::')[0],
              isSelected: true
            }
          ]
      : [],
    category: category,
    ...(attrType
      ? {
          attrType: {
            label: attrType.split('::')[1],
            value: attrType.split('::')[0]
          }
        }
      : {}),
    preSelected: preSelected ? preSelected.split(',') : [],
    activeJob: activeJob
  }
}

export const reorderFields = (
  fields: Field[] = [],
  recType: string | number | undefined,
  ignorePreselected = false,
  hash: string = window.location.hash
) => {
  // ORDER: default/pre-selected, percentage, general, custom
  // >>> Prioritize pre-selected fields <<<

  if (!fields.length) return []

  const orderedFields = []
  const { preSelected } = qs.parse(hash) as { preSelected: string }
  const preSelectedFieldNames = ignorePreselected ? [] : preSelected ? preSelected.split(',') : []

  //prioritising matter approver levels over other custom fields
  if (recType === 'matter_approvers') {
    const additionalPreselectedFields = fields
      .filter(f => f?.column_name?.startsWith('approval_level'))
      .map(f => f?.column_name)
    preSelectedFieldNames.push(...(additionalPreselectedFields as string[]))
  }

  const defaultFields = fields.filter(f => [f.field_type, f.type].includes('default'))
  !ignorePreselected && orderedFields.push(...sortObjectsList(defaultFields))

  //preparing general fields' tail and head arrays
  const generalFields = fields.filter(
    f =>
      ([f.field_type, f.type].includes('general') ||
        (ignorePreselected && [f.field_type, f.type].includes('default'))) &&
      ![f.column_name, f.value].includes('percentage')
  )
  const leadingGeneralFields = getInclusiveFields(generalFields, preSelectedFieldNames)
  const trailingGeneralFields = getNonInclusiveFields(generalFields, preSelectedFieldNames)

  //preparing custom fields' tail and head arrays
  const customFields = fields.filter(f => [f.field_type, f.type].includes('custom'))
  const leadingCustomFields = getInclusiveFields(customFields, preSelectedFieldNames)
  const trailingCustomFields = getNonInclusiveFields(customFields, preSelectedFieldNames)

  //preparing percentage field
  const percentageField = fields.find(f => [f.column_name, f.value].includes('percentage'))

  //ordering list
  orderedFields.push(...sortObjectsList(leadingGeneralFields))
  orderedFields.push(...sortObjectsList(leadingCustomFields))
  percentageField && orderedFields.push(percentageField)
  orderedFields.push(...sortObjectsList(trailingGeneralFields))
  orderedFields.push(...sortObjectsList(trailingCustomFields))

  return orderedFields
}

export const toFragment = ({
  record,
  tableFields = [],
  step,
  filters = [],
  activeTab,
  attrType,
  preSelected = [],
  templateType
}: BulkParams) => {
  const reorderedFilters = reorderFields(filters, record?.value)

  const filtersFragment = reorderedFilters.length
    ? `&${BulkMultiSelectFilterSerializer.toFragment(
        'filters',
        reorderedFilters.filter(x => x.isSelected)
      )}`
    : ''

  const fieldsFragment = tableFields.length
    ? `&${BulkMultiSelectFilterSerializer.toFragment(
        'fields',
        tableFields.filter(x => x.isSelected)
      )}`
    : ''

  const recordFragment = record
    ? `&${SingleSelectFilterSerializer.toFragment('record', record)}`
    : ''

  const templateFragment = templateType
    ? `&${SingleSelectFilterSerializer.toFragment('templateType', templateType)}`
    : ''

  const attrTypeFragment = attrType
    ? `&${SingleSelectFilterSerializer.toFragment('attrType', attrType)}`
    : ''

  const stepFragment = step ? `&step=${step}` : ''

  const preSelectedFragment = preSelected.length ? `&preSelected=${preSelected.join(',')}` : ''

  const paramStr = `#category=${activeTab}${stepFragment}${recordFragment}${templateFragment}${attrTypeFragment}${fieldsFragment}${filtersFragment}${preSelectedFragment}`

  window.history.replaceState('', '', paramStr)
}

export const toUsers = (options: UserOption[]) => {
  return options.map(o => ({
    value: o.user_id,
    label: o.full_name,
    email: o.primary_email
  }))
}

export const toReactSelect = (options: RSelectOption[]) => {
  return options.map(o => ({
    value: o.id,
    label: o.text
  }))
}

const serializeMultiFieldParams = (formFields: MultiFieldBulkEditParams) => {
  const reqBody = []
  if ('add' in formFields) {
    const option = formFields['add']
    reqBody.push({
      op: 'add',
      value: option['add'].map(opt => opt.value),
      ...(option.level ? { level: option.level.value } : {}),
      ...(option.clause ? { clause: option.clause.value } : {})
    })
  }
  if ('delete' in formFields) {
    const option = formFields['delete']
    reqBody.push({
      op: 'delete',
      value: option['delete'].map(opt => opt.value)
    })
  }
  if ('replace' in formFields) {
    const option = formFields['replace']
    reqBody.push({
      op: 'replace',
      value: [option['old'].value, option['new'].map(opt => opt.value)]
    })
  }
  return reqBody
}

const serializeGeneralParams = (formFields: GeneralBulkEditParams[]) => {
  return Object.entries(formFields).reduce(
    (params: SerializedGeneralParams[], [key, { dataType, value }]) => {
      if (dataType === 'currency') {
        params.push({
          field: key,
          value: [value.amount, value.code]
        })
      } else {
        params.push({
          field: key,
          value
        })
      }
      return params
    },
    []
  )
}

export const serializeFilters = (filters: BulkFilters, caType?: string) => {
  const serialized: {
    field: string
    condition: { op: string }
    value1?: unknown
    value2?: unknown
    currency_code?: string
  }[] = []

  const fields = filters ? Object.keys(filters) : []

  fields.forEach(field => {
    if (filters[field]) {
      serialized.push({
        field: field,
        condition: {
          op: Array.isArray(filters[field]?.val1)
            ? filters[field].field === 'is'
              ? 'in'
              : 'not in'
            : filters[field].field,
          ...(filters[field]?.type === 'date' ||
          (caType === 'date' && moment(filters[field].val1 as Date).isValid())
            ? {
                value1: moment
                  .utc(filters[field].val1 as Date)
                  .toDate()
                  .toISOString(),
                ...(filters[field].field === 'between' && filters[field]?.val2
                  ? {
                      value2: moment
                        .utc(filters[field].val2 as Date)
                        .toDate()
                        .toISOString()
                    }
                  : {})
              }
            : Array.isArray(filters[field]?.val1)
            ? { value1: (filters[field].val1 as Option[]).map(v => v.value) }
            : caType === 'currency'
            ? {
                ...(filters[field]?.val1
                  ? { value1: parseFloat(filters[field]?.val1 as string) }
                  : {}),
                ...(filters[field].field === 'between'
                  ? { value2: parseFloat(filters[field]?.val2 as string) }
                  : {}),
                ...(filters[field]?.val3
                  ? { currency_code: (filters[field].val3 as Option).value }
                  : {})
              }
            : { value1: (filters[field]?.val1 as Option)?.value || filters[field]?.val1 })
        }
      })
    }
  })

  return serialized
}

export const serializeBulkFormData = (
  bulkFieldValues: MultiFieldBulkEditParams | GeneralBulkEditParams[],
  action_type: string,
  record_type: string,
  record_ids: number[],
  globalSelection: boolean,
  filters: BulkFilters,
  excluded_ids: number[],
  backup_enabled: boolean,
  columns: Record<string, unknown>[],
  ca_type: string,
  template_id: number
) => {
  const params = MULTI_FIELD_RECORD_TYPES.includes(record_type)
    ? serializeMultiFieldParams(bulkFieldValues as MultiFieldBulkEditParams)
    : serializeGeneralParams(bulkFieldValues as GeneralBulkEditParams[])

  return {
    record_type,
    action_type,
    ...(ca_type ? { ca_type } : {}),
    ...(template_id ? { template_id } : {}),
    job_data: {
      all: globalSelection && !Object.keys(filters).length,
      backup_enabled: backup_enabled,
      ...(globalSelection ? { excluded_ids } : { record_ids }),
      filters: serializeFilters(filters, ca_type),
      record_fields: columns.map(col => col.columnKey),
      update: params
    }
  }
}

export const serializeDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const serializeJobParams = (params: {
  pageSize: number
  ordering: { columnKey: string; isDesc: boolean }
  page: number
  filters: BulkFilters
}) => {
  const { pageSize, ordering, page, filters } = params
  const serializedFilter = serializeFilters(filters)
  return {
    size: pageSize,
    page: page,
    ordering: ordering.isDesc ? `-${ordering.columnKey}` : ordering.columnKey,
    filters: serializedFilter
  }
}

//@ts-expect-error
const fromTeam = ({ name, id, description, member_count, contact, member_contacts }) => ({
  name,
  id,
  description,
  memberCount: member_count,
  contact: contact ? fromContact(contact) : null,
  members: fromContacts(member_contacts)
})

//@ts-expect-error
export const toTeamSelectUsers = options => {
  return options
    .map(fromTeam)
    .map((o: { id: number; contact?: { fullName: string }; name: string }) => ({
      ...o,
      value: o.id,
      label: o.contact ? o.contact.fullName : o.name
    }))
}

export const serializeHistoryResults = (results: { status: string; total_count: number }[]) =>
  results.map(resultObj => ({
    ...resultObj,
    ...(resultObj.status === 'submitted' && resultObj.total_count === 0
      ? { total_count: null }
      : {})
  }))

export const serializeBulkFieldList = (response: { field: string }[]) => {
  return response.map(f => (f.field === 'matter_short_name' ? { ...f, type: 'text' } : f))
}

export const removeNoneOption = (options: Option[]) => {
  return options.filter(o => o.text !== 'None' && o.label !== 'None')
}

export const toFilteredReactSelect = (options: Option[]) => {
  return removeNoneOption(options).map(o => ({
    value: o.id,
    label: o.text
  }))
}

export const filterOptions = (options: Option[], field: string) => {
  return field === 'matter_status' ? options.filter(o => o.value !== 'draft') : options
}

export const serializeImportRecordType = (recordType: RecordType) => {
  return Object.entries(recordType).reduce((serialized: SerializedRecordType[], [key, value]) => {
    if (value['import_record_type']) {
      if (!window.credentials?.user?.isCSM && !value['is_only_csm']) {
        serialized.push({
          value: key,
          label: value['display_name'],
          action: value['action']
        })
      } else if (window.credentials?.user?.isCSM) {
        serialized.push({
          value: key,
          label: value['display_name'],
          action: value['action']
        })
      }
    }
    return serialized
  }, [])
}

export const serializeBuilderRecordType = (recordType: RecordType) => {
  return Object.entries(recordType).reduce((serialized: SerializedRecordType[], [key, value]) => {
    if (value['builder_record_type']) {
      serialized.push({
        value: key,
        label: value['display_name'],
        action: value['action']
      })
    }
    return serialized
  }, [])
}

export const serializeAllRecordType = (recordType: RecordType) => {
  return Object.entries(recordType).reduce((serialized: SerializedRecordType[], [key, value]) => {
    serialized.push({
      value: key,
      label: value['display_name'],
      action: value['action']
    })
    return serialized
  }, [])
}

export const serializeImportParams = (
  actionType: Option,
  recordType: Option,
  templateType?: Option,
  fileId?: string | number,
  mapping?: Record<string, string>
) => {
  const importKey = recordType.value === CA_LIST_VALUES ? 'attribute_id' : 'template_id'
  return {
    action_type: actionType.value,
    record_type: recordType.value,
    ...(templateType ? { [importKey]: templateType.value } : {}),
    ...(fileId ? { file_id: fileId } : {}),
    ...(mapping ? { mapping } : {})
  }
}
