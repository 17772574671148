import { BulkEditValues } from './types'

export const OPERATORS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  IS_SET: 'IS_SET',
  IS_NOT_SET: 'IS_NOT_SET',
  IS_BEFORE: 'IS_BEFORE',
  IS_AFTER: 'IS_AFTER',
  IS_BETWEEN: 'IS_BETWEEN',
  WITH_COMMENTS: 'WITH_COMMENTS',
  WITHOUT_COMMENTS: 'WITHOUT_COMMENTS',
  WITH_ATTACHMENTS: 'WITH_ATTACHMENTS',
  WITHOUT_ATTACHMENTS: 'WITHOUT_ATTACHMENTS'
}

export const TASK_OPTIONS_ENDPOINTS = {
  PRIORITIES: '/task-management/task-priorities/?includeClientSettings',
  STATUSES: '/task-management/task-status-types/?includeClientSettings',
  TASK_TYPES: '/task-management/task-types/'
}

export const BULK_EDIT_INITIAL_VALUES: BulkEditValues = {
  assigneesToAdd: [],
  assigneesToRemove: [],
  followersToAdd: [],
  followersToRemove: [],
  priority: null,
  status: null,
  taskType: null,
  dueDate: undefined
}
