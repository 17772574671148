import classNames from 'classnames'
import { HoverAction, If } from 'simple-core-ui'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import ScopeContext from 'context/ScopeContext'

import ReviewStatus from './ReviewStatus'
import ReviewEntry from './ReviewEntry'
import ReviewEntryMembership from './ReviewEntryMembership'

import { extractEntry } from '../utils/extractEntry'
import { patchEntryActions } from '../utils/patchEntryActions'

import s from '../styles/ReviewLadder.scss'

const ReviewEntryGroup = ({
  status,
  isPartiallyApproved,
  membership,
  reviewedByOn,
  isExpanded,
  isCurrent,
  toggleGroup,
  group,
  entryActionHandlers
}) => (
  <section
    className={classNames(s.reviewEntryGroup, {
      [s.isExpanded]: isExpanded,
      [s.current]: isCurrent
    })}
  >
    <section className={s.reviewEntry}>
      <ReviewStatus status={status} isPartiallyApproved={isPartiallyApproved} />
      <ReviewEntryMembership name={membership} date={reviewedByOn} />
      <span className={s.toggle}>
        <HoverAction
          testid="expand_reviewers"
          onClick={toggleGroup}
          tip={isExpanded ? 'Collapse' : 'Expand'}
          icon={isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          size="xsmall"
        />
      </span>
    </section>
    <If condition={isExpanded}>
      <section className={s.reviewEntries}>
        <ScopeContext.Consumer>
          {({ scope }) =>
            group.map((nestedEntry, idx) => (
              <ReviewEntry
                key={idx}
                {...extractEntry(nestedEntry, scope)}
                {...patchEntryActions(entryActionHandlers, nestedEntry)}
                nested
              />
            ))
          }
        </ScopeContext.Consumer>
      </section>
    </If>
  </section>
)

export const CollapseIcon = () => <IoIosArrowUp data-testid="collapseIcon" />
export const ExpandIcon = () => <IoIosArrowDown data-testid="expandIcon" />

export default ReviewEntryGroup
