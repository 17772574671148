import { CSSProperties } from 'react'

export const CANCEL_BTN_STYLE: CSSProperties = {
  padding: '8px 16px',
  width: '120px'
}

export const SAVE_BTN_STYLE = {
  width: '180px',
  padding: '8px 16px',
  border: 'none',
  borderRadius: '4px'
}
