import s from './HistoryList.scss'
import moment from 'moment'
import { History } from '../../types'

interface Props {
  history: History[]
}

const loggedInUser = window.credentials.user
const isLoggedUser = (author: { id: number; full_name: string }): boolean => {
  return author?.id === +loggedInUser.id
}

const HistoryList = ({ history }: Props) => {
  return (
    <div className={s.list}>
      {history.map((item, index) => {
        return (
          <div key={index} className={s.historyItem}>
            <div className={s.info}>
              <span>
                <b>{isLoggedUser(item.user) ? 'You' : item.user.full_name}</b>{' '}
                <span>{item.description}</span>
              </span>
            </div>
            <div className={s.date}>
              {moment(item.log_date).format(
                moment(item.log_date)
                  .startOf('day')
                  .isSame(moment().startOf('day'), 'day')
                  ? '[Today] hh:mm a'
                  : 'MM/DD/YYYY hh:mm a'
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default HistoryList
