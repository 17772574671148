import React from 'react'
import { connect } from 'react-redux'
import ACT from 'budgets/actions'
import { formatLongDate } from 'utils/formatting'
import StateBanner from '../components/StateBanner'
import RejectionModal from '../components/RejectionModal'
import { BUDGET_STATES } from 'budgets/constants'
import { LoadingSkeleton } from 'simple-core-ui'

@connect(({ budgets, app }) => {
  const { proposalId, budgetDetails, modifiedDate, shouldCheckOnRenewal } = budgets
  let status = budgets.status
  const vendorName = budgetDetails.vendor_name

  if (!proposalId) {
    status = BUDGET_STATES.WAITING
  }

  return {
    proposalId,
    status,
    vendorName,
    modifiedDate,
    shouldCheckOnRenewal,
    isBudgetDetailLoading: app.loading.BUDGET_DETAILS_FETCH
  }
})
class BudgetRequestToolbar extends React.Component {
  state = {
    rejectNote: '',
    showRejectModal: false
  }

  approveProposal = () => {
    this.props.dispatch({
      type: ACT.MAKE_PROPOSAL_ACTION_REQUESTED,
      payload: {
        proposalId: this.props.proposalId,
        action: 'approve'
      }
    })
  }

  changeRejectNote = e => {
    this.setState({
      rejectNote: e.currentTarget.value
    })
  }

  onReject = () => {
    this.setState({
      showRejectModal: false
    })

    this.props.dispatch({
      type: ACT.MAKE_PROPOSAL_ACTION_REQUESTED,
      payload: {
        proposalId: this.props.proposalId,
        action: 'reject',
        rejectNote: this.state.rejectNote
      }
    })
  }

  rejectProposal = () => {
    this.setState({
      showRejectModal: true
    })
  }

  getStateBannerMap = () => {
    const { vendorName, modifiedDate, shouldCheckOnRenewal } = this.props

    let messageMap = {
      [BUDGET_STATES.REJECTED]: {
        bannerText: `Budget proposal from ${vendorName} was rejected. Currently awaiting ${vendorName} to submit a new budget
          proposal.`,
        actionButtons: []
      },
      [BUDGET_STATES.WAITING]: {
        bannerText: `Budget Request was submitted to ${vendorName} on ${formatLongDate(
          modifiedDate
        )}. Currently awaiting ${vendorName} to submit a budget proposal.`,
        actionButtons: []
      },
      [BUDGET_STATES.PROPOSED]: {
        bannerText: `Your action is required. You received this budget proposal from ${vendorName} on
            ${formatLongDate(modifiedDate)}.`,
        actionButtons: [
          <a
            key={'b'}
            data-testid="reject_budget"
            className="btn btn-medium"
            onClick={this.rejectProposal}
          >
            Reject
          </a>,
          <a
            key={'a'}
            data-testid="approve_budget"
            className="btn btn-primary"
            onClick={this.approveProposal}
          >
            Approve
          </a>
        ]
      }
    }

    if (shouldCheckOnRenewal) {
      messageMap[BUDGET_STATES.PROPOSED]['bannerText'] +=
        ' To renew this budget for next year, turn on "Auto Renew".'
    }

    return messageMap
  }

  onClose = () => {
    this.setState({
      showRejectModal: false
    })
  }

  render() {
    const { status, proposalId, vendorName, modifiedDate, isBudgetDetailLoading } = this.props
    let actionButtons, bannerText

    if (isBudgetDetailLoading) {
      return <LoadingSkeleton height={50} width={'100%'} />
    }

    const stateMap = this.getStateBannerMap()

    try {
      actionButtons = stateMap[status].actionButtons
      bannerText = stateMap[status].bannerText
    } catch (e) {
      throw Error('Status of the budget request is invalid')
    }

    return (
      <div>
        <StateBanner bannerText={bannerText} actionButtons={actionButtons} />
        <RejectionModal
          show={this.state.showRejectModal}
          changeRejectNote={this.changeRejectNote}
          onClose={this.onClose}
          onReject={this.onReject}
        />
      </div>
    )
  }
}

export default BudgetRequestToolbar
