import { Modal, Loading } from 'simple-core-ui'

const PreviewLoadingModal = ({ isVisible }) => {
  return (
    <Modal
      title="Preview File"
      size="sm"
      hasCloseIcon={false}
      content={
        <div>
          <div>We are waiting for your file to load </div>{' '}
          <div>
            <Loading />
          </div>
        </div>
      }
      isVisible={isVisible}
    />
  )
}

export default PreviewLoadingModal
