import { Matters, Vendors, Budgets, LegalRequests, Invoices, UsersIcon } from 'simple-core-ui'

const APP_PAGES_MAP = {
  // sign-in page navbar
  login: {
    text: 'Login',
    icon: 'login'
  },

  // main app navbar
  home: {
    text: 'Home',
    icon: 'home'
  },
  matter_request: {
    text: 'Matters',
    icon: 'folder-alt',
    isReact: true
  },
  vendor_list: {
    text: 'Vendors',
    icon: 'vendors'
  },
  vendor_list_uirefresh: {
    text: 'Vendors',
    icon: <Vendors />
  },
  invoice_list: {
    text: 'Invoices',
    icon: 'docs'
  },
  accrual_report_by_month: {
    text: 'Accruals',
    icon: 'clock'
  },
  budget_list: {
    text: 'Budgets',
    icon: 'budgets'
  },
  budget_list_uirefresh: {
    text: 'Budgets',
    icon: <Budgets />
  },
  reports: {
    text: 'Spend Dashboard',
    icon: 'speedometer'
  },
  report_list: {
    text: 'Reports',
    icon: 'chart',
    isReact: true
  },
  calendar: {
    text: 'Calendar',
    icon: 'calendar',
    isReact: true
  },
  tasks: {
    text: 'Tasks',
    icon: 'check',
    isReact: true
  },
  addressbook: {
    text: 'Address Book',
    icon: 'notebook',
    isReact: true
  },
  administration: {
    text: 'Administration',
    icon: 'settings',
    isReact: true
  },
  support: {
    text: 'Support',
    icon: 'wrench',
    isReact: true
  },
  zendesk_help: {
    text: 'Resource Center',
    icon: 'question',
    openInNewTab: true
  },
  // Quick Add New Links
  add_new_legal_request: {
    text: 'New Legal Request',
    icon: <LegalRequests />
  },
  add_new_matter: {
    text: 'New Matter',
    icon: <Matters />
  },
  add_new_vendor: {
    text: 'New Vendor',
    icon: <Vendors />
  },
  add_new_invoice: {
    text: 'New Invoice',
    icon: <Invoices />
  },
  add_new_user: {
    text: 'New User',
    icon: <UsersIcon />
  },
  // dropdown links
  ebill_user_me: {
    text: 'My Profile',
    icon: 'user'
  },
  auth_password_change: {
    text: 'Change Password',
    icon: 'key'
  },
  auth_logout: {
    text: 'Log Out',
    icon: 'power'
  },

  // footer links
  footerLinks: [
    {
      text: 'Privacy Policy',
      title: 'Privacy Policy',
      url: '/privacy-policy/'
    },
    {
      text: 'Security Policy',
      title: 'Security Policy',
      url: '/security-policy/'
    },
    {
      text: 'Terms of Service',
      title: 'Terms of Service',
      url: '/terms-of-service/'
    }
  ],

  // Matters -- landing page
  matter_list: {
    text: 'All Matters',
    description: 'Create and manage all matters.'
  },
  matter_request_list_url: {
    text: 'My Legal Requests',
    description: 'Submit and view status of your legal requests.',
    icon: 'folder-alt'
  },
  matter_requests: {
    text: 'Review Legal Requests',
    description: 'Review and approve submitted legal requests.'
  },

  // Reports -- landing page
  reportbuilder_adhoc: {
    text: 'Adhoc Report Builder',
    description: 'Build and save custom reports with spend or matter data.',
    isFirst: true
  },
  tableau_reports: {
    text: 'Tableau Reports',
    description: 'View a list of reports from Tableau.'
  },
  report_activity_by_assigned_attorney: {
    text: 'Activity by Lead Attorney',
    description:
      'Total monthly legal spend by matter lead / lead attorney summarized by vendor or matter group / practice area.',
    type: 'live'
  },
  accruals_vs_actuals: {
    text: 'Accruals vs. Actuals',
    description: 'Comparison of monthly accruals estimates to actual billed amounts.',
    type: 'live'
  },
  timekeeper_diversity: {
    text: 'Timekeeper Diversity',
    description: 'Comparison of the representation and utilization of diverse timekeepers.',
    type: 'live'
  },
  invoice_adjustments_report: {
    text: 'Invoice Adjustments',
    description: 'List of all invoices with amount billed, discounted, adjusted, and rejected.',
    type: 'live'
  },
  invoice_allocation: {
    text: 'Invoice Allocations',
    description: 'Overview of monthly allocations by invoice.',
    type: 'live'
  },
  report_invoice_list: {
    text: 'Invoice List',
    description: 'Comprehensive list of all invoices, filtered by status.',
    type: 'live'
  },
  report_simplereview: {
    text: 'AI Powered Invoice Review Flagged Report',
    description: 'List of all line items SimpleReview has flagged.',
    type: 'live'
  },
  report_matter_activity: {
    text: 'Matter Activity',
    description: 'Total monthly legal spend grouped and subtotaled by category.',
    type: 'live'
  },
  report_matter_compare_matter_cost: {
    text: 'Matter Cost Comparison',
    description:
      'Comparison of average monthly legal spend by matter, grouped and subtotaled by category.',
    type: 'live'
  },
  matter_inventory: {
    text: 'Matter Inventory',
    description:
      'Overview of open, closed and pending matter counts, and averages by matter lead / lead attorney or matter group / practice area.',
    type: 'live'
  },
  report_matter_file_sharing_history: {
    text: 'Matter File Sharing History',
    description:
      'A list of matter files that have been shared along with the CounselGO users who have downloaded them.',
    type: 'live'
  },
  report_general_file_sharing_history: {
    text: 'Vendor File Sharing History',
    description:
      'A list of vendor files that have been shared along with the CounselGO users who have downloaded them.',
    type: 'live'
  },
  report_matter_overview: {
    text: 'Matter Overview',
    description: 'List of all matters with hours, average rates and spend to date.',
    type: 'live'
  },
  report_spend_by_month: {
    text: 'Monthly Spend',
    description: 'High-level overview of spend by month.',
    type: 'live'
  },
  report_matter_compare_rates: {
    text: 'Rate Comparison',
    description: 'Average monthly realized rates by matter, grouped and subtotaled by category.',
    type: 'live'
  },
  report_matter_rows_columns: {
    text: 'Task Code Comparison',
    description:
      'Matter-level comparison of average monthly legal spend by task code grouped and subtotaled by category.',
    type: 'live'
  },
  report_wip_by_month: {
    text: 'Timekeeper Activity',
    description: 'Total hours and rates by timekeeper, grouped by category.',
    type: 'live'
  },
  custom_report_exports: {
    text: 'Custom Report Exports',
    description: 'List of your previously processed custom reports with direct download links.'
  },
  export_csv_report_list: {
    text: 'Custom & List Reports',
    description: 'Links to standard list reports and custom reports for download.',
    isLast: true
  },
  // Administration -- landing page
  costcode_management: {
    text: 'Cost Codes',
    description: 'Create and list accounting cost codes / GL accounts.',
    isDynamic: true
  },
  client_key_management: {
    text: 'Client Keys',
    description: 'Create and manage client keys.'
  },
  matter_attribute_management: {
    text: 'Custom Attributes',
    description:
      'Create and manage custom attributes for matters, legal entities, purchase orders, invoices and vendors.'
  },
  legal_entity_management: {
    text: 'Entities',
    description: 'Create, manage and list legal entities.',
    isDynamic: true
  },
  templates: {
    text: 'Matter Templates',
    description: 'Create, manage and list templates used for matter creation.'
  },
  matter_settings: {
    text: 'Matter Settings',
    description: 'Manage matter settings.'
  },
  matter_group_management: {
    text: 'Matter Groups',
    description: 'Create and manage matter groups / practice areas.',
    isDynamic: true
  },
  po_list: {
    text: 'Purchase Orders',
    description: 'Create, manage and track purchase orders.'
  },
  matter_request_forms: {
    text: 'Legal Request Forms',
    description: 'Create and manage legal request forms.'
  },
  custom_report_management: {
    text: 'Custom Report Management ',
    description: 'Toggle display settings for reports in V2 UI.'
  },
  document_categories: {
    text: 'Document Settings',
    description: 'Manage document management settings.'
  },
  tableau_settings: {
    text: 'Tableau Report Settings',
    description: ' Manage Tableau reporting users and permissions'
  },

  tax_authority_list: {
    text: 'Tax Authorities',
    description: 'Create and manage tax authorities, tax rates, tax codes and tax accounts.'
  },
  task_management: {
    text: 'Tasks',
    description: 'Create and manage task statuses, priorities, types and templates.'
  },
  timekeeper_list: {
    text: 'Timekeepers',
    description: 'Review, approve and manage timekeeper rates per vendor.'
  },
  manage_teams: {
    text: 'User Teams',
    description: 'Manage user teams and permissions.'
  },
  default_permissions: {
    text: 'Default Permissions',
    description: 'Manage default permissions.'
  },
  // additions to Administration landing page
  budget_request_setting: {
    text: 'Budget Refresh Settings',
    description: 'Refresh cycle for budgets'
  },
  accrual_settings: {
    text: 'Accrual Settings',
    description: 'Manage accrual notifications and last day to submit for vendors.'
  },
  ebill_logbook: {
    text: 'Activity Log',
    description: 'View, search and audit activity logbook of user and system user activities.'
  },
  advanced_settings: {
    text: 'Advanced Settings*',
    icon: 'settings',
    description: 'Advanced configuration settings (SimpleLegal support only).'
  },
  custom_labels: {
    text: 'Custom Labels',
    description:
      'Custom Labels for SimpleLegal standard attributes (cost code / GL account, legal entity, matter group / practice area).'
  },
  view_payment_list: {
    text: 'Invoice Payments',
    description: 'View and search invoice payments log information posted via flat file or API.'
  },
  view_invoice_settings: {
    text: 'Invoice Settings',
    description: 'Manage invoice settings.'
  },
  rule_management: {
    text: 'Rule Management',
    description:
      'Create and manage rules (e.g., invoice reviews (approval workflow), intelligent invoice workflow (IIW), billing guidelines, matter reviews).'
  },
  task_guidelines_list: {
    text: 'Invoice Task Code Validation*',
    description: 'Create and manage invoice task code validations (SimpleLegal support only).'
  },
  upload_files: {
    text: 'Invoice Upload',
    description: 'Upload basic 1 line item invoice and attach invoice PDF.'
  },
  incoming_ledes_file_internal: {
    text: 'Invoice Upload (internal)*',
    description: 'Upload or create LEDES invoices (SimpleLegal support only).'
  },
  matter_upload: {
    text: 'Matter Upload',
    description: 'Bulk upload matter data.'
  },
  bulk_dashboard: {
    text: 'Bulk Upload',
    description: 'Make batch changes to client data. See Bulk operation history. Manage Bulk jobs.'
  },
  bulk_dashboard_v2: {
    text: 'Bulk Actions',
    description: 'Bulk update records via CSV upload or with the UI editor.'
  },
  ebill_company_details: {
    text: 'User Management',
    description: 'Create and manage SimpleLegal users.'
  },
  rule_list: {
    text: 'Invoice Approval Workflow',
    description:
      'Create and manage invoice approval workflow rules, escalations and approval authority limits.'
  },
  escalation_management: {
    text: 'Escalation Management',
    description: 'Create and manage escalations and approval authority limits.'
  },
  event_management: {
    text: 'Events',
    description: 'Create and manage event integrations, calendar, types and court rules.'
  },
  external_calendar_sync: {
    text: 'External Calendar Sync',
    description:
      'Manage external calendar synchronization to Apple Calendar, Google Calendar, Microsoft Outlook, and other calendar applications.'
  },
  leguard_time_tracking: {
    text: 'LeGuard Time Tracking',
    description: 'Manage LeGuard API integration configuration and threshold settings'
  },
  ap_attachments_view: {
    text: 'AP Exports Attachments',
    description: 'Retrieve attachments from previously run AP Export jobs.'
  },
  incoming_job_results: {
    text: 'Incoming Job Results*',
    description: 'View summary and detailed results of Incoming Jobs (SimpleLegal support only).'
  },
  api_incoming_job_results: {
    text: 'API Incoming Job Results*',
    description:
      'View summary and detailed results of Incoming Jobs via API (SimpleLegal support only).'
  },
  // Support (internal-only) -- landing page
  'admin-console-main': {
    text: 'Admin Console',
    description: '--'
  },
  incoming_ledes_file: {
    text: 'Load Invoice',
    description: '--'
  },
  'admin-console-invoices': {
    text: 'Invoice List',
    description: '--'
  },
  'admin-console-invoice-submission-list': {
    text: 'Invoice Submission',
    description: '--'
  },
  'admin-console-mail-incoming': {
    text: 'Inbox',
    description: '--'
  },
  tracked_email_list: {
    text: 'Email Tracker',
    description: '--'
  }
}

export default APP_PAGES_MAP
