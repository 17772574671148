import s from './UpdateProgress.scss'
import ChartInfo from './ChartInfo/ChartInfo'
import { Spinner } from 'simple-core-ui'

const getPercentageOnException = (total, status) => (status === 'success' && !total ? '100' : total)

const displaySpinner = () => (
  <div className={s.preProcess}>
    <Spinner className={s.spinner} style={{ margin: 0 }} />
    <p className={s.messageText}>{'Saving backup file'}</p>
  </div>
)

const displayChart = (percent, total, status, error) => (
  <div className={s.chart}>
    <div className={s.percentage}>
      {total
        ? `${Math.round((100 * percent) / total)}%`
        : `${getPercentageOnException(total, status)}%`}
    </div>
    <div
      className={s.segment}
      style={{
        width: `${total ? (100 * percent) / total : getPercentageOnException(total, status)}%`,
        ...(status === 'failed' ? { background: '#DC143C', width: '100%' } : {})
      }}
    />
    <ChartInfo status={status} total={total} error={error} />
  </div>
)

const UpdateProgress = ({ percent, total, status, error, backupEnabled }) => {
  return backupEnabled && status === 'submitted'
    ? displaySpinner()
    : displayChart(percent, total, status, error)
}

export default UpdateProgress
