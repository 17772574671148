import cn from 'classnames'
import { FileUpload } from 'common/FileUpload'
import { Subtasks } from 'common/Subtasks'
import { Widget } from 'common/Widget'
import moment from 'moment'
import qs from 'query-string'
import { useEffect, useMemo, useRef, useState, Dispatch, SetStateAction } from 'react'
import { BsEye, BsPersonPlus } from 'react-icons/bs'
import { FaAngleLeft, FaRegCalendarCheck } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import {
  AvatarList,
  Button,
  Ellipsis,
  Markdown,
  Tab,
  Tabs,
  useLoading,
  useScrollLock,
  KeyValuePairs,
  PaginatedSelect,
  CharLimitInput,
  RichTextEditor,
  Users,
  DueDatePicker
} from 'simple-core-ui'
import { Updater } from 'use-immer'
import { makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest } from 'utils/api'
import { isBasicTaskManagementPlan, openLink, sortAlphabeticallyByProperty } from 'utils/helpers'
import { TASK_OPTIONS_ENDPOINTS } from '../constants'
import {
  fromAttachments,
  toPrioritiesOptions,
  toStatusesOptions,
  toTasks,
  toTaskTypesOptions,
  toMattersOptions
} from '../serializers'
import {
  APIAttachment,
  Attachment,
  Comment,
  CustomizedOption,
  DateFormat,
  History,
  InitialTask,
  LocalTask,
  Option,
  MatterOption,
  Subtask,
  Task,
  APISimpleMatter
} from '../types'
import { canEditTask } from '../utils'
import s from './AddTask.scss'
import { Comments } from './Comments'
import { CommentsList } from './CommentsList'
import { BASIC_TIER_TOTAL_ALLOWED, MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from './constants'
import { DeleteConfirmation } from './DeleteConfirmation'
import { HistoryList } from './HistoryList'
import { Overview } from './Overview'
import { toAPIAttachments, toLocalTask, toTask } from './serializers'
import { SetCompleted, Widgets } from './Widgets'
import { StylesConfig, OptionProps, components } from 'react-select'
import ReactTooltip from 'react-tooltip'

const CustomOption = (props: OptionProps<MatterOption>) => {
  return (
    <components.Option {...props}>
      <div className={s.option}>
        <div className={cn(s.header, s.displayBelow)}>
          <div className={s.optionTitle}>
            <Ellipsis width={280} lines={1}>
              {props.data.label}
            </Ellipsis>
          </div>
          <p className={s.clientMatterId}>{props.data.clientMatterId}</p>
        </div>
      </div>
    </components.Option>
  )
}

const customStyle: StylesConfig<MatterOption, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menu: provided => ({
    ...provided,
    width: '500px'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#3c99fd'
  })
}

interface Props {
  toggleAddTaskSidebar: () => void
  tasks: { id: number; name: string }[]
  scopeName: string
  task: Task | null
  oldTask: Task | null
  saveTask: (
    task: Task,
    isEdit?: boolean,
    callback?: (task?: Task) => void,
    shouldRefreshTable?: boolean
  ) => void
  onDeleteTask: (id: number) => void
  setTasks: Updater<Task[]>
  scopeId: string
  onEditSubtask: (subtask: Subtask) => void
  duplicateTask: (subtask: Task | Subtask, parentId?: number | string) => Promise<void>
  fetchTasks: () => void
  readOnly: boolean
  context: 'matter' | 'workbench'
  setScopeId: Dispatch<SetStateAction<string>>
  refreshTable: () => void
  openedTab?: string
}

const initialTask: InitialTask = {
  name: '',
  description: '',
  priority: null,
  status: null,
  taskType: null,
  isPrivate: false,
  dueDate: undefined,
  assignees: [],
  followers: [],
  subtasks: [],
  fileAttachments: [],
  parent: null,
  createdDate: '',
  createdBy: null
}

const isBasicPlan = isBasicTaskManagementPlan()

const AddTask = ({
  toggleAddTaskSidebar,
  tasks,
  scopeName,
  task: editedTask,
  oldTask,
  saveTask,
  onDeleteTask,
  setTasks,
  scopeId,
  onEditSubtask,
  duplicateTask,
  fetchTasks,
  readOnly,
  context,
  setScopeId,
  refreshTable,
  openedTab
}: Props) => {
  const [task, setTask] = useState(toLocalTask(editedTask) || initialTask)
  const [, withLoadingLocks] = useLoading()
  const [priorities, setPriorities] = useState<CustomizedOption[]>([])
  const [statuses, setStatuses] = useState<(CustomizedOption & { phase?: string })[]>([])
  const [taskTypes, setTaskTypes] = useState<CustomizedOption[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [attachmentToDeleteId, setAttachmentToDeleteId] = useState<number | string>('')
  const [prevTask, setPrevTask] = useState<LocalTask | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const dispatch = useDispatch()
  const [editedProperties, setEditedProperties] = useState<Partial<LocalTask>>({})
  const [comments, setComments] = useState<Comment[]>([])
  const [history, setHistory] = useState<History[]>([])
  const [activeTab, setActiveTab] = useState(openedTab || 'overview')
  const [prevActiveTab, setPrevActiveTab] = useState('')
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false)
  const { lockScroll, unlockScroll } = useScrollLock()
  const [nameInputFocused, setNameInputFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const _scopeId = scopeId || (task?.relatedMatter?.value ?? '')

  const isReadOnly = useMemo(() => {
    return readOnly || !canEditTask(task, context)
  }, [context, readOnly, task])

  const getTabIndex = () => {
    switch (activeTab) {
      case 'overview':
        return 0
      case 'files':
        return task.parent ? 1 : 2
      case 'comments':
        return task.parent ? 2 : 3
      default:
        return null
    }
  }

  const fetchHistory = async () => {
    const history = await withLoadingLocks(
      makeGetRequest(`/task-management/tasks/${editedTask?.id}/history/`)
    )
    setHistory(history)
  }

  if (activeTab && prevActiveTab !== activeTab) {
    setPrevActiveTab(activeTab)
    if (activeTab === 'history') fetchHistory()
  }

  const selectOptions = useMemo(() => {
    return {
      priorities: priorities.filter(priority => priority.isActive),
      statuses: statuses.filter(status => status.isActive),
      taskTypes: taskTypes.filter(taskType => taskType.isActive)
    }
  }, [priorities, statuses, taskTypes])

  const resetEditedProperties = () => {
    setEditedProperties({
      id: editedTask?.id
    })
  }

  if (
    editedTask &&
    (prevTask?.id !== editedTask.id || prevTask.subtasks.length !== editedTask.subtasks.length)
  ) {
    setTask(toLocalTask(editedTask) as LocalTask)
    setPrevTask(toLocalTask(editedTask))
    if (editedTask.id) {
      setIsEdit(true)
      resetEditedProperties()
    }
  }

  useEffect(() => {
    // remove id hash from url when sidebar is closed
    return () => {
      if (!isEdit) return
      const parsedQuery = qs.parse(window.location.search)
      const parsedHash = qs.parse(window.location.hash, { decode: false })
      delete parsedQuery.id
      const queryString = qs.stringify(parsedQuery)
      const hashString = qs.stringify(parsedHash, { encode: false })
      window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
    }
  }, [isEdit])

  useEffect(() => {
    lockScroll()
    return () => {
      unlockScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchOptions() {
      try {
        const priorities = await withLoadingLocks(makeGetRequest(TASK_OPTIONS_ENDPOINTS.PRIORITIES))
        setPriorities(toPrioritiesOptions(priorities))

        const statuses = await withLoadingLocks(makeGetRequest(TASK_OPTIONS_ENDPOINTS.STATUSES))
        setStatuses(toStatusesOptions(statuses))

        const { rows: taskTypes } = await withLoadingLocks(
          makeGetRequest(TASK_OPTIONS_ENDPOINTS.TASK_TYPES)
        )
        setTaskTypes(toTaskTypesOptions(taskTypes))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching options:', error)
      }
    }

    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchOptions() {
      if (!editedTask?.id) return
      const { rows: comments } = await withLoadingLocks(
        makeGetRequest(`/task-management/matters/${_scopeId}/tasks/${editedTask.id}/comments/`)
      )
      setComments(comments)
    }

    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedTask])

  const updateTask = (
    value:
      | string
      | null
      | Option
      | Option[]
      | boolean
      | Date
      | undefined
      | Subtask[]
      | APIAttachment[],
    property: string
  ) => {
    setTask({ ...task, [property]: value })
    if (isEdit) {
      setEditedProperties({
        id: editedTask?.id,
        [property]: value
      })
    }
  }

  const isValid = (): boolean => {
    if (context === 'workbench') {
      return (
        task.name[0] !== ' ' &&
        !!task.name.trim() &&
        !!task.relatedMatter &&
        task.name.length <= MAX_NAME_LENGTH
      )
    }
    return (
      task.name[0] !== ' ' &&
      !!task.name.trim() &&
      !isDescriptionTooLong &&
      task.name.length <= MAX_NAME_LENGTH
    )
  }

  const getUsersLabel = (prop: 'assignees' | 'followers'): string | JSX.Element => {
    switch (task[prop]?.length) {
      case 0:
        return ''
      case 1:
        return task[prop][0].label
      default:
        return (
          <AvatarList
            limit={3}
            size="xs"
            wrapperStyles={{ width: 24 }}
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(task[prop], 'label')}
          />
        )
    }
  }

  const getUsersIcon = (prop: 'assignees' | 'followers'): JSX.Element => {
    if (task[prop].length === 1) {
      return (
        <AvatarList
          size="md"
          wrapperStyles={{
            width: 36,
            position: 'relative',
            bottom: 2,
            right: 2
          }}
          className={s.avatar}
          entries={[task[prop][0]]}
          avatarStyles={{ border: '2px solid #0957ae', lineHeight: '30px' }}
        />
      )
    }

    return prop === 'assignees' ? <BsPersonPlus /> : <BsEye />
  }

  const deleteAttachment = async () => {
    if (isEdit) {
      await withLoadingLocks(
        makeDeleteRequest(
          `/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/attachments/${attachmentToDeleteId}/`
        )
      )

      const index = tasks.findIndex(t => t.id === editedTask?.id)

      if (~index) {
        setTasks(draft => {
          draft[index].fileAttachmentsCount--
        })
      }
    }

    setTask({
      ...task,
      fileAttachments: task.fileAttachments.filter(
        attachment => attachment.id !== attachmentToDeleteId
      )
    })
    setShowDeleteConfirmation(false)

    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: `Task ${task.name} successfully edited.`,
        level: 'success'
      }
    })
  }

  const showDeleteModal = (id: number | string) => {
    setShowDeleteConfirmation(true)
    setAttachmentToDeleteId(id)
  }

  const toggleCompleted = async (toRevert: boolean) => {
    let status
    if (toRevert) {
      const response = await withLoadingLocks(
        makePostRequest(
          `/task-management/tasks/${editedTask?.id}/statuses/set-previous-status/`,
          {}
        )
      )
      status = response === null ? null : toStatusesOptions([response])[0]
    } else {
      status = statuses.find(s => s.phase === 'Complete')
    }
    updateTask(status, 'status')
    saveTask(
      toTask({
        ...(isEdit
          ? {
              id: editedProperties.id
            }
          : editedProperties),
        status
      } as LocalTask),
      isEdit,
      () => {
        resetEditedProperties()
        if (activeTab === 'history') {
          fetchHistory()
        }
      }
    )
  }

  const addFiles = async (files: APIAttachment[]) => {
    const response = await withLoadingLocks(
      makePostRequest(`/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/attachments/`, {
        files: files.length ? fromAttachments(files) : null
      })
    )

    const existingNames = response.attachments.map((a: APIAttachment) => a.file_name)
    const newFiles = task.fileAttachments.filter(a => !existingNames.includes(a.file_name))
    updateTask([...newFiles, ...response.attachments], 'fileAttachments')

    const index = tasks.findIndex(t => t.id === editedTask?.id)

    if (~index) {
      setTasks((draft: Task[]) => {
        draft[index].fileAttachmentsCount = [...newFiles, ...response.attachments].length
        if (editedTask) {
          draft[index].status = editedTask.status
        }
      })
    }
  }

  const addAssignees = async (values: Option[]) => {
    try {
      const task = await withLoadingLocks(
        makePostRequest(`/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/assignees/`, {
          user_ids: values.map(v => v.value)
        })
      )

      const index = tasks.findIndex(t => t.id === task.id)

      if (~index) {
        setTasks((draft: Task[]) => {
          draft[index].assignees = toTasks([task])[0].assignees
          return draft
        })
      }

      refreshTable()

      if (activeTab === 'history') {
        fetchHistory()
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Task ${task.name} successfully edited.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const addFollowers = async (values: Option[]) => {
    try {
      const task = await withLoadingLocks(
        makePostRequest(`/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/followers/`, {
          user_ids: values.map(v => v.value)
        })
      )

      const index = tasks.findIndex(t => t.id === task.id)

      if (~index) {
        setTasks((draft: Task[]) => {
          draft[index].followers = toTasks([task])[0].followers
          return draft
        })
      }

      refreshTable()

      if (activeTab === 'history') {
        fetchHistory()
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Task ${task.name} successfully edited.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const addComment = async (comment: string) => {
    try {
      const response = await withLoadingLocks(
        makePostRequest(`/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/comments/`, {
          comment
        })
      )
      setComments([response, ...comments])

      const index = tasks.findIndex(t => t.id === editedTask?.id)

      if (~index) {
        setTasks((draft: Task[]) => {
          if (editedTask) {
            draft[index].commentsCount = draft[index].commentsCount + 1
            draft[index].status = editedTask.status
          }
          return draft
        })
      }

      setActiveTab('comments')

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully added to task ${task.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const deleteComment = async (id: number) => {
    try {
      await withLoadingLocks(
        makeDeleteRequest(
          `/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/comments/${id}/`
        )
      )

      setComments(comments.filter(c => c.id !== id))

      const index = tasks.findIndex(t => t.id === editedTask?.id)

      if (~index) {
        setTasks((draft: Task[]) => {
          if (editedTask) {
            draft[index].commentsCount = draft[index].commentsCount - 1
          }
          return draft
        })
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully deleted from task ${task.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const editComment = async (id: number, comment: string, cb: () => void) => {
    try {
      const response = await withLoadingLocks(
        makePatchRequest(
          `/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/comments/${id}/`,
          {
            comment
          }
        )
      )

      setComments(comments.map(c => (c.id === id ? response : c)))

      cb?.()

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully edited on task ${task.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const downloadAllFiles = () => {
    openLink(
      `/task-management/matters/${_scopeId}/tasks/${editedTask?.id}/attachments/bulk-download/`
    )
  }

  const errorObject = (task: InitialTask | LocalTask) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (isEdit && !task.name) {
      hasError = true
      errorMessage = 'Name is required.'
    }
    if (task.name && task.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }

    return { hasError, errorMessage }
  }

  function overwriteDuplicates(oldAtts: APIAttachment[], serializedAttachments: APIAttachment[]) {
    const existingNames = serializedAttachments.map(a => a.file_name)
    return oldAtts.filter(o => !existingNames.includes(o.file_name))
  }

  return (
    <>
      <div className={s.container} data-testid="add-task-container">
        <div className={s.close} onClick={toggleAddTaskSidebar} data-testid="add-task-close">
          &times;
        </div>
        {context === 'workbench' && !isEdit && (
          <>
            <div className={s.relatedMatter} data-for="resetWarning" data-tip>
              <KeyValuePairs
                style={{ margin: '10px 10px 0 0' }}
                pairs={[
                  {
                    key: 'Related to*',
                    style: { marginBottom: '10px', height: 40 },
                    keyClass: s.key,
                    sectionClass: s.section,
                    valueClass: s.value,
                    value: (
                      <>
                        <b style={{ position: 'relative', top: 4, marginRight: 10 }}>Matter: </b>
                        {/* @ts-expect-error */}
                        <PaginatedSelect
                          url="/manage/matters/v2/simple_matter_list/?can_edit=true"
                          value={task.relatedMatter as MatterOption}
                          onChange={(item: MatterOption): void => {
                            setTask({
                              ...task,
                              relatedMatter: {
                                value: item?.value as number,
                                label: item?.label ?? '',
                                status: item?.status ?? ''
                              },
                              assignees: [],
                              followers: [],
                              subtasks: task.subtasks.map(subtask => {
                                return {
                                  ...subtask,
                                  assignees: []
                                }
                              })
                            })
                            item?.value && setScopeId(String(item.value))
                          }}
                          isClearable={false}
                          placeholder="Select..."
                          className={s.select}
                          styles={customStyle}
                          comps={{ Option: CustomOption }}
                          defaultMenuIsOpen={context === 'workbench'}
                          serializer={(options: APISimpleMatter[]) =>
                            toMattersOptions(options, true)
                          }
                          pageStart={0}
                          resultsProperty="rows"
                          pageParam="page_number"
                          searchTermParam="search"
                        />
                      </>
                    )
                  }
                ]}
              />
            </div>
            {task.relatedMatter && (
              <ReactTooltip id="resetWarning" type="light" effect="solid" place="bottom" border>
                Changing a tasks relation will clear the selected Assignees and Followers
              </ReactTooltip>
            )}
          </>
        )}
        <div
          className={cn(s.header, {
            [s.error]: nameInputFocused && task.name.length > MAX_NAME_LENGTH
          })}
        >
          <CharLimitInput
            value={task.name}
            placeholder="Add task name*"
            onChangeCb={e => updateTask(e.target.value, 'name')}
            maxLength={MAX_NAME_LENGTH}
            hideInfoText={!nameInputFocused}
            hasError={nameInputFocused && errorObject(task).hasError}
            customErrorMessage={nameInputFocused ? errorObject(task).errorMessage : undefined}
            style={{ width: '100%', outline: 'none !important', padding: 5 }}
            cssClass={cn(s.nameInput, {
              [s.inputNoBorders]: task.name,
              [s.readOnly]: isReadOnly && isEdit,
              [s.focused]: nameInputFocused
            })}
            dynamicCharCalculation
            sectionStyles={{ width: '95%' }}
            onFocus={() => setNameInputFocused(true)}
            focused={context === 'matter' && !isEdit}
            refObject={inputRef}
            onBlur={() => {
              if (isEdit) {
                if (editedProperties.name?.trim() && editedProperties.name !== oldTask?.name) {
                  saveTask(toTask(editedProperties as LocalTask), isEdit, () => {
                    resetEditedProperties()
                    if (task.subtasks.length) {
                      fetchTasks()
                    }
                    if (activeTab === 'history') {
                      fetchHistory()
                    }
                  })
                }
              }
              setNameInputFocused(false)
            }}
            isDisabled={isEdit && isReadOnly}
            testid="task-name-input"
          />
          {isEdit && (
            <div className={s.info}>
              <span>
                {task.parent &&
                !(task.parent.isPrivate && !tasks.find(t => t.id === task.parent?.id)) ? (
                  <span onClick={() => onEditSubtask((task.parent as unknown) as Subtask)}>
                    <span className={s.parentLink}>
                      <FaAngleLeft />{' '}
                      <Ellipsis width={200} lines={1} className={s.inline}>
                        {task.parent.name}
                      </Ellipsis>{' '}
                    </span>
                    <span style={{ margin: '0 5px' }}>&#8226;</span>
                  </span>
                ) : (
                  ''
                )}{' '}
                {task.taskId}
                <span style={{ margin: '0 5px' }}>&#8226;</span>
                Created {moment(task.createdDate).format('MMM DD, YYYY')}{' '}
                <span style={{ margin: '0 5px' }}>&#8226;</span> Created by{' '}
                {task.createdBy?.label ?? ''}
              </span>
            </div>
          )}
        </div>
        <div
          className={cn(s.content, {
            [s.contentEdit]: isEdit,
            [s.workbench]: context === 'workbench'
          })}
        >
          <Widgets className={isEdit && s.spaceBetween}>
            <Widget
              label="ASSIGNEE(S)"
              value={getUsersLabel('assignees')}
              icon={getUsersIcon('assignees')}
              isDisabled={context === 'workbench' && !isEdit && !task.relatedMatter}
              hasTooltip={context === 'workbench' && !isEdit && !task.relatedMatter}
              tooltipText="Related to must be selected to assign assignees."
            >
              <Users
                value={task.assignees}
                style={{ width: '400px' }}
                requestParams={{ matterId: +_scopeId, canEdit: true, active: true }}
                onConfirm={(values: Option[]) => {
                  updateTask(values, 'assignees')
                  if (isEdit) {
                    addAssignees(values)
                  }
                }}
                readOnly={isEdit && isReadOnly}
              />
            </Widget>
            <Widget
              icon={<FaRegCalendarCheck />}
              label="DUE DATE"
              value={
                task.dueDate &&
                moment(task.dueDate).format(
                  moment(task.dueDate).isSame(new Date(), 'year') ? 'MMM DD' : 'MMM DD, YYYY'
                )
              }
            >
              <DueDatePicker
                onConfirm={(value: Date | DateFormat | undefined) => {
                  updateTask(value, 'dueDate')
                  if (isEdit) {
                    saveTask(
                      toTask({
                        id: editedProperties.id,
                        dueDate: value || ''
                      } as LocalTask),
                      isEdit,
                      () => {
                        resetEditedProperties()
                        if (activeTab === 'history') {
                          fetchHistory()
                        }
                      },
                      true
                    )
                  }
                }}
                value={task.dueDate}
                readOnly={isEdit && isReadOnly}
              />
            </Widget>
            <Widget
              label="FOLLOWER(S)"
              value={getUsersLabel('followers')}
              icon={getUsersIcon('followers')}
              placement="bottom"
              isDisabled={context === 'workbench' && !isEdit && !task.relatedMatter}
              hasTooltip={context === 'workbench' && !isEdit && !task.relatedMatter}
              tooltipText="Related to must be selected to assign followers."
            >
              <Users
                value={task.followers}
                style={{ width: '400px' }}
                requestParams={{ matterId: +_scopeId, canRead: true, active: true }}
                onConfirm={(values: Option[]) => {
                  updateTask(values, 'followers')
                  if (isEdit) {
                    addFollowers(values)
                  }
                }}
                readOnly={isEdit && isReadOnly}
              />
            </Widget>
            {isEdit ? (
              <SetCompleted readOnly={isReadOnly} status={task.status} onClick={toggleCompleted} />
            ) : null}
          </Widgets>
          <label className={s.label}>Description</label>
          {isEdit && (!showDescription || isReadOnly) && (
            <>
              {task.description ? (
                <Markdown
                  value={task.description}
                  className={cn(s.description, {
                    [s.readOnly]: isReadOnly
                  })}
                  onClick={() => (isReadOnly ? null : setShowDescription(!showDescription))}
                />
              ) : (
                <div
                  className={cn(s.descriptionPlaceholder, {
                    [s.readOnly]: isReadOnly
                  })}
                  onClick={() => (isReadOnly ? null : setShowDescription(!showDescription))}
                >
                  Add description...
                </div>
              )}
            </>
          )}
          {(!isEdit || showDescription) && (
            <RichTextEditor
              value={task.description ?? ''}
              onChange={value => {
                updateTask(value, 'description')
              }}
              onBlur={
                !isEdit
                  ? undefined
                  : () => {
                      editedProperties.description !== oldTask?.description &&
                        !isDescriptionTooLong &&
                        saveTask(toTask(editedProperties as LocalTask), isEdit, () => {
                          resetEditedProperties()
                          if (activeTab === 'history') {
                            fetchHistory()
                          }
                        })
                      setShowDescription(false)
                    }
              }
              maxLength={MAX_DESCRIPTION_LENGTH}
              dynamicCharCalculation
              validation={setIsDescriptionTooLong}
              customErrorMessage="Maximum character limit reached"
            />
          )}
          {isEdit ? (
            <>
              <Tabs selectedIndex={getTabIndex()}>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'overview'}
                  header="Overview"
                  onClickCb={() => {
                    setActiveTab('overview')
                  }}
                >
                  <Overview
                    priorities={selectOptions?.priorities ?? []}
                    taskTypes={selectOptions?.taskTypes ?? []}
                    statuses={selectOptions?.statuses ?? []}
                    task={task}
                    scopeName={scopeName || (task?.relatedMatter?.label ?? '')}
                    updateTask={updateTask}
                    saveTask={saveTask}
                    isEdit={isEdit}
                    editedProperties={editedProperties}
                    resetEditedProperties={resetEditedProperties}
                    readOnly={isReadOnly}
                    context={context}
                    scopeId={String(_scopeId)}
                  />
                </Tab>
                {!task.parent && (
                  // @ts-expect-error
                  <Tab
                    selected={activeTab === 'subtasks'}
                    header="Subtasks"
                    count={task.subtasks.filter(s => !s.draft).length || undefined}
                    onClickCb={() => {
                      setActiveTab('subtasks')
                    }}
                  >
                    <Subtasks
                      key={(task as LocalTask).id}
                      parentId={(task as Task).id}
                      value={task.subtasks}
                      scopeId={String(_scopeId)}
                      onSave={value => {
                        updateTask(value as Subtask[], 'subtasks')
                        saveTask(
                          toTask({
                            ...(isEdit
                              ? {
                                  id: editedProperties.id
                                }
                              : editedProperties),
                            subtasks: value
                          } as LocalTask),
                          isEdit,
                          (task?: Task) => {
                            updateTask(
                              [
                                ...(task?.subtasks ?? []),
                                ...(value as Subtask[]).filter(v => v.draft)
                              ],
                              'subtasks'
                            )
                            resetEditedProperties()
                          },
                          true
                        )
                      }}
                      onDelete={onDeleteTask}
                      duplicateTask={duplicateTask}
                      onEditSubtask={(subtask: Subtask) => {
                        onEditSubtask(subtask)
                        setActiveTab('overview')
                      }}
                      isEdit={isEdit}
                      readOnly={isReadOnly}
                      isPremium={!isBasicPlan}
                      limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                      hasTooltip={isBasicPlan}
                    />
                  </Tab>
                )}
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'files'}
                  header="Files"
                  count={task.fileAttachments.length || undefined}
                  onClickCb={() => {
                    setActiveTab('files')
                  }}
                >
                  {/* @ts-expect-error */}
                  <FileUpload
                    view="simple"
                    scope="task"
                    texts={{
                      previewModalTitle: 'Preparing to Download',
                      previewModalContent: 'File should download shortly'
                    }}
                    files={task.fileAttachments}
                    isEditable
                    deleteAttachment={showDeleteModal}
                    uploadFiles={(attachments: Attachment) => {
                      addFiles(toAPIAttachments(attachments))
                    }}
                    downloadAll={downloadAllFiles}
                    hideScroll={false}
                    readOnly={isReadOnly}
                    isPremium={!isBasicPlan}
                    limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                    existingFilesNumber={task.fileAttachments.length}
                    maxLimitReachedError={`File upload failed. A maximum of ${BASIC_TIER_TOTAL_ALLOWED} files are allowed per task.`}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'comments'}
                  header="Comments"
                  count={comments.length || undefined}
                  onClickCb={() => {
                    setActiveTab('comments')
                  }}
                >
                  <CommentsList
                    comments={comments}
                    editComment={editComment}
                    deleteComment={deleteComment}
                    readOnly={isReadOnly}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'history'}
                  header="History"
                  onClickCb={() => {
                    setActiveTab('history')
                  }}
                >
                  <HistoryList history={history} />
                </Tab>
              </Tabs>
            </>
          ) : (
            <>
              {!task.parent && (
                <Subtasks
                  value={task.subtasks}
                  scopeId={String(_scopeId)}
                  onSave={value => updateTask(value, 'subtasks')}
                  limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                  isPremium={!isBasicPlan}
                  hasTooltip={isBasicPlan}
                  disabledUsersWidget={context === 'workbench' && !task.relatedMatter}
                  disabledUsersWidgetTooltipText="Related to must be selected to assign assignees."
                />
              )}
              {/* @ts-expect-error */}
              <FileUpload
                view="simple"
                simpleViewProps={{
                  hideHoverAction: true
                }}
                scope="task"
                texts={{
                  previewModalTitle: 'Preparing to Download',
                  previewModalContent: 'File should download shortly'
                }}
                files={task.fileAttachments}
                isEditable
                deleteAttachment={(id: number | string) => {
                  setTask({
                    ...task,
                    fileAttachments: task.fileAttachments.filter(attachment => attachment.id !== id)
                  })
                }}
                uploadFiles={(attachments: Attachment) => {
                  const serializedAttachments = toAPIAttachments(attachments)

                  updateTask(
                    [
                      ...overwriteDuplicates(task.fileAttachments, serializedAttachments),
                      ...serializedAttachments
                    ],
                    'fileAttachments'
                  )
                }}
                hideScroll={false}
                limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                isPremium={!isBasicPlan}
                existingFilesNumber={task.fileAttachments.length}
                maxLimitReachedError={`File upload failed. A maximum of ${BASIC_TIER_TOTAL_ALLOWED} files are allowed per task.`}
              />
              <Overview
                priorities={selectOptions?.priorities ?? []}
                taskTypes={selectOptions?.taskTypes ?? []}
                statuses={selectOptions?.statuses ?? []}
                task={task}
                scopeName={scopeName}
                scopeId={String(_scopeId)}
                updateTask={updateTask}
              />
            </>
          )}
        </div>
        <div
          className={cn(s.footer, {
            [s.comments]: isEdit
          })}
        >
          {!isEdit ? (
            <>
              <Button onClick={toggleAddTaskSidebar} isPrimary isOutline hasNewDesign>
                Cancel
              </Button>
              <Button
                onClick={() => saveTask(toTask(task as LocalTask))}
                isPrimary
                isDisabled={!isValid()}
                hasNewDesign
              >
                Save Task
              </Button>
            </>
          ) : (
            <Comments readOnly={isReadOnly} addComment={addComment} />
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={deleteAttachment}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </>
  )
}

export default AddTask
