import { RequestFormRowActions } from '../common/RowActions'
import s from '../RequestFormRow.scss'

const RequestFormRowReadOnly = ({ item, attributeValue }) => (
  <li className={s.rowContainer}>
    <div className={s.rowAttributeSelect}>
      <span>{attributeValue}</span>
    </div>
    <div className={s.rowFieldSelect}>
      <span>{item.fieldType}</span>
    </div>
    <div className={s.formLabel}>
      <span>{item.label}</span>
    </div>
    <div className={s.rowInfoBox}>
      <span>{item.info || ''}</span>
    </div>
    <RequestFormRowActions item={item} readOnly />
  </li>
)

export default RequestFormRowReadOnly
