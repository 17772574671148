import { CheckboxContainer } from 'simple-core-ui'
import s from './CheckboxField.scss'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { useRef, useEffect, useState } from 'react'

const CheckboxField = ({ currentField, allFields, setFields, limitCustomFields }) => {
  const labelRef = useRef()
  const [overflow, setOverflow] = useState(false)

  const { label, isSelected, type, value, isDefault } = currentField

  const disabled = isDefault || (!isSelected && limitCustomFields)

  const labelText = `${label}${isDefault ? ' (Default)' : ''}`

  useEffect(() => {
    checkContentLength()
  }, [])

  const checkContentLength = () => {
    const offsetWidth = labelRef?.current?.offsetWidth
    const scrollWidth = labelRef?.current?.scrollWidth
    setOverflow(scrollWidth > offsetWidth)
  }

  const renderLabel = () => (
    <div
      className={cn(s.checkboxLabel, { [s.default]: isDefault, [s.disable]: disabled })}
      ref={labelRef}
    >
      {labelText}
    </div>
  )

  return (
    <div className={s.checkboxesAlign}>
      <CheckboxContainer
        styles={{
          marginRight: 5,
          ...(disabled ? { opacity: 0.4 } : {})
        }}
        size="sm"
        isChecked={isSelected}
        disabled={isDefault || (limitCustomFields && type === 'custom' && !isSelected)}
        cb={() => {
          if (!isDefault && (limitCustomFields ? type === 'custom' && isSelected : 1)) {
            const newFields = allFields.map(f =>
              f.value === value ? { ...f, isSelected: !f.isSelected } : f
            )
            setFields(newFields)
          }
        }}
      />
      {overflow ? (
        <span data-tip={labelText} data-for={value} className={s.tooltipContainer}>
          {renderLabel()}
          <ReactTooltip effect="solid" type="light" className={s.tooltipPopup} id={value}>
            {labelText}
          </ReactTooltip>
        </span>
      ) : (
        renderLabel()
      )}
    </div>
  )
}

export default CheckboxField
