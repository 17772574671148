import { Component } from 'react'
import { ModalContainer, HoverAction } from 'simple-core-ui'
import s from '../styles/Expand.scss'
import ReactTooltip from 'react-tooltip'
import { FaExpandAlt } from 'react-icons/fa'

class Expand extends Component {
  render() {
    return (
      <ModalContainer
        title={this.props.title}
        content={this.props.children}
        cancelCb={() => null}
        cancelText="Close"
        size="fp"
        closeOnEscape
      >
        {openModal => {
          return (
            <span
              className={s.icon}
              onClick={props => {
                ReactTooltip.rebuild()
                openModal(props)
              }}
            >
              <HoverAction onClick={() => null} icon={<FaExpandAlt />} tip="Expand" size="xsmall" />
            </span>
          )
        }}
      </ModalContainer>
    )
  }
}

export default Expand
