import { PracticeAreaSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { formatMultiSelectLabel } from '../helpers'

const PracticeAreaFilter = ({
  onSelect,
  reset,
  isMulti,
  limit,
  hasConfirmation,
  title = 'Practice Area',
  initialValue,
  paginated
}) => {
  return (
    <SelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti={isMulti}
      customLabel={formatMultiSelectLabel(isMulti, title)}
      hasConfirmation={hasConfirmation}
      render={(practiceArea, setPracticeArea) => {
        return (
          <PracticeAreaSelect
            value={practiceArea}
            onChange={item => {
              setPracticeArea(item)
            }}
            isPortal={false}
            isClearable
            isMulti={isMulti}
            limit={limit}
            placeholder={`Search ${title}`}
            paginated={paginated}
          />
        )
      }}
    />
  )
}

export default PracticeAreaFilter
