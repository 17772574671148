import { Checkbox, Alert } from 'simple-core-ui'
import s from './CheckboxLine.scss'

const CheckboxLine = ({ message, acknowledgement, setAcknowledgement }) => {
  return (
    <Alert
      style={{
        marginTop: '2rem'
      }}
      message={
        <div className={s.container}>
          <Checkbox
            isChecked={acknowledgement}
            triggerCheckbox={() => setAcknowledgement(!acknowledgement)}
            size="sm"
            hasGreyBorder
            testId="ack-checkbox"
          />{' '}
          <p className={s.message}>{message}</p>
        </div>
      }
      status="info"
    />
  )
}

export default CheckboxLine
