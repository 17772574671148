import s from './MatterListInnerTable.scss'
import moment from 'moment'

const MatterListInnerTable = ({
  accruals,
  actuals_before,
  actuals_after,
  delta_to_actual,
  startDate,
  idx,
  matterListIndex
}) => {
  const date = moment(startDate).add({ month: idx })
  const month = date.format('MMM')

  const actualsBeforeValue = actuals_before === null ? '--' : actuals_before
  const accrualsValue = accruals === null ? '--' : accruals
  const actualsAfterValue = actuals_after === null ? '--' : actuals_after
  const deltaToActualValue = delta_to_actual === null ? '--' : delta_to_actual

  return (
    <div className={s.tableColumn}>
      {matterListIndex === 0 && <p className={`${s.cardText} ${s.monthHeaders}`}>{month}</p>}
      <p className={`${actualsBeforeValue === '--' && s.gray} ${s.cardText}`}>
        {actualsBeforeValue}
      </p>
      <p className={`${accrualsValue === '--' && s.gray} ${s.cardText}`}>{accrualsValue}</p>
      <p className={`${actualsAfterValue === '--' && s.gray} ${s.cardText}`}>{actualsAfterValue}</p>
      <div className={s.deltaLine}></div>
      <p className={`${deltaToActualValue === '--' && s.gray} ${s.cardText} ${s.bold}`}>
        {deltaToActualValue}
      </p>
    </div>
  )
}

export default MatterListInnerTable
