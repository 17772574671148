import { getItemValue } from './utils'
import partition from 'lodash/partition'

export const toRequestJson = request => {
  const [relationships, attributes] = partition(
    request.items,
    item => item.fieldType === 'relationship'
  )

  const filteredAttributes = attributes.filter(item => !!item.value)
  const filteredRelationships = filterRelationships(relationships)

  return {
    request_form_id: request.selectedForm.id,
    other_fields: [
      {
        attributes: filteredAttributes.map(item => {
          const { fieldUniqueName } = item
          const itemValue = getItemValue(item)
          return {
            [fieldUniqueName]: itemValue
          }
        })
      },
      {
        relationships: fromRelationships(filteredRelationships)
      }
    ]
  }
}

const fromRelationships = relationships =>
  relationships.map((rel, i) => {
    const id = rel.model.split('RelAttrs__')[1]

    return {
      [`relattrs_id_${id}`]: rel.attributes.map(attr => ({
        [attr.fieldUniqueName]: +attr.value?.value || null
      }))
    }
  })

const filterRelationships = relationships =>
  relationships.filter(item => {
    if (!item.attributes.length) return false
    if (item.required) {
      return !item.attributes.some(attr => !attr.value)
    } else {
      return !item.attributes.every(attr => !attr.value)
    }
  })
