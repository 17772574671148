import * as React from 'react'
import { connect } from 'react-redux'

import { Badge, If } from 'simple-core-ui'

import { getReviewableStatusColor, STATUS_COLOR } from '../styles/cssInJs'

import s from '../styles/ReviewLadder.scss'

@connect(({ reviews: { reviewLadder } }) => {
  const { reviewableStatus } = reviewLadder.review
  return { reviewableStatus, isHydrating: reviewLadder.isHydrating }
})
class ReviewableStatusContainer extends React.Component {
  render() {
    const { reviewableStatus, scope, isHydrating } = this.props

    return (
      <span className={s.reviewableStatus}>
        <If
          condition={reviewableStatus}
          fallback={
            <Badge
              content="Status"
              style={{ backgroundColor: STATUS_COLOR.NEUTRAL }}
              size="sm"
              asPill
              isLoading
            />
          }
        >
          {() => (
            <Badge
              title={`Status: ${reviewableStatus}`}
              content={reviewableStatus}
              size="sm"
              style={{
                backgroundColor: getReviewableStatusColor({
                  scope,
                  reviewableStatus
                })
              }}
              asPill
              isLoading={isHydrating}
            />
          )}
        </If>
      </span>
    )
  }
}

export default ReviewableStatusContainer
