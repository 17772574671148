import { Ellipsis } from 'simple-core-ui'
import s from './VendorList.scss'
import { titleCase } from 'title-case'
import { hasModule } from 'utils/helpers'

const StatusIcon = props => {
  return <div className={`${s.statusDot} ${props.active ? s.active : s.inactive}`}></div>
}

export const fetchColumns = (selectedTab, permissions) => {
  const columns = [
    {
      columnKey: 'vendor_name',
      content: 'Vendor',
      isSortable: true,
      render: (cell, row) => {
        const name = cell.content
        return (
          <a href={`/invoices/vendors/${row.id}/?fromNewVendorList=true`}>
            <Ellipsis>{name}</Ellipsis>
          </a>
        )
      }
    },
    ...(permissions.enableManageVendorGroups
      ? [
          {
            columnKey: 'vendor_group',
            content: 'Vendor Group',
            isSortable: true,
            render: cell => {
              return <Ellipsis>{cell.content}</Ellipsis>
            }
          }
        ]
      : []),
    {
      columnKey: 'entities_billed',
      content: 'Entities Billed',
      isSortable: false,
      render: cell => {
        if (cell.content === '----') return 'None'
        return (
          <div className={s.cellContent}>
            <Ellipsis>{cell.content}</Ellipsis>
          </div>
        )
      }
    },
    {
      columnKey: 'contact',
      content: 'Contact',
      isSortable: true,
      render: cell => {
        return (
          <div className={s.cellContent}>
            <Ellipsis>{cell.content}</Ellipsis>
          </div>
        )
      }
    },
    {
      columnKey: 'vendor_lead',
      content: 'Vendor Manager',
      isSortable: true,
      render: cell => {
        const lead = cell.content.display
        if (!lead) return '----'
        return <Ellipsis>{lead}</Ellipsis>
      }
    },
    ...(selectedTab === 'All'
      ? [
          {
            columnKey: 'status',
            content: 'Status',
            isSortable: true,
            render: cell => (cell.content === 'deactivated' ? 'Inactive' : titleCase(cell.content))
          }
        ]
      : []),
    ...(hasModule('has_timekeepers')
      ? [
          {
            columnKey: 'timekeeper_validation',
            content: 'TK Validation',
            isSortable: true,
            render: cell => {
              if (cell.content === true) {
                return (
                  <div className={s.statusWrapper}>
                    <StatusIcon active />
                    {'Yes'}
                  </div>
                )
              }
              return (
                <div className={s.statusWrapper}>
                  <StatusIcon active={false} />
                  {'No'}
                </div>
              )
            }
          }
        ]
      : []),
    ...(hasModule('has_accruals')
      ? [
          {
            columnKey: 'accruals_enabled',
            content: 'Accruals',
            isSortable: true,
            render: cell => {
              if (cell.content === true) {
                return (
                  <div className={s.statusWrapper}>
                    <StatusIcon active />
                    {'Yes'}
                  </div>
                )
              } else {
                return (
                  <div className={s.statusWrapper}>
                    <StatusIcon active={false} />
                    {'No'}
                  </div>
                )
              }
            }
          }
        ]
      : []),
    {
      columnKey: 'cg_users',
      content: 'CounselGO',
      isSortable: true,
      render: cell => {
        if (cell.content > 0) {
          return (
            <div data-tip={`Users: ${cell.content}`} className={s.statusWrapper}>
              <StatusIcon active />
              {'Yes'}
            </div>
          )
        } else {
          return (
            <div className={s.statusWrapper}>
              <StatusIcon active={false} />
              {'No'}
            </div>
          )
        }
      }
    }
  ]

  return columns
}
