import { fes } from 'scui/utils/formatting'
const WARNING_THRESHOLD = 80
const OVER_BUDGET_THRESHOLD = 100

export { WARNING_THRESHOLD, OVER_BUDGET_THRESHOLD }

export const ACTIVE_BUDGETS_TAB = { value: 'active_budgets', label: 'Active Budgets' }
export const BUDGET_REQUESTS_TAB = { value: 'budget_requests', label: 'Budget Requests' }
export const DRAFTS_TAB = { value: 'draft_budgets', label: 'Drafts' }

export const BUDGET_TABS = [BUDGET_REQUESTS_TAB, ACTIVE_BUDGETS_TAB, DRAFTS_TAB]

export const BULK_ACTIONS = {
  draft: [
    { key: 'approve', label: 'Approve' },
    { key: 'archive', label: 'Archive' },
    { key: 'edit', label: 'Edit' },
    { key: 'delete', label: 'Delete' }
  ],
  approved: [{ key: 'archive', label: 'Archive' }],
  archived: [{ key: 'revert', label: 'Revert status' }]
}

export const DIALOG_BULK_ACTIONS = ['approve', 'archive', 'draft', 'revert', 'delete']

export const BULK_WARNING_MESSAGES = budgetsLength => ({
  approve: `${fes(budgetsLength, 'budgets')} will be moved to Approved`,
  archive: `${fes(budgetsLength, 'budgets')} will be moved to Archived`,
  draft: `${fes(budgetsLength, 'budgets')} will be moved to Draft`,
  revert: `You are reverting ${fes(budgetsLength, 'budgets')}`,
  delete: `You are deleting ${fes(budgetsLength, 'selected budgets')}`
})
