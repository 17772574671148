import { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import cn from 'classnames'
import 'react-day-picker/lib/style.css'
import 'styles/overrides/react-day-picker.css'
import s from './DatePicker.scss'

import { formatDate, parseDate } from 'react-day-picker/moment'

class ReactDatePicker extends Component {
  state = {
    selectedDay: this.props.value || ''
  }

  handleChange = value => {
    const { onChange } = this.props
    const selectedValue = value ? moment(value).format('L') : null
    this.setState({
      selectedDay: selectedValue
    })
    onChange(selectedValue)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevState.selectedDay && prevState.selectedDay) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedDay: this.props.value })
    }
  }

  render() {
    const { selectedDay } = this.state
    const { disabled, className, classNames } = this.props

    return (
      <div className={cn(s.dayPickerWrapper, className)}>
        <DayPickerInput
          value={selectedDay}
          formatDate={formatDate}
          parseDate={parseDate}
          classNames={classNames}
          placeholder={selectedDay ? selectedDay : 'MM/DD/YYYY'}
          onDayChange={this.handleChange}
          inputProps={{ disabled, onChange: this.props.onInputChange }}
        />
      </div>
    )
  }
}

export default ReactDatePicker
