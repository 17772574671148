import get from 'lodash/get'
import noop from 'lodash/noop'
import { HoverAction } from 'simple-core-ui'

import s from '../styles/ReviewLadder.scss'

const ReviewEntryActions = ({ actions }) => (
  <section className={s.hoverActions}>
    {actions.map((actionProps, idx) => (
      <HoverAction
        {...actionProps}
        onClick={get(actionProps, 'onClick', noop)}
        size="xsmall"
        style={{ marginLeft: '6px' }}
        isDisabled={!actionProps.onClick}
        key={idx}
      />
    ))}
  </section>
)

export default ReviewEntryActions
