import React from 'react'
import s from '../styles/VendorEval.scss'
import cn from 'classnames'
import { If } from 'simple-core-ui'

const Footer = ({ cancelSurvey, submitSurvey, returnToMatter, isClosed, isValid }) => {
  const OpenEvalContent = () => {
    return (
      <React.Fragment>
        <span className={cn(s.btn, s.cancelBtn)} onClick={cancelSurvey}>
          Cancel
        </span>
        <span
          className={cn(s.btn, s.submitBtn, { [s.disabled]: !isValid })}
          onClick={isValid ? submitSurvey : null}
        >
          Submit Survey
        </span>
      </React.Fragment>
    )
  }
  return (
    <footer className={s.footer}>
      <If condition={isClosed} fallback={<OpenEvalContent />}>
        <span className={cn(s.btn, s.cancelBtn)} onClick={returnToMatter}>
          Go Back to Matter
        </span>
      </If>
    </footer>
  )
}

export default Footer
