interface CurrencyData {
  value: string
  exponent: number
  label: string
}

const currencies: Array<CurrencyData> = [
  { value: 'USD', exponent: 2, label: 'USD - US Dollars' },
  { value: 'AUD', exponent: 2, label: 'AUD - Australian Dollar' },
  { value: 'BRL', exponent: 2, label: 'BRL - Brazilian Real' },
  { value: 'CAD', exponent: 2, label: 'CAD - Canadian Dollar' },
  { value: 'EUR', exponent: 2, label: 'EUR - Euro' },
  { value: 'GBP', exponent: 2, label: 'GBP - Pound Sterling' },
  { value: 'INR', exponent: 2, label: 'INR - Indian Rupee' },
  { value: 'JPY', exponent: 0, label: 'JPY - Yen' },
  { value: 'KRW', exponent: 0, label: 'KRW - Won' },
  { value: 'SGD', exponent: 2, label: 'SGD - Singapore Dollar' },
  { value: 'AED', exponent: 2, label: 'AED - UAE Dirham' },
  { value: 'AFN', exponent: 2, label: 'AFN - Afghani' },
  { value: 'ALL', exponent: 2, label: 'ALL - Lek' },
  { value: 'AMD', exponent: 2, label: 'AMD - Armenian Dram' },
  { value: 'ANG', exponent: 2, label: 'ANG - Netherlands Antillian Guilder' },
  { value: 'AOA', exponent: 2, label: 'AOA - Kwanza' },
  { value: 'ARS', exponent: 2, label: 'ARS - Argentine Peso' },
  { value: 'AWG', exponent: 2, label: 'AWG - Aruban Guilder' },
  { value: 'AZN', exponent: 2, label: 'AZN - Azerbaijanian Manat' },
  { value: 'BAM', exponent: 2, label: 'BAM - Convertible Marks' },
  { value: 'BBD', exponent: 2, label: 'BBD - Barbados Dollar' },
  { value: 'BDT', exponent: 2, label: 'BDT - Taka' },
  { value: 'BGN', exponent: 2, label: 'BGN - Bulgarian Lev' },
  { value: 'BHD', exponent: 3, label: 'BHD - Bahraini Dinar' },
  { value: 'BIF', exponent: 0, label: 'BIF - Burundi Franc' },
  { value: 'BMD', exponent: 2, label: 'BMD - Bermuda Dollar' },
  { value: 'BND', exponent: 2, label: 'BND - Brunei Dollar' },
  { value: 'BSD', exponent: 2, label: 'BSD - Bahamian Dollar' },
  { value: 'BTN', exponent: 2, label: 'BTN - Bhutanese ngultrum' },
  { value: 'BWP', exponent: 2, label: 'BWP - Pula' },
  { value: 'BYR', exponent: 2, label: 'BYR - Belarussian Ruble' },
  { value: 'BZD', exponent: 2, label: 'BZD - Belize Dollar' },
  { value: 'CDF', exponent: 2, label: 'CDF - Congolese franc' },
  { value: 'CHF', exponent: 2, label: 'CHF - Swiss Franc' },
  { value: 'CLP', exponent: 0, label: 'CLP - Chilean peso' },
  { value: 'CNY', exponent: 2, label: 'CNY - Yuan Renminbi' },
  { value: 'COP', exponent: 2, label: 'COP - Colombian peso' },
  { value: 'CRC', exponent: 2, label: 'CRC - Costa Rican Colon' },
  { value: 'CUC', exponent: 2, label: 'CUC - Cuban convertible peso' },
  { value: 'CUP', exponent: 2, label: 'CUP - Cuban Peso' },
  { value: 'CVE', exponent: 2, label: 'CVE - Cape Verde Escudo' },
  { value: 'CZK', exponent: 2, label: 'CZK - Czech Koruna' },
  { value: 'DJF', exponent: 0, label: 'DJF - Djibouti Franc' },
  { value: 'DKK', exponent: 2, label: 'DKK - Danish Krone' },
  { value: 'DOP', exponent: 2, label: 'DOP - Dominican Peso' },
  { value: 'DZD', exponent: 2, label: 'DZD - Algerian Dinar' },
  { value: 'EEK', exponent: 2, label: 'EEK - Kroon' },
  { value: 'EGP', exponent: 2, label: 'EGP - Egyptian Pound' },
  { value: 'ERN', exponent: 2, label: 'ERN - Nakfa' },
  { value: 'ETB', exponent: 2, label: 'ETB - Ethiopian Birr' },
  { value: 'FJD', exponent: 2, label: 'FJD - Fiji Dollar' },
  { value: 'FKP', exponent: 2, label: 'FKP - Falkland Islands Pound' },
  { value: 'GEL', exponent: 2, label: 'GEL - Lari' },
  { value: 'GHS', exponent: 2, label: 'GHS - Ghana Cedi' },
  { value: 'GIP', exponent: 2, label: 'GIP - Gibraltar Pound' },
  { value: 'GMD', exponent: 2, label: 'GMD - Dalasi' },
  { value: 'GNF', exponent: 0, label: 'GNF - Guinea Franc' },
  { value: 'GTQ', exponent: 2, label: 'GTQ - Quetzal' },
  { value: 'GYD', exponent: 2, label: 'GYD - Guyana Dollar' },
  { value: 'HKD', exponent: 2, label: 'HKD - Hong Kong Dollar' },
  { value: 'HNL', exponent: 2, label: 'HNL - Lempira' },
  { value: 'HRK', exponent: 2, label: 'HRK - Croatian Kuna' },
  { value: 'HTG', exponent: 2, label: 'HTG - Haitian gourde' },
  { value: 'HUF', exponent: 2, label: 'HUF - Forint' },
  { value: 'IDR', exponent: 2, label: 'IDR - Rupiah' },
  { value: 'ILS', exponent: 2, label: 'ILS - New Israeli Sheqel' },
  { value: 'IMP', exponent: 2, label: 'IMP - Isle of Man pount' },
  { value: 'IQD', exponent: 3, label: 'IQD - Iraqi Dinar' },
  { value: 'IRR', exponent: 2, label: 'IRR - Iranian Rial' },
  { value: 'ISK', exponent: 0, label: 'ISK - Iceland Krona' },
  { value: 'JMD', exponent: 2, label: 'JMD - Jamaican Dollar' },
  { value: 'JOD', exponent: 3, label: 'JOD - Jordanian Dinar' },
  { value: 'KES', exponent: 2, label: 'KES - Kenyan Shilling' },
  { value: 'KGS', exponent: 2, label: 'KGS - Som' },
  { value: 'KHR', exponent: 2, label: 'KHR - Riel' },
  { value: 'KMF', exponent: 0, label: 'KMF - Comoro Franc' },
  { value: 'KPW', exponent: 2, label: 'KPW - North Korean Won' },
  { value: 'KWD', exponent: 3, label: 'KWD - Kuwaiti Dinar' },
  { value: 'KYD', exponent: 2, label: 'KYD - Cayman Islands Dollar' },
  { value: 'KZT', exponent: 2, label: 'KZT - Tenge' },
  { value: 'LAK', exponent: 2, label: 'LAK - Kip' },
  { value: 'LBP', exponent: 2, label: 'LBP - Lebanese Pound' },
  { value: 'LKR', exponent: 2, label: 'LKR - Sri Lanka Rupee' },
  { value: 'LRD', exponent: 2, label: 'LRD - Liberian Dollar' },
  { value: 'LSL', exponent: 2, label: 'LSL - Lesotho loti' },
  { value: 'LTL', exponent: 2, label: 'LTL - Lithuanian Litas' },
  { value: 'LVL', exponent: 2, label: 'LVL - Latvian Lats' },
  { value: 'LYD', exponent: 3, label: 'LYD - Libyan Dinar' },
  { value: 'MAD', exponent: 2, label: 'MAD - Moroccan Dirham' },
  { value: 'MDL', exponent: 2, label: 'MDL - Moldovan Leu' },
  { value: 'MGA', exponent: 2, label: 'MGA - Malagasy Ariary' },
  { value: 'MKD', exponent: 2, label: 'MKD - Denar' },
  { value: 'MMK', exponent: 2, label: 'MMK - Kyat' },
  { value: 'MNT', exponent: 2, label: 'MNT - Tugrik' },
  { value: 'MOP', exponent: 2, label: 'MOP - Pataca' },
  { value: 'MRO', exponent: 2, label: 'MRO - Ouguiya' },
  { value: 'MUR', exponent: 2, label: 'MUR - Mauritius Rupee' },
  { value: 'MVR', exponent: 2, label: 'MVR - Rufiyaa' },
  { value: 'MWK', exponent: 2, label: 'MWK - Kwacha' },
  { value: 'MXN', exponent: 2, label: 'MXN - Mexican peso' },
  { value: 'MYR', exponent: 2, label: 'MYR - Malaysian Ringgit' },
  { value: 'MZN', exponent: 2, label: 'MZN - Metical' },
  { value: 'NAD', exponent: 2, label: 'NAD - Namibian Dollar' },
  { value: 'NGN', exponent: 2, label: 'NGN - Naira' },
  { value: 'NIO', exponent: 2, label: 'NIO - Cordoba Oro' },
  { value: 'NOK', exponent: 2, label: 'NOK - Norwegian Krone' },
  { value: 'NPR', exponent: 2, label: 'NPR - Nepalese Rupee' },
  { value: 'NZD', exponent: 2, label: 'NZD - New Zealand Dollar' },
  { value: 'OMR', exponent: 3, label: 'OMR - Rial Omani' },
  { value: 'PEN', exponent: 2, label: 'PEN - Nuevo Sol' },
  { value: 'PGK', exponent: 2, label: 'PGK - Kina' },
  { value: 'PHP', exponent: 2, label: 'PHP - Philippine Peso' },
  { value: 'PKR', exponent: 2, label: 'PKR - Pakistan Rupee' },
  { value: 'PLN', exponent: 2, label: 'PLN - Zloty' },
  { value: 'PYG', exponent: 0, label: 'PYG - Guarani' },
  { value: 'QAR', exponent: 2, label: 'QAR - Qatari Rial' },
  { value: 'RON', exponent: 2, label: 'RON - New Leu' },
  { value: 'RSD', exponent: 2, label: 'RSD - Serbian Dinar' },
  { value: 'RUB', exponent: 2, label: 'RUB - Russian Ruble' },
  { value: 'RWF', exponent: 0, label: 'RWF - Rwanda Franc' },
  { value: 'SAR', exponent: 2, label: 'SAR - Saudi Riyal' },
  { value: 'SBD', exponent: 2, label: 'SBD - Solomon Islands Dollar' },
  { value: 'SCR', exponent: 2, label: 'SCR - Seychelles Rupee' },
  { value: 'SDG', exponent: 2, label: 'SDG - Sudanese Pound' },
  { value: 'SEK', exponent: 2, label: 'SEK - Swedish Krona' },
  { value: 'SHP', exponent: 2, label: 'SHP - Saint Helena Pound' },
  { value: 'SKK', exponent: 2, label: 'SKK - Slovak Koruna' },
  { value: 'SLL', exponent: 2, label: 'SLL - Leone' },
  { value: 'SOS', exponent: 2, label: 'SOS - Somali Shilling' },
  { value: 'SRD', exponent: 2, label: 'SRD - Surinam Dollar' },
  { value: 'STD', exponent: 2, label: 'STD - Dobra' },
  { value: 'SYP', exponent: 2, label: 'SYP - Syrian Pound' },
  { value: 'SZL', exponent: 2, label: 'SZL - Lilangeni' },
  { value: 'THB', exponent: 2, label: 'THB - Baht' },
  { value: 'TJS', exponent: 2, label: 'TJS - Somoni' },
  { value: 'TMM', exponent: 2, label: 'TMM - Manat' },
  { value: 'TND', exponent: 3, label: 'TND - Tunisian Dinar' },
  { value: 'TOP', exponent: 2, label: 'TOP - Paanga' },
  { value: 'TRY', exponent: 2, label: 'TRY - Turkish Lira' },
  { value: 'TTD', exponent: 2, label: 'TTD - Trinidad and Tobago Dollar' },
  { value: 'TVD', exponent: 2, label: 'TVD - Tuvalu dollar' },
  { value: 'TWD', exponent: 2, label: 'TWD - New Taiwan Dollar' },
  { value: 'TZS', exponent: 2, label: 'TZS - Tanzanian Shilling' },
  { value: 'UAH', exponent: 2, label: 'UAH - Hryvnia' },
  { value: 'UGX', exponent: 0, label: 'UGX - Uganda Shilling' },
  { value: 'UYU', exponent: 2, label: 'UYU - Uruguayan peso' },
  { value: 'UZS', exponent: 2, label: 'UZS - Uzbekistan Sum' },
  { value: 'VEF', exponent: 2, label: 'VEF - Bolivar Fuerte' },
  { value: 'VND', exponent: 0, label: 'VND - Dong' },
  { value: 'VUV', exponent: 0, label: 'VUV - Vatu' },
  { value: 'WST', exponent: 2, label: 'WST - Tala' },
  { value: 'XAF', exponent: 0, label: 'XAF - CFA franc BEAC' },
  { value: 'XCD', exponent: 2, label: 'XCD - East Caribbean Dollar' },
  { value: 'XDR', exponent: 2, label: 'XDR - SDR' },
  { value: 'XFO', exponent: 2, label: 'XFO - Gold-Franc' },
  { value: 'XFU', exponent: 2, label: 'XFU - UIC-Franc' },
  { value: 'XOF', exponent: 0, label: 'XOF - CFA Franc BCEAO' },
  { value: 'XPD', exponent: 2, label: 'XPD - Palladium' },
  { value: 'XPF', exponent: 0, label: 'XPF - CFP Franc' },
  { value: 'XPT', exponent: 2, label: 'XPT - Platinum' },
  { value: 'YER', exponent: 2, label: 'YER - Yemeni Rial' },
  { value: 'ZAR', exponent: 2, label: 'ZAR - Rand' },
  { value: 'ZMK', exponent: 2, label: 'ZMK - Kwacha' },
  { value: 'ZWD', exponent: 2, label: 'ZWD - Zimbabwe Dollar' }
]

export { CurrencyData, currencies as default }
