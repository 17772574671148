export const getSurveyedProperty = (surveys, property) =>
  surveys.reduce((acc, s) => {
    if (!acc.includes(s[property])) {
      acc.push(s[property])
    }
    return acc
  }, [])

export const arrayToSelectOptions = arr =>
  arr.map(i => ({
    value: i,
    label: i
  }))
