const SUPPORTED_FILE_ICONS = {
  '3ds': true,
  aac: true,
  ai: true,
  avi: true,
  bmp: true,
  cad: true,
  cdr: true,
  css: true,
  csv: true,
  dat: true,
  dll: true,
  dmg: true,
  doc: true,
  docx: true,
  eps: true,
  fla: true,
  flv: true,
  gif: true,
  html: true,
  indd: true,
  iso: true,
  jpg: true,
  jpeg: true,
  js: true,
  json: true,
  midi: true,
  mov: true,
  mp3: true,
  mpg: true,
  pdf: true,
  php: true,
  png: true,
  ppt: true,
  pptx: true,
  ps: true,
  psd: true,
  raw: true,
  sql: true,
  svg: true,
  tif: true,
  txt: true,
  wmv: true,
  xls: true,
  xlsx: true,
  xml: true,
  zip: true
}

export default SUPPORTED_FILE_ICONS
