import { LegalEntitySelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { formatMultiSelectLabel } from '../helpers'

const LegalEntityFilter = ({
  onSelect,
  reset,
  isMulti,
  limit,
  hasConfirmation,
  title = 'Legal Entity',
  initialValue,
  paginated,
  noPlural
}) => {
  return (
    <SelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti={isMulti}
      customLabel={formatMultiSelectLabel(isMulti, title, noPlural)}
      hasConfirmation={hasConfirmation}
      render={(legalEntity, setLegalEntity) => {
        return (
          <LegalEntitySelect
            value={legalEntity}
            onChange={item => {
              setLegalEntity(item)
            }}
            isPortal={false}
            isClearable
            isMulti={isMulti}
            limit={limit}
            placeholder={`Search ${title}`}
            paginated={paginated}
          />
        )
      }}
    />
  )
}

export default LegalEntityFilter
