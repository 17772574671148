import BaseAsyncSelect from './BaseAsyncSelect'
import get from 'lodash/get'
import { makeGetRequest } from 'utils/api'
import { PURCHASE_ORDER_URL } from '../urls'
import { PaginatedSelect } from 'simple-core-ui'
import { toPurchaseOrderSelect } from '../serializers'

const loadOptionsSelect2Json = (url, extraParams = {}) => async search => {
  const params = search ? { params: { search, ...extraParams } } : null
  const result = await makeGetRequest(url, params)
  return get(result, 'options', [])
}

const PurchaseOrderSelect = ({
  value,
  isClearable,
  onChange,
  fetchCallback,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  placeholder = 'Search purchase order...',
  isDisabled,
  paginated = false,
  serializer,
  defaultOptions,
  defaultAction,
  id,
  withNoneOption
}) => {
  return paginated ? (
    <PaginatedSelect
      url={PURCHASE_ORDER_URL}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toPurchaseOrderSelect}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      isDisabled={isDisabled}
      placeholder={placeholder}
      limit={limit}
      resultsProperty="options"
      withNoneOption={withNoneOption}
    />
  ) : (
    <BaseAsyncSelect
      placeholder={placeholder}
      limit={limit}
      onChange={onChange}
      value={value}
      isMulti={isMulti}
      loadOptions={loadOptionsSelect2Json(PURCHASE_ORDER_URL)}
      isClearable={isClearable}
      isPortal={isPortal}
      isDisabled={isDisabled}
    />
  )
}

export default PurchaseOrderSelect
