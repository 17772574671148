import { reorderFields } from 'bulk/serializer'
import s from './FieldsList.scss'
import { useEffect, useState } from 'react'
import CheckboxField from './CheckboxField/CheckboxField'
import { CUSTOM_FIELDS_LIMIT } from 'bulk/constants'

const FieldsList = ({ id, type, fields, setFields, recType }) => {
  const [fieldList, setFieldList] = useState([])
  const [totalSelectedFields, setTotalSelectedFields] = useState(0)
  const [limitCustomFields, setLimitCustomFields] = useState(false)

  const setFieldsInfo = () => {
    const list = fields.filter(f =>
      id === 'general' ? ['general', 'default'].includes(f.type) : f.type === 'custom'
    )
    setFieldList(reorderFields(list, recType, type === 'filter'))
    setTotalSelectedFields(list.filter(f => f.isSelected).length)
  }

  useEffect(() => {
    setFieldsInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  useEffect(() => {
    if (id === 'custom') {
      const list = fields.filter(f => f.type === 'custom' && !f.isDefault && f.isSelected)
      setLimitCustomFields(list.length >= CUSTOM_FIELDS_LIMIT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldList])

  const onSelectAll = () => {
    const selected = fields.map(f =>
      ['general', 'default'].includes(f.type) && !f.hideOption
        ? { ...f, ...(!f.hideOption ? { isSelected: true } : {}) }
        : f
    )
    setFields(selected)
  }

  const onClearAll = () => {
    const cleared = fields.map(f =>
      !f.hideOption &&
      ((id === 'general' && ['general', 'default'].includes(f.type)) ||
        (id === 'custom' && f.type === 'custom'))
        ? {
            ...f,
            ...(!f.isDefault || type === 'filter' ? { isSelected: false } : {})
          }
        : f
    )
    setFields(cleared)
  }

  return (
    <div className={s.container}>
      <div className={s.header}>
        <p className={s.listTitle}>
          <span className={s.main}>{id === 'general' ? 'General' : 'Custom'}</span>
          <span className={s.suffix}>{` (${totalSelectedFields} Selected${
            id === 'custom' ? ` - Select up to ${CUSTOM_FIELDS_LIMIT}` : ''
          })`}</span>
        </p>
        <div className={s.actionButtons}>
          {id === 'general' ? <a onClick={onSelectAll}>Select All</a> : ''}
          <a onClick={onClearAll}>Clear All</a>
        </div>
      </div>
      <div className={s.listContainer}>
        {fieldList
          .filter(f => !f.hideOption)
          .map(field => (
            <CheckboxField
              currentField={field}
              allFields={fields}
              setFields={setFields}
              key={field.value}
              limitCustomFields={limitCustomFields}
            />
          ))}
      </div>
    </div>
  )
}

export default FieldsList
