import { useDrag, useDrop } from 'react-dnd'
import * as ACT from 'matters/templates/actions'
import isEmpty from 'lodash/isEmpty'
import s from './AttrGroupDragDrop.scss'
import { useDispatch } from 'react-redux'

const AttrGroupDragDrop = props => {
  const dispatch = useDispatch()
  const { section, attrIndex, attribute, type, children, sectionIndex } = props
  const [, drag] = useDrag(
    () => ({
      type: 'TemplateAttributeGroup',
      item: {
        section,
        sectionIndex,
        type,
        action: 'reorder'
      },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging()
        }
      }
    }),
    [sectionIndex, section]
  )
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['AttributeGroup', 'TemplateAttributeGroup'],
      drop: (item, monitor) => {
        // from => item
        // to => props

        if (isEmpty(item.section) && isEmpty(item.attributes)) {
          return {}
        }

        if (item.action === 'reorder') {
          const sectionItem = {
            sectionName: item.section.sectionName,
            attributes: item.section.attributes,
            displayExpanded: item.section.displayExpanded
          }

          dispatch({
            type: ACT.MATTER_TEMPLATES_REORDER_ATTRIBUTE_GROUP,
            payload: {
              from: {
                type: item.type,
                item: sectionItem,
                sectionIndex: item.sectionIndex
              },
              to: {
                type: type,
                item: sectionItem,
                sectionIndex: sectionIndex
              }
            }
          })
        }

        if (item.action === 'addGroup') {
          dispatch({
            type: ACT.MATTER_TEMPLATES_ADD_ATTRIBUTE_GROUP,
            payload: {
              type: type,
              item: item,
              sectionIndex: sectionIndex
            }
          })
        }

        return {}
      },
      collect: monitor => {
        return {
          isOver: monitor.isOver()
        }
      }
    }),
    [sectionIndex, section]
  )

  return (
    <div
      ref={node => drag(drop(node))}
      className={isOver ? s.overDropzone : s.dropzone}
      data-testid="drop_zone"
    >
      {children}
    </div>
  )
}

export default AttrGroupDragDrop
