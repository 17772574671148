import { Component } from 'react'

class WithDetailsTable extends Component {
  state = {
    showTable: false,
    count: 0
  }
  componentDidUpdate(prevProps, prevState) {
    const { count } = this.props
    if (count !== this.state.count && this.state.showTable) {
      this.toggleDetails()
    }
    if (prevProps.count !== prevState.count) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        count
      })
    }
  }
  toggleDetails = () => {
    this.setState(prevState => ({
      showTable: !prevState.showTable
    }))

    this.props.toggleCallback && this.props.toggleCallback()
  }
  render() {
    return this.props.children(this.state.showTable, this.toggleDetails)
  }
}

export default WithDetailsTable
