import classNames from 'classnames'
import { InfoBoxContainer } from 'simple-core-ui'
import { PiWarning } from 'react-icons/pi'

import { Hoverable } from 'components'
import { slColors } from 'styles/cssInJs'

import Contact from '../Contact/Contact'

import s from './SingularRoleWarning.scss'

const SingularRoleWarning = ({ role, contact, hasVendorCollaboration = false }) => {
  return hasVendorCollaboration ? (
    <p className={s.warningMessage}>
      <PiWarning />{' '}
      {`Re-assigning this label will remove the label from ${contact.firstName} ${contact.lastName}. The label
      ${role.label} can only be assigned to one contact.`}
    </p>
  ) : (
    <InfoBoxContainer
      style={{ color: slColors.darkerBlue }}
      icon={<i className={classNames('simple-line-icon-info', s.icon)} />}
      message={
        <>
          Re-assigning this role will remove the role from{' '}
          <Hoverable
            content={
              <section className={s.contact}>
                <Contact
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  title={contact.title}
                  company={contact.company}
                  phone={contact.phone}
                  email={contact.email}
                  readonly={contact.readonly}
                />
              </section>
            }
          >
            <strong className={s.hint}>{`${contact.firstName} ${contact.lastName}`}</strong>
          </Hoverable>
          .
        </>
      }
      details={
        <span>
          The role <strong>{role.label}</strong> can only be assigned to one contact.
        </span>
      }
    />
  )
}

export default SingularRoleWarning
