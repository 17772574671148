import * as React from 'react'

import { Filterable } from 'components'

class FilterableContainer extends React.Component {
  static defaultProps = {
    placeholder: 'Search...'
  }

  state = {
    filter: ''
  }

  changeHandler = event => {
    this.setState({
      filter: event.currentTarget.value
    })
  }

  render() {
    return <Filterable {...this.props} {...this.state} changeHandler={this.changeHandler} />
  }
}

export default FilterableContainer
