import { useState, useEffect, useMemo } from 'react'
import { DataTableWrapper, Button, Ellipsis } from 'simple-core-ui'
import { getSimpleBudgetV2Columns } from '../tableDefinitions'
import { useSelector, useDispatch } from 'react-redux'
import BHPill from 'budgets/detail_v2/components/BHPill'
import ACT from 'budgets/actions'
import CreateBudgetModal from './CreateBudgetModal'
import { useMatterGroupLabels } from 'hooks/selectors'

function SimpleBudgetList({ initialParams, canAddBudget, scope, scopeId, scopeName, readOnly }) {
  const dispatch = useDispatch()
  const isLoading = useSelector(({ app }) => app?.loading?.ACTIVE_BUDGETS_LIST_FETCH)
  const params = useSelector(state => state?.budgets?.activeListParams)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const matterGroupLabels = useMatterGroupLabels()

  const { rows: rowsList = [], totalEntries = 0, filteredEntries = 0 } = useSelector(
    state => state?.budgets?.activeBudgets
  )

  const rows = useMemo(() => {
    return rowsList.map(
      ({
        id,
        budgetName,
        budgetUrl,
        matterName,
        vendorName,
        mattergroupName,
        costcodeName,
        budgetType,
        budgetAmount,
        startDate,
        endDate,
        remainingTotal,
        matterleadName
      }) => ({
        id,
        cells: [
          {
            columnKey: 'budgetName',
            content: (
              <a href={budgetUrl}>
                <Ellipsis>{budgetName || '---'}</Ellipsis>
              </a>
            )
          },
          ...(scope !== 'matter'
            ? [
                {
                  content: <Ellipsis>{matterName || '---'}</Ellipsis>,
                  columnKey: 'budgetMatter'
                }
              ]
            : []),
          ...(scope !== 'matter'
            ? [
                {
                  content: <Ellipsis>{matterleadName || '---'}</Ellipsis>,
                  columnKey: 'matterleadName'
                }
              ]
            : []),
          {
            content: <Ellipsis>{vendorName || '---'}</Ellipsis>,
            columnKey: 'vendorName'
          },
          {
            content: <Ellipsis>{mattergroupName || '---'}</Ellipsis>,
            columnKey: 'mattergroupName'
          },
          {
            content: <Ellipsis>{costcodeName || '---'}</Ellipsis>,
            columnKey: 'costcodeName'
          },
          {
            content: <Ellipsis>{budgetType || '---'}</Ellipsis>,
            columnKey: 'budgetType'
          },
          {
            content: <Ellipsis>{startDate || '---'}</Ellipsis>,
            columnKey: 'budgetStartDate'
          },
          {
            content: <Ellipsis>{endDate || '---'}</Ellipsis>,
            columnKey: 'budgetEndDate'
          },
          {
            content: <Ellipsis>{budgetAmount || '---'}</Ellipsis>,
            columnKey: 'amounts'
          },
          {
            content: <BHPill isInBudget={remainingTotal >= 0} />,
            columnKey: 'budgetHealth'
          }
        ]
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsList])

  useEffect(() => {
    dispatch({
      type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      disableLoadingCursor: true,
      payload: {
        disableFragments: true,
        params: {
          ...(initialParams || params),
          category: 'Approved'
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = tableOpts => {
    dispatch({
      type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      disableLoadingCursor: true,
      payload: {
        disableFragments: true,
        params: {
          ...params,
          ...tableOpts,
          category: 'Approved'
        }
      }
    })
  }

  return (
    <div className="box">
      <div className="box-header">
        <h2>Approved Budgets</h2>
        {(scope === 'matter' || canAddBudget) && !readOnly && (
          <Button ariaLabel="Add Budget" isPrimary onClick={() => setIsModalOpen(true)}>
            Add Budget
          </Button>
        )}
      </div>
      <div className="box-content">
        <DataTableWrapper
          initialValues={params}
          isLoading={isLoading}
          hasActions
          hasTooltip
          remotePagination
          categories={[]}
          totalEntries={filteredEntries}
          params={params}
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          columns={getSimpleBudgetV2Columns(matterGroupLabels, scope)}
          updateTable={updateTable}
          rows={rows}
        />
      </div>
      {(scope === 'matter' || canAddBudget) && (
        <CreateBudgetModal
          scope={scope}
          scopeId={scopeId}
          scopeName={scopeName}
          isVisible={isModalOpen}
          setIsVisible={setIsModalOpen}
        />
      )}
    </div>
  )
}

export default SimpleBudgetList
