export const toPermissions = permissions =>
  permissions.map(perm => ({
    value: perm.id,
    label: perm.name,
    read: perm.perms.find(p => p.name === 'read').granted,
    edit: perm.perms.find(p => p.name === 'edit').granted
  }))

export const toClientTeams = teams =>
  teams.map(team => ({
    value: team.id,
    label: team.name
  }))
