import s from './Thermometer.scss'
import { isNumberRenderable } from 'utils/helpers'

const DataTip = ({
  colors,
  approved,
  pending,
  accrual,
  total,
  approvedPercentage,
  pendingPercentage,
  accrualPercentage,
  totalPercentage
}) => {
  return (
    <div className={s.dataTip}>
      <ul>
        <li>
          <div
            className={s.legendCircle}
            style={{
              background: colors.APPROVED
            }}
          />
          <span>
            Approved: {approved}{' '}
            {isNumberRenderable(approvedPercentage) ? `(${approvedPercentage}%)` : ''}
          </span>
        </li>
        <li>
          <div
            className={s.legendCircle}
            style={{
              background: colors.PENDING
            }}
          />
          <span>
            Pending: {pending}{' '}
            {isNumberRenderable(pendingPercentage) ? `(${pendingPercentage}%)` : ''}
          </span>
        </li>
        {accrual && (
          <li>
            <div
              className={s.legendCircle}
              style={{
                background: colors.ACCRUAL
              }}
            />
            <span>
              Accruals: {accrual}{' '}
              {isNumberRenderable(accrualPercentage) ? `(${accrualPercentage}%)` : ''}
            </span>
          </li>
        )}
      </ul>
    </div>
  )
}

export default DataTip
