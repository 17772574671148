import FiltersContainer, { SelectFilter } from 'common/Filters'
import Select from 'react-select'

const Filters = ({ filterParams, onSearch }) => {
  const { status } = filterParams
  return (
    <FiltersContainer
      onSearch={onSearch}
      filterParams={filterParams}
      render={(reset, filterSearch) => {
        return (
          <SelectFilter
            title="Status"
            reset={reset}
            onSelect={value => onSearch(value, 'status')}
            initialValue={status}
            render={(status, setStatus) => {
              return (
                <Select
                  value={status}
                  options={[
                    {
                      value: 'active',
                      label: 'Active'
                    },
                    {
                      value: 'inactive',
                      label: 'Not Active'
                    },
                    {
                      value: 'all',
                      label: 'All'
                    }
                  ]}
                  onChange={item => {
                    setStatus(item)
                  }}
                  isPortal={false}
                  isClearable
                />
              )
            }}
          />
        )
      }}
    />
  )
}

export default Filters
