import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import groupBy from 'lodash/groupBy'
import { STANDARD_REPORTS } from './constants'

const initialState = {
  users: [],
  clientUsers: [],
  reports: [],
  sortedKeys: [],
  groupedReports: {}
}

const getGroupedReports = reports => {
  const reportsWithCategory = reports.map(rep => {
    const sIndex = rep.name.indexOf(':')
    if (sIndex === -1) {
      return {
        ...rep,
        description: '---',
        category: 'Other'
      }
    }
    const category = rep.name.slice(0, sIndex).trim()
    const name = rep.name.slice(sIndex + 2).trim()
    const description = STANDARD_REPORTS[name] || '---'
    return {
      ...rep,
      name,
      description,
      category
    }
  })

  const groupedReports = groupBy(reportsWithCategory, ({ name, category }) => {
    return category || 'Other'
  })

  return groupedReports
}

const tableauReducer = createReducer(initialState, {
  [ACT.ADD_USER_SUCCESS](state, action) {
    const { user } = action.payload
    return update(state, {
      users: {
        $push: [user]
      }
    })
  },
  [ACT.USERS_FETCH_SUCCESS](state, action) {
    const { users, clientUsers } = action.payload
    return {
      ...state,
      users,
      clientUsers
    }
  },
  [ACT.DELETE_USER_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.users.findIndex(c => c.id === id)
    return update(state, {
      users: {
        $splice: [[index, 1]]
      }
    })
  },

  [ACT.EDIT_USER_SUCCESS](state, action) {
    const { user } = action.payload
    const index = state.users.findIndex(c => c.id === user.id)
    return update(state, {
      users: {
        [index]: { $set: user }
      }
    })
  },
  [ACT.REPORTS_FETCH_SUCCESS](state, action) {
    const { reports } = action.payload

    const groupedReports = getGroupedReports(reports)

    // don't know of a cleaner way of doing this for now,
    // want to have specific ordering...
    // Groups coming from tableau -> order we want it:
    // Audit, Spend, Matters -> Spend, Matters, Audit
    const order = ['Audit', 'Matters', 'Spend']
    const sortedKeys = Object.keys(groupedReports).sort(
      (a, b) => order.indexOf(a) - order.indexOf(b)
    )

    return {
      ...state,
      groupedReports,
      sortedKeys,
      reports,
      isLoading: false
    }
  },
  [ACT.FILTER_REPORTS_LIST](state, action) {
    const { query } = action.payload

    const filteredPages = state.reports.filter(report =>
      report.name.toLowerCase().includes(query.toLowerCase())
    )

    const groupedReports = getGroupedReports(filteredPages)

    return {
      ...state,
      groupedReports
    }
  },
  [ACT.REPORTS_FETCH_REQUESTED](state, action) {
    return {
      ...state,
      isLoading: true
    }
  }
})

export default tableauReducer
