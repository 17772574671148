import s from '../Diversity.scss'

function TkReportCard({ hasMargin, value, preferNotToSay, label, title, noData, no }) {
  return (
    <div className={`${s.cardContainer} ${hasMargin ? s.mr16 : ''}`}>
      <div>
        <h3 className={s.cardTitle}>{title} Tk report card</h3>
        <p className={s.dateLabel}>{label}</p>
        <h1 className={s.cardMainInfo}>{value}%</h1>
      </div>
      <div>
        <p className={s.cardSmallLetters}>{no}% no</p>
        <p className={s.cardSmallLetters}>{preferNotToSay}% prefer to not say</p>
        <p className={s.cardSmallLetters}>{noData}% no data</p>
      </div>
    </div>
  )
}

export default TkReportCard
