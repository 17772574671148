import { UserSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { getCustomSelectLabel } from '../helpers'

const UserFilter = ({
  onSelect,
  reset,
  title = 'Uploaded By',
  hasConfirmation,
  initialValue,
  isMulti,
  limit,
  paginated,
  withNoneOption
}) => {
  return (
    <SelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti={isMulti}
      hasConfirmation={hasConfirmation}
      customLabel={selected => getCustomSelectLabel(selected, title)}
      render={(user, setUser) => {
        return (
          <UserSelect
            value={user}
            isMulti={isMulti}
            onChange={item => {
              setUser(item)
            }}
            isPortal={false}
            isClearable
            limit={limit}
            paginated={paginated}
            withNoneOption={withNoneOption}
          />
        )
      }}
    />
  )
}

export default UserFilter
