import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'simple-core-ui'

import TeamsManagement from '../TeamsManagement/TeamsManagement'

const TeamsRouter = () => (
  <Routes>
    <Route path="/" element={<TeamsManagement />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default TeamsRouter
