import { useEffect, useState } from 'react'
import s from './File.scss'
import { ChartInfo } from './ChartInfo'
import { useFilesUpload, Alert } from 'simple-core-ui'
import cn from 'classnames'
import axios from 'axios'

const CancelToken = axios.CancelToken

const File = ({
  file,
  dispatch,
  successCb,
  selectedRelationship,
  setIsUploadFinished,
  setFileSource
}) => {
  const [percent, setPercent] = useState(0)
  const [error, setError] = useState(false)
  const [source] = useState(CancelToken.source())
  const [completed, setCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    uploadFile(file)
    setFileSource(source)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleError = ({ message }) => {
    setIsUploadFinished(true)
    setError(true)
    setErrorMessage(message)
  }

  const uploadFile = file => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFilesUpload({
      uploadUrl: `/templates/related/import_pairs_mappings/${selectedRelationship.value}`,
      withS3Credentials: true,
      successCb: response => {
        successCb(response)
      },
      handleServerErrorCb: error => {
        handleError(error.response.data)
      },
      errorCb: () => {
        handleError()
      },
      s3successCb: () => {
        setIsUploadFinished(true)
        setCompleted(true)
      },
      config: {
        onUploadProgress: function(progressEvent) {
          setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        cancelToken: source.token
      },
      files: [file],
      lifespan: 'short'
    })
  }

  return (
    <div className={s.row}>
      <div className={s.chart}>
        <div
          className={cn(s.segment, {
            [s.error]: error
          })}
          style={{ width: `${error ? 100 : percent}%` }}
        />
        <ChartInfo completed={completed} error={error} file={file} percent={percent} />
      </div>
      {errorMessage && <Alert className={s.alert} message={errorMessage} status="error" />}
    </div>
  )
}

export default File
