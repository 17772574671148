import { Component } from 'react'
import { connect } from 'react-redux'

import { paramDefToExecParam } from 'rules/utils'
import RuleActions from '../components/RuleActions'
import ACT from 'rules/actions'

@connect(({ rules }, ownProps) => {
  const { engine } = rules
  const availableActions = rules[engine].availableActions
  const actions = rules[engine].rulesList[ownProps.ruleIndex].actions

  return {
    actions,
    availableActions
  }
})
export default class RuleActionsContainer extends Component {
  state = {
    selected: {}
  }

  updateRuleAction = ({ name, params }, actionIndex) => {
    this.props.dispatch({
      type: ACT.UPDATE_RULE_ACTION,
      payload: {
        ruleIndex: this.props.ruleIndex,
        action: { name, params: params.map(paramDefToExecParam) },
        actionIndex
      }
    })
  }

  removeRuleAction = actionIndex => {
    this.props.dispatch({
      type: ACT.REMOVE_RULE_ACTION,
      payload: {
        ruleIndex: this.props.ruleIndex,
        actionIndex
      }
    })
  }

  render() {
    const { actions, availableActions, createRuleAction, ruleIndex } = this.props

    return (
      <RuleActions
        actions={actions}
        ruleIndex={ruleIndex}
        selected={this.state.selected}
        availableActions={availableActions}
        updateRuleAction={this.updateRuleAction}
        createRuleAction={createRuleAction}
        removeRuleAction={this.removeRuleAction}
      />
    )
  }
}
