import s from './CourtRuleFilters.scss'
import cn from 'classnames'
import { Filter } from './Filter'
import {
  FilterOption,
  Filters as FiltersType,
  CourtRuleFilters as CourtRuleFiltersType,
  DateFormat
} from '../types'
import { toNamesOptions } from '../serializers'
import { OPERATORS } from '../constants'
import { formatDate } from '../utils'
import { CheckboxList, DateRangePopper, DropdownPopper, DueDatePicker } from 'simple-core-ui'

const { IS, IS_NOT, IS_SET, IS_NOT_SET, IS_BEFORE, IS_AFTER, IS_BETWEEN } = OPERATORS

interface Props {
  className?: string
  courtRuleFilters: CourtRuleFiltersType
  setCourtRuleFilters: (filters: CourtRuleFiltersType) => void
  clearFilters: () => void
  scopeId: string
}
interface InjectedProps {
  setFilters: (values: FilterOption[]) => void
  filters: FiltersType | null
}

const getPickerValue = (filters: InjectedProps['filters'], index: number) => {
  if (filters?.values && filters.values[index]) {
    return formatDate(filters.values[index].value) as DateFormat | undefined | Date
  }
  return undefined
}

const CourtRuleFilters = ({
  className,
  courtRuleFilters,
  setCourtRuleFilters,
  clearFilters,
  scopeId
}: Props) => {
  return (
    <div className={cn(s.filters, className)}>
      <Filter
        label="Court Rule Name"
        value={courtRuleFilters.courtRulesName}
        onConfirm={(value, cb) => {
          setCourtRuleFilters({ ...courtRuleFilters, courtRulesName: value })
          cb?.()
        }}
        operatorsList={[
          {
            label: 'Is',
            value: IS
          },
          {
            label: 'Is not',
            value: IS_NOT
          }
        ]}
      >
        {({ filters, setFilters }: InjectedProps) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select court rule(s)"
            label="court rule"
          >
            <CheckboxList
              url={`/event-management/calendar_rules/court-rule-names/${scopeId}`}
              value={filters?.values ?? []}
              onConfirm={setFilters}
              serialiser={toNamesOptions}
              isPaginated
              firstPageIndex={1}
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Jurisdiction"
        value={courtRuleFilters.jurisdiction}
        onConfirm={(value, cb) => {
          setCourtRuleFilters({ ...courtRuleFilters, jurisdiction: value })
          cb?.()
        }}
        operatorsList={[
          {
            label: 'Is',
            value: IS
          },
          {
            label: 'Is not',
            value: IS_NOT
          }
        ]}
      >
        {({ filters, setFilters }: InjectedProps) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select jurisdiction(s)"
            label="jurisdiction"
          >
            <CheckboxList
              url={`/event-management/calendar_rules/jurisdiction-names/${scopeId}`}
              value={filters?.values ?? []}
              onConfirm={setFilters}
              serialiser={toNamesOptions}
              isPaginated
              firstPageIndex={1}
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Trigger Event"
        value={courtRuleFilters.triggerEvent}
        onConfirm={(value, cb) => {
          setCourtRuleFilters({ ...courtRuleFilters, triggerEvent: value })
          cb?.()
        }}
        operatorsList={[
          {
            label: 'Is',
            value: IS
          },
          {
            label: 'Is not',
            value: IS_NOT
          }
        ]}
      >
        {({ filters, setFilters }: InjectedProps) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select trigger event(s)"
            label="trigger event"
          >
            <CheckboxList
              url={`/event-management/calendar_rules/trigger-names/${scopeId}`}
              value={filters?.values ?? []}
              onConfirm={setFilters}
              serialiser={toNamesOptions}
              isPaginated
              firstPageIndex={1}
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Trigger Date"
        value={courtRuleFilters.triggerDate}
        onConfirm={(value, cb) => {
          setCourtRuleFilters({ ...courtRuleFilters, triggerDate: value })
          cb?.()
        }}
        validation={(value: FiltersType | null) => {
          // return true and let the filter default validation take care of it
          if (!value?.operator || !value.values) return true
          if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
            return false
          }
          return true
        }}
        operatorsList={[
          {
            label: 'Is',
            value: IS
          },
          {
            label: 'Is between',
            value: IS_BETWEEN
          },
          {
            label: 'Is before',
            value: IS_BEFORE
          },
          {
            label: 'Is after',
            value: IS_AFTER
          },
          {
            label: 'Is set',
            value: IS_SET
          },
          {
            label: 'Is not set',
            value: IS_NOT_SET
          }
        ]}
        customLabel={() => {
          const filters = courtRuleFilters.triggerDate
          if (!filters) return 'Trigger Date'
          switch (filters.operator?.value) {
            case IS:
              return `Trigger Date is on ${formatDate(filters.values?.[0].value)}`
            case IS_BETWEEN:
              return `Trigger Date is between ${formatDate(
                filters.values?.[0].value
              )} - ${formatDate(filters.values?.[1].value)}`
            case IS_AFTER:
              return `Trigger Date is after ${formatDate(filters.values?.[0].value)}`
            case IS_BEFORE:
              return `Trigger Date is before ${formatDate(filters.values?.[0].value)}`
            case IS_SET:
              return 'Trigger Date is set'
            case IS_NOT_SET:
              return 'Trigger Date is not set'
            default:
              return 'Trigger Date'
          }
        }}
      >
        {({ filters, setFilters }: InjectedProps) => {
          return (
            <DateRangePopper value={filters?.values ?? []}>
              <DueDatePicker
                value={getPickerValue(filters, 0)}
                onConfirm={(value: DateFormat | undefined | Date) => {
                  setFilters([
                    ...(value
                      ? [
                          {
                            value: value?.toString() ?? '',
                            label: 'Start Date'
                          }
                        ]
                      : []),
                    ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                  ])
                }}
              />
              {filters?.operator?.value === IS_BETWEEN ? (
                <DueDatePicker
                  value={getPickerValue(filters, 1)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'End Date'
                            }
                          ]
                        : [])
                    ])
                  }}
                />
              ) : null}
            </DateRangePopper>
          )
        }}
      </Filter>
      <div className={s.reset} onClick={clearFilters}>
        Reset
      </div>
    </div>
  )
}

export default CourtRuleFilters
