import { useDrag } from 'react-dnd'

const Attribute = ({ attr, classes, label, title, connectDragSource, draggable }) => {
  const [, drag] = useDrag(() => ({
    type: 'Attribute',
    item: {
      attr,
      action: 'addAttr'
    },
    canDrag: () => draggable,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      }
    }
  }))

  return (
    <div
      ref={node => (draggable ? drag(node) : node)}
      className={classes}
      title={title}
      data-testid={label}
    >
      {label}
    </div>
  )
}

export default Attribute
