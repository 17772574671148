import { SimpleBudgetList } from 'budgets/list_v2'

const BudgetsTab = ({ scopeId, matterName, readOnly }) => {
  return (
    <SimpleBudgetList
      canAddBudget
      scopeId={scopeId}
      scope="matter"
      scopeName={matterName}
      initialParams={{
        pageSize: 10,
        ordering: { columnKey: 'startDate', isDesc: true },
        search: '',
        page: 1,
        matter: [{ value: scopeId }]
      }}
      readOnly={readOnly}
    />
  )
}

export default BudgetsTab
