import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get'
import { makePostRequest, makePutRequest, makePatchRequest, getRequest } from 'utils/api'
import {
  navigateToProcessRequestsPage,
  removeAttributeOptionsAndDefaultOptions
} from 'matters/templates/utils'

import * as ACT from './actions'
import { SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS } from 'matters/detail/vendors/Surveys/SurveyConfigTab/actions'
import { MATTER_GROUP_ID, LEGAL_ENTITY_ID, SELECT_TEMPLATE_FLOW } from './constants'
import {
  fromAllocationsJson,
  toMatterTemplateJson,
  fromMatterTemplatePreviewJson,
  removeTrailingZerosAndFormatValues,
  emptyValuesTemplate
} from './serializers'

import { NotificationList } from 'components'
import cloneDeep from 'lodash/cloneDeep'

const getDrawerPayload = userFlowState => {
  let payload
  if (userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE) {
    payload = call(fetchMatterAttributes, {
      type: ACT.MATTER_ATTRIBUTES_FETCH_REQUESTED,
      payload: { id: userFlowState.id }
    })
  } else if (userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST) {
    payload = call(fetchLegalRequest, {
      type: ACT.LEGAL_REQUEST_FETCH_REQUESTED,
      payload: { id: userFlowState.id }
    })
  } else if (userFlowState.userFlow === SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION) {
    payload = call(fetchDefaultMatterAttributes, {
      type: ACT.MATTER_ATTRIBUTES_DEFAULT_FETCH_REQUESTED
    })
  }

  return payload
}

function* fetchTemplateAllocatableAttributes(action) {
  try {
    const url = '/templates/attributes/allocatable'
    const attributes = yield call(getRequest, url)

    yield put({
      type: ACT.MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_SUCCESS,
      payload: { attributes }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Template Allocatable Attributes',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplatesList(action) {
  const { latestTemplateId, formId } = action.payload
  try {
    const url = `/templates/`
    let list = yield call(getRequest, url)

    let payload = { list, latestTemplateId }
    if (formId) {
      const form_fetch_url = `/manage/matters/requests/request_form/${formId}/`
      const form = yield call(getRequest, form_fetch_url)
      const selectedTemplatesIds = form.selectedTemplates.map(t => t.id)
      list.templates = list.templates.map(template => ({
        ...template,
        belongs_to_form: !!selectedTemplatesIds.find(id => id === template.id)
      }))
      payload = { ...payload, formId }
    }
    yield put({ type: ACT.MATTER_TEMPLATE_LIST_FETCH_SUCCESS, payload: payload })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter Templates list',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchValuesForTemplate(action) {
  try {
    const { id, selectedValues, template, userFlowState } = action.payload
    const mrValuesForTemplateUrl = `/manage/matters/requests/template/values/json/${id}/`

    const mrValuesForTemplate = yield makePostRequest(mrValuesForTemplateUrl, {
      data: selectedValues.map(attr => {
        // ugly fix for https://simplelegal.atlassian.net/browse/MV-1928
        const attribute = cloneDeep(attr)
        removeAttributeOptionsAndDefaultOptions(attribute)

        if (attribute.type !== 'relationship') return attribute

        return {
          ...attribute,
          class: 'RelAttrs',
          attributes: attribute.attributes.map(attr => ({
            ...attr,
            class: 'Other_Attribute_Value_List'
          }))
        }
      })
    })
    const formattedJson = fromMatterTemplatePreviewJson(
      template,
      selectedValues,
      mrValuesForTemplate
    )

    const templateJson = formattedJson.templateJson
    if (userFlowState && userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST) {
      yield put({
        type: ACT.MATTER_TEMPLATE_APPEND_ADDITIONAL_ATTRIBUTES,
        payload: { additionalAttributes: formattedJson.additionalAttributes }
      })
    }
    yield put({
      type: ACT.MATTER_TEMPLATE_FETCH_SUCCESS,
      payload: { template: templateJson },
      loadingLock: 'off'
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'There was an issue fetching Matter Template',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplatesFilteredAttributes(action) {
  const { ids, userFlowState, templateId } = action.payload

  try {
    const url = `/templates/attributes/${templateId || -1}/${ids[MATTER_GROUP_ID]}/${
      ids[LEGAL_ENTITY_ID]
    }/`
    const groups = yield call(getRequest, url)

    yield put({ type: ACT.MATTER_FILTERED_ATTRIBUTES_FETCH_SUCCESS, payload: { groups } })

    if (
      (userFlowState && userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST) ||
      !userFlowState
    ) {
      yield put({
        type: ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_SUCCESS,
        payload: { groups }
      })
    }
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter Templates attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplate(action) {
  const { userFlowState, navigate, id } = action.payload
  let payload

  if (userFlowState) {
    payload = yield getDrawerPayload(userFlowState)
  }
  try {
    const attributes = payload ? payload.attributes : {}
    const url = `/templates/${id}/`
    const mrValuesForTemplateUrl = `/manage/matters/requests/template/values/json/${id}/`
    const template = yield call(getRequest, url)
    let templateJson = removeTrailingZerosAndFormatValues(template)
    const isSimpleMatterCreationOrChangeMatterTemplate = userFlowState
      ? [
          SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION,
          SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
        ].includes(userFlowState.userFlow)
      : false
    const isMatterTemplatesSelection = userFlowState
      ? [
          SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION,
          SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE,
          SELECT_TEMPLATE_FLOW.MATTER_REQUEST
        ].includes(userFlowState.userFlow)
      : false

    if (attributes.data && attributes.data.length && isMatterTemplatesSelection) {
      // ugly fix for https://simplelegal.atlassian.net/browse/MV-1928
      const data = Array.isArray(attributes.data)
        ? attributes.data.map(attr => {
            const attribute = cloneDeep(attr)
            removeAttributeOptionsAndDefaultOptions(attribute)
            return attribute
          })
        : attributes.data
      const mrValuesForTemplate = yield makePostRequest(mrValuesForTemplateUrl, { data })
      const formattedJson = fromMatterTemplatePreviewJson(
        userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
          ? emptyValuesTemplate(templateJson)
          : templateJson,
        attributes.data,
        mrValuesForTemplate
      )
      templateJson = formattedJson.templateJson

      yield put({
        type: ACT.MATTER_TEMPLATE_APPEND_ADDITIONAL_ATTRIBUTES,
        payload: { additionalAttributes: formattedJson.additionalAttributes }
      })
    }

    yield put({
      type: ACT.MATTER_TEMPLATE_FETCH_SUCCESS,
      payload: { template: templateJson },
      loadingLock: 'off'
    })

    yield put({
      type: SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS,
      payload: {
        sharedEvaluators: templateJson.survey_config
      }
    })

    if (isSimpleMatterCreationOrChangeMatterTemplate) {
      yield put({ type: ACT.MATTER_ATTRIBUTES_RESET_DEFAULTS_IN_DRAWER })
      yield put({
        type: ACT.MATTER_ATTRIBUTES_SET_DEFAULTS_IN_DRAWER,
        payload: { template: templateJson, userFlowState }
      })
    }
    if (userFlowState && userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST) {
      yield put({
        type: ACT.LEGAL_REQUEST_MAKE_ATTRIBUTES_READ_ONLY,
        payload: { template }
      })
    }
  } catch (error) {
    if (error.response.status === 404) {
      navigate('/v2/matters/templates/not_found')
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        loadingLock: 'off',
        payload: {
          title: 'There was an issue fetching Matter Template',
          message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
          level: 'error'
        }
      })
    }
  }
}

function* updateMatterTemplateStatus(action) {
  const { template, id, onSuccessActionType, redirect, navigate } = action.payload

  try {
    const url = `/templates/${id}/`
    yield makePatchRequest(url, template)

    if (onSuccessActionType) {
      yield put({ type: ACT[onSuccessActionType], payload: { id } })
    }
    if (redirect) {
      navigate('/v2/matters/templates/list')
    }
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue deleting the Matter Template',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* selectMatterTemplate(action) {
  document.body.style.cursor = 'wait'
  document.getElementById('react-app').style.pointerEvents = 'none'
  const {
    id,
    matterRequestId,
    nonTemplateAttributes,
    templateAttributes,
    isConfidential
  } = action.payload

  try {
    const url = `/manage/matters/create-from-template/`
    const templateJson = toMatterTemplateJson(templateAttributes)
    const response = yield makePostRequest(url, {
      templateID: id,
      matterRequestId,
      nonTemplateAttributes,
      templateAttributes: templateJson,
      is_confidential: isConfidential
    })
    yield put({
      type: ACT.SET_LOADING_STATE
    })
    window.location = response.matter_url
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue selecting the Matter Template',
        message: <NotificationList lines={formatResponse(error.response.data['errors'][0])} />,
        level: 'error'
      }
    })
  }
  document.body.style.cursor = 'default'
  document.getElementById('react-app').style.pointerEvents = 'auto'
}

function* updateMatterForNewTemplate(action) {
  document.getElementById('react-app').style.pointerEvents = 'none'
  document.body.style.cursor = 'wait'
  const { templateId, matterId, templateAttributes } = action.payload

  try {
    const url = `/manage/matters/update-for-new-template/`
    const templateJson = toMatterTemplateJson(templateAttributes, true)
    let attributes = []
    if (templateJson.left.length) {
      attributes = templateJson.left.flatMap(section => section.attributes)
    }
    if (templateJson.right.length) {
      attributes = attributes.concat(templateJson.right.flatMap(section => section.attributes))
    }

    const response = yield makePostRequest(url, {
      templateID: templateId,
      matterId,
      templateAttributes: attributes.filter(attribute => attribute.overwritten)
    })
    yield put({
      type: ACT.SET_LOADING_STATE
    })
    window.location = `/v2/matters/${response.matter_id}`
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue updating the Matter Template',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
  document.body.style.cursor = 'default'
  document.getElementById('react-app').style.pointerEvents = 'auto'
}

function* setDefaultTemplateForUser(action) {
  const { template, userId } = action.payload

  try {
    const url = '/company/user/update/default_template/'
    yield makePostRequest(url, {
      pk: userId,
      value: template.id
    })

    yield put({
      type: ACT.MATTER_TEMPLATE_SET_USER_DEFAULT_SUCCESS,
      payload: {
        selectedTemplate: template
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue setting the template as default',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchSelectionPageDescription(action) {
  const { pageKey, defaultDescription } = action.payload

  try {
    const url = `/api/v1/page/${pageKey}/metadata/`
    const section = yield call(getRequest, url)
    const description = get(section, 'content', defaultDescription)
    const processedDescription = get(section, 'content_processed', defaultDescription)

    yield put({
      type: ACT.SELECTION_PAGE_DESCRIPTION_FETCH_SUCCESS,
      payload: { description, processedDescription }
    })
  } catch (e) {
    yield put({
      type: ACT.SELECTION_PAGE_DESCRIPTION_FETCH_SUCCESS,
      payload: {
        description: defaultDescription,
        processedDescription: defaultDescription
      }
    })
  }
}

function* updateSelectionPageDescription(action) {
  const { pageKey, description } = action.payload

  try {
    const url = `/api/v1/page/${pageKey}/metadata/`
    const response = yield makePutRequest(url, {
      content: description
    })

    const processedDescription = response.content_processed

    yield put({
      type: ACT.MATTER_TEMPLATE_SELECTION_PAGE_UPDATE_DESCRIPTION,
      payload: { description, processedDescription }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue updating the page description',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* rejectLegalRequest(action) {
  const { comment, requestId, navState, navigate, dispatch } = action.payload

  try {
    const url = '/manage/matters/requests/reject/'
    yield makePostRequest(url, {
      comment,
      request_id: requestId
    })
    yield put({
      type: ACT.SET_LOADING_STATE
    })
    navigateToProcessRequestsPage(navState, navigate, dispatch)
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue rejecting the Legal Request',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchLegalRequest(action) {
  const { id } = action.payload
  try {
    const url = `/manage/matters/requests/templates/json/${id}`
    const attributes = yield call(getRequest, url)
    const payload = { attributes }

    yield put({ type: ACT.LEGAL_REQUEST_FETCH_SUCCESS, payload })
    return payload
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Legal Requests attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchDefaultMatterAttributes(action) {
  try {
    const url = '/manage/matters/templates/get_matter_default_attributes/'
    const attributes = yield call(getRequest, url)

    const payload = { attributes }
    yield put({ type: ACT.MATTER_ATTRIBUTES_DEFAULT_FETCH_SUCCESS, payload })
    return payload
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Legal Requests attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterAttributes(action) {
  const { id } = action.payload
  try {
    const url = `/manage/matters/templates/json/${id}`
    const attributes = yield call(getRequest, url)

    const payload = { attributes, userFlow: SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE }
    yield put({ type: ACT.LEGAL_REQUEST_FETCH_SUCCESS, payload })
    return payload
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchTemplateAllocations(action) {
  const { templateId } = action.payload
  try {
    const url = `/templates/allocate?templateId=${templateId}`
    const response = yield call(getRequest, url)
    const { allocations, attributes } = response

    yield put({
      type: ACT.MATTER_TEMPLATE_ALLOCATIONS_FETCH_SUCCESS,
      payload: { allocations: fromAllocationsJson(allocations, attributes) }
    })
    yield put({
      type: ACT.MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_SUCCESS,
      payload: { attributes }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching template Allocations',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplatesAllocationFilteredAttributes(action) {
  const { legalEntityId, allocationIndex } = action.payload
  try {
    const url = `/templates/attributes/allocatable/${legalEntityId}`
    const groups = yield call(getRequest, url)

    yield put({
      type: ACT.MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_SUCCESS,
      payload: { groups: groups.groups, allocationIndex }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Matter Templates attributes',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchMatterTemplatesDynamicAttributesPairedValues(action) {
  const {
    attributeId,
    relationshipIndex,
    category,
    sectionIndex,
    attrIndex,
    value,
    relationshipId,
    levels
  } = action.payload
  try {
    const url = `/templates/related/${relationshipId}/vals/${value.value}/paired`
    const { options } = yield call(getRequest, url)

    yield put({
      type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
      payload: {
        attrIndex,
        category,
        sectionIndex,
        relationshipIndex,
        options,
        levels
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching the paired values for the attribute',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchLegalRequestDynamicAttributesPairedValues(action) {
  const { index, value, property, relationshipIndex, levels, relationshipId } = action.payload
  try {
    const url = `/templates/related/${relationshipId}/vals/${value.value}/paired`
    const { options } = yield call(getRequest, url)

    yield put({
      type: ACT.LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
      payload: {
        index,
        relationshipIndex,
        options,
        levels
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching the paired values for the attribute',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

function* fetchDefaultTemplateFolders(action) {
  const { templateId } = action.payload
  try {
    const url = `/doc_management/native_docs/directories/matter_templates/${templateId}/?tree=1`
    const { data } = yield call(getRequest, url)

    yield put({
      type: ACT.DEFAULT_FOLDERS_FETCH_SUCCESS,
      payload: { rootNode: data }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching default folder structure.',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

const matterTemplatesSagas = [
  takeLatest(
    ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
    fetchMatterTemplatesFilteredAttributes
  ),
  takeLatest(ACT.MATTER_TEMPLATE_FETCH_REQUESTED, fetchMatterTemplate),
  takeLatest(ACT.MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED, updateMatterTemplateStatus),
  takeLatest(ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED, fetchMatterTemplatesList),
  takeLatest(ACT.MATTER_TEMPLATE_SELECT_REQUESTED, selectMatterTemplate),
  takeLatest(ACT.MATTER_UPDATE_FOR_NEW_TEMPLATE_REQUESTED, updateMatterForNewTemplate),
  takeLatest(ACT.MATTER_TEMPLATE_SET_USER_DEFAULT_REQUESTED, setDefaultTemplateForUser),
  takeLatest(ACT.SELECTION_PAGE_DESCRIPTION_FETCH_REQUESTED, fetchSelectionPageDescription),
  takeLatest(ACT.MATTER_TEMPLATE_SELECTION_PAGE_CHANGE_DESCRIPTION, updateSelectionPageDescription),
  takeLatest(ACT.LEGAL_REQUEST_FETCH_REQUESTED, fetchLegalRequest),
  takeLatest(ACT.LEGAL_REQUEST_REJECT_REQUESTED, rejectLegalRequest),
  takeLatest(ACT.MATTER_ATTRIBUTES_FETCH_REQUESTED, fetchMatterAttributes),
  takeLatest(ACT.VALUES_FOR_TEMPLATE_FETCH_REQUESTED, fetchValuesForTemplate),
  takeLatest(ACT.MATTER_ATTRIBUTES_DEFAULT_FETCH_REQUESTED, fetchDefaultMatterAttributes),
  takeLatest(
    ACT.MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_REQUESTED,
    fetchTemplateAllocatableAttributes
  ),
  takeLatest(ACT.MATTER_TEMPLATE_ALLOCATIONS_FETCH_REQUESTED, fetchTemplateAllocations),
  takeLatest(ACT.DEFAULT_FOLDERS_FETCH_REQUESTED, fetchDefaultTemplateFolders),
  takeLatest(
    ACT.MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
    fetchMatterTemplatesAllocationFilteredAttributes
  ),
  takeLatest(
    ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
    fetchMatterTemplatesDynamicAttributesPairedValues
  ),
  takeLatest(
    ACT.LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
    fetchLegalRequestDynamicAttributesPairedValues
  )
]

export default matterTemplatesSagas
