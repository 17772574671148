import s from './ChartInfo.scss'
import cn from 'classnames'

const actionLabel = {
  delete: 'Deleting',
  close: 'Closing',
  update: 'Updating'
}

const ChartInfo = ({ percent, total, action }) => {
  return (
    <div className={s.chartInfo}>
      <div
        className={cn(s.progressInfo, {
          [s.completed]: percent === total
        })}
      >
        {percent === total ? 'Completed' : `${actionLabel[action]} ${percent} of ${total} matters`}
      </div>
    </div>
  )
}

export default ChartInfo
