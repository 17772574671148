import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AsyncSelect from 'react-select/async'
import { FaSearch } from 'react-icons/fa'
import cn from 'classnames'
import { components } from 'react-select'
import debounce from 'debounce-promise'
import { makeGetRequest } from 'utils/api'
import { OutsideClickContainer } from 'simple-core-ui'
import { SelectCustomInput } from './SelectCustomInput'
import s from './BaseAsyncSelect.scss'

const AsyncSelectWithSearchBar = ({
  value,
  url,
  dbTime = 400,
  serializer,
  requestParamsSerializer,
  onChange
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [options, setOptions] = useState([])
  const [searchOptions, setSearchOptions] = useState([])
  const [searchBy, setSearchBy] = useState('')
  const dispatch = useDispatch()

  const fetchOptions = async (search = '') => {
    try {
      const response = await makeGetRequest(
        url,
        requestParamsSerializer && {
          params: requestParamsSerializer(search)
        }
      )
      const options = serializer(response)
      !!search ? setSearchOptions(options) : setOptions(options)
      setSearchBy(search)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debouncedFetchOptions = debounce(search => fetchOptions(search), dbTime)
  const debouncedFilterOptions = debounce(search => {
    setSearchBy(search)
    setSearchOptions(
      options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()))
    )
  }, dbTime)

  const setInitState = () => {
    setIsExpanded(false)
    setSearchBy('')
  }

  return (
    <OutsideClickContainer closeComponent={setInitState}>
      <section className={cn(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={value?.label || null}
          onClick={() =>
            setIsExpanded(expanded => {
              expanded && setSearchBy('')
              return !expanded
            })
          }
        />
        {isExpanded && (
          <div className={s.expandableContainer}>
            <div className={s.searchInputContainer}>
              <FaSearch className={s.searchIcon} />
              <input
                onChange={e => {
                  const { value } = e.target
                  requestParamsSerializer
                    ? debouncedFetchOptions(value)
                    : debouncedFilterOptions(value)
                }}
                className={s.searchInput}
                placeholder="Search"
              />
            </div>
            <div className={s.searchDivider} />
            <div className={s.listContainer}>
              {(searchBy ? searchOptions : options).map(option => {
                return (
                  <div
                    className={cn(s.option, { [s.selected]: option.value === value?.value })}
                    key={option.value}
                    onClick={() => {
                      onChange(option)
                      setInitState()
                    }}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </OutsideClickContainer>
  )
}

const BaseAsyncSelect = ({
  id,
  reset,
  value,
  loadOptions,
  placeholder,
  isClearable = true,
  defaultOptions = true,
  onChange,
  isPortal = true,
  isMulti = false,
  isDisabled = false,
  isLoading = false,
  limit = Infinity,
  comps = {},
  styles = {},
  dbTime = 250,
  filterOption,
  ariaLabel,
  noOptionsMessage,
  withMenuSearch,
  url,
  serializer,
  requestParamsSerializer
}) => {
  const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < limit ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Max limit achieved</div>
        )}
      </components.Menu>
    )
  }

  const debouncedLoadOptions = debounce(loadOptions, dbTime, {
    leading: false
  })

  return !withMenuSearch ? (
    <AsyncSelect
      id={id}
      aria-label={ariaLabel}
      key={reset}
      value={value}
      loadOptions={debouncedLoadOptions}
      defaultOptions={defaultOptions}
      cacheOptions
      placeholder={placeholder}
      onChange={onChange}
      isMulti={isMulti}
      // we cannot do isClearable with multi because
      // arr is null if user click X on all the pills
      // arr is [] if user clicks on the isClearable X
      // leads to lots of issues
      // https://github.com/JedWatson/react-select/issues/3632
      isClearable={isClearable && !isMulti}
      isLoading={isLoading}
      className={s.select}
      styles={{
        ...styles,
        ...(isPortal ? { menuPortal: base => ({ ...base, zIndex: 9999 }) } : {})
      }}
      menuPortalTarget={isPortal ? document.body : null}
      isDisabled={isDisabled}
      components={{ Menu, ...comps }}
      filterOption={filterOption}
      noOptionsMessage={noOptionsMessage}
    />
  ) : (
    <AsyncSelectWithSearchBar
      value={value}
      url={url}
      dbTime={dbTime}
      serializer={serializer}
      requestParamsSerializer={requestParamsSerializer}
      onChange={onChange}
    />
  )
}

export default BaseAsyncSelect
