import { toReactSelect, toUsers, toCategories } from './serializers'
import { CONTACTS_SEARCH_URL } from './urls'
import get from 'lodash/get'
import { makeGetRequest } from 'utils/api'

export const loadOptionsSelect2Json = (url, extraParams = {}, withNoneOption) => async search => {
  const params = search ? { params: { search_term: search, ...extraParams } } : null
  let result = await makeGetRequest(url, params)

  if (!withNoneOption) {
    result = {
      ...result,
      results: result.results.filter(r => r.text !== 'None')
    }
  }

  return toReactSelect(get(result, 'results', []))
}

export const loadOptionsContacts = async (search, withNoneOption) => {
  const params = search ? { params: { l: true, q: search } } : null
  const users = await makeGetRequest(CONTACTS_SEARCH_URL, params || { params: { l: true } })

  if (withNoneOption) {
    return [{ value: -1, label: 'None' }, ...toUsers(get(users, 'results', []))]
  }

  return toUsers(get(users, 'results', []))
}

export const loadOptionsCategories = async () => {
  const result = await makeGetRequest('/category/rules/')
  return toCategories(get(result, 'rows', []))
}
