import { Fragment } from 'react'
import s from './EditVendorModal.scss'
import { ORG_ROLE } from 'utils/constants'
import { UserSelect, VendorGroupSelect } from 'common/Selects'
import Select from 'react-select'
import omit from 'lodash/omit'
import { hasModule } from 'utils/helpers'

const EditVendorModal = ({
  isBulkEdit,
  permissions,
  formValues,
  setFormValues,
  totalRowsSelected
}) => {
  const options = [
    { label: 'On', value: true },
    { label: 'Off', value: false }
  ]

  const onChangeHandler = (field, option) => {
    option
      ? setFormValues({ ...formValues, [field]: option })
      : setFormValues(omit(formValues, field))
  }

  return (
    <Fragment>
      {isBulkEdit && (
        <div className={s.row}>
          <label>Vendor Group</label>
          <h4 className={s.subtitle}>Please, choose the vendor group</h4>
          <VendorGroupSelect
            value={formValues?.vendor_group}
            onChange={item => onChangeHandler('vendor_group', item)}
            isPortal
            isClearable
            placeholder="Search Vendor Group"
            paginated
            isDisabled={!permissions.enableManageVendorGroups}
          />
        </div>
      )}
      {
        <div className={s.row}>
          <label>Vendor Manager</label>
          <h4 className={s.subtitle}>Please, choose the vendor manager</h4>
          <UserSelect
            value={formValues?.vendor_lead}
            onChange={item => onChangeHandler('vendor_lead', item)}
            isPortal
            isClearable
            placeholder="Search Vendor Manager"
            paginated
            withNoneOption
          />
        </div>
      }
      {isBulkEdit && (
        <>
          {hasModule('has_timekeepers') && (
            <div className={s.row}>
              <label>TK Validation Enabled</label>
              <Select
                value={formValues?.tk_validation}
                isClearable
                options={options}
                placeholder="Select Status..."
                onChange={item => onChangeHandler('tk_validation', item)}
              />
            </div>
          )}
          {hasModule('has_accruals') && (
            <div className={s.row}>
              <label>Accruals Enabled</label>
              <Select
                value={formValues?.accruals_enabled}
                isClearable
                options={options}
                placeholder="Select Status..."
                onChange={item => onChangeHandler('accruals_enabled', item)}
              />
            </div>
          )}
          {permissions.requireInvoicePDF && (
            <div className={s.row}>
              <label>Require invoice PDF</label>
              <Select
                value={formValues?.pdf_required}
                isClearable
                options={options}
                placeholder="Select Status..."
                onChange={item => onChangeHandler('pdf_required', item)}
              />
            </div>
          )}
          {window.credentials.user.role === ORG_ROLE.ADMIN && permissions.allowCgInvoicing && (
            <div className={s.row}>
              <label>CounselGO Invoice Submission</label>
              <Select
                value={formValues?.cg_invoicing}
                isClearable
                options={options}
                placeholder="Select Status..."
                onChange={item => onChangeHandler('cg_invoicing', item)}
              />
            </div>
          )}
          {permissions.enableDiversityReporting && (
            <div className={s.row}>
              <label>Diversity Data Collection</label>
              <Select
                value={formValues?.diversity_collection}
                isClearable
                options={options}
                placeholder="Select Status..."
                onChange={item => onChangeHandler('diversity_collection', item)}
              />
            </div>
          )}
        </>
      )}
    </Fragment>
  )
}

export default EditVendorModal
