import * as ACT from 'matters/templates/actions'
import { createReducer } from 'redux-create-reducer'
import cloneDeep from 'lodash/cloneDeep'
import { TemplateFoldersState, Folder } from './types'
import { findNodeById, deleteFromTree, insertFolderIntoTree, renameFolderName } from './helpers'
import shortid from 'shortid'

const initialId = shortid.generate()

const initialState: TemplateFoldersState = {
  rootNode: {
    id: initialId,
    name: ''
  },
  currentFolder: {
    id: initialId,
    name: ''
  }
}

const templateFoldersReducer = createReducer(initialState, {
  [ACT.DEFAULT_FOLDERS_FETCH_SUCCESS](state, action) {
    const { rootNode } = action.payload
    return {
      ...state,
      rootNode,
      currentFolder: {
        id: rootNode.id,
        name: ''
      }
    }
  },
  [ACT.UPDATE_DEFAULT_FOLDERS](state, action) {
    const { parentId, newFolder } = action.payload
    const folderState = cloneDeep(state.rootNode)

    const updatedTree = insertFolderIntoTree(folderState, newFolder, parentId)
    return {
      ...state,
      rootNode: updatedTree
    }
  },
  [ACT.RENAME_FOLDER_NAME](state, action) {
    const folderState = cloneDeep(state.rootNode)
    const updatedTree = renameFolderName(folderState, action.payload.folder)
    return {
      ...state,
      rootNode: updatedTree
    }
  },
  [ACT.DELETE_FOLDER_NAME](state, action) {
    const folderState = cloneDeep(state.rootNode)
    const updatedTree = deleteFromTree(folderState, action.payload.id)
    return {
      ...state,
      rootNode: updatedTree
    }
  },
  [ACT.MOVE_FOLDER](state, action) {
    const { selectedFolderId, newParentId } = action.payload
    const folderState = cloneDeep(state.rootNode)

    const folderToMove = findNodeById(folderState, selectedFolderId) as Folder
    let modifiedFolderState = deleteFromTree(folderState, selectedFolderId)

    if (!folderToMove || !modifiedFolderState) return state

    modifiedFolderState = insertFolderIntoTree(modifiedFolderState, folderToMove, newParentId)
    return {
      ...state,
      rootNode: modifiedFolderState
    }
  },
  [ACT.SET_DEFAULT_FOLDERS_SUCCESS](state, action) {
    const { response } = action.payload
    return {
      ...state,
      rootNode: response,
      currentFolder: {
        id: response.id,
        name: ''
      }
    }
  },
  [ACT.UPDATE_CURRENT_FOLDER](state, action) {
    const { currentRow } = action.payload
    return {
      ...state,
      currentFolder: currentRow
    }
  },
  [ACT.RESET_DEFAULT_FOLDERS]() {
    return initialState
  }
})

export default templateFoldersReducer
