import { Loading, Tabs, Tab, Panel } from 'simple-core-ui'
import s from './StandardReports.scss'
import { CategoryContainer } from '../common/Category'
import { useMemo } from 'react'
import { STANDARD_TAB_HEADERS } from '../constants'
import {
  getAllStandardReports,
  getFilteredSupersetReports,
  getStandardReports
} from './serializers'

const StandardReports = ({ reports, supersetReports, loading }) => {
  const allReports = useMemo(() => getAllStandardReports(reports, supersetReports), [
    reports,
    supersetReports
  ])

  const getTabs = () => {
    const standardReports = getStandardReports(reports, supersetReports)
    const filteredSupersetReports = getFilteredSupersetReports(reports, supersetReports)
    return Object.keys({ ...standardReports, ...filteredSupersetReports })
      .sort()
      .map(key => (
        <Tab header={STANDARD_TAB_HEADERS[key] || supersetReports[key]?.title} key={key}>
          <CategoryContainer
            reports={{
              [key]: standardReports[key] || filteredSupersetReports[key]
            }}
            showLegend
          />
        </Tab>
      ))
  }

  return (
    <Panel title="Standard Reports" styles={{ boxShadow: 'none' }} className={s.reports}>
      {loading ? (
        <Loading />
      ) : (
        <Tabs alignRight>
          <Tab header={STANDARD_TAB_HEADERS.all}>
            <CategoryContainer reports={allReports} showLegend />
          </Tab>
          {getTabs()}
        </Tabs>
      )}
    </Panel>
  )
}

export default StandardReports
