import APP_PAGES_MAP from 'data/app_pages_map'
import s from '../styles/MobileAccountSettings.scss'

const MobileAccountSettings = ({ accountLinks = [] }) =>
  accountLinks.length && (
    <li className={s.container}>
      <ul className={s.list}>
        {accountLinks.map(link => (
          <li key={link.name} className={s.item}>
            <a href={link.url} className={s.link}>
              {APP_PAGES_MAP[link.name].text}
            </a>
          </li>
        ))}
      </ul>
    </li>
  )

export default MobileAccountSettings
