import { useEffect, useState } from 'react'
import { Panel, useLoading, Grid, KeyValuePairs } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { useDispatch } from 'react-redux'

const LegalRequestsTab = ({ scopeId }) => {
  const { Row, Column } = Grid
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [details, setDetails] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await withLoadingLocks(
          makeGetRequest(`/manage/matters/request_details/${scopeId}/`)
        )
        setDetails(response)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Panel title="Original Legal Request">
      <Row>
        <Column span="6">
          <KeyValuePairs
            pairs={
              details
                ? details.fields.map(field => ({
                    key: field.label,
                    value: field.display_name
                      ? typeof field.display_name === 'object'
                        ? `${field.display_name.amount || ''} ${field.display_name.currency_code ||
                            ''}`
                        : field.display_name
                      : '----',
                    style: { marginBottom: '5px' }
                  }))
                : []
            }
          />
        </Column>
        <Column span="6">
          <KeyValuePairs
            pairs={[
              {
                key: 'Requester',
                value: details ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/company/users/${details.requested_by.id}`}
                  >
                    {`${details.requested_by.last_name}, ${details.requested_by.first_name} - ${details.requested_by.email}`}
                  </a>
                ) : (
                  '---'
                ),
                style: { marginBottom: '5px' }
              },
              {
                key: 'Reviewed By',
                value: details
                  ? `${details.reviewed_by.last_name}, ${details.reviewed_by.first_name}`
                  : '---',
                style: { marginBottom: '5px' }
              },
              {
                key: 'Status',
                value: 'approved',
                style: { marginBottom: '5px' }
              },
              {
                key: 'Comments',
                value: details?.comment ? details.comment : '---',
                style: { marginBottom: '5px' }
              },
              details?.files
                ? {
                    key: 'Files',
                    value: (
                      <ul>
                        {details.files.map(file => (
                          <li key={file.url}>
                            <a target="_blank" rel="noreferrer" href={file.url}>
                              {file.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )
                  }
                : {}
            ]}
          />
        </Column>
      </Row>
    </Panel>
  )
}

export default LegalRequestsTab
