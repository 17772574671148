import BaseAsyncSelect from './BaseAsyncSelect'
import { loadOptionsSelect2Json } from '../helpers'
import { LEGAL_ENTITY_URL } from '../urls'
import { PaginatedSelect } from 'simple-core-ui'
import { toReactSelect } from '../serializers'

const LegalEntitySelect = ({
  value,
  isClearable,
  onChange,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  placeholder = 'Search legal entity...',
  isDisabled,
  paginated = false,
  serializer,
  defaultOptions,
  defaultAction,
  id
}) => {
  return paginated ? (
    <PaginatedSelect
      url={LEGAL_ENTITY_URL}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toReactSelect}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      isDisabled={isDisabled}
      placeholder={placeholder}
      limit={limit}
    />
  ) : (
    <BaseAsyncSelect
      placeholder={placeholder}
      limit={limit}
      onChange={onChange}
      value={value}
      isMulti={isMulti}
      loadOptions={loadOptionsSelect2Json(LEGAL_ENTITY_URL)}
      isClearable={isClearable}
      isPortal={isPortal}
    />
  )
}

export default LegalEntitySelect
