import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import { REQUEST_STATUSES } from 'matters/requests/constants'
import { itemValueChecker, getItemsOptions } from './utils'
import isEqual from 'lodash/isEqual'

const initialState = {
  status: REQUEST_STATUSES.PENDING,
  selectedForm: null,
  filterTerm: '',
  items: []
}

const requestReducer = createReducer(initialState, {
  [ACT.LEGAL_REQUEST_SET_SELECTED_FORM](state, action) {
    const { selectedForm } = action.payload

    return {
      ...state,
      selectedForm
    }
  },

  [ACT.REQUEST_FORM_FETCH_SUCCESS](state, action) {
    const { items } = action.payload.form
    const validItems = cloneDeep(items).map(item => {
      item.isValid = true
      return item
    })
    return {
      ...state,
      items: validItems
    }
  },

  [ACT.LEGAL_REQUEST_SET_ITEM_VALUE](state, action) {
    const { value, fieldUniqueName, relIndex } = action.payload
    const itemIndex = state.items.findIndex(item => item.fieldUniqueName === fieldUniqueName)
    const required = state.items[itemIndex].required
    const isValid = !required || itemValueChecker(value)

    if (relIndex !== undefined) {
      return update(state, {
        items: {
          [itemIndex]: {
            attributes: {
              [relIndex]: {
                value: { $set: value }
              }
            }
          }
        }
      })
    }

    return update(state, {
      items: {
        [itemIndex]: {
          value: { $set: value },
          isValid: { $set: isValid }
        }
      }
    })
  },

  [ACT.LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { attributes } = action.payload
    const items = getItemsOptions(attributes, state.items)

    return {
      ...state,
      items
    }
  },

  [ACT.LEGAL_REQUEST_RESET_STATE](state, action) {
    return {
      ...initialState
    }
  },

  [ACT.LEGAL_REQUEST_FORM_LIST_CHANGE_FILTER_TERM](state, action) {
    const { filterTerm } = action.payload
    return {
      ...state,
      filterTerm
    }
  },

  [ACT.CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS](state, action) {
    const { relIndex, options, relId } = action.payload
    const index = state.items.findIndex(item => item.model === `RelAttrs__${relId}`)

    return update(state, {
      items: {
        [index]: {
          attributes: {
            [relIndex + 1]: {
              options: {
                $set: options
              },
              value: { $set: '' }
            }
          }
        }
      }
    })
  },

  [ACT.LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { groups } = action.payload
    let newState
    const attributesToUpdate = []
    const updatedAttributes = {}

    state.items.forEach((attribute, ai) => {
      let updatedAttribute = groups.groups.flatMap(attributeSection => {
        return attributeSection.attributes.filter(sectionAttribute => {
          return (
            sectionAttribute.type === 'select' &&
            sectionAttribute.id == attribute.model.split('__')[1] &&
            !isEqual(
              sectionAttribute.options.filter(o => o.value !== -1),
              attribute.options
            )
          )
        })
      })[0]

      updatedAttribute &&
        attributesToUpdate.push({
          index: ai,
          attribute: updatedAttribute,
          filterOptions: attribute.filterOptions
        })
    })

    attributesToUpdate.forEach(updatedAttribute => {
      if (updatedAttribute) {
        updatedAttributes[updatedAttribute.index] = {
          ...state.items[updatedAttribute.index],
          // if the attribute has filtered options from a LR form, filter the options to only include the ones
          // that are both in the filter options array and valid for the selected practice area
          options: updatedAttribute.filterOptions?.length
            ? cloneDeep(updatedAttribute.attribute.options).filter(attr =>
                updatedAttribute.filterOptions.map(Number).includes(attr.value)
              )
            : cloneDeep(updatedAttribute.attribute.options),
          value: { value: -1, label: 'None' }
        }
      }
    })

    if (Object.keys(updatedAttributes).length) {
      newState = update(state, {
        items: {
          $merge: updatedAttributes
        }
      })
    }

    return newState || state
  }
})

export default requestReducer
