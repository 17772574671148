import { ErrorResponse } from './types'

export const parseError = (
  errorObj: ErrorResponse = { errors: '', error: {} },
  editMode: boolean
) => {
  let errorMessage = ''

  if (typeof errorObj.error === 'object') {
    const fieldErrorMessages = Object.values(errorObj.error)
    errorMessage = fieldErrorMessages.flat().join('\n')
  } else if (errorObj.error) {
    errorMessage = errorObj?.error as string
  }

  if (errorObj.errors) {
    errorMessage.concat(`${errorMessage.length ? '. ' : ''}${errorObj.errors}`)
  }

  return errorMessage || `There was an issue ${editMode ? 'updating' : 'creating'} the contact.`
}
