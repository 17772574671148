import { Component } from 'react'
import debounce from 'lodash/debounce'

import { Input } from 'components'

class InputContainer extends Component {
  static defaultProps = {
    debounceDelay: 250
  }
  state = { text: this.props.value || '' }

  componentDidMount() {
    this.onChange = debounce(this.onChange, this.props.debounceDelay)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.text) {
      this.setState({ text: nextProps.value || '' })
    }
  }

  handleChange = value => {
    this.setState({ text: value }, () => {
      if (value.trim() || value === '') {
        this.onChange(this.state.text)
      }
    })
  }

  onChange = value => {
    this.props.onChange(value)
  }

  render() {
    return <Input {...this.props} onChange={this.handleChange} value={this.state.text} />
  }
}

export default InputContainer
