import { Fragment } from 'react'
import { Question } from './Question'
import { AdditionalComments } from './AdditionalComments'
import { WithDetailsTable } from './common/WithDetailsTable'
import { ComparisonQuestion } from './ComparisonQuestion'
import { NoResults } from './NoResults'
import s from './Questions.scss'
import { If } from 'simple-core-ui'
import { showComparison, emptyComparison } from '../utils'

const Questions = ({
  questions,
  comments,
  commentsNumber,
  filters,
  comparisons,
  compareQuestions,
  currentVendorName,
  onShowQuestionAnswers,
  onShowAdditionalComments,
  count
}) => (
  <div className={s.container}>
    <If
      condition={
        (questions.length > 0 && comparisons.against === null) ||
        (questions.length > 0 && emptyComparison(comparisons)) ||
        (questions.length > 0 && showComparison(comparisons, compareQuestions))
      }
      fallback={<NoResults />}
    >
      <p className={s.title}>Survey Questions</p>
      <If
        condition={showComparison(comparisons, compareQuestions)}
        fallback={
          <Fragment>
            {questions.map((question, index) => (
              <WithDetailsTable
                count={count}
                toggleCallback={() => onShowQuestionAnswers(question.id, index)}
                key={question.id}
              >
                {(showTable, toggleDetails) => (
                  <Question
                    showTable={showTable}
                    toggleDetails={toggleDetails}
                    question={question}
                    index={index}
                  />
                )}
              </WithDetailsTable>
            ))}
            <WithDetailsTable count={count} toggleCallback={onShowAdditionalComments}>
              {(showTable, toggleDetails) => (
                <AdditionalComments
                  showTable={showTable}
                  toggleDetails={toggleDetails}
                  comments={comments}
                  commentsNumber={commentsNumber}
                />
              )}
            </WithDetailsTable>
          </Fragment>
        }
      >
        <ComparisonQuestion
          currentVendorName={currentVendorName}
          questions={questions}
          filters={filters}
          comparisons={comparisons}
          compareQuestions={compareQuestions}
        />
      </If>
    </If>
  </div>
)

export default Questions
