import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import { serializeColumns } from 'bulk/builder/list/DynamicBulkTable/tableDefinitions'
import { serializeHistoryResults } from './serializer'

let defaultParams = {
  ordering: {
    columnKey: 'matter_short_name',
    isDesc: false
  },
  page: 1,
  pageSize: 10,
  filters: {}
}

let defaultHistoryParams = {
  ordering: {
    columnKey: 'created_at',
    isDesc: true
  },
  page: 1,
  pageSize: 10,
  filters: {}
}

let defaultBuilderParams = {
  record: '',
  attrType: '',
  templateId: '',
  step: 1,
  fields: [],
  filters: [],
  preSelected: []
}

const initialState = {
  //For rendering loading skeleton
  columns: [
    { columnKey: 'sample_0', content: 'sample_0', isSortable: true },
    { columnKey: 'sample_1', content: 'sample_1' },
    { columnKey: 'sample_2', content: 'sample_2' }
  ],
  results: [],
  bulkParams: { ...defaultParams },
  totalEntries: 0,
  isLoading: true,
  history: {
    params: {
      ...defaultHistoryParams
    },
    filteredEntries: 0,
    totalEntries: 0,
    isLoading: false,
    results: []
  },
  bulkFields: [],
  bulkForm: {},
  filterValues: {},
  builderParams: { ...defaultBuilderParams },
  recordType: '',
  recordTypeObj: {},
  activeJob: false
}

const bulkReducer = createReducer(initialState, {
  [ACT.BULK_FETCH_SUCCESS](state, action) {
    const {
      columns,
      results,
      page,
      page_size,
      total,
      ordering,
      filters,
      record_type
    } = action.payload

    return {
      ...state,
      columns: serializeColumns(columns, record_type),
      results: results.map(resultItem => ({ ...resultItem, id: resultItem.record_id })),
      bulkParams: {
        ...state.bulkParams,
        page,
        pageSize: page_size,
        ordering: ordering,
        filters: filters
      },
      totalEntries: total,
      isLoading: false,
      recordType: record_type
    }
  },
  [ACT.BULK_HISTORY_FETCH_SUCCESS](state, action) {
    const { params, results } = action.payload
    return {
      ...state,
      history: {
        ...state.history,
        params: params,
        totalEntries: action.payload.total,
        results: serializeHistoryResults(results)
      }
    }
  },
  [ACT.BULK_FIELDS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      bulkFields: [...action.payload.results]
    }
  },
  [ACT.UPDATE_BULK_FIELDS_VALUES](state, action) {
    return {
      ...state,
      bulkForm: { ...action.payload }
    }
  },
  [ACT.RESET_BULK_FIELDS_VALUES](state, action) {
    return {
      ...state,
      bulkForm: {
        ...initialState.bulkForm
      }
    }
  },
  [ACT.CLEANUP_BULK](state, action) {
    const key = action.payload.key
    return {
      ...state,
      [key]: initialState[key]
    }
  },
  [ACT.SET_BUILDER_PARAMS](state, action) {
    return {
      ...state,
      builderParams: { ...action.payload }
    }
  },
  [ACT.SET_ACTIVE_JOB_STATUS](state, action) {
    return {
      ...state,
      ...action.payload
    }
  },
  [ACT.SET_RECORD_TYPE](state, action) {
    return {
      ...state,
      ...action.payload
    }
  },
  [ACT.BULK_SET_FILTER_VALUES](state, action) {
    return {
      ...state,
      filterValues: action.payload
    }
  },
  [ACT.CLEAR_ROWS](state) {
    return {
      ...state,
      results: []
    }
  }
})

export default bulkReducer
