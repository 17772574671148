import cn from 'classnames'
import { TimePicker } from 'common/TimePicker'
import { FileUpload } from 'common/FileUpload'
import { Widget } from 'common/Widget'
import {
  format,
  isSameYear,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  isAfter,
  isEqual
} from 'date-fns'
import qs from 'query-string'
import { useEffect, useMemo, useRef, useState, Dispatch, SetStateAction } from 'react'
import { BsEye, BsPersonPlus } from 'react-icons/bs'
import { FaRegCalendarCheck, FaRegClock } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import {
  AvatarList,
  Button,
  Ellipsis,
  Markdown,
  Tab,
  Tabs,
  useLoading,
  useScrollLock,
  KeyValuePairs,
  PaginatedSelect,
  CharLimitInput,
  RichTextEditor,
  Users,
  DueDatePicker
} from 'simple-core-ui'
import { Updater } from 'use-immer'
import { makeDeleteRequest, makeGetRequest, makePatchRequest, makePostRequest } from 'utils/api'
import {
  openLink,
  sortAlphabeticallyByProperty,
  combineDateAndTimeString,
  timezoneUtils,
  isBasicTaskManagementPlan
} from 'utils/helpers'
import { fromAttachments, toEvents, toEventTypesOptions, toMattersOptions } from '../serializers'
import {
  APIAttachment,
  Attachment,
  Comment,
  CustomizedOption,
  DateFormat,
  History,
  InitialEvent,
  LocalEvent,
  Option,
  MatterOption,
  Event,
  APISimpleMatter,
  Location as LocationType
} from '../types'
import { canEditEvent } from '../utils'
import s from './AddEvent.scss'
import { Comments } from './Comments'
import { CommentsList } from './CommentsList'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH, BASIC_TIER_TOTAL_ALLOWED } from './constants'
import { DeleteConfirmation } from './DeleteConfirmation'
import { HistoryList } from './HistoryList'
import { Overview } from './Overview'
import { toAPIAttachments, toLocalEvent, toEvent } from './serializers'
import { Widgets } from './Widgets'
import { StylesConfig, OptionProps, components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import { Location } from '../Location'
import { DATE_FORMATS } from 'utils/constants'
import { EVENT_OPTIONS_ENDPOINTS } from '../constants'
import { PiGavelFill } from 'react-icons/pi'

const CustomOption = (props: OptionProps<MatterOption>) => {
  return (
    <components.Option {...props}>
      <div className={s.option}>
        <div className={cn(s.header, s.displayBelow)}>
          <p className={s.optionTitle}>
            <Ellipsis width={280} lines={1}>
              {props.data.label}
            </Ellipsis>
          </p>
          <p className={s.clientMatterId}>{props.data.clientMatterId}</p>
        </div>
      </div>
    </components.Option>
  )
}

const customStyle: StylesConfig<MatterOption, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menu: provided => ({
    ...provided,
    width: '500px'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: provided => ({
    ...provided,
    color: '#3c99fd'
  })
}

interface Props {
  toggleAddEventSidebar: () => void
  events: { id: number; name: string }[]
  scopeName: string
  event: Event | null
  oldEvent: Event | null
  saveEvent: (
    event: Event,
    isEdit?: boolean,
    callback?: (event?: Event) => void,
    shouldRefreshTable?: boolean
  ) => void
  setEvents: Updater<Event[]>
  scopeId: string
  readOnly: boolean
  context: 'matter' | 'workbench'
  setScopeId: Dispatch<SetStateAction<string>>
  openedTab?: string
  refreshTable: () => void
}

const initialEvent: InitialEvent = {
  name: '',
  description: '',
  eventType: null,
  isPrivate: false,
  startDate: undefined,
  endDate: undefined,
  timezone: timezoneUtils.getUserTimezoneLabel(),
  attendees: [],
  fileAttachments: [],
  parent: null,
  createdDate: '',
  createdBy: null,
  calendar: {
    id: 1,
    name: 'team calendar',
    color: '#007FFF'
  },
  location: null,
  isExternal: false
}

const isBasicPlan = isBasicTaskManagementPlan()

const AddEvent = ({
  toggleAddEventSidebar,
  events,
  scopeName,
  event: editedEvent,
  oldEvent,
  saveEvent,
  setEvents,
  scopeId,
  readOnly,
  context,
  setScopeId,
  openedTab,
  refreshTable
}: Props) => {
  const [event, setEvent] = useState(toLocalEvent(editedEvent) || initialEvent)
  const [, withLoadingLocks] = useLoading()
  const [eventTypes, setEventTypes] = useState<CustomizedOption[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [attachmentToDeleteId, setAttachmentToDeleteId] = useState<number | string>('')
  const [prevEvent, setPrevEvent] = useState<LocalEvent | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const dispatch = useDispatch()
  const [editedProperties, setEditedProperties] = useState<Partial<LocalEvent>>({})
  const [comments, setComments] = useState<Comment[]>([])
  const [history, setHistory] = useState<History[]>([])
  const [activeTab, setActiveTab] = useState(openedTab || 'overview')
  const [prevActiveTab, setPrevActiveTab] = useState('')
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false)
  const { lockScroll, unlockScroll } = useScrollLock()
  const [nameInputFocused, setNameInputFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const _scopeId = scopeId || (event?.relatedMatter?.value ?? '')

  const isReadOnly = useMemo(() => {
    return readOnly || !canEditEvent(event, context)
  }, [context, readOnly, event])

  const getTabIndex = () => {
    switch (activeTab) {
      case 'overview':
        return 0
      case 'location':
        return 1
      case 'files':
        return 2
      case 'comments':
        return 3
      default:
        return null
    }
  }

  const fetchHistory = async () => {
    const history = await withLoadingLocks(
      makeGetRequest(`/event-management/matters/${_scopeId}/events/${editedEvent?.id}/history/`)
    )
    setHistory(history)
  }

  if (activeTab && prevActiveTab !== activeTab) {
    setPrevActiveTab(activeTab)
    if (activeTab === 'history') fetchHistory()
  }

  const selectOptions = useMemo(() => {
    return {
      eventTypes: eventTypes.filter(eventType => eventType.isActive)
    }
  }, [eventTypes])

  const resetEditedProperties = () => {
    setEditedProperties({
      id: editedEvent?.id
    })
  }

  if (editedEvent && prevEvent?.id !== editedEvent.id) {
    setEvent(toLocalEvent(editedEvent) as LocalEvent)
    setPrevEvent(toLocalEvent(editedEvent))
    if (editedEvent.id) {
      setIsEdit(true)
      resetEditedProperties()
    }
  }

  useEffect(() => {
    // remove id hash from url when sidebar is closed
    return () => {
      if (!isEdit) return
      const parsedQuery = qs.parse(window.location.search)
      const parsedHash = qs.parse(window.location.hash, { decode: false })
      delete parsedQuery.id
      const queryString = qs.stringify(parsedQuery)
      const hashString = qs.stringify(parsedHash, { encode: false })

      window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
    }
  }, [isEdit])

  useEffect(() => {
    lockScroll()
    return () => {
      unlockScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchOptions() {
      const { rows: eventTypes } = await withLoadingLocks(
        makeGetRequest(EVENT_OPTIONS_ENDPOINTS.EVENT_TYPES)
      )
      setEventTypes(toEventTypesOptions(eventTypes))
    }

    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchOptions() {
      if (!editedEvent?.id) return
      const { rows: comments } = await withLoadingLocks(
        makeGetRequest(`/event-management/matters/${_scopeId}/events/${editedEvent.id}/comments/`)
      )
      setComments(comments)
    }

    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedEvent])

  const updateEvent = (
    value:
      | string
      | number
      | null
      | Option<number | string>
      | Option[]
      | boolean
      | Date
      | undefined
      | APIAttachment[]
      | {
          type: string
          value: number | null
        }
      | (Date | null)[]
      | LocationType,
    property: keyof (LocalEvent | InitialEvent) | (keyof (LocalEvent | InitialEvent))[],
    nestedProperty?: string
  ) => {
    if (!Array.isArray(property)) {
      setEvent({
        ...event,
        ...(nestedProperty
          ? { [property]: { ...(event[property] as Partial<LocalEvent>), [nestedProperty]: value } }
          : { [property]: value })
      })
      if (isEdit) {
        setEditedProperties({
          id: editedEvent?.id,
          ...(nestedProperty
            ? { ...(event[property] as Partial<LocalEvent>), [nestedProperty]: value }
            : { [property]: value })
        })
      }
    } else {
      setEvent({
        ...event,
        [property[0]]: value && Array.isArray(value) ? value[0] : null,
        [property[1]]: value && Array.isArray(value) ? value[1] : null
      })
      if (isEdit) {
        setEditedProperties({
          id: editedEvent?.id,
          [property[0]]: value && Array.isArray(value) ? value[0] : null,
          [property[1]]: value && Array.isArray(value) ? value[1] : null
        })
      }
    }
  }

  const isValid = (): boolean => {
    if (context === 'workbench') {
      return (
        event.name[0] !== ' ' &&
        !!event.name.trim() &&
        !!event.relatedMatter &&
        !!event.startDate &&
        event.name.length <= MAX_NAME_LENGTH
      )
    }
    return (
      event.name[0] !== ' ' &&
      !!event.name.trim() &&
      !isDescriptionTooLong &&
      !!event.startDate &&
      event.name.length <= MAX_NAME_LENGTH
    )
  }

  const getUsersLabel = (prop: 'attendees'): string | JSX.Element => {
    switch (event[prop]?.length) {
      case 0:
        return ''
      case 1:
        return event[prop][0].label
      default:
        return (
          <AvatarList
            limit={3}
            size="xs"
            wrapperStyles={{ width: 24 }}
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(event[prop], 'label')}
          />
        )
    }
  }

  const getUsersIcon = (prop: 'attendees'): JSX.Element => {
    if (event[prop].length === 1) {
      return (
        <AvatarList
          size="md"
          wrapperStyles={{
            width: 36,
            position: 'relative',
            bottom: 2,
            right: 2
          }}
          className={s.avatar}
          entries={[event[prop][0]]}
          avatarStyles={{ border: '2px solid #0957ae', lineHeight: '30px' }}
        />
      )
    }

    return prop === 'attendees' ? <BsPersonPlus /> : <BsEye />
  }

  const deleteAttachment = async () => {
    if (isEdit) {
      await withLoadingLocks(
        makeDeleteRequest(
          `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/attachments/${attachmentToDeleteId}/`
        )
      )

      const index = events.findIndex(t => t.id === editedEvent?.id)

      if (~index) {
        setEvents(draft => {
          draft[index].fileAttachmentsCount--
        })
      }
    }

    setEvent({
      ...event,
      fileAttachments: event.fileAttachments.filter(
        attachment => attachment.id !== attachmentToDeleteId
      )
    })
    setShowDeleteConfirmation(false)

    dispatch({
      type: 'PUSH_NOTIFICATION',
      payload: {
        title: `Event ${event.name} successfully edited.`,
        level: 'success'
      }
    })
  }

  const showDeleteModal = (id: number | string) => {
    setShowDeleteConfirmation(true)
    setAttachmentToDeleteId(id)
  }

  const addFiles = async (files: APIAttachment[]) => {
    const response = await withLoadingLocks(
      makePostRequest(
        `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/attachments/`,
        {
          files: files.length ? fromAttachments(files) : null
        }
      )
    )

    const existingNames = response.attachments.map((a: APIAttachment) => a.file_name)
    const newFiles = event.fileAttachments.filter(a => !existingNames.includes(a.file_name))
    updateEvent([...newFiles, ...response.attachments], 'fileAttachments')

    const index = events.findIndex(t => t.id === editedEvent?.id)

    if (~index) {
      setEvents((draft: Event[]) => {
        draft[index].fileAttachmentsCount = [...newFiles, ...response.attachments].length
      })
    }
  }

  const addAttendees = async (values: Option[]) => {
    try {
      const event = await withLoadingLocks(
        makePostRequest(
          `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/attendees/`,
          {
            attendee_ids: values.map(v => v.value)
          }
        )
      )

      const index = events.findIndex(t => t.id === event.id)

      if (~index) {
        setEvents((draft: Event[]) => {
          draft[index].attendees = toEvents([event])[0].attendees
          return draft
        })
      }

      refreshTable()

      if (activeTab === 'history') {
        fetchHistory()
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Event ${event.name} successfully edited.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const addComment = async (comment: string) => {
    try {
      const response = await withLoadingLocks(
        makePostRequest(
          `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/comments/`,
          {
            comment
          }
        )
      )

      setComments([response, ...comments])

      const index = events.findIndex(t => t.id === editedEvent?.id)

      if (~index) {
        setEvents((draft: Event[]) => {
          if (editedEvent) {
            draft[index].commentsCount = draft[index].commentsCount + 1
          }
          return draft
        })
      }

      setActiveTab('comments')

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully added to event ${event.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const deleteComment = async (id: number) => {
    try {
      await withLoadingLocks(
        makeDeleteRequest(
          `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/comments/${id}/`
        )
      )

      setComments(comments.filter(c => c.id !== id))

      const index = events.findIndex(t => t.id === editedEvent?.id)

      if (~index) {
        setEvents((draft: Event[]) => {
          if (editedEvent) {
            draft[index].commentsCount = draft[index].commentsCount - 1
          }
          return draft
        })
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully deleted from event ${event.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const editComment = async (id: number, comment: string, cb: () => void) => {
    try {
      const response = await withLoadingLocks(
        makePatchRequest(
          `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/comments/${id}/`,
          {
            comment
          }
        )
      )

      setComments(comments.map(c => (c.id === id ? response : c)))

      cb?.()

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Comment successfully edited on event ${event.name}.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const downloadAllFiles = () => {
    openLink(
      `/event-management/matters/${_scopeId}/events/${editedEvent?.id}/attachments/bulk-download/`
    )
  }

  const errorObject = (event: InitialEvent | LocalEvent) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (isEdit && !event.name) {
      hasError = true
      errorMessage = 'Name is required.'
    }
    if (event.name && event.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }

    return { hasError, errorMessage }
  }

  function overwriteDuplicates(oldAtts: APIAttachment[], serializedAttachments: APIAttachment[]) {
    const existingNames = serializedAttachments.map(a => a.file_name)
    return oldAtts.filter(o => !existingNames.includes(o.file_name))
  }

  return (
    <>
      <div className={s.container} data-testid="add-event-container">
        <div className={s.close} onClick={toggleAddEventSidebar} data-testid="add-event-close">
          &times;
        </div>
        {context === 'workbench' && !isEdit && (
          <>
            <div className={s.relatedMatter} data-for="resetWarning" data-tip>
              <KeyValuePairs
                style={{ margin: '10px 10px 0 0' }}
                pairs={[
                  {
                    key: 'Related to*',
                    style: { marginBottom: '10px', height: 40 },
                    keyClass: s.key,
                    sectionClass: s.section,
                    valueClass: s.value,
                    value: (
                      <>
                        <b style={{ position: 'relative', top: 4, marginRight: 10 }}>Matter: </b>
                        {/* @ts-expect-error */}
                        <PaginatedSelect
                          url="/manage/matters/v2/simple_matter_list/?can_edit=true"
                          value={event.relatedMatter as MatterOption}
                          onChange={(item: MatterOption): void => {
                            setEvent({
                              ...event,
                              relatedMatter: {
                                value: item?.value as number,
                                label: item?.label ?? '',
                                status: item?.status ?? ''
                              },
                              attendees: []
                            })
                            item?.value && setScopeId(String(item.value))
                          }}
                          isClearable={false}
                          placeholder="Select..."
                          className={s.select}
                          styles={customStyle}
                          isPortal
                          comps={{ Option: CustomOption }}
                          defaultMenuIsOpen={context === 'workbench'}
                          serializer={(options: APISimpleMatter[]) =>
                            toMattersOptions(options, true)
                          }
                          pageStart={0}
                          resultsProperty="rows"
                          pageParam="page_number"
                          searchTermParam="search"
                        />
                      </>
                    )
                  }
                ]}
              />
            </div>
            {event.relatedMatter && (
              <ReactTooltip id="resetWarning" type="light" effect="solid" place="bottom" border>
                Changing a events relation will clear the selected Attendees
              </ReactTooltip>
            )}
          </>
        )}
        <div
          className={cn(s.header, {
            [s.error]: nameInputFocused && event.name.length > MAX_NAME_LENGTH
          })}
        >
          <CharLimitInput
            value={event.name}
            placeholder="Add event name*"
            onChangeCb={e => updateEvent(e.target.value, 'name')}
            maxLength={MAX_NAME_LENGTH}
            hideInfoText={!nameInputFocused}
            hasError={nameInputFocused && errorObject(event).hasError}
            customErrorMessage={nameInputFocused ? errorObject(event).errorMessage : undefined}
            style={{ width: '100%', outline: 'none !important', padding: 5 }}
            cssClass={cn(s.nameInput, {
              [s.inputNoBorders]: event.name,
              [s.readOnly]: isReadOnly,
              [s.focused]: nameInputFocused
            })}
            dynamicCharCalculation
            sectionStyles={{ width: '95%' }}
            onFocus={() => setNameInputFocused(true)}
            focused={context === 'matter' && !isEdit}
            refObject={inputRef}
            onBlur={() => {
              if (isEdit) {
                if (editedProperties.name?.trim() && editedProperties.name !== oldEvent?.name) {
                  saveEvent(toEvent(editedProperties as LocalEvent), isEdit, () => {
                    resetEditedProperties()
                    if (activeTab === 'history') {
                      fetchHistory()
                    }
                  })
                }
              }
              setNameInputFocused(false)
            }}
            isDisabled={isEdit && isReadOnly}
            testid="event-name-input"
          />
          {isEdit && (
            <div className={s.info}>
              <span>
                {event.eventId}
                <span style={{ margin: '0 5px' }}>&#8226;</span>
                Created {format(new Date(event.createdDate), 'MMM dd, yyyy')}{' '}
                <span style={{ margin: '0 5px' }}>&#8226;</span> Created by{' '}
                {event.isExternal ? (
                  <>
                    {event.createdBy?.label} using CalendarRules{' '}
                    <PiGavelFill className={s.gavelIcon} />
                  </>
                ) : (
                  event.createdBy?.label ?? ''
                )}
              </span>
            </div>
          )}
        </div>
        <div
          className={cn(s.content, {
            [s.contentEdit]: isEdit,
            [s.workbench]: context === 'workbench'
          })}
        >
          <Widgets>
            <Widget
              icon={<FaRegCalendarCheck data-testid="widget-date-icon" />}
              label={
                <span>
                  DATE <span style={{ color: 'red' }}>*</span>
                </span>
              }
              value={
                event.startDate &&
                format(
                  new Date(event.startDate),
                  isSameYear(new Date(event.startDate), new Date()) ? 'MMM dd' : 'MMM dd, yyyy'
                )
              }
            >
              <DueDatePicker
                onConfirm={(value: Date | DateFormat | undefined) => {
                  updateEvent([value as Date, null], ['startDate', 'endDate'])
                  if (isEdit) {
                    saveEvent(
                      toEvent({
                        id: editedProperties.id,
                        startDate: value || '',
                        endDate: null
                      } as LocalEvent),
                      isEdit,
                      () => {
                        resetEditedProperties()
                        if (activeTab === 'history') {
                          fetchHistory()
                        }
                      },
                      true
                    )
                  }
                }}
                onClearCb={() => {
                  updateEvent(undefined, 'endDate')
                }}
                value={
                  event.startDate &&
                  (`${format(
                    new Date(event.startDate as string),
                    DATE_FORMATS.DEFAULT_DATE_FNS
                  )}` as DateFormat)
                }
                readOnly={isEdit && isReadOnly}
                canSave={value => {
                  if (!isEdit) return true

                  return Boolean(value)
                }}
              />
            </Widget>
            <Widget
              icon={<FaRegClock />}
              label="TIME"
              value={
                event.endDate &&
                `${format(new Date(event.startDate as string), 'h:mm a')} - ${format(
                  new Date(event.endDate as string),
                  'h:mm a'
                )} ${event.timezone}`
              }
              isDisabled={!event.startDate}
            >
              <TimePicker
                onConfirm={(values: (string | null)[]) => {
                  const dateTimeValues =
                    event.startDate && event.startDate instanceof Date
                      ? [
                          combineDateAndTimeString(event.startDate, values[0]),
                          values[1] ? combineDateAndTimeString(event.startDate, values[1]) : null
                        ]
                      : []

                  updateEvent(dateTimeValues, ['startDate', 'endDate'])
                  if (isEdit) {
                    saveEvent(
                      toEvent({
                        id: editedProperties.id,
                        startDate: dateTimeValues[0],
                        endDate: dateTimeValues[1]
                      } as LocalEvent),
                      isEdit,
                      () => {
                        resetEditedProperties()
                        if (activeTab === 'history') {
                          fetchHistory()
                        }
                      },
                      true
                    )
                  }
                }}
                isRange
                values={
                  !event.endDate
                    ? [null, null]
                    : [
                        event.startDate ? format(new Date(event.startDate as string), 'HH:mm') : '',
                        event.endDate ? format(new Date(event.endDate as string), 'HH:mm') : ''
                      ]
                }
                readOnly={isEdit && isReadOnly}
                isValid={
                  !event.endDate
                    ? undefined
                    : ([start, end]) => {
                        // this is needed when the time is used together with a date. in this case
                        // if you initially have 9:00 AM and 9:00 PM as start/end time
                        // and then change timezone to something that is 10hours ahead or behind
                        // the start/end time will now be 9:00 PM and 9:00 AM respectively
                        // and this case should be valid because it will be on 2 different days

                        const startHours = start.getHours()
                        const startMinutes = start.getMinutes()
                        const startSeconds = start.getSeconds()
                        const startMilliseconds = start.getMilliseconds()

                        const endHours = end.getHours()
                        const endMinutes = end.getMinutes()
                        const endSeconds = end.getSeconds()
                        const endMilliseconds = end.getMilliseconds()

                        // Update event.startDate and event.endDate with the time from start and end
                        event.startDate = setHours(event.startDate as Date, startHours)
                        event.startDate = setMinutes(event.startDate, startMinutes)
                        event.startDate = setSeconds(event.startDate, startSeconds)
                        event.startDate = setMilliseconds(event.startDate, startMilliseconds)

                        event.endDate = setHours(event.endDate as Date, endHours)
                        event.endDate = setMinutes(event.endDate, endMinutes)
                        event.endDate = setSeconds(event.endDate, endSeconds)
                        event.endDate = setMilliseconds(event.endDate, endMilliseconds)

                        if (
                          isAfter(event.startDate, event.endDate) ||
                          isEqual(event.startDate, event.endDate)
                        ) {
                          return false
                        } else {
                          return true
                        }
                      }
                }
              />
            </Widget>
            <Widget
              label="ATTENDEE(S)"
              value={getUsersLabel('attendees')}
              icon={getUsersIcon('attendees')}
              isDisabled={context === 'workbench' && !isEdit && !event.relatedMatter}
              hasTooltip={context === 'workbench' && !isEdit && !event.relatedMatter}
              tooltipText="Related to must be selected to assign attendees."
            >
              <Users
                value={event.attendees}
                style={{ width: '400px' }}
                requestParams={{ matterId: +_scopeId, canEdit: true, active: true }}
                onConfirm={(values: Option[]) => {
                  updateEvent(values, 'attendees')
                  if (isEdit) {
                    addAttendees(values)
                  }
                }}
                readOnly={isEdit && isReadOnly}
              />
            </Widget>
          </Widgets>
          <label className={s.label}>Description</label>
          {isEdit && (!showDescription || isReadOnly) && (
            <>
              {event.description ? (
                <Markdown
                  value={event.description}
                  className={cn(s.description, {
                    [s.readOnly]: isReadOnly
                  })}
                  onClick={() => (isReadOnly ? null : setShowDescription(!showDescription))}
                />
              ) : (
                <div
                  className={cn(s.descriptionPlaceholder, {
                    [s.readOnly]: isReadOnly
                  })}
                  onClick={() => (isReadOnly ? null : setShowDescription(!showDescription))}
                >
                  Add description...
                </div>
              )}
            </>
          )}
          {(!isEdit || showDescription) && (
            <RichTextEditor
              value={event.description ?? ''}
              onChange={value => {
                updateEvent(value, 'description')
              }}
              onBlur={
                !isEdit
                  ? undefined
                  : () => {
                      editedProperties.description !== undefined &&
                        editedProperties.description !== oldEvent?.description &&
                        !isDescriptionTooLong &&
                        saveEvent(toEvent(editedProperties as LocalEvent), isEdit, () => {
                          resetEditedProperties()
                          if (activeTab === 'history') {
                            fetchHistory()
                          }
                        })
                      setShowDescription(false)
                    }
              }
              maxLength={event.isExternal ? undefined : MAX_DESCRIPTION_LENGTH}
              dynamicCharCalculation
              validation={setIsDescriptionTooLong}
              customErrorMessage="Maximum character limit reached"
            />
          )}
          {isEdit ? (
            <>
              <Tabs selectedIndex={getTabIndex()}>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'overview'}
                  header="Overview"
                  onClickCb={() => {
                    setActiveTab('overview')
                  }}
                >
                  <Overview
                    eventTypes={selectOptions?.eventTypes ?? []}
                    event={event}
                    scopeName={scopeName || (event?.relatedMatter?.label ?? '')}
                    updateEvent={updateEvent}
                    saveEvent={saveEvent}
                    isEdit={isEdit}
                    editedProperties={editedProperties}
                    resetEditedProperties={resetEditedProperties}
                    readOnly={isReadOnly}
                    context={context}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'location'}
                  header="Location"
                  count={event.location ? 1 : undefined}
                  onClickCb={() => {
                    setActiveTab('location')
                  }}
                >
                  <Location
                    value={event.location}
                    onSave={value => {
                      updateEvent(value, 'location')
                      saveEvent(
                        toEvent({
                          ...(isEdit
                            ? {
                                id: editedProperties.id
                              }
                            : editedProperties),
                          location: value
                        } as LocalEvent),
                        isEdit,
                        () => {
                          resetEditedProperties()
                        }
                      )
                    }}
                    isEdit={isEdit}
                    readOnly={isReadOnly}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'files'}
                  header="Files"
                  count={event.fileAttachments.length || undefined}
                  onClickCb={() => {
                    setActiveTab('files')
                  }}
                >
                  {/* @ts-expect-error */}
                  <FileUpload
                    view="simple"
                    scope="event"
                    texts={{
                      previewModalTitle: 'Preparing to Download',
                      previewModalContent: 'File should download shortly'
                    }}
                    files={event.fileAttachments}
                    isEditable
                    deleteAttachment={showDeleteModal}
                    uploadFiles={(attachments: Attachment) => {
                      addFiles(toAPIAttachments(attachments))
                    }}
                    downloadAll={downloadAllFiles}
                    hideScroll={false}
                    readOnly={isReadOnly}
                    isPremium={!isBasicPlan}
                    existingFilesNumber={event.fileAttachments.length}
                    limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                    maxLimitReachedError={`File upload failed. A maximum of ${BASIC_TIER_TOTAL_ALLOWED} files are allowed per event.`}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'comments'}
                  header="Comments"
                  count={comments.length || undefined}
                  onClickCb={() => {
                    setActiveTab('comments')
                  }}
                >
                  <CommentsList
                    comments={comments}
                    editComment={editComment}
                    deleteComment={deleteComment}
                    readOnly={isReadOnly}
                  />
                </Tab>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'history'}
                  header="History"
                  onClickCb={() => {
                    setActiveTab('history')
                  }}
                >
                  <HistoryList history={history} />
                </Tab>
              </Tabs>
            </>
          ) : (
            <>
              <Location
                value={event.location}
                onSave={value => {
                  updateEvent(value, 'location')
                }}
              />
              {/* @ts-expect-error */}
              <FileUpload
                view="simple"
                simpleViewProps={{
                  hideHoverAction: true
                }}
                scope="event"
                texts={{
                  previewModalTitle: 'Preparing to Download',
                  previewModalContent: 'File should download shortly'
                }}
                files={event.fileAttachments}
                isEditable
                deleteAttachment={(id: number | string) => {
                  setEvent({
                    ...event,
                    fileAttachments: event.fileAttachments.filter(
                      attachment => attachment.id !== id
                    )
                  })
                }}
                uploadFiles={(attachments: Attachment) => {
                  const serializedAttachments = toAPIAttachments(attachments)

                  updateEvent(
                    [
                      ...overwriteDuplicates(event.fileAttachments, serializedAttachments),
                      ...serializedAttachments
                    ],
                    'fileAttachments'
                  )
                }}
                hideScroll={false}
                existingFilesNumber={event.fileAttachments.length}
                isPremium={!isBasicPlan}
                limit={isBasicPlan ? BASIC_TIER_TOTAL_ALLOWED : undefined}
                maxLimitReachedError={`File upload failed. A maximum of ${BASIC_TIER_TOTAL_ALLOWED} files are allowed per event.`}
              />
              <Overview
                eventTypes={selectOptions?.eventTypes ?? []}
                event={event}
                scopeName={scopeName}
                updateEvent={updateEvent}
              />
            </>
          )}
        </div>
        <div
          className={cn(s.footer, {
            [s.comments]: isEdit
          })}
        >
          {!isEdit ? (
            <>
              <Button onClick={toggleAddEventSidebar} isPrimary isOutline hasNewDesign>
                Cancel
              </Button>
              <Button
                onClick={() => saveEvent(toEvent(event as LocalEvent))}
                isPrimary
                isDisabled={!isValid()}
                hasNewDesign
              >
                Save Event
              </Button>
            </>
          ) : (
            <Comments readOnly={isReadOnly} addComment={addComment} />
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={deleteAttachment}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </>
  )
}

export default AddEvent
