const _getErrObj = errors => {
  return { isError: !!Object.keys(errors).length, errors }
}

export const validateNewVendor = formState => {
  let errors = {}
  let isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  let vendorEmail = formState.vendorContactEmail

  if (!(vendorEmail && vendorEmail.match(isEmail))) {
    errors.vendorContactEmail = true
  }

  return _getErrObj(errors)
}
