import { Component } from 'react'
import s from '../../PreviewPanelContent.scss'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'

class PreviewPanelAttribute extends Component {
  render() {
    const { attribute, systemGeneratedCss, getAttributeValue } = this.props
    return (
      <div key={attribute.id} className={s.attributesRow}>
        {attribute.type === 'relationship' ? (
          <DynamicAttribute
            dynamicAttributeClass={s.dynamicAttribute}
            threeLevelsClass={s.threeLevels}
            twoLevelsClass={s.twoLevels}
          >
            {attribute.attributes.map((attr, relIndex) => (
              <div key={attr.id} className={s.attributeWrapper}>
                <div className={s.attributeLabel}>
                  {attr.label}
                  {attr.required && <span className={s.asterix}>*</span>}
                </div>
                <div className={s.attributeValue}>
                  <span>{attr.value && +attr.value !== -1 ? attr.value.label : 'N/A'}</span>
                </div>
              </div>
            ))}
          </DynamicAttribute>
        ) : (
          <>
            <div className={s.attributeLabel}>
              {attribute.label}
              {attribute.required && <span className={s.asterix}>*</span>}
            </div>
            <div className={s.attributeValue} data-testid={attribute.label}>
              <span className={systemGeneratedCss}>{getAttributeValue(attribute)}</span>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default PreviewPanelAttribute
