import { useEffect, useState, useRef, memo } from 'react'
import s from './DynamicAttributesImport.scss'
import {
  Panel,
  Stepper,
  useLoading,
  Button,
  HoverAction,
  FlexyHalf,
  ModalContainer,
  FileIcon,
  TextInput
} from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { openLink } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { toRelationships } from './serializers'
import { useImmer } from 'use-immer'
import Select from 'react-select'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { FaTrash, FaAngleLeft } from 'react-icons/fa'
import APP_ACT from 'app/actions'
import { UploadProgress } from './UploadProgress'
import { CSVLink } from 'react-csv'

const DynamicAttributesImport = () => {
  const [isLoading, withLoadingLocks] = useLoading()
  const [relationships, setRelationships] = useState([])
  const dispatch = useDispatch()
  const [steps, setSteps] = useImmer([])
  const [selectedRelationship, setSelectedRelationship] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [csv, setCsv] = useState(null)
  const [slColumns, setSlColumns] = useState(['Level1', 'Level2', 'Level3'])
  const [csvColumns, setCsvColumns] = useState([])
  const [initialCsvColumns, setInitialCsvColumns] = useState([])
  const [level1, setLevel1] = useState(null)
  const [level2, setLevel2] = useState(null)
  const [level3, setLevel3] = useState(null)
  const [s3Files, setS3Files] = useState([])
  const [isUploadProgressModalVisible, setIsUploadProgressModalVisible] = useState(false)
  const [isUploadFinished, setIsUploadFinished] = useState(false)
  const [fileSource, setFileSource] = useState(null)
  const [errorsCsv, setErrorsCsv] = useState(null)
  const csvLink = useRef()

  useEffect(() => {
    ;(async () => {
      try {
        const { relationships } = await withLoadingLocks(
          makeGetRequest('/templates/related/list_relationships')
        )
        setRelationships(toRelationships(relationships))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const stepsArr = [
      {
        text: 'What would you like to import?',
        completed: false,
        content: (
          <div className={s.nameSection}>
            <label>Relationship Name*</label>
            <Select
              placeholder="Select Name"
              noOptionsMessage={() => 'No results...'}
              value={selectedRelationship}
              onChange={value => {
                setSelectedRelationship(value)
              }}
              options={relationships}
              isOptionDisabled={option => option.used_on_matter || option.used_on_template}
              className={s.select}
              aria-label="relationship"
            />
          </div>
        )
      },
      {
        text: 'Select a CSV to import',
        completed: false,
        content: (
          <div className={s.nameSection}>
            <Button
              onClick={() =>
                openLink('/static/assets/templates/SimpleLegal_Relationship_Import_Template.csv')
              }
            >
              Download Template
            </Button>
            <a href="/help/?article_id=1500002288801" target="_blank" rel="noreferrer">
              What fields can I import?
            </a>
            {csv ? (
              <div className={s.csvList}>
                <span className={s.fileIcon}>
                  <FileIcon />
                </span>
                <label className={s.label}>{csv.name}</label>
                <HoverAction
                  className={s.delete}
                  onClick={removeFile}
                  icon={<FaTrash />}
                  size="xsmall"
                  tip="Remove"
                />
              </div>
            ) : (
              <div className={s.dropzoneWrapper}>
                <Dropzone />
              </div>
            )}
          </div>
        )
      },
      {
        text: 'Define fields',
        completed: false,
        content: (
          <div className={s.fieldsWrapper}>
            <FlexyHalf>
              <div>
                <label>Simplelegal Fields</label>
                <TextInput className={s.input} value={`${slColumns[0]}*`} isDisabled />
                <TextInput className={s.input} value={`${slColumns[1]}*`} isDisabled />
                <TextInput className={s.input} value={slColumns[2]} isDisabled />
              </div>
              <div>
                <label>CSV Fields</label>
                <Select
                  placeholder="Select Field"
                  noOptionsMessage={() => 'No results...'}
                  value={level1}
                  onChange={setLevel1}
                  options={csvColumns}
                  className={s.selectField}
                  isClearable
                  aria-label="level1"
                />
                <Select
                  placeholder="Select Field"
                  noOptionsMessage={() => 'No results...'}
                  value={level2}
                  onChange={setLevel2}
                  options={csvColumns}
                  className={s.selectField}
                  isClearable
                  aria-label="level2"
                />
                <Select
                  placeholder="Select Field"
                  noOptionsMessage={() => 'No results...'}
                  value={level3}
                  onChange={setLevel3}
                  options={csvColumns}
                  className={s.selectField}
                  isClearable
                  isDisabled={slColumns.length !== 3}
                />
              </div>
            </FlexyHalf>
          </div>
        )
      }
    ]

    setSteps(draft => {
      if (selectedRelationship) {
        stepsArr[0].completed = true
      }
      if (csv) {
        stepsArr[1].completed = true
      }
      if (level1?.value && level2?.value) {
        stepsArr[2].completed = true
      }
      return stepsArr
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationships, selectedRelationship, csv, slColumns, csvColumns, level1, level2, level3])

  useEffect(() => {
    if (selectedRelationship && csv) {
      setIsUploadProgressModalVisible(true)
    }
  }, [csv, selectedRelationship, dispatch])

  useEffect(() => {
    const filteredCsvCols = initialCsvColumns.filter(
      c => ![level1?.value, level2?.value, level3?.value].includes(c.value)
    )
    setCsvColumns(filteredCsvCols)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level1?.value, level2?.value, level3?.value])

  const onDrop = async files => {
    setCsv(files[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/*': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    multiple: false,
    disabled: !selectedRelationship,
    onDrop,
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    }
  })

  const Dropzone = memo(() => {
    return (
      <div
        data-testid="dropzone"
        aria-label="dropzone"
        {...getRootProps({ className: cn(s.dropzone, { [s.onDrag]: isDragging }) })}
      >
        <input {...getInputProps()} />
        <div className={s.dropzoneText}>
          Drag and drop completed CSV or <span>choose file</span> <br />
        </div>
      </div>
    )
  })

  const removeFile = () => {
    setCsv(null)
    setSlColumns(['Level1', 'Level2', 'Level3'])
    setLevel1(null)
    setLevel2(null)
    setLevel3(null)
    setInitialCsvColumns([])
    setCsvColumns([])
  }

  const startImport = async () => {
    const { key, contentType } = s3Files
    const mappings = [{ [slColumns[0]]: level1?.value }, { [slColumns[1]]: level2?.value }]

    if (level3?.value) {
      mappings.push({ [slColumns[2]]: level3?.value })
    }

    try {
      await withLoadingLocks(
        makePostRequest(`/templates/related/import_pairs/${selectedRelationship.value}`, {
          key,
          contentType,
          mappings
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Relationship successfully paired',
          level: 'success'
        }
      })
      removeFile()
    } catch (error) {
      if (error.response?.data?.message?.csv) {
        setErrorsCsv(error.response.data.message.csv)
      } else {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }
  }

  const successCb = response => {
    const csvCols = response.attachments[0].csv_columns.map(c => ({
      value: c,
      label: c
    }))
    setSlColumns(response.attachments[0].sl_columns)
    setCsvColumns(csvCols)
    setInitialCsvColumns(csvCols)
    setS3Files(response.s3Files[0])
  }

  const cancelFileUpload = () => {
    fileSource.cancel()
    removeFile()
    setIsUploadFinished(false)
  }

  return (
    <>
      <h2 className={s.title}>Import data fields from CSV...</h2>
      <Panel
        styles={{ boxShadow: 'none' }}
        className={s.importCsvContainer}
        leftActions={[
          <a key="0" href="/v2/dynamic_attributes/list">
            <FaAngleLeft /> Back to Relationships
          </a>
        ]}
      >
        <Stepper orientation="vertical" automaticHeader activeStep={0} steps={steps} />
        <Button
          style={{ margin: '0 0 0 140px' }}
          isDisabled={steps.filter(s => s.completed).length !== 3}
          onClick={startImport}
        >
          Start Import
        </Button>
        {isUploadProgressModalVisible && (
          <ModalContainer
            title={isUploadFinished ? 'Import completed' : 'Importing CSV...'}
            cancelText="Cancel"
            size="md"
            cancelCb={() => {
              cancelFileUpload()
              setIsUploadProgressModalVisible(false)
            }}
            confirmText="Close"
            confirmCb={() => {
              setIsUploadProgressModalVisible(false)
              setIsUploadFinished(false)
            }}
            isDisabled={!isUploadFinished}
            hideCancelBtn={isUploadFinished}
            hasCloseIcon={false}
            content={
              <UploadProgress
                dispatch={dispatch}
                file={csv}
                successCb={successCb}
                selectedRelationship={selectedRelationship}
                setIsUploadFinished={setIsUploadFinished}
                setFileSource={setFileSource}
              />
            }
          />
        )}
        {errorsCsv && (
          <>
            <ModalContainer
              title="Import failed"
              cancelText="Cancel"
              size="md"
              cancelCb={() => {
                setErrorsCsv(null)
              }}
              confirmText="Download"
              confirmCb={() => {
                csvLink.current.link.click()
                setTimeout(() => {
                  setErrorsCsv(null)
                }, 2000)
              }}
              hasCloseIcon={false}
              content={
                <span className={s.downloadInfo}>Download the report to see why it failed.</span>
              }
            />
            <CSVLink
              data={errorsCsv}
              filename={s3Files.name}
              className={s.hidden}
              ref={csvLink}
              separator=","
              target="_blank"
            />
          </>
        )}
      </Panel>
    </>
  )
}

export default DynamicAttributesImport
