import { Fragment } from 'react'
import s from './SharedEvaluators.scss'
import {
  HoverFieldContainer,
  Condense,
  CheckboxContainer,
  If,
  HoverAction,
  ModalContainer
} from 'simple-core-ui'
import Select from 'react-select'
import cn from 'classnames'
import { SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE } from '../actions'
import { FaInfoCircle } from 'react-icons/fa'

const SharedEvaluators = ({
  readOnly,
  grayBackground = true,
  withHeader = true,
  resetDefault,
  users,
  roles,
  changeAttributeValue,
  sharedEvaluators,
  loadCb,
  updateEvaluators
}) => (
  <div
    className={cn(s.container, {
      [s.grayBackground]: grayBackground
    })}
  >
    <If condition={withHeader}>
      <header className={s.header}>
        Shared Evaluators
        <section data-testid="shared_evaluators_section" className={s.reset}>
          <ModalContainer
            title="Are you sure you want to reset to template defaults?"
            content="This will reset your shared evaluators and may change your survey from inactive/active depending on your template default."
            confirmText="Reset"
            cancelText="Cancel"
            confirmCb={() => {
              resetDefault()
            }}
            cancelCb
            size="sm"
          >
            {openModal => (
              <CheckboxContainer
                cb={() => {
                  if (sharedEvaluators.isPinned) {
                    updateEvaluators('', true)
                  } else {
                    openModal()
                  }
                }}
                size={'sm'}
                isChecked={sharedEvaluators.isPinned}
                disabled={readOnly}
              />
            )}
          </ModalContainer>
          <span className={s.hoverAction}>
            Follow template default
            <HoverAction
              tip="This will reset your shared evaluators and may change your survey from inactive/active depending on your template default."
              icon={<FaInfoCircle />}
              size="xsmall"
              style={{
                padding: '1px',
                position: 'relative',
                top: '2px',
                left: '5px',
                background: '#a3a3a3',
                borderRadius: '50%'
              }}
            />
          </span>
        </section>
      </header>
    </If>
    <div className={s.row} data-testid="template_evaluators_roles">
      <p className={s.label}>Role: </p>
      <If
        condition={!readOnly && !sharedEvaluators.isPinned}
        fallback={
          <Fragment>
            {sharedEvaluators.roles.map(role => (
              <p data-testid="template_evaluators_role" className={s.items} key={role.value}>
                {role.label}
              </p>
            ))}
          </Fragment>
        }
      >
        <HoverFieldContainer
          onCloseEdit={updateEvaluators}
          alwaysShowPencil
          readOnly={readOnly}
          displayValue={
            <Condense
              values={sharedEvaluators.roles.map(({ label }) => label)}
              badgeProps={{
                size: 'sm',
                asPill: true
              }}
            />
          }
          renderField={() => (
            <Select
              autoFocus
              name="select-shared-roles"
              placeholder="Search..."
              noOptionsMessage={() => 'No results...'}
              value={sharedEvaluators.roles}
              onChange={value =>
                changeAttributeValue({
                  value,
                  property: 'roles',
                  type: SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE
                })
              }
              options={roles}
              isClearable={false}
              isMulti
            />
          )}
          isEmpty={!sharedEvaluators.roles.length}
        />
      </If>
    </div>
    <div className={s.row} data-testid="template_evaluators_users">
      <p className={s.label}>People: </p>
      <If
        condition={!readOnly && !sharedEvaluators.isPinned}
        fallback={
          <Fragment>
            {sharedEvaluators.users.map(user => (
              <p className={s.items} key={user.value}>
                {user.label}
              </p>
            ))}
          </Fragment>
        }
      >
        <HoverFieldContainer
          readOnly={readOnly}
          onCloseEdit={updateEvaluators}
          displayValue={
            <Condense
              values={sharedEvaluators.users.map(({ label }) => label)}
              badgeProps={{
                size: 'sm',
                asPill: true
              }}
            />
          }
          renderField={() => (
            <Select
              autoFocus
              name="select-shared-evaluators"
              placeholder="Search..."
              className={s.select}
              noOptionsMessage={() => 'No results...'}
              value={sharedEvaluators.users}
              onChange={value =>
                changeAttributeValue({
                  value,
                  property: 'users',
                  type: SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE
                })
              }
              options={users}
              isClearable={false}
              onInputChange={loadCb}
              isMulti
            />
          )}
          isEmpty={!sharedEvaluators.users.length}
        />
      </If>
    </div>
  </div>
)

export default SharedEvaluators
