import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Thermometer from 'budgets/Thermometer'
import s from '../styles/BudgetRequest.scss'
import { If } from 'simple-core-ui'
import moment from 'moment'
import { curMom } from 'utils/dateUtils'
import { BUDGET_TYPES } from 'budgets/constants'

const { PHASE, LIFE_OF_MATTER, MONTHLY, QUARTERLY } = BUDGET_TYPES

const typeLabel = {
  Monthly: 'Months',
  Quarterly: 'Quarters',
  'Life of Matter': 'Months',
  Phase: 'Months'
}

@connect(({ budgets }, ownProps) => {
  const details = budgets.budgetDetails
  const allocatedTotal = details.allocated_total
  const approvedTotal = details.approved_total
  const pendingTotal = details.pending_total
  const accrualTotal = details.accrual_total
  const combinedTotal = details.combined_total
  const type = details.type
  let timeRemaining

  const endMonth = moment(details.end_date).month()
  const endQuarter = moment(details.end_date).quarter()
  const currentMonth = curMom().month()
  const currentQuarter = curMom().quarter()

  if ([MONTHLY, PHASE, LIFE_OF_MATTER].includes(type)) {
    timeRemaining = endMonth - currentMonth
  } else if (type === QUARTERLY) {
    timeRemaining = endQuarter - currentQuarter + 1
  }

  return {
    allocatedTotal,
    approvedTotal,
    combinedTotal,
    pendingTotal,
    accrualTotal,
    timeRemaining,
    type,
    showTimeRemaining: ownProps.showTimeRemaining
  }
})
class TotalSnapshotContainer extends Component {
  render() {
    const {
      allocatedTotal,
      approvedTotal,
      combinedTotal,
      pendingTotal,
      accrualTotal,
      timeRemaining,
      type,
      showTimeRemaining
    } = this.props

    return (
      <Fragment>
        <div className={s.summaryHeader}>Total Spent to Date</div>
        <div className={s.thermometerContainer}>
          <Thermometer
            total={allocatedTotal}
            approved={approvedTotal}
            pending={pendingTotal}
            accrual={accrualTotal}
            combined={combinedTotal}
            timeRemaining={showTimeRemaining ? timeRemaining : null}
            timeType={typeLabel[type]}
          />
        </div>
        <If condition={showTimeRemaining}>
          <div>
            {timeRemaining} {typeLabel[type]?.toLowerCase()} left in budget
          </div>
        </If>
      </Fragment>
    )
  }
}

export default TotalSnapshotContainer
