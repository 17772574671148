import { useState, useMemo } from 'react'
import { MdUpdate } from 'react-icons/md'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import s from './CategoryContainer.scss'
import { Input, Popable, AssessmentOutlinedIcon } from 'simple-core-ui'
import { toReports } from './serializers'
import { Legend } from '../Legend'
import { LEGEND_ICON_DESCRIPTION, REPORT_TYPES } from '../../constants'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'

const getTooltipContent = key => {
  const [line1, line2] = LEGEND_ICON_DESCRIPTION[key]
  return (
    <>
      <div>{line1}</div>
      <div>{line2}</div>
    </>
  )
}

const legendConfig = {
  [REPORT_TYPES.live]: {
    icon: <AssessmentOutlinedIcon color="#57c787" height="22px" />,
    tooltip: getTooltipContent(REPORT_TYPES.live)
  },
  [REPORT_TYPES.daily]: {
    icon: <MdUpdate className={cn(s.legendIconColor, s.legendIcon)} />,
    tooltip: getTooltipContent(REPORT_TYPES.daily)
  }
}

const CategoryContainer = ({
  reports,
  showStatus = false,
  status = '',
  showFilter = true,
  showLegend
}) => {
  const [query, setQuery] = useState('')
  const { simplesetUserAccess } = useSelector(state => state.app.credentials.user)

  const filteredReports = useMemo(() => {
    return toReports(reports, query)
  }, [query, reports])

  const legendKeys = useMemo(() => {
    const isSupersetActive = hasModule(MODULE.SIMPLESET_REPORTING) && simplesetUserAccess
    const { live, daily } = REPORT_TYPES
    return isSupersetActive ? [live, daily] : [live]
  }, [simplesetUserAccess])

  return (
    <>
      {(showLegend || showFilter) && (
        <div className={showLegend ? s.filterWithLegend : s.filterOnly}>
          {showLegend && (
            <div className={s.legendContainer}>
              <Legend keys={legendKeys} />
            </div>
          )}
          {showFilter && (
            <div className={s.filterContainer}>
              {'Filter: '}
              <Input placeholder="Search" text={query} onChangeCb={e => setQuery(e.target.value)} />
            </div>
          )}
        </div>
      )}
      {Object.keys(filteredReports).map(
        category =>
          !!filteredReports[category].length && (
            <div key={category} className={s.category}>
              <h2 className={s.title}>{category}</h2>
              {filteredReports[category].map(report => (
                <div className={s.reportWrapper} key={report.url}>
                  {report.url ? (
                    <>
                      <div style={{ display: 'flex' }}>
                        <a
                          className={cn(s.name, {
                            [s.disabled]: report.kind === 'ui_visible_draft'
                          })}
                          href={report.kind === 'ui_visible_draft' ? undefined : report.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-for={`upgradeTier-${report.uuid}`}
                          data-tip
                        >
                          {report.text}
                        </a>
                        {report.type && (
                          <Popable
                            openOnHover
                            position="right"
                            content={legendConfig[report.type].tooltip}
                            contentStyles={{
                              padding: '0.5em',
                              borderRadius: '1px',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {legendConfig[report.type].icon}
                          </Popable>
                        )}
                      </div>
                    </>
                  ) : (
                    <p
                      className={cn(s.name, {
                        [s.disabled]: report.kind === 'ui_visible_draft'
                      })}
                    >
                      <span data-for={`upgradeTier-${report.uuid}`} data-tip>
                        {report.text}
                      </span>
                    </p>
                  )}
                  {report.kind === 'ui_visible_draft' && (
                    <ReactTooltip
                      id={`upgradeTier-${report.uuid}`}
                      type="light"
                      effect="solid"
                      place="top"
                      border
                    >
                      {report.reason || 'Restricted'}
                    </ReactTooltip>
                  )}
                  {showStatus && report.status === status && (
                    <i className={s.status}>{report.status}</i>
                  )}
                  <p className={s.description}>{report.description}</p>
                  {report.date && (
                    <p className={s.date}>{moment(report.date).format('MM/DD/YYYY h:mm a')}</p>
                  )}
                </div>
              ))}
            </div>
          )
      )}
    </>
  )
}

export default CategoryContainer
