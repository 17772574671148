import ContactsSelect from '../../../ContactsSelect/ContactsSelect'
import { Role, AssignRoleFormType, SelectedContact, ContactRolePair } from '../../types'
import ReactTooltip from 'react-tooltip'
import { SCOPE } from 'utils/constants'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import s from './AssignRolesForm.scss'
import { extractCgRoles, extractLabels } from 'contacts/AssignedContactsTableContainer/utils'
import Contact from 'contacts/Contact/Contact'
import { FaTimes } from 'react-icons/fa'
import { Grid } from 'simple-core-ui'
import Select, { StylesConfig } from 'react-select'
import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { SingularRoleWarning } from 'contacts/SingularRoleWarning'

interface Props {
  formValues: AssignRoleFormType
  onChangeHandler: (key: string, value: string | boolean | Role[] | SelectedContact) => void
  roles: Role[]
  vendorId: number
  clearFormValues: () => void
  isCgRoleAllowed: boolean
  maxAdminsCountReached: boolean
  singularRoleIntersectCb: (contact: SelectedContact | null, roles: Role[]) => ContactRolePair[]
}

const isMatterUpdateSharingEnabled = window.serverContext.get(
  'allow_vendor_collaboration_matter_updates'
)

const roleDropdownStyle: StylesConfig = {
  control: base => ({ ...base, maxHeight: 300 }),
  menu: base => ({ ...base, maxHeight: 300 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: '0.5px solid #040404',
    height: '30px',
    borderRadius: '3px'
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '400',
    paddingTop: '4.5px'
  })
}

const AssignRolesForm = ({
  formValues,
  onChangeHandler,
  roles,
  vendorId,
  clearFormValues,
  isCgRoleAllowed,
  maxAdminsCountReached,
  singularRoleIntersectCb
}: Props) => {
  const { Column, Row } = Grid

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const { selectedContact, labels } = formValues

  const scopedRequestConfig = (scope: string, vendorId: number) =>
    ({
      [SCOPE.VENDOR]: { v: vendorId, l: 'true', f: 'true' }
    }[scope])

  const singularRoleIntersect = useMemo(() => singularRoleIntersectCb(selectedContact, labels), [
    selectedContact,
    labels
  ])

  return (
    <div style={{ padding: '8px' }}>
      <Row>
        <Column>
          <div className={s.headingText}>
            {selectedContact ? <p>Selected Contact</p> : <p>Select Contact To Be Assigned</p>}
          </div>
          {selectedContact ? (
            <div className={s.selectContact}>
              <div style={{ width: '100%' }}>
                {/* @ts-expect-error */}
                <Contact
                  firstName={selectedContact.firstName}
                  lastName={selectedContact.lastName}
                  title={selectedContact.title}
                  company={selectedContact.org.name}
                  phone={selectedContact.phone}
                  email={selectedContact.email}
                  readOnly
                  hasNewForm
                />
              </div>
              <FaTimes className={s.closeModal} onClick={clearFormValues} />
            </div>
          ) : (
            /* @ts-expect-error */
            <ContactsSelect
              requestConfig={{ params: scopedRequestConfig(SCOPE.VENDOR, vendorId) }}
              selectedRoles={formValues.cgRoles}
              value={selectedContact}
              placeholder="Select..."
              onChange={(selectedContact: SelectedContact) =>
                onChangeHandler('selectedContact', selectedContact)
              }
            />
          )}
        </Column>
      </Row>

      {selectedContact && (
        <>
          {isCgRoleAllowed && (
            <Row>
              <Column span={6}>
                <div className={s.cgColumn}>
                  <div className={s.headingText}>
                    CounselGO Access<span className={s.asterisk}>*</span>
                    <AiOutlineInfoCircle
                      className={s.infoTooltip}
                      data-for="cgAccessInfoTooltip"
                      data-tip
                    />
                    <ReactTooltip
                      className={s.tooltip}
                      id="cgAccessInfoTooltip"
                      type="light"
                      effect="solid"
                      place="right"
                      border
                      borderColor="#939393"
                    >
                      <span>
                        CounselGO access allows a user to view and submit invoices and view referred
                        matters
                      </span>
                    </ReactTooltip>
                  </div>
                  <section className={s.radioContainer}>
                    <section className={s.radioField}>
                      <input
                        aria-label="is-cg-user"
                        type="radio"
                        checked={formValues.isCgUser}
                        onChange={() => {
                          onChangeHandler('isCgUser', true)
                        }}
                      />
                      {'Yes'}
                    </section>
                    <section className={s.radioField}>
                      <input
                        aria-label="is-not-cg-user"
                        type="radio"
                        checked={!formValues.isCgUser}
                        onChange={() => onChangeHandler('isCgUser', false)}
                      />
                      {'No'}
                    </section>
                  </section>
                </div>
              </Column>
              {formValues.isCgUser ? (
                <Column span={6}>
                  <div className={s.cgColumn}>
                    <div className={s.headingText}>
                      CounselGO Admin<span className={s.asterisk}>*</span>
                      <AiOutlineInfoCircle
                        className={s.infoTooltip}
                        data-for="cgAdminInfoTooltip"
                        data-tip
                      />
                      <ReactTooltip
                        className={s.tooltip}
                        id="cgAdminInfoTooltip"
                        type="light"
                        effect="solid"
                        place="right"
                        border
                        borderColor="#939393"
                      >
                        <span>
                          CounselGO admins have the ability to add new CounselGO users and admins
                        </span>
                      </ReactTooltip>
                    </div>
                    <section className={s.radioContainer}>
                      <section className={s.radioField} data-for="isCgAdmin" data-tip>
                        <input
                          aria-label="is-cg-admin"
                          type="radio"
                          className={s.cgUserInput}
                          checked={formValues.isCgAdmin}
                          onChange={() => {
                            onChangeHandler('isCgAdmin', true)
                          }}
                          disabled={maxAdminsCountReached}
                        />
                        {maxAdminsCountReached ? (
                          <ReactTooltip
                            className={s.tooltip}
                            id="isCgAdmin"
                            type="light"
                            effect="solid"
                            place="bottom"
                            border
                            event="click"
                            borderColor="#939393"
                            globalEventOff="click"
                            clickable
                            isCapture
                          >
                            <span>
                              The maximum limit of 2 CounselGO Admins has been reached. Click{' '}
                              {/* TODO: Replace span with <Link /> */}
                              <span className={s.helpLink}>here</span> to learn more.
                            </span>
                          </ReactTooltip>
                        ) : null}
                        {'Yes'}
                      </section>
                      <section className={s.radioField}>
                        <input
                          aria-label="is-not-cg-admin"
                          type="radio"
                          checked={!formValues.isCgAdmin}
                          onChange={() => onChangeHandler('isCgAdmin', false)}
                        />
                        {'No'}
                      </section>
                    </section>
                  </div>
                </Column>
              ) : null}
            </Row>
          )}
          {isCgRoleAllowed && formValues.isCgUser && (
            <Row>
              <Column>
                <section className={s.headingText}>
                  Included roles
                  <div className={s.descriptionText}>
                    {`(Invoices, Matter Basics, Budgets, Timekeepers${
                      formValues.isCgAdmin ? ', User Management' : ''
                    })`}
                  </div>
                  <AiOutlineInfoCircle
                    className={s.infoTooltip}
                    data-for="cgRoleInfoTooltip"
                    data-tip
                  />
                  <ReactTooltip
                    className={cn(s.tooltip, s.largeTooltip)}
                    id="cgRoleInfoTooltip"
                    type="light"
                    effect="solid"
                    place="right"
                    event="click"
                    border
                    borderColor="#939393"
                    globalEventOff="click"
                    clickable
                    isCapture
                  >
                    <span>
                      Add roles for CounselGO users who need advanced functionality (
                      {isMatterUpdateSharingEnabled ? (
                        <>
                          General File Sharing,
                          <br />
                          Matter File Sharing and Matter Updates
                        </>
                      ) : (
                        <>
                          General File Sharing and
                          <br />
                          Matter File Sharing
                        </>
                      )}
                      ). Click{' '}
                      <a
                        className={s.helpLink}
                        href="https://zendesk.simplelegal.com/hc/en-us/articles/1500002549702-CounselGO-User-Roles"
                      >
                        here
                      </a>{' '}
                      to learn more.
                    </span>
                  </ReactTooltip>
                </section>
                <section className={s.selectRoles}>
                  <p className={s.container}>Select additional roles:</p>
                  <Select
                    id="testid_contacts_roles_select"
                    aria-label="select cg role"
                    className={s.container}
                    name="contact-role-select"
                    noOptionsMessage={() => 'No contact roles found...'}
                    styles={roleDropdownStyle}
                    isClearable={false}
                    value={formValues.cgRoles}
                    onChange={selectedRoles => onChangeHandler('cgRoles', selectedRoles as Role[])}
                    options={extractCgRoles(roles)}
                    isMulti
                    menuPortalTarget={document.body}
                  />
                  <div className={s.selectedValueCount}>
                    {formValues.cgRoles.length} of {extractCgRoles(roles).length} selected
                  </div>
                </section>
              </Column>
            </Row>
          )}

          <Row>
            <Column>
              <section className={s.headingText}>
                Labels
                <AiOutlineInfoCircle
                  className={s.infoTooltip}
                  data-for="labelsInfoTooltip"
                  data-tip
                />
                <ReactTooltip
                  className={s.tooltip}
                  id="labelsInfoTooltip"
                  type="light"
                  effect="solid"
                  place="right"
                  event="click"
                  border
                  borderColor="#939393"
                  globalEventOff="click"
                  clickable
                  isCapture
                >
                  <span>
                    Tag users with labels that correspond to their daily tasks. Click{' '}
                    <a
                      className={s.helpLink}
                      href="https://zendesk.simplelegal.com/hc/en-us/articles/360006729494-Adding-Managing-Contacts"
                    >
                      here
                    </a>{' '}
                    to learn more.
                  </span>
                </ReactTooltip>
              </section>
              <section className={s.selectRoles}>
                <p className={s.container}>Select all that apply:</p>
                <Select
                  id="testid_contacts_roles_select"
                  className={s.container}
                  name="contact-role-select"
                  placeholder="Select..."
                  noOptionsMessage={() => 'No contact roles found...'}
                  styles={roleDropdownStyle}
                  isClearable={false}
                  value={labels}
                  onChange={selectedLabels => onChangeHandler('labels', selectedLabels as Role[])}
                  options={extractLabels(roles)}
                  isMulti
                  menuPortalTarget={document.body}
                />
                <div className={s.selectedValueCount}>
                  {labels.length} of {extractLabels(roles).length} selected
                </div>
              </section>
            </Column>
          </Row>
          <Row>
            {singularRoleIntersect.map(({ role, contact }) => (
              <SingularRoleWarning
                key={role.id}
                role={role}
                contact={contact}
                hasVendorCollaboration
              />
            ))}
          </Row>
        </>
      )}
    </div>
  )
}

export default AssignRolesForm
