import moment from 'moment'
import DateRangeSelect, { defaultOptions } from './DateRangeSelect'
import BaseFilter from './BaseFilter'
import { dateStr } from 'utils/dateUtils'

const getLabel = dateRange => {
  if (dateRange.min && !dateRange.max) {
    return `After ${dateStr(dateRange.min)}`
  } else if (dateRange.max && !dateRange.min) {
    return `Before ${dateStr(dateRange.max)}`
  } else {
    const defaultOption = defaultOptions.find(
      o =>
        moment(o.value.min).isSame(moment(dateRange.min).startOf('day')) &&
        moment(o.value.max).isSame(moment(dateRange.max).endOf('day'))
    )

    if (defaultOption) {
      return defaultOption.label
    }

    return `${dateStr(dateRange.min)} - ${dateStr(dateRange.max)}`
  }
}

const getInitialRange = selected => {
  if (!selected) return null
  const { value } = selected

  return {
    min: value.min ? moment(value.min).toDate() : '',
    max: value.max ? moment(value.max).toDate() : ''
  }
}

const getInitialValue = initialValue => {
  if (!initialValue) {
    return null
  } else {
    const newInitial = getInitialRange(initialValue)
    return {
      value: newInitial,
      label: getLabel(newInitial)
    }
  }
}

const DateFilter = ({
  onSelect,
  initialValue,
  defaultValue,
  reset,
  title = 'Date',
  hasConfirmation,
  customLabel,
  testid
}) => {
  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      defaultValue={defaultValue}
      initialValue={getInitialValue(initialValue)}
      customLabel={customLabel}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen, selected) => {
        return (
          <DateRangeSelect
            initialDateRange={getInitialRange(selected)}
            onConfirm={dateRange => {
              if (!dateRange || (!dateRange.min && !dateRange.max)) {
                setFilterSelection(null)
              } else {
                setFilterSelection({
                  value: dateRange,
                  label: getLabel(dateRange)
                })
              }
            }}
          />
        )
      }}
    ></BaseFilter>
  )
}

export default DateFilter
