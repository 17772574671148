import { Component, Fragment } from 'react'
import { HorizField, Input } from 'components'
import { CharLimitInput } from 'simple-core-ui'
import ACT from 'vendors/actions'
import { connect } from 'react-redux'

@connect(({ vendors }) => {
  const { createForm } = vendors
  return {
    createForm
  }
})
class CreateVendorForm extends Component {
  updateForm = (key, value) => {
    this.props.dispatch({
      type: ACT.UPDATE_ADD_VENDOR_FORM,
      payload: {
        [key]: value
      }
    })
  }

  render() {
    const {
      vendorName,
      vendorContactEmail,
      vendorContactPhone,
      vendorContactName,
      vendorUrl,
      errors
    } = this.props.createForm
    return (
      <Fragment>
        <p>Adding a vendor will allow you to assign invoices to a vendor</p>
        <HorizField label="Vendor Name">
          <Input
            type="text"
            value={vendorName}
            onChange={value => this.updateForm('vendorName', value)}
            testid="vendor-name"
          />
        </HorizField>
        <HorizField label="Contact Name">
          <Input
            type="text"
            value={vendorContactName}
            onChange={value => this.updateForm('vendorContactName', value)}
            testid="contact-name"
          />
        </HorizField>
        <HorizField label="Contact Email*" hasError={errors?.vendorContactEmail}>
          <Input
            type="text"
            value={vendorContactEmail}
            onChange={value => this.updateForm('vendorContactEmail', value)}
            testid="contact-email"
          />
        </HorizField>
        <HorizField label="Contact Phone">
          <Input
            type="text"
            value={vendorContactPhone}
            onChange={value => this.updateForm('vendorContactPhone', value)}
            testid="contact-phone"
          />
        </HorizField>
        <HorizField label="URL">
          <CharLimitInput
            value={vendorUrl || ''}
            placeholder=""
            onChangeCb={e => this.updateForm('vendorUrl', e.target.value)}
            maxLength={128}
            style={{ borderRadius: 4 }}
          />
        </HorizField>
      </Fragment>
    )
  }
}

export default CreateVendorForm
