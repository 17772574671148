import { useState, useEffect, useMemo } from 'react'
import s from './Permissions.scss'
import cn from 'classnames'
import { Checkbox, useLoading, Button } from 'simple-core-ui'
import { useImmer } from 'use-immer'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { toPermissions, toClientTeams } from './serializers'
import isEqual from 'lodash/isEqual'
import Select from 'react-select'
import PermsLoadingSkeleton from './Skeleton'

const Permissions = ({ scopeId, readOnly }) => {
  const [permissions, setPermissions] = useImmer([])
  const [initialPermissions, setInitialPermissions] = useImmer([])
  const [differentReadArr, setDifferentReadArr] = useState([])
  const [differentEditArr, setDifferentEditArr] = useState([])
  const [defaultPermissions, setDefaultPermissions] = useState([])
  const [clientTeams, setClientTeams] = useState([])
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const { matter_permissions } = await withLoadingLocks(
          makeGetRequest(`/manage/matters/permissions/${scopeId}/`)
        )
        const { settings } = matter_permissions
        setPermissions(() => toPermissions(settings))
        setInitialPermissions(() => toPermissions(settings))
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { matter_permissions } = await makeGetRequest('/manage/matters/default_permissions/')
        const { settings } = matter_permissions
        setDefaultPermissions(() => toPermissions(settings))
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { client_teams } = await makeGetRequest('/manage/matters/client_teams/')
        setClientTeams(toClientTeams(client_teams))
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!permissions.length || !initialPermissions.length) return

    let _differentReadArr = []
    let _differentEditArr = []

    permissions.forEach((item, i) => {
      if (item.read !== initialPermissions[i].read) {
        _differentReadArr.push(item)
      } else {
        _differentReadArr.push(null)
      }
      if (item.edit !== initialPermissions[i].edit) {
        _differentEditArr.push(item)
      } else {
        _differentEditArr.push(null)
      }
    })

    setDifferentReadArr(_differentReadArr)
    setDifferentEditArr(_differentEditArr)
  }, [initialPermissions, permissions])

  const isPristine = useMemo(() => {
    return isEqual(initialPermissions, permissions)
  }, [initialPermissions, permissions])

  const changeRead = (index, value) => {
    setPermissions(draft => {
      if (!value) {
        draft[index].edit = false
      }
      draft[index].read = value
    })
  }

  const changeEdit = (index, value) => {
    setPermissions(draft => {
      if (value) {
        draft[index].read = true
      }
      draft[index].edit = value
    })
  }

  const cancel = () => {
    setPermissions(() => initialPermissions)
  }

  const restore = () => {
    setPermissions(() => defaultPermissions)
  }

  const save = async () => {
    try {
      const formData = new FormData()

      const payload = {
        target_type: 'Matters',
        id: scopeId,
        actions: {
          read: {
            teams: permissions.filter(p => p.read).map(p => p.value),
            reset_to_default: false
          },
          edit: {
            teams: permissions.filter(p => p.edit).map(p => p.value),
            reset_to_default: false
          }
        },
        modified: true,
        customized: true
      }

      formData.append('permissions', JSON.stringify(payload))

      await makePostRequest('/teams/update_matter_perms/', formData)
      setInitialPermissions(() => permissions)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  return (
    <div className={s.wrapper}>
      <header className={s.header}>
        <div className={s.left}>Matter Permissions</div>
        <div className={s.right}>
          <span>Read</span>
          <span>Edit</span>
        </div>
      </header>
      {isLoading ? (
        <PermsLoadingSkeleton />
      ) : (
        permissions.map((p, i) => (
          <div
            aria-label="row"
            key={p.value}
            className={cn(s.row, {
              [s.even]: i % 2 === 0
            })}
          >
            <div className={s.left}>{p.label}</div>
            <div className={s.right}>
              <Checkbox
                hasGreyBorder
                size="md"
                isChecked={p.read}
                triggerCheckbox={value => changeRead(i, value.target.checked)}
                disabled={readOnly}
              />
              {differentReadArr[i] && (
                <span aria-label="asterix" className={cn(s.asterix, s.read)}>
                  *
                </span>
              )}
              <Checkbox
                hasGreyBorder
                size="md"
                isChecked={p.edit}
                triggerCheckbox={value => changeEdit(i, value.target.checked)}
                disabled={readOnly}
              />
              {differentEditArr[i] && (
                <span aria-label="asterix" className={cn(s.asterix, s.edit)}>
                  *
                </span>
              )}
            </div>
          </div>
        ))
      )}
      {!readOnly && (
        <>
          <Select
            className={s.select}
            aria-label="team"
            options={clientTeams.filter(team => {
              return !permissions.map(p => p.value).includes(team.value)
            })}
            value={null}
            onChange={value => {
              setPermissions(() => [
                ...permissions,
                {
                  ...value,
                  read: false,
                  edit: false
                }
              ])
              setInitialPermissions(() => [
                ...initialPermissions,
                {
                  ...value,
                  read: false,
                  edit: false
                }
              ])
            }}
          />
          <div className={s.buttons}>
            <Button isPrimary onClick={() => restore()}>
              Restore to Defaults
            </Button>
            <Button isDisabled={isPristine} isPrimary onClick={() => cancel()}>
              Cancel
            </Button>
            <Button isDisabled={isPristine} onClick={() => save()}>
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Permissions
