import ACT from './actions'
import { SCOPE } from './utils/constants'

export const SCOPED_URL = ({ scope, scopeId, actionType }) =>
  ({
    [ACT.HYDRATE_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/review`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/review`
    },
    [ACT.SEND_BACK_TO_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/retract`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/retract`
    },
    [ACT.FINALIZE_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/finalize`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/finalize`
    },
    [ACT.APPROVAL_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/approve`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/approve`
    },
    [ACT.UNAPPROVAL_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/retract`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/retract`
    },
    [ACT.REJECTION_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/reject`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/reject`
    },
    [ACT.UNREJECTION_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/retract`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/retract`
    },
    [ACT.ADMIN_ACTION_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/do_admin_action`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/do_admin_action`
    },
    [ACT.ADD_REVIEWERS_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/add_reviewers`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/add_reviewers`
    },
    [ACT.REMOVE_REVIEWER_REQUESTED]: {
      [SCOPE.INVOICE]: `/invoices/${scopeId}/remove_reviewer`,
      [SCOPE.MATTER]: `/manage/matters/${scopeId}/remove_reviewer`
    }
  }[actionType][scope])

export const REVIEWERS_SEARCH_URL = ({ scope, scopeId }) =>
  ({
    [SCOPE.INVOICE]: `/invoices/${scopeId}/search_reviewers`,
    [SCOPE.MATTER]: `/manage/matters/${scopeId}/search_reviewers`
  }[scope])
