import { LoadingSkeleton } from 'simple-core-ui'
import s from './DashboardSkeleton.scss'

const DashboardSkeleton = ({ style = {} }) => {
  return (
    <section className={s.dashboard} style={{ ...style }}>
      <div className={s.header}>
        <LoadingSkeleton height={28} width={160} />
        <LoadingSkeleton height={28} width={28} circle />
      </div>
      <div className={s.tabs}>
        <LoadingSkeleton height={20} width={60} />
        <LoadingSkeleton height={20} width={90} />
        <LoadingSkeleton height={20} width={70} />
      </div>
      <LoadingSkeleton line />
      <div className={s.tabContent}>
        <LoadingSkeleton height={22} />
        <LoadingSkeleton height={22} />
        <LoadingSkeleton height={22} />
      </div>
    </section>
  )
}

export default DashboardSkeleton
