import keyMirror from 'keymirror'

export default keyMirror({
  REPORTS_FETCH_REQUESTED: null,
  REPORTS_FETCH_SUCCESS: null,
  ADD_USER_REQUESTED: null,
  ADD_USER_SUCCESS: null,
  USERS_FETCH_REQUESTED: null,
  USERS_FETCH_SUCCESS: null,
  DELETE_USER_REQUESTED: null,
  DELETE_USER_SUCCESS: null,
  EDIT_USER_REQUESTED: null,
  EDIT_USER_SUCCESS: null,
  FILTER_REPORTS_LIST: null,
  PUSH_NOTIFICATION: null
})
