import keyMirror from 'keymirror'

export default keyMirror({
  INIT_VENDOR_INFO: null,
  SUBMIT_BUDGET_REQUEST: null,
  SUBMIT_BUDGET_REQUESTED: null,
  SUBMIT_BUDGET_SUCCESS: null,
  SUBMIT_BUDGET_REQUEST_SUCCESS: null,
  SUBMIT_REFERRAL_REQUESTED: null,
  SUBMIT_REFERRAL_SUCCESS: null,
  UPDATE_PROPOSAL_NOTES: null,
  REJECT_PROPOSAL_REQUESTED: null,
  APPROVE_PROPOSAL_REQUESTED: null,
  MAKE_PROPOSAL_ACTION_REQUESTED: null,
  MAKE_PROPOSAL_ACTION_SUCCESS: null,
  BUDGET_REQUESTS_LIST_FETCH_REQUESTED: null,
  UPDATE_ACTIVE_BUDGETS_LIST: null,
  BUDGET_REQUESTS_LIST_FETCH_SUCCESS: null,
  ACTIVE_BUDGETS_LIST_FETCH_REQUESTED: null,
  ACTIVE_BUDGETS_LIST_FETCH_SUCCESS: null,
  LOCK_STATE_FETCH_REQUESTED: null,
  LOCK_STATE_FETCH_SUCCESS: null,
  UPDATE_BUDGET_REQUESTED: null,
  BULK_UPDATE_BUDGET_REQUESTED: null,
  BULK_UPDATE_BUDGET_SUCCESS: null,
  UPDATE_BUDGET_REQUEST_FORM: null,
  DOWNLOAD_ACTIVE_BUDGETS: null,
  DOWNLOAD_ACTIVE_BUDGETS_SUCCESS: null,
  DELETE_BUDGET_REQUESTED: null,
  DELETE_BUDGET_SUCCESS: null,
  UPDATE_ROW_NOTES: null,
  UPDATE_BUDGET_AMOUNTS_REQUESTED: null,
  UPDATE_BUDGET_AMOUNTS_SUCCESS: null,
  CLEAR_BUDGET_FORM: null,
  SEND_BUDGET_REMINDERS_FINISH: null,
  UPDATE_REFORECAST_ROW_VALUE: null,
  START_REFORECAST: null,
  CANCEL_REFORECAST: null,
  SAVE_REFORECAST_REQUESTED: null,
  SAVE_REFORECAST_SUCCESS: null,
  UPDATE_RF_BUDGET_AMOUNT: null,
  REVERT_RF_BUDGET: null,
  CLEAR_RF_BUDGET: null,
  SUBMIT_COMMENT_REQUESTED: null,
  SUBMIT_COMMENT_SUCCESS: null,
  RECENT_BUDGET_FETCH_REQUESTED: null,
  RECENT_BUDGET_FETCH_SUCCESS: null,
  BUDGET_DETAILS_FETCH_REQUESTED: null,
  BUDGET_DETAILS_FETCH_SUCCESS: null,
  BUDGET_COMPARE_FETCH_REQUESTED: null,
  BUDGET_COMPARE_FETCH_SUCCESS: null
})
