import { useImmer } from 'use-immer'
import { useState } from 'react'
import omit from 'lodash/omit'
import { useDispatch, useSelector } from 'react-redux'
import ACT from 'bulk/actions'
import { fromFragment } from 'bulk/serializer'
import { isPopulatedArray, isEmptyArray, filterOpLabelByMap } from 'bulk/utils'
import clone from 'lodash/clone'

const useFilterState = () => {
  const frag = fromFragment()

  const [filterValues, setFilterValues] = useImmer({})
  const [currentRadioSelection, setCurrentRadioSelection] = useImmer({})
  const [currentInputSelection, setCurrentInputSelection] = useImmer({})

  const [reset, setReset] = useState(0)

  const params = useSelector(state => state.bulk.bulkParams)
  const values = useSelector(state => state.bulk.filterValues)
  const builderParams = useSelector(state => state.bulk.builderParams)

  const { record, attrType, templateType, fields } = frag.fields.length ? frag : builderParams

  const recordType = record?.value
  const caType = attrType?.value
  const templateId = templateType?.value

  const basicFilterOptions = ['is null', 'is not null', 'is n/a', 'is not n/a']
  const basicfilterLabels = ['is blank', 'is not blank', 'is n/a', 'is not n/a']

  const dispatch = useDispatch()

  const getFilterLabel = (filterOpLabel, displayName, value1, value2, value3) => {
    if (filterOpLabel && basicfilterLabels.includes(filterOpLabel)) {
      return `${displayName} ${filterOpLabel}`
    }

    if ((!value1 && !value3) || !filterOpLabel) {
      return null
    }

    const val1 = Array.isArray(value1) ? value1.map(v => v.label) : value1?.label || value1

    if (filterOpLabel === 'between') {
      if (!value2) {
        return null
      }
      const val2 = value2?.label || !isNaN(value2) ? parseInt(value2) : value2
      return `${displayName} is between ${`${value3 || ''} ${
        isNaN(val1) ? val1 : parseInt(val1)
      }`} and ${`${value3 || ''} ${val2}`}`
    }

    return `${displayName} ${
      filterOpLabelByMap(filterOpLabel, value3)
        ? filterOpLabelByMap(filterOpLabel, value3)
        : filterOpLabel
    } ${value3 || ''} ${
      !basicFilterOptions.includes(filterOpLabel)
        ? Array.isArray(val1)
          ? val1.length > 1
            ? `(${val1.length})`
            : val1[0] || ''
          : val1 || ''
        : ''
    }`
  }

  const handleFilterRadioChange = (event, field) => {
    event.persist()
    setCurrentRadioSelection(draft => {
      draft[field] = event.target.value
    })
    setCurrentInputSelection(draft => ({}))
  }

  const getInputValue = (field, operator, valueIndex = 0) => {
    const currentInputInfo = clone(currentInputSelection[field])
    const filterInfo = clone(filterValues[field])

    const value =
      currentInputInfo && currentInputInfo?.op === operator
        ? currentInputInfo[`value${valueIndex + 1}`]
        : filterInfo && filterInfo?.field === operator
        ? filterInfo[`val${valueIndex + 1}`]
        : null

    return value
  }

  const handleFilterInputChange = (
    field,
    optionValue,
    value1 = null,
    value2 = null,
    action = null,
    value3 = null
  ) => {
    const {
      op: currentOp = null,
      value1: curValue1 = null,
      value2: curValue2 = null,
      value3: curValue3 = null
    } = currentInputSelection[field] || {}

    setCurrentInputSelection(draft => {
      draft[field] = {
        op: optionValue
      }

      if (
        action &&
        (action.action === 'remove-value' || action.action === 'clear') &&
        value1 === null
      ) {
        draft[field].value1 = null
      } else if (value1) {
        draft[field].value1 = value1
        if (optionValue === 'between' && currentOp === optionValue) {
          if (curValue2) draft[field].value2 = curValue2
        }
        if (curValue3) draft[field].value3 = curValue3
      }

      if (value2) {
        if (optionValue === 'between' && !value3 && curValue1 && currentOp === optionValue)
          draft[field].value1 = curValue1
        draft[field].value2 = value2
        if (curValue3) draft[field].value3 = curValue3
      }

      if (value3) {
        if (curValue1 && currentOp === optionValue) draft[field].value1 = curValue1
        if (optionValue === 'between' && curValue2 && currentOp === optionValue)
          draft[field].value2 = curValue2
        draft[field].value3 = value3
      }

      preservePristineValues(value1, value2, value3, currentInputSelection[field], draft, field)
    })
  }

  const handleReset = () => {
    setReset(reset => reset + 1)
    setFilterValues(draft => ({}))
    setCurrentRadioSelection(draft => ({}))
    setCurrentInputSelection(draft => ({}))

    dispatch({
      type: ACT.BULK_FETCH_REQUESTED,
      payload: {
        recordType: recordType,
        recordFields: fields.map(field => field.value),
        ...(caType ? { caType } : {}),
        ...(templateId ? { templateId } : {}),
        params: {
          ...params,
          page: 1,
          filters: {}
        }
      },
      loadingLock: 'on'
    })
  }

  const handleClear = field => {
    setCurrentRadioSelection(draft => omit(currentRadioSelection, field))
    setCurrentInputSelection(draft => omit(currentInputSelection, field))
  }

  const handleCancel = field => {
    const fieldInfo = clone(filterValues[field])
    const currentInputInfo = clone(currentInputSelection[field])

    if (!fieldInfo) {
      currentInputInfo && setCurrentInputSelection(draft => omit(currentInputSelection, field))
      currentRadioSelection[field] &&
        setCurrentRadioSelection(draft => omit(currentRadioSelection, field))
    }

    const { field: fieldName, val1, val2, val3 } = fieldInfo || {}
    const { value1, value2, value3 } = currentInputInfo || {}

    if (
      values[field] &&
      isPopulatedArray(val1) &&
      (value1 === null || (isPopulatedArray(value1) && val1.length !== value1.length))
    ) {
      setInputInfo(fieldInfo, field)
    }

    if (fieldInfo && isEmptyArray(value1)) {
      setInputInfo(fieldInfo, field)
    }

    if (fieldName !== currentRadioSelection[field]) {
      setInputInfo(fieldInfo, field)
      setCurrentRadioSelection(draft => {
        draft[field] = fieldName
      })
    }

    if (val1 !== value1 || val2 !== value2 || val3 !== value3) {
      setInputInfo(fieldInfo, field)
    }
  }

  const handleFilter = filterValues => {
    const filterFields = Object.keys(filterValues)
    if (
      filterFields.every(field => filterValues[field].hasOwnProperty('field')) ||
      filterFields.some(field => !filterValues[field])
    ) {
      const updatedRadioSelection = {}
      const updatedInputSelection = {}

      filterFields.forEach(field => {
        const fieldInfo = clone(filterValues[field])
        const { field: fieldName, val1, val2, val3 } = fieldInfo || {}

        updatedRadioSelection[field] = fieldName
        updatedInputSelection[field] = {
          op: fieldName,
          value1: val1,
          ...(val2 ? { value2: val2 } : {}),
          ...(val3 ? { value3: val3 } : {})
        }
      })

      setCurrentRadioSelection(draft => updatedRadioSelection)
      setCurrentInputSelection(draft => updatedInputSelection)

      dispatch({
        type: ACT.BULK_FETCH_REQUESTED,
        payload: {
          recordType: recordType,
          recordFields: fields.map(field => field.value),
          ...(caType ? { caType } : {}),
          ...(templateId ? { templateId } : {}),
          params: {
            ...params,
            page: 1,
            filters: filterValues
          }
        },
        loadingLock: 'on'
      })
    }
  }

  const getUpdatedFilterValues = (field, selectedOpt) => {
    const { filterName, filterType } = field

    const currentInputInfo = clone(currentInputSelection[filterName])
    const { label = null, value = null } = selectedOpt || {}
    const { value1 = null, value2 = null, value3 = null } = currentInputInfo || {}

    return selectedOpt && basicFilterOptions.includes(value)
      ? {
          ...filterValues,
          [filterName]: {
            field: value,
            filterOpLabel: label || filterValues[filterName]?.filterOpLabel
          }
        }
      : !selectedOpt
      ? omit(filterValues, filterName)
      : {
          ...filterValues,
          [filterName]: {
            field: value,
            type: filterType,
            filterOpLabel: value,
            val1: value1,
            ...(value2 ? { val2: value2 } : {}),
            ...(value3 ? { val3: value3 } : {}),
            inputValues: [value1, value2, value3]
          }
        }
  }

  const setInputInfo = (fieldInfo, field) => {
    setCurrentInputSelection(draft => {
      if (!fieldInfo) {
        draft[field] = {}
      } else {
        draft[field] = {
          op: fieldInfo.field,
          value1: fieldInfo.val1,
          ...(fieldInfo.val2 ? { value2: fieldInfo.val2 } : {}),
          ...(fieldInfo.val3 ? { value3: fieldInfo.val3 } : {})
        }
      }
    })
  }

  const preservePristineValues = (val1, val2, val3, current, draft, field) => {
    const { value1, value2, value3 } = current || {}

    if (val1 === null && val2 === null && !val3) {
      draft[field].value1 = value1
      draft[field].value2 = value2
    }

    if (val2 === null && val3 === null && !val1) {
      draft[field].value2 = value2
      draft[field].value3 = value3
    }

    if (val1 === null && val3 === null && !val2) {
      draft[field].value1 = value1
      draft[field].value3 = value3
    }
  }

  return [
    filterValues,
    setFilterValues,
    currentRadioSelection,
    setCurrentRadioSelection,
    currentInputSelection,
    setCurrentInputSelection,
    getFilterLabel,
    handleFilterRadioChange,
    getInputValue,
    handleFilterInputChange,
    reset,
    handleReset,
    handleClear,
    handleCancel,
    handleFilter,
    getUpdatedFilterValues
  ]
}

export default useFilterState
