import { IoIosMore } from 'react-icons/io'
import ReactTooltip from 'react-tooltip'

import { HoverAction } from 'simple-core-ui'
import s from './Actions.scss'

interface Props {
  argName: string
  onDelete(): void
}

export const Actions = ({ argName, onDelete }: Props) => {
  return (
    <>
      <span
        data-for={`item-actions-${argName}`}
        aria-describedby={`item-actions-${argName}`}
        aria-label={`item-${argName}`}
        className={s.actionsContainer}
        data-event="click"
        data-testid={`param-actions`}
        data-tip=""
      >
        <HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />
      </span>
      <ReactTooltip
        id={`item-actions-${argName}`}
        type="light"
        effect="solid"
        place="bottom"
        globalEventOff="click"
        border
        clickable
        delayHide={1}
        isCapture
        className={s.tooltipContainer}
      >
        <ul className={s.actionsList}>
          <li className={s.actionItem} onClick={onDelete}>
            Delete
          </li>
        </ul>
      </ReactTooltip>
    </>
  )
}

export default Actions
