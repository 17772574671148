import * as React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import get from 'lodash/get'

import { If } from 'simple-core-ui'

import ACT from '../actions'

import ClientKeyModalContainer from './ClientKeyPlaceHolderContainer'

const emptyState = {
  key_val: '',
  type: '',
  secret_key: '',
  other_key: '',
  bucket: '',
  bucket_path: '',
  custom_field1_name: '',
  custom_field1: '',
  custom_field2_name: '',
  custom_field2: ''
}

@connect(({ clientKeysApp: { showForUpdate, showForCreate } }) => ({
  key_val: get(showForUpdate, 'key_val', ''),
  type: get(showForUpdate, 'type', ''),
  secret_key: get(showForUpdate, 'secret_key', ''),
  other_key: get(showForUpdate, 'other_key', ''),
  bucket: get(showForUpdate, 'bucket', ''),
  bucket_path: get(showForUpdate, 'bucket_path', ''),
  custom_field1_name: get(showForUpdate, 'custom_field1_name', ''),
  custom_field1: get(showForUpdate, 'custom_field1', ''),
  custom_field2_name: get(showForUpdate, 'custom_field2_name', ''),
  custom_field2: get(showForUpdate, 'custom_field2', ''),
  showForUpdate,
  showForCreate
}))
class ConnectedClientKeyModal extends React.Component {
  componentDidMount() {
    const clientKeyId = queryString.parse(window.location.search).clientKey

    if (clientKeyId !== undefined) {
      const { dispatch } = this.props
      dispatch({ type: ACT.CLIENT_KEY_SHOW_FOR_UPDATE_REQUESTED, payload: clientKeyId })
    }
  }

  render() {
    const { showForUpdate, showForCreate } = this.props

    return (
      <If condition={showForUpdate || showForCreate}>
        <ClientKeyModalContainer {...this.props} />
      </If>
    )
  }
}

export default ConnectedClientKeyModal
