import { Fragment } from 'react'
import { SidebarContainer } from './SidebarContainer'
import { ContentContainer } from './ContentContainer'

const RequestsList = ({ opened, toggleDrawer, getForm }) => (
  <Fragment>
    <SidebarContainer opened={opened} toggleDrawer={toggleDrawer} getForm={getForm} />
    <ContentContainer opened={opened} />
  </Fragment>
)

export default RequestsList
