import s from './UploadProgress.scss'
import { ChartInfo } from './ChartInfo'

const UploadProgress = ({ percent, total, action }) => {
  return (
    <div className={s.chart}>
      <div className={s.segment} style={{ width: `${(100 * percent) / total}%` }} />
      <ChartInfo action={action} percent={percent} total={total} />
    </div>
  )
}

export default UploadProgress
