import { EditableContentContainer, EditableTextareaContainer } from 'containers'

const EditableSection = ({
  content,
  processedContent,
  saveChanges,
  discardChanges,
  editContent,
  isEditing,
  pageDescription
}) => (
  <section>
    {isEditing ? (
      <EditableTextareaContainer
        content={content}
        saveChanges={saveChanges}
        discardChanges={discardChanges}
      />
    ) : (
      <EditableContentContainer
        pageDescription={pageDescription}
        content={processedContent}
        editContent={editContent}
      />
    )}
  </section>
)

export default EditableSection
