import flattenDeep from 'lodash/flattenDeep'

import { REVIEW_STATUS } from './constants'
import { areAndEntries } from './identifyEntry'

export const partiallyApproved = ({ entries, status }) => {
  if (!areAndEntries(entries)) {
    return false
  }

  const flattenedEntries = flattenDeep(entries)
  const allResponses = flattenedEntries.reduce((acc, { responses }) => [...acc, ...responses], [])

  const approvals = allResponses.filter(
    ({ reviewStatus }) => reviewStatus === REVIEW_STATUS.APPROVED
  )

  const containsApproval = approvals.length > 0

  return status === REVIEW_STATUS.PENDING && containsApproval
}
