import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { VENDOR_COMPARISON } from '../constants'
import HorizontalChart from './HorizontalChart'
import s from '../Diversity.scss'
import useResponsiveChart from './useResponsiveChart'
import useWindowSize from 'hooks/useWindowSize'

const [GENDER, RACE, STATUS] = VENDOR_COMPARISON

const createScrollStopListener = ({
  element,
  callback,
  timeout,
  setHorizontal,
  setShowRightGradient,
  setShowLeftGradient
}) => {
  let removed = false
  let handle = null

  const onScroll = e => {
    const scrollLeft = e.currentTarget.scrollLeft
    const isFullyScrolled =
      e.currentTarget.clientWidth + e.currentTarget.scrollLeft >= e.currentTarget.scrollWidth
    setHorizontal(scrollLeft)
    setShowRightGradient(false)
    setShowLeftGradient(false)

    if (handle) {
      clearTimeout(handle)
    }
    handle = setTimeout(() => callback(scrollLeft, isFullyScrolled), timeout || 200) // default 200 ms
  }

  element.addEventListener('scroll', onScroll)
  return () => {
    if (removed) {
      return
    }
    removed = true
    if (handle) {
      clearTimeout(handle)
    }
    element.removeEventListener('scroll', onScroll)
  }
}

function ScrollGradient({ containerRef, setHorizontal, horizontal }) {
  const windowSize = useWindowSize()
  const [showLeftGradient, setShowLeftGradient] = useState(false)
  const [showRightGradient, setShowRightGradient] = useState(false)

  useEffect(() => {
    const destroyListener = createScrollStopListener({
      element: containerRef.current,
      callback: (horizontal, isFullyScrolled) => {
        if (!isFullyScrolled) {
          setShowRightGradient(true)
        }
        if (horizontal > 10) {
          setShowLeftGradient(true)
        }
      },
      timeout: 40,
      setShowRightGradient,
      setShowLeftGradient,
      setHorizontal
    })

    if (windowSize.width <= 1430) {
      setShowRightGradient(true)
    }

    return () => destroyListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (windowSize.width <= 1430) {
    return (
      <>
        {showRightGradient && (
          <div className={s.gradientRight} style={{ right: -1 * horizontal }}></div>
        )}
        {showLeftGradient && (
          <div className={s.gradientLeft} style={{ left: horizontal + 116 }}></div>
        )}
      </>
    )
  } else {
    return null
  }
}

const HorizontalRow = ({ data, GENDER_CHART_WIDTH, RACE_CHART_WIDTH, STATUS_CHART_WIDTH }) => {
  return (
    <tr style={{ paddingLeft: 12 }}>
      <th className={s.compRowVendorName}>{data.vendorName}</th>
      <td>
        <HorizontalChart
          percentage={data.gender.Female}
          color={GENDER.columns[0].color}
          width={GENDER_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.gender.Male}
          color={GENDER.columns[1].color}
          width={GENDER_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.gender['Non-Binary']}
          color={GENDER.columns[2].color}
          width={GENDER_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.race['American Indian/Alaskan Native']}
          color={RACE.columns[0].color}
          width={RACE_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.race.Asian}
          color={RACE.columns[1].color}
          width={RACE_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.race['African American/Black']}
          color={RACE.columns[2].color}
          width={RACE_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.race['Hispanic/Latinx']}
          color={RACE.columns[3].color}
          width={RACE_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.race.Multiracial}
          color={RACE.columns[4].color}
          width={RACE_CHART_WIDTH}
        />
      </td>

      <td>
        <HorizontalChart
          percentage={data.race['Pacific Islander']}
          color={RACE.columns[5].color}
          width={RACE_CHART_WIDTH}
        />
      </td>

      <td>
        <HorizontalChart
          percentage={data.race.White}
          color={RACE.columns[6].color}
          width={RACE_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.disability.Yes}
          color={STATUS.columns[0].color}
          width={STATUS_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.veteran_status.Yes}
          color={STATUS.columns[1].color}
          width={STATUS_CHART_WIDTH}
        />
      </td>
      <td>
        <HorizontalChart
          percentage={data.lgbtq.Yes}
          color={STATUS.columns[2].color}
          width={STATUS_CHART_WIDTH}
        />
      </td>
    </tr>
  )
}

// add gradient to the left and to the right

const VendorComparisonChart = ({ containerRef }) => {
  const vendors = useSelector(({ diversityReport }) => diversityReport.vendorComparison)

  const [horizontal, setHorizontal] = useState(null)

  const scrollContainerRef = useRef()

  const [
    VENDOR_COMPARISON,
    MINWIDTH_FIRST_COL,
    GENDER_CHART_WIDTH,
    RACE_CHART_WIDTH,
    STATUS_CHART_WIDTH
  ] = useResponsiveChart()

  return (
    <div className={s.compChartContainer} ref={scrollContainerRef}>
      <div className={s.compChartInner}>
        <table className={s.vendorCompTable}>
          <thead>
            <tr className={s.categorySection}>
              <th className={s.compRowVendorName} />
              {VENDOR_COMPARISON.map((category, i) => {
                const colSpan = category.title === 'STATUS' || category.title === 'GENDER' ? 3 : 7
                return (
                  <th
                    key={i}
                    className={s.categoryHeader}
                    colSpan={colSpan}
                    style={{
                      minWidth: category.minWidth
                    }}
                  >
                    {category.title}
                  </th>
                )
              })}
            </tr>
            <tr>
              <th className={s.compRowVendorName} />
              {VENDOR_COMPARISON.map(category => {
                return category.columns.map(col => {
                  return (
                    <th
                      key={col.title}
                      className={`${s.categoryColumn} ${s[category.key]}`}
                      style={{
                        borderBottom: `solid 2px ${col.color}`
                      }}
                    >
                      {col.title}
                    </th>
                  )
                })
              })}
            </tr>
          </thead>
          <tbody>
            {vendors.map(
              (vendor, i) =>
                vendor?.gender && (
                  <HorizontalRow
                    key={i}
                    data={vendor}
                    GENDER_CHART_WIDTH={GENDER_CHART_WIDTH}
                    RACE_CHART_WIDTH={RACE_CHART_WIDTH}
                    STATUS_CHART_WIDTH={STATUS_CHART_WIDTH}
                  />
                )
            )}
          </tbody>
        </table>
      </div>

      <ScrollGradient
        horizontal={horizontal}
        setHorizontal={setHorizontal}
        containerRef={scrollContainerRef}
      />
    </div>
  )
}

export default VendorComparisonChart
