import { put, takeLatest } from 'redux-saga/effects'
import { makeGetRequest } from 'utils/api'
import ACT from './actions'
import { ALL_SUPERSET_DASHBOARDS_PAGE_SIZE } from '../constants'

function* fetchSupersetDashboards(action) {
  const { params, selectedTab = 'All' } = action.payload
  const page = params?.page || 0
  const pageSize = params?.pageSize || ALL_SUPERSET_DASHBOARDS_PAGE_SIZE
  const url = `/superset/dashboards?category=${selectedTab}&page=${page}&page_size=${pageSize}`
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.SUPERSET_DASHBOARDS_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: response
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      loadingLock: 'off',
      loadingType: ACT.SUPERSET_DASHBOARDS_FETCH_REQUESTED,
      error
    })
  }
}

const supersetReportsSagas = [
  takeLatest(ACT.SUPERSET_DASHBOARDS_FETCH_REQUESTED, fetchSupersetDashboards)
]

export default supersetReportsSagas
