import { useState } from 'react'
import { Button, withConfirmation } from 'simple-core-ui'
import { makePostRequest } from 'utils/api'
import { IoIosLock } from 'react-icons/io'
import swal from 'sweetalert'
import { useSelector, useDispatch } from 'react-redux'
import ACT from 'budgets/actions'

const lockOpts = {
  text:
    'All draft budgets with a start date of the current year will become locked and approved. \n \n Do you wish to continue?',
  title: 'Lock Budgets'
}

const LockBudgets = () => {
  const [isDisabled, setIsDisabled] = useState(false)
  const dispatch = useDispatch()
  const isDraftTab = useSelector(state => state.budgets.filters.category === 'draft')
  const filters = useSelector(state => state.budgets.filters)
  const lockState = useSelector(state => state.budgets.lockState)

  const handleLockBudgets = withConfirmation(async () => {
    try {
      const response = await makePostRequest('/budgets/lock/')

      swal({
        title: `You locked ${response.num_locked_budgets} budgets successfully!`,
        button: 'Ok',
        icon: 'success'
      })

      dispatch({ type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED, payload: { params: filters } })
      dispatch({ type: ACT.LOCK_STATE_FETCH_REQUESTED })

      setIsDisabled(true)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }, lockOpts)

  return (
    (lockState.budgetLockingFeatureEnabled || lockState.budgetsAreLockable) &&
    isDraftTab && (
      <Button
        isDisabled={isDisabled || !lockState.budgetsAreLockable}
        isSecondary
        onClick={handleLockBudgets}
      >
        {isDisabled || !lockState.budgetsAreLockable ? (
          <>
            <IoIosLock />
            Budgets locked
          </>
        ) : (
          <>
            <IoIosLock />
            Lock Budgets
          </>
        )}
      </Button>
    )
  )
}

export default LockBudgets
