import s from './Card.scss'
import { Card as CardType } from '../types'
import {
  MdDragIndicator,
  MdOutlineAttachFile,
  MdOutlineComment,
  MdVisibility,
  MdVisibilityOff
} from 'react-icons/md'
import { BiSubdirectoryRight } from 'react-icons/bi'
import cn from 'classnames'
import { AvatarList, Ellipsis, Tooltip } from 'simple-core-ui'
import { Task } from 'common/Tasks/types'
import { hex2rgba, sortAlphabeticallyByProperty } from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { format, getYear, isBefore, parse } from 'date-fns'

const Card = (props: CardType): JSX.Element => {
  return (
    <div
      style={{ borderTop: `4px solid ${props.color}` }}
      className={cn(s.container, {
        [s.draggable]: props.draggable
      })}
    >
      <div className={s.header}>
        <Ellipsis className={s.relatedTo} lines={1}>
          {props.relatedMatter.label || '--'}
        </Ellipsis>
        {props.draggable ? (
          <MdDragIndicator className={cn(s.dragIcon, 'dragIcon')} />
        ) : (
          <Tooltip
            triggerClassName={s.icon}
            trigger={<MdVisibility className={s.icon} />}
            content="View Only"
          />
        )}
      </div>
      <div className={s.content}>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          {props.parent && <BiSubdirectoryRight className={s.subTaskBigIcon} />}
          <div className={s.nameWrapper}>
            {props.parent && (
              <div className={s.parentName}>
                {props.parent.isPrivate &&
                !props.tasks.find((t: Task) => t.id === props.parent?.id) ? (
                  <i>
                    <MdVisibilityOff className={s.hiddenIcon} />
                    Private: Parent task hidden
                  </i>
                ) : (
                  <Ellipsis className={s.nameCell} lines={1}>
                    {props.parent.name}
                  </Ellipsis>
                )}
              </div>
            )}
            <a
              className={s.name}
              onClick={() => props.editTask(({ id: props.id } as unknown) as Task)}
            >
              <Ellipsis className={s.nameCell} lines={props.parent ? 1 : 2}>
                {props.title}
              </Ellipsis>
            </a>
          </div>
        </div>
        {props.priority && (
          <div
            className={s.level}
            style={{
              backgroundColor: props.priority.color && hex2rgba(props.priority.color, 0.15),
              border: `1px solid ${props.priority.color}`
            }}
          >
            <Ellipsis width={100} lines={1}>
              {props.priority.name}
            </Ellipsis>
          </div>
        )}
      </div>
      <div className={s.footer}>
        {!props.assignees?.length ? (
          <span className={s.noAssignee}>
            <BsPersonPlus />
          </span>
        ) : props.assignees?.length === 1 ? (
          <AvatarList
            size={'md'}
            limit={1}
            avatarStyles={{ marginLeft: 0 }}
            entries={[{ id: props.assignees[0].value, label: props.assignees[0].label }]}
            className={s.avatarList}
          />
        ) : (
          <AvatarList
            limit={3}
            size="xs"
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(props.assignees, 'label')}
            className={s.avatar}
          />
        )}
        {props.dueDate ? (
          <span
            style={
              isBefore(parse(props.dueDate, 'MM/dd/yyyy', new Date()), new Date())
                ? { color: '#bb342f' }
                : {}
            }
          >
            {format(
              parse(props.dueDate, 'MM/dd/yyyy', new Date()),
              getYear(parse(props.dueDate, 'MM/dd/yyyy', new Date())) === getYear(new Date())
                ? 'MMM dd'
                : 'MMM dd yyyy'
            )}
          </span>
        ) : (
          <span>No Due Date</span>
        )}
        <div>
          <MdOutlineComment
            style={{ fontSize: 20, position: 'relative', top: 5, marginRight: 5 }}
          />
          {props.commentsCount}
        </div>
        <div>
          <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />
          {props.fileAttachmentsCount}
        </div>
        {!!props.subtasksCount && (
          <div>
            <BiSubdirectoryRight style={{ fontSize: 30, position: 'relative', top: 7, left: -3 }} />
            <span style={{ position: 'relative', top: -5, left: -5 }}>{props.subtasksCount}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card
