import ReactTooltip from 'react-tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import s from './AssignedContactsTableContainer.scss'

const isMatterUpdateSharingEnabled = window.serverContext.get(
  'allow_vendor_collaboration_matter_updates'
)

export const columns = [
  {
    columnKey: 'firstName',
    content: 'First Name',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastName',
    content: 'Last Name',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'email',
    content: 'Email',
    isSortable: false,
    isFilterable: true
  },
  {
    columnKey: 'phone',
    content: 'Phone',
    isSortable: false,
    isFilterable: true
  },
  {
    columnKey: 'company',
    content: 'Company',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'isCounselGoUser',
    isSortable: true,
    isFilterable: true,
    content: (
      <>
        CounselGO
        <br />
        Access
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="cgAccessInfoTooltip" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="cgAccessInfoTooltip"
          type="light"
          effect="solid"
          place="bottom"
          border
        >
          <span>
            CounselGO access
            <br /> allows a user to view
            <br /> and submit invoices
            <br /> and view referred
            <br /> matters
          </span>
        </ReactTooltip>
      </>
    )
  },
  {
    columnKey: 'isCounselGoAdmin',
    isSortable: true,
    isFilterable: true,
    content: (
      <>
        CounselGO
        <br />
        Admin
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="cgAdminInfoTooltip" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="cgAdminInfoTooltip"
          type="light"
          effect="solid"
          place="bottom"
          border
        >
          <span>
            CounselGO admins
            <br /> have the ability to
            <br /> add new CounselGO
            <br /> users and admins
          </span>
        </ReactTooltip>
      </>
    )
  },
  {
    columnKey: 'rolesString',
    isSortable: false,
    isFilterable: true,
    content: (
      <>
        Additional
        <br />
        CounselGO Roles
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="cgRoleInfoTooltip" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="cgRoleInfoTooltip"
          type="light"
          effect="solid"
          place="bottom"
          border
        >
          <span>
            CounselGO users with advanced
            <br /> functionality (
            {isMatterUpdateSharingEnabled ? (
              <>
                General File Sharing,
                <br />
                Matter File Sharing and Matter Updates
              </>
            ) : (
              <>
                General File Sharing
                <br />
                and Matter File Sharing
              </>
            )}
            )
          </span>
        </ReactTooltip>
      </>
    )
  },
  {
    columnKey: 'labelsString',
    isSortable: false,
    isFilterable: true,
    content: (
      <>
        Labels
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="labelsInfoTooltip" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="labelsInfoTooltip"
          type="light"
          effect="solid"
          place="bottom"
          border
        >
          <span>
            Tags that
            <br /> correspond to a
            <br /> {"user's daily tasks"}
          </span>
        </ReactTooltip>
      </>
    )
  }
]
