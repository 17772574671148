import { Fragment } from 'react'
import s from './Question.scss'
import { Chart } from '../common/Chart'
import { AnswersTable } from '../common/AnswersTable'
import { PermissionsNotification } from '../common/PermissionsNotification'
import { answersTableSerializer } from 'vendors/scorecard/serializers'
import { Button, coreThemes } from 'simple-core-ui'
import { SURVEY_QUESTIONS } from 'vendors/constants'

const Question = ({ question, index, showTable, toggleDetails }) => (
  <div className={s.container}>
    <span className={s.questionText}>
      {index + 1}. {SURVEY_QUESTIONS[question.id]}
    </span>
    {Number(question.avg_score) > 0 && <b>{Number(question.avg_score).toFixed(1)}</b>}
    <i>({question.responses_number} responses)</i>

    {question.responses_number > 0 && (
      <Fragment>
        <Button
          onClick={toggleDetails}
          isSecondary
          theme={coreThemes.EB}
          style={{ float: 'right', fontFamily: 'Lato', margin: 0 }}
        >
          {showTable ? 'Hide Details' : 'See Details'}
        </Button>
        <div className={s.clearFix} />
      </Fragment>
    )}
    <Chart question={question} />

    {showTable && (
      <Fragment>
        {question.permissions_warning && <PermissionsNotification />}
        <AnswersTable rowsSerializer={answersTableSerializer} answers={question.answers} />
      </Fragment>
    )}
  </div>
)

export default Question
