export const ASSIGNED_CONTACTS_FETCH_SUCCESS = 'ASSIGNED_CONTACTS_FETCH_SUCCESS'
export const SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE =
  'SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE'
export const CONTACT_ADDRESS_BOOK_FETCH_SUCCESS = 'CONTACT_ADDRESS_BOOK_FETCH_SUCCESS'
export const CONTACT_ADDRESS_BOOK_FETCH_REQUESTED = 'CONTACT_ADDRESS_BOOK_FETCH_REQUESTED'
export const SURVEY_CONFIG_USERS_FETCH_REQUESTED = 'SURVEY_CONFIG_USERS_FETCH_REQUESTED'
export const SURVEY_CONFIG_ROLES_FETCH_REQUESTED = 'SURVEY_CONFIG_ROLES_FETCH_REQUESTED'
export const SURVEY_CONFIG_ROLES_FETCH_SUCCESS = 'SURVEY_CONFIG_ROLES_FETCH_SUCCESS'
export const SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_REQUESTED =
  'SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_REQUESTED'
export const SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_SUCCESS =
  'SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_SUCCESS'
export const SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE =
  'SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE'
export const SURVEY_CONFIG_TOGGLE_SURVEY_ON = 'SURVEY_CONFIG_TOGGLE_SURVEY_ON'
export const SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_REQUESTED =
  'SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_REQUESTED'
export const SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS =
  'SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS'
export const SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED =
  'SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED'
export const SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_SUCCESS =
  'SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_SUCCESS'
export const SURVEY_CONFIG_ADDITIONAL_EVALUATORS_UPDATE_REQUESTED =
  'SURVEY_CONFIG_ADDITIONAL_EVALUATORS_UPDATE_REQUESTED'
export const SURVEY_CONFIG_ADDITIONAL_EVALUATORS_MAKE_PRISTINE =
  'SURVEY_CONFIG_ADDITIONAL_EVALUATORS_MAKE_PRISTINE'
export const SYSTEM_ROLES_FETCH_SUCCESS = 'SYSTEM_ROLES_FETCH_SUCCESS'
export const SYSTEM_ROLES_FETCH_REQUESTED = 'SYSTEM_ROLES_FETCH_REQUESTED'
export const SURVEY_CONFIG_SHARED_CHANGE_STATUS = 'SURVEY_CONFIG_SHARED_CHANGE_STATUS'
export const SURVEY_CONFIG_SHARED_DEFAULT_ROLES = 'SURVEY_CONFIG_SHARED_DEFAULT_ROLES'
export const SURVEY_CONFIG_SHARED_REMOVE_EVALUATOR = 'SURVEY_CONFIG_SHARED_REMOVE_EVALUATOR'
export const SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_REQUESTED =
  'SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_REQUESTED'
export const SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_SUCCESS =
  'SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_SUCCESS'
export const SURVEY_CONFIG_HAS_SURVEYS_ENABLED_REQUESTED =
  'SURVEY_CONFIG_HAS_SURVEYS_ENABLED_REQUESTED'
export const SURVEY_CONFIG_HAS_SURVEYS_ENABLED_SUCCESS = 'SURVEY_CONFIG_HAS_SURVEYS_ENABLED_SUCCESS'
export const SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_REQUESTED =
  'SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_REQUESTED'
export const SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_SUCCESS =
  'SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_SUCCESS'
