import { DateType } from './types'

export const ASSIGNMENT_DATE: DateType = {
  key: 'ASSIGNMENT',
  title: 'Assignment Date',
  description: 'Set when template task list is assigned'
}

export const RELATIVE_DATE: DateType = {
  key: 'RELATIVE',
  title: 'Relative Due Date',
  description: 'Based on an existing task in the template task list'
}

export const DUE_DATE_RULE_TYPES: DateType[] = [ASSIGNMENT_DATE, RELATIVE_DATE]

export const TIME_UNIT_OPTIONS = [
  {
    label: 'Business days',
    value: 'BUSINESS_DAY'
  },
  {
    label: 'Days',
    value: 'DAY'
  },
  {
    label: 'Weeks',
    value: 'WEEK'
  },
  {
    label: 'Months',
    value: 'MONTH'
  },
  {
    label: 'Years',
    value: 'YEAR'
  }
]

export const BEFORE_OPTION = { label: 'Before', value: 'BEFORE' }
export const AFTER_OPTION = { label: 'After', value: 'AFTER' }
export const TIME_CONDITION_OPTIONS = [AFTER_OPTION, BEFORE_OPTION]

export const ASSIGNMENT_DATE_OPTION = { value: 0, label: 'Assignment Date' }

export const TIME_LIMITS: { [key: string]: number } = {
  BUSINESS_DAY: 25740,
  DAY: 36160,
  WEEK: 5148,
  MONTH: 1188,
  YEAR: 99
}
