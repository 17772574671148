import s from './WarningLine.scss'
import { FaExclamationTriangle } from 'react-icons/fa'
import classNames, { Argument } from 'classnames'
import { ReactNode } from 'react'

interface Props {
  message: string | ReactNode
  className?: Argument
}

const WarningLine = ({ message, className }: Props) => {
  return (
    <div className={classNames(s.warningLine, className)}>
      <FaExclamationTriangle />
      <div>{message}</div>
    </div>
  )
}

export default WarningLine
