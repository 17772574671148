import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requests/create/actions'
import { Request } from './Request'

@connect(({ requestFormsList }) => {
  return {
    redirect: requestFormsList.redirect
  }
})
class RequestContainer extends Component {
  state = {
    opened: true
  }
  toggleDrawer = () => {
    this.setState({
      opened: !this.state.opened
    })
  }
  getForm = form => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_FETCH_REQUESTED,
      payload: {
        id: form.id
      }
    })
  }
  render() {
    const { redirect } = this.props

    if (redirect) {
      return (
        <div style={{ margin: '0 auto', marginTop: 24, fontSize: 14 }}>
          Looks like you don’t have any published legal request forms.{' '}
          <a href="/v2/matters/templates/form/select/">Click here</a> to create one.
        </div>
      )
    }

    return (
      <Request opened={this.state.opened} toggleDrawer={this.toggleDrawer} getForm={this.getForm} />
    )
  }
}

export default RequestContainer
