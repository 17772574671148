import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { TaskType } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  type: TaskType
  editType: (row: TaskType) => void
  deleteType: (row: TaskType) => void
  canDelete: boolean
}

const ActionsPopover = ({ type, deleteType, editType, canDelete }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editType(type)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      visible: canDelete,
      onClick() {
        deleteType(type)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${type.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
