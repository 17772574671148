import FieldsSelector from './FieldsSelector'
import s from 'bulk/builder/stepper/BulkBuilder/BulkBuilderContainer.scss'

const TableFieldsSelection = ({
  id,
  record,
  tableFields,
  setTableFields,
  addButtonText,
  attrType,
  setStep,
  setType,
  buttonRef,
  isLoading
}) => {
  return (
    <div className={s.fieldsContainer}>
      <p className={s.tableFieldsTitle}>
        Record<span className={s.requiredField}>*</span>
      </p>
      <input
        value={`${record?.label} ${attrType ? `[${attrType.label}]` : ''}`}
        className={s.recordField}
        disabled
      />
      <div className={s.flexComponent}>
        <FieldsSelector
          id={id}
          isLoading={isLoading}
          setFields={setTableFields}
          fields={tableFields}
          addButtonText={addButtonText}
          setStep={setStep}
          setType={setType}
          recType={record.value}
          buttonRef={buttonRef}
        />
      </div>
    </div>
  )
}

export default TableFieldsSelection
