import { StylesConfig } from 'react-select'
import { CSSProperties } from 'react'

const OUTLINE_GREY = '#9a9a9a'

export const button: CSSProperties = { borderRadius: '5px', padding: '8px 20px' }

export const textInput: CSSProperties = {
  borderRadius: '5px',
  padding: '5px',
  borderColor: OUTLINE_GREY
}

export const textArea: CSSProperties = {
  borderRadius: '5px',
  padding: '10px',
  borderColor: OUTLINE_GREY
}

export const getSearchableSelectStyles = (noBorder = false): StylesConfig => ({
  control: (base, { selectProps }) => {
    const styles = {
      ...base,
      borderRadius: '5px',
      minHeight: '32px',
      borderColor: OUTLINE_GREY
    }
    if (selectProps.isDisabled) {
      if (noBorder) {
        styles.border = 'none'
        styles.background = 'transparent'
      } else {
        styles.background = '#fff'
      }
    }
    return styles
  },
  indicatorSeparator: base => ({ ...base, display: 'none' }),
  indicatorsContainer: (base, { selectProps }) => {
    const styles = { ...base, height: '30px' }
    if (selectProps.isDisabled) {
      styles.display = 'none'
    }
    return styles
  },
  menu: base => ({ ...base, borderRadius: '5px' }),
  menuList: base => ({ ...base, maxHeight: '168px' }),
  option: base => ({ ...base, height: '32px' }),
  singleValue: base => ({ ...base, color: '#000' }),
  valueContainer: base => ({ ...base, padding: '0 8px' })
})

export const hoverActionIconStyle = { height: 28, width: 28 }
