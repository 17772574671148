import { CustomizedOption, Task } from '../types'
import { BoardData, Card } from './types'

export const toCard = (task: Task, statusColor?: string): Card => {
  return {
    id: String(task.id),
    title: task.name,
    priority: task.priority,
    description: task.description,
    color: statusColor,
    assignees: task.assignees,
    dueDate: task.dueDate,
    parent: task.parent,
    isPrivate: task.isPrivate,
    fileAttachmentsCount: task.fileAttachmentsCount,
    commentsCount: task.commentsCount,
    subtasksCount: task.subtasks.length,
    relatedMatter: task.relatedMatter,
    draggable: task?.relatedMatter?.status !== 'closed' && (task?.relatedMatter?.canEdit ?? false)
  }
}

export const toBoardData = (
  tasks: Task[],
  statuses: (CustomizedOption & { phase?: string })[],
  boardColumnTotalEntries?: Record<string, number>
): BoardData => {
  const lanes = statuses
    .filter(s => s.isActive)
    .map(status => ({
      id: String(status.value),
      title: status.label,
      color: status.color,
      totalEntries: boardColumnTotalEntries?.[status.value] ?? 0,
      cards: tasks
        .filter(task => task.status?.id === status.value)
        .map(task => toCard(task, status.color))
    }))

  return { lanes }
}
