import { useDrag, useDrop } from 'react-dnd'
import isEmpty from 'lodash/isEmpty'
import s from './RowDragDrop.scss'

const RowDragDrop = ({ children }) => {
  const [, drag] = useDrag(() => ({
    type: 'RequestFormRow',
    item: children.props,
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      }
    }
  }))
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['RequestFormRow'],
    drop: (item, monitor) => {
      const { attrIndex, reorderItems } = item
      const formItem = item.item

      // from => item
      // to => props
      if (isEmpty(formItem)) {
        return {}
      }

      reorderItems({
        formItem,
        fromIndex: attrIndex,
        toIndex: children.props.attrIndex
      })

      return {}
    },
    collect: monitor => {
      return {
        isOver: monitor.isOver()
      }
    }
  }))

  return (
    <div ref={node => drag(drop(node))} className={isOver ? s.overDropzone : s.dropzone}>
      {children}
    </div>
  )
}

export default RowDragDrop
