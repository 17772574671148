export const columns = [{ columnKey: 'AccrualsVsActualsCard', content: '', isSortable: true }]

export const tableLabels = ['Actual period', 'Accrual estimate', 'Actual after period']

export const tooltipText = [
  'Submitted and Worked in period',
  'Estimate provided by firm',
  'Work in period submitted after Period End'
]

export const NO_CATEGORIES = []

export const modalTableLabels = [...tableLabels, 'Delta']

export const innerMatterTableColumns = [
  { columnKey: 'entityName', content: 'Entity', isSortable: false },
  { columnKey: 'matterName', content: 'Matter', isSortable: true },
  { columnKey: 'acrrualsTable', content: '', isSortable: false }
]

export const PAGE_SIZE = 50
