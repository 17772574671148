import { Component } from 'react'
import { Textarea } from 'components'
import debounce from 'lodash/debounce'

class TextareaContainer extends Component {
  state = { text: this.props.value || '' }

  componentDidMount() {
    this.onChange = debounce(this.onChange, 200)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.text) {
      this.setState({ text: nextProps.value || '' })
    }
  }

  handleChange = value => {
    this.setState({ text: value }, () => {
      if (value.trim() || value === '') {
        this.onChange(this.state.text)
      }
    })
  }

  onChange = value => {
    this.props.onChange(value)
  }

  render() {
    return (
      <Textarea
        rows="4"
        value={this.state.text}
        onChange={this.handleChange}
        isDisabled={this.props.disabled}
        id={this.props.id}
        data-testid={this.props.testid}
        styles={this.props.styles}
      />
    )
  }
}

export default TextareaContainer
