import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import update from 'immutability-helper'
import findIndex from 'lodash/findIndex'
import moment from 'moment'

export const initialState = {
  list: [],
  scopeId: window.serverContext.get('scope_id')
}

const todosReducer = createReducer(initialState, {
  [ACT.TODO_FETCH_SUCCESS](state, action) {
    const { todos, scope, scopeId } = action.payload

    return {
      ...state,
      list: todos,
      scope,
      scopeId
    }
  },

  [ACT.TODO_DELETE_SUCCESS](state, action) {
    const { id } = action.payload

    const index = findIndex(state.list, todo => todo.id === id)

    return update(state, {
      list: {
        $splice: [[index, 1]]
      }
    })
  },
  [ACT.TODO_COMPLETE_SUCCESS](state, action) {
    const { id } = action.payload
    const index = findIndex(state.list, todo => todo.id === id)

    //return if cant find todo
    if (index === -1) {
      return state
      //uncomplete
    } else if (state.list[index].completedDate) {
      return update(state, {
        list: {
          [index]: {
            completedDate: {
              $set: null
            }
          }
        }
      })
    }

    return update(state, {
      list: {
        [index]: {
          completedDate: {
            $set: moment().format('MM/DD/YYYY')
          }
        }
      }
    })
  }
})

export default todosReducer
