import * as ACT from './actions'
import * as CONST from './constants'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { getAttributesFromTemplate } from './utils'

const initialState = {
  attributes: [],
  files: [],
  matterAttributes: []
}

const changeMatterTemplateDrawerInformation = (matterAttributes, templateAttributes) => {
  // when a user tries to change the matter template, on the select template page,
  // inside the right drawer all the template attributes will be displayed. The attributes
  // present both on the matter and template will have the default value the one from the matter
  // all the other will be none.
  let newState = {
    attributes: [],
    matterAttributes
  }
  let templateAttributesClone = cloneDeep(templateAttributes)
  templateAttributes.forEach((attribute, index) => {
    matterAttributes.forEach(attr => {
      if (attr.type !== 'relationship' && attribute.type !== 'relationship') {
        // we want to allow coercion because some ids are strings like 'matter_short_name' and some are numnbers
        // eslint-disable-next-line
        if (attr.id == attribute.id) {
          attr.required = attribute.required
          attr.readOnly = attribute.readOnly
          newState = update(newState, {
            attributes: {
              $push: [attr]
            }
          })

          // set the template attribute value to equal the one from the matter
          attribute.value = attr.value
          attribute.overwritten = true

          // we want to keep undefined at the index position
          delete templateAttributesClone[index]
        }
      }
      if (attr.type === 'relationship' && attribute.type === 'relationship') {
        if (attr.id == attribute.id) {
          attribute.overwritten = true
          // we want to keep undefined at the index position
          delete templateAttributesClone[index]
          newState = update(newState, {
            attributes: {
              $push: [attr]
            }
          })
          attribute.attributes.forEach((attribute, index) => {
            attribute.value = attr.attributes[index].value
            attr.attributes[index].required = attribute.required
          })
        }
      }
    })
  })

  // set value to none for the template attributes that are not present in matter
  templateAttributes.forEach(attribute => {
    if (!attribute.overwritten) {
      if (attribute.type === 'relationship') {
        attribute.attributes.forEach(attribute => {
          attribute.value = ''
        })
      } else {
        attribute.value = ''
      }
    }
  })

  templateAttributesClone
    .filter(attr => typeof attr !== 'undefined')
    .forEach(attribute => {
      if (typeof attribute.id === 'number') {
        attribute.class = CONST.OTHER_ATTRIBUTE
        if (attribute.type === 'select') {
          attribute.class = CONST.OTHER_ATTRIBUTE_VALUE_LIST
        }
        if (attribute.type === 'relationship') {
          attribute.class = 'RelAttrs'
        }
      } else {
        if (attribute.id === CONST.MATTER_GROUP_ID) {
          attribute.class = CONST.MATTER_GROUP
        }
        if (attribute.id === CONST.LEGAL_ENTITY_ID) {
          attribute.class = CONST.LEGAL_ENTITY
        }
      }
      if (attribute.type !== CONST.SYSTEM_GENERATED) {
        if (attribute.type === 'relationship') {
          attribute.attributes.forEach(attribute => {
            attribute.value = ''
          })
        } else {
          attribute.value = ''
        }
        newState = update(newState, {
          attributes: {
            $push: [attribute]
          }
        })
      }
    })

  return newState
}

const legalRequestReducer = createReducer(initialState, {
  [ACT.MATTER_FILTERED_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { groups } = action.payload
    let newState = cloneDeep(state)
    const attributesToUpdate = []
    const updatedAttributes = {}

    state.attributes.forEach((attribute, ai) => {
      if (attribute.type === 'relationship') {
        attribute.attributes.forEach((sectionAttribute, sai) => {
          const updatedAttribute = groups.groups.flatMap(attributeSection => {
            return attributeSection.attributes.filter(attribute => {
              return (
                attribute.type === 'select' &&
                attribute.id == sectionAttribute.id &&
                attribute.options !== null &&
                !isEqual(attribute.options, sectionAttribute.options)
              )
            })
          })[0]

          updatedAttribute &&
            attributesToUpdate.push({
              index: sai,
              attribute: updatedAttribute,
              relationshipId: attribute.id
            })
        })
      } else {
        const updatedAttribute = groups.groups.flatMap(attributeSection => {
          return attributeSection.attributes.filter(sectionAttribute => {
            return (
              sectionAttribute.type === 'select' &&
              sectionAttribute.id == attribute.id &&
              !isEqual(sectionAttribute.options, attribute.options)
            )
          })
        })[0]
        updatedAttribute && attributesToUpdate.push({ index: ai, attribute: updatedAttribute })
      }
    })

    attributesToUpdate.forEach(updatedAttribute => {
      if (
        updatedAttribute &&
        ![CONST.MATTER_GROUP_ID, CONST.LEGAL_ENTITY_ID].includes(updatedAttribute.attribute.id)
      ) {
        updatedAttributes[updatedAttribute.index] = {
          ...updatedAttribute.attribute,
          class: 'Other_Attribute_Value_List',
          options: cloneDeep(updatedAttribute.attribute.options),
          value: { value: '-1', label: 'None' },
          ...(updatedAttribute.relationshipId
            ? { relationshipId: updatedAttribute.relationshipId }
            : {})
        }
      }
    })

    Object.keys(updatedAttributes).forEach(index => {
      if (updatedAttributes[index].relationshipId) {
        const relationshipIndex = newState.attributes.findIndex(
          attribute => attribute.id === updatedAttributes[index].relationshipId
        )
        const relationshipAttributeIndex = newState.attributes[
          relationshipIndex
        ].attributes.findIndex(attribute => attribute.id === updatedAttributes[index].id)
        newState = update(newState, {
          attributes: {
            [relationshipIndex]: {
              attributes: {
                [relationshipAttributeIndex]: {
                  $set: updatedAttributes[index]
                }
              }
            }
          }
        })
      } else {
        newState = update(newState, {
          attributes: {
            [index]: {
              $set: updatedAttributes[index]
            }
          }
        })
      }
    })

    return newState
  },

  [ACT.LEGAL_REQUEST_FETCH_SUCCESS](state, action) {
    const { attributes, userFlow } = action.payload
    if (userFlow === CONST.SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE) {
      return {
        ...state,
        matterAttributes: attributes.data
      }
    }

    return {
      ...state,
      attributes: attributes.data,
      files: attributes.files
    }
  },

  [ACT.MATTER_ATTRIBUTES_DEFAULT_FETCH_SUCCESS](state, action) {
    const { attributes } = action.payload

    return {
      ...state,
      attributes: attributes.data
    }
  },

  [ACT.LEGAL_REQUEST_MAKE_ATTRIBUTES_READ_ONLY](state, action) {
    const { template } = action.payload
    const attributes = getAttributesFromTemplate(template)
    let newState = { ...state }

    newState.attributes.forEach((attr, index) => {
      attributes.forEach(attribute => {
        // we want to allow coercion because some ids are strings like 'matter_short_name' and some are numnbers
        // eslint-disable-next-line
        if (attr.id == attribute.id) {
          if (!attr.readOnly) {
            newState = update(newState, {
              attributes: {
                [index]: {
                  readOnly: { $set: attribute.readOnly }
                }
              }
            })
          }
        }
      })
    })

    return newState
  },

  [ACT.MATTER_ATTRIBUTES_SET_DEFAULTS_IN_DRAWER](state, action) {
    const { template, userFlowState } = action.payload
    const ids = [CONST.MATTER_SHORT_NAME, CONST.MATTER_GROUP_ID, CONST.LEGAL_ENTITY_ID]
    const attributes = getAttributesFromTemplate(template)
    let newState

    if (userFlowState.userFlow !== CONST.SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE) {
      newState = { ...state }
      attributes.forEach(attribute => {
        if (ids.includes(attribute.id)) {
          newState.attributes.forEach((attr, index) => {
            let value = get(attribute, 'value', '')
            // we want to allow coercion because some ids are strings like 'matter_short_name' and some are numnbers
            // eslint-disable-next-line
            if (attr.id == attribute.id) {
              if (attribute.type === 'select') {
                value = get(attribute, 'value') || { value: -1, label: 'None' }
              }
              if (!attr.readOnly) {
                newState = update(newState, {
                  attributes: {
                    [index]: {
                      readOnly: { $set: attribute.readOnly }
                    }
                  }
                })
              }
              newState = update(newState, {
                attributes: {
                  [index]: {
                    value: { $set: value }
                  }
                }
              })
            }
          })
        } else {
          if (typeof attribute.id === 'number') {
            attribute.class = CONST.OTHER_ATTRIBUTE
            if (attribute.type === 'relationship') {
              attribute.class = 'RelAttrs'
            }
            if (attribute.type === 'select') {
              attribute.class = CONST.OTHER_ATTRIBUTE_VALUE_LIST
            }
          }
          if (attribute.type !== CONST.SYSTEM_GENERATED) {
            newState = update(newState, {
              attributes: {
                $push: [attribute]
              }
            })
          }
        }
      })
    } else {
      newState = changeMatterTemplateDrawerInformation(
        cloneDeep(state.matterAttributes),
        cloneDeep(attributes)
      )
    }

    return newState
  },

  [ACT.MATTER_ATTRIBUTES_RESET_DEFAULTS_IN_DRAWER](state) {
    let newState = { ...state }

    newState.attributes.forEach((attr, index) => {
      if (!attr.required && ![CONST.APPROVERS, CONST.MATTER_SHORT_NAME].includes(attr.id)) {
        newState = update(newState, {
          attributes: {
            [index]: {
              value: { $set: '' }
            }
          }
        })
      }
    })

    const requiredAttrsIndexArr = newState.attributes
      .map((attr, index) => {
        if (typeof attr.required !== 'undefined' && attr.id !== CONST.MATTER_LEAD) return index
      })
      .filter(attr => typeof attr !== 'undefined')

    requiredAttrsIndexArr
      .sort((a, b) => b - a)
      .forEach(index => {
        newState = update(newState, {
          attributes: { $splice: [[index, 1]] }
        })
      })

    return newState
  },

  [ACT.LEGAL_REQUEST_CHANGE_ATTRIBUTE_VALUE](state, action) {
    const { updatedAttributes } = action.payload

    return update(state, { attributes: { $set: updatedAttributes } })
  },

  [ACT.LEGAL_REQUEST_RESET_STATE]() {
    return {
      ...initialState
    }
  },

  [ACT.LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS](state, action) {
    const { index, relationshipIndex, options, levels } = action.payload
    // if options is undefined that means that the vales shouuld be reset to defaultOptions
    if (levels - relationshipIndex === 2) {
      // if has only one level below it
      return update(state, {
        attributes: {
          [index]: {
            attributes: {
              [relationshipIndex + 1]: {
                options: {
                  $set:
                    options ||
                    state.attributes[index].attributes[relationshipIndex + 1].defaultOptions
                },
                value: { $set: '' }
              }
            }
          }
        }
      })
    } else {
      // if has 2 levels below it
      return update(state, {
        attributes: {
          [index]: {
            attributes: {
              [relationshipIndex + 1]: {
                options: {
                  $set:
                    options ||
                    state.attributes[index].attributes[relationshipIndex + 1].defaultOptions
                },
                value: { $set: '' }
              },
              [relationshipIndex + 2]: {
                options: {
                  $set: state.attributes[index].attributes[relationshipIndex + 2].defaultOptions
                },
                value: { $set: '' }
              }
            }
          }
        }
      })
    }
  }
})

export default legalRequestReducer
