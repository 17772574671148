import { Fragment } from 'react'
import { Panel, Button } from 'simple-core-ui'
import { RequestFormRowContainer } from '../common/Rows'
import { LegalRequestFormListHeader } from '../common/Header'
import s from './RequestFormPreview.scss'

const RequestFormPreview = ({ form, unarchiveForm }) => (
  <Fragment>
    <div className={s.actionsContainer}>
      <Button onClick={unarchiveForm} testid="unarchive_form_button">
        Unarchive
      </Button>
    </div>
    <div className={s.container}>
      <h4>
        <b className={s.title}>{form.name}</b>
      </h4>
      <div className={s.panelWrapper}>
        <Panel className={s.panel} title="Form Items">
          <div className={s.formContent}>
            <ul className={s.formItemsList}>
              <LegalRequestFormListHeader />
              {form.items.map((item, index) => {
                return (
                  <RequestFormRowContainer
                    key={`${item.model}_${index}`}
                    attrIndex={index}
                    item={item}
                    readOnly
                  />
                )
              })}
            </ul>
          </div>
        </Panel>
      </div>
    </div>
  </Fragment>
)

export default RequestFormPreview
