export const LEGAL_REQUEST_FIELDS = {
  MATTER_NAME: 'MatterName',
  VENDOR: 'Vendor'
}

export const FORM_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
  DISABLED: 'disabled'
}
