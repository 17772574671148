import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { Button, Title } from 'simple-core-ui'
import { FORM_STATUSES } from 'matters/requestForms/constants'

import s from './FormHeader.scss'

const FormHeader = ({ formStatus, title, navigateToFormListPage, cloneForm }) => (
  <div>
    <Title text={title} rank={1} />
    <section className={s.linkContainer}>
      <Link
        to="/v2/matters/requests/forms"
        onClick={navigateToFormListPage}
        data-testid="form_list_navigation"
      >
        <FaAngleLeft />
        Return to Legal Request Forms
      </Link>
    </section>
    <div className={s.status}>
      Status: <b>{formStatus}</b>
      <div className={s.cloneButton}>
        {formStatus === FORM_STATUSES.PUBLISHED && (
          <Button onClick={cloneForm} isSecondary>
            Clone
          </Button>
        )}
      </div>
    </div>
  </div>
)

export default FormHeader
