import { useState, useEffect } from 'react'
import { makeBlobRequest } from 'utils/api'
import s from '../Diversity.scss'
import { Button, ButtonDropdown } from 'simple-core-ui'
import { tabsButton, dropdownButton } from '../constants'
import { fromFragment } from '../serializers'
import moment from 'moment'
import { useSelector } from 'react-redux'

const allTabsToFalse = tab =>
  Object.keys(tab).reduce(
    (a, b) => ({
      ...a,
      [b]: false
    }),
    {}
  )

const getParams = n => {
  let calls = []
  let str = '?filter_dates='
  for (let i = n - 1; i >= 0; i--) {
    const y = moment()
      .subtract('year', i)
      .endOf('year')
      .format('YYYY-MM-DD')

    str += `${y},`
  }
  return (str += 'ytd')
}

const tabsLabel = {
  percent_headcount: 'Timekeepers',
  value_billed: 'Fees Billed',
  hours_billed: 'Hours Billed'
}

const dropdown = {
  percent_headcount: 'headcount',
  hours_billed: 'hours',
  value_billed: 'value'
}

const Tabs = ({ changeTab, entityId, isVendor, viewBy, entityLabel, isMultiDate, filterDate }) => {
  const {
    tabSelector: { tab: fragTab }
  } = fromFragment(window.location.hash)
  const [tab, setTab] = useState({
    percent_headcount: fragTab === 'percent_headcount',
    value_billed: fragTab === 'value_billed',
    hours_billed: fragTab === 'hours_billed'
  })

  const filters = useSelector(state => state.diversityReport.filters)

  useEffect(() => {
    if (viewBy === 'vendor_lead' && !tab.percent_headcount) {
      setTab({
        ...allTabsToFalse(tab),
        percent_headcount: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBy])

  const InactiveTab = tabName => {
    if (tabName !== 'percent_headcount' && viewBy === 'vendor_lead') return null

    return (
      <div
        key={tabName}
        onClick={() => {
          changeTab(tabName)
          setTab({ ...allTabsToFalse(tab), [tabName]: true })
        }}
        className={s.inactiveTab}
      >
        <p className={s.inactiveLabel}>{tabsLabel[tabName]}</p>
      </div>
    )
  }
  const ActiveTab = tabName => (
    <div key={tabName} className={s.activeTab}>
      <p className={s.activeLabel}>{tabsLabel[tabName]}</p>
    </div>
  )

  const renderTabs = Object.entries(tab).map(([tabName, value]) =>
    value ? ActiveTab(tabName) : InactiveTab(tabName)
  )

  const downloadXlsx = async option => {
    let downloadUrl = `/reports/diversity/${isVendor ? 'vendor' : 'practice_area'}/${
      option.value
    }/download_xls/${dropdown[fragTab]}.xls`

    if (isMultiDate) {
      downloadUrl = `/reports/diversity/multiyear/${option.value}/${dropdown[fragTab]}.xls`

      if (filterDate === '2 yrs') {
        downloadUrl += getParams(2)
      } else if (filterDate === '3 yrs') {
        downloadUrl += getParams(3)
      }
    }

    if (viewBy === 'vendor_comparison') {
      downloadUrl = `/reports/diversity/multivendor/${filters.vendorComparison.map(v => v.value)}/${
        dropdown[fragTab]
      }.xls`
    }

    const data = await makeBlobRequest({
      url: downloadUrl,
      method: 'GET',
      params: {
        filter_date: filters.date.value
      }
    })

    if (data.size) {
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(data)
      link.download = 'Diversity Information.xlsx'
      link.click()
    }
  }

  return (
    <div>
      <div className={s.tabsWrapper}>
        {renderTabs}

        {isMultiDate && viewBy === 'practice_area' ? null : (
          <>
            {viewBy === 'vendor' ? (
              <div style={tabsButton}>
                <ButtonDropdown
                  displayText="Download"
                  onSelect={downloadXlsx}
                  options={[
                    { label: 'All Vendors', value: 'all' },
                    { label: entityLabel, value: entityId }
                  ]}
                  isPrimary
                  style={dropdownButton}
                  alignRight
                  listStyles={{ marginTop: 12 }}
                >
                  Download
                </ButtonDropdown>
              </div>
            ) : (
              <Button
                isPrimary
                onClick={() => downloadXlsx({ value: entityId })}
                style={tabsButton}
              >
                Download
              </Button>
            )}
          </>
        )}
      </div>
      <div className={s.tabUnderBar}></div>
    </div>
  )
}

export default Tabs
