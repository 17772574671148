import * as ACT from '../actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'

const initialState = {
  defaultTemplate: '',
  filterTerm: '',
  currentPage: 1,
  objectsPerPage: 25,
  sortOrder: '',
  dataType: 'Active',
  matterTemplates: [],
  maxTemplatesCount: ''
}

const matterTemplatesListReducer = createReducer(initialState, {
  [ACT.MATTER_TEMPLATE_LIST_FETCH_SUCCESS](state, action) {
    const { list } = action.payload
    return {
      ...state,
      matterTemplates: list.templates,
      maxTemplatesCount: list.max_templates_count
    }
  },

  [ACT.MATTER_TEMPLATES_DELETE_TEMPLATE](state, action) {
    const { id } = action.payload
    const templateIndex = state.matterTemplates.findIndex(template => template.id === id)

    return update(state, {
      matterTemplates: { $splice: [[templateIndex, 1]] }
    })
  },

  [ACT.MATTER_TEMPLATES_ARCHIVE_TEMPLATE](state, action) {
    const { id } = action.payload
    const templateIndex = state.matterTemplates.findIndex(template => template.id === id)

    return update(state, {
      matterTemplates: {
        [templateIndex]: {
          status: { $set: 'archived' }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATES_RESTORE_TEMPLATE](state, action) {
    const { id } = action.payload
    const templateIndex = state.matterTemplates.findIndex(template => template.id === id)

    return update(state, {
      matterTemplates: {
        [templateIndex]: {
          status: { $set: 'published' }
        }
      }
    })
  }
})

export default matterTemplatesListReducer
