import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { SurveyQuestion } from 'simple-core-ui'
import { SURVEY_QUESTIONS } from 'vendors/constants'
import s from '../styles/VendorEval.scss'
import FinalComment from '../components/FinalComment'
import ACT from 'vendors/survey/actions'
import Media from 'react-media'

@connect(({ vendorEvals }) => {
  const { evaluation } = vendorEvals
  return {
    answers: evaluation.answers,
    finalComment: evaluation.finalComment,
    isClosed: evaluation.isClosed
  }
})
class AnswersContainer extends Component {
  changeEvalAttribute = (attribute, value) => {
    this.props.dispatch({
      type: ACT.CHANGE_EVAL_ATTRIBUTE,
      payload: {
        attribute,
        value
      }
    })
  }

  changeEvalAnswer = (value, comment, ansIndex) => {
    const { answers } = this.props
    if (answers[ansIndex].value === value && answers[ansIndex].comment === comment) {
      this.props.dispatch({
        type: ACT.RESET_EVAL_ANSWER,
        payload: {
          ansIndex
        }
      })
    } else {
      this.props.dispatch({
        type: ACT.CHANGE_EVAL_ANSWER,
        payload: {
          value,
          comment,
          ansIndex
        }
      })
    }
  }

  getInfoText = matches => {
    if (matches) {
      return {
        '1': 'Below Expectations',
        '10': 'Exceeded Expectations'
      }
    }

    return {
      '1': 'Below Expectations',
      '7': 'Met Expectations',
      '10': 'Exceeded Expectations'
    }
  }

  render() {
    const {
      answers,
      finalComment,
      isClosed,
      matterName,
      vendorName,
      validateSurvey,
      invalidateSurvey
    } = this.props

    return (
      <main className={s.main}>
        <Media query="(max-width: 860px)">
          {matches => (
            <Fragment>
              <ol className={s.list}>
                {answers.map((answer, qIndex) => (
                  <SurveyQuestion
                    text={SURVEY_QUESTIONS[answer.name]}
                    initialValues={{
                      comment: answer.comment,
                      value: answer.value
                    }}
                    infoText={this.getInfoText(matches)}
                    onChange={({ value, comment }) => this.changeEvalAnswer(value, comment, qIndex)}
                    isClosed={isClosed}
                    key={`vs_qs_${qIndex}`}
                    required={qIndex === 0}
                  />
                ))}
              </ol>
              <FinalComment
                finalComment={finalComment}
                isClosed={isClosed}
                changeEvalAttribute={this.changeEvalAttribute}
                isSmallDevice={!matches}
              />
            </Fragment>
          )}
        </Media>
      </main>
    )
  }
}

export default AnswersContainer
