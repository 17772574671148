import keyMirror from 'keymirror'

export default keyMirror({
  EBILL_INITIALIZE: null,
  PUSH_NOTIFICATION: null,
  API_ERROR: null,
  CLEAR_NOTIFICATION: null,
  SET_LOADING_FLAGS: null,
  UPDATE_PAGE_TITLE: null,
  UPDATE_SCOPE: null,
  SEARCH_RESULTS_FETCH_REQUESTED: null,
  SEARCH_RESULTS_FETCH_SUCCESS: null,
  FILTER_SEARCH_RESULTS: null,
  SET_LOADING: null,
  SET_INITIAL_LOAD: null,
  ADD_AVATAR: null,
  REMOVE_AVATAR: null
})
