import keyMirror from 'keymirror'

export default keyMirror({
  ASSIGNED_CONTACTS_FETCH_REQUESTED: null,
  ASSIGNED_CONTACTS_FETCH_SUCCESS: null,
  ASSIGNED_CONTACTS_UPDATE_SUCCESS: null,
  ASSIGNED_CONTACTS_SUBMISSION_REQUESTED: null,
  ASSIGNED_CONTACTS_SUBMISSION_SUCCESS: null,
  ASSIGNED_CONTACT_REMOVAL_REQUESTED: null,
  ASSIGNED_CONTACT_REMOVAL_SUCCESS: null,
  ASSIGNED_CONTACT_ROLE_REMOVAL_REQUESTED: null,
  ASSIGNED_CONTACT_ROLE_REMOVAL_SUCCESS: null,
  CONTACTS_FETCH_REQUESTED: null,
  CONTACTS_FETCH_SUCCESS: null,
  CONTACT_FETCH_REQUESTED: null,
  CONTACT_FETCH_SUCCESS: null,
  CONTACT_FETCH_LOADING: null,
  CONTACT_CREATION_REQUESTED: null,
  CONTACT_CREATION_SUCCESS: null,
  CONTACT_UPDATE_REQUESTED: null,
  CONTACT_UPDATE_SUCCESS: null,
  CONTACT_DELETE_REQUESTED: null,
  CONTACT_DELETE_SUCCESS: null,
  CONTACT_ROLES_FETCH_REQUESTED: null,
  CONTACT_ROLES_FETCH_SUCCESS: null,
  ASSIGNED_CONTACTS_SYNC: null,
  ROLE_TIMESTAMP_UPDATE: null,
  CONTACT_PROFILE_FETCH_REQUESTED: null,
  CONTACT_ACTIVITY_FETCH_REQUESTED: null,
  CONTACT_ACTIVITY_FETCH_SUCCESS: null,
  CONTACT_ACTIVITY_LOADING: null,
  CONTACT_ACTIVITY_ERROR: null,
  CONTACT_AFFILIATION_FETCH_REQUESTED: null,
  CONTACT_AFFILIATION_FETCH_SUCCESS: null,
  CONTACT_AFFILIATION_LOADING: null,
  CONTACT_AFFILIATION_COUNTS_REQUESTED: null,
  CONTACT_AFFILIATION_COUNTS_SUCCESS: null,
  CONTACT_AFFILIATION_ERROR: null,
  CONTACT_ATTRIBUTES_UPDATE_REQUESTED: null,
  CONTACT_ATTRIBUTES_UPDATE_SUCCESS: null,
  CONTACT_AVATAR_UPLOAD_REQUESTED: null,
  CONTACT_AVATAR_UPLOAD_SUCCESS: null,
  CONTACT_AVATAR_DELETE_REQUESTED: null,
  CONTACT_AVATAR_DELETE_SUCCESS: null,
  CONTACT_AVATAR_FETCH_REQUESTED: null,
  CONTACT_AVATAR_FETCH_SUCCESS: null,
  CONTACT_AVATAR_LOADING: null,
  CONTACT_ADDITIONAL_FIELDS_FETCH_REQUESTED: null,
  CONTACT_ADDITIONAL_FIELDS_FETCH_SUCCESS: null,
  CONTACT_ADDITIONAL_FIELDS_LOADING: null,
  CONTACT_ADDITIONAL_FIELD_UPDATE_REQUESTED: null,
  CONTACT_ADDITIONAL_FIELD_UPDATE_SUCCESS: null,
  CONTACT_ADDITIONAL_FIELD_CREATE_REQUESTED: null,
  CONTACT_ADDITIONAL_FIELD_CREATE_SUCCESS: null,
  CONTACT_ADDITIONAL_FIELD_DELETE_REQUESTED: null,
  CONTACT_ADDITIONAL_FIELD_DELETE_SUCCESS: null,
  CONTACT_ADDRESS_BOOK_FETCH_REQUESTED: null,
  CONTACT_ADDRESS_BOOK_FETCH_SUCCESS: null,
  CONTACT_ADDRESS_BOOK_LOADING: null,
  CONTACT_USER_SETTINGS_FETCH_REQUESTED: null,
  CONTACT_USER_SETTINGS_FETCH_SUCCESS: null,
  CONTACT_USER_SETTINGS_LOADING: null,
  CONTACT_USER_SETTING_UPDATE_REQUESTED: null,
  CONTACT_USER_SETTING_UPDATE_SUCCESS: null,
  CURRENT_CG_ADMINS_NO_CHANGE: null
})
