import { useState } from 'react'
import { ModalContainer } from 'simple-core-ui'
import s from './CopyMethods.scss'
import { FaTasks } from 'react-icons/fa'
import { MdTaskAlt } from 'react-icons/md'
import cn from 'classnames'

interface Props {
  onConfirm: (includeSubtasks: boolean) => void
  onCancel: () => void
}

const CopyMethods = ({ onConfirm, onCancel }: Props) => {
  const [selected, setSelected] = useState<'' | 'all' | 'single'>('')

  return (
    <ModalContainer
      title="Choose a copy method"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Save"
      confirmCb={() => onConfirm(selected === 'all')}
      isDisabled={!selected}
      content={
        <div className={s.flex}>
          <div
            className={cn(s.method, {
              [s.selected]: selected === 'all'
            })}
            onClick={() => setSelected('all')}
          >
            <p className={s.header}>
              <FaTasks /> Tasks and subtasks
            </p>
            <span>Copy this task and all of it&apos;s subtasks</span>
          </div>
          <div
            className={cn(s.method, {
              [s.selected]: selected === 'single'
            })}
            onClick={() => setSelected('single')}
          >
            <p className={s.header}>
              <MdTaskAlt /> Single Task
            </p>
            <span>Copy this task only</span>
          </div>
        </div>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default CopyMethods
