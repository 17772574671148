import useWindowSize from 'hooks/useWindowSize'
import { VENDOR_COMPARISON } from '../constants'

export default function useResponsiveChart() {
  const windowSize = useWindowSize()
  const MIN_WINDOW_WIDTH = 1510

  let MINWIDTH_FIRST_COL = 132

  let GENDER_CHART_WIDTH = 85
  let RACE_CHART_WIDTH = 85
  let STATUS_CHART_WIDTH = 60

  if (windowSize.width < MIN_WINDOW_WIDTH) {
    MINWIDTH_FIRST_COL = 132

    VENDOR_COMPARISON[0].marginTop = 30
    VENDOR_COMPARISON[2].marginTop = 30

    VENDOR_COMPARISON[0].minWidth = 275
    VENDOR_COMPARISON[1].minWidth = 657
    VENDOR_COMPARISON[2].minWidth = 200

    GENDER_CHART_WIDTH = 85
    RACE_CHART_WIDTH = 85
    STATUS_CHART_WIDTH = 60
  } else {
    MINWIDTH_FIRST_COL = 132

    VENDOR_COMPARISON[0].marginTop = 0
    VENDOR_COMPARISON[2].marginTop = 0

    VENDOR_COMPARISON[1].columns[0].title = 'AK/Native Am.'
    VENDOR_COMPARISON[1].columns[5].title = 'Pac. Islander'

    VENDOR_COMPARISON[0].minWidth = 275 + (windowSize.width - MIN_WINDOW_WIDTH) * (275 / 1132)
    VENDOR_COMPARISON[1].minWidth = 657 + (windowSize.width - MIN_WINDOW_WIDTH) * (657 / 1132)
    VENDOR_COMPARISON[2].minWidth = 200 + (windowSize.width - MIN_WINDOW_WIDTH) * (200 / 1132)

    GENDER_CHART_WIDTH = 85 + (windowSize.width - MIN_WINDOW_WIDTH) * (275 / 1132 / 3)
    RACE_CHART_WIDTH = 85 + (windowSize.width - MIN_WINDOW_WIDTH) * (657 / 1132 / 7)
    STATUS_CHART_WIDTH = 60 + (windowSize.width - MIN_WINDOW_WIDTH) * (200 / 1132 / 3)
  }

  if (windowSize.width <= 1600) {
    VENDOR_COMPARISON[0].marginTop = 30
    VENDOR_COMPARISON[2].marginTop = 30
    VENDOR_COMPARISON[1].columns[0].title = 'AK/Native American'
    VENDOR_COMPARISON[1].columns[5].title = 'Pacific Islander'
  }

  return [
    VENDOR_COMPARISON,
    MINWIDTH_FIRST_COL,
    GENDER_CHART_WIDTH,
    RACE_CHART_WIDTH,
    STATUS_CHART_WIDTH
  ]
}
