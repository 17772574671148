import get from 'lodash/get'

export const getSize = size => {
  if (!size) return

  return {
    min: Number(size.split('-')[0]),
    max: Number(size.split('-')[1])
  }
}

export const toParams = (q, params) => {
  const size = getSize(get(params, 'size.value'))
  const sizeMin = get(size, 'min')
  const sizeMax = get(size, 'max')
  const dateMin = get(params, 'date.value.min')
  const dateMax = get(params, 'date.value.max')
  const matter = get(params, 'matter.value')
  const modifiedBy = get(params, 'modifiedBy.value')
  const label = get(params, 'label.value')
  const category = get(params, 'category.value')
  return {
    query: q,
    ...(sizeMin ? { size_min: sizeMin } : {}),
    ...(sizeMax ? { size_max: sizeMax } : {}),
    ...(dateMin ? { date_min: dateMin } : {}),
    ...(dateMax ? { date_max: dateMax } : {}),
    ...(matter ? { matter_id: matter } : {}),
    ...(modifiedBy ? { modified_by: modifiedBy } : {}),
    ...(label ? { label_ids: label.map(l => l.value) } : {}),
    ...(category ? { category_ids: category.map(l => l.value) } : {})
  }
}
