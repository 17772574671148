import React from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import { ActivityLog } from 'simple-core-ui'
import { LOGBOOK_ACTIONS } from 'logbook/constants'
import ACT from '../actions'
import { initialState } from '../reducer'

@connect(({ contacts, app }) => {
  const { activity } = contacts
  const {
    credentials: { user }
  } = app
  return { activity, user }
})
@withFetchFlow({
  flag: 'contactActivity',
  getFetchAction: props => {
    return {
      type: ACT.CONTACT_ACTIVITY_FETCH_REQUESTED,
      payload: {
        contactId: props.router.params.id
      },
      loadingStateDisabled: true
    }
  }
})
class ContactActivityContainer extends React.Component {
  static defaultProps = {
    contactId: -1,
    activity: initialState.activity,
    dispatch: obj => {}
  }

  loadMoreActivity = () => {
    this.props.dispatch({
      type: ACT.CONTACT_ACTIVITY_FETCH_REQUESTED,
      payload: {
        contactId: this.props.router.params.id,
        offset: this.getOldestTimestamp()
      }
    })
  }

  getOwner = log => (log.user === this.props.user.email ? 'You' : log.user)
  prettifyLogs = logs =>
    logs.map(log => ({
      ...log,
      owner: this.getOwner(log),
      action: LOGBOOK_ACTIONS[log.category]
    }))

  getOldestTimestamp = () => Math.min(...this.props.activity.logs.map(({ created }) => created))

  render = () => (
    <ActivityLog
      logs={this.prettifyLogs(this.props.activity.logs)}
      isFetching={this.props.activity.isLoading}
      isError={this.props.activity.isError}
      hasMore={this.props.activity.hasMore}
      loadMoreCb={this.loadMoreActivity}
    />
  )
}

export default ContactActivityContainer
