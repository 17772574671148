import currency from 'currency.js'
import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid'
import partition from 'lodash/partition'

export const toAllocationList = (allocations, sortedAttributes, names) => {
  const response = {}

  sortedAttributes.forEach((attribute, index) => {
    if (attribute.allocation_type === 'legal_entities') {
      response[names.legal_entity_management[0]] = allocations.reduce((acc, allocation) => {
        if (allocation.legal_entity) {
          if (acc[allocation.legal_entity.value]) {
            acc[allocation.legal_entity.value] = currency(acc[allocation.legal_entity.value])
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc[allocation.legal_entity.value] = currency(
              allocation.allocation_percentage
            ).value.toFixed(2)
          }
        } else {
          if (acc['None']) {
            acc['None'] = currency(acc['None'])
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc['None'] = currency(allocation.allocation_percentage).value.toFixed(2)
          }
        }
        if (isEmpty(acc)) {
          return null
        } else {
          return acc
        }
      }, {})
    } else if (attribute.allocation_type === 'cost_codes') {
      response[names.costcode_management[0]] = allocations.reduce((acc, allocation) => {
        if (allocation.cost_code) {
          if (acc[allocation.cost_code.value]) {
            acc[allocation.cost_code.value] = currency(acc[allocation.cost_code.value])
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc[allocation.cost_code.value] = currency(
              allocation.allocation_percentage
            ).value.toFixed(2)
          }
        } else {
          if (acc['None']) {
            acc['None'] = currency(acc['None'])
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc['None'] = currency(allocation.allocation_percentage).value.toFixed(2)
          }
        }
        if (isEmpty(acc)) {
          return null
        } else {
          return acc
        }
      }, {})
    } else {
      response[attribute.other_attribute_short_name] = allocations.reduce((acc, allocation) => {
        const id = attribute.other_attribute_id
        if (allocation.other_attributes[id]) {
          if (acc[allocation.other_attributes[id].attr_short_name]) {
            acc[allocation.other_attributes[id].attr_short_name] = currency(
              acc[allocation.other_attributes[id].attr_short_name]
            )
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc[allocation.other_attributes[id].attr_short_name] = currency(
              allocation.allocation_percentage
            ).value.toFixed(2)
          }
        } else {
          if (acc['None']) {
            acc['None'] = currency(acc['None'])
              .add(allocation.allocation_percentage)
              .value.toFixed(2)
          } else {
            acc['None'] = currency(allocation.allocation_percentage).value.toFixed(2)
          }
        }
        if (isEmpty(acc)) {
          return null
        } else {
          return acc
        }
      }, {})
    }
  })
  const mapper = Object.keys(response).map((attribute, attrIndex) => {
    return Object.keys(response[attribute]).map((value, index) => {
      return {
        id: `${attrIndex}-${index}`,
        cells: [
          { columnKey: 'attribute', content: index === 0 ? attribute : '' },
          { columnKey: 'value', content: value },
          { columnKey: 'percentage', content: `${response[attribute][value]}%` }
        ],
        avatar: false
      }
    })
  })

  return mapper.flat()
}

export const toAllocations = (allocations, sortedAttributes, names, entityFilteredAttributes) => {
  return allocations.map(allocation => {
    return {
      id: uuidv4(),
      percentage: currency(allocation.allocation_percentage).value.toFixed(2),
      attributes: sortedAttributes.map(attribute => {
        return {
          type: attribute.allocation_type,
          id: attribute.other_attribute_id,
          name:
            attribute.allocation_type === 'legal_entities'
              ? names.legal_entity_management[0]
              : attribute.allocation_type === 'cost_codes'
              ? names.costcode_management[0]
              : attribute.other_attribute_short_name,
          options: ['legal_entities', 'cost_codes'].includes(attribute.allocation_type)
            ? toOptions(attribute.values)
            : toOtherAttributesOptions(
                attribute.other_attribute_id,
                allocation.legal_entity?.id ?? 0,
                entityFilteredAttributes
              ),
          value:
            attribute.allocation_type === 'legal_entities'
              ? allocation.legal_entity
                ? {
                    value: allocation.legal_entity.id,
                    label: allocation.legal_entity.value
                  }
                : null
              : attribute.allocation_type === 'cost_codes'
              ? allocation.cost_code
                ? {
                    value: allocation.cost_code.id,
                    label: allocation.cost_code.value
                  }
                : null
              : allocation.other_attributes[attribute.other_attribute_id]
              ? {
                  value:
                    allocation.other_attributes[attribute.other_attribute_id].attribute_value_id,
                  label: allocation.other_attributes[attribute.other_attribute_id].attr_short_name
                }
              : null
        }
      })
    }
  })
}

const toOtherAttributesOptions = (attributeId, legalEntityId, entityFilteredAttributes) => {
  const attribute = entityFilteredAttributes[legalEntityId].find(
    a => a.attribute_id === attributeId
  )

  return (
    attribute?.options.map(o => ({
      value: o.id,
      label: o.value
    })) ?? []
  )
}

const toOptions = options =>
  options.map(option => ({
    value: option[0],
    label: option[1]
  }))

export const fromAllocations = allocations => {
  return {
    allocations: allocations.map(allocation => {
      const [attributes, nonAttributes] = partition(
        allocation.attributes,
        item => item.type === 'attribute'
      )
      const legalEntity = nonAttributes.find(a => a.type === 'legal_entities')
      const costCode = nonAttributes.find(a => a.type === 'cost_codes')
      return {
        legal_entity: legalEntity.value ? legalEntity.value.value : null,
        cost_code: costCode.value ? costCode.value.value : null,
        percentage: allocation.percentage,
        attributes: attributes.map(a => ({
          attribute_id: a.id,
          value_id: a.value ? a.value.value : null
        }))
      }
    })
  }
}

export const toDefaultAllocations = (allocations, sortedAttributes) => {
  if (!allocations.length) {
    return [
      {
        allocation_percentage: '100.0000',
        cost_code: null,
        legal_entity: null,
        other_attributes: {}
      }
    ]
  }

  return allocations
}
