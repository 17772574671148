import { Alert } from 'simple-core-ui'
import s from './ActionsReadOnly.scss'
import { Action, AvailableAction } from 'simple_review/@types/api'

const commentActions: Array<AvailableAction['name']> = [
  'add_activity_code',
  'add_expense_code',
  'add_task_code',
  'flag_invoice',
  'flag_line_item'
]

interface Props {
  actions: Array<Action>
  availableActions: Array<AvailableAction>
  ruleIndex: number
}

const getAvailableActionIndex = (
  action: Action,
  availableActions: Array<AvailableAction>
): number => {
  return availableActions.findIndex(availableAction => availableAction.name === action.name)
}

const getActionDisplayName = (
  availableActionIndex: number,
  availableActions: Array<AvailableAction>
): string => {
  const availableAction = availableActions[availableActionIndex]
  return availableAction ? availableAction.display_name : ''
}

const getParamDisplayName = (
  paramName: string,
  availableActions: Array<AvailableAction>,
  availableActionIndex: number
): string => {
  const availableAction = availableActions[availableActionIndex]
  if (paramName === 'comment') {
    if (commentActions.includes(availableAction.name)) return 'Comment'
    return 'Vendor and Internal Comment'
  }
  if (paramName === 'vendor_explanation') return 'Vendor Comment'
  if (paramName === 'client_explanation') return 'Internal Comment'

  const param = availableAction.params.find(param => param.name === paramName)

  return param ? param.display_name : ''
}

const renderActions = (
  actions: Array<Action>,
  availableActions: Array<AvailableAction>,
  ruleIndex: number
): Array<JSX.Element> => {
  return actions.map((action, index) => {
    const availableActionIndex = getAvailableActionIndex(action, availableActions)
    return (
      <div key={index} data-testid={`action-read-only-${ruleIndex}`}>
        <div className={s.action}>
          <span className={s.actionName}>
            {getActionDisplayName(availableActionIndex, availableActions)}{' '}
          </span>
          <span className={s.actionArgs}>
            {action.params.map((arg, index) => {
              return (
                <span key={index} className={s.actionArg}>
                  <span>
                    {index === action.params.length - 1 || index === 0 ? 'with ' : ', '}
                    <span className={s.argName}>
                      {getParamDisplayName(arg.param_name, availableActions, availableActionIndex)}{' '}
                    </span>
                  </span>
                  <span className={s.argValue}>{`"${arg.value.label}" `}</span>
                  {index !== action.params.length - 1 && (
                    <span className={s.argSeparator}>and </span>
                  )}
                </span>
              )
            })}
          </span>
        </div>
        {index !== actions.length - 1 && <p className={s.actionSeparator}>AND</p>}
      </div>
    )
  })
}

const ActionsReadOnly = ({ actions, availableActions, ruleIndex }: Props) => {
  if (!actions.length) {
    return (
      <Alert
        message="This rule has no actions."
        status="info"
        style={{ alignSelf: 'flex-start' }}
      />
    )
  }

  return (
    <div className={s.actionsContainer}>{renderActions(actions, availableActions, ruleIndex)}</div>
  )
}

export default ActionsReadOnly
