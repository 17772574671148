import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Row } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  row: Row
  onDelete(id: number): void
  onEdit(id: number): void
}

const ActionsPopover = ({
  row: { id, count, is_default: isDefaultCategory, active },
  onDelete,
  onEdit
}: Props) => {
  if (isDefaultCategory || count >= 1) return null

  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      disabled: active,
      onClick() {
        onEdit(id)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      disabled: active,
      onClick() {
        onDelete(id)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.tooltipContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
