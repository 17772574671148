export const LOGBOOK_ACTIONS = {
  ADD: 'added',
  EDIT: 'edited',
  DELETE: 'deleted',
  VIEW: 'viewed',
  UPDATE: 'updated',
  SUBMIT: 'submitted',
  SAVE: 'saved',
  LOGIN: 'logged in',
  LOGOUT: 'logged out',
  MERGE: 'merged',
  CLONE: 'cloned'
}
