import { ModalContainer } from 'simple-core-ui'

interface Props {
  onConfirm: (id?: number) => void
  onCancel: () => void
}

const CopyConfirmation = ({ onConfirm, onCancel }: Props) => {
  return (
    <ModalContainer
      title="Copy to Our Files?"
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Copy"
      confirmCb={onConfirm}
      content={<div>Copying a file creates a duplicate in &quot;Our Files&quot;.</div>}
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default CopyConfirmation
