import * as ACT from '../actions'
import * as CONST from '../constants'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

const getCategoryAttributes = (category = []) => {
  return flatMap(category.reduce((a, b) => a.concat(b.attributes), []))
}

const getAllAttributes = (state = {}) => {
  return getCategoryAttributes(state.right).concat(getCategoryAttributes(state.left))
}

const initialState = {
  name: '',
  description: '',
  status: 'draft',
  default: false,
  left: [],
  right: [],
  forms: [],
  breakingWarning: '',
  survey_config: {
    status: 'disabled',
    users: [],
    roles: []
  },
  isSaving: false
}

const matterTemplatesReducer = createReducer(initialState, {
  [ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    // update already dragged template attributes
    const { groups } = action.payload
    const categoryArr = ['left', 'right']
    let newState = cloneDeep(state)

    categoryArr.forEach((category, ci) => {
      newState[category] = newState[category].map((section, si) => {
        return {
          ...section,
          attributes: section.attributes.map((attribute, ai) => {
            if (attribute.type === 'relationship') {
              const updatedAttributes = attribute.attributes.map((sectionAttribute, sai) => {
                const updatedAttribute = groups.groups.flatMap(attributeSection => {
                  return attributeSection.attributes.filter(attribute => {
                    return (
                      attribute.type === 'select' &&
                      attribute.id == sectionAttribute.id &&
                      !isEqual(attribute.options, sectionAttribute.options)
                    )
                  })
                })[0]

                if (
                  updatedAttribute &&
                  ![CONST.MATTER_GROUP_ID, CONST.LEGAL_ENTITY_ID].includes(updatedAttribute.id)
                ) {
                  return {
                    ...sectionAttribute,
                    options: cloneDeep(updatedAttribute.options),
                    value: { value: '-1', label: 'None' }
                  }
                } else {
                  return sectionAttribute
                }
              })

              return {
                ...attribute,
                attributes: updatedAttributes
              }
            } else {
              let updatedAttribute = groups.groups.flatMap(attributeSection =>
                attributeSection.attributes.filter(
                  sectionAttribute =>
                    sectionAttribute.type === 'select' &&
                    sectionAttribute.id === attribute.id &&
                    !isEqual(sectionAttribute.options, attribute.options)
                )
              )[0]

              if (
                updatedAttribute &&
                ![CONST.MATTER_GROUP_ID, CONST.LEGAL_ENTITY_ID].includes(updatedAttribute.id)
              ) {
                return {
                  ...attribute,
                  options: cloneDeep(updatedAttribute.options),
                  value: { value: '-1', label: 'None' }
                }
              } else {
                return attribute
              }
            }
          })
        }
      })
    })

    return newState
  },

  [ACT.NEW_MATTER_TEMPLATE_REQUESTED](state, action) {
    return {
      ...state
    }
  },

  [ACT.MATTER_TEMPLATE_CREATE_SUCCESS](state, action) {
    const { id } = action.payload

    return {
      ...state,
      id
    }
  },

  [ACT.MATTER_TEMPLATE_CREATE_SUCCESS_AND_REDIRECT](state, action) {
    return {
      ...state,
      shouldRedirect: true
    }
  },

  [ACT.MATTER_TEMPLATE_FETCH_SUCCESS](state, action) {
    const { template } = action.payload
    return {
      ...template
    }
  },

  [ACT.MATTER_TEMPLATE_REMOVE_REDIRECT_FLAG](state, action) {
    return update(state, { $unset: ['shouldRedirect'] })
  },

  [ACT.MATTER_TEMPLATES_CHANGE_TEMPLATE_NAME](state, action) {
    const { name } = action.payload
    return {
      ...state,
      name
    }
  },

  [ACT.MATTER_TEMPLATES_REMOVE_SECTION](state, action) {
    const { category, sectionIndex } = action.payload

    return update(state, {
      [category]: { $splice: [[sectionIndex, 1]] }
    })
  },

  [ACT.MATTER_TEMPLATES_PIN_SECTION](state, action) {
    const { category, sectionIndex, displayExpanded } = action.payload

    return update(state, {
      [category]: {
        [sectionIndex]: {
          displayExpanded: { $set: displayExpanded }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATES_REMOVE_ATTRIBUTE](state, action) {
    const { category, sectionIndex, attrIndex } = action.payload

    return update(state, {
      [category]: { [sectionIndex]: { attributes: { $splice: [[attrIndex, 1]] } } }
    })
  },

  [ACT.MATTER_TEMPLATES_ADD_ATTRIBUTE_GROUP](state, action) {
    const { type, item, sectionIndex } = action.payload

    // filter out existing attributes
    const allAttributes = getAllAttributes(state)
    const attributes = item.attributes.filter(attr => !find(allAttributes, a => a.id === attr.id))

    const section = {
      sectionName: item.name,
      attributes,
      displayExpanded: true
    }

    if (sectionIndex != null) {
      return update(state, {
        [type]: { $splice: [[sectionIndex, 0, section]] }
      })
    }

    return state
  },

  [ACT.MATTER_TEMPLATES_REORDER_ATTRIBUTE_GROUP](state, action) {
    const { from, to } = action.payload

    const removedState = update(state, {
      [from.type]: { $splice: [[from.sectionIndex, 1]] }
    })

    const newState = update(removedState, {
      [to.type]: { $splice: [[to.sectionIndex, 0, from.item]] }
    })

    return newState
  },

  [ACT.MATTER_TEMPLATES_ADD_ATTRIBUTE](state, action) {
    const { type, item, sectionIndex, attrIndex } = action.payload

    if (attrIndex != null) {
      return update(state, {
        [type]: { [sectionIndex]: { attributes: { $splice: [[attrIndex, 0, item]] } } }
      })
    }

    return state
  },

  [ACT.MATTER_TEMPLATES_REORDER_ATTRIBUTE](state, action) {
    const { from, to } = action.payload

    const removedState = update(state, {
      [from.type]: { [from.sectionIndex]: { attributes: { $splice: [[from.attrIndex, 1]] } } }
    })

    const newState = update(removedState, {
      [to.type]: { [to.sectionIndex]: { attributes: { $splice: [[to.attrIndex, 0, from.item]] } } }
    })

    return newState
  },

  [ACT.MATTER_TEMPLATES_CHANGE_TEMPLATE_DESCRIPTION](state, action) {
    const { description } = action.payload
    return {
      ...state,
      description
    }
  },

  [ACT.MATTER_TEMPLATES_CHANGE_PANEL_TITLE](state, action) {
    const { panelTitle } = action.payload
    return {
      ...state,
      panelTitle
    }
  },

  [ACT.MATTER_TEMPLATES_CHANGE_SECTION_NAME](state, action) {
    const { sectionName, category, sectionIndex } = action.payload

    return update(state, {
      [category]: {
        [sectionIndex]: {
          sectionName: { $set: sectionName }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATES_ADD_NEW_SECTION](state, action) {
    const { category } = action.payload

    return update(state, {
      [category]: {
        $push: [
          {
            sectionName: `Section ${state[category].length + 1}`,
            attributes: [],
            displayExpanded: true
          }
        ]
      }
    })
  },

  [ACT.MATTER_TEMPLATES_REMOVE_SECTION](state, action) {
    const { category, sectionIndex } = action.payload

    return update(state, {
      [category]: { $splice: [[sectionIndex, 1]] }
    })
  },

  [ACT.MATTER_TEMPLATES_REMOVE_ATTRIBUTE](state, action) {
    const { category, attrIndex, sectionIndex } = action.payload

    return update(state, {
      [category]: {
        [sectionIndex]: {
          attributes: { $splice: [[attrIndex, 1]] }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATES_CHANGE_ATTRIBUTE_VALUE](state, action) {
    const {
      attrIndex,
      value,
      property = 'value',
      category,
      sectionIndex,
      relationshipIndex
    } = action.payload

    if (relationshipIndex !== undefined) {
      return update(state, {
        [category]: {
          [sectionIndex]: {
            attributes: {
              [attrIndex]: {
                attributes: {
                  [relationshipIndex]: {
                    [property]: { $set: value }
                  }
                }
              }
            }
          }
        }
      })
    }

    return update(state, {
      [category]: {
        [sectionIndex]: {
          attributes: {
            [attrIndex]: {
              [property]: { $set: value }
            }
          }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_UNCHECK_REQUIRED_FOR_SUB_LEVELS](state, action) {
    const { attrIndex, value, category, sectionIndex, relationshipIndex, levels } = action.payload

    if (levels - relationshipIndex === 2) {
      // if has only one level below it
      return update(state, {
        [category]: {
          [sectionIndex]: {
            attributes: {
              [attrIndex]: {
                attributes: {
                  [relationshipIndex + 1]: {
                    required: { $set: value }
                  }
                }
              }
            }
          }
        }
      })
    } else {
      // if has 2 levels below it
      return update(state, {
        [category]: {
          [sectionIndex]: {
            attributes: {
              [attrIndex]: {
                attributes: {
                  [relationshipIndex + 1]: {
                    required: { $set: value }
                  },
                  [relationshipIndex + 2]: {
                    required: { $set: value }
                  }
                }
              }
            }
          }
        }
      })
    }
  },

  [ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS](state, action) {
    const { attrIndex, category, sectionIndex, relationshipIndex, options, levels } = action.payload
    // if options is undefined that means that the vales shouuld be reset to defaultOptions
    if (levels - relationshipIndex === 2) {
      // if has only one level below it
      return update(state, {
        [category]: {
          [sectionIndex]: {
            attributes: {
              [attrIndex]: {
                attributes: {
                  [relationshipIndex + 1]: {
                    options: {
                      $set:
                        options ||
                        state[category][sectionIndex].attributes[attrIndex].attributes[
                          relationshipIndex + 1
                        ].defaultOptions
                    },
                    value: { $set: '' }
                  }
                }
              }
            }
          }
        }
      })
    } else {
      // if has 2 levels below it
      return update(state, {
        [category]: {
          [sectionIndex]: {
            attributes: {
              [attrIndex]: {
                attributes: {
                  [relationshipIndex + 1]: {
                    options: {
                      $set:
                        options ||
                        state[category][sectionIndex].attributes[attrIndex].attributes[
                          relationshipIndex + 1
                        ].defaultOptions
                    },
                    value: { $set: '' }
                  },
                  [relationshipIndex + 2]: {
                    options: {
                      $set:
                        state[category][sectionIndex].attributes[attrIndex].attributes[
                          relationshipIndex + 2
                        ].defaultOptions
                    },
                    value: { $set: '' }
                  }
                }
              }
            }
          }
        }
      })
    }
  },

  [ACT.MATTER_TEMPLATE_RESET_STATE](state, action) {
    return {
      ...initialState
    }
  },

  [ACT.MATTER_TEMPLATE_RESTORE_TEMPLATE](state, action) {
    return {
      ...state,
      status: 'published'
    }
  },

  [ACT.MATTER_TEMPLATE_RESTORE_TO_DRAFT](state, action) {
    return {
      ...state,
      status: 'draft'
    }
  },

  [ACT.MATTER_TEMPLATES_UPDATE_FILTERED_ATTRIBUTES](state, action) {
    const { category, sectionIndex, attributeIndex, options } = action.payload

    return update(state, {
      [category]: {
        [sectionIndex]: {
          attributes: {
            [attributeIndex]: {
              options: { $set: options },
              value: {
                $set: { value: 1, label: 'None' }
              }
            }
          }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATE_SET_BREAKING_WARNING](state, action) {
    const { breakingWarning } = action.payload
    return {
      ...state,
      breakingWarning
    }
  },

  [ACT.MATTER_TEMPLATE_SET_IS_SAVING](state, action) {
    const { isSaving } = action.payload
    return {
      ...state,
      isSaving
    }
  }
})

export default matterTemplatesReducer
