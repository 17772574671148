import { BaseGuard } from './types'

/**
 * Represents a guard that controls the user's access to a route depending on a custom predicate.
 * @param predicate - Any function that returns a boolean value.
 */
export default function CustomGuard(predicate: () => boolean): BaseGuard {
  return {
    canRoute: predicate
  }
}
