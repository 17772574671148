import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requestForms/detail/actions'
import * as CONST from 'matters/requestForms/constants'
import { LEGAL_REQUEST_FIELDS } from 'matters/requestForms/constants'
import { RequestFormRow } from './Row'
import { RequestFormRowReadOnly } from './PreviewRow'
import { RowDragDrop } from './RowDragDrop'
import s from './RequestFormRow.scss'
import { toCustomAttributes, toGroupedAttributes } from './serializers'

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '2px 5px',
  textAlign: 'center'
}

@connect(({ requestForm }) => {
  return {
    attributes: requestForm.attributes,
    chosenAttributes: requestForm.chosenAttributes,
    relationships: requestForm.relationships
  }
})
class RequestFormRowContainer extends Component {
  state = {
    filterVisible: false
  }
  onLabelChange = (attrIndex, value, relAttrIndex, relId) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_LABEL_VALUE,
      payload: {
        attrIndex,
        value,
        relAttrIndex,
        relId
      }
    })
  }
  onAttributeChange = (attrIndex, value, relAttrIndex) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_ATTRIBUTE_VALUE,
      payload: {
        attrIndex,
        value,
        relAttrIndex
      }
    })
  }
  onTypeChange = (attrIndex, value) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_TYPE_VALUE,
      payload: {
        attrIndex,
        value
      }
    })
  }
  onInfoChange = (attrIndex, value, relAttrIndex, relId) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_INFO_VALUE,
      payload: {
        attrIndex,
        value,
        relAttrIndex,
        relId
      }
    })
  }
  changeFormFieldValue = ({ attrIndex, value, property }) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CHANGE_FORM_FIELD_VALUE,
      payload: {
        attrIndex,
        value,
        property
      }
    })
  }
  removeItem = attrIndex => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_REMOVE_ITEM,
      payload: {
        attrIndex
      }
    })
  }
  getItemAttributeOptions = item => {
    const { attributes, chosenAttributes, relationships } = this.props
    const attrs = ['None', CONST.LEGAL_REQUEST_FIELDS.VENDOR]
    const availableAttributes = attributes.filter(
      attribute =>
        attrs.includes(attribute.value) ||
        item.model === attribute.value ||
        !chosenAttributes.includes(attribute.value)
    )

    const availableRelationships = relationships.filter(
      attribute => !chosenAttributes.includes(attribute.value)
    )
    if (relationships.length) {
      return [
        ...toGroupedAttributes(availableAttributes, 'Custom Attributes'),
        ...toGroupedAttributes(availableRelationships, 'Relationships')
      ]
    }
    return toCustomAttributes(availableAttributes)
  }
  toggleFilter = () => {
    this.setState({
      filterVisible: !this.state.filterVisible
    })
  }
  getItemAttributeValue = item => {
    const attributeValue = this.getItemAttributeOptions(item).filter(
      opt => item.model === opt.value
    )

    return attributeValue.length ? attributeValue[0].label : item.modelLabel
  }
  reorderItems = ({ formItem, fromIndex, toIndex }) => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_REORDER_ATTRIBUTE_ITEMS,
      payload: {
        fromIndex,
        toIndex,
        item: formItem
      }
    })
  }
  render() {
    const { item, displayRemove, attrIndex, readOnly, disabled, relationships } = this.props

    const relationship =
      item.fieldType === 'relationship' ? relationships.find(rel => rel.value === item.model) : null
    const relAttrReadOnly = !relationship && item.fieldType === 'relationship'

    const requestFormRow = (
      <RequestFormRow
        attributeOptions={this.getItemAttributeOptions(item)}
        item={item}
        onLabelChange={this.onLabelChange}
        onAttributeChange={this.onAttributeChange}
        onTypeChange={this.onTypeChange}
        onInfoChange={this.onInfoChange}
        attrIndex={attrIndex}
        changeFormFieldValue={this.changeFormFieldValue}
        removeItem={this.removeItem}
        displayRemove={displayRemove}
        toggleFilter={this.toggleFilter}
        filterVisible={this.state.filterVisible}
        disabled={disabled || relAttrReadOnly}
        reorderItems={this.reorderItems}
        formatGroupLabel={relationships.length ? formatGroupLabel : null}
        hasRelationships={!!relationships.length}
        relationshipAttributes={
          relationship?.attributes ||
          (item.fieldType === 'relationship'
            ? item.attributes.map(a => ({ ...a, label: a.modelLabel }))
            : [])
        }
        relId={relationship?.id}
      />
    )

    return readOnly ? (
      <RequestFormRowReadOnly attributeValue={this.getItemAttributeValue(item)} item={item} />
    ) : item.model === LEGAL_REQUEST_FIELDS.MATTER_NAME || disabled ? (
      requestFormRow
    ) : (
      <div className={s.draggable} data-testid={`request_form_row_${attrIndex}`}>
        <RowDragDrop>{requestFormRow}</RowDragDrop>
      </div>
    )
  }
}

export default RequestFormRowContainer
