import { ChangeEventHandler, useState } from 'react'
import AutoComplete from 'simple_review/common/AutoComplete'
import DropdownItem from 'simple_review/common/DropdownItem'
import { IoIosArrowBack } from 'react-icons/io'
import { useImmer } from 'use-immer'
import cloneDeep from 'lodash/cloneDeep'
import { CustomAttributeOption, CustomAttrRhs } from 'simple_review/@types/api'
import set from 'lodash/set'
import s from './CustomAttr.scss'
import { Button, useSyncStateWithProps } from 'simple-core-ui'
import { Select } from '../select'
import { Currency } from '../currency'
import { Constant } from 'simple_review/@types/common'
import { Percentage } from '../percentage'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { hasModule } from 'utils/helpers'

export type RhsValue = RhsConstant | CustomAttrRhs | null

interface RhsConstant {
  constant: Constant
  sub_type: string | null
  type?: string
}

interface Props {
  onChangeValue: (rhs: Constant, code?: string) => void
  onChangeCaValue: (rhs: CustomAttrRhs) => void
  value: RhsValue
  isPercentage: boolean
  isCurrency: boolean
  isReadOnly: boolean
}

interface RhsOption {
  name: string
  display_name: string
  fields?: number[]
}

export function CustomAttr({
  onChangeValue,
  onChangeCaValue,
  isPercentage,
  isCurrency,
  isReadOnly,
  ...props
}: Props) {
  const [state, setState] = useImmer({
    isOpen: false,
    search: ''
  })

  const { state: context } = useSimpleReviewContext()
  const { customAttributes } = context

  const options = [
    {
      name: '',
      display_name: 'Set Value',
      fields: []
    },
    {
      name: '',
      display_name: 'Set Custom Attribute',
      fields: []
    }
  ]

  let initialSelectedOption
  let initialLocalValue
  let initialLocalCaValue
  let initialLocalCurrValue

  if (props.value && 'constant' in props.value && props.value.type !== 'currency') {
    initialSelectedOption = options[0]
    initialLocalValue = props.value.constant.value
  }

  if (props.value && 'attr_id' in props.value) {
    initialSelectedOption = options[1]
    const attr = customAttributes.find(ca => {
      return props.value && 'attr_id' in props?.value && ca.value == props.value?.attr_id
    })
    initialLocalCaValue = attr
  }

  if (props.value && 'constant' in props.value && props.value.type === 'currency') {
    initialSelectedOption = options[0]
    initialLocalCurrValue = cloneDeep(props.value)
  }

  const [selectedOption, setSelectedOption] = useState<RhsOption | undefined | null>(
    initialSelectedOption
  )

  const [localValue, setLocalValue] = useSyncStateWithProps<string | undefined>(
    initialLocalValue ?? ''
  )

  const [localCaValue, setLocalCaValue] = useSyncStateWithProps<CustomAttributeOption | null>(
    initialLocalCaValue ?? null
  )

  const [localCurrValue, setLocalCurrValue] = useSyncStateWithProps<RhsConstant | null>(
    initialLocalCurrValue ?? null
  )

  const closeBox = () => {
    setState(draft => {
      draft.isOpen = false
      draft.search = ''
    })
  }

  const openBox = () => {
    setState(draft => {
      draft.isOpen = true
    })
  }

  const onSelect = (newLhsValue: RhsOption) => () => {
    const newLhs = cloneDeep(newLhsValue)
    setSelectedOption(newLhs)
  }

  const getValue = () => {
    if (!props.value) {
      return ''
    }

    if ('attr_id' in props?.value) {
      const attr = customAttributes.find(ca => {
        return props.value && 'attr_id' in props?.value && ca.value == props.value?.attr_id
      })
      return attr?.label
    } else if (props?.value.type === 'currency') {
      return `${props?.value.sub_type} ${props.value?.constant?.label}`
    } else {
      return props.value?.constant?.label
    }
  }

  const onJumpBack = () => {
    setSelectedOption(null)
  }

  const onSearch = (path: string): ChangeEventHandler<HTMLInputElement> => e => {
    e.preventDefault()
    setState(draft => {
      set(draft, path, e.target.value)
    })
  }

  const submitSetValue = () => {
    const constant = { value: localValue || '', label: localValue || '' }
    onChangeValue(constant)
    setLocalCaValue(null)
    setLocalCurrValue(null)
    closeBox()
  }

  const submitSetCurrValue = () => {
    if (!localCurrValue) {
      closeBox()
      return
    }

    onChangeValue(localCurrValue.constant, localCurrValue.sub_type || undefined)
    setLocalCaValue(null)
    setLocalValue(undefined)
    closeBox()
  }

  const submitSetCaValue = () => {
    if (!localCaValue) {
      closeBox()
      return
    }

    const model = localCaValue.model

    const rhs = {
      attr_id: String(localCaValue.value),
      model_name: model
    }

    onChangeCaValue(rhs)
    setLocalValue(undefined)
    setLocalCurrValue(null)
    closeBox()
  }

  function renderCurrency() {
    const code = localCurrValue?.sub_type || ''
    const constant = localCurrValue?.constant || { value: '', label: '' }
    return (
      <Currency
        isPortal={false}
        isReadOnly={isReadOnly}
        code={code}
        amount={constant}
        onChange={(v: Constant, newCode: string) => {
          setLocalCurrValue({ constant: v, sub_type: newCode })
        }}
      />
    )
  }

  return (
    <div>
      <AutoComplete
        isOpen={state.isOpen}
        isReadOnly={isReadOnly}
        openBox={openBox}
        closeBox={closeBox}
        value={getValue()}
        onChange={onSearch('search')}
        disableSearch
        width={240}
      >
        <>
          {selectedOption && (
            <div className={s.parentCondition}>
              <div data-testid="go-back" className={s.goBack} onClick={onJumpBack}>
                <IoIosArrowBack />
                <span>{selectedOption?.display_name}</span>
              </div>
            </div>
          )}
          {!selectedOption && (
            <ul className={s.options}>
              {options
                .filter(o => {
                  // we don't want to allow Custom Attribute option for numeric types
                  // if user doesnt have experimental custom attribute types
                  return (
                    o.display_name !== 'Set Custom Attribute' ||
                    (hasModule('has_exp_custom_attribute_types') && isPercentage) ||
                    !isPercentage
                  )
                })
                .map((option, index) => (
                  <li key={index}>
                    <DropdownItem
                      label={option.display_name}
                      onSelect={onSelect(option)}
                      showArrow={!!option.fields}
                    />
                  </li>
                ))}
            </ul>
          )}
          {selectedOption?.display_name === 'Set Value' && (
            <div className={s.inputContainer}>
              {isPercentage ? (
                <>
                  <Percentage
                    value={{ value: localValue ?? '', label: localValue ?? '' }}
                    onChange={(v: Constant) => setLocalValue(v?.value)}
                    autoWidth={false}
                  />
                  <Button isSecondary onClick={submitSetValue}>
                    Set Value
                  </Button>
                </>
              ) : isCurrency ? (
                <>
                  {renderCurrency()}
                  <Button isSecondary onClick={submitSetCurrValue}>
                    Set Value
                  </Button>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    className={s.subConditionSearch}
                    placeholder={`Enter Attribute`}
                    value={localValue}
                    onChange={e => setLocalValue(e.target.value)}
                  />
                  <Button isSecondary onClick={submitSetValue}>
                    Set Value
                  </Button>
                </>
              )}
            </div>
          )}

          {selectedOption?.display_name === 'Set Custom Attribute' && (
            <div className={s.inputContainer}>
              <Select
                value={localCaValue}
                onChange={(value: CustomAttributeOption) => {
                  setLocalCaValue(value)
                }}
                choices={`/client/rules/attributes/?type=${
                  isPercentage ? 'percentage' : isCurrency ? 'currency' : 'text'
                }`}
                serializer={(response: Constant[]) => {
                  return response.map(o => {
                    return {
                      ...o,
                      model:
                        o.model === 'vendors'
                          ? 'vendor'
                          : o.model === 'matters'
                          ? 'matter'
                          : o.model
                    }
                  })
                }}
              />
              <Button isSecondary onClick={submitSetCaValue}>
                Set Value
              </Button>
            </div>
          )}
        </>
      </AutoComplete>
    </div>
  )
}
