import s from './ChartInfo.scss'
import { filesize } from 'filesize'
import cn from 'classnames'
import { CustomFile } from 'bulk/types'

interface Props {
  percent: number
  file: CustomFile
  error: boolean
  completed: boolean
}

const ChartInfo = ({ percent, file, error, completed }: Props) => {
  return (
    <div className={s.chartInfo}>
      <div
        className={cn(s.progressInfo, {
          [s.completed]: percent === 100 && !error && completed,
          [s.error]: error
        })}
      >
        {error
          ? 'Failed'
          : completed
          ? 'Complete'
          : percent === 100
          ? 'Processing...'
          : `${percent}% Complete`}
      </div>
      <div className={s.fileName}>{file.name}</div>
      <div className={s.fileSize}>{filesize(file.size) as string}</div>
    </div>
  )
}

export default ChartInfo
