import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import ACT from '../actions'
import { Badge, withConfirmation } from 'simple-core-ui'

const TAB_CONF = {
  title: 'List of matters selected will be lost.',
  text: 'Do you wish to continue?',
  buttons: ['Cancel', 'Yes'],
  icon: 'warning'
}

const Tabs = ({ selectedTab, setSelectedTab, hasConfirmation }) => {
  const statusCounts = useSelector(state => state.matters.matterSummary)
  const params = useSelector(state => state.matters.matterListParams)

  const dispatch = useDispatch()
  const tabs = useMemo(() => {
    return [
      {
        label: 'My Matters',
        value: 'mine',
        count: statusCounts['mine'].total || null
      },
      {
        label: 'All',
        value: 'all',
        count: statusCounts['all'].total || null
      }
    ]
  }, [statusCounts])

  const selectTab = value => {
    const selectCb = () => {
      setSelectedTab(value)
      if (value === 'all' && params.search.filter_by) delete params.search.filter_by
      if (value === 'mine') {
        params.search.filter_by = +window.credentials.user.id
      }
      dispatch({
        type: ACT.MATTER_LIST_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: {
          status: value,
          params: {
            ...params,
            page: 1
          }
        }
      })
    }
    hasConfirmation
      ? withConfirmation(() => {
          selectCb()
        }, TAB_CONF)()
      : selectCb()
  }

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => selectTab(tab.value)}
          key={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
          data-testid={`tab-${tab.value}`}
        >
          {tab.count !== null ? (
            tab.value === 'mine' ? (
              <Badge
                asPill
                className={`${s.pill} ${selectedTab !== 'mine' && s.transparent}`}
                content={tab.count}
                size="sm"
              />
            ) : (
              <span className={s.count}>{tab.count}</span>
            )
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
