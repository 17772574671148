import { set, startOfMinute, getHours, getMinutes, parseISO } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import qs from 'query-string'

export const getFirstEntry = (count: number, pages: number): number => count * (pages - 1) + 1

export const getLastEntry = (count: number, pages: number, total: number): number =>
  count * pages - (count - total)

export const toObject = (
  arr: Array<string>,
  fn: (a: unknown) => Record<string, unknown>
): Record<string, unknown> => {
  const result = {} as Record<string, unknown>
  for (let i = 0; i < arr.length; i++) {
    result[arr[i]] = fn(arr[i])
  }
  return result
}

export type AnyObject = Record<string | number | symbol, unknown>

export type HasAtLeastOneStringValue =
  | Record<string | number | symbol, string>
  | Record<string | number | symbol, any>

const getStringProperty = <T, K extends keyof T>(someObject: T, property: K): string => {
  const value: unknown = someObject[property]
  if (typeof value !== 'string') {
    throw Error(`The value of the object's ${property.toString()} property is not of type string.`)
  }
  return value
}
/**
 *
 * @param array A homogeneous list with elements of type T.
 * @param key A property of the type T, whose value is a string.
 * @returns The list, sorted alphabetically according to the value each object had for the designated property.
 */
export const sortAlphabeticallyByProperty = <T extends HasAtLeastOneStringValue, K extends keyof T>(
  array: T[],
  key: K
): T[] => {
  const byPropertyValue = (property: K): ((left: T, right: T) => number) => {
    return (leftElement: T, rightElement: T) => {
      return getStringProperty(leftElement, property)
        .toLocaleLowerCase()
        .localeCompare(getStringProperty(rightElement, property).toLocaleLowerCase())
    }
  }

  return [...array].sort(byPropertyValue(key))
}

export const move = (arr: Array<unknown>, oldIndex: number, newIndex: number): Array<unknown> => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

export const createPageTitle = ({
  client,
  view,
  detail,
  baseApp = 'SimpleLegal'
}: {
  client: string
  view: string
  detail: string
  baseApp: string
}): string => {
  const addPrefix = (prefix: string, base: string) => (prefix ? `${prefix} · ${base}` : base)
  return addPrefix(detail, addPrefix(view, addPrefix(client, baseApp)))
}

// Returns boolean indicating whether an object has changed from the
// original form. This is a flat check and can be used to diff to
// prevent unnecessary updates.
//
// i.e
//    hasChanged({a: 1}, {a: 2}) -> true
//    hasChanged({a: 1}, {a: 1}) -> false
export const hasChanged = (
  original: { [k: string]: unknown },
  updated: { [y: string]: unknown }
): boolean =>
  Object.keys(updated).reduce((acc, key) => acc || updated[key] !== original[key], false)

// Returns an object with all the fields that are of type string
// properly trimmed. This is meant to provide some sanitization. This
// operation only applies to flat objects.
export const trimFields = (fields: AnyObject): AnyObject =>
  Object.keys(fields).reduce(
    (acc, key) => ({
      ...acc,
      [key]: typeof fields[key] === 'string' ? (fields[key] as string).trim() : fields[key]
    }),
    {}
  )

export const combineCssModules = (arr: Array<unknown>): string => arr.join(' ').trim()

export const select2ToReactSelect = ({
  results
}: {
  results: Array<{ text: string; id: string | number }>
}): {
  options: Array<{ label: string; value: string | number }>
} => ({
  options: results.map(({ text, id }) => ({
    label: text,
    value: id
  }))
})

export const moveToFrontOfArray = (
  array: Array<AnyObject>,
  key: string,
  value: string | boolean = true
): Array<AnyObject> => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      array = array.splice(i, 1).concat(array)
    }
  }

  return array
}

export const containsObject = (obj: { value: unknown }, list: Array<{ value: unknown }>): boolean =>
  list.some(l => l.value === obj.value)

export const isNumberRenderable = (num: number): boolean => {
  return !isNaN(num) && isFinite(num)
}

export const isArrayWithLength = (arr: unknown): boolean => Array.isArray(arr) && !!arr.length

export const encodeText = (text: string): string => encodeURIComponent(text).replace(/%20/g, '+')

export const uriEncodeObject = (obj: {
  [k: keyof AnyObject]: string | number | boolean
}): string => {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

export const hasModule = (module: string): boolean =>
  (window as Window).credentials.modules.includes(module)

export const updateLinkParam = (param: Record<string, string>): void => {
  const { search, pathname } = window.location
  const queryParams = new URLSearchParams(search.slice(1))

  param.value ? queryParams.set(param.key, param.value) : queryParams.delete(param.key)

  window.history.replaceState(null, '', `${pathname}?${String(queryParams)}`)
}

export const deleteLinkParam = (key: string): void => {
  const { search, pathname } = window.location
  const queryParams = new URLSearchParams(search.slice(1))

  if (queryParams.has(key)) queryParams.delete(key)

  window.history.replaceState(null, '', `${pathname}?${String(queryParams)}`)
}

export const alphabeticObjectCompare = <T extends HasAtLeastOneStringValue, K extends keyof T>(
  firstValue: T,
  secondValue: T,
  propertyName: K
): 1 | 0 | -1 => {
  if (firstValue[propertyName] > secondValue[propertyName]) {
    return 1
  }
  if (firstValue[propertyName] < secondValue[propertyName]) {
    return -1
  }
  return 0
}

export const parseErrorMessage = (
  responseData?:
    | string[]
    | {
        error?: string | string[]
        errors?: Record<string, string[]> | { [key: string]: { [innerKey: string]: string[] }[] }
      }
): string => {
  const result: string[] = []

  if (Array.isArray(responseData)) {
    return responseData.join(' ')
  } else if (responseData?.error) {
    return Array.isArray(responseData.error) ? responseData.error.join(' ') : responseData.error
  } else if (responseData?.errors) {
    const errors = responseData.errors

    for (const property in errors as
      | Record<string, string[]>
      | { [key: string]: { [innerKey: string]: string[] }[] }) {
      const propertyErrors = errors[property]

      if (Array.isArray(propertyErrors)) {
        propertyErrors.forEach(errorArrayOrObject => {
          if (typeof errorArrayOrObject === 'object' && errorArrayOrObject !== null) {
            for (const key in errorArrayOrObject) {
              const keyErrors = errorArrayOrObject[key]
              if (Array.isArray(keyErrors)) {
                keyErrors.forEach(error => {
                  result.push(`${property}: ${key}: ${error}`)
                })
              }
            }
          } else if (Array.isArray(errorArrayOrObject)) {
            errorArrayOrObject.forEach(innerError => {
              for (const key in innerError) {
                const innerKeyErrors = innerError[key]
                if (Array.isArray(innerKeyErrors)) {
                  innerKeyErrors.forEach(error => {
                    result.push(`${property}: ${key}: ${error}`)
                  })
                }
              }
            })
          } else if (typeof errorArrayOrObject === 'string') {
            result.push(`${property}: ${errorArrayOrObject}`)
          }
        })
      }
    }

    return result.join('\n')
  }

  return result.join(' ')
}

export const openLink = (link: string): void => {
  const a = document.createElement('a')
  a.setAttribute('href', link)
  a.setAttribute('target', '_blank')
  a.click()
  a.remove()
}

export const openBlob = (data: Blob | MediaSource, fileName: string): void => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  link.download = fileName
  link.click()
}

export const getFileNameExtension = (fileName: string): { fileName: string; extension: string } => {
  const expressionResults = /^(.*?)(\.[^.]*)?$/.exec(fileName)
  if (!expressionResults) {
    throw new Error(
      `Unable to parse file name and extension from file '${fileName}'. Verify the format is correct and try again.`
    )
  }
  const [, name, extension] = expressionResults

  return {
    fileName: name,
    extension: extension.toLowerCase()
  }
}

export const hex2rgba = (hex: string, alpha = 1): string => {
  const [r, g, b] = hex?.match(/\w\w/g)?.map(x => parseInt(x, 16)) ?? [0, 0, 0]
  return `rgba(${r},${g},${b},${alpha})`
}

type ObjectWithId = {
  id: string | number
} & {
  [key: string]: unknown
}

export const checkByNameIfInArray = (
  entity: string,
  array: Array<{ [key: string]: unknown }>,
  exclude?: ObjectWithId
): boolean => {
  const normalizedEntity = entity?.trim().toLocaleLowerCase()
  if (exclude) {
    return array.some(
      p =>
        typeof p.name === 'string' &&
        p.name.trim().toLocaleLowerCase() === normalizedEntity &&
        p.id !== exclude.id
    )
  }

  return array.some(
    p => typeof p.name === 'string' && p.name.trim().toLocaleLowerCase() === normalizedEntity
  )
}

export const formatName = (
  user:
    | {
        first_name: string
        last_name: string
      }
    | undefined
    | null
): string => {
  return user ? `${user.first_name} ${user.last_name}` : ''
}

export const capitalizeSentence = (sentence: string): string => {
  return sentence
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const isBasicTaskManagementPlan = () => {
  const matterManagementPlan: 'M1' | 'M2' = window.credentials.matterManagementPlan
  return matterManagementPlan !== 'M2'
}

export const isNoneMatterManagementPlan = () => {
  const matterManagementPlan: 'M1' | 'M2' = window.credentials.matterManagementPlan
  return matterManagementPlan !== 'M1' && matterManagementPlan !== 'M2'
}

export const isBasicSpendPlan = () => {
  const systemPlan: 'professional' | 'enterprise' | 'basic' = window.credentials.systemPlan
  return !['professional', 'enterprise'].includes(systemPlan)
}

export const isUserAdmin = () => {
  return window.credentials?.user?.role === 'admin'
}

export const timezoneUtils = {
  getFormattedElement(timeZone: string, name: string, value: string) {
    return (
      new Intl.DateTimeFormat('en', {
        [name]: value,
        timeZone
      })
        .formatToParts()
        .find(el => el.type === name) || {}
    ).value
  },
  getAbbreviation(timeZone: string) {
    return timezoneUtils.getFormattedElement(timeZone, 'timeZoneName', 'short')
  },
  getOffset(timeZone: string) {
    return timezoneUtils.getFormattedElement(timeZone, 'timeZoneName', 'shortOffset')
  },
  getTimezonesOptions() {
    // @ts-expect-error
    if (!Intl.supportedValuesOf) return []

    // @ts-expect-error
    return Intl.supportedValuesOf('timeZone').map(timeZone => ({
      value: timeZone,
      label: timeZone.replace(/_/g, ' '),
      abbr: timezoneUtils.getAbbreviation(timeZone),
      offSet: timezoneUtils.getOffset(timeZone)
    }))
  },
  getUserTimezone() {
    return ['None', ''].includes(window.credentials.user?.timezone)
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : window.credentials.user?.timezone
  },
  getUserTimezoneLabel() {
    return ['None', ''].includes(window.credentials.user?.timezone)
      ? Intl.DateTimeFormat()
          .resolvedOptions()
          .timeZone?.replace(/_/g, ' ')
      : window.credentials.user?.timezone?.replace(/_/g, ' ')
  }
}

export const utcDate = (date: Date, timezone?: string): string => {
  const targetTimeZone = timezone || timezoneUtils.getUserTimezone()

  return zonedTimeToUtc(date, targetTimeZone).toISOString()
}

export const utcTime = (timeString: string): string => {
  const today = new Date()
  const [hours, minutes] = timeString.split(':').map(Number)
  const timeDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes)

  const timeZone = timezoneUtils.getUserTimezone()

  const utcTime = zonedTimeToUtc(timeDate, timeZone).toISOString()

  return format(parseISO(utcTime.split('Z')[0]), 'HH:mm')
}

export const timezoneDate = (utcDateString: string, timezone?: string): Date => {
  const targetTimeZone = timezone || timezoneUtils.getUserTimezone()

  const utcDate = parseISO(utcDateString)
  return utcToZonedTime(utcDate, targetTimeZone)
}

export const combineDateAndTimeString = (dateObj: Date, timeString: string | null) => {
  let newDateObj = new Date(dateObj)

  if (timeString) {
    const [hours, minutes] = timeString.split(':').map(Number)

    return set(dateObj, {
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0
    })
  } else {
    const currentHour = getHours(new Date())
    const currentMinute = getMinutes(new Date())

    newDateObj = set(newDateObj, {
      hours: currentHour,
      minutes: currentMinute,
      seconds: 0,
      milliseconds: 0
    })

    return startOfMinute(newDateObj)
  }
}

export const updateUrlFragment = (fragment: string, url?: string): string => {
  const originalUrl = (url ?? window.location.href).split('#')[0]

  return `${originalUrl}#${fragment}`
}

export const removeHashFromUrl = (url?: string) => {
  return (url ?? window.location.href).split('#')[0]
}

export const removeLabelsFromURL = (url: string) => {
  url = url.replace(/;[^,;&]*(?=,|&)/g, '')

  // Remove any trailing ';' and what comes after the last ';'
  url = url.replace(/;[^;]*$/, '')

  return url
}

export const deleteQueryParam = (param: string) => {
  const currentParams = qs.parse(window.location.search)

  delete currentParams[param]

  const newQueryString = qs.stringify(currentParams)

  return `${window.location.pathname}?${newQueryString}`
}

export const getUrlWithSubtab = (subtab: string, removeHash?: boolean) => {
  const currentParams = qs.parse(window.location.search)

  const newParams = {
    ...currentParams,
    subtab: subtab
  }

  const newQueryString = qs.stringify(newParams)

  if (removeHash) {
    return `${removeHashFromUrl().split('?')[0]}?${newQueryString}`
  }
  return `${window.location.pathname}?${newQueryString}`
}

export const isLink = (name: string): boolean => {
  const pattern = new RegExp(
    '^https?:\\/\\/' + // protocol is mandatory
    '(?:[a-z\\d-]{1,63}\\.)*[a-z\\d-]{2,63}\\.[a-z]{2,6}' + // domain name
    '(?::\\d{1,5})?' + // optional port
      '(?:[/?#]\\S*)?$', // optional path, query string, and fragment
    'i'
  )
  return pattern.test(name)
}
