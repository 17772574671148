export const isFolder = id => typeof id === 'string' && id.includes('folder_')

export const getFolderId = folderId => folderId.split('_')[1]

export const preventDefaultCallback = (e, cb) => {
  e.stopPropagation()
  e.preventDefault()
  cb && cb()
}

export const getAttachmentLabels = (attachments, id) => {
  const attachment = attachments.find(a => a.id === id)
  return (attachment && attachment.labels) || []
}

export const getFileStatus = (attachments, id) => {
  const attachment = attachments.find(a => a.id === id)
  return attachment.fileStatus
}

export const getFileEmailId = (attachments, id) => {
  const attachment = attachments.find(a => a.id === id)
  return attachment.email_id
}

export const getFileSize = (attachments, id) => {
  const attachment = attachments.find(a => a.id === id)
  return attachment?.size
}

export const getFolderType = (folders, id) => {
  const folder = folders.find(a => a.id === id)
  return folder ? folder.directory_type : ''
}

// we chose the standard Megabyte = 1024^2 instead of 1000^2
export const bitesToMb = size => size / 1024 / 1024
