import { useState } from 'react'
import { Operator as OperatorType } from 'simple_review/@types/api'
import AutoComplete from 'simple_review/common/AutoComplete'
import DropdownItem from 'simple_review/common/DropdownItem'
import s from './Operator.scss'

interface Props {
  value: OperatorType | null
  operators: Array<OperatorType>
  isReadOnly: boolean
  onChangeOperator(newOperator: OperatorType): void
}

export const Operator = ({ value, operators, isReadOnly, onChangeOperator }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const openBox = () => {
    setIsOpen(true)
  }

  const closeBox = () => {
    setIsOpen(false)
  }

  const onSelect = (newOperator: OperatorType) => () => {
    onChangeOperator(newOperator)
    closeBox()
  }

  return (
    <AutoComplete
      isOpen={isOpen}
      isReadOnly={isReadOnly}
      openBox={openBox}
      closeBox={closeBox}
      value={value?.label || ''}
      disableSearch
      placeholder="Select an option"
      testid="operator"
    >
      <ul className={s.options}>
        {operators.map(operator => (
          <li key={operator.label}>
            <DropdownItem label={operator.label} onSelect={onSelect(operator)} showArrow={false} />
          </li>
        ))}
      </ul>
    </AutoComplete>
  )
}
