import { Fragment } from 'react'
import { put, takeLatest, call, select } from 'redux-saga/effects'

import { makeGetRequest, makePostRequest } from 'utils/api'

import APP_ACT from 'app/actions'
import ACT from './actions'

import {
  CREATE_CLIENT_KEY_URL,
  CLIENT_KEY_INFO_URL,
  CLIENT_KEY_UPDATE_URL,
  MANAGE_CLIENT_KEYS_URL,
  CLIENT_KEY_DELETE_URL
} from './urls'

function* fetchClientKeys(action) {
  yield put({ type: ACT.CLIENT_KEYS_IS_LOADING })

  try {
    const response = yield call(makeGetRequest, MANAGE_CLIENT_KEYS_URL)

    yield put({
      type: ACT.CLIENT_KEYS_FETCH_SUCCESS,
      payload: response.client_keys
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: e.stack,
        level: 'error'
      }
    })
  }
}

function* showForUpdate(action) {
  const getClientKeys = ({ clientKeysApp: { clientKeys } }) => clientKeys
  const clientKeys = yield select(getClientKeys)
  const existingClientKey = clientKeys.find(({ id }) => id === action.payload)

  if (existingClientKey) {
    yield put({ type: ACT.CLIENT_KEY_SHOW_FOR_UPDATE_SUCCESS, payload: existingClientKey })
  } else {
    try {
      yield put({
        type: ACT.CLIENT_KEYS_IS_LOADING,
        loadingLock: 'on'
      })
      const apiClientKey = yield call(makeGetRequest, CLIENT_KEY_INFO_URL(action.payload))
      yield put({
        type: ACT.CLIENT_KEY_SHOW_FOR_UPDATE_SUCCESS,
        loadingLock: 'off',
        payload: apiClientKey
      })
    } catch (e) {
      console.warn('ClientKey does not exist...')
      yield put({
        type: ACT.CLIENT_KEYS_IS_NOT_LOADING,
        loadingLock: 'off'
      })
    }
  }
}

function* createClientKey(action) {
  try {
    yield put({
      type: ACT.CLIENT_KEYS_IS_LOADING,
      loadingLock: 'on'
    })

    const apiClientKey = yield call(makePostRequest, CREATE_CLIENT_KEY_URL, action.payload)

    const client_key = apiClientKey

    yield put({
      type: ACT.CLIENT_KEY_CREATE_SUCCESS,
      loadingLock: 'off',
      payload: client_key
    })

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'ClientKey Successful Created',
        message: (
          <Fragment>
            <strong>{client_key.type}</strong> has been add to the list of client_keys in your
            organization.
          </Fragment>
        ),
        level: 'success'
      }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: (
          <Fragment>
            There was an issue creating <strong>{action.payload.type}</strong>.
          </Fragment>
        ),
        level: 'error'
      }
    })

    yield put({ type: ACT.CLIENT_KEYS_IS_NOT_LOADING })
  }
}

function* updateClientKey(action) {
  try {
    yield makePostRequest(CLIENT_KEY_UPDATE_URL(action.payload.id), action.payload)

    yield put({
      type: ACT.CLIENT_KEY_UPDATE_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: (
          <Fragment>
            There was an issue updating <strong>{action.payload.type}</strong>.
          </Fragment>
        ),
        level: 'error'
      }
    })
  }
}

function* deleteClientKey(action) {
  try {
    makePostRequest(CLIENT_KEY_DELETE_URL(action.payload))

    yield put({
      type: ACT.CLIENT_KEY_DELETE_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue when attempting to delete a client_key.',
        level: 'error'
      }
    })
  }
}

const clientKeysSagas = [
  takeLatest(ACT.CLIENT_KEYS_FETCH_REQUESTED, fetchClientKeys),
  takeLatest(ACT.CLIENT_KEY_SHOW_FOR_UPDATE_REQUESTED, showForUpdate),
  takeLatest(ACT.CLIENT_KEY_CREATE_REQUESTED, createClientKey),
  takeLatest(ACT.CLIENT_KEY_UPDATE_REQUESTED, updateClientKey),
  takeLatest(ACT.CLIENT_KEY_DELETE_REQUESTED, deleteClientKey)
]

export default clientKeysSagas
