import s from './ModalField.scss'

const ModalField = ({ label, subtitle, children }) => {
  return (
    <div className={s.row}>
      {label && <label>{label}</label>}
      {subtitle && <h4 className={s.subtitle}>{subtitle}</h4>}
      {children}
    </div>
  )
}

export default ModalField
