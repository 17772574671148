import { Component } from 'react'
import s from '../styles/HomeDashboard.scss'
import moment from 'moment'
import { Tooltip } from 'simple-core-ui'

class DashboardItem extends Component {
  renderDate = date => {
    const daysAgo = moment().diff(moment(date), 'days')
    const isRecent = Number(daysAgo) < 5
    const { dateLabel } = this.props

    if (daysAgo === 0) {
      return `${dateLabel} Today`
    } else if (daysAgo === 1) {
      return `${dateLabel} Yesterday`
    }

    return isRecent
      ? `${dateLabel} ${daysAgo} days ago`
      : `${dateLabel} on ${moment(date).format('MM/DD/YYYY')}`
  }

  renderDueDate = date => {
    const daysUntil = moment(date).diff(moment(), 'days')
    const isDueDateSoon = Number(date) < 5

    if (daysUntil === 0) {
      return 'Due Today'
    } else if (daysUntil === 1) {
      return 'Due Tomorrow'
    }

    return isDueDateSoon ? `Due in ${daysUntil}` : `Due on ${moment(date).format('MM/DD/YYYY')}`
  }

  render() {
    const {
      testid,
      title,
      secondaryTitle,
      subTitle,
      secondarySubTitle,
      date,
      dueDate,
      flags
    } = this.props

    return (
      <a className="no-link" href={this.props.url}>
        <section className={s.item} data-testid={testid}>
          <section>
            <div className={s.header}>
              {title} {secondaryTitle && `· ${secondaryTitle}`}
            </div>
            <div className={s.subHeader}>
              {subTitle} {secondarySubTitle && `· ${secondarySubTitle}`}
            </div>
          </section>
          <section className={s.rhs}>
            {flags && (
              <Tooltip
                trigger={<i className={'icon-warning-sign'} />}
                triggerClassName={s.invoiceFlag}
                content={flags}
              />
            )}
            {dueDate && <div className={s.date}>{this.renderDueDate(dueDate)}</div>}
            {date && <div className={s.date}>{this.renderDate(date)}</div>}
          </section>
        </section>
      </a>
    )
  }
}

export default DashboardItem
