import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Row } from '../types'
import s from './ActionsPopover.scss'

type FolderAction = (rowId: Row['id'], name: string, depth?: number) => void

interface Props {
  rowId: Row['id']
  folderName: string
  rowDepth: number
  editFolder: FolderAction
  deleteFolder: FolderAction
  moveFolder: FolderAction
}
const ActionsPopover = ({
  rowId,
  folderName,
  rowDepth,
  editFolder,
  deleteFolder,
  moveFolder
}: Props) => {
  const actions = [
    {
      name: 'RENAME',
      text: 'Rename',
      onClick() {
        editFolder(rowId, folderName, rowDepth)
      }
    },
    {
      name: 'MOVE',
      text: 'Move',
      onClick() {
        moveFolder(rowId, folderName)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteFolder(rowId, folderName)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
