import update from 'immutability-helper'
import find from 'lodash/find'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import findIndex from 'lodash/findIndex'
import partial from 'lodash/partial'
import isNull from 'lodash/isNull'
import has from 'lodash/has'
import fromPairs from 'lodash/fromPairs'
import { UNARY_OPERATORS } from 'data/operators'
import { makeGetRequest } from 'utils/api'
import { MODULE, ORG_ROLE } from 'utils/constants'
import { hasModule } from 'utils/helpers'

import {
  ACTION_TYPE,
  PARAM_SUBTYPE,
  PARAM_ARGNAME,
  PARAM_ARG,
  ENGINE,
  BOOLEAN_OPERATORS,
  LHS_TYPES
} from 'rules/constants'

export const isActionInvalid = (actions = []) => {
  if (!actions.length) {
    return false
  }

  return actions.some(action => {
    return (
      action.params &&
      // ReviewerConfig values exist in a separate object and are not required
      action.params.some(
        param => !get(param, 'value.value') && param.sub_type !== PARAM_SUBTYPE.REVIEWER_CONFIG
      )
    )
  })
}

export const isRuleComplete = condition => {
  if (isNull(condition)) return true

  if (condition && condition.operands) {
    for (let i = 0; i < condition.operands.length; i++) {
      const o = condition.operands[i]
      if (o.operands) {
        return isRuleComplete(o)
      }

      const isUnary = find(UNARY_OPERATORS, op => o.op === op)

      if (isEmpty(o.lhs) || !o.op || (!isUnary && isEmpty(o.rhs))) {
        return false
      }
    }
    return true
  }
}

export const getLoadReferenceCb = choices => {
  return typeof choices === 'string'
    ? search =>
        !search
          ? Promise.resolve({ options: [] }) // We don't want to search on empty
          : makeGetRequest(`/react_select`, {
              params: { model: choices, search, rhs_meta: true }
            })
              .then(response => {
                return {
                  options: response.options.map(option => ({
                    ...option,
                    display_name: option.label,
                    constant: option.value
                  }))
                }
              })
              .then(response => response)
    : null
}

export const convertToTags = value => {
  const deepValue = get(value, 'value[0]', '')

  if (
    value &&
    !value.length &&
    typeof deepValue === 'string' &&
    deepValue &&
    value.label.includes(', ')
  ) {
    return value.label.split(', ').map(v => ({ label: v, value: v }))
  }

  return get(value, 'value', '')
}

export const downloadTextFile = (filename, text) => {
  let element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const isUnconditional = condition => condition === null

export const hasReviewerConfig = actions =>
  actions.some(({ params }) =>
    params.some(({ sub_type }) => sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG)
  )

export const hasExistingReviewerConfig = actions =>
  actions.some(({ params }) =>
    params.some(
      ({ sub_type, value }) => sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG && get(value, 'value')
    )
  )

export const hasExistingWithoutReviewerConfig = actions =>
  actions.some(({ params }) =>
    params.some(({ param_name, value }) => param_name === 'custom_reviewers' && get(value, 'value'))
  )

export const hasNewReviewerConfig = actions =>
  actions.some(({ params }) =>
    params.some(
      ({ sub_type, value }) => sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG && !get(value, 'value')
    )
  )

export const getCustomReviewersActionIndex = rule =>
  findIndex(rule.actions, ({ name }) => name === ACTION_TYPE.CUSTOM_REVIEWERS)

export const getReviewersActionIndex = rule =>
  findIndex(rule.actions, action => {
    const name = action.name || ''
    return name.startsWith('add_') && name.endsWith('_reviewers')
  })

export const getCustomReviewersParamIndex = (rule, actionIdx) =>
  findIndex(
    rule.actions[actionIdx].params,
    ({ sub_type }) => sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG
  )

export const getCustomReviewersParamIndexByArgName = (rule, actionIdx) =>
  findIndex(
    rule.actions[actionIdx].params,
    ({ param_name }) => param_name === PARAM_ARGNAME.PARAM_NAME
  )

export const getRulePriorityParamIndex = (rule, actionIdx) =>
  findIndex(
    rule.actions[actionIdx].params,
    ({ param_name }) => param_name === PARAM_ARG.LADDER_PRIORITY
  )

export const getRulePriority = rule => {
  const actionIdx = getReviewersActionIndex(rule)
  if (actionIdx !== -1) {
    const paramIdx = getRulePriorityParamIndex(rule, actionIdx)
    return Number(get(rule, ['actions', actionIdx, 'params', paramIdx, 'value', 'value'], 0))
  } else {
    return 0
  }
}

export const patchWithReviewerConfigId = (rule, reviewerConfigId) => {
  const actionIdx = getCustomReviewersActionIndex(rule)
  const paramIdx = getCustomReviewersParamIndexByArgName(rule, actionIdx)

  const value = reviewerConfigId !== null ? String(reviewerConfigId) : null

  return update(rule, {
    actions: {
      [actionIdx]: {
        params: {
          [paramIdx]: {
            value: { $set: { value, label: value } }
          }
        }
      }
    }
  })
}

export const getReviewerConfigIdFromRule = rule => {
  const actionIdx = getCustomReviewersActionIndex(rule)

  if (actionIdx === -1) return null

  const paramIdx = getCustomReviewersParamIndexByArgName(rule, actionIdx)
  return get(rule, ['actions', actionIdx, 'params', paramIdx, 'value', 'value'], null)
}

export const getRulePriorityMap = rules =>
  get(rules, 'length') ? fromPairs(rules.map(rule => [rule.id, getRulePriority(rule)])) : {}

export const getRuleIndexMap = rules =>
  get(rules, 'length') ? fromPairs(rules.map(({ id }, idx) => [id, idx])) : {}

export const getRuleNameMap = rules =>
  get(rules, 'length') ? fromPairs(rules.map(({ id, name }) => [id, name])) : {}

export const sortRules = (rules, rulePriorityMap, ruleNameMap) =>
  sortBy(rules, [
    ({ clearable }) => (clearable ? 0 : 1),
    ({ id }) => (typeof id === 'undefined' ? Number.NEGATIVE_INFINITY : rulePriorityMap[id]),
    ({ id }) => ruleNameMap[id]
  ])

export const usesPrioritySystem = engine =>
  [ENGINE.INVOICE_REVIEW, ENGINE.MATTER_REVIEW].includes(engine)

// TODO: Would ideally by controlled by a permission setting of some
// sort, but temporary logic will be checking if user is CSM for
// Invoice Validation Engine otherwise simply checking if they are an
// admin.
export const canEditEngine = engine =>
  window.credentials.user.isCSM ||
  (engine !== ENGINE.INVOICE_VALIDATION && window.credentials.user.role === ORG_ROLE.ADMIN)

const getByKey = (key, list, value) => list.find(item => item[key] === value) || {}
export const getByName = partial(getByKey, 'name')
export const getByAttrId = partial(getByKey, 'attr_id')

export const getModelAttrField = (lhs, availableFields) => {
  if (!Object.keys(lhs).length || lhs.operand_type === LHS_TYPES.FUNC_CALL)
    return { model: '', listAttr: '', field: '' }
  const model = getByName(availableFields, lhs.model_name)
  const listAttr = getByAttrId(model.list_custom_attrs, lhs.attr_id)
  const field = getByName(listAttr.fields || model.fields, lhs.field_name)

  return { model, listAttr, field }
}

export const paramDefToExecParam = paramDef => {
  const { name, type, sub_type } = paramDef

  return {
    param_name: name,
    type,
    sub_type,
    value: { label: '', value: '' }
  }
}

const isCustomField = ({ field_type }) => field_type === 'custom'

export const isAttrOperand = operand => has(operand, 'attr_id') && has(operand, 'model_name')

const getChoicesByType = models => {
  let choicesByType = {}

  for (let model of models) {
    for (let field of model.fields) {
      if (isCustomField(field)) {
        if (!has(choicesByType, field.type)) choicesByType[field.type] = []

        choicesByType[field.type].push({
          attr_id: field.name,
          model_name: model.name,
          label: `${model.display_name} · ${field.display_name}`,
          value: `${model.display_name} · ${field.display_name}`
        })
      }
    }
  }

  return choicesByType
}

export const addOtherAttributesAsChoices = models => {
  const choicesByType = getChoicesByType(models)

  for (let [modelIndex, model] of models.entries()) {
    for (let [fieldIndex, field] of model.fields.entries()) {
      if (isNull(field.choices) && isCustomField(field)) {
        models = update(models, {
          [modelIndex]: {
            fields: {
              [fieldIndex]: {
                choices: {
                  $set: get(choicesByType, field.type, []).filter(
                    ({ attr_id, model_name }) =>
                      !(model.name === model_name && field.name === attr_id)
                  )
                }
              }
            }
          }
        })
      }
    }
  }

  return models
}

export const getAttrOperandLabel = (models, attrOperand) => {
  for (let model of models) {
    if (model.name === attrOperand.model_name) {
      for (let field of model.fields) {
        if (field.name === attrOperand.attr_id)
          return `${model.display_name} · ${field.display_name}`
      }
    }
  }

  return 'Select...'
}

export function pushToTimeline({ nextState, ruleIndex }) {
  const rule = nextState[nextState.engine].rulesList[ruleIndex]

  const newTimeline = [...rule.timeline.slice(0, rule.timelineIndex + 1), rule.condition]

  return update(nextState, {
    [nextState.engine]: {
      rulesList: {
        [ruleIndex]: {
          timeline: { $set: newTimeline },
          timelineIndex: { $set: rule.timelineIndex + 1 }
        }
      }
    }
  })
}

export function annotateTimeline(rule) {
  return {
    ...rule,
    timeline: [rule.condition],
    timelineIndex: 0
  }
}

export function stripTimeline(ruleWithTimeline = {}) {
  const { timeline, timelineIndex, ...rule } = ruleWithTimeline
  return rule
}

export function timelineStep({ state, ruleIndex, stepAmount }) {
  const rule = state[state.engine].rulesList[ruleIndex]

  const newTimelineIndex = rule.timelineIndex + stepAmount
  const newCondition = rule.timeline[newTimelineIndex]

  return update(state, {
    [state.engine]: {
      rulesList: {
        [ruleIndex]: {
          timelineIndex: { $set: newTimelineIndex },
          condition: { $set: newCondition }
        }
      }
    }
  })
}

function isBooleanOperand(operand) {
  return BOOLEAN_OPERATORS.includes(operand.op)
}

// This util helps in ensuring two things:
//
// 1. That boolean expressions do not have direct descendants with the
//    same boolean operator.
//
// Turing this:
//
// AND -- AND -- a
//     |      |
//     |      -- AND -- b
//     |             |
//     |             -- c
//     |
//     -- d
//
// into:
//
// AND -- a
//     |
//     -- b
//     |
//     -- c
//     |
//     -- d
//
// 2. That no orphaned boolean expressions exist and that they rather
//    flatten out and merge with their parent's operands.
//
// Turing this:
//
// AND -- OR -- a
//     |
//     -- b
//
// into:
//
// AND -- a
//     |
//     -- b
//
export function simplifyOperands(operands, parentOperator) {
  if (!operands.length) return operands

  const [head, ...tail] = operands

  let prefix = [head]

  if (isBooleanOperand(head)) {
    if (head.op === parentOperator || head.operands.length === 1) {
      prefix = simplifyOperands(head.operands, parentOperator)
    } else {
      prefix = [{ op: head.op, operands: simplifyOperands(head.operands, head.op) }]
    }
  }

  return [...prefix, ...simplifyOperands(tail, parentOperator)]
}

export function canSimplifyCondition(condition) {
  return get(condition, 'operands.length', 0) > 1
    ? !isEqual(simplifyOperands([condition]), [condition])
    : false
}

export function ruleMatchesSearch(
  { name = '', description = '', text = '', reviewers_text = '' },
  search
) {
  const nSearch = search.toLowerCase()
  const nName = name.toLowerCase()
  const nDescription = description.toLowerCase()
  const nReviewersText = reviewers_text.toLowerCase()
  const nDSL = text.toLowerCase()

  return [nName, nDescription, nDSL, nReviewersText].some(v => v.includes(nSearch))
}

export const nameToLabel = name =>
  name
    .split('_')
    .map(v => v[0].toUpperCase() + v.slice(1))
    .join(' ')

export const stripNullActions = rule => ({
  ...rule,
  actions: rule.actions.filter(({ name }) => name !== null)
})

export function getHasSimpleReview() {
  const activeModules = getActiveRuleModules()

  return activeModules.hasSimpleReview
}

export function getActiveRuleModules() {
  const { user } = window.credentials

  const activeModules = {
    hasIIW: false,
    hasAI: false,
    hasEditableAI: false,
    hasSimpleReview: false
  }

  if (get(user, 'isCSM', false) || get(user, 'role') === ORG_ROLE.ADMIN) {
    activeModules.hasEditableAI = hasModule(MODULE.CUSTOMER_FACING_SIMPLE_REVIEW_EDITABLE)
    activeModules.hasAI =
      hasModule(MODULE.CUSTOMER_FACING_SIMPLE_REVIEW) || activeModules.hasEditableAI
    activeModules.hasIIW = window.credentials.invoiceRulesManagementEnabled
  }
  activeModules.hasSimpleReview = activeModules.hasIIW || activeModules.hasAI

  return activeModules
}
