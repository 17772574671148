import { VersionHistory } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'
import { VersionDifference } from 'simple_review/@types/editor'
import moment from 'moment'
import shortid from 'shortid'
import { RuleVersionSerializer } from './rule-version.serializer'

export const deserializeVersionHistory = (
  versionHistory: VersionHistory,
  context: ContextState
): VersionDifference => {
  return {
    versions: versionHistory.differences.map(rawVersion => ({
      id: shortid.generate(),
      date: moment(rawVersion.date).format('M/DD/YYYY'),
      time: moment(rawVersion.date).format('h:mmA'),
      rawDate: rawVersion.date,
      modifiedBy: rawVersion.user?.first_name?.concat(` ${rawVersion.user?.last_name}`),
      changesMade: RuleVersionSerializer(rawVersion, context).toString()
    }))
  }
}
