import keyMirror from 'keymirror'

export default keyMirror({
  TEAMS_FETCH_REQUESTED: null,
  TEAMS_FETCH_SUCCESS: null,
  TEAMS_MANAGE_META_FETCH_SUCCESS: null,
  TEAM_CREATE_REQUESTED: null,
  TEAM_CREATE_SUCCESS: null,
  TEAM_UPDATE_REQUESTED: null,
  TEAM_UPDATE_SUCCESS: null,
  TEAM_DELETE_REQUESTED: null,
  TEAM_DELETE_SUCCESS: null,
  TEAM_SHOW_FOR_CREATE: null,
  TEAM_SHOW_FOR_UPDATE_REQUESTED: null,
  TEAM_SHOW_FOR_UPDATE_SUCCESS: null,
  TEAM_MODAL_CLOSE: null,
  TEAMS_IS_LOADING: null,
  TEAMS_IS_NOT_LOADING: null,
  TEAMS_DOWNLOAD_XLSX: null,
  TEAMS_XLSX_DOWNLOADING: null
})
