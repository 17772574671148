import * as React from 'react'

import { Expandable } from 'components'

class ExpandableContainer extends React.Component {
  static defaultProps = {
    isExpanded: false
  }

  state = {
    isExpanded: this.props.isExpanded
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.isExpanded === prevState.isExpanded
      ? { isExpanded: nextProps.isExpanded }
      : null
  }

  toggleExpansion = event => {
    if (this.props.expandCb) {
      this.props.expandCb(event)
    }

    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }))
  }

  childrenToArray = children => (Array.isArray(children) ? children : [children])

  render() {
    return (
      <Expandable {...this.props} {...this.state} toggleExpansion={this.toggleExpansion}>
        {this.childrenToArray(this.props.children)}
      </Expandable>
    )
  }
}

export default ExpandableContainer
