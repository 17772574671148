export const toTableRows = rows =>
  rows.map(row => ({
    id: row.id,
    actions: { useTrashcan: true },
    cells: [
      { columnKey: 'name', content: row.name },
      { columnKey: 'client_matter_id', content: row.client_matter_id || 'None' },
      { columnKey: 'practice_area', content: row.practice_area || 'None' },
      { columnKey: 'open_date', content: row.open_date },
      { columnKey: 'closed_date', content: row.closed_date || 'None' }
    ],
    avatar: false
  }))
