import { useState } from 'react'
import { ModalContainer } from 'simple-core-ui'
import { UsersCheckboxSelect } from 'common/Selects'
import { Option, User } from '../types'
import s from './AddUserModal.scss'

interface Props {
  toggleAddUserModal: () => void
  onSave: (users: Option[] | User[]) => void
  scopeId: number
  view: 'fileSharing' | 'courtRules' | 'updates'
  scope: string
  selectedUsers: User[]
}

const AddUserModal = ({
  toggleAddUserModal,
  onSave,
  scopeId,
  view,
  scope,
  selectedUsers
}: Props) => {
  const [users, setUsers] = useState<Option[] | User[]>(selectedUsers)

  const isCourtRulesView = view === 'courtRules'
  const isFileSharingView = view === 'fileSharing'

  return (
    <ModalContainer
      title="Add User"
      subtitle={
        isCourtRulesView
          ? 'Choose who receives notifications when Events with Court Rules are updated'
          : isFileSharingView
          ? 'The selected user will get emails for files shared by the vendor'
          : 'The selected user will get emails for updates shared by assigned vendors.'
      }
      hasNewButtons
      cancelText="Cancel"
      size={isCourtRulesView ? 'md' : 'lg'}
      cancelCb={toggleAddUserModal}
      confirmText="Add"
      contentStyle={{ minHeight: 'auto' }}
      confirmCb={() => {
        onSave(users)
      }}
      isDisabled={!users.length}
      content={
        <>
          <div className={s.selectUserLabel}>
            {isCourtRulesView
              ? 'Choose who receives notifications when Events with Court Rules are updated'
              : 'The selected user will get emails for files shared by the vendor'}
          </div>
          <UsersCheckboxSelect
            value={users as Option[]}
            requestParams={{ active: true, matterId: scopeId, canEdit: true }}
            updateSelectedUsers={(values: Option[]) => {
              setUsers(values)
            }}
            scope={scope}
            showEmail={!isCourtRulesView}
          />
        </>
      }
    />
  )
}

export default AddUserModal
