import { createReducer } from 'redux-create-reducer'
import ACT from 'vendors/survey/actions'
import update from 'immutability-helper'

const initialState = {
  evaluation: {
    answers: []
  },
  permissionDenied: false
}

const vendorEvalsReducer = createReducer(initialState, {
  [ACT.VENDOR_EVAL_PERMISSION_DENIED](state, action) {
    const { permissionDenied } = action.payload
    return {
      ...state,
      permissionDenied
    }
  },

  [ACT.FETCH_VENDOR_EVAL_SUCCESS](state, action) {
    const { evaluation } = action.payload
    return {
      ...state,
      evaluation,
      permissionDenied: false
    }
  },

  [ACT.SUBMIT_VENDOR_EVAL_SUCCESS](state, action) {
    return {
      ...state,
      evaluation: {
        ...state.evaluation,
        isClosed: true
      }
    }
  },

  [ACT.CHANGE_EVAL_ATTRIBUTE](state, action) {
    const { value, attribute } = action.payload
    return update(state, {
      evaluation: {
        [attribute]: {
          $set: value
        }
      }
    })
  },

  [ACT.RESET_EVAL_ANSWER](state, action) {
    const { ansIndex } = action.payload
    return update(state, {
      evaluation: {
        answers: {
          [ansIndex]: {
            value: {
              $set: null
            },
            comment: {
              $set: ''
            }
          }
        }
      }
    })
  },

  [ACT.CHANGE_EVAL_ANSWER](state, action) {
    const { value, comment, ansIndex } = action.payload
    return update(state, {
      evaluation: {
        answers: {
          [ansIndex]: {
            value: {
              $set: value
            },
            comment: {
              $set: comment
            }
          }
        }
      }
    })
  }
})

export default vendorEvalsReducer
