import { Ellipsis } from 'simple-core-ui'
import moment from 'moment'

export const columns = [
  {
    columnKey: 'received_date',
    content: 'Received Date',
    isSortable: true,
    isFilterable: true,
    render: cell => {
      return moment(cell.content).format('M/D/YYYY')
    }
  },
  {
    columnKey: 'invoice_number',
    content: 'Invoice Number',
    isSortable: true,
    render: (cell, row) => {
      const [name, url] = cell.content
      return (
        <a data-testid={name} href={url}>
          <Ellipsis>{name}</Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'vendor__vendor_name',
    content: 'Vendor',
    isSortable: true,
    render: (cell, row) => {
      const [name, url] = cell.content
      return (
        <a href={url}>
          <Ellipsis>{name}</Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'invoice_status',
    content: 'Status',
    isSortable: true,
    render: (cell, row) => {
      return cell.content
    }
  },
  {
    columnKey: 'invoice_total',
    content: 'Amount',
    isSortable: true,
    style: { textAlign: 'right' }
  }
]
