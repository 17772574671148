import classNames from 'classnames'
import { Panel, Button } from 'simple-core-ui'
import Template from './Template'

import s from './Templates.scss'

const Templates = ({ templates, navigateToSelectTemplates, removeTemplate, disabled }) => (
  <Panel title="Matter Templates" className={s.templatesContainer}>
    {!disabled && (
      <Button onClick={navigateToSelectTemplates} isSecondary>
        Choose Preferred Template(s)
      </Button>
    )}
    {!!templates.length ? (
      <ul data-testid="templates_container">
        <li className={classNames(s.templateRow, s.templatesHeader)}>
          <div className={classNames(s.templateItem, s.templateName)}>
            <b>Name</b>
          </div>
          <div className={classNames(s.templateItem, s.templateDescription)}>
            <b>Description</b>
          </div>
        </li>
        {templates.map((template, index) => {
          return (
            <Template
              key={template.id}
              template={template}
              templateIndex={index}
              removeTemplate={removeTemplate}
              disabled={disabled}
            />
          )
        })}
      </ul>
    ) : (
      <p className={s.templateInfoContainer}>All templates are available for this form</p>
    )}
  </Panel>
)

export default Templates
