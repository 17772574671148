import { Component } from 'react'
import { Matters } from 'components'

class MattersContainer extends Component {
  state = {}
  render() {
    return <Matters {...this.props} {...this.state} />
  }
}

export default MattersContainer
