import { HelpText } from 'simple-core-ui'
import { nameToLabel } from 'rules/utils'
import { FUNC_ARG_TYPES } from 'rules/constants'

import s from '../styles/FuncForm.scss'

const FuncArgs = ({ args, argFieldMap }) =>
  args.map((arg, key) => (
    <span key={key} className={s.arg}>
      <span className={s.argName}>{nameToLabel(arg.arg_name)}</span>
      <span className={s.argValue}>
        {arg.arg_type === FUNC_ARG_TYPES.FIELD
          ? `${arg.model_name} · ${argFieldMap[arg.arg_name].display_name}`
          : arg.constant.label}
      </span>
    </span>
  ))

const FuncLabel = ({ funcName, funcArgs, argFieldMap, showMore }) => {
  const argCount = <span className={s.more}>{`(+${funcArgs.length} parameters)`}</span>

  return (
    <section className={s.funcLabel}>
      <span>{funcName}</span>
      {Boolean(funcArgs.length) &&
        (showMore ? (
          <HelpText
            position="bottom"
            message={<FuncArgs args={funcArgs} argFieldMap={argFieldMap} />}
            hasUnderline={false}
          >
            {argCount}
          </HelpText>
        ) : (
          argCount
        ))}
    </section>
  )
}

export default FuncLabel
