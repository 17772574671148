import { Chart } from '../../common/Chart'
import s from './Row.scss'
import cn from 'classnames'

const Row = ({ entity, question, withPill, className }) => (
  <div className={s.comparisonRow}>
    <p className={s.comparisonEntity}>{entity}</p>
    <div className={s.score}>
      {Number(question.avg_score) > 0 && (
        <b
          className={cn({
            [s.pill]: withPill
          })}
        >
          {Number(question.avg_score).toFixed(1)}
        </b>
      )}
      <i>({question.responses_number} responses)</i>
    </div>
    <Chart className={cn(s.chart, className)} question={question} />
  </div>
)

export default Row
