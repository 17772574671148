import { Fragment } from 'react'
import { Alert, useFocusInput, CharLimitInput } from 'simple-core-ui'
import s from './CreateFolder.scss'
import * as ACT from '../actions'

const CreateFolder = ({ folder, folders, dispatch, isDuplicated }) => {
  const changeFolderName = name => {
    dispatch({
      type: ACT.RENAME_FOLDER,
      payload: {
        folderName: name.replace(/\/|\\/g, '')
      }
    })
  }
  const setFocus = useFocusInput()
  return (
    <Fragment>
      {isDuplicated && (
        <Alert
          className={s.alertText}
          message="This folder name already exists. Specify another name."
          status="error"
        />
      )}
      <label>Folder Name</label>
      <CharLimitInput
        value={folder.name}
        onChangeCb={e => changeFolderName(e.target.value)}
        placeholder="Folder Name"
        hasError={isDuplicated}
        maxLength={50}
      />
    </Fragment>
  )
}

export default CreateFolder
