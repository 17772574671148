import { CSSProperties, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  makeBlobRequest,
  makeFormDataPost,
  makeGetRequest,
  makePostRequest,
  makePutRequest
} from 'utils/api'
import { openBlob } from 'utils/helpers'
import {
  Panel,
  Button,
  DataTableWrapper,
  SwitchToggle,
  withConfirmation,
  Modal
} from 'simple-core-ui'
import APP_ACT from 'app/actions'
import { ActionsPopover } from './ActionsPopover'
import ReactTooltip from 'react-tooltip'
import { useImmer } from 'use-immer'
import { getNormalizedCellContent } from './tableConfigs'
import { Params } from '@common_types/common'
import { APICategory, EditorCategory, Row } from './types'
import s from './RuleCategoriesList.scss'
import { CategoryModal } from './CategoryModal'
import swal from 'sweetalert'

const BUTTON_STYLE: CSSProperties = { borderRadius: '5px', padding: '4px 26px' }
const BASE_URL = '/category/rules/'
const DEFAULT_PAGE_SIZE = 25
const DISABLE_CATEGORY_CONFIG = {
  title: 'Mark as Inactive?',
  text:
    'This category will not appear as an option when adding or editing invoice validation rules.'
}
const ENABLE_CATEGORY_CONFIG = {
  title: 'Mark as Active?',
  text: 'This category will appear as an option when adding or editing invoice validation rules.'
}

const getBaseCategory = (): EditorCategory => ({
  name: '',
  active: false
})

interface State {
  categoriesNameList: Array<string>
  categories: Array<APICategory>
  params: Params
  totalEntries: number
  category: EditorCategory
  isEditorModalVisible: boolean
  isDownloadModalVisible: boolean
}

const initialState: State = {
  categories: [],
  categoriesNameList: [],
  params: {
    pageSize: DEFAULT_PAGE_SIZE,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  },
  totalEntries: 0,
  category: getBaseCategory(),
  isEditorModalVisible: false,
  isDownloadModalVisible: false
}

const RuleCategoriesList = () => {
  const [localState, setLocalState] = useImmer(initialState)
  const { categories, params, totalEntries, category, isEditorModalVisible } = localState

  const dispatch = useDispatch()

  const getTableData = async () => {
    try {
      const { rows, totalEntries } = await makeGetRequest(`${BASE_URL}`, { params })
      setLocalState(draft => {
        draft.categories = rows
        draft.totalEntries = totalEntries
      })
    } catch (error) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: 'There was an issue fetching rule categories',
          level: 'error'
        }
      })
    }
  }

  useEffect(() => {
    getTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleToggleStatus = async (categoryId: number, newStatus: boolean) => {
    const config = newStatus ? ENABLE_CATEGORY_CONFIG : DISABLE_CATEGORY_CONFIG
    const result = await swal({
      ...config,
      buttons: ['Cancel', 'Yes']
    })

    if (result) {
      const index = categories.findIndex(c => c.id === categoryId)
      const category = categories[index]

      const serialized = {
        id: category.id,
        name: category.name,
        active: newStatus
      }

      try {
        await makePutRequest(`${BASE_URL}update/`, serialized)
      } catch (error) {
        return dispatch({
          type: 'API_ERROR',
          error
        })
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `"${category.name}" successfully set to ${newStatus ? 'active' : 'inactive'}`,
          level: 'success'
        }
      })

      setLocalState(draft => {
        draft.categories[index].active = newStatus
      })
    }
  }

  const columns = [
    {
      columnKey: 'name',
      content: 'Rule Category',
      isSortable: true,
      isFilterable: true
    },
    {
      columnKey: 'count',
      content: 'Related Rules',
      isSortable: true,
      render: (cell: { content: boolean | string }) =>
        cell.content === '----' ? '--' : cell.content
    },
    {
      columnKey: 'active',
      content: 'Active',
      isSortable: true,
      render: (cell: { content: boolean | string }, row: Row) => (
        <div className={s.toggleWrapper}>
          <SwitchToggle
            id={String(row.id)}
            onToggle={
              row.count > 0 && row.active
                ? undefined
                : checked => handleToggleStatus(row.id, checked)
            }
            checked={cell.content !== '----'}
            tooltipContent="Categories with rules cannot be deactivated"
          />
        </div>
      )
    }
  ]

  const handleAddCategory = () => {
    setLocalState(draft => {
      draft.isEditorModalVisible = true
      draft.category = getBaseCategory()
    })
  }

  const handleCloseModal = () => {
    setLocalState(draft => {
      draft.isEditorModalVisible = false
    })
  }

  const handleSubmit = async (categoryName: string) => {
    const serialized: EditorCategory = {
      name: categoryName,
      active: category.active
    }

    try {
      if (category.id) {
        serialized.id = category.id
        await makePutRequest(`${BASE_URL}update/`, serialized)
      } else {
        await makeFormDataPost(`${BASE_URL}add/`, serialized)
      }
    } catch (err) {
      dispatch({
        type: APP_ACT.API_ERROR,
        payload: {
          title: 'Error',
          message: `There was an issue ${category.id ? 'updating' : 'creating'} category`,
          level: 'error'
        }
      })
    }

    await getTableData()

    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: `"${categoryName}" ${category.id ? 'edited' : 'added'} successfully`,
        level: 'success'
      }
    })

    setLocalState(draft => {
      draft.category = getBaseCategory()
    })

    handleCloseModal()
  }

  const handleDelete = withConfirmation(
    async (categoryId: number) => {
      try {
        await makePostRequest(`${BASE_URL}delete/`, { id: categoryId })
      } catch (err) {
        dispatch({
          type: APP_ACT.API_ERROR,
          error: err,
          payload: {
            title: 'Error',
            message: 'There was an issue deleting category',
            level: 'error'
          }
        })
        return
      }

      await getTableData()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Rule category deleted successfully',
          message: 'Successfully deleted rule category.',
          level: 'success'
        }
      })
    },
    {
      title: 'Delete Rule Category Name',
      text:
        'Deleting a rule category removes it from the invoice rule categories list. Do you wish to continue?'
    }
  )

  const handleEdit = (categoryId: number) => {
    setLocalState(draft => {
      const index = draft.categories.findIndex(c => c.id === categoryId)
      const category = draft.categories[index]
      draft.category = category
      draft.isEditorModalVisible = true
    })
  }

  const renderCustomAction = (row: Row) => (
    <ActionsPopover row={row} onDelete={handleDelete} onEdit={handleEdit} />
  )

  const updateTable = (updatedParams: Params) => {
    setLocalState(draft => {
      draft.params = updatedParams
    })
    ReactTooltip.hide()
  }

  const handleDownload = async () => {
    try {
      const data = await makeBlobRequest({
        url: `${BASE_URL}export/`,
        method: 'GET'
      })
      if (data.size) {
        openBlob(data, 'RuleCategories.xlsx')
      } else {
        setLocalState(draft => {
          draft.isDownloadModalVisible = true
        })
      }
    } catch (err) {
      dispatch({
        type: APP_ACT.API_ERROR,
        payload: {
          title: 'Error',
          message: 'There was an issue downloading categories',
          level: 'error'
        }
      })
    }
  }

  return (
    <>
      <Panel
        title="Manage Invoice Validation Rule Categories"
        rightActions={[
          <Button style={BUTTON_STYLE} onClick={handleDownload} isSecondary key="xls">
            Download
          </Button>,
          <Button style={BUTTON_STYLE} onClick={handleAddCategory} isPrimary key="add">
            Add Category
          </Button>
        ]}
        styles={{ boxShadow: 'none' }}
        className={s.listContainer}
      >
        <DataTableWrapper
          hasActions
          params={params}
          categories={[]}
          totalEntries={totalEntries}
          rows={categories}
          columns={columns}
          customAction={renderCustomAction}
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          getNormalizedCellContent={getNormalizedCellContent}
          updateTable={updateTable}
          remotePagination={true}
        />
      </Panel>
      {isEditorModalVisible && (
        <CategoryModal
          category={category}
          onSubmit={handleSubmit}
          onCloseModal={handleCloseModal}
        />
      )}
      <Modal
        size="sm"
        title="We are working on your download"
        content="You’ll receive an email once your report is ready."
        isVisible={localState.isDownloadModalVisible}
        confirmText="OK"
        confirmCb={() => {
          setLocalState(draft => {
            draft.isDownloadModalVisible = false
          })
        }}
      />
    </>
  )
}

export default RuleCategoriesList
