import { PaginatedSelect } from 'simple-core-ui'
import { toUsers } from 'common/Selects/serializers'
import { TeamSelect } from 'teams'
import { toTeamSelectUsers, toFilteredReactSelect } from 'bulk/serializer'
import { selectStyles } from 'bulk/styles/styleSettings'
import { TEAM_SELECT_FIELDS, USER_SELECT_FIELDS } from 'bulk/constants'

const optWrapperStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  width: '390px',
  justifyContent: 'space-between'
}

const width = { width: 110 }

const CustomFilterLabel = ({
  fieldName,
  optionsSource,
  onChange,
  isMulti,
  limit,
  value,
  isPortal,
  size,
  isDisabled,
  placeholder,
  label,
  optionParams
}) => {
  const updatedUrl = [
    'matter_vendors',
    'allocated_cost_code',
    'allocated_legal_entity',
    'legal_entity'
  ].includes(fieldName)
    ? `${optionsSource}?all=true`
    : optionsSource

  const { page_number_key, page_size_key, search_key, start_page } = optionParams

  return (
    <div style={optWrapperStyle}>
      <p style={width}>{label}</p>
      <div style={{ width: '100%' }}>
        {TEAM_SELECT_FIELDS.includes(fieldName) ? (
          <TeamSelect
            url={optionsSource}
            value={value}
            onChange={onChange}
            multi
            id={fieldName}
            ariaLabel="reviewers"
            placeholder="Select Reviewers..."
            isDisabled={isDisabled}
            limit={20}
            serializer={toTeamSelectUsers}
            styles={selectStyles}
          />
        ) : (
          <PaginatedSelect
            id={fieldName}
            url={updatedUrl}
            onChange={onChange}
            isMulti={isMulti}
            limit={limit}
            serializer={USER_SELECT_FIELDS.includes(fieldName) ? toUsers : toFilteredReactSelect}
            value={value}
            isPortal={isPortal}
            size={size}
            isDisabled={isDisabled}
            placeholder={`Search ${placeholder}`}
            paginated
            styles={selectStyles}
            pageParam={page_number_key}
            pageSizeParam={page_size_key}
            searchTermParam={search_key}
            pageStart={start_page}
          />
        )}
      </div>
    </div>
  )
}

export default CustomFilterLabel
