import { BaseSerializer } from './base.serializer'
import { RuleInfoEditSerializer } from './rule-info-edit.serializer'

export function RuleCategoryEditSerializer(
  oldValue: string | null,
  newValue: string
): BaseSerializer {
  return {
    toString() {
      if (!oldValue) {
        return RuleInfoEditSerializer(null, newValue, 'category').toString()
      }

      return RuleInfoEditSerializer(oldValue, newValue, 'category').toString()
    }
  }
}
