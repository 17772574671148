import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from './actions'
import { TEMPLATE_STATUSES } from 'matters/templates/constants'
import { LegalRequestFormsTableContainer } from './Main'

import s from './LegalRequestFormsList.scss'

@connect(({ matterTemplatesList }) => {
  const templateCount = matterTemplatesList.matterTemplates.filter(
    template => template.status === TEMPLATE_STATUSES.PUBLISHED
  ).length
  return {
    templateCount
  }
})
class LegalRequestFormsListContainer extends Component {
  componentDidMount() {
    this.props.dispatch({ type: ACT.LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED, loadingLock: 'on' })
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED,
      payload: {
        latestTemplateId: {}
      }
    })
  }
  getNewFormPageLink = () => {
    const { templateCount } = this.props
    return templateCount > 1 ? '/v2/matters/templates/form/select/' : '/v2/matters/requests/form/'
  }
  render() {
    return (
      <div className={s.container}>
        <LegalRequestFormsTableContainer newFormLink={this.getNewFormPageLink()} />
      </div>
    )
  }
}

export default LegalRequestFormsListContainer
