import { If, Button, Tooltip } from 'simple-core-ui'
import s from './EntityActionsFooter.scss'
import swal from 'sweetalert'

const EntityActionsFooter = ({
  entity,
  archive,
  saveAndPublish,
  save,
  cancelChanges,
  showDelete,
  deleteEntity,
  status,
  buttonsDisabled,
  canPublish
}) => {
  const isPublished = entity.status === status
  return (
    <div className={s.buttons}>
      <div>
        <If condition={showDelete}>
          <Button isDisabled={buttonsDisabled} onClick={() => deleteEntity()}>
            Delete
          </Button>
        </If>
        <If condition={isPublished}>
          <Button
            isDisabled={buttonsDisabled}
            onClick={async () => {
              if (entity.default) {
                await swal({
                  title: 'Default Template',
                  text:
                    'You need to set another template as default if you want to archive this template.',
                  button: 'Ok',
                  icon: 'warning'
                })
                return
              }

              archive(entity)
            }}
            testid="archive_button"
          >
            Archive
          </Button>
        </If>
      </div>
      <div>
        <Button isDisabled={buttonsDisabled} onClick={() => cancelChanges()} isSecondary>
          Cancel
        </Button>
        <Button
          isDisabled={buttonsDisabled}
          onClick={() => save(entity)}
          isPrimary={isPublished}
          isSecondary={!isPublished}
        >
          Save
        </Button>
        <If condition={!isPublished}>
          <Tooltip
            trigger={
              <Button
                isDisabled={buttonsDisabled || !canPublish}
                onClick={() => saveAndPublish(entity)}
                isPrimary
                testid="save_publish_button"
              >
                Save & Publish
              </Button>
            }
            content={
              canPublish !== null && !canPublish ? 'Contact customer success to upgrade.' : null
            }
            placement="top"
          />
        </If>
      </div>
    </div>
  )
}

export default EntityActionsFooter
