import { useRef, useEffect, useState } from 'react'
import s from '../SearchFilter.scss'
import { Button } from 'simple-core-ui'
import cn from 'classnames'
import { isOutOfViewport } from '../helpers'

const SearchSelectBody = ({
  onSubmit,
  onClear,
  cancelText = 'Clear',
  submitText = 'Apply',
  children = [],
  className,
  style,
  width,
  isDisabled,
  ref,
  bodyClassName
}) => {
  const containerRef = useRef()
  const [containerBoundariesInfo, setContainerBoundariesInfo] = useState({})

  useEffect(() => {
    const boundingObj = isOutOfViewport(containerRef.current)
    setContainerBoundariesInfo(boundingObj)
  }, [])

  return (
    <div
      style={{
        ...(containerBoundariesInfo.right ? { left: -containerBoundariesInfo.rightOffset } : {}),
        ...style,
        ...(width ? { width } : {})
      }}
      className={cn(s.menu, s.selectSearchMenu, {
        [className]: className
      })}
      ref={containerRef}
    >
      <div className={cn(s.selectSearchBody, { [bodyClassName]: bodyClassName })}>{children}</div>
      <footer className={s.filterFooter}>
        <Button onClick={onClear} isSecondary>
          {cancelText}
        </Button>
        <Button onClick={onSubmit} isDisabled={isDisabled} testid="submit_filter">
          {submitText}
        </Button>
      </footer>
    </div>
  )
}

export default SearchSelectBody
