import { fieldType } from '../constant-model'
import { ListConstantMapper } from './list-constant-mapper'
import { ConstantMapper } from './constant-mapper'
import { ListConstant } from './constant-model'
import { RhsTypes } from 'simple_review/@types/editor'

export class DateConstantMapper extends ConstantMapper {
  type: fieldType = 'date'
}

export class DateListConstantMapper extends ListConstantMapper {
  type: fieldType = 'date'

  // TODO: ascertain rhsInputType from choices, operator, rhs type
  fromJSON(): ListConstant {
    return {
      type: this.type,
      isList: true,
      values: this._constant,
      label: this._constant.map(({ label }) => label).join(' and '),
      subType: '',
      rhsInputType: RhsTypes.Creatable
    }
  }
}
