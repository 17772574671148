import * as React from 'react'
import get from 'lodash/get'
import last from 'lodash/last'

import { makeGetRequest } from 'utils/api'
import { ActivityLog, coreThemes } from 'simple-core-ui'

import { toActivityLogParams, fromActivity } from '../serializers'
import { ACTIVITY_LOG_URL } from '../urls'

class ActivityLogContainer extends React.Component {
  state = {
    activity: [],
    hasMore: true,
    isLoading: false,
    isError: false
  }

  static defaultProps = {
    pageSize: 15
  }

  async componentDidMount() {
    await this.fetchActivity()
  }

  fetchActivity = async () => {
    this.setState({ isLoading: true })

    try {
      const lastLogId = get(last(this.state.activity), 'id')
      const params = toActivityLogParams({ ...this.props, lastLogId })
      const response = await makeGetRequest(ACTIVITY_LOG_URL, { params })
      const activity = fromActivity(response)

      this.setState(prevState => ({
        activity: [...prevState.activity, ...activity],
        isLoading: false,
        hasMore: activity.length === this.props.pageSize
      }))
    } catch (e) {
      this.setState({ isLoading: false, isError: true })
    }
  }

  render() {
    const { isBodyOnly } = this.props
    const { activity, isLoading, isError, hasMore } = this.state

    return (
      <ActivityLog
        logs={activity}
        isFetching={isLoading}
        isError={isError}
        hasMore={hasMore}
        loadMoreCb={this.fetchActivity}
        isBodyOnly={isBodyOnly}
        theme={coreThemes.EB}
      />
    )
  }
}

export default ActivityLogContainer
