import { Notifications } from 'matters/detail/settings/Notifications'
import { Panel } from 'simple-core-ui'
import { SCOPE } from 'utils/constants'
import s from './NotificationsContainer.scss'

const { object_id: vendorId } = window.serverContext.all()

const NotificationsContainer = () => {
  return (
    <Panel title="File Sharing Notifications">
      <p className={s.subtitle}>
        Choose who receives notifications when this vendor shares files or updates a file
        that&apos;s been shared. A minimum of 1 user must be listed.
      </p>
      <Notifications type="fileSharing" scope={SCOPE.VENDOR} scopeId={vendorId} />
    </Panel>
  )
}

export default NotificationsContainer
