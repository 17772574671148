import { useMemo } from 'react'
import cn from 'classnames'

import { Alert } from 'simple-core-ui'
import { Condition, Operand } from 'simple_review/@types/api'
import { getFlatConditions, isOperandCondition } from 'simple_review/utils/helpers'
import ExpressionReadOnly from 'simple_review/expressions/ExpressionReadOnly'
import { ConditionReadOnly } from './condition-read-only'
import s from './ReadOnlyRule.scss'

interface Props {
  condition: Condition
  ruleName: string
}

const ReadOnlyView = ({ condition, ruleName }: Props) => {
  const flatConditions = useMemo(() => {
    if (condition) return getFlatConditions(condition)
    return []
  }, [condition])

  if (!condition) {
    return (
      <Alert
        message="This rule is unconditional and will run under all circumstances."
        status="info"
        style={{ alignSelf: 'flex-start' }}
      />
    )
  }

  return (
    <ul>
      {flatConditions.map((operand, index) => {
        const { booleanOp, condition } = operand

        const isAiPowered = (() => {
          if (!('operands' in condition)) return false
          return condition.operands.some(op => {
            if (!('lhs' in op)) return false
            if (!('model_name' in op.lhs)) return false
            return op.lhs.model_name === 'AdjustmentCodeClassification'
          })
        })()

        return (
          <li key={index} className={cn(s.condition, isAiPowered && s.aiPowered)}>
            {isOperandCondition(condition) ? (
              <ConditionReadOnly condition={condition as Condition} ruleName={ruleName} />
            ) : (
              <ExpressionReadOnly operand={condition as Operand} ruleName={ruleName} />
            )}
            {index < flatConditions.length - 1 && (
              <div className={s.booleanOperator}>{booleanOp}</div>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default ReadOnlyView
