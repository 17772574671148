import { useState } from 'react'
import { DataTableWrapper, Ellipsis } from 'simple-core-ui'
import { Vendor, Cell } from '../types'
import { IoWarningOutline } from 'react-icons/io5'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import s from './SelectVendorsStep.scss'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: Infinity,
  ordering: { columnKey: 'name', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

interface Props {
  vendors: Vendor[]
  selectedRows: number[]
  allRowsSelected: boolean
  selectAllRows: () => void
  selectRow: (id: number) => void
}

const columns = [
  {
    columnKey: 'name',
    content: <b>Vendor Name</b>,
    isSortable: true,
    isFilterable: false,
    render: (cell: Cell) => {
      return <Ellipsis>{cell.content}</Ellipsis>
    }
  },
  {
    columnKey: 'id',
    content: <b>Vendor ID</b>,
    isSortable: false,
    isFilterable: false
  }
]

const categories: { label: string; value: string }[] = []

const SelectVendorsStep = ({
  vendors,
  selectedRows,
  allRowsSelected,
  selectAllRows,
  selectRow
}: Props) => {
  const [params, setParams] = useState(initialParams)

  const updateTable = (params: Params) => {
    setParams(params)
  }

  return (
    <div style={{ marginTop: 10 }}>
      <h2 className={s.title}>Add Vendors</h2>
      <AiOutlineInfoCircle className={s.info} /> Only active vendors who have a vendor contact
      enabled with CounselGO access and general file sharing permissions are displayed.
      <p>
        <IoWarningOutline
          style={{ fontSize: 22, color: '#bb342f', position: 'relative', top: 5 }}
        />{' '}
        File(s) will be shared with all vendor contacts with General File Sharing permission and
        will be overwritten if previously shared with vendors.
      </p>
      <p style={{ fontWeight: 600, fontSize: 16, marginTop: 15, marginLeft: 10 }}>
        {selectedRows.length}/{vendors.length} Vendors selected
      </p>
      <DataTableWrapper
        params={params}
        categories={categories}
        rows={vendors}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasTooltip
        categoryKey="events"
        hasActionsHeader={false}
        hasPagination={false}
        selectAllRows={selectAllRows}
        selectRow={row => selectRow(row.id)}
        selectedRows={new Set(selectedRows)}
        allRowsSelected={allRowsSelected}
        checkboxSize="md"
      />
    </div>
  )
}

export default SelectVendorsStep
