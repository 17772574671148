import { ReactNode } from 'react'
import s from './LaneHeader.scss'
import { Lane } from '../types'

interface Props extends Lane {
  [key: string]: any
}

const LaneHeader = (props: Props): ReactNode => {
  return (
    <div style={{ borderTop: `4px solid ${props.color}` }} className={s.container}>
      {props.title}{' '}
      {props.totalEntries && <span className={s.totalEntries}>{props.totalEntries}</span>}
    </div>
  )
}

export default LaneHeader
