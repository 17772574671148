import { useContext, useEffect } from 'react'

import TabContext from 'context/TabContext'

const useAppTabs = ({ tabs, selected, onClick }) => {
  const updateTabs = useContext(TabContext)

  useEffect(() => {
    const appTabs = tabs.map(({ label, value }) => ({
      text: label,
      isSelected: selected === value,
      onClick: () => onClick(value)
    }))

    updateTabs && updateTabs(appTabs)
  }, [updateTabs, tabs, selected, onClick])
}

export default useAppTabs
