export const toAttachments = files => {
  return files.map(file => ({
    file_name: file.fileName,
    file_type: file.fileType,
    file_url: file.fileURL,
    fileStatus: file.fileStatus,
    file_size: file.fileSize,
    file_upload_date: file.fileUploadDate,
    source: file.file_source,
    id: file.id,
    download_url: '/attachment/' + file.fileURL
  }))
}
