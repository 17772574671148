import s from './AddMatterModal.scss'
import { MatterSelect } from 'common/Selects'

const AddMatterModal = ({ modalType, setSelectedMatter, selectedMatter }) => {
  return (
    <div className={s.container}>
      <p>
        {modalType === 'parent'
          ? 'Add a Parent to this Matter:'
          : 'Add a Related Matter to this Matter:'}
      </p>
      <MatterSelect
        ariaLabel="Search Parent Matter"
        value={selectedMatter}
        onChange={item => {
          setSelectedMatter(item)
        }}
        isPortal
        paginated
      />
    </div>
  )
}

export default AddMatterModal
