import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './Popover.scss'

interface Props {
  conditionId: string
  isCopyDisabled: boolean
  onCopy(): void
  onDelete(): void
}

const ActionsPopover = ({ isCopyDisabled, onCopy, onDelete }: Props) => {
  const actions = [
    {
      name: 'COPY',
      text: 'Copy',
      visible: !isCopyDisabled,
      onClick() {
        onCopy()
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        onDelete()
      }
    }
  ]

  return (
    <Popover
      trigger={<HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />}
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
