import keyMirror from 'keymirror'

export default keyMirror({
  HYDRATE_REQUESTED: null,
  HYDRATE_SUCCESS: null,
  ADD_REVIEWERS_REQUESTED: null,
  ADD_REVIEWERS_SUCCESS: null,
  REMOVE_REVIEWER_REQUESTED: null,
  REMOVE_REVIEWER_SUCCESS: null,
  SEND_BACK_TO_REQUESTED: null,
  SEND_BACK_TO_SUCCESS: null,
  FINALIZE_REQUESTED: null,
  FINALIZE_SUCCESS: null,
  APPROVAL_REQUESTED: null,
  APPROVAL_SUCCESS: null,
  REJECTION_REQUESTED: null,
  REJECTION_SUCCESS: null,
  UNAPPROVAL_REQUESTED: null,
  UNAPPROVAL_SUCCESS: null,
  UNREJECTION_REQUESTED: null,
  UNREJECTION_SUCCESS: null,
  ADMIN_ACTION_REQUESTED: null,
  ADMIN_ACTION_SUCCESS: null,
  IS_HYDRATING: null,
  IS_NOT_HYDRATING: null
})
