import s from './CustomHeader.scss'
import subMonths from 'date-fns/subMonths'
import addMonths from 'date-fns/addMonths'
import subWeeks from 'date-fns/subWeeks'
import addWeeks from 'date-fns/addWeeks'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import { Button } from 'simple-core-ui'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { View } from 'react-big-calendar'
import enUS from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import cn from 'classnames'

interface CustomHeaderProps {
  date: Date
  onNavigate: (newDate: Date, view: View) => void
  onView: (view: View) => void
  currentView: string
  setCurrentView: (view: string) => void
}

const CustomHeader = ({
  date,
  onNavigate,
  onView,
  currentView,
  setCurrentView
}: CustomHeaderProps) => {
  const navigate = (action: string) => {
    if (action === 'TODAY') {
      onNavigate(new Date(), currentView as View)
    } else if (action === 'PREV') {
      let newDate
      if (currentView === 'month') {
        newDate = subMonths(date, 1)
      } else if (currentView === 'week') {
        newDate = subWeeks(date, 1)
      } else if (currentView === 'day') {
        newDate = subDays(date, 1)
      }
      onNavigate(newDate as Date, currentView as View)
    } else if (action === 'NEXT') {
      let newDate
      if (currentView === 'month') {
        newDate = addMonths(date, 1)
      } else if (currentView === 'week') {
        newDate = addWeeks(date, 1)
      } else if (currentView === 'day') {
        newDate = addDays(date, 1)
      }
      onNavigate(newDate as Date, currentView as View)
    }
  }

  const handleViewChange = (view: View) => {
    setCurrentView(view)
    onView(view)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.dateWrapper}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaChevronLeft
            className={s.navigation}
            style={{ marginRight: 10 }}
            onClick={() => navigate('PREV')}
          />
          <FaChevronRight className={s.navigation} onClick={() => navigate('NEXT')} />
          <span className={s.date}>{format(date, 'MMMM yyyy', { locale: enUS })}</span>
          <Button className={s.button} isSecondary onClick={() => navigate('TODAY')}>
            Today
          </Button>
        </div>
      </div>
      <div className={s.buttonsWrapper}>
        <div className={s.buttonGroup}>
          <Button
            className={cn(s.button, s.customBtn, currentView === 'month' && s.active)}
            isSecondary
            onClick={() => handleViewChange('month')}
          >
            Month
          </Button>
          <Button
            className={cn(s.button, s.customBtn, currentView === 'week' && s.active)}
            isSecondary
            onClick={() => handleViewChange('week')}
          >
            Week
          </Button>
          <Button
            className={cn(s.button, s.customBtn, currentView === 'day' && s.active)}
            isSecondary
            onClick={() => handleViewChange('day')}
          >
            Day
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CustomHeader
