import { ChangeEventHandler, useRef, useState } from 'react'
import capitalize from 'lodash/capitalize'

import { ModalContainer, Alert, CharLimitInput } from 'simple-core-ui'
import s from './CategoryModal.scss'
import { makeGetRequest } from 'utils/api'

const BASE_URL = '/category/rules/'

const startCase = (raw: string) => {
  return raw
    .toLowerCase()
    .split(' ')
    .map(capitalize)
    .join(' ')
}

interface Category {
  id?: number
  name: string
}

interface Props {
  category: Category
  onSubmit(categoryName: string): Promise<void>
  onCloseModal(): void
}

const CategoryModal = ({ category, onSubmit, onCloseModal }: Props) => {
  const [categoryName, setCategoryName] = useState(category.name)
  const [isDuplicate, setIsDuplicate] = useState(false)

  const formatted = startCase(categoryName)

  const timeout = useRef<NodeJS.Timeout>()

  const debouncedCheckDuplicate = (name: string) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(async () => {
      const response = await makeGetRequest(`${BASE_URL}exists`, {
        params: { name, categoryId: category.id }
      })
      setIsDuplicate(response.exists)
    }, 200)
  }

  const onChangeCategoryName: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    let value = e.target.value
    // remove non alpha-numeric characters
    value = value.replace(/([^\s\w\d])*/g, '')
    // remove extra spaces
    value = value.replace(/\s+/g, ' ')
    setCategoryName(value)
    debouncedCheckDuplicate(startCase(value))
  }

  const isEdit = typeof category.id === 'number'
  const isPristine = formatted === category.name

  return (
    <ModalContainer
      title={isEdit ? 'Edit Invoice Rule Category' : 'Add Invoice Rule Category'}
      subtitle={isEdit ? 'Category Name' : 'New Category'}
      size="sm"
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={() => onSubmit(formatted)}
      cancelCb={onCloseModal}
      isDisabled={isDuplicate || isPristine || formatted.length === 0 || formatted.length > 50}
      content={
        <div>
          {isDuplicate && (
            <Alert
              className={s.alert}
              message="The category already exists. Please specify a different category."
              status="error"
            />
          )}
          <div className={s.title}>Category</div>
          <CharLimitInput
            value={categoryName}
            onChangeCb={onChangeCategoryName}
            placeholder="Enter Rule Category Name"
            dynamicCharCalculation
            maxLength={50}
          />
        </div>
      }
    />
  )
}

export default CategoryModal
