import { useEffect, useState, CSSProperties, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { makeDeleteRequest, makeGetRequest } from 'utils/api'
import APP_ACT from 'app/actions'
import { Button, DataTableWrapper, ModalContainer, useLoading, Avatar } from 'simple-core-ui'
import { Calendar, Row, Cell } from './types'
import { toCalendars } from './serializers'
import s from './ExternalTaskCalendars.scss'
import { isTaskCalendarSyncEnabled } from 'common/Tasks/utils'
import { isEventsCalendarSyncEnabled } from 'common/Events/utils'
import { format } from 'date-fns'

const DATE_FORMAT = `d MMM, yyyy h:mm a`

interface Props {
  panelTitle?: string
  subtitle?: ReactNode
  panelStyles?: CSSProperties
  profilePage?: boolean
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const ExternalTaskCalendars = ({ panelTitle, subtitle, panelStyles, profilePage }: Props) => {
  const dispatch = useDispatch()
  const [calendars, setCalendars] = useState<Calendar[]>([])
  const [isLoading, withLoadingLocks] = useLoading()
  const [isRevokeAccessModalVisible, setIsRevokeAccessModalVisible] = useState(false)
  const [revokeAccessUser, setRevokeAccessUser] = useState<{
    id: number
    name: string
  } | null>(null)
  const [params, setParams] = useState({
    pageSize: 10,
    ordering: { columnKey: 'fullName', isDesc: true },
    search: '',
    page: 1,
    category: 'all'
  })

  const columns = [
    {
      columnKey: 'fullName',
      content: 'Shared by',
      isSortable: true,
      isFilterable: true,
      render: (cell: Cell) => {
        return (
          <div className={s.sharedByCell}>
            <Avatar className={s.avatar} initials={cell.content as string} />
            <div className={s.name}>{cell.content as string}</div>
          </div>
        )
      }
    },
    {
      columnKey: 'createdAt',
      content: 'Created at',
      isSortable: true,
      isFilterable: false,
      render: (cell: Cell) => {
        return cell.content !== '----' ? format(cell.content as Date, DATE_FORMAT) + ' PST' : '--'
      }
    },
    {
      columnKey: 'lastSyncAt',
      content: 'Last sync at',
      isSortable: true,
      isFilterable: false,
      render: (cell: Cell) => {
        return cell.content !== '----' ? format(cell.content as Date, DATE_FORMAT) + ' PST' : '--'
      }
    }
  ]

  const fetchCalendars = async () => {
    try {
      if (isTaskCalendarSyncEnabled() || isEventsCalendarSyncEnabled()) {
        const { calendars } = await withLoadingLocks(
          makeGetRequest(
            `/event-management/calendar/${profilePage ? window.credentials.user.id : ''}`
          )
        )
        setCalendars(toCalendars(calendars))
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        setCalendars([])
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
  }

  useEffect(() => {
    fetchCalendars()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  const revokeCalendarAccess = async () => {
    try {
      await withLoadingLocks(
        makeDeleteRequest(`/event-management/calendar/${revokeAccessUser?.id}/`)
      )
      setCalendars(calendars.filter(calendar => calendar.id !== revokeAccessUser?.id))
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Calendar desynchronized successfully.',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setRevokeAccessUser(null)
    setIsRevokeAccessModalVisible(false)
  }

  const revokeAccessAction = (row: Row) => {
    return (
      <Button
        className={s.revokeAccessButton}
        isPrimary
        hasNewDesign
        onClick={() => {
          setRevokeAccessUser({ id: row.id, name: row.fullName })
          setIsRevokeAccessModalVisible(true)
        }}
      >
        Revoke Access
      </Button>
    )
  }

  return (
    <>
      <DataTableWrapper
        panelTitle={panelTitle || 'My External Tasks and Events Calendars'}
        subtitle={
          subtitle ||
          'The records in the table below show calendar synchronizations with Apple Calendar, Google Calendar, Microsoft Outlook, or other calendar applications.'
        }
        panelStyles={panelStyles}
        isLoading={isLoading}
        rows={calendars}
        columns={columns}
        customAction={revokeAccessAction}
        totalEntries={calendars.length}
        params={params}
        updateTable={updateTable}
        categories={[]}
        hasSearch={false}
        hasPagination={false}
        hasActions
        alwaysShowActions
        alwaysShowLoadingSkeleton
        customStatusText={
          <>
            There are currently none, click on <b>Calendar Sync</b> in Tasks or Matter Events Tab to
            get started.
          </>
        }
      />
      {isRevokeAccessModalVisible && (
        <ModalContainer
          title="Revoke calendar sync access?"
          content={
            <div>
              If you revoke access
              <b>
                {` all active calendar links for ${revokeAccessUser?.name} will permanently stop functioning. This
                cannot be un-done. `}
              </b>
              Do you wish to continue?
            </div>
          }
          confirmText="Revoke Access"
          size="sm"
          hasNewButtons
          contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
          confirmCb={revokeCalendarAccess}
          cancelCb={() => {
            setRevokeAccessUser(null)
            setIsRevokeAccessModalVisible(false)
          }}
        />
      )}
    </>
  )
}

export default ExternalTaskCalendars
