import { Checkbox } from 'simple-core-ui'
import { ActionsPopover } from './popover'
import s from './Actions.scss'

type CheckboxProps = Parameters<typeof Checkbox>[0]

interface Props {
  checkboxProps: CheckboxProps
  isCheckboxShown: boolean
  checkboxLabel?: string
  conditionId: string
  isCopyDisabled: boolean
  onCopy(): void
  onDelete(): void
}

const Actions = ({
  checkboxProps,
  isCheckboxShown,
  checkboxLabel = 'Group',
  conditionId,
  isCopyDisabled,
  onCopy,
  onDelete
}: Props) => (
  <div className={s.container} data-testid="condition-actions">
    {isCheckboxShown && (
      <div className={s.checkboxContainer}>
        <span className={s.checkboxLabel}>{checkboxLabel}</span>
        <Checkbox {...checkboxProps} testId="group-checkbox" />
      </div>
    )}
    <ActionsPopover
      conditionId={conditionId}
      isCopyDisabled={isCopyDisabled}
      onCopy={onCopy}
      onDelete={onDelete}
    />
  </div>
)

export default Actions
