import { APITask, DateFormat } from 'common/Tasks/types'
import { Task } from './types'
import format from 'date-fns/format'
import { DATE_FORMATS } from 'utils/constants'

const { DEFAULT_DATE_FNS } = DATE_FORMATS

export const toTasks = (tasks: APITask[], callback?: (tasks: Task[]) => Task[]): Task[] => {
  const tasksArray = tasks.map((task: APITask) => {
    const { id, name, status, due_date, priority, task_type, related_matter } = task

    return {
      id,
      name,
      type: 'task',
      status: status
        ? {
            id: status.id,
            name: status.client_settings?.display_name ?? status.default_display_name,
            color: status.client_settings?.color ?? status.default_color,
            occurredDate: format(new Date(status.occurred_date), DEFAULT_DATE_FNS) as DateFormat,
            phase: status.phase.description
          }
        : null,
      dueDate: due_date ? (format(new Date(due_date), DEFAULT_DATE_FNS) as DateFormat) : undefined,
      priority: priority
        ? {
            id: priority.id,
            name: priority.client_settings?.display_name ?? priority.default_display_name,
            color: priority.client_settings?.color ?? priority.default_color
          }
        : null,
      taskType: task_type
        ? {
            id: task_type.id,
            name: task_type.name
          }
        : null,
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status
            }
          }
        : {}),
      title: name,
      start: due_date ? format(new Date(due_date), DEFAULT_DATE_FNS) : undefined,
      end: due_date ? format(new Date(due_date), DEFAULT_DATE_FNS) : undefined,
      allDay: true
    }
  })

  return callback ? callback(tasksArray) : tasksArray
}
