import { Component } from 'react'
import s from './Dashboard.scss'
import { Column } from './Column'
import cn from 'classnames'
import { connect } from 'react-redux'
import ACT from '../actions'
import { withRouter } from 'simple-core-ui/hocs'
import { withCurrency } from './utils'

@withRouter
@connect(({ scorecard }) => {
  return {
    currentVendor: scorecard.currentVendor
  }
})
class DashboardContainer extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: ACT.CURRENT_VENDOR_FETCH_REQUESTED,
      payload: {
        // this component is used both in react and django templates and requires 2 ways of
        // getting the id
        id:
          this.props.router.params.vendorId ||
          this.props.router.location.pathname.split('vendors/')[1].slice(0, -1)
      }
    })
  }
  render() {
    const { currentVendor, isLoading } = this.props
    const overallSatisfaction = Number(currentVendor.overall_satisfaction)
    const legalSpend = withCurrency(
      currentVendor.currency_symbol,
      currentVendor.legal_spend,
      '(0.00 a)'
    )
    const invoiceAdjustments = withCurrency(
      currentVendor.currency_symbol,
      currentVendor.invoice_adjustments,
      '(0.00 a)'
    )
    return (
      <div className={s.container}>
        <Column
          label="Overall Satisfaction"
          classNames={cn({
            [s.exceeded]: overallSatisfaction >= 9,
            [s.met]: overallSatisfaction >= 7 && overallSatisfaction < 9,
            [s.below]: overallSatisfaction < 7 && overallSatisfaction > 0
          })}
          primaryInfo={isLoading ? '' : `${overallSatisfaction.toFixed(1)} / 10`}
          secondaryInfo={`${isLoading ? '' : currentVendor.total_surveys} ${
            currentVendor.total_surveys === 1 ? 'survey' : 'surveys'
          }`}
        />
        <Column
          label="Open Matters"
          primaryInfo={currentVendor.open_matters}
          secondaryInfo={isLoading ? '' : `${currentVendor.surveyed_matters} surveyed`}
        />
        <Column label="Legal Spend" primaryInfo={legalSpend} secondaryInfo="last 12 months" />
        <Column
          label="Invoice Adjustments"
          primaryInfo={invoiceAdjustments}
          secondaryInfo="last 12 months"
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.app.loading.CURRENT_VENDOR_FETCH
  }
}

export default connect(mapStateToProps, null)(DashboardContainer)
