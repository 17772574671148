import React, { useEffect, useState, useCallback } from 'react'
import s from './File.scss'
import { ChartInfo } from './ChartInfo'
import { useFilesUpload, HoverAction } from 'simple-core-ui'
import * as ACT from '../../actions'
import { toFilesData } from '../../serializers'
import { getFolderId } from '../../helpers'
import cn from 'classnames'
import { IoIosClose } from 'react-icons/io'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'

const CancelToken = axios.CancelToken

const getUploadUrl = matterId => {
  const id = window.serverContext.get('matter_id') || matterId
  return `/doc_management/upload_files/${id}/`
}

const CancelButton = React.memo(({ onClick }) => {
  return (
    <HoverAction icon={<IoIosClose />} size="xsmall" className={s.hoverAction} onClick={onClick} />
  )
})

const File = ({
  file,
  attachments,
  currentFolder,
  dispatch,
  setUploadsFinishedNo,
  cancelFileUpload,
  setCanCancelFilesArr,
  matterId
}) => {
  const [percent, setPercent] = useState(0)
  const [error, setError] = useState(false)
  const [source] = useState(CancelToken.source())
  const [completed, setCompleted] = useState(false)
  const reduxDispatch = useDispatch()

  const uploadUrl = getUploadUrl(matterId)

  const getFileUploadUrl = file =>
    file.hasOwnProperty('reshare') ? `${uploadUrl}?reshare=${file.reshare}` : uploadUrl

  useEffect(() => {
    uploadFile(file)
    setCanCancelFilesArr(prev => {
      prev.push(source)
      return prev
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addFilesToTable = files => {
    dispatch({
      type: ACT.ADD_MATTER_ATTACHMENTS,
      payload: {
        files: toFilesData(files)
      }
    })
  }

  const uploadFile = file => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFilesUpload({
      uploadUrl: getFileUploadUrl(file),
      successCb: attachments => {
        addFilesToTable(attachments)
      },
      errorCb: () => {
        setUploadsFinishedNo()
        setError(true)
      },
      handleServerErrorCb: error => {
        setUploadsFinishedNo()
        setError(true)
        setCompleted(false)
        const msg = error?.response?.data?.error || 'Failed to upload file'
        reduxDispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: msg,
            level: 'error'
          }
        })
      },
      s3successCb: () => {
        setUploadsFinishedNo()
        setCompleted(true)
      },
      cancelCb: () => {
        cancelFileUpload(file.id)
      },
      config: {
        onUploadProgress: function(progressEvent) {
          setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        cancelToken: source.token
      },
      files: [file],
      folderId: currentFolder.id ? getFolderId(currentFolder.id) : 0,
      lifespan: 'short'
    })
  }

  const cancelUpload = useCallback(() => source.cancel(), [source])

  return (
    <div className={s.row}>
      <div className={s.chart}>
        <div
          className={cn(s.segment, {
            [s.error]: error
          })}
          style={{ width: `${error ? 100 : percent}%` }}
        />
        <ChartInfo completed={completed} error={error} file={file} percent={percent} />
      </div>
      {!error && percent !== 100 && <CancelButton onClick={cancelUpload} />}
    </div>
  )
}

export default File
