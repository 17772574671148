import moment from 'moment'
import { MatterUpdatesFilters } from './types'

export const formatText = (text: string, wrap: boolean) => (wrap ? `(${text})` : text)

export const createQueryParamsUrl = (filters?: MatterUpdatesFilters, searchPhrase?: string) => {
  const queryParams: string[] = []

  if (searchPhrase) {
    const searchQuery = searchPhrase ? `status_update=icontains:${searchPhrase}` : ''
    queryParams.push(searchQuery.toLowerCase())
  }

  for (const key in filters) {
    const filter = filters[key as keyof MatterUpdatesFilters]

    if (filter) {
      const { operator, values } = filter

      if (values?.length) {
        if (key === 'date') {
          const valuesString = values.map(val => moment(val.value).format('YYYY-MM-DD'))
          queryParams.push(`created_date=${String(operator?.value).toLowerCase()}:${valuesString}`)
        }

        if (key === 'updatedBy') {
          const valuesString = values.map(val => val.value)
          const key = operator?.value === 'INTERNAL' ? 'update_from_user' : 'cg_contact'
          queryParams.push(`${key}=is:${valuesString}`)
        }

        if (key === 'vendor') {
          const valuesString = values.map(val => val.value)
          queryParams.push(`vendor=${String(operator?.value).toLowerCase()}:${valuesString}`)
        }

        if (key === 'visibility') {
          queryParams.push(`is_internal=${values[0].value === 'internal'}`)
        }
      }
    }
  }

  return `${queryParams.join('&')}`
}
