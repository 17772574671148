import BaseAsyncSelect from './BaseAsyncSelect'
import { loadOptionsSelect2Json } from '../helpers'
import { VENDOR_SELECT_URL } from '../urls'
import { PaginatedSelect } from 'simple-core-ui'
import { toReactSelect } from '../serializers'

const VendorSelect = ({
  id,
  value,
  isClearable,
  onChange,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  paginated = false,
  serializer,
  defaultOptions,
  defaultAction,
  style,
  filterOption,
  withNoneOption,
  withInactiveVendors = true,
  placeholder = 'Search vendors...',
  ariaLabel,
  className,
  classNamePrefix
}) => {
  return paginated ? (
    <PaginatedSelect
      ariaLabel={ariaLabel}
      url={`${VENDOR_SELECT_URL}?all=${withInactiveVendors}`}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toReactSelect}
      defaultOptions={defaultOptions}
      styles={style}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      placeholder={placeholder}
      limit={limit}
      filterOption={filterOption}
      withNoneOption={withNoneOption}
      className={className}
      classNamePrefix={classNamePrefix}
    />
  ) : (
    <BaseAsyncSelect
      id={id}
      ariaLabel={ariaLabel}
      placeholder={placeholder}
      limit={limit}
      onChange={onChange}
      styles={style}
      value={value}
      isMulti={isMulti}
      loadOptions={loadOptionsSelect2Json(VENDOR_SELECT_URL, {}, withNoneOption)}
      isClearable={isClearable}
      isPortal={isPortal}
    />
  )
}

export default VendorSelect
