import * as React from 'react'

class DjangoPageContainer extends React.Component {
  wrapper = React.createRef()
  djangoNode = document.getElementById('django')

  componentDidMount() {
    this.init()
  }

  init = async () => {
    await this.handshakeWithDjango()
    await this.updateTemplateStyling()
  }

  updateTemplateStyling = () => {
    if (this.djangoNode) {
      this.djangoNode.style.display = 'block'
      this.djangoNode.style.opacity = '1'
    }
  }

  handshakeWithDjango = () => {
    const { current } = this.wrapper
    if (current && current.parentNode && this.djangoNode) {
      current.parentNode.appendChild(this.djangoNode)
    }
  }

  render() {
    return this.wrapper && <span ref={this.wrapper} data-meta={'djangoPageRef'} />
  }
}

export default DjangoPageContainer
