import { useCallback } from 'react'
import { Input, Textarea } from 'simple-core-ui'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { components } from 'react-select'
import s from '../../MatterDetailContainer.scss'
import { makeGetRequest } from 'utils/api'
import ModalField from './ModalField'
import debounce from 'debounce-promise'

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      <section>
        {!props.data.email ? (
          <span className={s.multiPrimaryLabel}>{props.data.label}</span>
        ) : (
          <>
            <span className={s.multiPrimaryLabel}>{props.data.fullName}</span>
            <span className={s.multiSecondaryLabel}>{props.data.email}</span>
          </>
        )}
      </section>
    </components.MultiValueLabel>
  )
}

const NotifyForm = ({ user, subject, message, setUser, setSubject, setMessage, vendorId }) => {
  const loadOptions = useCallback(
    async search => {
      const response = await makeGetRequest('/user/my/contacts/', {
        params: { vendor: vendorId, term: search }
      })

      return response.map(user => {
        const emailMatch = user.match(/<(.*?)>/, '')
        const email = emailMatch ? emailMatch[1] : ''
        const nameMatch = user.match(/^[a-zA-Z ]+/)
        const fullName = nameMatch ? nameMatch[0] : ''

        return {
          email,
          fullName,
          label: user,
          value: email
        }
      })
    },
    [vendorId]
  )

  const debouncedLoadOptions = debounce(loadOptions, 250, {
    leading: false
  })

  return (
    <section>
      <ModalField label="To">
        <AsyncCreatableSelect
          key={vendorId ?? 0}
          isMulti
          placeholder="Select or add email..."
          defaultOptions
          loadOptions={debouncedLoadOptions}
          onChange={setUser}
          value={user}
          components={{ MultiValueLabel }}
        />
      </ModalField>
      <ModalField label="Subject">
        <Input onChangeCb={setSubject} text={subject} />
      </ModalField>

      <ModalField label="Message">
        <Textarea
          onChange={setMessage}
          styles={{ height: 250, whiteSpace: 'pre-wrap' }}
          value={message}
        />
      </ModalField>
    </section>
  )
}

export default NotifyForm
