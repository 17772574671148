import { useState, useEffect } from 'react'
import {
  Panel,
  DataTableWrapper,
  SwitchToggle,
  useLoading,
  UpdateToPremiumLabel
} from 'simple-core-ui'
import { getNormalizedCellContent } from './utils'
import s from './Preferences.scss'
import { useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { toPreferences } from './serializers'
import { Preference, Cell } from './types'
import { makePostRequest, makeGetRequest } from 'utils/api'
import { DisablePreferenceModal } from './DisablePreferenceModal'
import noop from 'lodash/noop'
import { hasModule, isBasicTaskManagementPlan } from 'utils/helpers'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'label', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const Preferences = () => {
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [preferences, setPreferences] = useState<Preference[]>([])
  const [preference, setPreference] = useState<Preference | null>(null)
  const [isDisableSyncToCalendarModalVisible, setIsDisableSyncToCalendarModalVisible] = useState(
    false
  )

  const toggleStatus = async (name: string, index: number) => {
    try {
      const url = '/event-management/settings/'

      await withLoadingLocks(
        makePostRequest(url, {
          value: !preferences[index].value,
          name
        })
      )
      setPreferences([
        ...preferences.slice(0, index),
        { ...preferences[index], value: !preferences[index].value },
        ...preferences.slice(index + 1)
      ])

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Preference ${preferences[index].label} successfully updated.`,
          level: 'success'
        }
      })

      window.location.reload()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchPreferences = async () => {
    try {
      if (hasModule('has_event_management') || hasModule('has_tasks')) {
        const preferences = await withLoadingLocks(makeGetRequest('/event-management/settings/'))
        setPreferences(toPreferences(preferences))
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onTogglePreference = (id: number) => {
    const index = preferences.findIndex(p => p.id === id)
    const preference = preferences[index]
    setPreference(preference)

    if (preference.value && preference.name === 'has_external_calendar_sync') {
      setIsDisableSyncToCalendarModalVisible(true)
    } else {
      toggleStatus(preference.name, index)
    }
  }

  const columns = [
    {
      columnKey: 'label',
      content: 'Preferences',
      isSortable: true,
      isFilterable: true,
      style: { width: '200px' },
      render: (cell: Cell) => {
        return <b>{cell.content}</b>
      }
    },
    {
      columnKey: 'description',
      content: '',
      isSortable: false,
      isFilterable: true
    },
    ...(!isBasicTaskManagementPlan()
      ? []
      : [
          {
            columnKey: 'premium',
            content: '',
            isSortable: false,
            isFilterable: false,
            style: { width: '150px' },
            render: () => <UpdateToPremiumLabel dataTestId="secure-lock" />
          }
        ]),
    {
      columnKey: 'value',
      content: (
        <>
          Active
          <AiOutlineInfoCircle className={s.infoTooltip} data-for="infoTooltip" data-tip />
          <ReactTooltip id="infoTooltip" type="light" effect="solid" place="top" border>
            Toggle calendar sync feature
          </ReactTooltip>
        </>
      ),
      render: (cell: Cell, row: Preference) => {
        const isActive = cell.content !== '----'
        return (
          <SwitchToggle
            id={`preference-${row.id}`}
            dataTestId={String(row.id)}
            checked={isActive}
            tooltipContent="Contact customer success to upgrade."
            onToggle={isBasicTaskManagementPlan() ? undefined : () => onTogglePreference(row.id)}
          />
        )
      }
    }
  ]

  useEffect(() => {
    fetchPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  return (
    <Panel
      title="Manage External Calendar Sync Preferences"
      subtitle="Use preferences to enable additional tasks and events features. "
      styles={{ boxShadow: 'none' }}
      className={s.preferences}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={preferences}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        hasTooltip
        categoryKey="preference"
        getNormalizedCellContent={getNormalizedCellContent}
        hasActionsHeader={false}
        hasPagination={false}
      />
      {isDisableSyncToCalendarModalVisible && (
        <DisablePreferenceModal
          toggleModal={() =>
            setIsDisableSyncToCalendarModalVisible(!isDisableSyncToCalendarModalVisible)
          }
          title={`Disable ${preference?.label}?`}
          content={
            <p>
              If you disable this setting,{' '}
              <b>all active calendar links for all users will permanently stop functioning</b>. Do
              you wish to continue?
            </p>
          }
          confirmCb={
            preference
              ? () =>
                  toggleStatus(
                    preference.name,
                    preferences.findIndex(p => p.id === preference.id)
                  )
              : noop
          }
        />
      )}
    </Panel>
  )
}

export default Preferences
