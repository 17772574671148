import keyMirror from 'keymirror'

export default keyMirror({
  PENDING_VENDORS_LOADING: null,
  VENDOR_LIST_FETCH_REQUESTED: null,
  VENDOR_LIST_FETCH_SUCCESS: null,
  VENDOR_LIST_RESET: null,
  VENDOR_SUMMARY_FETCH_REQUESTED: null,
  VENDOR_SUMMARY_FETCH_SUCCESS: null,
  UPDATE_ADD_VENDOR_FORM: null,
  ADD_NEW_VENDOR_REQUESTED: null,
  ADD_NEW_VENDOR_REQUEST: null,
  PUSH_NOTIFICATION: null,
  ADD_NEW_VENDOR_REQUEST_SUCCESS: null,
  UPDATE_VENDOR_REQUESTED: null,
  UPDATE_VENDOR_SUCCESS: null,
  UPDATE_VENDORS_SUCCESS: null
})
