import { Component } from 'react'
import Select, { components } from 'react-select'

class LegalRequestSelect extends Component {
  handleChange = selectedOption => {
    this.props.onChange(selectedOption)
  }
  renderValue = () => {
    return ({ children, ...props }) => (
      <components.SingleValue {...props}>{this.props.displayString}</components.SingleValue>
    )
  }
  render() {
    const {
      options,
      selectedOption,
      className,
      isDisabled,
      formatGroupLabel,
      hasRelationships
    } = this.props

    const _options = hasRelationships ? [...options[0].options, ...options[1].options] : options
    const displayPlaceholder = !_options?.filter(option => option.value === selectedOption.value)
      ?.length

    return displayPlaceholder ? (
      <Select
        name="form-field-name"
        value={{ value: 'None', label: 'None' }}
        onChange={this.handleChange}
        options={options}
        components={{ SingleValue: this.renderValue() }}
        isDisabled={isDisabled}
        isClearable={false}
        formatGroupLabel={formatGroupLabel}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    ) : (
      <Select
        name="form-field-name"
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isClearable={false}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        {...this.props}
      />
    )
  }
}

export default LegalRequestSelect
