import cn from 'classnames'
import { Button } from 'simple-core-ui'
import s from './ContentHeader.scss'

const ContentHeader = ({
  formName,
  userRole,
  pageDescription,
  cancelLegalRequest,
  createLegalRequest,
  isMenuExpanded,
  isSidebarOpen
}) => (
  <section
    className={cn(
      {
        [s.sidebarOpen]: isSidebarOpen,
        [s.menuExpanded]: isMenuExpanded
      },
      s.headerContainer
    )}
  >
    <h2 className={s.title}>{formName}</h2>
    <h4 ref={pageDescription} className={s.description}>
      Select and fill out your legal request below. Click submit when complete.
    </h4>
    <section className={s.buttonsContainer}>
      <Button key="1" onClick={cancelLegalRequest} isSecondary>
        Cancel
      </Button>
      <Button key="2" onClick={createLegalRequest} isPrimary testid="submit_request">
        Submit Request
      </Button>
    </section>
  </section>
)

export default ContentHeader
