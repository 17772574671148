import { useSelector } from 'react-redux'

export const useMatterGroupLabels = () => {
  const matterGroupLabels = useSelector(
    ({ app }) =>
      app?.credentials?.custom?.names?.matter_group_management || [
        'Practice Area',
        'Practice Areas'
      ]
  )
  return matterGroupLabels
}

export const useEntityLabels = () => {
  const entityLabels = useSelector(
    ({ app }) =>
      app?.credentials?.custom?.names?.legal_entity_management || [
        'Practice Area',
        'Practice Areas'
      ]
  )

  return entityLabels
}
