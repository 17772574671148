import {
  DateFilter,
  UserFilter,
  CheckboxFilter,
  RuleFilter,
  RuleCategoryFilter
} from 'common/Filters'
import { Filters } from 'simple_review/@types/list'
import { useSimpleReviewContext } from 'simple_review/hooks'
import s from './FiltersContainer.scss'
import { getActiveRuleModules } from 'rules/utils'

interface Props {
  reset: number
  filters: Filters
  handleFilters(filterObj: Partial<Filters>, reset?: number): void
}

const resetFilter = {
  rules: null,
  ruleCategory: null,
  modifiedBy: null,
  lastModified: null,
  conditions: null,
  actions: null
}

const mapOptions = (options: Array<{ name: string; display_name: string }>) =>
  options.map(({ name, display_name }) => ({
    value: name,
    label: display_name
  }))

const activeRuleModules = getActiveRuleModules()

const FiltersContainer = ({ reset, handleFilters, filters }: Props) => {
  const { state } = useSimpleReviewContext()

  const { availableActions, availableFields } = state
  const conditions = mapOptions(availableFields)
  const actions = mapOptions(availableActions)

  const hasOnlyAI = activeRuleModules.hasAI && !activeRuleModules.hasIIW

  return (
    <div className={s.filterContainer}>
      {/* @ts-expect-error */}
      <RuleFilter
        initialValue={filters.rules}
        reset={reset}
        onSelect={(item: Array<Record<string, number>>) => handleFilters({ rules: item })}
        category={filters.category}
      />
      {/* @ts-expect-error */}
      <RuleCategoryFilter
        initialValue={filters.ruleCategory}
        reset={reset}
        onSelect={(item: Array<Record<string, number>>) => handleFilters({ ruleCategory: item })}
      />
      {/* @ts-expect-error */}
      <UserFilter
        title="Modified By"
        isMulti
        paginated
        initialValue={filters.modifiedBy}
        reset={reset}
        onSelect={(item: Array<Record<string, number>>) => handleFilters({ modifiedBy: item })}
      />
      {/* @ts-expect-error */}
      <DateFilter
        title="Last Modified"
        initialValue={filters.lastModified}
        reset={reset}
        customLabel={() => `Last Modified`}
        onSelect={(item: Record<string, string>) => handleFilters({ lastModified: item })}
      />
      {!hasOnlyAI && (
        /* @ts-expect-error */
        <CheckboxFilter
          initialValue={filters.conditions}
          title="Rule Conditions"
          reset={reset}
          options={conditions}
          onSelect={(item: Array<Record<string, string>>) => handleFilters({ conditions: item })}
        />
      )}
      {!hasOnlyAI && (
        /* @ts-expect-error */
        <CheckboxFilter
          initialValue={filters.actions}
          title="Rule Actions"
          reset={reset}
          options={actions}
          onSelect={(item: Array<Record<string, string>>) => handleFilters({ actions: item })}
        />
      )}
      <div className={s.filterReset} onClick={() => handleFilters(resetFilter, reset + 1)}>
        Reset
      </div>
    </div>
  )
}

export default FiltersContainer
