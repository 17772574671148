import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({
  rowId,
  deleteMatter,
  editMatterLead,
  editMatterGroup,
  canDelete,
  canEdit,
  matterGroupLabels
}) => {
  const actions = [
    {
      name: 'EDIT_MATTER_LEAD',
      text: 'Edit Matter Lead',
      visible: canEdit,
      onClick() {
        editMatterLead(rowId)
      }
    },
    {
      name: 'EDIT_MATTER_GROUP',
      text: `Edit ${matterGroupLabels[0]}`,
      visible: canEdit,
      onClick() {
        editMatterGroup(rowId)
      }
    },
    {
      name: 'DELETE_MATTER',
      text: 'Delete',
      visible: canDelete,
      onClick() {
        deleteMatter(rowId)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
