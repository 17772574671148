import { useState, useEffect } from 'react'
import s from './Notes.scss'
import { Button, Input, useLoading } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'

const Notes = ({ scopeId, readOnly }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [note, setNote] = useState('')
  const [initialNote, setInitialNote] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const { notes } = await withLoadingLocks(
          makeGetRequest(`/manage/matters/matter_notes/${scopeId}/`)
        )
        setNote(notes)
        setInitialNote(notes)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveNote = async () => {
    try {
      const response = await withLoadingLocks(
        makePostRequest(`/manage/matters/matter_notes/${scopeId}/update/`, { value: note })
      )
      setIsEdit(false)
      setInitialNote(note)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  return (
    <>
      <div className={s.wrapper}>
        {!readOnly && (
          <>
            {!isEdit ? (
              <Button className={s.editBtn} isPrimary onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            ) : (
              <>
                <Button className={s.editBtn} isPrimary onClick={saveNote}>
                  Save
                </Button>
                <Button
                  className={s.editBtn}
                  isSecondary
                  onClick={() => {
                    setIsEdit(false)
                    setNote(initialNote)
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        )}
        <h3 className={s.title}>Invoice Notes</h3>
        <p className={s.subtitle}>Invoice Notes will appear on all invoices, emails and PDFs.</p>
        <Input
          type="textarea"
          onChangeCb={({ target: { value } }) => setNote(value)}
          placeholder="Type your note here"
          text={note}
          cssClass={s.notes}
          isDisabled={!isEdit}
          ariaLabel="notes"
        />
      </div>
    </>
  )
}

export default Notes
