import { FormatOptionLabelMeta } from 'react-select'

import { useAutosizeField } from 'simple-core-ui'
import { Currency as CurrencyContainer } from 'containers'
import { cleanNonNumeric } from 'utils/formatting'
import { Constant } from 'simple_review/@types/common'
import s from './Currency.scss'

interface Props {
  code: string | null
  amount: Constant | null
  onChange(newValue: Constant, newSubType?: string): void
  isPortal: boolean
  isReadOnly: boolean
}

interface ChangeParams {
  amount: string
  code: string | { value: string }
}

export const Currency = (props: Props) => {
  const onChange = ({ amount, code }: ChangeParams) => {
    let newCode = code

    if (newCode && typeof newCode !== 'string') {
      newCode = newCode.value
    }

    props.onChange({ value: String(amount), label: String(amount) }, newCode)
  }

  const currencyCode = props.code
  const amount = props.amount?.value ?? ''

  const { width: inputWidth } = useAutosizeField({
    autoWidth: true,
    value: amount,
    placeholder: 'Value',
    paddingLeft: 12,
    paddingRight: 32
  })

  return (
    <CurrencyContainer
      // this is not properly typed, or it doesn't cover 100% of events
      // @ts-expect-error
      onChange={onChange}
      classNameMap={{
        currencyWrapper: s.currencyWrapper,
        inputWrapper: s.inputWrapper,
        inputContainer: s.inputContainer,
        input: s.input,
        select: s.select,
        selectWrapper: s.selectWrapper
      }}
      selectedOption={currencyCode as string}
      amount={cleanNonNumeric(amount)}
      disabled={props.isReadOnly}
      size={'md'}
      isPortal={props.isPortal}
      hasError
      inputStyles={{ width: inputWidth }}
      selectStyles={{
        control: () => ({
          height: 32,
          paddingLeft: 12,
          background: 'white',
          borderRadius: 5,
          border: '1px solid #9a9a9a',
          fontFamily: 'Lato',
          fontWeight: '500',
          fontSize: 14,
          color: 'black',
          display: 'flex',
          justifyContent: 'space-between'
        }),
        valueContainer: provided => ({
          ...provided,
          padding: 0,
          flex: 1,
          width: 20
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        menu: provided => ({
          ...provided,
          width: 200
        })
      }}
      bulkInput={false}
      selectProps={{
        formatOptionLabel(data: Constant, meta: FormatOptionLabelMeta<Constant>) {
          if (meta.context === 'value') return data.value
          return data.label
        }
      }}
    />
  )
}
