import { RadioFilter } from 'common/Filters'
import { ControlledDatePicker } from 'containers'
import s from '../DynamicBulkTable.scss'
import { naOptions, nullOptions } from 'bulk/helpers'

const disabledDateRange = (type, currentInputSelection) => {
  if (currentInputSelection) {
    const { value1, value2 } = currentInputSelection

    return type === 'start' && value2
      ? { disabledDays: { after: new Date(value2) } }
      : type === 'end' && value1
      ? { disabledDays: { before: new Date(value1) } }
      : {}
  }
}

const DateFilter = ({
  reset,
  getFilterLabel,
  curField,
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  hasConfirmation,
  optWrapperStyle,
  width,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const basicFilterOptions = ['is null', 'is not null', 'is n/a', 'is not n/a']
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      width={500}
      title={filter.display_name}
      initialValue={
        curField?.field && {
          value: curField?.field
        }
      }
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt)
      }}
      customLabelFn={s =>
        getFilterLabel(curField?.filterOpLabel, filter.display_name, curField?.val1, curField?.val2)
      }
      isDisabled={
        curSelection
          ? curSelection === 'between'
            ? !curInputSelection?.value1 || !curInputSelection?.value2
            : !basicFilterOptions.includes(curSelection)
            ? !curInputSelection?.value1
            : false
          : false
      }
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onCancelCb={() => onCancel(filter.field)}
      onClear={() => onClear(filter.field)}
      hasConfirmation={hasConfirmation}
      options={[
        {
          value: 'between',
          renderLabel: props => (
            <div style={optWrapperStyle}>
              <p style={width}>between</p>
              <ControlledDatePicker
                key={`${curSelection}_from_date`}
                placeholder="MM/DD/YYYY"
                value={
                  curSelection === 'between' && curInputSelection?.op === 'between'
                    ? getInputValue(filter.field, 'between')
                    : ''
                }
                onChange={event => {
                  onFilterInputChange(filter.field, 'between', event ?? '')
                }}
                classNames={{
                  container: curSelection === 'between' ? s.datepicker : s.disabledDatepicker,
                  overlay: 'DayPickerInput-Overlay',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper'
                }}
                disabled={curSelection !== 'between'}
                dayPickerProps={disabledDateRange('start', curInputSelection)}
                {...props}
              />
              <p style={{ margin: '0 6px' }}>and</p>
              <ControlledDatePicker
                key={`${curSelection}_to_date`}
                placeholder="MM/DD/YYYY"
                onChange={event => {
                  onFilterInputChange(filter.field, 'between', null, event ?? '')
                }}
                value={
                  curSelection === 'between' && curInputSelection?.op === 'between'
                    ? getInputValue(filter.field, 'between', 1)
                    : ''
                }
                classNames={{
                  container: curSelection === 'between' ? s.datepicker : s.disabledDatepicker,
                  overlay: 'DayPickerInput-Overlay',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper'
                }}
                disabled={curSelection !== 'between'}
                dayPickerProps={disabledDateRange('end', curInputSelection)}
                {...props}
              />
            </div>
          )
        },
        {
          value: '<',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>before</p>
              <ControlledDatePicker
                key={`${curSelection}_before_date`}
                placeholder="MM/DD/YYYY"
                value={
                  curSelection === '<' && curInputSelection?.op === '<'
                    ? getInputValue(filter.field, '<')
                    : ''
                }
                onChange={event => {
                  onFilterInputChange(filter.field, '<', event ?? '')
                }}
                classNames={{
                  container: curSelection === '<' ? s.datepickerBig : s.disabledDatepickerBig,
                  overlay: 'DayPickerInput-Overlay',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper'
                }}
                disabled={curSelection !== '<'}
              />
            </div>
          )
        },
        {
          value: '>',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>after</p>
              <div>
                <ControlledDatePicker
                  key={`${curSelection}_after_date`}
                  placeholder="MM/DD/YYYY"
                  value={
                    curSelection === '>' && curInputSelection?.op === '>'
                      ? getInputValue(filter.field, '>')
                      : ''
                  }
                  onChange={event => {
                    onFilterInputChange(filter.field, '>', event ?? '')
                  }}
                  classNames={{
                    container: curSelection === '>' ? s.datepickerBig : s.disabledDatepickerBig,
                    overlay: 'DayPickerInput-Overlay',
                    overlayWrapper: 'DayPickerInput-OverlayWrapper'
                  }}
                  disabled={curSelection !== '>'}
                />
              </div>
            </div>
          )
        },
        {
          value: 'is',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>equal to</p>
              <ControlledDatePicker
                key={`${curSelection}_equal_to_date`}
                placeholder="MM/DD/YYYY"
                value={
                  curSelection === 'is' && curInputSelection?.op === 'is'
                    ? getInputValue(filter.field, 'is')
                    : ''
                }
                onChange={event => {
                  onFilterInputChange(filter.field, 'is', event ?? '')
                }}
                classNames={{
                  container: curSelection === 'is' ? s.datepickerBig : s.disabledDatepickerBig,
                  overlay: 'DayPickerInput-Overlay',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper'
                }}
                disabled={curSelection !== 'is'}
              />
            </div>
          )
        },
        ...nullOptions,
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default DateFilter
