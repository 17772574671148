import { Fragment } from 'react'
import FiltersContainer, {
  UserFilter,
  VendorGroupFilter,
  LegalEntityFilter,
  RadioFilter,
  VendorFilter
} from 'common/Filters'

const VendorListFilters = ({ filterParams, onSearch, selectedTab, permissions }) => {
  const {
    vendor_id,
    status,
    vendor_group_ids,
    billed_entities,
    vendor_leads,
    timekeeper_validation,
    counselgo_enabled,
    accruals_enabled
  } = filterParams
  return (
    <FiltersContainer
      onSearch={onSearch}
      filterParams={filterParams}
      render={(reset, filterSearch) => {
        return (
          <Fragment>
            <VendorFilter
              initialValue={vendor_id}
              reset={reset}
              isMulti
              onSelect={item => filterSearch('vendor_id', item)}
              paginated
            />
            {permissions.enableManageVendorGroups && (
              <VendorGroupFilter
                initialValue={vendor_group_ids}
                reset={reset}
                title="Vendor Groups"
                isMulti
                onSelect={item => filterSearch('vendor_group_ids', item)}
                paginated
              />
            )}
            <LegalEntityFilter
              initialValue={billed_entities}
              reset={reset}
              onSelect={item => filterSearch('billed_entities', item)}
              title="Entities Billed"
              isMulti
              paginated
              noPlural
            />
            <UserFilter
              initialValue={vendor_leads}
              title="Vendor Manager"
              reset={reset}
              isMulti
              onSelect={item => filterSearch('vendor_leads', item)}
              paginated
              withNoneOption
            />
            {selectedTab === 'All' && (
              <RadioFilter
                initialValue={status}
                reset={reset}
                onSelect={item => filterSearch('status', item)}
                title="Status"
                options={[
                  {
                    value: 'active',
                    label: 'Active'
                  },
                  {
                    value: 'deactivated',
                    label: 'Inactive'
                  }
                ]}
              />
            )}
            <RadioFilter
              initialValue={timekeeper_validation}
              reset={reset}
              onSelect={item => filterSearch('timekeeper_validation', item)}
              hasConfirmation={false}
              title="TK Validation"
              options={[
                {
                  value: true,
                  label: 'Yes',
                  alternativeLabel: 'TK w/ Validation'
                },
                {
                  value: false,
                  label: 'No',
                  alternativeLabel: 'TK w/o Validation'
                }
              ]}
            />
            <RadioFilter
              initialValue={accruals_enabled}
              reset={reset}
              onSelect={item => filterSearch('accruals_enabled', item)}
              title="Accruals"
              options={[
                {
                  value: true,
                  label: 'Yes',
                  alternativeLabel: 'Accruals Enabled'
                },
                {
                  value: false,
                  label: 'No',
                  alternativeLabel: 'Accruals Disabled'
                }
              ]}
            />
            <RadioFilter
              initialValue={counselgo_enabled}
              reset={reset}
              onSelect={item => filterSearch('counselgo_enabled', item)}
              title="CounselGO"
              options={[
                {
                  value: true,
                  label: 'Yes',
                  alternativeLabel: 'CounselGO Enabled'
                },
                {
                  value: false,
                  label: 'No',
                  alternativeLabel: 'CounselGO Disabled'
                }
              ]}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default VendorListFilters
