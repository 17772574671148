import { useState, useReducer, useEffect } from 'react'
import { DataTableWrapper, useLoading, Panel, Button } from 'simple-core-ui'
import { FaTrash, FaPencilAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import update from 'immutability-helper'
import { SSODomainModal, SSOUserModal } from './SSOConfigModal'
import swal from 'sweetalert'
import { Tabs } from './Tabs'
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest } from 'utils/api'
import { Params } from 'simple-core-ui/containers/Core/DataTableContainer/DataTableWrapper'
import { AxiosError } from 'axios'
import { ConnectionType, SSO_TABS } from './constants'
import cloneDeep from 'lodash/cloneDeep'

export interface UserBased {
  name: string
  idp_id: string
  connection_type: ConnectionType
  is_default: boolean
}

interface Domain {
  email_domain: string
  provisioned: boolean
}

export interface DomainBased {
  name: string
  idp_id: string
  connection_type: ConnectionType
  email_domains: Domain[]
}

export type Row = { id: number } & (UserBased | DomainBased)

interface IState {
  curUser: UserBased
  curDomain: DomainBased
  params: Params
}

interface Action {
  type: string
  payload: {
    row?: Row
    rows?: Row[]
    value?: string
    key?: string
    connectionType?: ConnectionType
    params?: Params
  }
}

type StringError = {
  error: string
}

type ListError = {
  error: Record<string, string[]>
}

type NestedListError = {
  error: Record<string, ListError[]>
}

export type ErrorState = StringError | ListError | NestedListError

const initialState: IState = {
  curDomain: {
    name: '',
    idp_id: '',
    connection_type: ConnectionType.CLIENT_BASED,
    email_domains: []
  },
  curUser: {
    name: '',
    idp_id: '',
    connection_type: ConnectionType.USER_BASED,
    is_default: false
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const reducer = (state: IState, action: Action): IState => {
  const { value, row, rows, params, key, connectionType } = action.payload
  const curObj = connectionType === ConnectionType.CLIENT_BASED ? 'curDomain' : 'curUser'
  switch (action.type) {
    case 'UPDATE_CUR_OBJ':
      if (!key) return state
      return update(state, {
        [curObj]: {
          [key]: {
            $set: value
          }
        }
      })
    case 'RESET_CUR_OBJ':
      return update(state, {
        [curObj]: {
          $set: initialState[curObj]
        }
      })
    case 'SELECT_OBJ':
      if (!row || !rows) return state
      // if id is not defined its just index
      const obj = rows[row.id]
      return update(state, {
        [curObj]: {
          $set: cloneDeep(obj)
        }
      })
    case 'UPDATE_TABLE_PARAMS':
      if (!params) return state
      return { ...state, params }
    default:
      throw new Error('Action not found')
  }
}

const userBasedColumns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  {
    columnKey: 'idp_id',
    content: 'IDP UUID',
    isSortable: true
  },
  {
    columnKey: 'is_default',
    content: 'Default',
    isSortable: true,
    render: (cell: any) => {
      return <>{cell.content.toString()}</>
    }
  }
]

const domainBasedColumns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  {
    columnKey: 'idp_id',
    content: 'IDP UUID',
    isSortable: true
  },
  {
    columnKey: 'email_domains',
    content: 'Email Domains',
    isSortable: true,
    render: (cell: any) => {
      if (!Array.isArray(cell.content)) {
        return null
      }

      return (
        <>
          {cell.content
            .map(
              (d: any) =>
                `${d.email_domain} (${d.provisioned ? 'provisioned' : 'not provisioned'}) `
            )
            .join(', ')}
        </>
      )
    }
  }
]

const SSOConfigContainer = () => {
  const [showModal, setShowModal] = useState(false)
  const [rows, setRows] = useState([])
  const [connectionType, setConnectionType] = useState<ConnectionType>(
    SSO_TABS[0].value as ConnectionType
  )
  const [isLoading, withLoadingLocks] = useLoading()
  const [isEdit, setIsEdit] = useState(false)
  const [errorState, setErrorState] = useState<ErrorState | null>(null)

  const [localState, dispatchState] = useReducer(reducer, initialState)
  const { curUser, curDomain, params } = localState

  const dispatch = useDispatch()

  async function fetchRows() {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(`/accounts/login/list_sso_connections/${connectionType}/`)
      )
      setRows(response)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const toggleModal = (isEdit = false) => {
    setShowModal(!showModal)
    setIsEdit(isEdit)
    setErrorState(null)
    dispatchState({
      type: 'RESET_CUR_OBJ',
      payload: {
        connectionType
      }
    })
  }

  const addRow = async () => {
    const row = connectionType === ConnectionType.CLIENT_BASED ? curDomain : curUser
    try {
      await makePostRequest(`/accounts/login/create_client_sso_config/${connectionType}/`, row)
      toggleModal()
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          level: 'success',
          message: 'Successfully created connection'
        }
      })
      fetchRows()
    } catch (e) {
      const error = e as AxiosError
      setErrorState(error?.response?.data as ErrorState)
    }
  }

  const editRow = async () => {
    const row = connectionType === ConnectionType.CLIENT_BASED ? curDomain : curUser
    try {
      await makePutRequest(
        `/accounts/login/update_client_sso_config/${connectionType}/${row.idp_id}/`,
        row
      )
      toggleModal()
      fetchRows()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const handleChangeCurObj = (key: string, value: string) => {
    dispatchState({
      type: 'UPDATE_CUR_OBJ',
      payload: {
        key,
        value,
        connectionType
      }
    })
  }

  const updateTable = (params: Params) => {
    dispatchState({
      type: 'UPDATE_TABLE_PARAMS',
      payload: {
        params
      }
    })
  }

  const deleteRow = async (row: Row) => {
    const willDelete = await swal({
      title: 'Delete Connection',
      text: 'Are you sure you want to delete this connection?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })
    if (willDelete) {
      try {
        await makeDeleteRequest(
          `/accounts/login/delete_client_sso_config/${connectionType}/${row.idp_id}/`
        )
        fetchRows()
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error
        })
      }
    }
  }

  const showEditModal = (row: Row) => {
    toggleModal(true)
    dispatchState({
      type: 'SELECT_OBJ',
      payload: {
        row,
        rows,
        connectionType
      }
    })
  }

  useEffect(() => {
    setRows([])
    updateTable(initialState.params)
    fetchRows()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionType])

  return (
    <Panel
      title="Manage SSO Connections"
      rightActions={[
        <Button onClick={() => toggleModal()} isPrimary key="0">
          Add
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
    >
      <Tabs selectedTab={connectionType} setSelectedTab={setConnectionType} />
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={rows}
        columns={
          connectionType === ConnectionType.CLIENT_BASED ? domainBasedColumns : userBasedColumns
        }
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        actions={[
          {
            icon: FaTrash,
            tooltip: 'Delete',
            onClick: (e: Event, row: Row) => deleteRow(row)
          },
          {
            icon: FaPencilAlt,
            tooltip: 'Edit',
            onClick: (e: Event, row: Row) => showEditModal(row)
          }
        ]}
        categoryKey="status"
        // Need to rerender actions on delete
        customActionsMemoizationDeps={[rows]}
      />

      {showModal &&
        (connectionType === ConnectionType.CLIENT_BASED ? (
          <SSODomainModal
            domainBased={curDomain}
            toggleModal={toggleModal}
            onChange={handleChangeCurObj}
            onCreate={addRow}
            onEdit={editRow}
            isEdit={isEdit}
            error={errorState}
          />
        ) : (
          <SSOUserModal
            userBased={curUser}
            toggleModal={toggleModal}
            onChange={handleChangeCurObj}
            onCreate={addRow}
            onEdit={editRow}
            isEdit={isEdit}
            error={errorState}
          />
        ))}
    </Panel>
  )
}

export default SSOConfigContainer
