import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import s from './EnhancedMatterUpdates.scss'
import {
  Button,
  ModalContainer,
  useLoading,
  Modal,
  RichTextEditor,
  Tooltip,
  UpdateToPremiumLabel
} from 'simple-core-ui'
import { MatterUpdatesHeader } from './Header'
import { Comment } from './Comment'
import { MatterUpdatesFilters, MatterUpdate, Vendor } from './types'
import cn from 'classnames'
import { ActionsPopover } from './ActionsPopover'
import { makeGetRequest, makePostRequest, makeDeleteRequest, makePutRequest } from 'utils/api'
import { fromMatterUpdate } from './serializers'
import { isBasicTaskManagementPlan, isNoneMatterManagementPlan, openLink } from 'utils/helpers'
import { ShareUpdate } from './ShareUpdate'
import Filters from './Filters/Filters'
import { createQueryParamsUrl } from './utils'
import { IoMdLock } from 'react-icons/io'

const initialFilters = {
  date: null,
  updatedBy: null,
  vendor: null,
  visibility: null
}
import { AxiosError } from 'axios'
import pluralize from 'pluralize'

interface Props {
  user: User
  matterId: number
  readOnly: boolean
  isConfidential: boolean
  updateId: number | null
  setUpdateId: (id: number | null) => void
}

const isBasicPlan = isBasicTaskManagementPlan()
const isNonePlan = isNoneMatterManagementPlan()

const { matter_updates_sharing_permissions: hasMatterUpdateSharingPermissions } =
  window.serverContext.get('data_for_react') ?? {}

const footerContent = (
  <div className={s.modalFooterContent}>
    <IoMdLock />
    <div className={s.textContainer}>
      <p>
        This vendor has received the max number of updates allowed. Contact Customer Success to
        upgrade.
      </p>
    </div>
  </div>
)

const footerStyle = {
  justifyContent: footerContent && isBasicPlan ? 'space-between' : 'flex-end'
}

const EnhancedMatterUpdates = ({
  user,
  matterId,
  readOnly,
  isConfidential,
  updateId,
  setUpdateId
}: Props) => {
  const [comments, setComments] = useState<MatterUpdate[]>([])
  const [editorState, setEditorState] = useState('')
  const [modalEditorState, setModalEditorState] = useState('')
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false)
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null)
  const [isInternalComment, setIsInternalComment] = useState(true)
  const [isShareModalVisible, setIsShareModalVisible] = useState(false)
  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([])
  const [totalCgUsersCount, setTotalCgUsersCount] = useState(0)
  const [search, setSearch] = useState('')
  const [isLoading, withLoadingLocks] = useLoading()
  const [filters, setFilters] = useState<MatterUpdatesFilters>(initialFilters)
  const [showLockedFooterMessage, setShowLockedFooterMessage] = useState(false)

  const updateCommentRef = useRef<HTMLDivElement | null>(null)
  const [statusUpdateId, setStatusUpdateId] = useState(updateId)
  const isEmptyFilter = Object.values(filters).every(value => !value)

  const dispatch = useDispatch()

  const fetchMatterUpdates = async (
    updateFilters?: MatterUpdatesFilters,
    searchPhrase?: string
  ) => {
    const queryString = createQueryParamsUrl(updateFilters || filters, searchPhrase || search)
    try {
      const url = `/manage/matters/${matterId}/matter_status_updates/?${queryString}`
      const { matter_status_updates } = await withLoadingLocks(makeGetRequest(url))
      setComments(matter_status_updates)
    } catch (err) {
      const error = err as AxiosError
      if (error.response?.status === 403) {
        window.location.href = '/'
      }
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchMatterUpdates(filters, search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, search])

  const validateVendorMatterUpdateLimit = async () => {
    try {
      const url = `/manage/matters/referral_list/${matterId}/?vrn=_matter_updates`
      const response = await makeGetRequest(url)
      const isMatterUpdateSharingLimitExceeded = response.results.some(
        (result: { matter_updates_sharing_limit_exceeded: boolean }) =>
          result.matter_updates_sharing_limit_exceeded
      )
      setShowLockedFooterMessage(isMatterUpdateSharingLimitExceeded && isBasicPlan)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    validateVendorMatterUpdateLimit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearFilters = () => setFilters(initialFilters)

  const resetState = () => {
    setSelectedVendors([])
    setTotalCgUsersCount(0)
    setIsShareModalVisible(false)
    setIsEditModalVisible(false)
    setSelectedCommentId(null)
    setEditorState('')
    setModalEditorState('')
    setIsInternalComment(true)
  }

  const closeDeleteUpdateModal = () => {
    setIsDeleteModalVisible(false)
    setSelectedCommentId(null)
  }

  const saveMatterUpdate = async (mode: string = '') => {
    const comment = mode ? modalEditorState : editorState
    const sharedWithVendors = mode === 'share' ? selectedVendors : []
    const requestBody = fromMatterUpdate(
      mode,
      comment,
      selectedCommentId,
      isInternalComment,
      sharedWithVendors
    )

    try {
      if (mode === 'edit') {
        const url = `/manage/matters/${matterId}/matter_status_updates/${selectedCommentId}/`
        const response = await withLoadingLocks(makePutRequest(url, requestBody))

        const updatedComments = comments.map(comment =>
          comment.id === selectedCommentId ? response : comment
        )
        setComments(updatedComments)
      } else {
        const url = `/manage/matters/${matterId}/matter_status_updates/`
        await withLoadingLocks(makePostRequest(url, requestBody))
        fetchMatterUpdates()
        validateVendorMatterUpdateLimit()
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    resetState()
  }

  const deleteMatterUpdate = async () => {
    try {
      const url = `/manage/matters/${matterId}/matter_status_updates/${selectedCommentId}/`
      await withLoadingLocks(makeDeleteRequest(url))
      fetchMatterUpdates()
      validateVendorMatterUpdateLimit()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    closeDeleteUpdateModal()
  }

  useEffect(() => {
    if (updateCommentRef.current) {
      const headerHeight = 125
      const position = updateCommentRef.current.getBoundingClientRect().top
      window.scrollTo({ top: position - headerHeight })
      updateCommentRef.current.style.backgroundColor = 'rgba(60, 152, 253, 0.05)'
    }
    setUpdateId(null)
    const url = new URL(window.location.href)
    url.searchParams.delete('update_id')
    window.history.replaceState({}, '', url)
  }, [comments])
  const downloadXlsx = async () => {
    const url = `/manage/matters/matter_status/excel/download/${matterId}/`
    if (comments.length < 50) {
      openLink(url)
    } else {
      await makeGetRequest(url)
      setIsDownloadModalVisible(true)
    }
  }

  const openEditCommentModal = (comment: MatterUpdate) => {
    setSelectedCommentId(comment.id)
    setModalEditorState(comment.status_update)
    setIsEditModalVisible(true)
  }

  const openDeleteCommentModal = (comment: MatterUpdate) => {
    setSelectedCommentId(comment.id)
    setIsDeleteModalVisible(true)
  }

  const internalAndVendors = (
    <span
      className={s.option}
      style={
        isNonePlan || isConfidential || !hasMatterUpdateSharingPermissions ? { opacity: 0.5 } : {}
      }
    >
      <input
        type="radio"
        checked={!isInternalComment}
        onChange={() => {
          setIsInternalComment(false)
          setModalEditorState(editorState)
          setIsShareModalVisible(true)
        }}
        disabled={readOnly || isNonePlan || isConfidential || !hasMatterUpdateSharingPermissions}
      />
      Internal & Vendors
    </span>
  )

  const categorySelectionSection = (
    <>
      <p className={s.fieldLabel}>
        Select who to share the matter update with <span style={{ color: '#FF7272' }}>*</span>
      </p>
      <div className={cn(s.radio, { [s.disabled]: readOnly })}>
        <span className={s.option}>
          <input
            type="radio"
            checked={isInternalComment}
            onChange={() => setIsInternalComment(true)}
            disabled={readOnly}
          />
          Internal
        </span>
        {isNonePlan ? (
          <Tooltip
            trigger={internalAndVendors}
            content={
              <div className={s.popper}>
                <div className={s.textWrap}>
                  Sharing matter updates with vendors is only available with a matter management
                  plan. Contact customer success to upgrade.
                </div>
                <UpdateToPremiumLabel />
              </div>
            }
            placement="left"
            hasArrow={false}
            contentClassName={s.tooltip}
          />
        ) : isConfidential ? (
          <Tooltip
            trigger={internalAndVendors}
            content={
              <div className={s.textWrap}>
                Updates on confidential matters cannot be shared with vendors.
              </div>
            }
            placement="left"
            hasArrow={false}
            contentClassName={s.confidentialTooltip}
          />
        ) : (
          internalAndVendors
        )}
      </div>
    </>
  )

  const renderCustomAction = (comment: MatterUpdate) => (
    <ActionsPopover
      hoverActionClassName={s.hoverAction}
      comment={comment}
      editComment={openEditCommentModal}
      deleteComment={openDeleteCommentModal}
    />
  )

  const handleSearch = (searchPhrase: string) => setSearch(searchPhrase)

  const isShareModalInvalid =
    isShareModalVisible && (isLoading || !selectedVendors.length || !modalEditorState)
  const isEditModalInvalid = isEditModalVisible && !modalEditorState

  return (
    <div className={s.container}>
      <Filters
        updateFilters={filters}
        setUpdateFilters={filters => setFilters(filters)}
        clearFilters={clearFilters}
        scopeId={matterId}
        className={s.filters}
        isNonePlan={isNonePlan}
        isMatterUpdatePermissionDenied={!hasMatterUpdateSharingPermissions}
      />
      <MatterUpdatesHeader
        search={search}
        onSearch={handleSearch}
        downloadXlsx={downloadXlsx}
        isLoading={isLoading}
      />
      <div className={s.commentSection}>
        {comments.length > 0 ? (
          <>
            <div className={s.updatePill}>{`${pluralize('Updates', comments.length, true)}`}</div>
            {comments.map(comment => (
              <div
                className={s.row}
                ref={el => {
                  if (comment.id === statusUpdateId) {
                    updateCommentRef.current = el
                  }
                }}
                key={comment.id}
              >
                <Comment
                  key={comment.id}
                  comment={comment}
                  loggedInUser={user}
                  readOnly={readOnly}
                  customAction={renderCustomAction}
                />
              </div>
            ))}
          </>
        ) : (
          <div className={s.noComments}>
            {isEmptyFilter ? 'No Updates Added' : 'No updates match the applied filter(s)'}
          </div>
        )}
      </div>
      {categorySelectionSection}
      <RichTextEditor
        value={editorState}
        onChange={value => setEditorState(value)}
        readOnly={readOnly}
        className={cn(s.editor, { [s.disabled]: readOnly })}
        restrictMaxHeight
        isTiny
        hasNewDesign
      />
      {!readOnly && (
        <Button
          onClick={() => saveMatterUpdate()}
          hasNewDesign
          isPrimary
          className={s.submitButton}
          isDisabled={isLoading || !editorState.trim().length}
        >
          Submit
        </Button>
      )}
      <Modal
        title={isEditModalVisible ? 'Edit Update' : 'Share Matter Update'}
        confirmText="Submit"
        size={isEditModalVisible ? 'md' : 'xl'}
        isVisible={isEditModalVisible || isShareModalVisible}
        content={
          isEditModalVisible ? (
            <RichTextEditor
              value={modalEditorState}
              onChange={value => setModalEditorState(value)}
              isExpanded
              hasNewDesign
            />
          ) : (
            <ShareUpdate
              setBaseEditorState={setEditorState}
              editorState={modalEditorState}
              setEditorState={setModalEditorState}
              isInternalComment={isInternalComment}
              setIsInternalComment={setIsInternalComment}
              selectedVendors={selectedVendors}
              setSelectedVendors={setSelectedVendors}
              mId={matterId}
              isLoading={isLoading}
              withLoadingLocks={withLoadingLocks}
              setIsShareModalVisible={setIsShareModalVisible}
              totalCgUsersCount={totalCgUsersCount}
              setTotalCgUsersCount={setTotalCgUsersCount}
            />
          )
        }
        hasNewButtons
        cancelCb={resetState}
        confirmCb={() =>
          isEditModalVisible ? saveMatterUpdate('edit') : saveMatterUpdate('share')
        }
        isDisabled={isShareModalInvalid || isEditModalInvalid}
        contentClassName={cn({ [s.shareModalContent]: isShareModalVisible })}
        {...(showLockedFooterMessage && isShareModalVisible ? { footerContent, footerStyle } : {})}
      />
      {isDeleteModalVisible && (
        <ModalContainer
          title="Delete Update?"
          size="sm"
          content="The update will no longer be visible for internal or vendor users. Do you wish to continue?"
          confirmCb={deleteMatterUpdate}
          cancelCb={closeDeleteUpdateModal}
          hasNewButtons
          confirmText="Delete"
          contentClassName={s.modalContent}
          wrapperStyles={{ maxWidth: 400 }}
        />
      )}
      {isDownloadModalVisible && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your report is ready."
          size="sm"
          hasNewButtons
          confirmText="OK"
          hasCloseIcon={false}
          confirmCb={() => setIsDownloadModalVisible(false)}
          contentClassName={s.modalContent}
        />
      )}
    </div>
  )
}

export default EnhancedMatterUpdates
