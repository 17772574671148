import { Component } from 'react'
import { connect } from 'react-redux'

import { usesPrioritySystem, canSimplifyCondition } from 'rules/utils'
import Rule from '../components/Rule'

import ACT from 'rules/actions'

@connect(({ rules }, ownProps) => {
  const { engine, isSaving } = rules
  const { rulesList } = rules[engine]
  const ruleIndex = ownProps.ruleIndex
  const rule = rulesList[ruleIndex]
  const condition = rule.condition
  const { actions, timeline, timelineIndex } = rule
  const canUndo = timelineIndex > 0
  const canRedo = timelineIndex < timeline.length - 1
  const canSimplify = canSimplifyCondition(condition)

  return {
    condition,
    actions,
    isSaving,
    engine,
    canUndo,
    canRedo,
    canSimplify
  }
})
class RuleContainer extends Component {
  state = {}

  changeBooleanOperator = (booleanOperator, refs, coords) => {
    const { ruleIndex } = this.props
    this.props.dispatch({
      type: ACT.UPDATE_BOOLEAN_OPERATOR,
      payload: {
        ruleIndex,
        booleanOperator,
        refs,
        coords
      }
    })
  }

  createRuleAction = () => {
    this.props.dispatch({
      type: ACT.CREATE_RULE_ACTION,
      payload: {
        ruleIndex: this.props.ruleIndex
      }
    })
  }

  render() {
    const { actions, engine } = this.props
    const canAddActions = !(actions.length && usesPrioritySystem(engine))

    return (
      <Rule
        {...this.state}
        {...this.props}
        changeBooleanOperator={this.changeBooleanOperator}
        createRuleAction={canAddActions && this.createRuleAction}
      />
    )
  }
}

export default RuleContainer
