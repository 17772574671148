import moment from 'moment'
const donutColors = ['#1A4375', '#0789D7', '#AFE4EF', '#5BB9E3', 'rgb(209, 231, 236)', '#C4C4C4']

export const donutData = ({ data, labels }) => ({
  labels,
  datasets: [
    {
      label: '',
      data,
      backgroundColor: donutColors,
      borderColor: donutColors,
      borderWidth: 1
    }
  ]
})

export const NoData = 'No Data'
export const GENDER = {
  M: 'Male',
  F: 'Female',
  NB: 'Non-Binary',
  PNTS: 'Prefer not to say'
}

export const STATUS = {
  DISABILITY: 'Disability Status',
  VETERAN: 'Veteran Status',
  LGBTQ: 'LGBTQ+ Status'
}

export const STATUS_COLOR = {
  DISABILITY: '#5EC9BF',
  VETERAN: '#BB342F',
  LGBTQ: '#C85089'
}

export const RACE = {
  W: 'White',
  A: 'Asian',
  HL: 'Hispanic/Latinx',
  PI: 'Pacific Islander',
  AAB: 'African American/Black',
  AIAN: 'American Indian/Alaskan Native',
  PNTS: 'Prefer not to say',
  MULTI: 'Multiracial'
}

const donutColorMap = {
  [GENDER.M]: '#1C65A5',
  [GENDER.F]: '#0789D7',
  [GENDER.NB]: '#54BDDE',
  [GENDER.PNTS]: '#AFE4EF',
  [NoData]: '#E0E0E0'
}

const barChartColorMap = {
  [RACE.W]: '#436C85',
  [NoData]: '#E0E0E0',
  [RACE.A]: '#EF595A',
  [RACE.HL]: '#F5A11B',
  [RACE.PI]: '#FD7B47',
  [RACE.AAB]: '#54BDDE',
  [RACE.PNTS]: '#357978',
  [RACE.MULTI]: '#820263',
  [RACE.AIAN]: '#57C787'
}

const barChartColors = [
  '#144766D7',
  '#E0E0E0D7',
  '#820263D7',
  '#EF595AD7',
  '#F5A11BD7',
  '#54BDDED7',
  '#57C787D7',
  '#FD7B47D7',
  '#357978D7'
]

const sortByLabel = (colors, labels, map) => {
  let result = colors.slice()
  labels.forEach((l, i) => {
    const c = map[l]
    result[i] = c
  })
  return result
}

export const barChartData = ({ data, labels }) => {
  const sortedBarColors = sortByLabel(barChartColors, labels, barChartColorMap)
  return {
    labels,
    datasets: [
      {
        label: '',
        data,
        backgroundColor: sortedBarColors,
        borderColor: sortedBarColors,
        borderWidth: 1
      }
    ]
  }
}

export const multiBarChartData = (datasets, type) => {
  const labels = datasets[0].labels
  const sortedBarColors =
    type === 'race'
      ? sortByLabel(barChartColors, labels, barChartColorMap)
      : sortByLabel(donutColors, labels, donutColorMap)

  return {
    labels,
    datasets: datasets.map((dataset, i) => ({
      label: `'${moment()
        .subtract('year', datasets.length - 1 - i)
        .endOf('year')
        .format('YY')}`,
      data: dataset.data,
      backgroundColor: sortedBarColors,
      borderColor: sortedBarColors,
      borderWidth: 1
    }))
  }
}

export const donutOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: false
    },
    legend: {
      display: false
    }
  }
}

export const multiBarOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      fontSize: 12
    }
  },
  plugins: {
    datalabels: {
      labels: {
        value: {
          anchor: 'start',
          align: 'bottom',
          clip: false,
          color: '#ababab',
          font: {
            size: 14
          },
          formatter: function(value, context) {
            return context.dataset.label
          }
        },
        title: {
          anchor: 'end',
          align: 'top',
          clamp: true,
          labelController: function(context) {
            return context.dataset.data[context.dataIndex] >= 95 ? { offset: 0 } : { offset: 4 }
          },
          color: '#000',
          font: {
            size: 14
          },
          formatter: function(value, context) {
            return value + '%'
          }
        }
      }
    },
    tooltip: { enabled: false },
    legend: {
      display: false
    }
  },
  layout: {
    padding: {
      top: 30,
      bottom: 13
    }
  },
  scales: {
    y: {
      ticks: {
        stepSize: 20,
        callback: (label, index, labels) => {
          return label + '%'
        },
        color: () => {
          return '#ababab'
        }
      },
      max: 100,
      border: {
        display: false
      },
      grid: {
        color: context => (context.tick.value % 20 ? '#fff' : '#EBEBEB')
      }
    },
    x: {
      ticks: {
        display: false
      },
      border: {
        display: false
      },
      grid: {
        display: false
      }
    }
  }
}

export const barOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    labels: {
      fontSize: 12
    }
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      clamp: true,
      labelController: function(context) {
        return context.dataset.data[context.dataIndex] >= 95 ? { offset: 0 } : { offset: 4 }
      },
      color: '#000',
      font: {
        size: 14
      },
      formatter: function(value, context) {
        return value + '%'
      }
    },
    tooltip: { enabled: false },
    legend: {
      display: false
    }
  },
  layout: {
    padding: {
      top: 30
    }
  },
  scales: {
    y: {
      ticks: {
        stepSize: 20,
        callback: (label, index, labels) => {
          return label + '%'
        },
        color: () => {
          return '#ababab'
        }
      },
      max: 100,
      border: {
        display: false
      },
      grid: {
        color: context => (context.tick.value % 20 ? '#fff' : '#EBEBEB')
      }
    },
    x: {
      ticks: {
        display: false
      },
      border: {
        display: false
      },
      grid: {
        display: false
      }
    }
  }
}

export const tabsButton = {
  position: 'absolute',
  right: -4,
  bottom: 14,
  height: 42,
  width: 160,
  fontSize: 14
}

export const dropdownButton = {
  height: 42,
  width: 160,
  fontSize: 14
}

export const selectStyle = {
  control: base => ({
    ...base,
    border: '1px solid #5399F6',
    '&:hover': {
      border: '1px solid #5399F6'
    }
  }),
  indicatorSeparator: base => ({
    ...base,
    opacity: 0
  }),
  indicatorsContainer: base => ({
    ...base,
    color: '#5399F6'
  }),
  singleValue: base => ({
    ...base,
    fontWeight: '700',
    color: '#5399F6'
  }),
  placeholder: base => ({
    ...base,
    fontWeight: '700',
    color: '#5399F6'
  })
}

export let VENDOR_COMPARISON = [
  {
    title: 'GENDER',
    key: 'gender',
    minWidth: 275,
    marginTop: 30,
    columns: [
      { title: 'Female', color: '#0789D7' },
      { title: 'Male', color: '#144766' },
      { title: 'Non-binary', color: '#54BDDE' }
    ]
  },
  {
    title: 'RACE',
    key: 'race',
    minWidth: 657,
    columns: [
      { title: 'AK/Native American', color: '#57C787' },
      { title: 'Asian', color: '#EF595A' },
      { title: 'Black', color: '#54BDDE' },
      { title: 'Latinx', color: '#F5A11B' },
      { title: 'Multiracial', color: '#820263' },
      { title: 'Pacific Islander', color: '#FD7B47' },
      { title: 'White', color: '#144766' }
    ]
  },
  {
    title: 'STATUS',
    marginTop: 30,
    key: 'status',
    minWidth: 200,
    columns: [
      { title: 'Disability', color: '#5EC9BF' },
      { title: 'Veteran', color: '#BB342F' },
      { title: 'LGBTQ+', color: '#C85089' }
    ]
  }
]
