import { InfoBoxContainer } from 'simple-core-ui'

import s from './ContactLockReason.scss'

const ContactLockReason = ({ type }) => (
  <InfoBoxContainer
    className={s.container}
    icon={<i className="simple-line-icon-lock" />}
    message={
      type === 'client' ? (
        <span>
          Contacts corresponding to a login user can only be modified from the{' '}
          <a href="/company/" className={s.link}>
            User Management Page
          </a>
        </span>
      ) : type === 'vendor' ? (
        <span>Contacts corresponding to a vendor cannot be re-assigned to another company.</span>
      ) : (
        <span>This contact contains fields which cannot be modified.</span>
      )
    }
  />
)

export default ContactLockReason
