import { Component } from 'react'
import ACT from 'matters/requests/create/actions'
import { connect } from 'react-redux'
import { Sidebar } from './Sidebar'
import { FORM_STATUSES } from 'matters/requestForms/constants'

@connect(({ requestFormsList, request }) => {
  const { requestForms } = requestFormsList
  const { selectedForm, filterTerm } = request

  const forms = requestForms.filter(
    form =>
      form.status === FORM_STATUSES.PUBLISHED &&
      form.name.toLowerCase().includes(filterTerm.toLowerCase())
  )

  return {
    forms,
    selectedForm,
    filterTerm,
    noForms: requestForms.length
  }
})
class SidebarContainer extends Component {
  state = {
    filterTerm: this.props.filterTerm
  }
  componentDidMount() {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED
    })
    this.state.filterTerm && this.onFilterChange('')
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.selectedForm) {
      const { forms } = this.props
      forms && forms.length && this.selectAndGetForm(forms[0])
    }
  }
  onFilterChange = term => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_FORM_LIST_CHANGE_FILTER_TERM,
      payload: {
        filterTerm: term
      }
    })
  }

  setSelectedForm = selectedForm => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_SET_SELECTED_FORM,
      payload: {
        selectedForm
      }
    })
  }
  selectAndGetForm = form => {
    const { getForm } = this.props
    getForm(form)
    this.setSelectedForm(form)
  }

  render() {
    const { forms, filterTerm, opened, toggleDrawer, selectedForm, noForms } = this.props
    const showSidebar = !!noForms

    return (
      <Sidebar
        showSidebar={showSidebar}
        showFilterInput
        filterTerm={filterTerm}
        list={forms}
        toggleDrawer={toggleDrawer}
        opened={opened}
        onFilterChange={this.onFilterChange}
        selectAndGetForm={this.selectAndGetForm}
        selectedForm={selectedForm}
      />
    )
  }
}

export default SidebarContainer
