import { Title, VerticalTabs } from 'simple-core-ui'
import s from './EventManagementSettings.scss'
import { useMatch } from 'react-router-dom'
import { EventTypes } from './EventTypes'
import { Integrations } from './Integrations'
import { CourtRules } from './CourtRules'
import { JurisdictionRules } from './CourtRules/JurisdictionRules'
import { IoIosArrowBack } from 'react-icons/io'

const EventManagementSettings = () => {
  const match = useMatch('/v2/event_management/settings/:tabKey/*')
  const activeTab = match ? match.params.tabKey : 'rules'

  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '8px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <Title text="Event Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs
          withRoutes
          activeTabKey={activeTab as string}
          tabs={[
            {
              index: true,
              key: 'rules',
              label: 'Court Rules',
              path: 'rules',
              element: <CourtRules />
            },
            {
              key: 'integrations',
              label: 'Integrations',
              path: 'integrations',
              element: <Integrations />
            },
            {
              key: 'types',
              label: 'Types',
              path: 'types',
              element: <EventTypes />
            },
            {
              key: 'jurisdictionRules',
              label: '',
              path: 'rules/:id',
              element: <JurisdictionRules />
            }
          ]}
        />
      </section>
    </section>
  )
}

export default EventManagementSettings
