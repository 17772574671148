import { Operand, FieldOperand, Condition, FuncOperand } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'
import { CanSerialize } from 'simple_review/expressions/can-serialize'
import { generateConstant, generateListConstant } from 'simple_review/expressions/constant-factory'
import { ExpressionMapper } from 'simple_review/expressions/expression-mapper'
import {
  FuncFieldMapper,
  ModelFieldMapper
} from 'simple_review/expressions/model-field/model-field-mapper'
import { FuncFieldModel } from 'simple_review/expressions/model-field/model-field-model'
import { formatArgOptionLabel } from 'simple_review/utils/helpers'
import { BaseSerializer } from './base.serializer'

interface ReturnValue extends BaseSerializer {
  isAI(): boolean
}

export function RuleConditionEditOperandSerializer(
  item: Operand | Condition | null,
  context: ContextState
): ReturnValue {
  return {
    isAI() {
      if (!item) return false
      if ('operands' in item) {
        return item.operands.every(operand => {
          return RuleConditionEditOperandSerializer(operand, context).isAI()
        })
      }
      if (!('model_name' in item.lhs)) return false
      return item.lhs.model_name === 'AdjustmentCodeClassification'
    },
    toString() {
      if (!item) return ''

      if ('operands' in item) {
        return item.operands
          .reduce((acc, x) => {
            const operand = x as Operand
            const serialized = RuleConditionEditOperandSerializer(operand, context)
            if (serialized.isAI()) return acc
            return [...acc, serialized.toString()]
          }, [] as (string | JSX.Element)[])
          .join(` ${item.op} `)
      }

      let operand = item
      if (Array.isArray(operand)) {
        operand = operand[0]
      }

      let constant
      if (!operand.rhs) {
        constant = null
      } else if ('attr_id' in operand.rhs) {
        constant = generateConstant('custom_attribute', operand.rhs, '', context)
      } else if (Array.isArray(operand.rhs.constant)) {
        constant = generateListConstant(
          operand.rhs.type,
          operand.rhs.constant,
          operand.rhs.sub_type
        )
      } else {
        constant = generateConstant(
          operand.rhs.type,
          operand.rhs.constant,
          operand.rhs.sub_type,
          context
        )
      }

      let leftHandSide: CanSerialize
      if (operand.lhs.operand_type === 'func') {
        const funcLhs = operand.lhs as FuncOperand
        leftHandSide = new FuncFieldMapper(funcLhs.func_name, funcLhs.args)
      } else {
        const fieldLhs = operand.lhs as FieldOperand
        leftHandSide = new ModelFieldMapper(
          fieldLhs.model_name,
          fieldLhs.field_name,
          fieldLhs.attr_id
        )
      }

      const serializedOperand = new ExpressionMapper(leftHandSide, operand.op, constant).fromJSON(
        context
      )

      let stringified = ''
      if (operand.lhs.operand_type === 'func') {
        const serializedFuncLhs = serializedOperand.lhs as FuncFieldModel
        stringified = `${serializedOperand.lhs.modelName} (${serializedFuncLhs.args
          .map(arg => {
            let value = ''
            if (arg.arg_type === 'field') {
              value = arg.model_name.concat(` ∙ ${arg.model_field}`)
            } else if (Array.isArray(arg.constant)) {
              value = arg.constant.map(v => v.label).join(', ')
            } else {
              value = arg.constant.value.toString()
            }
            return `${formatArgOptionLabel(arg.arg_name)} = ${value}`
          })
          .join(', ')}) ${serializedOperand.op.label.toLowerCase()}`
      } else {
        stringified = `${serializedOperand.lhs.path} ${serializedOperand.op.label.toLowerCase()}`
      }
      if (serializedOperand.rhs) {
        stringified += ` ${serializedOperand.rhs.label}`
      }

      return stringified
    }
  }
}
