import { TaskType, APITaskType, Option } from './types'
import { formatName } from 'utils/helpers'

export const toTaskTypes = (taskTypes: APITaskType[]): TaskType[] => {
  return taskTypes.map((type: APITaskType) => {
    const {
      id,
      name,
      related_tasks,
      is_active,
      edited_by,
      edited_date,
      strategic_value,
      complexity,
      related_templates = []
    } = type

    return {
      id,
      name,
      relatedTasks: String(related_tasks),
      lastEdited: edited_date ?? null,
      editedBy: formatName(edited_by) || null,
      isActive: is_active,
      strategicValue: strategic_value,
      complexity,
      relatedTemplates: related_templates.map(template => ({
        templateId: template.template_id,
        taskIds: template.task_ids
      }))
    }
  })
}

export const toOptions = (taskTypes: TaskType[]): (Option & { isActive: boolean })[] => {
  return taskTypes.map((taskType: TaskType) => {
    return {
      value: taskType.id,
      label: taskType.name,
      isActive: taskType.isActive
    }
  })
}
