import { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

const initialCache = {
  options: [],
  hasMore: true,
  isLoading: false
}

class ScrollSelect extends Component {
  state = {
    page: 0,
    search: '',
    optionsCache: {},
    selectedOption: null
  }

  static defaultProps = {
    params: {},
    url: '',
    pageSize: 30,
    cacheUniq: null
  }

  componentDidMount() {
    this.loadOptions = debounce(this.loadOptions, 200)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.params, this.props.params)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedOption: null,
        optionsCache: {},
        page: 0
      })
    }
    if (this.props.value !== prevState.selectedOption) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedOption: this.props.value })
    }
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption })
    this.props.onChange(selectedOption)
  }

  loadOptions = async newSearch => {
    const { optionsCache } = this.state
    const isSearching = newSearch || newSearch === ''
    const search = isSearching ? newSearch : this.state.search
    const { pageSize, params } = this.props

    const currentOptions = optionsCache[search] || initialCache

    if (currentOptions.isLoading || (!currentOptions.hasMore && !isSearching)) {
      return
    }

    if (isSearching && !!optionsCache[search]) {
      return this.setState({
        search,
        page: 1
      })
    }

    const page = this.state.page
    this.setState(prevState => ({
      search,
      page: isSearching ? 1 : page + 1,
      optionsCache: {
        ...prevState.optionsCache,
        [search]: {
          ...currentOptions,
          isLoading: true
        }
      }
    }))

    try {
      const resp = await axios.get(this.props.url, {
        params: {
          search_term: search,
          page: isSearching ? 1 : page + 1,
          page_size: pageSize,
          ...params
        }
      })

      const { results, more } = resp.data
      const options = results.map(r => ({ value: r.id, label: r.text }))

      this.setState(prevState => ({
        optionsCache: {
          ...prevState.optionsCache,
          [search]: {
            ...currentOptions,
            options: currentOptions.options.concat(options),
            hasMore: !!more,
            isLoading: false
          }
        }
      }))
    } catch (e) {
      this.setState(prevState => ({
        optionsCache: {
          ...prevState.optionsCache,
          [search]: {
            ...currentOptions,
            isLoading: false
          }
        }
      }))
    }
  }

  handleOnOpen = async () => {
    const { optionsCache } = this.state

    if (!optionsCache['']) {
      await this.loadOptions()
    }
  }

  handleInputChange = search => {
    if (search !== this.state.search && search !== undefined) {
      this.loadOptions(search)
    }
  }

  handleMenuScrollToBottom = async () => {
    const { search, optionsCache } = this.state

    const currentOptions = optionsCache[search]

    if (currentOptions) {
      await this.loadOptions()
    }
  }

  render() {
    const { className, isMulti, disabled, id, ariaLabel } = this.props
    const { optionsCache, search, selectedOption } = this.state

    const currentOptions = optionsCache[search] || initialCache
    const noResultsText = currentOptions.isLoading ? 'Loading...' : 'No results found...'

    return (
      <Select
        id={id}
        aria-label={ariaLabel}
        name="form-field-name"
        isDisabled={disabled}
        isMulti={isMulti}
        value={selectedOption}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onMenuOpen={this.handleOnOpen}
        noOptionsMessage={() => noResultsText}
        isLoading={currentOptions.isLoading}
        options={currentOptions.options}
        onMenuScrollToBottom={this.handleMenuScrollToBottom}
        className={className}
      />
    )
  }
}

export default ScrollSelect
