import { formatArgOptionLabel } from 'simple_review/utils/helpers'
import { FUNC_ARG_TYPES } from 'simple_review/utils/constants'

import s from './FuncLabel.scss'
import ReactTooltip from 'react-tooltip'
import { LhsArg } from 'simple_review/@types/list'

const FuncArgs = ({
  args,
  argFieldMap
}: {
  args: Array<LhsArg>
  argFieldMap: { [key: string]: { display_name: string } }
}): JSX.Element => {
  return (
    <>
      {args.map((arg, key) => (
        <span key={key} className={s.arg}>
          <span className={s.argName}>{formatArgOptionLabel(arg.arg_name)}</span>
          <span className={s.argValue}>
            {arg.arg_type === FUNC_ARG_TYPES.FIELD
              ? `${arg.model_name} · ${argFieldMap[arg.arg_name].display_name}`
              : arg.constant.label}
          </span>
        </span>
      ))}
    </>
  )
}

const FuncLabel = ({
  funcName,
  funcArgs,
  argFieldMap
}: {
  funcName: string
  funcArgs: Array<LhsArg>
  argFieldMap: { [key: string]: { display_name: string } }
}) => {
  const argCount = <span className={s.more}>{`(+${funcArgs.length} parameters)`}</span>

  return (
    <section className={s.funcLabel}>
      <span>{funcName}</span>

      {Boolean(funcArgs.length) && (
        <>
          <div data-for="buttonTooltip" data-tip>
            {argCount}
          </div>
          <ReactTooltip id="buttonTooltip" type="light" effect="solid" place="bottom" border>
            <FuncArgs args={funcArgs} argFieldMap={argFieldMap} />
          </ReactTooltip>
        </>
      )}
    </section>
  )
}

export default FuncLabel
