/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect, useCallback } from 'react'
import s from './DynamicAttributesDetails.scss'
import {
  Panel,
  Stepper,
  Button,
  EditableFieldFilterContainer,
  DESIGN,
  useLoading,
  useUnsavedChanges,
  withConfirmation,
  Ellipsis
} from 'simple-core-ui'
import { InnerSidebarFilter, InnerSidebar } from 'components'
const { color } = DESIGN
import { FaAngleLeft, FaInfoCircle, FaCheckCircle } from 'react-icons/fa'
import cn from 'classnames'
import { makeGetRequest, makePostRequest } from 'utils/api'
import APP_ACT from 'app/actions'
import { useDispatch } from 'react-redux'
import { useImmer } from 'use-immer'
import { toEditableFieldFilterValues, toValuesArray } from './serializers'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { RELATIONSHIP_STATUS } from '../common/constants'
import { AddRelationship } from '../common/AddRelationship'
import { checkDuplicateName, isArraysEqual } from '../common/utils'
import { toGroupedAttributes, toDynamicAttributes, toAttrIds } from '../common/serializers'
import cloneDeep from 'lodash/cloneDeep'
import { useMatterGroupLabels, useEntityLabels } from 'hooks/selectors'
import ReactTooltip from 'react-tooltip'

const initialRelationship = {
  name: '',
  description: '',
  level1: {},
  level2: {},
  level3: {}
}

const saveConfirmation = (callback, secondaryCallback) => {
  withConfirmation(
    callback,
    {
      title: 'You have unsaved changes',
      text: 'Would you like to save before you proceed?',
      buttons: {
        cancel: 'Cancel',
        discard: {
          text: 'Discard',
          value: 'secondaryConfirm'
        },
        confirm: {
          text: 'Save',
          value: true
        }
      }
    },
    secondaryCallback
  )()
}

const DynamicAttributesDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeStep, setActiveStep] = useState(2)
  const [filterTerm, setFilterTerm] = useState('')
  const [relationshipAttributes, setRelationshipAttributes] = useState([])
  const [sidebarAttributes, setSidebarAttributes] = useImmer([])
  const [sidebarSelectedAttr, setSidebarSelectedAttr] = useState({})
  const [steps, setSteps] = useImmer([])
  const [availableValues, setAvailableValues] = useState([])
  const [selectedValues, setSelectedValues] = useState([])
  const [initialSelectedValues, setInitialSelectedValues] = useState([])
  const [isPristine, setIsPristine] = useState(true)
  const [isLoading, withLoadingLocks] = useLoading()
  const [isSaving, withLoadingLockSaving] = useLoading()
  const [isEdit, setIsEdit] = useState(false)
  const [relationship, setRelationship] = useImmer(null)
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [shouldRefetchRelationshipAttributes, setShouldRefetchRelationshipAttributes] = useState(
    false
  )
  const [dynamicAttributes, setDynamicAttributes] = useState([])
  const [customAttributes, setCustomAttributes] = useState([])
  const [initialCustomAttributes, setinitialCustomAttributes] = useState([])
  const [baseEditedRelationship, setBaseEditedRelationship] = useImmer(null)

  const editAttribute = async cb => {
    try {
      const { id, name, description, isLevelsChanged, level1, level2, level3 } = relationship

      await withLoadingLockSaving(
        makePostRequest('/templates/related/update', {
          rel_id: String(id),
          name,
          description
        })
      )

      if (isLevelsChanged) {
        await withLoadingLockSaving(
          makePostRequest('/templates/related/remove_cols', {
            rel_id: String(id),
            attr_ids: toAttrIds([
              baseEditedRelationship.level1,
              baseEditedRelationship.level2,
              baseEditedRelationship.level3
            ])
          })
        )

        await withLoadingLockSaving(
          makePostRequest('/templates/related/add_cols', {
            rel_id: String(id),
            attr_ids: toAttrIds([level1, level2, level3])
          })
        )

        setShouldRefetchRelationshipAttributes(true)
      }

      setBaseEditedRelationship(draft => relationship)

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Relationship successfully edited',
          level: 'success'
        }
      })

      setIsPristine(true)
      cb && cb()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  // this is neccessary so that useUnsavedChanges won't run multiple times
  const editAttributeMemoized = useCallback(editAttribute, [relationship])
  const savePairMemoized = useCallback(savePair, [selectedValues])

  useUnsavedChanges(
    relationship?.status === RELATIONSHIP_STATUS.INACTIVE ? false : !isPristine,
    isEdit && activeStep === 1 ? editAttributeMemoized : savePairMemoized
  )
  const entityLabels = useEntityLabels()
  const matterGroupLabels = useMatterGroupLabels()

  useEffect(() => {
    ;(async () => {
      try {
        const rel = await withLoadingLocks(makeGetRequest(`/templates/related/${id}/`))
        setRelationship(draft => {
          return toDynamicAttributes([rel])[0]
        })
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        if (relationship) return

        const { attrs } = await withLoadingLocks(
          makeGetRequest(`/templates/related/get_cols/${id}/`)
        )
        setRelationshipAttributes(attrs)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [relationship])

  useEffect(() => {
    ;(async () => {
      try {
        if (!shouldRefetchRelationshipAttributes) return

        const { attrs } = await withLoadingLocks(
          makeGetRequest(`/templates/related/get_cols/${id}/`)
        )
        setRelationshipAttributes(attrs)
        setShouldRefetchRelationshipAttributes(false)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [shouldRefetchRelationshipAttributes])

  useEffect(() => {
    const isEdit = !!queryString.parse(location.hash).edit
    setIsEdit(isEdit)
    if (isEdit) setActiveStep(1)
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        if (!isEdit) return
        const { groups } = await makeGetRequest('/templates/custom-attributes')
        const attributes = toGroupedAttributes(groups)
        setCustomAttributes(attributes)
        setinitialCustomAttributes(attributes)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [isEdit])

  useEffect(() => {
    ;(async () => {
      try {
        if (!isEdit) return
        const { relationships } = await withLoadingLocks(
          makeGetRequest('/templates/related/list_relationships')
        )
        setDynamicAttributes(toDynamicAttributes(relationships))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [isEdit])

  useEffect(() => {
    if (!initialCustomAttributes.length || !isEdit || !relationship) return

    const { level1, level2, level3 } = relationship

    if (!level1) return

    const filteredAttributes = initialCustomAttributes.map(attr => ({
      ...attr,
      options: attr.options.filter(
        a => ![+level1?.value, +level2?.value, +level3?.value].includes(a.value)
      )
    }))
    setCustomAttributes(filteredAttributes)
  }, [
    relationship?.level1?.value,
    relationship?.level2?.value,
    relationship?.level3?.value,
    initialCustomAttributes
  ])

  useEffect(() => {
    if (!dynamicAttributes.length || !isEdit || !relationshipAttributes.length || !relationship)
      return

    const newAttr = relationshipAttributes.reduce((acc, val, index) => {
      acc[`level${index + 1}`] = {
        value: val.value,
        label: val.label
      }
      return acc
    }, cloneDeep(relationship))

    setRelationship(draft => {
      return newAttr
    })
    setBaseEditedRelationship(draft => {
      return newAttr
    })
  }, [dynamicAttributes, relationshipAttributes, isEdit])

  useEffect(() => {
    const steps = [
      {
        text: `${isEdit ? 'Edit' : 'Define'} Relationship`,
        ...(isEdit
          ? {
              onClick: () => {
                if (activeStep !== 1 && !isPristine) {
                  saveConfirmation(
                    () => {
                      savePairMemoized(() => setActiveStep(1))
                    },
                    () => {
                      setActiveStep(1)
                      setIsPristine(true)
                    }
                  )
                } else {
                  setActiveStep(1)
                }
              }
            }
          : {})
      },
      ...relationshipAttributes
        .map((attr, i) => {
          if (i < relationshipAttributes.length - 1) {
            return {
              text: `Pair Level ${i + 1}/Level ${i + 2}`,
              onClick: () => {
                if (activeStep !== i + 2 && !isPristine) {
                  saveConfirmation(
                    () => {
                      activeStep === 1
                        ? editAttributeMemoized(() => setActiveStep(i + 2))
                        : savePairMemoized(() => setActiveStep(i + 2))
                    },
                    () => {
                      setActiveStep(i + 2)
                      setIsPristine(true)
                      activeStep === 1 && setRelationship(draft => baseEditedRelationship)
                    }
                  )
                } else {
                  setActiveStep(i + 2)
                }
              }
            }
          }
        })
        .filter(attr => attr !== undefined)
    ]
    setSteps(draft => steps)
  }, [
    relationshipAttributes,
    isEdit,
    setSteps,
    activeStep,
    savePairMemoized,
    isPristine,
    editAttributeMemoized,
    baseEditedRelationship
  ])

  useEffect(() => {
    ;(async () => {
      try {
        if (!relationshipAttributes.length || activeStep < 2) return
        const { vals } = await withLoadingLocks(
          makeGetRequest(
            `/templates/related/${id}/col/${relationshipAttributes[activeStep - 2].value}/vals`
          )
        )
        setSidebarAttributes(draft => {
          return vals
        })
        setSidebarSelectedAttr(vals[0])

        const { vals: vals2 } = await withLoadingLocks(
          makeGetRequest(
            `/templates/related/${id}/col/${relationshipAttributes[activeStep - 1].value}/vals`
          )
        )
        setAvailableValues(vals2)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [relationshipAttributes, activeStep])

  useEffect(() => {
    ;(async () => {
      try {
        if (sidebarSelectedAttr.has_pairing) {
          const { options } = await withLoadingLocks(
            makeGetRequest(`/templates/related/${id}/vals/${sidebarSelectedAttr.value}/paired`)
          )
          setSelectedValues(options)

          // this is used for enabling the sava pairing button only if the selection is changed
          if (!initialSelectedValues.length) {
            setInitialSelectedValues(options)
          }
        } else {
          setSelectedValues([])
          setInitialSelectedValues([])
        }
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
  }, [sidebarSelectedAttr])

  const filteredSidebarAttributes = useMemo(() => {
    return sidebarAttributes.filter(attr =>
      attr.label.toLowerCase().includes(filterTerm.toLowerCase())
    )
  }, [filterTerm, sidebarAttributes])

  const canUpdatePair = useMemo(() => {
    if (relationship?.status === RELATIONSHIP_STATUS.INACTIVE) {
      return false
    }

    return true
  }, [relationship?.status])

  async function savePair(cb) {
    try {
      await withLoadingLockSaving(
        makePostRequest('/templates/related/set_pairs', {
          rel_id: id,
          val1_id: sidebarSelectedAttr.value,
          val2_ids: toValuesArray(selectedValues)
        })
      )
      const index = sidebarAttributes.findIndex(a => +a.value === +sidebarSelectedAttr.value)
      setSidebarAttributes(draft => {
        if (selectedValues.length) {
          draft[index].has_pairing = true
        } else {
          draft[index].has_pairing = false
        }
      })
      setInitialSelectedValues(selectedValues)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          message: 'Your pairing has been saved successfully',
          level: 'success'
        }
      })
      setIsPristine(true)
      cb && cb()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  const nextOrActivate = async () => {
    if (activeStep === steps.length) {
      try {
        if (relationship?.status !== RELATIONSHIP_STATUS.ACTIVE) {
          await withLoadingLocks(
            makePostRequest('/templates/related/update', {
              rel_id: String(id),
              status: 'active'
            })
          )
        }
        navigate('/v2/dynamic_attributes/list')
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            message: 'Relationship activated successfully',
            level: 'success'
          }
        })
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
      return
    } else {
      if (!isPristine) {
        saveConfirmation(
          () => {
            activeStep !== 1
              ? savePairMemoized(() => setActiveStep(state => state + 1))
              : editAttributeMemoized(() => setActiveStep(state => state + 1))
          },
          () => {
            setActiveStep(state => state + 1)
            setIsPristine(true)
            activeStep === 1 && setRelationship(draft => baseEditedRelationship)
          }
        )
      } else {
        setActiveStep(state => state + 1)
      }
    }
  }

  const backToList = () => {
    navigate('/v2/dynamic_attributes/list')
  }

  const optionFormat = option => {
    const { filtered, label, value, filtered_by } = option
    if (!filtered) return label
    const overlayBaseText = 'Attribute will only show for '
    let overlayText =
      filtered_by['matter-group'].length &&
      overlayBaseText +
        `${matterGroupLabels[0].toLowerCase()} ${filtered_by['matter-group']
          .map(p => p.name)
          .join(', ')}`

    filtered_by['matter-group'].length &&
      filtered_by['legal-entity'].length &&
      (overlayText += ' and ')

    filtered_by['legal-entity'].length &&
      (overlayText += `${entityLabels[0].toLowerCase()} ${filtered_by['legal-entity']
        .map(l => l.name)
        .join(', ')}`)
    return (
      <>
        {label}
        <span className={s.infoIcon} data-for={`item-actions-${value}`} data-tip>
          <FaInfoCircle />
        </span>
        <ReactTooltip
          id={`item-actions-${value}`}
          type="light"
          effect="solid"
          place="bottom"
          border
        >
          {overlayText}
        </ReactTooltip>
      </>
    )
  }

  const isBtnDisabled = () => {
    return (
      showDuplicateError ||
      !relationship?.name.trim() ||
      !relationship?.level1?.value ||
      !relationship?.level2?.value ||
      relationship?.status === RELATIONSHIP_STATUS.INACTIVE
    )
  }

  const changeProperty = (property, value) => {
    setIsPristine(false)
    setRelationship(draft => {
      draft[property] = value
      if (isEdit && ['level1', 'level2', 'level3'].includes(property)) {
        draft['isLevelsChanged'] = true
      }
    })
  }

  return (
    <Panel
      styles={{ boxShadow: 'none' }}
      className={s.relationshipsContainer}
      leftActions={[
        <a key="0" onClick={backToList}>
          <FaAngleLeft /> Back to Relationships
        </a>
      ]}
      rightActions={[
        // the previous button should appear on the creation page if the active step is greater than 2
        // and only if there are 3 steps. because on this page the first step is dummy, we can't access it.
        // on the edit page we can access the 1st step as well
        ...((!isEdit && activeStep !== 2 && steps.length > 2) || (isEdit && activeStep !== 1)
          ? [
              <a
                onClick={() => {
                  if (!isPristine) {
                    saveConfirmation(
                      () => {
                        savePairMemoized(() => setActiveStep(state => state - 1))
                      },
                      () => {
                        setIsPristine(true)
                        setActiveStep(state => state - 1)
                      }
                    )
                  } else {
                    setActiveStep(state => state - 1)
                  }
                }}
                key="1"
                className={s.previous}
              >
                Previous
              </a>
            ]
          : []),
        ...(isEdit && activeStep === 1 && !isPristine
          ? [
              <Button
                ariaLabel="edit_button"
                key="2"
                onClick={() => editAttribute()}
                isDisabled={isBtnDisabled()}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            ]
          : []),
        <Button
          onClick={nextOrActivate}
          isPrimary
          key="0"
          isDisabled={
            relationship?.status === RELATIONSHIP_STATUS.INACTIVE && activeStep === steps.length
          }
        >
          {activeStep === steps.length
            ? relationship?.status === RELATIONSHIP_STATUS.ACTIVE
              ? 'Save'
              : 'Activate'
            : 'Next'}
        </Button>
      ]}
    >
      {steps.length > 1 && (
        <div className={s.stepperWrapper}>
          <section className={s.stepperContainer}>
            <Stepper
              orientation="horizontal"
              automaticHeader
              activeStep={activeStep}
              steps={steps}
            />
          </section>
        </div>
      )}
      <div className={s.body}>
        {activeStep > 1 ? (
          <>
            <InnerSidebar
              style={{ position: 'absolute', top: '0', bottom: '0', left: '0' }}
              title={
                <>
                  Select Level {activeStep - 1}:
                  {relationshipAttributes.length ? (
                    <Ellipsis width={150} className={s.title}>
                      {relationshipAttributes[activeStep - 2].label}
                    </Ellipsis>
                  ) : (
                    ''
                  )}
                </>
              }
            >
              <InnerSidebarFilter
                filterTerm={filterTerm}
                onChange={value => setFilterTerm(value)}
              />
              <div className={s.sidebarAttributesWrapper}>
                {filteredSidebarAttributes.map(attr => (
                  <div
                    aria-label={`level1-attribute-${attr.value}`}
                    key={attr.value}
                    className={cn(s.sidebarAttribute, {
                      [s.selected]:
                        sidebarSelectedAttr.value && +sidebarSelectedAttr.value === +attr.value
                    })}
                    onClick={() => {
                      if (+sidebarSelectedAttr.value !== +attr.value) setIsPristine(true)
                      setSidebarSelectedAttr(attr)
                    }}
                  >
                    <div className={s.labelSection}>{optionFormat(attr)}</div>
                    <FaCheckCircle
                      className={cn(s.checkIcon, {
                        [s.paired]: attr.has_pairing
                      })}
                    />
                  </div>
                ))}
              </div>
            </InnerSidebar>
            <div className={s.editableFieldWrapper}>
              <h2 style={{ marginBottom: '15px' }}>
                {`Pair with Level ${activeStep}: ${
                  relationshipAttributes.length ? relationshipAttributes[activeStep - 1].label : ''
                }`}
              </h2>
              <div className={s.editableField}>
                <EditableFieldFilterContainer
                  style={{ flex: '1 1 100%' }}
                  allOptions={toEditableFieldFilterValues(availableValues)}
                  selectedOptions={toEditableFieldFilterValues(selectedValues)}
                  updateSelectedOptions={options => {
                    setIsPristine(isArraysEqual(options, initialSelectedValues, ['value']))
                    setSelectedValues(options)
                  }}
                  optionFormat={optionFormat}
                  isReadOnly={!canUpdatePair}
                  isMulti
                />
                <Button
                  isDisabled={
                    isArraysEqual(selectedValues, initialSelectedValues, ['value']) ||
                    !canUpdatePair
                  }
                  onClick={() => savePair()}
                >
                  {isSaving ? 'Saving...' : 'Save Pairing'}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className={s.addRelationship}>
            <AddRelationship
              showDuplicateError={showDuplicateError}
              changeProperty={changeProperty}
              relationship={relationship}
              checkDuplicateName={(name, id) => checkDuplicateName(dynamicAttributes, name, id)}
              setShowDuplicateError={setShowDuplicateError}
              customAttributes={customAttributes}
            />
          </div>
        )}
      </div>
    </Panel>
  )
}

export default DynamicAttributesDetails
