import { Component } from 'react'
import { EditableField } from 'components'
import { CheckboxContainer } from 'containers'
import { FaTrash } from 'react-icons/fa'
import * as CONST from 'matters/templates/constants'
import s from './TemplateAttribute.scss'

class TemplateAttribute extends Component {
  render() {
    const {
      attribute,
      category,
      changeAttributeValue,
      removeAttribute,
      sectionIndex,
      attrIndex,
      isRelationship,
      relationshipIndex,
      relationshipId,
      isRequiredDisabled,
      isDisabled
    } = this.props
    // Please make this true by default and fix the mismatch between -1
    // and "-1"
    let hasAttributeInactiveValues = false
    if (!isRelationship) {
      if (attribute.value && attribute.value.value) {
        hasAttributeInactiveValues =
          attribute.options.map(option => option.value).indexOf(parseInt(attribute.value.value)) < 0
      }
    }

    return (
      <div
        className={
          attribute.type === CONST.SYSTEM_GENERATED
            ? `${s.container} ${s.systemGenerated}`
            : s.container
        }
      >
        <div className={s.attributeWrapper}>
          <label>{attribute.label}</label>
          <EditableField
            type={attribute.type}
            disabled={isDisabled}
            options={attribute.options}
            value={attribute.value}
            onChange={value =>
              changeAttributeValue({
                category,
                sectionIndex,
                attrIndex,
                value,
                attributeId: attribute.id,
                relationshipIndex,
                relationshipId
              })
            }
            id={`slm_template_attribute_${category}_${sectionIndex}_${attrIndex}`}
            isPortal={false}
          />
          {hasAttributeInactiveValues && (
            <div className={s.warningFlag}>
              <span className="simple-line-icon-shield" />
              This attribute value is not active.
            </div>
          )}
        </div>
        <div className={s.checkBoxesWrapper}>
          <div className={s.requiredWrapper} id={attribute.label}>
            <label>Required</label>
            <CheckboxContainer
              defaultChecked={attribute.required}
              property="required"
              disabled={
                attribute.type === CONST.SYSTEM_GENERATED ||
                attribute.requiredByClient ||
                isRequiredDisabled
              }
              onChange={value =>
                changeAttributeValue({
                  category,
                  sectionIndex,
                  attrIndex,
                  value,
                  property: 'required',
                  relationshipIndex
                })
              }
            />
          </div>
          <div style={isRelationship ? { visibility: 'hidden' } : {}} className={s.readOnlyWrapper}>
            <label>Read Only</label>
            <CheckboxContainer
              defaultChecked={attribute.readOnly}
              property="readOnly"
              disabled={attribute.type === CONST.SYSTEM_GENERATED}
              onChange={value =>
                changeAttributeValue({
                  category,
                  sectionIndex,
                  attrIndex,
                  value,
                  property: 'readOnly'
                })
              }
            />
          </div>
        </div>
        {(!isRelationship || relationshipIndex === 0) && (
          <div className={s.remove}>
            <FaTrash
              className={s.trashIcon}
              onClick={() => removeAttribute(attrIndex, category, sectionIndex, attribute.label)}
            />
          </div>
        )}
      </div>
    )
  }
}

export default TemplateAttribute
