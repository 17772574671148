import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { Badge } from 'simple-core-ui'

interface Props {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  myTasksCount: number
  myRequestsCount: number
  myFollowingCount: number
  totalTasksCount: number
}

const Tabs = ({
  selectedTab,
  setSelectedTab,
  myTasksCount,
  myRequestsCount,
  myFollowingCount,
  totalTasksCount
}: Props) => {
  const tabs = useMemo(() => {
    return [
      {
        label: 'All',
        value: 'all',
        count: totalTasksCount
      },
      {
        label: 'My Tasks',
        value: 'mine',
        count: myTasksCount
      },
      {
        label: 'My Requests',
        value: 'requests',
        count: myRequestsCount
      },
      {
        label: 'Following',
        value: 'watching',
        count: myFollowingCount
      }
    ]
  }, [myFollowingCount, myRequestsCount, myTasksCount, totalTasksCount])

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => setSelectedTab(tab.value)}
          key={tab.value}
          data-testid={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
        >
          {tab.count !== null ? (
            <Badge asPill className={s.pill} content={String(tab.count)} size="sm" />
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
