import { useEffect, useState } from 'react'
import BaseAsyncSelect from './BaseAsyncSelect'
import { makeGetRequest } from 'utils/api'
import { loadOptionsSelect2Json } from '../helpers'
import { toReactSelect } from '../serializers'
import { VENDOR_CONTACT_URL } from '../urls'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { PaginatedSelect } from 'simple-core-ui'

const VendorContactSelect = ({
  id,
  value,
  onChange,
  selectedVendorId,
  isClearable,
  isDisabled,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  ariaLabel,
  paginated = false,
  serializer,
  defaultOptions: defaultOptionsProp,
  defaultAction,
  filterOption,
  withNoneOption
}) => {
  const [defaultOptions, setDefaultOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!selectedVendorId || paginated) return noop
    const loadDefaultOptions = async () => {
      const search = ''
      setIsLoading(true)
      const params = { params: { search_term: '', selected_vendor_id: selectedVendorId } }
      const response = await makeGetRequest(VENDOR_CONTACT_URL, params)
      setDefaultOptions(toReactSelect(get(response, 'results', [])))
      setIsLoading(false)
    }
    loadDefaultOptions()
  }, [selectedVendorId, paginated])

  return paginated ? (
    <PaginatedSelect
      ariaLabel={ariaLabel}
      url={VENDOR_CONTACT_URL}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toReactSelect}
      defaultOptions={defaultOptionsProp || defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      placeholder="Search vendor contacts..."
      limit={limit}
      filterOption={filterOption}
      withNoneOption={withNoneOption}
      isDisabled={isDisabled}
      mandatoryQueryParams={{ selected_vendor_id: selectedVendorId }}
      key={selectedVendorId}
    />
  ) : (
    <BaseAsyncSelect
      ariaLabel={ariaLabel}
      id={id}
      value={value}
      defaultOptions={defaultOptions}
      loadOptions={loadOptionsSelect2Json(VENDOR_CONTACT_URL, {
        selected_vendor_id: selectedVendorId
      })}
      onChange={onChange}
      placeholder="Search vendor contacts..."
      isDisabled={isDisabled}
      limit={limit}
      isClearable
      isPortal
      isLoading={isLoading}
    />
  )
}

export default VendorContactSelect
