import { put, takeLatest, call } from 'redux-saga/effects'
import APP_ACT from 'app/actions'
import ACT from './actions'
import { makePostRequest, makeGetRequest } from 'utils/api'
import { serializeJobHistoryParams, serializeFilters } from './serializer'

function* bulkFetch(action) {
  const {
    recordType,
    recordFields,
    caType,
    templateId,
    params: { page, pageSize, ordering, filters = {} }
  } = action.payload

  const serializedFilters = serializeFilters(filters, caType)
  try {
    const response = yield makePostRequest('/bulkv2/bulk_selection', {
      page: page,
      size: pageSize,
      record_type: recordType,
      record_fields: recordFields,
      ...(caType ? { ca_type: caType } : {}),
      ...(templateId ? { template_id: templateId } : {}),
      ordering: ordering.isDesc ? `-${ordering.columnKey}` : ordering.columnKey,
      ...(filters && serializedFilters.length ? { filters: serializedFilters } : {})
    })

    yield put({
      type: ACT.BULK_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: {
        record_type: recordType,
        page: page,
        page_size: pageSize,
        total: response.total,
        results: response.results,
        columns: response.columns,
        ordering: ordering,
        filters: filters
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching bulk.',
        level: 'error'
      }
    })
  }
}

function* bulkHistoryFetch(action) {
  const url = '/bulkv2/jobs'
  const { params } = action.payload
  const serialized = serializeJobHistoryParams(params)

  try {
    const response = yield makePostRequest(
      `${url}?page=${params.page}&size=${params.pageSize}`,
      serialized
    )
    yield put({
      type: ACT.BULK_HISTORY_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: {
        total: response.count,
        results: response.results,
        params: params
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching bulk history.',
        level: 'error'
      }
    })
  }
}

function* bulkFieldsFetch(action) {
  const { recordType, caType, templateId } = action.payload
  const url = `/bulkv2/bulk_update_fields/?record_type=${recordType}${
    caType ? `&ca_type=${caType}` : ''
  }${templateId ? `&template_id=${templateId}` : ''}`
  try {
    const response = yield call(makeGetRequest, url)
    yield put({
      type: ACT.BULK_FIELDS_FETCH_SUCCESS,
      payload: {
        results: response
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching bulk fields.',
        level: 'error'
      }
    })
  }
}

const bulkSagas = [
  takeLatest(ACT.BULK_FETCH_REQUESTED, bulkFetch),
  takeLatest(ACT.BULK_HISTORY_FETCH_REQUESTED, bulkHistoryFetch),
  takeLatest(ACT.BULK_FIELDS_FETCH_REQUESTED, bulkFieldsFetch)
]

export default bulkSagas
