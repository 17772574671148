import { TeamSelect } from 'teams'
import { useState, useRef } from 'react'
import { useOutsideAlerter } from 'hooks'
import classNames from 'classnames'

import ScopeContext from 'context/ScopeContext'
import { SCOPE } from 'reviews/constants'

import s from '../styles/TierForm.scss'

const MAX_HEIGHT_DROPDOWN = 176

const TEAM_SELECT_HEIGHT_CAP = {
  [SCOPE.PRACTICE_AREA]: '165px'
}

const MultiSelectTop = ({ values, onChange, subtitle, id, ...props }) => (
  <section className={s.container}>
    <ScopeContext.Consumer>
      {({ scope }) => (
        <>
          {!!values?.length && (
            <div className={s.multiSelectViewerContainer}>
              <div style={{ display: 'flex' }}>
                <button className={s.buttonClearAll} onClick={() => onChange([])}>
                  Clear All
                </button>
              </div>
              <TeamSelect
                value={values}
                onChange={onChange}
                multi
                styles={{
                  input: () => ({ display: 'none' }),
                  indicatorsContainer: () => ({ display: 'none' }),
                  valueContainer: base => ({
                    ...base,
                    maxHeight: 200,
                    overflow: 'auto',
                    paddingBottom: 38,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)'
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    flex: 1
                  })
                }}
                placeholder=""
                cappedInputHeight={TEAM_SELECT_HEIGHT_CAP[scope]}
                onMouseDown={e => {}}
                menuIsOpen={false}
                id={id}
                {...props}
              />
              <p className={s.multiSelectViewerDescriber}>{subtitle}</p>
            </div>
          )}
        </>
      )}
    </ScopeContext.Consumer>
  </section>
)

const MultiSelectBottom = ({
  onChange,
  id,
  placeholder = 'Select or Type here',
  styles,
  values,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const selectWithMenu = useRef(null)

  useOutsideAlerter(selectWithMenu, () => setMenuIsOpen(false))

  return (
    <div className={classNames(s.multiSelectNoChip)} ref={selectWithMenu} style={{ ...styles }}>
      <TeamSelect
        value={null}
        placeholder={placeholder}
        onChange={onChange}
        multi
        isClearable={false}
        maxMenuHeight={MAX_HEIGHT_DROPDOWN}
        id={id}
        closeMenuOnSelect={false}
        filterOption={candidate => !values?.find(value => value.id === candidate.data.id)}
        menuIsOpen={menuIsOpen}
        onMenuOpen={e => {
          setMenuIsOpen(true)
        }}
        {...props}
      />
      <div
        className={classNames({
          [s.menuHeightHelperOpen]: menuIsOpen,
          [s.menuHeightHelper]: !menuIsOpen
        })}
      />
    </div>
  )
}

export { MultiSelectTop, MultiSelectBottom }
