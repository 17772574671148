import { DataTableContainer } from 'simple-core-ui'

import ClientKeysListTitle from './ClientKeysListTitle'

const ClientKeysList = ({
  initialParams,
  getTableContents,
  showModal,
  deleteClientKey,
  updateTable,
  clientKeyCount,
  clientKeyLimit,
  isUpdating
}) => (
  <DataTableContainer
    {...getTableContents()}
    {...{ primaryAction: { children: 'Create ClientKey', onClick: () => showModal() } }}
    initialValues={initialParams}
    renderCell={({ columnKey, content }) => {
      return content
    }}
    title={<ClientKeysListTitle clientKeyCount={clientKeyCount} clientKeyLimit={clientKeyLimit} />}
    categories={[{ label: 'clientKeys', value: 'clientKeys' }]}
    categoryValue="clientKeys"
    isLoading={isUpdating}
    clickRow={({ id }) => showModal(id)}
    deleteRow={({ id }) => deleteClientKey(id)}
    updateTable={updateTable}
    hasActions
  />
)

export default ClientKeysList
