import { useState, useEffect } from 'react'
import { DataTableWrapper, Panel, useLoading, Button } from 'simple-core-ui'
import { useDispatch } from 'react-redux'
import { makeFormDataPost } from 'utils/api'
import { columns } from './tableDefinitions'
import { ordering2Str } from 'utils/serializers'

function MatterInvoiceList({ matterId, lineItemCount }) {
  const dispatch = useDispatch()
  const [isLoading, withLoadingLocks] = useLoading()

  const [params, setParams] = useState({
    page_size: 10,
    ordering: { columnKey: 'received_date', isDesc: true },
    search: '',
    page: 1
  })

  const [results, setResults] = useState({ rows: [] })

  async function fetchInvoices(newParams) {
    try {
      const url = `/manage/matters/recent_matter_invoices/${matterId}/`
      const { total, results } = await withLoadingLocks(
        makeFormDataPost(url, {
          ...newParams,
          page: newParams.page,
          ordering: ordering2Str(newParams.ordering)
        })
      )

      setResults({ total, rows: results })
    } catch (error) {
      if (error.response.status === 403) {
        return
      }
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const updateTable = async tableOpts => {
    setParams({ ...tableOpts, page_size: tableOpts.pageSize })
  }

  useEffect(() => {
    fetchInvoices(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <Panel
      title="Invoices"
      rightActions={
        lineItemCount > 0
          ? [
              <Button key="0" isPrimary href={`/reports/matter/${matterId}/`}>
                {`View All Line Items (${lineItemCount})`}
              </Button>
            ]
          : []
      }
    >
      <DataTableWrapper
        initialValues={params}
        isLoading={isLoading}
        hasActions
        hasTooltip
        remotePagination
        categories={[]}
        totalEntries={results.total}
        params={params}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        columns={columns}
        updateTable={updateTable}
        rows={results.rows}
      />
    </Panel>
  )
}

export default MatterInvoiceList
