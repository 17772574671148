import { useState } from 'react'
import { ModalContainer, Alert, CheckboxContainer } from 'simple-core-ui'
import s from './ConfirmModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { IoWarningOutline } from 'react-icons/io5'

const ConfirmModal = ({
  toggleConfirmModal,
  onConfirm
}: {
  toggleConfirmModal: () => void
  onConfirm: () => void
}) => {
  const [isDisabled, setIsDisabled] = useState(true)

  return (
    <ModalContainer
      title="Calendar Rules Integration"
      subtitle={
        <div style={{ marginTop: 5 }}>
          <AiOutlineInfoCircle className={s.info} /> Court Rule sets are populated through
          CalendarRules a third-party application.
        </div>
      }
      hasNewButtons
      size="sm"
      cancelCb={toggleConfirmModal}
      confirmText="Connect"
      confirmCb={() => {
        onConfirm()
        toggleConfirmModal()
      }}
      isDisabled={isDisabled}
      content={
        <>
          <div className={s.warning} style={{ display: 'flex' }}>
            <IoWarningOutline
              style={{
                fontSize: 22,
                color: '#bb342f',
                width: 130,
                position: 'relative',
                bottom: 2,
                marginRight: 5
              }}
            />
            <span>
              You are responsible for the accuracy of and reliance on any data imported into
              SimpleLegal. There may be instances where errors on the part of CalendarRules could
              lead to inaccuracies in deadline calculations within SimpleLegal. These errors,
              although rare, could potentially result in missed deadlines or penalties for legal
              teams if solely relied upon. Users should always independently verify the accuracy of
              deadlines with the respective courts.
            </span>
          </div>
          <Alert
            message={
              <>
                <CheckboxContainer
                  size="sm"
                  styles={{ position: 'relative', top: 2, marginRight: 5, borderColor: '#5399f6' }}
                  isChecked={!isDisabled}
                  cb={() => setIsDisabled(!isDisabled)}
                />
                I acknowledge I&apos;ve read and understand the above message
              </>
            }
            status="info"
          />
        </>
      }
    />
  )
}

export default ConfirmModal
