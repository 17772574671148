import keyMirror from 'keymirror'

export default keyMirror({
  PUSH_NOTIFICATION: null,
  LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_REQUESTED: null,
  REQUEST_FORM_FETCH_REQUESTED: null,
  REQUEST_FORM_FETCH_SUCCESS: null,
  LEGAL_REQUEST_FORM_CREATE_REQUESTED: null,
  LEGAL_REQUEST_FORM_UPDATE_REQUESTED: null,
  LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_SUCCESS: null,
  REQUEST_FORM_CHANGE_ATTRIBUTE_VALUE: null,
  REQUEST_FORM_CHANGE_LABEL_VALUE: null,
  REQUEST_FORM_CHANGE_TYPE_VALUE: null,
  REQUEST_FORM_CHANGE_INFO_VALUE: null,
  LEGAL_REQUEST_FORM_ADD_NEW_ITEM: null,
  LEGAL_REQUEST_FORMS_UPDATE_STATUS_REQUESTED: null,
  LEGAL_REQUEST_CHANGE_FORM_NAME: null,
  LEGAL_REQUEST_CHANGE_FORM_DESCRIPTION: null,
  REQUEST_FORM_RESET_STATE: null,
  REQUEST_FORM_CHANGE_FORM_FIELD_VALUE: null,
  REQUEST_FORM_REMOVE_ITEM: null,
  REQUEST_FORM_CREATE_SUCCESS: null,
  REQUEST_FORM_CREATE_SUCCESS_AND_REDIRECT: null,
  REQUEST_FORM_REMOVE_REDIRECT_FLAG: null,
  REQUEST_FORM_CHANGE_ATTRIBUTE_FILTER_OPTIONS: null,
  LEGAL_REQUEST_FORM_UPDATE_STATUS: null,
  NEW_REQUEST_FORM_REQUESTED: null,
  MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED: null,
  REQUEST_FORM_SET_TEMPLATES_LIST: null,
  FORM_SET_TMP_TEMPLATES: null,
  REQUEST_FORM_REMOVE_TEMPLATE: null,
  MATTER_TEMPLATE_FORM_LIST_SET_PREFERRED: null,
  MATTER_TEMPLATE_COUNT_FETCH_REQUESTED: null,
  REQUEST_FORM_CLONE_REQUESTED: null,
  REQUEST_FORM_RESTORE_TO_DRAFT: null,
  MATTER_TEMPLATE_LIST_FETCH_REQUESTED: null,
  SET_LOADING_STATE: null,
  REMOVE_LOADING_STATE: null,
  LEGAL_REQUEST_FORM_SET_INVALID_ITEMS: null,
  FORM_SET_NOTIFY_USERS: null,
  LEGAL_REQUEST_FORM_USERS_FETCH_REQUESTED: null,
  LEGAL_REQUEST_FORM_USERS_FETCH_SUCCESS: null,
  REQUEST_FORM_REORDER_ATTRIBUTE_ITEMS: null
})
