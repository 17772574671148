import classNames from 'classnames'
import { IoIosClose } from 'react-icons/io'
import { BsPlusSquare } from 'react-icons/bs'
import { Actionable, HoverAction, If } from 'simple-core-ui'

import { StackList } from 'components'

import { normalizeTeam } from '../utils/utils'

import s from '../styles/TierContent.scss'

const SubscribersContent = ({ subscribers = [], clearSubscribers, openCb, readOnly }) => (
  <section
    className={classNames(s.container, { [s.readOnly]: readOnly })}
    data-testid="matter_subscribers_content"
  >
    <If condition={!!subscribers.length}>
      <Actionable
        actions={
          readOnly
            ? []
            : [
                <HoverAction
                  key={0}
                  icon={<span className={classNames('simple-line-icon-pencil', s.icon)} />}
                  onClick={openCb}
                  tip="Edit Subscribers"
                />,
                <HoverAction
                  key={1}
                  icon={<IoIosClose data-testid="clear_subscribers" />}
                  onClick={event => {
                    event.stopPropagation()
                    clearSubscribers()
                  }}
                  tip="Clear Subscribers"
                />
              ]
        }
        style={{ padding: '1em 0.7em' }}
      >
        <h6 className={s.condition}>{'All of:'}</h6>
        <StackList stacks={subscribers.map(normalizeTeam)} />
      </Actionable>
    </If>
    <If condition={!subscribers.length && !readOnly}>
      <button
        onClick={openCb}
        className={s.addSubscribers}
        data-testid="subscribers-button-modal-open"
      >
        <BsPlusSquare style={{ marginRight: 8 }} />
        Add Subscribers
      </button>
    </If>
    <If condition={!subscribers.length && readOnly}>
      <span className={s.readOnlyEmpty}>No Subscribers have been set.</span>
    </If>
  </section>
)

export default SubscribersContent
