import { Component } from 'react'
import queryString from 'query-string'
import { TEMPLATE_STATUSES, SELECT_TEMPLATE_FLOW } from 'matters/templates/constants'
import * as ACT from 'matters/templates/actions'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { withRouter } from 'simple-core-ui/hocs'

const templateListSelector = state => state.templateList
const filterTermSelector = state => state.filterTerm
const userFlowStateSelector = state => state.userFlowState
const filteredTemplateListSelector = createSelector([templateListSelector], templateList => {
  let publishedList = templateList.filter(
    template => template.status === TEMPLATE_STATUSES.PUBLISHED
  )
  return publishedList
})
const publishedListSelector = createSelector(
  [templateListSelector, filterTermSelector, userFlowStateSelector],
  (templateList, filterTerm, userFlow) => {
    let publishedList = templateList.filter(
      template =>
        template.status === TEMPLATE_STATUSES.PUBLISHED &&
        (template.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
          template.description.toLowerCase().includes(filterTerm.toLowerCase()))
    )
    if (userFlow && userFlow.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST) {
      const tmpList = publishedList.filter(template => template.belongs_to_form)
      publishedList = tmpList.length ? tmpList : publishedList
    }
    return publishedList
  }
)
const showSidebarSelector = createSelector([filteredTemplateListSelector], templateList => {
  return templateList.length > 1
})

const withSelectTemplateFlowSwitcher = WrappedComponent => {
  @connect(({ matterTemplatesSelection, legalRequest }) => {
    const {
      templateList,
      filterTerm,
      description,
      processedDescription,
      pageDescription,
      loading,
      userFlowState
    } = matterTemplatesSelection
    const selectedValues = legalRequest.attributes
    const matterAttributes = legalRequest.matterAttributes

    const state = {
      description,
      processedDescription,
      pageDescription,
      list: publishedListSelector({ templateList, filterTerm, userFlowState }),
      loading,
      userFlowState,
      showSidebar: showSidebarSelector({ templateList, filterTerm, userFlowState })
    }

    if (userFlowState.userFlow === SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION) {
      return state
    }

    return {
      ...state,
      selectedValues,
      matterAttributes
    }
  })
  @withRouter
  class SelectionPageContainerParent extends Component {
    async componentDidMount() {
      await this.setFlowContext()
    }
    setFlowContext = () => {
      const { matterId, matterRequestId, formId } = queryString.parse(
        this.props.router.location.search
      )
      let flow, id
      if (matterId) {
        flow = SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
        id = matterId
      } else if (matterRequestId) {
        flow = SELECT_TEMPLATE_FLOW.MATTER_REQUEST
        id = matterRequestId
      } else if (this.props.forLegalRequestFormTemplateSelection) {
        flow = SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
        id = formId
      } else {
        flow = SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION
      }

      this.updateUserFlowState({ flow, id })
    }
    updateUserFlowState = obj => {
      this.props.dispatch({
        type: ACT.UPDATE_SELECTION_USER_FLOW,
        payload: {
          userFlow: obj.flow,
          id: obj.id,
          loadingUserFlow: false
        }
      })
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  return SelectionPageContainerParent
}

export default withSelectTemplateFlowSwitcher
