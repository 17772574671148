import get from 'lodash/get'
import { Banner, If } from 'simple-core-ui'

const SiteWideBanner = () => {
  // TODO Remove once IE is deprecated
  if (window.document.documentMode) {
    return (
      <Banner
        title="Switch your browser"
        message="SimpleLegal is ending support for Internet Explorer on August 17, 2021. Please switch to Edge, Chrome, Safari, or Firefox."
      />
    )
  }

  return (
    <>
      <If condition={window.credentials.globalNotification}>
        <Banner
          title={get(window.credentials.globalNotification, 'title')}
          message={get(window.credentials.globalNotification, 'message')}
        />
      </If>
      <If condition={window.credentials.perClientNotification}>
        <If condition={window.credentials.globalNotification}>
          <div style={{ 'margin-top': '10px' }} />
        </If>
        <Banner
          title={get(window.credentials.perClientNotification, 'title')}
          message={get(window.credentials.perClientNotification, 'message')}
        />
      </If>
    </>
  )
}

export default SiteWideBanner
