import { useState, useEffect } from 'react'
import {
  Panel,
  Button,
  DataTableWrapper,
  AvatarList,
  SwitchToggle,
  useLoading,
  ModalContainer
} from 'simple-core-ui'
import { TaskTypeModal } from './TaskTypeModal'
import { getNormalizedCellContent } from './utils'
import { checkByNameIfInArray, openLink, isBasicTaskManagementPlan } from 'utils/helpers'
import s from './TaskTypes.scss'
import { ActionsPopover } from './ActionsPopover'
import moment from 'moment'
import { useImmer } from 'use-immer'
import { TaskType } from './types'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import { makeGetRequest, makePatchRequest, makePostRequest, makeDeleteRequest } from 'utils/api'
import { toTaskTypes, toOptions } from './serializers'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { DeleteConfirmation } from './DeleteConfirmation'
import { TaskTemplateCascadeUpdateModal } from '../common/TaskTemplateCascadeUpdateModal'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}
interface CascadeUpdateModal {
  display?: boolean
  actionType?: 'delete' | 'toggleStatus'
}

const emptyTaskType = {
  name: ''
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 10,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Type',
    isSortable: true,
    isFilterable: true,
    style: { whiteSpace: 'break-spaces' }
  },
  {
    columnKey: 'relatedTasks',
    content: 'Related Tasks',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'relatedTemplates',
    content: 'Related Templates',
    isSortable: true,
    isFilterable: false
  },
  {
    columnKey: 'strategicValue',
    content: 'Strategic Value (1-10)',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'complexity',
    content: 'Complexity (1-10)',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastEdited',
    content: 'Last Edited',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'editedBy',
    content: 'Edited By',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'isActive',
    content: (
      <>
        Active
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="infoTooltip" data-tip />
        <ReactTooltip id="infoTooltip" type="light" effect="solid" place="top" border>
          Active settings can be applied to a task
        </ReactTooltip>
      </>
    )
  }
]

const TaskTypes = () => {
  const [showTaskTypeModal, setShowTaskTypeModal] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [taskTypes, setTaskTypes] = useImmer<TaskType[]>([])
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [taskType, setTaskType] = useState({} as TaskType)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [cascadeUpdateModal, setCascadeUpdateModal] = useState<CascadeUpdateModal>({})
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const fetchTaskTypes = async () => {
    try {
      const { rows } = await withLoadingLocks(makeGetRequest('/task-management/task-types/'))
      setTaskTypes(toTaskTypes(rows))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchTaskTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleStatus = async (id: number) => {
    const index = taskTypes.findIndex(t => t.id === id)
    try {
      const updatedTaskType = await withLoadingLocks(
        makePatchRequest(`/task-management/task-types/${id}/`, {
          is_active: !taskTypes[index].isActive,
          id,
          name: taskTypes[index].name
        })
      )
      setTaskTypes(draft => {
        draft[index] = toTaskTypes([updatedTaskType])[0]
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task type ${taskTypes[index].name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onToggleStatus = (id: number) => {
    const index = taskTypes.findIndex(p => p.id === id)
    const taskType = taskTypes[index]
    setTaskType(taskType)
    if (taskType.isActive && taskType.relatedTemplates.length > 0) {
      setCascadeUpdateModal({ display: true, actionType: 'toggleStatus' })
    } else {
      toggleStatus(id)
    }
  }

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (!content && content !== 0) {
      return '--'
    }

    if (columnKey === 'isActive') {
      const isActive = content !== '----'
      return (
        <SwitchToggle
          id={`taskType-${rowId}`}
          dataTestId={String(rowId)}
          checked={isActive}
          onToggle={isBasicTaskManagementPlan() ? undefined : () => onToggleStatus(rowId)}
          tooltipContent="Contact customer success to upgrade."
        />
      )
    }
    if (columnKey === 'relatedTemplates') {
      return content?.length || 0
    }
    if (columnKey === 'lastEdited') {
      return content === '----' ? '--' : moment(content).format('M/D/YYYY')
    }
    if (columnKey === 'editedBy') {
      return content === '----' ? (
        '--'
      ) : (
        <AvatarList size="md" wrapperStyles={{ width: 36 }} entries={[{ label: content }]} />
      )
    }
    return content
  }

  const toggleTaskTypeModal = () => {
    setShowTaskTypeModal(!showTaskTypeModal)
    setShowDuplicateError(false)
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const showEditModal = (taskType: TaskType) => {
    toggleTaskTypeModal()
    setTaskType(taskType)
  }

  const handleDelete = (taskType: TaskType) => {
    setTaskType(taskType)
    if (taskType.relatedTemplates.length > 0) {
      setCascadeUpdateModal({ display: true, actionType: 'delete' })
    } else {
      setShowDeleteConfirmation(true)
    }
  }

  const onDeleteType = async () => {
    const index = taskTypes.findIndex(e => e.id === taskType.id)
    try {
      await makeDeleteRequest(`/task-management/task-types/${taskType.id}/`)

      const arr = [...taskTypes]
      arr.splice(index, 1)
      setTaskTypes(arr)

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Task type successfully deleted',
          level: 'success'
        }
      })
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem deleting the task type',
          level: 'error'
        }
      })
    }

    setShowDeleteConfirmation(false)
  }

  const updateTaskType = (value: string | number | null, property: string) => {
    if (property === 'name' && typeof value === 'string') {
      setShowDuplicateError(checkByNameIfInArray(value, taskTypes, { id: taskType.id }))
    }
    setTaskType({
      ...taskType,
      [property]: value
    })
  }

  const saveTaskType = async () => {
    const { id, name, complexity, strategicValue } = taskType
    try {
      if (id) {
        const index = taskTypes.findIndex(p => p.id === id)
        const oldTaskType = taskTypes[index]
        const data = {
          id,
          name,
          ...(oldTaskType.complexity !== complexity ? { complexity } : {}),
          ...(oldTaskType.strategicValue !== strategicValue
            ? { strategic_value: strategicValue }
            : {})
        }
        const updatedTaskType = await withLoadingLocks(
          makePatchRequest(`/task-management/task-types/${id}/`, data)
        )
        setTaskTypes(draft => {
          draft[index] = toTaskTypes([updatedTaskType])[0]
        })

        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: `Task type ${taskType.name} successfully updated.`,
            level: 'success'
          }
        })
      } else {
        const newTaskType = await withLoadingLocks(
          makePostRequest(`/task-management/task-types/`, {
            name,
            complexity,
            strategic_value: strategicValue
          })
        )
        setTaskTypes(draft => {
          draft.push(toTaskTypes([newTaskType])[0])
        })

        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: `Task type ${taskType.name} successfully created.`,
            level: 'success'
          }
        })
      }

      toggleTaskTypeModal()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      toggleTaskTypeModal()
    }
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering

    const url = `/task-management/task-types/export/?columnKey=${columnKey}&isDesc=${+isDesc}`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const renderCustomAction = (row: TaskType) => (
    <ActionsPopover
      type={row}
      deleteType={handleDelete}
      canDelete={+row.relatedTasks === 0}
      editType={showEditModal}
    />
  )

  return (
    <Panel
      title="Manage Task Types"
      subtitle="Use types to categorize your tasks."
      rightActions={[
        <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary isOutline>
          Download
        </Button>,
        <Button
          hasNewDesign
          key="add"
          onClick={() => showEditModal(emptyTaskType as TaskType)}
          isPrimary
        >
          Add Type
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.statuses}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={taskTypes}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        customAction={renderCustomAction}
        hasActions
        hasTooltip
        alwaysShowActions
        categoryKey="taskType"
        getNormalizedCellContent={getNormalizedCellContent}
        renderCell={renderCell}
        multiSort
        customActionsMemoizationDeps={[taskTypes]}
      />
      {showTaskTypeModal && (
        <TaskTypeModal
          saveTaskType={saveTaskType}
          taskType={taskType}
          updateTaskType={updateTaskType}
          toggleTaskTypeModal={toggleTaskTypeModal}
          showDuplicateError={showDuplicateError}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={onDeleteType}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
      {cascadeUpdateModal.display && (
        <TaskTemplateCascadeUpdateModal
          options={toOptions(taskTypes)}
          entity={taskType}
          callMainAction={() => {
            if (cascadeUpdateModal.actionType === 'toggleStatus') {
              return toggleStatus(taskType.id)
            }
            if (cascadeUpdateModal.actionType === 'delete') {
              return onDeleteType()
            }
          }}
          onCancel={() => setCascadeUpdateModal({ display: false })}
          refreshTable={fetchTaskTypes}
          label="type"
          getPayload={id => ({
            task_ids: taskType.relatedTemplates.map(t => t.taskIds).flat(),
            task_type_id: id
          })}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </Panel>
  )
}

export default TaskTypes
