import React from 'react'
import { connect } from 'react-redux'
import { HorizField } from 'components'
import { If, Modal } from 'simple-core-ui'
import { VendorSelect, VendorContactSelect, TimekeeperSelect } from 'common/Selects'
import s from 'budgets/forms/styles/CreateBudgetRequest.scss'
import get from 'lodash/get'
import ACT from 'budgets/actions'
import BudgetRequestForm from 'budgets/forms/containers/BudgetRequestForm'
import { validateReferral } from 'budgets/forms/validators'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

const refreshPeriods = [
  { label: 'None', value: 'None' },
  { label: 'Annually', value: 'annually' },
  { label: 'Biannually', value: 'biannually' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' }
]

const MODAL_BUFFER = 350

@connect(({ budgets }, ownProps) => {
  const { matterId, referVendorUrl, createForm } = budgets

  return {
    matterId: ownProps?.matterId ?? matterId,
    referVendorUrl,
    createForm
  }
})
class CreateReferralModal extends React.Component {
  state = {
    vendorId: null,
    vendorContactId: null,
    budgetRequested: false,
    timekeeperId: null,
    reset: 0
  }

  submitBudgetRequest = async () => {
    const { budgetRequested, vendorId, vendorContactId, timekeeperId } = this.state
    const { matterId, createForm, cancelCb, submitCb } = this.props
    const formState = { ...createForm, budgetRequested, vendorId, vendorContactId }
    const { isError, errors } = validateReferral(formState)
    if (isError) {
      this.props.dispatch({
        type: ACT.UPDATE_BUDGET_REQUEST_FORM,
        payload: {
          errors
        }
      })
    } else {
      submitCb({
        ...formState,
        vendorId: vendorId?.value,
        vendorContactId: vendorContactId?.value,
        matterId,
        timekeeperId: timekeeperId?.value
      })
      this.setState({
        vendorId: null,
        vendorContactId: null,
        budgetRequested: false,
        reset: this.state.reset + 1
      })
    }
  }

  toggleBudgetRequest = e => {
    this.setState({
      budgetRequested: !!e.target.checked
    })
  }

  selectVendor = selectedVendor => {
    this.setState({
      vendorId: selectedVendor,
      vendorContactId: null,
      budgetRequested: false
    })
  }

  selectTimekeeper = selectedTimekeeper => {
    this.setState({
      timekeeperId: selectedTimekeeper,
      budgetRequested: false
    })
  }

  selectVendorContact = selectedVendorContact => {
    if (!selectedVendorContact) {
      this.setState({
        timekeeperid: null
      })
    }

    this.setState({
      vendorContactId: selectedVendorContact
    })
  }

  render() {
    const { createForm, isVisible, cancelCb } = this.props
    const {
      budgetRequested,
      budgetType,
      refreshPeriod,
      vendorId,
      reset,
      timekeeperId,
      vendorContactId
    } = this.state
    const errors = createForm?.errors

    return (
      <Modal
        title="Assign Vendor To Matter"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={
          <div>
            <HorizField label="Assigned Vendor" hasError={errors?.vendor} isRequired>
              <VendorSelect
                ariaLabel="Search Vendors"
                value={vendorId}
                isPortal={false}
                isClearable={false}
                id="testid_assigned_vendor_select"
                onChange={this.selectVendor}
                paginated
                withInactiveVendors={false}
              />
            </HorizField>

            <HorizField label="Vendor Lead">
              <VendorContactSelect
                ariaLabel="Search Vendor Leads"
                value={vendorContactId}
                isPortal={false}
                id="testid_vendor_lead"
                isDisabled={!vendorId}
                selectedVendorId={get(vendorId, 'value')}
                onChange={this.selectVendorContact}
                isClearable
                paginated
              />
            </HorizField>

            {hasModule(MODULE.VISIBLE_DIVERSITY_REPORTS) && (
              <HorizField label="Is the vendor lead also a timekeeper? If yes, select which timekeeper.">
                <TimekeeperSelect
                  ariaLabel="Search vendor timekeepers"
                  value={timekeeperId}
                  id="testid_timekeeper"
                  isDisabled={
                    !vendorId || !vendorContactId || !window.credentials.user.role === 'admin'
                  }
                  selectedVendorId={get(vendorId, 'value')}
                  onChange={this.selectTimekeeper}
                  isPortal={false}
                  isClearable
                />
              </HorizField>
            )}

            {hasModule(MODULE.BUDGET_REQUESTS) && (
              <div className={s.horizontalField}>
                <input
                  type="checkbox"
                  name="request_budget"
                  id="slm_toggle_budget_request"
                  data-testid="slm_toggle_budget_request"
                  value={budgetRequested}
                  disabled={!vendorId}
                  onChange={this.toggleBudgetRequest}
                  style={{ marginRight: 4 }}
                />
                <span>Request a budget from this vendor for this matter</span>
              </div>
            )}

            <If condition={!budgetRequested}>
              <div className="p-sp700"></div>
            </If>
            <If condition={budgetRequested}>
              <hr />
              <BudgetRequestForm vendorId={get(vendorId, 'value')} errors={errors} />
            </If>
          </div>
        }
        isVisible={isVisible}
        confirmCb={this.submitBudgetRequest}
        cancelCb={cancelCb}
      />
    )
  }
}

export default CreateReferralModal
