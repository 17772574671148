import { Anchor } from 'simple-core-ui'

import { PENDING_INVOICES_COLUMN_KEYS } from '../../utils'

import s from './PendingInvoicesCell.scss'

const PendingInvoicesCell = ({ rowId, columnKey, content }) => {
  const urlBaseMap = {
    matter: '/manage/matters/v2/',
    vendor: '/invoices/vendors/',
    invoice: '/invoices/'
  }

  switch (columnKey) {
    case PENDING_INVOICES_COLUMN_KEYS.NUMBER:
      return <Anchor url={`${urlBaseMap['invoice']}${rowId}`}>{content}</Anchor>
    case PENDING_INVOICES_COLUMN_KEYS.MATTERS:
      return (
        <ul>
          {content.map(({ id, name }) => (
            <li key={id} className={s.matter}>
              <Anchor url={`${urlBaseMap['matter']}${id}`}>{name}</Anchor>
            </li>
          ))}
        </ul>
      )
    case PENDING_INVOICES_COLUMN_KEYS.VENDOR:
      return <Anchor url={`${urlBaseMap['vendor']}${content.id}`}>{content.name}</Anchor>
    default:
      return content
  }
}

export default PendingInvoicesCell
