import s from './BoxSelect.scss'

const INITIAL_SIZE = 10

function BoxSelect({ units, selectedUnit, setSelectedUnit, ranges, hideRanges }) {
  if (hideRanges) {
    return (
      <div className={s.container}>
        <div className={s.column} style={{ width: '100%', borderRight: 'none' }}>
          {units.slice(0, INITIAL_SIZE).map((unit, i) =>
            unit.value === selectedUnit?.value ? (
              <div key={i} className={s.selectedUnit}>
                {unit.label}
              </div>
            ) : (
              <div key={i} className={s.unselectedUnit} onClick={() => setSelectedUnit(unit)}>
                {unit.label}
              </div>
            )
          )}
        </div>
      </div>
    )
  }

  return units ? (
    <div className={s.container}>
      <div className={s.column}>
        {units.slice(0, INITIAL_SIZE).map((unit, i) =>
          unit.value === selectedUnit?.value ? (
            <div key={unit.value} className={s.selectedUnit}>
              {unit.label}
            </div>
          ) : (
            <div
              key={unit.value}
              className={s.unselectedUnit}
              onClick={() => setSelectedUnit(unit)}
            >
              {unit.label}
            </div>
          )
        )}
      </div>
      {ranges && (
        <div className={s.column}>
          {ranges.map((r, i) => {
            return r.value === selectedUnit?.value ? (
              <div key={i} className={s.selectedUnit}>
                {r.label}
              </div>
            ) : (
              <div key={i} className={s.unselectedUnit} onClick={() => setSelectedUnit(r)}>
                {r.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    <div>loading...</div>
  )
}

export default BoxSelect
