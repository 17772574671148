import SimpleSelectFilter from './SimpleSelectFilter'
import { makeGetRequest } from 'utils/api'
import { formatMultiSelectLabel } from '../helpers'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import s from './Filter.scss'
import BoxSelect from '../../Selects/components/BoxSelect'

const url = (isVendor, entityId) =>
  `/reports/diversity/${!isVendor ? 'practice_area' : 'vendor'}/${entityId}/filter_dates/`

const YearFilter = ({
  onSelect,
  reset,
  title = 'Year',
  hasConfirmation,
  initialValue,
  isVendor,
  isMulti,
  entityId,
  limit,
  paginated,
  withNoneOption,
  setYears,
  years,
  hideRanges
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    try {
      if (entityId) {
        makeGetRequest(url(isVendor, entityId)).then(y => setYears(y))
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId])

  const yearRanges = [
    { value: '2 yrs', label: 'Last 2 years' },
    { value: '3 yrs', label: 'Last 3 years' }
  ]

  return (
    <SimpleSelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti={isMulti}
      hasConfirmation={hasConfirmation}
      customLabel={formatMultiSelectLabel(isMulti, title)}
      style={{ width: '250px', padding: '12px 16px 12px' }}
      render={(year, setYear) => (
        <div className={s.simpleFilterContainer} style={hideRanges ? { width: 200 } : {}}>
          <BoxSelect
            selectedUnit={year}
            setSelectedUnit={setYear}
            units={years}
            ranges={yearRanges}
            hideRanges={hideRanges}
          />
        </div>
      )}
    />
  )
}

export default YearFilter
