import * as React from 'react'
import { connect } from 'react-redux'
import { DataTableContainer, coreThemes } from 'simple-core-ui'
import get from 'lodash/get'

import { SCOPE } from 'utils/constants'

import ContactAffiliationCell from './ContactAffiliationCell/ContactAffiliationCell'
import { isClientContact, INITIAL_AFFILIATION_PARAMS } from '../utils'

import ACT from '../actions'
import { initialState } from '../reducer'

@connect(({ contacts }) => {
  const { contact, affiliation } = contacts
  const isClientType = isClientContact(contact)

  return { affiliation, isClientType }
})
class ContactAffiliationContainer extends React.Component {
  static defaultProps = {
    affiliation: initialState.affiliation,
    isClientType: false,
    dispatch: obj => {}
  }

  updateAffiliationList = updateParams => {
    const { dispatch, contactId, isClientType } = this.props

    dispatch({
      type: ACT.CONTACT_AFFILIATION_FETCH_REQUESTED,
      payload: {
        contactId,
        isClientType,
        ...updateParams
      }
    })
  }

  categories = () => {
    const { categoryCounts } = this.props.affiliation

    return [
      { label: 'Matters', value: SCOPE.MATTER, count: categoryCounts.matter },
      { label: 'Vendors', value: SCOPE.VENDOR, count: categoryCounts.vendor },
      { label: 'Legal Entities', value: SCOPE.LEGAL_ENTITY, count: categoryCounts.legalEntity },
      { label: 'Practice Areas', value: SCOPE.PRACTICE_AREA, count: categoryCounts.practiceArea }
    ]
  }

  pageSizeOptions = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  getCategoryLabel = categoryValue =>
    get(
      this.categories().find(({ value }) => categoryValue === value),
      'label',
      ''
    )

  styleColumns = columns => {
    const columnWidths = {
      name: '35%',
      roles: '45%',
      permissions: '20%'
    }

    return columns.map(column => ({
      ...column,
      style: { width: columnWidths[column.columnKey] }
    }))
  }

  renderCell = ({ rowId, columnKey, content }) => (
    <ContactAffiliationCell
      rowId={rowId}
      columnKey={columnKey}
      content={content}
      category={this.props.affiliation.categoryValue}
    />
  )

  getTableProps = () => {
    const {
      rows,
      columns,
      page,
      previousPage,
      nextPage,
      totalEntries,
      filteredTotal,
      categoryValue,
      isLoading,
      isError
    } = this.props.affiliation

    return {
      rows,
      columns: this.styleColumns(columns),
      initialValues: {
        ...INITIAL_AFFILIATION_PARAMS,
        page: page
      },
      title: categoryValue ? `${this.getCategoryLabel(categoryValue)} (${filteredTotal})` : '',
      pageSizeOptions: this.pageSizeOptions,
      previousPage,
      nextPage,
      totalEntries,
      filteredTotal,
      categories: this.categories(),
      categoriesClearable: false,
      panelTitle: 'Contact Affiliations',
      isLoading,
      isError,
      renderCell: this.renderCell,
      updateTable: this.updateAffiliationList,
      theme: coreThemes.EB
    }
  }

  render = () => {
    return <DataTableContainer {...this.getTableProps()} />
  }
}

export default ContactAffiliationContainer
