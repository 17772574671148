import { useLocation } from 'react-router-dom'
import ReviewLadderContainer from './containers/ReviewLadderContainer'
import AdminActionsContainer from './containers/AdminActionsContainer'
import ReviewableStatusContainer from './containers/ReviewableStatusContainer'
import ReviewableBannerContainer from './containers/ReviewableBannerContainer'

import { invoiceScoped, matterScoped } from './scopedProps'
import { SCOPE } from './utils/constants'

export function ScopedReviewableStatus() {
  const location = useLocation()
  if (location.pathname.includes('invoices')) {
    return <ReviewableStatusContainer {...invoiceScoped} scope={SCOPE.INVOICE} />
  } else {
    return null
  }
}

export function ScopedReviewLadder({ className }) {
  return (
    <>
      {location.pathname.includes('invoices') && <ReviewLadderContainer {...invoiceScoped} />}
      {location.pathname.includes('matters') && (
        <ReviewLadderContainer {...matterScoped} withPanel className={className} />
      )}
    </>
  )
}

export function ScopedAdminActions() {
  return <>{location.pathname.includes('invoices') && <AdminActionsContainer />}</>
}

export function ScopedReviewableBanner({ classNameMap, reviewId, pendingRequiredFields }) {
  return (
    <>
      {location.pathname.includes('matters') && (
        <ReviewableBannerContainer
          classNameMap={classNameMap}
          reviewId={reviewId || matterScoped.reviewId}
          pendingRequiredFields={pendingRequiredFields}
        />
      )}
    </>
  )
}

export default ReviewLadderContainer
