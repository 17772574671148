import { Constant } from 'simple_review/@types/common'
import { RhsTypes } from 'simple_review/@types/editor'
import { fieldType } from '../constant-model'
import { CanSerialize } from '../can-serialize'

interface ListConstant {
  type: string
  isList: boolean
  values: Array<Constant>
  label: string
  subType: string | null
  rhsInputType: string
}

export abstract class ListConstantMapper implements CanSerialize {
  abstract readonly type: fieldType
  constructor(protected _constant: Array<Constant>, protected _subType: string | null = '') {}

  toJSON(): string {
    const data = {
      constant: this._constant,
      type: this.type,
      sub_type: this._subType
    }

    return JSON.stringify(data)
  }

  fromJSON(): ListConstant {
    return {
      type: this.type,
      isList: true,
      values: this._constant,
      label: this._constant.map(({ label }) => label).join(', '),
      subType: '',
      rhsInputType: RhsTypes.MultiSelect
    }
  }
}
