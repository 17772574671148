import { Priority, APIPriority, Option } from './types'
import { formatName } from 'utils/helpers'

export const toPriorities = (priorities: APIPriority[]): Priority[] => {
  return priorities.map((priority: APIPriority) => {
    const {
      id,
      level,
      default_display_name,
      default_color,
      client_settings,
      related_tasks,
      related_templates
    } = priority

    return {
      id: id,
      name: client_settings?.display_name ?? default_display_name,
      color: client_settings?.color ?? default_color,
      level: String(level),
      relatedTasks: String(related_tasks),
      relatedTemplates: related_templates.map(template => ({
        templateId: template.template_id,
        taskIds: template.task_ids
      })),
      lastEdited: client_settings?.edited_date ?? null,
      editedBy: formatName(client_settings?.edited_by) || null,
      isActive: client_settings?.is_active ?? true
    }
  })
}

export const toOptions = (priorities: Priority[]): (Option & { isActive: boolean })[] => {
  return priorities.map((priority: Priority) => {
    return {
      value: priority.id,
      label: priority.name,
      isActive: priority.isActive
    }
  })
}
