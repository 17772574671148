import { LoadingSkeleton } from 'simple-core-ui'
import s from './Permissions.scss'

export default function PermsLoadingSkeleton() {
  return (
    <>
      {[...Array(4)].map((_, index) => {
        return (
          <div key={index} className={s.row} style={{ marginBottom: 8 }}>
            <LoadingSkeleton height={50} />
          </div>
        )
      })}
    </>
  )
}
