import { TagIcon } from 'simple-core-ui'
import chroma from 'chroma-js'
import s from './TableLabels.scss'

const TableLabels = ({ visibleLabels, fileLabels, tagRequired = true }) => {
  return (
    <>
      {visibleLabels.length > 0 && (
        <div className={s.labelsWrapper}>
          {tagRequired && <TagIcon className={s.tagIcon} />}
          {visibleLabels.map(label => {
            const color = chroma(label.color)
            return (
              <div
                key={label.value}
                style={{
                  backgroundColor: color.alpha(0.3).css()
                }}
                className={s.label}
              >
                {label.label}
              </div>
            )
          })}
          {fileLabels.length > visibleLabels.length && (
            <div
              style={{
                backgroundColor: chroma('black')
                  .alpha(0.1)
                  .css()
              }}
              className={s.label}
            >
              +{fileLabels.length - visibleLabels.length}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default TableLabels
