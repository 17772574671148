import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from '../FileUpload.scss'

const ActionsPopover = ({ rowId, deleteAttachment }) => {
  const actions = [
    {
      name: 'DELETE_ATTACHMENT',
      text: 'Delete',
      onClick() {
        deleteAttachment(rowId)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
