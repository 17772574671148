import { useSelector, useDispatch } from 'react-redux'
import { Button, ModalContainer } from 'simple-core-ui'
import { useMatterGroupLabels } from 'hooks/selectors'

import s from '../styles/BudgetsList.scss'
import {
  VendorFilter,
  MatterFilter,
  UserFilter,
  BudgetTypeFilter,
  MonthFilter,
  RadioFilter,
  CostCodeFilter,
  PracticeAreaFilter,
  BudgetFilter,
  BudgetIdFilter
} from 'common/Filters'
import { serializeParams } from 'budgets/serializers'
import { LockBudgets } from '../components/LockBudgets'
import { makeGetRepeatedParams } from 'utils/api'
import { uriEncodeObject } from 'utils/helpers'
import APP_ACT from 'app/actions'

const Filters = ({ handleFilters, reset, setReset, setIsModalOpen, hasConfirmation }) => {
  const filters = useSelector(state => state.budgets.filters)
  const matterGroupLabels = useMatterGroupLabels()
  const dispatch = useDispatch()

  const handleDownload = openModal => async () => {
    const url = '/budgets/active/excel/download/'
    const serializedPayload = serializeParams(filters)
    try {
      const { count } = await makeGetRepeatedParams(url, { ...serializedPayload, count_only: true })
      if (count < 50) {
        window.location.href = `${url}?${uriEncodeObject(serializedPayload)}`
      } else {
        await makeGetRepeatedParams(url, serializedPayload)
        openModal()
      }
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          level: 'error',
          message: 'There was an issue computing the budget requests list on the server.'
        }
      })
    }
  }

  return (
    <div className={s.filterContainer}>
      <BudgetFilter
        title="Name"
        hasConfirmation={hasConfirmation}
        reset={reset}
        isMulti
        onSelect={budget => handleFilters({ budget })}
        initialValue={filters.budget}
      />
      <BudgetIdFilter
        title="ID"
        hasConfirmation={hasConfirmation}
        reset={reset}
        isMulti
        onSelect={ids => handleFilters({ ids })}
        initialValue={filters.ids}
      />
      <MatterFilter
        onSelect={matter => {
          handleFilters({ matter })
        }}
        isMulti
        initialValue={filters.matter}
        reset={reset}
      />
      <UserFilter
        initialValue={filters.matter_lead}
        title="Matter Lead"
        reset={reset}
        onSelect={matter_lead => {
          handleFilters({ matter_lead })
        }}
        hasConfirmation={hasConfirmation}
        isMulti
        paginated
        withNoneOption
      />
      <VendorFilter
        hasConfirmation={hasConfirmation}
        isMulti
        onSelect={vendors => {
          handleFilters({ vendors })
        }}
        initialValue={filters.vendors}
        reset={reset}
      />
      <PracticeAreaFilter
        hasConfirmation={hasConfirmation}
        onSelect={matter_group => {
          handleFilters({ matter_group })
        }}
        initialValue={filters.matter_group}
        reset={reset}
        isMulti
        title={matterGroupLabels[0]}
      />
      <CostCodeFilter
        hasConfirmation={hasConfirmation}
        onSelect={cost_code => {
          handleFilters({ cost_code })
        }}
        reset={reset}
        isMulti
        initialValue={filters.cost_code}
        title="Cost Code"
      />
      <BudgetTypeFilter
        hasConfirmation={hasConfirmation}
        onSelect={budget_type => {
          handleFilters({ budget_type })
        }}
        initialValue={filters.budget_type}
        isMulti
        reset={reset}
      />
      <MonthFilter
        hasConfirmation={hasConfirmation}
        onSelect={start_date => {
          handleFilters({ start_date })
        }}
        initialValue={filters.start_date}
        reset={reset}
        title="Start Date"
        customLabel={selected => {
          if (!selected) return 'Start Date'
          return `Start Date: ${selected.label}`
        }}
      />
      <MonthFilter
        hasConfirmation={hasConfirmation}
        onSelect={end_date => {
          handleFilters({ end_date })
        }}
        initialValue={filters.end_date}
        reset={reset}
        title="End Date"
        customLabel={selected => {
          if (!selected) return 'End Date'
          return `End Date: ${selected.label}`
        }}
      />
      <RadioFilter
        hasConfirmation={hasConfirmation}
        onSelect={budget_health => {
          handleFilters({ budget_health })
        }}
        initialValue={filters.budget_health}
        title="Health"
        options={[
          {
            value: 0,
            label: 'Within'
          },
          {
            value: 1,
            label: 'Over'
          }
        ]}
        reset={reset}
      />
      <RadioFilter
        hasConfirmation={hasConfirmation}
        onSelect={source => {
          handleFilters({ source })
        }}
        initialValue={filters.source}
        title="Source"
        options={[
          {
            value: 0,
            label: 'External'
          },
          {
            value: 1,
            label: 'Internal'
          }
        ]}
        reset={reset}
      />
      <div className={s.clear} onClick={() => setReset(reset + 1)}>
        Reset
      </div>
      <div className={s.buttonWrapper}>
        <LockBudgets />
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your report is ready."
          confirmText="OK"
          size="sm"
          hasCloseIcon={false}
          confirmCb={() => null}
        >
          {openModal => (
            <Button isSecondary onClick={handleDownload(openModal)} testid="download_xlsx_button">
              Download
            </Button>
          )}
        </ModalContainer>
        <Button isPrimary onClick={() => setIsModalOpen(true)} testid="add_budget_button">
          Add Budget
        </Button>
      </div>
    </div>
  )
}

export default Filters
