import { useState, useEffect, useMemo } from 'react'
import { filesize } from 'filesize'
import { Modal, HoverAction, Download, Document } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { preventDefaultCallback } from 'matters/detail/files/MatterAttachmentsTab/helpers'
import { viewAttachment } from 'matters/detail/files/MatterAttachmentsTab/utils'
import { FileStatusIcon } from 'matters/detail/files/MatterAttachmentsTab/FileStatusIcon'
import { FILE_STATUS_INFECTED } from '../../constants'
import s from './VersionHistory.scss'

const VersionHistory = ({
  fileId,
  showVersionHistory,
  toggleVersionHistory,
  hasVendorCollaboration
}) => {
  const [versions, setVersions] = useState([])

  useEffect(() => {
    if (showVersionHistory) {
      ;(async () => {
        const result = await makeGetRequest(`/doc_management/native_docs/versions/${fileId}`)
        setVersions(result.versions)
      })()
    }
  }, [fileId, showVersionHistory])

  const sortedVersions = useMemo(
    () =>
      versions
        .sort((version1, version2) => version2.version - version1.version)
        .map(v => ({ ...v, disabled: v.fileStatus === FILE_STATUS_INFECTED })),
    [versions]
  )

  return (
    <Modal
      title="Version History"
      size="sm"
      isVisible={showVersionHistory}
      confirmText="Close"
      confirmCb={e => {
        preventDefaultCallback(e, () => {
          toggleVersionHistory()
        })
      }}
      hideCancelBtn
      cancelCb={toggleVersionHistory}
      hasNewButtons={hasVendorCollaboration}
      content={
        <div
          onClick={e => {
            preventDefaultCallback(e)
          }}
        >
          <div>
            {sortedVersions.map((version, index) => (
              <div key={`version-${version.id}`} className={s.versionContainer}>
                <div className={s.versionIconContainer}>
                  <Document />
                  <span className={s.versionNumber}>{`v${version.version}`}</span>
                </div>
                <div>
                  <div className={s.versionInfo}>
                    <FileStatusIcon fileStatus={version.fileStatus} />
                    {index === 0 ? 'Current Version' : `Updated ${version.fileUploadDate}`}
                  </div>
                  <div className={s.additonalInfo}>
                    {version.uploadedBy} {filesize(version.fileSize)}
                  </div>
                </div>
                <div
                  className={s.downloadIconContainer}
                  onClick={e => {
                    preventDefaultCallback(e, () => {
                      !version.disabled && viewAttachment(version)
                    })
                  }}
                >
                  <HoverAction
                    tip="Download"
                    icon={<Download />}
                    size="xsmall"
                    className={s.hoverAction}
                    isDisabled={version.disabled}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    />
  )
}

export default VersionHistory
