const Checkbox = ({ defaultChecked, disabled, onChange }) => (
  <input
    type="checkbox"
    disabled={disabled}
    checked={defaultChecked || false}
    onChange={e => onChange(e.target.checked)}
  />
)

export default Checkbox
