import { useState, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel, Button } from 'simple-core-ui'
import s from './NotificationsContainer.scss'
import { FILTER_TYPE } from 'doc_management/settings/constants'
import ACT from 'doc_management/settings/actions'
import { Notifications } from './Notifications'
import { AddAdmin } from './AddAdmin'
import { DeleteAdmin } from './DeleteAdmin'

const initialState = {
  params: {
    pageSize: 25,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: FILTER_TYPE.ALL.toLowerCase()
  },
  selectedAdmin: {}
}

const reducer = (state, action) => {
  const { params, selectedAdmin } = action.payload
  switch (action.type) {
    case ACT.UPDATE_TABLE_PARAMS:
      return { ...state, params }
    case ACT.SELECT_ADMIN:
      return { ...state, selectedAdmin }
    default:
      throw new Error('Action not found')
  }
}

const columns = [
  { columnKey: 'name', content: 'Admin Name', isSortable: true, isFilterable: true },
  { columnKey: 'email', content: 'Email Address', isSortable: true, isFilterable: true },
  { columnKey: 'added_ts', content: 'Added on', isSortable: true, isFilterable: true }
]

const NotificationsContainer = () => {
  const [showAddAdmin, setShowAddAdmin] = useState(false)
  const [showDeleteAdmin, setShowDeleteAdmin] = useState(false)

  const { notifications } = useSelector(state => state.documentManagementSettings)
  const dispatch = useDispatch()
  const [localState, dispatchState] = useReducer(reducer, initialState)
  const { params, selectedAdmin } = localState

  useEffect(() => {
    dispatch({ type: ACT.NOTIFICATIONS_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleAddAdmin = () => setShowAddAdmin(prevShowAddAdmin => !prevShowAddAdmin)

  const toggleDeleteAdmin = () => setShowDeleteAdmin(prevShowDeleteAdmin => !prevShowDeleteAdmin)

  const updateTable = params => {
    dispatchState({
      type: ACT.UPDATE_TABLE_PARAMS,
      payload: {
        params
      }
    })
  }

  const addAdmin = admin => {
    dispatch({
      type: ACT.ADD_ADMIN_REQUESTED,
      payload: {
        id: admin.value
      }
    })
    toggleAddAdmin()
  }

  const deleteAdmin = notification => {
    const { id } = notification
    dispatch({
      type: ACT.DELETE_ADMIN_REQUESTED,
      payload: {
        id
      }
    })
    toggleDeleteAdmin()
  }

  const selectAdmin = row => {
    const { id } = row
    const selectedAdmin = notifications.find(admin => admin.id === id)
    dispatchState({
      type: ACT.SELECT_ADMIN,
      payload: {
        selectedAdmin
      }
    })
    toggleDeleteAdmin()
  }

  return (
    <Panel
      title="Manage Notifications"
      rightActions={[
        <Button onClick={() => toggleAddAdmin()} isPrimary key="admins">
          Add Admin
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.notificationsContainer}
    >
      <Notifications
        params={params}
        columns={columns}
        rows={notifications}
        updateTable={updateTable}
        selectAdmin={selectAdmin}
      />
      {showAddAdmin && (
        <AddAdmin showAddAdmin={showAddAdmin} toggleAddAdmin={toggleAddAdmin} addAdmin={addAdmin} />
      )}
      {showDeleteAdmin && (
        <DeleteAdmin
          showDeleteAdmin={showDeleteAdmin}
          toggleDeleteAdmin={toggleDeleteAdmin}
          deleteAdmin={deleteAdmin}
          notification={selectedAdmin}
        />
      )}
    </Panel>
  )
}

export default NotificationsContainer
