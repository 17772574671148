import { useState, useEffect } from 'react'
import s from './BuilderFieldsModal.scss'
import { IoIosSearch } from 'react-icons/io'
import FieldsList from './FieldsList/FieldsList'
import { TextInput } from 'simple-core-ui'

const inputStyles = {
  borderRadius: 2,
  marginBottom: 3,
  padding: '0.6rem 1.6rem',
  border: '1px solid $gray1100',
  width: '90%',
  fontSize: 14
}

const BuilderFieldsModal = ({ type, fields, selectedFields, setSelectedFields, recType }) => {
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    const sourceList = selectedFields.length ? selectedFields : fields
    setSelectedFields(
      sourceList.map(listItem => ({
        ...listItem,
        hideOption: !listItem.label.toLowerCase().includes(searchInput.toLowerCase())
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  return (
    <>
      <div>
        <IoIosSearch className={s.searchIcon} />
        <TextInput
          placeholder="Search attribute name"
          value={searchInput}
          onChange={value => setSearchInput(value)}
          debounceDelay={0}
          style={inputStyles}
        />
        {searchInput && (
          <a className={s.clearText} onClick={() => setSearchInput('')}>
            Clear
          </a>
        )}
      </div>
      <div className={s.columnsWrapper}>
        <FieldsList
          id="general"
          type={type}
          fields={selectedFields}
          setFields={setSelectedFields}
          recType={recType}
        />
        <FieldsList
          id="custom"
          type={type}
          fields={selectedFields}
          setFields={setSelectedFields}
          recType={recType}
        />
      </div>
    </>
  )
}

export default BuilderFieldsModal
