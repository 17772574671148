export const SYSTEM_GENERATED = 'property'
export const SYSTEM_GENERATED_LABEL = 'System Generated'
export const LEGAL_ENTITY_ID = 'legal_entity'
export const MATTER_GROUP_ID = 'matter_group'
export const LEGAL_ENTITY = 'LegalEntity'
export const MATTER_GROUP = 'MatterGroup'
export const OTHER_ATTRIBUTE = 'Other_Attribute'
export const OTHER_ATTRIBUTE_VALUE_LIST = 'Other_Attribute_Value_List'
export const MATTER_SHORT_NAME = 'matter_short_name'
export const MATTER_LEAD = 'customuser_id'
export const APPROVERS = 'approvers'
export const MAX_TEMPLATES_COUNT = 1000000
export const LIST_LENGTH_UNTIL_PAGINATION_NOT_REQUIRED = 200
export const MAX_DROPDOWN_HEIGHT = 250

export const SELECT_TEMPLATE_FLOW = {
  CHANGE_MATTER_TEMPLATE: 'forChangeMatterTemplate',
  MATTER_REQUEST: 'forMatterRequest',
  LEGAL_REQUEST_FORM_TEMPLATE_SELECTION: 'forLegalRequestFormTemplateSelection',
  SIMPLE_MATTER_CREATION: 'forSimpleMatterCreation',
  CHANGE_MATTER_TEMPLATE_ID: 'matterId',
  MATTER_REQUEST_ID: 'matterRequestId',
  LEGAL_REQUEST_FORM_TEMPLATE_SELECTIOM_ID: 'formId'
}

export const PAGE_DESCRIPTIONS = {
  FORM_TEMPLATES_SELECT_PAGE_KEY: 'select_template_for_matter_request_form',
  MATTER_TEMPLATES_SELECT_PAGE_KEY: 'select_template_for_matter',
  MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_KEY: 'change_template_for_matter',
  FORM_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION:
    'Select templates used to create the matters made from this Legal Request.',
  MATTER_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION: 'Select a template to create a matter from.',
  MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_DEFAULT_DESCRIPTION: 'Select a new template for the matter.'
}

export const TEMPLATE_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
  DELETED: 'deleted'
}
