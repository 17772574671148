import { MdUpdate } from 'react-icons/md'
import cn from 'classnames'
import s from './Legend.scss'
import { AssessmentOutlinedIcon } from 'simple-core-ui'
import { LEGEND_ICON_DESCRIPTION } from '../../constants'

const reportIconConfig = {
  live: {
    icon: <AssessmentOutlinedIcon color="#57c787" height="22px" />,
    iconText: 'Live',
    description: LEGEND_ICON_DESCRIPTION.live.join('')
  },
  daily: {
    icon: <MdUpdate className={cn(s.legendIconColor, s.legendIcon)} />,
    iconText: '24hr',
    description: LEGEND_ICON_DESCRIPTION.daily.join('')
  }
}

const Legend = ({ keys }) => {
  return keys.map(key => (
    <div className={s.legendItem} key={key}>
      {reportIconConfig[key].icon}
      <div className={s.legendIconColor}>{reportIconConfig[key].iconText}</div>
      <div className={s.legendDescription}>{reportIconConfig[key].description}</div>
    </div>
  ))
}

export default Legend
