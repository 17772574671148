import { ModalContainer } from 'simple-core-ui'
import s from './WarningModal.scss'

const WarningModal = ({
  isWarningModalVisible,
  setIsWarningModalVisible,
  mattersLength,
  setMatters,
  templatesLength,
  setTemplates,
  matterGroupManagement,
  legalEntityManagement
}) => {
  const getEntitiesLabel = () => {
    let label = ''
    if (mattersLength) {
      label += 'matters '
      if (templatesLength) {
        label += 'and '
      }
    }
    if (templatesLength) {
      label += 'templates '
    }
    return label
  }
  return (
    <ModalContainer
      title="Changes could not be saved"
      size="md"
      confirmText="OK"
      confirmCb={() => {
        setIsWarningModalVisible(false)
        setMatters([])
        setTemplates([])
      }}
      content={
        <div className={s.modalBody}>
          <p className={s.description}>
            The {matterGroupManagement[0]} or {legalEntityManagement[0]} value(s) selected are not
            compatible with one or more {getEntitiesLabel()} where the custom attribute value is
            currently in use.
          </p>
        </div>
      }
    />
  )
}

export default WarningModal
