import * as ACT from 'matters/templates/actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

const updateAllocationOptions = (state, options, allocationIndex, columnIndex) => {
  Object.assign(state.allocations[allocationIndex].columns[columnIndex], {
    options,
    value: { value: -1, label: 'None' }
  })
}

const initialState = {
  attributes: [],
  allocations: []
}

const templateAllocationsReducer = createReducer(initialState, {
  [ACT.MATTER_TEMPLATE_ALLOCATIONS_RESET_STATE](state, action) {
    return {
      ...initialState
    }
  },

  [ACT.MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { groups, allocationIndex } = action.payload
    const attributes = get(groups, '[0].attributes', [])
    let newState = cloneDeep(state)

    if (groups[0] && attributes.length) {
      // for each attribute go through the allocation columns and update the
      // required attribute with the correct options)
      attributes.forEach(attribute => {
        state.allocations[allocationIndex].columns.forEach((column, index) => {
          if (column.id === attribute.id) {
            updateAllocationOptions(newState, attribute.options, allocationIndex, index)
          }
        })
      })

      return newState || state
    }

    return state
  },

  [ACT.MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { attributes } = action.payload

    if (state.attributes.length) return state

    const allocation = {
      columns: [
        {
          id: 'percentage',
          value: ''
        }
      ]
    }
    attributes.forEach(attribute => {
      let id
      switch (attribute.allocation_type) {
        case 'legal_entities':
          id = 'legal_entity'
          break
        case 'cost_codes':
          id = 'cost_code'
          break
        default:
          id = attribute.id
      }

      allocation.columns.push({
        id,
        value: ''
      })
    })

    let newState = update(state, {
      attributes: { $set: attributes }
    })

    if (!state.allocations.length) {
      newState = update(newState, {
        allocations: {
          $push: [allocation]
        }
      })
    }

    return newState
  },

  [ACT.MATTER_TEMPLATE_ADD_EMPTY_ALLOCATION](state, action) {
    const { allocation } = action.payload

    return update(state, {
      allocations: {
        $push: [allocation]
      }
    })
  },

  [ACT.MATTER_TEMPLATE_REMOVE_ALLOCATION](state, action) {
    const { index } = action.payload

    return update(state, {
      allocations: { $splice: [[index, 1]] }
    })
  },

  [ACT.MATTER_TEMPLATE_CHANGE_ALLOCATION](state, action) {
    const { allocationIndex, attributeIndex, value } = action.payload
    return update(state, {
      allocations: {
        [allocationIndex]: {
          columns: {
            [attributeIndex]: {
              value: { $set: value }
            }
          }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATE_ALLOCATIONS_FETCH_SUCCESS](state, action) {
    let { allocations } = action.payload

    if (!allocations.length) return initialState

    allocations.forEach(allocation => {
      allocation = allocation.columns.sort((a, b) => {
        return a.sort_order - b.sort_order
      })
    })

    return update(state, {
      allocations: { $set: allocations }
    })
  }
})

export default templateAllocationsReducer
