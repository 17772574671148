import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'rules/actions'

import Operand from '../components/Operand'

@connect(({ rules }, ownProps) => {
  const { ruleIndex, coords, refs } = ownProps
  const operand = refs[coords].operand
  const { engine } = rules

  return {
    ruleIndex,
    operand,
    availableFields: rules[engine].availableFields
  }
})
class OperandContainer extends Component {
  state = {
    isOver: false
  }

  remove = () => {
    const { refs, ruleIndex, coords } = this.props
    if (coords === 'op_0' && refs[coords].condition.operands.length === 1) {
      this.props.dispatch({
        type: ACT.CLEAR_RULE_CONDITION,
        payload: {
          ruleIndex
        }
      })
    } else if (refs[coords].condition.operands.length === 1) {
      this.props.dispatch({
        type: ACT.REMOVE_RULE_CONDITION,
        payload: {
          ruleIndex,
          refs,
          coord: coords
        }
      })
    } else {
      this.props.dispatch({
        type: ACT.REMOVE_RULE_OPERAND,
        payload: {
          ruleIndex,
          refs,
          coord: coords
        }
      })
    }
  }

  add = booleanOperator => {
    const { refs, ruleIndex, coords } = this.props
    this.props.dispatch({
      type: ACT.ADD_AND_OPERAND,
      payload: {
        ruleIndex,
        booleanOperator,
        refs,
        coord: coords
      }
    })
  }

  setLhs = lhs => {
    const { index, ruleIndex, refs, coords } = this.props

    this.props.dispatch({
      type: ACT.CLEAR_OPERAND_OPERATOR,
      payload: {
        operandIndex: index,
        ruleIndex,
        coords,
        refs
      }
    })

    this.props.dispatch({
      type: ACT.UPDATE_RULE_OPERAND,
      payload: {
        ruleIndex,
        coords,
        refs,
        key: 'lhs',
        value: lhs
      }
    })
  }

  setRhs = rhs => {
    const { index, ruleIndex, refs, coords } = this.props

    this.props.dispatch({
      type: ACT.UPDATE_RULE_OPERAND,
      payload: {
        operandIndex: index,
        ruleIndex,
        key: 'rhs',
        coords,
        refs,
        value: rhs
      }
    })
  }

  render() {
    const { index, operand, ruleIndex, refs, coords, availableFields } = this.props
    const { isOver } = this.state

    return (
      <Operand
        onHover={this.onHover}
        isOver={isOver}
        index={index}
        operand={operand}
        refs={refs}
        coords={coords}
        ruleIndex={ruleIndex}
        remove={this.remove}
        add={this.add}
        availableFields={availableFields}
        setLhs={this.setLhs}
        setRhs={this.setRhs}
      />
    )
  }
}

export default OperandContainer
