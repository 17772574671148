export const getCreateEventUrl = matterId => {
  return `/manage/matters/${matterId}/events/create`
}

export const getFetchEventsUrl = matterId =>
  matterId ? `/manage/matters/${matterId}/events` : '/events/'

export const getUpdateEventUrl = (matterId, eventId) => {
  return `/manage/matters/${matterId}/events/update/${eventId}`
}

export const getDeleteEventUrl = (matterId, eventId) => {
  return `/manage/matters/${matterId}/events/delete/${eventId}`
}

export const getFetchCalendarListUrl = matterId => {
  return `/manage/matters/calendar/${matterId}/`
}

export const getCreateCalendarListEventUrl = matterId => {
  return `/manage/matters/${matterId}/calendar_entry/`
}
