import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Event } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  event: Event
  canEdit: boolean
  canCancel: boolean
  canDuplicate: boolean
  editEvent: (row: Event) => void
  cancelEvent: (row: Event) => void
  duplicateEvent: (row: Event) => void
}

const ActionsPopover = ({
  event,
  canEdit,
  canCancel,
  canDuplicate,
  cancelEvent,
  editEvent,
  duplicateEvent
}: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      visible: canEdit,
      onClick() {
        editEvent(event)
      }
    },
    {
      name: 'COPY',
      text: 'Copy',
      visible: canDuplicate,
      onClick() {
        duplicateEvent(event)
      }
    },
    {
      name: 'CANCEL',
      text: 'Cancel',
      visible: canCancel,
      onClick() {
        cancelEvent(event)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${event.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
