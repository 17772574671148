export const UPDATE_TABLE_PARAMS = 'UPDATE_TABLE_PARAMS'
export const DELETE_MATTER_ATTACHMENT = 'DELETE_MATTER_ATTACHMENT'
export const ADD_MATTER_ATTACHMENTS = 'ADD_MATTER_ATTACHMENTS'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const ADD_FILES = 'ADD_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const CHANGE_FILE_NAME = 'CHANGE_FILE_NAME'
export const CLEAR_FILES = 'CLEAR_FILES'
export const RENAME_FOLDER = 'RENAME_FOLDER'
export const CLEAR_FOLDER_NAME = 'CLEAR_FOLDER_NAME'
export const ADD_FOLDER = 'ADD_FOLDER'
export const FETCH_MATTER_FOLDER_ITEMS = 'FETCH_MATTER_FOLDER_ITEMS'
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const ADD_LABELS = 'ADD_LABELS'
export const SELECT_FOLDER = 'SELECT_FOLDER'
export const FETCH_FOLDERS_TREE_REQUESTED = 'FETCH_FOLDERS_TREE_REQUESTED'
export const FETCH_FOLDERS_TREE_SUCCESS = 'FETCH_FOLDERS_TREE_SUCCESS'
export const SELECT_FILE = 'SELECT_FILE'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const EDIT_LABELS = 'EDIT_LABELS'
export const EDIT_SHARED_WITH_USERS = 'EDIT_SHARED_WITH_USERS'
export const CHANGE_LABELS_FOR_SELECTED_FILE = 'CHANGE_LABELS_FOR_SELECTED_FILE'
export const CHANGE_CATEGORY_FOR_SELECTED_FILE = 'CHANGE_CATEGORY_FOR_SELECTED_FILE'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_LABELS = 'FETCH_LABELS'
export const DELETE_MATTER_FOLDER = 'DELETE_MATTER_FOLDER'
export const CLEAR_FILE_NAME = 'CLEAR_FILE_NAME'
export const RENAME_FILE = 'RENAME_FILE'
export const CLEAR_VERSION_FILE = 'CLEAR_VERSION_FILE'
export const SELECT_VERSION_FILE = 'SELECT_VERSION_FILE'
export const REPLACE_MATTER_ATTACHMENT = 'REPLACE_MATTER_ATTACHMENT'
export const CLEAR_DUPLICATED_FILES = 'CLEAR_DUPLICATED_FILES'
export const ADD_DUPLICATED_FILES = 'ADD_DUPLICATED_FILES'
export const ADD_FILES_TO_UPLOAD = 'ADD_FILES_TO_UPLOAD'
export const CLEAR_FILES_TO_UPLOAD = 'CLEAR_FILES_TO_UPLOAD'
export const CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD'
export const ADD_EMAIL = 'ADD_EMAIL'
export const CLEAR_EMAIL = 'CLEAR_EMAIL'
export const CLEAR_OTHER_DIR_DUP_FILES = 'CLEAR_OTHER_DIR_DUP_FILES'
export const ADD_OTHER_DIR_DUP_FILES = 'ADD_OTHER_DIR_DUP_FILES'
