import { Component } from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from './actions'
import { RequestsList } from './RequestsList'
import { RequestStatusTooltip } from '../common/RequestStatusTooltip'
import { MatterStatusTooltip } from '../common/MatterStatusTooltip'

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  { columnKey: 'createdDate', content: 'Date Received', isSortable: true, isFilterable: false },
  { columnKey: 'reviewed_by_user', content: 'Reviewed by', isSortable: true, isFilterable: true },
  {
    columnKey: 'status',
    content: (
      <span>
        Legal Request Status <RequestStatusTooltip />
      </span>
    ),
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'matterStatus',
    content: (
      <span>
        Matter Status <MatterStatusTooltip />
      </span>
    ),
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'clientMatterId',
    content: 'Client Matter ID',
    isSortable: true,
    isFilterable: false
  },
  {
    columnKey: 'lastStatusUpdated',
    content: 'Status Last Updated',
    isSortable: true,
    isFilterable: false
  }
]

@connect(({ requestsList, requestFormsList }) => {
  const { requests } = requestsList
  const { requestForms } = requestFormsList
  return {
    requestsAvailable: requests.length || requestForms.length
  }
})
@withFetchFlow({
  flag: 'RequestList',
  getFetchAction: props => ({
    type: ACT.LEGAL_REQUEST_LIST_FETCH_REQUESTED,
    payload: {
      showMyRequests: true
    }
  })
})
class RequestsListContainer extends Component {
  render() {
    return <RequestsList requestsAvailable={this.props.requestsAvailable} columns={columns} />
  }
}

export default RequestsListContainer
