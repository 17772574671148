import { RadioFilter } from 'common/Filters'
import { Input, Textarea } from 'simple-core-ui'
import st from './Filters.scss'
import { nullOptions, naOptions } from 'bulk/helpers'
import variables from 'styles/_variables.scss'

const TextareaFilter = ({
  reset,
  getFilterLabel,
  curField,
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  hasConfirmation,
  optWrapperStyle,
  width,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const basicFilterOptions = ['is null', 'is not null', 'is n/a', 'is not n/a']
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      width={500}
      title={filter.display_name}
      initialValue={curField ? { value: curField?.field } : undefined}
      customLabelFn={s =>
        getFilterLabel(curField?.filterOpLabel, filter.display_name, curField?.val1)
      }
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt)
      }}
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onClear={() => onClear(filter.field)}
      onCancelCb={() => onCancel(filter.field)}
      hasConfirmation={hasConfirmation}
      isDisabled={
        curInputSelection?.op === 'contains'
          ? !curInputSelection.value1
          : ![curInputSelection?.op, ...basicFilterOptions].includes(curSelection)
      }
      options={[
        {
          value: 'contains',
          renderLabel: props => (
            <div style={optWrapperStyle}>
              <p style={width}>contains</p>
              {filter.type === 'long_text' || filter.type === 'textarea' ? (
                <Textarea
                  rows={3}
                  onChange={(event, action) =>
                    onFilterInputChange(filter.field, 'contains', event, undefined, action)
                  }
                  value={
                    curSelection === 'contains' && curInputSelection?.op === 'contains'
                      ? getInputValue(filter.field, 'contains')
                      : ''
                  }
                  styles={{
                    width: '350px',
                    marginLeft: '2rem',
                    ...(curSelection === 'contains' ? { borderColor: variables.bulkBlack } : {})
                  }}
                  isDisabled={curSelection !== 'contains'}
                  placeholder={filter.display_name}
                  label="contains"
                  {...props}
                />
              ) : (
                <div style={{ width: '100%' }}>
                  <Input
                    style={curSelection === 'contains' ? { borderColor: variables.bulkBlack } : {}}
                    className={st.textInput}
                    text={
                      curSelection === 'contains' && curInputSelection?.op === 'contains'
                        ? getInputValue(filter.field, 'contains')
                        : ''
                    }
                    onChangeCb={event => {
                      onFilterInputChange(filter.field, 'contains', event.target.value)
                    }}
                    isDisabled={curSelection !== 'contains'}
                  />
                </div>
              )}
            </div>
          )
        },
        ...nullOptions,
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default TextareaFilter
