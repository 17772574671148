import cloneDeep from 'lodash/cloneDeep'
import { AvailableField, FuncOperand } from 'simple_review/@types/api'

export function RuleOperandLhsFuncSerializer() {
  return {
    fromJSON(lhs: FuncOperand, fields: Array<AvailableField>): AvailableField | null {
      let field: AvailableField | null = null

      const fieldIndex = fields.findIndex(field => field.name === lhs.func_name)

      if (fieldIndex < 0) return field

      field = cloneDeep(fields[fieldIndex])
      field.isCustomAttrEnabled = true
      field.base_args = field.args
      field.args = []

      let i = 0
      for (const arg of lhs.args) {
        const baseArg = field.base_args?.find(x => x.arg_name === arg.arg_name)

        if (!baseArg) continue

        const obj = {
          ...baseArg
        }

        if (arg.arg_type === 'field') {
          let label = `${arg.model_name} ∙ ${arg.model_field}`
          if (arg.model_field === 'id') {
            if (arg.arg_name === 'matter') {
              label = 'All matters by ID'
            } else if (arg.arg_name === 'vendor') {
              label = 'All Vendors'
            }
          }

          obj.constant = {
            ...arg,
            value: arg.model_field,
            label
          }
        } else {
          obj.constant = arg.constant
        }

        field.args[i++] = obj
      }

      return field
    }
  }
}
