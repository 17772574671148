import { Component, Fragment } from 'react'
import { CollapsibleContainer, Popable, ConditionalRender, If } from 'simple-core-ui'

import s from './RequestInformation.scss'

import { MODULE } from 'utils/constants'
import {
  SELECT_TEMPLATE_FLOW,
  LIST_LENGTH_UNTIL_PAGINATION_NOT_REQUIRED,
  OTHER_ATTRIBUTE_VALUE_LIST,
  MAX_DROPDOWN_HEIGHT
} from 'matters/templates/constants'
import ModuleContext from 'context/ModuleContext'
import { EditableField, Files } from 'components'
import { CheckboxContainer } from 'containers'
import { ConfidentialMatterToggle } from './ConfidentialMatterToggle'
import { CustomAttributesSection } from './CustomAttributesSection'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import {
  getTemplateAttributesMap,
  isDynamicDropdownDisabled,
  isRequired
} from 'matters/templates/utils'
import { toReactSelect } from 'matters/templates/serializers'

class RequestInformation extends Component {
  render() {
    const {
      userFlowState,
      files,
      changeAttributeValue,
      title,
      templateActions,
      noOfPreferred,
      isConfidential,
      toggleIsConfidential,
      selectedTemplate
    } = this.props
    let { attributes } = this.props
    let customAttributes = []
    let hasCustomAttributes = false
    let hasAttributes = false
    const textInputDebounce = 900
    const isSimpleMatterCreationOrChangeMatterTemplate = [
      SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION,
      SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
    ].includes(userFlowState.userFlow)

    if (isSimpleMatterCreationOrChangeMatterTemplate) {
      customAttributes = attributes.map(attr => {
        if (typeof attr.required !== 'undefined' && !attr.required) {
          return attr
        }
      })
      attributes = attributes.map(attr => {
        if (typeof attr.required === 'undefined' || attr.required) {
          return attr
        }
      })
      hasCustomAttributes = !!customAttributes.filter(attr => typeof attr !== 'undefined').length
      hasAttributes = !!attributes.filter(attr => typeof attr !== 'undefined').length
    }

    const templateAttributesMap = getTemplateAttributesMap(selectedTemplate)

    const isMatterRequest = userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST

    return (
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <ConditionalRender
          conditions={[
            {
              condition:
                userFlowState.userFlow ===
                SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION,
              content: (
                <p>
                  {noOfPreferred > 1
                    ? `The selected templates (${noOfPreferred})`
                    : noOfPreferred === 1
                    ? 'The selected template'
                    : 'All templates'}{' '}
                  will be available for the Legal Request Form.
                </p>
              )
            },
            {
              condition: userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST,
              content: (
                <Fragment>
                  <p>Edit input fields to see preview in selected template.</p>
                  <p className={s.secondaryDescription}>
                    Additionally, you can comment on the Legal Request below.
                  </p>
                </Fragment>
              )
            }
          ]}
          fallback={<p>Edit input fields to see preview in selected template.</p>}
        />
        {isMatterRequest ? null : templateActions}
        <hr className={s.separator} />
        {userFlowState.userFlow !== SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION && (
          <Fragment>
            <div className={s.attributes}>
              {attributes.map((attribute, index) => {
                if (typeof attribute === 'undefined') return ''
                if (attribute.type === 'relationship') {
                  return (
                    <div key={attribute.id} className={s.dynamicAttributeWrapper}>
                      <DynamicAttribute>
                        {attribute.attributes.map((attr, relIndex) => (
                          <div className={s.dynamicAttribute} key={attr.id}>
                            <span className={s.label}>{attr.label}</span>
                            <EditableField
                              type={attr.type}
                              disabled={isDynamicDropdownDisabled(attribute, relIndex)}
                              options={attr.options || []}
                              value={attr.value}
                              clearable={false}
                              {...(attr.type === 'text'
                                ? { debounceDelay: textInputDebounce }
                                : {})}
                              onChange={value =>
                                changeAttributeValue({
                                  index,
                                  value,
                                  inTemplate: true,
                                  attributeId: attr.id,
                                  relationshipIndex: relIndex,
                                  relationshipId: attribute.id
                                })
                              }
                            />
                          </div>
                        ))}
                      </DynamicAttribute>
                    </div>
                  )
                }
                return (
                  <div className={s.attribute} key={attribute.id}>
                    <span className={s.label}>
                      {attribute.label}
                      {isRequired(attribute, templateAttributesMap) && (
                        <span className={s.redAsterisk}>*</span>
                      )}
                    </span>
                    <EditableField
                      type={attribute.type}
                      url={
                        attribute.type === 'select' &&
                        `/manage/attributes/${attribute.id}/select2_json`
                      }
                      paginated={
                        attribute.class === OTHER_ATTRIBUTE_VALUE_LIST &&
                        attribute.type === 'select' &&
                        attribute.options?.length > LIST_LENGTH_UNTIL_PAGINATION_NOT_REQUIRED
                      }
                      serializer={toReactSelect}
                      options={attribute.options}
                      value={attribute.value}
                      disabled={attribute.readOnly}
                      {...(attribute.type === 'text' ? { debounceDelay: textInputDebounce } : {})}
                      onChange={value =>
                        changeAttributeValue({
                          index,
                          value,
                          inTemplate: !attribute.not_in_template,
                          attributeId: attribute.id
                        })
                      }
                      testid={`drawer_attribute_${attribute.label
                        .toLowerCase()
                        .split(' ')
                        .join('_')}`}
                      isPortal={false}
                      maxMenuHeight={MAX_DROPDOWN_HEIGHT}
                    />
                    {String(attribute.id).startsWith('customuser') && (
                      <div className={s.checkboxWrapper}>
                        <CheckboxContainer
                          defaultChecked={attribute.add_as_approver}
                          property="addAsApprover"
                          onChange={value =>
                            changeAttributeValue({
                              index,
                              value,
                              property: 'add_as_approver'
                            })
                          }
                        />
                        <span> Also add as approver</span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <Files files={files} />

            {isSimpleMatterCreationOrChangeMatterTemplate && hasCustomAttributes && (
              <CustomAttributesSection
                hasAttributes={hasAttributes}
                customAttributes={customAttributes}
                changeAttributeValue={changeAttributeValue}
              />
            )}
            <ModuleContext.Consumer>
              {modules => (
                <If condition={modules.includes(MODULE.CONFIDENTIAL_MATTERS)}>
                  <CollapsibleContainer title="Advanced">
                    <div className={s.confidential}>
                      <span className={s.info}>
                        Confidential?
                        <span className={s.infoIcon}>
                          <Popable
                            content={
                              <span className={s.infoTip}>
                                This matter will only be visible to you and users explicitly added.
                              </span>
                            }
                          >
                            <i className="simple-line-icon-info" />
                          </Popable>
                        </span>
                      </span>
                      <ConfidentialMatterToggle
                        checked={isConfidential}
                        onChange={toggleIsConfidential}
                      />
                    </div>
                  </CollapsibleContainer>
                </If>
              )}
            </ModuleContext.Consumer>
            {isMatterRequest ? templateActions : null}
          </Fragment>
        )}
      </div>
    )
  }
}

export default RequestInformation
