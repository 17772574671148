import { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import find from 'lodash/find'
import { ComboBoxContainer } from 'containers'
import { EditableField } from 'components'
import Select from 'react-select'
import Parameter from '../components/Parameter'
import { CustomValue } from 'rules/operand'
import { getLoadReferenceCb, getByName } from 'rules/utils'
import { PARAM_SUBTYPE, ENGINE_SCOPE_MAP } from 'rules/constants'
import ACT from 'rules/actions'
import { INPUT } from 'data/operators'
import s from 'rules/operand/styles/CustomValue.scss'

import { ScopedReviewerConfig } from 'reviews'

const getParameter = (actionType, options, parameter) => {
  const action = find(options, option => option.name === actionType) || {}
  const p = getByName(action.params, parameter.param_name || parameter.name)
  return p
}

@connect(({ rules }, ownProps) => {
  const { engine } = rules
  const { actionType, param } = ownProps
  const availableActions = rules[engine].availableActions
  let availableParam = getParameter(actionType, availableActions, param)

  const scope = ENGINE_SCOPE_MAP[engine]

  return {
    availableParam,
    availableActions,
    scope
  }
})
export default class ParameterContainer extends Component {
  state = {
    customComboValue: {}
  }

  customChangeHandler = value => {
    this.setState({
      customComboValue: value
    })
  }

  updateRuleActionParameters = (actionParameters, actionIndex, parameterIndex) => {
    this.props.dispatch({
      type: ACT.UPDATE_RULE_ACTION_PARAMETERS,
      payload: {
        ruleIndex: this.props.ruleIndex,
        actionParameters,
        actionIndex,
        parameterIndex
      }
    })
  }

  render() {
    const { param, paramIndex, availableParam, actionIndex, scope, canEdit } = this.props
    const { customComboValue } = this.state

    const selectionCb = value => {
      const type = availableParam.type === 'text' ? 'string' : availableParam.type
      this.updateRuleActionParameters(
        {
          value: { value, label: value },
          param_name: availableParam.name,
          type,
          sub_type: availableParam.sub_type
        },
        actionIndex,
        paramIndex
      )
    }

    const comboSelectionCb = v => {
      let type = v.type || 'string'

      this.setState({
        customComboValue: {}
      })

      this.updateRuleActionParameters(
        { ...v, param_name: availableParam.name, type },
        actionIndex,
        paramIndex
      )
    }
    return (
      <Parameter
        availableParam={availableParam}
        input={
          availableParam.sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG ? (
            <ScopedReviewerConfig
              scope={scope}
              reviewerConfigId={get(param, 'value.value')}
              readOnly={!canEdit}
            />
          ) : get(availableParam, 'choices.length') ? (
            <Select
              options={availableParam.choices}
              value={get(param, 'value')}
              className={s.selectContainer}
              onChange={obj => selectionCb(get(obj, 'value'))}
              isDisabled={!canEdit}
            />
          ) : availableParam.type === 'reference' ? (
            <ComboBoxContainer
              selectionCb={comboSelectionCb}
              options={[]}
              label={param.label}
              selected={get(param, 'value.value') && param}
              loadCb={getLoadReferenceCb(availableParam.choices)}
              custom={comboBoxSelectionCb => (
                <CustomValue
                  type={INPUT.STRING}
                  selected={customComboValue}
                  changeCb={this.customChangeHandler}
                  selectionCb={comboBoxSelectionCb}
                />
              )}
              disabled={!canEdit}
            />
          ) : (
            <EditableField
              type={
                availableParam.type === 'number'
                  ? 'number'
                  : ['comment', 'explanation'].some(keyword =>
                      get(availableParam, 'name', '')
                        .toLowerCase()
                        .includes(keyword)
                    )
                  ? 'textarea'
                  : 'text'
              }
              onChange={selectionCb}
              value={get(param, 'value.value')}
              disabled={!canEdit}
              key={paramIndex}
            />
          )
        }
        paramIndex={paramIndex}
      />
    )
  }
}
