import { Component } from 'react'
import { connect } from 'react-redux'
import * as ACT from '../actions'
import moment from 'moment'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import { TableContainer } from './Table'
import s from './MatterTemplatesListContainer.scss'
import { TEMPLATE_STATUSES } from '../constants'
import { IoIosArrowBack } from 'react-icons/io'

@connect(({ matterTemplatesList }) => {
  const { matterTemplates, maxTemplatesCount } = matterTemplatesList

  const templates = matterTemplates.map(template => {
    return {
      ...template,
      lastModified: moment(template.modified_date).format('MM/DD/YYYY'),
      createdBy: template.created_by_user,
      defaultDisplayColumn: 'name'
    }
  })

  const activeTemplatesNo = templates.filter(
    template => template.status.toLowerCase() === TEMPLATE_STATUSES.PUBLISHED
  ).length

  const disabled = activeTemplatesNo >= maxTemplatesCount

  return {
    matterTemplates: templates,
    disabled,
    activeTemplatesNo,
    maxTemplatesCount
  }
})
@withFetchFlow({
  flag: 'MatterTemplates',
  getFetchAction: props => ({
    type: ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED,
    payload: {}
  })
})
class MatterTemplatesListContainer extends Component {
  getTemplatesForDropdown = () =>
    this.props.matterTemplates.filter(
      template => template.status.toLowerCase() === TEMPLATE_STATUSES.PUBLISHED
    )

  render() {
    return (
      <div className={s.container}>
        <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
          <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
          Back to Administration
        </a>
        <TableContainer {...this.props} />
      </div>
    )
  }
}

export default MatterTemplatesListContainer
