import { Component, Fragment } from 'react'

import { ReviewLadder } from 'reviews'

const Wrapper = ({ children }) => (
  <section
    style={{
      width: '100%',
      minHeight: '869px',
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'space-around'
    }}
  >
    {children}
  </section>
)

class IsoContainer extends Component {
  render() {
    return (
      <Fragment>
        <Wrapper>
          <ReviewLadder scopeId={1} scope="invoice" />
        </Wrapper>
      </Fragment>
    )
  }
}

export default IsoContainer
