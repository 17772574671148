import { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { AxiosError } from 'axios'
import pluralize from 'pluralize'
import {
  format,
  getYear,
  isBefore,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  startOfWeek,
  subDays
} from 'date-fns'
import {
  Button,
  DataTableWrapper,
  AvatarList,
  ButtonDropdown,
  Ellipsis,
  useLoading,
  ModalContainer,
  LawRuleIcon,
  Tooltip,
  UpdateToPremiumLabel,
  AppleCalendarIcon,
  GoogleCalendarIcon,
  MicrosoftOfficeOutlookIcon
} from 'simple-core-ui'
import s from './Events.scss'
import { Tabs } from './Tabs'
import {
  canEditEvent,
  createQueryParamsUrl,
  createFiltersObjectFromQueryString,
  createCourtRuleFiltersObjectFromQueryString,
  updateUrlToUseUtcTime,
  isEventsCalendarSyncEnabled,
  getInitParams,
  canCancelEvent,
  buildEventsUrl
} from './utils'
import { ActionsPopover } from './ActionsPopover'
import { RulesActionsPopover } from './RulesActionsPopover'
import { Event, EventFilters, BulkEditValues, Cell, CourtRuleFilters, CourtRule } from './types'
import { useImmer, Updater } from 'use-immer'
import { toEvents, fromEvent, serializeBulkEditOptions, toCourtRules } from './serializers'
import {
  openLink,
  sortAlphabeticallyByProperty,
  removeLabelsFromURL,
  updateUrlFragment,
  isBasicTaskManagementPlan,
  isUserAdmin,
  deleteQueryParam,
  getUrlWithSubtab,
  timezoneUtils
} from 'utils/helpers'
import { BsPersonPlus } from 'react-icons/bs'
import { MdOutlineComment, MdOutlineAttachFile } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import APP_ACT from 'app/actions'
import cn from 'classnames'
import { FaList, FaCalendarAlt } from 'react-icons/fa'
import { AddEvent } from './AddEvent'
import { makeGetRequest, makeDeleteRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { ConfirmationDialog } from './ConfirmationDialog'
import { Filters } from './Filters'
import ReactTooltip from 'react-tooltip'
import { BulkEditModal } from './BulkEditModal'
import { BULK_EDIT_INITIAL_VALUES } from './constants'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import ThemeContext from 'context/ThemeContext'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { FaLocationDot } from 'react-icons/fa6'
import { CalendarView } from './CalendarView'
import { AddCourtRulesModal } from './AddCourtRulesModal'
import { PiGavelFill } from 'react-icons/pi'
import { Event as CalendarEvent } from './CalendarView/types'
import { toEvents as toCalendarEvents } from './CalendarView/serializers'
import { IoWarningOutline } from 'react-icons/io5'
import { ViewCourtRuleModal } from './ViewCourtRuleModal'
import { DeleteCourtRuleModal } from './DeleteCourtRuleModal'
import { EditCourtRuleModal } from './EditCourtRuleModal'
import { UpdateRulesModal } from './UpdateRulesModal'
import { CourtRuleFilters as CRFilters } from './CourtRuleFilters'

interface Props {
  scopeId?: string
  readOnly?: boolean
  scopeName?: string
  context: 'workbench' | 'matter'
  baseUrl: string
  restrictAllActions?: boolean
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const BULK_OPTIONS = [
  { label: 'Edit', value: 'edit' },
  { label: 'Cancel', value: 'cancel' }
]

const bulkEditInitialState = {
  isBulkEditDialog: false,
  options: BULK_EDIT_INITIAL_VALUES,
  isBulkEditConfirmationDialog: false
}

const updateUrlHash = (id: number | string) => {
  const parsedQuery = qs.parse(window.location.search)
  const parsedHash = qs.parse(window.location.hash, { decode: false })

  const mergedQuery = {
    ...parsedQuery,
    id
  }
  const queryString = qs.stringify(mergedQuery)
  const hashString = qs.stringify(parsedHash, { encode: false })

  window.history.replaceState(null, '', `?${queryString}${hashString ? `#${hashString}` : ''}`)
}

const Events = ({
  scopeId = '',
  readOnly = false,
  scopeName = '',
  context,
  baseUrl,
  restrictAllActions
}: Props) => {
  const initialEventFilters = {
    eventType: null,
    attendees: null,
    id: null,
    createdBy: null,
    comments: null,
    fileAttachments: null,
    date: null,
    createdDate: null,
    eventId: null,
    time: null,
    courtRulesName: null,
    ...(context === 'workbench' ? { relatedMatter: null } : {})
  }

  const initialCourtRuleEventFilters = {
    courtRulesName: null,
    jurisdiction: null,
    triggerEvent: null,
    triggerDate: null,
    needsReview: null
  }

  const [view, setViewState] = useState('list')
  const [localState, setLocalState] = useState(getInitParams(context, window.location.hash, view))
  const { params } = localState
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [events, setEvents]: [Event[], Updater<Event[]>] = useImmer<Event[]>([])
  const [courtRules, setCourtRules]: [CourtRule[], Updater<CourtRule[]>] = useImmer<CourtRule[]>([])
  const dispatch = useDispatch()
  const [isAddEventVisible, setIsAddEventVisible] = useState(false)
  const [editedEvent, setEditedEvent] = useState<Event | null>(null)
  const [oldEvent, setOldEvent] = useState<Event | null>(null)
  const [isLoading, withLoadingLocksGetEvents] = useLoading()
  const [, withLoadingLocks] = useLoading()
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
  const [showBulkCancelConfirmation, setShowBulkCancelConfirmation] = useState(false)
  const [showBulkCancelNotAllowed, setShowBulkCancelNotAllowed] = useState(false)
  const [eventFilters, setEventFilters] = useState<EventFilters>(
    createFiltersObjectFromQueryString(window.location.href, context)
  )
  const [oldEventFilters, setOldEventFilters] = useState<EventFilters>(eventFilters)
  const [courtRuleFilters, setCourtRuleFilters] = useState<CourtRuleFilters>(
    createCourtRuleFiltersObjectFromQueryString(window.location.href)
  )
  const [oldCourtRuleFilters, setOldCourtRuleFilters] = useState<CourtRuleFilters>(courtRuleFilters)
  const [bulkEdit, setBulkEdit] = useState(bulkEditInitialState)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [_scopeId, setScopeId] = useState(scopeId)
  const { state } = useContext(ThemeContext)
  const [totalEntries, setTotalEntries] = useState(0)
  const [filteredTotal, setFilteredTotal] = useState(0)
  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0)
  const [needsReviewCount, setNeedsReviewCount] = useState(0)
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [detailsSubtab, setDetailsSubtab] = useState('')

  const [hasCalendarRules, setHasCalendarRules] = useState(null)
  const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<number | null>(null)
  const [isAddCourtRulesModalVisible, setAddCourtRulesModalVisible] = useState(false)
  const isBasicPlan = isBasicTaskManagementPlan()

  // calendar
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([])
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [currentCalendarView, setCurrentCalendarView] = useState('month')

  //court rules
  const [isViewCourtRuleModalVisible, setViewCourtRuleModalVisible] = useState(false)
  const [isDeleteCourtRuleModalVisible, setDeleteCourtRuleModalVisible] = useState(false)
  const [isEditCourtRuleModalVisible, setEditCourtRuleModalVisible] = useState(false)
  const [isUpdateRulesModalVisible, setUpdateRulesModalVisible] = useState(false)
  const [selectedCourtRuleId, setSelectedCourtRuleId] = useState<number | null>(null)

  const clearAllSelectedRows: () => void = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const fetchCalendarRulesConfig = async () => {
    try {
      const { configured, jurisdiction_system_id } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setHasCalendarRules(configured)
      setSelectedJurisdictionId(jurisdiction_system_id)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const editEvent = async (event: Event) => {
    try {
      if (event.id) {
        const tsk = await withLoadingLocks(makeGetRequest(`${baseUrl}/events/${event.id}/`))
        setIsAddEventVisible(true)
        setEditedEvent(toEvents([tsk])[0])
        setOldEvent(toEvents([tsk])[0])
        updateUrlHash(event.id)
      } else {
        setIsAddEventVisible(true)
        setEditedEvent(event as Event)
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'The event no longer exists',
            message: `We couldn't find the event you referenced. It may have been canceled.`,
            level: 'error'
          }
        })
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
    clearAllSelectedRows()
  }

  const openDetailsSubtab = (id: number, subtab: string) => {
    setDetailsSubtab(subtab)
    editEvent({ id } as Event)
  }

  const courtRulesColumns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Court Rule Name',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: CourtRule) => {
          return (
            <>
              <div style={{ float: 'left' }}>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (readOnly) return
                    if (!row.needsReview) {
                      setViewCourtRuleModalVisible(true)
                    } else {
                      setUpdateRulesModalVisible(true)
                    }
                    setSelectedCourtRuleId(row.id)
                  }}
                >
                  <Ellipsis className={s.nameCell}>
                    {typeof cell.content === 'string' && cell.content}
                  </Ellipsis>
                </a>
              </div>
            </>
          )
        }
      },
      {
        columnKey: 'needsReview',
        content: 'Review Required',
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: CourtRule) => {
          return row.needsReview ? (
            <IoWarningOutline style={{ fontSize: 22, color: '#bb342f' }} />
          ) : (
            ''
          )
        }
      },
      {
        columnKey: 'jurisdiction',
        content: 'Jurisdiction',
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: CourtRule) => {
          return row.jurisdiction ? row.jurisdiction.label : '--'
        }
      },
      {
        columnKey: 'triggerEvent',
        content: 'Trigger Event',
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: CourtRule) => {
          return row.triggerEvent ? row.triggerEvent.label : '--'
        }
      },
      {
        columnKey: 'triggerDate',
        content: 'Trigger Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell) => {
          if (cell.content === '----') {
            return '--'
          }
          return (
            <span style={isBefore(cell.content as Date, new Date()) ? { color: '#bb342f' } : {}}>
              {!(cell.content instanceof Date)
                ? '--'
                : format(
                    cell.content,
                    getYear(cell.content) === getYear(new Date()) ? 'MMM dd' : 'MMM dd yyyy'
                  )}
            </span>
          )
        }
      },
      {
        columnKey: 'eventsCount',
        content: 'Events from Rule',
        isSortable: false,
        isFilterable: false
      }
    ]
  }, [readOnly])

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Event Name',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: Event) => {
          return (
            <>
              <div style={{ float: 'left' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => row && editEvent(row)}>
                  <Ellipsis className={s.nameCell}>
                    {typeof cell.content === 'string' && cell.content}
                  </Ellipsis>
                </a>
              </div>
            </>
          )
        }
      },
      ...(hasCalendarRules
        ? [
            {
              columnKey: 'isExternal',
              content: 'Source',
              isSortable: true,
              isFilterable: false,
              render: (_cell: Cell, row: Event) => {
                return row.isExternal ? (
                  <span className={s.calendarRulesIcon}>
                    <Tooltip
                      trigger={<PiGavelFill />}
                      content={row.isExternal ? 'CalendarRules' : row.createdBy?.label}
                      placement="top"
                    />
                  </span>
                ) : (
                  '--'
                )
              }
            }
          ]
        : []),
      {
        columnKey: 'eventId',
        content: 'Event ID',
        isSortable: true,
        isFilterable: true
      },
      ...(context === 'workbench'
        ? [
            {
              columnKey: 'relatedMatter',
              content: 'Related To',
              isSortable: true,
              isFilterable: true,
              filterableBy: 'label',
              render: (cell: Cell) => {
                if (typeof cell.content === 'object' && 'label' in cell.content) {
                  return (
                    <Link to={`/v2/matters/${cell.content.value}?fromEventsWorkbench=true`}>
                      <Ellipsis width={120} lines={1}>
                        {cell.content.label}
                      </Ellipsis>
                    </Link>
                  )
                }
                return '--'
              }
            }
          ]
        : []),
      {
        columnKey: 'attendees',
        content: 'Attendees',
        isSortable: false,
        isFilterable: true,
        filterableBy: 'label',
        render: (cell: Cell, row: Event) => {
          return Array.isArray(cell.content) && !cell.content.length ? (
            !readOnly && row && canEditEvent(row, context) ? (
              <span onClick={() => editEvent(row)} className={s.noAssignee}>
                <BsPersonPlus />
              </span>
            ) : (
              '--'
            )
          ) : (
            <AvatarList
              size={Array.isArray(cell.content) && cell.content.length > 1 ? 'sm' : 'md'}
              limit={2}
              avatarStyles={{ marginLeft: 0 }}
              entries={sortAlphabeticallyByProperty(
                Array.isArray(cell.content) ? cell.content : [],
                'label'
              )}
            />
          )
        }
      },
      {
        columnKey: 'startDate',
        content: 'Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell) => {
          if (cell.content === '----') {
            return '--'
          }
          return (
            <span style={isBefore(cell.content as Date, new Date()) ? { color: '#bb342f' } : {}}>
              {!(cell.content instanceof Date)
                ? '--'
                : format(
                    cell.content,
                    getYear(cell.content) === getYear(new Date()) ? 'MMM dd' : 'MMM dd yyyy'
                  )}
            </span>
          )
        }
      },
      {
        columnKey: 'endDate',
        content: 'Time',
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          return (
            <span>
              {cell.content === '----' ||
              !(row.startDate instanceof Date) ||
              !(row.endDate instanceof Date)
                ? '--'
                : `${format(row.startDate, 'h:mm a')} - ${format(row.endDate, 'h:mm a')}`}
            </span>
          )
        }
      },
      {
        columnKey: 'timezone',
        content: 'Time Zone',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'eventType',
        content: 'Event Type',
        isSortable: true,
        isFilterable: true,
        filterableBy: 'name',
        render: (cell: Cell) => {
          return cell.content === '----' ? (
            '--'
          ) : (
            <Ellipsis width={150} lines={1}>
              {typeof cell.content === 'object' && 'name' in cell.content && cell.content.name}
            </Ellipsis>
          )
        }
      },
      {
        columnKey: 'location',
        content: <FaLocationDot style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content

          let location: JSX.Element | string = ''

          if (typeof content === 'object' && 'address' in content) {
            location = content.address

            if (
              'name' in content &&
              content.name.trim() !== content.address.trim() &&
              !content.address.includes(content.name.trim())
            ) {
              location = (
                <>
                  <b>{content.name}</b> {content.address}
                </>
              )
            }
          }

          return (
            <div onClick={() => openDetailsSubtab(row.id, 'location')}>
              <FaLocationDot data-for={`location-${row.id}`} data-tip className={s.tableIcons} />
              {content && content !== '----' ? (
                <>
                  <span className={s.linkColor}>1</span>
                  <ReactTooltip
                    id={`location-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {location}
                  </ReactTooltip>
                </>
              ) : (
                0
              )}
            </div>
          )
        }
      },
      {
        columnKey: 'commentsCount',
        content: <MdOutlineComment style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content as string
          return (
            <div onClick={() => openDetailsSubtab(row.id, 'comments')}>
              <MdOutlineComment className={s.tableIcons} data-for={`comments-${row.id}`} data-tip />
              {+content > 10 ? (
                <>
                  <span className={s.linkColor}>10+</span>
                  <ReactTooltip
                    id={`comments-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : content === '----' ? (
                <span className={s.linkColor}>0</span>
              ) : (
                <span className={s.linkColor}>{content}</span>
              )}
            </div>
          )
        }
      },
      {
        columnKey: 'fileAttachmentsCount',
        content: <MdOutlineAttachFile style={{ fontSize: 20, position: 'relative', top: 5 }} />,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: Event) => {
          const content = cell.content as string

          return (
            <div onClick={() => openDetailsSubtab(row.id, 'files')}>
              <MdOutlineAttachFile className={s.tableIcons} data-for={`files-${row.id}`} data-tip />
              {+content > 10 ? (
                <>
                  <span className={s.linkColor}>10+</span>
                  <ReactTooltip
                    id={`files-${row.id}`}
                    type="light"
                    effect="solid"
                    place="top"
                    border
                  >
                    {content}
                  </ReactTooltip>
                </>
              ) : content === '----' ? (
                <span className={s.linkColor}>0</span>
              ) : (
                <span className={s.linkColor}>{content}</span>
              )}
            </div>
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, events, hasCalendarRules])

  if (eventFilters !== oldEventFilters && view === 'list') {
    setOldEventFilters(eventFilters)
  }

  if (courtRuleFilters !== oldCourtRuleFilters && view === 'courtRules') {
    setOldCourtRuleFilters(courtRuleFilters)
  }

  const isFiltered = useMemo(() => {
    return Object.values(eventFilters).some(f => f !== null)
  }, [eventFilters])

  const isCourtRulesFiltered = useMemo(() => {
    return Object.values(courtRuleFilters).some(f => f !== null)
  }, [courtRuleFilters])

  const filteredEvents = useMemo(() => {
    return events.filter(t => !t.hidden)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, eventFilters])

  useEffect(() => {
    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: 1,
        category: selectedTab
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view === 'list' ? eventFilters : courtRuleFilters, selectedTab])

  useEffect(() => {
    clearAllSelectedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilters, selectedTab, localState.params.search])

  const updateUrl = (queryString: string) => {
    const frg = updateUrlFragment(queryString)

    window.history.replaceState(null, '', frg)
  }

  const getFilterEventsUrl = async (queryString: string): Promise<string> => {
    const { baseParams, filters, url } = buildEventsUrl(queryString, baseUrl)

    if (filters) {
      const { filter_id } = await makePostRequest('/event-management/events/filters', filters)
      return `${baseUrl}/events/?${qs.stringify({ ...baseParams, filter_id })}`
    }

    return url
  }

  const fetchEvents = async ({
    filters,
    tableParams,
    cb,
    hash,
    tab,
    view: _view
  }: {
    filters?: EventFilters | CourtRuleFilters
    tableParams?: Params
    cb?: (url: string) => void
    hash?: string
    tab?: string
    view?: string
  } = {}) => {
    const eventFiltersMap = {
      eventType: 'event_type',
      name: 'name',
      startDate: 'start_date',
      eventId: 'event_id',
      relatedMatter: 'matter_id',
      isExternal: 'is_external'
    }
    const courtRulesFiltersMap = {
      name: 'name',
      triggerDate: 'trigger_date'
    }
    try {
      const mapObject = (_view || view) === 'list' ? eventFiltersMap : courtRulesFiltersMap
      const base = `columnKey=${
        mapObject[(tableParams || params).ordering.columnKey as keyof typeof mapObject]
      }&isDesc=${Number((tableParams || params).ordering.isDesc)}&search=${encodeURIComponent(
        (tableParams || params).search
      )}&page_number=${(tableParams || params).page}&page_size=${(tableParams || params).pageSize}`

      const queryString = hash
        ? updateUrlToUseUtcTime(removeLabelsFromURL(hash.split('#')[1]))
        : `${base}&category=${tab ?? selectedTab}&${createQueryParamsUrl(
            filters || ((_view || view) === 'list' ? eventFilters : courtRuleFilters),
            false,
            _view || view
          )}`

      const {
        rows,
        totalEntries,
        currentPageNumber: responsePage
      } = await withLoadingLocksGetEvents(
        makeGetRequest(
          (_view || view) === 'list'
            ? await getFilterEventsUrl(queryString)
            : `/event-management/calendar_rules/court-rules/${scopeId}?${queryString}`
        )
      )

      // handling delete related operations when there is no events on the last page
      if (responsePage < params.page) {
        setLocalState(currentState => ({
          ...currentState,
          params: { ...currentState.params, page: responsePage }
        }))
        const urlParams = window.location.hash.split('#')[1]
        updateUrl(`${urlParams?.replace(/(page_number=)\d+/, `page_number=${responsePage}`)}`)
      }

      if ((_view || view) === 'list') {
        setEvents(
          toEvents(rows, (events: Event[]) => {
            if (context === 'workbench') {
              return events.map(t =>
                t?.relatedMatter?.status === 'closed' || !t?.relatedMatter?.canEdit
                  ? { ...t, canEdit: false }
                  : t
              )
            }
            return events
          })
        )
      } else {
        setCourtRules(toCourtRules(rows))
      }
      setFilteredTotal(totalEntries)

      cb?.(
        hash
          ? hash.split('#')[1]
          : `${base}&category=${tab ?? selectedTab}&${createQueryParamsUrl(
              filters || ((_view || view) === 'list' ? eventFilters : courtRuleFilters),
              true,
              _view || view
            )}`
      )
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onChangeTabCb = (selectedTab: string) => {
    setOldSelectedTab(selectedTab)

    if (selectedTab === oldSelectedTab) return

    let newFilters

    switch (selectedTab) {
      case 'all':
        newFilters = view === 'list' ? initialEventFilters : initialCourtRuleEventFilters
        break
      case 'upcoming':
        newFilters = {
          ...initialEventFilters,
          date: {
            operator: {
              label: 'Is after',
              value: 'IS_AFTER'
            },
            values: [
              {
                value: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
                label: 'Start Date'
              }
            ]
          }
        }
        break
      case 'review':
        newFilters = {
          ...initialCourtRuleEventFilters,
          needsReview: {
            operator: null,
            values: [
              {
                value: 'NEEDS_REVIEW',
                label: 'Needs Review'
              }
            ]
          }
        }
        break
      default:
        break
    }

    if (!newFilters) return

    fetchEvents({ filters: newFilters, cb: updateUrl, tab: selectedTab })

    if (view === 'list') {
      setEventFilters(newFilters as EventFilters)
    } else {
      setCourtRuleFilters(newFilters as CourtRuleFilters)
    }
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  const clearFilters = () => {
    const resetFilters = () => {
      if (view === 'list') {
        setEventFilters(initialEventFilters)
      } else {
        setCourtRuleFilters(initialCourtRuleEventFilters)
      }
      fetchEvents({
        filters: view === 'list' ? initialEventFilters : initialCourtRuleEventFilters,
        cb: updateUrl
      })
    }
    selectedTab !== 'all' ? changeTab('all') : resetFilters()
  }

  const fetchEventsCounts = async (_view?: string) => {
    try {
      if ((_view || view) === 'courtRules') {
        const { total, needs_review } = await withLoadingLocks(
          makeGetRequest(`/event-management/calendar_rules/court-rules/counts/${scopeId}`)
        )
        setTotalEntries(total)
        setNeedsReviewCount(needs_review)
        return
      } else {
        const { totalEntries, upcomingEventsCount } = await withLoadingLocks(
          makeGetRequest(`${baseUrl}/events/counts`)
        )
        setTotalEntries(totalEntries)
        setUpcomingEventsCount(upcomingEventsCount)
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const setView = (view: string) => {
    const param =
      view === 'courtRules'
        ? {
            page: 1,
            pageSize: 50,
            category: 'all',
            ordering: {
              columnKey: 'triggerDate',
              isDesc: false
            }
          }
        : {
            page: 1,
            pageSize: 10,
            category: 'upcoming',
            ordering: {
              columnKey: 'startDate',
              isDesc: false
            }
          }
    if (view !== 'calendar') {
      const tab = view === 'courtRules' ? 'all' : 'upcoming'
      fetchEvents({
        cb: updateUrl,
        view,
        tableParams: {
          ...params,
          ...param
        },
        tab
      })
      fetchEventsCounts(view)

      setSelectedTab(tab)
      oldSelectedTab !== tab && setOldSelectedTab(tab)

      setLocalState(state => ({
        ...state,
        params: {
          ...state.params,
          ...param
        }
      }))

      if (view === 'list') {
        setEventFilters({
          ...initialEventFilters,
          date: {
            operator: {
              label: 'Is after',
              value: 'IS_AFTER'
            },
            values: [
              {
                value: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
                label: 'Start Date'
              }
            ]
          }
        })
      }
    }
    setViewState(view)
  }

  useEffect(() => {
    const hash = window.location.hash
    const search = window.location.search
    const parsedQuery = qs.parse(search)

    if (parsedQuery.subtab && parsedQuery.subtab === 'calendar') return

    if (!hash) {
      const newFilters =
        parsedQuery.subtab === 'courtRules'
          ? createCourtRuleFiltersObjectFromQueryString(window.location.href)
          : createFiltersObjectFromQueryString(`${window.location.href}&category=upcoming`, context)

      fetchEvents({ cb: updateUrl, filters: newFilters, view: parsedQuery.subtab as string })
      if (parsedQuery.subtab === 'courtRules') {
        setCourtRuleFilters(newFilters as CourtRuleFilters)
      } else {
        setEventFilters(newFilters as EventFilters)
      }
    } else {
      fetchEvents({ cb: updateUrl, hash, view: parsedQuery.subtab as string })
    }
    setLocalState(getInitParams(context, hash, (parsedQuery.subtab as string) || view))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const parsedQuery = qs.parse(window.location.search)
    if (parsedQuery.tab !== 'events') return
    if (parsedQuery.subtab) {
      setViewState(parsedQuery.subtab as string)
    }
  }, [])

  useEffect(() => {
    if (view === 'calendar') return
    const search = window.location.search
    const parsedQuery = qs.parse(search)

    fetchEventsCounts((parsedQuery.subtab as string) || view)
    fetchCalendarRulesConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (view !== 'list') return
    const parsedQuery = qs.parse(window.location.search)

    if (parsedQuery.tab !== 'events') return

    if (parsedQuery.id) {
      editEvent({ id: +parsedQuery.id } as Event)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (view !== 'list') return
    const parsedQuery = qs.parse(window.location.search)

    if (parsedQuery.tab !== 'events') return

    if (parsedQuery.courtRuleId) {
      setViewCourtRuleModalVisible(true)
      setSelectedCourtRuleId(+parsedQuery.courtRuleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectRow = ({ id }: { id: number }) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(
      allRowsSelected ? [] : filteredEvents.filter(r => r.canEdit !== false).map(t => t.id)
    )
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    fetchEvents({
      filters: view === 'list' ? eventFilters : courtRuleFilters,
      tableParams: params,
      cb: updateUrl
    })
  }

  const downloadEventsXlsx = async () => {
    const { columnKey, isDesc } = params.ordering
    const mappedTab: Record<string, string> = {
      upcoming: 'upcoming'
    }

    clearAllSelectedRows()

    const url = `${baseUrl}/events/export/?columnKey=${columnKey}&isDesc=${+isDesc}${
      selectedTab !== 'all' ? `&category=${mappedTab[selectedTab]}` : ''
    }&${createQueryParamsUrl(eventFilters, false, view)}&search=${encodeURIComponent(
      params.search
    )}${context === 'workbench' ? '&template_id=is_not_set' : ''}`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(`${url}&timezone=${timezoneUtils.getUserTimezone()}`)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const downloadCourtRulesXlsx = async () => {
    const { columnKey, isDesc } = params.ordering
    const url = `/event-management/calendar_rules/court-rules/${scopeId}/export?columnKey=${columnKey}&isDesc=${+isDesc}&${createQueryParamsUrl(
      courtRuleFilters,
      false,
      view
    )}&search=${encodeURIComponent(params.search)}`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(`${url}&timezone=${timezoneUtils.getUserTimezone()}`)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const fetchCalendarEvents = async (date: Date, currentView: string) => {
    const DATE_FORMAT = 'yyyy-MM-dd'
    const startDate = startOfMonth(date)
    const endDate = endOfMonth(date)
    try {
      let endpoint = ''

      if (currentView === 'month') {
        endpoint = `${baseUrl}/events/?columnKey=start_date&isDesc=0&start_date=is_between:${format(
          startDate,
          DATE_FORMAT
        )},${format(endDate, DATE_FORMAT)}`
      } else if (currentView === 'week') {
        const firstDayOfWeek = startOfWeek(date)
        const lastDayOfWeek = endOfWeek(date)
        endpoint = `${baseUrl}/events/?columnKey=start_date&isDesc=0&start_date=is_between:${format(
          firstDayOfWeek,
          DATE_FORMAT
        )},${format(lastDayOfWeek, DATE_FORMAT)}`
      } else if (currentView === 'day') {
        endpoint = `${baseUrl}/events/?columnKey=start_date&isDesc=0&start_date=is:${format(
          date,
          DATE_FORMAT
        )}`
      }

      const { rows } = await withLoadingLocks(makeGetRequest(endpoint))

      setCalendarEvents(sortAlphabeticallyByProperty(toCalendarEvents(rows), 'name'))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const toggleAddEventSidebar = () => {
    setIsAddEventVisible(!isAddEventVisible)
    setEditedEvent(null)
    setDetailsSubtab('')
  }

  const refreshTable = (toggleSidebar: boolean = true) => {
    if (view === 'calendar') {
      fetchCalendarEvents(calendarDate, currentCalendarView)
      if (toggleSidebar) {
        toggleAddEventSidebar()
      }
    } else {
      fetchEvents()
      fetchEventsCounts()
    }
  }

  const showCancelModal = (event: Event) => {
    setShowCancelConfirmation(true)
    setEditedEvent(event)
  }

  const onCancelEvent = async () => {
    const eventIdToCancel = editedEvent?.id
    try {
      await makeDeleteRequest(`${baseUrl}/events/${eventIdToCancel}/`)

      clearAllSelectedRows()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Event successfully canceled.',
          level: 'success'
        }
      })
      refreshTable()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    setShowCancelConfirmation(false)
  }

  const duplicateEvent = async (event: Event) => {
    try {
      await withLoadingLocks(
        makePostRequest(
          `/event-management/matters/${_scopeId ||
            String(event?.relatedMatter?.value ?? '')}/events/clone/`,
          {
            object_id: event.id
          }
        )
      )

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Event ${event.name} successfully copied.`,
          level: 'success'
        }
      })

      refreshTable()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const renderCustomAction = useCallback(
    (row: Event) => {
      if (readOnly) {
        return <span className={s.actionsPlaceholder}>...</span>
      }
      return (
        <ActionsPopover
          event={row}
          canEdit={canEditEvent(row, context)}
          canCancel={canCancelEvent(row)}
          editEvent={() => editEvent(row)}
          canDuplicate={!row.isExternal}
          cancelEvent={showCancelModal}
          duplicateEvent={duplicateEvent}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly]
  )

  const toggleAddCourtRulesModal = () => {
    setAddCourtRulesModalVisible(!isAddCourtRulesModalVisible)
  }

  const toggleViewCourtRulesModal = () => {
    setViewCourtRuleModalVisible(!isViewCourtRuleModalVisible)
  }

  const toggleDeleteCourtRuleModal = () => {
    setDeleteCourtRuleModalVisible(!isDeleteCourtRuleModalVisible)
  }

  const toggleEditCourtRuleModal = () => {
    setEditCourtRuleModalVisible(isEditCourtRuleModalVisible => !isEditCourtRuleModalVisible)
  }

  const toggleUpdateRulesModal = () => {
    setUpdateRulesModalVisible(isUpdateRulesModalVisible => !isUpdateRulesModalVisible)
  }

  const renderCustomCourtRuleAction = useCallback(
    (row: CourtRule) => {
      if (readOnly) {
        return <span className={s.actionsPlaceholder}>...</span>
      }
      return (
        <RulesActionsPopover
          rule={row}
          editRule={row => {
            toggleEditCourtRuleModal()
            setSelectedCourtRuleId(row.id)
          }}
          deleteRule={row => {
            toggleDeleteCourtRuleModal()
            setSelectedCourtRuleId(row.id)
          }}
          viewRule={row => {
            toggleViewCourtRulesModal()
            setSelectedCourtRuleId(row.id)
          }}
          updateRule={row => {
            toggleUpdateRulesModal()
            setSelectedCourtRuleId(row.id)
          }}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly]
  )

  const saveEvent = async (
    event: Event,
    isEdit: boolean | undefined,
    callback?: (event?: Event) => void,
    shouldRefreshTable?: boolean
  ) => {
    try {
      if (event.id) {
        const index = events.findIndex(p => p.id === event.id)
        const scopeIdFallback = _scopeId || String(editedEvent?.relatedMatter?.value ?? '')

        const response = await withLoadingLocks(
          makePatchRequest(`/event-management/matters/${scopeIdFallback}/events/${event.id}/`, {
            ...fromEvent(event, scopeIdFallback, isEdit),
            is_manual_update: true
          })
        )
        const formattedResponse = toEvents([response])[0]
        setEvents(draft => {
          draft[index] = formattedResponse
        })

        callback && callback(formattedResponse)
        setOldEvent(formattedResponse)
        setEditedEvent(formattedResponse)

        if (shouldRefreshTable) {
          refreshTable()
        }
      } else {
        const newEvent = await withLoadingLocks(
          makePostRequest(
            `/event-management/matters/${_scopeId}/events/`,
            fromEvent(event, _scopeId)
          )
        )
        // fetch newly created event
        editEvent(toEvents([newEvent])[0])
        refreshTable()
      }

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Event ${event?.name ?? editedEvent?.name} successfully ${
            isEdit ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    clearAllSelectedRows()
  }

  const updateBulkEvents = async () => {
    try {
      await withLoadingLocks(
        makePatchRequest(
          `${baseUrl}/events/bulk-operations/`,
          serializeBulkEditOptions(bulkEdit.options, selectedRows)
        )
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('event', selectedRows.length, true)} successfully updated.`,
          level: 'success'
        }
      })
      refreshTable()
    } catch (err) {
      const error = err as AxiosError
      const customMessage = `Failed to bulk edit the ${pluralize(
        'event',
        selectedRows.length
      )}. Please try again later. If the issue persists, please contact support for further assistance.`
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    setBulkEdit(bulkEditInitialState)
    clearAllSelectedRows()
  }

  const cancelBulkEvents = async () => {
    setShowBulkCancelConfirmation(false)
    try {
      await withLoadingLocks(
        makeDeleteRequest(`${baseUrl}/events/bulk-operations/`, {
          data: { event_ids: selectedRows }
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `${pluralize('event', selectedRows.length, true)} successfully canceled.`,
          level: 'success'
        }
      })
      refreshTable()
    } catch (err) {
      const error = err as AxiosError
      const customMessage =
        'Failed to bulk cancel the events. Please try again later. If the issue persists, please contact support for further assistance.'
      dispatch({
        type: APP_ACT.API_ERROR,
        error: { ...error, response: { ...error.response, data: customMessage } }
      })
    }
    clearAllSelectedRows()
  }

  const handleBulkCancelAction = () => {
    const isBulkCancelNotAllowed = filteredEvents.some(
      event => selectedRows.includes(event.id) && !canCancelEvent(event)
    )
    isBulkCancelNotAllowed ? setShowBulkCancelNotAllowed(true) : setShowBulkCancelConfirmation(true)
  }

  const triggerBulkAction = (option: { value: string; label: string }) => {
    switch (option.value) {
      case 'edit':
        setBulkEdit(state => ({ ...state, isBulkEditDialog: true }))
        break
      case 'cancel':
        handleBulkCancelAction()
        break
      default:
        break
    }
  }

  const bulkActionsButton = (
    <ButtonDropdown
      displayText={`Bulk Actions (${selectedRows.length})`}
      options={BULK_OPTIONS}
      style={{ padding: '10px', borderRadius: '4px' }}
      listStyles={{ width: '100%', top: '42px' }}
      listItemStyles={{ fontSize: '14px' }}
      onSelect={triggerBulkAction}
    />
  )

  const cantBulkCancelConfirmationContent = () => {
    const cantCancelEventsList = filteredEvents
      .filter(event => selectedRows.includes(event.id) && !canCancelEvent(event))
      .map(event => event.eventId)
    const cantCancelEvents = cantCancelEventsList.join(', ')
    const events = pluralize('event', cantCancelEventsList.length)
    const are = pluralize('is', cantCancelEventsList.length)
    const these = pluralize('this', cantCancelEventsList.length)
    return (
      <>
        <div className={s.cantCancelReason}>
          {`The following ${events} ${are} private ${events} and cannot be canceled: ${cantCancelEvents}.`}
        </div>
        <div>{`Remove ${these} ${events} from your bulk selection to proceed.`}</div>
      </>
    )
  }

  const getPaginationTotalEntries = () => {
    switch (selectedTab) {
      case 'review':
        return needsReviewCount
      default:
        return filteredTotal
    }
  }

  const getCourtRulesConnectionContentForMaxPlan = () => {
    return isUserAdmin() ? (
      <>
        <span>
          Click on the <a href={'/v2/event_management/settings/integrations'}>Integrations tab</a>{' '}
          in Event Settings to connect
        </span>
        <span>
          <b>CalendarRules</b> with your SimpleLegal account to access Court Rules.
        </span>
      </>
    ) : (
      <>
        <span>
          Administrators can connect <b>CalendarRules</b> using the Integrations tab
        </span>
        <span>in Event Settings to access Court Rules.</span>
      </>
    )
  }

  return (
    <section>
      {isAddEventVisible && (
        <AddEvent
          toggleAddEventSidebar={toggleAddEventSidebar}
          events={events.map(t => ({ id: t.id, name: t.name }))}
          scopeName={scopeName}
          event={editedEvent}
          saveEvent={saveEvent}
          setEvents={setEvents}
          scopeId={scopeId}
          oldEvent={oldEvent}
          readOnly={readOnly}
          context={context}
          setScopeId={setScopeId}
          openedTab={detailsSubtab}
          refreshTable={refreshTable}
        />
      )}
      <div className={cn('box', { [s.noActions]: isAddEventVisible || restrictAllActions })}>
        <div className="box-content" style={{ minHeight: '70vh' }}>
          <div className={s.header}>
            <h2 className={s.title} data-testid="title">
              Events
            </h2>
            <div className={s.tabs}>
              <span
                className={cn({ [s.selected]: view === 'list' })}
                onClick={() => {
                  setView('list')
                  const newUrl = deleteQueryParam('subtab')
                  window.history.replaceState(null, '', newUrl)
                }}
              >
                <FaList /> List
              </span>
              <span
                className={cn({ [s.selected]: view === 'calendar' })}
                onClick={() => {
                  setView('calendar')
                  const newUrl = getUrlWithSubtab('calendar', true)
                  window.history.replaceState(null, '', newUrl)
                  setLocalState(getInitParams(context, window.location.hash, view))
                }}
              >
                <FaCalendarAlt /> Calendar
              </span>
              <span
                className={cn({ [s.selected]: view === 'courtRules' })}
                onClick={() => {
                  setView('courtRules')
                  const newUrl = getUrlWithSubtab('courtRules', true)
                  window.history.replaceState(null, '', newUrl)
                }}
                data-testid="courtRulesTab"
              >
                <PiGavelFill style={{ fontSize: 14, position: 'relative', top: 2 }} /> Court Rules{' '}
                {isBasicPlan && <UpdateToPremiumLabel dataTestId="secure-lock" />}
              </span>
            </div>
            <span className={s.rightActions}>
              {!readOnly && isEventsCalendarSyncEnabled() && (
                <>
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: `${isBasicPlan ? 5 : 8}px 15px`,
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 1
                      }}
                      key="sync"
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      {isBasicPlan ? (
                        <>
                          <FaCalendarAlt className={s.basicPlanCalendarIcon} /> Calendar Sync{' '}
                          <UpdateToPremiumLabel />
                        </>
                      ) : (
                        <>
                          Calendar Sync
                          <MicrosoftOfficeOutlookIcon className={s.calendarIcon} />
                          <GoogleCalendarIcon className={s.calendarIcon} />
                          <AppleCalendarIcon className={cn(s.calendarIcon, s.appleCalendarIcon)} />
                        </>
                      )}
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </>
              )}
              {(view === 'list' || view === 'courtRules') && (
                <Button
                  style={{ padding: '10px 15px' }}
                  key="download"
                  onClick={view === 'list' ? downloadEventsXlsx : downloadCourtRulesXlsx}
                  isPrimary
                  isOutline
                  hasNewDesign
                >
                  Download
                </Button>
              )}
              {!readOnly && (
                <ButtonDropdown
                  displayText="Add"
                  onSelect={option => {
                    if (option.value === 'simpleEvent') {
                      toggleAddEventSidebar()
                    } else {
                      toggleAddCourtRulesModal()
                    }
                    clearAllSelectedRows()
                  }}
                  options={[
                    { label: 'Create an event', value: 'simpleEvent' },
                    {
                      label: 'Add court rules',
                      value: 'courtRoules',
                      isPremium: !(isBasicPlan || !hasCalendarRules),
                      hasTooltip: isBasicPlan || !hasCalendarRules
                    }
                  ]}
                  isPrimary
                  alignRight
                  listStyles={{ marginTop: 12, fontSize: 14 }}
                  style={{
                    padding: '10px 15px',
                    borderRadius: '4px',
                    marginLeft: 10,
                    position: 'relative',
                    bottom: 2
                  }}
                  listItemStyles={{ fontSize: 14, margin: '10px 0' }}
                  hasNewDesign
                />
              )}
            </span>
          </div>
          {view === 'list' ? (
            <>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={changeTab}
                total={totalEntries}
                other={upcomingEventsCount}
                otherLabel="Agenda"
                otherValue="upcoming"
              />
              <DataTableWrapper
                isLoading={isLoading}
                remotePagination
                alwaysShowLoadingSkeleton
                params={params}
                categories={[]}
                rows={filteredEvents}
                totalEntries={totalEntries}
                filteredTotal={getPaginationTotalEntries()}
                columns={columns}
                updateTable={updateTable}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                className={s.itemsTable}
                customAction={renderCustomAction}
                hasActions={!readOnly}
                alwaysShowActions
                categoryKey="event"
                hasTooltip
                selectAllRows={!readOnly ? selectAllRows : undefined}
                selectRow={!readOnly ? selectRow : undefined}
                selectedRows={new Set(selectedRows)}
                allRowsSelected={allRowsSelected}
                checkboxSize="md"
                tooltipClassName={s.tooltip}
                filters={
                  <Filters
                    eventFilters={eventFilters}
                    setEventFilters={filters => {
                      setEventFilters(filters)
                      fetchEvents({ filters, cb: updateUrl })
                    }}
                    clearFilters={clearFilters}
                    baseUrl={baseUrl}
                    scopeId={scopeId}
                    context={context}
                    hasCalendarRules={hasCalendarRules}
                  />
                }
                bulkActions={bulkActionsButton}
                customStatusText={
                  !isFiltered && filteredEvents.length === 0
                    ? 'There are currently none, click Add to get started.'
                    : undefined
                }
              />
            </>
          ) : view === 'calendar' ? (
            <CalendarView
              fetchCalendarEvents={fetchCalendarEvents}
              changeTab={setView}
              events={calendarEvents}
              setEvents={setCalendarEvents}
              date={calendarDate}
              setDate={setCalendarDate}
              currentView={currentCalendarView}
              setCurrentView={setCurrentCalendarView}
            />
          ) : view === 'courtRules' ? (
            hasCalendarRules ? (
              <>
                <Tabs
                  selectedTab={selectedTab}
                  setSelectedTab={changeTab}
                  total={totalEntries}
                  other={needsReviewCount}
                  otherLabel="Review Court Rule Updates"
                  otherValue="review"
                />
                <DataTableWrapper
                  isLoading={isLoading}
                  remotePagination
                  alwaysShowLoadingSkeleton
                  params={params}
                  categories={[]}
                  rows={courtRules}
                  totalEntries={totalEntries}
                  filteredTotal={getPaginationTotalEntries()}
                  columns={courtRulesColumns}
                  updateTable={updateTable}
                  panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                  className={s.itemsTable}
                  customAction={renderCustomCourtRuleAction}
                  hasActions={!readOnly}
                  alwaysShowActions
                  categoryKey="courtRule"
                  hasTooltip
                  tooltipClassName={s.tooltip}
                  customStatusText={
                    !isCourtRulesFiltered && courtRules.length === 0
                      ? 'There are currently none, click Add to get started.'
                      : undefined
                  }
                  filters={
                    <CRFilters
                      courtRuleFilters={courtRuleFilters}
                      setCourtRuleFilters={filters => {
                        setCourtRuleFilters(filters)
                        fetchEvents({ filters, cb: updateUrl })
                      }}
                      clearFilters={clearFilters}
                      scopeId={scopeId}
                    />
                  }
                />
              </>
            ) : (
              <div className={s.courtRulesNotConfigured}>
                {isBasicPlan ? (
                  <>
                    <span>Contact Customer Service to upgrade your plan for access to using </span>
                    <span>
                      events with Court rules from <b>CalendarRules</b> .
                    </span>
                  </>
                ) : (
                  getCourtRulesConnectionContentForMaxPlan()
                )}
                <LawRuleIcon className={s.lawRuleIcon} />
              </div>
            )
          ) : null}
        </div>
      </div>
      {showCancelConfirmation && (
        <ConfirmationDialog
          title="Cancel this event?"
          confirmText="Cancel Event"
          cancelText="Exit"
          content="This will also remove the event information, files and comments."
          onConfirm={onCancelEvent}
          onCancel={() => {
            setShowCancelConfirmation(false)
          }}
        />
      )}
      {showBulkCancelConfirmation && (
        <ConfirmationDialog
          title={`Cancel ${pluralize('event', selectedRows.length, true)}?`}
          confirmText={`Cancel ${pluralize('Event', selectedRows.length)}`}
          cancelText="No"
          content="This will also remove all associated files, and comments? This action cannot be undone."
          onConfirm={cancelBulkEvents}
          onCancel={() => setShowBulkCancelConfirmation(false)}
        />
      )}
      {showBulkCancelNotAllowed && (
        <ConfirmationDialog
          title={`Can’t cancel ${selectedRows.length} selected ${pluralize(
            'event',
            selectedRows.length
          )}`}
          content={cantBulkCancelConfirmationContent()}
          hideButtons
          onCancel={() => setShowBulkCancelNotAllowed(false)}
        />
      )}
      {bulkEdit.isBulkEditDialog && (
        <BulkEditModal
          eventsNumber={selectedRows.length}
          matterId={+scopeId}
          onUpdate={(options: BulkEditValues) =>
            setBulkEdit({ isBulkEditDialog: false, options, isBulkEditConfirmationDialog: true })
          }
          onCancel={() => setBulkEdit(bulkEditInitialState)}
          context={context}
        />
      )}
      {bulkEdit.isBulkEditConfirmationDialog && (
        <ConfirmationDialog
          title={`Update ${pluralize('event', selectedRows.length, true)}`}
          confirmText="Update"
          content={`Are you sure you want to update the ${pluralize(
            'event',
            selectedRows.length,
            true
          )} you selected?`}
          onConfirm={updateBulkEvents}
          onCancel={() => setBulkEdit(bulkEditInitialState)}
        />
      )}
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
      {isDownloadModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your export is ready."
          confirmText="OK"
          confirmCb={() => setIsDownloadModalOpen(false)}
          cancelCb={() => setIsDownloadModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
      {isAddCourtRulesModalVisible && (
        <AddCourtRulesModal
          toggleModal={toggleAddCourtRulesModal}
          selectedJurisdictionId={selectedJurisdictionId}
          scopeName={scopeName}
          scopeId={_scopeId}
          refreshTable={refreshTable}
        />
      )}
      {isViewCourtRuleModalVisible && (
        <ViewCourtRuleModal
          toggleModal={toggleViewCourtRulesModal}
          selectedCourtRuleId={selectedCourtRuleId}
          scopeName={scopeName}
          toggleEditCourtRuleModal={toggleEditCourtRuleModal}
        />
      )}
      {isDeleteCourtRuleModalVisible && (
        <DeleteCourtRuleModal
          toggleModal={toggleDeleteCourtRuleModal}
          selectedCourtRuleId={selectedCourtRuleId}
          refreshTable={refreshTable}
        />
      )}
      {isEditCourtRuleModalVisible && (
        <EditCourtRuleModal
          toggleModal={toggleEditCourtRuleModal}
          selectedCourtRuleId={selectedCourtRuleId}
          scopeName={scopeName}
          refreshTable={refreshTable}
          baseUrl={baseUrl}
          scopeId={scopeId}
        />
      )}
      {isUpdateRulesModalVisible && (
        <UpdateRulesModal
          toggleModal={toggleUpdateRulesModal}
          scopeName={scopeName}
          selectedCourtRuleId={selectedCourtRuleId}
          scopeId={scopeId}
          refreshTable={refreshTable}
          baseUrl={baseUrl}
        />
      )}
    </section>
  )
}

export default Events
