import { Component } from 'react'
import s from '../styles/HomeDashboard.scss'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import { Grid } from 'simple-core-ui'
import { connect } from 'react-redux'
import ACT from 'dashboard/actions'
import get from 'lodash/get'
import { KpiSkeleton } from 'common'

const getLoadingSkeleton = () => {
  const { Row, Column } = Grid
  const kpiNumber = 3
  let skeletons = []
  for (let i = 0; i < kpiNumber; i++) {
    skeletons.push(
      <Column span={4} breakpoint="laptop" key={i}>
        <section className={s.kpi}>
          <KpiSkeleton />
        </section>
      </Column>
    )
  }
  return (
    <section className={s.kpisContainer}>
      <Row>{skeletons}</Row>
    </section>
  )
}

const KPI = ({ main, secondary, tertiary, url }) => {
  return (
    <section>
      <a className="no-link" href={url} data-testid={url}>
        <section className={s.kpi}>
          <section className={s.kpiMain}>
            <div className={s.num} data-testid="kpiMainNumber">
              {main.num}
            </div>
          </section>
          <section className={s.kpiSecondary}>
            <div className={s.kpiLabel}>{main.label}</div>
            <div className={s.kpiDotLabel}>
              <span className={s.kpiCircleGreen} />
              <span data-testid="kpiSecondaryLabel">
                {secondary.num} {secondary.label}
              </span>
            </div>
            <div className={s.kpiDotLabel}>
              <span className={s.kpiCircleBlue} />
              <span data-testid="kpiTertiaryLabel">
                {tertiary.num} {tertiary.label}
              </span>
            </div>
          </section>
        </section>
      </a>
    </section>
  )
}

@connect(({ dashboard }) => {
  const { kpis } = dashboard
  return {
    kpis
  }
})
class KPIsContainer extends Component {
  render() {
    const { Row, Column } = Grid
    const { kpis } = this.props

    return (
      <Row>
        <Column span={4} breakpoint="laptop">
          <KPI
            url="/v2/invoices?#category=Received"
            main={{
              num: get(kpis, 'open_invoices.total', '--'),
              label: 'Open Invoices'
            }}
            secondary={{
              num: get(kpis, 'open_invoices.received_during_range', '--'),
              label: 'Received in last week'
            }}
            tertiary={{
              num: get(kpis, 'open_invoices.approved_during_range', '--'),
              label: 'Approved in last week'
            }}
          />
        </Column>
        <Column span={4} breakpoint="laptop">
          <KPI
            url="/v2/invoices/?#category=On Hold"
            main={{
              num: get(kpis, 'invoices_on_hold.total', '--'),
              label: 'Invoices On Hold'
            }}
            secondary={{
              num: get(kpis, 'invoices_on_hold.on_hold_during_range', '--'),
              label: 'On Hold in last week'
            }}
            tertiary={{
              num: get(kpis, 'invoices_on_hold.resolved_during_range', '--'),
              label: 'Resolved in last week'
            }}
          />
        </Column>
        <Column span={4} breakpoint="laptop">
          <KPI
            url="/v2/matters/list"
            main={{
              num: get(kpis, 'open_matters.total', '--'),
              label: 'Open Matters'
            }}
            secondary={{
              num: get(kpis, 'open_matters.opened_during_range', '--'),
              label: 'Opened in last week'
            }}
            tertiary={{
              num: get(kpis, 'open_matters.closed_during_range', '--'),
              label: 'Closed in last week'
            }}
          />
        </Column>
      </Row>
    )
  }
}

@withFetchFlow({
  flag: 'Dashboard_KPIs',
  render: () => <>{getLoadingSkeleton()}</>,
  getFetchAction: () => ({
    type: ACT.DASHBOARD_KPIS_FETCH_REQUESTED
  })
})
class DashboardKPIs extends Component {
  render() {
    return (
      <section className={s.kpisContainer}>
        <KPIsContainer />
      </section>
    )
  }
}

export default DashboardKPIs
