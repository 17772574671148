import { useEffect, useState, useMemo } from 'react'
import { ModalContainer, Button, useLoading, Tooltip, DataTableWrapper } from 'simple-core-ui'
import s from './UpdateRulesModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { makeGetRequest, makeDeleteRequest, makePatchRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { Cell } from '../types'
import { format } from 'date-fns'
import { timezoneDate, timezoneUtils } from 'utils/helpers'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import cn from 'classnames'
import { useImmer, Updater } from 'use-immer'
import { ExtendedCourtRule, CourtRuleEvent, Rule } from './types'
import { toCourtRule } from '../serializers'
import { IoWarningOutline } from 'react-icons/io5'
import { fromEvent, toCourtRuleEvent } from './serializers'
import capitalize from 'lodash/capitalize'
import partition from 'lodash/partition'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: Infinity,
  ordering: { columnKey: 'date', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

interface Props {
  toggleModal: () => void
  scopeName: string
  selectedCourtRuleId: number | null
  refreshTable: () => void
  scopeId: string
  baseUrl: string
}

const UpdateRulesModal = ({
  toggleModal,
  scopeName,
  selectedCourtRuleId,
  refreshTable,
  scopeId,
  baseUrl
}: Props) => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [courtRule, setCourtRule]: [ExtendedCourtRule, Updater<ExtendedCourtRule>] = useImmer<
    ExtendedCourtRule
  >({} as ExtendedCourtRule)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [events, setEvents] = useState<Rule[]>([])
  const [params, setParams] = useState(initialParams)
  const [shouldUpdateEvents, setShouldUpdateEvents] = useState(false)

  const selectAllRows = (localEvents?: CourtRuleEvent[]) => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(allRowsSelected ? [] : (localEvents || events).map(t => t.id))
  }

  const selectRow = (id: number) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const fetchRule = async () => {
    try {
      const response = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/court-rules/details/${selectedCourtRuleId}`
        )
      )
      setCourtRule({
        ...toCourtRule(response),
        events: response.events,
        triggerDateAPI: response.trigger_date,
        time: [
          format(
            timezoneDate(response.trigger_date as string, response.jurisdiction.time_zone),
            'HH:mm'
          ),
          null
        ]
      })

      setShouldUpdateEvents(response.should_update_events)

      let courtRuleEvents = response.events.map((e: CourtRuleEvent) => toCourtRuleEvent(e))

      if (response.deletions?.length) {
        courtRuleEvents = courtRuleEvents.map((e: CourtRuleEvent) => {
          if (response.deletions.includes(e.id)) {
            e.type = 'delete'
          }
          return e
        })
      }

      if (response.updates?.length) {
        courtRuleEvents = courtRuleEvents.map((e: CourtRuleEvent) => {
          const event = response.updates.find((event: CourtRuleEvent) => event.id === e.id)
          if (event) {
            e.type = 'update'
            e.prev_date = event?.old_date
            e.do_not_recalculate = event?.do_not_recalculate
            e.is_event_docket = event?.is_event_docket
            e.is_on_dnc_list = event?.is_on_dnc_list
          }
          return e
        })
      }

      if (response.additions?.length) {
        courtRuleEvents.push(
          ...response.additions.map((e: CourtRuleEvent) => toCourtRuleEvent(e, 'add'))
        )
      }

      setEvents(courtRuleEvents)
      selectAllRows(courtRuleEvents)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchRule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleExpand = (id: number) => {
    const updatedEvents = events.map(event => {
      if (event.id === id) {
        return {
          ...event,
          expanded: !event.expanded
        }
      }
      return event
    })
    setEvents(updatedEvents)
  }

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'prev_date',
        content: <b>Old Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell) => {
          return cell.content !== '----'
            ? format(
                timezoneDate(cell.content as string, courtRule.jurisdiction?.timezone),
                'MM/dd/yyyy'
              )
            : ''
        }
      },
      {
        columnKey: 'date',
        content: <b>New Date</b>,
        isSortable: true,
        isFilterable: true,
        style: { width: '200px' },
        render: (cell: Cell) => {
          return cell.content !== '----'
            ? format(
                timezoneDate(cell.content as string, courtRule.jurisdiction?.timezone),
                'MM/dd/yyyy'
              )
            : ''
        }
      },
      {
        columnKey: 'type',
        content: <b>Event Changes</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell) => {
          return typeof cell.content === 'string' ? capitalize(cell.content) : '----'
        }
      },
      {
        columnKey: 'description',
        content: <b>Events</b>,
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell, row: CourtRuleEvent) => {
          return (
            <div
              className={cn(s.description, {
                [s.descriptionCollapsed]: !row.expanded
              })}
            >
              {cell.content as string}
            </div>
          )
        }
      },
      {
        columnKey: 'expanded',
        isSortable: false,
        isFilterable: false,
        content: '',
        render: (_cell: Cell, row: CourtRuleEvent) => {
          return row.expanded ? (
            <FaChevronUp className={s.arrow} onClick={() => toggleExpand(row.id)} />
          ) : (
            <FaChevronDown className={s.arrow} onClick={() => toggleExpand(row.id)} />
          )
        }
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, courtRule])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  const updateEvents = async () => {
    try {
      if (!shouldUpdateEvents) {
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Success',
            message: `${courtRule.name} successfully updated`,
            level: 'success'
          }
        })

        toggleModal()
        return
      }

      const additionalEvents = events.filter((event: Rule) => event.type === 'add')
      const deletions = events.filter((event: Rule) => event.type === 'delete').map(e => e.id)
      const updates = events.filter((event: Rule) => event.type === 'update')

      let selectedForDeletionEventIds: number[] = []
      let notSelectedForDeletionEventIds: number[] = []
      let selectedEventsForUpdate: Rule[] = []
      let notSelectedEventsForUpdate: Rule[] = []
      let selectedEventsForAddition: Rule[] = []
      let notSelectedEventsForAddition: Rule[] = []

      if (deletions.length) {
        ;[selectedForDeletionEventIds, notSelectedForDeletionEventIds] = partition(deletions, id =>
          selectedRows.includes(id)
        )
        await withLoadingLocks(
          makeDeleteRequest(`${baseUrl}/events/bulk-operations/`, {
            data: { event_ids: selectedForDeletionEventIds }
          })
        )
      }
      if (additionalEvents.length) {
        ;[selectedEventsForAddition, notSelectedEventsForAddition] = partition(
          additionalEvents,
          (event: Rule) => selectedRows.includes(event.id)
        )
        await withLoadingLocks(
          makePostRequest(`/event-management/calendar_rules/create-events/${scopeId}`, {
            events: selectedEventsForAddition.map(fromEvent),
            court_rule_name: courtRule.name,
            jurisdiction_system_id: courtRule.jurisdiction?.value,
            jurisdiction_name: courtRule.jurisdiction?.label,
            trigger_date: courtRule.triggerDateAPI || '',
            trigger_item_system_id: courtRule.triggerEvent?.value,
            trigger_item_name: courtRule.triggerEvent?.label,
            prefix: courtRule.prefix
          })
        )
      }
      if (updates.length) {
        ;[selectedEventsForUpdate, notSelectedEventsForUpdate] = partition(updates, (event: Rule) =>
          selectedRows.includes(event.id)
        )

        await withLoadingLocks(
          makePatchRequest(`/event-management/matters/${scopeId}/events/bulk-update/`, {
            events: selectedEventsForUpdate.map(event => ({
              start_date: event.start_date,
              name: event.name,
              description: event.description,
              id: event.id
            }))
          })
        )

        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Success',
            message: `${selectedEventsForUpdate.length} event(s) from ${courtRule.name} were successfuly updated`,
            level: 'success'
          }
        })
      }

      await makePostRequest(
        `/event-management/calendar_rules/court-rules/mark-updates/${selectedCourtRuleId}`,
        {
          applied: {
            added: selectedEventsForAddition.map(e => e.id),
            deleted: selectedForDeletionEventIds,
            updated: selectedEventsForUpdate.map(e => e.id)
          },
          declined: {
            added: notSelectedEventsForAddition.map(e => e.id),
            deleted: notSelectedForDeletionEventIds,
            updated: notSelectedEventsForUpdate.map(e => e.id)
          }
        }
      )

      refreshTable()
      toggleModal()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onCancel = async () => {
    try {
      const additions = events.filter((event: Rule) => event.type === 'add')
      const deletions = events.filter((event: Rule) => event.type === 'delete')
      const updates = events.filter((event: Rule) => event.type === 'update')

      await makePostRequest(
        `/event-management/calendar_rules/court-rules/mark-updates/${selectedCourtRuleId}`,
        {
          applied: {
            added: [],
            deleted: [],
            updated: []
          },
          declined: {
            added: additions.map(e => e.id),
            deleted: deletions.map(e => e.id),
            updated: updates.map(e => e.id)
          }
        }
      )

      refreshTable()
      toggleModal()
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  if (!courtRule.id) return null

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={toggleModal}
      content={
        <div className={s.container}>
          <div className={s.content}>
            <h2 className={s.title}>Update Court Rules</h2>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application
            <p>
              <IoWarningOutline
                style={{ fontSize: 22, color: '#bb342f', position: 'relative', top: 5 }}
              />{' '}
              There are updates from <b>CalendarRules</b> that require action. They may change
              matter with court rules and the associated events.
            </p>
            <div className={s.item}>
              <p className={s.label}>Court Rule Name</p>
              <p>{courtRule.name}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Matter</p>
              <p className={s.matterName}>{scopeName}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Jurisdiction</p>
              <p>{courtRule.jurisdiction?.label}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Trigger Event</p>
              <p>{courtRule.triggerEvent?.label}</p>
            </div>
            <div className={s.item}>
              <p className={s.label}>Trigger Date</p>
              <p>{format(courtRule.triggerDate as Date, 'MM/dd/yyyy')}</p>
            </div>
            <div className={s.item}>
              <div className={s.label}>
                Trigger Time{' '}
                <Tooltip
                  trigger={<AiOutlineInfoCircle className={s.info} />}
                  content="Time zone is from the Jurisdiction"
                  placement="top"
                />
              </div>
              <p>
                {courtRule.time
                  ? courtRule.time[0] +
                    ' ' +
                    (courtRule.jurisdiction?.timezone || timezoneUtils.getUserTimezone())
                  : 'N/A'}
              </p>
            </div>
            <div className={s.item}>
              <div className={s.label}>
                Prefix{' '}
                <Tooltip
                  trigger={<AiOutlineInfoCircle className={s.info} />}
                  content="The prefix is prepended to the event name"
                  placement="top"
                />
              </div>
              <p>[{courtRule.prefix}]</p>
            </div>
            <div style={{ marginTop: 10 }}>
              <p style={{ fontWeight: 600, fontSize: 16, position: 'relative', top: 15, left: 10 }}>
                {selectedRows.length}/{events.length} Court Rule Events selected
              </p>
              <DataTableWrapper
                params={params}
                categories={[]}
                rows={events}
                columns={columns}
                updateTable={updateTable}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                hasTooltip
                categoryKey="events"
                hasActionsHeader={false}
                hasPagination={false}
                selectAllRows={selectAllRows}
                selectRow={row => selectRow(row.id)}
                selectedRows={new Set(selectedRows)}
                allRowsSelected={allRowsSelected}
                checkboxSize="md"
              />
            </div>
          </div>
          <div className={s.footer}>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={onCancel}
              isPrimary
              isOutline
              hasNewDesign
            >
              Decline {selectedRows.length} event updates
            </Button>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2
              }}
              onClick={updateEvents}
              isPrimary
              hasNewDesign
              isDisabled={!selectedRows.length}
            >
              Accept {selectedRows.length} event updates
            </Button>
          </div>
        </div>
      }
    />
  )
}

export default UpdateRulesModal
