import { useLocation } from 'react-router-dom'
import AssignedContactsContainer from '../AssignedContacts/AssignedContactsContainer'
import s from './IntegratedAssignedContacts.scss'
import AssignedContactsTableContainer from 'contacts/AssignedContactsTableContainer/AssignedContactsTableContainer'

const IntegratedAssignedContacts = ({ readOnly, matterStatus, className }) => {
  const location = useLocation()

  return (
    <section className={s.container}>
      {location.pathname.includes('matters') && (
        <AssignedContactsContainer
          closedKeyword="is_matter_closed"
          editableKeyword={false}
          readOnly={readOnly}
          matterStatus={matterStatus}
          className={className}
        />
      )}
      {location.pathname.includes('vendors') ? <AssignedContactsTableContainer /> : null}
    </section>
  )
}

export default IntegratedAssignedContacts
