import { useState, useEffect, useMemo } from 'react'
import { DataTableWrapper, useLoading, Badge, DESIGN, Ellipsis } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import update from 'immutability-helper'
import { useDispatch } from 'react-redux'
import { getNormalizedCellContent } from './utils'
import { Link } from 'react-router-dom'
import moment from 'moment'
import s from './SurveyCompletedTab.scss'
import { Filters } from './Filters'
import { getSurveyedProperty, arrayToSelectOptions } from './serializers'
import { useImmer } from 'use-immer'

const initialParams = {
  pageSize: 10,
  ordering: { columnKey: 'completed_date', isDesc: false },
  search: '',
  page: 1,
  category: 'all',
  filters: {}
}

const completedSurveysList = {
  results: [],
  filtered: 0,
  total: 0,
  page: 1
}

const {
  color: { blue800 }
} = DESIGN

function SurveyCompletedTab({ scopeId }) {
  const [params, setParams] = useImmer(draft => initialParams)
  const [completedSurveys, setCompletedSurveys] = useState(completedSurveysList)
  const [filteredCompletedSurveys, setFilteredCompletedSurveys] = useImmer(
    draft => completedSurveysList
  )
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const { completed_surveys } = await withLoadingLocks(
          makeGetRequest(`/manage/matters/completed_vendor_surveys/${scopeId}/`)
        )
        const newState = update(completedSurveys, {
          results: { $set: completed_surveys }
        })
        setCompletedSurveys(newState)
        setFilteredCompletedSurveys(draft => newState)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { results } = completedSurveys
    const filtered = results
      .filter(r => {
        if (!params.filters.vendor) return true
        return r.vendor === params.filters.vendor.value
      })
      .filter(r => {
        if (!params.filters.period) return true
        return r.period === params.filters.period.value
      })

    setFilteredCompletedSurveys(draft => {
      draft.results = filtered
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.filters.period, params.filters.vendor])

  const results = useMemo(() => {
    const { results } = filteredCompletedSurveys
    const { total } = completedSurveys
    const columns = [
      {
        columnKey: 'completed_date',
        content: 'Completed',
        isSortable: true,
        render: cell => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'overall_satisfaction',
        content: 'Satisfaction',
        isSortable: true,
        render: (cell, row) => {
          const content = cell.content
          return (
            <Link to={row.eval_link}>
              <Badge
                style={{ background: blue800 }}
                title={content}
                content={content}
                className={s.dashPill}
                asPill
                size="md"
              />
            </Link>
          )
        },
        style: {
          textAlign: 'center'
        }
      },
      {
        columnKey: 'period',
        content: 'Survey Period',
        isSortable: false,
        style: {
          width: '200px'
        }
      },
      {
        columnKey: 'vendor',
        content: 'Vendor',
        isSortable: true,
        isFilterable: true,
        style: {
          width: '300px'
        }
      },
      {
        columnKey: 'evaluator',
        content: 'Evaluator',
        isSortable: true,
        isFilterable: true,
        style: {
          width: '200px'
        }
      },
      {
        columnKey: 'role',
        content: 'Role(s)',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'comment',
        content: 'Satisfaction Comment',
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          const content = cell.content
          return <Ellipsis lines={1}>{content}</Ellipsis>
        }
      }
    ]
    return {
      columns,
      rows: results,
      totalEntries: total,
      filteredTotal: total
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCompletedSurveys])

  const updateTable = updatedParams => {
    setParams(draft => ({ ...updatedParams, filters: draft.filters }))
  }

  const onSearch = (value, filter) => {
    setParams(draft => {
      if (filter) {
        draft.filters[filter] = value
      } else {
        draft.filters.vendor = null
        draft.filters.period = null
      }
    })
  }

  return (
    <DataTableWrapper
      params={params}
      customSearchOptions={{
        styles: { width: 270, fontStyle: 'italic' }
      }}
      categories={[]}
      rows={results.rows}
      columns={results.columns}
      filters={
        <Filters
          filterParams={params.filters}
          onSearch={onSearch}
          vendors={arrayToSelectOptions(getSurveyedProperty(completedSurveys.results, 'vendor'))}
          periods={arrayToSelectOptions(getSurveyedProperty(completedSurveys.results, 'period'))}
        />
      }
      totalEntries={results.totalEntries}
      filteredTotal={results.filteredTotal}
      updateTable={updateTable}
      panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
      hasTooltip
      getNormalizedCellContent={getNormalizedCellContent}
    />
  )
}

export default SurveyCompletedTab
