import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requestForms/detail/actions'
import { withRouter } from 'simple-core-ui/hocs'
import { TEMPLATE_STATUSES } from 'matters/templates/constants'
import { RequestFormContentContainer } from './Content'
import { FormHeader } from './FormHeader'
import { FORM_STATUSES } from 'matters/requestForms/constants'

@withRouter
@connect(({ requestForm, matterTemplatesList }) => {
  const { name, description, selectedTemplates, status, users, availableUsers } = requestForm
  const templateCount = matterTemplatesList.matterTemplates.filter(
    template => template.status === TEMPLATE_STATUSES.PUBLISHED
  ).length

  return {
    name,
    description,
    selectedTemplates,
    status,
    templateCount,
    users,
    availableUsers
  }
})
class LegalRequestFormContentContainer extends Component {
  componentDidMount() {
    const { templateCount } = this.props

    if (!templateCount) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED,
        payload: {
          latestTemplateId: {}
        }
      })
    }
  }
  resetForm = () => {
    this.props.dispatch({ type: ACT.REQUEST_FORM_RESET_STATE })
  }
  changeFormName = name => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_CHANGE_FORM_NAME,
      payload: {
        name: name
      }
    })
  }
  changeFormDescription = description => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_CHANGE_FORM_DESCRIPTION,
      payload: {
        description
      }
    })
  }
  navigateToSelectTemplates = () => {
    const { navigate, params } = this.props.router
    const id = params.id
    const queryString = id ? `?formId=${id}` : ''
    navigate(`/v2/matters/templates/form/select/${queryString}`, { state: { fromFormPage: true } })

    const ids = this.props.selectedTemplates.map(template => {
      return template.id
    })
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FORM_LIST_SET_PREFERRED,
      payload: {
        ids
      }
    })

    this.props.dispatch({
      type: ACT.FORM_SET_TMP_TEMPLATES
    })
  }
  onUsersChange = users => {
    this.props.dispatch({
      type: ACT.FORM_SET_NOTIFY_USERS,
      payload: {
        users
      }
    })
  }
  render() {
    const { name, description, status, users, availableUsers } = this.props
    const disabled = status === FORM_STATUSES.PUBLISHED || status === FORM_STATUSES.DISABLED
    return (
      <Fragment>
        <FormHeader
          name={name}
          description={description}
          changeName={this.changeFormName}
          changeDescription={this.changeFormDescription}
          userOptions={availableUsers}
          userValue={users}
          onUsersChange={this.onUsersChange}
        />
        <RequestFormContentContainer
          disabled={disabled}
          navigateToSelectTemplates={this.navigateToSelectTemplates}
        />
      </Fragment>
    )
  }
}

export default LegalRequestFormContentContainer
