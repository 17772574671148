import keyMirror from 'keymirror'

export default keyMirror({
  ITEMS_NEEDING_MY_ACTION_FETCH_REQUESTED: null,
  ITEMS_NEEDING_MY_ACTION_FETCH_SUCCESS: null,
  RECENT_ITEMS_FETCH_REQUESTED: null,
  RECENT_ITEMS_FETCH_SUCCESS: null,
  FOLLOWING_FETCH_SUCCESS: null,
  FOLLOWING_FETCH_REQUESTED: null,
  EVENTS_FETCH_SUCCESS: null,
  EVENTS_FETCH_REQUESTED: null,
  DASHBOARD_KPIS_FETCH_REQUESTED: null,
  DASHBOARD_KPIS_FETCH_SUCCESS: null
})
