import { Component } from 'react'
import get from 'lodash/get'
import has from 'lodash/has'
import sortBy from 'lodash/sortBy'

import { ComboBoxContainer } from 'containers'
import { EngineContext } from 'rules/context'
import { LHS_TYPES, FUNC_ARG_TYPES, ER6_FIELDS } from 'rules/constants'
import { getByName, getByAttrId, getModelAttrField, nameToLabel } from 'rules/utils'

import FuncForm from '../components/FuncForm.js'
import FuncLabel from '../components/FuncLabel.js'

const isSet = val => Boolean(Object.keys(val).length)

const isEmpty = val => !isSet(val)

const EMPTY = {}

export default class LhsContainer extends Component {
  constructor(props) {
    super(props)
    const { lhs, availableFields } = this.props

    this.state = {
      ...this.getInitialFuncState(),
      ...getModelAttrField(lhs, availableFields)
    }
  }

  getInitialFuncState = () => {
    const { lhs, availableFields } = this.props

    const initialFuncDef = availableFields.find(
      f => f.isFunction && f.name === get(lhs, 'func_name')
    )

    return {
      funcDef: initialFuncDef || EMPTY,
      funcCall: lhs.operand_type === LHS_TYPES.FUNC_CALL ? lhs : EMPTY,
      activeParam: null
    }
  }

  isField = value => has(value, 'field_type')

  funcFieldArgsByType = this.props.availableFields.reduce((acc, { name = '', fields = [] }) => {
    for (let field of fields) {
      const funcFieldArg = {
        model_name: name,
        model_field: field.name,
        display_name: field.display_name,
        arg_type: FUNC_ARG_TYPES.FIELD,
        ...(typeof field.choices === 'string' ? { url: field.choices } : {})
      }

      if (has(acc, [field.type, field.sub_type])) {
        acc[field.type][field.sub_type].push(funcFieldArg)
      } else if (has(acc, [field.type])) {
        acc[field.type][field.sub_type] = [funcFieldArg]
      } else {
        acc[field.type] = {}
        acc[field.type][field.sub_type] = [funcFieldArg]
      }
    }

    return acc
  }, {})

  annotateForComboBox = options =>
    options.map(option => ({
      ...option,
      label: option.label || option.display_name,
      isGroup: !this.isField(option)
    }))

  getOptions = () => {
    const { availableFields } = this.props
    const { model, listAttr } = this.state

    let options = availableFields

    if (!window.credentials.user.isCSM) {
      options = options.filter(({ isFunction }) => !isFunction)
    }

    if (isSet(model) && isSet(listAttr)) {
      const extractedModel = getByName(options, get(model, 'name'))
      const extractedListAttr = getByAttrId(
        extractedModel.list_custom_attrs,
        get(listAttr, 'attr_id')
      )

      options = get(extractedListAttr, 'fields', [])
    } else if (isSet(model)) {
      const extractedModel = getByName(options, get(model, 'name'))

      options = [...extractedModel.fields, ...extractedModel.list_custom_attrs].filter(field => {
        return !ER6_FIELDS.includes(field.name)
      })
    }

    return sortBy(this.annotateForComboBox(options), 'label')
  }

  getPath = ({ model, listAttr, field }, emptyText = 'Select...') => {
    return `${isSet(model) ? model.display_name : emptyText}${
      isSet(listAttr) ? ` · ${listAttr.display_name}` : ''
    }${isSet(field) ? ` · ${field.display_name}` : ''}`.trim()
  }

  getArgFieldMap = lhsArgs => {
    const fields = this.props.availableFields.reduce((acc, c) => {
      return c.isFunction ? acc : [...acc, ...c.fields]
    }, [])

    const argFieldMap = lhsArgs.reduce((acc, c) => {
      if (c.arg_type !== FUNC_ARG_TYPES.FIELD) {
        return acc
      }
      const field = fields.find(({ name }) => name === c.model_field)

      acc[c.arg_name] = field
      return acc
    }, {})

    return argFieldMap
  }

  getFuncPath = ({ func_name, args }, comboIsOpen) => (
    <FuncLabel
      funcName={func_name}
      funcArgs={args}
      argFieldMap={this.getArgFieldMap(args)}
      showMore={!comboIsOpen}
    />
  )

  getLabel = comboIsOpen => {
    const { lhs, availableFields } = this.props

    return lhs.operand_type === LHS_TYPES.FUNC_CALL
      ? this.getFuncPath(lhs, comboIsOpen)
      : this.getPath(getModelAttrField(lhs, availableFields))
  }

  getHeading = () => {
    return this.getPath(this.state, '')
  }

  getFuncHeading = () => {
    const { funcDef, activeParam } = this.state
    const paramHeading = activeParam ? ` · ${nameToLabel(activeParam.arg_name)}` : ''

    return `${funcDef.display_name}${paramHeading}`
  }

  stepIn = value => {
    if (value.isFunction) {
      this.setState(
        get(this.props.lhs, 'func_name') === value.name
          ? this.getInitialFuncState()
          : { funcDef: value }
      )
    } else if (this.isField(value)) {
      this.setState({ field: value }, this.onChange)
    } else if (isEmpty(this.state.model)) {
      this.setState({ model: value })
    } else {
      this.setState({ listAttr: value })
    }
  }

  stepOut = () => {
    if (this.state.activeParam) {
      this.setState({ activeParam: null })
    } else if (isSet(this.state.funcDef)) {
      this.setState({ funcDef: EMPTY, funcCall: EMPTY })
    } else if (isSet(this.state.listAttr)) {
      this.setState({ listAttr: EMPTY, field: EMPTY })
    } else {
      this.setState({ model: EMPTY, field: EMPTY })
    }
  }

  onChange = () => {
    const { field, model, listAttr } = this.state
    const listAttrId = get(listAttr, 'attr_id')

    this.props.onChange({
      ...(listAttrId ? { attr_id: listAttrId } : {}),
      field_name: field.name,
      model_name: model.name,
      operand_type: listAttrId ? 'list_custom_attr' : LHS_TYPES.FIELD_VAR
    })
  }

  setFunc = funcCall => {
    this.setState({ funcCall })
    this.props.onChange(funcCall)
  }

  updateParams = newParams => {
    this.setState(prevState => ({
      funcCall: { ...prevState.funcCall, args: newParams }
    }))
  }

  setActiveParam = param => {
    this.setState({ activeParam: param })
  }

  resetFunc = ({ outsideClick }) => {
    if (outsideClick) {
      this.setState(this.getInitialFuncState())
    }
  }

  isFuncWithNonCSM = () =>
    this.props.lhs.operand_type === LHS_TYPES.FUNC_CALL && !window.credentials.user.isCSM

  render() {
    const { model, funcDef, funcCall, activeParam } = this.state
    const funcWithNonCSM = this.isFuncWithNonCSM()
    const FUNC_FORM_WIDTH = '400px'

    return (
      <EngineContext.Consumer>
        {({ canEdit }) =>
          isSet(funcDef) ? (
            <ComboBoxContainer
              heading={this.getFuncHeading()}
              selectionCb={this.setFunc}
              label={this.getLabel}
              navigateBackCb={() => this.stepOut()}
              onClose={this.resetFunc}
              custom={comboBoxSelectionCb => (
                <FuncForm
                  funcDef={funcDef}
                  funcCall={funcCall}
                  activeParam={activeParam}
                  setActiveParam={this.setActiveParam}
                  updateParams={this.updateParams}
                  onSet={comboBoxSelectionCb}
                  funcFieldArgsByType={this.funcFieldArgsByType}
                  argFieldMap={this.getArgFieldMap(get(funcCall, 'args', []))}
                />
              )}
              style={{ dropdown: { left: `calc(50% - ${FUNC_FORM_WIDTH}/2)` } }}
              disabled={!canEdit || funcWithNonCSM}
            />
          ) : (
            <ComboBoxContainer
              heading={this.getHeading()}
              label={this.getLabel}
              onClose={this.resetFunc}
              {...(isSet(model) ? { navigateBackCb: this.stepOut } : {})}
              selectionCb={this.stepIn}
              options={this.getOptions()}
              disabled={!canEdit || funcWithNonCSM}
            />
          )
        }
      </EngineContext.Consumer>
    )
  }
}
