import qs from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { DateFilterSerializer, MultiSelectFilterSerializer } from 'common/Filters/serializers'

export const serializeParams = params => {
  const getOrdering = ordering => {
    let key = ordering.columnKey
    switch (key) {
      case 'mattergroups':
        key = 'mattergroup_name'
        break
    }
    return ordering.isDesc ? `-${key}` : key
  }

  return {
    size: params.pageSize,
    ordering: params.ordering ? getOrdering(params.ordering) : null,
    page: params.page,
    other_attribute: params.other_attribute,
    other_attribute_list_value: params.other_attribute_list_value,
    search: {
      ...params.search,
      ...(params.search.status ? { status: params.search.status.map(s => s.value) } : {}),
      ...(params.search.vendor_ids
        ? { vendor_ids: params.search.vendor_ids.map(s => s.value) }
        : {}),
      ...(params.search.matter_id ? { matter_id: params.search.matter_id.map(s => s.value) } : {}),
      ...(params.search.matter_group_ids
        ? {
            matter_group_ids: params.search.matter_group_ids.map(s => s.value)
          }
        : {}),
      ...(params.search.matter_lead
        ? {
            matter_lead: params.search.matter_lead.map(s => s.value)
          }
        : {}),
      ...(params.search.legal_entity_ids
        ? {
            legal_entity_ids: params.search.legal_entity_ids.map(s => s.value)
          }
        : {}),
      ...(params.search.template_update_available
        ? {
            template_update_available: params.search.template_update_available.value === 'true'
          }
        : {}),
      ...(params.search.open_date
        ? {
            open_date: DateFilterSerializer.toServer(params.search.open_date)
          }
        : {}),
      ...(params.search.last_billed
        ? {
            last_billed: DateFilterSerializer.toServer(params.search.last_billed)
          }
        : {})
    }
  }
}

export const fromFragment = (hash, initialParams) => {
  const parsedHash = qs.parse(hash)

  const result = cloneDeep(initialParams)

  Object.keys(parsedHash).forEach(key => {
    if (key === 'columnKey' || key === 'isDesc') {
      result.ordering = {
        columnKey: parsedHash.columnKey,
        isDesc: parsedHash.isDesc === 'true'
      }
    } else if (key === 'keyword') {
      result.search[key] = parsedHash[key]
    } else if (
      ['matter_group_ids', 'matter_lead', 'legal_entity_ids', 'matter_id', 'vendor_ids'].includes(
        key
      )
    ) {
      result.search[key] = MultiSelectFilterSerializer.fromFragment(key, parsedHash)
    } else if (key.includes('last_billed')) {
      result.search.last_billed = DateFilterSerializer.fromFragment('last_billed', parsedHash)
    } else if (key.includes('open_date')) {
      result.search.open_date = DateFilterSerializer.fromFragment('open_date', parsedHash)
    } else if (key === 'template_update_available') {
      result.search[key] =
        parsedHash[key] === 'true'
          ? { value: 'true', label: 'Yes', alternativeLabel: 'Template w/ updates' }
          : { value: 'false', label: 'No', alternativeLabel: 'Template w/o updates' }
    } else if (key === 'status') {
      result.search[key] = Array.isArray(parsedHash[key])
        ? parsedHash[key].map(p => ({
            value: p.split(':')[0],
            label: p.split(':')[1]
          }))
        : [{ value: parsedHash[key].split(':')[0], label: parsedHash[key].split(':')[1] }]
    } else if (key === 'other_attribute' || key === 'other_attribute_list_value') {
      result[key] = Number(parsedHash[key])
    } else if (key === 'pageSize' || key === 'page') {
      result[key] = Number(parsedHash[key])
    } else {
      result[key] = parsedHash[key]
    }
  })

  return result
}

export const toFragment = (obj, selectedTab) => {
  const newObj = {
    pageSize: obj.pageSize,
    page: obj.page,
    category: selectedTab
  }

  let newFilters = {}
  if (obj.search) {
    Object.keys(obj.search).forEach(key => {
      const currFilter = obj.search[key]
      if (
        ['matter_group_ids', 'matter_lead', 'legal_entity_ids', 'matter_id', 'vendor_ids'].includes(
          key
        ) &&
        !!currFilter
      ) {
        newFilters[key] = MultiSelectFilterSerializer.toFragment(key, currFilter)
      } else if ((key === 'open_date' || key === 'last_billed') && !!currFilter) {
        newFilters[key] = DateFilterSerializer.toFragment(key, currFilter)
      } else if (
        ['matter_group_ids', 'matter_lead', 'legal_entity_ids', 'matter_id'].includes(key) &&
        !!currFilter
      ) {
      } else if (key === 'status' && !!currFilter) {
        newFilters[key] = `${key}=${currFilter
          .map(v => `${encodeURIComponent(v.value)}:${encodeURIComponent(v.label)}`)
          .join(`&${key}=`)}`
      } else if (key === 'template_update_available') {
        newFilters[key] = `${key}=${get(currFilter, 'value', '')}`
      }
    })
  }
  if (obj.other_attribute) {
    newFilters['other_attribute'] = `other_attribute=${obj['other_attribute']}`
  }
  if (obj.other_attribute_list_value) {
    newFilters[
      'other_attribute_list_value'
    ] = `other_attribute_list_value=${obj['other_attribute_list_value']}`
  }

  const paramStr = qs.stringify(newObj)
  const search = qs.stringify({ keyword: obj.search.keyword })
  const ordering = qs.stringify(obj.ordering)

  const optParam = p => (p ? `&${p}` : '')

  const {
    vendor_ids,
    matter_id,
    open_date,
    last_billed,
    matter_group_ids,
    matter_lead,
    legal_entity_ids,
    status,
    template_update_available,
    other_attribute,
    other_attribute_list_value
  } = newFilters

  const frag = `${paramStr}${optParam(search)}&${ordering}${optParam(vendor_ids)}${optParam(
    matter_id
  )}${optParam(open_date)}${optParam(last_billed)}${optParam(matter_group_ids)}${optParam(
    matter_lead
  )}${optParam(legal_entity_ids)}${optParam(status)}${optParam(
    template_update_available
  )}${optParam(other_attribute)}${optParam(other_attribute_list_value)}`

  return frag
}

export const toSummary = response => ({
  ...response,
  summary: { all: response.all, mine: response.mine }
})

export const toMatters = response => ({
  ...response,
  results: response.results.map(m => ({
    ...m,
    isSelectable: m.editable
  }))
})
