import { useContext } from 'react'
import { If, Alert, HoverAction, Button } from 'simple-core-ui'
import RuleActionsContainer from 'rules/action'
import OperandContainer from 'rules/operand'
import Select from 'react-select'
import operandStyles from 'rules/operand/styles/Operand.scss'
import { EngineContext } from 'rules/context'
import { isRuleComplete, isActionInvalid } from 'rules/utils'
import { DisabledTip } from 'rules/components'
import s from '../styles/Rule.scss'
import cloneDeep from 'lodash/cloneDeep'
import { titleCase } from 'title-case'
import { IoIosUndo, IoIosRedo } from 'react-icons/io'

const RuleFooter = ({ addAction, save, cancel, isSaving, canSave }) => {
  const { canEdit } = useContext(EngineContext)

  const submitRuleBtn = (
    <Button
      onClick={save}
      isPrimary
      style={{ marginRight: 0 }}
      isLoading={isSaving}
      isDisabled={!canSave}
    >
      Save Rule
    </Button>
  )

  return (
    <div className={s.bottomIcons + ' ' + s.footer}>
      <section>
        <Button onClick={cancel} isSecondary isDisabled={isSaving}>
          {canEdit ? 'Cancel' : 'Close'}
        </Button>
        <If condition={canEdit}>
          {canSave ? (
            submitRuleBtn
          ) : (
            <DisabledTip reason="The rule cannot be saved as incomplete.">
              {submitRuleBtn}
            </DisabledTip>
          )}
        </If>
      </section>
      {canEdit && addAction && (
        <Button onClick={addAction} isSecondary style={{ marginLeft: 0 }} isDisabled={isSaving}>
          Add Action
        </Button>
      )}
    </div>
  )
}

const coordToString = arr => {
  let str = `op_`
  arr.forEach(coord => {
    str += coord + '_'
  })

  return str.slice(0, -1)
}

const renderOperandTree = (
  opRef,
  condition,
  ruleIndex,
  focusedOp,
  focusOnOperand,
  currentLevel,
  currentCoords,
  changeBooleanOperator,
  canEdit
) => {
  const outerGutterWidth = 50

  const styles = {
    list: {
      paddingLeft: outerGutterWidth,
      cursor: 'pointer'
    },
    connector: {
      width: outerGutterWidth
    }
  }
  const options = condition.operands.map((op, i) => {
    let innerOperands
    const coordCopy = cloneDeep(currentCoords)
    coordCopy[currentLevel] = i
    let str = coordToString(coordCopy)
    opRef[str] = { condition, operand: op, coords: coordCopy }

    const innerGutterWidth = 40

    const innerStyles = {
      list: {
        paddingLeft: innerGutterWidth,
        cursor: 'default'
      },
      connector: {
        width: innerGutterWidth
      }
    }

    if (op.operands && op.operands.length >= 1) {
      innerOperands = renderOperandTree(
        opRef,
        op,
        ruleIndex,
        focusedOp,
        focusOnOperand,
        currentLevel + 1,
        coordCopy,
        changeBooleanOperator,
        canEdit
      )
    }

    if (op.lhs) {
      return (
        <ul key={`operand_${i}-${str}`} style={innerStyles.list} className={s.operandsList}>
          {currentLevel === 0 && condition.operands.length === 1 ? null : (
            <span className={s.connector} style={innerStyles.connector} />
          )}

          {i === condition.operands.length - 1 ? <div className={s.bottomConnectMask} /> : null}
          {i === 0 ? <div className={s.topConnectMask} /> : null}

          <OperandContainer ruleIndex={ruleIndex} index={i} refs={opRef} coords={str} />
        </ul>
      )
    }
    // return innerOperands
    return (
      <div
        key={`operand_${i}-${str}`}
        style={innerStyles.list}
        className={`${s.conditionContainer}`}
      >
        {i === condition.operands.length - 1 ? (
          <div className={s.bottomConnectConditionMask} />
        ) : null}
        {i === 0 ? <div className={s.topConnectMask} /> : null}

        {innerOperands}
      </div>
    )
  })

  const coordCopyCond = cloneDeep(currentCoords)
  coordCopyCond[currentLevel] = 0
  let condStr = coordToString(coordCopyCond)

  const [initialValue, selectableOptions] = [
    { value: condition.op, label: titleCase(condition.op.toLowerCase()) },
    [
      { label: 'And', value: 'AND' },
      { label: 'Or', value: 'OR' }
    ]
  ]

  return (
    <div className={s.innerConditionContainer}>
      {Object.keys(opRef).length > 1 && (
        <div className={s.booleanOperator}>
          {condStr !== 'op_0' && <span className={s.conditionConnector} style={styles.connector} />}
          <>
            <Select
              name="condition"
              value={initialValue}
              isClearable={false}
              onChange={option => changeBooleanOperator(option.value, opRef, condStr)}
              options={selectableOptions}
              isDisabled={!canEdit}
            />
            <span className={s.conditionConnector2} />
          </>
        </div>
      )}
      <div className={s.options}>{options}</div>
    </div>
  )
}

const Rule = ({
  condition,
  actions,
  save,
  cancel,
  focusedOp,
  focusOnOperand,
  ruleIndex,
  createRuleAction,
  changeBooleanOperator,
  removeRuleOperand,
  undo,
  redo,
  simplify,
  canUndo,
  canRedo,
  canSimplify,
  isSaving,
  isUnconditional
}) => {
  const { canEdit } = useContext(EngineContext)

  let coords = [0]
  let opRef = {}

  return (
    <section className={operandStyles.ruleMain}>
      <main className={operandStyles.ruleSection}>
        <header className={s.header}>
          <span>Conditions:</span>
          {!isUnconditional && (
            <section className="m-l-sp500" style={{ textTransform: 'none' }}>
              <HoverAction
                className="m-r-sp200"
                onClick={undo}
                tip={'Undo'}
                icon={<IoIosUndo />}
                isDisabled={!canUndo}
              />
              <HoverAction onClick={redo} tip={'Redo'} icon={<IoIosRedo />} isDisabled={!canRedo} />
              <HoverAction
                className="m-l-sp500"
                onClick={simplify}
                tip={'Clean Up Rule'}
                icon={<span className="simple-line-icon-refresh" />}
                isDisabled={!canSimplify}
              />
            </section>
          )}
        </header>
        <If
          condition={!isUnconditional}
          fallback={
            <Alert
              message="This rule is unconditional and will run under all circumstances."
              status="info"
              style={{ alignSelf: 'flex-start' }}
            />
          }
        >
          {() =>
            renderOperandTree(
              opRef,
              condition,
              ruleIndex,
              focusedOp,
              focusOnOperand,
              0,
              coords,
              changeBooleanOperator,
              canEdit
            )
          }
        </If>
      </main>
      <footer>
        <RuleActionsContainer ruleIndex={ruleIndex} createRuleAction={createRuleAction} />
        <RuleFooter
          save={save}
          addAction={createRuleAction}
          cancel={cancel}
          isSaving={isSaving}
          canSave={isRuleComplete(condition) && !isActionInvalid(actions)}
        />
      </footer>
    </section>
  )
}

export default Rule
