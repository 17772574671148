import { useParams } from 'react-router-dom'

import { LoadingSkeleton, NotFound, useLoading } from 'simple-core-ui'
import { IIWEditor } from './iiw'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { useEffect, useState } from 'react'
import { AiRule, Rule as ApiEditorRule } from 'simple_review/@types/api'
import { Rule } from 'simple_review/@types/editor'
import { useDispatch } from 'react-redux'
import { makeGetRequest } from 'utils/api'
import { RuleSerializer } from './iiw/serializers/rule.serializer'
import { getRootCondition } from './iiw/content/constants'
import { useAppTabs } from 'hooks'
import { getActiveRuleModules } from 'rules/utils'
import { RuleType } from 'simple_review/@types/common'

const activeRuleModules = getActiveRuleModules()

const SimpleReviewEditor = () => {
  const { state } = useSimpleReviewContext()
  const { availableFields, operators } = state

  const [rule, setRule] = useState<Rule | null>(null)

  const { id = 'new' } = useParams()
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()

  const fetchRule = async () => {
    try {
      const data: AiRule | ApiEditorRule = await withLoadingLocks(
        makeGetRequest(`/client/rules/${id}/`)
      )
      const serializedRule = RuleSerializer().fromJSON(
        data as ApiEditorRule,
        availableFields,
        operators
      )

      setRule(serializedRule)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  useEffect(() => {
    if (id === 'new') {
      setRule({
        name: '',
        text: '',
        description: '',
        category: null,
        isActive: false,
        condition: getRootCondition(),
        actions: []
      })
    } else if (availableFields.length && operators.length) {
      fetchRule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, availableFields, operators])

  useAppTabs({ tabs: [], selected: null, onClick: null })

  // @ts-expect-error
  if (!rule) return <LoadingSkeleton />

  if (
    (rule.type === RuleType.AI || rule.type === RuleType.AI_CONDITIONAL) &&
    !activeRuleModules.hasAI
  )
    return <NotFound />

  return <IIWEditor rule={rule as Rule} setRule={setRule} />
}

export default SimpleReviewEditor
