import { RequestFormRowContainer } from 'matters/requestForms/detail/Main/common/Rows'
import { LegalRequestFormListHeader } from 'matters/requestForms/detail/Main/common/Header'
import { Panel, Button } from 'simple-core-ui'
import s from '../RequestFormContent.scss'

const Content = ({ form, displayRemove, addNewItem, disabled }) => (
  <Panel title="Form Items">
    <div className={s.formContent}>
      <ul className={s.formItemsList} data-testid="request_form_items">
        <LegalRequestFormListHeader />
        {form.items.map((item, index) => {
          return (
            <RequestFormRowContainer
              key={`${item.model}-${index}`}
              attrIndex={index}
              displayRemove={displayRemove}
              disabled={disabled}
              attributes={form.attributes}
              chosenAttributes={form.chosenAttributes}
              item={form.items[index]}
            />
          )
        })}
      </ul>
      {!disabled && (
        <Button className={s.pullRight} onClick={addNewItem} testid="add_request_form_item">
          + Add Item
        </Button>
      )}
    </div>
  </Panel>
)

export default Content
