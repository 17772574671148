import { KeyValuePairs, TextInput, Tooltip } from 'simple-core-ui'
import { SearchableSelect } from 'containers'
import s from './FormHeader.scss'

const FormHeader = ({
  name,
  description,
  changeDescription,
  changeName,
  userOptions,
  userValue,
  onUsersChange
}) => (
  <div className={s.container}>
    <KeyValuePairs
      pairs={[
        {
          key: 'Form Name',
          value: (
            <TextInput
              type="text"
              value={name}
              containerClassName={s.nameInput}
              placeholder="Form Name"
              onChange={changeName}
              testid="template_name_input"
            />
          )
        },
        {
          key: 'Form Description',
          value: (
            <TextInput
              type="text"
              value={description}
              containerClassName={s.input}
              placeholder="Description"
              onChange={changeDescription}
            />
          )
        }
      ]}
      stackedKeys
    />
    <div className={s.usersLabel}>
      Notify Admins
      <Tooltip
        content="Selected admins will receive notifications for legal requests using this form."
        triggerClassName={s.tooltipTrigger}
      />
    </div>
    <div className={s.selectUsers}>
      <SearchableSelect
        options={userOptions}
        selectedOption={userValue}
        value={userValue}
        onChange={onUsersChange}
        placeholder="Select.. "
        openOnFocus
        isMulti
      />
    </div>
  </div>
)

export default FormHeader
