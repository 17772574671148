import { DraftingContext, PromptsHistoryAPI, PromptsHistoryItem } from './types'
import { parseISO, format } from 'date-fns'

export const serializeDraftingContext = (context: DraftingContext) => {
  const {
    text,
    practiceArea,
    documentType,
    writingStyle,
    tone,
    creativity,
    length,
    language
  } = context

  return {
    prompt: {
      drafting_context: text,
      practice_area: practiceArea,
      document_type: documentType,
      writing_style: writingStyle,
      tone,
      creativity,
      length,
      language
    }
  }
}

export const serializePromptsHistory = (response: PromptsHistoryAPI[]): PromptsHistoryItem[] => {
  return response.map(r => {
    const {
      id,
      drafting_context,
      practice_area,
      document_type,
      writing_style,
      tone,
      creativity,
      length,
      language,
      created_date
    } = r

    return {
      id,
      text: drafting_context,
      practiceArea: practice_area,
      documentType: document_type,
      writingStyle: writing_style,
      date: format(parseISO(created_date), 'MM/dd/yyyy'),
      tone,
      creativity,
      length,
      language
    }
  })
}
