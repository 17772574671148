import { useSelector } from 'react-redux'
import { currency2Num } from 'utils/formatting'
import { formatPercentage } from 'simple-core-ui/utils/formatting'
import s from '../styles/BudgetDetail.scss'
import { thermometerColors } from 'styles/cssInJs'
import Thermometer from 'budgets/Thermometer'
import SnapshotRow from '../components/SnapshotRow'
import BHPill from '../components/BHPill'
import { LoadingSkeleton } from 'simple-core-ui'

const BudgetHealth = () => {
  const {
    amount,
    remaining_budget,
    allocated_total,
    approved_total,
    pending_total,
    accrual_total,
    combined_total
  } = useSelector(({ budgets }) => budgets.budgetDetails)
  const { loading } = useSelector(({ app }) => app)
  const amountNum = currency2Num(amount)
  const allocatedNum = currency2Num(allocated_total)
  const pendingNum = currency2Num(pending_total)
  const approvedNum = currency2Num(approved_total)
  const accrualNum = currency2Num(accrual_total)
  const remainingNum = currency2Num(remaining_budget)
  const isInBudget = remainingNum >= 0

  const approvedPerc = (approvedNum / amountNum) * 100
  const pendingPerc = (pendingNum / amountNum) * 100
  const accrualPerc = (accrualNum / amountNum) * 100

  const remainingPerc = amountNum !== 0 ? 100 - approvedPerc - pendingPerc - accrualPerc : 0
  const colors = isInBudget ? thermometerColors['IN_BUDGET'] : thermometerColors['OVERBUDGET']
  const isLoading = loading.BUDGET_DETAILS_FETCH

  return (
    <section style={{ height: 500, padding: 20 }}>
      <header className={s.header}>
        <span data-testid="budget_amount_span">
          Budget Amount:{' '}
          {!isLoading ? (
            amount
          ) : (
            <LoadingSkeleton height={15} width={110} style={{ display: 'inline-block' }} />
          )}
        </span>
      </header>
      <main className={s.body}>
        <div className={s.thermContainer}>
          <div className={s.pillContainer}>
            <BHPill isInBudget={isInBudget} />
          </div>
          <Thermometer
            total={amount}
            approved={approved_total}
            combined={combined_total}
            pending={pending_total}
            accrual={accrual_total}
            disableDataTip
            disableFooter
          />
        </div>
        <SnapshotRow
          backgroundColor={colors.APPROVED}
          value={
            !isLoading ? (
              `${approved_total} (${formatPercentage(approvedPerc)})`
            ) : (
              <LoadingSkeleton height={15} width={110} style={{ display: 'inline-block' }} />
            )
          }
          label="Approved Invoices"
        />
        <SnapshotRow
          backgroundColor={colors.PENDING}
          value={
            !isLoading ? (
              `${pending_total} (${formatPercentage(pendingPerc)})`
            ) : (
              <LoadingSkeleton height={15} width={110} style={{ display: 'inline-block' }} />
            )
          }
          label="Pending Invoices"
        />
        <SnapshotRow
          backgroundColor={colors.ACCRUAL}
          value={
            !isLoading ? (
              `${accrual_total} (${formatPercentage(accrualPerc)})`
            ) : (
              <LoadingSkeleton height={15} width={110} style={{ display: 'inline-block' }} />
            )
          }
          label="Accruals"
        />
        <hr />
        <SnapshotRow
          backgroundColor={colors.REMAINING}
          value={
            !isLoading ? (
              `${remaining_budget} (${formatPercentage(remainingPerc)})`
            ) : (
              <LoadingSkeleton height={15} width={110} style={{ display: 'inline-block' }} />
            )
          }
          label="Remaining Budget"
        />
      </main>
    </section>
  )
}
export default BudgetHealth
