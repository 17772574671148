import { ReactElement } from 'react'
import cn from 'classnames'

import s from './DateTypeItem.scss'

interface Props {
  icon: ReactElement
  title: string
  description: string
  isSelected: boolean
  onClick: () => void
}

const DateTypeItem = ({ icon, title, description, isSelected, onClick }: Props) => {
  return (
    <div className={cn(s.container, { [s.selectedContainer]: isSelected })} onClick={onClick}>
      <div className={s.titleSection}>
        {icon}
        <div className={s.title}>{title}</div>
      </div>
      <div className={s.description}>{description}</div>
    </div>
  )
}

export default DateTypeItem
