import { useEffect } from 'react'
import { makeGetRequest } from 'utils/api'
import s from './EmailContent.scss'
import { KeyValuePairs, Input, useLoading } from 'simple-core-ui'
import { useDispatch } from 'react-redux'

const EmailContent = ({ contactToRemind, setSubject, setMessage, subject, message }) => {
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  useEffect(() => {
    const getSubject = async () => {
      try {
        const subject = await withLoadingLocks(
          makeGetRequest(
            `/manage/matters/evaluations/evaluation_email_subject/${contactToRemind.evaluation_id}/`
          )
        )
        setSubject(subject)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }

    const getMessage = async () => {
      try {
        const message = await withLoadingLocks(
          makeGetRequest(
            `/manage/matters/evaluations/evaluation_email_body/${contactToRemind.evaluation_id}/`
          )
        )
        setMessage(message)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }

    getSubject()
    getMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <KeyValuePairs
      pairs={[
        {
          key: 'To',
          keyClass: s.keyClass,
          valueClass: s.valueClass,
          value: <Input text={contactToRemind.email} isDisabled />
        },
        {
          key: 'Subject',
          keyClass: s.keyClass,
          valueClass: s.valueClass,
          value: (
            <Input
              placeholder="Subject"
              text={subject}
              onChangeCb={({ target }) => setSubject(target.value)}
            />
          )
        },
        {
          key: 'Message',
          keyClass: s.keyClass,
          valueClass: s.valueClass,
          value: (
            <Input
              placeholder="Message"
              text={message}
              type="textarea"
              cssClass={s.textarea}
              onChangeCb={({ target }) => setMessage(target.value)}
            />
          )
        }
      ]}
    />
  )
}

export default EmailContent
