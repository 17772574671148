export const MATTER_SELECT_URL = '/manage/matters/select2_json/'
export const COST_CODE_SELECT_URL = '/manage/costcodes/select2_json/'
export const VENDOR_SELECT_URL = '/invoices/vendors/select2_json'
export const RULE_SELECT_URL = '/client/rules/'
export const VENDOR_GROUP_SELECT_URL = '/invoices/v2/vendors/vendor_groups_select2_json'
export const LEGAL_ENTITY_URL = '/manage/matters/legal_entities_list_json'
export const PRACTICE_AREA_URL = '/manage/matter_groups/select2_json'
export const CONTACTS_SEARCH_URL = '/manage/contacts/search/'

export const DOC_CATEGORIES_URL = '/doc_management/native_docs/categories'
export const DOC_LABELS_URL = '/doc_management/native_docs/labels'

export const VENDOR_CONTACT_URL = '/invoices/vendors/contacts/select2_json'
export const PURCHASE_ORDER_URL = '/invoices/search_po'

export const TIMEKEEPER_URL = '/timekeepers/select2_json'
