import { Fragment } from 'react'
import s from './Row.scss'
import { SearchableSelect, InputContainer } from 'containers'
import get from 'lodash/get'

const Row = ({
  allocation,
  attributes,
  changeAllocation,
  allocationIndex,
  readOnly,
  getOptions,
  dropdownMenuPosition,
  maxMenuHeight
}) => (
  <Fragment>
    <div className={s.attribute}>
      <InputContainer
        id={`slm_template_allocation_input_${allocationIndex}`}
        testid="matter_template_allocation_input"
        value={get(allocation, 'columns[0].value', '')}
        className={s.inputNoBorders}
        type="number"
        min="0"
        disabled={readOnly}
        onChange={value =>
          changeAllocation({
            allocationIndex,
            attributeIndex: 0,
            value
          })
        }
      />
      <p className={s.name}>Percentage</p>
    </div>
    {attributes.map(attribute => {
      let hasAttributeInactiveValues = false
      let idToFind
      if (attribute.id) {
        idToFind = attribute.id
      } else {
        //using hardcoding as the names in the dicts are different between allocations and attributes
        if (attribute.allocation_type === 'legal_entities') {
          idToFind = 'legal_entity'
        } else if (attribute.allocation_type === 'cost_codes') {
          idToFind = 'cost_code'
        }
      }
      let allocationAttributeIndex = allocation.columns.findIndex(a => a.id === idToFind)
      let allocationColumn = allocation.columns[allocationAttributeIndex]
      const options = readOnly
        ? attribute.values
        : getOptions(allocation, attribute) || attribute.values
      const value = allocationColumn?.value
      if (value?.value) {
        hasAttributeInactiveValues = options.findIndex(a => a.value === value.value) < 0
      }
      return (
        <div className={s.attribute} key={allocationAttributeIndex}>
          {readOnly ? (
            <p className={s.readOnly}>{allocation && allocationColumn.value.label}</p>
          ) : (
            <SearchableSelect
              options={options}
              className={s.noBorders}
              selectedOption={allocationColumn.value}
              onChange={value =>
                changeAllocation({
                  allocationIndex,
                  attributeIndex: allocationAttributeIndex,
                  attributeId: allocationColumn.id,
                  value
                })
              }
              placeholder="Select..."
              isClearable={false}
              dropdownMenuPosition={dropdownMenuPosition}
              maxMenuHeight={maxMenuHeight}
            />
          )}
          <p className={s.name}>{attribute.short_name}</p>
          {hasAttributeInactiveValues && (
            <div className={s.warningFlag}>
              <span className="simple-line-icon-shield" />
              This attribute value is not active.
            </div>
          )}
        </div>
      )
    })}
  </Fragment>
)

export default Row
