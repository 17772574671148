import keyMirror from 'keymirror'

export default keyMirror({
  FETCH_VENDOR_EVAL_REQUESTED: null,
  FETCH_VENDOR_EVAL_SUCCESS: null,
  SUBMIT_VENDOR_EVAL_REQUESTED: null,
  SUBMIT_VENDOR_EVAL_SUCCESS: null,
  SAVE_VENDOR_EVAL_REQUESTED: null,
  SAVE_VENDOR_EVAL_SUCCESS: null,
  CHANGE_EVAL_ATTRIBUTE: null,
  RESET_EVAL_ANSWER: null,
  CHANGE_EVAL_ANSWER: null,
  VENDOR_EVAL_PERMISSION_DENIED: null
})
