import moment from 'moment'
import { DATE_FORMATS } from 'utils/constants'
import get from 'lodash/get'
const { DJANGO_DATETIME } = DATE_FORMATS

export const DateFilterSerializer = {
  toServer: obj => {
    if (!get(obj, 'value')) {
      return { min: '', max: '' }
    }

    return {
      min: obj.value.min ? moment(obj.value.min).format(DJANGO_DATETIME) : '',
      max: obj.value.max ? moment(obj.value.max).format(DJANGO_DATETIME) : ''
    }
  },

  toFragment: (key, currFilter) => {
    return `${key}_min=${get(currFilter, 'value.min', '')}&${key}_max=${get(
      currFilter,
      'value.max',
      ''
    )}`
  },

  fromFragment: (key, parsedHash) => {
    return {
      value: {
        min: parsedHash[`${key}_min`],
        max: parsedHash[`${key}_max`]
      }
    }
  }
}

export const MultiSelectFilterSerializer = {
  toServer: arr => {
    return arr.map(p => p.value)
  },

  toFragment: (key, currFilter) => {
    const value2String = v => {
      return `${encodeURIComponent(v.value)}::${encodeURIComponent(v.label)}`
    }

    return `${key}=${currFilter.map(value2String).join(`&${key}=`)}`
  },

  fromFragment: (key, parsedHash) => {
    return Array.isArray(parsedHash[key])
      ? parsedHash[key].map(p => ({
          value: Number(p.split('::')[0]),
          label: p.split('::')[1]
        }))
      : [SingleSelectFilterSerializer.fromFragment(key, parsedHash)]
  }
}

export const SingleSelectFilterSerializer = {
  toServer: v => {
    return v.value
  },

  toFragment: (key, currFilter) => {
    const value2String = v => {
      return `${encodeURIComponent(v.value)}::${encodeURIComponent(v.label)}`
    }

    return `${key}=${value2String(currFilter)}`
  },

  fromFragment: (key, parsedHash, toNumber = true) => {
    return {
      value: toNumber ? Number(parsedHash[key].split('::')[0]) : parsedHash[key].split('::')[0],
      label: parsedHash[key].split('::')[1]
    }
  }
}

export const AmountFilterSerializer = {
  toServer: obj => {
    return { ...obj, values: obj.values.map(Number) }
  },

  toFragment: (key, currFilter) => {
    return `${key}=${get(currFilter, 'operation', '')}:${get(currFilter, 'values', []).join(',')}`
  },

  fromFragment: (key, parsedHash) => {
    return {
      operation: parsedHash[key].split(':')[0],
      values: parsedHash[key].split(':')[1].split(',')
    }
  }
}

export const BulkMultiSelectFilterSerializer = {
  toServer: arr => {
    return arr.map(p => p.value)
  },

  toFragment: (key, currFilter) => {
    const value2String = v => {
      return `${encodeURIComponent(v.value)}::${encodeURIComponent(v.label)}::${encodeURIComponent(
        v.type || v.field_type
      )}`
    }

    return `${key}=${currFilter.map(value2String).join(`&${key}=`)}`
  },

  fromFragment: (key, parsedHash) => {
    return Array.isArray(parsedHash[key])
      ? parsedHash[key].map(p => ({
          value: Number(p.split('::')[0]),
          label: p.split('::')[1],
          field_type: p.split('::')[2]
        }))
      : [SingleSelectFilterSerializer.fromFragment(key, parsedHash)]
  }
}
