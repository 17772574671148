import { Button } from 'simple-core-ui'

import { AttrGroupDragDrop } from './AttrGroupDragDrop'
import { AttributeSection } from './AttributeSection'

import s from './AttributeCategory.scss'

const AttributeCategory = ({
  category,
  categoryLabel,
  changeAttributeValue,
  changeSectionTitle,
  addNewSection,
  removeSection,
  pinSection,
  removeAttribute
}) => (
  <div className={s.container}>
    {category.map((section, sectionIndex) => (
      <AttrGroupDragDrop
        key={sectionIndex}
        section={section}
        sectionIndex={sectionIndex}
        type={categoryLabel}
      >
        <AttributeSection
          key={sectionIndex}
          title={section.sectionName}
          changeSectionTitle={changeSectionTitle}
          removeSection={removeSection}
          pinSection={pinSection}
          sectionIndex={sectionIndex}
          removeAttribute={removeAttribute}
          changeAttributeValue={changeAttributeValue}
          attributes={section.attributes}
          category={categoryLabel}
          displayExpanded={section.displayExpanded}
          noAttributesText={'Drag attributes to this section'}
        />
      </AttrGroupDragDrop>
    ))}
    <AttrGroupDragDrop section={{}} sectionIndex={category.length} type={categoryLabel} />
    <div className={s.newSection}>
      <Button
        onClick={() => addNewSection(categoryLabel)}
        category={categoryLabel}
        isSecondary
        testid={`testid_add_template_section_button_${categoryLabel}`}
      >
        + Add Section
      </Button>
    </div>
  </div>
)

export default AttributeCategory
