import { getFuncPath } from 'simple_review/list/ConditionReadOnly/ConditionReadOnly'
import { getModelAttrField, getPath } from 'simple_review/list/ConditionReadOnly/helpers'
import { AvailableField } from 'simple_review/@types/api'
import { LhsArg } from 'simple_review/@types/list'
import { CanSerialize } from '../can-serialize'
import { FuncFieldModel, ModelFieldModel } from './model-field-model'

export class FuncFieldMapper implements CanSerialize {
  constructor(private _funcName: string, private _args: Array<LhsArg>) {}

  toJSON(): string {
    return JSON.stringify({
      func_name: this._funcName,
      args: this._args
    })
  }

  fromJSON(availableFields: Array<AvailableField>): FuncFieldModel {
    return {
      operandType: 'func',
      modelName: this._funcName,
      args: this._args,
      path: getFuncPath({ func_name: this._funcName, args: this._args }, availableFields)
    }
  }
}

export class ModelFieldMapper implements CanSerialize {
  constructor(private _modelName: string, private _fieldName: string, private _attrId?: string) {}

  toJSON(): string {
    return JSON.stringify({
      model_name: this._modelName,
      field_name: this._fieldName,
      operand_type: 'field',
      attr_id: this._attrId
    })
  }

  fromJSON(availableFields: Array<AvailableField>): ModelFieldModel {
    //modelfield combined data of LHS (dsl from server) and availableFields (fields with all info)
    const fullModelField = getModelAttrField(
      {
        operand_type: 'field',
        model_name: this._modelName,
        field_name: this._fieldName,
        attr_id: this._attrId || ''
      },
      availableFields
    )

    const { model, field, listAttr } = fullModelField

    return {
      operandType: 'field',
      modelName: model.name,
      fieldName: field.name,
      modelFieldType: field.type,
      fieldType: field.field_type,
      choices: field.choices,
      path: getPath(fullModelField),
      listAttrId: listAttr.attrId,
      listAttrDisplayName: listAttr.display_name
    }
  }
}
