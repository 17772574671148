import { EventType, APIEventType } from './types'
import { formatName } from 'utils/helpers'

export const toEventTypes = (eventTypes: APIEventType[]): EventType[] => {
  return eventTypes.map((type: APIEventType) => {
    const { id, name, related_events, is_active, edited_by, edited_date } = type

    return {
      id,
      name,
      relatedEvents: String(related_events),
      lastEdited: edited_date ?? null,
      editedBy: formatName(edited_by) || null,
      isActive: is_active
    }
  })
}
