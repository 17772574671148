import { useState, useEffect, useMemo } from 'react'
import { DataTableWrapper, useLoading, ModalContainer } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import update from 'immutability-helper'
import { useDispatch } from 'react-redux'
import { getNormalizedCellContent } from './utils'
import moment from 'moment'
import { FaEnvelope } from 'react-icons/fa'
import { EmailContent } from './EmailContent'
import APP_ACT from 'app/actions'

const initialParams = {
  pageSize: 10,
  ordering: { columnKey: 'due_date', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const pendingSurveysList = {
  results: [],
  filtered: 0,
  total: 0,
  page: 1
}

function SurveyPendingTab({ scopeId, readOnly }) {
  const [params, setParams] = useState(initialParams)
  const [pendingSurveys, setPendingSurveys] = useState(pendingSurveysList)
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [contactToRemind, setContactToRemind] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const { pending_surveys } = await withLoadingLocks(
          makeGetRequest(`/manage/matters/pending_vendor_surveys/${scopeId}/`)
        )
        const newState = update(pendingSurveys, {
          results: { $set: pending_surveys }
        })
        setPendingSurveys(newState)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const results = useMemo(() => {
    const { results, total } = pendingSurveys
    const columns = [
      {
        columnKey: 'due_date',
        content: 'Due By',
        isSortable: true,
        render: cell => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'vendor',
        content: 'Vendor',
        isSortable: true,
        isFilterable: true,
        style: {
          width: '400px'
        }
      },
      {
        columnKey: 'evaluator',
        content: 'Evaluator',
        isSortable: true,
        isFilterable: true,
        style: {
          width: '250px'
        }
      },
      {
        columnKey: 'role',
        content: 'Role(s)',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'response_rate',
        content: 'Response Rate',
        isSortable: true,
        render: (cell, row) => {
          const content = cell.content
          return isNaN(content) ? content : `${content}%`
        },
        style: {
          textAlign: 'center'
        }
      },
      {
        columnKey: 'last_sent',
        content: 'Last Notify',
        isSortable: true,
        render: cell => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'next_reminder',
        content: 'Next Reminder',
        isSortable: true,
        render: cell => {
          return moment(cell.content).format('M/D/YYYY')
        }
      }
    ]
    return {
      columns,
      rows: results,
      totalEntries: total,
      filteredTotal: total
    }
  }, [pendingSurveys])

  const updateTable = updatedParams => {
    setParams(updatedParams)
  }

  const resetReminderModal = () => {
    setContactToRemind(null)
    setSubject('')
    setMessage('')
  }

  const updateEvaluation = (last_sent, next_reminder, id) => {
    const index = pendingSurveys.results.findIndex(s => +s.evaluation_id === +id)
    const newState = update(pendingSurveys, {
      results: {
        [index]: {
          last_sent: { $set: moment(last_sent).format('M/D/YYYY') },
          next_reminder: { $set: moment(next_reminder).format('M/D/YYYY') }
        }
      }
    })
    setPendingSurveys(newState)
  }

  const sendReminder = async () => {
    try {
      const { evaluation_id, email } = contactToRemind
      const { last_sent, next_reminder } = await withLoadingLocks(
        makePostRequest(`/manage/matters/evaluations/send_evaluation_reminder_json/`, {
          evaluation_id: evaluation_id,
          matter_id: scopeId,
          message_to: email,
          message_subject: subject,
          message_body: message
        })
      )
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: `Evaluation reminder sent successfully to ${email}`,
          level: 'success'
        }
      })
      updateEvaluation(last_sent, next_reminder, evaluation_id)
      resetReminderModal()
    } catch (error) {
      resetReminderModal()
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  return (
    <>
      <DataTableWrapper
        params={params}
        customSearchOptions={{
          styles: { width: 270, fontStyle: 'italic' }
        }}
        categories={[]}
        rows={results.rows}
        columns={results.columns}
        totalEntries={results.totalEntries}
        filteredTotal={results.filteredTotal}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasTooltip
        getNormalizedCellContent={getNormalizedCellContent}
        actions={
          !readOnly
            ? [
                row => ({
                  icon: FaEnvelope,
                  tooltip: 'Remind',
                  onClick: (event, row) => setContactToRemind(row)
                })
              ]
            : null
        }
      />
      {contactToRemind && (
        <ModalContainer
          title="Send Reminder"
          size="md"
          confirmText="Send"
          cancelText="Close"
          confirmCb={() => {
            sendReminder()
          }}
          cancelCb={() => setContactToRemind(null)}
          content={
            <EmailContent
              subject={subject}
              setSubject={setSubject}
              message={message}
              setMessage={setMessage}
              contactToRemind={contactToRemind}
            />
          }
        />
      )}
    </>
  )
}

export default SurveyPendingTab
