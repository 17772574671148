import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import findIndex from 'lodash/findIndex'
import update from 'immutability-helper'
import get from 'lodash/get'

export const initialState = {
  list: [],
  timezone: get(window.credentials, 'user.clientTimezone'),
  scopeId: window.serverContext.get('scope_id')
}

const eventsReducer = createReducer(initialState, {
  [ACT.EVENTS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      list: action.payload.events
    }
  },
  [ACT.EVENT_DELETE_SUCCESS](state, action) {
    const { id } = action.payload

    const index = findIndex(state.list, event => event.id === id)

    return update(state, {
      list: {
        $splice: [[index, 1]]
      }
    })
  },
  [ACT.EVENT_UPDATE_SUCCESS](state, action) {
    const { event } = action.payload

    const index = findIndex(state.list, e => e.id === event.id)

    return update(state, {
      list: {
        [index]: {
          $set: event
        }
      }
    })
  },
  [ACT.CALENDAR_LIST_CREATE_EVENT_SUCCESS](state, action) {
    const { event } = action.payload

    return update(state, {
      list: {
        $push: [event]
      }
    })
  }
})

export default eventsReducer
