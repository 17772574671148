import { useNavigate } from 'react-router-dom'
import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  rowId: string
  cloneRule(): void
  deleteRule(): void
}

const ActionsPopover = ({ rowId, deleteRule, cloneRule }: Props) => {
  const navigate = useNavigate()

  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        navigate(`/v2/rules/simplereview/${rowId}`)
      }
    },
    {
      name: 'CLONE',
      text: 'Clone',
      onClick() {
        cloneRule()
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteRule()
      }
    }
  ]

  return (
    <Popover
      trigger={<HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />}
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
