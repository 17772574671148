import { useState } from 'react'
import s from './SearchFilter.scss'
import { withConfirmation } from 'simple-core-ui'
import { DEFAULT_CONF } from './components/BaseFilter'

const FiltersContainer = ({ filterParams, onSearch, hasConfirmation, render, onClear }) => {
  const [reset, setReset] = useState(0)

  const filterSearch = (filterType, itemValue) => {
    const newFilterParmas = {
      ...filterParams,
      [filterType]: itemValue
    }
    onSearch &&
      onSearch({
        filterParams: newFilterParmas
      })
  }

  const _clearAll = () => {
    setReset(reset => reset + 1)
    if (onClear) {
      onClear()
      return
    }
    onSearch && onSearch({ filterParams: {} })
  }

  const clearAll = hasConfirmation ? withConfirmation(_clearAll, DEFAULT_CONF) : _clearAll

  return (
    <section className={s.filterContainer}>
      {render(reset, filterSearch)}
      <div data-testid="clear_all" className={s.clear} onClick={clearAll}>
        Reset
      </div>
    </section>
  )
}

export default FiltersContainer
