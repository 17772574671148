import moment from 'moment'
import get from 'lodash/get'

export const dateRangeLabels = array => {
  if (array.dateRange.start !== null || array.dateRange.end !== null) {
    return `${
      array.dateRange.start !== null ? moment(array.dateRange.start).format('DD/MM/YYYY') : 'N/A'
    } - ${array.dateRange.end !== null ? moment(array.dateRange.end).format('DD/MM/YYYY') : 'N/A'}`
  }

  return 'All'
}

export const limitCommentCharacters = comment => {
  const charLimit = 600
  return comment.length > charLimit ? comment.substring(0, charLimit - 3) + '...' : comment
}

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'score':
      return Number(cellContent)
    case 'date':
      return moment(cellContent.split('-')[0], 'DD/MM/YYYY').unix()
    case 'matter':
      return cellContent.toLowerCase()
    case 'practice_area':
      return cellContent.toLowerCase()
    case 'evaluator':
      return cellContent.toLowerCase()
    case 'role':
      return cellContent.toLowerCase()
    case 'comment':
      return cellContent.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const showComparison = (comparisons, compareQuestions) => {
  return (
    (comparisons.dateRange.start !== null ||
      comparisons.dateRange.end !== null ||
      comparisons.vendor !== null ||
      comparisons.practiceArea !== null) &&
    compareQuestions.length > 0
  )
}

export const emptyComparison = comparisons => {
  return (
    comparisons.against !== null &&
    comparisons.dateRange.start === null &&
    comparisons.dateRange.end === null &&
    comparisons.vendor === null &&
    comparisons.practiceArea === null
  )
}
