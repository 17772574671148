import { fieldType } from '../constant-model'
import { ListConstantMapper } from './list-constant-mapper'
import { ConstantMapper } from './constant-mapper'
import { RhsTypes } from 'simple_review/@types/editor'
import { ListConstant, NonListConstant } from './constant-model'
import { ModelFieldModel } from '../model-field/model-field-model'

export class NumericListConstantMapper extends ListConstantMapper {
  type: fieldType = 'number'

  fromJSON(): ListConstant {
    return {
      type: this.type,
      isList: true,
      values: this._constant,
      label: this._constant.map(({ label }) => label).join(', '),
      subType: '',
      rhsInputType: RhsTypes.Creatable
    }
  }
}

export class NumericConstantMapper extends ConstantMapper {
  type: fieldType = 'number'

  fromJSON(fullLhs: ModelFieldModel): NonListConstant {
    const rhsInputType =
      fullLhs.modelName === 'invoice_discount' ? RhsTypes.Slider : RhsTypes.Numeric

    const isCustomAttrEnabled =
      fullLhs.modelName === 'invoice_discount' ||
      (fullLhs.modelName === 'Invoice' && fullLhs.fieldName === 'invoice_total') ||
      (fullLhs.modelName === 'matter_vendor_task_code' && fullLhs.fieldName === 'spend')

    return {
      type: this.type,
      isList: false,
      value: this._constant?.value,
      label: this._constant?.label,
      subType: this._subType,
      isCustomAttrEnabled,
      rhsInputType
    }
  }
}
