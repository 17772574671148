import { useRef, useState } from 'react'
import Select, { components, Props as SelectProps } from 'react-select'
import { ScrollbarWrapper } from 'simple_review/common/ScrollbarWrapper'
import footerScss from 'simple_review/editor/iiw/footer/EditorFooter.scss'
import globalScss from 'styles/_variables.scss'

interface Props extends SelectProps {
  maxHeight?: number
}

const DEFAULT_HEIGHT = 200
const editorFooterAndNavHeight =
  parseInt(footerScss.footerHeight.replace(/\D/g, '')) +
  parseInt(globalScss.footerHeight.replace(/\D/g, ''))

export default function AutoFlipSelect({ maxHeight = DEFAULT_HEIGHT, ...props }: Props) {
  const [isOverlapping, setIsOverlapping] = useState(false)

  const selectRef = useRef<HTMLDivElement | null>(null)

  const recalculateMenu = () => {
    // To keep 16px gap
    // 32 = 16px gap + 16px spacing used inside select
    const windowHeight = window.innerHeight - (editorFooterAndNavHeight + 32)
    setIsOverlapping(
      windowHeight - (selectRef.current?.getBoundingClientRect().top || 0) < maxHeight
    )
  }

  return (
    <Select
      {...props}
      styles={{
        ...props.styles,
        menu(base) {
          return {
            ...base,
            ...(isOverlapping
              ? {
                  top: 'auto',
                  bottom: '100%'
                }
              : {})
          }
        }
      }}
      components={{
        MenuList: ScrollbarWrapper,
        Control(props) {
          return <components.Control {...props} innerRef={selectRef} />
        }
      }}
      onMenuOpen={recalculateMenu}
      closeMenuOnScroll={() => {
        recalculateMenu()
        return false
      }}
      minMenuHeight={maxHeight}
      maxMenuHeight={maxHeight}
    />
  )
}
