import { FaPlus, FaRandom } from 'react-icons/fa'
import cn from 'classnames'
import { LogicalOperator } from 'simple_review/@types/common'
import s from './Op.scss'

interface Props {
  value: LogicalOperator
  isReadOnly?: boolean
  isLeaf?: boolean
  onChangeOp(newOp: LogicalOperator): void
  onAddConditionBtwn?(): void
}

export const Op = ({
  value,
  isReadOnly = false,
  isLeaf = false,
  onChangeOp,
  onAddConditionBtwn
}: Props) => {
  return isLeaf || isReadOnly ? (
    <div className={s.container}>
      <div className={cn(s.box, !isLeaf && s.group)}>
        <button
          className={cn(s.forceValueButton, s[value.toLowerCase()])}
          onClick={() => {
            onChangeOp(value === 'AND' ? 'OR' : 'AND')
          }}
          disabled={isReadOnly}
        >
          <div className={cn(s.forceValueContent, value === 'AND' && s.bottom)}>
            <span>OR</span>
            <span>AND</span>
          </div>
          {!isReadOnly && <FaRandom className={s.icon} />}
        </button>
      </div>
    </div>
  ) : (
    <div className={cn(s.container, !isLeaf && s.groupCond)}>
      <div className={cn(s.box, !isLeaf && s.group)}>
        <div className={cn(s.pill, value === 'OR' && s.left, value === 'AND' && s.blue)} />
        <div className={s.buttons}>
          <button
            className={cn(s.button, value === 'OR' && s.selected)}
            onClick={() => onChangeOp('OR')}
          >
            OR
          </button>
          <button
            className={cn(s.button, value === 'AND' && s.selected)}
            onClick={() => onChangeOp('AND')}
          >
            AND
          </button>
        </div>
      </div>
      <div className={s.addCondition} onClick={() => onAddConditionBtwn && onAddConditionBtwn()}>
        <FaPlus size={12} /> Add Condition
      </div>
    </div>
  )
}
