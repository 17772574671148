import get from 'lodash/get'
import moment from 'moment'

interface Cell {
  columnKey: string
  content: string
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
    case 'color':
    case 'editedBy':
    case 'status':
      return cellContent.toLowerCase()
    case 'phase':
    case 'complexity':
    case 'strategicValue':
    case 'relatedTasks':
    case 'relatedTemplates':
      return +cellContent
    case 'lastEdited':
      return moment(cellContent).valueOf()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
