import cn from 'classnames'
import { FaTrash } from 'react-icons/fa'
import s from './Templates.scss'

const Template = ({ template, templateIndex, removeTemplate, disabled }) => (
  <li className={s.templateRow}>
    <div className={cn(s.templateItem, s.templateName)}>{template.name}</div>
    <div className={cn(s.templateItem, s.templateDescription)}>{template.description}</div>
    {!disabled && (
      <div className={cn(s.templateItem, s.remove)}>
        <FaTrash className={s.trashIcon} onClick={() => removeTemplate(templateIndex)} />
      </div>
    )}
  </li>
)

export default Template
