import get from 'lodash/get'

export const getLabel = (values, text) => {
  if (!values) {
    return null
  } else if (values.length === 1) {
    return values[0].label
  } else {
    return `${values.length} ${text}`
  }
}

export const getValue = (values, initialValues) => {
  return values || values === null ? values : get(initialValues, 'value')
}

export const getSelection = (values, text) => {
  return get(values, 'length')
    ? {
        label: getLabel(values, text),
        value: values
      }
    : null
}

export const getCustomSelectLabel = (selected, title) => {
  if (!selected || !selected.length) return title
  if (selected.length === 1) {
    return selected[0].label
  }
  return `${title} (${selected.length})`
}

export const formatMultiSelectLabel = (isMulti, title, noPlural) => {
  return isMulti
    ? array => {
        if (!array) return title
        if (array.length > 1) return `${array.length} ${title}${noPlural ? '' : 's'}`
        return array[0].label.length > 12 ? array[0].label.substring(0, 12) + '...' : array[0].label
      }
    : null
}

export const isOutOfViewport = elem => {
  // Get element's bounding
  var bounding = elem.getBoundingClientRect()

  // Check if it's out of the viewport on each side
  var out = {}
  out.top = bounding.top < 0
  out.left = bounding.left < 0
  out.leftOffset = out.left ? 0 - bounding.left : 0
  out.bottom = bounding.bottom > document.documentElement.clientHeight
  out.right = bounding.right > document.documentElement.clientWidth
  out.rightOffset = out.right ? bounding.right - document.documentElement.clientWidth : 0
  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right

  return out
}
