import { useMemo } from 'react'

import { Tooltip, CleanFileIcon, ScanningFileIcon, InfectedFileIcon } from 'simple-core-ui'
import { FILE_STATUS_SCANNING, FILE_STATUS_CLEAN, FILE_STATUS_INFECTED } from '../constants'

const FileStatusIcon = ({ file }) => {
  const info = useMemo(() => {
    const map = {
      [FILE_STATUS_SCANNING]: {
        status: <ScanningFileIcon />,
        tip: 'File is still being scanned for malware.'
      },
      [FILE_STATUS_CLEAN]: {
        status: <CleanFileIcon />,
        tip: 'No malware detected.'
      },
      [FILE_STATUS_INFECTED]: {
        status: <InfectedFileIcon />,
        tip: 'Malware was detected.'
      }
    }

    return map[file.fileStatus]
  }, [file.fileStatus])

  if (!info) return null

  return <Tooltip trigger={info.status} content={info.tip} border />
}

export default FileStatusIcon
