import { TemplateAttribute } from './TemplateAttribute'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import { AttrDragDrop } from './AttrDragDrop'
import { If, TextInput } from 'simple-core-ui'
import s from './AttributeSection.scss'
import { FaTrash } from 'react-icons/fa'
import { isDynamicDropdownDisabled } from 'matters/templates/utils'

const AttributeSection = ({
  title,
  changeSectionTitle,
  sectionIndex,
  category,
  displayExpanded,
  attributes,
  noAttributesText,
  changeAttributeValue,
  removeAttribute,
  removeSection,
  pinSection,
  isOver
}) => (
  <div className={`${s.container} ${isOver ? s.dragOver : null}`}>
    <div className={s.header}>
      <TextInput
        type="text"
        className={s.panelTitleInput}
        placeholder="Add Section Title"
        value={title}
        onChange={value => changeSectionTitle(value, category, sectionIndex)}
        index={sectionIndex}
        category={category}
      />
      <If condition={!attributes.length}>
        <div className={s.remove}>
          <FaTrash className={s.trashIcon} onClick={() => removeSection(category, sectionIndex)} />
        </div>
      </If>
      <div
        className={`${s.pinSection} ${!displayExpanded && s.pinSectionVisible}`}
        title="Collapse section by default"
      >
        <div
          className="simple-line-icon-size-actual"
          onClick={() => pinSection(category, sectionIndex, !displayExpanded)}
        />
      </div>
    </div>
    <div className={s.content}>
      {attributes &&
        attributes.map((attribute, index) => (
          <AttrDragDrop
            key={attribute.id}
            attribute={attribute}
            attrIndex={index}
            sectionIndex={sectionIndex}
            type={category}
          >
            {attribute.type === 'relationship' ? (
              <DynamicAttribute>
                {attribute.attributes.map((attr, relIndex) => (
                  <TemplateAttribute
                    key={attr.id}
                    attribute={attr}
                    readOnly={attr.readOnly}
                    isRelationship
                    required={attr.required}
                    isRequiredDisabled={
                      attribute.attributes[relIndex - 1] &&
                      !attribute.attributes[relIndex - 1].required
                    }
                    isDisabled={isDynamicDropdownDisabled(attribute, relIndex)}
                    attrIndex={index}
                    removeAttribute={removeAttribute}
                    sectionIndex={sectionIndex}
                    category={category}
                    relationshipIndex={relIndex}
                    relationshipId={attribute.id}
                    changeAttributeValue={changeAttributeValue}
                  />
                ))}
              </DynamicAttribute>
            ) : (
              <TemplateAttribute
                attribute={attribute}
                readOnly={attribute.readOnly}
                required={attribute.required}
                attrIndex={index}
                removeAttribute={removeAttribute}
                sectionIndex={sectionIndex}
                category={category}
                changeAttributeValue={changeAttributeValue}
              />
            )}
          </AttrDragDrop>
        ))}
      {!attributes.length && <p className={s.noAttributesText}>{noAttributesText}</p>}
      <AttrDragDrop
        key={attributes.length}
        attribute={{}}
        attrIndex={attributes.length}
        sectionIndex={sectionIndex}
        type={category}
      />
    </div>
  </div>
)

export default AttributeSection
