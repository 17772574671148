import { Component } from 'react'
import { connect } from 'react-redux'
import { Panel, Table, DataTableSerializer, renderWithLineBreaks, TextInput } from 'simple-core-ui'
import s from '../styles/BudgetRequest.scss'
import Thermometer from 'budgets/Thermometer'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { getAmountsColumns, getNoteRows, withTotalRow } from '../tableDefinitions'
import ACT from 'budgets/actions'
import cn from 'classnames'
import { BUDGET_STATES as BS } from 'budgets/constants'

const NOTE_PLACEHOLDER =
  'If you have any comments/concerns about this section, let your vendor know here.'

@connect(({ budgets }) => {
  let details = budgets.budgetDetails
  let compareDetails = budgets.compareDetails

  const rawDetails = JSON.parse(JSON.stringify(details))
  const status = budgets.status
  const columns = getAmountsColumns(rawDetails.type)
  const rows = withTotalRow(rawDetails)

  const table = {
    rows
  }

  const budgetTable = DataTableSerializer.getRowsByCategory(table, columns)

  if ([BS.REJECTED, BS.PROPOSED].includes(status)) {
    budgetTable.rows = getNoteRows(budgetTable.rows)
  }

  return {
    rawDetails,
    compareDetails,
    columns,
    budgetTable,
    status,
    vendorName: rawDetails.vendor_name
  }
})
class BudgetAmountsContainer extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: ACT.BUDGET_DETAILS_FETCH_REQUESTED,
      loadingLock: 'on'
    })
    this.props.dispatch({
      type: ACT.BUDGET_COMPARE_FETCH_REQUESTED,
      loadingLock: 'on'
    })
  }
  updateNotes = async (rowId, value) => {
    await this.props.dispatch({
      type: ACT.UPDATE_ROW_NOTES,
      payload: {
        rowId,
        value
      }
    })

    this.updateBudgetAmounts()
  }

  updateBudgetAmounts = () => {
    const { rawDetails } = this.props

    if (!rawDetails.id) return

    this.props.dispatch({
      type: ACT.UPDATE_BUDGET_AMOUNTS_REQUESTED,
      payload: {
        details: rawDetails
      }
    })
  }

  renderThermometerCell = (rowId, content) => {
    const { rawDetails } = this.props
    const row = rowId === 'total' ? rawDetails : find(rawDetails.rows, r => r.id === rowId)

    if (!row) {
      return content
    }

    return (
      <div>
        <Thermometer
          total={row.allocated_total}
          approved={row.approved_total}
          combined={row.combined_total}
          pending={row.pending_total}
          accrual={row.accrual_total}
        />
      </div>
    )
  }

  renderComparison = (rowId, content) => {
    const { compareDetails, rawDetails } = this.props

    const rowIndex = findIndex(rawDetails.rows, r => r.id === rowId)
    const compareRow = rowId === 'total' ? compareDetails : compareDetails?.rows[rowIndex]
    const row = rowId === 'total' ? rawDetails : rawDetails.rows[rowIndex]

    if (compareRow && compareRow.allocated_total !== row.allocated_total) {
      return (
        <div>
          <span className={s.highlight}>{row.allocated_total}</span>
          <div className={s.previously}>Previously {compareRow.allocated_total}</div>
        </div>
      )
    }

    return content
  }

  renderNote = rowId => {
    const { rawDetails, budgetTable, status, vendorName } = this.props

    const realRowIndex = findIndex(budgetTable.rows, r => r.id === rowId) - 1

    if (realRowIndex < 0) {
      return
    }

    const realRowId = budgetTable.rows[realRowIndex].id
    const realRow = find(rawDetails.rows, r => r.id === realRowId)
    const isDisabled = status === BS.REJECTED
    const placeholder = !isDisabled ? NOTE_PLACEHOLDER : ''

    return (
      <div>
        <label>Comment for {vendorName}</label>
        {
          <TextInput
            testid={`${realRowIndex}_budget_note_input`}
            value={realRow ? realRow.note : ''}
            isDisabled={isDisabled}
            onChange={value => this.updateNotes(realRowId, value)}
            placeholder={placeholder}
            type="textarea"
          />
        }

        {realRow?.vendor_note ? (
          <div className={s.vendorNote} data-testid={`${realRowIndex}_vendor_note`}>
            <label>
              <span className={s.vendorNoteLabel}>Response From {vendorName}</span>
            </label>
            {renderWithLineBreaks(realRow.vendor_note)}
          </div>
        ) : null}
      </div>
    )
  }

  renderAccrual = (rowId, content) => {
    const { rawDetails } = this.props
    const row = find(rawDetails.rows, b => b.id === rowId)

    if (!row) {
      return content
    }

    return (
      <div>
        <div>{content}</div> <div className={s.accrualCell}>{row.accrual_total}</div>
      </div>
    )
  }

  renderCell = ({ rowId, columnKey, content }) => {
    if (columnKey === 'combined_percentage' && !rowId.toString().includes('note')) {
      return this.renderThermometerCell(rowId, content)
    } else if (
      columnKey === 'allocated_total' &&
      this.props.status === 'Proposed' &&
      this.props.compareDetails &&
      !rowId.toString().includes('note')
    ) {
      return this.renderComparison(rowId, content)
    } else if (
      columnKey === 'approved_total' &&
      typeof rowId === 'string' &&
      rowId.includes('note')
    ) {
      return this.renderNote(rowId)
    } else if (columnKey === 'pending_total') {
      return this.renderAccrual(rowId, content)
    }

    return content
  }

  render() {
    const { budgetTable, columns, status } = this.props

    return (
      <Panel title="Budget Details" styles={{ marginBottom: 48 }}>
        <div
          className={cn(s.tableContainer, {
            [s.notesTable]: [BS.REJECTED, BS.PROPOSED].includes(status)
          })}
        >
          <Table rows={budgetTable.rows} columns={columns} renderCell={this.renderCell} />
        </div>
      </Panel>
    )
  }
}

export default BudgetAmountsContainer
