import { useState, useEffect } from 'react'
import s from './Allocations.scss'
import { Button, ModalContainer, useLoading, Table } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  toAllocationList,
  toAllocations,
  fromAllocations,
  toDefaultAllocations
} from './serializers'
import { AllocationsModal } from './AllocationsModal'
import { useImmer } from 'use-immer'
import currency from 'currency.js'

const columns = [
  { columnKey: 'attribute', content: 'Attribute', isSortable: false, isFilterable: false },
  { columnKey: 'value', content: 'Value', isSortable: false, isFilterable: false },
  { columnKey: 'percentage', content: 'Percentage', isSortable: false, isFilterable: false }
]

const AllocationsContainer = ({ scopeId, readOnly, isTest }) => {
  const [isAllocationModalVisible, setIsAllocationModalVisible] = useState(false)
  const [isLoading, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [allocationList, setAllocationList] = useState([])
  const [percentageAlert, setPercentageAlert] = useState(false)
  const [allocations, setAllocations] = useImmer([])
  const [initialAllocations, setInitialAllocations] = useImmer([])
  const { costcode_management, legal_entity_management } = useSelector(
    state => state.app.credentials.custom.names
  )
  const [limitAllocations, setLimitAllocations] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const {
          allocations,
          sorted_allocation_attributes,
          entity_filtered_attributes,
          limit_allocations
        } = await withLoadingLocks(makeGetRequest(`/manage/matters/get_allocations/${scopeId}/`))

        const defaultAllocations = toDefaultAllocations(allocations, sorted_allocation_attributes)

        setAllocationList(
          toAllocationList(defaultAllocations, sorted_allocation_attributes, {
            costcode_management,
            legal_entity_management
          })
        )
        const allocs = toAllocations(
          defaultAllocations,
          sorted_allocation_attributes,
          {
            costcode_management,
            legal_entity_management
          },
          entity_filtered_attributes
        )
        setAllocations(() => allocs)
        setInitialAllocations(() => allocs)
        setLimitAllocations(limit_allocations)
      } catch (error) {
        if (error.response.status === 403) {
          return
        }
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetModal = () => {
    setIsAllocationModalVisible(false)
    setPercentageAlert(false)
  }

  const saveAllocations = async () => {
    const total = allocations.reduce((acc, allocation) => {
      return currency(allocation.percentage).add(acc)
    }, 0)
    if (total.value === 100) {
      try {
        const response = await withLoadingLocks(
          makePostRequest(
            `/manage/matters/allocations/${scopeId}/update/`,
            fromAllocations(allocations)
          )
        )
        setAllocationList(
          toAllocationList(response.allocations, response.sorted_allocation_attributes, {
            costcode_management,
            legal_entity_management
          })
        )
        resetModal()
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error
        })

        resetModal()
      }
    } else {
      setPercentageAlert(true)
    }
  }

  const onChangeLegalEntity = async (id, index) => {
    try {
      const { results } = await withLoadingLocks(
        makeGetRequest(`/manage/matters/entity_allocations/${id || 0}/`)
      )
      setAllocations(draft => {
        const attributeIds = results.map(r => r.attribute_id)
        draft[index].attributes.forEach(attribute => {
          if (attribute.type === 'attribute') {
            if (attributeIds.includes(attribute.id)) {
              const newOptions = results.find(r => r.attribute_id === attribute.id).options
              attribute.options = newOptions.map(o => ({
                value: o.id,
                label: o.value
              }))
            } else {
              attribute.options = []
            }
            attribute.value = null
          }
        })
      })
    } catch (error) {
      if (error.response.status === 403) {
        return
      }
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  return (
    <>
      <div className={s.wrapper}>
        {!readOnly && (
          <Button className={s.editBtn} isPrimary onClick={() => setIsAllocationModalVisible(true)}>
            Edit
          </Button>
        )}
        <h3 className={s.title}>Allocations</h3>
        <Table rows={allocationList} columns={columns} />
      </div>
      {isAllocationModalVisible && (
        <ModalContainer
          title={'Allocate'}
          cancelText="Cancel"
          size="fs"
          cancelCb={() => {
            setIsAllocationModalVisible(false)
            setPercentageAlert(false)
            setAllocations(() => initialAllocations)
          }}
          confirmText={isLoading ? 'Saving...' : 'Save'}
          confirmCb={isLoading ? () => {} : saveAllocations}
          shouldModalClose={false}
          content={
            <AllocationsModal
              percentageAlert={percentageAlert}
              allocations={allocations}
              setAllocations={setAllocations}
              onChangeLegalEntity={onChangeLegalEntity}
              isPortal={!isTest}
              limitAllocations={limitAllocations}
            />
          }
        />
      )}
    </>
  )
}

export default AllocationsContainer
