import { HelpText } from 'simple-core-ui'

import s from './PriorityTip.scss'

const PriorityTip = ({ position, hasUnderline, children }) => (
  <HelpText
    message={
      <section className={s.container}>
        <p>
          <strong>Ladder priority</strong>
          {
            "is used to determine where a rule's reviewers will be inserted into the review process."
          }
        </p>
        <p>
          {
            "A higher priority will place a rule's reviewers further down the ladder and thus later in the review process."
          }
        </p>
      </section>
    }
    position={position}
    hasUnderline={hasUnderline}
  >
    {children}
  </HelpText>
)

export default PriorityTip
