import { RadioFilter } from 'common/Filters'
import CustomFilterLabel from './CustomFilterLabel'
import { nullOptions, naOptions } from 'bulk/helpers'

const PaginatedListFilter = ({
  reset,
  getFilterLabel,
  curField,
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  handleDisableMultiSelects,
  hasConfirmation,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      width={500}
      title={filter.display_name}
      initialValue={curField ? { value: curField?.field } : undefined}
      customLabelFn={s =>
        getFilterLabel(curField?.filterOpLabel, filter.display_name, curField?.val1, curField?.val2)
      }
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt, undefined, 'currency')
      }}
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onClear={() => onClear(filter.field)}
      onCancelCb={() => onCancel(filter.field)}
      hasConfirmation={hasConfirmation}
      isDisabled={handleDisableMultiSelects(filter.field)}
      options={[
        {
          value: 'is',
          renderLabel: props => (
            <CustomFilterLabel
              fieldName={filter.field}
              optionsSource={filter.options.url}
              onChange={(event, action) =>
                onFilterInputChange(filter.field, 'is', event, undefined, action)
              }
              isMulti
              //TODO: Request additional config for limit from BE
              limit={20}
              value={
                curSelection === 'is' &&
                curInputSelection?.op === 'is' &&
                getInputValue(filter.field, 'is')
              }
              isPortal={false}
              size={10}
              isDisabled={curSelection !== 'is'}
              placeholder={filter.display_name}
              label={'is'}
              optionParams={filter.options}
              {...props}
            />
          )
        },
        {
          value: 'is not',
          renderLabel: props => (
            <CustomFilterLabel
              fieldName={filter.field}
              optionsSource={filter.options.url}
              onChange={(event, action) =>
                onFilterInputChange(filter.field, 'is not', event, undefined, action)
              }
              isMulti
              //TODO: Request additional config for limit from BE
              limit={20}
              value={
                curSelection === 'is not' &&
                curInputSelection?.op === 'is not' &&
                getInputValue(filter.field, 'is not')
              }
              isPortal={false}
              size={10}
              isDisabled={curSelection !== 'is not'}
              placeholder={filter.display_name}
              label={'is not'}
              optionParams={filter.options}
              {...props}
            />
          )
        },
        ...nullOptions,
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default PaginatedListFilter
