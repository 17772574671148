import { RadioFilter } from 'common/Filters'
import { Input } from 'simple-core-ui'
import { nullOptions, naOptions } from 'bulk/helpers'

const NumericFilter = ({
  reset,
  getFilterLabel,
  curField,
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  hasConfirmation,
  optWrapperStyle,
  width,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      customLabelFn={s =>
        getFilterLabel(curField?.filterOpLabel, filter.display_name, curField?.val1, curField?.val2)
      }
      width={500}
      initialValue={
        curField?.field && {
          value: curField?.field
        }
      }
      title={filter.display_name}
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt)
      }}
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onCancelCb={() => onCancel(filter.field)}
      hasConfirmation={hasConfirmation}
      options={[
        {
          value: 'between',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>between</p>{' '}
              <div style={{ width: 134 }}>
                <Input
                  value={
                    curSelection === 'between' && curInputSelection?.op === 'between'
                      ? getInputValue(filter.field, 'between')
                      : ''
                  }
                  onChangeCb={event => {
                    onFilterInputChange(filter.field, 'between', event.target.value)
                  }}
                />
              </div>
              <p style={{ margin: '0 6px' }}>and</p>
              <div style={{ width: 134 }}>
                <Input
                  value={
                    curSelection === 'between' && curInputSelection?.op === 'between'
                      ? getInputValue(filter.field, 'between', 1)
                      : ''
                  }
                  onChangeCb={event => {
                    onFilterInputChange(filter.field, 'between', undefined, event.target.value)
                  }}
                />
              </div>
            </div>
          )
        },
        {
          value: '<',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>less than</p>{' '}
              <Input
                value={
                  curSelection === '<' && curInputSelection?.op === '<'
                    ? getInputValue(filter.field, 'less than')
                    : ''
                }
                onChangeCb={event => {
                  onFilterInputChange(filter.field, 'less than', event.target.value)
                }}
              />
            </div>
          )
        },
        {
          value: '<=',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>less than or equal to</p>{' '}
              <Input
                value={
                  curSelection === '<=' && curInputSelection?.op === '<='
                    ? getInputValue(filter.field, 'less than or equal to')
                    : ''
                }
                onChangeCb={event => {
                  onFilterInputChange(filter.field, 'less than or equal to', event.target.value)
                }}
              />
            </div>
          )
        },
        {
          value: '>',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>greater than</p>{' '}
              <Input
                value={
                  curSelection === '>' && curInputSelection?.op === '>'
                    ? getInputValue(filter.field, 'greater than')
                    : ''
                }
                onChangeCb={event => {
                  onFilterInputChange(filter.field, 'greater than', event.target.value)
                }}
              />
            </div>
          )
        },
        {
          value: '>=',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>greater than or equal to</p>{' '}
              <Input
                value={
                  curSelection === '>=' && curInputSelection?.op === '>='
                    ? getInputValue(filter.field, 'greater than or equal to')
                    : ''
                }
                onChangeCb={event => {
                  onFilterInputChange(filter.field, 'greater than or equal to', event.target.value)
                }}
              />
            </div>
          )
        },
        {
          value: 'is',
          label: (
            <div style={optWrapperStyle}>
              <p style={width}>equal to</p>{' '}
              <Input
                value={
                  curSelection === 'is' && curInputSelection?.op === 'is'
                    ? getInputValue(filter.field, 'equal to')
                    : ''
                }
                onChangeCb={event => {
                  onFilterInputChange(filter.field, 'equal to', event.target.value)
                }}
              />
            </div>
          )
        },
        ...nullOptions,
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default NumericFilter
