import { Fragment } from 'react'
import FiltersContainer, {
  UserFilter,
  DateFilter,
  SelectFilter,
  CheckboxFilter
} from 'common/Filters'
import Select from 'react-select'
import { sortObjectsList } from '../../utils'

const JobHistoryFilters = ({ filterParams, onSearch, recordType }) => {
  const { submitted_at, submitted_by, record_type, action_type, option_type, status } = filterParams

  return (
    <FiltersContainer
      filterParams={filterParams}
      onSearch={onSearch}
      render={(reset, filterSearch) => {
        return (
          <Fragment>
            <DateFilter
              initialValue={submitted_at}
              reset={reset}
              title="Date"
              onSelect={item => filterSearch('submitted_at', item)}
            />
            <UserFilter
              initialValue={submitted_by}
              title="Submitted By"
              reset={reset}
              isMulti
              limit={3}
              onSelect={item => filterSearch('submitted_by', item)}
              paginated
            />
            <SelectFilter
              title="Record"
              reset={reset}
              initialValue={record_type}
              isMulti
              onSelect={item => filterSearch('record_type', item)}
              render={(record, setRecord) => {
                return (
                  <Select
                    placeholder="Search records"
                    value={record}
                    onChange={setRecord}
                    options={sortObjectsList(recordType.slice())}
                    isMulti
                  />
                )
              }}
            />
            <CheckboxFilter
              initialValue={action_type}
              title="Action"
              reset={reset}
              onSelect={item => filterSearch('action_type', item)}
              options={[
                {
                  label: 'Create',
                  value: 'create'
                },
                {
                  label: 'Update',
                  value: 'edit'
                }
              ]}
            />
            <CheckboxFilter
              initialValue={option_type}
              title="Option"
              reset={reset}
              onSelect={item => filterSearch('option_type', item)}
              options={[
                {
                  label: 'Editor',
                  value: 'bulk_builder'
                },
                {
                  label: 'Import',
                  value: 'import_csv'
                }
              ]}
            />
            <CheckboxFilter
              initialValue={status}
              title="Status"
              reset={reset}
              onSelect={item => filterSearch('status', item)}
              options={[
                {
                  label: 'Success',
                  value: 'success'
                },
                {
                  label: 'Failure',
                  value: 'failed'
                },
                {
                  label: 'Processing',
                  value: 'processing'
                }
              ]}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default JobHistoryFilters
