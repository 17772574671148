import { openLink } from 'utils/helpers'
import { RESOURCE_CENTER_LINK_LABELS } from './constants'

export const filterOpLabelByMap = (label, currency) => {
  if (label === '>') return currency ? 'after' : 'more than'
  if (label === '=') return 'equal to'
  if (label === '<') return currency ? 'before' : 'less than'
}

export const downloadOrgFile = id => {
  openLink(`/upload/s3/download/${id}`)
}

export const downloadErrFile = id => {
  openLink(`/bulkv2/job/${id}/report`)
}

export const alphaNumericArraySort = list => {
  return list.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    }
    return 0
  })
}

export const sortObjectsList = list => {
  return list.sort((a, b) => {
    if (
      a.hasOwnProperty('label') &&
      b.hasOwnProperty('label') &&
      !!a.label.match(/\d/g) &&
      !!b.label.match(/\d/g)
    ) {
      return compareAlphaNumeric(a, b, 'label')
    } else if (a.hasOwnProperty('label') && b.hasOwnProperty('label')) {
      return compareObjects(a, b, 'label')
    } else if (
      a.hasOwnProperty('display_name') &&
      b.hasOwnProperty('display_name') &&
      !!a.display_name.match(/\d/g) &&
      !!b.display_name.match(/\d/g)
    ) {
      return compareAlphaNumeric(a, b, 'display_name')
    } else if (a.hasOwnProperty('display_name') && b.hasOwnProperty('display_name')) {
      return compareObjects(a, b, 'display_name')
    }
  })
}

export const getInclusiveFields = (fields, values) =>
  fields.filter(f =>
    f.column_name
      ? values.includes(f.column_name)
      : f.value
      ? values.includes(f.value)
      : values.includes(f.field)
  )

export const getNonInclusiveFields = (fields, values) =>
  fields.filter(f =>
    f.column_name
      ? !values.includes(f.column_name)
      : f.value
      ? !values.includes(f.value)
      : !values.includes(f.field)
  )

const compareObjects = (obj1, obj2, key) => {
  if (obj1[key].toLowerCase() < obj2[key].toLowerCase()) {
    return -1
  }
  if (obj1[key].toLowerCase() > obj2[key].toLowerCase()) {
    return 1
  }
  return 0
}

const compareAlphaNumeric = (obj1, obj2, key) => {
  if (Number(obj1[key].match(/\d/g).join('')) < Number(obj2[key].match(/\d/g).join(''))) {
    return -1
  }
  if (Number(obj1[key].match(/\d/g).join('')) > Number(obj2[key].match(/\d/g).join(''))) {
    return 1
  }
  return 0
}

export const historyOptionByMap = {
  import_csv: 'Import',
  bulk_builder: 'Editor'
}

export const isPopulatedArray = entity => Boolean(entity && Array.isArray(entity) && entity.length)

export const isEmptyArray = entity => Boolean(entity && Array.isArray(entity) && !entity.length)

export const checkSpaces = str => str.split('').every(char => char.charCodeAt(0) === 32)

export const makeOptions = list => list.map(opt => ({ value: opt, label: opt }))

export const makeOptionsFromObject = obj => Object.keys(obj).map(key => ({ [key]: obj[key] }))

export const getLinkLabelFor = (record_type, action) => {
  if (action === 'edit') {
    return 'What fields can I import?'
  }
  const labelsObj = RESOURCE_CENTER_LINK_LABELS()
  return `How do I start creating ${labelsObj[record_type]}?`
}
