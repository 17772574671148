import s from '../styles/LandingPage.scss'

const LandingPageItem = ({ text, description, url, name }) => (
  <li className={s.item}>
    <a id={`slm_${name}_lp_item`} href={url} title={text}>
      {text}
    </a>
    <p>{description}</p>
  </li>
)

export default LandingPageItem
