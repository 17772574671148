import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Calendar as ReactBigCalendar, momentLocalizer } from 'react-big-calendar'
import { CALENDAR_LIST_FETCH_REQUESTED, CALENDAR_LIST_CREATE_EVENT_REQUESTED } from './actions'
import moment from 'moment'
const localizer = momentLocalizer(moment)
import { Panel, ModalContainer, CustomCalendarToolbar } from 'simple-core-ui'
import { eventStyleGetter } from './helpers'
import { createSelector } from 'reselect'
import { AddEventModal } from './AddEventModal'

const eventsSelector = state => state.events
const todosSelector = state => state.todos
const selectEvents = createSelector([eventsSelector, todosSelector], (events, todos) => {
  return events.list
    .map(e => {
      return {
        id: e.id,
        title: e.title,
        start: e.startDate,
        end: e.endDate
      }
    })
    .concat(
      todos.list
        .filter(todo => !todo.completedDate)
        .map(t => ({
          id: t.id,
          title: t.title,
          start: moment(t.dueDate).toDate(),
          end: moment(t.dueDate).toDate(),
          type: 'todo'
        }))
    )
})

const Calendar = ({ scopeId }) => {
  const events = useSelector(selectEvents)
  const dispatch = useDispatch()
  const [isAddCalendarEventModalVisible, setIsAddCalendarEventModalVisible] = useState(false)
  const [eventName, setEventName] = useState('')
  const [eventDescription, setEventDescription] = useState('')
  const [eventDate, setEventDate] = useState('')

  useEffect(() => {
    dispatch({
      type: CALENDAR_LIST_FETCH_REQUESTED,
      payload: {
        scope: 'matter',
        scopeId
      }
    })
  }, [dispatch, scopeId])

  const addCalendarEvent = () => {
    dispatch({
      type: CALENDAR_LIST_CREATE_EVENT_REQUESTED,
      payload: {
        scopeId,
        event: {
          name: eventName,
          event_time: moment(eventDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          description: eventDescription
        }
      }
    })
  }

  return (
    <>
      <Panel isBodyOnly styles={{ height: '800px' }}>
        <ReactBigCalendar
          height={450}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          components={{
            toolbar: props => (
              <CustomCalendarToolbar
                {...props}
                customActions={[
                  {
                    isPrimary: true,
                    style: { marginLeft: '20px' },
                    text: 'Add Event',
                    cb: () => setIsAddCalendarEventModalVisible(true)
                  }
                ]}
              />
            )
          }}
        />
      </Panel>
      {isAddCalendarEventModalVisible && (
        <ModalContainer
          title="Add Calendar Event"
          cancelText="Cancel"
          size="md"
          cancelCb={() => {
            setIsAddCalendarEventModalVisible(false)
          }}
          confirmText="Save"
          confirmCb={addCalendarEvent}
          content={
            <AddEventModal
              eventDate={eventDate}
              eventDescription={eventDescription}
              eventName={eventName}
              setEventDate={setEventDate}
              setEventDescription={setEventDescription}
              setEventName={setEventName}
            />
          }
        />
      )}
    </>
  )
}

export default Calendar
