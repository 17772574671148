import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({
  rowId,
  status,
  updateVendorCb,
  setEditedVendorId,
  setIsEditModalVisible,
  enableEditVendor
}) => {
  if (!enableEditVendor) return null

  const actions = [
    {
      name: 'EDIT_VENDOR_MANAGER',
      text: 'Edit Vendor Manager',
      visible: status === 'active',
      onClick() {
        setEditedVendorId(rowId)
        setIsEditModalVisible(true)
      }
    },
    {
      name: 'INACTIVATE',
      text: 'Inactivate',
      visible: status === 'active',
      onClick() {
        updateVendorCb(rowId, 'deactivated')
      }
    },
    {
      name: 'REACTIVATE',
      text: 'Reactivate',
      visible: status === 'deactivated',
      onClick() {
        updateVendorCb(rowId, 'active')
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
