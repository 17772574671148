import { UserRole, LoginPref } from './types'

export const createUserOptions = (hasLegalRequests: boolean): UserRole[] => {
  return [
    { label: 'Admin', value: 'admin', default_notify: ['arrival'] },
    { label: 'Finance', value: 'finance', default_notify: ['approval'] },
    { label: 'Email Only', value: 'emailonly', default_notify: ['approval'] },
    { label: 'Email Only Filtered', value: 'emailonly_filtered', default_notify: ['approval'] },
    { label: 'Filtered', value: 'filtered', default_notify: ['arrival'], isDefault: true },
    { label: 'Power User', value: 'poweruser', default_notify: ['arrival'] },
    ...(hasLegalRequests
      ? [{ label: 'Legal Requests Only', value: 'requestonly', default_notify: ['arrival'] }]
      : [])
  ]
}

export const createLoginOptions = (
  sso_enabled: boolean,
  soft_sso_enabled: boolean
): LoginPref[] => {
  return [
    {
      label: 'SSO',
      value: 'sso',
      isDisabled: !sso_enabled,
      isDefault: sso_enabled
    },
    {
      label: 'Password',
      value: 'password',
      isDisabled: sso_enabled && !soft_sso_enabled,
      isDefault: !sso_enabled
    }
  ].sort((a, b) => {
    return +a.isDisabled - +b.isDisabled
  })
}
