import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import ACT from 'matters/requestForms/detail/actions'
import { RequestFormPreview } from './Preview'
import { FORM_STATUSES } from 'matters/requestForms/constants'

@withRouter
@connect(({ requestForm }) => {
  return {
    form: { ...requestForm }
  }
})
class RequestFormPreviewContainer extends Component {
  unarchiveForm = () => {
    const formId = this.props.router.params.id
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_FORMS_UPDATE_STATUS_REQUESTED,
      payload: {
        id: formId,
        form: {
          status: FORM_STATUSES.PUBLISHED
        },
        onSuccessActionType: ACT.LEGAL_REQUEST_FORM_UPDATE_STATUS
      }
    })
  }
  render() {
    const { form } = this.props
    return <RequestFormPreview form={form} unarchiveForm={this.unarchiveForm} />
  }
}

export default RequestFormPreviewContainer
