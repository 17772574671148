export const FILTER_TYPE = {
  ALL: 'All',
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
}

export const CATEGORIES_TAB = { value: 'categories', label: 'Document Categories' }

export const SETTINGS_TABS = [CATEGORIES_TAB]

export const tableCategories = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' }
]
