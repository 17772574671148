export const MANAGE_TEAMS_URL = '/teams/manage/'

export const CREATE_TEAM_URL = '/teams/create/'

export const TEAM_INFO_URL = teamId => `/teams/${teamId}/info/`

export const TEAM_UPDATE_URL = teamId => `/teams/${teamId}/update/`

export const TEAM_DELETE_URL = teamId => `/teams/delete/${teamId}/`

export const TEAM_DOWNLOAD_XLSX_URL = `/teams/xlsx_download/`
