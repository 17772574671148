import qs from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import {
  DateFilterSerializer,
  AmountFilterSerializer,
  MultiSelectFilterSerializer
} from 'common/Filters/serializers'
import { ordering2Str } from 'utils/serializers'
import { InvoiceListParams } from 'invoices/types'

export const serializeParams = (params: InvoiceListParams) => {
  const { filters, pageSize, ordering, page, category } = params

  return {
    size: pageSize,
    ordering: ordering ? ordering2Str(ordering) : null,
    page: page,
    search: {
      ...(filters.amount
        ? {
            amount: AmountFilterSerializer.toServer(filters.amount)
          }
        : {}),
      ...(filters.received_date
        ? {
            received_date: DateFilterSerializer.toServer(filters.received_date)
          }
        : {}),
      ...(filters.invoice_date
        ? {
            invoice_date: DateFilterSerializer.toServer(filters.invoice_date)
          }
        : {}),
      ...(filters.vendor_id
        ? {
            vendor_id: MultiSelectFilterSerializer.toServer(filters.vendor_id)
          }
        : {}),
      ...(filters.matter_ids
        ? {
            matter_ids: MultiSelectFilterSerializer.toServer(filters.matter_ids)
          }
        : {}),
      // we do not have Approvers filter on Approved tab
      ...(filters.approver_ids && category !== 'Approved'
        ? {
            approver_ids: MultiSelectFilterSerializer.toServer(filters.approver_ids)
          }
        : {}),
      ...(filters.hasOwnProperty('has_violations') && filters.has_violations !== null
        ? {
            has_violations: filters.has_violations.value
          }
        : {}),
      ...(params.search ? { keyword: params.search } : {})
    }
  }
}

export const fromFragment = (hash: string, initialParams: InvoiceListParams) => {
  const parsedHash = qs.parse(hash)

  const result = cloneDeep(initialParams)

  Object.keys(parsedHash).forEach(key => {
    if (key === 'columnKey' || key === 'isDesc') {
      result.ordering = {
        columnKey: parsedHash.columnKey as string,
        isDesc: parsedHash.isDesc === 'true'
      }
    } else if (key === 'vendor_id' || key === 'matter_ids' || key === 'approver_ids') {
      result.filters[key] = MultiSelectFilterSerializer.fromFragment(key, parsedHash)
    } else if (key === 'amount') {
      result.filters[key] = AmountFilterSerializer.fromFragment(key, parsedHash)
    } else if (key.includes('received_date') && !result.filters.received_date) {
      result.filters.received_date = DateFilterSerializer.fromFragment('received_date', parsedHash)
    } else if (key.includes('invoice_date') && !result.filters.invoice_date) {
      result.filters.invoice_date = DateFilterSerializer.fromFragment('invoice_date', parsedHash)
    } else if (key === 'has_violations') {
      result.filters.has_violations =
        parsedHash[key] === 'true'
          ? { value: true, label: 'Has Violation' }
          : { value: false, label: 'No Violation' }
    } else if (key === 'pageSize' || key === 'page') {
      result[key] = Number(parsedHash[key])
    } else {
      result[key] = parsedHash[key]
    }
  })

  return result
}

export const toFragment = (obj: InvoiceListParams) => {
  const newObj = {
    pageSize: obj.pageSize,
    page: obj.page,
    category: obj.category
  }

  const newFilters: { [key: string]: string } = {}
  Object.keys(obj.filters).forEach(key => {
    const currFilter = obj.filters[key]
    if ((key === 'vendor_id' || key === 'matter_ids' || key === 'approver_ids') && !!currFilter) {
      newFilters[key] = MultiSelectFilterSerializer.toFragment(key, currFilter)
    } else if (key === 'amount' && !!currFilter) {
      newFilters[key] = AmountFilterSerializer.toFragment(key, currFilter)
    } else if ((key === 'received_date' || key === 'invoice_date') && !!currFilter) {
      newFilters[key] = DateFilterSerializer.toFragment(key, currFilter)
    } else if (key === 'has_violations') {
      newFilters[key] = `${key}=${get(currFilter, 'value', '')}`
    }
  })

  const paramStr = qs.stringify(newObj)
  const search = qs.stringify({ search: obj.search })
  const ordering = qs.stringify(obj.ordering)

  const optParam = (p: string) => (p ? `&${p}` : '')

  const {
    vendor_id,
    matter_ids,
    amount,
    received_date,
    invoice_date,
    has_violations,
    approver_ids
  } = newFilters

  const frag = `${paramStr}${optParam(search)}&${ordering}${optParam(vendor_id)}${optParam(
    matter_ids
  )}${optParam(approver_ids)}${optParam(amount)}${optParam(received_date)}${optParam(
    invoice_date
  )}${optParam(has_violations)}`

  return frag
}

export const formatPreferenceSettings = (response: { name: string; [key: string]: unknown }[]) =>
  response.reduce((acc: any, val: any) => {
    acc[val.name] = val
    return acc
  }, {} as Record<string, unknown>)

export const serializeBulkRejectData = ({
  reasons,
  additionalDetails
}: {
  reasons: { value: string }[]
  additionalDetails: string
}) => ({
  reasons: reasons.map(r => r.value),
  additional_details: additionalDetails
})
