import { Component } from 'react'
import { Panel, List, Tabs, Tab } from 'simple-core-ui'
import DashboardItem from '../components/DashboardItem'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from '../actions'
import Expand from '../components/Expand'
import { DashboardSkeleton } from 'common'

class InnerPanel extends Component {
  render() {
    const { mattersFollowing, vendorsFollowing, expanded } = this.props

    return (
      <Tabs>
        <Tab header={'Matters'}>
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={mattersFollowing}
              fallback={'Matters will only appear on the list if you are listed as a matter lead'}
              render={item => {
                const { id, name, clientMatterId } = item
                return (
                  <DashboardItem
                    title={name}
                    subTitle={clientMatterId}
                    dateLabel="Created"
                    url={`/v2/matters/${id}`}
                  />
                )
              }}
            />
          </div>
        </Tab>
        <Tab header={'Vendors'}>
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={vendorsFollowing}
              fallback={'Vendors will only appear on the list if you are listed as a vendor lead'}
              render={item => {
                const { id, name } = item
                return (
                  <DashboardItem title={name} dateLabel="Created" url={`/invoices/vendors/${id}`} />
                )
              }}
            />
          </div>
        </Tab>
      </Tabs>
    )
  }
}

@connect(({ dashboard }) => {
  const { vendorsFollowing, mattersFollowing } = dashboard
  return {
    vendorsFollowing,
    mattersFollowing
  }
})
@withFetchFlow({
  flag: 'Following',
  render: () => <DashboardSkeleton />,
  getFetchAction: () => ({
    type: ACT.FOLLOWING_FETCH_REQUESTED
  })
})
class Following extends Component {
  getTitle = (title, num) => {
    if (!num) {
      return title
    } else {
      return `${title} (${num})`
    }
  }

  render() {
    const title = 'Following'
    return (
      <Panel
        className="dashboard-panel"
        title={title}
        rightActions={[
          <Expand key={0} title={title}>
            <InnerPanel {...this.props} expanded />
          </Expand>
        ]}
      >
        <InnerPanel {...this.props} />
      </Panel>
    )
  }
}

export default Following
