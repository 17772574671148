import { Tag, Anchor } from 'simple-core-ui'

import { isCounselGo } from '../../utils'

import s from './ContactAffiliationCell.scss'

const ContactAffiliationCell = ({ rowId, columnKey, content, category }) => {
  const urlBaseMap = {
    matter: '/v2/matters/',
    vendor: '/invoices/vendors/',
    legalEntity: '/manage/entities/',
    practiceArea: '/manage/matter_groups/'
  }
  if (columnKey === 'name') {
    return <Anchor url={`${urlBaseMap[category]}${rowId}`}>{content}</Anchor>
  } else if (columnKey === 'roles') {
    return (
      <section className={s.roles}>
        {content.length
          ? content.map((role, idx) => {
              const color = isCounselGo(role) ? 'red' : role.singular ? 'blue' : 'black'

              return (
                <Tag key={idx} color={color}>
                  {role.label}
                </Tag>
              )
            })
          : '----'}
      </section>
    )
  } else if (columnKey === 'isCounselGoUser' || columnKey === 'isCounselGoAdmin') {
    return <section>{content ? 'Yes' : 'No'}</section>
  } else if (columnKey === 'rolesString' || columnKey === 'labelsString') {
    return <section>{content.length ? content.map(item => item.label).join(', ') : '----'}</section>
  } else if (columnKey === 'permissions') {
    return (
      <ul>
        {content.length
          ? content
              .filter(permission => !(permission === 'Viewer' && content.includes('Approver')))
              .map((permission, idx) => (
                <li className={s.permission} key={idx}>
                  {permission}
                </li>
              ))
          : '----'}
      </ul>
    )
  } else {
    return content
  }
}

export default ContactAffiliationCell
