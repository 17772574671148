import { Modal } from 'simple-core-ui'
import Select from 'react-select'
import s from './UserManagement.scss'

const UserModal = ({
  isVisible,
  toggleUserModal,
  updateSelectedUser,
  addUser,
  users,
  selectedUser
}) => {
  return (
    <Modal
      size="sm"
      cancelCb={toggleUserModal}
      isVisible={isVisible}
      title="Add User"
      confirmText="Save"
      cancelText="Cancel"
      confirmCb={addUser}
      content={
        <div className={s.modalBody}>
          <div className={s.info} style={{ marginTop: 12 }}>
            SimpleLegal User
          </div>
          <Select
            placeholder="Select SimpleLegal User"
            value={selectedUser}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={value => updateSelectedUser(value)}
            options={users.map(user => ({
              label: user.first_name + ' ' + user.last_name,
              value: user.id
            }))}
          />
        </div>
      }
    />
  )
}

export default UserModal
