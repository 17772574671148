import get from 'lodash/get'
import { Row } from './types'

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'label':
      return cellContent.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
