import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'

const initialState = {
  currentVendor: {},
  questions: [],
  additionalCommentsResponses: '',
  comments: {
    permissions_warning: false,
    list: []
  },
  compareQuestions: [],
  practiceAreas: [],
  vendors: []
}

const scorecardReducer = createReducer(initialState, {
  [ACT.CURRENT_VENDOR_FETCH_SUCCESS](state, action) {
    const { currentVendor, practiceAreas, vendors } = action.payload
    return {
      ...state,
      isLoading: false,
      currentVendor,
      practiceAreas,
      vendors
    }
  },
  [ACT.QUESTION_ANSWERS_FETCH_SUCCESS](state, action) {
    const { answers, index, permissions_warning } = action.payload

    return update(state, {
      questions: {
        [index]: {
          answers: { $set: answers },
          permissions_warning: { $set: permissions_warning }
        }
      }
    })
  },
  [ACT.ADDITIONAL_COMMENTS_FETCH_SUCCESS](state, action) {
    const { answers, permissions_warning } = action.payload

    return update(state, {
      comments: {
        list: { $set: answers },
        permissions_warning: { $set: permissions_warning }
      }
    })
  },
  [ACT.FILTERED_QUESTIONS_SUCCESS](state, action) {
    const { questions, additionalCommentsResponses } = action.payload

    return update(state, {
      questions: { $set: questions },
      additionalCommentsResponses: { $set: additionalCommentsResponses }
    })
  },
  [ACT.COMPARE_QUESTIONS_SUCCESS](state, action) {
    const { questions } = action.payload

    return update(state, {
      compareQuestions: { $set: questions }
    })
  }
})

export default scorecardReducer
