import { ConditionalRender, Anchor } from 'simple-core-ui'

import { REASON_TYPE } from '../utils/constants'

import s from '../styles/ReviewLadder.scss'

const { BUILTIN, ESCALATION, EDIT, CUSTOM_TEAM, MESSAGE } = REASON_TYPE

const Reasons = ({ entry }) =>
  entry !== null && (
    <>
      <h4 data-testid={'reasons-title'} className={s.reasonsTitle}>
        Reasons for {entry.name}
      </h4>
      <ul data-testid={'reasons-list'} className={s.reasons}>
        {entry.reasons.map((reason, idx) => (
          <ConditionalRender
            key={idx}
            conditions={[
              {
                condition: reason.reasonType === BUILTIN,
                content: <BuiltinReason {...reason} key={idx} />
              },
              {
                condition: reason.reasonType === ESCALATION,
                content: <EscalationReason {...reason} key={idx} />
              },
              {
                condition: reason.reasonType === EDIT,
                content: <EditReason {...reason} key={idx} />
              },
              {
                condition: reason.reasonType === CUSTOM_TEAM,
                content: <CustomTeamReason {...reason} key={idx} />
              },
              {
                condition: reason.reasonType === MESSAGE,
                content: <MessageReason {...reason} key={idx} />
              }
            ]}
          />
        ))}
      </ul>
    </>
  )

const StyledAnchor = ({ url, children }) => (
  <Anchor url={url} style={{ margin: '0 0.25em' }}>
    {children}
  </Anchor>
)

export const BuiltinReason = ({ configLabel, configUrl, ruleLabel, ruleUrl }) => (
  <li className={s.reason}>
    Assigned by <StyledAnchor url={configUrl}>{configLabel}</StyledAnchor> via{' '}
    <StyledAnchor url={ruleUrl}>{ruleLabel}</StyledAnchor>
  </li>
)

export const EscalationReason = ({ reportUserLabel, reportUserUrl }) => (
  <li className={s.reason}>
    Escalated from <StyledAnchor url={reportUserUrl}>{reportUserLabel}</StyledAnchor>
  </li>
)

export const EditReason = ({ editingUserLabel, editingUserUrl }) => (
  <li className={s.reason}>
    Added by <StyledAnchor url={editingUserUrl}>{editingUserLabel}</StyledAnchor>
  </li>
)

export const CustomTeamReason = ({ ruleLabel, ruleUrl }) => (
  <li className={s.reason}>
    Assigned by <StyledAnchor url={ruleUrl}>{ruleLabel}</StyledAnchor>
  </li>
)

export const MessageReason = ({ message }) => <li className={s.reason}>{message}</li>

export default Reasons
