import { Panel } from 'simple-core-ui'
import Toggle from 'react-toggle'
import { useSelector, useDispatch } from 'react-redux'
import ACT from 'rules/actions'
import s from '../styles/RulesList.scss'
import { toInvoiceAI } from 'rules/serializers'

const icons = {
  checked: <p className={s.switchLabel}>On</p>,
  unchecked: <p className={s.switchLabel}>Off</p>
}

const InvoiceAI = () => {
  const aiData = useSelector(app => app.rules.invoiceAi)
  const dispatch = useDispatch()

  return (
    <Panel className={s.panel} title="Category Codes">
      {toInvoiceAI(aiData).map((invoice, index) => (
        <div className={s.row} key={invoice.key}>
          <div className={s.firstColumn}>
            <p className={s.aiDescription}>{invoice.label}</p>
          </div>
          <div className={s.secondColumn}>
            <Toggle
              icons={icons}
              checked={invoice.value}
              onChange={() => {
                const { value, key } = invoice
                dispatch({
                  type: ACT.SAVE_INVOICE_AI_REQUESTED,
                  payload: { ...aiData, [key]: !value }
                })
              }}
              disabled={false}
            />
          </div>
        </div>
      ))}
    </Panel>
  )
}

export default InvoiceAI
