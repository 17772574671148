import { createContext } from 'react'
import { DraftingContextState } from './files/MatterAttachmentsTab/DocumentDrafting/types'

const DocumentDraftingContext = createContext<DraftingContextState>({
  showDraftingPreview: false,
  showDraftingSidebar: false,
  setShowDraftingPreview: () => {},
  setShowDraftingSidebar: () => {},
  draftingContext: {
    text: '',
    practiceArea: '',
    documentType: '',
    writingStyle: '',
    tone: '',
    creativity: '',
    length: '',
    language: ''
  },
  setDraftingContext: () => {}
})

export default DocumentDraftingContext
