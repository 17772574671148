import * as CONST from './record_types'
import { TeamSelect } from 'teams'
import { VendorSelect } from 'common/Selects'

const MATTER_VENDORS_FIELDS = [
  {
    field: 'add',
    display_name: 'Assign Vendor',
    field_options: [
      {
        key: 'add',
        placeholder: 'Select a vendor',
        width: 12,
        props: {
          isMulti: true,
          paginated: true,
          isClearable: true,
          withInactiveVendors: false,
          isPortal: false
        }
      }
    ]
  },
  {
    field: 'delete',
    display_name: 'Remove Vendor',
    field_options: [
      {
        key: 'delete',
        placeholder: 'Select a vendor',
        width: 12,
        props: {
          isMulti: true,
          paginated: true,
          isClearable: true,
          isPortal: false
        }
      }
    ]
  },
  {
    field: 'replace',
    display_name: 'Replace Vendor',
    field_options: [
      {
        key: 'old',
        label: 'Replace from',
        label_width: 3,
        placeholder: 'Select existing vendor',
        width: 9,
        props: {
          paginated: true,
          isClearable: true,
          isPortal: false
        }
      },
      {
        key: 'new',
        label: 'Replace to',
        label_width: 3,
        placeholder: 'Select new vendor',
        width: 9,
        props: {
          isMulti: true,
          paginated: true,
          isClearable: true,
          withInactiveVendors: false,
          isPortal: false
        }
      }
    ]
  }
]

const MATTER_SUBSCRIBERS_FIELDS = [
  {
    field: 'add',
    display_name: 'Add matter subscriber',
    field_options: [
      {
        key: 'add',
        placeholder: 'Select a subscriber',
        width: 12,
        props: {
          multi: true
        }
      }
    ]
  },
  {
    field: 'delete',
    display_name: 'Remove matter subscriber',
    field_options: [
      {
        key: 'delete',
        placeholder: 'Select a subscriber',
        width: 12,
        props: {
          multi: true,
          customQueryParams: { include_deleted: true }
        }
      }
    ]
  },
  {
    field: 'replace',
    display_name: 'Replace matter subscriber',
    field_options: [
      {
        key: 'old',
        label: 'Replace from',
        label_width: 3,
        placeholder: 'Select existing subscriber',
        width: 9,
        props: {
          isClearable: true,
          customQueryParams: { include_deleted: true }
        }
      },
      {
        key: 'new',
        label: 'Replace to',
        label_width: 3,
        placeholder: 'Select new subscriber',
        width: 9,
        props: {
          multi: true,
          isClearable: true
        }
      }
    ]
  }
]

const MATTER_APPROVERS_FIELDS = [
  {
    field: 'add',
    display_name: 'Add matter approver',
    field_options: [
      {
        key: 'clause',
        placeholder: 'All of',
        width: 4,
        options: [
          { label: 'All of', value: 'all' },
          { label: 'Any of', value: 'any' }
        ]
      },
      {
        key: 'add',
        placeholder: 'Choose approver',
        width: 8,
        props: {
          multi: true
        }
      },
      {
        key: 'level',
        placeholder: 'Level',
        width: 12,
        options: [
          { label: 'First', value: 'first' },
          { label: 'Last', value: 'last' }
        ]
      }
    ]
  },
  {
    field: 'delete',
    display_name: 'Remove matter approver',
    field_options: [
      {
        key: 'delete',
        placeholder: 'Choose approver',
        width: 12,
        props: {
          multi: true,
          customQueryParams: { include_deleted: true }
        }
      }
    ]
  },
  {
    field: 'replace',
    display_name: 'Replace matter approver',
    field_options: [
      {
        key: 'old',
        label: 'Replace from',
        label_width: 3,
        placeholder: 'Choose old approver',
        width: 9,
        props: {
          isClearable: true,
          customQueryParams: { include_deleted: true }
        }
      },
      {
        key: 'new',
        label: 'Replace to',
        label_width: 3,
        placeholder: 'Choose new approver',
        width: 9,
        props: {
          multi: true,
          isClearable: true
        }
      }
    ]
  }
]

export const RECORD_TO_FIELDS_MAPPING = {
  [CONST.MATTER_APPROVERS]: MATTER_APPROVERS_FIELDS,
  [CONST.MATTER_VENDORS]: MATTER_VENDORS_FIELDS,
  [CONST.MATTER_SUBSCRIBERS]: MATTER_SUBSCRIBERS_FIELDS
}

const MATTER_APPROVERS_FIELDS_PER_ROW = {
  add: 3,
  delete: 1,
  replace: 2
}

const MATTER_VENDORS_FIELDS_PER_ROW = {
  add: 1,
  delete: 1,
  replace: 2
}

export const RECORD_TO_FIELDS_COUNT_MAPPING = {
  [CONST.MATTER_APPROVERS]: MATTER_APPROVERS_FIELDS_PER_ROW,
  [CONST.MATTER_VENDORS]: MATTER_VENDORS_FIELDS_PER_ROW,
  [CONST.MATTER_SUBSCRIBERS]: MATTER_VENDORS_FIELDS_PER_ROW
}

export const CUSTOM_ATTRIBUTE_TYPES = [
  {
    label: 'Currency',
    value: 'currency'
  },
  {
    label: 'Date',
    value: 'date'
  },
  {
    label: 'List',
    value: 'list'
  },
  {
    label: 'Text Area',
    value: 'textarea'
  },
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Yes/No',
    value: 'boolean'
  }
]

export const BULK_TAB_NAMES_BY_MAP = {
  bulkImport: 'import',
  builder: 'editor',
  history: 'history'
}

export const MATTER_TEMPLATE_URL = '/templates/matter_templates_json/'

export const LIST_CUSTOM_ATTRIBUTE_URL = '/bulkv2/list_custom_attributes/select2_json'

export const MATTER_TEMPLATE_WITH_UPDATE_URL = '/bulkv2/matter_templates/select2_json'

export const ACTIVE_JOB_STATUS_URL = '/bulkv2/active_job/'

export const IMPORT_CSV_RECORD_TO_TEMPLATE_URL_MAPPING: { [key: string]: string } = {
  [CONST.MATTER_CUSTOM_ATTRIBUTES]: MATTER_TEMPLATE_URL,
  [CONST.CREATE_MATTER]: MATTER_TEMPLATE_URL,
  [CONST.MATTER_TEMPLATES]: MATTER_TEMPLATE_WITH_UPDATE_URL,
  [CONST.CA_LIST_VALUES]: LIST_CUSTOM_ATTRIBUTE_URL
}

export const COST_CODE_URLS: { [key: string]: string } = {
  [CONST.ACTION_TYPE.CREATE]: '/help/?article_id=13068295621911-CSV-Import-Creating-New-Cost-Codes',
  [CONST.ACTION_TYPE.EDIT]: '/help/?article_id=13159521793559-CSV-Import-Cost-Codes'
}

export const CA_LIST_URLS: { [key: string]: string } = {
  [CONST.ACTION_TYPE.CREATE]: '/help/?article_id=15454327171863',
  [CONST.ACTION_TYPE.EDIT]: '/help/?article_id=15457983991703'
}

export const HELP_CENTER_URL_BY_MAP: { [key: string]: string | object } = {
  [CONST.MATTERS_ONLY]: '/help/?article_id=4477400298647',
  [CONST.MATTER_ALLOCATIONS]: '/help/?article_id=4477460509463',
  [CONST.MATTER_APPROVERS]: '/help/?article_id=4477419312663',
  [CONST.MATTER_CUSTOM_ATTRIBUTES]: '/help/?article_id=4477449109783',
  [CONST.MATTER_TEMPLATES]: '/help/?article_id=4477487373207',
  [CONST.MATTER_SUBSCRIBERS]: '/help/?article_id=4477410102167',
  [CONST.MATTER_VENDORS]: '/help/?article_id=4477416830487',
  [CONST.CREATE_MATTER]: '/help/?article_id=5875765664151',
  [CONST.COST_CODES]: COST_CODE_URLS,
  [CONST.CREATE_VENDOR]: '/help/?article_id=12988695197335-CSV-Import-Creating-New-Vendors',
  [CONST.UPDATE_VENDOR_ATTRIBUTE]:
    '/help/?article_id=12991697584407-CSV-Import-Vendors-with-Attributes',
  [CONST.UPDATE_VENDOR_REVIEWERS]:
    '/help/?article_id=12991583280023-CSV-Import-Vendors-with-Approvers',
  [CONST.CREATE_ENTITY]: '/help/?article_id=14621323802775',
  [CONST.UPDATE_ENTITY_ATTRIBUTE]: '/help/?article_id=14642776401687',
  [CONST.UPDATE_ENTITY_APPROVERS]: '/help/?article_id=14643852884375',
  [CONST.CREATE_MATTER_GROUP]: '/help/?article_id=14643454830487',
  [CONST.UPDATE_MATTER_GROUP_ATTRIBUTE]: '/help/?article_id=14643858368663',
  [CONST.UPDATE_MATTER_GROUP_APPROVERS]: '/help/?article_id=14643865960343',
  [CONST.CA_LIST_VALUES]: CA_LIST_URLS
}

export const TEAM_SELECT_FIELDS = ['matter_approvers', 'matter_subscribers']

export const USER_SELECT_FIELDS = ['matter_lead', 'matter_creator']

export const CUSTOM_FIELDS_LIMIT = 10

export const ROWS_LIMIT = {
  [CONST.ACTION_TYPE.EDIT]: '10,000',
  [CONST.ACTION_TYPE.CREATE]: '1,000'
}

export const MULTI_FIELD_RECORDS_DROPDOWN_MAPPING = {
  [CONST.MATTER_APPROVERS]: TeamSelect,
  [CONST.MATTER_VENDORS]: VendorSelect,
  [CONST.MATTER_SUBSCRIBERS]: TeamSelect
}

export const RESOURCE_CENTER_LINK_LABELS = () => {
  const customLabels = window.credentials.custom.names
  const {
    costcode_management: ccLabel,
    legal_entity_management: leLabel,
    matter_group_management: mgLabel
  } = customLabels
  return {
    [CONST.COST_CODES]: ccLabel[1],
    [CONST.CREATE_VENDOR]: 'Vendors',
    [CONST.CREATE_MATTER]: 'Matters',
    [CONST.CREATE_ENTITY]: leLabel[1],
    [CONST.CREATE_MATTER_GROUP]: mgLabel[1],
    [CONST.CA_LIST_VALUES]: 'Custom Attribute List Values'
  }
}
