import ThemeContext from 'context/ThemeContext'
import s from './InnerSidebar.scss'
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'
import cn from 'classnames'

const InnerSiderbar = ({ title, children, closable = false, toggleDrawer, opened, style }) => (
  <ThemeContext.Consumer>
    {({ state }) => (
      <aside
        className={cn({
          [s.containerExpanded]: state.isMenuExpanded,
          [s.container]: !state.isMenuExpanded,
          [s.paddingRight]: closable,
          [s.closed]: !opened && closable
        })}
        style={style}
      >
        {closable && (
          <div
            className={cn(s.toggler, {
              [s.expanded]: state.isMenuExpanded,
              [s.closedToggler]: !opened
            })}
            onClick={toggleDrawer}
          >
            {opened ? <FaAngleLeft className={s.arrow} /> : <FaAngleRight className={s.arrow} />}
          </div>
        )}
        <h2 className={s.title}>{title}</h2>
        {children}
      </aside>
    )}
  </ThemeContext.Consumer>
)

export default InnerSiderbar
