import classNames from 'classnames'

import s from './Hoverable.scss'

const Hoverable = ({ position, isOpen, isHoverable, content, styles, contentStyles, children }) => (
  <span
    style={styles}
    className={classNames(s.container, {
      [s.isHoverable]: isHoverable,
      [s.isOpen]: isOpen
    })}
  >
    {children}
    <section className={classNames(s.contentWrapper, s[position])} style={contentStyles}>
      {content}
    </section>
  </span>
)

Hoverable.defaultProps = {
  isOpen: false,
  isHoverable: true,
  position: 'top',
  styles: {},
  contentStyles: {}
}

export default Hoverable
