import { APITask, DateFormat } from 'common/Tasks/types'
import { Task, Event } from './types'
import format from 'date-fns/format'
import { DATE_FORMATS } from 'utils/constants'
import { APIEvent } from 'common/Events/types'
import { timezoneDate, formatName } from 'utils/helpers'

const { DEFAULT_DATE_FNS } = DATE_FORMATS

export const toEvents = (events: APIEvent[], callback?: (events: Event[]) => Event[]): Event[] => {
  const eventsArray = events.map((event: APIEvent) => {
    const {
      id,
      name,
      event_type,
      related_matter,
      location,
      start_date,
      attendees,
      is_external,
      end_date,
      court_rule
    } = event

    return {
      id,
      name,
      type: 'event',
      location,
      attendees: attendees.map(attendee => ({
        value: attendee.id,
        label: formatName(attendee) as string
      })),
      isExternal: is_external,
      startDate: start_date ? timezoneDate(start_date) : undefined,
      endDate: end_date ? timezoneDate(end_date) : undefined,
      eventType: event_type
        ? {
            id: event_type.id,
            name: event_type.name
          }
        : null,
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status
            }
          }
        : {}),
      title: name,
      start: start_date ? timezoneDate(start_date) : undefined,
      end: start_date ? timezoneDate(end_date || start_date) : undefined,
      allDay: end_date ? false : true,
      courtRule: court_rule
    }
  })

  return callback ? callback(eventsArray) : eventsArray
}

export const toTasks = (tasks: APITask[], callback?: (tasks: Task[]) => Task[]): Task[] => {
  const tasksArray = tasks.map((task: APITask) => {
    const { id, name, status, due_date, priority, task_type, related_matter } = task

    return {
      id,
      name,
      type: 'task',
      status: status
        ? {
            id: status.id,
            name: status.client_settings?.display_name ?? status.default_display_name,
            color: status.client_settings?.color ?? status.default_color,
            occurredDate: format(new Date(status.occurred_date), DEFAULT_DATE_FNS) as DateFormat,
            phase: status.phase.description
          }
        : null,
      dueDate: due_date ? (format(new Date(due_date), DEFAULT_DATE_FNS) as DateFormat) : undefined,
      priority: priority
        ? {
            id: priority.id,
            name: priority.client_settings?.display_name ?? priority.default_display_name,
            color: priority.client_settings?.color ?? priority.default_color
          }
        : null,
      taskType: task_type
        ? {
            id: task_type.id,
            name: task_type.name
          }
        : null,
      ...(related_matter
        ? {
            relatedMatter: {
              value: related_matter.id,
              label: related_matter.name,
              status: related_matter.status
            }
          }
        : {}),
      title: name,
      start: due_date ? format(new Date(due_date), DEFAULT_DATE_FNS) : undefined,
      end: due_date ? format(new Date(due_date), DEFAULT_DATE_FNS) : undefined,
      allDay: true
    }
  })

  return callback ? callback(tasksArray) : tasksArray
}
