import KEY_CODES from 'data/key_codes'
import s from './MessageArea.scss'
import { Button, RichTextEditor } from 'simple-core-ui'

const MessageArea = ({
  placeholder,
  value,
  clickHandler,
  changeHandler,
  buttonContent,
  readOnly,
  isRichTextEditor,
  isLoading
}) => {
  return (
    <>
      {isRichTextEditor ? (
        <RichTextEditor
          value={value}
          onChange={changeHandler}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      ) : (
        <section className={s.container}>
          <textarea
            className={s.textarea}
            placeholder={placeholder}
            onChange={changeHandler}
            onKeyDown={event => {
              if (event.keyCode === KEY_CODES.enter && !event.shiftKey) {
                event.preventDefault()
                if (value.trim().length) {
                  clickHandler(event)
                }
              }
            }}
            value={value}
            disabled={readOnly}
          />
        </section>
      )}
      {!readOnly && (
        <Button onClick={clickHandler} isDisabled={isLoading || !value.trim().length}>
          {buttonContent}
        </Button>
      )}
    </>
  )
}

MessageArea.defaultProps = {
  placeholder: '',
  value: '',
  clickHandler: e => e.preventDefault(),
  changeHandler: e => e.preventDefault(),
  buttonContent: '',
  isRichTextEditor: false,
  isLoading: false
}

export default MessageArea
