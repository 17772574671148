import { useMemo } from 'react'
import { ButtonDropdown, Badge, DESIGN, Alert, Ellipsis } from 'simple-core-ui'
import s from '../MatterDetailContainer.scss'
import { MATTER_STATUS } from 'matters/constants'

const {
  color: { gray500, gray900, green500, green900 }
} = DESIGN

function MatterHeader({ matterName, handleAction, settings = {}, scopeId }) {
  const options = useMemo(() => {
    const canReopen = () => {
      const permissions = settings?.permissions

      if (!settings?.is_processing && settings?.status?.slug === MATTER_STATUS.CLOSED) {
        if (settings?.templates?.has_outdated_template) {
          return true
        }
        return permissions?.has_edit_capability && permissions?.can_change_matter_status
      }
      return false
    }

    return [
      {
        value: 'reopen',
        label: 'Reopen',
        isDisabled: !canReopen()
      },
      {
        value: 'close',
        label: 'Close',
        isDisabled: !(
          settings?.permissions?.can_change_matter_status &&
          settings?.permissions?.has_edit_capability &&
          settings?.status?.is_status_changeable &&
          settings?.status?.slug === MATTER_STATUS.OPEN
        )
      },
      {
        value: 'clone',
        label: 'Duplicate',
        isDisabled:
          settings?.is_confidential ||
          settings?.templates?.has_outdated_template ||
          !settings?.permissions?.can_change_matter_status
      },
      {
        value: 'change_template',
        label: 'Change Template',
        isDisabled: !(
          settings?.status?.slug !== MATTER_STATUS.CLOSED &&
          settings?.templates?.client_templates_count > 1
        )
      },
      {
        value: 'merge',
        label: 'Merge',
        isDisabled: !(
          settings?.permissions?.has_edit_capability &&
          ![MATTER_STATUS.CLOSED, MATTER_STATUS.DRAFT].includes(settings?.status?.slug) &&
          !settings?.has_legal_request
        )
      },
      {
        value: 'delete',
        label: 'Delete',
        isDisabled: !(settings?.permissions?.has_edit_capability && !settings?.has_lineitems)
      }
    ]
  }, [settings])

  if (!settings) {
    return null
  }

  return (
    <div className={s.header}>
      <div data-testid="matter_details_header" data-value={matterName}>
        <div className={s.matterName}>
          <Ellipsis width={1000} lines={1}>
            {matterName}
          </Ellipsis>
        </div>
        {settings.status && (
          <Badge
            asPill
            size="md"
            style={{
              textTransform: 'uppercase',
              backgroundColor: settings.status?.slug === MATTER_STATUS.OPEN ? green500 : gray500,
              color: settings.status?.slug === MATTER_STATUS.OPEN ? green900 : gray900
            }}
            title={settings.status?.full_name}
            content={settings.status?.full_name}
          />
        )}
      </div>
      <div data-testid="header">
        <ButtonDropdown
          alignRight
          displayText="Actions"
          onSelect={handleAction}
          options={options}
        />
      </div>
      {settings.templates?.has_outdated_template &&
        settings?.status?.slug !== MATTER_STATUS.CLOSED && (
          <Alert
            style={{ width: '100%' }}
            message={
              <>
                Please update the matter with the new template requirements to enable further edits.
                <a
                  href={`/new/matters/templates/select?matterId=${scopeId}&latestTemplateId=${settings.latest_template_id}`}
                >
                  &nbsp; Update matter.
                </a>
              </>
            }
            status="warning"
          />
        )}
    </div>
  )
}

export default MatterHeader
