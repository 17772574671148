import { useState } from 'react'
import s from './CommentsList.scss'
import { Comment, User } from '../../types'
import { AvatarList, Button, CharLimitInput } from 'simple-core-ui'
import { formatName } from 'utils/helpers'
import moment from 'moment'
import { ActionsPopover } from './ActionsPopover'
import { DeleteConfirmation } from './DeleteConfirmation'

interface Props {
  comments: Comment[]
  deleteComment: (id: number) => void
  editComment: (id: number, comment: string, cb: () => void) => void
  readOnly?: boolean
}

const loggedInUser = window.credentials.user
const isAdmin: boolean = loggedInUser?.role === 'admin'

const isTheAuthor = (author: User): boolean => {
  return author?.id === +loggedInUser.id
}

const CommentsList = ({ comments, deleteComment, editComment, readOnly }: Props) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [commentToDeleteId, setCommentToDeleteId] = useState<number | null>(null)
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null)

  const onEditComment = (comment: Comment) => {
    setSelectedComment(comment)
  }

  return (
    <>
      <div className={s.commentsList}>
        {comments.map(comment => {
          return (
            <div key={comment.id} className={s.comment}>
              <AvatarList
                size="md"
                wrapperStyles={{ width: 36 }}
                entries={[
                  {
                    label: formatName(comment.author)
                  }
                ]}
              />
              <div className={s.commentContent}>
                <div className={s.info}>
                  <span>
                    <b>{formatName(comment.author)}</b> &#8226;{' '}
                    {moment(comment.created_date).format(
                      moment(comment.created_date)
                        .startOf('day')
                        .isSame(moment().startOf('day'), 'day')
                        ? '[Today] hh:mm a'
                        : 'MM/DD/yyyy h:mm a'
                    )}
                  </span>
                </div>
                {selectedComment && selectedComment.id === comment.id ? (
                  <>
                    <CharLimitInput
                      type="textarea"
                      value={selectedComment.comment}
                      placeholder="Add a comment"
                      onChangeCb={e =>
                        setSelectedComment({ ...selectedComment, comment: e.target.value })
                      }
                      maxLength={1000}
                      hideInfoText
                      cssClass={s.input}
                    />
                    <div className={s.actions}>
                      <Button
                        style={{
                          background: '#fff',
                          color: '#0957ae',
                          border: '1px solid #0957ae',
                          height: '25px',
                          position: 'relative',
                          top: '3px'
                        }}
                        onClick={() => {
                          setSelectedComment(null)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          background: '#0957ae',
                          color: '#fff',
                          height: '25px',
                          position: 'relative',
                          top: '3px'
                        }}
                        onClick={() => {
                          editComment(selectedComment.id, selectedComment.comment, () => {
                            setSelectedComment(null)
                          })
                        }}
                        isDisabled={!selectedComment.comment}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                ) : (
                  <p className={s.content}>{comment.comment}</p>
                )}
              </div>
              {(isTheAuthor(comment.author) || isAdmin) && !readOnly && (
                <ActionsPopover
                  comment={comment}
                  deleteComment={id => {
                    setCommentToDeleteId(id)
                    setIsDeleteConfirmationOpen(true)
                  }}
                  editComment={onEditComment}
                  canEdit={isTheAuthor(comment.author)}
                />
              )}
            </div>
          )
        })}
      </div>
      {isDeleteConfirmationOpen && (
        <DeleteConfirmation
          onConfirm={() => {
            if (commentToDeleteId) {
              deleteComment(commentToDeleteId)
            }
            setIsDeleteConfirmationOpen(false)
          }}
          onCancel={() => {
            setIsDeleteConfirmationOpen(false)
            setCommentToDeleteId(null)
          }}
        />
      )}
    </>
  )
}

export default CommentsList
