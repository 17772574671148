import ReactTooltip from 'react-tooltip'
import { Component, Fragment } from 'react'
import { Modal, Alert, Checkbox } from 'simple-core-ui'
import VendorsSelect from 'contacts/VendorsSelect/VendorsSelect'
import { makePostRequest, handleError } from 'utils/api'
import s from '../../MatterDetailContainer.scss'
import APP_ACT from 'app/actions'

const numReferredVendors = window.serverContext.get('num_referred_vendors')

const initialState = { selectedVendors: null, allVendors: null, isLoading: false }

class ManualRequestModal extends Component {
  state = initialState

  updateVendors = vendors => {
    this.setState(({ selectedVendors }) => {
      return {
        selectedVendors: vendors
      }
    })
  }

  openModal = () => {
    this.setState({
      isVisible: true
    })
  }

  selectAllVendors = () => {
    this.setState(prevState => {
      return { allVendors: !prevState.allVendors, selectedVendors: null }
    })
  }

  submitForm = async () => {
    // send request
    const { matterId } = this.props
    const url = `/accruals/manual/${matterId}/`
    const { isLoading, ...params } = this.state
    const { cancelCb, submitCb } = this.props
    this.setState({ isLoading: true })
    try {
      const response = await makePostRequest(url, params)
      window.store.dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          text: 'Sent Manual Accrual Requests',
          message: 'Sent Manual Accrual Requests',
          level: 'success'
        }
      })
    } catch (e) {
      handleError(e, 'Could not send manual accrual request.')
    }

    cancelCb()
  }

  render() {
    const { isVisible, matterId, cancelCb, periodEnd } = this.props

    return (
      <>
        <span data-tip="Can not request accruals with no referred vendors">
          <ReactTooltip effect="solid" type="light" className={s.tooltip} />
        </span>
        <Modal
          title="Manually Request Accruals for this Matter?"
          content={
            <Fragment>
              <Alert
                message={
                  <p>
                    This will request accruals from vendors that are active and have accruals turned
                    on for the period ending: <strong>{periodEnd}</strong>
                  </p>
                }
                status="info"
              />
              <h2 />
              <VendorsSelect
                id="vendor-selection"
                value={this.state.selectedVendors}
                onChange={this.updateVendors}
                multi
                isPortal={false}
                maxHeight="200px"
                placeholder="Select vendors..."
                disabled={this.state.allVendors}
                requestParams={{ referred_matter_id: matterId }}
              />
              <section className="m-t-sp300" style={{ display: 'flex' }}>
                <Checkbox
                  isChecked={this.state.allVendors}
                  triggerCheckbox={this.selectAllVendors}
                  size="md"
                />
                <span className="m-l-sp300">Submit to All Vendors</span>
              </section>
            </Fragment>
          }
          confirmText="Request"
          cancelText="Cancel"
          isVisible={isVisible}
          confirmCb={this.submitForm}
          cancelCb={cancelCb}
          size="md"
          isDisabled={!(this.state.selectedVendors || this.state.allVendors)}
          isLoading={this.state.isLoading}
        />
      </>
    )
  }
}

export default ManualRequestModal
