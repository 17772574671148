import { useState } from 'react'
import { format, getYear, isBefore } from 'date-fns'
import { DataTableWrapper, Ellipsis } from 'simple-core-ui'
import { CourtRule, Cell } from './types'

interface Params {
  courtRules: CourtRule[]
  expanded: boolean
}

interface TableParams {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: 100,
  ordering: { columnKey: 'triggerDate', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const columns = [
  {
    columnKey: 'name',
    content: 'Court Rule Name',
    isSortable: false,
    isFilterable: true,
    render: (cell: Cell, row: CourtRule) => {
      return (
        <a
          href={`/v2/matters/${row?.matterId}?category=review&subtab=courtRules&tab=events#columnKey=trigger_date&isDesc=0&search=&page_number=1&page_size=50&category=review&needs_review=is_set`}
        >
          <Ellipsis width={190}>{typeof cell.content === 'string' && cell.content}</Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'matterName',
    content: 'Related to',
    isSortable: false,
    isFilterable: true,
    render: (cell: Cell, row: CourtRule) => {
      return (
        <a style={{ cursor: 'pointer' }} href={`/v2/matters/${row?.matterId}?tab=overview`}>
          <Ellipsis width={160} lines={1}>
            {typeof cell.content === 'string' && cell.content}
          </Ellipsis>
        </a>
      )
    }
  },
  {
    columnKey: 'triggerDate',
    content: 'Trigger Date',
    isSortable: true,
    isFilterable: true,
    render: (cell: Cell) => {
      return (
        <span style={isBefore(cell.content as Date, new Date()) ? { color: '#bb342f' } : {}}>
          {!(cell.content instanceof Date)
            ? '--'
            : format(
                cell.content,
                getYear(cell.content) === getYear(new Date()) ? 'MMM dd' : 'MMM dd yyyy'
              )}
        </span>
      )
    }
  },
  {
    columnKey: 'eventsCount',
    content: 'Events From Rule',
    isSortable: false,
    isFilterable: true,
    render: (cell: Cell, row: CourtRule) => {
      if (cell.content === '----') {
        return '--'
      }
      const ruleName = encodeURIComponent(row.name)
      return (
        <a
          style={{ cursor: 'pointer' }}
          href={`/v2/matters/${row?.matterId}?tab=events#columnKey=start_date&isDesc=0&search=&page_number=1&page_size=10&category=all&court_rule_names=is:${ruleName}`}
        >
          {cell.content as string}
        </a>
      )
    }
  }
]

const CourtRulesUpdates = ({ courtRules, expanded }: Params) => {
  const [params, setParams] = useState(initialParams)

  return (
    <DataTableWrapper
      params={params}
      categories={[]}
      columns={columns}
      rows={courtRules}
      updateTable={(params: TableParams) => setParams(params)}
      panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
      tableHeight={expanded ? '60vh' : '320px'}
      hasActionsHeader={false}
      hasPagination={false}
      fixedHeader
    />
  )
}

export default CourtRulesUpdates
