import { Fragment } from 'react'
import { If, Button } from 'simple-core-ui'
import { FaTrash } from 'react-icons/fa'

import s from './Allocations.scss'
import { Row } from './Row'

const MAX_DROPDOWN_HEIGHT = 250
const DROPDOWN_POSITION = 'absolute'

const Allocations = ({
  attributes,
  allocations,
  addAllocation,
  removeAllocation,
  changeAllocation,
  getOptions,
  readOnly
}) => (
  <Fragment>
    <div className={s.list}>
      {allocations.map((allocation, index) => (
        <div key={index} className={s.allocationRowWrapper}>
          <Row
            attributes={attributes}
            changeAllocation={changeAllocation}
            allocationIndex={index}
            readOnly={readOnly}
            allocation={allocation}
            getOptions={getOptions}
            dropdownMenuPosition={DROPDOWN_POSITION}
            maxMenuHeight={MAX_DROPDOWN_HEIGHT}
          />
          {allocations.length > 1 && !readOnly && (
            <div className={s.remove}>
              <FaTrash className={s.trashIcon} onClick={() => removeAllocation(index)} />
            </div>
          )}
        </div>
      ))}
    </div>
    <If condition={!readOnly}>
      <div className={s.addAllocation}>
        <Button onClick={addAllocation} isSecondary>
          + Allocation
        </Button>
      </div>
    </If>
  </Fragment>
)

export default Allocations
