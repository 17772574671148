import ModalField from './ModalField'
import { PurchaseOrderSelect } from 'common/Selects'

const EditPOForm = ({ currentPO, selectedVendor, setCurrentPO }) => {
  return (
    <ModalField label="Purchase Order">
      <PurchaseOrderSelect
        value={currentPO}
        selectedVendorId={selectedVendor}
        onChange={setCurrentPO}
        isPortal={false}
        isClearable
        paginated
      />
    </ModalField>
  )
}

export default EditPOForm
