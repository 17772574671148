import moment from 'moment'
import { DATE_FORMATS } from 'utils/constants'

const { DEFAULT_DATE } = DATE_FORMATS

export const curMom = () => {
  return moment()
}

export const safeFormatDate = (date, format) =>
  moment(date).isValid() ? moment(date).format(format) : date

export const dateStr = (date, fmt = DEFAULT_DATE) => (date ? moment(date).format(fmt) : '')

export const isBefore = (date1, date2) => {
  if (date2) {
    return moment(date1).isBefore(date2)
  } else {
    return true
  }
}

export const isAfter = (date1, date2) => {
  if (date2) {
    return moment(date1).isAfter(date2)
  } else {
    return true
  }
}
