import { RhsTypes } from 'simple_review/@types/editor'
import { CanSerialize } from '../can-serialize'
import { NonListConstant } from './constant-model'

export class CurrencyConstantMapper implements CanSerialize {
  type = 'currency'
  constructor(private _label: string, private _value: number | string) {}

  toJSON(): string {
    const data = {
      constant: {
        label: this._label,
        value: this._value
      },
      type: this.type,
      sub_type: ''
    }

    return JSON.stringify(data)
  }

  fromJSON(): NonListConstant {
    return {
      type: this.type,
      isList: false,
      label: this._label,
      value: this._value,
      subType: '',
      rhsInputType: RhsTypes.Currency
    }
  }
}
