import { useState, useEffect, useRef } from 'react'
import BaseFilter from './BaseFilter'
import SearchSelectBody from './SearchSelectBody'
import s from '../SearchFilter.scss'

const RadioFilter = ({
  onSelect,
  onClear = () => {},
  onSubmit = () => {},
  reset,
  title,
  hasConfirmation,
  options,
  initialValue,
  width,
  customLabelFn,
  onChangeCb,
  isDisabled,
  onCancelCb,
  testid
}) => {
  const [selection, setSelection] = useState(initialValue)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setSelection('')
    }
  }, [reset])

  const onCancel = () => {
    onCancelCb && onCancelCb()
    setSelection(initialValue)
  }

  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      onCancel={onCancel}
      initialValue={initialValue}
      customLabel={selected => {
        if (selected) {
          if (customLabelFn) return customLabelFn()
          return selected.alternativeLabel || selected.label
        }
        return title
      }}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen) => {
        return (
          <SearchSelectBody
            width={width}
            onClear={() => {
              onClear()
              setSelection('')
            }}
            onSubmit={() => {
              onSubmit()
              setFilterSelection(selection)
            }}
            isDisabled={isDisabled}
          >
            <div>
              {options.map(option => (
                <div key={option.value} className={s.radio}>
                  <label>
                    <input
                      type="radio"
                      value={option.value}
                      checked={selection && selection.value === option.value}
                      onChange={e => {
                        onChangeCb && onChangeCb(e)
                        setSelection(option)
                      }}
                    />
                  </label>

                  {option.renderLabel ? option.renderLabel() : option.label}
                </div>
              ))}
            </div>
          </SearchSelectBody>
        )
      }}
    />
  )
}

export default RadioFilter
