import { XeditableContainer, If } from 'simple-core-ui'
import s from './Header.scss'

const Header = ({ readOnly, resetDefault }) => (
  <header className={s.header}>
    <span>{readOnly ? 'Default Shared Evaluators' : 'Set Default Shared Evaluators'}</span>
    <If condition={!readOnly}>
      <section className={s.reset}>
        <XeditableContainer
          title="Are you sure you want to reset to the default? (Matter Lead and Matter Approver)"
          placeholder="Reset"
          withoutUnderline
          position="left"
          setValueCb={resetDefault}
          renderForm={() => null}
        />
      </section>
    </If>
  </header>
)

export default Header
