import { If } from 'simple-core-ui'
import { InnerSidebarFilter, InnerSidebar } from 'components'
import s from './Sidebar.scss'

const Sidebar = ({
  showSidebar,
  showFilterInput,
  filterTerm,
  list,
  selectedForm,
  toggleDrawer,
  opened,
  onFilterChange,
  selectAndGetForm
}) => (
  <div className={s.container}>
    <If condition={showSidebar}>
      <InnerSidebar title="Select Form" toggleDrawer={toggleDrawer} opened={opened} closable>
        <If condition={showFilterInput}>
          <InnerSidebarFilter filterTerm={filterTerm} onChange={onFilterChange} />
        </If>
        <div className={s.formListSidebarWrapper} data-testid="form_list_sidebar">
          {list.map(form => {
            const selectedCss = selectedForm && selectedForm.id === form.id ? s.selected : ''
            return (
              <div
                data-testid={`${form.id}_form_selection`}
                key={form.id}
                className={`${s.form} ${selectedCss}`}
                onClick={() => selectAndGetForm(form)}
              >
                <p className={s.formName}>{form.name.trim()}</p>
                <small>{form.description}</small>
              </div>
            )
          })}
        </div>
      </InnerSidebar>
    </If>
  </div>
)

export default Sidebar
