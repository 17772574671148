import { Action, AvailableAction } from 'simple_review/@types/api'
import { BaseSerializer } from './base.serializer'

const getAvailableActionIndex = (
  action: Action,
  availableActions: Array<AvailableAction>
): number => {
  return availableActions.findIndex(availableAction => availableAction.name === action.name)
}

const getActionDisplayName = (
  availableActionIndex: number,
  availableActions: Array<AvailableAction>
): string => {
  const availableAction = availableActions[availableActionIndex]
  return availableAction ? availableAction.display_name : ''
}

const getParamDisplayName = (
  paramName: string,
  availableActions: Array<AvailableAction>,
  availableActionIndex: number
): string => {
  const availableAction = availableActions[availableActionIndex]
  const param = availableAction.params.find(param => param.name === paramName)

  return param ? param.display_name : ''
}

const getActionToString = (action: Action, availableActions: Array<AvailableAction>) => {
  const actionIndex = getAvailableActionIndex(action, availableActions)

  return (
    <>
      <q>
        <strong>{getActionDisplayName(actionIndex, availableActions)}</strong>
      </q>{' '}
      <span>
        {action.params.map((arg, index) => {
          return (
            <span key={index}>
              <span>
                {index === action.params.length - 1 || index === 0 ? 'with ' : ', '}
                <span>{getParamDisplayName(arg.param_name, availableActions, actionIndex)} </span>
              </span>
              <q>
                <strong>{arg.value.label}</strong>
              </q>{' '}
              {index !== action.params.length - 1 && <span>and </span>}
            </span>
          )
        })}
      </span>
    </>
  )
}

export function RuleActionEditSerializer(
  oldValue: Action | null,
  newValue: Action,
  availableActions: Array<AvailableAction>
): BaseSerializer {
  return {
    toString() {
      return (
        <div>
          {oldValue && <p>Removed Action {getActionToString(oldValue, availableActions)}</p>}
          {newValue && <p>Added Action {getActionToString(newValue, availableActions)}</p>}
        </div>
      )
    }
  }
}
