import { put, takeLatest } from 'redux-saga/effects'
import APP_ACT from 'app/actions'
import ACT from './actions'

import { makeGetRequest, makePostRequest, makePutRequest } from 'utils/api'
import { serializeParams, toMatters, toSummary } from './serializers'

function* matterListFetch(action) {
  const { params, status } = action.payload

  try {
    const response = yield makePostRequest(
      '/manage/matters/v2/matter_list/',
      serializeParams(params)
    )

    yield put({
      type: ACT.MATTER_LIST_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { matters: toMatters(response), params, status }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching matter list.',
        level: 'error'
      }
    })
  }
}

function* matterSummaryFetch(action) {
  const params = action.payload?.params
  const requestParams = params ? { params: { ...serializeParams(params) } } : null
  try {
    const summary = yield makeGetRequest('/manage/matters/v2/matter_list_summary/', requestParams)

    yield put({
      type: ACT.MATTER_SUMMARY_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { summary: toSummary(summary), ...summary }
    })
  } catch (error) {
    if (error.response.status === 403) {
      window.location.href = '/v2/matters/list'
      return
    }
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching matter summary.',
        level: 'error'
      }
    })
  }
}

function* deleteMatter(action) {
  const { id, selectedTab } = action.payload
  try {
    yield makePostRequest(`/manage/matters/${id}/delete`)

    yield put({
      type: ACT.MATTER_LIST_DELETE_SUCCESS,
      payload: { id, selectedTab }
    })

    yield put({
      type: ACT.MATTER_SUMMARY_FETCH_REQUESTED
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue deleting matter.',
        level: 'error'
      }
    })
  }
}

function* updateMatter(action) {
  const { practiceArea, matterLead, id, selectedTab } = action.payload
  try {
    yield makePutRequest(`/manage/matters/v2/matter_fields_update/${id}`, {
      ...(practiceArea ? { practice_area: practiceArea.value } : {}),
      ...(matterLead ? { lead: matterLead.value } : {})
    })

    yield put({
      type: ACT.UPDATE_MATTER_SUCCESS,
      payload: { practiceArea, matterLead, id, selectedTab }
    })
    yield put({
      type: ACT.MATTER_SUMMARY_FETCH_REQUESTED
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        message: 'Matter updated successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue updating matter.',
        level: 'error'
      }
    })
  }
}

const mattersSagas = [
  takeLatest(ACT.MATTER_LIST_FETCH_REQUESTED, matterListFetch),
  takeLatest(ACT.MATTER_SUMMARY_FETCH_REQUESTED, matterSummaryFetch),
  takeLatest(ACT.MATTER_LIST_DELETE_REQUESTED, deleteMatter),
  takeLatest(ACT.UPDATE_MATTER_REQUESTED, updateMatter)
]

export default mattersSagas
