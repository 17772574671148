import { Fragment } from 'react'
import { Button } from 'simple-core-ui'
import { AnswersTable } from '../common/AnswersTable'
import { PermissionsNotification } from '../common/PermissionsNotification'
import { commentsTableSerializer } from 'vendors/scorecard/serializers'
import s from './AdditionalComments.scss'

const AdditionalComments = ({ commentsNumber, comments, showTable, toggleDetails }) => (
  <div className={s.container}>
    <span className={s.label}>Additional Comments</span>
    <i>({commentsNumber} responses)</i>
    {commentsNumber > 0 && (
      <Button onClick={toggleDetails} isSecondary style={{ float: 'right' }}>
        {showTable ? 'Hide Details' : 'See Details'}
      </Button>
    )}
    <div className={s.clearfix} />

    {showTable && (
      <Fragment>
        {comments.permissions_warning && <PermissionsNotification />}
        <AnswersTable rowsSerializer={commentsTableSerializer} answers={comments.list} />
      </Fragment>
    )}
  </div>
)

export default AdditionalComments
