import AsyncSelect from 'react-select/async'
import { toReactSelect } from './serializers'
import { fetchVendors } from './utils'
import debounce from 'debounce-promise'

const VendorsSelect = ({
  id,
  value,
  onChange,
  filterOptions,
  disabled,
  className,
  isVendorScoped,
  scopeId,
  multi,
  placeholder,
  requestParams,
  time = 500,
  ariaLabel
}) => {
  const CUSTOM_OPTION = { text: isVendorScoped ? 'Other' : 'Custom...', id: -1 }

  const loadOptions = async inputValue => {
    const vendors = await fetchVendors(inputValue, requestParams, isVendorScoped, scopeId)
    return toReactSelect(
      isVendorScoped ? [...vendors.results, CUSTOM_OPTION] : [CUSTOM_OPTION, ...vendors.results]
    )
  }

  const debouncedLoadOptions = debounce(loadOptions, time, {
    leading: false
  })

  return (
    <AsyncSelect
      id={id}
      className={className}
      name="vendor-select"
      placeholder={placeholder}
      noOptionsMessage={() => 'No results...'}
      value={value}
      onChange={onChange}
      filterOption={filterOptions}
      loadOptions={value => debouncedLoadOptions(value)}
      isDisabled={disabled}
      cacheOptions
      isMulti={multi}
      isClearable
      defaultOptions
      aria-label={ariaLabel}
    />
  )
}

VendorsSelect.defaultProps = {
  maxHeight: '400px',
  placeholder: 'Select...',
  requestParams: {}
}

export default VendorsSelect
