import { useState } from 'react'
import { Button } from 'simple-core-ui'
import cn from 'classnames'
import s from './TimePicker.scss'
import Select from 'react-select'
import {
  generateTimeOptions,
  toOptions,
  convertTo12HourFormat,
  convertTo24HourFormat,
  setDefaultTime
} from './utils'
import { parse, isBefore, isEqual } from 'date-fns'
import { timezoneUtils } from 'utils/helpers'

interface Props {
  values: (string | null)[]
  className?: string
  placeholder?: string
  togglePopper?: () => void
  readOnly?: boolean
  onConfirm: (values: (string | null)[]) => void
  isRange?: boolean
  withLabel?: boolean
  withButtons?: boolean
  isValid?: (values: Date[]) => boolean
  hasDefaultValues?: boolean
  timezone?: string
}

const REGULAR_TIME_STRING_LENGTH = 5

const timeOptions = toOptions(generateTimeOptions())
const amPmOptions = [
  {
    label: 'AM',
    value: 'AM'
  },
  {
    label: 'PM',
    value: 'PM'
  }
]

const TimePickerComponent = ({
  className,
  values,
  onConfirm,
  togglePopper,
  readOnly,
  isRange,
  withLabel = true,
  withButtons = true,
  isValid,
  hasDefaultValues = true,
  timezone
}: Props) => {
  const [startTime, setStartTime] = useState<string | null>(
    hasDefaultValues || (values.length && values[0])
      ? convertTo12HourFormat(setDefaultTime(values[0]))[0]
      : null
  )
  const [amPmStart, setAmPmStart] = useState<string | null>(
    hasDefaultValues || (values.length && values[0])
      ? convertTo12HourFormat(setDefaultTime(values[0]))[1]
      : null
  )
  const [endTime, setEndTime] = useState<string | null>(
    hasDefaultValues || values.length
      ? convertTo12HourFormat(setDefaultTime(values[1], true))[0]
      : null
  )
  const [amPmEnd, setAmPmEnd] = useState<string | null>(
    hasDefaultValues || values.length
      ? convertTo12HourFormat(setDefaultTime(values[1], true))[1]
      : null
  )
  const [startTimeInputValue, setStartTimeInputValue] = useState<string | null>(null)
  const [endTimeInputValue, setEndTimeInputValue] = useState<string | null>(null)

  const onClear = () => {
    setStartTime(null)
    setEndTime(null)
    setAmPmStart(null)
    setAmPmEnd(null)
  }

  const isStartTimeAfterEndTime = () => {
    if (isRange && startTime && endTime && amPmStart && amPmEnd) {
      const startTimeDate = parse(convertTo24HourFormat(startTime, amPmStart), 'HH:mm', new Date())
      const endTimeDate = parse(convertTo24HourFormat(endTime, amPmEnd), 'HH:mm', new Date())

      if (!isBefore(startTimeDate, endTimeDate) || isEqual(startTimeDate, endTimeDate)) {
        return true
      }
    }

    return false
  }

  const formIsValid = () => {
    if (isRange && startTime && endTime && amPmStart && amPmEnd) {
      const startTimeDate = parse(convertTo24HourFormat(startTime, amPmStart), 'HH:mm', new Date())
      const endTimeDate = parse(convertTo24HourFormat(endTime, amPmEnd), 'HH:mm', new Date())

      return isValid?.([startTimeDate, endTimeDate]) ?? true
    }

    return true
  }

  const isButtonDisabled = () => {
    if (
      (!isRange && startTime && !amPmStart) ||
      (isRange && ((startTime && !amPmStart) || (endTime && !amPmEnd))) ||
      (isValid ? !formIsValid() : isStartTimeAfterEndTime())
    ) {
      return true
    }

    return Boolean(readOnly || (isRange && ((startTime && !endTime) || (!startTime && endTime))))
  }

  const onInputChange = (input: string, cb: (v: string) => void) => {
    // remove non-numeric characters
    let sanitizedInput = input.replace(/\D/g, '')

    if (sanitizedInput.length > 4) {
      // keep only four digits
      sanitizedInput = sanitizedInput.substring(0, 4)
    }

    if (sanitizedInput.length >= 1) {
      if (sanitizedInput.length < 2) {
        // add a leading "0" if there's only a single digit for hours
        sanitizedInput = +sanitizedInput > 1 ? '0' + sanitizedInput : sanitizedInput
      }

      if (sanitizedInput.startsWith('00')) {
        sanitizedInput = '12' + sanitizedInput.substring(2)
      }

      if (sanitizedInput.length >= 2) {
        // insert ":" after the first two digits
        sanitizedInput = sanitizedInput.substring(0, 2) + ':' + sanitizedInput.substring(2)
      }

      if (sanitizedInput.length >= 3) {
        // get the first digit of minutes
        const minutes = sanitizedInput.substring(3, 4)

        if (parseInt(minutes, 10) >= 6) {
          sanitizedInput = sanitizedInput.slice(0, -1) // Remove last character
        }

        let hours = parseInt(sanitizedInput.substring(0, 2), 10)
        if (hours > 12) {
          hours -= 12
          sanitizedInput = '0' + hours.toString() + ':' + minutes
        }
      }
    }

    cb(sanitizedInput)
  }

  const getValue = (time: string | null) => {
    if (time) {
      return (
        timeOptions.find(option => option.value === time) ?? {
          value: time,
          label: time
        }
      )
    }

    return null
  }

  return (
    <div className={cn(s.timePickerWrapper, className)}>
      <div className={s.main}>
        <div style={{ marginBottom: 30 }}>
          {withLabel && (
            <p style={{ fontWeight: 600, marginBottom: 5 }}>{isRange ? 'Start Time' : 'Time'}</p>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              aria-label="startTime"
              options={timeOptions}
              value={getValue(startTime)}
              inputValue={startTimeInputValue || ''}
              onChange={option => {
                setStartTime(option ? option?.value : null)
                setStartTimeInputValue(null)

                if (!withButtons) {
                  onConfirm?.([
                    option && amPmStart ? convertTo24HourFormat(option?.value, amPmStart) : null,
                    isRange && endTime && amPmEnd ? convertTo24HourFormat(endTime, amPmEnd) : null
                  ])
                }
              }}
              placeholder="HH:MM"
              isDisabled={readOnly}
              className={s.select}
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onInputChange={value =>
                onInputChange(value, (v: string) => {
                  if (v && v.length === REGULAR_TIME_STRING_LENGTH) {
                    setStartTime(v)

                    if (!withButtons) {
                      onConfirm?.([
                        v && amPmStart ? convertTo24HourFormat(v, amPmStart) : null,
                        isRange && endTime && amPmEnd
                          ? convertTo24HourFormat(endTime, amPmEnd)
                          : null
                      ])
                    }
                  }

                  setStartTimeInputValue(v)
                })
              }
            />
            <Select
              aria-label="startAmPmTime"
              options={amPmOptions}
              value={amPmStart ? amPmOptions.find(option => option.value === amPmStart) : null}
              onChange={option => {
                setAmPmStart(option ? option?.value : null)

                if (!withButtons) {
                  onConfirm?.([
                    startTime && option ? convertTo24HourFormat(startTime, option.value) : null,
                    isRange && endTime && amPmEnd ? convertTo24HourFormat(endTime, amPmEnd) : null
                  ])
                }
              }}
              placeholder="AM/PM"
              isDisabled={readOnly}
              className={s.select}
              isClearable
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            <b>{timezone || timezoneUtils.getUserTimezoneLabel()}</b>
          </div>
        </div>
        {isRange && (
          <div>
            <p style={{ fontWeight: 600, marginBottom: 5 }}>End Time</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                aria-label="endTime"
                options={timeOptions}
                value={getValue(endTime)}
                inputValue={endTimeInputValue || ''}
                onChange={option => {
                  setEndTime(option ? option?.value : null)
                  setEndTimeInputValue(null)

                  if (!withButtons) {
                    onConfirm?.([
                      startTime && amPmStart ? convertTo24HourFormat(startTime, amPmStart) : null,
                      isRange && option && amPmEnd
                        ? convertTo24HourFormat(option?.value, amPmEnd)
                        : null
                    ])
                  }
                }}
                placeholder="HH:MM"
                isDisabled={readOnly}
                className={s.select}
                isClearable
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onInputChange={value =>
                  onInputChange(value, (v: string) => {
                    if (v && v.length === REGULAR_TIME_STRING_LENGTH) {
                      setEndTime(v)

                      if (!withButtons) {
                        onConfirm?.([
                          startTime && amPmStart
                            ? convertTo24HourFormat(startTime, amPmStart)
                            : null,
                          isRange && v && amPmEnd ? convertTo24HourFormat(v, amPmEnd) : null
                        ])
                      }
                    }

                    setEndTimeInputValue(v)
                  })
                }
              />
              <Select
                aria-label="endAmPmTime"
                options={amPmOptions}
                value={amPmEnd ? amPmOptions.find(option => option.value === amPmEnd) : null}
                onChange={option => {
                  setAmPmEnd(option ? option?.value : null)

                  if (!withButtons) {
                    onConfirm?.([
                      startTime && amPmStart ? convertTo24HourFormat(startTime, amPmStart) : null,
                      isRange && endTime && option
                        ? convertTo24HourFormat(endTime, option.value)
                        : null
                    ])
                  }
                }}
                placeholder="AM/PM"
                isDisabled={readOnly}
                className={s.select}
                isClearable
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
              <b>{timezone || timezoneUtils.getUserTimezoneLabel()}</b>
            </div>
            {(isValid ? !formIsValid() : isStartTimeAfterEndTime()) && (
              <p style={{ color: 'red', marginTop: 10 }}>Start time must be before end time.</p>
            )}
          </div>
        )}
      </div>
      {withButtons && (
        <div className={s.footer}>
          <Button hasNewDesign onClick={onClear} isPrimary isOutline isDisabled={readOnly}>
            Clear
          </Button>
          <Button
            hasNewDesign
            onClick={() => {
              onConfirm?.([
                startTime && amPmStart ? convertTo24HourFormat(startTime, amPmStart) : null,
                isRange && endTime && amPmEnd ? convertTo24HourFormat(endTime, amPmEnd) : null
              ])
              togglePopper?.()
            }}
            isPrimary
            isDisabled={isButtonDisabled()}
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  )
}

export default TimePickerComponent
