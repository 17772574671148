import { Folder, Row, FolderPath } from './types'
import { standardRow } from './serializers'

export const insertFolderIntoTree = (
  node: Folder,
  newNode: Folder,
  parentId: Row['parentRowId']
) => {
  if (!parentId || node.id === parentId) {
    if (!node.children) {
      node.children = []
    }
    node.children.push(newNode)
    return node
  }
  if (node.children) {
    node.children.map(node => insertFolderIntoTree(node, newNode, parentId))
  }
  return node
}

export const deleteFromTree = (treeNode: Folder, nodeIdToDelete: Row['id']) => {
  if (treeNode.id === nodeIdToDelete) {
    return {} as Folder
  }
  if (treeNode.children) {
    treeNode.children = treeNode.children
      .map(childNode => deleteFromTree(childNode, nodeIdToDelete))
      .filter(childNode => Object.keys(childNode).length) as Folder[]
  }
  return treeNode
}

export const renameFolderName = (node: Folder, newNode: Folder) => {
  if (node.id === newNode.id) {
    node.name = newNode.name
    return node
  }
  node.children?.map(node => renameFolderName(node, newNode))
  return node
}

export const renderTableRows = (
  treeNode: Folder | null,
  expandedRowIds: Row['id'][],
  expandAllRows = false
) => {
  const tableRows: Row[] = []

  const recursivelyRender = (node: Folder, depth: number, parentRowId: Row['parentRowId']) => {
    const expanded = expandedRowIds.includes(node.id) || expandAllRows
    const row = standardRow(node, depth, parentRowId, expanded)
    tableRows.push(row)

    if (expanded) {
      node.children?.forEach(childNode => recursivelyRender(childNode, depth + 1, node.id))
    }
  }

  treeNode?.children?.forEach(node => recursivelyRender(node, -1, null))

  return tableRows
}

export const findNodeById = (folder: Folder, nodeId: number | string): Folder | null => {
  if (folder.id === nodeId) {
    return folder
  }

  if (folder.children) {
    for (const child of folder.children) {
      const foundNode = findNodeById(child, nodeId)
      if (foundNode) {
        return foundNode
      }
    }
  }

  return null
}

export const checkDuplicateFolderName = (folderNames: string[], folderName: string) => {
  return folderNames.includes(
    folderName
      .trim()
      .replace(/ +/g, ' ')
      .toLowerCase()
  )
}

export const extractNodesAtSameDepth = (rows: Row[], depth = -1) =>
  rows.filter(row => row.depth === depth).map(row => row.name.trim().toLowerCase())

export const expandChildRows = (
  rowList: Row[],
  atIndex: number,
  rowToExpand: Row,
  updateExpandedNodes: (param: Row['id']) => void
) => {
  // Append the child rows right after the expanded row
  updateExpandedNodes(rowToExpand.id)
  return rowList.splice(
    atIndex + 1,
    0,
    ...rowToExpand.children.map((child: Folder) =>
      standardRow(child, rowToExpand.depth + 1, rowToExpand.id)
    )
  )
}

export const collapseChildRows = (
  rowList: Row[],
  rowId: Row['id'],
  updateExpandedNodes: (param: Row['id']) => void
) => {
  const row = rowList.find(r => r.id === rowId)
  if (!row) return

  row.expanded = false
  updateExpandedNodes(row.id)

  const childRows = rowList.filter(r => r.parentRowId === row.id)

  childRows.forEach(r => {
    if (r.expanded) {
      collapseChildRows(rowList, r.id, updateExpandedNodes)
    }
    const idx = rowList.findIndex(row => row.id === r.id)
    rowList.splice(idx, 1)
  })
}

export const toggleRowCb = (
  rowId: Row['id'],
  toggle: boolean,
  rowList: Row[],
  setRowList: (param: Row[]) => void,
  updateExpandedNodes: (param: Row['id']) => void
) => {
  const idx = rowList.findIndex((row: Row) => row.id === rowId)
  if (idx === -1) return

  const copiedRows: Row[] = [...(rowList as Row[])]
  copiedRows[idx].expanded = toggle

  if (toggle) {
    expandChildRows(copiedRows, idx, copiedRows[idx], updateExpandedNodes)
  } else {
    collapseChildRows(copiedRows, rowId, updateExpandedNodes)
  }
  setRowList(copiedRows)
}

// this method recursively traverses the hierarchical structure to build the path
export const createPathArray = (
  lastBreadcrumbElementId: number,
  currentRowId: Row['id'],
  rowList: Row[],
  newFoldersPath: FolderPath,
  className: string
): FolderPath => {
  if (!currentRowId) return newFoldersPath
  const selectedRow = rowList.find(r => r.id === currentRowId)
  if (selectedRow) {
    newFoldersPath.push({
      title: selectedRow.name,
      id: selectedRow.id,
      className
    })
    if (selectedRow.parentRowId === lastBreadcrumbElementId) return newFoldersPath
  }
  return createPathArray(
    lastBreadcrumbElementId,
    selectedRow?.parentRowId as number,
    rowList,
    newFoldersPath,
    className
  )
}
