import s from './ChartInfo.scss'

const ChartInfo = ({ percent, responses }) => {
  if (Number(percent) > 0) {
    return (
      <p className={s.chartInfo} style={{ width: `${percent}%` }}>
        {percent}% ({Math.round((percent * responses) / 100)})
      </p>
    )
  }
  return null
}

export default ChartInfo
