import s from './JurisdictionRules.scss'
import { DataTableWrapper, Panel, Ellipsis, useLoading } from 'simple-core-ui'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { makeGetRequest } from 'utils/api'
import { toCourtRules } from './serializers'
import { Cell, CourtRule } from './types'

const initialState = {
  pageSize: 10,
  ordering: { columnKey: 'name', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Court Rule Name',
    isSortable: true,
    isFilterable: true,
    render: (cell: Cell) => {
      return (
        <Ellipsis className={s.nameCell}>
          {typeof cell.content === 'string' && cell.content}
        </Ellipsis>
      )
    }
  },
  {
    columnKey: 'matter',
    content: 'Related To',
    isSortable: false,
    isFilterable: false,
    render: (cell: Cell) => {
      if (typeof cell.content === 'object' && 'value' in cell.content) {
        return (
          <a href={`/v2/matters/${cell.content.value}?tab=overview`}>
            <Ellipsis width={120} lines={1}>
              {cell.content.label}
            </Ellipsis>
          </a>
        )
      }
      return '--'
    }
  },
  {
    columnKey: 'needsReview',
    content: 'Update',
    isSortable: false,
    isFilterable: false,
    render: (_cell: Cell, row: CourtRule) => {
      return row.needsReview ? <IoWarningOutline style={{ fontSize: 22, color: '#bb342f' }} /> : ''
    }
  },
  {
    columnKey: 'triggerEvent',
    content: 'Trigger Event',
    isSortable: false,
    isFilterable: false,
    render: (_cell: Cell, row: CourtRule) => {
      return row.triggerEvent ? row.triggerEvent.label : '--'
    }
  },
  {
    columnKey: 'triggerDate',
    content: 'Trigger Date',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'eventsCount',
    content: 'Events',
    isSortable: false,
    isFilterable: false
  }
]

const JurisdictionRules = () => {
  const [params, setParams] = useState(initialState)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const { id } = useParams<{ id: string }>()
  const [courtRules, setCourtRules] = useState<CourtRule[]>([])
  const [jurisdictionName, setJurisdictionName] = useState('')

  const fetchJurisdictionsList = async () => {
    try {
      const { rows, jurisdictionName } = await withLoadingLocks(
        makeGetRequest(`/event-management/calendar_rules/court-rules/jurisdiction/${id}`)
      )
      setCourtRules(toCourtRules(rows))
      setJurisdictionName(jurisdictionName)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchJurisdictionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  return (
    <Panel
      breadcrumbs={<Link to="../rules">&lt; Back to Jurisdictions</Link>}
      title="Court Rules"
      subtitle={
        <>
          <p style={{ fontWeight: 400, fontSize: 18, marginBottom: 10 }}>{jurisdictionName}</p>
          <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
          <b>CalendarRules</b> a third party application. Contact CalendarRules to add or remove
          jurisdictions.
          <p>
            <IoWarningOutline
              style={{ fontSize: 22, color: '#bb342f', position: 'relative', top: 5 }}
            />{' '}
            There are updates from <b>CalendarRules</b> that require action. They may change matter
            with court rules and the associated events.
          </p>
        </>
      }
      styles={{ boxShadow: 'none' }}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={courtRules}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasTooltip
        categoryKey="rules"
        hasActions={false}
      />
    </Panel>
  )
}

export default JurisdictionRules
