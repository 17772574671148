import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import swal from 'sweetalert'
import moment from 'moment'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import ACT from '../actions'
import { ListTableContainer } from 'containers'
import { FORM_STATUSES } from 'matters/requestForms/constants'
import { IoIosArrowBack } from 'react-icons/io'

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  { columnKey: 'lastModified', content: 'Last Modified', isSortable: true, isFilterable: true },
  { columnKey: 'requests_count', content: 'Requests count', isSortable: true, isFilterable: false },
  {
    columnKey: 'most_recent_request_date',
    content: 'Most recent request date',
    isSortable: true,
    isFilterable: false
  },
  { columnKey: 'status', content: 'Status', isSortable: true, isFilterable: true }
]

@withRouter
@connect(({ requestFormsList }) => {
  const { requestForms } = requestFormsList

  const forms = requestForms.map(form => {
    return {
      ...form,
      lastModified: form.modified_date,
      defaultDisplayColumn: 'name'
    }
  })

  return {
    requestForms: forms
  }
})
@connect(({ app }) => ({
  requestFormsIsLoading: app.loading.LEGAL_REQUEST_FORM_LIST_FETCH
}))
class LegalRequestFormsTableContainer extends Component {
  deleteForm = async formData => {
    const form = this.props.requestForms.find(form => form.id === formData.id)
    let title
    let text
    let successStatus
    let willDelete
    let onSuccessActionType
    let status

    if (form.status.toLowerCase() === FORM_STATUSES.ARCHIVED) {
      title = 'Restore Form?'
      text = 'This form will become published.'
      successStatus = 'restored'
      onSuccessActionType = ACT.LEGAL_REQUEST_FORMS_RESTORE_FORM
      status = FORM_STATUSES.PUBLISHED
    } else if (form.status.toLowerCase() === FORM_STATUSES.DRAFT) {
      title = 'Delete Form?'
      text = 'You will not be able to recover this form.'
      successStatus = 'deleted'
      onSuccessActionType = ACT.LEGAL_REQUEST_FORMS_DELETE_FORM
      status = FORM_STATUSES.DELETED
    } else {
      title = 'Archive Form?'
      text = 'Users will no longer be able to create matter requests with this form.'
      successStatus = 'archived'
      onSuccessActionType = ACT.LEGAL_REQUEST_FORMS_ARCHIVE_FORM
      status = FORM_STATUSES.ARCHIVED
    }
    willDelete = await swal({
      title: title,
      text: text,
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })
    if (willDelete) {
      this.props.dispatch({
        type: ACT.LEGAL_REQUEST_FORMS_UPDATE_STATUS_REQUESTED,
        payload: {
          id: form.id,
          form: {
            status
          },
          onSuccessActionType
        }
      })
      swal(`${successStatus.toUpperCase()}!`, `The form has been ${successStatus}.`, 'success')
    }
  }

  viewFormDetail = form => {
    this.props.router.navigate(`/v2/matters/requests/form/${form.id}`)
  }

  getNormalizedCellContent = (columnKey, row) => {
    const { cells } = row
    const cellContent = get(
      cells.find(cell => cell.columnKey === columnKey),
      'content',
      ''
    )

    if (!cellContent) {
      return cellContent
    }

    switch (columnKey) {
      case 'name':
        return cellContent.toLowerCase()
      case 'lastModified':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'requests_count':
        return Number(cellContent)
      case 'most_recent_request_date':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'status':
        return cellContent.toLowerCase()
      default:
        console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
        return ''
    }
  }

  render() {
    const { requestForms } = this.props
    const { DRAFT, PUBLISHED, ARCHIVED } = FORM_STATUSES

    const filterListItems = [
      { label: 'All', value: 'All' },
      { label: capitalize(DRAFT), value: [DRAFT] },
      { label: capitalize(PUBLISHED), value: [PUBLISHED] },
      { label: capitalize(ARCHIVED), value: [ARCHIVED] }
    ]

    const formsList = requestForms.map(form => {
      return {
        ...form,
        actions: {
          canDelete: true,
          useTrashcan: form.status.toLowerCase() === DRAFT,
          useRefresh: form.status.toLowerCase() === ARCHIVED,
          useArchive: form.status.toLowerCase() === PUBLISHED
        }
      }
    })

    return (
      <div data-testid="request_form_table">
        <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
          <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
          Back to Administration
        </a>
        <ListTableContainer
          title="Legal Request Forms"
          primaryAction={{ href: this.props.newFormLink, children: 'New Form' }}
          objects={formsList}
          columns={columns}
          actions
          filterListItems={filterListItems}
          clickRowAction={this.viewFormDetail}
          getNormalizedCellContent={this.getNormalizedCellContent}
          deleteRow={this.deleteForm}
          isLoading={!!this.props.requestFormsIsLoading}
        />
      </div>
    )
  }
}

export default LegalRequestFormsTableContainer
