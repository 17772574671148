import { compareAsc, compareDesc } from 'date-fns'

import { Version } from 'simple_review/@types/editor'
import { Sort } from './types'

export const sortVersions = ({ isDesc, columnKey }: Sort) => (a: Version, b: Version): number => {
  if (columnKey !== 'date') {
    return isDesc ? (a[columnKey] > b[columnKey] ? 1 : -1) : b[columnKey] > a[columnKey] ? 1 : -1
  }

  const compareFn = isDesc ? compareDesc : compareAsc
  return compareFn(new Date(a.rawDate), new Date(b.rawDate))
}
