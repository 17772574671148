import React, { Component } from 'react'
import { If, ModalContainer } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'
import { EditableSection } from 'components'
import { TemplateActions } from './TemplateActions'
import { RequestInformationContainer } from './RequestInformationDrawer'
import { TemplatePreview } from 'matters/templates/common/TemplatePreview'
import { connect } from 'react-redux'
import * as ACT from 'matters/templates/actions'
import s from './SelectionPageContentContainer.scss'
import cn from 'classnames'
import { navigateToProcessRequestsPage } from 'matters/templates/utils'
import ThemeContext from 'context/ThemeContext'
import { makeGetRequest } from 'utils/api'

@withRouter
@connect(({ app, matterTemplatesSelection, matterTemplates, legalRequest }) => {
  const { filterTerm, selectedTemplate, userFlowState } = matterTemplatesSelection
  return {
    template: matterTemplates,
    filterTerm,
    selectedTemplate,
    userId: app.credentials.user.id,
    userRole: app.credentials.user.role,
    legalRequest,
    userFlowState
  }
})
class SelectionPageContentContainer extends Component {
  state = {
    isEditing: false,
    templateHeight: 0,
    isConfidential: false,
    isDuplicateNameModalVisible: false
  }

  pageDescription = React.createRef()
  pageTitle = React.createRef()

  editContent = () => this.setState({ isEditing: !this.state.isEditing })

  getSelectedTemplateIndex = () =>
    this.props.list.findIndex(l => l.id === this.props.selectedTemplate.id)

  handleCreateMatter = async () => {
    const { legalRequest, userFlowState, template } = this.props
    const { isConfidential } = this.state
    const nonTemplateAttributes = legalRequest.attributes.filter(request => request.not_in_template)

    if (this.props.selectedTemplate) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_SELECT_REQUESTED,
        payload: {
          id: this.props.selectedTemplate.id,
          matterRequestId: userFlowState.id,
          nonTemplateAttributes: nonTemplateAttributes,
          templateAttributes: template,
          isConfidential
        }
      })
    }
  }

  createMatter = async () => {
    const { legalRequest } = this.props
    const matterName = legalRequest.attributes.find(request => request.id === 'matter_short_name')

    if (matterName?.value) {
      try {
        const response = await makeGetRequest(
          `/manage/matters/v2/check_duplicate_name?name=${matterName.value}`
        )
        this.handleCreateMatter()
      } catch (error) {
        if (error.response.status === 400) {
          this.setState({ isDuplicateNameModalVisible: true })
        } else {
          this.dispatch({
            type: 'API_ERROR',
            error
          })
        }
      }
    } else {
      this.handleCreateMatter()
    }
  }

  updateMatterForNewTemplate = () => {
    const { template, selectedTemplate, userFlowState } = this.props

    if (selectedTemplate) {
      this.props.dispatch({
        type: ACT.MATTER_UPDATE_FOR_NEW_TEMPLATE_REQUESTED,
        payload: {
          templateId: selectedTemplate.id,
          matterId: userFlowState.id,
          templateAttributes: template
        }
      })
    }
  }

  setAsDefault = () => {
    if (this.props.selectedTemplate) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_SET_USER_DEFAULT_REQUESTED,
        payload: {
          template: this.props.selectedTemplate,
          userId: this.props.userId
        }
      })
    }
  }

  saveChanges = description => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_SELECTION_PAGE_CHANGE_DESCRIPTION,
      payload: {
        description,
        pageKey: this.props.pageKey
      }
    })
    this.editContent()
    setTimeout(this.setTitleAndDescriptionHeight, 1000)
  }

  setTitleAndDescriptionHeight = () => {
    if (this.pageTitle.current || this.pageDescription.current) {
      const titleHeight = this.pageTitle.current ? this.pageTitle.current.clientHeight : 0
      const descHeight = this.pageDescription.current
        ? this.pageDescription.current.clientHeight
        : 0
      const templateHeight = titleHeight + descHeight

      this.setState({ templateHeight })
    }
  }

  componentDidMount() {
    this.setTitleAndDescriptionHeight()
  }

  navigateToRequestsList = () => {
    const navState = this.props.router.location.state
    const { router, dispatch } = this.props
    const { navigate } = router
    navigateToProcessRequestsPage(navState, navigate, dispatch)
  }

  rejectLegalRequest = () => {
    const { userFlowState, legalRequest, router, dispatch } = this.props
    const { navigate, location } = router
    const navState = location.state
    const comments = legalRequest.attributes.filter(request => request.id === 'comments')
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_REJECT_REQUESTED,
      payload: {
        requestId: userFlowState.id,
        comment: comments.length ? comments[0].value : '',
        navState,
        navigate,
        dispatch
      }
    })
  }

  toggleIsConfidential = value => {
    this.setState({ isConfidential: value })
  }

  renderTemplateActions = () => {
    const {
      legalRequest,
      navigateToFormPage,
      navigateToFormPageWithoutIds,
      noOfPreferred = 0,
      template,
      userFlowState
    } = this.props

    const navState = this.props.router.location.state
    // only display reject button if the legal requests react app is turned off
    const hideRejectButton = navState && navState.fromProcessPage

    return (
      <TemplateActions
        attributes={legalRequest.attributes}
        navigateToFormPage={navigateToFormPage}
        navigateToFormPageWithoutIds={navigateToFormPageWithoutIds}
        navigateToRequestsList={this.navigateToRequestsList}
        noOfPreferred={noOfPreferred}
        hideRejectButton={hideRejectButton}
        rejectLegalRequest={this.rejectLegalRequest}
        selectTemplate={this.createMatter}
        template={template}
        updateMatterForNewTemplate={this.updateMatterForNewTemplate}
        userFlowState={userFlowState}
      />
    )
  }

  render() {
    const {
      template,
      selectedTemplate,
      description,
      processedDescription,
      title,
      navigateToFormPage,
      navigateToFormPageWithoutIds,
      noOfPreferred = 0,
      list,
      fullContent
    } = this.props

    const { isConfidential } = this.state

    return (
      <div className={s.container}>
        <ThemeContext.Consumer>
          {({ state, triggerMenu }) => (
            <div
              className={cn(s.contentContainer, s.opened, {
                [s.fullContent]: fullContent,
                [s.withNavExpand]: !fullContent && state.isMenuExpanded,
                [s.fullContentWithNav]: fullContent && state.isMenuExpanded
              })}
            >
              <h2 ref={this.pageTitle} className={s.title}>
                {title}
              </h2>
              <If condition={list.length > 1}>
                {this.props.userRole === 'admin' ? (
                  <EditableSection
                    pageDescription={this.pageDescription}
                    content={description}
                    processedContent={processedDescription}
                    isEditing={this.state.isEditing}
                    editContent={this.editContent}
                    saveChanges={this.saveChanges}
                    discardChanges={this.editContent}
                  />
                ) : (
                  <h4 ref={this.pageDescription}>{processedDescription}</h4>
                )}
              </If>
              {selectedTemplate && (
                <TemplatePreview
                  templateHeight={this.state.templateHeight}
                  selectTemplate={this.createMatter}
                  updateMatterForNewTemplate={this.updateMatterForNewTemplate}
                  template={template}
                  setAsDefault={this.setAsDefault}
                  selectedTemplate={selectedTemplate}
                  noOfPreferred={noOfPreferred}
                  navigateToFormPage={navigateToFormPage}
                  navigateToFormPageWithoutIds={navigateToFormPageWithoutIds}
                  navigateToRequestsList={this.navigateToRequestsList}
                  rejectLegalRequest={this.rejectLegalRequest}
                />
              )}
            </div>
          )}
        </ThemeContext.Consumer>
        <RequestInformationContainer
          templateActions={this.renderTemplateActions()}
          noOfPreferred={noOfPreferred}
          isConfidential={isConfidential}
          toggleIsConfidential={this.toggleIsConfidential}
        />
        {this.state.isDuplicateNameModalVisible && (
          <ModalContainer
            title="Duplicate Matter Name"
            size="sm"
            contentStyle={{ minHeight: 'auto' }}
            hasNewButtons
            cancelCb={() => {
              this.setState({ isDuplicateNameModalVisible: false })
            }}
            confirmText="Yes"
            cancelText="No"
            confirmCb={() => this.handleCreateMatter()}
            content={<p>A matter with this name already exists, would you like to proceed?</p>}
          />
        )}
      </div>
    )
  }
}

export default SelectionPageContentContainer
