import { Condition, Operand, RuleVersionDifference } from 'simple_review/@types/api'
import { BaseSerializer } from './base.serializer'
import { RuleConditionEditOperandSerializer } from './rule-condition-edit-operand.serializer'
import { ContextState } from 'simple_review/@types/context'

export function RuleConditionEditSerializer(
  oldValue: Operand | Condition | null,
  newValue: Operand | Condition | null,
  type: RuleVersionDifference['type'],
  context: ContextState
): BaseSerializer {
  return {
    toString() {
      const serializedOldOperand = RuleConditionEditOperandSerializer(oldValue, context)
      const serializedNewOperand = RuleConditionEditOperandSerializer(newValue, context)

      return (
        <div>
          {newValue && (
            <p>
              {type === 'value' && !serializedOldOperand.isAI()
                ? 'After Edit: '
                : 'Added Condition '}
              <q>
                <strong>{serializedNewOperand.toString()}</strong>
              </q>
            </p>
          )}
          {oldValue && !serializedOldOperand.isAI() && (
            <p>
              {type === 'value' ? 'Before Edit: ' : 'Removed Condition '}
              <q>
                <strong>{serializedOldOperand.toString()}</strong>
              </q>
            </p>
          )}
        </div>
      )
    }
  }
}
