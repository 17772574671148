import { ModalContainer } from 'simple-core-ui'

const DeleteFile = ({ file, showDeleteFile, toggleDeleteFile, deleteFile }) => {
  return (
    <ModalContainer
      title={`Delete ${file.fileName} from the system?`}
      size="sm"
      isVisible={showDeleteFile}
      confirmText="Delete"
      cancelText="Cancel"
      confirmCb={deleteFile}
      cancelCb={toggleDeleteFile}
      content={
        <div>
          Delete will erase this version of the file. To access other versions, go to see other
          versions to download.
        </div>
      }
    />
  )
}

export default DeleteFile
