import pluralize from 'pluralize'
import { Fragment } from 'react'
import { FaPlus } from 'react-icons/fa'
import cloneDeep from 'lodash/cloneDeep'
import noop from 'lodash/noop'
import cn from 'classnames'

import { Button } from 'simple-core-ui'
import { validateCondition } from 'simple_review/editor/validators'
import { LogicalOperator } from 'simple_review/@types/common'
import { Condition as ConditionType, Operand as OperandType } from 'simple_review/@types/editor'
import { getBaseOperand, getShortId } from '../../constants'
import { Operand } from '../operand'
import { Op } from '../op'
import { Actions } from '../actions'
import { isCondition } from '../helpers'
import s from './Condition.scss'
import { useSimpleReviewContext } from 'simple_review/hooks'

const ADDITIONAL_PARAM_STYLE = {
  margin: '0 auto',
  marginTop: 4,
  display: 'flex'
}

interface Props {
  condition: ConditionType
  shouldShowInfo: boolean
  shouldShowGrouping?: boolean
  shouldIndent?: boolean
  isCheckedToGroup?: boolean
  isGray: boolean
  isReadOnly: boolean
  checkboxLabel?: string
  ruleName: string
  onChangeCondition(newCondition: ConditionType): void
  onCopyCondition(): void
  onRemoveCondition(conditionId: string): void
  onToggleGroupCheck?(): void
}

const Condition = ({
  condition,
  shouldShowInfo,
  shouldShowGrouping,
  shouldIndent,
  isCheckedToGroup,
  isGray,
  isReadOnly,
  checkboxLabel,
  ruleName,
  onChangeCondition,
  onCopyCondition,
  onRemoveCondition,
  onToggleGroupCheck
}: Props) => {
  const errors = useSimpleReviewContext().state.errors

  const isConditionValid = validateCondition(condition)

  const handleChangeOperand = (index: number) => (newOperand: ConditionType | OperandType) => {
    const newCondition = cloneDeep(condition)
    newCondition.operands[index] = newOperand
    onChangeCondition(newCondition)
  }

  const handleAddOperand = () => {
    const newCondition = cloneDeep(condition)
    newCondition.operands.push(getBaseOperand())
    onChangeCondition(newCondition)
  }

  const handleCopyOperand = (index: number) => {
    const newCondition = cloneDeep(condition)
    const copiedOperand = cloneDeep(newCondition.operands[index])
    if ('id' in copiedOperand) {
      copiedOperand.id = getShortId()
    }
    newCondition.operands.splice(index + 1, 0, copiedOperand)
    onChangeCondition(newCondition)
  }

  const handleRemoveOperand = (index: number) => () => {
    if (condition.operands.length > 1) {
      const newCondition = cloneDeep(condition)
      newCondition.operands.splice(index, 1)
      onChangeCondition(newCondition)
    } else {
      onRemoveCondition(condition.id)
    }
  }

  const handleChangeOp = (newOp: LogicalOperator) => {
    const newCondition = cloneDeep(condition)
    newCondition.op = newOp
    onChangeCondition(newCondition)
  }

  return (
    <div
      className={cn(s.container, { [s.indented]: shouldIndent, [s.gray]: isGray })}
      data-testid="condition"
    >
      {!isReadOnly && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {errors.length > 0 && (
            <div style={{ marginBottom: 20 }}>
              <p className={s.errorTitle}>
                The following {pluralize('condition', errors.length)}{' '}
                {pluralize('is', errors.length)} configured incorrectly:
              </p>
              {errors.map((error, index) => {
                return (
                  <div className={s.error} key={index}>
                    {error}
                  </div>
                )
              })}
            </div>
          )}
          <Actions
            isCheckboxShown={Boolean(shouldShowGrouping)}
            checkboxProps={{
              styles: { width: 28, height: 28 },
              isChecked: Boolean(isCheckedToGroup),
              triggerCheckbox: onToggleGroupCheck || noop
            }}
            checkboxLabel={checkboxLabel}
            conditionId={condition.id}
            isCopyDisabled={!validateCondition(condition)}
            onCopy={onCopyCondition}
            onDelete={() => onRemoveCondition(condition.id)}
          />
        </div>
      )}
      {condition.operands.map((operand, index) => {
        const isLast = condition.operands.length - 1 === index
        const isOperandCondition = isCondition(operand)

        return (
          <Fragment key={operand.id}>
            {isOperandCondition ? (
              <Condition
                condition={operand as ConditionType}
                shouldShowInfo={shouldShowInfo && index === 0}
                shouldIndent
                isGray={!isGray}
                isReadOnly={isReadOnly}
                ruleName={ruleName}
                onChangeCondition={handleChangeOperand(index)}
                onCopyCondition={() => handleCopyOperand(index)}
                onRemoveCondition={handleRemoveOperand(index)}
              />
            ) : (
              <Operand
                operand={operand as OperandType}
                shouldShowInfo={shouldShowInfo && index === 0}
                isReadOnly={isReadOnly}
                onChangeOperand={handleChangeOperand(index)}
                onCopyOperand={() => handleCopyOperand(index)}
                onRemoveOperand={handleRemoveOperand(index)}
              />
            )}
            {!isLast && (
              <Op isLeaf value={condition.op} isReadOnly={isReadOnly} onChangeOp={handleChangeOp} />
            )}
          </Fragment>
        )
      })}
      {!isReadOnly && (
        <Button
          style={ADDITIONAL_PARAM_STYLE}
          className={s.addOperand}
          isSecondary
          isDisabled={!isConditionValid}
          onClick={handleAddOperand}
        >
          <div className={s.btnContent}>
            <FaPlus size={12} />
            {/* `parameter` is the same as operand */}
            Add additional parameter
          </div>
        </Button>
      )}
    </div>
  )
}

export default Condition
