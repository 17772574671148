export const ROW_ACTIONS = {
  COPY: 'COPY',
  RENAME: 'RENAME',
  DOWNLOAD: 'DOWNLOAD',
  DELETE: 'DELETE'
} as const

export const vendorRestrictedColumns = ['fileStatus', 'share']

export const scanRestrictedActions = [ROW_ACTIONS.COPY, ROW_ACTIONS.DOWNLOAD]
