import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './Field.scss'

const Field = ({ label, error, isRequired, className, children }) => (
  <section className={classNames(s.container, { [error]: s.error, [className]: className })}>
    <label className={s.label}>
      <span>
        {label}
        {isRequired && (
          <abbr className={s.asterick} title={'required'}>
            {' * '}
          </abbr>
        )}
      </span>
      {error && <span className={s.error}>{error}</span>}
    </label>
    {children}
  </section>
)

Field.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

export default Field
