import { If } from 'simple-core-ui'

import s from '../styles/ReviewLadder.scss'

const ReviewEntryMembership = ({ name, date }) => (
  <section className={s.info} data-testid="review-entry-membership">
    <span data-testid={'review-entry-membership-name'} className={s.membership}>
      {name}
    </span>
    <If condition={date}>
      {' '}
      <small data-testid={'review-entry-membership-date'} className={s.timestamp}>
        {date}
      </small>
    </If>
  </section>
)

export default ReviewEntryMembership
