import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import ACT from './actions'
import { fromFragment, toFragment } from './list/serializers'

const DEFAULT_TAB = 'Active'

let defaultParams = {
  pageSize: 50,
  ordering: { columnKey: 'vendor_name', isDesc: false },
  search: '',
  filters: {},
  page: 1,
  category: DEFAULT_TAB
}

if (!!window.location.hash) {
  defaultParams = fromFragment(window.location.hash, defaultParams)
}

export const initialState = {
  vendorList: {
    results: []
  },
  vendorListParams: { ...defaultParams },
  vendorListSummary: {},
  vendorPermissions: {},
  createForm: {
    vendorName: null,
    vendorContactEmail: null,
    vendorContactPhone: null,
    vendorContactName: null,
    vendorUrl: null,
    errors: {}
  },
  vendorListFragment: window.location.hash.slice(1)
}

const vendorsReducer = createReducer(initialState, {
  [ACT.VENDOR_LIST_FETCH_SUCCESS](state, action) {
    const { vendors, params } = action.payload

    const fragment = toFragment(params)

    return {
      ...state,
      vendorList: vendors,
      vendorListParams: params,
      vendorListFragment: fragment
    }
  },
  [ACT.VENDOR_LIST_RESET](state) {
    return {
      ...state,
      vendorList: initialState.vendorList
    }
  },

  [ACT.VENDOR_SUMMARY_FETCH_SUCCESS](state, action) {
    const {
      status_count,
      page_title,
      attribute_name,
      back_link,
      permissions: {
        allow_cg_invoicing,
        enable_edit_vendor,
        enable_manage_vendor_groups,
        has_diversity_reporting,
        require_invoice_pdf
      }
    } = action.payload
    return {
      ...state,
      vendorListSummary: status_count,
      pageTitle: page_title,
      attributeName: attribute_name,
      backLink: back_link,
      vendorPermissions: {
        allowCgInvoicing: allow_cg_invoicing,
        enableEditVendor: enable_edit_vendor,
        enableManageVendorGroups: enable_manage_vendor_groups,
        enableDiversityReporting: has_diversity_reporting,
        requireInvoicePDF: require_invoice_pdf
      }
    }
  },

  [ACT.ADD_NEW_VENDOR_REQUEST_SUCCESS](state) {
    return {
      ...state,
      createForm: {
        ...initialState.createForm
      }
    }
  },

  [ACT.UPDATE_ADD_VENDOR_FORM](state, action) {
    return {
      ...state,
      createForm: {
        ...state.createForm,
        ...action.payload
      }
    }
  },

  [ACT.UPDATE_VENDOR_SUCCESS](state, action) {
    const { id, vendor_lead, status } = action.payload
    const index = state.vendorList.results.findIndex(r => r.id === id)
    return update(state, {
      vendorList:
        status && state.vendorListParams.category !== 'All'
          ? {
              results: { $splice: [[index, 1]] },
              total: { $set: state.vendorList.total - 1 }
            }
          : status
          ? {
              results: {
                [index]: {
                  status: { $set: status }
                }
              }
            }
          : {
              results: {
                [index]: {
                  vendor_lead: {
                    $set: {
                      display: vendor_lead ? vendor_lead.label : null,
                      id: vendor_lead ? vendor_lead.value : null
                    }
                  }
                }
              }
            }
    })
  },

  [ACT.UPDATE_VENDORS_SUCCESS](state, action) {
    const { vendor_ids } = action.payload
    const updates = action.payload.update
    let vendors,
      count = 0
    if (updates.status && state.vendorListParams.category !== 'All') {
      vendors = state.vendorList.results.filter(vendor => {
        if (vendor_ids.includes(vendor.id)) {
          count = count + 1
          return false
        }
        return true
      })
    } else {
      vendors = state.vendorList.results.map(vendor => {
        if (vendor_ids.includes(vendor.id)) {
          return {
            ...vendor,
            ...updates
          }
        } else {
          return vendor
        }
      })
    }
    return update(state, {
      vendorList: {
        results: {
          $set: vendors
        },
        total: { $set: state.vendorList.total - count }
      }
    })
  }
})

export default vendorsReducer
