import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'

import {
  Button,
  DataTableWrapper,
  Ellipsis,
  Modal,
  AiIcon,
  ConditionalIcon,
  useLoading,
  withConfirmation
} from 'simple-core-ui'
import { useAppTabs } from 'hooks'
import APP_ACT from 'app/actions'
import ACT from 'rules/actions'
import {
  ENABLED_ENGINES,
  ENGINE,
  ENGINE_LABEL,
  QUERY_PARAMS,
  IVR_PAGE_TITLE
} from 'rules/constants'
import { makeBlobRequest, makeGetRequest, makePostRequest } from 'utils/api'
import { updateLinkParam } from 'utils/helpers'
import { decodeFilterSearch, encodeFilterSearch, serializeParams } from 'simple_review/serializers'
import { Filters, RuleListRow, TableOptions } from 'simple_review/@types/list'
import { RuleType } from 'simple_review/@types/common'
import StatusPill from './StatusPill'
import { ActionsPopover, ActionsPopoverAI } from './ActionsPopover'
import RuleExpandableContent from './RuleExpandableContent'
import CustomCategory from './CustomCategory'
import FiltersContainer from './FiltersContainer'
import { COLUMNS, INIT_CATEGORIES } from './TableConfigs'
import s from './SimpleReviewList.scss'
import { AiRule } from 'simple_review/@types/api'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { getActiveRuleModules } from 'rules/utils'
import { IoIosArrowBack } from 'react-icons/io'

const ENGINE_TABS = ENABLED_ENGINES.map(engine => ({
  label: ENGINE_LABEL[engine],
  value: engine
}))

const activeRuleModules = getActiveRuleModules()

/*
SIMPLEREVIEW is a combination of 3 types of rules: IIW rules, AI rules, and
AI + IIW Rules. Therefore, we have conditional logic in here to enable/disable
based on which module_access toggles are turned on.
*/

const SimpleReviewList = () => {
  const [rowList, setRowList] = useState<Array<RuleListRow>>([])
  const [reset, setReset] = useState(0)
  const [filteredEntries, setFilteredEntries] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const { state, updateParams } = useSimpleReviewContext()
  const params = state.filterSearch

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, withLoadingLocks] = useLoading()

  const addRule = () => {
    navigate('/v2/rules/simplereview/new')
  }

  const getTableData = async (params: Filters) => {
    updateParams(params)

    try {
      const response = await withLoadingLocks(
        makeGetRequest(`/client/rules/`, {
          params: serializeParams(params)
        })
      )
      const newRowList = response.rows
      setRowList(newRowList)
      setFilteredEntries(response.filteredEntries)
      setTotalEntries(response.totalEntries)
    } catch (e) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(e as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  const updateTable = (tableOpts: TableOptions) => {
    getTableData({ ...params, ...tableOpts })
    // Update query params
    navigate({ hash: encodeFilterSearch(tableOpts) }, { replace: true })
  }

  const handleFilters = (filterObj: Filters, r?: number) => {
    r && setReset(r)
    updateTable({ ...params, ...filterObj })
  }

  useEffect(() => {
    handleFilters(decodeFilterSearch(window.location.hash))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateEngine = (engine: string) => {
    updateLinkParam({ key: QUERY_PARAMS.ENGINE, value: engine })
    dispatch({
      type: ACT.UPDATE_RULE_ENGINE,
      payload: engine
    })
    navigate(`/v2/rules/list?engine=${engine}`)
  }

  useAppTabs({ tabs: ENGINE_TABS, selected: ENGINE.SIMPLE_REVIEW, onClick: updateEngine })

  const cloneRule = async (id: string) => {
    try {
      const ruleToCopy = await withLoadingLocks(makeGetRequest(`/client/rules/${id}/`))
      const newRule = {
        ...ruleToCopy,
        id: null,
        name: `(Clone) ${ruleToCopy.name}`.slice(0, 150),
        is_draft: true
      }
      const createdRule = await withLoadingLocks(
        makePostRequest('/rules/invoice_validation/save_webspec/', newRule)
      )

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `${ruleToCopy.name} was cloned successfully`,
          level: 'success'
        }
      })

      navigate(`/v2/rules/simplereview/${createdRule.id}?cloned`)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: {
          ...(error as object),
          errorTitle: 'Error'
        }
      })
    }
  }

  const deleteRule = withConfirmation(
    async (id: number) => {
      try {
        await withLoadingLocks(
          makePostRequest('/rules/invoice_validation/delete/', {
            ruleIds: [id]
          })
        )
        await getTableData(params)
      } catch (e) {
        dispatch({
          type: 'API_ERROR',
          error: {
            ...(e as object),
            errorTitle: 'Error'
          }
        })
      }
    },
    {
      title: 'Delete',
      text: 'Are you sure you want to delete this rule?',
      className: 'iiw-swal-design'
    }
  )

  const handleToggleStatus = (id: string, isActive: boolean) => {
    return withConfirmation(
      async () => {
        try {
          const rule: AiRule = await withLoadingLocks(makeGetRequest(`/client/rules/${id}/`))
          const rulePayload = { ...rule }
          rulePayload.is_draft = !isActive

          await withLoadingLocks(
            makePostRequest('/rules/invoice_validation/save_webspec/', rulePayload)
          )

          dispatch({
            type: APP_ACT.PUSH_NOTIFICATION,
            payload: {
              title: `Rule has been successfully ${isActive ? 'activated' : 'deactivated'}`,
              level: 'success'
            }
          })

          getTableData(params)
        } catch (err) {
          dispatch({
            type: 'API_ERROR',
            error: {
              ...(err as object),
              errorTitle: 'Error'
            }
          })
        }
      },
      {
        title: isActive ? 'Activate' : 'Deactivate',
        text: `Are you sure you want to ${isActive ? 'activate' : 'deactivate'} this rule?`,
        className: 'iiw-swal-design'
      }
    )()
  }

  const rows = useMemo(
    () =>
      rowList.map((row, index) => {
        return {
          id: row.id,
          cells: [
            {
              columnKey: 'name',
              content: (
                <Link to={`/v2/rules/simplereview/${row.id}`}>
                  <Ellipsis>{row.name || '---'}</Ellipsis>
                </Link>
              )
            },
            {
              columnKey: 'category',
              content: <Ellipsis> {row.category_name || '---'}</Ellipsis>
            },
            {
              columnKey: 'description',
              content: <Ellipsis>{row.description || '---'}</Ellipsis>
            },
            {
              columnKey: 'type',
              content: (
                <div className={s.ruleTypeBox}>
                  {/* Icon */}
                  {row.type === RuleType.BLANK ? null : row.type === RuleType.CONDITIONAL ? (
                    <ConditionalIcon />
                  ) : (
                    <AiIcon />
                  )}
                  {/* Text */}
                  {row.type === RuleType.BLANK
                    ? '--'
                    : row.type === RuleType.CONDITIONAL
                    ? 'Conditional Rule'
                    : 'AI Rule'}
                </div>
              )
            },
            {
              columnKey: 'modified_by',
              content: (
                <Ellipsis>
                  {row.modified_by
                    ? `${row.modified_by.first_name} ${row.modified_by.last_name}`
                    : 'SimpleLegal Support'}
                </Ellipsis>
              )
            },
            {
              columnKey: 'modified_date',
              content: (
                <Ellipsis>{moment(row.modified_date).format('MM/DD/YYYY') || '---'}</Ellipsis>
              )
            },
            {
              columnKey: 'is_draft',
              content: <StatusPill isActive={!row.is_draft} />
            },
            {
              columnKey: 'actions',
              content:
                row.type === RuleType.AI || row.type === RuleType.AI_CONDITIONAL ? (
                  <ActionsPopoverAI
                    rowId={row.id}
                    isDraft={row.is_draft}
                    onToggleStatus={isActive => handleToggleStatus(row.id, isActive)}
                  />
                ) : (
                  <ActionsPopover
                    rowId={row.id}
                    deleteRule={() => deleteRule(row.id)}
                    cloneRule={() => cloneRule(row.id)}
                  />
                )
            }
          ],
          expandableContent: {
            content: <RuleExpandableContent id={row.id} ruleIndex={index} />
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowList]
  )

  const categories = useMemo(
    () =>
      INIT_CATEGORIES.map(category => {
        const { value, label } = category
        const isActive = value === params.category

        return {
          ...category,
          isActive,
          customContent: isActive,
          count: isActive ? totalEntries : 0,
          label: isActive ? (
            <CustomCategory
              isLoading={isLoading as boolean}
              amount={totalEntries}
              label={label as string}
            />
          ) : (
            label
          )
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, rowList, isLoading]
  )

  const handleDownload = async () => {
    try {
      const data = await makeBlobRequest({
        url: '/client/rules/export/',
        method: 'GET',
        params: serializeParams(params)
      })
      if (data.size) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        const fileName = IVR_PAGE_TITLE.replace(/ /g, '_')
        link.download = fileName
        link.click()
      } else setIsDownloadModalOpen(true)
    } catch (error) {
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Sorry, couldn't retrieve the XLSX for your request. Please try again with other filters.`,
          level: 'error'
        }
      })
    }
  }

  const getActionButtons = () => {
    const actionButtons = [
      <Button
        key="download-btn"
        isPrimary
        isOutline
        onClick={handleDownload}
        hasNewDesign
        style={{
          padding: '8px 16px'
        }}
      >
        Download
      </Button>,
      <Button
        key="add-btn"
        isPrimary
        onClick={addRule}
        hasNewDesign
        style={{
          padding: '8px 16px'
        }}
      >
        Add Rule
      </Button>
    ]

    /* Remove Add button if user has only the AI rules application active */
    if (!activeRuleModules.hasIIW) {
      actionButtons.splice(1)
    }

    return actionButtons
  }

  return (
    <>
      <div className={s.container}>
        <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
          <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
          Back to Administration
        </a>
        {window.credentials.user.isCSM ? (
          <div style={{ marginTop: -12, marginBottom: 12 }}>
            <Link to={`/v2/rules/dsl?engine=simple_review`}>Navigate to Rules Text Editor</Link>
          </div>
        ) : null}
        <DataTableWrapper
          title={IVR_PAGE_TITLE}
          rows={rows}
          initialValues={params}
          params={params}
          columns={COLUMNS}
          categories={categories}
          category={params.category}
          isLoading={isLoading}
          totalEntries={filteredEntries}
          filteredTotal={totalEntries}
          bulkDismiss
          hasTooltip
          remotePagination
          updateTable={updateTable}
          filters={
            <FiltersContainer reset={reset} handleFilters={handleFilters} filters={params} />
          }
          actionButtons={getActionButtons()}
        />
      </div>
      <Modal
        title="We are working on your download"
        content="You’ll receive an email once your report is ready."
        confirmText="OK"
        confirmCb={() => setIsDownloadModalOpen(false)}
        size="sm"
        isVisible={isDownloadModalOpen}
      />
    </>
  )
}

export default SimpleReviewList
