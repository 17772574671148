import s from '../styles/BudgetAmounts.scss'

const columnKey2Title = columnKey => {
  return columnKey.includes('fees') ? 'Fees' : columnKey.includes('expenses') ? 'Expenses' : 'Total'
}

const BACell = ({ children, columnKey, title }) => {
  const header = title || columnKey2Title(columnKey)
  return (
    <div>
      <div className={s.cellHeader}>{header}</div>
      {children}
    </div>
  )
}

export default BACell
