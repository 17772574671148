import s from '../styles/VendorEval.scss'

const Header = ({ matterName, vendorName, startDate, endDate, info, headerHeight }) => {
  return (
    <header ref={headerHeight} className={s.header}>
      <h1 className={s.title}>{`${matterName} / ${vendorName}`}</h1>
      <h2 className={s.date}>{`${startDate} - ${endDate}`}</h2>
      <p className={s.info}>{info}</p>
    </header>
  )
}

export default Header
