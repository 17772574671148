import { Button } from 'simple-core-ui'
import s from './Notification.scss'

const Notification = ({ onKeepInactive, onMakeActive }) => (
  <div className={s.container}>
    <h3>Do you want to turn on surveys?</h3>
    <p>
      Your survey is set on &quot;inactive&quot;. Your listed evaluators will not receive a survey.
    </p>
    <div>
      <Button isSecondary onClick={onKeepInactive}>
        Keep inactive
      </Button>
      <Button testid="testid_evaluators_make_survey_active" isPrimary onClick={onMakeActive}>
        Yes, make active
      </Button>
    </div>
  </div>
)

export default Notification
