import { parseISO, format } from 'date-fns'
import get from 'lodash/get'
import { SerializedVendorFile, VendorFile } from './types'
import { getFileNameExtension } from '../MatterAttachmentsTab/utils'

export const resultsSerializer = (results: VendorFile[]): SerializedVendorFile[] =>
  results.map(row => ({
    ...row,
    type: row.fileName,
    created: row.created ? format(parseISO(row.created), 'MM/dd/yyyy') : null
  }))

export const getNormalizedCellContent = (columnKey: string, row: any) => {
  const { cells } = row
  const cellContent = get(
    cells.find((cell: { columnKey: string }) => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }
  switch (columnKey) {
    case 'type':
      return getFileNameExtension(cellContent).extension
    case 'fileName':
    case 'cg_user':
    case 'vendor_name':
    case 'fileStatus':
      return cellContent.toLowerCase()
    case 'fileSize':
      return +cellContent
    case 'created':
      return new Date(cellContent).valueOf()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
