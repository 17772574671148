import { createBgImg } from 'simple-core-ui'
import s from '../styles/SimpleLegalLogo.scss'

const SimpleLegalLogo = () => {
  const styles = {
    logo: { ...createBgImg({ path: `img/SimpleLegal.svg` }) },
    smallLogo: { ...createBgImg({ path: `img/SimpleLegalSmall.svg` }) }
  }
  return (
    <a href={'/'} title={'SimpleLegal'} className={s.container} style={styles.container}>
      <span className={s.logo} style={styles.logo} />
      <span className={s.smallLogo} style={styles.smallLogo} />
    </a>
  )
}

export default SimpleLegalLogo
