import { memo } from 'react'
import s from './Filters.scss'
import cn from 'classnames'
import moment from 'moment'
import { Filter } from './Filter'
import {
  FilterOption,
  Filters as FiltersType,
  Option,
  DateFormat,
  MatterUpdatesFilters,
  SingleVendorAPI
} from '../types'
import Select, { StylesConfig } from 'react-select'
import { DATE_FORMATS } from 'utils/constants'
import { OPERATORS } from '../constants'
import isEqual from 'lodash/isEqual'
import {
  CheckboxList,
  DateRangePopper,
  DropdownPopper,
  DueDatePicker,
  Tooltip,
  UpdateToPremiumLabel,
  Users
} from 'simple-core-ui'
import { toCgUsers } from '../serializers'
import { Placement } from '@floating-ui/react'

const { IS, IS_NOT, IS_BEFORE, IS_AFTER, IS_BETWEEN } = OPERATORS

const { DEFAULT_DATE } = DATE_FORMATS

const customStyle: StylesConfig<FilterOption, false> = {
  control: (provided, state) => {
    if (state.hasValue) {
      return {
        ...provided,
        border: '1px solid #3c99fd',
        '&:hover': {
          borderColor: '#3c99fd'
        },
        '&:focus': {
          borderColor: '#3c99fd'
        },
        boxShadow: 'none'
      }
    }
    return {
      ...provided,
      boxShadow: 'none'
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    if (!state.data) return provided
    return {
      ...provided,
      color: '#3c99fd'
    }
  }
}

interface Props {
  className?: string
  updateFilters: MatterUpdatesFilters
  setUpdateFilters: (filters: MatterUpdatesFilters) => void
  clearFilters: () => void
  scopeId: number
  isNonePlan: boolean
  isMatterUpdatePermissionDenied: boolean
}

interface InjectedProps {
  setFilters: (values: FilterOption[]) => void
  filters: FiltersType | null
}

const getPickerValue = (filters: InjectedProps['filters'], index: number) => {
  if (filters?.values && filters.values[index]) {
    return moment(filters.values[index].value).format(DEFAULT_DATE) as DateFormat | undefined | Date
  }
  return undefined
}

const selectOption = [
  {
    label: 'Internal',
    value: 'internal'
  },
  {
    label: 'Internal & Vendors',
    value: 'vendors'
  }
]

const Filters = memo(
  ({
    className,
    updateFilters,
    setUpdateFilters,
    clearFilters,
    scopeId,
    isNonePlan,
    isMatterUpdatePermissionDenied
  }: Props) => {
    const vendorFilter = (
      <Filter
        label="Vendor"
        value={updateFilters.vendor}
        isDisable={isNonePlan || isMatterUpdatePermissionDenied}
        onConfirm={(value, cb) => {
          setUpdateFilters({ ...updateFilters, vendor: value })
          cb?.()
        }}
        operatorsList={[
          {
            label: 'Is',
            value: IS
          },
          {
            label: 'Is Not',
            value: IS_NOT
          }
        ]}
        customLabel={() => {
          const filters = updateFilters.vendor
          if (!filters) return 'Vendor'
          switch (filters.operator?.value) {
            case IS:
              return `${
                filters.values && filters.values.length > 1
                  ? `Is ${filters.values.length} Vendors`
                  : `Vendor is ${filters.values?.[0].label}`
              }`
            case IS_NOT:
              return `${
                filters.values && filters.values.length > 1
                  ? `Is not ${filters.values.length} Vendors`
                  : `Vendor is not ${filters.values?.[0].label}`
              }`
            default:
              return 'Vendor'
          }
        }}
      >
        {({ filters, setFilters }: InjectedProps) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select Vendor(s)"
            label="vendor"
          >
            <CheckboxList
              url="/invoices/vendors/select2_json?all=true"
              value={filters?.values ?? []}
              onConfirm={setFilters}
              serialiser={({ results }) =>
                results.map((o: SingleVendorAPI) => ({
                  value: o.id,
                  label: o.text
                }))
              }
              isPaginated
              firstPageIndex={1}
              pageParam="page"
              searchTermParam="search_term"
            />
          </DropdownPopper>
        )}
      </Filter>
    )

    const visibilityFilter = (
      <Filter
        label="Visibility"
        value={updateFilters.visibility}
        isDisable={isNonePlan || isMatterUpdatePermissionDenied}
        hasOperators={false}
        onConfirm={(value, cb) => {
          setUpdateFilters({ ...updateFilters, visibility: value })
          cb?.()
        }}
        customLabel={() => {
          const value = updateFilters.visibility?.values?.[0]
          return value ? `Visibility ${value.label}` : 'Visibility'
        }}
      >
        {({ filters, setFilters }: InjectedProps) => (
          <Select
            value={filters?.values ?? []}
            onChange={value => {
              setFilters(value ? [value] : [])
            }}
            className={s.select}
            styles={customStyle}
            options={selectOption}
          />
        )}
      </Filter>
    )

    const renderPremiumFilter = (filter: JSX.Element, placement: Placement): JSX.Element => {
      return isNonePlan ? (
        <Tooltip
          trigger={filter}
          content={
            <div className={s.popper}>
              <div className={s.textWrap}>
                Sharing matter updates with vendors is only available with a matter management plan.
                Contact customer success to upgrade.
              </div>
              <UpdateToPremiumLabel />
            </div>
          }
          placement={placement}
          hasArrow={false}
          contentClassName={s.tooltip}
        />
      ) : (
        filter
      )
    }

    return (
      <div className={cn(s.filters, className)}>
        <Filter
          label="Date"
          value={updateFilters.date}
          onConfirm={(value, cb) => {
            setUpdateFilters({ ...updateFilters, date: value })
            cb?.()
          }}
          validation={(value: FiltersType | null) => {
            // return true and let the filter default validation take care of it
            if (!value?.operator || !value.values) return true
            if (value.operator.value === IS_BETWEEN && value.values?.length < 2) {
              return false
            }
            return true
          }}
          operatorsList={[
            {
              label: 'Is',
              value: IS
            },
            {
              label: 'Is between',
              value: IS_BETWEEN
            },
            {
              label: 'Is before',
              value: IS_BEFORE
            },
            {
              label: 'Is after',
              value: IS_AFTER
            }
          ]}
          customLabel={() => {
            const filters = updateFilters.date
            if (!filters) return 'Date'
            switch (filters.operator?.value) {
              case IS:
                return `Date ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BETWEEN:
                return `Date ${moment(filters.values?.[0].value).format(DEFAULT_DATE)} - ${moment(
                  filters.values?.[1].value
                ).format(DEFAULT_DATE)}`
              case IS_AFTER:
                return `Date after ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              case IS_BEFORE:
                return `Date before ${moment(filters.values?.[0].value).format(DEFAULT_DATE)}`
              default:
                return 'Date'
            }
          }}
        >
          {({ filters, setFilters }: InjectedProps) => {
            return (
              <DateRangePopper value={filters?.values ?? []}>
                <DueDatePicker
                  value={getPickerValue(filters, 0)}
                  onConfirm={(value: DateFormat | undefined | Date) => {
                    setFilters([
                      ...(value
                        ? [
                            {
                              value: value?.toString() ?? '',
                              label: 'Start Date'
                            }
                          ]
                        : []),
                      ...(filters?.values?.[1] ? [filters?.values?.[1]] : [])
                    ])
                  }}
                  shortcutType="past"
                />
                {filters?.operator?.value === IS_BETWEEN ? (
                  <DueDatePicker
                    value={getPickerValue(filters, 1)}
                    onConfirm={(value: DateFormat | undefined | Date) => {
                      setFilters([
                        ...(filters?.values?.[0] ? [filters?.values?.[0]] : []),
                        ...(value
                          ? [
                              {
                                value: value?.toString() ?? '',
                                label: 'End Date'
                              }
                            ]
                          : [])
                      ])
                    }}
                    shortcutType="past"
                  />
                ) : null}
              </DateRangePopper>
            )
          }}
        </Filter>
        <Filter
          label="Updated By"
          value={updateFilters.updatedBy}
          onConfirm={(value, cb) => {
            setUpdateFilters({ ...updateFilters, updatedBy: value })
            cb?.()
          }}
          operatorsList={[
            {
              label: 'Internal',
              value: 'INTERNAL'
            },
            {
              label: 'Vendors',
              value: 'VENDORS'
            }
          ]}
          customLabel={() => {
            const filters = updateFilters.updatedBy
            if (!filters?.values) return 'Updated By'
            switch (filters.operator?.value) {
              case 'INTERNAL':
              case 'VENDORS':
                return `Updated by ${
                  filters.values && filters.values.length > 1
                    ? `${filters.values.length} users`
                    : filters.values?.[0].label
                }`
              default:
                return 'Updated By'
            }
          }}
          resetValueOnOperatorChange
        >
          {({ filters, setFilters }: InjectedProps) => (
            <DropdownPopper
              value={filters?.values ?? []}
              placeholder="Select Name(s)"
              label="users"
            >
              <Users
                showEmail
                value={(filters?.values as Option[]) ?? []}
                style={{ width: '400px' }}
                requestParams={{
                  ...(filters && filters.operator && filters.operator.value === 'VENDORS'
                    ? { mId: +scopeId }
                    : {})
                }}
                onConfirm={setFilters}
                {...(filters && filters.operator && filters.operator.value === 'VENDORS'
                  ? {
                      serializer: toCgUsers,
                      url: `/manage/matters/${+scopeId}/matter_status_updates/cg_users/`
                    }
                  : {})}
              />
            </DropdownPopper>
          )}
        </Filter>
        {renderPremiumFilter(vendorFilter, 'left')}
        {renderPremiumFilter(visibilityFilter, 'right')}
        <div className={s.reset} onClick={clearFilters}>
          Reset
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.updateFilters, nextProps.updateFilters)
  }
)

export default Filters
