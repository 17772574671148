import { createReducer } from 'redux-create-reducer'
import ACT from './actions'

const emptyReviewerConfig = reviewerConfigKey => ({
  reviewerTiers: [],
  subscribers: [],
  isLeadReviewer: false,
  reviewerConfigId: window.serverContext ? window.serverContext.get(reviewerConfigKey) : null,
  isInitializing: true
})

const initialState = {
  invoice: emptyReviewerConfig('invoice_reviewer_config_id'),
  matter: emptyReviewerConfig('matter_reviewer_config_id')
}

const reviewerConfigReducer = createReducer(initialState, {
  [ACT.INITIALIZE_REVIEWER_CONFIG_SUCCESS](state, action) {
    const {
      reviewerTiers,
      subscribers,
      isLeadReviewer,
      reviewerScope,
      reviewerConfigId
    } = action.payload

    return {
      ...state,
      [reviewerScope]: {
        ...state[reviewerScope],
        reviewerConfigId,
        reviewerTiers,
        subscribers,
        isLeadReviewer,
        reviewerScope,
        isInitializing: false
      }
    }
  },
  [ACT.UPDATE_REVIEWER_CONFIG_SUCCESS](state, action) {
    const { reviewerTiers, subscribers, isLeadReviewer, reviewerScope } = action.payload
    return {
      ...state,
      [reviewerScope]: {
        ...state[reviewerScope],
        reviewerTiers,
        subscribers,
        isLeadReviewer
      }
    }
  },
  [ACT.CREATE_REVIEWER_CONFIG_SUCCESS](state, action) {
    const { reviewerScope, reviewerConfigId } = action.payload
    return {
      ...state,
      [reviewerScope]: {
        ...state[reviewerScope],
        reviewerConfigId
      }
    }
  },
  [ACT.RESET_REVIEWER_CONFIG](state, action) {
    const { reviewerScope } = action.payload
    return {
      ...state,
      [reviewerScope]: {
        ...initialState[reviewerScope]
      }
    }
  },
  [ACT.NOOP](state, action) {
    return {
      ...state,
      [action.payload]: {
        ...initialState[action.payload],
        isInitializing: false
      }
    }
  }
})

export default reviewerConfigReducer
