export const SAMPLE_PROMPTS = [
  'Create a cease and desist letter for trademark infringement against a competitor using a similar logo.',
  'Draft a response to a wrongful termination lawsuit filed by a former employee, citing the employee’s violation of company policies.',
  'Draft a settlement agreement to resolve an ongoing employment discrimination lawsuit, citing any relevant laws or regulations that inform the terms.',
  'Prepare a petition to the United States Tax Court challenging an IRS determination on corporate taxes owed.',
  'Update the company’s employee handbook to reflect new work-from-home policies. Include relevant federal and state labor laws that govern remote work.'
]

export const INITIAL_DRAFTING_CONTEXT = {
  text: '',
  practiceArea: 'Corporate Law',
  documentType: 'Contract',
  writingStyle: 'Formal',
  tone: 'Professional',
  creativity: 'High',
  length: 'Medium',
  language: 'English'
}
