import { thermometerColors } from 'styles/cssInJs'
import s from './StatusPill.scss'

interface Props {
  isActive: boolean
}

const StatusPill = ({ isActive }: Props) => {
  const colors = isActive ? thermometerColors.ACTIVE_RULE : thermometerColors.INACTIVE_RULE
  return (
    <div className={s.pillContainer}>
      <div
        className={s.pill}
        style={{
          backgroundColor: colors.BACKGROUND,
          color: colors.TEXT
        }}
      >
        <div>{isActive ? 'ACTIVE' : 'INACTIVE'}</div>
      </div>
    </div>
  )
}

export default StatusPill
