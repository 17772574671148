import { useEffect, useState } from 'react'
import noop from 'lodash/noop'
import { Modal } from 'simple-core-ui'

import ReviewerTierContent from './ReviewerTierContent'
import { MultiSelectBottom, MultiSelectTop } from './SubscribersForm'

import { ConfirmableContainer } from 'containers'
import { fes } from 'scui/utils/formatting'
import { makeGetRequest, handleError } from 'utils/api'

import { APPROVAL_LEVEL_CONDITION } from '../utils/constants'
import s from '../styles/TierContent.scss'

import Select from 'react-select'

const DEFAULT_SUBSCRIBERS_ENDPOINT = '/teams/search/'

const ReviewerTier = ({
  savedTier,
  editableTier = [],
  editTier,
  removeTier,
  addNewTier,
  revertReviewerTiers,
  isNewTier,
  isEmptyTier,
  readOnly,
  tierIndex
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [totalReviewers, setTotalReviewers] = useState(0)

  const tierReviewers = editableTier.reviewers.map(x => ({ ...x, value: x.id, label: x.name }))

  const onEditTier = newReviewers => {
    editTier({ reviewers: newReviewers })
  }

  const onPushTier = newReviewers => {
    const isSelectedAlready = tierReviewers.find(reviewer => reviewer.id === newReviewers?.[0]?.id)
    if (isSelectedAlready) {
      return
    }
    editTier({ reviewers: tierReviewers.concat(newReviewers) })
  }

  const closeCb = () => setIsOpen(false)

  const openCb = () => setIsOpen(true)

  const confirmCb = e => {
    addNewTier()
    closeCb()
  }

  const closeHandler = e => {
    revertReviewerTiers()
    closeCb()
  }

  useEffect(() => {
    async function getTotal() {
      try {
        const response = await makeGetRequest(`${DEFAULT_SUBSCRIBERS_ENDPOINT}`)
        setTotalReviewers(response.total)
      } catch (e) {
        handleError(e, 'Could not fetch total subscribers.')
      }
    }

    getTotal()
  }, [])

  return readOnly ? (
    <ReviewerTierContent
      tier={savedTier}
      isEmptyTier={isEmptyTier}
      isNewTier={isNewTier}
      removeTier={noop}
      openCb={noop}
      readOnly
    />
  ) : (
    <section data-testid={`matter_reviewer_tier_${tierIndex || 0}`} className={s.reviewerModal}>
      <ConfirmableContainer
        promptText="Clear reviewers?"
        confirmCallback={removeTier}
        cancelCallback={revertReviewerTiers}
        render={openPrompt => (
          <>
            <Modal
              title={`${isNewTier ? 'Add' : 'Edit'} Reviewers`}
              confirmText="Save"
              size="lg"
              contentStyle={{ minHeight: 'auto', padding: '0px 24px 8px 24px' }}
              isFooterContentGray
              content={
                <div>
                  <div style={{ width: 130 }}>
                    <Select
                      name="approvalConditionSelect"
                      value={{
                        value: editableTier.approvalClause,
                        label:
                          editableTier.approvalClause === APPROVAL_LEVEL_CONDITION.ALL
                            ? 'All of'
                            : 'Any of'
                      }}
                      onChange={option => editTier({ approvalClause: option.value })}
                      backspaceRemovesValue={false}
                      isClearable={false}
                      options={[
                        { value: APPROVAL_LEVEL_CONDITION.ALL, label: 'All of' },
                        { value: APPROVAL_LEVEL_CONDITION.ANY, label: 'Any of' }
                      ]}
                    />
                  </div>
                  <p className={s.reviewTierSubtitle}>
                    Please select your reviewers from the list or start typing
                  </p>
                  {!!tierReviewers?.length && (
                    <MultiSelectTop
                      values={tierReviewers}
                      onChange={onEditTier}
                      subtitle={`${fes(tierReviewers.length, `reviewers`)} ${
                        totalReviewers ? `selected of ${totalReviewers}` : 'selected'
                      }`}
                      id={`testid_matter_reviewers_select_${tierIndex || 0}`}
                    />
                  )}
                </div>
              }
              isVisible={isOpen}
              confirmCb={confirmCb}
              cancelCb={closeHandler}
              footerContent={
                <div style={{ marginTop: -7, width: '100%' }}>
                  <MultiSelectBottom
                    id={`testid_matter_reviewers_select_bottom_${tierIndex || 0}`}
                    values={tierReviewers}
                    onChange={onPushTier}
                    styles={{ marginBottom: 23 }}
                  />
                </div>
              }
            />
            <ReviewerTierContent
              tier={savedTier}
              isNewTier={isNewTier}
              isEmptyTier={isEmptyTier}
              removeTier={() => {
                closeCb()
                removeTier && removeTier()
              }}
              openCb={openCb}
              key={2}
              testid={`matter_reviewer_tier_content`}
            />
          </>
        )}
      />
    </section>
  )
}

export default ReviewerTier
