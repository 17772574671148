import { Component } from 'react'
import { If } from 'simple-core-ui'
import { HorizField, Input } from 'components'
import Select from 'react-select'
import MonthPickerInput from 'react-month-picker-input'
import { ScrollSelect, ReactDatePicker } from 'containers'
import moment from 'moment'
import ACT from 'budgets/actions'
import get from 'lodash/get'
import { connect } from 'react-redux'
import s from '../styles/BudgetRequestForm.scss'

const refreshPeriods = [
  { label: 'None', value: 'None' },
  { label: 'Annually', value: 'annually' },
  { label: 'Biannually', value: 'biannually' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' }
]

const monthPickerOptions = {
  dateFormat: { default: 'MM/YYYY' },
  monthFormat: 'short',
  monthNames: {
    default: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  }
}

const REFRESH_TOOLTIP_TEXT =
  'Select frequency for sending automated requests to the vendor to keep your budget up to date.'

@connect(({ budgets }, ownProps) => {
  const { phaseSets, budgetTypes, createForm } = budgets
  const budgetTypeOptions = budgetTypes.map(type => ({
    value: type.id,
    label: type.name,
    id: type.id
  }))

  return {
    phaseSets,
    budgetTypeOptions,
    createForm,
    matterName: ownProps.scope === 'matter' ? ownProps.scopeName : null
  }
})
class BudgetRequestForm extends Component {
  updateForm = (key, value) => {
    this.props.dispatch({
      type: ACT.UPDATE_BUDGET_REQUEST_FORM,
      payload: {
        [key]: value
      }
    })
  }

  render() {
    const {
      budgetTypeOptions,
      phaseSets,
      vendorId,
      createForm,
      isLegacyBudget,
      matterName
    } = this.props
    const {
      budgetName,
      budgetType,
      budgetYear,
      collaborators,
      phaseSet,
      errors,
      refreshPeriod,
      amount,
      budgetDueDate
    } = createForm

    return (
      <>
        <HorizField label="Budget Type" hasError={errors?.budgetType} isRequired>
          <Select
            aria-label="Budget Type"
            id={`testid_${vendorId}_budget_type_select`}
            value={budgetType}
            options={budgetTypeOptions}
            onChange={op => this.updateForm('budgetType', op)}
          />
        </HorizField>

        {matterName && isLegacyBudget && <HorizField label="Matter">{matterName}</HorizField>}

        <If condition={!isLegacyBudget}>
          <HorizField label="Budget Name" hasError={errors?.budgetName} isRequired>
            <Input
              type="text"
              value={budgetName}
              id={`testid_${vendorId}_budget_name_input`}
              onChange={value => this.updateForm('budgetName', value)}
              className={s.textInput}
            />
          </HorizField>
        </If>

        <If condition={budgetType && budgetType.value !== 4}>
          <HorizField label="Start Date" hasError={errors?.budgetStartDate} isRequired>
            <MonthPickerInput
              year={parseInt(moment().format('YYYY'))}
              inputProps={{
                id: `testid_${vendorId}_budget_start_date_input`,
                className: s.textInput
              }}
              i18n={monthPickerOptions}
              closeOnSelect
              onChange={(maskedValue, selectedYear, selectedMonth) => {
                const date = moment()
                  .month(selectedMonth)
                  .year(selectedYear)
                  .format('MM-YYYY')
                this.updateForm('budgetStartDate', date)
              }}
            />
          </HorizField>
          <HorizField label="End Date" hasError={errors?.budgetEndDate} isRequired>
            <MonthPickerInput
              year={parseInt(moment().format('YYYY'))}
              inputProps={{
                id: `testid_${vendorId}_budget_end_date_input`,
                className: s.textInput
              }}
              i18n={monthPickerOptions}
              closeOnSelect
              onChange={(maskedValue, selectedYear, selectedMonth) => {
                const date = moment()
                  .month(selectedMonth)
                  .year(selectedYear)
                  .format('MM-YYYY')
                this.updateForm('budgetEndDate', date)
              }}
            />
          </HorizField>
        </If>

        <If condition={budgetType && budgetType.value === 4}>
          <HorizField label="Quarter Year" hasError={errors?.budgetYear} isRequired>
            <Input
              ariaLabel="Quarter Year"
              className={s.textInput}
              id={`testid_${vendorId}_budget_quarter_year`}
              type="text"
              value={budgetYear}
              onChange={value => this.updateForm('budgetYear', value)}
            />
          </HorizField>
        </If>

        <If condition={budgetType && budgetType.value === 1}>
          <HorizField label="Phase Set" hasError={errors?.phaseSet} isRequired>
            <Select
              aria-label="Phase Set"
              id={`testid_${vendorId}_phase_set_select`}
              value={phaseSet}
              options={phaseSets}
              onChange={op => this.updateForm('phaseSet', op)}
            />
          </HorizField>
        </If>

        <If condition={!isLegacyBudget}>
          <HorizField label="Due Date" hasError={errors?.budgetDueDate}>
            <ReactDatePicker
              value={budgetDueDate ? moment(budgetDueDate).format('L') : ''}
              className={s.dueDate}
              onChange={d => {
                const isDateValid = moment(d).isValid()
                this.updateForm('budgetDueDate', isDateValid ? moment(d).format('YYYY-MM-DD') : d)
              }}
              onInputChange={e => {
                this.updateForm('budgetDueDateInput', e.target.value)
              }}
            />
          </HorizField>

          <HorizField label="Refresh Cycle" tooltip={REFRESH_TOOLTIP_TEXT}>
            <Select
              aria-label="Refresh Cycle"
              value={refreshPeriod}
              options={refreshPeriods}
              onChange={op => this.updateForm('refreshPeriod', op)}
            />
          </HorizField>

          <HorizField label="CounselGO Collaborators" hasError={errors?.collaborators} isRequired>
            <ScrollSelect
              ariaLabel="CounselGO Collaborators"
              id={`testid_${vendorId}_counselgo_collaborators_select`}
              url="/invoices/vendors/contacts/select2_json"
              onChange={c => this.updateForm('collaborators', c?.length ? c : null)}
              params={{ selected_vendor_id: vendorId }}
              value={collaborators}
              isMulti
            />
          </HorizField>
          <div style={{ height: 100 }} />
        </If>

        <If condition={isLegacyBudget}>
          <If condition={budgetType && budgetType.value === 3 && !matterName}>
            <HorizField label="Matter" hasError={errors?.matterId} isRequired>
              <ScrollSelect
                ariaLabel="Matter"
                id={`testid_${vendorId}_budget_matter_select`}
                url="/manage/matters/select2_json/?matter_status=open"
                onChange={m => this.updateForm('matterId', get(m, 'value', null))}
              />
            </HorizField>
          </If>
          <HorizField label="Amount" hasError={errors?.amount} isRequired>
            <Input
              ariaLabel="Budget Amount"
              type="number"
              value={amount}
              id={`testid_${vendorId}_budget_amount_input`}
              onChange={value => this.updateForm('amount', value)}
              style={{ marginRight: 4 }}
            />
          </HorizField>
        </If>
      </>
    )
  }
}

export default BudgetRequestForm
