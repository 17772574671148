import { NumericConstantMapper } from './constant-mapper/numeric-constant-mapper'
import {
  StringConstantMapper,
  StringListConstantMapper
} from './constant-mapper/string-constant-mapper'
import {
  ReferenceConstantMapper,
  ReferenceListConstantMapper
} from './constant-mapper/reference-constant-mapper'
import { BooleanConstantMapper } from './constant-mapper/boolean-constant-mapper'
import { DateConstantMapper, DateListConstantMapper } from './constant-mapper/date-constant-mapper'
import { CurrencyConstantMapper } from './constant-mapper/currency-constant-mapper'
import { Constant } from 'simple_review/@types/common'
import { CustomAttributeMapper } from './constant-mapper/custom-attribute-mapper'
import { CustomAttrRhs } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'

// factory for constants
export const generateConstant = (
  type: string,
  constant: Constant | CustomAttrRhs,
  subType: string | null,
  context: ContextState
) => {
  if ('attr_id' in constant) {
    return new CustomAttributeMapper(constant, subType ?? '', context)
  }

  switch (type) {
    case 'number':
      return new NumericConstantMapper(constant)
    case 'string':
      return new StringConstantMapper(constant)
    case 'reference':
    case 'list':
      return new ReferenceConstantMapper(constant, subType)
    case 'boolean':
      return new BooleanConstantMapper(constant)
    case 'date':
      return new DateConstantMapper(constant)
    case 'currency':
      return new CurrencyConstantMapper(constant.label, constant.value)
    default:
      throw new Error(`rhs type ${type} not supported`)
  }
}

export const generateListConstant = (
  type: string,
  constant: Array<Constant>,
  subType: string | null
) => {
  switch (type) {
    case 'string':
      return new StringListConstantMapper(constant)
    case 'reference':
    case 'list':
      return new ReferenceListConstantMapper(constant, subType)
    case 'date':
      return new DateListConstantMapper(constant)
    //TODO: Not implemented on frontend yet (bugs)
    // case 'number':
    //   return new NumericListConstantMapper(constant)
    // case 'boolean':
    //   return new BooleanConstantMapper(rhs.constant)
    // case 'currency':
    //   return new CurrencyConstantMapper(rhs.constant.label, rhs.constant.value)
    default:
      throw new Error(`rhs type ${type} not supported`)
  }
}
