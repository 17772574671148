export const MATTER_STATUSES = {
  DRAFT: 'Draft',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete'
}

export const REQUEST_EXTRA_FIELDS = {
  REVIEWED_BY: 'Reviewed By',
  COMMENTS: 'Reviewer Comments'
}
