import { limitCommentCharacters } from './utils'

export const answersTableSerializer = (answers = []) => {
  const columns = [
    { columnKey: 'score', content: 'Score', isSortable: true, isFilterable: true },
    {
      columnKey: 'date',
      content: 'Surveyed Period',
      style: { whiteSpace: 'nowrap' },
      isSortable: true,
      isFilterable: true
    },
    { columnKey: 'matter', content: 'Matter', isSortable: true, isFilterable: true },
    { columnKey: 'practice_area', content: 'Practice Area', isSortable: true, isFilterable: true },
    { columnKey: 'evaluator', content: 'Evaluator', isSortable: true, isFilterable: true },
    { columnKey: 'role', content: 'Role' },
    { columnKey: 'comment', content: 'Comment', isSortable: true, isFilterable: true }
  ]

  const rows = answers.map(answer => ({
    id: answer.id,
    cells: [
      { columnKey: 'score', content: answer.score },
      { columnKey: 'date', content: `${answer.date_from} - ${answer.date_to}` },
      { columnKey: 'matter', content: answer.matter },
      { columnKey: 'practice_area', content: answer.practice_area.join(', ') },
      { columnKey: 'evaluator', content: answer.evaluator },
      { columnKey: 'role', content: answer.role.join(', ') },
      { columnKey: 'comment', content: limitCommentCharacters(answer.comment) }
    ]
  }))

  return {
    columns,
    rows
  }
}

export const commentsTableSerializer = (answers = []) => {
  const columns = [
    {
      columnKey: 'date',
      content: 'Surveyed Period',
      style: { whiteSpace: 'nowrap' },
      isSortable: true,
      isFilterable: true
    },
    { columnKey: 'matter', content: 'Matter', isSortable: true, isFilterable: true },
    { columnKey: 'practice_area', content: 'Practice Area', isSortable: true, isFilterable: true },
    { columnKey: 'evaluator', content: 'Evaluator', isSortable: true, isFilterable: true },
    { columnKey: 'role', content: 'Role', isFilterable: true },
    { columnKey: 'comment', content: 'Comment', isSortable: true, isFilterable: true }
  ]

  const rows = answers.map(answer => ({
    id: answer.id,
    cells: [
      { columnKey: 'date', content: `${answer.date_from} - ${answer.date_to}` },
      { columnKey: 'matter', content: answer.matter },
      { columnKey: 'practice_area', content: answer.practice_area.join(', ') },
      { columnKey: 'evaluator', content: answer.evaluator },
      { columnKey: 'role', content: answer.role.join(', ') },
      { columnKey: 'comment', content: limitCommentCharacters(answer.comment) }
    ]
  }))

  return {
    columns,
    rows
  }
}
