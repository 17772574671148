import s from './DynamicAttribute.scss'
import cn from 'classnames'

const DynamicAttribute = ({
  children,
  key,
  dynamicAttributeClass,
  threeLevelsClass,
  twoLevelsClass
}) => {
  return (
    <div
      key={key}
      className={cn(s.dynamicAttribute, {
        [threeLevelsClass || s.threeLevels]: children.length === 3,
        [twoLevelsClass || s.twoLevels]: children.length === 2,
        [dynamicAttributeClass]: dynamicAttributeClass
      })}
    >
      {children}
    </div>
  )
}

export default DynamicAttribute
