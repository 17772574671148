import { Modal } from 'simple-core-ui'
import { CreateBudgetForm } from 'budgets/forms'
import { useDispatch, useSelector } from 'react-redux'
import { validateLegacyBudget } from 'budgets/forms/validators'
import ACT from 'budgets/actions'

const CreateBudgetModal = ({ isVisible, setIsVisible, scope, scopeId, scopeName }) => {
  const createForm = useSelector(state => state.budgets.createForm)
  const dispatch = useDispatch()

  const submitNewBudget = () => {
    const { isError, errors } = validateLegacyBudget(createForm, scope)
    if (isError) {
      dispatch({
        type: ACT.UPDATE_BUDGET_REQUEST_FORM,
        payload: {
          errors
        }
      })
    } else {
      dispatch({
        type: ACT.SUBMIT_BUDGET_REQUESTED,
        loadingLock: 'on',
        payload: {
          ...createForm,
          ...(scope === 'matter' ? { matterId: scopeId } : {}),
          ...(scope === 'vendor' ? { vendorId: scopeId } : {}),
          requested: false
        }
      })
      setIsVisible(false)
    }
  }

  return (
    <Modal
      title="Add Budget"
      confirmText="Submit"
      cancelText="Cancel"
      size="md"
      content={<CreateBudgetForm scope={scope} scopeName={scopeName} />}
      isVisible={isVisible}
      confirmCb={submitNewBudget}
      cancelCb={() => setIsVisible(false)}
    />
  )
}

export default CreateBudgetModal
