import * as React from 'react'
import classNames from 'classnames'
import { If } from 'simple-core-ui'

import TabContext from 'context/TabContext'

import s from '../styles/TabProvider.scss'

class TabProvider extends React.Component {
  state = {
    tabs: []
  }

  setTabs = tabs => {
    this.setState({ tabs })
  }

  render() {
    const { children } = this.props
    const { tabs } = this.state

    return (
      <TabContext.Provider value={this.setTabs}>
        <If condition={tabs.length}>
          <section className={s.tabBar}>
            {tabs.map(({ text, isSelected, onClick }, idx) => (
              <span
                data-testid={`${text}_tab`}
                className={classNames(s.tab, { [s.selected]: isSelected })}
                onClick={onClick}
                key={idx}
              >
                {text}
              </span>
            ))}
          </section>
        </If>
        {children}
      </TabContext.Provider>
    )
  }
}

export default TabProvider
