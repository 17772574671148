import { Component } from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import get from 'lodash/get'
import differenceBy from 'lodash/differenceBy'
import ScopeContext from 'context/ScopeContext'
import { withRouter } from 'simple-core-ui/hocs'

import APP_ACT from 'app/actions'
import ACT from '../actions'

import AssignedContacts from './AssignedContacts'

import ContactsLoading from './ContactsLoading/ContactsLoading'

import { SPECIAL_ACCESS_ROLES, SCOPE } from 'utils/constants'

import {
  filterRolesByContactType,
  nullifyContactIfIncompatible,
  getAssignedRoles,
  isCounselGo,
  getSingularRoleIntersect,
  patchRoleTimestamp,
  getDependentRoles,
  getWarningMessageType,
  maxCGAdminsWarningMessageType,
  containsCounselGoAdmin,
  containsCounselGo,
  getCGAdminsNo,
  setDataWithMessage
} from '../utils'

const getScopeData = (location, params) => {
  const scope = location.pathname.includes('matters') ? SCOPE.MATTER : SCOPE.VENDOR
  let scopeId = params.matterId || params.vendorId || params.id
  if (scope === SCOPE.VENDOR) scopeId = window.serverContext.get('object_id')
  return { scope, scopeId }
}

@connect(({ contacts }) => {
  const { assignedContacts, roles, rolesSettings } = contacts
  const tagFilterSet = getAssignedRoles(assignedContacts).map(role => ({
    ...role,
    color: isCounselGo(role) ? 'red' : role.singular ? 'blue' : 'black'
  }))

  return {
    assignedContacts,
    roles,
    tagFilterSet,
    rolesSettings
  }
})
@withFetchFlow({
  render: () => <ContactsLoading />,
  flag: 'AssignedContacts',
  getFetchAction: props => {
    const { location, params } = props.router
    const { scope, scopeId } = getScopeData(location, params)

    return {
      type: ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED,
      payload: {
        scope,
        scopeId
      }
    }
  }
})
@withRouter
class AssignedContactsContainer extends Component {
  static defaultProps = {
    assignedContacts: [],
    tagFilterSet: [],
    roles: [],
    rolesSettings: null,
    dispatch: obj => {}
  }

  state = {
    selectedContact: null,
    selectedRoles: [],
    isAssignFormOpen: false,
    isContactQuickFormOpen: false,
    contactToUpdate: null,
    isExpanded: false,
    selectedRoleId: null,
    isClosed: this.props.closedKeyword ? window.serverContext.get(this.props.closedKeyword) : false,
    isEditable: this.props.editableKeyword
      ? window.serverContext.get(this.props.editableKeyword)
      : true,
    warningMessageType: '',
    rolesSettings: this.props.rolesSettings
  }

  componentDidMount() {
    const { dispatch, router } = this.props
    const { location, params } = router
    const { scope, scopeId } = getScopeData(location, params)

    dispatch({
      type: APP_ACT.UPDATE_SCOPE,
      payload: {
        id: scopeId,
        scope
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { currentCGAdminsNo } = this.props.rolesSettings
    if (prevProps.rolesSettings.currentCGAdminsNo !== currentCGAdminsNo) {
      this.setCGAdminsNo(currentCGAdminsNo)
    }
  }

  contactsChangeCb = selectedContact => {
    this.setState(prevState => {
      const filteredRoles = filterRolesByContactType(
        selectedContact,
        get(prevState, 'selectedRoles', []),
        SPECIAL_ACCESS_ROLES
      )

      const filteredRolesAreEmpty = !filteredRoles || !filteredRoles.length

      return {
        selectedRoles: filteredRolesAreEmpty ? [] : filteredRoles,
        selectedContact
      }
    })
  }

  removeCGAdmin = roles => {
    if (containsCounselGoAdmin(roles)) {
      const { currentCGAdminsNo } = this.state.rolesSettings
      this.changeCGAdminsNo(currentCGAdminsNo - 1)
    }
  }

  changeCGAdminsNo = currentCGAdminsNo => {
    this.props.dispatch({
      type: ACT.CURRENT_CG_ADMINS_NO_CHANGE,
      payload: {
        currentCGAdminsNo
      }
    })
  }

  setCGAdminsNo = currentCGAdminsNo => {
    this.setState(prevState => {
      return {
        rolesSettings: {
          ...prevState.rolesSettings,
          currentCGAdminsNo
        }
      }
    })
  }

  setContactData = (selectedRoles, currentCGAdminsNo, warningMessageType) => {
    this.setState(prevState => {
      return {
        selectedRoles,
        selectedContact: nullifyContactIfIncompatible(
          { ...prevState.selectedContact, selectedRoles },
          selectedRoles,
          SPECIAL_ACCESS_ROLES
        ),
        warningMessageType
      }
    })
    this.setCGAdminsNo(currentCGAdminsNo)
  }

  rolesChangeCb = selectedRoles => {
    const { roles } = this.props
    const { rolesSettings } = this.state
    const prevSelectedRoles = this.state.selectedRoles
    const { currentCGAdminsNo, maxAllowedCGAdmins } = rolesSettings
    if (containsCounselGoAdmin(roles)) {
      const addedRole = differenceBy(selectedRoles, prevSelectedRoles, 'systemName')
      const deletedRole = differenceBy(prevSelectedRoles, selectedRoles, 'systemName')

      const correctSelectedRoles = getDependentRoles(selectedRoles, prevSelectedRoles, roles)
      let warningMessageType = getWarningMessageType(
        deletedRole[0],
        selectedRoles,
        correctSelectedRoles,
        false
      )
      if (currentCGAdminsNo + 1 > maxAllowedCGAdmins && containsCounselGoAdmin(addedRole)) {
        this.setState({
          warningMessageType: maxCGAdminsWarningMessageType
        })
      } else {
        const updatedCGAdminNo = getCGAdminsNo(addedRole, deletedRole, currentCGAdminsNo)
        if (!containsCounselGo(correctSelectedRoles)) {
          warningMessageType = ''
        }
        this.setContactData(correctSelectedRoles, updatedCGAdminNo, warningMessageType)
      }
    } else {
      setDataWithMessage(selectedRoles, currentCGAdminsNo, this.setContactData)
    }
  }

  toggleAssignForm = () => {
    this.setState(prevState => ({
      isAssignFormOpen: !prevState.isAssignFormOpen,
      selectedContact: null,
      selectedRoles: [],
      warningMessageType: ''
    }))
  }

  toggleContactQuickForm = () => {
    this.setState(prevState => ({
      isContactQuickFormOpen: !prevState.isContactQuickFormOpen,
      isAssignFormOpen: prevState.isContactQuickFormOpen && !prevState.contactToUpdate,
      contactToUpdate: null
    }))
  }

  unassignContact = contact => {
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params)

    this.props.dispatch({
      type: ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED,
      payload: {
        contact,
        scope,
        scopeId
      }
    })
    this.removeCGAdmin(contact.roles)
  }

  unassignContactRole = (contact, roleId) => {
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params)

    this.props.dispatch({
      type: ACT.ASSIGNED_CONTACT_ROLE_REMOVAL_REQUESTED,
      payload: {
        roleId,
        contact,
        scope,
        scopeId
      }
    })
  }

  assignContact = async () => {
    const { selectedRoles, rolesSettings } = this.state
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params)

    const selectedContact = patchRoleTimestamp(
      this.state.selectedContact,
      this.props.assignedContacts
    )

    await this.props.dispatch({
      type: ACT.ASSIGNED_CONTACTS_SUBMISSION_REQUESTED,
      payload: {
        contact: selectedContact,
        roles: selectedRoles,
        scope,
        scopeId
      }
    })

    this.changeCGAdminsNo(rolesSettings.currentCGAdminsNo)

    this.setState({
      selectedContact: null,
      selectedRoles: [],
      isAssignFormOpen: false
    })
  }

  setContactToUpdate = contactToUpdate => {
    this.setState({
      isContactQuickFormOpen: true,
      isAssignFormOpen: false,
      contactToUpdate
    })
  }

  expandCb = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }))
  }

  roleFilterCb = selectedRoleId => {
    this.setState(prevState => ({
      selectedRoleId: prevState.selectedRoleId === selectedRoleId ? null : selectedRoleId
    }))
  }

  singularRoleIntersect = () => {
    const { selectedContact, selectedRoles } = this.state
    const { assignedContacts } = this.props

    const filteredAssignedContacts = selectedContact
      ? assignedContacts.filter(({ id }) => id !== selectedContact.id)
      : assignedContacts

    return getSingularRoleIntersect(filteredAssignedContacts, selectedRoles)
  }

  render() {
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params)

    return (
      <ScopeContext.Provider value={{ scope, scopeId }}>
        <AssignedContacts
          {...this.props}
          {...this.state}
          assignContactCb={this.assignContact}
          unassignRoleCb={this.unassignContactRole}
          unassignContactCb={this.unassignContact}
          contactsChangeCb={this.contactsChangeCb}
          rolesChangeCb={this.rolesChangeCb}
          toggleAssignFormCb={this.toggleAssignForm}
          toggleContactQuickFormCb={this.toggleContactQuickForm}
          setContactToUpdateCb={this.setContactToUpdate}
          expandCb={this.expandCb}
          roleFilterCb={this.roleFilterCb}
          singularRoleIntersect={this.singularRoleIntersect()}
          changeCGAdminsNoCb={this.changeCGAdminsNo}
          userCanEditContactCG={
            this.props.rolesSettings.userCanEditContactCG && this.state.isEditable
          }
        />
      </ScopeContext.Provider>
    )
  }
}

export default AssignedContactsContainer
