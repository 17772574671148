import * as React from 'react'

import { Modal, Input, PickList } from 'simple-core-ui'

import { ContactsSelect, Contact } from 'contacts'

const TeamModal = ({ showForCreate, onCancel, onSubmit, readOnly, ...teamFormProps }) => (
  <Modal
    isVisible
    content={<TeamForm {...teamFormProps} readOnly={readOnly} />}
    title={readOnly ? 'View Team' : showForCreate ? 'Create New Team' : 'Update Team'}
    confirmCb={onSubmit}
    confirmText={showForCreate ? 'Create' : 'Update'}
    cancelCb={onCancel}
    cancelText="Cancel"
    isDisabled={!teamFormProps.name || readOnly}
    size="sm"
  />
)

const TeamForm = ({ name, description, onUpdateTextField, readOnly, ...teamPickListProps }) => (
  <React.Fragment>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('name', value)}
      placeholder="Name"
      text={name}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
      isDisabled={readOnly}
    />
    <Input
      onChangeCb={({ target: { value } }) => {
        onUpdateTextField('description', value)
      }}
      placeholder="Description"
      text={description}
      style={{ marginBottom: '16px' }}
      isDisabled={readOnly}
    />
    <TeamPickList {...teamPickListProps} readOnly={readOnly} />
  </React.Fragment>
)

const TeamPickList = ({ members, onUpdateMembers, readOnly }) => (
  <PickList
    numberedLabel="member"
    emptyText="No members have been selected for this team."
    initialValues={members}
    renderOption={contact => (
      <section style={{ fontSize: '12px' }}>
        <Contact {...contact} readOnly />
      </section>
    )}
    renderSelect={(onAdd, selected) => (
      <ContactsSelect
        value={null}
        requestConfig={{ params: { l: true } }}
        onChange={onAdd}
        filterOptions={contacts => {
          if (!selected.length) return true
          return !selected.find(s => s.id === contacts.value)
        }}
        placeholder="Search Team Members..."
        maxHeight="200px"
      />
    )}
    height="155px"
    onUpdate={onUpdateMembers}
    byLatest
    readOnly={readOnly}
  />
)

export default TeamModal
