import { getLastAction } from './utils'
import { BUDGET_STATES } from './constants'
import {
  activeBudgetOrderByMap,
  budgetRequestsColumnsOrderByMap,
  budgetRequestsCategoriesByMap
} from './list/tableDefinitions'
import { capitalizeWord } from 'utils/formatting'
import qs from 'query-string'

import {
  MultiSelectFilterSerializer,
  SingleSelectFilterSerializer
} from 'common/Filters/serializers'

export const toFragment = param => {
  const {
    budget,
    ids,
    budget_type,
    cost_code,
    budget_health,
    vendors,
    start_date,
    end_date,
    budget_category,
    matter,
    matter_lead,
    matter_group,
    ordering,
    page,
    search,
    category
  } = param

  const budgetHealthSelector = budget_health
    ? `${SingleSelectFilterSerializer.toFragment('budget_health', budget_health)}&`
    : ''
  const budgetTypeSelector = budget_type
    ? `${MultiSelectFilterSerializer.toFragment('budget_type', budget_type)}&`
    : ''
  const costCodeSelector = cost_code
    ? `${MultiSelectFilterSerializer.toFragment('cost_code', cost_code)}&`
    : ''
  const budgetSelector = budget
    ? `${MultiSelectFilterSerializer.toFragment('budget', budget)}&`
    : ''
  const budgetIdsSelector = ids ? `${MultiSelectFilterSerializer.toFragment('ids', ids)}&` : ''
  const startDateSelector = start_date ? `start_date=${start_date.value}&` : ''
  const endDateSelector = end_date ? `end_date=${end_date.value}&` : ''
  const vendorsSelector = vendors
    ? `${MultiSelectFilterSerializer.toFragment('vendors', vendors)}&`
    : ''
  const matterSelector = matter
    ? `${MultiSelectFilterSerializer.toFragment('matter', matter)}&`
    : ''
  const matterLeadSelector = matter_lead
    ? `${MultiSelectFilterSerializer.toFragment('matter_lead', matter_lead)}&`
    : ''
  const budgetCategorySelector = budget_category
    ? `${SingleSelectFilterSerializer.toFragment('budget_category', budget_category)}&`
    : ''
  const matterGroupSelector = matter_group
    ? `${MultiSelectFilterSerializer.toFragment('matter_group', matter_group)}&`
    : ''

  const pageSelector = page ? `${qs.stringify({ page })}&` : ''
  const searchSelector = search ? `${qs.stringify({ search })}&` : ''
  const categorySelector = category ? `${qs.stringify({ category })}&` : ''
  const orderingSelector = ordering ? `${qs.stringify(ordering)}&` : ''

  const fragment = `${pageSelector}${searchSelector}${categorySelector}${orderingSelector}${matterLeadSelector}${startDateSelector}${budgetSelector}${budgetIdsSelector}${costCodeSelector}${budgetHealthSelector}${budgetTypeSelector}${endDateSelector}${vendorsSelector}${matterSelector}${budgetCategorySelector}${matterGroupSelector}`
  window.history.replaceState('', '', `#${fragment}`)

  try {
    window.localStorage.setItem('savedBudgetList', fragment)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Local Storage is full. Please empty data')
  }
}

export const fromFragment = hash => {
  const {
    budget,
    ids,
    vendors,
    start_date,
    end_date,
    budget_category,
    matter,
    matter_lead,
    matter_group,
    budget_health,
    budget_type,
    source,
    cost_code,
    page = 1,
    search = '',
    category = 'approved',
    columnKey = 'budgetStartDate',
    isDesc = true
  } = qs.parse(hash)

  const startDateSelector = start_date && {
    label: start_date.replace('-', '/'),
    value: start_date
  }

  const endDateSelector = end_date && {
    label: end_date.replace('-', '/'),
    value: end_date
  }

  const budgetSelector = budget && MultiSelectFilterSerializer.fromFragment('budget', { budget })

  const idsSelector = ids && MultiSelectFilterSerializer.fromFragment('ids', { ids })

  const costCodeSelector =
    cost_code && MultiSelectFilterSerializer.fromFragment('cost_code', { cost_code })

  const budgetTypeSelector =
    budget_type && MultiSelectFilterSerializer.fromFragment('budget_type', { budget_type })

  const vendorsSelector =
    vendors && MultiSelectFilterSerializer.fromFragment('vendors', { vendors })

  const matterSelector = matter && MultiSelectFilterSerializer.fromFragment('matter', { matter })

  const matterLeadSelector =
    matter_lead && MultiSelectFilterSerializer.fromFragment('matter_lead', { matter_lead })

  const budgetHealthSelector =
    budget_health && SingleSelectFilterSerializer.fromFragment('budget_health', { budget_health })

  const sourceSelector = source && SingleSelectFilterSerializer.fromFragment('source', { source })

  const budgetCategorySelector =
    budget_category &&
    SingleSelectFilterSerializer.fromFragment('budget_category', { budget_category })

  const matterGroupSelector =
    matter_group && MultiSelectFilterSerializer.fromFragment('matter_group', { matter_group })

  const returnable = {
    budget: budgetSelector,
    ids: idsSelector,
    vendors: vendorsSelector,
    matter: matterSelector,
    matter_lead: matterLeadSelector,
    budget_category: budgetCategorySelector,
    matter_group: matterGroupSelector,
    start_date: startDateSelector,
    cost_code: costCodeSelector,
    budget_type: budgetTypeSelector,
    end_date: endDateSelector,
    budget_health: budgetHealthSelector,
    source: sourceSelector,
    ...(page ? { page: Number(page) } : {}),
    ...(search ? { search } : {}),
    ...(category ? { category } : {}),
    ...(columnKey
      ? {
          ordering: {
            columnKey,
            isDesc: !!isDesc
          }
        }
      : {})
  }

  return returnable
}

export const serializeBudgetRequestList = response => {
  return response.rows.map(b => {
    return {
      id: b.id,
      budgetName: b.budget_name.name,
      budgetUrl: b.budget_name.url,
      matterName: b.matter_name.name,
      practiceArea: b.entity_name.name,
      vendorName: b.vendor_name.name,
      costCode: b.costcode_name.name,
      startDate: b.budget_start_date,
      endDate: b.budget_end_date,
      status: b.last_proposal_status === BUDGET_STATES.PROPOSED ? 'Approval Needed' : 'With Vendor',
      amount: b.budget_amount,
      lastAction: getLastAction(b.last_proposal_date || b.created_date)
    }
  })
}

// $1,000.51 => $1,001
export const roundCur = amount => {
  const value = amount.replace(/[^a-zA-Z0-9,.]/g, '')
  const numToRound = value.slice(-4)
  const rounded = Number(numToRound).toFixed(0)

  return amount.replace(numToRound, rounded)
}

export const serializeUpdateBudget = payload => {
  return {
    pk: payload.pk,
    ...Object.entries(payload).reduce((x1, [key, { value }]) => {
      if (key === 'status') {
        return {
          name: 'budget_stage',
          value: payload.status
        }
      }
      if (key === 'matter') {
        return {
          name: 'budget_matter',
          value
        }
      }
      if (key === 'costcode') {
        return {
          name: 'budget_costcode',
          value
        }
      }
      if (key === 'vendor') {
        return {
          name: 'budget_vendor',
          value
        }
      }
      if (key === 'mattergroup') {
        return {
          name: 'budget_matter_group',
          value
        }
      }
      return x1
    }, {})
  }
}

export const serializeActiveBudgetList = response => {
  return {
    ...response,
    rows: response.rows.map(b => {
      return {
        id: b.id,
        budgetName: b.budget_name.name,
        budgetUrl: b.budget_name.url,
        matterName: b.matter_name.name,
        matterId: b.matter_name.id,
        practiceArea: b.entity_name.name,
        mattergroupName: b.mattergroup_name.name,
        mattergroupId: b.mattergroup_name.id,
        matterleadName: b.matter_lead,
        budgetType: b.budget_type,
        budgetCategory: b.budget_category,
        costcodeName: b.costcode_name.name,
        costcodeId: b.costcode_name.id,
        vendorName: b.vendor_name.name,
        vendorId: b.vendor_name.id,
        external: b.external,
        lockDate: b.lock_date,
        canEdit: b.can_edit,
        remainingTotal: b.unformatted_amount - b.cached_amount,
        costCode: b.costcode_name.name,
        startDate: b.budget_start_date,
        endDate: b.budget_end_date,
        budgetAmount: b.budget_amount
      }
    })
  }
}

export const serializeBudgetAmounts = rows => {
  const result = {}
  for (let i = 0; i < rows.length; i++) {
    let notesKey = `notes-${rows[i].id}`
    let feesKey = `fees-${rows[i].id}`
    let expensesKey = `expenses-${rows[i].id}`
    result[notesKey] = rows[i].note
    result[feesKey] = rows[i].allocated_fees
    result[expensesKey] = rows[i].allocated_expenses
  }
  return result
}

export const serializeParams = params => {
  const { ordering, page, pageSize, search, category, budget, ids } = params
  const arrayAttributeToJoinedValue = value => value.map(x => x.value)
  const budgetIds =
    !!budget || !!ids
      ? [
          ...new Set([
            ...arrayAttributeToJoinedValue(budget || []),
            ...arrayAttributeToJoinedValue(ids || [])
          ])
        ]
      : []

  return {
    columnKey: activeBudgetOrderByMap[ordering.columnKey],
    isDesc: ordering.isDesc ? 1 : 0,
    page,
    pageSize,
    search,
    ...(budgetIds.length ? { budget_ids: budgetIds } : {}),
    ...(params.budget_type ? { budget_type: arrayAttributeToJoinedValue(params.budget_type) } : {}),
    ...(params.vendors ? { vendor_ids: arrayAttributeToJoinedValue(params.vendors) } : {}),
    ...(params.matter ? { matter_ids: arrayAttributeToJoinedValue(params.matter) } : {}),
    ...(params.matter_lead
      ? { matter_lead_ids: arrayAttributeToJoinedValue(params.matter_lead) }
      : {}),
    ...(params.budget_category?.label
      ? { category: params.budget_category.label.toLowerCase() }
      : {}),
    ...(params.budget_health?.label
      ? { budget_health: params.budget_health.label.toLowerCase() }
      : {}),
    ...(params.start_date?.label ? { start_date: params.start_date.label } : {}),
    ...(params.end_date?.label ? { end_date: params.end_date.label } : {}),
    ...(params.cost_code ? { cost_code: arrayAttributeToJoinedValue(params.cost_code) } : {}),
    ...(params.matter_group
      ? { matter_group: arrayAttributeToJoinedValue(params.matter_group) }
      : {}),
    ...(category ? { status: capitalizeWord(category) } : {}),
    ...(params.source?.label ? { category: params.source.label.toLowerCase() } : {})
  }
}

export const serializeBudgetRequestParams = params => {
  const { ordering, category, page, pageSize, search } = params

  const columnKeyParam = budgetRequestsColumnsOrderByMap[ordering.columnKey]

  const status = budgetRequestsCategoriesByMap[category]

  let isDesc = ordering.isDesc
  if (columnKeyParam === 'budget_last_proposal_action') {
    isDesc = !isDesc
  }

  const serialized = {
    page,
    pageSize,
    search,
    columnKey: columnKeyParam,
    isDesc: Number(isDesc)
  }

  if (status) {
    serialized.status = status
  }

  return serialized
}

export const serializeCreateBudgetRequest = ({
  budgetType,
  budgetName,
  vendorId,
  refreshPeriod,
  requested = true,
  collaborators = [],
  matterId,
  phaseSet,
  budgetStartDate,
  budgetEndDate,
  budgetDueDate,
  budgetYear
}) => {
  return {
    budget_type: budgetType ? budgetType.value : null,
    budget_name: budgetName,
    budget_vendor_id: vendorId,
    requested: true,
    refresh: refreshPeriod ? refreshPeriod.value : null,
    budget_year: budgetYear,
    counselgo_collaborators: collaborators.map(c => c.value),
    budget_matter_id: matterId,
    budget_phase_set: phaseSet ? phaseSet.value : null,
    budget_start_date: budgetStartDate,
    budget_end_date: budgetEndDate,
    budget_due_date: budgetDueDate
  }
}
