import { useState } from 'react'
import { Condense, ModalContainer } from 'simple-core-ui'
import SearchListModal from '../../BulkEditModal/SearchListModal/SearchListModal'
import s from './CondensedCell.scss'

export const CondensedCell = ({ cellContent, columnKey, threshold, rowData, listSeparator }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const list = cellContent.split(listSeparator)
  const record =
    columnKey === 'matter_vendors'
      ? 'Vendors'
      : columnKey === 'matter_subscribers'
      ? 'Subscribers'
      : 'Approvers'

  const renderTitle = () => (
    <>
      <p className={s.header}>{`${rowData.matter_short_name}: ${record}`}</p>
      <p className={s.subtitle}>{rowData.client_matter_id}</p>
    </>
  )

  return (
    <>
      <Condense
        values={cellContent.split(';')}
        threshold={threshold}
        badgeAsPill
        onBadgeClick={e => {
          setModalVisible(true)
        }}
        badgeSize="sm"
        badgeClassName={s.badge}
        withoutSuffix
        hideTitle
      />
      {modalVisible && (
        <ModalContainer
          title={renderTitle()}
          size="md"
          cancelText="Close"
          cancelCb={() => setModalVisible(false)}
          hasCloseIcon={false}
          content={<SearchListModal listItems={list} />}
        />
      )}
    </>
  )
}
