import { BreadcrumbItem } from './BreadcrumbItem'
import s from './Breadcrumbs.scss'

const Breadcrumbs = ({ items }) => (
  <ul className={s.breadcrumbItems}>
    {items.map((item, i) => (
      <BreadcrumbItem {...item} key={i} />
    ))}
  </ul>
)

export default Breadcrumbs
