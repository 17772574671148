import { useState, useEffect } from 'react'
import { ModalContainer } from 'simple-core-ui'
import { MdOutlineToday, MdOutlineEventRepeat } from 'react-icons/md'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import s from './ReopenMatterModal.scss'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { makeFormDataPost } from 'utils/api'
import { useDispatch } from 'react-redux'

interface Props {
  matterId: number
  onCancel: () => void
}

const ReopenMatterModal = ({ matterId, onCancel }: Props) => {
  const [selectedOption, setSelectedOption] = useState('preserve')
  const dispatch = useDispatch()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const reopenMatterCb = async () => {
    try {
      await makeFormDataPost('/manage/matters/change_status/', {
        pk: matterId,
        status: 'open',
        ...(selectedOption === 'reset' ? { invoice_until_date: '' } : {})
      })
      window.location.reload()
    } catch (e) {
      dispatch({ type: 'API_ERROR', error: e })
    }
  }

  const modalSubtitle = (
    <>
      Reset the <b>Invoice Accepted Prior To </b>
      <AiOutlineInfoCircle className={s.infoIcon} data-for="tooltip" data-tip />
      date to allow vendors to submit invoices to the matter or keep the existing date:
      <ReactTooltip id="tooltip" place="top" type="light" effect="solid" className={s.tooltip}>
        The <b>Invoices Accepted Prior to</b> field in a matter record controls whether vendors can
        bill against the matter after a certain date. The date field must be updated if vendors need
        to submit invoices to the matter.
      </ReactTooltip>
    </>
  )

  return (
    <ModalContainer
      title="Before you reopen this matter"
      hasNewButtons
      cancelText="Cancel"
      confirmText="Reopen Matter"
      cancelCb={onCancel}
      confirmCb={reopenMatterCb}
      size="sm"
      content={
        <>
          <div className={s.subtitle}>{modalSubtitle}</div>
          <div className={s.flex}>
            <div
              className={cn(s.method, {
                [s.selected]: selectedOption === 'preserve'
              })}
              onClick={() => setSelectedOption('preserve')}
            >
              <p className={s.header}>
                <MdOutlineToday /> Keep Date
              </p>
              <span>Reopen and keep the existing date</span>
            </div>
            <div
              className={cn(s.method, {
                [s.selected]: selectedOption === 'reset'
              })}
              onClick={() => setSelectedOption('reset')}
            >
              <p className={s.header}>
                <MdOutlineEventRepeat /> Reset Date
              </p>
              <span>Allow vendors to submit invoices to the matter</span>
            </div>
          </div>
        </>
      }
    />
  )
}

export default ReopenMatterModal
