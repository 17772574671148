import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Comment } from 'common/Tasks/types'
import s from './ActionsPopover.scss'

interface Props {
  deleteComment: (id: number) => void
  editComment: (comment: Comment) => void
  comment: Comment
  canEdit: boolean
}

const ActionsPopover = ({ deleteComment, editComment, comment, canEdit }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      visible: canEdit,
      onClick() {
        editComment(comment)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteComment(comment.id)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
