export const SURVEY_STATUSES = [
  {
    label: 'Active',
    value: 'enabled'
  },
  {
    value: 'disabled',
    label: 'Inactive'
  }
]
