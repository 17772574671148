import { Row } from './Row'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import s from '../RequestFormRow.scss'

const RequestFormRow = ({
  attributeOptions,
  item,
  onLabelChange,
  onAttributeChange,
  onTypeChange,
  onInfoChange,
  attrIndex,
  changeFormFieldValue,
  removeItem,
  displayRemove,
  toggleFilter,
  filterVisible,
  disabled,
  formatGroupLabel,
  hasRelationships,
  relationshipAttributes,
  relId
}) => {
  return item.fieldType === 'relationship' ? (
    <DynamicAttribute
      key={item.id}
      dynamicAttributeClass={s.dynamicAttribute}
      threeLevelsClass={s.threeLevels}
      twoLevelsClass={s.twoLevels}
    >
      {relationshipAttributes.map((attr, index) => (
        <Row
          key={attr.value}
          attributeOptions={[{ value: attr.value, label: attr.label }]}
          item={item}
          onLabelChange={onLabelChange}
          onInfoChange={onInfoChange}
          attrIndex={attrIndex}
          relAttrIndex={index}
          changeFormFieldValue={changeFormFieldValue}
          removeItem={removeItem}
          filterVisible={filterVisible}
          selectedAttribute={{ value: attr.value, label: attr.label }}
          isRelationshipAttribute
          selectedType={{ value: 'select', label: 'List' }}
          displayRemove={displayRemove && index === 0}
          relId={relId}
          relationshipAttribute={attr}
          disabled={disabled}
        />
      ))}
    </DynamicAttribute>
  ) : (
    <Row
      attributeOptions={attributeOptions}
      item={item}
      onLabelChange={onLabelChange}
      onAttributeChange={onAttributeChange}
      onTypeChange={onTypeChange}
      onInfoChange={onInfoChange}
      attrIndex={attrIndex}
      changeFormFieldValue={changeFormFieldValue}
      removeItem={removeItem}
      displayRemove={displayRemove}
      toggleFilter={toggleFilter}
      filterVisible={filterVisible}
      disabled={disabled}
      formatGroupLabel={formatGroupLabel}
      hasRelationships={hasRelationships}
    />
  )
}

export default RequestFormRow
