import { IoIosClose, IoIosMenu } from 'react-icons/io'
import SimpleDropdown from 'common/SimpleDropdown'
import SearchContainer from '../containers/SearchContainer'
import QuickNavDropdown from './QuickNavDropdown'
import AccountDropdown from './AccountDropdown'
import SimpleLegalLogo from './SimpleLegalLogo'
import s from '../styles/Header.scss'
import { ORG_ROLE } from 'utils/constants'

const Header = ({ isMenuExpanded, isMobile, triggerMenu }) => {
  return (
    <header className={s.container}>
      <section className={s.leftSide}>
        <SimpleLegalLogo />
      </section>
      {window.credentials.isAuthenticated &&
        window.credentials.user &&
        window.credentials.user.role !== ORG_ROLE.REQUEST_ONLY && (
          <section>
            <SearchContainer />
          </section>
        )}
      {window.credentials.isAuthenticated && !isMobile && (
        <section className={s.rightSide}>
          <ul className={s.actions}>
            <li data-testid="quick_add" className={s.item}>
              <SimpleDropdown
                render={(isExpanded, openDropdown) => {
                  return <QuickNavDropdown isExpanded={isExpanded} openDropdown={openDropdown} />
                }}
              />
            </li>
            <li data-testid="account_settings" className={s.item}>
              <SimpleDropdown
                render={(isExpanded, openDropdown) => {
                  return <AccountDropdown isExpanded={isExpanded} openDropdown={openDropdown} />
                }}
              />
            </li>
          </ul>
        </section>
      )}

      {window.credentials.isAuthenticated && isMobile && (
        <section className={s.rightSide}>
          <span className={s.trigger} onClick={triggerMenu}>
            {isMenuExpanded ? <IoIosClose /> : <IoIosMenu />}
          </span>
        </section>
      )}
    </header>
  )
}

export default Header
