export const serializeResults = results => {
  return results.map(r => {
    return {
      ...r,
      ...(r.referral_vendor_contact
        ? {
            referral_vendor_contact_name: `${r.referral_vendor_contact__family_name}, ${r.referral_vendor_contact__given_name}`
          }
        : {})
    }
  })
}
