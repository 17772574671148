import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'simple-core-ui'
import { PrivateRoute } from 'components'
import { RoleGuard } from 'components/Utils/PrivateRoute/guards'
import { DynamicAttributesList } from './list'
import { DynamicAttributesDetails } from './details'
import { DynamicAttributesImport } from './import'

const DynamicAttributes = () => (
  <Routes>
    <Route
      path="list"
      element={
        <PrivateRoute
          component={DynamicAttributesList}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="pair/:id"
      element={
        <PrivateRoute
          component={DynamicAttributesDetails}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route
      path="import"
      element={
        <PrivateRoute
          component={DynamicAttributesImport}
          guards={[RoleGuard('admin', 'poweruser')]}
        />
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default DynamicAttributes
