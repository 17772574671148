import { Template, APITemplate, MatterGroup } from './types'
import { formatName } from 'utils/helpers'

const toMatterGroup = (
  matterGroup: {
    id: number | string
    name: string
  } | null
): MatterGroup | null => {
  if (!matterGroup) {
    return null
  }
  return {
    value: matterGroup.id,
    label: matterGroup.name
  }
}

export const toTemplates = (templates: APITemplate[]): Template[] => {
  return templates.map((template: APITemplate) => {
    const {
      id,
      name,
      is_active,
      related_tasks,
      matter_group,
      description,
      edited_by,
      edited_date
    } = template

    return {
      id,
      name,
      matterGroup: toMatterGroup(matter_group),
      relatedTasks: related_tasks,
      lastEdited: edited_date,
      editedBy: formatName(edited_by) || null,
      isActive: is_active,
      description
    }
  })
}
