import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VerticalTabs, VerticalTab } from 'simple-core-ui'
import { IoIosArrowBack } from 'react-icons/io'
import s from './BulkActions.scss'
import { BulkBuilderContainer } from './stepper/BulkBuilder'
import JobHistoryContainer from '../history/JobHistoryContainer'
import BulkImportContainer from 'bulk/import/BulkImportContainer'
import { fromFragment } from '../serializer'
import { BULK_TAB_NAMES_BY_MAP } from '../constants'
import APP_ACT from 'app/actions'
import ACT from 'bulk/actions'
import { makeGetRequest } from 'utils/api'
import cn from 'classnames'

type FieldType = 'default' | 'general' | 'custom'

interface Field {
  field_type: FieldType
  hideOption: boolean
  isDefault: boolean
  isSelected: boolean
  label: string
  type: FieldType
  value: string
}

interface SelectOption {
  value: string
  label: string
  action: {
    edit: boolean
    create: boolean
  }
}

interface BuilderParams {
  attrType: string
  fields: Field[]
  filters: Field[]
  preSelected: string[]
  record: SelectOption
  step: number
  templateType: string
}

interface State {
  bulk: {
    builderParams: BuilderParams
    activeJob: boolean
  }
}

const BulkActionsContainer = () => {
  const { bulkImport, builder, history } = BULK_TAB_NAMES_BY_MAP
  const dispatch = useDispatch()

  const frag = fromFragment()
  const builderParams = useSelector<State, BuilderParams>(state => state.bulk.builderParams)
  const [step, setStep] = useState(frag.step || builderParams.step)
  const [activeTab, setActiveTab] = useState(frag.category || builder)
  const activeJob = useSelector<State, boolean>(state => state.bulk.activeJob)

  useEffect(() => {
    const fetchRecordType = async () => {
      try {
        const recordType = await makeGetRequest('/bulkv2/record_type')
        dispatch({
          type: ACT.SET_RECORD_TYPE,
          payload: { recordTypeObj: recordType }
        })
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching record type.',
            level: 'error'
          }
        })
      }
    }
    fetchRecordType()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={s.container}>
      <section className={s.panel}>
        {[bulkImport, history].includes(activeTab) || (activeTab === builder && step !== 5) ? (
          <a href="/v2/administration/" style={{ marginBottom: '13px' }}>
            <IoIosArrowBack />
            {' Back to Administration'}
          </a>
        ) : null}
        <h2 className={s.header}>Bulk Actions</h2>
        <VerticalTabs
          activeTabKey={activeTab}
          hasControlledTabs
          className={cn({ [s.tabsContainerHeight]: Number(step) <= 4 })}
        >
          <VerticalTab key={bulkImport} label="Import" onClick={() => setActiveTab(bulkImport)}>
            <BulkImportContainer activeTab={activeTab} setActiveTab={setActiveTab} />
          </VerticalTab>
          <VerticalTab
            key={builder}
            label="Editor"
            onClick={() => {
              setActiveTab(builder)
              activeJob && step === 5 ? setStep(4) : null
            }}
          >
            <BulkBuilderContainer
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              step={step}
              setStep={setStep}
            />
          </VerticalTab>
          <VerticalTab key={history} label="Job History" onClick={() => setActiveTab(history)}>
            <JobHistoryContainer activeTab={activeTab} />
          </VerticalTab>
        </VerticalTabs>
      </section>
    </section>
  )
}

export default BulkActionsContainer
