import TYPES from './types'

import { Input } from 'components'

import s from './ComboBox.scss'

const Filter = ({ filterValue, filterCb, label = 'Filter...' }) => (
  <section className={s.filterSection}>
    <Input
      testid="filter-input"
      type="text"
      placeholder={label}
      value={filterValue}
      onChange={filterCb}
      className={s.filter}
    />
  </section>
)

Filter.propTypes = {
  filterValue: TYPES.filterValue.isRequired,
  filterCb: TYPES.filterCb.isRequired
}

export default Filter
