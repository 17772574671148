import keyMirror from 'keymirror'

export default keyMirror({
  CLIENT_KEYS_FETCH_REQUESTED: null,
  CLIENT_KEYS_FETCH_SUCCESS: null,
  CLIENT_KEY_CREATE_REQUESTED: null,
  CLIENT_KEY_CREATE_SUCCESS: null,
  CLIENT_KEY_UPDATE_REQUESTED: null,
  CLIENT_KEY_UPDATE_SUCCESS: null,
  CLIENT_KEY_DELETE_REQUESTED: null,
  CLIENT_KEY_DELETE_SUCCESS: null,
  CLIENT_KEY_SHOW_FOR_CREATE: null,
  CLIENT_KEY_SHOW_FOR_UPDATE_REQUESTED: null,
  CLIENT_KEY_SHOW_FOR_UPDATE_SUCCESS: null,
  CLIENT_KEY_MODAL_CLOSE: null,
  CLIENT_KEYS_IS_LOADING: null,
  CLIENT_KEYS_IS_NOT_LOADING: null
})
