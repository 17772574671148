import { fromContact } from 'contacts/serializer'
import { SCOPE } from 'reviews/constants'

export const fromTeam = ({
  name,
  id,
  description,
  member_count, // eslint-disable-line camelcase
  contact
}) => ({
  name,
  id,
  description,
  memberCount: member_count,
  contact: contact !== null ? fromContact(contact) : null
})

export const fromReviewerTier = ({ clause, teams = [] }) => ({
  approvalClause: clause,
  reviewers: teams.map(fromTeam)
})

export const fromReviewerTiers = (reviewerTiers = []) => reviewerTiers.map(fromReviewerTier)

export const fromSubscribers = subscribers => subscribers.map(fromTeam)

export const toReviewerConfig = ({
  reviewerTiers,
  subscribers,
  isLeadReviewer,
  scope,
  scopeId
}) => {
  const serialized = {
    reviewer_tiers: reviewerTiers.map(({ approvalClause, reviewers }) => ({
      clause: approvalClause,
      teams: reviewers.map(({ id }) => id)
    })),
    subscribers: subscribers.map(({ id }) => id),
    lead_is_reviewer: isLeadReviewer
  }
  if (scope === SCOPE.MATTER) {
    serialized.matter_id = scopeId
  }
  return serialized
}

// TODO: Remove this once Invoice Approval Workflow is completely
// re-written in React.
//
// This is needed so that legacy frontend can properly serialize
// reviewerConfig data from the globally available window.store.
window.toReviewerConfig = toReviewerConfig
