import { If } from 'simple-core-ui'

import s from './StackList.scss'

const StackList = ({ stacks }) => (
  <ul className={s.container}>
    {stacks.map(({ primary, secondary }, idx) => (
      <li key={idx} className={s.stack}>
        <If condition={primary}>
          <span className={s.primary}>{primary}</span>
        </If>
        <If condition={secondary}>
          <span className={s.secondary}>{secondary}</span>
        </If>
      </li>
    ))}
  </ul>
)

export default StackList
