import { useContext, useEffect, useRef, useState } from 'react'
import s from './DraftPreview.scss'
import { DraftingContextForm } from '../DraftingContextForm'
import { useDispatch } from 'react-redux'
import { makeStreamedRequest } from 'utils/api'
import { serializeDraftingContext } from '../serializer'
import DocumentDraftingContext from 'matters/detail/context'

const DocumentDraftPreview = () => {
  const [response, setResponse] = useState('')
  const [blink, setBlink] = useState(false)

  const { draftingContext } = useContext(DocumentDraftingContext)

  const containerRef = useRef<HTMLDivElement>(null)
  const cursorRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const draftRequest = () => {
    makeStreamedRequest({
      url: '/doc_management/native_docs/doc_drafting/prompt/',
      body: serializeDraftingContext(draftingContext),
      onStart: () => {
        setBlink(true)
        setResponse('')
      },
      onChunkReceived: chunk => {
        setResponse(prevResponse => prevResponse + chunk)
      },
      onComplete: () => {
        setBlink(false)
      },
      onError: error => {
        dispatch({
          type: 'API_ERROR',
          error
        })
        setBlink(false)
      }
    })
  }

  useEffect(() => {
    draftRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (blink) {
      let intervalId: number | NodeJS.Timeout
      if (cursorRef.current) {
        const cursor = cursorRef.current
        const rect = cursor.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        if (rect.top <= viewportHeight - 100) {
          intervalId = setInterval(() => {
            if (cursorRef.current)
              cursorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }, 100)
        }
      }

      return () => clearInterval(intervalId)
    }
  }, [blink])

  const streamedDocumentText = (
    <div ref={containerRef} className={s.responseBody}>
      <pre className={s.responseText}>
        {response}
        <div ref={cursorRef} className={blink ? s.blink : s.hidden}></div>
      </pre>
    </div>
  )

  const saveDocument = () => {}

  return (
    <div className={s.container}>
      <div className={s.previewContainer}>
        <div className={s.documentContainer}>{streamedDocumentText}</div>
        <div className={s.contextContainer}>
          <DraftingContextForm
            primaryActionCb={saveDocument}
            secondaryActionCb={() => {
              containerRef.current &&
                containerRef.current.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              draftRequest()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DocumentDraftPreview
