export const DEFAULT_OPERATOR_LIST = [
  {
    label: 'Is',
    value: 'IS'
  },
  {
    label: 'Is not',
    value: 'IS_NOT'
  },
  {
    label: 'Is set',
    value: 'IS_SET'
  },
  {
    label: 'Is not set',
    value: 'IS_NOT_SET'
  }
]
