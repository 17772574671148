import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

export const getCurrencyValue = item => {
  const amount = get(item, 'amount')
  const code = get(item, 'code.value')
  return `${amount} ${code}`
}

export const getItemValue = item => {
  const { value, fieldType } = item
  if (fieldType === 'currency') {
    return getCurrencyValue(value)
  } else {
    return typeof value === 'object' && value.value !== null ? value.value : value
  }
}

export const itemValueChecker = (value, excludeNone) => {
  let isValid = true
  if (typeof value === 'object' && value !== null) {
    if (excludeNone && value.value === -1) return false
    isValid = (!!value.code && !!value.amount) || (!!value.value && !!value.label)
  } else {
    isValid = !!value
  }

  return isValid
}

export const getItemOptions = (attribute, item) => {
  if (attribute && attribute.options) {
    return attribute.options.filter(
      option =>
        ((!item.required || option.value !== 'None') && !item.filterOptions.length) ||
        item.filterOptions.includes(option.value.toString())
    )
  }
  return []
}

export const getItemsOptions = (attributes, items) => {
  const a = cloneDeep(items).map(item => {
    const attribute = attributes.find(attr => attr.value === item.model)
    item.options = getItemOptions(attribute, item).filter(o => !o.filtered)
    return item
  })
  return a
}
