import { connect } from 'react-redux'
import classNames from 'classnames'

const Dispatchable = ({ actions, dispatch, className, children }) => (
  <span
    className={classNames({ [className]: className })}
    onClick={() => {
      actions.forEach(action => dispatch(action))
    }}
  >
    {children}
  </span>
)

export default connect()(Dispatchable)
