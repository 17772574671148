import { Component } from 'react'
import { If } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'
import { InnerSidebarFilter, InnerSidebar, Checkbox } from 'components'
import { connect } from 'react-redux'
import { SELECT_TEMPLATE_FLOW } from 'matters/templates/constants'
import * as ACT from 'matters/templates/actions'
import s from './SelectionPageSidebarContainer.scss'
import { FaStar } from 'react-icons/fa'
import queryString from 'query-string'

@connect(({ matterTemplatesSelection }) => {
  const { filterTerm, selectedTemplate, userFlowState } = matterTemplatesSelection
  return {
    filterTerm,
    selectedTemplate,
    userFlowState
  }
})
@withRouter
class SelectionPageSidebarContainer extends Component {
  state = {
    showDescription: false
  }
  componentDidUpdate(prevProps) {
    if (this.props.userFlowState.loadingUserFlow !== prevProps.userFlowState.loadingUserFlow) {
      this.getTemplatesList()
      return
    }
    if (!prevProps.selectedTemplate || this.props.selectedTemplate !== prevProps.selectedTemplate) {
      const selectedTemplate = this.getSelectedTemplate()
      const userFlow = this.props.userFlowState.userFlow
      if (
        selectedTemplate &&
        (selectedTemplate !== this.props.selectedTemplate ||
          ([
            SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE,
            SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION,
            SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
          ].includes(userFlow) &&
            !prevProps.selectedTemplate))
      ) {
        this.props.getTemplate(selectedTemplate)
      }
    }
  }
  isTemplateInList = () => {
    const { list, selectedTemplate } = this.props
    if (!selectedTemplate) return false
    return list.some(template => {
      return template.id === selectedTemplate.id
    })
  }
  getSelectedTemplate = () => {
    return this.props.showSidebar && this.isTemplateInList()
      ? this.props.selectedTemplate
      : this.props.list[0]
  }
  getTemplatesList = () => {
    let { formId, latestTemplateId } = queryString.parse(this.props.router.location.search)
    const { userFlow } = this.props.userFlowState
    const isMatterRequestOrFormTemplateSelection = [
      SELECT_TEMPLATE_FLOW.MATTER_REQUEST,
      SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
    ].includes(userFlow)
    if (isMatterRequestOrFormTemplateSelection) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED,
        payload: {
          latestTemplateId,
          formId
        }
      })
    } else {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_LIST_FETCH_REQUESTED,
        payload: {
          latestTemplateId
        }
      })
    }
  }
  onFilterChange = term => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FILTER_TERM,
      payload: {
        term
      }
    })
  }
  toggleDescription = () => {
    this.setState({
      showDescription: !this.state.showDescription
    })
  }
  togglePrefferedAndGetTemplate = template => {
    const { getTemplate, togglePreferred } = this.props
    getTemplate(template)
    togglePreferred && togglePreferred(template.id)
  }

  render() {
    const { filterTerm, list, selectedTemplate, userFlowState, showSidebar = true } = this.props
    const { numberOfTemplates, matterRequestId } = queryString.parse(
      this.props.router.location.search
    )

    const showFilterInput = !numberOfTemplates || numberOfTemplates !== '1'
    const isMatterRequestOrFormTemplateSelection = [
      SELECT_TEMPLATE_FLOW.MATTER_REQUEST,
      SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
    ].includes(userFlowState.userFlow)

    const setPreferredTemplate =
      userFlowState.userFlow === SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION

    return (
      <div className={s.container} data-testid="template_selection_sidebar_container">
        <If condition={showSidebar}>
          <InnerSidebar
            title="Select Template"
            toggleDrawer={this.props.toggleTemplateSidebar}
            opened={this.props.isTemplateSidebarOpen}
            closable
          >
            <If condition={showFilterInput}>
              <InnerSidebarFilter filterTerm={filterTerm} onChange={this.onFilterChange} />
            </If>
            <span className={s.showDescription}>
              <Checkbox
                onChange={this.toggleDescription}
                defaultChecked={this.state.showDescription}
              />
              <span onClick={this.toggleDescription}>Show description</span>
            </span>
            {list.map(template => {
              const selectedCss =
                selectedTemplate && selectedTemplate.id === template.id ? s.selected : ''
              return (
                <div
                  key={template.id}
                  className={`${s.template} ${selectedCss}`}
                  onClick={() => this.togglePrefferedAndGetTemplate(template)}
                >
                  <If condition={isMatterRequestOrFormTemplateSelection}>
                    <FaStar
                      className={`${s.star} ${template.belongs_to_form ? s.preferred : ''} ${
                        setPreferredTemplate ? s.starHover : ''
                      }`}
                    />
                  </If>
                  <p
                    className={s.templateName}
                    data-testid={`template_${template.name
                      .toLowerCase()
                      .split(' ')
                      .join('_')}`}
                  >
                    {`${template.name} ${template.default ? '(Default)' : ''}`.trim()}
                  </p>
                  <If condition={this.state.showDescription}>
                    <small>{template.description}</small>
                  </If>
                </div>
              )
            })}
          </InnerSidebar>
        </If>
      </div>
    )
  }
}

export default SelectionPageSidebarContainer
