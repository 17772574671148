import { all } from 'redux-saga/effects'

import matterSagas from './matters/matter/sagas'
import matterTemplatesDetailSagas from 'matters/templates/detail/sagas'
import matterTemplatesSagas from 'matters/templates/sagas'
import matterRequestFormsSagas from 'matters/requestForms/list/sagas'
import matterRequestFormSagas from 'matters/requestForms/detail/sagas'
import matterRequestsSagas from 'matters/requests/common/RequestsTableContainer/sagas'
import processRequestSagas from 'matters/requests/process/sagas'
import matterRequestSagas from 'matters/requests/create/sagas'
import contactsSagas from 'contacts/sagas'
import invoicesSagas from 'invoices/sagas'
import diversitySagas from 'diversity/sagas'
import mattersSagas from 'matters/list/sagas'
import vendorEvalsSagas from './vendors/survey/sagas'
import surveyConfigTabSagas from 'matters/detail/vendors/Surveys/SurveyConfigTab/sagas'
import scorecardSagas from 'vendors/scorecard/sagas'
import dashboardSagas from 'dashboard/sagas'
import matterEventsSagas from 'matter_events/sagas'
import documentManagementSettingsSagas from 'doc_management/settings/sagas'
import tableauSagas from 'tableau/sagas'
import supersetReportsSagas from 'reports/Superset/sagas'

import rulesSagas from 'rules/sagas'
import accrualsVsActualsSagas from 'accruals_vs_actuals/sagas'
import budgetsSagas from 'budgets/sagas'
import clientKeysSagas from 'client_keys/sagas'
import todosSagas from 'todos/sagas'
import reviewsSagas from 'reviews/sagas'
import teamsSagas from 'teams/sagas'
import appSagas from 'app/sagas'
import vendorSagas from './vendors/sagas'
import bulkSagas from './bulk/sagas'

export default function* rootSaga() {
  yield all([
    ...matterSagas,
    ...matterTemplatesSagas,
    ...matterTemplatesDetailSagas,
    ...matterRequestFormsSagas,
    ...matterRequestFormSagas,
    ...matterRequestsSagas,
    ...processRequestSagas,
    ...matterRequestSagas,
    ...rulesSagas,
    ...contactsSagas,
    ...accrualsVsActualsSagas,
    ...diversitySagas,
    ...invoicesSagas,
    ...vendorSagas,
    ...mattersSagas,
    ...vendorEvalsSagas,
    ...budgetsSagas,
    ...reviewsSagas,
    ...surveyConfigTabSagas,
    ...scorecardSagas,
    ...clientKeysSagas,
    ...teamsSagas,
    ...todosSagas,
    ...dashboardSagas,
    ...matterEventsSagas,
    ...documentManagementSettingsSagas,
    ...tableauSagas,
    ...supersetReportsSagas,
    ...appSagas,
    ...bulkSagas
  ])
}
