import { Component } from 'react'
import * as ACT from 'matters/templates/actions'
import { Allocations } from './Allocations'
import { Panel } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'
import { connect } from 'react-redux'
import { LEGAL_ENTITY_ID } from 'matters/templates/constants'
import s from './TemplateAllocationsContainer.scss'
import get from 'lodash/get'
import cn from 'classnames'

@withRouter
@connect(({ templateAllocations }) => {
  return {
    attributes: templateAllocations.attributes,
    allocations: templateAllocations.allocations
  }
})
class TemplateAllocationsContainer extends Component {
  addAllocation = () => {
    const allocation = {
      columns: [
        {
          id: 'percentage',
          value: ''
        }
      ]
    }
    this.props.attributes.forEach(attribute => {
      let id
      switch (attribute.allocation_type) {
        case 'legal_entities':
          id = 'legal_entity'
          break
        case 'cost_codes':
          id = 'cost_code'
          break
        default:
          id = attribute.id
      }

      allocation.columns.push({
        id,
        value: ''
      })
    })
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_ADD_EMPTY_ALLOCATION,
      payload: {
        allocation
      }
    })
  }
  removeAllocation = index => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_REMOVE_ALLOCATION,
      payload: {
        index
      }
    })
  }
  changeAllocation = ({ allocationIndex, attributeIndex, value, attributeId = '' }) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_CHANGE_ALLOCATION,
      payload: {
        allocationIndex,
        attributeIndex,
        value
      }
    })

    // if practice area or legal entity change, fire request to get the correct attributes values
    if (LEGAL_ENTITY_ID === attributeId) {
      this.fetchFilteredAttributes(value, allocationIndex)
    }
  }
  fetchFilteredAttributes = (value, allocationIndex) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
      payload: {
        legalEntityId: get(value, 'value', -1),
        allocationIndex
      }
    })
  }
  getOptions = (allocation, attribute) => {
    let options
    allocation.columns.forEach(column => {
      if (column.id === attribute.id && column.options) {
        options = column.options
      }
    })

    return options
  }
  componentDidMount() {
    const { id } = this.props.router.params

    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_REQUESTED
    })
    if (id) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_ALLOCATIONS_FETCH_REQUESTED,
        payload: {
          templateId: id
        }
      })
    }
  }
  render() {
    return (
      <Panel title="Allocations" className={cn(s.noFlex, s.panel)}>
        <Allocations
          allocations={this.props.allocations}
          attributes={this.props.attributes}
          addAllocation={this.addAllocation}
          removeAllocation={this.removeAllocation}
          changeAllocation={this.changeAllocation}
          getOptions={this.getOptions}
        />
      </Panel>
    )
  }
}

export default TemplateAllocationsContainer
