import { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getTotalDaysInQuarter, getDayInQuarter } from 'budgets/utils'
import { curMom } from 'utils/dateUtils'
import Thermometer from 'budgets/Thermometer'
import s from '../styles/BudgetRequest.scss'
import findIndex from 'lodash/findIndex'

const typeLabel = {
  Monthly: 'Month',
  Quarterly: 'Quarter'
}

@connect(({ budgets }) => {
  const details = budgets.budgetDetails
  const type = details.type

  return {
    details,
    type
  }
})
class CurrentSnapshotContainer extends Component {
  getRowIndex = (currentMonth, currentYear) => {
    const rows = this.props.details.rows
    return findIndex(rows, row => {
      // reliant on 'Jan 2018' formatting or MMM YYYY
      const m = moment(row.title, 'MMM YYYY')
      return m.year() === currentYear && m.month() === currentMonth
    })
  }

  getCurrentData = () => {
    const { details } = this.props
    const type = details.type
    let section, approved, pending, accrual, total, rowIndex, timeRemaining, combined
    const startQuarter = moment(details.start_date).quarter()

    const currentMonth = curMom().month()
    const currentYear = curMom().year()
    const currentDay = curMom().date()
    const currentQuarter = curMom().quarter()

    if (type === 'Monthly') {
      rowIndex = this.getRowIndex(currentMonth, currentYear)
      section = curMom().format('MMMM')
      timeRemaining = curMom().daysInMonth() - currentDay
    } else if (type === 'Quarterly') {
      rowIndex = currentQuarter - startQuarter
      section = 'Q' + curMom().format('Q')
      timeRemaining = getTotalDaysInQuarter() - getDayInQuarter()
    }

    if (!details.rows || !details.rows[rowIndex]) {
      throw new Error('Budget Row does not exist')
    }

    total = details.rows[rowIndex].allocated_total
    approved = details.rows[rowIndex].approved_total
    pending = details.rows[rowIndex].pending_total
    accrual = details.rows[rowIndex].accrual_total
    combined = details.rows[rowIndex].combined_total

    return {
      section,
      total,
      approved,
      pending,
      accrual,
      combined,
      timeRemaining
    }
  }

  render() {
    const { type } = this.props
    const {
      section,
      total,
      approved,
      pending,
      accrual,
      combined,
      timeRemaining
    } = this.getCurrentData()

    return (
      <div
        className={s.thermometerContainer}
        style={{ marginTop: 48 }}
        data-testid="curr-snapshot-container"
      >
        <div className={s.summaryHeader}>Current {typeLabel[type]} Spend</div>
        <div className={s.thermometerContainer}>
          <Thermometer
            total={total}
            approved={approved}
            pending={pending}
            accrual={accrual}
            combined={combined}
            timeRemaining={timeRemaining}
            timeType={'Days'}
          />
        </div>
        <div>
          {timeRemaining} days left in {section}
        </div>
      </div>
    )
  }
}

export default CurrentSnapshotContainer
