import cn from 'classnames'
import { FaChevronRight } from 'react-icons/fa'
import s from './BreadcrumbItem.scss'

const BreadcrumbItem = ({ title, clickCb, className = '' }) => {
  return (
    <li className={cn(s.item, className, { [s.clickable]: clickCb })} onClick={clickCb}>
      {title}
      {clickCb && <FaChevronRight />}
    </li>
  )
}

export default BreadcrumbItem
