import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'

const initialState = {
  requests: [],
  currentRequest: {
    otherFields: [],
    additionalFields: []
  }
}

const requestsListReducer = createReducer(initialState, {
  [ACT.LEGAL_REQUEST_LIST_FETCH_SUCCESS](state, action) {
    const { list } = action.payload
    return {
      ...state,
      requests: list
    }
  },

  [ACT.CLEAR_CURRENT_REQUEST_DETAILS](state) {
    return {
      ...state,
      currentRequest: initialState.currentRequest
    }
  },

  [ACT.LEGAL_REQUEST_DETAIL_FETCH_SUCCESS](state, action) {
    const { request } = action.payload
    return {
      ...state,
      currentRequest: request
    }
  },

  [ACT.REQUEST_REJECTED_SUCCESS](state, action) {
    const { request } = action.payload
    const index = state.requests.findIndex(r => r.id === request.id)
    return update(state, {
      requests: {
        [index]: { $set: request }
      }
    })
  },

  [ACT.LEGAL_REQUEST_DETAIL_CHANGE_COMMENTS](state, action) {
    const { value } = action.payload
    return update(state, {
      currentRequest: {
        comments: { $set: value }
      }
    })
  }
})

export default requestsListReducer
