import React from 'react'

import s from './DeletionText.scss'

const DeletionText = () => (
  <React.Fragment>
    <span className={s.text}>Are you sure you want to delete this contact?</span>
    <span className={s.warn}>This will remove the contact from the system.</span>
  </React.Fragment>
)

export default DeletionText
