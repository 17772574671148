import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import { FORM_STATUSES } from 'matters/requestForms/constants'

const initialState = {
  requestForms: []
}

const requestFormsListReducer = createReducer(initialState, {
  [ACT.LEGAL_REQUEST_FORM_LIST_FETCH_SUCCESS](state, action) {
    const { list } = action.payload
    return {
      ...state,
      requestForms: list.request_forms
    }
  },

  [ACT.LEGAL_REQUEST_FORMS_RESTORE_FORM](state, action) {
    const { id } = action.payload
    const formIndex = state.requestForms.findIndex(form => form.id === id)

    return update(state, {
      requestForms: {
        [formIndex]: {
          status: { $set: FORM_STATUSES.PUBLISHED }
        }
      }
    })
  },

  [ACT.LEGAL_REQUEST_FORMS_DELETE_FORM](state, action) {
    const { id } = action.payload
    const formIndex = state.requestForms.findIndex(form => form.id === id)

    return update(state, {
      requestForms: { $splice: [[formIndex, 1]] }
    })
  },

  [ACT.LEGAL_REQUEST_FORMS_ARCHIVE_FORM](state, action) {
    const { id } = action.payload
    const formIndex = state.requestForms.findIndex(form => form.id === id)

    return update(state, {
      requestForms: {
        [formIndex]: {
          status: { $set: FORM_STATUSES.ARCHIVED }
        }
      }
    })
  },
  [ACT.SHOW_REDIRECT](state, action) {
    return {
      ...state,
      redirect: true
    }
  }
})

export default requestFormsListReducer
