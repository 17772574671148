import { DataTableWrapper } from 'simple-core-ui'
import { getNormalizedCellContent } from 'doc_management/settings/utils'
import s from './Notifications.scss'
import { FaTrash } from 'react-icons/fa'

const Notifications = ({ params, rows, categories, columns, updateTable, selectAdmin }) => {
  return (
    <section>
      <section className={s.description}>
        Choose who receives notifications when uploaded files are infected
      </section>
      <DataTableWrapper
        initialValues={params}
        params={params}
        categories={[]}
        rows={rows}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.notificationsTable}
        categoryKey="status"
        getNormalizedCellContent={getNormalizedCellContent}
        actions={[
          {
            icon: FaTrash,
            tooltip: 'Delete',
            onClick: (e, row) => selectAdmin(row)
          }
        ]}
      />
    </section>
  )
}

export default Notifications
