import { hasModule } from 'utils/helpers'
import { BaseGuard } from './types'

/**
 * Represents a guard that controls the user's access to a route depending on having a certain module enabled.
 * `canRoute` returns true if the user has at least one of the modules enabled.
 * @param {...string} modules - A list of modules.
 */
export default function ModuleGuard(...modules: Array<string>): BaseGuard {
  return {
    canRoute() {
      return modules.some(module => hasModule(module))
    }
  }
}
