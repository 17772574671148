import { useState, useEffect } from 'react'
import s from './Comments.scss'
import { Spinner, Modal, withConfirmation } from 'simple-core-ui'
import { makeGetRequest, makePostRequest, makeDeleteRequest, makePutRequest } from 'utils/api'
import MessageArea from './MessageArea/MessageArea'
import Message from './Message/Message'
import Empty from './Empty/Empty'
import { comments2Messages } from './serializers'
import { COMMENTS_URL } from './urls'
import { useDispatch } from 'react-redux'

import update from 'immutability-helper'

const deleteConf = {
  title: 'Delete',
  text: 'Are you sure you want to delete your comment?'
}

const Comments = ({
  user,
  objectId,
  modelName,
  appLabel,
  isError,
  isLoading,
  msgs = [],
  readOnly
}) => {
  const [value, setValue] = useState('')
  const [messages, setMessages] = useState(msgs)
  const [editingMessage, setEditingMessage] = useState('')
  const [editingIndex, setEditingIndex] = useState(-1)
  const dispatch = useDispatch()

  const updateCommentValue = event => {
    let value = event.target.value
    setValue(value)
  }

  const updateComment = async (message, index) => {
    try {
      await makePutRequest(COMMENTS_URL, {
        commentIDs: [message.id],
        text: message.text
      })
      setMessages(
        update(messages, {
          [index]: {
            $set: message
          }
        })
      )
      setValue('')
      setEditingIndex(-1)
      setEditingMessage('')
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const submitComment = async () => {
    try {
      const response = await makePostRequest(COMMENTS_URL, {
        objectIDs: [objectId],
        modelName,
        appLabel,
        text: value
      })
      setMessages(
        update(messages, {
          $push: [
            {
              timestamp: new Date().getTime(),
              user: { full_name: user.firstName + ' ' + user.lastName, id: user.id },
              id: response[0],
              text: value
            }
          ]
        })
      )
      setValue('')
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const editComment = (message, index) => {
    setEditingIndex(index)
    setEditingMessage(message)
  }

  const deleteComment = withConfirmation(async (id, index) => {
    try {
      await makeDeleteRequest(COMMENTS_URL, {
        data: { commentIDs: [id] }
      })
      setMessages(update(messages, { $splice: [[index, 1]] }))
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }, deleteConf)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await makeGetRequest(
          `${COMMENTS_URL}?data=${JSON.stringify({
            objectIDs: [objectId],
            modelName,
            appLabel
          })}`
        )
        setMessages(comments2Messages(response))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.container}>
      {isError ? (
        <Empty>An error has occurred.</Empty>
      ) : isLoading ? (
        <Empty>
          <Spinner />
        </Empty>
      ) : messages.length ? (
        <ul className={s.messages}>
          {messages.map((message, idx) => (
            <Message
              user={user}
              message={message}
              onDelete={e => deleteComment(message.id, idx)}
              onEdit={e => editComment(message, idx)}
              key={message.id}
              readOnly={readOnly}
            />
          ))}
        </ul>
      ) : (
        <Empty>Type your first message below...</Empty>
      )}
      <Modal
        title="Edit Comment"
        size="md"
        confirmText="Submit"
        isVisible={editingIndex !== -1}
        content={
          <textarea
            value={editingMessage.text}
            onChange={e => setEditingMessage({ ...editingMessage, text: e.target.value })}
          />
        }
        cancelCb={() => setEditingIndex(-1)}
        confirmCb={() => updateComment(editingMessage, editingIndex)}
      />
      <MessageArea
        placeholder="Add a comment..."
        value={value}
        changeHandler={updateCommentValue}
        clickHandler={submitComment}
        buttonContent="Submit"
        readOnly={readOnly}
      />
    </div>
  )
}

export default Comments
