import { Option, Jurisdiction } from './types'
import { timezoneDate } from 'utils/helpers'
import { format } from 'date-fns'

interface Obj {
  id: number
  name: string
}

interface JurisdictionObj {
  id: number
  name: string
  needs_review: boolean
  added: string
  matter_count: number
}

export const toOptions = (list: Obj[]): Option[] => {
  return list.map(el => ({
    value: el.id,
    label: el.name
  }))
}

export const toJurisdictionOptions = (list: JurisdictionObj[]): Jurisdiction[] => {
  return list.map(el => ({
    id: el.id,
    value: el.id,
    label: el.name,
    needsReview: el.needs_review,
    added: format(timezoneDate(el.added), 'MM/dd/yyyy'),
    matterCount: el.matter_count
  }))
}
