import update from 'immutability-helper'
import get from 'lodash/get'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'

import { SCOPE } from 'reviews/constants'

import { APPROVAL_LEVEL_CONDITION } from './constants'

export const removeReviewerTier = (tierIdx, tiers) => tiers.filter((_, idx) => idx !== tierIdx)

export const addReviewerTier = (newTier, tiers) => [...tiers, newTier]

export const coalesceReviewerTier = (prevTier, tier) => ({
  reviewers: get(tier, 'reviewers', prevTier.reviewers),
  approvalClause: get(tier, 'approvalClause', prevTier.approvalClause)
})

export const editReviewerTier = (tierIdx, updatedTier, tiers) => {
  const prevTier = tiers[tierIdx]
  const coalescedTier = coalesceReviewerTier(prevTier, updatedTier)

  return update(tiers, {
    $splice: [[tierIdx, 1, coalescedTier]]
  })
}

export const generateNewReviewerTier = () => ({
  reviewers: [],
  approvalClause: APPROVAL_LEVEL_CONDITION.ALL
})

export const filterEmptyTiers = tiers => tiers.filter(({ reviewers }) => reviewers.length)

export const dedupeReviewerTiers = (lastUpdatedIndex, tiers) => {
  const updatedTier = tiers[lastUpdatedIndex] ?? {}

  return filterEmptyTiers(
    tiers.map((tier, tierIdx) =>
      tierIdx === lastUpdatedIndex
        ? tier
        : {
            ...tier,
            reviewers: differenceWith(tier.reviewers, updatedTier.reviewers, isEqual)
          }
    )
  )
}

export const normalizeTeam = team => {
  return team.contact
    ? {
        primary: `${team.contact.firstName} ${team.contact.lastName}`,
        secondary: team.contact.email
      }
    : {
        primary: team.name,
        secondary: `${team.description} (${team.memberCount} member${
          team.memberCount > 1 ? 's' : ''
        })`.trim()
      }
}

// Used to determine if the UPDATE saga should POST or not.
// In cases where this returns true the store will be updated and a
// POST will be made to the server with the updated config.
// Otherwise it will simply update the store with the new config.
export const shouldPostOnUpdate = scope =>
  ![SCOPE.APPROVAL_WORKFLOW, SCOPE.MATTER_REVIEW_RULES].includes(scope)
