import { put, takeLatest, call } from 'redux-saga/effects'

import ACT from './actions'

import { makeGetRequest } from 'utils/api'

function* fetchMatterDetails(action) {
  try {
    const response = yield call(makeGetRequest, `/manage/matters/v2/${action.payload}/json/`)

    yield put({
      type: ACT.MATTER_DETAIL_FETCH_SUCCESS,
      payload: { isConfidential: response.is_confidential }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue fetching the details for the matter.',
        level: 'error'
      }
    })
  }
}

const matterSagas = [takeLatest(ACT.MATTER_DETAIL_FETCH_REQUESTED, fetchMatterDetails)]

export default matterSagas
