import { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  DataTableWrapper,
  Button,
  coreThemes,
  ModalContainer,
  Panel,
  useLoading,
  Ellipsis
} from 'simple-core-ui'
import s from './DynamicAttributesList.scss'
import moment from 'moment'
import { getNormalizedCellContent } from './utils'
import { checkDuplicateName } from '../common/utils'
import { AddRelationship } from '../common/AddRelationship'
import { useImmer } from 'use-immer'
import { toGroupedAttributes, toDynamicAttributes, toAttrIds } from '../common/serializers'
import { ActionsPopover } from './ActionsPopover'
import swal from 'sweetalert'
import update from 'immutability-helper'
import { makeGetRequest, makePostRequest } from 'utils/api'
import APP_ACT from 'app/actions'
import { capitalizeStr } from 'utils/formatting'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { RELATIONSHIP_STATUS } from '../common/constants'
import { FaAngleLeft } from 'react-icons/fa'
import { openLink } from 'utils/helpers'

const initialParams = {
  pageSize: 10,
  ordering: { columnKey: 'last_updated', isDesc: true },
  search: '',
  page: 1,
  category: 'all'
}

const initialRelationship = {
  name: '',
  description: '',
  level1: {},
  level2: {},
  level3: {}
}

const dynamicAttributesList = {
  results: [],
  total: 0,
  filtered: 0,
  page: 1
}

const DynamicAttributesList = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState(initialParams)
  const [dynamicAttributes, setDynamicAttributes] = useState(dynamicAttributesList)
  const [customAttributes, setCustomAttributes] = useState([])
  const [initialCustomAttributes, setinitialCustomAttributes] = useState([])
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [showAddRelationshipModal, setShowAddRelationshipModal] = useState(false)
  const [relationship, setRelationship] = useImmer(initialRelationship)
  const navigate = useNavigate()
  const [isLoading, withLoadingLocks] = useLoading()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      try {
        const { groups } = await makeGetRequest('/templates/custom-attributes')
        const attributes = toGroupedAttributes(groups)
        setCustomAttributes(attributes)
        setinitialCustomAttributes(attributes)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { relationships } = await withLoadingLocks(
          makeGetRequest('/templates/related/list_relationships')
        )
        const newState = update(dynamicAttributes, {
          results: { $set: toDynamicAttributes(relationships) }
        })
        setDynamicAttributes(newState)
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { level1, level2, level3 } = relationship
    const filteredAttributes = initialCustomAttributes.map(attr => ({
      ...attr,
      options: attr.options.filter(
        a => ![+level1.value, +level2.value, +level3?.value].includes(a.value)
      )
    }))
    setCustomAttributes(filteredAttributes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationship.level1?.value, relationship.level2?.value, relationship.level3?.value])

  const results = useMemo(() => {
    const { results, total } = dynamicAttributes
    const columns = [
      {
        columnKey: 'name',
        content: 'Name',
        isSortable: true,
        isFilterable: true,
        render: (cell, row) => {
          const content = cell.content
          return <Link to={`/v2/dynamic_attributes/pair/${row.id}#edit=true`}>{content}</Link>
        }
      },
      {
        columnKey: 'description',
        content: 'Description',
        isSortable: true,
        render: (cell, row) => {
          const content = cell.content
          return <Ellipsis lines={1}>{content}</Ellipsis>
        }
      },
      {
        columnKey: 'scope',
        content: 'Attribute Scope',
        isSortable: false
      },
      {
        columnKey: 'levels',
        content: 'Levels',
        isSortable: true
      },
      {
        columnKey: 'last_updated',
        content: 'Last Updated',
        isSortable: true,
        render: cell => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'needs_update',
        content: 'Needs Update',
        isSortable: true,
        render: cell => {
          if (cell.content === '----') return 'No'
          return 'Yes'
        }
      },
      {
        columnKey: 'status',
        content: 'Status',
        isSortable: true,
        render: cell => {
          return capitalizeStr(cell.content)
        }
      },
      {
        columnKey: 'related',
        content: 'Related',
        isSortable: true
      }
    ]
    return {
      columns,
      rows: results,
      totalEntries: total,
      filteredTotal: total
    }
  }, [dynamicAttributes])

  const updateTable = updatedParams => {
    setParams(updatedParams)
  }

  const toggleAddRelationshipModal = () => {
    setShowAddRelationshipModal(!showAddRelationshipModal)
    setShowDuplicateError(false)
    setRelationship(draft => {
      return initialRelationship
    })
    if (showAddRelationshipModal) setCustomAttributes(initialCustomAttributes)
  }

  const changeProperty = (property, value) => {
    setRelationship(draft => {
      draft[property] = value
    })
  }

  const deleteAttribute = async id => {
    const index = dynamicAttributes.results.findIndex(e => e.id === id)
    try {
      let willDelete = await swal({
        title: 'Delete Relationship',
        text: `Are you sure you want to delete the relationship ${dynamicAttributes.results[index]?.name}?`,
        buttons: ['No', 'Yes'],
        icon: 'warning'
      })
      if (willDelete) {
        await withLoadingLocks(
          makePostRequest('/templates/related/delete', {
            rel_id: String(id)
          })
        )
        const newState = update(dynamicAttributes, {
          results: { $splice: [[index, 1]] }
        })
        setDynamicAttributes(newState)
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Success',
            message: 'Relationship successfully deleted',
            level: 'success'
          }
        })
      }
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: 'The relationship has not been deleted properly.',
          level: 'error'
        }
      })
    }
  }

  const deactivateRelationship = async id => {
    try {
      const index = dynamicAttributes.results.findIndex(a => a.id === id)
      const relationship = dynamicAttributes.results[index]

      await withLoadingLocks(
        makePostRequest('/templates/related/update', {
          rel_id: String(id),
          status: RELATIONSHIP_STATUS.INACTIVE
        })
      )

      const newState = update(dynamicAttributes, {
        results: {
          [index]: {
            $set: {
              ...relationship,
              last_updated: moment().format('M/D/YYYY'),
              status: RELATIONSHIP_STATUS.INACTIVE
            }
          }
        }
      })
      setDynamicAttributes(newState)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Relationship successfully inactivated',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  const downloadPairings = id => {
    openLink(`/templates/related/export_pairs/${id}`)
  }

  const activateRelationship = async id => {
    try {
      const index = dynamicAttributes.results.findIndex(a => a.id === id)
      const relationship = dynamicAttributes.results[index]

      await withLoadingLocks(
        makePostRequest('/templates/related/update', {
          rel_id: String(id),
          status: RELATIONSHIP_STATUS.ACTIVE
        })
      )

      const newState = update(dynamicAttributes, {
        results: {
          [index]: {
            $set: {
              ...relationship,
              last_updated: moment().format('M/D/YYYY'),
              status: RELATIONSHIP_STATUS.ACTIVE
            }
          }
        }
      })
      setDynamicAttributes(newState)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Relationship successfully activated',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  const renderCustomAction = row => (
    <ActionsPopover
      rowId={row.id}
      deleteAttribute={deleteAttribute}
      canDelete={!row.usedOnTemplate}
      activateRelationship={activateRelationship}
      deactivateRelationship={deactivateRelationship}
      downloadPairings={downloadPairings}
      status={row.status}
      hasPairings={row.has_pairings}
    />
  )

  const addRelationship = async cb => {
    const { level1, level2, level3 } = relationship
    try {
      const { rel_id, status } = await withLoadingLocks(
        makePostRequest('/templates/related/create', {
          name: relationship.name,
          description: relationship.description,
          cols: toAttrIds([level1, level2, level3])
        })
      )

      const newState = update(dynamicAttributes, {
        results: {
          $push: [
            {
              ...relationship,
              id: rel_id,
              last_updated: moment().format('YYYY-MM-DD'),
              levels: [level1.value, level2.value, level3?.value].filter(l => l !== undefined)
                .length,
              scope: 'Matter',
              status,
              related: 0
            }
          ]
        }
      })

      setDynamicAttributes(newState)
      toggleAddRelationshipModal()
      cb && cb(rel_id)
    } catch (error) {
      toggleAddRelationshipModal()
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  const addAndSetup = () => {
    addRelationship(id => {
      navigate(`/v2/dynamic_attributes/pair/${id}`)
    })
  }

  const isBtnDisabled = () => {
    return (
      showDuplicateError ||
      !relationship.name.trim() ||
      relationship.name.length > 250 ||
      relationship.description.length > 150 ||
      !relationship.level1.value ||
      !relationship.level2.value
    )
  }
  return (
    <>
      <h2 className={s.title}>Manage Relationships</h2>
      <Panel
        rightActions={[
          <Button key="import" href="/v2/dynamic_attributes/import">
            Import CSV
          </Button>,
          <Button isPrimary key="relationship" onClick={() => toggleAddRelationshipModal()}>
            Add Relationship
          </Button>
        ]}
        leftActions={[
          <a key="0" href="/manage/matter_attributes/">
            <FaAngleLeft /> Back to Custom Attributes
          </a>
        ]}
        styles={{ boxShadow: 'none' }}
        className={s.relationshipsContainer}
      >
        <section>
          <DataTableWrapper
            params={params}
            customSearchOptions={{
              styles: { width: 270, fontStyle: 'italic' }
            }}
            // remotePagination
            className={s.relationshipsTable}
            categories={[]}
            rows={results.rows}
            columns={results.columns}
            totalEntries={results.totalEntries}
            filteredTotal={results.filteredTotal}
            updateTable={updateTable}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            customAction={renderCustomAction}
            hasActions
            statusText="No records to display. Once you create relationships, they will be visible here."
            hasTooltip
            theme={coreThemes.EB}
            getNormalizedCellContent={getNormalizedCellContent}
          />
        </section>
        {showAddRelationshipModal && (
          <ModalContainer
            size="sm"
            cancelCb={toggleAddRelationshipModal}
            title="Add New Relationship"
            confirmText="Add and Setup"
            cancelText="Cancel"
            secondaryConfirmText="Quick Add"
            secondaryConfirmCb={() => addRelationship()}
            confirmCb={() => addAndSetup()}
            isDisabled={isBtnDisabled()}
            isDisabledSecondary={isBtnDisabled()}
            content={
              <AddRelationship
                showDuplicateError={showDuplicateError}
                changeProperty={changeProperty}
                relationship={relationship}
                checkDuplicateName={name => checkDuplicateName(dynamicAttributes.results, name)}
                setShowDuplicateError={setShowDuplicateError}
                customAttributes={customAttributes}
              />
            }
          />
        )}
      </Panel>
    </>
  )
}

export default DynamicAttributesList
