import { useSimpleReviewContext } from 'simple_review/hooks'
import { SerializedOperandReadOnly } from './SerializedOperandReadOnly'
import { Operand } from 'simple_review/@types/api'
import { buildExpressionMapperFromApi } from './build-expression-mapper'
import { Button } from 'simple-core-ui'
import s from './ExpressionReadOnly.scss'

type Props = {
  operand: Operand
  ruleName: string
}

// Component to render a comparison template.
function ExpressionReadOnly({ operand, ruleName }: Props) {
  const { state: context } = useSimpleReviewContext()

  if ('model_name' in operand.lhs && operand.lhs.model_name === 'AdjustmentCodeClassification')
    return (
      <div className={s.aiPowered}>
        <div className={s.wrapper}>
          <Button className={s.button}>AI Powered : {ruleName}</Button>
        </div>
      </div>
    )

  const expressionMapper = buildExpressionMapperFromApi(operand, context)

  const serializedOperand = expressionMapper.fromJSON(context)

  const { lhs, op, rhs } = serializedOperand

  return <SerializedOperandReadOnly lhsPath={lhs.path} opLabel={op.label} rhsLabel={rhs?.label} />
}

export default ExpressionReadOnly
