import * as React from 'react'
import classNames from 'classnames'
import castArray from 'lodash/castArray'

import { IoIosArrowDown } from 'react-icons/io'

import s from './Expandable.scss'

const Expandable = ({ isExpanded, toggleExpansion, showCount, minShowing, children }) => (
  <React.Fragment>
    {castArray(children).length <= minShowing ? (
      children
    ) : isExpanded ? (
      <React.Fragment>
        {children}
        <section data-testid="toggle_expand" onClick={toggleExpansion} className={s.arrowContainer}>
          <IoIosArrowDown className={classNames(s.arrow, { [s.rotated]: isExpanded })} />
        </section>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {children.slice(0, minShowing)}
        <section data-testid="toggle_expand" onClick={toggleExpansion} className={s.arrowContainer}>
          {showCount && (
            <span className={s.more}>
              Show More <span className={s.count}>({children.length - minShowing})</span>
            </span>
          )}
          <IoIosArrowDown className={s.arrow} />
        </section>
      </React.Fragment>
    )}
  </React.Fragment>
)

Expandable.defaultProps = {
  minShowing: 1
}

export default Expandable
