import ModalField from './ModalField'
import { VendorContactSelect, TimekeeperSelect } from 'common/Selects'

const EditLeadForm = ({
  currentLead,
  selectedVendor,
  setCurrentLead,
  currentTimekeeper,
  setCurrentTimekeeper
}) => {
  return (
    <>
      <ModalField label="Vendor Lead">
        <VendorContactSelect
          value={currentLead}
          selectedVendorId={selectedVendor}
          onChange={v => {
            setCurrentTimekeeper(null)
            setCurrentLead(v)
          }}
          isPortal={false}
          isClearable
          paginated
        />
      </ModalField>
      <ModalField label="Is the vendor lead also a timekeeper? If yes, select which timekeeper.">
        <TimekeeperSelect
          key={currentTimekeeper}
          value={currentTimekeeper}
          selectedVendorId={selectedVendor}
          onChange={v => {
            setCurrentTimekeeper(v)
          }}
          isPortal={false}
          isDisabled={!currentLead || !window.credentials.user.role === 'admin'}
          isClearable
          paginated
        />
      </ModalField>
    </>
  )
}

export default EditLeadForm
