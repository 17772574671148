import { Component } from 'react'
import { SelectionPageContentContainer } from './SelectionPageContent'
import { SelectionPageSidebarContainer } from './SelectionPageSidebar'
import { Loading } from 'components'
import s from './TemplateSelection.scss'

class TemplateSelection extends Component {
  state = {
    isTemplateSidebarOpen: true
  }

  toggleTemplateSidebar = () => {
    this.setState(prevState => ({
      isTemplateSidebarOpen: !prevState.isTemplateSidebarOpen
    }))
  }

  render() {
    if (this.props.loading) {
      return <Loading />
    }

    return (
      <div className={s.container}>
        <SelectionPageSidebarContainer
          togglePreferred={this.props.togglePreferred}
          list={this.props.list}
          getTemplate={this.props.getTemplate}
          showSidebar={this.props.showSidebar}
          toggleTemplateSidebar={this.toggleTemplateSidebar}
          isTemplateSidebarOpen={this.state.isTemplateSidebarOpen}
        />
        <SelectionPageContentContainer
          navigateToFormPage={this.props.navigateToFormPage}
          navigateToFormPageWithoutIds={this.props.navigateToFormPageWithoutIds}
          noOfPreferred={this.props.noOfPreferred}
          title={this.props.title}
          description={this.props.description}
          processedDescription={this.props.processedDescription}
          pageKey={this.props.pageKey}
          list={this.props.list}
          getTemplate={this.props.getTemplate}
          fullContent={!this.props.showSidebar || !this.state.isTemplateSidebarOpen}
        />
      </div>
    )
  }
}

export default TemplateSelection
