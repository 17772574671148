import { useState } from 'react'
import { useLoading, ModalContainer } from 'simple-core-ui'
import { RequestsTableContainer } from 'matters/requests/common/RequestsTableContainer'
import s from './ProcessRequest.scss'
import { REQUEST_STATUSES } from 'matters/requests/constants'
import capitalize from 'lodash/capitalize'
import { makeGetRequest } from 'utils/api'
import { openLink } from 'utils/helpers'

const { PENDING } = REQUEST_STATUSES

const ProcessRequests = ({ processRequest, rejectRequest, columns, getNormalizedCellContent }) => {
  const [isLoading, withLoadingLocks] = useLoading()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getSecondaryAction = (requests, category) => {
    return requests.length
      ? {
          onClick: async () => {
            const response = await withLoadingLocks(
              makeGetRequest(`/manage/matters/requests/export?status=${category}`)
            )
            if (response?.url) {
              openLink(response.url)
            } else {
              setIsModalOpen(true)
            }
          },
          children: 'Download'
        }
      : null
  }

  return (
    <div className={s.container}>
      <RequestsTableContainer
        title="Review Legal Requests"
        columns={columns}
        getNormalizedCellContent={getNormalizedCellContent}
        saveBtnText="Create Matter"
        secondaryConfirmText="Reject"
        confirmCb={processRequest}
        secondaryConfirmCb={rejectRequest}
        hideCancelBtn
        initialCategory={capitalize(PENDING)}
        getSecondaryAction={getSecondaryAction}
      />
      {isModalOpen && (
        <ModalContainer
          title="We are working on your download"
          content="You’ll receive an email once your report is ready."
          confirmText="OK"
          confirmCb={() => setIsModalOpen(false)}
          cancelCb={() => setIsModalOpen(false)}
          size="sm"
          hideCancelBtn
        />
      )}
    </div>
  )
}

export default ProcessRequests
