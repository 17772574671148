import moment from 'moment'

export default [
  {
    title: 'Court Hearing',
    description: 'We are going to court for this matter',
    time: moment().toDate()
  },
  {
    title: 'Litigation Meeting',
    description: 'We are meeting about litigation',
    time: moment()
      .add(2, 'days')
      .toDate()
  },
  {
    title: 'Budgeting Summit',
    description: 'We are meeting about budgets',
    time: moment()
      .add(10, 'days')
      .toDate()
  },
  {
    title: 'Accruals Meeting',
    description: 'We are meeting about accruals',
    time: moment()
      .add(4, 'days')
      .toDate()
  },
  {
    title: 'Sign Contract',
    description: 'We are signing a contract',
    time: moment()
      .add(12, 'days')
      .toDate()
  },
  {
    title: 'Run down of legal activities',
    description: 'We are meeting about legal matters',
    time: moment()
      .add(1, 'days')
      .toDate()
  },
  {
    title: 'Important Meeting',
    description: 'We are meeting about something important',
    time: moment()
      .add(5, 'days')
      .toDate()
  },
  {
    title: 'Important Meeting',
    description: 'We are meeting about something important',
    time: moment()
      .subtract(2, 'days')
      .toDate()
  }
]
