import { put, takeLatest, call, all } from 'redux-saga/effects'
import ACT from './actions'
import { formatBarData, formatCardData, formatDoughnutData, toFragment } from './serializers'
import { makeGetRequest } from 'utils/api'
import moment from 'moment'

const getCalls = (n, years, body, url) => {
  let calls = []
  for (let i = n - 1; i >= 0; i--) {
    const y = moment()
      .subtract('year', i)
      .endOf('year')
      .format('YYYY-MM-DD')

    body = {
      filter_date: y
    }

    calls.push(call(makeGetRequest, url, { params: body }))
  }
  return calls
}

const getCharts = (responses, n, withoutYear) => {
  let charts = []

  responses.forEach((r, i) => {
    const year = moment()
      .subtract('year', responses.length - 1 - i)
      .endOf('year')
      .format('YYYY')

    const barChart = formatBarData(r.race)
    const doughnutChart = formatDoughnutData(r.gender)
    const disabilityStatus = formatCardData(r.disability)
    const veteranStatus = formatCardData(r.veteran_status)
    const lgbtqStatus = formatCardData(r.lgbtq)

    charts.push({
      barChart,
      doughnutChart,
      disabilityStatus,
      veteranStatus,
      lgbtqStatus,
      ...(withoutYear ? {} : { year })
    })
  })

  return charts
}

export function* requestDiversityData(action) {
  try {
    const { isVendor, viewBy, years } = action.payload
    let { filters } = action.payload

    let body = {
      filter_date: filters.date.value
    }

    if (viewBy === 'vendor_lead') {
      filters = {
        ...filters,
        tab: 'percent_headcount'
      }
    }

    const tab =
      typeof filters.tab === 'object' && filters.tab !== null
        ? Object.entries(filters.tab).find(([key, value]) => value)[0]
        : filters.tab

    let urlTernary

    if (viewBy !== 'vendor_lead' && viewBy !== 'vendor_comparison') {
      urlTernary = isVendor ? filters.vendor.value : filters.practiceArea.value
      if (!urlTernary) {
        return yield put({
          type: ACT.DIVERSITY_SUCCESS,
          loadingLock: 'off',
          payload: {
            filters: {
              ...action.payload.filters
            }
          }
        })
      }
    } else {
      urlTernary = 'all'
    }

    const url = `/reports/diversity/${viewBy}/${urlTernary}/${tab}/`

    if (body.filter_date === '3 yrs') {
      const calls = getCalls(3, years, body, url)
      let response = yield all(calls)

      const charts = getCharts(response, 3)

      window.history.replaceState('', '', toFragment(action.payload.filters))

      yield put({
        type: ACT.DIVERSITY_SUCCESS,
        loadingLock: 'off',
        payload: {
          filters: {
            ...action.payload.filters
          },
          charts,
          rawData: response
        }
      })
    } else if (viewBy === 'vendor_comparison') {
      const url = vendor => `/reports/diversity/vendor/${vendor}/${tab}/`
      const response = yield all(
        filters.vendorComparison.map(vendor =>
          call(makeGetRequest, url(vendor.value), { params: { filter_date: filters.date.value } })
        )
      )

      window.history.replaceState('', '', toFragment(action.payload.filters))

      yield put({
        type: ACT.DIVERSITY_SUCCESS,
        loadingLock: 'off',
        payload: {
          filters: {
            ...action.payload.filters
          },
          vendorComparison: response.map((r, i) => ({
            ...r,
            status: {
              lgbtq: r.lgbtq,
              veteran_status: r.veteran_status,
              disability: r.disability
            },
            vendorName: filters.vendorComparison[i].label,
            vendorId: filters.vendorComparison[i].value
          }))
        }
      })
    } else if (body.filter_date === '2 yrs') {
      const calls = getCalls(2, years, body, url)
      const response = yield all(calls)

      const charts = getCharts(response, 2)

      window.history.replaceState('', '', toFragment(action.payload.filters))

      yield put({
        type: ACT.DIVERSITY_SUCCESS,
        loadingLock: 'off',
        payload: {
          filters: {
            ...action.payload.filters
          },
          charts,
          rawData: response
        }
      })
    } else if (body.filter_date === '12 mo') {
      const date = moment()
        .subtract('year', 1)
        .format('YYYY-MM-DD')

      body = {
        filter_date: date
      }

      const response = yield call(makeGetRequest, url, { params: body })

      const barChart = formatBarData(response.race)
      const doughnutChart = formatDoughnutData(response.gender)
      const disabilityStatus = formatCardData(response.disability)
      const veteranStatus = formatCardData(response.veteran_status)
      const lgbtqStatus = formatCardData(response.lgbtq)

      window.history.replaceState('', '', toFragment(action.payload.filters))

      yield put({
        type: ACT.DIVERSITY_SUCCESS,
        loadingLock: 'off',
        payload: {
          filters: {
            ...action.payload.filters
          },
          barChart,
          doughnutChart,
          disabilityStatus,
          veteranStatus,
          lgbtqStatus,
          rawData: response
        }
      })
    } else {
      const response = yield call(makeGetRequest, url, { params: body })

      const barChart = formatBarData(response.race)
      const doughnutChart = formatDoughnutData(response.gender)
      const disabilityStatus = formatCardData(response.disability)
      const veteranStatus = formatCardData(response.veteran_status)
      const lgbtqStatus = formatCardData(response.lgbtq)

      window.history.replaceState('', '', toFragment(action.payload.filters))

      yield put({
        type: ACT.DIVERSITY_SUCCESS,
        loadingLock: 'off',
        payload: {
          filters: {
            ...action.payload.filters
          },
          barChart,
          doughnutChart,
          disabilityStatus,
          veteranStatus,
          lgbtqStatus,
          rawData: response
        }
      })
    }
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

const diversitySagas = [takeLatest(ACT.DIVERSITY_REQUESTED, requestDiversityData)]

export default diversitySagas
