import { Button } from 'simple-core-ui'
import s from './Prompt.scss'
import { PromptsListItem } from '../../types'

interface Props {
  prompt: PromptsListItem
  cb: (arg: string) => void
  isHistoryItem: boolean
}

const Prompt = ({ prompt, cb, isHistoryItem }: Props) => {
  return (
    <div className={s.container}>
      {isHistoryItem ? <p className={s.date}>{prompt.date}</p> : null}
      <div className={s.contentWrapper}>
        <p className={s.text}>{prompt.text}</p>
        <Button
          onClick={() => cb(prompt.text)}
          isSecondary
          isOutline
          hasNewDesign
          className={s.button}
        >
          Use
        </Button>
      </div>
    </div>
  )
}

export default Prompt
