/* eslint-disable react/jsx-key */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { toFragment, fromFragment, toStartEndDateFormatted } from '../serializers'

import s from './AccrualsVsActuals.scss'
import { VendorFilter, DateFilter } from 'common/Filters'
import { LAST_6_MONTHS, LAST_YEAR, defaultOptions } from 'common/Filters/components/DateRangeSelect'

import ACT from 'accruals_vs_actuals/actions'

import AccVSAccCard from 'accruals_vs_actuals/card/AccrualsVsActualsCard'
import { Button, ModalContainer, DataTableContainer } from 'simple-core-ui'
import { makeBlobRequest } from 'utils/api'
import { DATE_FORMATS } from 'utils/constants'
import { columns, NO_CATEGORIES } from '../constants'

const { DJANGO_DATE } = DATE_FORMATS
const last6months = defaultOptions[4]
@connect(
  ({ accrualsVsActuals: { list, totalResults, excelUrl, filteredResults }, app: { loading } }) => {
    return {
      list,
      filteredResults,
      excelUrl,
      totalResults,
      isLoading: loading.ACCRUALS_ACTUALS_FETCH
    }
  }
)
class AccrualsVsActuals extends React.Component {
  state = {
    reset: 0,
    filters: {
      pageSize: 50,
      ordering: { columnKey: 'total', isDesc: true },
      page: 1,
      search: '',
      category: '',
      vendor_id_list: [],
      reset: 0,
      ...fromFragment(window.location.hash)
    }
  }

  componentDidMount() {
    this.filterHandler()
  }

  componentDidUpdate({ list }) {
    const { list: actualList } = this.props
    if (list.length !== actualList.length) {
      ReactTooltip.rebuild()
    }
  }

  filterHandler = (filter = {}) => {
    const { filters } = this.state

    this.setState(
      {
        filters: {
          ...filters,
          page: 1,
          ...filter
        }
      },
      this.handleVendors
    )
  }

  handleDownload = openModal => async () => {
    const serializedPayload = {
      search: this.state.filters.search,
      vendor_id_list: this.state.filters.vendor_id_list.map(x => x.value),
      end_date: this.state.filters.end_date,
      start_date: this.state.filters.start_date
    }
    try {
      const data = await makeBlobRequest({
        url: this.props.excelUrl,
        method: 'POST',
        data: serializedPayload
      })
      if (data.size) {
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = 'Accruals v Actuals.xlsx'
        link.click()
      } else openModal()
    } catch (error) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Sorry, couldn't retrieve the XLSX for your request. Please try again with other filters.`,
          level: 'error'
        }
      })
    }
  }

  handleVendors = () => {
    const { filters } = this.state
    this.props.dispatch({
      type: ACT.ACCRUALS_ACTUALS_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: { ...filters, vendor_id_list: filters.vendor_id_list.map(x => x.value) }
    })
  }

  resetAllFilters = () => {
    window.history.pushState('', '', '#')

    const { filters } = this.state
    this.filterHandler({
      reset: filters.reset + 1,
      vendor_id_list: [],
      ...toStartEndDateFormatted(LAST_6_MONTHS)
    })
  }

  dateFilterHandler = date => {
    toFragment({ ...this.state.filters, ...date })

    this.filterHandler(
      date
        ? {
            start_date: moment(date.value.min).format(DJANGO_DATE),
            end_date: moment(date.value.max).format(DJANGO_DATE)
          }
        : toStartEndDateFormatted(LAST_YEAR)
    )
  }

  vendorFilterHandler = vendors => {
    toFragment({ ...this.state.filters, vendor_id_list: vendors })

    this.filterHandler({
      vendor_id_list: vendors || []
    })
  }

  render() {
    const { list, filteredResults, isLoading } = this.props
    const { page, filters } = this.state

    return (
      <section className={s.container}>
        <ReactTooltip effect="solid" place="bottom" type="light" className={s.tooltipPopup} />

        <DataTableContainer
          initialValues={filters}
          categories={NO_CATEGORIES}
          striped={false}
          hideHeader
          isLoading={isLoading}
          noHover
          title="Accruals v Actuals Report"
          filters={
            <div className={s.filterContainer}>
              <DateFilter
                reset={filters.reset}
                onSelect={this.dateFilterHandler}
                defaultValue={last6months}
                initialValue={{
                  value: {
                    min: filters.start_date,
                    max: filters.end_date
                  }
                }}
              />

              <VendorFilter
                reset={filters.reset}
                isMulti
                initialValue={filters.vendor_id_list.length ? filters.vendor_id_list : null}
                onSelect={this.vendorFilterHandler}
              />
              <div className={s.clear} onClick={this.resetAllFilters}>
                Reset
              </div>
              <ModalContainer
                title="We are working on your download"
                content="You’ll receive an email once your report is ready."
                confirmText="OK"
                // eslint-disable-next-line no-console
                confirmCb={() => console.log('Clicked close modal!')}
                size="sm"
              >
                {openModal => (
                  <Button
                    isSecondary
                    onClick={this.handleDownload(openModal)}
                    style={{ right: 0, position: 'absolute' }}
                  >
                    Download
                  </Button>
                )}
              </ModalContainer>
            </div>
          }
          previousPage={page - 1}
          nextPage={page + 1}
          totalEntries={list.length}
          filteredTotal={filteredResults}
          rows={list.map(
            ({ vendor_total, vendor_name, vendor_url, id, vendor_currency, vendor_id }) => ({
              id,
              cells: [
                {
                  columnKey: 'AccrualsVsActualsCard',
                  content: (
                    <AccVSAccCard
                      key={id}
                      vendorUrl={vendor_url}
                      startDate={this.state.filters.start_date}
                      endDate={this.state.filters.end_date}
                      vendorTotal={vendor_total}
                      vendorName={vendor_name}
                      vendorCurrency={vendor_currency}
                      vendorId={vendor_id}
                    />
                  )
                }
              ],
              canDelete: true,
              borderColor: 'transparent'
            })
          )}
          columns={columns}
          updateTable={this.filterHandler}
        />
      </section>
    )
  }
}

export default AccrualsVsActuals
