import { Component } from 'react'
import { EditableTextarea } from 'components'

class EditableTextareaContainer extends Component {
  static defaultProps = {
    content: ''
  }
  state = { text: this.props.content }

  handleChange = value => this.setState({ text: value })

  render() {
    const { saveChanges, discardChanges } = this.props

    return (
      <EditableTextarea
        text={this.state.text}
        handleChange={this.handleChange}
        saveChanges={saveChanges}
        discardChanges={discardChanges}
      />
    )
  }
}

export default EditableTextareaContainer
