import { Component } from 'react'
import get from 'lodash/get'

import Body from '../components/Body'
import ModuleContext from 'context/ModuleContext'

class BodyContainer extends Component {
  componentDidMount() {
    this.fadeInPage()
  }

  fadeInPage = () => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        document.body.style.opacity = '1'
      })
    })
  }

  render() {
    return (
      <ModuleContext.Provider value={get(window, 'credentials.modules', [])}>
        <Body {...this.props} />
      </ModuleContext.Provider>
    )
  }
}

export default BodyContainer
