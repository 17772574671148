import ACT from './actions'
import { createReducer } from 'redux-create-reducer'

const initialState = {
  invoices: [],
  budgets: [],
  legalRequests: [],
  vendorSurveys: [],
  matters: [],
  kpis: {
    open_invoices: {},
    invoices_on_hold: {},
    open_matters: {}
  },
  recentItems: { lastWeek: [], lastMonth: [] },
  vendorsFollowing: [],
  mattersFollowing: [],
  timekeepers: [],
  courtRules: [],
  events: []
}

const dashboardReducer = createReducer(initialState, {
  [ACT.ITEMS_NEEDING_MY_ACTION_FETCH_SUCCESS](state, action) {
    const {
      invoices,
      budgets,
      legalRequests,
      vendorSurveys,
      matters,
      timekeepers,
      courtRules
    } = action.payload

    return {
      ...state,
      invoices,
      budgets,
      legalRequests,
      vendorSurveys,
      matters,
      timekeepers,
      courtRules
    }
  },
  [ACT.RECENT_ITEMS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      recentItems: action.payload
    }
  },
  [ACT.FOLLOWING_FETCH_SUCCESS](state, action) {
    const { vendorsFollowing, mattersFollowing } = action.payload

    return {
      ...state,
      vendorsFollowing,
      mattersFollowing
    }
  },
  [ACT.DASHBOARD_KPIS_FETCH_SUCCESS](state, action) {
    const { kpis } = action.payload

    return {
      ...state,
      kpis
    }
  }
})

export default dashboardReducer
