import { Component } from 'react'
import { SELECT_TEMPLATE_FLOW, PAGE_DESCRIPTIONS } from 'matters/templates/constants'
import * as ACT from 'matters/templates/actions'

const withSelectTemplatePageDescription = WrappedComponent => {
  class PageDescriptionParent extends Component {
    async componentDidUpdate(prevProps) {
      if (this.props.pageDescription !== prevProps.pageDescription) {
        const { pageKey, defaultDescription } = this.props.pageDescription
        this.getPageDescription(pageKey, defaultDescription)
      }
      if (this.props.userFlowState !== prevProps.userFlowState) {
        await this.setPageDescription()
      }
    }
    setPageDescription = () => {
      const {
        MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_KEY,
        MATTER_TEMPLATES_SELECT_PAGE_KEY,
        MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_DEFAULT_DESCRIPTION,
        MATTER_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION,
        FORM_TEMPLATES_SELECT_PAGE_KEY,
        FORM_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION
      } = PAGE_DESCRIPTIONS

      const pageKey =
        this.props.userFlowState.userFlow ===
        SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
          ? FORM_TEMPLATES_SELECT_PAGE_KEY
          : this.props.userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
          ? MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_KEY
          : MATTER_TEMPLATES_SELECT_PAGE_KEY
      const defaultDescription =
        this.props.userFlowState.userFlow ===
        SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
          ? FORM_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION
          : this.props.userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
          ? MATTER_TEMPLATES_CHANGE_TEMPLATE_PAGE_DEFAULT_DESCRIPTION
          : MATTER_TEMPLATES_SELECT_PAGE_DEFAULT_DESCRIPTION

      this.props.dispatch({
        type: ACT.UPDATE_SELECTION_PAGE_DESCRIPTION,
        payload: {
          pageKey,
          defaultDescription
        }
      })
    }
    getPageDescription = (pageKey, defaultDescription) => {
      this.props.dispatch({
        type: ACT.SELECTION_PAGE_DESCRIPTION_FETCH_REQUESTED,
        payload: {
          pageKey,
          defaultDescription
        }
      })
    }
    setPageTitle = () => {
      let title
      switch (this.props.userFlowState.userFlow) {
        case SELECT_TEMPLATE_FLOW.MATTER_REQUEST:
          title = 'Process Request'
          break
        case SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE:
          title = 'Change Matter Template'
          break
        case SELECT_TEMPLATE_FLOW.SIMPLE_MATTER_CREATION:
          title = 'Create a Matter'
          break
        default:
          title = 'Select Templates'
      }

      return title
    }
    render() {
      return <WrappedComponent {...this.props} title={this.setPageTitle()} />
    }
  }
  return PageDescriptionParent
}

export default withSelectTemplatePageDescription
