import { useEffect, useState } from 'react'
import noop from 'lodash/noop'
import { Modal } from 'simple-core-ui'

import { ConfirmableContainer } from 'containers'
import { fes } from 'scui/utils/formatting'

import { makeGetRequest, handleError } from 'utils/api'
import { MultiSelectBottom, MultiSelectTop } from './SubscribersForm'
import SubscribersContent from './SubscribersContent'
import s from '../styles/TierForm.scss'

const getTitleByTypeAndLengh = (list, type) =>
  `${list?.length ? 'Edit' : 'Add'} ${type ? type[0].toUpperCase() + type.slice(1) : ''}s`

const DEFAULT_SUBSCRIBERS_ENDPOINT = '/teams/search/'

const Subscribers = ({
  savedSubscribers = [],
  editableSubscribers = [],
  updateSubscribers,
  revertSubscribers = () => {},
  editSubscribers,
  clearSubscribers,
  readOnly,
  removeSubscribers,
  teamSelectProps = {},
  type = 'subscriber',
  OpenButton
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [totalSubscribers, setTotalSubscribers] = useState(0)

  const closeCb = () => setIsOpen(false)

  const openHandler = () => setIsOpen(true)

  const confirmCb = () => {
    updateSubscribers()
    closeCb()
  }

  const closeHandler = () => {
    revertSubscribers()
    closeCb()
  }

  useEffect(() => {
    async function getTotal() {
      try {
        const response = await makeGetRequest(
          `${teamSelectProps?.url || DEFAULT_SUBSCRIBERS_ENDPOINT}`
        )
        setTotalSubscribers(response.total)
      } catch (e) {
        handleError(e, 'Could not fetch total subscribers.')
      }
    }

    getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscribers = editableSubscribers.map(x => ({ ...x, value: x.id, label: x.name }))

  const Content = ({ clearSubscribers }) => {
    const subscribersContentProps = {
      subscribers: savedSubscribers,
      openCb: readOnly ? noop : openHandler,
      readOnly: readOnly,
      key: 2,
      clearSubscribers
    }

    return OpenButton ? (
      <OpenButton {...subscribersContentProps} />
    ) : (
      <SubscribersContent {...subscribersContentProps} />
    )
  }

  if (readOnly) {
    return <Content />
  }
  const title = getTitleByTypeAndLengh(savedSubscribers, type)
  const haveSubscribers = subscribers?.length

  return (
    <ConfirmableContainer
      promptText={`Clear ${type}s?`}
      confirmCallback={clearSubscribers}
      cancelCallback={revertSubscribers}
      render={openPrompt => (
        <>
          <Modal
            title={title}
            subtitle={`Please select your ${type}s from the list or start typing`}
            confirmText="Save"
            size="lg"
            classNameContentTop={s.modalContentTop}
            classNameContentBottom={s.modalContentBottom}
            contentStyle={{ padding: !!haveSubscribers ? '8px 24px' : '0', minHeight: 'auto' }}
            footerStyle={{ paddingTop: 16 }}
            subtitleStyle={{ marginTop: 16 }}
            isFooterContentGray
            content={
              !!haveSubscribers && (
                <MultiSelectTop
                  values={subscribers}
                  onChange={removeSubscribers}
                  subtitle={`${fes(subscribers.length, `${type}s`)} ${
                    totalSubscribers ? `selected of ${totalSubscribers}` : 'selected'
                  }`}
                  key={1}
                  id={`testid_matter_${type}s_select`}
                  {...teamSelectProps}
                />
              )
            }
            isVisible={isOpen}
            confirmCb={confirmCb}
            cancelCb={closeHandler}
            footerContent={
              <MultiSelectBottom
                data-testid={`multi-select-${type}`}
                id={`testid_matter_${type}s_select_bottom`}
                values={subscribers}
                onChange={editSubscribers}
                styles={{ marginBottom: 23, width: '100%' }}
                key={2}
                customQueryParams={{ columnKey: 'contact.full_name' }}
                {...teamSelectProps}
              />
            }
          />
          <Content
            clearSubscribers={
              readOnly
                ? noop
                : () => {
                    closeCb()
                    openPrompt()
                  }
            }
          />
        </>
      )}
    />
  )
}

export default Subscribers
