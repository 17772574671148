import { Tasks as TasksList } from 'common/Tasks'

interface Props {
  scopeId: string
  readOnly: boolean
  scopeName: string
}

const Tasks = ({ scopeId, readOnly, scopeName }: Props) => {
  return (
    <TasksList
      scopeId={scopeId}
      readOnly={readOnly}
      scopeName={scopeName}
      context="matter"
      baseUrl={`/task-management/matters/${scopeId}`}
    />
  )
}

export default Tasks
