import { useState } from 'react'
import { getSelection, getValue } from '../helpers'
import { CategorySelect } from 'common/Selects'
import BaseFilter from './BaseFilter'
import SearchSelectBody from './SearchSelectBody'

const CategoryFilter = ({ onSelect, reset, testid, hasConfirmation }) => {
  const [categories, setCategories] = useState([])

  const selection = getSelection(categories, 'Categories')

  return (
    <BaseFilter
      testid={testid}
      hasConfirmation={hasConfirmation}
      title="Category"
      reset={reset}
      onSelect={onSelect}
      render={(setFilterSelection, setIsOpen, initialValues) => {
        const value = getValue(categories, initialValues)
        return (
          <SearchSelectBody
            onClear={() => setCategories([])}
            onSubmit={() => setFilterSelection(selection)}
          >
            <CategorySelect
              value={value}
              onChange={items => setCategories(items)}
              isPortal={false}
              isMulti
              isClearable
            />
          </SearchSelectBody>
        )
      }}
    ></BaseFilter>
  )
}

export default CategoryFilter
