import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects'
import partial from 'lodash/partial'
import ACT from './actions'

import { makeGetRequest, makePostRequest, handleError } from 'utils/api'

import { shouldPostOnUpdate } from './utils/utils'
import {
  FETCH_REVIEWER_CONFIG_URL,
  UPDATE_REVIEWER_CONFIG_URL,
  CREATE_REVIEWER_CONFIG_URL
} from './urls'

import { fromReviewerTiers, fromSubscribers, toReviewerConfig } from './serializers'

export function* initializeReviewerConfig(action) {
  const { reviewerConfigId, reviewerScope } = action.payload
  const response = yield call(makeGetRequest, FETCH_REVIEWER_CONFIG_URL(reviewerConfigId))

  const reviewerTiers = fromReviewerTiers(response.reviewer_tiers)
  const subscribers = fromSubscribers(response.subscribers)
  const isLeadReviewer = response.lead_is_reviewer

  yield put({
    type: ACT.INITIALIZE_REVIEWER_CONFIG_SUCCESS,
    payload: {
      reviewerTiers,
      subscribers,
      isLeadReviewer,
      reviewerScope,
      reviewerConfigId
    }
  })
}

export function* updateReviewerConfig(action) {
  try {
    const { reviewerConfigId, scope } = action.payload

    if (reviewerConfigId && shouldPostOnUpdate(scope)) {
      yield call(
        makePostRequest,
        UPDATE_REVIEWER_CONFIG_URL(reviewerConfigId),
        toReviewerConfig(action.payload)
      )
    }
    yield put({
      type: ACT.UPDATE_REVIEWER_CONFIG_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    handleError(e, 'The reviewer configuration changes failed to save.')
  }
}

export const getReviewerConfig = (reviewerScope, state) =>
  state.reviews.reviewerConfig[reviewerScope]

export function* saveReviewerConfig(action) {
  try {
    const { reviewerScope } = action.payload
    const reviewerConfig = yield select(partial(getReviewerConfig, reviewerScope))

    yield call(
      makePostRequest,
      UPDATE_REVIEWER_CONFIG_URL(reviewerConfig.reviewerConfigId),
      toReviewerConfig(reviewerConfig)
    )
  } catch (e) {
    handleError(e, 'The reviewer configuration changes failed to save.')
  }
}

export function* createReviewerConfig(action) {
  try {
    const { reviewerScope } = action.payload
    const reviewerConfig = yield select(partial(getReviewerConfig, reviewerScope))

    const { id } = yield call(
      makePostRequest,
      CREATE_REVIEWER_CONFIG_URL,
      toReviewerConfig(reviewerConfig)
    )

    yield put({
      type: ACT.CREATE_REVIEWER_CONFIG_SUCCESS,
      payload: {
        reviewerScope,
        reviewerConfigId: id
      }
    })

    return id
  } catch (e) {
    handleError(e, 'Failed to create reviewer configuration.')
  }
}

export function* cloneReviewerConfig(action) {
  try {
    const { reviewerConfigId } = action.payload

    const response = yield call(makeGetRequest, FETCH_REVIEWER_CONFIG_URL(reviewerConfigId))

    const reviewerTiers = fromReviewerTiers(response.reviewer_tiers)
    const subscribers = fromSubscribers(response.subscribers)
    const isLeadReviewer = response.lead_is_reviewer

    const newConfig = yield call(
      makePostRequest,
      CREATE_REVIEWER_CONFIG_URL,
      toReviewerConfig({ reviewerTiers, subscribers, isLeadReviewer })
    )

    return newConfig.id
  } catch (e) {
    handleError(e, 'Failed to clone reviewer configuration.')
  }
}

const reviewerConfigSagas = [
  takeEvery(ACT.INITIALIZE_REVIEWER_CONFIG_REQUESTED, initializeReviewerConfig),
  takeLatest(ACT.UPDATE_REVIEWER_CONFIG_REQUESTED, updateReviewerConfig),
  takeLatest(ACT.SAVE_REVIEWER_CONFIG_REQUESTED, saveReviewerConfig),
  takeLatest(ACT.CREATE_REVIEWER_CONFIG_REQUESTED, createReviewerConfig)
]

export default reviewerConfigSagas
