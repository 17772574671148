import head from 'lodash/head'

import { ENTRY_TYPE } from './constants'

export const areOrEntries = entries => entries.length === 1 && entries[0].length > 1

export const areAndEntries = entries =>
  entries.length > 1 && entries.every(membership => membership.length === 1)

export const isSingleEntry = entries => entries.length === 1 && head(entries).length === 1

export const identifyEntries = entries =>
  isSingleEntry(entries)
    ? ENTRY_TYPE.SINGLE
    : areAndEntries(entries)
    ? ENTRY_TYPE.AND
    : areOrEntries(entries)
    ? ENTRY_TYPE.OR
    : null
