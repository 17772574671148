import { Component } from 'react'
import { Grid } from 'simple-core-ui'
import ItemsNeedMyAction from './ItemsNeedMyAction'
import RecentItems from './RecentItems'
import Following from './Following'
import DashboardKPIs from './DashboardKPIs'
import { TasksAndEvents } from './TasksAndEvents'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

class HomeDashboard extends Component {
  render() {
    const { Row, Column } = Grid

    return (
      <section style={{ marginBottom: 30 }}>
        <Row>
          <Column span={12} breakpoint="laptop">
            <DashboardKPIs />
          </Column>
        </Row>
        <Row>
          <Column span={6} breakpoint="laptop">
            <ItemsNeedMyAction />
            <Following />
          </Column>
          <Column span={6} breakpoint="laptop">
            <RecentItems />
            {(hasModule(MODULE.TASKS) || hasModule(MODULE.EVENT_MANAGEMENT)) && <TasksAndEvents />}
          </Column>
        </Row>
      </section>
    )
  }
}

export default HomeDashboard
