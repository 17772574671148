import { Operator } from './api'

export type LogicalOperator = 'AND' | 'OR'

export type Unpacked<T> = T extends (infer U)[] ? U : T

export type OperatorByType = Partial<Record<Unpacked<Operator['types']>, Array<Operator>>>

export interface Constant<ValueType = string> {
  value: ValueType
  label: string
  model?: string
}

export const RuleType = {
  AI: 0,
  AI_CONDITIONAL: 1,
  CONDITIONAL: 2,
  BLANK: 3
} as const

export type RuleTypeEnum = typeof RuleType[keyof typeof RuleType]
