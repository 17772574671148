import { Title, VerticalTabs, VerticalTab } from 'simple-core-ui'
import s from './MatterManagementSettings.scss'
import { Notifications } from './Notifications'
import { useMatch } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { MatterPreferences } from './MatterPreferences'

const MatterManagementSettings = () => {
  const match = useMatch('/v2/matter_management/settings/:tabKey/*')
  const activeTab = match ? match.params.tabKey : 'notifications'

  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '8px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <Title text="Matter Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs activeTabKey={'notifications'}>
          <VerticalTab key="notifications" label="Notifications">
            <Notifications />
          </VerticalTab>
          <VerticalTab key="preferences" label="Preferences">
            <MatterPreferences />
          </VerticalTab>
        </VerticalTabs>
      </section>
    </section>
  )
}

export default MatterManagementSettings
