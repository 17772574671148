import { ContextState } from 'simple_review/@types/context'
import { CanSerialize } from './can-serialize'
import { ExpressionModel, Operator, ExpressionForUI } from './expression-model'

export class ExpressionMapper implements CanSerialize {
  constructor(
    protected _leftHandSide: CanSerialize,
    protected _operator: Operator,
    protected _rightHandSide: CanSerialize | null
  ) {}

  toJSON(): string {
    const data: ExpressionModel = {
      lhs: JSON.parse(this._leftHandSide.toJSON()),
      op: this._operator,
      rhs: !!this._rightHandSide ? JSON.parse(this._rightHandSide.toJSON()) : null
    }

    return JSON.stringify(data)
  }

  fromJSON(context: ContextState): ExpressionForUI {
    const { operators, availableFields } = context
    const fullLhs = this._leftHandSide.fromJSON(availableFields)
    const fullOperator = operators.find(o => o.symbol === this._operator)
    if (!fullOperator) {
      throw new Error(`Operator ${this._operator} not found`)
    }
    return {
      lhs: fullLhs,
      op: fullOperator,
      rhs: !!this._rightHandSide ? this._rightHandSide.fromJSON(fullLhs, fullOperator) : null
    }
  }
}
