import { Fragment } from 'react'
import { Panel, LoadingSkeleton } from 'simple-core-ui'
import { v4 as uuidv4 } from 'uuid'

import s from './ContactsLoading.scss'

const DEFAULT_STYLE = { marginBottom: 12 }

const ContactLines = () => {
  return [120, 80, 160].map((width, index) => {
    return (
      <Fragment key={uuidv4()}>
        <LoadingSkeleton key={uuidv4()} width={width} height={18} style={DEFAULT_STYLE} />
        <LoadingSkeleton key={uuidv4()} width={width} height={18} style={DEFAULT_STYLE} />
        <LoadingSkeleton key={uuidv4()} width={width} height={18} style={DEFAULT_STYLE} />
      </Fragment>
    )
  })
}

const ContactsLoading = () => (
  <Panel title="Contacts" className={s.container}>
    <section className={s.panelBody}>
      <LoadingSkeleton key={uuidv4()} height={20} style={{ marginBottom: 24 }} />
      <LoadingSkeleton
        key={uuidv4()}
        width={400}
        height={2}
        style={{ margin: '0 auto', marginBottom: 24 }}
      />
      <ContactLines key="0" />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={{ marginBottom: 24 }} />
      <ContactLines key="1" />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={DEFAULT_STYLE} />
    </section>
  </Panel>
)

export default ContactsLoading
