import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-plain_text'
import 'ace-builds/src-noconflict/theme-textmate'

const RulesDSLEditor = ({
  placeholder,
  value,
  onChange,
  readOnly,
  makeHeightOfContent,
  name,
  fontSize = 14,
  style = {},
  noSyntax
}) => (
  <AceEditor
    placeholder={placeholder}
    name={name || 'dsl_editor'}
    mode={noSyntax ? 'plain_text' : 'python'}
    theme="textmate"
    onChange={onChange}
    fontSize={fontSize}
    showPrintMargin={!readOnly}
    showGutter={!readOnly}
    highlightActiveLine={!readOnly}
    value={value}
    editorProps={{ $blockScrolling: Infinity }}
    setOptions={{
      showLineNumbers: true,
      displayIndentGuides: !readOnly,
      tabSize: 2,
      ...(makeHeightOfContent ? { maxLines: Infinity } : {})
    }}
    style={{ width: '100%', ...style }}
    readOnly={readOnly}
    wrapEnabled
  />
)

RulesDSLEditor.defaultProps = {
  placeholder: 'Enter DSL rules...',
  onChange: () => {}
}

export default RulesDSLEditor
