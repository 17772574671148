import keyMirror from 'keymirror'

export default keyMirror({
  CURRENT_VENDOR_FETCH_SUCCESS: null,
  CURRENT_VENDOR_FETCH_REQUESTED: null,
  QUESTION_ANSWERS_FETCH_REQUESTED: null,
  QUESTION_ANSWERS_FETCH_SUCCESS: null,
  ADDITIONAL_COMMENTS_FETCH_REQUESTED: null,
  ADDITIONAL_COMMENTS_FETCH_SUCCESS: null,
  FILTERED_QUESTIONS_REQUESTED: null,
  FILTERED_QUESTIONS_SUCCESS: null,
  COMPARE_QUESTIONS_REQUESTED: null,
  COMPARE_QUESTIONS_SUCCESS: null,
  QUESTIONS_FETCH_REQUESTED: null,
  VENDOR_DASHBOARD_LIST_FETCH_REQUESTED: null
})
