import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { put, takeLatest } from 'redux-saga/effects'
import { makePostRequest, makeGetRequest } from 'utils/api'

import ACT from './actions'

import { NotificationList } from 'components'

const user2Table = user => {
  return {
    id: user.id,
    name: user.tableau_username,
    customUserId: user.custom_user.id,
    customUserName: user.custom_user.first_name + ' ' + user.custom_user.last_name
  }
}

function* createUser(action) {
  try {
    const url = '/tableau/users/create'
    const response = yield makePostRequest(url, action.payload)

    yield put({
      type: ACT.ADD_USER_SUCCESS,
      loadingLock: 'off',
      payload: {
        user: user2Table(response)
      }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'There was an issue creating the user',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchUsers(action) {
  const url = '/tableau/users'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.USERS_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { users: response.users.map(user2Table), clientUsers: response.client_users }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Users',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* deleteUser(action) {
  const { id } = action.payload

  try {
    const url = `/tableau/users/delete`
    yield makePostRequest(url, { tab_user_id: id })

    yield put({
      type: ACT.DELETE_USER_SUCCESS,
      loadingLock: 'off',
      payload: {
        id
      }
    })
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'User deactivated successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'There was an issue deleting the User',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchReports(action) {
  const url = '/tableau/views'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.REPORTS_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: {
        reports: response.views.filter(view => view.name !== 'Config' && view.name !== 'Details')
      }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Reports',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

const reportSettingsSagas = [
  takeLatest(ACT.ADD_USER_REQUESTED, createUser),
  takeLatest(ACT.USERS_FETCH_REQUESTED, fetchUsers),
  takeLatest(ACT.DELETE_USER_REQUESTED, deleteUser),
  takeLatest(ACT.REPORTS_FETCH_REQUESTED, fetchReports)
]

export default reportSettingsSagas
