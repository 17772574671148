import get from 'lodash/get'
import values from 'lodash/values'

import ACT from '../actions'
import { isApprovalFlow } from './identifyReviewAction'

export const ENTRY_TYPE = {
  SINGLE: 'single',
  OR: 'or',
  AND: 'and'
}

export const REVIEW_STATUS = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending'
}

export const RESPONSE_LABEL = (scope, reviewStatus) =>
  get(
    {
      [SCOPE.MATTER]: {
        [REVIEW_STATUS.REJECTED]: 'change request'
      }
    },
    `${scope}.${reviewStatus}`,
    reviewStatus
  )

export const REASON_TYPE = {
  BUILTIN: 'builtin_rule',
  ESCALATION: 'escalation',
  EDIT: 'edit',
  CUSTOM_TEAM: 'custom_team_rule',
  MESSAGE: 'message'
}

export const SCOPE = {
  INVOICE: 'invoice',
  MATTER: 'matter'
}

export const REVIEWABLE_STATUS = {
  [SCOPE.INVOICE]: {
    PROCESSING: 'processing',
    RECEIVED: 'received',
    ON_HOLD: 'on hold',
    REJECTED: 'rejected',
    APPROVED: 'approved',
    SENT_TO_AP: 'sent to ap',
    POSTED_TO_AP: 'posted to ap',
    PAID: 'paid',
    PARTIALLY_PAID: 'partially paid',
    OVER_PAID: 'over paid'
  }
}

export const REVIEWABLE_STATUSES = (() =>
  values(SCOPE).reduce(
    (statuses, scope) => ({ ...statuses, ...get(REVIEWABLE_STATUS, scope, {}) }),
    {}
  ))()

const DEFAULT_REVIEW_ACTION_LABELS = {
  [ACT.FINALIZE_REQUESTED]: 'Finalize',
  [ACT.APPROVAL_REQUESTED]: 'Approve',
  [ACT.REJECTION_REQUESTED]: 'Reject',
  [ACT.UNAPPROVAL_REQUESTED]: 'Unapprove',
  [ACT.UNREJECTION_REQUESTED]: 'Unreject'
}

export const REVIEW_ACTION_LABEL = (scope, actionType, isFinalApproval) =>
  get(
    {
      [SCOPE.MATTER]: {
        ...DEFAULT_REVIEW_ACTION_LABELS,
        [ACT.FINALIZE_REQUESTED]: isFinalApproval ? 'Finalize and Publish' : 'Finalize',
        [ACT.APPROVAL_REQUESTED]: isFinalApproval ? 'Approve and Publish' : 'Approve',
        [ACT.REJECTION_REQUESTED]: 'Request Changes',
        [ACT.UNREJECTION_REQUESTED]: 'Resolve'
      }
    },
    scope,
    DEFAULT_REVIEW_ACTION_LABELS
  )[actionType]

export const ADMIN_ACTIONS = {
  START_REVIEW: 'start review'
}

export const DEFAULT_ENTRY_ACTION_LABELS = {
  [ACT.REMOVE_REVIEWER_REQUESTED]: 'Remove',
  [ACT.SEND_BACK_TO_REQUESTED]: 'Send Back'
}

export const ENTRY_ACTION_LABEL = (scope, actionType) =>
  get(
    {
      [SCOPE.MATTER]: {
        ...DEFAULT_ENTRY_ACTION_LABELS,
        [ACT.SEND_BACK_TO_REQUESTED]: 'Request Review'
      }
    },
    scope,
    DEFAULT_ENTRY_ACTION_LABELS
  )[actionType]

export const REVIEW_MODAL_LABELS = (review_action_label, scope) =>
  get(
    {
      [SCOPE.MATTER]: {
        primaryLabel: review_action_label
      },
      ...(review_action_label === 'Reject' || isApprovalFlow(review_action_label)
        ? {
            [SCOPE.INVOICE]: {
              primaryLabel: review_action_label
            }
          }
        : {})
    },
    scope,
    {
      primaryLabel: `${review_action_label} and Notify`,
      secondaryLabel: review_action_label
    }
  )

export const HIDE_REJECTION_REASON = [SCOPE.INVOICE]
