import { Checkbox } from 'simple-core-ui'
import { FilesToUpload } from '../types'
import s from './FileReshareModalContent.scss'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

interface Props {
  dupSharedFileList: FilesToUpload[]
  handleSharedDupFilesChange: (key: number) => void
}

const FileReshareModalContent = ({ dupSharedFileList, handleSharedDupFilesChange }: Props) => {
  return (
    <>
      <p style={{ fontSize: '16px' }}>
        Uploading file(s) will revoke sharing and remove all current collaborators’ access.{' '}
      </p>
      <div>
        <table className={s.table}>
          <thead>
            <tr>
              <th>File Name</th>
              <th>
                Share file(s) with the existing collaborators.
                <p>Uncheck if you prefer to share the file later.</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortAlphabeticallyByProperty(dupSharedFileList, 'name').map(
              (file: FilesToUpload, idx: number) => {
                return (
                  <tr key={idx}>
                    <td>{file.name}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Checkbox
                        isChecked={file.reshare as boolean}
                        triggerCheckbox={() => handleSharedDupFilesChange(file.id)}
                        size="sm"
                      />
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default FileReshareModalContent
