import ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import moment from 'moment'

const initialState = {
  categories: [],
  labels: [],
  previewSettings: [],
  admins: [],
  notifications: [],
  infectedFiles: []
}

const documentManagementSettingsReducer = createReducer(initialState, {
  [ACT.ADD_CATEGORY_SUCCESS](state, action) {
    const { category } = action.payload
    return update(state, {
      categories: {
        $push: [category]
      }
    })
  },
  [ACT.CATEGORIES_FETCH_SUCCESS](state, action) {
    const { categories } = action.payload
    return {
      ...state,
      categories
    }
  },
  [ACT.DELETE_CATEGORY_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.categories.findIndex(c => c.id === id)
    return update(state, {
      categories: {
        $splice: [[index, 1]]
      }
    })
  },
  [ACT.DELETE_LABEL_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.labels.findIndex(c => c.id === id)
    return update(state, {
      labels: {
        $splice: [[index, 1]]
      }
    })
  },
  [ACT.EDIT_CATEGORY_SUCCESS](state, action) {
    const { category } = action.payload
    const index = state.categories.findIndex(c => c.id === category.id)
    return update(state, {
      categories: {
        [index]: { $set: category }
      }
    })
  },
  [ACT.EDIT_LABEL_SUCCESS](state, action) {
    const { label } = action.payload
    const index = state.labels.findIndex(c => c.id === label.id)
    return update(state, {
      labels: {
        [index]: { $set: label }
      }
    })
  },
  [ACT.LABELS_FETCH_SUCCESS](state, action) {
    const { labels } = action.payload
    return {
      ...state,
      labels
    }
  },
  [ACT.ADD_LABEL_SUCCESS](state, action) {
    const { label } = action.payload
    return update(state, {
      labels: {
        $push: [label]
      }
    })
  },
  [ACT.PREVIEW_SETTINGS_FETCH_SUCCESS](state, action) {
    const { previewSettings } = action.payload
    return {
      ...state,
      previewSettings
    }
  },
  [ACT.EDIT_PREVIEW_SETTING_SUCCESS](state, action) {
    const { setting } = action.payload
    const index = state.previewSettings.findIndex(s => s.name === setting.name)
    return update(state, {
      previewSettings: {
        [index]: { $set: setting }
      }
    })
  },
  [ACT.ADMINS_FETCH_SUCCESS](state, action) {
    const { admins } = action.payload
    return {
      ...state,
      admins
    }
  },
  [ACT.NOTIFICATIONS_FETCH_SUCCESS](state, action) {
    const { notifications } = action.payload
    return {
      ...state,
      notifications: notifications.map(n => {
        return {
          ...n,
          added_ts: moment(n.added_ts).format('MM/DD/YYYY')
        }
      })
    }
  },
  [ACT.ADD_ADMIN_SUCCESS](state, action) {
    const { admin } = action.payload
    return update(state, {
      notifications: {
        $push: [admin]
      }
    })
  },
  [ACT.DELETE_ADMIN_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.notifications.findIndex(n => n.id === id)
    return update(state, {
      notifications: {
        $splice: [[index, 1]]
      }
    })
  },
  [ACT.INFECTED_FILES_FETCH_SUCCESS](state, action) {
    const { infectedFiles } = action.payload
    return {
      ...state,
      infectedFiles
    }
  },
  [ACT.DELETE_INFECTED_FILE_SUCCESS](state, action) {
    const { id } = action.payload
    const index = state.infectedFiles.findIndex(file => file.id === id)

    return update(state, {
      infectedFiles: {
        $splice: [[index, 1]]
      }
    })
  }
})

export default documentManagementSettingsReducer
