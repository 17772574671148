import s from './SetCompleted.scss'
import { FaCheck } from 'react-icons/fa'
import cn from 'classnames'
import moment from 'moment'

interface Option {
  value: number
  label: string
  color?: string
  occurredDate?: string
  phase?: string
}

interface Props {
  status: Option | null
  onClick: (v: boolean) => void
  readOnly?: boolean
}

const SetCompleted = ({ status, onClick, readOnly }: Props) => {
  return (
    <div
      onClick={() => {
        readOnly ? null : onClick(status?.phase === 'Complete')
      }}
      className={cn(s.widget, {
        [s.readOnly]: readOnly
      })}
    >
      <div
        className={cn(s.icon, {
          [s.active]: status?.phase === 'Complete'
        })}
      >
        <FaCheck />
      </div>
      <div className={s.content}>
        <span
          className={cn(s.label, {
            [s.labelActive]: status?.phase === 'Complete'
          })}
        >
          {status?.phase === 'Complete' ? 'COMPLETED' : 'COMPLETE'}
        </span>
        {status?.phase === 'Complete' && (
          <span className={s.value}>
            {moment(status.occurredDate ?? new Date()).format(
              moment(status.occurredDate ?? new Date()).isSame(new Date(), 'year')
                ? 'MMM DD'
                : 'MMM DD YYYY'
            )}
          </span>
        )}
      </div>
    </div>
  )
}

export default SetCompleted
