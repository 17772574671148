import s from '../styles/BudgetDetail.scss'

const SnapshotRow = ({ backgroundColor, value, label }) => {
  return (
    <div className={s.snapshotRow}>
      <div className={s.snapshotSquare} style={{ backgroundColor }}></div>
      <div>
        {value}
        <div className={s.snapshotLabel}>{label}</div>
      </div>
    </div>
  )
}

export default SnapshotRow
