import { Component } from 'react'
import { connect } from 'react-redux'
import { Panel } from 'simple-core-ui'
import swal from 'sweetalert'
import * as ACT from 'matters/templates/actions'
import { AttributeCategory } from './AttributeCategory'
import s from './TemplateCategoryContainer.scss'
import { MATTER_GROUP_ID, LEGAL_ENTITY_ID } from 'matters/templates/constants'
import get from 'lodash/get'

@connect(({ matterTemplates }) => {
  return {
    template: { ...matterTemplates }
  }
})
class TemplateCategoryContainer extends Component {
  changeAttributeValue = ({
    attrIndex,
    value,
    category,
    sectionIndex,
    property,
    attributeId,
    relationshipIndex,
    relationshipId
  }) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_CHANGE_ATTRIBUTE_VALUE,
      payload: {
        attrIndex,
        value,
        property,
        category,
        sectionIndex,
        relationshipIndex
      }
    })

    // if practice area or legal entity change, fire request to get the correct attributes values
    if ([MATTER_GROUP_ID, LEGAL_ENTITY_ID].includes(attributeId)) {
      this.fetchFilteredAttributes(attributeId, value)
    }

    // on changing the value of an attribute from a dynamic dropdown fetch the paired values for next level
    if (relationshipIndex !== undefined) {
      if (property !== 'required') {
        this.fetchDynamicAttributePairedValues({
          attributeId,
          relationshipIndex,
          category,
          sectionIndex,
          attrIndex,
          value,
          relationshipId
        })
      } else {
        const { template } = this.props
        const levels = template[category][sectionIndex].attributes[attrIndex].attributes.length
        const hasNextLevel = relationshipIndex !== levels - 1

        if (hasNextLevel && !value) {
          this.props.dispatch({
            type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_UNCHECK_REQUIRED_FOR_SUB_LEVELS,
            payload: {
              attrIndex,
              value,
              category,
              sectionIndex,
              relationshipIndex,
              levels
            }
          })
        }
      }
    }
  }
  fetchDynamicAttributePairedValues = params => {
    // check if the relationship has a next level
    const { template } = this.props
    const { relationshipIndex, category, sectionIndex, attrIndex, value } = params
    const levels = template[category][sectionIndex].attributes[attrIndex].attributes.length
    const hasNextLevel = relationshipIndex !== levels - 1

    if (value) {
      if (hasNextLevel) {
        this.props.dispatch({
          type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
          payload: {
            ...params,
            levels
          }
        })
      }
    } else {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
        payload: {
          attrIndex,
          category,
          sectionIndex,
          relationshipIndex,
          levels
        }
      })
    }
  }
  fetchFilteredAttributes = (attributeId, value) => {
    const categoryArr = ['left', 'right']
    // value param is either a practice area or a legal entity value,
    // the one that the user changes. The API expects both practice area and legal entity values
    // so we need to grab the other one (e.g if value is the value for practice area then
    // attributeId == 'matter_group' and otherAttribute == 'legal_entity'
    const otherAttribute = [MATTER_GROUP_ID, LEGAL_ENTITY_ID].filter(id => id !== attributeId)[0]
    const { template } = this.props
    // the server expects -1 value if a practice area or a legal entity is not selected
    let ids = {
      [attributeId]: value ? value.value : -1,
      [otherAttribute]: -1
    }

    categoryArr.forEach(category => {
      template[category].forEach(section => {
        section.attributes.forEach(attribute => {
          if (otherAttribute === attribute.id) {
            // a list type attribute can have value == '3' when coming from the API, or
            // value == {value: '3', label: 'some label'} when posting to the API
            // we need to support both so that's why the object check below
            let value = 'value'
            if (typeof attribute.value === 'object') {
              value = 'value.value'
            }
            ids = {
              ...ids,
              [attribute.id]: get(attribute, value, -1) || -1
            }
          }
        })
      })
    })

    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
      payload: {
        templateId: template.id,
        ids: { ...ids }
      }
    })
  }
  removeAttribute = (attrIndex, category, sectionIndex, attrLabel) => {
    const { isEditTemplatePage, toggleModal } = this.props
    if (isEditTemplatePage) {
      toggleModal(attrLabel, attrIndex, category, sectionIndex)
    } else {
      this.removeAttributeDispatchAction(attrIndex, category, sectionIndex)
    }
  }
  removeAttributeDispatchAction = (attrIndex, category, sectionIndex) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_REMOVE_ATTRIBUTE,
      payload: {
        attrIndex,
        category,
        sectionIndex
      }
    })
  }
  changePanelTitle = panelTitle => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_CHANGE_PANEL_TITLE,
      payload: {
        panelTitle
      }
    })
  }
  changeSectionTitle = (sectionName, category, sectionIndex) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_CHANGE_SECTION_NAME,
      payload: {
        sectionName,
        category,
        sectionIndex
      }
    })
  }
  addNewSection = category => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_ADD_NEW_SECTION,
      payload: {
        category
      }
    })
  }
  removeSection = async (category, sectionIndex) => {
    const willDelete = await swal({
      title: 'Delete Section?',
      text: 'You will lose all changes for this section.',
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })
    if (willDelete) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATES_REMOVE_SECTION,
        payload: {
          category,
          sectionIndex
        }
      })
      swal('Deleted!', `The section has been deleted.`, 'success')
    }
  }
  pinSection = (category, sectionIndex, displayExpanded) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_PIN_SECTION,
      payload: {
        category,
        sectionIndex,
        displayExpanded
      }
    })
  }
  render() {
    const { template } = this.props
    return (
      <div className={s.panelWrapper}>
        <Panel title="Matter Attributes">
          <AttributeCategory
            category={template.left}
            categoryLabel="left"
            changeAttributeValue={this.changeAttributeValue}
            changeSectionTitle={this.changeSectionTitle}
            addNewSection={this.addNewSection}
            removeSection={this.removeSection}
            pinSection={this.pinSection}
            removeAttribute={this.removeAttribute}
          />
        </Panel>
        <Panel title="Other Attributes">
          <AttributeCategory
            category={template.right}
            categoryLabel="right"
            changeAttributeValue={this.changeAttributeValue}
            changeSectionTitle={this.changeSectionTitle}
            addNewSection={this.addNewSection}
            removeSection={this.removeSection}
            pinSection={this.pinSection}
            removeAttribute={this.removeAttribute}
          />
        </Panel>
      </div>
    )
  }
}

export default TemplateCategoryContainer
