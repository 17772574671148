import { Fragment } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import noop from 'lodash/noop'

import { Button } from 'simple-core-ui'
import { Condition as ConditionType } from 'simple_review/@types/editor'
import { validateCondition } from 'simple_review/editor/validators'
import { LogicalOperator } from 'simple_review/@types/common'
import { getBaseCondition } from '../../constants'
import { Actions } from '../actions'
import { Op } from '../op'
import s from './ConditionGroup.scss'

const UNGROUP_BUTTON_STYLE = {
  marginLeft: 'auto',
  display: 'flex',
  marginBottom: 12
}

interface Props {
  children(subCondition: ConditionType, subConditionIndex: number): React.ReactNode
  conditionGroup: ConditionType
  isReadOnly: boolean
  onChangeConditionGroup(newConditionGroup: ConditionType): void
  onCopyConditionGroup(): void
  onRemoveConditionGroup(conditionId: string): void
  onUngroup(conditionIndex?: number): void
}

const ConditionGroup = ({
  children,
  conditionGroup,
  isReadOnly,
  onChangeConditionGroup,
  onCopyConditionGroup,
  onRemoveConditionGroup,
  onUngroup
}: Props) => {
  const handleAddCondition = (index: number) => {
    const newConditionGroup = cloneDeep(conditionGroup)
    newConditionGroup.operands.splice(index + 1, 0, getBaseCondition())
    onChangeConditionGroup(newConditionGroup)
  }

  const handleChangeOp = (newOp: LogicalOperator) => {
    const newConditionGroup = cloneDeep(conditionGroup)
    newConditionGroup.op = newOp
    onChangeConditionGroup(newConditionGroup)
  }

  return (
    <div className={s.container} data-testid="condition-group">
      {!isReadOnly && (
        <>
          <Button style={UNGROUP_BUTTON_STYLE} onClick={() => onUngroup()}>
            Ungroup
          </Button>
          <Actions
            isCheckboxShown={false}
            checkboxProps={{ isChecked: false, triggerCheckbox: noop }}
            conditionId={conditionGroup.id}
            isCopyDisabled={!validateCondition(conditionGroup)}
            onCopy={onCopyConditionGroup}
            onDelete={() => onRemoveConditionGroup(conditionGroup.id)}
          />
        </>
      )}
      {conditionGroup.operands.map((condition, index) => {
        const isLast = conditionGroup.operands.length - 1 === index

        return (
          <Fragment key={condition.id}>
            {children(condition as ConditionType, index)}
            {!isLast && (
              <Op
                value={conditionGroup.op}
                isReadOnly={isReadOnly}
                onChangeOp={handleChangeOp}
                onAddConditionBtwn={() => handleAddCondition(index)}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default ConditionGroup
