import { Component } from 'react'
import { withRouter } from 'simple-core-ui/hocs'
import queryString from 'query-string'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import ThemeContext from 'context/ThemeContext'

const mobileMaxWidth = 768

@withRouter
class ThemeContainer extends Component {
  checkIfMobileViewport = () => window.innerWidth <= mobileMaxWidth
  state = {
    isMenuExpanded:
      !!JSON.parse(localStorage.getItem('isMenuExpanded')) && !this.checkIfMobileViewport(),
    searchQuery: '',
    windowSize: { width: 0, height: 0 },
    isMobile: this.checkIfMobileViewport()
  }
  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions)
  }
  init = () => {
    this.getDimensions = throttle(this.getDimensions, 250)
    window.addEventListener('resize', this.getDimensions)

    this.getSearchQuery()
    this.getDimensions()
  }
  getSearchQuery = () => {
    const searchQuery = get(queryString.parse(this.props.router.location.search), 'q', '')

    this.setState({ searchQuery })
  }
  getDimensions = () => {
    this.setState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      isMobile: this.checkIfMobileViewport(),
      isMenuExpanded: this.state.isMenuExpanded && !this.checkIfMobileViewport()
    })
  }
  triggerMenu = async () => {
    await this.setState(prevState => ({
      isMenuExpanded: !prevState.isMenuExpanded
    }))
    try {
      localStorage.setItem('isMenuExpanded', this.state.isMenuExpanded)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Local Storage is full. Please empty data')
    }
  }
  closeMenu = () => {
    this.setState({
      isMenuExpanded: false
    })
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{
          state: this.state,
          triggerMenu: this.triggerMenu
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContainer
