import keyMirror from 'keymirror'

export default keyMirror({
  LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED: null,
  LEGAL_REQUEST_FORM_LIST_FETCH_SUCCESS: null,
  LEGAL_REQUEST_FORM_LIST_CHANGE_PARAM: null,
  LEGAL_REQUEST_FORMS_RESTORE_FORM: null,
  LEGAL_REQUEST_FORMS_ARCHIVE_FORM: null,
  LEGAL_REQUEST_FORMS_DELETE_FORM: null,
  LEGAL_REQUEST_FORMS_UPDATE_STATUS_REQUESTED: null,
  LEGAL_REQUEST_FORM_UPDATE_STATUS: null,
  MATTER_TEMPLATE_LIST_FETCH_REQUESTED: null,
  SHOW_REDIRECT: null
})
