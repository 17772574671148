import { Component } from 'react'
import BudgetRequestForm from '../containers/BudgetRequestForm'
import { hasModule } from 'utils/helpers'

class CreateBudgetForm extends Component {
  render() {
    return (
      <div>
        {hasModule('has_budget_requests') && (
          <p>
            {`You are creating an internal budget. If you wish to request a budget from a vendor,
                    navigate to the matter you wish to create a budget request for and click the "Assigned
                                      Vendors" tab.`}
          </p>
        )}
        <BudgetRequestForm {...this.props} isLegacyBudget />
      </div>
    )
  }
}

export default CreateBudgetForm
