import { useLocation, useParams } from 'react-router-dom'
import { Panel, Tabs, Tab } from 'simple-core-ui'

import { MODULE } from 'utils/constants'

import ScopeContext from 'context/ScopeContext'

import { SCOPE, REVIEWER_SCOPE, REVIEWER_SCOPE_LABEL } from 'reviews/constants'

import ReviewerConfigContainer from '../containers/ReviewerConfigContainer'

import s from './IntegratedReviewerConfig.scss'

const invoiceReviewerScope = window.credentials.modules.includes(MODULE.INVOICE_REVIEWS)
  ? [REVIEWER_SCOPE.INVOICE]
  : []

const matterReviewerScope = window.credentials.modules.includes(MODULE.MATTER_REVIEWS)
  ? [REVIEWER_SCOPE.MATTER]
  : []

const reviewerScopeMap = {
  [SCOPE.MATTER]: [...invoiceReviewerScope],
  [SCOPE.VENDOR]: [...invoiceReviewerScope],
  [SCOPE.LEGAL_ENTITY]: [...invoiceReviewerScope, ...matterReviewerScope],
  [SCOPE.PRACTICE_AREA]: [...invoiceReviewerScope, ...matterReviewerScope],
  [SCOPE.APPROVAL_WORKFLOW]: [...invoiceReviewerScope],
  [SCOPE.MATTER_REVIEW_RULES]: [...matterReviewerScope]
}

export const ScopedReviewerConfig = ({ reviewerConfigId, withPanel, readOnly, ...props }) => {
  const location = useLocation()
  const params = useParams()
  let scope = ''
  if (props.scope) {
    scope = props.scope
  } else if (location.pathname.includes('matters')) {
    scope = SCOPE.MATTER
  } else if (location.pathname.includes('vendors')) {
    scope = SCOPE.VENDOR
  } else if (location.pathname.includes('entities')) {
    scope = SCOPE.LEGAL_ENTITY
  } else if (location.pathname.includes('matter_groups')) {
    scope = SCOPE.PRACTICE_AREA
  } else if (location.pathname.includes('rules')) {
    scope = SCOPE.APPROVAL_WORKFLOW
  }

  const scopeId =
    params.matterId ||
    params.vendorId ||
    params.groupId ||
    params.ruleId ||
    params.entityId ||
    params.id
  const reviewerScopes = reviewerScopeMap[scope]
  const Scoped = ({ reviewerScope }) => (
    <ScopeContext.Provider value={{ scope, scopeId }}>
      <ReviewerConfigContainer
        reviewerScope={reviewerScope}
        reviewerConfigId={reviewerConfigId}
        readOnly={readOnly}
      />
    </ScopeContext.Provider>
  )

  const Tabbed = () =>
    reviewerScopes.length === 1 ? (
      <Scoped reviewerScope={reviewerScopes[0]} />
    ) : (
      <Tabs>
        {reviewerScopes.map((reviewerScope, idx) => (
          <Tab header={REVIEWER_SCOPE_LABEL[reviewerScope]} key={idx}>
            <section className="p-t-sp300">
              <Scoped reviewerScope={reviewerScope} />
            </section>
          </Tab>
        ))}
      </Tabs>
    )

  return withPanel ? (
    <Panel title="Reviewers" className={s.panel}>
      <Tabbed />
    </Panel>
  ) : (
    <Tabbed />
  )
}

const IntegratedReviewerConfig = ({ reviewerConfigId, readOnly }) => {
  const location = useLocation()

  return (
    <>
      {location.pathname.includes('matters') && (
        <ScopedReviewerConfig reviewerConfigId={reviewerConfigId} readOnly={readOnly} />
      )}
      {location.pathname.includes('vendors') && <ScopedReviewerConfig withPanel />}
      {location.pathname.includes('entities') && <ScopedReviewerConfig withPanel />}
      {location.pathname.includes('matter_groups') && <ScopedReviewerConfig withPanel />}
      {location.pathname.includes('rules') && <ScopedReviewerConfig />}
    </>
  )
}

export default IntegratedReviewerConfig
