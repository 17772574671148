import * as React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import get from 'lodash/get'
import pick from 'lodash/pick'

import { If } from 'simple-core-ui'

import ACT from '../actions'

import TeamModal from './TeamModal.js'

const emptyState = {
  name: '',
  description: '',
  members: []
}

class TeamModalContainer extends React.Component {
  state = pick(this.props, ['name', 'description', 'members'])

  onUpdateTextField = (field, value) => {
    this.setState({ [field]: value })
  }

  onUpdateMembers = members => {
    this.setState({ members })
  }

  clearModal = () => {
    this.props.dispatch({ type: ACT.TEAM_MODAL_CLOSE })
    this.setState(emptyState)
  }

  onCancel = () => {
    this.clearModal()
  }

  onSubmit = () => {
    const { showForUpdate, dispatch } = this.props
    const action = showForUpdate
      ? {
          type: ACT.TEAM_UPDATE_REQUESTED,
          payload: {
            ...showForUpdate,
            memberCount: this.state.members.length,
            ...this.state
          }
        }
      : {
          type: ACT.TEAM_CREATE_REQUESTED,
          payload: this.state
        }

    dispatch(action)
    this.clearModal()
  }

  render() {
    const { showForCreate, readOnly } = this.props

    return (
      <TeamModal
        {...this.state}
        showForCreate={showForCreate}
        onUpdateTextField={this.onUpdateTextField}
        onUpdateMembers={this.onUpdateMembers}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        readOnly={readOnly}
      />
    )
  }
}

@connect(({ teamsApp: { showForUpdate, showForCreate, canEditTeams } }) => ({
  name: get(showForUpdate, 'name', ''),
  description: get(showForUpdate, 'description', ''),
  members: get(showForUpdate, 'members', []),
  showForUpdate,
  showForCreate,
  readOnly: !canEditTeams
}))
class ConnectedTeamModal extends React.Component {
  componentDidMount() {
    const teamId = queryString.parse(window.location.search).team

    if (teamId !== undefined) {
      const { dispatch } = this.props
      dispatch({ type: ACT.TEAM_SHOW_FOR_UPDATE_REQUESTED, payload: teamId })
    }
  }

  render() {
    const { showForUpdate, showForCreate } = this.props

    return (
      <If condition={showForUpdate || showForCreate}>
        {() => <TeamModalContainer {...this.props} />}
      </If>
    )
  }
}

export default ConnectedTeamModal
