import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fes } from 'simple-core-ui/utils/formatting'
import Select from 'react-select'
import s from '../styles/ReviewLadder.scss'
import { makeGetRequest } from 'utils/api'
import { If, Input } from 'simple-core-ui'
import { IoIosClose } from 'react-icons/io'

import APP_ACT from 'app/actions'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { toReasonsList } from '../serializers'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { Fields, PreferenceSetting, Reason } from 'invoices/types'

const REJECTION_REASONS_BASE_URL = '/invoices/rejection/reason/'

const OPTION_PROPS = {
  SHOW: 'SHOW',
  HIDE: 'HIDE'
}

type Props = {
  fields: Fields
  updateFields: (args: { reasons?: Reason[]; additionalDetails?: string }) => void
  preferenceSetting: PreferenceSetting | null
  isRejection: boolean
}

const ReasonsForm = ({ fields, updateFields, preferenceSetting, isRejection }: Props) => {
  const [options, setOptions] = useState<Reason[]>([])
  const dispatch = useDispatch()

  const notifyChange = (title: string, message: string, level: string) => {
    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: { title, message, level }
    })
  }

  const fetchReasons = async () => {
    let response = []
    try {
      response = await makeGetRequest(`${REJECTION_REASONS_BASE_URL}list/`, {
        params: { all: true }
      })

      const reasonsList = toReasonsList(response)
      setOptions(reasonsList)
    } catch (error) {
      notifyChange('Error', 'There was an issue fetching invoice rejection reasons', 'error')
    }
  }

  const updateOptions = (value: number, type: string) => {
    const optionIndex = options.findIndex(e => e.value === value)
    const updatedReasons = [...options]
    updatedReasons[optionIndex] = {
      ...updatedReasons[optionIndex],
      hideOption: type !== 'SHOW'
    }
    setOptions(updatedReasons)
  }

  useEffect(() => {
    isRejection && fetchReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.reasonsContainer}>
      <If condition={isRejection}>
        <>
          <span className={s.fieldLabel}>
            Add Rejection Reason(s)
            {preferenceSetting?.invoice_rejection_reason_preference?.value ? (
              <span className={s.asterisk}>*</span>
            ) : null}
          </span>
          {fields?.reasons?.length ? (
            <>
              <div className={s.reasonsBox}>
                {sortAlphabeticallyByProperty(fields.reasons, 'label').map((r: Reason) => (
                  <div key={r.value} className={s.customTag}>
                    <p className={s.tagLabel}>{r.label}</p>
                    <div
                      className={s.close}
                      onClick={() => {
                        const index = fields.reasons.findIndex((e: Reason) => e.value === r.value)
                        const updated = [...fields.reasons]
                        updated.splice(index, 1)
                        updateFields({ reasons: updated })
                        updateOptions(r.value, OPTION_PROPS.SHOW)
                      }}
                    >
                      <IoIosClose />
                    </div>
                  </div>
                ))}
              </div>
              <p className={s.reasonsBoxFooter}>{fes(fields.reasons.length, 'reasons')} selected</p>
            </>
          ) : null}
          <Select
            value={null}
            placeholder={'Select Reason or Type Here'}
            onChange={(option: Reason | Reason[] | null) => {
              if (!Array.isArray(option) && option !== null) {
                updateFields({ reasons: [...fields?.reasons, option] })
                updateOptions(option.value, OPTION_PROPS.HIDE)
              }
            }}
            options={sortAlphabeticallyByProperty(options, 'label')}
            filterOption={(option: FilterOptionOption<Reason>, inputValue: string) =>
              !option.data.hideOption &&
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
        </>
      </If>
      <span className={s.fieldLabel}>Please provide additional details</span>
      <Input
        onChangeCb={({ target }) => updateFields({ additionalDetails: target.value })}
        placeholder=""
        style={{ height: '150px' }}
        text={fields.additionalDetails}
        type="textarea"
      />
    </div>
  )
}

export default ReasonsForm
