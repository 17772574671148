import moment from 'moment'

const requiredAnswers = ['overall_satisfaction']

const fromDB = evaluation => {
  return {
    matterName: evaluation.matter_name,
    matterId: evaluation.matter_id,
    vendorName: evaluation.vendor_name,
    startDate: moment(evaluation.start_date).format('MM/DD/YYYY'),
    endDate: moment(evaluation.end_date).format('MM/DD/YYYY'),
    info: evaluation.info,
    isClosed: evaluation.read_only,
    finalComment: evaluation.comment,
    answers: evaluation.answers.map((ans, index) => ({
      ...ans,
      required: requiredAnswers.includes(ans.name)
    })),
    userName: evaluation.user_first_name,
    nextSurvey: evaluation.next_survey
  }
}

const toDB = evaluation => {
  return {
    matter_name: evaluation.matterName,
    vendor_name: evaluation.vendorName,
    info: evaluation.info,
    read_only: evaluation.isClosed,
    comment: evaluation.finalComment,
    answers: evaluation.answers
  }
}

export { fromDB, toDB }
