import { IoIosClose, IoIosArrowBack } from 'react-icons/io'
import { Anchor, Avatar, If, Loading, UploadableContainer } from 'simple-core-ui'

import { SCOPE, IMAGE_FORMATS } from 'utils/constants'
import { formatContactFullName } from '../utils'

import ScopeContext from 'context/ScopeContext'

import s from './ContactProfile.scss'
import ReactTooltip from 'react-tooltip'

const scopedUrl = (scope, scopeId) =>
  ({
    [SCOPE.MATTER]: {
      url: `/manage/matters/v2/${scopeId}/`,
      label: 'Matter'
    },
    [SCOPE.VENDOR]: {
      url: `/invoices/vendors/${scopeId}/`,
      label: 'Vendor'
    },
    [SCOPE.LEGAL_ENTITY]: {
      url: `/manage/entities/${scopeId}/`,
      label: 'Legal Entity'
    },
    [SCOPE.PRACTICE_AREA]: {
      url: `/manage/matter_groups/${scopeId}/`,
      label: 'Practice Area'
    },
    [SCOPE.APPROVAL_WORKFLOW]: {
      url: `/rules/${scopeId}/`,
      label: 'Approval Workflow'
    },
    [SCOPE.COMPANY]: {
      url: `/company/`,
      label: 'User Management'
    }
  }[scope])

const ContactProfileHeader = ({ contact, avatar, uploadAvatar, deleteAvatar, readOnly }) => {
  return (
    <>
      <ScopeContext.Consumer>
        {({ scope, scopeId }) => {
          const { url, label } = scope
            ? scopedUrl(scope, scopeId)
            : { url: '/v2/contacts/addressbook', label: 'Address Book' }

          return (
            <Anchor
              url={url}
              style={{
                display: 'inline-flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                marginBottom: '1em',
                fontSize: '1.1em'
              }}
            >
              <IoIosArrowBack className={s.backIcon} />
              {` Return to ${label}`}
            </Anchor>
          )
        }}
      </ScopeContext.Consumer>
      <section className={s.header}>
        <section className={s.contactName}>
          <section className={s.avatarContainer}>
            <If condition={!readOnly} fallback={renderAvatar(contact, avatar, false, true)}>
              <UploadableContainer
                uploadSuccess={files => uploadAvatar(files[0])}
                acceptedTypes={IMAGE_FORMATS}
                render={(isUploading, isError) =>
                  renderAvatar(contact, avatar, isUploading, readOnly)
                }
              />
            </If>
            <If condition={avatar.src && !readOnly}>
              <IoIosClose
                onClick={deleteAvatar}
                className={s.delete}
                title="Remove Profile Image"
              />
            </If>
          </section>
          <h1 className={s.heading} data-testid="contact_profile_header_name">
            {formatContactFullName(contact) || '-- --'}
          </h1>
        </section>
        <span className={s.createdDate}>
          <span className={s.createdLabel}>Created:</span>
          {contact.createdDate || '--'}
        </span>
      </section>
    </>
  )
}

const renderAvatar = (contact, avatar, isUploading, readOnly) => {
  const loading = avatar.isLoading || isUploading
  const hasImage = loading || avatar.src
  return (
    <>
      <div data-for="avatarTooltip" data-tip className={s.avatarWrapper}>
        {hasImage ? (
          <div
            style={{
              backgroundImage: `url(${avatar.src}${avatar.version ? `?${avatar.version}` : ''})`
            }}
            className={s.imageContainer}
          >
            {loading && (
              <Loading
                style={{ height: '28px', width: '28px', marginLeft: '13px', marginTop: '13px' }}
              />
            )}
          </div>
        ) : (
          <Avatar
            initials={contact.fullName}
            className={s.avatar}
            styles={{
              color: '#0c56ae',
              fontWeight: 700,
              lineHeight: 0,
              letterSpacing: '-0.5px',
              fontSize: '20px',
              fontStyle: 'normal',
              border: '1px solid #ddd',
              boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)'
            }}
            textClassName={s.avatarText}
          />
        )}
      </div>
      <p style={loading ? { left: '10px' } : {}} className={s.photoSubtitle}>
        {loading ? 'Loading...' : avatar.src ? 'Profile Photo' : 'Add Photo'}
      </p>
      {!readOnly && (
        <ReactTooltip
          id="avatarTooltip"
          effect="solid"
          place="bottom"
          type="dark"
          className={s.tooltipPopup}
        >
          {avatar.src ? 'Replace photo' : 'Upload photo'}
        </ReactTooltip>
      )}
    </>
  )
}

export default ContactProfileHeader
