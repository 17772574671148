import { useState } from 'react'
import Select, { components } from 'react-select'
import { VendorSelect, PracticeAreaSelect, MultiVendorSelect } from 'common/Selects'
import s from '../Diversity.scss'
import { PeopleImage } from 'simple-core-ui'
import { toFragment } from '../serializers'

import { selectStyle } from '../constants'

function DiversityChoice() {
  const [vendor, setVendor] = useState(null)
  const [choice, setChoice] = useState(null)
  const [practiceArea, setPracticeArea] = useState(null)
  const [vendorComparison, setVendorComparison] = useState(null)

  const handleFilters = filter => {
    const frag = toFragment(filter)
    window.location.href = `/v2/diversity/report${frag}tab=percent_headcount`
  }

  const handleVendorSelect = vendor => {
    setVendor(vendor)
    handleFilters({ vendor, choice })
  }

  const handlePracticeAreaSelect = practiceArea => {
    setPracticeArea(practiceArea)
    handleFilters({ practiceArea, choice })
  }

  const handleVendorComparisonSelect = vendorComparison => {
    setVendorComparison(vendorComparison)
    handleFilters({ vendorComparison, choice })
  }

  const SingleValue = ({ children, ...rest }) => {
    return <components.SingleValue {...rest}>View by {children}</components.SingleValue>
  }

  const selectWidth = choice !== null && choice?.value !== 'vendor_lead' ? '500px' : '300px'

  return (
    <div className={s.container}>
      <h2>Diversity Reports</h2>
      <div className={s.filterContainerChoice}>
        <p>How would you like to view your report?</p>
        <div className={`${s.filter} ${s.choiceFilter}`} style={{ width: selectWidth }}>
          <Select
            placeholder="View report by..."
            value={choice}
            onChange={selectedVal => {
              setChoice(selectedVal)
              if (selectedVal.value == 'vendor_lead') {
                const vendor = { label: 'Vendor Lead', value: 'all' }
                handleFilters({ vendor, choice: selectedVal })
              }
            }}
            options={[
              { label: 'Vendor', value: 'vendor' },
              { label: 'Practice Area', value: 'practice_area' },
              { label: 'Vendor Lead', value: 'vendor_lead' },
              { label: 'Vendor Comparison', value: 'vendor_comparison' }
            ]}
            components={{ SingleValue }}
            isClearable={false}
            className={s.select}
            menuPortalTarget={null}
            styles={selectStyle}
            isDisabled={false}
          />
          {choice !== null &&
            (choice.value === 'vendor' ? (
              <VendorSelect
                placeholder="Choose a vendor"
                value={vendor}
                isClearable={false}
                className={s.select}
                style={selectStyle}
                onChange={handleVendorSelect}
                paginated
              />
            ) : choice.value === 'practice_area' ? (
              <PracticeAreaSelect
                placeholder="Choose a practice area"
                style={selectStyle}
                className={s.select}
                isClearable={false}
                value={practiceArea}
                onChange={handlePracticeAreaSelect}
                paginated
              />
            ) : choice.value === 'vendor_comparison' ? (
              <MultiVendorSelect onChange={handleVendorComparisonSelect} />
            ) : null)}
        </div>
      </div>
      <div className={s.illustrationContainer}>
        <p className={s.diversityText}>
          Compare the representations and utilization of diverse lawyers <br />
          {"across the legal department's outside counsel."}
        </p>
        <img src={PeopleImage} />
      </div>
    </div>
  )
}

export default DiversityChoice
