import { useContext, useState } from 'react'
import classNames from 'classnames'
import Toggle from 'react-toggle'

import {
  Input,
  HoverAction,
  If,
  Badge,
  CopiableContainer,
  DESIGN,
  useScrollIntoView
} from 'simple-core-ui'

import RuleContainer from '../containers/RuleContainer'
import RulesDSLEditor from 'rules/dsl/components/RulesDSLEditor'
import { EngineContext } from 'rules/context'
import { PriorityTip } from 'rules/components'
import { usesPrioritySystem } from 'rules/utils'
import { FaClone } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowUp, IoIosTrash } from 'react-icons/io'

import s from '../styles/RuleCard.scss'

const {
  spacing: { sp200, sp300, sp500, sp900, sp1200 },
  borderRadius: { bdR200 },
  color: { gray800 }
} = DESIGN

function clipRuleDSL(dsl, lineCap) {
  const lines = dsl.split('\n')
  const shownSlice = lines.slice(0, lineCap)
  const hiddenSlice = lines.slice(lineCap)

  return [lines.length <= lineCap ? dsl : shownSlice.join('\n'), hiddenSlice]
}

const MoreLines = ({ lineCount }) => <span className={s.moreLines}>{lineCount} more lines...</span>

const RuleCard = ({
  id,
  name,
  isActive,
  description,
  ruleIndex,
  ruleDSL,
  date,
  isEditing,
  toggleCard,
  removeCard,
  cloneRule,
  isUnconditional,
  isComplete,
  save,
  cancel,
  undo,
  redo,
  simplify,
  updateField,
  ruleWasUpdated,
  priority,
  engine,
  isSaving,
  newRulePending
}) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const { canEdit } = useContext(EngineContext)
  const cardRef = useScrollIntoView([isEditing])

  const [shownText, hiddenLines] = clipRuleDSL(ruleDSL, 7)

  const Name =
    isEditing && canEdit ? (
      <Input
        placeholder="Rule Name..."
        text={name}
        // https://github.com/react-bootstrap/react-bootstrap/issues/1517
        maxLength="120"
        onChangeCb={({ target: { value } }) => updateField('name', value, ruleIndex)}
        style={{
          marginBottom: sp200,
          maxWidth: sp1200,
          padding: sp200,
          borderRadius: bdR200
        }}
      />
    ) : (
      <p className={s.ruleName}>{name}</p>
    )

  const Description =
    isEditing && canEdit ? (
      <Input
        type="textarea"
        placeholder="Description..."
        text={description}
        onChangeCb={({ target: { value } }) => updateField('description', value, ruleIndex)}
        style={{ height: sp900, padding: sp200, borderRadius: bdR200 }}
      />
    ) : (
      <p className={s.ruleDescription}>{description}</p>
    )

  return (
    <li className={classNames(s.container, { [s.isOpen]: isEditing })} ref={cardRef}>
      <header className={s.header} style={!description ? { paddingBottom: '0' } : {}}>
        {usesPrioritySystem(engine) && !isEditing && (
          <span className={s.priorityWrapper}>
            <PriorityTip position="right" hasUnderline={false}>
              <span className={s.priority}>{isFinite(priority) ? priority : '--'}</span>
            </PriorityTip>
          </span>
        )}
        <section className={s.leftSide}>
          {Name}
          {Description}
        </section>
        <section className={s.rightSide}>
          <If condition={!isEditing && ruleWasUpdated}>
            <Badge
              content="Unsaved"
              size="sm"
              title="Unsaved Rule"
              className={classNames(s.badge, s.unsavedBadge)}
            />
          </If>
          <If condition={!isEditing && isUnconditional}>
            <Badge
              content="Unconditional"
              size="sm"
              title="Unconditional Rule"
              className={classNames(s.badge, s.unconditionalBadge)}
            />
          </If>
          <section className={s.toggleField}>
            <label htmlFor="active-rule" className={isActive ? s.active : s.inactive}>
              {isActive ? 'ACTIVE' : 'INACTIVE'}
            </label>
            <If condition={isEditing && canEdit}>
              <Toggle
                id="active-rule"
                checked={isActive}
                disabled={!isEditing || isSaving}
                onChange={() => {
                  updateField('is_draft', isActive, ruleIndex)
                }}
              />
            </If>
          </section>
          <section className={s.hoverActions}>
            <If condition={window.credentials.user.isCSM}>
              <CopiableContainer
                textToCopy={ruleDSL}
                hoverActionProps={{
                  className: s.hoverAction,
                  size: 'small'
                }}
              />
            </If>
            <If condition={canEdit}>
              <HoverAction
                onClick={() => removeCard(ruleIndex)}
                tip="Delete Rule"
                icon={<IoIosTrash />}
                className={s.hoverAction}
                isDisabled={isSaving}
              />
              <HoverAction
                onClick={cloneRule}
                tip="Clone Rule"
                icon={<FaClone />}
                className={s.hoverAction}
                isDisabled={isSaving || !id || ruleWasUpdated || newRulePending}
              />
            </If>
            <HoverAction
              onClick={toggleCard}
              tip={isEditing ? 'Hide Rule' : canEdit ? 'Edit Rule' : 'Show Rule'}
              icon={
                isEditing ? (
                  <IoIosArrowUp />
                ) : canEdit ? (
                  <span className={classNames('simple-line-icon-pencil', s.editIcon)} />
                ) : (
                  <IoIosArrowDown />
                )
              }
              className={s.hoverAction}
              isDisabled={isSaving}
            />
          </section>
        </section>
      </header>
      {isEditing ? (
        <main className={s.mainContent}>
          <RuleContainer
            ruleIndex={ruleIndex}
            save={save}
            cancel={cancel}
            undo={undo}
            redo={redo}
            simplify={simplify}
            isUnconditional={isUnconditional}
          />
        </main>
      ) : (
        <section
          onClick={() => setCollapsed(!isCollapsed)}
          className={s.dslContainer}
          style={hiddenLines.length ? { cursor: 'pointer' } : {}}
        >
          <RulesDSLEditor
            value={isCollapsed ? shownText : ruleDSL}
            style={{
              width: `calc(100% - ${usesPrioritySystem(engine) ? '83px' : sp500})`,
              backgroundColor: 'transparent',
              color: gray800,
              marginBottom: sp500,
              marginLeft: usesPrioritySystem(engine) ? '83px' : sp500
            }}
            name={`rule_dsl_${id}`}
            fontSize={12}
            noSyntax
            makeHeightOfContent
            readOnly
          />
          {isCollapsed && !!hiddenLines.length && <MoreLines lineCount={hiddenLines.length} />}
        </section>
      )}
    </li>
  )
}

export default RuleCard
