import keyMirror from 'keymirror'

export default keyMirror({
  MATTER_LIST_FETCH_REQUESTED: null,
  MATTER_LIST_FETCH_SUCCESS: null,
  MATTER_LIST_RESET: null,
  MATTER_SUMMARY_FETCH_REQUESTED: null,
  MATTER_SUMMARY_FETCH_SUCCESS: null,
  MATTER_LIST_DELETE_REQUESTED: null,
  MATTER_LIST_DELETE_SUCCESS: null,
  UPDATE_MATTER_REQUESTED: null,
  UPDATE_MATTER_SUCCESS: null,
  MATTER_LIST_MERGE_SUCCESS: null,
  SELECTED_MATTERS_DELETED: null,
  SELECTED_MATTERS_CLOSED: null,
  SELECTED_MATTERS_UPDATED: null
})
