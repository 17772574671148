import { Option, Event, TriggerOption, Jurisdiction, JurisdictionAPI } from './types'

interface Obj {
  id: number
  name: string
}

export const toOptions = (list: Obj[]): Option[] => {
  return list.map(el => ({
    value: el.id,
    label: el.name
  }))
}

export const toTriggersOptions = (
  list: {
    system_id: number
    description: string
    served: boolean
  }[]
): TriggerOption[] => {
  return list.map(el => ({
    value: el.system_id,
    label: el.description,
    served: el.served
  }))
}

export const toJurisdictions = (list: JurisdictionAPI[]): Jurisdiction[] =>
  list.map(jurisdiction => ({
    id: jurisdiction.id,
    name: jurisdiction.name,
    timezone: jurisdiction.time_zone
  }))

export const toRules = (events: Event[], prefix?: string) => {
  return events.map(event => ({
    id: event.system_id,
    name: prefix ? `[${prefix}] ${event.short_name}` : event.short_name,
    date: event.event_date,
    description:
      (prefix ? `[${prefix}] ${event.short_name}` : event.short_name) +
      '\n\n' +
      event.date_rules.map((r: any) => r.rule_text + '\n\n') +
      event.court_rules.map((r: any) => r.rule_text + '\n\n'),
    expanded: false,
    do_not_recalculate: event.do_not_recalculate,
    is_event_docket: event.is_event_docket,
    is_on_dnc_list: event.is_on_dnc_list
  }))
}

export const fromEvent = (event: Event) => {
  const { name, attendee_ids, date, description, reminder, id } = event

  return {
    system_id: id,
    name,
    attendee_ids: attendee_ids?.map((attendee: Option) => attendee.value) ?? [],
    start_date: date || null,
    description,
    reminder: reminder
      ? {
          amount: reminder?.value,
          time_unit: reminder?.type
        }
      : null,
    do_not_recalculate: event.do_not_recalculate,
    is_event_docket: event.is_event_docket,
    is_on_dnc_list: event.is_on_dnc_list
  }
}
