import { BulkEditValues } from './types'

export const OPERATORS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  IS_SET: 'IS_SET',
  IS_NOT_SET: 'IS_NOT_SET',
  IS_BEFORE: 'IS_BEFORE',
  IS_AFTER: 'IS_AFTER',
  IS_BETWEEN: 'IS_BETWEEN',
  WITH_COMMENTS: 'WITH_COMMENTS',
  WITHOUT_COMMENTS: 'WITHOUT_COMMENTS',
  WITH_ATTACHMENTS: 'WITH_ATTACHMENTS',
  WITHOUT_ATTACHMENTS: 'WITHOUT_ATTACHMENTS',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  DOES_NOT_NEED_REVIEW: 'DOES_NOT_NEED_REVIEW'
}

export const EVENT_OPTIONS_ENDPOINTS = {
  EVENT_TYPES: '/event-management/event-types/'
}

export const BULK_EDIT_INITIAL_VALUES: BulkEditValues = {
  attendeesToAdd: [],
  attendeesToRemove: [],
  date: undefined,
  time: { startTime: null, endTime: null },
  location: null,
  reminder: null,
  eventType: null
}
