import { Routes, Route, useMatch } from 'react-router-dom'
import { PrivateRoute } from 'components'
import { ModuleGuard, RoleGuard } from 'components/Utils/PrivateRoute/guards'
import { DevRoute, NotFound } from 'simple-core-ui'

import { MattersContainer } from 'containers'

import IsoContainer from '../containers/IsoContainer'
import LandingPageContainer from '../containers/LandingPageContainer'
import { TabSettingsContainer, TabReportsContainer } from 'tableau'
import { VendorEvalContainer } from 'vendors/survey'
import { VendorScorecardContainer } from 'vendors/scorecard'
import { DocumentManagementSettings } from 'doc_management/settings'
import { TaskManagementSettings } from 'task_management/settings'
import { EventManagementSettings } from 'event_management/settings'
import { MatterManagementSettings } from 'matter_management/settings'
import { ExternalCalendarSyncSettings } from 'external_calendar_sync/settings'
import { DynamicAttributes } from 'dynamic_attributes'
import { ReportsContainer } from 'reports'
import { AccrualsVsActuals } from 'accruals_vs_actuals'
import BudgetsList from 'budgets/list_v2'
import { ContactsRouter } from 'contacts'
import { InvoicesListContainer } from 'invoices'
import { VendorsListContainer } from '../../../vendors'
import { BulkActionsContainer } from 'bulk'
import { TasksWorkbench } from 'tasks_workbench'
import { CalendarWorkbench } from 'calendar_workbench'
import s from '../styles/ReactPage.scss'

import Budgets from 'budgets'
import Rules from 'rules'
import { TeamsRouter } from 'teams'
import { ClientKeysRouter } from 'client_keys'

import SearchResultsContainer from 'app/search'
import SessionExpirationContainer from '../containers/SessionExpirationContainer'
import { DiversityChoice, DiversityReports } from '../../../diversity'
import { InvoiceRejectionSettings } from 'invoice_settings'

const ReactPage = () => (
  <section className={s.innerReactPageContainer}>
    <Routes>
      <Route path=":landingPage" element={<LandingPageContainer />} />
      <Route path="search" element={<SearchResultsContainer />} />
      <Route path="reports" element={<ReportsContainer />} />
      <Route path="reports/:activeTab" element={<ReportsContainer />} />
      <Route path="reports/:activeTab/:adhocActiveTab" element={<ReportsContainer />} />
      <Route path="reports/accr_vs_act" element={<AccrualsVsActuals />} />
      <Route path="invoices" element={<InvoicesListContainer />} />
      <Route path="vendor/list" element={<VendorsListContainer />} />
      {!useMatch({
        path: '/v2/matters',
        end: true,
        caseSensitive: true
      }) && <Route path="matters/*" element={<MattersContainer />} />}
      <Route path="rules/*" element={<Rules />} />
      <Route path="vendor/evaluations/:evalId" element={<VendorEvalContainer />} />
      <Route path="vendor/scorecard/:vendorId" element={<VendorScorecardContainer />} />
      <Route path="budgets/list_v2" element={<BudgetsList />} />
      <Route path="budgets" element={<Budgets />} />
      <Route path="contacts/*" element={<ContactsRouter />} />
      <Route path="teams/*" element={<TeamsRouter />} />
      <Route path="client_keys/*" element={<ClientKeysRouter />} />
      <Route path="bulk" element={<BulkActionsContainer />} />
      <Route path="diversity/report" element={<DiversityReports />} />
      <Route path="diversity" element={<DiversityChoice />} />
      <Route path="/iso" element={<DevRoute />}>
        <Route index element={<IsoContainer />} />
      </Route>
      <Route
        path="doc_management/settings"
        element={
          <PrivateRoute
            component={DocumentManagementSettings}
            guards={[RoleGuard('admin', 'poweruser')]}
          />
        }
      />
      <Route
        path="task_management/settings/*"
        element={
          <PrivateRoute
            component={TaskManagementSettings}
            guards={[ModuleGuard('has_tasks'), RoleGuard('admin')]}
          />
        }
      />
      <Route
        path="event_management/settings/*"
        element={
          <PrivateRoute
            component={EventManagementSettings}
            guards={[ModuleGuard('has_event_management'), RoleGuard('admin')]}
          />
        }
      />
      <Route
        path="matter_management/settings/*"
        element={
          <PrivateRoute component={MatterManagementSettings} guards={[RoleGuard('admin')]} />
        }
      />
      <Route
        path="external_calendar_sync/settings/*"
        element={
          <PrivateRoute
            component={ExternalCalendarSyncSettings}
            guards={[ModuleGuard('has_event_management', 'has_tasks'), RoleGuard('admin')]}
          />
        }
      />
      <Route
        path="tableau_settings"
        element={<PrivateRoute component={TabSettingsContainer} guards={[RoleGuard('admin')]} />}
      />
      <Route path="reports/tableau" element={<TabReportsContainer />} />
      <Route path="dynamic_attributes/*" element={<DynamicAttributes />} />
      <Route
        path="tasks"
        element={<PrivateRoute component={TasksWorkbench} guards={[ModuleGuard('has_tasks')]} />}
      />
      <Route
        path="calendar"
        element={
          <PrivateRoute
            component={CalendarWorkbench}
            guards={[ModuleGuard('has_tasks', 'has_event_management')]}
          />
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route
        path="invoice_settings/"
        element={
          <PrivateRoute component={InvoiceRejectionSettings} guards={[RoleGuard('admin')]} />
        }
      />
    </Routes>
    <SessionExpirationContainer />
  </section>
)

export default ReactPage
