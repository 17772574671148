import { InfoBoxContainer } from 'simple-core-ui'

import { slColors } from 'styles/cssInJs'

const IntegratedConfidentialMatterInfo = () => (
  <InfoBoxContainer
    initExpanded
    message={
      <strong data-testid="confidential_matter">
        This matter is confidential. Only users granted permission can view the matter details.
      </strong>
    }
    details={<p>If accruals are enabled, this matter name will be visible in the accruals form.</p>}
    icon={<span className="simple-line-icon-lock" />}
    style={{ color: slColors.darkerBlue }}
  />
)

export default IntegratedConfidentialMatterInfo
