import s from './Tabs.scss'
import cn from 'classnames'
import { SetStateAction, Dispatch } from 'react'
import { ConnectionType, SSO_TABS } from 'sso_config/constants'

interface IProps {
  selectedTab: ConnectionType
  setSelectedTab: Dispatch<SetStateAction<ConnectionType>>
}

const Tabs = ({ selectedTab, setSelectedTab }: IProps) => {
  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {SSO_TABS.map(tab => (
        <li
          onClick={() => setSelectedTab(tab.value)}
          key={tab.value}
          data-testid={tab.value}
          className={cn(s.tab, { [s.selected]: selectedTab === tab.value })}
        >
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
