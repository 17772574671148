import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'simple-core-ui'
import StateBanner from 'budgets/detail/components/StateBanner'
import { MATTER_STATUS } from 'matters/constants'

@connect(({ app }, ownProps) => {
  return {
    id: app.scope?.id || ownProps.scopeId
  }
})
class BannerContainer extends React.Component {
  getNewStateBannerMap = pendingRequiredFields => {
    const pendingFieldsMessage = pendingRequiredFields.length
      ? `The following fields require values: ${pendingRequiredFields.join(', ')}. `
      : ''
    return {
      [MATTER_STATUS.DRAFT]: {
        bannerText: `${pendingFieldsMessage}Publish your matter to make it available for vendors.`,
        actionButtons: [
          <Button
            key={'a'}
            isPrimary
            onClick={this.props.publishMatter}
            isDisabled={pendingRequiredFields.length}
          >
            Publish Matter
          </Button>
        ]
      }
    }
  }

  render() {
    const { status = MATTER_STATUS.DRAFT, classNameMap, pendingRequiredFields } = this.props
    let actionButtons, bannerText
    const stateMap = this.getNewStateBannerMap(pendingRequiredFields)
    try {
      actionButtons = stateMap[status].actionButtons
      bannerText = stateMap[status].bannerText
    } catch (e) {
      throw Error('Status of the matter is invalid')
    }

    return (
      <StateBanner
        bannerText={bannerText}
        actionButtons={actionButtons}
        classNameMap={classNameMap}
      />
    )
  }
}

export default BannerContainer
