import { ReactNode } from 'react'
import { ModalContainer } from 'simple-core-ui'

interface Props {
  title: string
  confirmText?: string
  cancelText?: string
  content: ReactNode | string
  hideButtons?: boolean
  onConfirm?: () => void
  onCancel: () => void
}

const ConfirmationDialog = ({
  title,
  confirmText,
  cancelText,
  content,
  hideButtons,
  onConfirm,
  onCancel
}: Props) => {
  return (
    <ModalContainer
      title={title}
      content={content}
      confirmText={confirmText}
      cancelText={cancelText}
      size="sm"
      hasNewButtons
      hideButtons={hideButtons}
      contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
      confirmCb={onConfirm}
      cancelCb={onCancel}
    />
  )
}

export default ConfirmationDialog
