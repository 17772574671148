import { Fragment } from 'react'
import s from './EditMatterModal.scss'
import { PracticeAreaSelect, UserSelect } from 'common/Selects'

const EditMatterModal = ({
  newPracticeArea,
  setNewPracticeArea,
  newMatterLead,
  setNewMatterLead,
  canEditPracticeArea,
  matterGroupLabels,
  isEditMatterGroup,
  isEditMatterLead,
  isBulkEdit
}) => {
  return (
    <Fragment>
      {(isEditMatterLead || isBulkEdit) && (
        <div className={s.row}>
          <label>Matter Lead</label>
          <h4 className={s.subtitle}>The assigned matter lead will have read and write access</h4>
          <UserSelect
            value={newMatterLead}
            onChange={item => {
              setNewMatterLead(item)
            }}
            isPortal
            isClearable
            paginated
            placeholder="Search Matter Lead"
            customQueryParams={{ l: true, i: true }}
          />
        </div>
      )}
      {(isEditMatterGroup || isBulkEdit) && (
        <div className={s.row}>
          <label>{matterGroupLabels[0]}</label>
          <h4
            className={s.subtitle}
          >{`This cannot be edited when the matter template in use has a fixed ${matterGroupLabels[0]}`}</h4>
          <PracticeAreaSelect
            value={newPracticeArea}
            onChange={item => {
              setNewPracticeArea(item)
            }}
            isPortal
            isDisabled={!canEditPracticeArea}
            isClearable
            paginated
            placeholder={`Search ${matterGroupLabels[0]}`}
          />
        </div>
      )}
    </Fragment>
  )
}

export default EditMatterModal
