export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'

/**
 * Matter Templates Attributes
 */
export const MATTER_TEMPLATES_ATTRIBUTES_FETCH_REQUESTED = 'MATTER_TEMPLATES_DATA_FETCH_REQUESTED'
export const MATTER_TEMPLATES_ATTRIBUTES_FETCH_SUCCESS = 'MATTER_TEMPLATES_DATA_FETCH_SUCCESS'
export const MATTER_TEMPLATES_FILTER_TERM = 'MATTER_TEMPLATES_FILTER_TERM'
export const MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_REQUESTED =
  'MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_REQUESTED'
export const MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_SUCCESS =
  'MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_SUCCESS'
export const MATTER_FILTERED_ATTRIBUTES_FETCH_SUCCESS = 'MATTER_FILTERED_ATTRIBUTES_FETCH_SUCCESS'
export const MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_REQUESTED =
  'MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_REQUESTED'
export const MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_SUCCESS =
  'MATTER_TEMPLATE_ALLOCATION_ATTRIBUTES_FETCH_SUCCESS'
export const MATTER_TEMPLATE_ADD_EMPTY_ALLOCATION = 'MATTER_TEMPLATE_ADD_EMPTY_ALLOCATION'
export const MATTER_TEMPLATE_REMOVE_ALLOCATION = 'MATTER_TEMPLATE_REMOVE_ALLOCATION'
export const MATTER_TEMPLATE_CHANGE_ALLOCATION = 'MATTER_TEMPLATE_CHANGE_ALLOCATION'
export const MATTER_TEMPLATE_SET_ALLOCATIONS_REQUESTED = 'MATTER_TEMPLATE_SET_ALLOCATIONS_REQUESTED'
export const MATTER_TEMPLATE_ALLOCATIONS_FETCH_REQUESTED =
  'MATTER_TEMPLATE_ALLOCATIONS_FETCH_REQUESTED'
export const MATTER_TEMPLATE_ALLOCATIONS_FETCH_SUCCESS = 'MATTER_TEMPLATE_ALLOCATIONS_FETCH_SUCCESS'
export const MATTER_TEMPLATE_ALLOCATIONS_RESET_STATE = 'MATTER_TEMPLATE_ALLOCATIONS_RESET_STATE'
export const MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_REQUESTED =
  'MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_REQUESTED'
export const MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_SUCCESS =
  'MATTER_TEMPLATES_ALLOCATION_FILTERED_ATTRIBUTES_FETCH_SUCCESS'
export const MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED =
  'MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED'
export const MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS =
  'MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS'
export const MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_REQUESTED =
  'MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_REQUESTED'
export const MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_SUCCESS =
  'MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_SUCCESS'
export const MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_UNCHECK_REQUIRED_FOR_SUB_LEVELS =
  'MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_UNCHECK_REQUIRED_FOR_SUB_LEVELS'

export const DEFAULT_FOLDERS_FETCH_REQUESTED = 'DEFAULT_FOLDERS_FETCH_REQUESTED'
export const DEFAULT_FOLDERS_FETCH_SUCCESS = 'DEFAULT_FOLDERS_FETCH_SUCCESS'
export const UPDATE_DEFAULT_FOLDERS = 'UPDATE_DEFAULT_FOLDERS'
export const RENAME_FOLDER_NAME = 'RENAME_FOLDER_NAME'
export const DELETE_FOLDER_NAME = 'DELETE_FOLDER_NAME'
export const MOVE_FOLDER = 'MOVE_FOLDER'
export const SET_DEFAULT_FOLDERS_REQUESTED = 'SET_DEFAULT_FOLDERS_REQUESTED'
export const SET_DEFAULT_FOLDERS_SUCCESS = 'SET_DEFAULT_FOLDERS_SUCCESS'
export const UPDATE_CURRENT_FOLDER = 'UPDATE_CURRENT_FOLDER'
export const RESET_DEFAULT_FOLDERS = 'RESET_DEFAULT_FOLDERS'

/**
 * Matter Templates
 */
export const MATTER_TEMPLATES_CHANGE_TEMPLATE_DESCRIPTION =
  'MATTER_TEMPLATES_CHANGE_TEMPLATE_DESCRIPTION'
export const MATTER_TEMPLATES_CHANGE_TEMPLATE_NAME = 'MATTER_TEMPLATES_CHANGE_TEMPLATE_NAME'
export const MATTER_TEMPLATES_CHANGE_ATTRIBUTE_VALUE = 'MATTER_TEMPLATES_CHANGE_ATTRIBUTE_VALUE'
export const MATTER_TEMPLATES_ADD_ATTRIBUTE_GROUP = 'MATTER_TEMPLATES_ADD_ATTRIBUTE_GROUP'
export const MATTER_TEMPLATES_ADD_ATTRIBUTE = 'MATTER_TEMPLATES_ADD_ATTRIBUTE'
export const MATTER_TEMPLATES_REORDER_ATTRIBUTE = 'MATTER_TEMPLATES_REORDER_ATTRIBUTE'
export const MATTER_TEMPLATES_CHANGE_PANEL_TITLE = 'MATTER_TEMPLATES_CHANGE_PANEL_TITLE'
export const MATTER_TEMPLATES_CHANGE_SECTION_NAME = 'MATTER_TEMPLATES_CHANGE_SECTION_NAME'
export const MATTER_TEMPLATES_ADD_NEW_SECTION = 'MATTER_TEMPLATES_ADD_NEW_SECTION'
export const MATTER_TEMPLATES_REORDER_ATTRIBUTE_GROUP = 'MATTER_TEMPLATES_REORDER_ATTRIBUTE_GROUP'
export const MATTER_TEMPLATES_REMOVE_ATTRIBUTE = 'MATTER_TEMPLATES_REMOVE_ATTRIBUTE'
export const MATTER_TEMPLATES_REMOVE_SECTION = 'MATTER_TEMPLATES_REMOVE_SECTION'
export const MATTER_TEMPLATES_PIN_SECTION = 'MATTER_TEMPLATES_PIN_SECTION'
export const MATTER_TEMPLATE_CREATE_REQUESTED = 'MATTER_TEMPLATE_CREATE_REQUESTED'
export const MATTER_TEMPLATE_UPDATE_REQUESTED = 'MATTER_TEMPLATE_UPDATE_REQUESTED'
export const MATTER_TEMPLATE_CREATE_SUCCESS = 'MATTER_TEMPLATE_CREATE_SUCCESS'
export const MATTER_TEMPLATE_CREATE_SUCCESS_AND_REDIRECT =
  'MATTER_TEMPLATE_CREATE_SUCCESS_AND_REDIRECT'
export const MATTER_TEMPLATE_REMOVE_REDIRECT_FLAG = 'MATTER_TEMPLATE_REMOVE_REDIRECT_FLAG'
export const MATTER_TEMPLATE_FETCH_REQUESTED = 'MATTER_TEMPLATE_FETCH_REQUESTED'
export const MATTER_TEMPLATE_FETCH_SUCCESS = 'MATTER_TEMPLATE_FETCH_SUCCESS'
export const NEW_MATTER_TEMPLATE_REQUESTED = 'NEW_MATTER_TEMPLATE_REQUESTED'
export const LEGAL_REQUEST_FETCH_REQUESTED = 'LEGAL_REQUEST_FETCH_REQUESTED'
export const LEGAL_REQUEST_FETCH_SUCCESS = 'LEGAL_REQUEST_FETCH_SUCCESS'
export const MATTER_ATTRIBUTES_FETCH_REQUESTED = 'MATTER_ATTRIBUTES_FETCH_REQUESTED'
export const LEGAL_REQUEST_CHANGE_ATTRIBUTE_VALUE = 'LEGAL_REQUEST_CHANGE_ATTRIBUTE_VALUE'
export const LEGAL_REQUEST_REJECT_REQUESTED = 'LEGAL_REQUEST_REJECT_REQUESTED'
export const LEGAL_REQUEST_RESET_STATE = 'LEGAL_REQUEST_RESET_STATE'
export const VALUES_FOR_TEMPLATE_FETCH_REQUESTED = 'VALUES_FOR_TEMPLATE_FETCH_REQUESTED'
export const MATTER_TEMPLATES_UPDATE_FILTERED_ATTRIBUTES =
  'MATTER_TEMPLATES_UPDATE_FILTERED_ATTRIBUTES'
export const MATTER_TEMPLATE_SET_BREAKING_WARNING = 'MATTER_TEMPLATE_SET_BREAKING_WARNING'
export const MATTER_TEMPLATE_CLONE_REQUESTED = 'MATTER_TEMPLATE_CLONE_REQUESTED'
export const MATTER_ATTRIBUTES_DEFAULT_FETCH_REQUESTED = 'MATTER_ATTRIBUTES_DEFAULT_FETCH_REQUESTED'
export const MATTER_ATTRIBUTES_DEFAULT_FETCH_SUCCESS = 'MATTER_ATTRIBUTES_DEFAULT_FETCH_SUCCESS'
export const MATTER_ATTRIBUTES_SET_DEFAULTS_IN_DRAWER = 'MATTER_ATTRIBUTES_SET_DEFAULTS_IN_DRAWER'
export const MATTER_ATTRIBUTES_RESET_DEFAULTS_IN_DRAWER =
  'MATTER_ATTRIBUTES_RESET_DEFAULTS_IN_DRAWER'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const UPDATE_SELECTION_USER_FLOW = 'UPDATE_SELECTION_USER_FLOW'
export const LEGAL_REQUEST_MAKE_ATTRIBUTES_READ_ONLY = 'LEGAL_REQUEST_MAKE_ATTRIBUTES_READ_ONLY'
export const UPDATE_SELECTION_PAGE_DESCRIPTION = 'UPDATE_SELECTION_PAGE_DESCRIPTION'
export const REQUEST_FORM_SET_TEMPLATES_LIST = 'REQUEST_FORM_SET_TEMPLATES_LIST'
export const MATTER_TEMPLATE_SELECTION_RESET_STATE = 'MATTER_TEMPLATE_SELECTION_RESET_STATE'
export const MATTER_TEMPLATE_SET_IS_SAVING = 'MATTER_TEMPLATE_SET_IS_SAVING'
export const LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED =
  'LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED'
export const LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS =
  'LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS'

/**
 * Matter Templates List
 */
export const MATTER_TEMPLATE_LIST_FETCH_REQUESTED = 'MATTER_TEMPLATE_LIST_FETCH_REQUESTED'
export const MATTER_TEMPLATE_LIST_FETCH_SUCCESS = 'MATTER_TEMPLATE_LIST_FETCH_SUCCESS'
export const MATTER_TEMPLATE_SELECT_REQUESTED = 'MATTER_TEMPLATE_SELECT_REQUESTED'
export const MATTER_UPDATE_FOR_NEW_TEMPLATE_REQUESTED = 'MATTER_UPDATE_FOR_NEW_TEMPLATE_REQUESTED'
export const MATTER_TEMPLATE_RESET_STATE = 'MATTER_TEMPLATE_RESET_STATE'
export const MATTER_TEMPLATES_LIST_CHANGE_PARAM = 'MATTER_TEMPLATES_LIST_CHANGE_PARAM'
export const MATTER_TEMPLATE_RESTORE_TEMPLATE = 'MATTER_TEMPLATE_RESTORE_TEMPLATE'
export const MATTER_TEMPLATE_RESTORE_TO_DRAFT = 'MATTER_TEMPLATE_RESTORE_TO_DRAFT'
export const MATTER_TEMPLATES_DELETE_TEMPLATE = 'MATTER_TEMPLATES_DELETE_TEMPLATE'
export const MATTER_TEMPLATES_ARCHIVE_TEMPLATE = 'MATTER_TEMPLATES_ARCHIVE_TEMPLATE'
export const MATTER_TEMPLATES_RESTORE_TEMPLATE = 'MATTER_TEMPLATES_RESTORE_TEMPLATE'
export const MATTER_TEMPLATES_FETCH_SUCCESS = 'MATTER_TEMPLATES_FETCH_SUCCESS'
export const MATTER_TEMPLATE_DELETE_REQUESTED = 'MATTER_TEMPLATE_DELETE_REQUESTED'
export const MATTER_TEMPLATE_ARCHIVE_REQUESTED = 'MATTER_TEMPLATE_ARCHIVE_REQUESTED'
export const MATTER_TEMPLATE_RESTORE_REQUESTED = 'MATTER_TEMPLATE_RESTORE_REQUESTED'
export const MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED = 'MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED'
export const MATTER_TEMPLATE_APPEND_ADDITIONAL_ATTRIBUTES =
  'MATTER_TEMPLATE_APPEND_ADDITIONAL_ATTRIBUTES'
export const MATTER_TEMPLATE_RESET_ADDITIONAL_ATTRIBUTES =
  'MATTER_TEMPLATE_RESET_ADDITIONAL_ATTRIBUTES'
export const MATTER_TEMPLATE_RESET_SELECTED_VALUES = 'MATTER_TEMPLATE_RESET_SELECTED_VALUES'
export const MATTER_TEMPLATE_SET_USER_DEFAULT_REQUESTED =
  'MATTER_TEMPLATE_SET_USER_DEFAULT_REQUESTED'
export const MATTER_TEMPLATE_SET_USER_DEFAULT_SUCCESS = 'MATTER_TEMPLATE_SET_USER_DEFAULT_SUCCESS'
export const SELECTION_PAGE_DESCRIPTION_FETCH_REQUESTED =
  'SELECTION_PAGE_DESCRIPTION_FETCH_REQUESTED'
export const SELECTION_PAGE_DESCRIPTION_FETCH_SUCCESS = 'SELECTION_PAGE_DESCRIPTION_FETCH_SUCCESS'
export const MATTER_TEMPLATE_SELECTION_PAGE_UPDATE_DESCRIPTION =
  'MATTER_TEMPLATE_SELECTION_PAGE_UPDATE_DESCRIPTION'
export const MATTER_TEMPLATE_SELECTION_PAGE_CHANGE_DESCRIPTION =
  'MATTER_TEMPLATE_SELECTION_PAGE_CHANGE_DESCRIPTION'
export const MATTER_TEMPLATE_FILTER_TERM = 'MATTER_TEMPLATE_FILTER_TERM'
export const MATTER_TEMPLATE_PREVIEW = 'MATTER_TEMPLATE_PREVIEW'

/**
 * Preferred form templates
 */
export const MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED =
  'MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED'

export const MATTER_DETAIL_FETCH_REQUESTED = 'MATTER_DETAIL_FETCH_REQUESTED'
export const MATTER_DETAIL_FETCH_SUCCESS = 'MATTER_DETAIL_FETCH_SUCCESS'
export const MATTER_TEMPLATE_FORM_LIST_CLEAR_PREFERRED = 'MATTER_TEMPLATE_FORM_LIST_CLEAR_PREFERRED'
export const MATTER_TEMPLATE_FORM_LIST_SET_PREFERRED = 'MATTER_TEMPLATE_FORM_LIST_SET_PREFERRED'
