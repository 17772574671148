import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import moment from 'moment'
import defaultTo from 'lodash/defaultTo'
import { titleCase } from 'title-case'
import currencies from 'data/currencies'

export const capitalizeWord = str => {
  if (typeof str !== 'string') {
    return
  }

  return str ? str[0].toUpperCase() + str.slice(1) : ''
}

export const cleanNonNumeric = currency => {
  if (typeof currency === 'number') return currency
  if (currency === '') return currency

  let temp = ''

  // first remove non alphanumeric chars from the beginning,
  // whether it is ltr or rtl (for ex. arabic is rtl)
  let sign = 1
  for (let i = 0; i < currency.length; i++) {
    if (!isNaN(currency[i])) {
      temp = currency.substring(i)
      break
    } else if (currency[i] === '-') {
      sign = -1
    }
  }

  // remove non alphanumeric chars from the end
  for (let j = temp.length - 1; j >= 0; j--) {
    if (!isNaN(temp[j])) {
      temp = temp.substring(0, j + 1)
      break
    }
  }

  // then remove unnecessary commas/other things
  temp = temp.replace(/[^0-9.-]+/g, '')

  return Number(temp) * sign
}

export const currency2Num = (currency, forceConvert) => {
  if (typeof currency !== 'string') {
    return currency
  } else if (currency === '') {
    return forceConvert ? Number(currency) : ''
  }

  return cleanNonNumeric(currency)
}

export const currencyAwareFormat = (amount, curr) => {
  if (typeof amount === 'string' && !Number.isNaN(Number(amount))) {
    amount = Number(amount)
  }

  if (typeof amount !== 'number') {
    return
  }

  //Default to USD if no currency exists
  const currObj = currencies.find(c => c.value === curr) || currencies[0]

  try {
    const options = {
      style: 'currency',
      currency: currObj.value,
      currencyDisplay: 'symbol',
      minimumFractionDigits: currObj.exponent,
      maximumFractionDigits: currObj.exponent
    }

    return amount.toLocaleString('en-US', options)
  } catch (e) {
    console.error(e)
    return 'Invalid Currency: ' + e.message
  }
}

export const capitalizeStr = str => {
  if (typeof str !== 'string') {
    return
  }

  return str
    .split(' ')
    .map(capitalizeWord)
    .join(' ')
    .trim()
}

export const formatResponse = (response, fallback = '') => {
  /*
   * Will return a string if response is of type string.
   *
   * Will break down an object/json response into a list of strings.
   *
   * Will return fallback value if not a plain object or a string.
   */
  if (typeof response === 'string') {
    return response
  }

  if (!isPlainObject(response)) {
    return fallback
  }

  return Object.keys(response).reduce((acc, c) => {
    const value = response[c]

    if (Array.isArray(value)) {
      return [...acc, ...value]
    }

    return [...acc, value]
  }, [])
}

export const formatErrorMessage = error =>
  isArray(error.response.data.message)
    ? error.response.data.message.join('. ')
    : error.response.data.message

export const formatErrors = errors => errors.map(e => e.message).join('.\n')

// Will capitalize first letter of each word split on space
export const capitalize = titleCase

export const formatFullName = (firstName = '', lastName = '') => `${firstName} ${lastName}`.trim()

export const formatLongDate = date => {
  return moment(date).format('MMMM D, YYYY')
}

export const formatCityState = ({ city, state }) =>
  `${defaultTo(city, '')}${city && state ? ', ' : ''}${defaultTo(state, '')}`
