import { capitalizeStr } from 'utils/formatting'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

export const toAttributeDetails = (attribute, matterGroupLabel) => {
  const {
    name,
    description,
    is_allocatable,
    display_group,
    id,
    scopes,
    active,
    field_type,
    is_toggable,
    has_purchase_orders
  } = attribute
  const hasPOInScopes = scopes?.findIndex(a => a.name === 'purchase-order') > -1
  const list = [
    {
      value: 'matters',
      label: 'Matters'
    },
    {
      value: 'legal-entity',
      label: matterGroupLabel
    },
    ...(has_purchase_orders || hasPOInScopes
      ? [
          {
            value: 'purchase-order',
            label: 'Purchase Order'
          }
        ]
      : []),
    {
      value: 'invoice',
      label: 'Invoice'
    },
    {
      value: 'vendor',
      label: 'Vendor'
    }
  ]
  return [
    {
      name,
      type: field_type,
      description,
      isActive: active,
      isAllocatable: is_allocatable ? { value: true, label: 'Yes' } : { value: false, label: 'No' },
      isToggable: is_toggable,
      displayGroup: display_group ? { value: display_group.id, label: display_group.name } : null,
      scopes: sortAlphabeticallyByProperty(scopes, 'name').map(scope => {
        return list.find(l => l.value === scope.name)
      }),
      id
    },
    list
  ]
}

export const fromValue = value => {
  if (Array.isArray(value)) {
    return value.map(v => ({
      name: v.value
    }))
  }
  return value?.value ? { id: value.value } : value
}

export const toDisplayGroups = groups =>
  groups.map(g => ({
    value: g.id,
    label: g.name
  }))

export const toValueType = type => {
  if (type === 'boolean') {
    return 'Yes/No'
  }
  if (type === 'textarea') {
    return 'Text Area'
  }
  return capitalizeStr(type)
}
