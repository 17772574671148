import { useState, useEffect } from 'react'
import {
  Panel,
  Button,
  DataTableWrapper,
  AvatarList,
  SwitchToggle,
  useLoading
} from 'simple-core-ui'
import { PriorityModal } from './PriorityModal'
import { getNormalizedCellContent } from './utils'
import { hex2rgba, checkByNameIfInArray, openLink, isBasicTaskManagementPlan } from 'utils/helpers'
import s from './Priorities.scss'
import moment from 'moment'
import { useImmer } from 'use-immer'
import { LEVELS } from './constants'
import { Priority } from './types'
import APP_ACT from 'app/actions'
import { useDispatch } from 'react-redux'
import { makeGetRequest, makePatchRequest } from 'utils/api'
import { toPriorities, toOptions } from './serializers'
import { ActionsPopover } from './ActionsPopover'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { TaskTemplateCascadeUpdateModal } from '../common/TaskTemplateCascadeUpdateModal'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'level', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  {
    columnKey: 'name',
    content: 'Name',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'color',
    content: 'Color',
    isSortable: false,
    isFilterable: true
  },
  {
    columnKey: 'level',
    content: 'Level',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'relatedTasks',
    content: 'Related Tasks',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'relatedTemplates',
    content: 'Related Templates',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'lastEdited',
    content: 'Last Edited',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'editedBy',
    content: 'Edited By',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'isActive',
    content: (
      <>
        Active
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="infoTooltip" data-tip />
        <ReactTooltip id="infoTooltip" type="light" effect="solid" place="top" border>
          Active settings can be applied to a task
        </ReactTooltip>
      </>
    )
  }
]

const Priorities = () => {
  const [showPriorityModal, setShowPriorityModal] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [priority, setPriority] = useState({} as Priority)
  const [priorities, setPriorities] = useImmer<Priority[]>([])
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [showTaskTemplateCascadeUpdateModal, setShowTaskTemplateCascadeUpdateModal] = useState(
    false
  )

  const fetchPriorities = async () => {
    try {
      const priorities = await withLoadingLocks(
        makeGetRequest('/task-management/task-priorities/?includeClientSettings')
      )
      setPriorities(toPriorities(priorities))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchPriorities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleStatus = async (id: number, index: number) => {
    try {
      const updatedPriority = await withLoadingLocks(
        makePatchRequest(`/task-management/task-priorities/${id}/`, {
          is_active: !priorities[index].isActive
        })
      )
      setPriorities(draft => {
        draft[index] = toPriorities([updatedPriority])[0]
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task priority ${priorities[index].name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onToggleStatus = (id: number) => {
    const index = priorities.findIndex(p => p.id === id)
    const priority = priorities[index]
    setPriority(priority)
    if (priority.isActive && priority.relatedTemplates.length > 0) {
      setShowTaskTemplateCascadeUpdateModal(true)
    } else {
      toggleStatus(id, index)
    }
  }

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (columnKey === 'isActive') {
      const isActive = content !== '----'
      return (
        <SwitchToggle
          id={`priority-${rowId}`}
          dataTestId={String(rowId)}
          checked={isActive}
          onToggle={isBasicTaskManagementPlan() ? undefined : () => onToggleStatus(rowId)}
          tooltipContent="Contact customer success to upgrade."
        />
      )
    }
    if (columnKey === 'name') {
      const color = priorities.find(p => p.id === rowId)?.color
      return (
        <div
          className={s.level}
          style={{
            backgroundColor: color && hex2rgba(color, 0.15),
            border: `1px solid ${color}`
          }}
        >
          {content}
        </div>
      )
    }
    if (columnKey === 'color') {
      return <div className={s.color} style={{ backgroundColor: content }} />
    }
    if (columnKey === 'level') {
      return LEVELS[+content]
    }
    if (columnKey === 'relatedTemplates') {
      return content.length
    }
    if (columnKey === 'lastEdited') {
      return content === '----' ? '--' : moment(content).format('M/D/YYYY')
    }
    if (columnKey === 'editedBy') {
      return content === '----' ? (
        '--'
      ) : (
        <AvatarList size="md" wrapperStyles={{ width: 36 }} entries={[{ label: content }]} />
      )
    }
    return content
  }

  const togglePriorityModal = () => {
    setShowPriorityModal(!showPriorityModal)
    setShowDuplicateError(false)
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const showEditModal = (priority: Priority) => {
    togglePriorityModal()
    setPriority(priority)
  }

  const updatePriority = (value: string, property: string) => {
    setShowDuplicateError(checkByNameIfInArray(value, priorities, { id: priority.id }))
    setPriority({
      ...priority,
      [property]: value
    })
  }

  const savePriority = async () => {
    try {
      const { id, name, color } = priority
      const index = priorities.findIndex(p => p.id === id)
      const oldPriority = priorities[index]

      const data = {
        ...(oldPriority.name !== name ? { display_name: name } : {}),
        ...(oldPriority.color !== color ? { color } : {})
      }
      const updatedPriority = await withLoadingLocks(
        makePatchRequest(`/task-management/task-priorities/${id}/`, data)
      )

      setPriorities(draft => {
        draft[index] = toPriorities([updatedPriority])[0]
      })

      togglePriorityModal()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task priority ${priority.name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      togglePriorityModal()
    }
  }

  const downloadXlsx = () => {
    const { columnKey, isDesc } = params.ordering
    openLink(`/task-management/task-priorities/export/?columnKey=${columnKey}&isDesc=${+isDesc}`)
  }

  const renderCustomAction = (row: Priority) => {
    return <ActionsPopover priority={row} editPriority={showEditModal} />
  }

  return (
    <Panel
      title="Manage Task Priorities"
      subtitle="Use priorities to indicate the urgency and importance of your tasks."
      rightActions={[
        <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary>
          Download
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.Priorities}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={priorities}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        customAction={renderCustomAction}
        hasActions
        hasTooltip
        alwaysShowActions
        categoryKey="priority"
        getNormalizedCellContent={getNormalizedCellContent}
        renderCell={renderCell}
        hasActionsHeader={false}
        hasPagination={false}
        multiSort
        memoizedRowActions={false}
      />
      {showPriorityModal && (
        <PriorityModal
          savePriority={savePriority}
          priority={priority}
          updatePriority={updatePriority}
          togglePriorityModal={togglePriorityModal}
          showDuplicateError={showDuplicateError}
        />
      )}
      {showTaskTemplateCascadeUpdateModal && (
        <TaskTemplateCascadeUpdateModal
          options={toOptions(priorities)}
          entity={priority}
          callMainAction={() =>
            toggleStatus(
              priority.id,
              priorities.findIndex(p => p.id === priority.id)
            )
          }
          onCancel={() => setShowTaskTemplateCascadeUpdateModal(false)}
          refreshTable={fetchPriorities}
          label="priority"
          getPayload={id => ({
            task_ids: priority.relatedTemplates.map(t => t.taskIds).flat(),
            task_priority_id: id
          })}
        />
      )}
    </Panel>
  )
}

export default Priorities
