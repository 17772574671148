import { fromContact, fromContacts } from 'contacts/serializer'

export const fromTeam = ({
  name,
  id,
  description,
  member_count, // eslint-disable-line camelcase
  contact,
  member_contacts
}) => ({
  name,
  id,
  description,
  memberCount: member_count,
  contact: contact ? fromContact(contact) : null,
  members: fromContacts(member_contacts)
})

export const fromTeams = apiTeams => apiTeams.map(fromTeam)

export const toTeam = ({ name, description, members }) => ({
  name,
  description,
  user_ids: members.map(({ userId }) => userId)
})

export const toUsers = options => {
  return options.map(fromTeam).map(o => ({
    ...o,
    value: o.id,
    label: o.name
  }))
}

export const fromTeamsManageMeta = ({ can_edit_teams, max_team_count }) => ({
  canEditTeams: can_edit_teams,
  teamLimit: max_team_count
})
