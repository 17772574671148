import { DataTableWrapper } from 'simple-core-ui'
import { getNormalizedCellContent } from 'doc_management/settings/utils'
import s from './Categories.scss'
import { FaTrash, FaPencilAlt } from 'react-icons/fa'

const Categories = ({ params, rows, columns, updateTable, deleteCategory, showEditModal }) => {
  return (
    <section className={s.container}>
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={rows}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.categoriesTable}
        actions={[
          {
            icon: FaTrash,
            tooltip: 'Delete',
            onClick: (e, row) => deleteCategory(row)
          },
          {
            icon: FaPencilAlt,
            tooltip: 'Edit',
            onClick: (e, row) => showEditModal(row)
          }
        ]}
        categoryKey="status"
        getNormalizedCellContent={getNormalizedCellContent}
      />
    </section>
  )
}

export default Categories
