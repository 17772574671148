import s from './CustomCategory.scss'

interface Props {
  amount: number
  label: string
  isLoading: boolean
}

const CustomCategory = ({ amount, label, isLoading }: Props) => {
  return (
    <span className={s.categoryContainer}>
      <span className={s.amountContainer}>
        {!isLoading ? <span>{amount}</span> : <span style={{ padding: '0 6px' }}></span>}
      </span>
      {label}
    </span>
  )
}

export default CustomCategory
