import { useState, useEffect } from 'react'
import s from './MultiVendorSelect.scss'
import { makeGetRequest } from 'utils/api'
import { CheckboxContainer, OutsideClickContainer, Button } from 'simple-core-ui'
import { VENDOR_SELECT_URL as url } from '../urls'

import { IoIosArrowDown, IoIosSearch } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { toReactSelect } from '../serializers'
import debounce from 'debounce-promise'

const MultiVendorSelect = ({ onChange, value = [], shouldChangeOnClear }) => {
  const [vendors, setVendors] = useState([])
  const [selectedVendors, setSelectedVendors] = useState(value)
  const [menuOpen, setMenuOpen] = useState(false)
  const dispatch = useDispatch()

  const fetchVendors = async (search = '') => {
    let response
    try {
      response = await makeGetRequest(url, {
        params: {
          page: 1,
          pageSizeParam: 10,
          search_term: search
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      return {
        hasMore: false
      }
    }
    setVendors(toReactSelect(response.results))
  }

  const debouncedFetchVendors = debounce(fetchVendors, 400)

  useEffect(() => {
    fetchVendors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleCheck = option => {
    const arr = [...selectedVendors]
    if (arr.find(el => el.value === option.value)) {
      const index = arr.findIndex(e => e.value === option.value)
      arr.splice(index, 1)
      setSelectedVendors(arr)
    } else {
      setSelectedVendors([...arr, option])
    }
  }

  const onClear = () => {
    setSelectedVendors([])
    if (shouldChangeOnClear) {
      onChange([])
    }
  }

  const cancel = () => {
    setMenuOpen(false)
    setSelectedVendors(value)
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setMenuOpen(!menuOpen)
        }}
        className={s.multiVendorSelect}
      >
        <span className={s.inputLabel}>
          {selectedVendors.length
            ? `${selectedVendors.length} vendors selected`
            : 'Choose vendor to compare'}
        </span>
        <IoIosArrowDown style={{ position: 'absolute', color: '#ccc', right: 6, top: 12 }} />
      </div>
      {menuOpen && (
        <OutsideClickContainer closeComponent={cancel}>
          <div className={s.menuWrapper}>
            <IoIosSearch
              style={{ position: 'absolute', color: '#b3b3b3', top: 10, left: 38, fontSize: 18 }}
            />
            <input
              onChange={e => {
                const { value } = e.target
                debouncedFetchVendors(value)
              }}
              className={s.inputCheckbox}
              placeholder="Search"
            />
            <div className={s.description}>Select up to 10 vendors to compare</div>
            {selectedVendors.length > 0 && (
              <div className={s.selected}>{selectedVendors.length} selected</div>
            )}

            {selectedVendors.map(option => {
              return (
                <div className={s.checkboxOption} key={option.value}>
                  <CheckboxContainer
                    styles={{
                      borderColor: '#c4c4c4',
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      marginTop: 2
                    }}
                    hasGrayBorder
                    size="sm"
                    isChecked={
                      selectedVendors
                        ? !!selectedVendors.find(el => el.value === option.value)
                        : false
                    }
                    cb={() => toggleCheck(option)}
                  />
                  <label className={s.checkboxLabel}>{option.label}</label>
                </div>
              )
            })}

            {selectedVendors.length > 0 && <div className={s.divider} />}

            {vendors
              .filter(v => !selectedVendors.find(sv => v.value === sv.value))
              .slice(0, 10)
              .map(option => (
                <div className={s.checkboxOption} key={option.value}>
                  <CheckboxContainer
                    styles={{
                      borderColor: '#c4c4c4',
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      marginTop: 2
                    }}
                    hasGrayBorder
                    size="sm"
                    isChecked={
                      selectedVendors
                        ? !!selectedVendors.find(el => el.value === option.value)
                        : false
                    }
                    cb={() => toggleCheck(option)}
                  />
                  <label className={s.checkboxLabel}>{option.label}</label>
                </div>
              ))}

            <div className={s.footer}>
              <Button
                isOutline
                isPrimary
                style={{
                  height: 42,
                  fontSize: 14,
                  borderRadius: 4,
                  padding: '0 24px'
                }}
                onClick={onClear}
              >
                Clear
              </Button>

              <Button
                isPrimary
                isDisabled={!selectedVendors.length}
                style={{
                  height: 42,
                  fontSize: 14,
                  borderRadius: 4,
                  padding: '0 24px'
                }}
                onClick={() => {
                  setMenuOpen(false)
                  onChange(selectedVendors)
                }}
              >
                Compare
              </Button>
            </div>
          </div>
        </OutsideClickContainer>
      )}
    </div>
  )
}

export default MultiVendorSelect
