import isEqual from 'lodash/isEqual'
import {
  AvailableField,
  CustomAttribute,
  FieldOperand,
  Model,
  Operand
} from 'simple_review/@types/api'
import { RuleOperandLhsFuncSerializer } from './rule-operand-lhs-func.serializer'

export const lhsHasCustomAttr = (lhs: AvailableField, modelParent: AvailableField) => {
  return (
    modelParent.name === 'invoice_discount' ||
    modelParent.name === 'AnnualSpend' ||
    (modelParent.name === 'Invoice' && lhs.name === 'invoice_total') ||
    (modelParent.name === 'matter_vendor_task_code' && lhs.name === 'spend')
  )
}

export function RuleOperandLhsSerializer() {
  return {
    toJSON(lhs: AvailableField) {
      const handleLhsOperand = () => {
        if ('aiOperand' in lhs) return JSON.stringify(lhs.aiOperand)

        if (lhs.field_type === 'custom' && lhs.lhsPath) {
          const model = lhs.lhsPath[0] as AvailableField
          const attribute = lhs.lhsPath[lhs.lhsPath.length - 1] as CustomAttribute

          /**
           * Response if custom attribute has only 2 levels - e.g.: Matter -> Subpoena received
           */
          if (isEqual(model, attribute)) {
            return JSON.stringify({
              field_name: lhs.name,
              model_name: model.name,
              operand_type: 'field'
            })
          }

          /**
           * Otherwise if attribute has 3 levels - e.g.: Matter -> Country -> Id
           */
          return JSON.stringify({
            attr_id: attribute.attr_id,
            field_name: lhs.name,
            model_name: model.name,
            operand_type: 'list_custom_attr'
          })
        }

        if (lhs.isFunction) {
          let args: unknown[] = []

          if (lhs.args) {
            args = lhs.args
              .filter(arg => arg.constant)
              .map(arg => {
                const { arg_name, constant } = arg

                if (!constant) {
                  return arg
                }

                if ('model_name' in constant) {
                  return {
                    arg_name,
                    arg_type: constant.arg_type,
                    model_name: constant.model_name,
                    model_field: constant.model_field
                  }
                }

                const constantVal = Array.isArray(constant)
                  ? constant
                  : {
                      value:
                        typeof constant.value !== 'boolean'
                          ? String(constant.value)
                          : constant.value,
                      label: constant?.label
                    }

                return {
                  arg_name,
                  arg_type: 'const',
                  constant: constantVal
                }
              })
          }

          return JSON.stringify({
            func_name: lhs.name,
            args,
            operand_type: 'func'
          })
        }

        if (lhs.lhsPath) {
          const parent = lhs.lhsPath[lhs.lhsPath.length - 1] as AvailableField

          return JSON.stringify({
            field_name: lhs.name,
            model_name: parent?.name ?? '',
            operand_type: 'field'
          })
        }
      }
      return lhs ? handleLhsOperand() : ''
    },
    fromJSON(operand: Operand, fields: Array<AvailableField>) {
      const { lhs } = operand

      let field: AvailableField | null = null

      if ('model_name' in lhs) {
        if (lhs.model_name === 'AdjustmentCodeClassification') {
          return {
            name: lhs.field_name,
            display_name: lhs.field_name,
            aiOperand: lhs
          }
        }

        const parent = fields.find(field => field.name === lhs.model_name) || null
        if (!parent) return field

        const modelParent = parent as Model
        /**
         * Custom attribute logic
         */
        if (lhs.operand_type === 'list_custom_attr') {
          const customAttribute = modelParent.list_custom_attrs.find(
            field => field.attr_id === lhs.attr_id
          )
          if (!customAttribute) return null

          const field = customAttribute?.fields.find(field => field.name === lhs.field_name) as
            | AvailableField
            | undefined
          if (!field) return null

          field.lhsPath = [parent, customAttribute]
          return field
        }

        /**
         * Built-in field logic
         */
        field = modelParent.fields.find(field => field.name === lhs.field_name) || null
        if (!field) return null

        field.lhsPath = [parent]

        /**
         * Invoice discount logic (this is needed to show the slider component instead of the regular input)
         */
        if (parent.name === 'invoice_discount') {
          field.rhsType = 'slider'
        }

        if (lhsHasCustomAttr(field, modelParent)) {
          field.isCustomAttrEnabled = true
        }
      } else if ('field_name' in lhs) {
        const fieldLhs = (lhs as unknown) as FieldOperand
        field = fields.find(field => field.name === fieldLhs.field_name) || null
      } else {
        field = RuleOperandLhsFuncSerializer().fromJSON(lhs, fields)
      }

      return field
    }
  }
}
