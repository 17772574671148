import { APIRule, Rule, Option, CourtRuleEvent } from './types'

export const toRules = (events: APIRule[], prefix?: string): Rule[] => {
  return events.map(event => ({
    id: event.system_id,
    name: prefix ? `[${prefix}] ${event.short_name}` : event.short_name,
    date: event.event_date,
    description: prefix
      ? `[${prefix}] ${event.short_name}`
      : event.short_name +
        '\n\n' +
        event.date_rules.map((r: any) => r.rule_text + '\n\n') +
        event.court_rules.map((r: any) => r.rule_text + '\n\n'),
    expanded: false,
    do_not_recalculate: event.do_not_recalculate,
    is_event_docket: event.is_event_docket,
    is_on_dnc_list: event.is_on_dnc_list
  }))
}

export const fromEvent = (event: Rule) => {
  const { name, attendee_ids, date, description, reminder, id } = event

  return {
    system_id: id,
    name,
    attendee_ids: attendee_ids?.map((attendee: Option) => attendee.value) ?? [],
    start_date: date || null,
    description,
    reminder: reminder
      ? {
          amount: reminder?.value,
          time_unit: reminder?.type
        }
      : null,
    do_not_recalculate: event.do_not_recalculate,
    is_event_docket: event.is_event_docket,
    is_on_dnc_list: event.is_on_dnc_list
  }
}

export const toCourtRuleEvent = (event: CourtRuleEvent, type?: string) => ({
  id: event.id,
  name: event.name,
  description: event.description,
  date: event.start_date,
  prev_date: event.old_date,
  expanded: false,
  type,
  do_not_recalculate: event.do_not_recalculate,
  is_event_docket: event.is_event_docket,
  is_on_dnc_list: event.is_on_dnc_list
})
