import { MouseEvent, useState } from 'react'
import { format } from 'date-fns'
import cn from 'classnames'
import { DueDatePicker, OutsideClickContainer } from 'simple-core-ui'
import { SelectCustomInput } from 'common/Selects'
import s from './DatePickerSelect.scss'

interface Props {
  value: Date | undefined
  openUpwards?: boolean
  selectDate: (value: Date | undefined) => void
  isDisabled?: boolean
}

const DatePickerSelect = ({ value, openUpwards, selectDate, isDisabled }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const clearValue = (e: MouseEvent) => {
    e.stopPropagation()
    selectDate(undefined)
  }

  return (
    <OutsideClickContainer closeComponent={() => setIsExpanded(false)}>
      <section className={cn(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={value ? format(value, 'MM/dd/yyyy') : null}
          onClick={() => setIsExpanded(expanded => !expanded)}
          onClear={clearValue}
          isDisabled={isDisabled}
        />
        {isExpanded && (
          <div className={cn(s.datePickerContainer, { [s.upwardContainer]: openUpwards })}>
            <DueDatePicker
              value={value}
              onConfirm={(value: Date | undefined) => {
                selectDate(value)
                setIsExpanded(false)
              }}
            />
          </div>
        )}
      </section>
    </OutsideClickContainer>
  )
}

export default DatePickerSelect
