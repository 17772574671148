import * as React from 'react'
import moment from 'moment'
import take from 'lodash/take'
import head from 'lodash/head'
import last from 'lodash/last'
import capitalize from 'lodash/capitalize'

import { HelpText } from 'simple-core-ui'

import { REVIEW_STATUS, ENTRY_TYPE, RESPONSE_LABEL } from './constants'

export const formatMemberNames = (names, condition) =>
  ![ENTRY_TYPE.OR, ENTRY_TYPE.AND].includes(condition) ? (
    ''
  ) : names.length === 2 ? (
    <React.Fragment>
      {formatMember(head(names))} {condition} {formatMember(last(names))}
    </React.Fragment>
  ) : (
    <React.Fragment>
      {take(names, names.length - 1).map((name, idx) => (
        <React.Fragment key={idx}>{formatMember(name)}, </React.Fragment>
      ))}
      {condition} {formatMember(last(names))}
    </React.Fragment>
  )

export const formatMember = ({ name, soloUserId, teamId }) =>
  soloUserId ? (
    name
  ) : (
    <HelpText message="Only one approval from the team is needed.">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/v2/teams/?team=${teamId}`}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        {name}
      </a>
    </HelpText>
  )

export const formatReviewedBy = (scope, action, date, reviewer) => {
  const isValidStatus = [REVIEW_STATUS.APPROVED, REVIEW_STATUS.REJECTED].includes(action)

  const reviewAction = capitalize(RESPONSE_LABEL(scope, action))

  const reviewedBy = reviewer ? `by ${reviewer}, ` : ''

  const THREE_DAYS_AGO = moment()
    .clone()
    .subtract(3, 'days')
    .startOf('day')
  const reviewDate = moment(date)
  const reviewedOn = reviewDate.isAfter(THREE_DAYS_AGO, 'd')
    ? reviewDate.fromNow()
    : reviewDate.format('MMM D YYYY')

  return isValidStatus ? `${reviewAction} ${reviewedBy}${reviewedOn}` : ''
}
