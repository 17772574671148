import * as React from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import { If, Spinner } from 'simple-core-ui'

import ACT from '../actions'

import { NamedGroups } from 'components'
import { spinnerStyles } from 'styles/cssInJs'

import ReviewerTiersContainer from './ReviewerTiersContainer'
import SubscribersContainer from './SubscribersContainer'

import ScopeContext from 'context/ScopeContext'
import { REVIEWER_SCOPE_LABEL } from 'reviews/constants'

@connect(({ reviews: { reviewerConfig } }, { reviewerScope, reviewerConfigId }) => {
  const configId = reviewerConfigId || reviewerConfig[reviewerScope].reviewerConfigId

  return {
    ...reviewerConfig[reviewerScope],
    reviewerConfigId: configId
  }
})
@withFetchFlow({
  render: () => null,
  flag: 'ReviewerConfig',
  getFetchAction: ({ reviewerConfigId, reviewerScope }) =>
    reviewerConfigId
      ? {
          type: ACT.INITIALIZE_REVIEWER_CONFIG_REQUESTED,
          payload: { reviewerConfigId, reviewerScope },
          loadingStateDisabled: true
        }
      : { type: ACT.NOOP, payload: reviewerScope }
})
class ReviewerConfigContainer extends React.Component {
  reviewerScopeLabel = REVIEWER_SCOPE_LABEL[this.props.reviewerScope]

  render() {
    const {
      reviewerTiers,
      subscribers,
      isLeadReviewer,
      reviewerConfigId,
      isInitializing,
      dispatch,
      reviewerScope
    } = this.props

    const readOnly =
      window.serverContext.get('has_edit_capability') === false || this.props.readOnly

    return (
      <If condition={!isInitializing} fallback={<Spinner style={spinnerStyles} />}>
        <ScopeContext.Consumer>
          {({ scope, scopeId }) => (
            <NamedGroups
              groups={[
                {
                  name: `${this.reviewerScopeLabel} Reviewers`,
                  content: (
                    <ReviewerTiersContainer
                      reviewerTiers={reviewerTiers}
                      subscribers={subscribers}
                      isLeadReviewer={isLeadReviewer}
                      reviewerConfigId={reviewerConfigId}
                      readOnly={readOnly}
                      dispatch={dispatch}
                      scope={scope}
                      scopeId={scopeId}
                      reviewerScope={reviewerScope}
                    />
                  )
                },
                {
                  name: `${this.reviewerScopeLabel} Subscribers`,
                  content: (
                    <SubscribersContainer
                      reviewerTiers={reviewerTiers}
                      subscribers={subscribers}
                      isLeadReviewer={isLeadReviewer}
                      reviewerConfigId={reviewerConfigId}
                      readOnly={readOnly}
                      dispatch={dispatch}
                      scope={scope}
                      scopeId={scopeId}
                      reviewerScope={reviewerScope}
                    />
                  )
                }
              ]}
            />
          )}
        </ScopeContext.Consumer>
      </If>
    )
  }
}

export default ReviewerConfigContainer
