import { useSelector } from 'react-redux'

const BudgetUnconvertedAlert = () => {
  const { budget_currency, unconverted_spend } = useSelector(({ budgets }) => budgets.budgetDetails)
  return unconverted_spend ? (
    <div className="alert alert-warning">
      Fetching additional exchange rates for {unconverted_spend} {budget_currency}. Please refresh
      the page.
    </div>
  ) : null
}

export default BudgetUnconvertedAlert
