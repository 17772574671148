import { OutsideClickContainer, Ellipsis, useWithPopper } from 'simple-core-ui'
import {
  cloneElement,
  ReactElement,
  ReactNode,
  JSXElementConstructor,
  CSSProperties,
  useEffect,
  useRef
} from 'react'
import ReactTooltip from 'react-tooltip'
import s from './Widget.scss'
import cn from 'classnames'
import { Placement } from '@floating-ui/react'
import shortid from 'shortid'

interface Props {
  icon: ReactElement
  value: string | undefined | ReactElement | null
  children: ReactElement<any, string | JSXElementConstructor<any>>
  label?: ReactNode
  size?: 'sm' | 'md'
  style?: CSSProperties
  iconClassName?: string
  readOnly?: boolean
  placement?: Placement
  portal?: boolean
  hideIcon?: boolean
  contentClassName?: string
  isDisabled?: boolean
  hasTooltip?: boolean
  tooltipText?: string
}

const Widget = ({
  value,
  children,
  label,
  icon,
  size = 'md',
  style,
  iconClassName,
  readOnly,
  placement = 'bottom-start',
  portal = false,
  hideIcon = false,
  contentClassName,
  isDisabled,
  hasTooltip = false,
  tooltipText = 'Please contact customer support to enable this feature'
}: Props) => {
  const { isPopperOpen, togglePopper, setReference, setFloating, floatingStyles } = useWithPopper({
    placement,
    strategy: portal ? 'fixed' : 'absolute'
  })

  const idRef = useRef(shortid.generate())

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [value])

  const _togglePopper = () => {
    if (readOnly) return
    togglePopper()
  }

  return (
    <>
      <div
        data-for={`${label || idRef.current}-widgetTooltip`}
        data-tip
        style={style}
        className={cn(s.widget, {
          [s.small]: size === 'sm'
        })}
      >
        <div
          ref={setReference}
          className={cn(s.icon, iconClassName && iconClassName, {
            [s.active]: isPopperOpen || !!value,
            [s.readOnly]: readOnly,
            [s.disabled]: isDisabled
          })}
          onClick={_togglePopper}
          style={hideIcon ? { visibility: 'hidden' } : {}}
        >
          {icon}
        </div>
        <div className={cn(s.content, contentClassName)}>
          {label && (
            <span
              className={cn(s.label, {
                [s.labelActive]: !!value
              })}
            >
              {label}
            </span>
          )}
          {value && (
            <span onClick={_togglePopper} className={s.value}>
              {typeof value === 'string' ? (
                <Ellipsis width={200} lines={1}>
                  {value}
                </Ellipsis>
              ) : (
                value
              )}
            </span>
          )}
        </div>
        {isPopperOpen && (
          <OutsideClickContainer styles={{ height: 'initial' }} closeComponent={togglePopper}>
            <div tabIndex={-1} style={floatingStyles} ref={setFloating} className={s.popper}>
              {cloneElement(children, { togglePopper })}
            </div>
          </OutsideClickContainer>
        )}
      </div>
      {hasTooltip && (
        <ReactTooltip
          id={`${label || idRef.current}-widgetTooltip`}
          type="light"
          effect="solid"
          place="top"
          border
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </>
  )
}

export default Widget
