import { thermometerColors } from 'styles/cssInJs'
import s from '../styles/BHPill.scss'

const BHPill = ({ isInBudget }) => {
  const colors = isInBudget ? thermometerColors['IN_BUDGET'] : thermometerColors['OVERBUDGET']
  return (
    <div
      className={s.pill}
      style={{
        backgroundColor: colors.ACCRUAL,
        color: colors.DOT
      }}
    >
      <div className={s.dot} style={{ backgroundColor: colors.DOT }}></div>
      <div data-testid="budget_health_status">{isInBudget ? 'WITHIN' : 'OVER'}</div>
    </div>
  )
}

export default BHPill
