import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { containsObject } from 'utils/helpers'
import {
  getAttributeValue,
  getCurrencyAttributeValue,
  isDifferentAttributeValue,
  toDecimalRepresentation,
  removeAttributeOptionsAndDefaultOptions
} from './utils'

const updateAttributeValue = attribute => {
  let attributeClone = cloneDeep(attribute)
  if (attributeClone.type === 'select' || attributeClone.type === 'boolean') {
    attributeClone.value = getAttributeValue(attributeClone)
  }
  if (attributeClone.type === 'currency') {
    attributeClone.value = getCurrencyAttributeValue(attributeClone)
  }
  if (['percentage', 'decimal'].includes(attributeClone.type) && attributeClone.value) {
    attributeClone.value = toDecimalRepresentation(attributeClone.value)
  }

  return attributeClone.value
}

export const toMatterTemplateJson = (template, includeOverwritten = false) => {
  const categoryArr = ['left', 'right']
  let templateClone = cloneDeep(template)
  categoryArr.forEach(category => {
    templateClone[category].forEach(section => {
      section.attributes.forEach(attribute => {
        // ugly fix for https://simplelegal.atlassian.net/browse/MV-1928
        removeAttributeOptionsAndDefaultOptions(attribute)
        attribute.value = updateAttributeValue(attribute)
        if (attribute.value === -1) {
          attribute.value = String(attribute.value)
        }
        if (attribute.class) delete attribute.class
        if (!includeOverwritten) {
          delete attribute.overwritten
        }
      })
    })
  })

  return templateClone
}

export const toDropdownJson = (list, opts) => {
  return list.map(item => ({
    value: item[opts.value],
    label: item[opts.label]
  }))
}

/* @param {object} template
  @param {array} selectedValues - array of attributes coming from a matter request
  @param {array} mrValuesForTemplate - array of attributes with correct values that should overwrite template values
  output {object}: {templateJson: {...}, additionalAttributes: [...]}
  templateJson {object} is the new template with the corrected values
  additionalAttributes {array} - array of attributes present in the matter request and not present in the template
*/
export const fromMatterTemplatePreviewJson = (template, selectedValues, mrValuesForTemplate) => {
  const categoryArr = ['left', 'right']
  let templateClone = cloneDeep(template)
  let selectedValuesClone = cloneDeep(selectedValues)
  let mrValuesForTemplateClone = cloneDeep(mrValuesForTemplate)

  const findIndexOfAttr = (array, attribute) => {
    return array.findIndex(value => {
      if (
        (attribute.type === 'relationship' && value.type !== 'relationship') ||
        (attribute.type !== 'relationship' && value.type === 'relationship')
      ) {
        return false
      }
      if (isNaN(value.id)) {
        return attribute.id === value.id
      }
      return attribute.id === Number(value.id)
    })
  }

  categoryArr.forEach(category => {
    templateClone[category].forEach(section => {
      section.attributes.forEach(attribute => {
        const selectedIndex = findIndexOfAttr(selectedValuesClone, attribute)
        const mrValuesIndex = findIndexOfAttr(mrValuesForTemplateClone, attribute)
        if (attribute.type === 'relationship') {
          attribute.attributes.forEach(attribute => {
            attribute.defaultOptions = attribute.options
          })

          selectedValuesClone
            .filter(attr => attr.class === 'RelAttrs')
            .forEach(attr => {
              if (+attr.id === +attribute.id) {
                attribute.attributes.forEach((a, i) => {
                  if (a.value?.value !== attr.attributes[i].value?.value) {
                    Object.assign(a, {
                      value: attr.attributes[i].value
                    })
                    attribute.overwritten = true
                  }
                })
              }
            })
        }

        if (~mrValuesIndex) {
          if (
            isDifferentAttributeValue(
              attribute.value,
              mrValuesForTemplateClone[mrValuesIndex].value,
              attribute.type
            )
          ) {
            if (
              attribute.type === 'select' &&
              attribute.options &&
              typeof mrValuesForTemplateClone[mrValuesIndex].value === 'object' &&
              !containsObject(mrValuesForTemplateClone[mrValuesIndex].value, attribute.options)
            ) {
              attribute.options.push(mrValuesForTemplateClone[mrValuesIndex].value)
            }
            Object.assign(attribute, {
              value: mrValuesForTemplateClone[mrValuesIndex].value,
              overwritten: true
            })
          }
        } else {
          if (attribute.type === 'select') {
            Object.assign(attribute, {
              value: { value: '-1', label: 'None' },
              overwritten: true
            })
          }
        }

        if (~selectedIndex) {
          selectedValuesClone.splice(selectedIndex, 1)
        }
      })
    })
  })

  return {
    templateJson: templateClone,
    additionalAttributes: selectedValuesClone
  }
}

export const removeTrailingZerosAndFormatValues = template => {
  const categoryArr = ['left', 'right']
  let templateClone = cloneDeep(template)
  categoryArr.forEach(category => {
    templateClone[category].forEach(section => {
      section.attributes.forEach(attribute => {
        if (['percentage', 'decimal'].includes(attribute.type) && attribute.value) {
          attribute.value = toDecimalRepresentation(attribute.value)
        }
        if (['boolean', 'select'].includes(attribute.type) && attribute.value) {
          attribute.value = toValue(attribute.value, attribute.options)
        }
      })
    })
  })

  return templateClone
}

const toValue = (value, options) => {
  return typeof value !== 'object' ? options.find(v => +v.value === +value) : value
}

export const emptyValuesTemplate = template => {
  const categoryArr = ['left', 'right']
  let templateClone = cloneDeep(template)
  categoryArr.forEach(category => {
    templateClone[category].forEach(section => {
      section.attributes.forEach(attribute => {
        attribute.value = ''
      })
    })
  })

  return templateClone
}

export const toAllocationsJson = allocations => {
  allocations.forEach(allocation => {
    allocation.columns.forEach(column => {
      if (!column.value) {
        if (column.id === 'percentage') {
          column.value = 0
        } else {
          column.value = {
            value: -1,
            label: 'None'
          }
        }
      }
    })
  })

  return allocations
}

export const fromAllocationsJson = (allocations, attributes) => {
  if (!allocations.length) return allocations

  attributes
    .filter(attribute => attribute.id)
    .forEach(attribute => {
      allocations.forEach(allocation => {
        set(allocation, 'columns[0].value', +get(allocation, 'columns[0].value').toFixed(2))
        const index = allocation.columns.findIndex(column => attribute.id === column.id)
        if (!~index) {
          allocation.columns.push({
            id: attribute.id,
            sort_order: attribute.sort_order + 1,
            value: {
              value: -1,
              label: 'None'
            }
          })
        }

        allocation.columns.sort((a, b) => a.sort_order - b.sort_order)
      })
    })

  return allocations
}

export const toMatterTemplateWithEvaluatorsJson = (template, evaluators) => {
  let templateClone = cloneDeep(template)
  let evaluatorsClone = cloneDeep(evaluators)

  templateClone.survey_config.status = evaluatorsClone.status
  templateClone.survey_config.users = evaluatorsClone.users
  templateClone.survey_config.roles = evaluatorsClone.roles

  return templateClone
}

export const toDynamicDropdowns = relationships => {
  return {
    attributes: relationships.attributes.reduce((acc, val) => {
      acc.push({
        ...val,
        attributes: val.attributes.map(attr => ({
          ...attr,
          defaultOptions: attr.options,
          readOnly: false,
          required: false,
          value: '',
          type: 'select',
          requiredByClient: attr.requiredByClient
        })),
        type: 'relationship'
      })

      return acc
    }, []),
    displayExpanded: true,
    sectionName: 'Relationships'
  }
}

export const toReactSelect = options => options.map(o => ({ value: o.id, label: o.text }))
