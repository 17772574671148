import classNames from 'classnames'
import { IoMdClose, IoMdCheckmark } from 'react-icons/io'

import { REVIEW_STATUS } from '../utils/constants'

import s from '../styles/ReviewLadder.scss'

export const ApprovedIcon = () => <IoMdCheckmark data-testid={'approved-icon'} />
export const RejectedIcon = () => <IoMdClose data-testid={'rejected-icon'} />

const ReviewStatus = ({ status, isPartiallyApproved }) => (
  <span
    className={classNames(s.statusIcon, s[status], { [s['partial']]: isPartiallyApproved })}
    data-testid="review-status"
  >
    {
      {
        [REVIEW_STATUS.APPROVED]: <ApprovedIcon />,
        [REVIEW_STATUS.REJECTED]: <RejectedIcon />,
        [REVIEW_STATUS.PENDING]: ''
      }[status]
    }
  </span>
)

export default ReviewStatus
