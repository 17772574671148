import { Textarea } from 'components'
import s from '../styles/VendorEval.scss'

const FinalComment = ({ isClosed, finalComment, changeEvalAttribute, isSmallDevice }) => {
  return (
    <section className={s.finalCommentContainer}>
      <p className={s.additionalFeedback}>Additional Feedback</p>
      <Textarea
        onChange={c => changeEvalAttribute('finalComment', c)}
        value={finalComment}
        placeholder={'Any final thoughts or comments?'}
        isDisabled={isClosed}
        withCgStyling={isSmallDevice}
      />
    </section>
  )
}

export default FinalComment
