import s from '../styles/BudgetRequest.scss'
import { Modal } from 'simple-core-ui'

const RejectionModal = ({ show, onClose, onReject, changeRejectNote }) => {
  return (
    <Modal
      size="sm"
      cancelCb={onClose}
      isVisible={show}
      title="Reject Proposal"
      confirmText="Submit"
      cancelText="Cancel"
      confirmCb={onReject}
      content={
        <div className={s.rejectForm}>
          <h3>Add a note to let the vendor know why you rejected this proposal:</h3>
          <textarea data-testid="reject_message" onChange={changeRejectNote} />
        </div>
      }
    />
  )
}

export default RejectionModal
