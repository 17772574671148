import Referrals from './Referrals'
import { Surveys } from './Surveys'
import { hasModule } from 'utils/helpers'

const VendorsTab = ({ scopeId, surveysStatus, matterName, readOnly, hasPurchaseOrders }) => {
  return (
    <>
      <Referrals
        matterId={scopeId}
        matterName={matterName}
        readOnly={readOnly}
        hasPurchaseOrders={hasPurchaseOrders}
      />
      {surveysStatus !== 'hidden' && hasModule('has_vendor_surveys') && (
        <Surveys matterId={scopeId} readOnly={readOnly} />
      )}
    </>
  )
}

export default VendorsTab
