import { Modal, EditableFieldFilterContainer, Tooltip } from 'simple-core-ui'
import { IoIosAlert, IoIosFunnel } from 'react-icons/io'
import s from './RequestAttributeFilter.scss'
import { useMatterGroupLabels, useEntityLabels } from 'hooks/selectors'
import { FaInfoCircle } from 'react-icons/fa'

const RequestAttributeFilter = ({
  toggleFilter,
  filterVisible,
  allOptions,
  filteredOptions,
  inactiveOptions,
  updateSelectedOptions,
  saveFilter,
  isConfirmDisabled
}) => {
  const entityLabels = useEntityLabels()
  const matterGroupLabels = useMatterGroupLabels()

  const optionFormat = option => {
    const { filtered, label, value, filtered_by } = option
    if (!filtered) return label
    const overlayBaseText = 'Attribute will only show for '
    let overlayText =
      filtered_by['matter-group'].length &&
      overlayBaseText +
        `${matterGroupLabels[0].toLowerCase()} ${filtered_by['matter-group']
          .map(p => p.name)
          .join(', ')}`

    filtered_by['matter-group'].length &&
      filtered_by['legal-entity'].length &&
      (overlayText += ' and ')

    filtered_by['legal-entity'].length &&
      (overlayText += `${entityLabels[0].toLowerCase()} ${filtered_by['legal-entity']
        .map(l => l.name)
        .join(', ')}`)
    return (
      <>
        {label}
        <Tooltip
          trigger={<FaInfoCircle className={s.infoIcon} />}
          content={overlayText}
          placement="top"
        />
      </>
    )
  }

  return (
    <div>
      <div className={s.filterToggleContainer}>
        <IoIosFunnel onClick={toggleFilter} />
      </div>
      {filterVisible && (
        <Modal
          size="lg"
          title="Filter values"
          saveBtnText="Save"
          closeBtnText="Close"
          isVisible={filterVisible}
          confirmCb={saveFilter}
          cancelCb={toggleFilter}
          isDisabled={isConfirmDisabled}
          content={
            <section className={s.modalContent}>
              {inactiveOptions && !!inactiveOptions.length && (
                <div className={s.inactiveContainer}>
                  <IoIosAlert />
                  <p>
                    The following attribute values have been marked not active:{' '}
                    {inactiveOptions.map(opt => opt.label).join(',')}
                  </p>
                </div>
              )}
              <p>
                The values in the right pane are currently selected. Use the arrows to add or remove
                any values you want to use.
              </p>
              <EditableFieldFilterContainer
                allOptions={allOptions}
                selectedOptions={filteredOptions}
                updateSelectedOptions={updateSelectedOptions}
                optionFormat={optionFormat}
              />
            </section>
          }
        />
      )}
    </div>
  )
}

export default RequestAttributeFilter
