import { Component } from 'react'
import ACT from 'matters/requests/create/actions'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import ThemeContext from 'context/ThemeContext'
import { Content } from './Content'
import cloneDeep from 'lodash/cloneDeep'
import { itemValueChecker } from 'matters/requests/create/utils'
import swal from 'sweetalert'
import { hasDupFileNames } from 'common/FileUpload/utils'

@withRouter
@connect(({ requestForm, request }) => {
  const { name, selectedTemplates, loading, relationships } = requestForm
  const templates = cloneDeep(selectedTemplates).map(template => template.id)

  return {
    name,
    templates,
    loading,
    request,
    relationships
  }
})
class ContentContainer extends Component {
  state = {
    files: []
  }
  changeRequestItemValue = ({
    value,
    fieldUniqueName,
    relIndex,
    relId,
    relAttributes,
    isPracticeArea
  }) => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_SET_ITEM_VALUE,
      payload: {
        value,
        fieldUniqueName,
        relIndex
      }
    })

    if (isPracticeArea) {
      this.props.dispatch({
        type: ACT.LEGAL_REQUEST_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
        payload: {
          matterGroupId: value.value
        }
      })
    }

    // on changing the value of an attribute from a dynamic dropdown fetch the paired values for next level
    if (relIndex !== undefined) {
      this.fetchDynamicAttributePairedValues({
        value,
        relIndex,
        relId,
        relAttributes
      })
    }
  }
  fetchDynamicAttributePairedValues = params => {
    // check if the relationship has a next level
    const { value, relIndex, relId, relAttributes } = params

    const levels = relAttributes.length
    const hasNextLevel = relIndex !== levels - 1

    if (value && value?.value !== -1) {
      if (hasNextLevel) {
        this.props.dispatch({
          type: ACT.CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
          payload: params
        })
      }
    } else {
      this.props.dispatch({
        type: ACT.CREATE_LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
        payload: {
          relIndex,
          relId
        }
      })
    }
  }
  resetState = () => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_RESET_STATE
    })
    this.props.dispatch({
      type: ACT.SET_LOADING_STATE
    })
  }
  cancelLegalRequest = () => {
    this.resetState()
    this.props.router.navigate('/v2/matters/requests/list')
  }
  validateFields = (request, cb) => {
    let error
    //required fields validation
    request.items.forEach(item => {
      const { value, fieldUniqueName, required } = item
      if (required) {
        if (item.fieldType === 'relationship') {
          if (item.attributes.length) {
            error = item.attributes.some(attr => !attr.value)
          }
        } else {
          if (!itemValueChecker(value, true)) {
            error = true
          }
        }
        this.props.dispatch({
          type: ACT.LEGAL_REQUEST_SET_ITEM_VALUE,
          payload: {
            value,
            fieldUniqueName
          }
        })
      }
    })

    if (error) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'All required fields must have a value',
          level: 'error'
        }
      })
      return
    }

    //other validations
    const currencyErrorField = request.items.find(item => {
      if (item.fieldType === 'currency' && item.value) {
        return (item.value.amount && !item.value.code) || (item.value.code && !item.value.amount)
      }
    })
    if (currencyErrorField) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Please select a currency ${currencyErrorField.value.amount ? 'code' : 'amount'}`,
          level: 'error'
        }
      })
      return
    }
    cb()
    this.resetState()
  }
  createLegalRequest = () => {
    const { request, router } = this.props
    const { navigate } = router
    const { files } = this.state

    this.validateFields(request, () => {
      this.props.dispatch({
        type: ACT.LEGAL_REQUEST_CREATE_REQUESTED,
        payload: {
          request,
          navigate,
          files
        }
      })
    })
  }

  upload = e => {
    const uploadedFiles = e.target.files
    if (uploadedFiles.length) {
      const hasDuplicates = hasDupFileNames(Array.from(uploadedFiles))
      if (hasDuplicates) {
        swal({
          title: 'Duplicate files detected',
          text: 'Please reselect without including files that have duplicate names.'
        })
        e.target.value = ''
        return
      }

      for (let i = 0; i < uploadedFiles.length; i++) {
        let fr = new FileReader()
        fr.onload = () => {
          this.setState({
            files: [...this.state.files, uploadedFiles[i]]
          })
        }
        fr.readAsDataURL(uploadedFiles[i])
      }
    }
  }
  render() {
    const { name, request, opened, loading, relationships } = this.props
    const { items } = request

    return (
      <ThemeContext.Consumer>
        {({ state }) => (
          <Content
            formName={name}
            items={items}
            changeRequestItemValue={this.changeRequestItemValue}
            cancelLegalRequest={this.cancelLegalRequest}
            createLegalRequest={this.createLegalRequest}
            opened={opened}
            isMenuExpanded={state.isMenuExpanded}
            upload={this.upload}
            loading={loading}
            relationships={relationships}
          />
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default ContentContainer
