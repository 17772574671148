export const absolutePosition = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
}

export const absolutelyCentered = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

export const flexyCentered = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const fullSize = {
  height: '100%',
  width: '100%'
}

export const slColors = {
  white: '#fff',
  offwhite: '#f5f5f5',
  red: '#fa2735',
  blue: '#36a9e1',
  darkerBlue: '#1e8fc6',
  grey: '#dddddd'
}

export const thermometerColors = {
  IN_BUDGET: {
    DOT: '#033A7D',
    APPROVED: '#0B80FE',
    PENDING: '#8DC4FF',
    ACCRUAL: '#C0DFFF',
    REMAINING: '#E7E7E7'
  },
  OVERBUDGET: {
    DOT: '#A91735',
    APPROVED: '#C72D4E',
    PENDING: '#FF8089',
    ACCRUAL: '#FBCED7',
    REMAINING: '#E7E7E7'
  },
  INACTIVE_RULE: {
    TEXT: '#0B3688',
    BACKGROUND: '#CCDCFA'
  },
  ACTIVE_RULE: {
    TEXT: '#04672E',
    BACKGROUND: '#D2ECD9'
  }
}

export const requestLegendColors = {
  REQUEST_STATUS: {
    PENDING: '#DDDDDD',
    APPROVED: '#58C127',
    REJECTED: '#D0021B'
  },
  MATTER_STATUS: {
    DRAFT: '#DDDDDD',
    IN_PROGRESS: '#008ECA',
    COMPLETE: '#222222'
  }
}

export const notificationStyles = {
  Containers: {
    tc: {
      padding: 'inherit',
      paddingTop: 0,
      margin: 0,
      marginLeft: 0,
      overflow: 'hidden',
      width: 'calc(100% - 50px)',
      top: '50px',
      left: '25px'
    }
  },
  NotificationItem: {
    DefaultStyle: {
      backgroundColor: '#fff'
    },
    error: {
      backgroundColor: '#f2dede',
      borderColor: '#ebccd1',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none'
    },
    warning: {
      backgroundColor: '#fcf8e3',
      borderColor: '#fbeed5',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none'
    },
    success: {
      backgroundColor: '#dff0d8',
      borderColor: '#d6e9c6',
      borderWidth: '1px',
      borderStyle: 'solid',
      boxShadow: 'none'
    }
  },
  Title: {
    DefaultStyle: {
      position: 'relative',
      paddingRight: '1em',
      marginBottom: '0.75em',
      lineHeight: 'initial'
    }
  },
  Dismiss: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      color: '#000',
      fontSize: '32px',
      fontWeight: '400',
      fontFamily: 'inherit',
      opacity: '0.4',
      transition: 'opacity 0.44s',
      top: '10px',
      right: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}

export const spinnerStyles = {
  display: 'flex',
  fontSize: '20px',
  width: '100%',
  height: '75px',
  margin: '0 auto'
}
