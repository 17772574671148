import { CSSProperties, ReactNode } from 'react'
// @ts-expect-error
import Board from 'react-trello'

interface Card {
  id: string
  [key: string]: unknown
}

interface Lane {
  id: string
  title: string
  cards: Card[]
  style?: CSSProperties
}

interface LaneHeaderProps extends Lane {
  [key: string]: unknown
}

interface CardProps extends Card {
  [key: string]: unknown
}
interface Props {
  data: {
    lanes: Lane[]
  }
  components?: {
    LaneHeader?: (props: LaneHeaderProps) => ReactNode
    Card?: (props: CardProps) => ReactNode
    AddCardLink?: () => ReactNode
  }
  onDragEnd?: (
    targetLaneId: number,
    id: string | number,
    sourceLaneId: number,
    position: number
  ) => void
  onLaneScroll?: (requestedPage: number, laneId: number) => void
  cardDragClass?: string
  draggable?: boolean
}

const dragStart = () => {
  const board = document.getElementsByClassName('react-trello-board')[0]
  board.classList.add('dragging')
}

const dragEnd = () => {
  const board = document.getElementsByClassName('react-trello-board')[0]
  board.classList.remove('dragging')
}

const KanbanBoard = ({
  data,
  components,
  onDragEnd,
  onLaneScroll,
  cardDragClass,
  draggable = true
}: Props) => {
  return (
    <div onClick={dragEnd}>
      <Board
        data={data}
        draggable={draggable}
        laneDraggable={false}
        handleDragStart={dragStart}
        handleDragEnd={(
          _cardId: number,
          sourceLaneId: number,
          targetLaneId: number,
          position: number,
          cardDetails: Card
        ) => {
          dragEnd()
          if (sourceLaneId === targetLaneId) return false
          onDragEnd && onDragEnd(targetLaneId, cardDetails.id, sourceLaneId, position)
        }}
        onLaneScroll={onLaneScroll}
        components={components}
        cardDragClass={cardDragClass}
      />
    </div>
  )
}

export default KanbanBoard
