import { LoadingSkeleton } from 'simple-core-ui'
import s from './MatterDetailContainer.scss'

export default function HeaderLoadingSkeleton() {
  return (
    <div className={s.loadingSkeleton}>
      <div style={{ display: 'flex' }}>
        <LoadingSkeleton width={200} height={24} style={{ marginRight: 12 }} />
        <LoadingSkeleton width={50} height={24} style={{ borderRadius: '1em' }} />
      </div>
      <LoadingSkeleton width={80} height={24} />
    </div>
  )
}
