import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { isBasicTaskManagementPlan } from 'utils/helpers'
import { Priority } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  priority: Priority
  editPriority: (row: Priority) => void
}

const ActionsPopover = ({ priority, editPriority }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      disabled: isBasicTaskManagementPlan(),
      tip: isBasicTaskManagementPlan() ? 'Contact customer success to upgrade.' : undefined,
      onClick() {
        if (isBasicTaskManagementPlan()) return
        editPriority(priority)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${priority.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
