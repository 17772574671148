import { Input, Button, withConfirmation } from 'simple-core-ui'
import { useSelector, useDispatch } from 'react-redux'
import ACT from 'budgets/actions'
import { curr2Num4Input } from 'budgets/reducer'
import { currency2Num } from 'utils/formatting'
import cn from 'classnames'
import s from '../styles/BudgetAmounts.scss'
import { BADiff } from './ReforecastDiff'

const cancelConf = {
  title: 'Cancel',
  text: 'Are you sure you want to cancel? Your changes will be lost.'
}

const saveConf = {
  title: 'Save',
  text: 'Are you sure you want to save your changes?'
}

const clearConf = {
  title: 'Clear',
  text: 'Are you sure you want to clear all values in the Allocated column?'
}

const revertConf = {
  title: 'Revert',
  text:
    'Are you sure you want to revert values in the Allocated column to the perviously saved amounts?'
}

const AmountsHeader = () => {
  const dispatch = useDispatch()
  const { isReforecasting, rfBudgetDetails, budgetDetails, canEdit } = useSelector(
    ({ budgets }) => budgets
  )
  const details = isReforecasting ? rfBudgetDetails : budgetDetails
  const { type, amount, allocated_total, unallocated } = details

  const isDiff = isReforecasting && rfBudgetDetails.amount !== curr2Num4Input(budgetDetails.amount)

  const cancel = withConfirmation(() => {
    dispatch({ type: ACT.CANCEL_REFORECAST })
  }, cancelConf)

  const save = withConfirmation(() => {
    dispatch({
      type: ACT.SAVE_REFORECAST_REQUESTED,
      loadingLock: 'on',
      payload: { details: rfBudgetDetails }
    })
  }, saveConf)

  const revert = withConfirmation(() => {
    dispatch({ type: ACT.REVERT_RF_BUDGET })
  }, revertConf)

  const clear = withConfirmation(() => {
    dispatch({ type: ACT.CLEAR_RF_BUDGET })
  }, clearConf)

  const actions = isReforecasting
    ? [
        <Button isSecondary key={2} onClick={cancel}>
          Cancel
        </Button>,
        <Button key={3} onClick={save} testid="save_amount_button">
          Save Values
        </Button>
      ]
    : canEdit
    ? [
        <Button
          testid="edit-values"
          key={2}
          onClick={() => {
            dispatch({ type: ACT.START_REFORECAST })
          }}
        >
          Edit Values
        </Button>
      ]
    : []

  return (
    <section className={s.amountsHeader}>
      <div className={s.actions}>{actions}</div>
      <div className={s.topLevelAmounts}>
        <div className={s.leftSide}>
          <div className={s.kpi}>
            <div className={s.kpiHeader}>Budget Amount</div>
            <div className={s.kpiAmount}>
              {isReforecasting ? (
                <Input
                  text={amount}
                  testid="edit_budget_amount_input"
                  type="number"
                  cssClass={cn(s.tableInput, {
                    highlight: isDiff
                  })}
                  style={{ width: 50, fontSize: '16px' }}
                  onChangeCb={e =>
                    dispatch({
                      type: ACT.UPDATE_RF_BUDGET_AMOUNT,
                      payload: {
                        value: e.target.value === '' ? e.target.value : Number(e.target.value)
                      }
                    })
                  }
                />
              ) : (
                <>
                  <BADiff columnKey="amount" />
                  {amount}
                </>
              )}
            </div>
          </div>
          <div className={s.kpi}>
            <div className={s.kpiHeader}>Allocated</div>
            <div className={s.kpiAmount}>
              <>
                <BADiff columnKey="allocated_total" />
                {allocated_total}
              </>
            </div>
          </div>
          <div className={s.kpi}>
            <div className={s.kpiHeader}>
              {currency2Num(unallocated) < 0 ? 'Overallocated' : 'Unallocated'}
            </div>
            <div className={s.kpiAmount}>{unallocated}</div>
          </div>
        </div>
        {isReforecasting && (
          <div className={s.rightSide}>
            <div className={s.reset} onClick={revert}>
              Revert
            </div>
            <div className={s.reset} onClick={clear}>
              Clear
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default AmountsHeader
