import { Fragment } from 'react'
import { If, Tooltip } from 'simple-core-ui'
import { EditableField } from 'components'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import s from './ContentItem.scss'
import { isDynamicDropdownDisabled } from 'matters/templates/utils'

const ContentItem = ({ item, changeRequestItemValue, relationships }) => {
  if (item.fieldType === 'relationship') {
    const relationship = relationships.find(r => r.value === item.model)
    return (
      <div className={s.itemWrapper}>
        <div className={s.labelContainer} />
        <div className={s.dynamicAttributeWrapper}>
          <DynamicAttribute
            dynamicAttributeClass={s.dynamicAttribute}
            threeLevelsClass={s.threeLevels}
            twoLevelsClass={s.twoLevels}
          >
            {item.attributes.map((attr, relIndex) => (
              <div className={s.dynamicAttribute} key={attr.model}>
                <span className={s.label}>
                  {attr.label} {item.required && <span className={s.redAsterix}>*</span>}
                </span>
                <EditableField
                  type={attr.fieldType}
                  options={
                    attr.options ||
                    (relationship?.attributes?.length
                      ? relationship.attributes[relIndex].options
                      : [])
                  }
                  value={attr.value}
                  disabled={isDynamicDropdownDisabled(item, relIndex)}
                  onChange={value => {
                    changeRequestItemValue({
                      fieldUniqueName: item.fieldUniqueName,
                      value,
                      relIndex,
                      relId: item.model.split('RelAttrs__')[1],
                      relAttributes: item.attributes
                    })
                  }}
                />
              </div>
            ))}
          </DynamicAttribute>
        </div>
      </div>
    )
  }

  return (
    <div className={s.itemWrapper}>
      <div className={s.labelContainer}>
        <label>
          {item.label}
          {item.required && <span className={s.redAsterix}>*</span>}
        </label>
      </div>
      <div className={s.inputContainer} data-testid={`${item.model}_wrapper`}>
        <div className={s.itemField}>
          {item.type === 'file' ? (
            <input
              type="file"
              data-testid="upload_file_input"
              onChange={item.onChange}
              multiple="multiple"
              className={s.fileInput}
            />
          ) : (
            <Fragment>
              <div className={s.itemInput}>
                <EditableField
                  id={`testid_${item.label
                    .toLowerCase()
                    .split(' ')
                    .join('_')}`}
                  type={item.fieldType}
                  options={item.options}
                  value={item.value}
                  isInvalid={!item.isValid}
                  currencySize="lg"
                  onChange={value => {
                    changeRequestItemValue({
                      fieldUniqueName: item.fieldUniqueName,
                      value,
                      isPracticeArea: item.model === 'MatterGroup'
                    })
                  }}
                  alphabeticallySorted
                />
              </div>
              <If condition={item.info}>
                <div className={s.hoverAction}>
                  <Tooltip content={item.info} />
                </div>
              </If>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentItem
