import * as React from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import get from 'lodash/get'
import {
  Anchor,
  DataTableService,
  If,
  DataTableContainer,
  DataTableSerializer,
  ModalContainer
} from 'simple-core-ui'

import ACT from '../actions'

import { setPageTitle } from 'hocs'
import { formatCityState } from 'utils/formatting'
import { makeGetRequest } from 'utils/api'
import { openLink } from 'utils/helpers'

const COLUMNS = [
  {
    columnKey: 'firstName',
    content: 'First Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '10%' }
  },
  {
    columnKey: 'lastName',
    content: 'Last Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '10%' }
  },
  {
    columnKey: 'email',
    content: 'Email',
    isSortable: true,
    isFilterable: true,
    style: { width: '23%' }
  },
  {
    columnKey: 'phone',
    content: 'Phone Number',
    isFilterable: true,
    style: { width: '12%' }
  },
  {
    columnKey: 'org',
    content: 'Company',
    isSortable: true,
    isFilterable: true,
    style: { width: '18%' }
  },
  {
    columnKey: 'title',
    content: 'Job Title',
    isSortable: true,
    isFilterable: true,
    style: { width: '12%' }
  },
  {
    columnKey: 'address',
    content: 'Location',
    isSortable: true,
    isFilterable: true,
    style: { width: '15%' }
  }
]

const INITIAL_PAGE_SIZE = 25

@setPageTitle(({ credentials }) => {
  return {
    client: get(credentials, 'user.clientName', ''),
    view: 'Contacts',
    detail: 'Address Book'
  }
})
@connect(({ contacts }) => {
  const { addressBook } = contacts
  const { rows, filteredTotal, totalEntries, isLoading } = addressBook
  const { getRowsByCategory } = DataTableSerializer

  const convertedRows = get(getRowsByCategory({ contacts: rows }, COLUMNS), 'contacts', [])

  return {
    rows: convertedRows,
    filteredTotal,
    totalEntries,
    isUpdating: isLoading
  }
})
@withFetchFlow({
  flag: 'AddressBookInitialize',
  getFetchAction: props => ({
    type: ACT.CONTACT_ADDRESS_BOOK_FETCH_REQUESTED,
    payload: null,
    loadingStateDisabled: true
  })
})
class AddressBookContainer extends React.Component {
  state = {
    ordering: {
      columnKey: 'firstName',
      isDesc: false
    },
    page: 1,
    pageSize: INITIAL_PAGE_SIZE,
    isModalOpen: false
  }

  updateTable = updateParams => {
    this.props.dispatch({
      type: ACT.CONTACT_ADDRESS_BOOK_FETCH_REQUESTED,
      payload: updateParams
    })

    const { ordering, page, pageSize } = updateParams

    this.setState({ ordering, page, pageSize })
  }

  clickRow = ({ id }) => {
    window.location = `/v2/contacts/profile/${id}`
  }

  renderCell = ({ rowId, columnKey, content }) => {
    if (!content) {
      return '--'
    }

    switch (columnKey) {
      case 'email':
        return <Anchor url={`mailto:${content}`}>{content}</Anchor>
      case 'phone':
        return <Anchor url={`tel:${content}`}>{content}</Anchor>
      case 'org':
        const LINKABLE_ORG_TYPES = ['client', 'vendor', 'legal_entity']
        const constructOrgUrl = (orgType, orgId) =>
          ({
            vendor: `/invoices/vendors/${orgId}/`,
            legal_entity: `/manage/entities/${orgId}/`,
            client: `/company/`
          }[orgType])

        return (
          <If condition={LINKABLE_ORG_TYPES.includes(content.type)} fallback={content.name}>
            <Anchor url={constructOrgUrl(content.type, content.id)}>{content.name}</Anchor>
          </If>
        )
      case 'address':
        const address = formatCityState(content)
        return address.length ? address : '--'
      default:
        return content
    }
  }

  dataTableProps = () => {
    const { ordering, page, pageSize } = this.state
    const { rows, totalEntries, filteredTotal, isUpdating } = this.props
    const { reorderColumns, getSurroundingPages } = DataTableService
    const { previousPage, nextPage } = getSurroundingPages(page, pageSize, filteredTotal)

    return {
      rows,
      columns: reorderColumns(COLUMNS, ordering),
      clickRow: this.clickRow,
      renderCell: this.renderCell,
      title: 'Address Book',
      pageSizeOptions: [
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '200', value: 200 }
      ],
      previousPage,
      nextPage,
      totalEntries,
      filteredTotal,
      categories: [
        {
          label: 'Contacts',
          value: 'contacts',
          count: filteredTotal
        }
      ],
      categoryValue: 'contacts',
      initialValues: {
        pageSize: INITIAL_PAGE_SIZE,
        //Only initialValue updated by gDSFP in Data Table Container
        page: this.state.page,
        search: '',
        ordering: { columnKey: 'firstName', isDesc: false },
        category: 'contacts'
      },
      updateTable: this.updateTable,
      isLoading: isUpdating,
      secondaryAction: {
        onClick: async () => {
          const response = await makeGetRequest('/manage/contacts/export')
          if (response?.url) {
            openLink(response.url)
          } else {
            this.setState({ isModalOpen: true })
          }
        },
        children: 'Download'
      }
    }
  }

  render() {
    return (
      <>
        <DataTableContainer {...this.dataTableProps()} />
        {this.state.isModalOpen && (
          <ModalContainer
            title="We are working on your download"
            content="You’ll receive an email once your report is ready."
            confirmText="OK"
            confirmCb={() => this.setState({ isModalOpen: false })}
            cancelCb={() => this.setState({ isModalOpen: false })}
            size="sm"
            hideCancelBtn
          />
        )}
      </>
    )
  }
}

export default AddressBookContainer
