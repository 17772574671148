import { HTMLAttributes } from 'react'
import classNames from 'classnames'

import s from './DropdownItem.scss'
import { CheckboxContainer, Ellipsis } from 'simple-core-ui'
import { Constant } from 'simple_review/@types/common'

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  option: Constant
  onSelect(option: Constant): void
  isDisabled?: boolean
  showArrow?: boolean
  isSelected?: boolean
}

const AutoCheckboxItem = ({
  option,
  onSelect,
  isDisabled = false,
  isSelected = false,
  ...props
}: Props) => {
  return (
    <div
      data-testid="dropdown-item-container"
      className={classNames(s.checkboxBox, { [s.disabled]: isDisabled })}
      onClick={() => onSelect(option)}
      {...props}
    >
      <CheckboxContainer
        styles={{
          borderColor: '#000',
          border: '1px solid #000',
          marginRight: 8
        }}
        size="sm"
        isChecked={isSelected}
        cb={() => onSelect(option)}
      />
      <Ellipsis width={300} lines={2}>
        {option.label}
      </Ellipsis>
    </div>
  )
}

export default AutoCheckboxItem
