import { titleCase } from 'title-case'
import moment from 'moment'
import s from './JobHistoryContainer.scss'
import { FaDownload } from 'react-icons/fa'
import { HelpText, Ellipsis } from 'simple-core-ui'
import { downloadOrgFile, downloadErrFile } from 'bulk/utils'
import { historyOptionByMap } from '../utils'
import { RecordType } from 'bulk/types'

export const fetchColumns = (recordTypeMapping: Record<string, string>) => {
  const columns = [
    {
      columnKey: 'created_at',
      content: 'Date',
      isSortable: true,
      render: (cell: { content: Date }) => {
        return <Ellipsis>{moment(cell.content).format('MM/DD/YYYY')}</Ellipsis>
      }
    },
    {
      columnKey: 'user',
      content: 'Submitted By',
      isSortable: true,
      render: (cell: { content: Record<string, string> }) => {
        return <Ellipsis>{cell.content.user_name}</Ellipsis>
      }
    },
    {
      columnKey: 'record_type',
      content: 'Record',
      isSortable: true,
      render: (cell: { content: keyof RecordType }) => {
        return <Ellipsis>{recordTypeMapping[cell.content]}</Ellipsis>
      }
    },
    {
      columnKey: 'action_type',
      content: 'Action',
      isSortable: true,
      render: (cell: { content: string }) => {
        const action = cell.content
        return <p>{titleCase(action === 'edit' ? 'update' : action)}</p>
      }
    },
    {
      columnKey: 'total_count',
      content: 'Records Selected',
      isSortable: false,
      render: (cell: { content: string | number }) => {
        return (
          <div className={s.colWrapper}>
            <Ellipsis>{cell.content}</Ellipsis>
          </div>
        )
      }
    },
    {
      columnKey: 'source',
      content: 'Option',
      isSortable: false,
      render: (cell: { content: 'import_csv' | 'bulk_builder' }) => {
        return <p>{historyOptionByMap[cell.content]}</p>
      }
    },
    {
      columnKey: 'status',
      content: 'Status',
      isSortable: true,
      render: (cell: { content: string }) => {
        return (
          <p style={cell.content === 'failed' ? { color: '#DC143C' } : {}}>
            {cell.content === 'failed' ? 'Failure' : titleCase(cell.content)}
          </p>
        )
      }
    },
    {
      columnKey: 'backup_enabled',
      content: 'Backup File',
      render: (
        cell: { content: string },
        row: { archived: boolean; backup_file: { id: number } }
      ) => {
        return !row.archived && cell.content && row.backup_file ? (
          <HelpText
            position="top"
            message="Download Original File"
            hasUnderline={false}
            className={s.colWrapper}
            messageStyles={{ fontSize: '12px' }}
          >
            <FaDownload
              className={s.orgFileIcon}
              onClick={() => downloadOrgFile(row.backup_file.id)}
            />
          </HelpText>
        ) : null
      }
    },
    {
      columnKey: 'error',
      content: 'Error File',
      render: (
        cell: Record<string, unknown>,
        row: { archived: boolean; status: string; job_id: number }
      ) => {
        return !row.archived && row.status === 'failed' ? (
          <HelpText
            position="top"
            message="Download Error File"
            hasUnderline={false}
            className={s.colWrapper}
            messageStyles={{ fontSize: '12px' }}
          >
            <FaDownload className={s.errFileIcon} onClick={() => downloadErrFile(row.job_id)} />
          </HelpText>
        ) : null
      }
    }
  ]
  return columns
}
