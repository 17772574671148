import classNames from 'classnames'
import s from './LegalRequestFormListHeader.scss'

const LegalRequestFormListHeader = () => (
  <li className={s.rowContainer}>
    <div className={s.rowAttributeSelect}>
      <b>Attribute</b>
    </div>
    <div className={s.rowFieldSelect}>
      <b>Input Type</b>
    </div>
    <div className={s.formLabel}>
      <b>Label</b>
    </div>
    <div className={s.rowInfoBox}>
      <b>Helper Text</b>
    </div>
    <div className={classNames(s.checkboxWrapper, s.requiredWrapper)}>
      <b>Required</b>
    </div>
    <div className={s.checkboxWrapper}>
      <b>Visible</b>
    </div>
  </li>
)

export default LegalRequestFormListHeader
