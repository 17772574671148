import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requests/common/RequestsTableContainer/actions'
import { REQUEST_STATUSES } from 'matters/requests/constants'
import { RequestModal } from './RequestModal'
import { getRequestRepresentation } from '../utils'
import { createSelector } from 'reselect'
import { withRouter } from 'simple-core-ui/hocs'

const requestSelector = state => state.request
const requestFormSelector = state => state.requestForm
const selectRequestItems = createSelector(
  [requestSelector, requestFormSelector],
  (request, requestForm) => {
    let requestItems = []
    if (request.otherFields && request.otherFields.length) {
      requestForm.items.forEach(item => {
        const requestItem = request.otherFields.find(field => field.id === item.fieldUniqueName)
        if (requestItem) {
          if (requestItem.type === 'relationship') {
            requestItems.push({ ...requestItem, required: item.required })
          } else {
            requestItems.push({
              key: requestItem.key,
              value: requestItem.value,
              required: item.required
            })
          }
        }
      })

      request.extraFields.forEach(item =>
        requestItems.push({
          key: item.key,
          value: item.value,
          required: false
        })
      )
    }

    return requestItems
  }
)
@withRouter
@connect(({ requestsList, requestForm }) => {
  const request = requestsList.currentRequest
  return {
    request,
    requestItems: selectRequestItems({ request, requestForm })
  }
})
class RequestModalContainer extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_DETAIL_FETCH_REQUESTED,
      payload: {
        id: this.props.id,
        navigate: this.props.router.navigate
      }
    })
  }
  componentWillUnmount() {
    this.props.dispatch({
      type: ACT.CLEAR_CURRENT_REQUEST_DETAILS
    })
  }
  changeComments = value => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_DETAIL_CHANGE_COMMENTS,
      payload: {
        value
      }
    })
  }
  render() {
    const {
      request,
      requestItems,
      saveBtnText,
      closeBtnText,
      confirmCb,
      cancelCb,
      closeCb,
      secondaryConfirmCb,
      secondaryConfirmText,
      hideCancelBtn
    } = this.props

    const hideButtons = request.status !== REQUEST_STATUSES.PENDING || !confirmCb
    const showCommentTextarea = confirmCb && request.status === REQUEST_STATUSES.PENDING
    const { additionalFields } = request

    return (
      <RequestModal
        request={request}
        otherFields={requestItems}
        additionalFields={getRequestRepresentation(additionalFields)}
        saveBtnText={saveBtnText}
        closeBtnText={closeBtnText}
        hideButtons={hideButtons}
        confirmCb={confirmCb}
        cancelCb={cancelCb}
        closeCb={closeCb}
        secondaryConfirmCb={secondaryConfirmCb}
        secondaryConfirmText={secondaryConfirmText}
        hideCancelBtn={hideCancelBtn}
        showCommentTextarea={showCommentTextarea}
        changeComments={this.changeComments}
      />
    )
  }
}

export default RequestModalContainer
