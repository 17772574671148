export const toRequestFormJson = form => {
  return {
    name: form.name,
    description: form.description,
    status: form.status,
    notify_users: form.users.map(user => user.value),
    items: form.items.map(item => {
      if (item.fieldType === 'relationship') {
        item.attributes = item.attributes.map(attr => ({
          model: attr.value,
          label: attr.label,
          info: attr.info,
          required: item.required,
          fieldVisible: item.fieldVisible,
          fieldType: 'select'
        }))
      }
      item.field_type = item.fieldType
      if (
        item.filterOptions &&
        item.allOptions &&
        item.filterOptions.length === item.allOptions.length
      ) {
        delete item.filterOptions
      }
      return item
    }),
    templates: form.selectedTemplates.map(template => {
      return template.id
    })
  }
}

export const toAdminsSelect = admins =>
  admins.map(admin => ({
    value: admin.id,
    label: admin.name
  }))
