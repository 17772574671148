import moment from 'moment'
import PropTypes from 'prop-types'

import s from './Timestamp.scss'

const Timestamp = ({ position, date }) => {
  return (
    <figure className={position === 'left' ? s.left : s.right}>
      <time className={s.date}>{moment(date).format('MMM D YYYY')}</time>
      <time className={s.time}>{moment(date).format('LT')}</time>
    </figure>
  )
}

Timestamp.defaultProps = {
  position: 'left',
  date: new Date()
}

Timestamp.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  date: PropTypes.object
}

export default Timestamp
