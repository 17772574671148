import { Vendor, ReferralListAPI, UserOption, APIUser, FilterKey } from './types'

export const fromMatterUpdate = (
  mode: string,
  comment: string,
  commentId: number | null,
  isInternal: boolean,
  selectedVendors: Vendor[] = []
) => {
  if (mode === 'edit') {
    return {
      id: commentId,
      status_update: comment
    }
  }
  return {
    status_update: comment,
    is_internal: isInternal,
    ...(!isInternal ? { shared_with_vendors: selectedVendors.map(vendor => vendor.id) } : {})
  }
}

export const toVendorSelect = (results: ReferralListAPI[]): Vendor[] =>
  results.map(
    ({
      vendor__vendor_name,
      vendor_id,
      role_user_count,
      is_active = false,
      matter_updates_sharing_limit_exceeded = false
    }) => ({
      id: vendor_id,
      value: vendor_id,
      label: vendor__vendor_name,
      vendor_name: vendor__vendor_name,
      userCount: role_user_count,
      users: [],
      isActive: is_active,
      isDisabled: matter_updates_sharing_limit_exceeded
    })
  )

export const toShareWithVendors = (update: string, selectedVendors: Vendor[]) => ({
  status_update: update,
  is_internal: false,
  shared_with_vendors: selectedVendors.map(v => v.value)
})

export const toCgUsers = (options: APIUser[], filterKey: string): UserOption[] => {
  return options.map(o => ({
    value: o.id,
    label: o[filterKey as FilterKey],
    email: o.primary_email
  }))
}
