import { Component } from 'react'
import { connect } from 'react-redux'
import ACT from 'matters/requestForms/detail/actions'
import Templates from './components/Templates'

@connect(({ requestForm }, ownProps) => {
  return {
    templates: requestForm.selectedTemplates
  }
})
class TemplatesContainer extends Component {
  removeTemplate = attrIndex => {
    this.props.dispatch({
      type: ACT.REQUEST_FORM_REMOVE_TEMPLATE,
      payload: {
        attrIndex
      }
    })
  }
  render() {
    const { templates, navigateToSelectTemplates, disabled } = this.props

    return (
      <Templates
        templates={templates}
        navigateToSelectTemplates={navigateToSelectTemplates}
        removeTemplate={this.removeTemplate}
        disabled={disabled}
      />
    )
  }
}

export default TemplatesContainer
