import { BudgetSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { formatMultiSelectLabel } from '../helpers'

const BudgetFilter = ({
  title = 'Budget',
  onSelect,
  reset,
  isMulti,
  initialValue,
  limit,
  hasConfirmation,
  filterOption,
  paginated,
  withNoneOption
}) => {
  return (
    <SelectFilter
      title={title}
      initialValue={initialValue}
      onSelect={onSelect}
      reset={reset}
      customLabel={formatMultiSelectLabel(isMulti, title)}
      hasConfirmation={hasConfirmation}
      isMulti={isMulti}
      render={(budget, setBudget) => {
        return (
          <BudgetSelect
            value={budget}
            onChange={item => {
              setBudget(item)
            }}
            isPortal={false}
            isClearable
            isMulti={isMulti}
            limit={limit}
            filterOption={filterOption}
            paginated={paginated}
            withNoneOption={withNoneOption}
          />
        )
      }}
    />
  )
}

export default BudgetFilter
