import { ReactNode } from 'react'
import { ModalContainer } from 'simple-core-ui'

interface Props {
  title: string
  confirmText: string
  content: ReactNode | string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmationDialog = ({ title, confirmText, content, onConfirm, onCancel }: Props) => {
  return (
    <ModalContainer
      title={title}
      content={content}
      confirmText={confirmText}
      size="sm"
      hasNewButtons
      contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
      confirmCb={onConfirm}
      cancelCb={onCancel}
    />
  )
}

export default ConfirmationDialog
