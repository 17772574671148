import { Component } from 'react'
import { MATTER_GROUP_ID, LEGAL_ENTITY_ID, SELECT_TEMPLATE_FLOW } from 'matters/templates/constants'
import * as ACT from 'matters/templates/actions'
import { connect } from 'react-redux'
import { RequestInformation } from './RequestInformationContent'
import { withRouter } from 'simple-core-ui/hocs'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { getAttributesFromTemplate } from 'matters/templates/utils'

@withRouter
@connect(({ legalRequest, matterTemplatesSelection, matterTemplates, requestsList }) => {
  const legalRequestComments = get(requestsList, 'currentRequest.comments', '')
  return {
    attributes: legalRequest.attributes,
    files: legalRequest.files,
    templateId:
      matterTemplatesSelection.selectedTemplate && matterTemplatesSelection.selectedTemplate.id,
    selectedTemplate: matterTemplates,
    userFlowState: matterTemplatesSelection.userFlowState,
    legalRequestComments
  }
})
class RequestInformationContainer extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.attributes.length &&
      !get(
        prevProps.attributes.find(attr => attr.id === 'comments'),
        'value',
        ''
      ) &&
      this.props.legalRequestComments
    ) {
      const { attributes, legalRequestComments } = this.props
      if (legalRequestComments && attributes.length) {
        const index = attributes.findIndex(attr => attr.id === 'comments')
        const value =
          get(
            prevProps.attributes.find(attr => attr.id === 'comments'),
            'value',
            ''
          ) || legalRequestComments
        this.changeAttributeValue({ index, value })
      }
    }
  }
  shouldComponentUpdate(nextProps) {
    return (
      this.props.userFlowState.userFlow ===
        SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION ||
      nextProps.attributes !== this.props.attributes ||
      nextProps.isConfidential !== this.props.isConfidential
    )
  }
  componentWillUnmount() {
    this.props.userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST &&
      this.props.dispatch({ type: ACT.LEGAL_REQUEST_RESET_STATE })
  }
  getUpdatedAttributes(index, value, property = 'value', relationshipIndex) {
    const attributes = cloneDeep(this.props.attributes)
    if (relationshipIndex !== undefined) {
      const relAttribute = attributes[index].attributes[relationshipIndex]
      attributes[index].attributes[relationshipIndex] = { ...relAttribute, [property]: value }
      return attributes
    }
    attributes[index] = { ...attributes[index], [property]: value }
    return attributes
  }
  changeAttributeValue = ({
    index,
    value,
    property,
    inTemplate,
    attributeId,
    relationshipIndex,
    relationshipId
  }) => {
    const { userFlowState } = this.props
    // for attributes of type currency the code is an object {value: "AUD", label: "AUD - Australian Dollar"}
    // that's why we use code.value
    if (value && value.code && typeof value.code === 'object') {
      value.code = value.code.value
    }
    const updatedAttributes = this.getUpdatedAttributes(index, value, property, relationshipIndex)
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_CHANGE_ATTRIBUTE_VALUE,
      payload: { updatedAttributes }
    })

    // if practice area or legal entity change, fire request to get the correct attributes values
    if ([MATTER_GROUP_ID, LEGAL_ENTITY_ID].includes(attributeId)) {
      this.fetchFilteredAttributes(attributeId, value)
    }

    if (inTemplate) {
      this.props.dispatch({
        type: ACT.VALUES_FOR_TEMPLATE_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: {
          id: this.props.templateId,
          selectedValues: updatedAttributes,
          template: this.props.selectedTemplate,
          userFlowState
        }
      })
    }

    // on changing the value of an attribute from a dynamic dropdown fetch the paired values for next level
    if (relationshipIndex !== undefined) {
      if (property !== 'required') {
        this.fetchDynamicAttributePairedValues({
          index,
          value,
          property,
          relationshipIndex,
          relationshipId
        })
      }
    }
  }
  fetchDynamicAttributePairedValues = params => {
    // check if the relationship has a next level
    const { attributes } = this.props
    const { index, value, relationshipIndex, relationshipId } = params

    const levels = attributes[index].attributes.length
    const hasNextLevel = relationshipIndex !== levels - 1

    if (value && value?.value !== -1) {
      if (hasNextLevel) {
        this.props.dispatch({
          type: ACT.LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_REQUESTED,
          payload: {
            ...params,
            levels
          }
        })
      }
    } else {
      this.props.dispatch({
        type: ACT.LEGAL_REQUEST_DYNAMIC_ATTRIBUTES_PAIRED_VALUES_FETCH_SUCCESS,
        payload: {
          index,
          relationshipIndex,
          relationshipId,
          levels
        }
      })
    }
  }
  fetchFilteredAttributes = (attributeId, value) => {
    // value param is either a practice area or a legal entity value,
    // the one that the user changes. The API expects both practice area and legal entity values
    // so we need to grab the other one (e.g if value is the value for practice area then
    // attributeId == 'matter_group' and otherAttribute == 'legal_entity'
    const otherAttribute = [MATTER_GROUP_ID, LEGAL_ENTITY_ID].filter(id => id !== attributeId)[0]
    const { attributes, userFlowState } = this.props
    const isChangeMatterTemplateOrMatterRequest = [
      SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE,
      SELECT_TEMPLATE_FLOW.MATTER_REQUEST
    ].includes(userFlowState.userFlow)
    // the server expects -1 value if a practice area or a legal entity is not selected
    let ids = {
      [attributeId]: value ? value.value : -1,
      [otherAttribute]: -1
    }

    attributes.forEach(attribute => {
      if (otherAttribute === attribute.id) {
        // a list type attribute can have value == '3' when coming from the API, or
        // value == {value: '3', label: 'some label'} when posting to the API
        // we need to support both so that's why the object check below
        let value = 'value'
        if (typeof attribute.value === 'object') {
          value = 'value.value'
        }
        ids = {
          ...ids,
          [attribute.id]: get(attribute, value, -1) || -1
        }
      }
    })

    if (isChangeMatterTemplateOrMatterRequest || this.templateHasAttribute(attributeId)) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_REQUESTED,
        payload: {
          ids: { ...ids },
          templateId: this.props.selectedTemplate?.id,
          userFlowState
        }
      })
    }
  }
  templateHasAttribute = () => {
    const template = this.props.selectedTemplate
    const ids = [MATTER_GROUP_ID, LEGAL_ENTITY_ID]
    const attributes = getAttributesFromTemplate(template)
    let hasPAorLE = false

    attributes.forEach(attribute => {
      if (ids.includes(attribute.id)) {
        hasPAorLE = true
      }
    })

    return hasPAorLE
  }

  render() {
    const {
      attributes,
      files,
      templateActions,
      noOfPreferred,
      isConfidential,
      toggleIsConfidential,
      userFlowState,
      selectedTemplate
    } = this.props

    return (
      <RequestInformation
        attributes={attributes}
        userFlowState={userFlowState}
        templateActions={templateActions}
        selectedTemplate={selectedTemplate}
        files={files}
        changeAttributeValue={this.changeAttributeValue}
        title={
          userFlowState.userFlow === SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION
            ? 'Configure Request Form'
            : userFlowState.userFlow === SELECT_TEMPLATE_FLOW.MATTER_REQUEST
            ? 'Process Request'
            : userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
            ? 'Update Matter'
            : 'Create Matter'
        }
        noOfPreferred={noOfPreferred}
        isConfidential={isConfidential}
        toggleIsConfidential={toggleIsConfidential}
      />
    )
  }
}

export default RequestInformationContainer
