import { LoadingSkeleton } from 'simple-core-ui'
import s from './KpiSkeleton.scss'

const KpiSkeleton = () => {
  return (
    <section className={s.kpi}>
      <LoadingSkeleton height={82} width={82} />
      <div className={s.description}>
        <LoadingSkeleton height={22} width={120} />
        <div className={s.details}>
          <LoadingSkeleton height={16} width={180} />
          <LoadingSkeleton height={16} width={160} />
        </div>
      </div>
    </section>
  )
}

export default KpiSkeleton
