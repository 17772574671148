import ACT from './actions'
import { createReducer } from 'redux-create-reducer'

const initialState = {
  supersetDashboards: {}
}

const supersetReducer = createReducer(initialState, {
  [ACT.SUPERSET_DASHBOARDS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      supersetDashboards: action.payload
    }
  }
})

export default supersetReducer
