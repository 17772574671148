import { Component } from 'react'

class HomeRedirect extends Component {
  componentDidMount() {
    window.location.href = this.props.redirectTo
  }

  render() {
    return <div />
  }
}

export default HomeRedirect
