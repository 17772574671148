import s from '../BulkEditModalContainer.scss'
import { Checkbox, Grid } from 'simple-core-ui'
import Select from 'react-select'
import { Fragment } from 'react'
import omit from 'lodash/omit'
import {
  RECORD_TO_FIELDS_MAPPING,
  RECORD_TO_FIELDS_COUNT_MAPPING,
  MULTI_FIELD_RECORDS_DROPDOWN_MAPPING
} from 'bulk/constants'
import { modalControlStyles } from 'bulk/styles/styleSettings'
import { MATTER_VENDORS } from 'bulk/record_types'
import variables from 'styles/_variables.scss'

const MultifieldForm = ({
  searchString,
  recordType,
  formValues,
  setFormValues,
  selectedFields,
  setSelectedFields,
  errors,
  setErrors
}) => {
  const { Row, Column } = Grid

  const modalFields = RECORD_TO_FIELDS_MAPPING[recordType.value]
  const fieldToLengthMap = RECORD_TO_FIELDS_COUNT_MAPPING[recordType.value]

  const onChangeHandler = (field, subField, option) => {
    const currentForm = formValues[field] ?? {}
    !selectedFields[field] && setSelectedFields({ ...selectedFields, [field]: true })

    if ((Array.isArray(option) && !option.length) || !option) {
      const updatedField = omit(currentForm, subField)
      if (Object.keys(updatedField).length !== fieldToLengthMap[field] + 1) {
        setErrors({ ...errors, [field]: true })
      }
      if (Object.keys(updatedField).length <= 1) {
        setFormValues(omit(formValues, field))
        setErrors({ ...errors, [field]: true })
      } else {
        setFormValues({ ...formValues, [field]: omit(currentForm, subField) })
      }
    } else {
      const updatedField = {
        ...currentForm,
        op: field,
        [subField]: option
      }
      setFormValues({ ...formValues, [field]: updatedField })
      if (Object.keys(updatedField).length === fieldToLengthMap[field] + 1) {
        setErrors(omit(errors, field))
      } else {
        setErrors({ ...errors, [field]: true })
      }
    }
  }

  const SelectField = props => {
    const { id, placeholder, options, field, ...rest } = props
    const fieldVal = formValues[field] ?? {}
    return (
      <Select
        id={id}
        value={fieldVal[id]}
        placeholder={placeholder}
        onChange={option => {
          onChangeHandler(field, id, option)
        }}
        className={s.inputFieldContainer}
        options={options}
        styles={modalControlStyles(errors[field] && !fieldVal[id])}
      />
    )
  }

  const CustomSelectField = props => {
    const Component = MULTI_FIELD_RECORDS_DROPDOWN_MAPPING[recordType.value]
    const { id, placeholder, field, label, ...rest } = props
    const fieldVal = formValues[field] ?? {}
    const styles = {
      ...modalControlStyles(errors[field] && !fieldVal[id]),
      valueContainer: base => ({
        ...base,
        maxHeight: 120,
        overflow: 'auto',
        color: variables.bulkBlack
      })
    }
    //VendorSelect and TeamSelect have different style props
    const styleProp = recordType.value === MATTER_VENDORS ? { style: styles } : { styles }
    return (
      <Component
        id={id}
        value={fieldVal[id]}
        placeholder={placeholder}
        onChange={option => {
          onChangeHandler(field, id, option)
        }}
        className={s.inputFieldContainer}
        {...styleProp}
        {...rest}
      />
    )
  }

  const filteredFields = modalFields.filter(field =>
    field.display_name.toLowerCase().includes(searchString.toLowerCase())
  )

  const checkboxHandler = (field, isChecked) => {
    setSelectedFields({ ...selectedFields, [field]: !isChecked })
    if (isChecked) {
      setFormValues(omit(formValues, field))
      setErrors(omit(errors, field))
    } else {
      setErrors({ ...errors, [field]: true })
    }
  }

  return (
    <div className={s.fieldsContainer}>
      {filteredFields.map(fieldRow => (
        <Row key={fieldRow.field} className={s.ModalRow} style={{ marginBottom: '16px' }}>
          <Column span={1}>
            <Checkbox
              isChecked={selectedFields[fieldRow.field]}
              triggerCheckbox={() =>
                checkboxHandler(fieldRow.field, selectedFields[fieldRow.field])
              }
              size="sm"
              styles={{ color: '#6C6C6C', marginBottom: '-3px' }}
              hasGreyBorder
            />
          </Column>

          <Column span={4}>
            <p>{fieldRow.display_name}</p>
          </Column>

          <Column span={7}>
            <Row>
              {fieldRow.field_options.map(option => {
                return (
                  <Fragment key={option.key}>
                    {option.label && (
                      <Column span={option.label_width} className={s.label}>
                        {option.label}
                      </Column>
                    )}
                    <Column span={option.width} key={option.key}>
                      {option.options ? (
                        <SelectField
                          id={option.key}
                          placeholder={option.placeholder}
                          field={fieldRow.field}
                          options={option.options}
                        />
                      ) : (
                        <CustomSelectField
                          id={option.key}
                          placeholder={option.placeholder}
                          field={fieldRow.field}
                          {...(option.props ? option.props : {})}
                        />
                      )}
                    </Column>
                  </Fragment>
                )
              })}
            </Row>
            <div>
              <p className={s.error}>{errors[fieldRow.field] && 'Please provide a value'}</p>
            </div>
          </Column>
        </Row>
      ))}
    </div>
  )
}

export default MultifieldForm
