import { useNavigate } from 'react-router-dom'
import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  rowId: string
  isDraft: boolean
  onToggleStatus(isActive: boolean): void
}

const ActionsPopoverAI = ({ rowId, onToggleStatus, isDraft }: Props) => {
  const navigate = useNavigate()

  const actions = [
    {
      name: 'ACTIVATE',
      text: 'Activate',
      visible: isDraft,
      onClick() {
        onToggleStatus(true)
      }
    },
    {
      name: 'DEACTIVATE',
      visible: !isDraft,
      text: 'Deactivate',
      onClick() {
        onToggleStatus(false)
      }
    },
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        navigate(`/v2/rules/simplereview/${rowId}/`)
      }
    }
  ]

  return (
    <Popover
      trigger={<HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />}
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopoverAI
