import { useState, useEffect, useRef } from 'react'
import { Input } from 'simple-core-ui'
import BaseFilter from './BaseFilter'
import Select from 'react-select'
import SearchSelectBody from './SearchSelectBody'
import s from '../SearchFilter.scss'
import numeral from 'numeral'
import cn from 'classnames'
import get from 'lodash/get'

const defaultOperator = {
  value: '==',
  label: '='
}

const getInitialOperator = initialValue => {
  const operation = get(initialValue, 'operation')
  if (operation === '==') {
    return defaultOperator
  } else {
    return { label: operation, value: operation }
  }
}

const AmountFilter = ({
  onSelect,
  title,
  initialValue,
  placeholder,
  hasConfirmation,
  reset,
  testid
}) => {
  const [operator, setOperator] = useState(
    initialValue ? getInitialOperator(initialValue) : defaultOperator
  )

  const isInitialMount = useRef(true)
  const [value, setValue] = useState(get(initialValue, 'values[0]', ''))
  const [maxValue, setMaxValue] = useState(get(initialValue, 'values[1]', ''))
  const [error, setError] = useState(false)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setOperator(defaultOperator)
      setValue('')
      setMaxValue('')
    }
  }, [reset])

  const onCancel = () => {
    setValue(get(initialValue, 'values[0]', ''))
    setMaxValue(get(initialValue, 'values[1]', ''))
    setOperator(initialValue ? getInitialOperator(initialValue) : defaultOperator)
  }

  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      onCancel={onCancel}
      initialValue={initialValue}
      customLabel={selected => {
        if (!selected) return title

        let { operation, values } = selected
        if (operation === '==') operation = '='

        if (operation === 'between') {
          return (
            'Amount ' +
            operation +
            ' ' +
            numeral(values[0]).format('0,0.00') +
            ' and ' +
            numeral(values[1]).format('0,0.00')
          )
        }
        return 'Amount ' + operation + ' ' + numeral(values[0]).format('0,0.00')
      }}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen) => {
        return (
          <SearchSelectBody
            onClear={() => {
              setOperator(defaultOperator)
              setValue('')
              setMaxValue('')
              setError(false)
            }}
            className={cn({
              [s.searchContainer]: operator.value === 'between'
            })}
            onSubmit={() => {
              if (operator.value === 'between' && +value > +maxValue) {
                setError(true)
                return
              }
              setFilterSelection(
                value
                  ? maxValue
                    ? {
                        operation: operator.value,
                        values: [value, maxValue]
                      }
                    : operator.value === 'between'
                    ? null
                    : {
                        operation: operator.value,
                        values: [value]
                      }
                  : null,
                true
              )
            }}
          >
            <>
              <div style={{ display: 'flex' }}>
                <Select
                  value={operator}
                  className={s.operatorSelect}
                  onChange={value => {
                    setOperator(value)
                    setMaxValue('')
                    setError(false)
                  }}
                  options={[
                    {
                      value: '>',
                      label: '>'
                    },
                    {
                      value: '>=',
                      label: '>='
                    },
                    {
                      value: '<',
                      label: '<'
                    },
                    {
                      value: '<=',
                      label: '<='
                    },
                    {
                      value: '==',
                      label: '='
                    },
                    {
                      value: 'between',
                      label: 'between'
                    }
                  ]}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
                <Input
                  text={value || ''}
                  onChangeCb={e => setValue(e.target.value)}
                  placeholder={placeholder}
                  type="number"
                  cssClass={s.amountInput}
                />
                {operator.value === 'between' && (
                  <Input
                    text={maxValue || ''}
                    onChangeCb={e => setMaxValue(e.target.value)}
                    placeholder={placeholder}
                    type="number"
                    cssClass={s.amountInput}
                  />
                )}
              </div>
              {error && <p className={s.errors}>Second value should be greater than first one</p>}
            </>
          </SearchSelectBody>
        )
      }}
    />
  )
}

export default AmountFilter
