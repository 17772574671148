export const toSettingPostData = ({ name, value }) => ({ name, value })

export const toAdminsSelect = admins =>
  admins.map(admin => ({
    value: admin.id,
    label: `${admin.name} (${admin.email})`
  }))

const getPreviewTitle = name => {
  switch (name) {
    case 'sl_document_preview':
      return 'Document Preferences'
    default:
      return ''
  }
}

export const toSettingsData = settings =>
  settings.map(setting => ({ ...setting, title: getPreviewTitle(setting.name) }))
