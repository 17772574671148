import { DataTableContainer } from 'simple-core-ui'
import s from './UserManagement.scss'

const Users = ({ params, results, updateTable, deleteUser, showEditModal }) => {
  return (
    <section className={s.container}>
      <DataTableContainer
        initialValues={params}
        categories={[]}
        previousPage={results.previousPage}
        nextPage={results.nextPage}
        totalEntries={results.totalEntries}
        filteredTotal={results.filteredTotal}
        rows={results.rows}
        columns={results.columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.categoriesTable}
        hasActions
        deleteRow={deleteUser}
        editRow={showEditModal}
      />
    </section>
  )
}

export default Users
