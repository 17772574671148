import { Fragment } from 'react'
import cn from 'classnames'
import { Loading } from 'components'
import { ContentHeader } from './ContentHeader'
import { ContentMain } from './ContentMain'
import s from './Content.scss'

const Content = ({
  formName,
  items,
  userRole,
  pageDescription,
  changeRequestItemValue,
  cancelLegalRequest,
  createLegalRequest,
  opened,
  isMenuExpanded,
  upload,
  loading,
  relationships
}) => (
  <section className={cn(s.container, { [s.expanded]: !opened })}>
    {loading && <Loading />}
    {!loading && (
      <Fragment>
        <ContentHeader
          formName={formName}
          userRole={userRole}
          pageDescription={pageDescription}
          cancelLegalRequest={cancelLegalRequest}
          createLegalRequest={createLegalRequest}
          isMenuExpanded={isMenuExpanded}
          isSidebarOpen={opened}
        />
        <ContentMain
          items={items}
          changeRequestItemValue={changeRequestItemValue}
          upload={upload}
          relationships={relationships}
        />
      </Fragment>
    )}
  </section>
)

export default Content
