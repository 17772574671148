import shortid from 'shortid'
import { LogicalOperator } from 'simple_review/@types/common'
import { Condition, Operand } from 'simple_review/@types/editor'

export const getShortId = () => shortid.generate()

export const getBaseOperand = (): Operand => ({
  id: getShortId(),
  lhs: null,
  op: null,
  rhs: null
})

export const getBaseCondition = (op: LogicalOperator = 'AND'): Condition => ({
  id: getShortId(),
  op,
  operands: [getBaseOperand()]
})

export const getRootCondition = () => {
  const baseCondition = getBaseCondition()
  baseCondition.operands[0] = getBaseCondition()
  return baseCondition
}
