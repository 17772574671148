import qs from 'query-string'
import moment from 'moment'

import { DateFilterSerializer, MultiSelectFilterSerializer } from 'common/Filters/serializers'
import { LAST_6_MONTHS } from 'common/Filters/components/DateRangeSelect'
import { DATE_FORMATS } from 'utils/constants'

const { DJANGO_DATE } = DATE_FORMATS

export const toStartEndDateFormatted = ({ min, max }) => ({
  start_date: moment(min).format(DJANGO_DATE),
  end_date: moment(max).format(DJANGO_DATE)
})

export const toFragment = param => {
  const { vendor_id_list, value } = param

  const dateSelector = value ? DateFilterSerializer.toFragment('date', { value }) : ''
  const idListSelectors = vendor_id_list
    ? MultiSelectFilterSerializer.toFragment('vendor_id_list', vendor_id_list)
    : ''

  window.history.replaceState('', '', `#${dateSelector}&${idListSelectors}`)
}

export const fromFragment = hash => {
  const { vendor_id_list, date_min, date_max } = qs.parse(hash)

  const list =
    vendor_id_list && MultiSelectFilterSerializer.fromFragment('vendor_id_list', { vendor_id_list })
  const { value = LAST_6_MONTHS } =
    date_min && date_max ? DateFilterSerializer.fromFragment('date', { date_min, date_max }) : {}

  return {
    vendor_id_list: list || [],
    ...toStartEndDateFormatted(value)
  }
}
