import { useState } from 'react'
import { DataTableWrapper } from 'simple-core-ui'
import { DeleteFile } from './DeleteFile'
import s from './InfectedFiles.scss'
import { FaTrash } from 'react-icons/fa'

const InfectedFiles = ({
  params,
  results,
  updateTable,
  selectedFile,
  selectInfectedFile,
  deleteFile
}) => {
  const [showDeleteFile, setShowDeleteFile] = useState(false)

  const toggleDeleteFile = () => setShowDeleteFile(prevShowDeleteFile => !prevShowDeleteFile)

  const showDeleteModal = row => {
    selectInfectedFile(row.id)
    toggleDeleteFile()
  }

  return (
    <section>
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={results.rows}
        columns={results.columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.infectedFilesTable}
        actions={[
          {
            icon: FaTrash,
            tooltip: 'Delete',
            onClick: (e, row) => showDeleteModal(row)
          }
        ]}
      />
      {showDeleteFile && (
        <DeleteFile
          file={selectedFile}
          showDeleteFile={showDeleteFile}
          toggleDeleteFile={toggleDeleteFile}
          deleteFile={() => deleteFile(selectedFile)}
        />
      )}
    </section>
  )
}

export default InfectedFiles
