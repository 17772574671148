import noop from 'lodash/noop'
import { useState } from 'react'
import { BsPlusSquare } from 'react-icons/bs'

import Subscribers from '../../reviewer_config/components/Subscribers'

import s from '../styles/ReviewLadder.scss'

const AddReviewers = ({ onAddReviewers, searchUrl }) => {
  const [reviewers, setReviewers] = useState([])

  const editSubscribers = (newReviewer = []) => {
    const isSelectedAlready = reviewers.find(e => e.id === newReviewer[0]?.id)
    if (isSelectedAlready) {
      return
    }
    setReviewers(e => e?.concat(newReviewer))
  }

  const removeReviewers = newReviewers => {
    setReviewers(newReviewers)
  }

  return (
    <Subscribers
      type="reviewer"
      updateSubscribers={() =>
        reviewers.length ? onAddReviewers(reviewers.map(({ id }) => id)) : noop
      }
      editableSubscribers={reviewers}
      editSubscribers={editSubscribers}
      teamSelectProps={{ url: searchUrl, multi: true, id: 'cy_reviewer_team_select' }}
      removeSubscribers={removeReviewers}
      OpenButton={AddButton}
    />
  )
}

export const AddButton = ({ openCb }) => (
  <section className={s.addApprover} data-testid="add_reviewers" onClick={openCb}>
    <BsPlusSquare style={{ marginRight: 8 }} />
    Add Reviewers
  </section>
)

export default AddReviewers
