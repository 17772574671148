import { If } from 'simple-core-ui'
import s from './ClientKeysList.scss'

const ClientKeysListTitle = ({ clientKeyCount, clientKeyLimit }) => (
  <section className={s.title}>
    ClientKey Management
    <If condition={clientKeyLimit}>
      <span className={s.limitText}>
        <span className={s.leftParen}>(</span>
        Using <strong>{clientKeyCount}</strong> out of <strong>{clientKeyLimit}</strong> clientKeys.
        <span className={s.rightParen}>)</span>
      </span>
    </If>
  </section>
)

export default ClientKeysListTitle
