import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTableWrapper, coreThemes } from 'simple-core-ui'
import { fetchColumns } from './tableDefinitions'
import { serializeImportRecordType, serializeAllRecordType } from 'bulk/serializer'
import JobHistoryFilters from './Filters/Filters'
import ACT from '../actions'
import s from './JobHistoryContainer.scss'
import { toFragment } from '../serializer'
import { BULK_TAB_NAMES_BY_MAP } from 'bulk/constants'
import cn from 'classnames'
import { Params } from '@common_types/common' //TODO: Move to @types/common
import { RecordType } from 'bulk/types'

type Props = {
  activeTab: string | undefined
}

type SingleHistoryItem = {
  id: number
  action_type: string
  archived: boolean
  backup_enabled: boolean
  backup_file: string | null
  created_at: string
  error_count: number
  error_file: string | null
  job_id: number
  record_type: string
  source: string
  status: string
  success_count: number
  total_count: number
  user: { id: number; user_name: string }
}

interface History {
  params: Params & { filters: Record<string, unknown> }
  results: SingleHistoryItem[]
  totalEntries: number
}

interface State {
  bulk: {
    history: History
    recordTypeObj: RecordType
  }
  app: {
    loading: {
      BULK_HISTORY_FETCH: boolean
    }
  }
}

const JobHistoryContainer = ({ activeTab }: Props) => {
  const { history: bulkHistory } = BULK_TAB_NAMES_BY_MAP

  const history = useSelector<State, History>(state => state.bulk.history)
  const { params, results, totalEntries: total } = history

  const loading = useSelector<State, boolean>(({ app }) => app?.loading?.BULK_HISTORY_FETCH)
  const recordTypeObj = useSelector<State, RecordType>(state => state.bulk.recordTypeObj)

  const dispatch = useDispatch()

  useEffect(() => {
    toFragment({ activeTab })
  }, [activeTab])

  useEffect(() => {
    dispatch({
      type: ACT.BULK_HISTORY_FETCH_REQUESTED,
      payload: {
        params: params
      }
    })

    return () => {
      dispatch({
        type: ACT.CLEANUP_BULK,
        payload: { key: bulkHistory }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const recordTypeMapping = useMemo(() => {
    const recordTypeMap = {} as Record<string, string>
    serializeAllRecordType(recordTypeObj).forEach(record => {
      recordTypeMap[record.value] = record.label
    })
    return recordTypeMap
  }, [recordTypeObj])
  const columns = useMemo(() => fetchColumns(recordTypeMapping), [recordTypeMapping])

  const updateTable = (updatedParams: Params) => {
    dispatch({
      type: ACT.BULK_HISTORY_FETCH_REQUESTED,
      payload: {
        params: { ...updatedParams, filters: params.filters }
      }
    })
  }

  const onSearch = ({
    filterParams: updatedFilterParams
  }: {
    filterParams: Record<string, unknown>
  }) => {
    dispatch({
      type: ACT.BULK_HISTORY_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params,
          page: 1,
          filters: updatedFilterParams
        }
      }
    })
  }

  const rows = results

  useEffect(() => {
    //fix for react-truncate inconsistency
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 1)
  }, [rows])

  return (
    <div className="box" style={{ marginBottom: 280, boxShadow: 'none' }}>
      <div className={cn('box-content', s.content)}>
        <div className={s.header}>
          <h2 className={s.title}>Job History</h2>
          <h4 className={s.subTitle}>
            Records are deleted after 90 days. Download files to save a copy.
          </h4>
        </div>
        <DataTableWrapper
          params={params}
          customSearchOptions={{
            placeholder: 'Press enter to search',
            styles: { width: 270, fontStyle: 'italic' }
          }}
          remotePagination
          tableHeight="500px"
          categories={[]}
          rows={rows}
          columns={columns}
          totalEntries={total}
          updateTable={updateTable}
          panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
          hasActions
          hasTooltip
          isLoading={loading}
          theme={coreThemes.EB}
          hasSearch={false}
          customBlankValue={<span>&#8212;</span>}
          filters={
            <JobHistoryFilters
              filterParams={params.filters}
              onSearch={onSearch}
              recordType={serializeImportRecordType(recordTypeObj)}
            />
          }
        />
      </div>
    </div>
  )
}

export default JobHistoryContainer
