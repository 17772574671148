import shortid from 'shortid'
import { AvailableField, Operator, Rule as APIRule } from 'simple_review/@types/api'
import { Condition, Operand, Rule } from 'simple_review/@types/editor'
import { RuleOperandLhsSerializer } from './rule-operand-lhs.serializer'
import { getBaseCondition } from '../content/constants'
import { isOperandCondition } from 'simple_review/utils/helpers'
import { Constant } from 'simple_review/@types/common'
import { isConditionGroup } from '../content/conditions/helpers'
import { UTBMS_CODE_REGEX } from 'simple_review/utils/constants'

const codeFieldNames = [
  'activity_code',
  'expense_code',
  'task_code',
  'line_item_activity_code',
  'line_item_expense_code',
  'line_item_task_code'
]

export function RuleConditionSerializer() {
  return {
    toJSON(operand: Operand | Condition, isRoot = false): string {
      if ('operands' in operand) {
        if (isRoot) {
          if (
            operand.operands.length === 1 &&
            isOperandCondition(operand.operands[0]) &&
            !isConditionGroup(operand.operands[0] as Condition)
          ) {
            /* If root condition has only one operand and it's a condition remove extra wrapper condition */
            operand = operand.operands[0] as Condition
          }
        }
        return JSON.stringify({
          op: operand.op,
          operands: operand.operands.map(item => JSON.parse(RuleConditionSerializer().toJSON(item)))
        })
      } else {
        const lhsJsonString = RuleOperandLhsSerializer().toJSON(operand.lhs as AvailableField)
        const obj = {
          lhs: lhsJsonString ? JSON.parse(lhsJsonString) : null,
          op: operand.op?.symbol,
          rhs: operand.rhs || undefined
        }
        if (!operand.rhs) {
          delete obj.rhs
        } else if ('type' in operand.rhs && operand.rhs.type === 'date') {
          if (!operand.op?.rhs_is_array && Array.isArray(operand.rhs.constant)) {
            operand.rhs.constant = operand.rhs.constant[0]
          }
        } else if (
          operand.lhs &&
          codeFieldNames.includes(operand.lhs?.name) &&
          obj.rhs &&
          'constant' in obj.rhs
        ) {
          if (Array.isArray(obj.rhs.constant)) {
            obj.rhs.constant = obj.rhs.constant.map(item => {
              const aux = { ...item }
              if (UTBMS_CODE_REGEX.test(aux.value)) {
                aux.value = aux.value.trim().toUpperCase()
                aux.label = aux.label.trim().toUpperCase()
              }
              return aux
            })
          } else if (UTBMS_CODE_REGEX.test(obj.rhs.constant.value)) {
            obj.rhs.constant = {
              value: obj.rhs.constant.value.trim().toUpperCase(),
              label: obj.rhs.constant.label.trim().toUpperCase()
            }
          }
        }
        return JSON.stringify(obj)
      }
    },
    fromJSON(
      condition: APIRule['condition'],
      fields: Array<AvailableField>,
      operators: Array<Operator>,
      isRoot = false
    ): Rule['condition'] {
      const itemsToWrap: Array<Condition | Operand> = []
      const obj = {
        id: shortid.generate(),
        op: condition.op,
        operands: condition.operands.map(item => {
          if ('operands' in item) {
            const subCondition = RuleConditionSerializer().fromJSON(item, fields, operators)
            if (isRoot) itemsToWrap.push(subCondition)
            return subCondition
          } else {
            const operand: Operand = {
              id: shortid.generate(),
              lhs: RuleOperandLhsSerializer().fromJSON(item, fields),
              op: operators.find(op => op.symbol === item.op) || null,
              rhs: item.rhs
            }
            if (operand.lhs?.type === 'date' && operand.rhs) {
              if (!operand.op?.rhs_is_array && 'constant' in operand.rhs) {
                operand.rhs.constant = [operand.rhs.constant as Constant]
              }
            }
            /* This is needed to support old rule conditions that could have operands at the top level. New rules will always have conditions at the top level. */
            if (isRoot) itemsToWrap.push(operand)
            return operand
          }
        })
      }
      if (itemsToWrap.length) {
        const conditionsToWrap = [],
          operandsToWrap = []
        for (const item of itemsToWrap) {
          if ('operands' in item) conditionsToWrap.push(item)
          else operandsToWrap.push(item)
        }
        obj.operands = [...conditionsToWrap]
        if (operandsToWrap.length) {
          const wrapper = getBaseCondition(obj.op)
          wrapper.operands = operandsToWrap
          obj.operands.push(wrapper)
        }
      }
      return obj
    }
  }
}
