import get from 'lodash/get'
import moment from 'moment'

interface Cell {
  columnKey: string
  content:
    | string
    | {
        value: number | string
        label: string
      }
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
    case 'editedBy':
      return typeof cellContent === 'string' ? cellContent.toLowerCase() : ''
    case 'relatedTasks':
      return +cellContent
    case 'lastEdited':
      return typeof cellContent === 'string' ? moment(cellContent).valueOf() : ''
    case 'matterGroup':
      return typeof cellContent === 'object' ? cellContent.label.toLowerCase() : ''
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
