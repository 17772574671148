import s from 'bulk/builder/stepper/BulkBuilder/BulkBuilderContainer.scss'
import { IoIosClose } from 'react-icons/io'
import { Button } from 'simple-core-ui'
import { reorderFields } from 'bulk/serializer'

const FieldsSelector = ({
  id,
  fields,
  isLoading,
  setFields,
  title = "Add Fields you'd like to see",
  addButtonText,
  setType,
  recType,
  buttonRef,
  setStep
}) => {
  const sortedFields = reorderFields(fields, recType, id === 'filter')

  const handleClick = () => {
    setType(id)
    setStep(id === 'field' ? 3 : 4)
  }

  return (
    <div className={id === 'field' ? s.fieldsSelector : s.filterSelector}>
      <p className={s.selectorTitle}>{title}</p>
      <div className={s.tableSelectorContainer}>
        {sortedFields
          .filter(field => field.isSelected)
          .map(field => (
            <div key={field.value} className={s.customTag}>
              {field.label}
              {!field.isDefault && (
                <div
                  className={s.close}
                  onClick={() => {
                    !field.isDefault &&
                      setFields(
                        fields.map(tableField =>
                          tableField.value === field.value
                            ? { ...tableField, isSelected: false }
                            : tableField
                        )
                      )
                  }}
                >
                  {' '}
                  <IoIosClose />
                </div>
              )}
            </div>
          ))}
        <div ref={buttonRef} className={s.PrimaryButton} style={{ marginTop: '5px' }}>
          <Button
            key={id}
            onClick={handleClick}
            isPrimary
            className={s.button}
            isDisabled={isLoading}
          >
            {addButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FieldsSelector
