import * as React from 'react'

import { ConditionalRender, Spinner, Panel } from 'simple-core-ui'

import s from '../styles/ReviewLadder.scss'

const style = {
  background: 'rgba(255, 255, 255, 0.9)',
  width: '100%',
  height: '100%',
  left: '0px',
  top: '0px',
  position: 'absolute',
  margin: '0px',
  zIndex: '1',
  fontSize: '20px'
}

const Hydrating = ({ isVisible, isInitial, withPanel }) => {
  const Wrapper = ({ children }) =>
    withPanel ? (
      <Panel title="Review">{children}</Panel>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    )

  return (
    <ConditionalRender
      conditions={[
        {
          condition: isVisible && isInitial,
          content: (
            <Wrapper>
              <section className={s.initialHydration}>
                <Spinner style={style} />
              </section>
            </Wrapper>
          )
        },
        { condition: isVisible, content: <Spinner style={style} /> }
      ]}
    />
  )
}

export default Hydrating
