import { Constant } from 'simple_review/@types/common'
import { ChangeEventHandler, KeyboardEventHandler } from 'react'

import { useAutosizeField } from 'simple-core-ui'
import s from './Numeric.scss'

interface Props {
  value: Constant | null
  onChange(newValue: Constant): void
  maxDecimalPlaces?: number
  isReadOnly: boolean
}

export const Numeric = ({ value, maxDecimalPlaces = 2, isReadOnly, ...props }: Props) => {
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key !== '.') return
    if (e.currentTarget.value.includes('.') || maxDecimalPlaces === 0) {
      e.preventDefault()
    }
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    let newValue = e.target.value.replace(/[^\d\.]/g, '')
    if (newValue === '') {
      props.onChange({
        value: '',
        label: ''
      })
      return
    }
    const periodIndex = newValue.indexOf('.')
    if (periodIndex !== -1) {
      newValue = newValue.substring(0, periodIndex + (maxDecimalPlaces + 1))
    }
    props.onChange({
      value: newValue,
      label: newValue
    })
  }

  const { width } = useAutosizeField({
    autoWidth: true,
    value: value?.value,
    placeholder: 'Enter numeric value',
    paddingLeft: 12,
    paddingRight: 12
  })

  return (
    <input
      type="text"
      className={s.container}
      placeholder="Enter numeric value"
      value={value?.value || ''}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={isReadOnly}
      style={{ width }}
    />
  )
}
