import { useState } from 'react'
import AutoComplete from 'simple_review/common/AutoComplete'
import DropdownItem from 'simple_review/common/DropdownItem'
import AutoCheckboxList, { toggleCheck } from 'simple_review/common/AutoCheckboxList'
import { IoIosArrowBack } from 'react-icons/io'
import cloneDeep from 'lodash/cloneDeep'
import { ArgModel, CustomAttrRhs } from 'simple_review/@types/api'
import { Button, useSyncStateWithProps } from 'simple-core-ui'
import s from './VendorAutocomplete.scss'
import { Constant } from 'simple_review/@types/common'
import { VENDOR_GROUP_SELECT_URL, VENDOR_SELECT_URL } from 'common/Selects/urls'

export type RhsValue = RhsConstant | CustomAttrRhs | null

interface RhsConstant {
  constant: Constant
  sub_type: string | null
  type?: string
}

interface Props {
  onChangeArg(
    argName: string,
    newValue: Constant[] | ArgModel | AllVendorsOption | string | null | undefined
  ): void
  value: Constant[] | ArgModel | null
  defaultIsOpen?: boolean
  arg_name: string
}

interface VendorOption {
  name: string
  display_name: string
  arg_name?: string
  fields?: number[]
}

export interface AllVendorsOption {
  label: string
  value: string
  model_field: string
  model_name: string
  arg_type: string
}

const allVendorsById = {
  label: 'All Vendors',
  value: 'id',
  model_field: 'id',
  model_name: 'Vendor',
  arg_type: 'field'
}

export function VendorAutocomplete({ onChangeArg, defaultIsOpen = false, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const options = [
    {
      name: '',
      display_name: 'All Vendors'
    },
    {
      name: '',
      display_name: 'Vendor Name',
      arg_name: 'vendor',
      fields: []
    },
    {
      name: '',
      display_name: 'Vendor Group',
      arg_name: 'vendor_group',
      fields: []
    }
  ]

  let initialSelectedOption
  let initialLocalValueVen
  let initialLocalValueVenGroup

  if (Array.isArray(props.value)) {
    initialSelectedOption = options.find(o => o.arg_name === props.arg_name) ?? options[0]
    if (props.arg_name === 'vendor') {
      initialLocalValueVen = props.value
    }
    if (props.arg_name === 'vendor_group') {
      initialLocalValueVenGroup = props.value
    }
  } else {
    initialSelectedOption = null
  }

  const [selectedOption, setSelectedOption] = useState<VendorOption | null>(initialSelectedOption)

  const [localValueVen, setLocalValueVen] = useSyncStateWithProps<Constant[]>(
    initialLocalValueVen ?? []
  )
  const [localValueVenGroup, setLocalValueVenGroup] = useSyncStateWithProps<Constant[]>(
    initialLocalValueVenGroup ?? []
  )

  const closeBox = () => {
    setIsOpen(false)
  }

  const openBox = () => {
    setIsOpen(true)
  }

  const onSelect = (newLhsValue: VendorOption) => () => {
    const newLhs = cloneDeep(newLhsValue)
    if (newLhs.display_name === 'All Vendors') {
      closeBox()
      setLocalValueVen([])
      setLocalValueVenGroup([])
      onChangeArg('vendor', allVendorsById)
      return
    }

    setSelectedOption(newLhs)
  }

  const getValue = () => {
    if (!props.value) {
      return ''
    }

    if ('model_field' in props.value) {
      return props.value.label
    }

    if (Array.isArray(props.value) && !props.value.length) {
      return ''
    }

    return `${props.arg_name === 'vendor' ? 'Vendor Name' : 'Vendor Group'} - ${
      props.value.length
    } selected`
  }

  const onJumpBack = () => {
    setSelectedOption(null)
  }

  const submitSetValue = (localValue: Constant[]) => {
    onChangeArg(selectedOption?.arg_name || 'vendor', localValue)
    closeBox()
  }

  const onSelectCheckboxVen = (option: Constant) => {
    toggleCheck(option, localValueVen, setLocalValueVen)
  }

  const onSelectCheckboxVenGroup = (option: Constant) => {
    toggleCheck(option, localValueVenGroup, setLocalValueVenGroup)
  }

  return (
    <AutoComplete
      isOpen={isOpen}
      openBox={openBox}
      closeBox={closeBox}
      value={getValue()}
      disableSearch
      width={240}
      classNameMap={{ dropdown: s.dropdown }}
    >
      <>
        {selectedOption && (
          <div className={s.parentCondition}>
            <div data-testid="go-back" className={s.goBack} onClick={onJumpBack}>
              <IoIosArrowBack />
              <span>{selectedOption?.display_name}</span>
            </div>
          </div>
        )}
        {!selectedOption && (
          <ul className={s.options}>
            {options.map((option, index) => (
              <li key={index}>
                <DropdownItem
                  label={option.display_name}
                  onSelect={onSelect(option)}
                  showArrow={!!option.fields}
                />
              </li>
            ))}
          </ul>
        )}
        {selectedOption?.display_name === 'Vendor Name' && (
          <div className={s.inputContainer}>
            <AutoCheckboxList
              url={VENDOR_SELECT_URL}
              selection={localValueVen}
              searchPlaceholder="Enter vendor name"
              onChange={onSelectCheckboxVen}
            />
            <Button isSecondary onClick={() => submitSetValue(localValueVen)}>
              Set Value
            </Button>
          </div>
        )}
        {selectedOption?.display_name === 'Vendor Group' && (
          <div className={s.inputContainer}>
            <AutoCheckboxList
              url={VENDOR_GROUP_SELECT_URL}
              selection={localValueVenGroup}
              searchPlaceholder="Enter vendor group"
              onChange={onSelectCheckboxVenGroup}
              reqParams={{ add_none_entry: false }}
            />
            <Button isSecondary onClick={() => submitSetValue(localValueVenGroup)}>
              Set Value
            </Button>
          </div>
        )}
      </>
    </AutoComplete>
  )
}
