import { BaseAsyncSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { getCustomSelectLabel } from '../helpers'
import { loadOptionsCategories } from 'common/Selects/helpers'

const RuleCategoryFilter = ({
  onSelect,
  reset,
  title = 'Category',
  hasConfirmation,
  initialValue,
  placeholder,
  styles
}) => {
  return (
    <SelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti
      hasConfirmation={hasConfirmation}
      customLabel={selected => getCustomSelectLabel(selected, title)}
      render={(category, setCategory) => {
        return (
          <BaseAsyncSelect
            placeholder={placeholder}
            loadOptions={loadOptionsCategories}
            filterOption={(option, inputValue) => {
              return option.label.toLowerCase().includes(inputValue.toLowerCase())
            }}
            cacheOptions
            value={category}
            onChange={setCategory}
            isClearable
            isPortal={false}
            isMulti
            styles={styles}
          />
        )
      }}
    />
  )
}

export default RuleCategoryFilter
