import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { RELATIONSHIP_STATUS } from '../../common/constants'
import s from './ActionsPopover.scss'

const ActionsPopover = ({
  rowId,
  deleteAttribute,
  activateRelationship,
  deactivateRelationship,
  status,
  canDelete,
  hasPairings,
  downloadPairings
}) => {
  const actions = [
    {
      name: 'ACTIVATE',
      text: 'Activate',
      visible: status !== RELATIONSHIP_STATUS.ACTIVE,
      onClick() {
        activateRelationship(rowId)
      }
    },
    {
      name: 'DEACTIVATE',
      text: 'Deactivate',
      visible: status === RELATIONSHIP_STATUS.ACTIVE,
      onClick() {
        deactivateRelationship(rowId)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      visible: canDelete,
      onClick() {
        deleteAttribute(rowId)
      }
    },
    {
      name: 'DOWNLOAD',
      text: 'Download Pairings',
      visible: hasPairings,
      onClick() {
        downloadPairings(rowId)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${rowId}`}
      trigger={
        <HoverAction tip="More" icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
