import BaseAsyncSelect from './BaseAsyncSelect'
import { loadOptionsContacts } from '../helpers'
import { PaginatedSelect } from 'simple-core-ui'
import { toUsers } from '../serializers'
import { CONTACTS_SEARCH_URL } from '../urls'

const Option = props => {
  const {
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data
  } = props
  return (
    <div
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <div>{data.label}</div>
      <div
        style={{
          fontSize: 12,
          color: isSelected ? '#fff' : '#939393'
        }}
      >
        {data.email}
      </div>
    </div>
  )
}

// gets all users that current user has access to
const UserSelect = ({
  value,
  isClearable,
  size,
  onChange,
  isPortal = true,
  isMulti = false,
  placeholder = 'Search users...',
  limit = Infinity,
  paginated = false,
  serializer,
  defaultOptions,
  defaultAction,
  filterOption,
  id,
  withNoneOption,
  isDisabled,
  customQueryParams,
  components,
  styles,
  closeMenuOnSelect,
  menuIsOpen
}) => {
  return paginated ? (
    <PaginatedSelect
      url={CONTACTS_SEARCH_URL}
      menuIsOpen={menuIsOpen}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      serializer={serializer || toUsers}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      size={size}
      placeholder={placeholder}
      limit={limit}
      filterOption={filterOption}
      comps={{ Option, ...components }}
      pageParam="p"
      pageSizeParam="n"
      searchTermParam="q"
      pageStart={0}
      mandatoryQueryParams={customQueryParams ? customQueryParams : { l: true }}
      withNoneOption={withNoneOption}
      isDisabled={isDisabled}
      styles={styles}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  ) : (
    <BaseAsyncSelect
      value={value}
      loadOptions={search => loadOptionsContacts(search, withNoneOption)}
      cacheOptions
      limit={limit}
      comps={{ Option }}
      placeholder={placeholder}
      onChange={onChange}
      isClearable={isClearable}
      isPortal={isPortal}
      isMulti={isMulti}
      isDisabled={isDisabled}
      styles={styles}
    />
  )
}

export default UserSelect
