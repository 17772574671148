import { useEffect, useRef } from 'react'
import BaseFilter from './BaseFilter'
import SearchSelectBody from './SearchSelectBody'
import { useSyncStateWithProps } from 'simple-core-ui'

const SelectFilter = ({
  testid,
  title,
  onSelect,
  initialValue,
  hasConfirmation,
  customLabel,
  reset,
  isMulti,
  render,
  style
}) => {
  const [entity, setEntity] = useSyncStateWithProps(initialValue)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setEntity(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const onCancel = () => {
    setEntity(initialValue)
  }

  const setMultiEntity = arr => {
    // arr is null if user click X on all the pills
    // arr is [] if user clicks on the isClearable X
    // https://github.com/JedWatson/react-select/issues/3632
    if (!arr || !arr.length) {
      setEntity(null)
    } else {
      setEntity(arr)
    }
  }

  const changeCallback = isMulti ? setMultiEntity : setEntity

  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      onCancel={onCancel}
      initialValue={initialValue}
      customLabel={customLabel}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen) => {
        return (
          <SearchSelectBody
            style={style}
            onClear={() => setEntity(null)}
            onSubmit={() => {
              setFilterSelection(entity)
            }}
          >
            {render(entity, changeCallback)}
          </SearchSelectBody>
        )
      }}
    />
  )
}

export default SelectFilter
