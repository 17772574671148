import get from 'lodash/get'
import { currency2Num } from 'utils/formatting'
import moment from 'moment'

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'vendor__vendor_name':
      return cellContent.toLowerCase()
    case 'referral_vendor_contact__family_name':
      return cellContent.toLowerCase()
    case 'po__po':
      return cellContent.toLowerCase()
    case 'sum_total':
      return currency2Num(cellContent)
    case 'timekeeper_count':
      return Number(cellContent)
    case 'notified_date':
      return moment(cellContent).format('MM/DD/YYYY')
    case 'last_requested_budget_date':
      return moment(cellContent).format('MM/DD/YYYY')
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const getColumns = hasPurchaseOrders => {
  return [
    {
      columnKey: 'vendor__vendor_name',
      content: 'Vendor',
      isSortable: true,
      isFilterable: true,
      render: (cell, row) => {
        return <a href={`/invoices/vendors/${row.vendor_id}`}>{cell.content}</a>
      }
    },
    {
      columnKey: 'referral_vendor_contact_name',
      content: 'Lead',
      isSortable: true,
      isFilterable: true
    },
    ...(hasPurchaseOrders
      ? [
          {
            columnKey: 'po__po',
            content: 'PO Number',
            isSortable: true,
            isFilterable: true
          }
        ]
      : []),
    {
      columnKey: 'sum_total',
      content: 'Billed to Date',
      isSortable: true,
      isFilterable: true,
      style: { textAlign: 'right' }
    },
    {
      columnKey: 'timekeeper_count',
      content: 'Timekeepers',
      isSortable: true,
      isFilterable: true,
      style: { textAlign: 'right' }
    },
    {
      columnKey: 'last_requested_budget_date',
      content: 'Budget Last Requested',
      isSortable: true,
      isFilterable: true,
      style: { textAlign: 'right' },
      render: cell => {
        return cell.content !== '----' ? moment(cell.content).format('MM/DD/YYYY') : cell.content
      }
    },
    {
      columnKey: 'notified_date',
      content: 'Last Notified',
      isSortable: true,
      isFilterable: true,
      style: { textAlign: 'right' },
      render: cell => {
        return cell.content !== '----' ? moment(cell.content).format('MM/DD/YYYY') : cell.content
      }
    }
  ]
}
