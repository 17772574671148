import { CSSProperties, useMemo, useState } from 'react'
import s from './PromptsList.scss'
import { FaSearch } from 'react-icons/fa'
import { Prompt } from './Prompt'
import { PromptsListItem } from '../types'
import pluralize from 'pluralize'
import { LoadingSkeleton } from 'simple-core-ui'

interface Props {
  prompts: PromptsListItem[]
  setPrompt: (prompt: string) => void
  styles: CSSProperties
  isHistory?: boolean
  isLoading?: boolean
}

const PromptsList = ({
  prompts,
  setPrompt,
  styles,
  isHistory = false,
  isLoading = false
}: Props) => {
  const [searchPhrase, setSearchPhrase] = useState('')

  const listItems = useMemo(
    () =>
      prompts
        .filter(({ text }) => text.toLowerCase().includes(searchPhrase.toLowerCase()))
        .map((prompt, idx) => (
          <Prompt key={idx} prompt={prompt} cb={setPrompt} isHistoryItem={isHistory} />
        )),
    [isLoading]
  )

  return (
    <div className={s.container} style={styles}>
      <div className={s.headerWrapper}>
        {isHistory ? (
          <label className={s.headerTitle}>{`Your last ${pluralize(
            'prompt',
            prompts.length,
            true
          )}`}</label>
        ) : (
          <>
            <FaSearch className={s.searchIcon} />
            <input
              onChange={e => {
                const { value } = e.target
                setSearchPhrase(value)
              }}
              className={s.input}
              placeholder="Search"
            />
          </>
        )}
      </div>
      {isLoading ? (
        // @ts-expect-error
        <LoadingSkeleton />
      ) : (
        <div className={s.promptsListWrapper}>
          {prompts.length ? listItems : <p className={s.blankText}>No prompts found.</p>}
        </div>
      )}
    </div>
  )
}

export default PromptsList
