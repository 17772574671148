import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  deleteParam(): void
  copyParam(): void
  isCopyDisabled: boolean
}

const ActionsPopover = ({ deleteParam, copyParam, isCopyDisabled }: Props) => {
  const actions = [
    {
      name: 'CLONE',
      text: 'Clone',
      visible: !isCopyDisabled,
      onClick() {
        copyParam()
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteParam()
      }
    }
  ]

  return (
    <Popover
      testId="operand-actions"
      trigger={<HoverAction icon={<IoIosMore />} size="medium" className={s.hoverAction} />}
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
