import { RadioFilter } from 'common/Filters'

const SizeFilter = ({ onSelect, reset, hasConfirmation }) => {
  return (
    <RadioFilter
      title="Size"
      hasConfirmation={hasConfirmation}
      reset={reset}
      onSelect={onSelect}
      options={[
        {
          value: '',
          label: 'Any Size'
        },
        {
          value: '0-5000000',
          label: '0-5 MB'
        },
        {
          value: '5000000-25000000',
          label: '5-25 MB'
        },
        {
          value: '100000000-500000000',
          label: '100-500 MB'
        },
        {
          value: '500000000-1000000000',
          label: '500 - 1 GB'
        },
        {
          value: '1000000000-',
          label: '1 GB+'
        }
      ]}
    />
  )
}

export default SizeFilter
