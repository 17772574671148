import LandingPageItem from './LandingPageItem'
import s from '../styles/LandingPage.scss'
import { TextInput } from 'simple-core-ui'

const LandingPage = ({ landingPage, filteredPages, filterQuery, isFiltering, filterPages }) => (
  <section className={s.container}>
    <header className={s.header}>
      <h2>{landingPage}</h2>
      <div className={s.filterContainer}>
        {'Filter: '}
        <TextInput
          onChange={filterPages}
          value={filterQuery}
          isLoading={isFiltering}
          testid="admin_search_txt_box"
        />
      </div>
    </header>
    <main className={s.main}>
      <ul className={s.list}>
        {filteredPages.length ? (
          filteredPages.map(el => <LandingPageItem {...el} key={el.name} />)
        ) : (
          <li className={s.emptyList}>{'[ no pages found ]'}</li>
        )}
      </ul>
    </main>
  </section>
)

export default LandingPage
