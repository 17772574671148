import { useContext } from 'react'
import { Button, HoverAction, If } from 'simple-core-ui'
import { IoIosTrash } from 'react-icons/io'
import LhsContainer from '../containers/LhsContainer'
import RhsContainer from '../containers/RhsContainer'
import OperatorContainer from '../containers/OperatorContainer'
import { EngineContext } from 'rules/context'
import { UNARY_OPERATORS } from 'data/operators'
import s from '../styles/Operand.scss'

const isUnaryOperator = operator => UNARY_OPERATORS.includes(operator)

const Operand = ({
  operand,
  choices,
  index,
  ruleIndex,
  onHover,
  isOver,
  className,
  refs,
  coords,
  add,
  remove,
  addOr,
  availableFields,
  setLhs,
  setRhs
}) => {
  const { canEdit } = useContext(EngineContext)

  return (
    <li className={s.container}>
      <LhsContainer lhs={operand.lhs} availableFields={availableFields} onChange={setLhs} />
      <OperatorContainer
        operandIndex={index}
        lhs={operand.lhs}
        operator={operand.op}
        ruleIndex={ruleIndex}
        refs={refs}
        coords={coords}
      />
      <If condition={operand.op && !isUnaryOperator(operand.op)}>
        <RhsContainer
          lhs={operand.lhs}
          operator={operand.op}
          rhs={operand.rhs}
          availableFields={availableFields}
          onChange={setRhs}
        />
      </If>

      <If condition={canEdit}>
        <span className={s.opButtonGroup}>
          <Button isSecondary className={s.operandButton} onClick={() => add('AND')}>
            And
          </Button>
          <Button isSecondary className={s.operandButton} onClick={() => add('OR')}>
            Or
          </Button>
          <HoverAction
            onClick={remove}
            tip="Remove Condition"
            icon={<IoIosTrash />}
            className={s.removeCondition}
          />
        </span>
      </If>
    </li>
  )
}

export default Operand
