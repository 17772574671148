import { Component } from 'react'
import { DataTableContainer, DataTableService } from 'simple-core-ui'
import s from './AnswersTable.scss'
import { getNormalizedCellContent } from 'vendors/scorecard/utils'

class AnswersTable extends Component {
  state = {
    params: {
      pageSize: 10,
      ordering: { columnKey: 'date', isDesc: true },
      search: '',
      page: 1,
      category: 'all'
    }
  }
  updateTable = params => {
    this.setState({
      params
    })
  }
  render() {
    const { answers } = this.props
    const { columns, rows } = this.props.rowsSerializer(answers)

    const scorecardsService = new DataTableService(
      {
        all: rows
      },
      columns,
      getNormalizedCellContent
    )

    const results = scorecardsService.getUpdatedResults(this.state.params)

    return (
      <DataTableContainer
        initialValues={this.state.params}
        categories={[]}
        previousPage={results.previousPage}
        nextPage={results.nextPage}
        totalEntries={results.totalEntries}
        filteredTotal={results.filteredTotal}
        rows={results.rows}
        columns={results.columns}
        updateTable={this.updateTable}
        hasActionsHeader={false}
        statusText=""
        panelStyles={{ border: 'none', padding: '0' }}
        className={s.answersTable}
      />
    )
  }
}

export default AnswersTable
