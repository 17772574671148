import { PaginatedSelect } from 'simple-core-ui'
import { toBudgetIdsOptions } from '../serializers'

const URL = '/react_select'

const BudgetIdSelect = ({
  value,
  isClearable,
  onChange,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  filterOption,
  defaultOptions,
  defaultAction,
  id
}) => {
  return (
    <PaginatedSelect
      url={URL}
      value={value}
      serializer={toBudgetIdsOptions}
      onChange={onChange}
      isMulti={isMulti}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      searchTermParam="search"
      placeholder="Search id..."
      hasMoreProperty="has_more"
      resultsProperty="options"
      limit={limit}
      filterOption={filterOption}
      mandatoryQueryParams={{ model: 'budget_ids' }}
    />
  )
}

export default BudgetIdSelect
