import React from 'react'
import { connect } from 'react-redux'
import SharedEvaluators from './SharedEvaluators'
import {
  SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_REQUESTED,
  SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED,
  SURVEY_CONFIG_USERS_FETCH_REQUESTED
} from '../actions'
import debounce from 'lodash/debounce'

@connect(({ surveyConfig }) => {
  const { users, roles, sharedEvaluators, templateDefaults } = surveyConfig
  return {
    roles,
    users,
    sharedEvaluators,
    templateDefaults
  }
})
class SharedEvaluatorsContainer extends React.Component {
  updateEvaluators = (templateDefaults = '', force = false) => {
    let { sharedEvaluators, dispatch, entityId } = this.props
    let usersArr = sharedEvaluators.users.map(user => user.value)
    let rolesArr = sharedEvaluators.roles.map(role => role.value)
    let status = sharedEvaluators.status

    if (!sharedEvaluators.hasChanged && !force) return

    if (templateDefaults) {
      usersArr = templateDefaults.users.map(user => user.value)
      rolesArr = templateDefaults.roles.map(role => role.value)
      status = templateDefaults.status
    }

    dispatch({
      type: SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED,
      payload: {
        entityId,
        users: usersArr,
        roles: rolesArr,
        status
      }
    })

    this.props.hasChangedSection()
  }
  getUsersCb = debounce(search => {
    this.props.dispatch({
      type: SURVEY_CONFIG_USERS_FETCH_REQUESTED,
      payload: {
        search
      }
    })
  }, 500)
  resetDefault = async () => {
    await this.props.dispatch({
      type: SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_REQUESTED,
      payload: {
        entityId: this.props.entityId
      }
    })

    window._setSurveyStatusToTemplateDefault(this.props.templateDefaults.status, false)
  }
  render() {
    return (
      <SharedEvaluators
        {...this.props}
        changeAttributeValue={this.props.changeAttributeValue}
        loadCb={this.getUsersCb}
        updateEvaluators={this.updateEvaluators}
        resetDefault={this.resetDefault}
      />
    )
  }
}

export default SharedEvaluatorsContainer
