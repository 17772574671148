import get from 'lodash/get'
import moment from 'moment'

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
      return cellContent.toLowerCase()
    case 'description':
      return cellContent.toLowerCase()
    case 'levels':
      return +cellContent
    case 'related':
      return +cellContent
    case 'last_updated':
      return moment(cellContent).valueOf()
    case 'status':
      return cellContent.toLowerCase()
    case 'needs_update':
      return cellContent.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
