import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

const ActionsPopover = ({ rowId, actions }) => {
  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions.map(({ key, label, callback }) => ({
        name: key + label,
        text: label,
        onClick: () => callback(rowId)
      }))}
      border
    />
  )
}

export default ActionsPopover
