import { Fragment } from 'react'
import Select from 'react-select'
import { Modal, Input, TextInput } from 'simple-core-ui'

const ClientKeyModal = ({ showForCreate, onCancel, onSubmit, ...clientKeyFormProps }) => (
  <Modal
    isVisible
    content={<ClientKeyForm {...clientKeyFormProps} />}
    title={showForCreate ? 'Create New ClientKey' : 'Update ClientKey'}
    confirmCb={onSubmit}
    confirmText={showForCreate ? 'Create' : 'Update'}
    cancelCb={onCancel}
    cancelText="Cancel"
    isDisabled={!clientKeyFormProps.type}
    size="sm"
  />
)

const TypeOptions = [
  { value: 's3_erp_export', label: 'Keys for s3 buckets to export files to, specifically for ERP' },
  { value: 'sftp', label: 'SFTP keys for placing files on our/customer SFTP servers' },
  { value: 'gpg', label: 'GPG keys for encrypting/decripting files on AWS Lambda' },
  { value: 'email', label: 'email only' },
  { value: 'document_management', label: 'Document management settings' },
  { value: 'calendar_rules', label: 'Calendar Rules settings' }
]

const ClientKeyForm = ({
  key_val,
  type,
  secret_key,
  other_key,
  bucket,
  bucket_path,
  custom_field1_name,
  custom_field1,
  custom_field2_name,
  custom_field2,
  onUpdateTextField
}) => (
  <Fragment>
    <p>
      <label>Key Type</label>
    </p>
    <section>
      <Select
        placeholder="Type"
        value={{
          value: type,
          label: type
        }}
        onChange={option => onUpdateTextField('type', option.value)}
        backspaceRemovesValue={false}
        isClearable={false}
        options={TypeOptions}
      />
    </section>
    <br></br>
    <p>
      <label>Key</label>
    </p>
    <TextInput
      type="textarea"
      onChange={value => onUpdateTextField('key_val', value)}
      placeholder="Key"
      value={key_val}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Secret Key</label>
    </p>
    <Input
      type="password"
      onChangeCb={({ target: { value } }) => onUpdateTextField('secret_key', value)}
      placeholder="Secret Key"
      text={secret_key || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Other Key</label>
    </p>
    <TextInput
      type="textarea"
      onChange={value => onUpdateTextField('other_key', value)}
      placeholder="Other Key"
      value={other_key}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Bucket</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('bucket', value)}
      placeholder="Bucket"
      text={bucket || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Bucket Path</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('bucket_path', value)}
      placeholder="Bucket Path"
      text={bucket_path || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Custom Field1 Name</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('custom_field1_name', value)}
      placeholder="Custom Field1 Name"
      text={custom_field1_name || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Custom Field1</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('custom_field1', value)}
      placeholder="Custom Field1"
      text={custom_field1 || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Custom Field2 Name</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('custom_field2_name', value)}
      placeholder="Custom Field2 Name"
      text={custom_field2_name || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
    <p>
      <label>Custom Field2</label>
    </p>
    <Input
      onChangeCb={({ target: { value } }) => onUpdateTextField('custom_field2', value)}
      placeholder="Custom Field2"
      text={custom_field2 || ''}
      style={{ marginBottom: '12px', maxWidth: '256px' }}
    />
  </Fragment>
)

export default ClientKeyModal
