import ACT from './actions'
import { put, takeLatest } from 'redux-saga/effects'
import { SagaQuest, withQuest } from 'simple-core-ui/fetchFlow'
import {
  getCreateEventUrl,
  getFetchEventsUrl,
  getUpdateEventUrl,
  getDeleteEventUrl,
  getFetchCalendarListUrl,
  getCreateCalendarListEventUrl
} from './urls'
import { toEvent, fromEvents, fromCalendarList, toCalendarListEvent } from './serializers'
import mockEvents from './mockEvents'

const eventQuest = new SagaQuest({ entity: 'Event' })

function* updateEvent(action) {
  const { matterId, event } = action.payload
  const url = getUpdateEventUrl(matterId, event.id)
  const newEvent = toEvent(event)
  const response = yield eventQuest.makePost(url, newEvent)

  yield put({
    type: ACT.EVENT_UPDATE_SUCCESS,
    loadingLock: 'off',
    payload: {
      event
    }
  })
}

function* createEvent(action) {
  const { matterId, event } = action.payload
  const url = getCreateEventUrl(matterId)
  const response = yield eventQuest.makePost(url, toEvent(event))

  yield put({
    type: ACT.EVENT_CREATE_SUCCESS,
    loadingLock: 'off',
    payload: {}
  })

  yield put({
    type: ACT.EVENTS_FETCH_REQUESTED,
    payload: {}
  })
}

function* createCalendarListEvent(action) {
  const { scopeId, event } = action.payload
  const url = getCreateCalendarListEventUrl(scopeId)
  const response = yield eventQuest.makePost(url, event)

  yield put({
    type: ACT.CALENDAR_LIST_CREATE_EVENT_SUCCESS,
    loadingLock: 'off',
    payload: {
      event: toCalendarListEvent(event)
    }
  })
}

function* fetchEvents(action) {
  const { matterId } = action.payload
  let url = getFetchEventsUrl(matterId)
  // TODO: hook up to real endpoint
  // const response = yield eventQuest.makeGet(url)
  const response = { events: mockEvents }

  yield put({
    type: ACT.EVENTS_FETCH_SUCCESS,
    loadingLock: 'off',
    payload: {
      events: fromEvents(response.events)
    }
  })
}

function* fetchCalendarList(action) {
  const { scopeId } = action.payload
  let url = getFetchCalendarListUrl(scopeId)
  const { calendars } = yield eventQuest.makeGet(url)

  yield put({
    type: ACT.CALENDAR_LIST_FETCH_SUCCESS,
    loadingLock: 'off'
  })

  yield put({
    type: ACT.EVENTS_FETCH_SUCCESS,
    payload: {
      events: fromCalendarList(calendars)
    }
  })
}

function* deleteEvent(action) {
  const { matterId, id } = action.payload
  let url = getDeleteEventUrl(matterId, id)
  const response = yield eventQuest.makeDelete(url)

  yield put({
    type: ACT.EVENT_DELETE_SUCCESS,
    loadingLock: 'off',
    payload: {
      id
    }
  })
}

const eventSagas = [
  takeLatest(ACT.EVENT_CREATE_REQUESTED, withQuest(createEvent)),
  takeLatest(ACT.EVENT_UPDATE_REQUESTED, withQuest(updateEvent)),
  takeLatest(ACT.EVENTS_FETCH_REQUESTED, withQuest(fetchEvents)),
  takeLatest(ACT.EVENT_DELETE_REQUESTED, withQuest(deleteEvent)),
  takeLatest(ACT.CALENDAR_LIST_FETCH_REQUESTED, withQuest(fetchCalendarList)),
  takeLatest(ACT.CALENDAR_LIST_CREATE_EVENT_REQUESTED, withQuest(createCalendarListEvent))
]

export default eventSagas
