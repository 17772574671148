import { Fragment } from 'react'
import FiltersContainer, { SelectFilter } from 'common/Filters'
import Select from 'react-select'

const Filters = ({ filterParams, onSearch, vendors, periods }) => {
  const { period, vendor } = filterParams
  return (
    <FiltersContainer
      onSearch={onSearch}
      filterParams={filterParams}
      render={(reset, filterSearch) => {
        return (
          <Fragment>
            <SelectFilter
              title="Vendor"
              reset={reset}
              onSelect={value => onSearch(value, 'vendor')}
              initialValue={vendor}
              render={(vendor, setVendor) => {
                return (
                  <Select
                    value={vendor}
                    options={vendors}
                    onChange={item => {
                      setVendor(item)
                    }}
                    isPortal={false}
                    isClearable
                  />
                )
              }}
            />
            <SelectFilter
              title="Surveyed Period"
              reset={reset}
              onSelect={value => onSearch(value, 'period')}
              initialValue={period}
              render={(period, setPeriod) => {
                return (
                  <Select
                    value={period}
                    options={periods}
                    onChange={item => {
                      setPeriod(item)
                    }}
                    isPortal={false}
                    isClearable
                  />
                )
              }}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default Filters
