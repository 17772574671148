import cloneDeep from 'lodash/cloneDeep'
import { REQUEST_EXTRA_FIELDS } from './constants'

export const getRequestItemValue = value => {
  return value && typeof value === 'object' && value !== null
    ? `${value.amount} ${value.currency_code}`
    : value
}

export const getRequestRepresentation = additionalFields => {
  const fields = cloneDeep(additionalFields)
  fields &&
    fields.map(field => {
      if (
        [REQUEST_EXTRA_FIELDS.REVIEWED_BY, REQUEST_EXTRA_FIELDS.COMMENTS].includes(field.key) &&
        !field.value
      ) {
        field.value = '-----'
      }
      return field
    })
  return fields
}

export const limitCommentCharacters = comment => {
  const charLimit = 100
  return comment.length > charLimit ? comment.substring(0, charLimit - 3) + '...' : comment
}
