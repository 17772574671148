import { APITemplate, Template } from './types'

export const toTemplates = (templates: APITemplate[]): Template[] => {
  return templates.map((template: APITemplate) => {
    const { id, name, is_active, related_tasks, matter_group, description } = template

    return {
      value: id,
      label: name,
      matterGroup: matter_group?.name ?? '',
      relatedTasks: String(related_tasks),
      isActive: is_active,
      description
    }
  })
}
