export const TITLE = 'Invoice Preferences'

export const columns = [
  {
    columnKey: 'code',
    content: 'Adjustment Code',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'description',
    content: 'Reason',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'long_description',
    content: 'Description',
    isSortable: true,
    isFilterable: true
  }
]
