import { sortAlphabeticallyByProperty } from 'utils/helpers'

export const getStandardReports = (reports, supersetReports) =>
  Object.keys(reports).reduce(
    (acc, key) => ({
      ...acc,
      [key]: supersetReports[key]
        ? sortAlphabeticallyByProperty([...reports[key], ...supersetReports[key].reports], 'text')
        : reports[key]
    }),
    {}
  )

export const getFilteredSupersetReports = (reports, supersetReports) => {
  const standardKeys = Object.keys(reports)
  return Object.keys(supersetReports).reduce(
    (acc, key) =>
      standardKeys.includes(key)
        ? acc
        : { ...acc, [key]: sortAlphabeticallyByProperty(supersetReports[key].reports, 'text') },
    {}
  )
}

export const getAllStandardReports = (reports, supersetReports) => {
  const standardReports = getStandardReports(reports, supersetReports)
  const filteredSupersetReports = getFilteredSupersetReports(reports, supersetReports)
  return Object.keys({ ...standardReports, ...filteredSupersetReports })
    .sort()
    .reduce(
      (acc, key) => ({ ...acc, [key]: standardReports[key] || filteredSupersetReports[key] }),
      {}
    )
}
