import swal from 'sweetalert'
import { renderToString } from 'react-dom/server'

const withSweetAlert = Component => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = renderToString(Component)

  return async (options, callback, additionalParams) => {
    const willAccept = await swal({
      ...options,
      content: wrapper
    })
    if (willAccept) {
      if (additionalParams.okConfirmation) {
        swal(
          additionalParams.successAction || 'Saved!',
          additionalParams.successText || 'Your changes have been saved.',
          additionalParams.successStatus || 'success'
        )
      }
      callback && callback()
    } else if (!willAccept) {
      if (additionalParams.cancelConfirmation) {
        swal(
          additionalParams.cancelAction || 'Cancelled',
          additionalParams.cancelText || 'Your changes will not be saved.',
          additionalParams.cancelStatus || 'error'
        )
      }
      additionalParams.onCancel && additionalParams.onCancel()
    }
  }
}

export default withSweetAlert
