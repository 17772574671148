import sortBy from 'lodash/sortBy'
import { fes } from 'simple-core-ui'

export const SPECIFIC_BULK_ACTIONS: { [key: string]: { label: string; value: string }[] } = {
  Mine: [
    { label: 'Approve', value: 'approve' },
    { label: 'Reject', value: 'reject' }
  ],
  Approved: [{ label: 'Mark as Paid', value: 'mark_paid' }]
}

export const COMMON_BULK_ACTIONS = [
  { label: 'Download CSV', value: 'DownloadCsv' },
  { label: 'Download PDF', value: 'DownloadPdf' },
  { label: 'Download Attachments', value: 'DownloadAttachments' }
]

export const BULK_ACTIONS = (tabName: string) => {
  const options = [...COMMON_BULK_ACTIONS, ...(SPECIFIC_BULK_ACTIONS[tabName] ?? [])]
  return sortBy(options, ['label'])
}

export const DIALOG_BULK_ACTIONS = ['approve', 'mark_paid']

export const BULK_WARNING_MESSAGES = (invoiceLength: number) => ({
  approve: {
    title: `You are approving ${fes(invoiceLength, 'selected invoices')}`,
    text: 'Are you sure you want to approve?',
    reduxAction: 'SELECTED_INVOICES_APPROVED'
  },
  mark_paid: {
    title: `You are marking as paid ${fes(invoiceLength, 'selected invoices')}`,
    text: 'Are you sure you want to mark as paid?',
    reduxAction: 'SELECTED_INVOICES_PAID'
  }
})
