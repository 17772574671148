import { Fragment } from 'react'
import s from './BulkEditBudgetModal.scss'
import { PracticeAreaSelect, VendorSelect, MatterSelect, CostCodeSelect } from 'common/Selects'

const BulkEditBudgetModal = ({
  newVendor,
  newMatter,
  newPracticeArea,
  newCostCode,
  setNewVendor,
  setNewMatter,
  setNewPracticeArea,
  setNewCostCode,
  matterGroupLabels,
  selectedFieldsLength,
  setSelectedFieldsLength
}) => {
  const updateFieldsLength = actionType => {
    actionType === 'clear'
      ? setSelectedFieldsLength(selectedFieldsLength - 1)
      : setSelectedFieldsLength(selectedFieldsLength + 1)
  }

  return (
    <Fragment>
      <div className={s.row}>
        <label>Matter</label>
        <h4 className={s.subtitle}>Please, choose the matter</h4>
        <MatterSelect
          value={newMatter}
          onChange={(value, actionType) => {
            setNewMatter(value)
            updateFieldsLength(actionType)
          }}
          isPortal
          isClearable
        />
      </div>
      <div className={s.row}>
        <label>Vendor</label>
        <h4 className={s.subtitle}>Please, choose the vendor</h4>
        <VendorSelect
          value={newVendor}
          onChange={(value, actionType) => {
            setNewVendor(value)
            updateFieldsLength(actionType)
          }}
          isPortal
          isClearable
        />
      </div>
      <div className={s.row}>
        <label>{matterGroupLabels[0]}</label>
        <h4 className={s.subtitle}>Please, choose the {matterGroupLabels[0]}</h4>
        <PracticeAreaSelect
          value={newPracticeArea}
          onChange={(value, actionType) => {
            setNewPracticeArea(value)
            updateFieldsLength(actionType)
          }}
          isPortal
          isClearable
          placeholder={`Search ${matterGroupLabels[0]}`}
        />
      </div>
      <div className={s.row}>
        <label>Cost Code</label>
        <h4 className={s.subtitle}>Please, choose the cost code</h4>
        <CostCodeSelect
          value={newCostCode}
          onChange={(value, actionType) => {
            setNewCostCode(value)
            updateFieldsLength(actionType)
          }}
          isPortal
          isClearable
        />
      </div>
    </Fragment>
  )
}

export default BulkEditBudgetModal
