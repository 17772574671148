import React from 'react'

const RequestFormList = ({ forms, additionalText = '' }) => (
  <React.Fragment>
    <p>
      {additionalText && <p>{additionalText}</p>}
      <b>The following request forms will be impacted: </b>
    </p>
    {forms.map(form => (
      <p key={form.id}>
        <a
          href={`/manage/matters/requests/form/${form.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {form.name}
        </a>
      </p>
    ))}
  </React.Fragment>
)

export default RequestFormList
