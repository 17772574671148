import find from 'lodash/find'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import * as ACT from './actions'

export const getAttributeValue = attribute => {
  if (attribute.type === 'currency') {
    return getCurrencyAttributeValue(attribute)
  } else {
    return typeof attribute.value === 'object' && attribute.value !== null
      ? attribute.value.value
      : attribute.value
  }
}

export const getAttributeLabel = attribute => {
  if (attribute.type === 'currency') {
    return getCurrencyAttributeLabel(attribute)
  } else if (attribute.type === 'boolean') {
    return getBooleanAttributeLabel(attribute)
  } else {
    return get(attribute, 'value.label', attribute.value) || '-'
  }
}

export const getCurrencyAttributeLabel = attribute => {
  let currency
  if (typeof attribute.value === 'object' && attribute.value !== null) {
    currency = {
      amount: attribute.value.amount,
      code: attribute.value.code
    }
  } else {
    currency = attribute
  }
  return `${get(currency, 'amount', '')} ${get(currency, 'code', '') || ''}`
}

export const getCurrencyAttributeValue = attribute => {
  let code
  if (typeof attribute.value.code === 'object') {
    code = attribute.value.code === null ? '' : attribute.value.code.value
  } else {
    code = attribute.value.code
  }
  return {
    amount: get(attribute, 'value.amount'),
    code: code
  }
}

const getBooleanAttributeLabel = attribute => {
  const value =
    typeof attribute.value === 'object' && attribute.value !== null
      ? attribute.value.value
      : attribute.value
  return value === null ? '' : attribute.options.filter(attr => attr.value === value)[0].label
}

export const isAttributeValueEmpty = attrValue => {
  return (!attrValue && attrValue !== 0) || attrValue.toString() === '-1'
}

export const categoryHasAttributes = (category = []) => {
  return !!find(category, section => section.attributes.length)
}

export const getHasAllocations = allocations => {
  return !!(
    allocations.length &&
    (allocations.length > 1 || get(allocations, '[0].columns[0].value') === 100)
  )
}

export const getHasAdditionalAttributes = additionalAttributes => {
  return !!additionalAttributes.some(attribute => !attribute.not_in_template)
}

export const isDifferentAttributeValue = (templateValue, mrValue, type) => {
  return getComparableValue(templateValue, type) !== getComparableValue(mrValue, type)
}

const getComparableValue = (val, type) => {
  let result

  if (typeof val === 'object') {
    result = type === 'currency' ? getCurrencyAttributeLabel(val) : get(val, 'value', -1)
  } else if (!isNaN(val) && val !== '') {
    result = Number(val)
  } else {
    result = type === 'date' ? moment(val).format('MM/DD/YYYY') : val
  }

  return result
}

export const toDecimalRepresentation = value => {
  return Number(Number(value).toFixed(4))
}

export const canSubmitDrawer = attributes => {
  return (
    attributes.filter(attr => {
      const value =
        typeof attr.value === 'object' && attr.value !== null
          ? attr.type === 'currency'
            ? attr.value.amount && attr.value.code
            : attr.value.value
          : attr.value
      if (attr.type === 'relationship') {
        return attr.required && attr.attributes.some(attr => !attr.value)
      }
      return attr.required && ((!value && value !== 0) || value.toString() === '-1')
    }).length > 0
  )
}

export const getAttributesFromTemplate = template => {
  const categories = ['left', 'right']
  let attributes = []
  categories.forEach(category => {
    template[category].forEach(section => {
      section.attributes.forEach(attr => {
        attributes.push(cloneDeep(attr))
      })
    })
  })
  return attributes
}

export const navigateToProcessRequestsPage = (navState, navigate, dispatch) => {
  if (navState && navState.fromProcessPage) {
    navigate('/v2/matters/requests/process/')
  } else {
    window.location.href = '/manage/matters/requests/list/'
  }

  dispatch({
    type: ACT.MATTER_TEMPLATE_SELECTION_RESET_STATE
  })
}

export const isDynamicDropdownDisabled = (attribute, index) => {
  const attr = attribute.attributes[index - 1]
  return attr && (!attr.value || +attr.value === -1)
}

export const removeAttributeOptionsAndDefaultOptions = attribute => {
  if (attribute.options) {
    attribute.options = attribute.options.filter(o => o.value === attribute.value?.value)
  }
  if (attribute.defaultOptions) {
    delete attribute.defaultOptions
  }
}

export const getTemplateAttributesMap = template => {
  const { left = [], right = [] } = template
  return [...left, ...right].reduce((acc, section) => {
    Array.isArray(section.attributes) &&
      section.attributes.forEach(attr => {
        acc[attr.id] = attr
      })
    return acc
  }, {})
}

export const isRequired = (attr, templateAttributeMap) => {
  return !!attr?.required || !!templateAttributeMap[attr.id]?.required
}
