import cn from 'classnames'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { Button } from 'simple-core-ui'
import Action from './action'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { Action as ActionType } from 'simple_review/@types/api'
import { Rule } from 'simple_review/@types/editor'
import { btnStyling } from 'simple_review/editor/styles'
import s from './Actions.scss'
import es from '../EditorContent.scss'

const EMPTY_ACTION = { name: '', params: [] }
const ACTIONS_LIMIT = 8

interface Props {
  rule: Rule
  isReadOnly: boolean
  onChangeActions(newActions: Array<ActionType>): void
}

const Actions = ({ rule, isReadOnly, onChangeActions }: Props) => {
  const {
    state: { availableActions }
  } = useSimpleReviewContext()
  const addLabel = !rule.actions.length ? '+ Add actions' : '+ Add additional action'

  const addAction = () => {
    onChangeActions([...rule.actions, EMPTY_ACTION])
  }

  const removeAction = (index: number) => {
    onChangeActions(rule.actions.filter((_, idx) => index !== idx))
  }

  const updateActions = (updatedAction: ActionType, index: number): void => {
    const updatedActions = rule.actions.map((action, i) => (i === index ? updatedAction : action))
    onChangeActions(updatedActions)
  }

  return (
    <div className={cn('box', es.cellContainer)}>
      <div className={cn(es.label, es.bigMargin)}>
        <span className={es.required}>* </span>ACTIONS:
      </div>
      <div className={s.content}>
        {rule.actions.map((action, index) => (
          <div key={`${action.name}${index}`} className={s.actionsContainer}>
            {!!index && (
              <div className={s.operator}>
                <Button style={{ borderRadius: '12px', padding: '2px 20px', margin: '20px 0' }}>
                  AND
                </Button>
              </div>
            )}
            <Action
              action={action}
              actionIndex={index}
              availableActions={sortAlphabeticallyByProperty(availableActions, 'display_name')}
              isReadOnly={isReadOnly}
              removeAction={() => removeAction(index)}
              updateAction={updatedAction => updateActions(updatedAction, index)}
            />
          </div>
        ))}
        {!isReadOnly && (
          <Button
            isSecondary
            style={btnStyling}
            onClick={addAction}
            isDisabled={rule.actions.length >= ACTIONS_LIMIT}
          >
            {addLabel}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Actions
