import { Component } from 'react'
import s from './Thermometer.scss'
import { thermometerColors } from 'styles/cssInJs'
import { currency2Num } from 'utils/formatting'
import ColorBar from './ColorBar'
import DataTip from './DataTip'
import { isNumberRenderable } from 'utils/helpers'

//expects currencies e.g. $2,000.00
class Thermometer extends Component {
  static defaultProps = {
    isDetail: true
  }

  state = {
    isHovering: false
  }

  perc2Width = perc => {
    if (isNaN(perc) || !isFinite(perc)) {
      return '0%'
    }

    if (Number(perc) > 100) {
      return '100%'
    }

    return perc + '%'
  }

  getBudgetPerc = (invoicedAmount, budgetedAmount) => {
    return Math.round((currency2Num(invoicedAmount) / currency2Num(budgetedAmount)) * 100)
  }

  showDataTip = () => {
    this.setState({
      isHovering: true
    })
  }

  hideDataTip = () => {
    this.setState({
      isHovering: false
    })
  }

  render() {
    const {
      total,
      approved,
      pending,
      accrual,
      combined,
      disableDataTip,
      disableFooter
    } = this.props
    const { isHovering } = this.state
    const { IN_BUDGET, OVERBUDGET } = thermometerColors

    const approvedPercentage = this.getBudgetPerc(approved, total)
    const pendingPercentage = this.getBudgetPerc(pending, total)
    const accrualPercentage = this.getBudgetPerc(accrual, total)
    const totalPercentage = approvedPercentage + pendingPercentage
    const totalPercentageWithAccrual = approvedPercentage + pendingPercentage + accrualPercentage

    let approvedWidth = this.perc2Width(approvedPercentage)
    let pendingWidth = this.perc2Width(pendingPercentage)
    let accrualWidth = this.perc2Width(accrualPercentage)

    let totalWidth = this.perc2Width(totalPercentageWithAccrual)

    let colors
    if (totalPercentageWithAccrual <= 100 || isNaN(totalPercentageWithAccrual)) {
      colors = IN_BUDGET
    } else {
      colors = OVERBUDGET
    }

    // for X / 0 cases
    if (currency2Num(total) === 0 && currency2Num(pending) > 0) {
      colors = OVERBUDGET
      totalWidth = '100%'
      pendingWidth = '100%'
    }

    if (currency2Num(total) === 0 && currency2Num(approved) > 0) {
      colors = OVERBUDGET
      totalWidth = '100%'
      approvedWidth = '100%'
    }

    return (
      <div className={s.wrapper} onMouseEnter={this.showDataTip} onMouseLeave={this.hideDataTip}>
        <div className={s.thermometerContainer}>
          <div className={s.thermometer}>
            <ColorBar background={colors.APPROVED} width={approvedWidth} left="0" />
            <ColorBar background={colors.PENDING} width={pendingWidth} left={approvedWidth} />
            <ColorBar
              background={colors.ACCRUAL}
              width={accrualWidth}
              left={this.perc2Width(approvedPercentage + pendingPercentage)}
            />
            <div
              className={s.tick}
              style={{
                left: totalWidth
              }}
            />

            {!disableFooter && (
              <div className={s.totalSpent}>
                Total: {combined}{' '}
                {isNumberRenderable(totalPercentage) ? (
                  <span>({totalPercentage + '%'})</span>
                ) : null}
              </div>
            )}
          </div>
        </div>
        {isHovering && !disableDataTip && (
          <DataTip
            colors={colors}
            approved={approved}
            pending={pending}
            accrual={accrual}
            approvedPercentage={approvedPercentage}
            pendingPercentage={pendingPercentage}
            accrualPercentage={accrualPercentage}
            total={total}
            totalPercentage={totalPercentage}
          />
        )}
      </div>
    )
  }
}

export default Thermometer
