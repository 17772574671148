import { Tooltip, CharLimitInput, PaginatedSelect, Ellipsis } from 'simple-core-ui'
import { DatePickerSelect } from 'common/Selects'
import { TimePicker } from 'common/TimePicker'
import s from '../AddCourtRulesModal.scss'
import { Jurisdiction, Option, SetupObject, TriggerOption } from '../types'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { toOptions } from '../serializers'
import { APISimpleMatter, MatterOption } from 'common/Events/types'
import { Dispatch, SetStateAction } from 'react'
import Select, { OptionProps, components } from 'react-select'
import cn from 'classnames'
import { toMattersOptions } from 'common/Tasks/serializers'

const CustomOption = (props: OptionProps<MatterOption>) => {
  return (
    <components.Option {...props}>
      <div className={s.option}>
        <div className={cn(s.header, s.displayBelow)}>
          <div className={s.optionTitle}>
            <Ellipsis width={280} lines={1}>
              {props.data.label}
            </Ellipsis>
          </div>
          <p className={s.clientMatterId}>{props.data.clientMatterId}</p>
        </div>
      </div>
    </components.Option>
  )
}

interface Props {
  scopeName: string
  jurisdictionsList: Jurisdiction[]
  serviceTypesList: Option[]
  triggersList: TriggerOption[]
  updateSetupObject: <P extends keyof SetupObject>(value: SetupObject[P], property: P) => void
  setSetupObject: (value: any) => void
  setupObject: SetupObject
  fetchServiceTypes: (jurisdictionId: number) => void
  fetchTriggers: (jurisdictionId: number) => void
  resetDefaultValues: () => void
  isTimeRequired: boolean
  context: 'matter' | 'workbench'
  setScopeId?: Dispatch<SetStateAction<string>>
}

const CourtRulesSetup = ({
  scopeName,
  jurisdictionsList,
  triggersList,
  serviceTypesList,
  updateSetupObject,
  setSetupObject,
  setupObject,
  fetchServiceTypes,
  resetDefaultValues,
  fetchTriggers,
  isTimeRequired,
  context,
  setScopeId
}: Props) => {
  return (
    <>
      {context === 'workbench' ? (
        <div className={s.item}>
          <p className={s.label}>
            Matter <span style={{ color: 'red' }}>*</span>
          </p>
          {/* @ts-expect-error */}
          <PaginatedSelect
            url="/manage/matters/v2/simple_matter_list/?can_edit=true"
            value={setupObject.relatedMatter as MatterOption}
            onChange={(item: MatterOption): void => {
              setSetupObject({
                ...setupObject,
                relatedMatter: item
              })
              item?.value && setScopeId?.(String(item.value))
            }}
            isClearable={false}
            placeholder="Select..."
            className={s.mattersSelect}
            comps={{ Option: CustomOption }}
            defaultMenuIsOpen={context === 'workbench'}
            serializer={(options: APISimpleMatter[]) => toMattersOptions(options, true)}
            pageStart={0}
            resultsProperty="rows"
            pageParam="page_number"
            searchTermParam="search"
          />
        </div>
      ) : (
        <div className={s.item}>
          <p className={s.label}>Matter</p>
          <p className={s.matterName}>{scopeName}</p>
        </div>
      )}
      <div className={s.item}>
        <p className={s.label}>
          Court Rule Name <span style={{ color: 'red' }}>*</span>
        </p>
        <CharLimitInput
          value={setupObject.name}
          placeholder="Enter a Court Rule Name"
          onChangeCb={e => updateSetupObject(e.target.value, 'name')}
          maxLength={25}
          dynamicCharCalculation
          style={{ borderRadius: 4 }}
          customErrorMessage="Maximum character limit reached"
        />
      </div>
      <div className={s.item}>
        <p className={s.label}>
          Jurisdiction Rule Setup <span style={{ color: 'red' }}>*</span>
        </p>
        <Select
          options={toOptions(jurisdictionsList)}
          value={setupObject.selectedJurisdiction}
          onChange={value => {
            setSetupObject({
              ...setupObject,
              selectedJurisdiction: value,
              selectedServiceType: null
            })
            if (value) {
              fetchServiceTypes(value?.value)
              fetchTriggers(value?.value)
            }
            resetDefaultValues()
          }}
          placeholder="Select a Jurisdiction"
          isSearchable={true}
          isClearable
          aria-label="selectJurisdiction"
        />
      </div>
      <div className={s.item}>
        <p className={s.label}>
          Trigger Event <span style={{ color: 'red' }}>*</span>
        </p>
        <Select
          options={triggersList}
          value={setupObject.selectedTrigger}
          onChange={value => {
            setSetupObject({
              ...setupObject,
              selectedTrigger: value,
              selectedServiceType: null
            })
          }}
          placeholder="Select a Trigger"
          isSearchable={true}
          isClearable
          isDisabled={!setupObject.selectedJurisdiction}
          aria-label="selectTrigger"
        />
      </div>
      <div className={s.flex}>
        <div className={s.item}>
          <p className={s.label}>
            Trigger Date <span style={{ color: 'red' }}>*</span>
          </p>
          <div style={{ width: 500 }}>
            <DatePickerSelect
              value={setupObject.triggerDate}
              selectDate={(dueDate: Date | undefined) => {
                setSetupObject({
                  ...setupObject,
                  triggerDate: dueDate
                })
              }}
              openUpwards={true}
            />
          </div>
        </div>
        <div className={s.item}>
          <p className={s.label}>
            Time {isTimeRequired ? <span style={{ color: 'red' }}>*</span> : ''}
          </p>
          <TimePicker
            onConfirm={(values: (string | null)[]) => {
              updateSetupObject(values, 'time')
            }}
            values={
              setupObject.time
                ? [setupObject.time[0] ?? null, setupObject.time[1] ?? null]
                : [null, null]
            }
            withLabel={false}
            className={s.timePicker}
            withButtons={false}
            hasDefaultValues={false}
            timezone={
              jurisdictionsList.find(j => j.id === setupObject.selectedJurisdiction?.value)
                ?.timezone.code
            }
          />
          {isTimeRequired && setupObject.time[0] === '00:00' && (
            <p className={s.error}>Please enter a time before midnight</p>
          )}
        </div>
      </div>
      {setupObject.selectedTrigger?.served && (
        <div className={s.item}>
          <p className={s.label}>Service Type</p>
          <Select
            options={serviceTypesList}
            onChange={value => {
              updateSetupObject(value, 'selectedServiceType')
            }}
            value={setupObject.selectedServiceType}
            placeholder="Select a Service Type"
            isSearchable={true}
            isClearable
          />
        </div>
      )}
      <div className={s.item}>
        <div className={s.label}>
          Prefix{' '}
          <Tooltip
            trigger={<AiOutlineInfoCircle className={s.info} />}
            content="The prefix will be prepended to the event name"
            placement="top"
          />
        </div>
        <CharLimitInput
          maxLength={25}
          value={setupObject.prefix}
          onChangeCb={e => updateSetupObject(e.target.value, 'prefix')}
          placeholder="Enter a prefix"
          dynamicCharCalculation
          style={{ borderRadius: 4 }}
          customErrorMessage="Maximum character limit reached"
        />
      </div>
    </>
  )
}

export default CourtRulesSetup
