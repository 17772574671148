import { Component } from 'react'
import s from './ComparisonQuestion.scss'
import { Row } from './Row'
import get from 'lodash/get'
import { dateRangeLabels } from '../../utils'
import { SURVEY_QUESTIONS } from 'vendors/constants'

class ComparisonQuestion extends Component {
  render() {
    const { questions, filters, comparisons, compareQuestions, currentVendorName } = this.props
    let currentEntity = 'All'
    let comparedEntity
    if (comparisons.dateRange.start !== null || comparisons.dateRange.end !== null) {
      currentEntity = dateRangeLabels(filters)
      comparedEntity = dateRangeLabels(comparisons)
    }
    if (comparisons.practiceArea !== null) {
      comparedEntity = get(comparisons, 'practiceArea.label', '')
      if (filters.practiceArea !== null) {
        currentEntity = get(filters, 'practiceArea.label', '')
      }
    }
    if (comparisons.vendor !== null) {
      comparedEntity = get(comparisons, 'vendor.label', '')
      if (filters.vendor !== null) {
        currentEntity = currentVendorName
      }
    }
    return (
      <div className={s.container}>
        {questions.map((question, index) => (
          <div className={s.questionWrapper} key={question.id}>
            <span className={s.questionText}>
              {index + 1}. {SURVEY_QUESTIONS[question.id]}
            </span>
            <Row
              question={question}
              entity={currentEntity}
              withPill={+question.avg_score > +compareQuestions[index].avg_score}
            />
            <Row
              className={s.comparedEntity}
              question={compareQuestions[index]}
              entity={comparedEntity}
              withPill={+question.avg_score < +compareQuestions[index].avg_score}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default ComparisonQuestion
