import { useState, useEffect } from 'react'
import Select from 'react-select'
import s from './BaseAsyncSelect.scss'
import { toLabels } from '../serializers'
import { makeGetRequest } from 'utils/api'
import chroma from 'chroma-js'
import { DOC_LABELS_URL } from '../urls'
import isEqual from 'lodash/isEqual'

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 6,
    content: '" "',
    display: 'block',
    marginLeft: 8,
    marginRight: 3,
    height: 6,
    width: 6
  }
})

const colourStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ...dot(data.color),
      ':active': {
        ...styles[':active']
      }
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      ...dot(data.color)
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ':hover': {
      backgroundColor: data.color,
      color: 'white'
    }
  })
}

const LabelsSelect = ({
  value,
  onChange,
  isClearable,
  isPortal = true,
  isDisabled = false,
  components,
  options
}) => {
  const [labels, setLabels] = useState([])
  const [prevLabels, setPrevLabels] = useState([])

  if (options && !isEqual(prevLabels, options)) {
    setLabels(toLabels(options))
    setPrevLabels(options)
  }

  useEffect(() => {
    if (options) return
    ;(async () => {
      const labels = await makeGetRequest(DOC_LABELS_URL)
      setLabels(toLabels(labels.results))
    })()
  }, [options])

  return (
    <Select
      placeholder="Search labels"
      noOptionsMessage={() => 'No results...'}
      value={value}
      onChange={onChange}
      options={labels}
      isClearable={isClearable}
      isMulti
      className={s.select}
      styles={colourStyles}
      menuPortalTarget={isPortal ? document.body : null}
      isDisabled={isDisabled}
      components={components}
    />
  )
}

export default LabelsSelect
