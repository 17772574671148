import s from '../DynamicBulkTable.scss'
import { reorderFields } from 'bulk/serializer'
import ListFilter from './ListFilter'
import NumericFilter from './NumericFilter'
import DateFilter from './DateFilter'
import PaginatedListFilter from './PaginatedListFilter'
import TextareaFilter from './TextareaFilter'
import CurrencyFilter from './CurrencyFilter'
import { isEmptyArray } from 'bulk/utils'

const optWrapperStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  width: '390px',
  justifyContent: 'space-between'
}

const currencyOptWrapperStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  width: '450px',
  justifyContent: 'space-between'
}

const width = { width: 110 }

const IS_TYPE_FILTER = false

function Filters({
  filters,
  getFilterLabel,
  getInputValue,
  handleFilterApply,
  filterValues,
  onFilterInputChange,
  onChange,
  onClear,
  currentSelection,
  currentInputSelection,
  resetCb,
  onCancel,
  reset,
  hasConfirmation,
  recordType
}) {
  const reorderedFilters = reorderFields(filters, recordType, IS_TYPE_FILTER)
  const activeFiltersCount = Object.keys(filterValues).length
  const basicFilterOptions = ['is null', 'is not null', 'is n/a', 'is not n/a']

  const handleDisableMultiSelects = field => {
    const curSelection = currentSelection[field]
    const curInputSelection = currentInputSelection[field]

    const { op = null, value1 = null } = curInputSelection || {}

    if (!curSelection || basicFilterOptions.includes(curSelection)) {
      return false
    }
    if (!value1 || op !== curSelection || isEmptyArray(value1)) {
      return true
    }
  }

  return (
    <>
      {reorderedFilters.map((filter, index) => {
        const curField = filterValues[filter.field]
        const curSelection = currentSelection[filter.field]
        const curInputSelection = currentInputSelection[filter.field]

        if (
          Array.isArray(filter.options) ||
          (filter.type === 'list' && Array.isArray(filter.options))
        ) {
          return (
            <ListFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              handleDisableMultiSelects={handleDisableMultiSelects}
              hasConfirmation={hasConfirmation}
              optWrapperStyle={optWrapperStyle}
              width={width}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
        if (filter.type === 'int' && !filter.options) {
          return (
            <NumericFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              hasConfirmation={hasConfirmation}
              optWrapperStyle={optWrapperStyle}
              width={width}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
        if (filter.type === 'date') {
          return (
            <DateFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              hasConfirmation={hasConfirmation}
              optWrapperStyle={optWrapperStyle}
              width={width}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
        if (
          (filter.options && !Array.isArray(filter.options)) ||
          (filter.type === 'list' && filter.options && !Array.isArray(filter.options))
        ) {
          return (
            <PaginatedListFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              handleDisableMultiSelects={handleDisableMultiSelects}
              hasConfirmation={hasConfirmation}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
        if (filter.type === 'long_text' || filter.type === 'textarea' || filter.type === 'text') {
          return (
            <TextareaFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              hasConfirmation={hasConfirmation}
              optWrapperStyle={optWrapperStyle}
              width={width}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
        if (filter.type === 'currency') {
          return (
            <CurrencyFilter
              key={filter.field}
              reset={reset}
              getFilterLabel={getFilterLabel}
              curField={curField}
              filter={filter}
              handleFilterApply={handleFilterApply}
              onChange={onChange}
              onClear={onClear}
              onCancel={onCancel}
              hasConfirmation={hasConfirmation}
              currencyOptWrapperStyle={currencyOptWrapperStyle}
              width={width}
              curSelection={curSelection}
              curInputSelection={curInputSelection}
              getInputValue={getInputValue}
              onFilterInputChange={onFilterInputChange}
            />
          )
        }
      })}
      {filters.length ? (
        <div
          className={activeFiltersCount ? s.resetAllowed : s.resetDisabled}
          onClick={activeFiltersCount ? resetCb : () => {}}
        >
          Reset
        </div>
      ) : null}
    </>
  )
}

export default Filters
