export const checkChildPages = urls =>
  urls &&
  urls.filter(
    ({ url }) =>
      `${window.location.pathname}${window.location.hash}` === url ||
      `${window.location.pathname}${window.location.hash}`.startsWith(url)
  ).length

// bunch of weird edge cases..
// our django urls are very un-semantic, makes finding the active page much harder
export const checkRootPage = ({ name, url }) => {
  const { pathname } = window.location
  if (name === 'home') {
    return false
  }
  if (name === 'invoice_list') {
    if (pathname.includes('/vendors/') || pathname.includes('/payments/')) {
      return false
    }
  }
  if (name === 'matter_request') {
    if (pathname.startsWith('/v2/matters/templates/')) {
      return false
    }
  }
  if (name === 'administration') {
    if (
      pathname.includes('/matters/templates') ||
      pathname.startsWith('/reports/v2/custom/management/')
    ) {
      return true
    }
  }
  if (name === 'addressbook') {
    if (pathname.includes('/v2/contacts')) {
      return true
    }
  }
  if (name === 'reports') {
    if (pathname.startsWith('/reports/filtered/') || pathname === '/reports/') {
      return true
    } else {
      return false
    }
  }
  if (name === 'report_list') {
    if (pathname.startsWith('/reports/') && pathname !== '/reports/') {
      if (
        pathname.startsWith('/reports/filtered/') ||
        pathname.startsWith('/reports/v2/custom/management/')
      ) {
        return false
      } else {
        return true
      }
    }
  }
  if (name === 'administration') {
    if (pathname.startsWith('/v2/matters/templates/')) {
      return true
    }
  }
  return pathname.startsWith(url)
}
