import * as React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'
import { If, Panel } from 'simple-core-ui'
import { isTaskCalendarSyncEnabled } from 'common/Tasks/utils'
import { isEventsCalendarSyncEnabled } from 'common/Events/utils'

import ContactActivityContainer from '../ContactActivity/ContactActivityContainer'
import ContactAffiliationContainer from '../ContactAffiliation/ContactAffiliationContainer'
import ContactAttributesContainer from '../ContactAttributes/ContactAttributesContainer'
import ContactUserSettingsContainer from '../ContactUserSettings/ContactUserSettingsContainer'
import { PendingInvoicesContainer } from 'invoices'
import { ExternalTaskCalendars } from 'common/ExternalTaskCalendars'

import ContactProfileHeader from './ContactProfileHeader'

import s from './ContactProfile.scss'

const loggedInUser = window.credentials.user

const ContactProfile = ({ contact, contactId, avatar, uploadAvatar, deleteAvatar, readOnly }) => (
  <React.Fragment>
    <ContactProfileHeader
      contact={contact}
      avatar={avatar}
      uploadAvatar={uploadAvatar}
      deleteAvatar={deleteAvatar}
      readOnly={readOnly}
    />
    <section className={classNames(s.rowFlex, s.topRow)}>
      <section className={classNames(s.column, s.withGutter)}>
        <Panel title="Attributes">
          <ContactAttributesContainer readOnly={readOnly} />
        </Panel>
      </section>
      <section className={s.column}>
        <ContactUserSettingsContainer />
        <ContactActivityContainer contactId={contactId} />
      </section>
    </section>
    <If condition={get(contact, 'userId')}>
      <section className={classNames(s.row)}>
        <PendingInvoicesContainer userId={get(contact, 'userId')} />
      </section>
    </If>
    <section className={s.row}>
      <ContactAffiliationContainer contactId={contactId} />
    </section>
    {(isTaskCalendarSyncEnabled() || isEventsCalendarSyncEnabled()) && (
      <section className={s.row}>
        <ExternalTaskCalendars profilePage />
      </section>
    )}
  </React.Fragment>
)

export default ContactProfile
