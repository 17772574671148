import keyMirror from 'keymirror'

export default keyMirror({
  TODO_FETCH_REQUESTED: null,
  TODO_FETCH_SUCCESS: null,
  TODO_CREATE_REQUESTED: null,
  TODO_CREATE_SUCCESS: null,
  TODO_UPDATE_REQUESTED: null,
  TODO_UPDATE_SUCCESS: null,
  TODO_DELETE_REQUESTED: null,
  TODO_DELETE_SUCCESS: null,
  TODO_COMPLETE_REQUESTED: null,
  TODO_COMPLETE_SUCCESS: null,
  OPEN_EDIT_MODAL: null,
  OPEN_NEW_MODAL: null,
  UPDATE_MODAL_TODO: null,
  CANCEL_MODAL: null,
  SHOW_ALL: null,
  SHOW_COMPLETED: null,
  SHOW_ACTIVE: null,
  REGISTER_FOR_FEATURE_REQUESTED: null,
  REGISTER_FOR_FEATURE_SUCCESS: null,
  PUSH_NOTIFICATION: null
})
