import { Tag } from 'simple-core-ui'

import s from './TagBar.scss'

const TagBar = ({ tags, selectedTagId, selectCb }) => (
  <section className={s.container}>
    {tags.map(({ id, label, color }) => (
      <Tag key={id} selectCb={() => selectCb(id)} isSelected={id === selectedTagId} color={color}>
        {label}
      </Tag>
    ))}
  </section>
)

export default TagBar
