import { useState, useEffect } from 'react'
import s from '../Diversity.scss'
import Tabs from './Tabs'
import ACT from '../actions'
import { YearFilter } from 'common/Filters'
import { VendorSelect, PracticeAreaSelect, MultiVendorSelect } from 'common/Selects'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Select, { components } from 'react-select'
import VendorComparisonChart from './VendorComparisonChart'
import { Navigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { Bar, Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  defaults
} from 'chart.js'

import {
  barChartData,
  multiBarChartData,
  barOptions,
  donutData,
  donutOptions,
  selectStyle,
  multiBarOptions,
  STATUS,
  STATUS_COLOR
} from '../constants'
import TkReportCard from './TkReportCard'
import TkMultiReportCard from './TkMultiReportCard'
import { fromFragment } from '../serializers'

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, ChartDataLabels)
defaults.font.size = 16
defaults.font.weight = '500'

const useViewBy = () => {
  const frag = fromFragment(window.location.hash)
  return [...useState(frag.choiceSelector?.value), frag]
}

function DiversityReports() {
  const dispatch = useDispatch()

  const [viewBy, setViewBy, frag] = useViewBy()
  const [years, setYears] = useState(null)

  const [isVendor, setIsVendor] = useState(
    viewBy === 'vendor' || viewBy === 'vendor_lead' || viewBy === 'vendor_comparison'
  )

  const [reset, setReset] = useState(0)

  const [redirect, setRedirect] = useState(false)

  const filters = useSelector(state => state.diversityReport.filters)
  const { date, vendor, practiceArea } = filters
  const isMultiDate = date.value === '2 yrs' || date.value === '3 yrs'

  useEffect(() => {
    if (!viewBy || (viewBy !== 'vendor_lead' && !vendor?.value && !practiceArea?.value)) {
      if (viewBy !== 'vendor_comparison') {
        return setRedirect(true)
      }
    }

    let initialFilters = {
      ...filters,
      tab: frag?.tabSelector?.tab ?? filters.tab
    }

    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: { filters: initialFilters, isVendor, viewBy, years }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    veteranStatus,
    lgbtqStatus,
    disabilityStatus,
    barChart,
    doughnutChart,
    charts
  } = useSelector(state => state.diversityReport)

  const diversityEntity = isVendor ? vendor : practiceArea
  const donutdata = donutData(doughnutChart)

  const handleMultiVendorSelect = vendors => {
    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: {
        filters: { ...filters, vendorComparison: vendors },
        isVendor,
        viewBy,
        years
      }
    })
  }

  const handleVendorSelect = vendor => {
    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: { filters: { ...filters, vendor }, isVendor, viewBy, years }
    })
  }

  const handlePracticeAreaSelect = practiceArea => {
    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: { filters: { ...filters, practiceArea }, isVendor, viewBy, years }
    })
  }

  const handleChoiceSelect = choice => {
    const newViewBy = choice?.value
    const newIsVendor = newViewBy === 'vendor' || newViewBy === 'vendor_lead'
    setViewBy(newViewBy)
    setIsVendor(newIsVendor)
    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: {
        filters: { ...filters, choice },
        isVendor: newIsVendor,
        viewBy: newViewBy,
        years
      }
    })
  }

  const handleDateFilter = data => {
    const date = data || { label: 'All time', value: 'all' }
    dispatch({
      type: ACT.DIVERSITY_REQUESTED,
      payload: { filters: { ...filters, date }, isVendor, viewBy, years }
    })
  }

  const SingleValue = ({ children, ...rest }) => {
    return <components.SingleValue {...rest}>View by {children}</components.SingleValue>
  }

  const shouldUseMulti = isMultiDate && charts && charts.length
  if (redirect) {
    return <Navigate to="/v2/diversity" />
  }

  let widthNamesBar = 93 + window.innerWidth * 0.001
  let marginLeftNamesBar = 65

  return (
    <>
      <a
        href="/v2/diversity"
        style={{ padding: viewBy === 'vendor_comparison' ? '0 24px' : 0 }}
      >{`<< Return to Diversity Report`}</a>
      {(vendor || practiceArea) && date.label !== 'Date not set' && (
        <div style={{ padding: viewBy === 'vendor_comparison' ? 24 : 0 }}>
          <div className={s.diversityTopBar}>
            <div className={s.diversityTitleDivider}>
              <span className={s.titleReport}>
                {viewBy === 'vendor_comparison'
                  ? 'Vendor Comparison Diversity'
                  : diversityEntity.label}{' '}
                Report
              </span>
            </div>
            <div className={s.selectContainer}>
              <div className={`${s.filter} ${s.choiceFilter}`} style={{ width: '500px' }}>
                <Select
                  placeholder="View report by..."
                  value={filters.choice}
                  onChange={handleChoiceSelect}
                  options={[
                    { label: 'Vendor', value: 'vendor' },
                    { label: 'Practice Area', value: 'practice_area' },
                    { label: 'Vendor Lead', value: 'vendor_lead' },
                    { label: 'Vendor Comparison', value: 'vendor_comparison' }
                  ]}
                  components={{ SingleValue }}
                  isClearable={false}
                  className={s.select}
                  menuPortalTarget={null}
                  styles={selectStyle}
                  isDisabled={false}
                />
                {viewBy === 'vendor' ? (
                  <VendorSelect
                    placeholder="Choose a vendor"
                    isClearable={false}
                    style={selectStyle}
                    className={s.select}
                    onChange={handleVendorSelect}
                    value={vendor?.value ? vendor : null}
                    paginated
                  />
                ) : viewBy === 'vendor_comparison' ? (
                  <MultiVendorSelect
                    shouldChangeOnClear
                    onChange={handleMultiVendorSelect}
                    value={filters.vendorComparison}
                  />
                ) : viewBy !== 'vendor_lead' ? (
                  <PracticeAreaSelect
                    placeholder="Choose a practice area"
                    isClearable={false}
                    style={selectStyle}
                    className={s.select}
                    onChange={handlePracticeAreaSelect}
                    value={practiceArea?.value ? practiceArea : null}
                    hasNone={false}
                    paginated
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className={s.mt42}>
            <Tabs
              entityId={isVendor ? vendor.value : practiceArea.value}
              entityLabel={isVendor ? vendor.label : practiceArea.label}
              viewBy={viewBy}
              isVendor={isVendor}
              isMultiDate={isMultiDate}
              filterDate={date.value}
              changeTab={tabName => {
                dispatch({
                  type: ACT.DIVERSITY_REQUESTED,
                  payload: { filters: { ...filters, tab: tabName }, isVendor, viewBy, years }
                })
              }}
            />
          </div>
          {viewBy === 'vendor_comparison' ? (
            <div className={s.barChartContainer}>
              <div className={s.filterContainer}>
                <p className={s.filterTitle}>Filter by:</p>
                <YearFilter
                  reset={reset}
                  initialValue={date}
                  isVendor={isVendor}
                  entityId={filters.vendorComparison[0]?.value}
                  onSelect={handleDateFilter}
                  setYears={setYears}
                  years={years}
                  hideRanges
                />
              </div>
              <VendorComparisonChart />
            </div>
          ) : (
            <div className={s.barChartContainer}>
              <div className={s.filterContainer}>
                <p className={s.filterTitle}>Filter by:</p>
                {(practiceArea.value || vendor.value) && (
                  <YearFilter
                    reset={reset}
                    initialValue={date}
                    isVendor={isVendor}
                    entityId={isVendor ? vendor.value : practiceArea.value}
                    onSelect={handleDateFilter}
                    setYears={setYears}
                    years={years}
                  />
                )}
              </div>
              <div className={s.barContainer}>
                <div className={s.titleSpacer}>
                  <h3 className={s.barChartTitle}>Race</h3>
                  <p className={s.dateLabel}>{date.label}</p>
                </div>
                <div className={s.barSpacer} style={!shouldUseMulti ? { paddingBottom: 0 } : {}}>
                  <Bar
                    data={
                      shouldUseMulti
                        ? multiBarChartData(
                            charts.map(x => x.barChart),
                            'race'
                          )
                        : barChartData(barChart)
                    }
                    height={300}
                    options={shouldUseMulti ? multiBarOptions : barOptions}
                  />
                </div>
                {(barChart.labels.length !== 0 || shouldUseMulti) && (
                  <div
                    style={{ width: `${widthNamesBar}%`, marginLeft: marginLeftNamesBar }}
                    className={s.namesBar}
                  >
                    {shouldUseMulti
                      ? charts[0].barChart.labels.map((label, i) => (
                          <p key={`${i}barLabel`}>{label}</p>
                        ))
                      : barChart.labels.map((label, i) => <p key={`${i}barLabel`}>{label}</p>)}
                  </div>
                )}
              </div>
              <div className={s.cardsContainer}>
                {shouldUseMulti ? (
                  <div className={`${s.w60} ${s.barContainer} ${s.mr16}`}>
                    <div className={s.titleSpacer}>
                      <h3 className={s.barChartTitle}>Gender</h3>
                      <p className={s.dateLabel}>{date.label}</p>
                    </div>
                    <div className={s.barSpacer}>
                      <Bar
                        data={multiBarChartData(
                          charts.map(x => x.doughnutChart),
                          'gender'
                        )}
                        height={300}
                        options={multiBarOptions}
                      />
                    </div>
                    {(barChart.labels.length !== 0 || shouldUseMulti) && (
                      <div
                        style={{ width: `${88 + window.innerWidth * 0.001}%`, marginLeft: 72 }}
                        className={s.namesBar}
                      >
                        {shouldUseMulti
                          ? charts[0].doughnutChart.labels.map((label, i) => (
                              <p key={`${i}barLabel`}>{label}</p>
                            ))
                          : doughnutChart.labels.map((label, i) => (
                              <p key={`${i}barLabel`}>{label}</p>
                            ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={s.donutContainer}>
                    <h3 className={s.barChartTitle}>Gender</h3>
                    <p className={s.dateLabel}>{date.label}</p>
                    <div className={s.diversityTopBar}>
                      <div className={s.donutLimiter}>
                        <Doughnut data={donutData(doughnutChart)} options={donutOptions} />
                        <div className={s.donutMask}></div>
                      </div>
                      <div className={s.donutLabelsContainer}>
                        {donutdata.labels.map((x, i) => (
                          <div key={`${i}donutLabel`} className={s.donutLabelWrapper}>
                            <div
                              className={s.donutColorDot}
                              style={{
                                backgroundColor: donutdata.datasets[0].backgroundColor[i]
                              }}
                            ></div>
                            <p className={s.donutLabelText}>{donutdata.datasets[0].data[i]}% </p>{' '}
                            {x}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {!shouldUseMulti ? (
                  <>
                    <TkReportCard
                      title={STATUS.DISABILITY}
                      label={date.label}
                      hasMargin
                      value={disabilityStatus.value}
                      preferNotToSay={disabilityStatus.preferNotToSay}
                      noData={disabilityStatus.noData}
                      no={disabilityStatus.no}
                    />
                    <TkReportCard
                      title={STATUS.VETERAN}
                      label={date.label}
                      hasMargin
                      value={veteranStatus.value}
                      preferNotToSay={veteranStatus.preferNotToSay}
                      noData={veteranStatus.noData}
                      no={veteranStatus.no}
                    />
                    <TkReportCard
                      title={STATUS.LGBTQ}
                      label={date.label}
                      value={lgbtqStatus.value}
                      preferNotToSay={lgbtqStatus.preferNotToSay}
                      noData={lgbtqStatus.noData}
                      no={lgbtqStatus.no}
                    />
                  </>
                ) : (
                  <>
                    <TkMultiReportCard
                      title={STATUS.DISABILITY}
                      label={date.label}
                      color={STATUS_COLOR.DISABILITY}
                      hasMargin
                      data={charts.map(chart => ({
                        data: chart.disabilityStatus,
                        year: chart.year
                      }))}
                    />
                    <TkMultiReportCard
                      title={STATUS.VETERAN}
                      label={date.label}
                      hasMargin
                      color={STATUS_COLOR.VETERAN}
                      data={charts.map(chart => ({
                        data: chart.veteranStatus,
                        year: chart.year
                      }))}
                    />
                    <TkMultiReportCard
                      title={STATUS.LGBTQ}
                      label={date.label}
                      color={STATUS_COLOR.LGBTQ}
                      data={charts.map(chart => ({ data: chart.lgbtqStatus, year: chart.year }))}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DiversityReports
