import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Table } from 'simple-core-ui'
import { makeGetRequest } from 'utils/api'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { VersionHistory as VersionHistoryType } from 'simple_review/@types/api'
import { VersionDifference } from 'simple_review/@types/editor'
import { deserializeVersionHistory } from './serializers'
import { sortVersions } from './helpers'
import { Sort } from './types'
import s from './VersionHistory.scss'

const COLUMN_STYLE = {
  paddingLeft: '24px'
}

interface Props {
  ruleId: string | undefined
}

export const VersionHistory = ({ ruleId }: Props) => {
  const dispatch = useDispatch()

  const { state: contextState } = useSimpleReviewContext()

  const [versionHistory, setVersionHistory] = useState<VersionHistoryType>()
  const [sort, setSort] = useState<Sort>({
    columnKey: 'date',
    isDesc: true
  })

  const { versions } = useMemo<VersionDifference>(() => {
    if (versionHistory) {
      return deserializeVersionHistory(versionHistory, contextState)
    }
    return {
      versions: []
    }
  }, [versionHistory, contextState])

  useEffect(() => {
    const fetchVersionHistory = async () => {
      try {
        const response: VersionHistoryType = await makeGetRequest(
          `/client/rules/${ruleId}/version_history`
        )

        setVersionHistory(response)
      } catch (err) {
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: `Sorry, couldn't format all version differences.`,
            level: 'error'
          }
        })
      }
    }

    if (ruleId && ruleId !== 'new') {
      fetchVersionHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId])

  const sortTable = (newState: typeof sort) => {
    setSort(prev => ({
      columnKey: newState.columnKey,
      isDesc: newState.columnKey === prev.columnKey ? !prev.isDesc : true
    }))
  }

  const columns = useMemo(
    () => [
      {
        columnKey: 'date',
        content: 'Date',
        isSortable: true,
        isDesc: sort.columnKey === 'date' ? sort.isDesc : null,
        style: COLUMN_STYLE
      },
      {
        columnKey: 'time',
        content: 'Time',
        isSortable: false,
        style: COLUMN_STYLE
      },
      {
        columnKey: 'modifiedBy',
        content: 'User',
        isSortable: true,
        isDesc: sort.columnKey === 'modifiedBy' ? sort.isDesc : null,
        style: COLUMN_STYLE
      },
      {
        columnKey: 'changesMade',
        content: 'Changes made',
        isSortable: false,
        style: COLUMN_STYLE
      }
    ],
    [sort.columnKey, sort.isDesc]
  )

  const rows = useMemo(
    () =>
      versions.sort(sortVersions(sort)).map(version => ({
        id: version.id,
        cells: [
          {
            columnKey: 'date',
            content: version.date
          },
          {
            columnKey: 'time',
            content: version.time
          },
          {
            columnKey: 'modifiedBy',
            content: version.modifiedBy ?? 'SimpleLegal Support'
          },
          {
            columnKey: 'changesMade',
            content: version.changesMade
          }
        ]
      })),
    [sort, versions]
  )

  return (
    // @ts-expect-error
    <Table
      rows={rows}
      columns={columns}
      sortTable={sortTable}
      striped={rows.length > 0}
      fixedHeader={rows.length > 0}
      noOverflow
      className={s.table}
      testid="version-history-table"
    />
  )
}
