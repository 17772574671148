import SUPPORTED_FILE_ICONS from 'data/supported_file_icons'

const createFileIconPath = filePath => {
  let ext = filePath
    ?.split('.')
    .pop()
    .toLowerCase()

  if (!SUPPORTED_FILE_ICONS.hasOwnProperty(ext)) {
    ext = 'default'
  }

  return `${window.credentials.urls.s3BucketUrl}react/assets/img/file-types/${ext}.svg`
}

const ExtensionImage = ({ file, width = 30 }) => {
  return <img width={width} src={createFileIconPath(file.path)} />
}

export default ExtensionImage
