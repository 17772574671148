import { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { ModalContainer, Stepper, useLoading, Button, Spinner } from 'simple-core-ui'
import s from './AddCourtRulesModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { toOptions, toTriggersOptions, toRules, fromEvent, toJurisdictions } from './serializers'
import { useDispatch } from 'react-redux'
import {
  Option,
  SetupObject,
  EventSettingsObject,
  Event,
  TriggerOption,
  Jurisdiction
} from './types'
import { CourtRulesSetup } from './CourtRulesSetup'
import { EventSettings } from './EventSettings'
import { EventSelection } from './EventSelection'
import { combineDateAndTimeString, utcDate } from 'utils/helpers'
import { IoWarningOutline } from 'react-icons/io5'

interface Props {
  toggleModal: () => void
  selectedJurisdictionId: number | null
  scopeName: string
  scopeId: string
  refreshTable: (toggleSidebar: boolean) => void
  context?: 'matter' | 'workbench'
  setScopeId?: Dispatch<SetStateAction<string>>
}

const MAX_CHAR = 25

const AddCourtRulesModal = ({
  toggleModal,
  scopeName,
  scopeId,
  selectedJurisdictionId,
  refreshTable,
  context = 'matter',
  setScopeId
}: Props) => {
  const [activeStep, setActiveStep] = useState(1)
  const [jurisdictionsList, setJurisdictionsList] = useState<Jurisdiction[]>([])
  const [triggersList, setTriggersList] = useState<TriggerOption[]>([])
  const [serviceTypesList, setServiceTypesList] = useState<Option[]>([])
  const [isLoading, withLoadingLocks, resetLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [setupObject, setSetupObject] = useState<SetupObject>({
    name: '',
    prefix: '',
    selectedServiceType: null,
    selectedJurisdiction: null,
    selectedTrigger: null,
    triggerDate: undefined,
    time: [null, null],
    relatedMatter: null
  })
  const [eventSettingsObject, setEventSettingsObject] = useState<EventSettingsObject>({
    calendar: {
      id: 1,
      name: 'team calendar',
      color: '#007FFF'
    },
    attendees: []
  })
  const [localSelectedJurisdictionId, setLocalSelectedJurisdictionId] = useState<number | null>(
    selectedJurisdictionId
  )
  const [localOldSelectedJurisdictionId, setLocalOldSelectedJurisdictionId] = useState<
    number | null
  >(localSelectedJurisdictionId)
  const [localSelectedServiceTypeId, setLocalSelectedServiceTypeId] = useState<number | null>()
  const [rules, setRules] = useState<Event[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [isTimeRequired, setIsTimeRequired] = useState(false)

  const steps = useMemo(() => {
    return [
      {
        text: <span className={s.step}>Court Rules Setup</span>,
        onClick: () => {
          if (activeStep > 1) {
            setActiveStep(1)
          }
        }
      },
      {
        text: <span className={s.step}>Event Selection</span>,
        onClick: () => {
          if (activeStep > 2) {
            setActiveStep(2)
          }
        }
      },
      {
        text: <span className={s.step}>Event Settings</span>
      }
    ]
  }, [activeStep])

  const selectRow = (id: number) => {
    setAllRowsSelected(false)

    if (selectedRows.includes(id)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
    }
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    setSelectedRows(allRowsSelected ? [] : rules.map(t => t.id))
  }

  if (selectedJurisdictionId !== localOldSelectedJurisdictionId) {
    setLocalSelectedJurisdictionId(selectedJurisdictionId)
    setLocalOldSelectedJurisdictionId(selectedJurisdictionId)
  }

  const fetchJurisdictionsList = async (stateId?: number) => {
    try {
      const { jurisdictions } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/jurisdictions${stateId ? `?state_id=${stateId}` : ''}`
        )
      )
      const jurisdictionsList = toJurisdictions(jurisdictions)
      setJurisdictionsList(jurisdictionsList)
      if (selectedJurisdictionId) {
        const jurisdictionOptions = toOptions(jurisdictionsList)
        setSetupObject(setupObject => ({
          ...setupObject,
          selectedJurisdiction:
            jurisdictionOptions.find(({ value }) => value === selectedJurisdictionId) || null
        }))
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchServiceTypes = async (jurisdictionId?: number) => {
    try {
      const { service_types } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/service-types${
            jurisdictionId ? `?jurisdiction_id=${jurisdictionId}` : ''
          }`
        )
      )
      setServiceTypesList(toOptions(service_types))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchTriggers = async (jurisdictionId?: number) => {
    try {
      const { triggers } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/triggers${
            jurisdictionId ? `?jurisdiction_id=${jurisdictionId}` : ''
          }`
        )
      )
      setTriggersList(toTriggersOptions(triggers))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchRequirments = async (jurisdictionId: number, triggerId: number) => {
    try {
      const { is_time_required } = await withLoadingLocks(
        makeGetRequest(
          `/event-management/calendar_rules/compute-requirements?jurisdiction_id=${jurisdictionId}&trigger_id=${triggerId}`
        )
      )
      setIsTimeRequired(is_time_required)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchJurisdictionsList()
    fetchServiceTypes()

    if (selectedJurisdictionId) {
      fetchTriggers(selectedJurisdictionId)
    }

    return () => {
      resetLoadingLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!setupObject.selectedJurisdiction || !setupObject.selectedTrigger) return
    fetchRequirments(setupObject.selectedJurisdiction?.value, setupObject.selectedTrigger?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupObject.selectedJurisdiction, setupObject.selectedTrigger])

  const updateSetupObject = <P extends keyof SetupObject>(value: SetupObject[P], property: P) => {
    setSetupObject(setupObject => ({
      ...setupObject,
      [property]: value
    }))
  }

  const updateEventSettingsObject = (
    value: string | Option | null | Option[] | undefined | { type: string; value: number | null },
    property: keyof EventSettingsObject,
    nestedProperty?: string
  ) => {
    setEventSettingsObject(eventSettingsObject => ({
      ...eventSettingsObject,
      ...(nestedProperty
        ? {
            [property]: {
              ...(eventSettingsObject[property] as Partial<EventSettingsObject>),
              [nestedProperty]: value
            }
          }
        : { [property]: value })
    }))
  }

  useEffect(() => {
    if (localSelectedServiceTypeId && serviceTypesList.length) {
      updateSetupObject(
        serviceTypesList.find(({ value }) => value === localSelectedServiceTypeId) ?? null,
        'selectedServiceType'
      )
    }
    if (localSelectedJurisdictionId && jurisdictionsList.length) {
      const selectedJurisdiction = jurisdictionsList.find(
        ({ id }) => id === localSelectedJurisdictionId
      )
      updateSetupObject(
        selectedJurisdiction
          ? { value: selectedJurisdiction.id, label: selectedJurisdiction.name }
          : null,
        'selectedJurisdiction'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelectedServiceTypeId, localSelectedJurisdictionId, jurisdictionsList, serviceTypesList])

  const getTriggerDate = () => {
    const timezone = jurisdictionsList.find(j => j.id === setupObject.selectedJurisdiction?.value)
      ?.timezone.code
    return setupObject.triggerDate
      ? utcDate(combineDateAndTimeString(setupObject.triggerDate, setupObject.time[0]), timezone)
      : ''
  }

  const fetchRules = async () => {
    try {
      const { events } = await withLoadingLocks(
        makePostRequest('/event-management/calendar_rules/compute-events', {
          jurisdiction_system_id: setupObject.selectedJurisdiction?.value,
          service_type_system_id: setupObject.selectedServiceType?.value,
          trigger_date: getTriggerDate(),
          trigger_item_system_id: setupObject.selectedTrigger?.value
        })
      )
      const rules = toRules(events, setupObject.prefix)
      setRules(rules)
      setAllRowsSelected(true)
      setSelectedRows(rules.map(t => t.id))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const createEvents = async () => {
    try {
      await withLoadingLocks(
        makePostRequest(`/event-management/calendar_rules/create-events/${scopeId}`, {
          events: rules
            .filter(({ id }) => selectedRows.includes(id))
            .map(r => ({
              ...r,
              attendee_ids: eventSettingsObject.attendees,
              reminder: eventSettingsObject.reminder
            }))
            .map(fromEvent),
          court_rule_name: setupObject.name,
          jurisdiction_system_id: setupObject.selectedJurisdiction?.value,
          jurisdiction_name: setupObject.selectedJurisdiction?.label,
          jurisdiction_time_zone: jurisdictionsList.find(
            j => j.id === setupObject.selectedJurisdiction?.value
          )?.timezone.code,
          service_type_system_id: setupObject.selectedServiceType?.value,
          service_type_name: setupObject.selectedServiceType?.label,
          trigger_date: getTriggerDate(),
          trigger_item_system_id: setupObject.selectedTrigger?.value,
          trigger_item_name: setupObject.selectedTrigger?.label,
          prefix: setupObject.prefix
        })
      )

      window.location.href = `/v2/matters/${scopeId}?tab=events`

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Court Rules Events successfully created.',
          level: 'success'
        }
      })
      toggleModal()
      refreshTable(false)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onNext = () => {
    switch (activeStep) {
      case 1:
        fetchRules()
        break
      case 3:
        createEvents()
        return
      default:
        break
    }

    setActiveStep(activeStep + 1)
  }

  const onBack = () => {
    setActiveStep(activeStep - 1)
  }

  const isDisabled = () => {
    if (activeStep === 1) {
      return (
        !setupObject.selectedJurisdiction ||
        !setupObject.triggerDate ||
        !setupObject.selectedTrigger ||
        !setupObject.name ||
        setupObject.name.length > MAX_CHAR ||
        setupObject.prefix.length > MAX_CHAR ||
        (isTimeRequired ? !setupObject.time[0] || setupObject.time[0] === '00:00' : false) ||
        (context === 'workbench' && !setupObject.relatedMatter)
      )
    }
    if (activeStep === 2) {
      return !selectedRows.length
    }

    return false
  }

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={toggleModal}
      content={
        <div className={s.container}>
          <Stepper
            orientation="horizontal"
            automaticHeader={false}
            activeStep={activeStep}
            steps={steps}
            displayIndex
            hasNewDesign
          />
          <div className={s.content}>
            <h2 className={s.title}>Add court rules</h2>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application
            {activeStep === 2 && (
              <p>
                <IoWarningOutline
                  style={{ fontSize: 22, color: '#bb342f', position: 'relative', top: 5 }}
                />{' '}
                Court rules are all day events, users will be required to manually input a time for
                each respective event.
              </p>
            )}
            {activeStep === 1 && (
              <CourtRulesSetup
                scopeName={scopeName}
                jurisdictionsList={jurisdictionsList}
                triggersList={triggersList}
                updateSetupObject={updateSetupObject}
                setSetupObject={setSetupObject}
                setupObject={setupObject}
                serviceTypesList={serviceTypesList}
                fetchServiceTypes={fetchServiceTypes}
                fetchTriggers={fetchTriggers}
                resetDefaultValues={() => {
                  setLocalSelectedJurisdictionId(null)
                  setLocalSelectedServiceTypeId(null)
                  updateSetupObject(null, 'selectedTrigger')
                }}
                isTimeRequired={isTimeRequired}
                context={context}
                setScopeId={setScopeId}
              />
            )}
            {activeStep === 2 &&
              (isLoading ? (
                <div className={s.loading}>
                  <Spinner hasNewDesign className={s.wheel} />
                  <p style={{ marginTop: 30 }}>Your events are being generated by CalendarRules.</p>
                </div>
              ) : (
                <EventSelection
                  events={rules}
                  setRules={setRules}
                  selectedRows={selectedRows}
                  allRowsSelected={allRowsSelected}
                  selectRow={selectRow}
                  selectAllRows={selectAllRows}
                />
              ))}
            {activeStep === 3 &&
              (isLoading ? (
                <div className={s.loading}>
                  <Spinner hasNewDesign className={s.wheel} />
                  <p style={{ marginTop: 30 }}>
                    Your court rule events are being added to your calendar.
                  </p>
                </div>
              ) : (
                <EventSettings
                  eventSettingsObject={eventSettingsObject}
                  updateEventSettingsObject={updateEventSettingsObject}
                  scopeId={scopeId}
                />
              ))}
          </div>
          <div className={s.footer}>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2,
                visibility: activeStep === 1 ? 'hidden' : 'visible'
              }}
              onClick={onBack}
              isPrimary
              isOutline
              hasNewDesign
              isDisabled={isLoading}
            >
              Back
            </Button>
            <div className={s.rightButtons}>
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={toggleModal}
                isPrimary
                isOutline
                hasNewDesign
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={onNext}
                isPrimary
                hasNewDesign
                isDisabled={isDisabled() || isLoading}
              >
                {activeStep === 3 ? 'Add Court Rules Events' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default AddCourtRulesModal
