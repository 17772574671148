import * as ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import { handleNullValue } from './helpers'

const initialState = {
  users: [],
  roles: [],
  sharedEvaluators: {
    status: 'disabled',
    users: [],
    roles: [],
    hasChanged: false,
    isPinned: false
  },
  templateDefaults: {},
  additionalEvaluators: [],
  hasSurveysEnabled: false
}

const surveyConfigReducer = createReducer(initialState, {
  [ACT.SURVEY_CONFIG_ROLES_FETCH_SUCCESS](state, action) {
    const { roles } = action.payload
    return {
      ...state,
      roles: [...roles]
    }
  },
  [ACT.SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE](state, action) {
    const { value, property } = action.payload
    return update(state, {
      sharedEvaluators: {
        [property]: { $set: handleNullValue(value) },
        hasChanged: { $set: true }
      }
    })
  },
  [ACT.SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE](state, action) {
    const { value, property, index } = action.payload
    return update(state, {
      additionalEvaluators: {
        [index]: {
          [property]: { $set: handleNullValue(value) },
          hasChanged: { $set: true }
        }
      }
    })
  },
  [ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_MAKE_PRISTINE](state, action) {
    const { index } = action.payload
    return update(state, {
      additionalEvaluators: {
        [index]: {
          hasChanged: { $set: false }
        }
      }
    })
  },
  [ACT.SURVEY_CONFIG_TOGGLE_SURVEY_ON](state, action) {
    const { status, index } = action.payload
    return update(state, {
      additionalEvaluators: {
        [index]: {
          status: { $set: status },
          hasChanged: { $set: true }
        }
      }
    })
  },
  [ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_SUCCESS](state, action) {
    const { additionalEvaluators } = action.payload
    return {
      ...state,
      additionalEvaluators: [...additionalEvaluators]
    }
  },
  [ACT.SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS](state, action) {
    const { sharedEvaluators, templateDefaults } = action.payload
    return {
      ...state,
      sharedEvaluators: { ...sharedEvaluators },
      templateDefaults: { ...templateDefaults }
    }
  },
  [ACT.CONTACT_ADDRESS_BOOK_FETCH_SUCCESS](state, action) {
    const { rows } = action.payload
    return {
      ...state,
      users: rows
        .filter(option => option.userId && option.userId !== null)
        .map(option => ({
          label: option.firstName + ' ' + option.lastName,
          value: option.id
        }))
    }
  },
  [ACT.SURVEY_CONFIG_SHARED_CHANGE_STATUS](state, action) {
    const { status } = action.payload
    return update(state, {
      sharedEvaluators: {
        status: { $set: status }
      }
    })
  },
  [ACT.SURVEY_CONFIG_SHARED_DEFAULT_ROLES](state, action) {
    const { hasChanged = false } = action.payload
    return update(state, {
      sharedEvaluators: {
        roles: {
          $set: state.roles.filter(r => ['Matter Lead', 'Matter Approver'].includes(r.label))
        },
        users: { $set: [] },
        hasChanged: { $set: hasChanged }
      }
    })
  },
  [ACT.SURVEY_CONFIG_SHARED_REMOVE_EVALUATOR](state, action) {
    const { property, index } = action.payload

    return update(state, {
      sharedEvaluators: {
        [property]: { $splice: [[index, 1]] },
        hasChanged: { $set: true }
      }
    })
  },
  [ACT.SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_SUCCESS](state) {
    const templateDefaults = cloneDeep(state.templateDefaults)
    return update(state, {
      sharedEvaluators: {
        isPinned: { $set: true },
        users: { $set: templateDefaults.users },
        roles: { $set: templateDefaults.roles },
        hasChanged: { $set: true },
        status: { $set: templateDefaults.status }
      }
    })
  },
  [ACT.SURVEY_CONFIG_HAS_SURVEYS_ENABLED_SUCCESS](state, action) {
    const { hasSurveysEnabled } = action.payload
    return update(state, {
      hasSurveysEnabled: { $set: hasSurveysEnabled }
    })
  },
  [ACT.SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_SUCCESS](state, action) {
    const { status } = action.payload
    let newState = state
    state.additionalEvaluators.forEach((refferal, index) => {
      newState = update(newState, {
        additionalEvaluators: {
          [index]: {
            status: { $set: status }
          }
        }
      })
    })

    return newState
  },
  [ACT.SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_SUCCESS](state) {
    return update(state, {
      sharedEvaluators: {
        isPinned: { $set: false }
      }
    })
  }
})

export default surveyConfigReducer
