import { ChangeEventHandler } from 'react'

import { Constant } from 'simple_review/@types/common'
import { Percentage } from '../percentage'
import s from './Slider.scss'

const MIN_VALUE = 0
const MAX_VALUE = 100

export const calcPos = (value = '') => {
  const containerWidth = 120,
    labelWidth = 30,
    thumbWidth = 16,
    maxProgress = 100

  return (type: 'label' | 'progress') => {
    const numVal = Number(value)
    const toCalc = type === 'label' ? labelWidth / 2 : thumbWidth

    return (containerWidth * numVal) / maxProgress - (toCalc * numVal) / maxProgress
  }
}

interface Props {
  value: Constant | null
  onChange(newValue: Constant): void
  hideInput?: boolean
  isReadOnly: boolean
}

// TODO: isDisabled
export const Slider = ({ value, onChange, hideInput = false, isReadOnly }: Props) => {
  const onChangeSlider: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.target.value
    const cappedValue = String(Math.max(MIN_VALUE, Math.min(MAX_VALUE, Number(newValue))))
    onChange({
      value: cappedValue,
      label: cappedValue
    })
  }

  const percentageValue = value ? `${value.value}%` : ''
  const getPos = calcPos(value?.value)
  const labelPos = getPos('label')
  const progressWidth = getPos('progress')

  return (
    <div className={s.container}>
      {!hideInput && <Percentage value={value} onChange={onChange} />}
      {percentageValue !== '' && (
        <div className={s.box}>
          <span className={s.sliderLabel} style={{ left: labelPos }}>
            {percentageValue}
          </span>
          <input
            className={s.slider}
            type="range"
            step={0.01}
            value={value?.value || ''}
            onChange={onChangeSlider}
            disabled={isReadOnly}
          />
          <div className={s.progress} style={{ width: progressWidth }} />
        </div>
      )}
    </div>
  )
}
