import React from 'react'

import s from './UnassignText.scss'

const UnassignText = ({ counselGoOnly, removesCounselGo }) => (
  <React.Fragment>
    {counselGoOnly ? (
      <span className={s.text}>
        Are you sure you want to unassign the <strong>CounselGO</strong> role?
      </span>
    ) : (
      <span className={s.text}>Are you sure you want to unassign this contact?</span>
    )}
    {removesCounselGo && (
      <span className={s.warn}>
        This will revoke CounselGO access from this contact for this vendor.
      </span>
    )}
  </React.Fragment>
)

UnassignText.defaultProps = {
  counselGoOnly: false
}

export default UnassignText
