import { Grid } from 'simple-core-ui'
import { useSelector } from 'react-redux'
import { InvoiceKpiSkeleton } from 'common'
import s from './Summary.scss'

const Summary = () => {
  const { Row, Column } = Grid
  const summary = useSelector(state => state.invoices.invoiceSummary)
  const isLoading = useSelector(state => state.app.loading.INVOICE_SUMMARY_FETCH)

  const summaryKeys = ['Received', 'Hold', 'Approved']

  return (
    <section className={s.container}>
      {isLoading ? (
        <InvoiceKpiSkeleton itemsNumber={summaryKeys.length} />
      ) : (
        <Row>
          {summaryKeys.map((key, i) => {
            return (
              <Column key={i} span={4} breakpoint="laptop">
                <section className={s.tile}>
                  <Row>
                    <Column span={6} breakpoint="laptop">
                      <div className={s.rect}>{summary[key].count}</div>
                    </Column>

                    <Column span={6} breakpoint="laptop">
                      <div>
                        <div className={s.label} data-testid={`summary-key-${key}`}>
                          {key}
                        </div>
                        <div className={s.amount}>{summary[key].amount}</div>
                      </div>
                    </Column>
                  </Row>
                </section>
              </Column>
            )
          })}
        </Row>
      )}
    </section>
  )
}

export default Summary
