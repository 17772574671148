import { useOutletContext } from 'react-router-dom'

import { ContextState } from '../@types/context'
import { Filters } from 'simple_review/@types/list'

const useSimpleReviewContext = () =>
  useOutletContext<{
    state: ContextState
    setState: React.Dispatch<React.SetStateAction<ContextState>>
    updateParams(params: Filters): void
  }>()

export default useSimpleReviewContext
