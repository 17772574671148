import keyMirror from 'keymirror'

export default keyMirror({
  ACCRUALS_ACTUALS_FETCH_REQUESTED: null,
  PUSH_NOTIFICATION: null,
  ACCRUALS_ACTUALS_FETCH_SUCCESS: null,
  ACCRUALS_ACTUALS_RESET: null,
  ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_REQUESTED: null,
  ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_SUCCESS: null,
  ACCRUALS_MATTER_LIST_RESET: null
})
