import { useState, useEffect } from 'react'
import APP_ACT from 'app/actions'
import ACT from 'bulk/actions'
import { useDispatch, useSelector } from 'react-redux'
import s from './BulkBuilderContainer.scss'
import { Panel, Button, Alert } from 'simple-core-ui'
import { StepperContainer } from '../StepperContainer'
import { fromFragment, toFragment, serializeBuilderRecordType } from 'bulk/serializer'
import { makeGetRequest } from 'utils/api'
import { BulkParams, Field, RecordType } from 'bulk/types'
import { ACTIVE_JOB_STATUS_URL } from 'bulk/constants'

interface Props {
  activeTab: string | undefined
  setActiveTab: (arg: string) => void
  step: number
  setStep: (arg: number) => void
}

interface State {
  bulk: {
    builderParams: BulkParams
    recordTypeObj: RecordType
    activeJob: boolean
  }
}

const BulkBuilderContainer = ({ activeTab, setActiveTab, step, setStep }: Props) => {
  const frag = fromFragment()
  const dispatch = useDispatch()

  const { activeJob, builderParams, recordTypeObj } = useSelector<State, State['bulk']>(
    state => state.bulk
  )
  const [record, setRecord] = useState(frag.record || builderParams.record)
  const [tableFields, setTableFields] = useState(
    frag.fields?.length ? frag.fields : builderParams.fields
  )
  const [filters, setFilters] = useState(
    frag.filters?.length ? frag.filters : builderParams.filters
  )
  const [attrType, setAttrType] = useState(frag.attrType || builderParams.attrType)
  const [templateType, setTemplateType] = useState(frag.templateType || builderParams.templateType)

  const [preSelected, setPreSelected] = useState(
    frag.preSelected?.length ? frag.preSelected : builderParams.preSelected
  )

  useEffect(() => {
    toFragment({
      record,
      tableFields,
      step,
      filters,
      activeTab,
      attrType,
      preSelected,
      templateType
    })

    dispatch({
      type: ACT.SET_BUILDER_PARAMS,
      payload: {
        record,
        attrType,
        templateType,
        step,
        fields: (tableFields as Field[]).filter(field => field.isSelected),
        filters: (filters as Field[]).filter(field => field.isSelected),
        preSelected
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFields, record, step, filters, activeTab, attrType, preSelected, templateType])

  useEffect(() => {
    const fetchActiveJobStatus = async () => {
      try {
        const { active_job } = await makeGetRequest(ACTIVE_JOB_STATUS_URL)
        dispatch({
          type: ACT.SET_ACTIVE_JOB_STATUS,
          payload: { activeJob: active_job }
        })
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching active job status.',
            level: 'error'
          }
        })
      }
    }
    fetchActiveJobStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Panel
      title={step === 5 ? 'Results' : 'Editor'}
      className={s.updateContainer}
      bodyClassName={step < 5 ? s.panelBody : null}
      rightActions={
        step <= 4
          ? [
              <Button
                key={1}
                isDisabled={
                  step === 1 || !tableFields?.some(field => field.isSelected) || activeJob
                }
                isPrimary
                onClick={() => setStep(5)}
                className={s.headerButton}
              >
                View Results
              </Button>
            ]
          : []
      }
    >
      {step < 5 && activeJob && (
        <Alert
          message="There is an existing update in progress. This update must complete processing before you can start a new bulk update."
          status="info"
          style={{ width: '100%', margin: '-6px 0 24px 0' }}
        />
      )}
      <div className={(s.panel, step <= 4 ? s.stepperContainer : null)}>
        <StepperContainer
          step={step}
          setStep={setStep}
          record={record}
          recordType={serializeBuilderRecordType(recordTypeObj)}
          setRecord={setRecord}
          tableFields={tableFields}
          setTableFields={setTableFields}
          filters={filters}
          setFilters={setFilters}
          setPreSelected={setPreSelected}
          setActiveTab={setActiveTab}
          attrType={attrType}
          setAttrType={setAttrType}
          templateType={templateType}
          setTemplateType={setTemplateType}
        />
      </div>
    </Panel>
  )
}

export default BulkBuilderContainer
