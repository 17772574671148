export const SCOPE = {
  VENDOR: 'vendor',
  MATTER: 'matter',
  LEGAL_ENTITY: 'legalEntity',
  PRACTICE_AREA: 'practiceArea',
  APPROVAL_WORKFLOW: 'approvalWorkflow',
  MATTER_REVIEW_RULES: 'matterReviewRules'
}

export const REVIEWER_SCOPE = {
  MATTER: SCOPE.MATTER,
  INVOICE: 'invoice'
}

export const REVIEWER_SCOPE_LABEL = {
  [REVIEWER_SCOPE.MATTER]: 'Matter',
  [REVIEWER_SCOPE.INVOICE]: 'Invoice'
}
