import { XeditableContainer } from 'simple-core-ui'
import { loadOptionsSelect2Json } from 'common/Selects/helpers'
import { BaseAsyncSelect } from 'common/Selects'

const ConditionalAttribute = ({
  attr,
  attrGroup,
  relationId,
  setValueCb,
  matterId,
  selectKey,
  readOnly
}) => {
  const attrIndex = attrGroup.attributes.findIndex(a => a.id === attr.id)

  let prevAttributeValue
  if (attrIndex > 0) {
    const prevAttr = attrGroup.attributes[attrIndex - 1]
    prevAttributeValue = prevAttr?.value
  }

  const value = attr.value

  return (
    <XeditableContainer
      controlled
      setValueCb={setValueCb}
      renderForm={(_, updateFormValue, formValue) => {
        const url = `/manage/attributes/${
          attr.id
        }/select2_json?matter_id=${matterId}&relationship_id=${relationId}&prev_attribute_value=${
          prevAttributeValue ? prevAttributeValue?.value : ''
        }`

        return (
          <BaseAsyncSelect
            reset={selectKey}
            value={formValue ?? value}
            loadOptions={loadOptionsSelect2Json(url)}
            onChange={updateFormValue}
          />
        )
      }}
      readOnly={attr.readOnly || readOnly || false}
      type="list"
      initialValue={value}
      isClosableByOutsideClick={false}
    />
  )
}

export default ConditionalAttribute
