import { Component } from 'react'
import { connect } from 'react-redux'
import { Panel, LoadingSkeleton } from 'simple-core-ui'
import s from '../styles/BudgetRequest.scss'
import { BUDGET_TYPES } from 'budgets/constants'
import TotalSnapshotContainer from './TotalSnapshotContainer'
import CurrentSnapshotContainer from './CurrentSnapshotContainer'
import { curMom } from 'utils/dateUtils'

const getShowCurrentSnapshot = (type, startDate, endDate) => {
  const { PHASE, LIFE_OF_MATTER } = BUDGET_TYPES
  const now = curMom().startOf('day')
  return !(
    type === PHASE ||
    type === LIFE_OF_MATTER ||
    now.isAfter(endDate) ||
    now.isBefore(startDate)
  )
}

@connect(({ budgets, app }) => {
  const { budgetDetails, status } = budgets
  const { type } = budgetDetails

  const showCurrentSnapshot = getShowCurrentSnapshot(
    type,
    budgetDetails.start_date,
    budgetDetails.end_date
  )

  return {
    showCurrentSnapshot,
    type,
    status,
    isLoading: app.loading.BUDGET_DETAILS_FETCH
  }
})
class BudgetSummaryPanel extends Component {
  render() {
    const { showCurrentSnapshot, isLoading } = this.props

    if (isLoading) {
      return (
        <Panel title="Budget Snapshot">
          <div className={s.snapshot}>
            <div style={{ padding: '30px 0px' }}></div>
            <LoadingSkeleton height={10} width={'100%'} />
            <div style={{ padding: '60px 0px' }}></div>
            <LoadingSkeleton height={10} width={'100%'} />
          </div>
        </Panel>
      )
    }

    return (
      <Panel title="Budget Snapshot">
        <div className={s.snapshot}>
          <TotalSnapshotContainer showTimeRemaining={showCurrentSnapshot} />

          {showCurrentSnapshot && <CurrentSnapshotContainer />}
        </div>
      </Panel>
    )
  }
}

export default BudgetSummaryPanel
