import { useContext } from 'react'
import { IoIosTrash } from 'react-icons/io'
import { EditableField } from 'components'
import { HoverAction, If } from 'simple-core-ui'
import { EngineContext } from 'rules/context'
import ParameterContainer from '../containers/ParameterContainer'

import get from 'lodash/get'

import s from '../styles/RuleActions.scss'

const ActionParameters = ({ parameters, actionType, actionIndex, ruleIndex }) => {
  const { canEdit } = useContext(EngineContext)

  return parameters.map((parameter, index) => {
    return (
      <ParameterContainer
        actionType={actionType}
        paramIndex={index}
        actionIndex={actionIndex}
        ruleIndex={ruleIndex}
        param={parameter}
        canEdit={canEdit}
        key={`param_${index}`}
      />
    )
  })
}

const Action = ({
  action,
  updateAction,
  availableActions,
  ruleIndex,
  actionIndex,
  removeRuleAction
}) => {
  const { canEdit } = useContext(EngineContext)
  const actionType = get(action, 'name') || ''
  const selected = {
    label: get(
      availableActions.find(({ name }) => name === actionType) || {},
      'display_name',
      actionType
    ),
    value: actionType
  }

  return (
    <li className={s.actionItem}>
      <div className={s.leftSide}>
        <EditableField
          type={'select'}
          options={availableActions.map(availableAction => ({
            ...availableAction,
            label: availableAction.display_name,
            value: availableAction.name
          }))}
          value={selected}
          onChange={updateAction}
          className={s.actionSelect}
          clearable={false}
          disabled={!canEdit}
        />
      </div>
      <If condition={actionType}>
        <div className={s.rightSide}>
          <ActionParameters
            parameters={get(action, 'params', [])}
            actionIndex={actionIndex}
            ruleIndex={ruleIndex}
            actionType={actionType}
          />
        </div>
      </If>
      <If condition={canEdit}>
        <div className={s.iconSide}>
          <HoverAction
            onClick={() => removeRuleAction(actionIndex)}
            tip="Remove Action"
            icon={<IoIosTrash />}
          />
        </div>
      </If>
    </li>
  )
}

export default Action
