import { useMemo } from 'react'
import { IoIosInformationCircle } from 'react-icons/io'
import { IoTrashOutline } from 'react-icons/io5'
import { MultiValueGenericProps, components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

import { HoverAction, CharLimitInput } from 'simple-core-ui'
import { EditableField } from 'components'
import { AutoFlipSelect } from 'simple_review/common/AutoFlipSelect'
import {
  Action as ActionType,
  AvailableAction,
  AvailableActionParam
} from 'simple_review/@types/api'
import {
  hoverActionIconStyle,
  getSearchableSelectStyles,
  textArea,
  textInput
} from 'simple_review/styles/styles'
import { getUpdatedParams, getSelectedAction } from './utils'
import s from './Action.scss'
import { Select } from '../../conditions/operand/rhs/select'
import { Constant } from 'simple_review/@types/common'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

interface Props {
  actionIndex: number
  availableActions: Array<AvailableAction>
  action: ActionType
  isReadOnly: boolean
  removeAction(): void
  updateAction(action: ActionType): void
}

const textAreaParamNames = ['comment', 'client_explanation', 'vendor_explanation']

const commentActions: Array<AvailableAction['name']> = [
  'add_activity_code',
  'add_expense_code',
  'add_task_code',
  'flag_invoice',
  'flag_line_item'
]

const getLabelValue = (selectedAction: AvailableAction, param: AvailableActionParam): string => {
  if (param.name === 'comment') {
    if (commentActions.includes(selectedAction.name)) {
      return 'Comment'
    }
    return 'Vendor and Internal Comment'
  } else if (param.name === 'client_explanation') {
    return 'Internal Comment'
  }
  return 'Vendor Comment'
}

const getParameterValue = (action: ActionType, availableParam: AvailableActionParam): string => {
  // @ts-expect-error
  return action.params.reduce((value, param) => {
    if (param.param_name === availableParam.name) {
      return availableParam.choices.length ? param.value : param.value.value
    }
    return value
  }, '')
}

const getInputLabel = (label: string, className: string, showSubText = false) => (
  <div className={className}>
    <span>{`${label}:`}</span>
    {showSubText && <span className={s.subText}>Visible to CounselGO user(s)</span>}
  </div>
)

const getInputPlacholder = (
  selectedAction: AvailableAction,
  param: AvailableActionParam
): string => {
  if (commentActions.includes(selectedAction.name)) return 'Enter comment'
  if (selectedAction.name === 'reject_invoice') {
    if (param.name === 'client_explanation') {
      return 'Enter reason of rejection for your team'
    } else {
      return 'Enter rejection comments'
    }
  }
  return 'Enter comment for vendor'
}

const autoFlipSelectStyle = getSearchableSelectStyles()

const MultiValueLabel = ({ children, ...props }: MultiValueGenericProps) => {
  return (
    <>
      <components.MultiValueLabel {...props}>
        <div data-for={props.data.value} data-tip>
          <div className={s.rejectionReasonChip}>{children}</div>
        </div>
      </components.MultiValueLabel>
      <ReactTooltip
        id={props.data.value}
        type="light"
        effect="solid"
        place="bottom"
        border
        className={s.rejectionReasonTip}
      >
        {children}
      </ReactTooltip>
    </>
  )
}

const Action = ({
  actionIndex,
  action,
  availableActions,
  isReadOnly,
  removeAction,
  updateAction
}: Props) => {
  const selectedAction = useMemo(() => getSelectedAction(action, availableActions), [
    action,
    availableActions
  ])

  const updateActionParam = (
    parameter: AvailableActionParam,
    value: string | number | { value: string; label: string }
  ) => {
    // @ts-expect-error
    updateAction({ ...action, params: getUpdatedParams(action.params, parameter, value) })
  }

  return (
    <div className={s.container}>
      <div className={s.counter}>{`${actionIndex + 1})`}</div>
      <div className={s.inputsContainer}>
        <div className={cn({ [s.mainSelectSection]: !!selectedAction })}>
          <a
            className={s.info}
            href="/help/?article_id=8906398422679"
            target="_blank"
            rel="noreferrer"
          >
            <IoIosInformationCircle size={16} />
            <span>How to set up automated actions</span>
          </a>
          <AutoFlipSelect
            value={selectedAction}
            // @ts-expect-error
            onChange={({ name }: ActionType) => updateAction({ name, params: [] })}
            placeholder="Select action"
            options={availableActions.map(availableAction => ({
              ...availableAction,
              label: availableAction.display_name,
              value: availableAction.name
            }))}
            clearable={false}
            styles={autoFlipSelectStyle}
            isDisabled={isReadOnly}
          />
          {selectedAction &&
            selectedAction.params.map(param => {
              if (param.type === 'number') {
                return (
                  <div key={param.name}>
                    {getInputLabel(param.display_name, s.inputLabel)}
                    {/* @ts-expect-error */}
                    <EditableField
                      type="number"
                      placeholder={param.description || ' '}
                      value={getParameterValue(action, param)}
                      clearable={false}
                      style={textInput}
                      onChange={(value: number) => updateActionParam(param, value)}
                      disabled={isReadOnly}
                    />
                  </div>
                )
              } else if (param.name === 'rejection_reason') {
                const rejectionReasonValues = sortAlphabeticallyByProperty(
                  action.params
                    .filter(p => {
                      return p.param_name === 'rejection_reason'
                    })
                    .map(p => p.value),
                  'label'
                )

                return (
                  <div key={param.name}>
                    {getInputLabel(param.display_name, s.inputLabel)}
                    <Select
                      choices={'/rules/invoice_validation/choices/RejectionReasons/'}
                      onChange={(value: Constant) => {
                        updateActionParam(param, value)
                      }}
                      placeholder={param.description || ' '}
                      value={rejectionReasonValues}
                      isMulti
                      styles={{
                        container(base) {
                          return { ...base, maxWidth: 'unset' }
                        },
                        multiValueLabel(base) {
                          return { ...base, whiteSpace: 'normal' }
                        }
                      }}
                      components={{
                        MultiValueLabel
                      }}
                      isDisabled={isReadOnly}
                    />
                  </div>
                )
              } else if (param.choices.length) {
                return (
                  <div key={param.name}>
                    {getInputLabel(param.display_name, s.inputLabel)}
                    <AutoFlipSelect
                      value={getParameterValue(action, param)}
                      // @ts-expect-error
                      onChange={(value: { value: string; label: string }) =>
                        updateActionParam(param, value)
                      }
                      placeholder=" "
                      options={param.choices}
                      clearable={false}
                      styles={getSearchableSelectStyles()}
                      isDisabled={isReadOnly}
                    />
                  </div>
                )
              } else if (
                param.type === 'string' &&
                !textAreaParamNames.includes(param.name.toLowerCase())
              ) {
                return (
                  <div key={param.name}>
                    {getInputLabel(param.display_name, s.inputLabel)}
                    {/* @ts-expect-error */}
                    <EditableField
                      type="text"
                      placeholder={param.description || ' '}
                      value={getParameterValue(action, param)}
                      style={textInput}
                      onChange={(value: string) => updateActionParam(param, value)}
                      disabled={isReadOnly}
                    />
                  </div>
                )
              }
            })}
        </div>
        {selectedAction && (
          <div className={s.commentsSection}>
            {selectedAction.params.map(param => {
              const shouldShowSubText =
                !commentActions.includes(selectedAction.name) &&
                (selectedAction.name !== 'reject_invoice' ||
                  (selectedAction.name === 'reject_invoice' && param.name === 'vendor_explanation'))

              if (
                param.type === 'string' &&
                textAreaParamNames.includes(param.name.toLowerCase())
              ) {
                return (
                  <div key={param.name}>
                    {getInputLabel(
                      getLabelValue(selectedAction, param),
                      s.textAreaLabel,
                      shouldShowSubText
                    )}
                    <CharLimitInput
                      type="textarea"
                      value={getParameterValue(action, param)}
                      style={{ ...textArea, minHeight: '110px', marginRight: '20px' }}
                      maxLength={2000}
                      focused={false}
                      placeholder={getInputPlacholder(selectedAction, param)}
                      onChangeCb={e => updateActionParam(param, e.target.value)}
                      isDisabled={isReadOnly}
                    />
                  </div>
                )
              }
            })}
          </div>
        )}
      </div>
      {!isReadOnly && (
        <HoverAction
          tip="Remove Action"
          data-testid="remove-action"
          className={s.trashIcon}
          forceClass
          icon={<IoTrashOutline />}
          style={{ ...hoverActionIconStyle, marginTop: '24px' }}
          onClick={removeAction}
        />
      )}
    </div>
  )
}

export default Action
