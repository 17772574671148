import { KeyValuePairs, TextInput } from 'simple-core-ui'
import s from './GeneralInfoHeader.scss'

const GeneralInfoHeader = ({
  name,
  description,
  changeDescription,
  changeName,
  namePlaceholder,
  nameLabel,
  descriptionLabel,
  disabled
}) => (
  <div className={s.container}>
    <KeyValuePairs
      pairs={[
        {
          key: nameLabel,
          value: (
            <TextInput
              type="text"
              value={name}
              className={s.nameInput}
              placeholder={namePlaceholder}
              onChange={changeName}
              isDisabled={disabled}
              testid="template_name_input"
              style={{ maxWidth: 250, marginTop: '0.5rem' }}
            />
          )
        },
        {
          key: descriptionLabel,
          value: (
            <TextInput
              type="text"
              value={description}
              className={s.input}
              placeholder="Description"
              onChange={changeDescription}
              isDisabled={disabled}
              testid="template_description_input"
              style={{ marginTop: '0.5rem' }}
            />
          )
        }
      ]}
      stackedKeys
    />
  </div>
)

export default GeneralInfoHeader
