import { useState } from 'react'
import s from './SearchListModal.scss'
import { IoIosSearch } from 'react-icons/io'
import { TextInput } from 'simple-core-ui'

const inputStyles = {
  borderRadius: '2px',
  padding: '0.2rem 1.3rem',
  width: '70%'
}

const SearchListModal = ({ listItems }) => {
  const [searchString, setSearchString] = useState('')

  const handleSearch = searchTerm => {
    setSearchString(searchTerm)
  }

  const renderList = () => (
    <div className={s.listContainer}>
      {listItems
        .filter(li => li.toLowerCase().includes(searchString.toLowerCase()))
        .map(li => (
          <p className={s.listItem} key={li}>
            {li}
          </p>
        ))}
    </div>
  )

  return (
    <div className={s.modalContainer}>
      <div className={s.searchFieldContainer}>
        <IoIosSearch className={s.searchIcon} />
        <TextInput
          placeholder="Type to search"
          onChange={handleSearch}
          value={searchString}
          style={inputStyles}
          onFocus={() => setSearchString('')}
        />
      </div>
      {renderList()}
    </div>
  )
}

export default SearchListModal
