import { IoIosMore } from 'react-icons/io'

import { Task } from '../types'
import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  task: Task
  editTask: (row: Task) => void
  deleteTask: (row: Task) => void
  duplicateTask: (row: Task) => void
}

const ActionsPopover = ({ task, deleteTask, editTask, duplicateTask }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editTask(task)
      }
    },
    {
      name: 'COPY',
      text: 'Copy',
      onClick() {
        duplicateTask(task)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteTask(task)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${task.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
