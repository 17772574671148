import { Tabs, Tab, Panel } from 'simple-core-ui'
import BudgetHealth from './BudgetHealth'
import ActivityLog from 'common/ActivityLog'

const BudgetSnapshot = () => {
  return (
    <Panel isBodyOnly>
      <Tabs>
        <Tab header="Budget Health">
          <BudgetHealth />
        </Tab>
        <Tab header="Budget Activity">
          <ActivityLog modelId={window.serverContext.get('object_id')} modelName="Budgets" />
        </Tab>
      </Tabs>
    </Panel>
  )
}

export default BudgetSnapshot
