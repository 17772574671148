import { useState, Fragment, useEffect } from 'react'
import { CalendarView } from './CalendarView'
import { Panel, Button, UpdateToPremiumLabel, ButtonDropdown, useLoading } from 'simple-core-ui'
import { CalendarSyncModal } from 'common/CalendarSyncModal'
import { isTaskCalendarSyncEnabled } from 'common/Tasks/utils'
import s from './CalendarWorkbench.scss'
import ReactTooltip from 'react-tooltip'
import { isBasicTaskManagementPlan, hasModule } from 'utils/helpers'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { AddTask } from 'common/Tasks/AddTask'
import noop from 'lodash/noop'
import { Task } from 'common/Tasks/types'
import { Event } from 'common/Events/types'
import { fromTask } from 'common/Tasks/serializers'
import { AddEvent } from 'common/Events/AddEvent'
import { fromEvent } from 'common/Events/serializers'
import { AddCourtRulesModal } from 'common/Events/AddCourtRulesModal'
import { FaCalendarAlt } from 'react-icons/fa'

const isBasicPlan = isBasicTaskManagementPlan()

const CalendarWorkbench = () => {
  const [isCalendarSyncModalVisible, setIsCalendarSyncModalVisible] = useState(false)
  const [hasCalendarRules, setHasCalendarRules] = useState(null)
  const [selectedJurisdictionId, setSelectedJurisdictionId] = useState(null)
  const [isAddTaskVisible, setIsAddTaskVisible] = useState(false)
  const [isAddEventVisible, setIsAddEventVisible] = useState(false)
  const [isAddCourtRulesModalVisible, setAddCourtRulesModalVisible] = useState(false)
  const [scopeId, setScopeId] = useState('')
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [reRenderKey, setReRenderKey] = useState(0)

  const fetchCalendarRulesConfig = async () => {
    try {
      const { configured, jurisdiction_system_id } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setHasCalendarRules(configured)
      setSelectedJurisdictionId(jurisdiction_system_id)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (!hasModule('has_event_management')) return

    fetchCalendarRulesConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleAddTaskSidebar = () => {
    setIsAddTaskVisible(!isAddTaskVisible)
  }

  const toggleAddEventSidebar = () => {
    setIsAddEventVisible(!isAddEventVisible)
  }

  const toggleAddCourtRulesModal = () => {
    setAddCourtRulesModalVisible(!isAddCourtRulesModalVisible)
  }

  const saveTask = async (task: Task) => {
    try {
      const newTask = await withLoadingLocks(
        makePostRequest(`/task-management/matters/${scopeId}/tasks/`, fromTask(task, scopeId))
      )
      toggleAddTaskSidebar()
      setReRenderKey(reRenderKey => reRenderKey + 1)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Task ${newTask?.name} successfully created.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const saveEvent = async (event: Event) => {
    try {
      const newEvent = await withLoadingLocks(
        makePostRequest(`/event-management/matters/${scopeId}/events/`, fromEvent(event, scopeId))
      )
      toggleAddEventSidebar()
      setReRenderKey(reRenderKey => reRenderKey + 1)

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Event ${newEvent?.name} successfully created.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <Fragment key={reRenderKey}>
      <Panel
        title="Calendar"
        rightActions={[
          ...(isTaskCalendarSyncEnabled()
            ? [
                <Fragment key="sync">
                  <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                    <Button
                      style={{
                        padding: '8px 15px',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        bottom: 2
                      }}
                      onClick={() => setIsCalendarSyncModalVisible(true)}
                      isPrimary
                      isOutline
                      hasNewDesign
                      isDisabled={isBasicPlan}
                    >
                      <FaCalendarAlt className={s.calendarIcon} /> Calendar Sync{' '}
                      {isBasicPlan && <UpdateToPremiumLabel />}
                    </Button>
                  </div>
                  <ReactTooltip
                    id="buttonTooltip"
                    type="light"
                    effect="solid"
                    place="top"
                    border
                    disable={!isBasicPlan}
                    className={s.tooltipPopup}
                  >
                    Contact customer success to upgrade.
                  </ReactTooltip>
                </Fragment>
              ]
            : []),
          <ButtonDropdown
            key="add"
            displayText="Add"
            onSelect={option => {
              if (option.value === 'simpleTask') {
                toggleAddTaskSidebar()
              } else if (option.value === 'simpleEvent') {
                toggleAddEventSidebar()
              } else {
                toggleAddCourtRulesModal()
              }
            }}
            options={[
              ...(hasModule('has_tasks') ? [{ label: 'Create a task', value: 'simpleTask' }] : []),
              ...(hasModule('has_event_management')
                ? [{ label: 'Create an event', value: 'simpleEvent' }]
                : []),
              {
                label: 'Add court rules',
                value: 'courtRoules',
                isPremium: !(isBasicPlan || !hasCalendarRules),
                hasTooltip: isBasicPlan || !hasCalendarRules
              }
            ]}
            isPrimary
            alignRight
            listStyles={{ marginTop: 12, fontSize: 14 }}
            style={{
              padding: '10px 15px',
              borderRadius: '4px',
              marginLeft: 10,
              position: 'relative',
              bottom: 2
            }}
            listItemStyles={{ fontSize: 14, margin: '10px 0' }}
            hasNewDesign
          />
        ]}
      >
        <CalendarView />
      </Panel>
      {isCalendarSyncModalVisible && (
        <CalendarSyncModal
          toggleModal={() => setIsCalendarSyncModalVisible(!isCalendarSyncModalVisible)}
        />
      )}
      {isAddTaskVisible && (
        <AddTask
          toggleAddTaskSidebar={toggleAddTaskSidebar}
          tasks={[]}
          fetchTasks={noop}
          task={null}
          scopeName=""
          saveTask={saveTask}
          onDeleteTask={noop}
          setTasks={noop}
          scopeId={scopeId}
          oldTask={null}
          onEditSubtask={noop}
          duplicateTask={() => Promise.resolve()}
          readOnly={false}
          context="workbench"
          setScopeId={setScopeId}
          openedTab=""
          refreshTable={noop}
        />
      )}
      {isAddEventVisible && (
        <AddEvent
          toggleAddEventSidebar={toggleAddEventSidebar}
          events={[]}
          scopeName=""
          event={null}
          saveEvent={saveEvent}
          setEvents={noop}
          scopeId={scopeId}
          oldEvent={null}
          readOnly={false}
          context="workbench"
          setScopeId={setScopeId}
          openedTab=""
          refreshTable={noop}
        />
      )}
      {isAddCourtRulesModalVisible && (
        <AddCourtRulesModal
          toggleModal={toggleAddCourtRulesModal}
          selectedJurisdictionId={selectedJurisdictionId}
          scopeName=""
          scopeId={scopeId}
          refreshTable={() => {
            setReRenderKey(reRenderKey => reRenderKey + 1)
            toggleAddCourtRulesModal()
          }}
          context="workbench"
          setScopeId={setScopeId}
        />
      )}
    </Fragment>
  )
}

export default CalendarWorkbench
