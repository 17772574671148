import { useState, useEffect, useRef } from 'react'
import { OutsideClickContainer, useSyncStateWithProps, withConfirmation } from 'simple-core-ui'
import s from '../SearchFilter.scss'
import cn from 'classnames'

export const DEFAULT_CONF = {
  title: 'List of selections will be lost.',
  text: 'Do you wish to continue?'
}

const BaseFilter = ({
  onSelect,
  title,
  render,
  initialValue,
  defaultValue,
  customLabel,
  onCancel,
  hasConfirmation,
  reset,
  testid
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useSyncStateWithProps(initialValue)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setSelected(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const setFilterSelection = (item, predefined = false) => {
    const filterSelectionCb = () => {
      let itemValue
      if (predefined) {
        itemValue = item
      } else {
        itemValue = item || null
      }

      setSelected(item)
      setIsOpen(false)
      onSelect(itemValue)
    }

    hasConfirmation
      ? withConfirmation(() => {
          filterSelectionCb()
        }, DEFAULT_CONF)()
      : filterSelectionCb()
  }

  const renderLabel = () => {
    if (Array.isArray(selected) && !selected.length) {
      return title
    } else {
      return selected
        ? Array.isArray(selected)
          ? selected.map(i => i.label).join(', ')
          : selected.label
        : title
    }
  }

  const cancel = () => {
    onCancel && onCancel()
    setIsOpen(false)
  }

  return (
    <OutsideClickContainer closeComponent={() => cancel()}>
      <div
        data-testid={testid}
        className={cn(s.filter, { [s.selected]: !!selected })}
        onClick={() => {
          if (!isOpen) {
            setIsOpen(true)
          }
        }}
      >
        <span>{customLabel ? customLabel(selected) : renderLabel()}</span>
        {isOpen ? render(setFilterSelection, setIsOpen, selected) : null}
      </div>
    </OutsideClickContainer>
  )
}

export default BaseFilter
