import { ModalContainer, Alert, CharLimitInput } from 'simple-core-ui'
import s from './CategoryModal.scss'

const CategoryModal = ({
  category,
  toggleCategoryModal,
  updateCategoryName,
  addCategory,
  showDuplicateError,
  isEdit,
  editCategory
}) => {
  return (
    <ModalContainer
      title={isEdit ? 'Edit Category' : 'Add Category'}
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleCategoryModal}
      confirmText="Save"
      confirmCb={isEdit ? editCategory : addCategory}
      isDisabled={showDuplicateError || !category.name.trim() || category.name.length > 250}
      content={
        <div className={s.modalBody}>
          {showDuplicateError && (
            <Alert
              className={s.alert}
              message="The document category name already exists. Please specify a different name."
              status="error"
            />
          )}
          <div className={s.info}>New category name</div>
          <CharLimitInput
            value={category.name}
            onChangeCb={e => updateCategoryName(e.target.value)}
            placeholder="Category"
          />
        </div>
      }
    />
  )
}

export default CategoryModal
