import get from 'lodash/get'
import { RejectionReason } from './types'

interface Cell {
  columnKey: string
  content: any
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find((cell: any) => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
    case 'code':
    case 'description':
    case 'long_description':
      return cellContent.toLowerCase()
    case 'related_invoices':
      return cellContent === '----' ? 0 : +cellContent
    case 'active':
      return cellContent !== '----'
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const checkDuplicateReason = (reasonsNameList: Array<string>, reasonName: string) => {
  return reasonsNameList.includes(
    reasonName
      .trim()
      .replace(/ +(?= )/g, '')
      .toLowerCase()
  )
}

export const sortReasonsList = (reasonsList: Array<RejectionReason>) =>
  reasonsList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
