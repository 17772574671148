import AccountSettingsItem from './AccountSettingsItem'
import APP_PAGES_MAP from 'data/app_pages_map'
import s from '../styles/AccountSettings.scss'
import { createBgImg } from 'simple-core-ui'

const QuickNavSettings = ({ accountLinks }) => {
  return (
    <ul className={s.container}>
      {accountLinks.map(link => {
        const styles = {
          ...createBgImg({ path: `icons/${APP_PAGES_MAP[link.name].icon}.svg` }),
          height: '1em',
          width: '1em',
          fontSize: '1em',
          margin: '0 0.2em'
        }

        let icon = <span style={styles} className={s.svgIcon} />

        const baseStyles = {
          height: '1em',
          width: '1em',
          fontSize: '1em',
          margin: '0 0.2em'
        }
        icon = (
          <span style={baseStyles} className={s.svgIcon}>
            {APP_PAGES_MAP[link.name].icon}
          </span>
        )

        return (
          <AccountSettingsItem
            {...APP_PAGES_MAP[link.name]}
            icon={icon}
            url={link.url}
            key={link.name}
            id={`slm_${link.name}_link`}
          />
        )
      })}
    </ul>
  )
}

export default QuickNavSettings
