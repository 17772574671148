import { useContext } from 'react'
import { ComboBoxContainer } from 'containers'
import { EngineContext } from 'rules/context'

const operator = ({ operatorView, selectionCb }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { canEdit } = useContext(EngineContext)

  return (
    !!operatorView.options.length && (
      <ComboBoxContainer
        selectionCb={selectionCb}
        options={operatorView.options}
        label={operatorView.label}
        disabled={!canEdit}
      />
    )
  )
}

export default operator
