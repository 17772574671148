import qs from 'query-string'
import {
  SingleSelectFilterSerializer,
  MultiSelectFilterSerializer
} from 'common/Filters/serializers'
import { GENDER, RACE, NoData } from './constants'

export const formatCardData = cardData => ({
  value: Number((cardData.Yes * 100).toFixed(0)),
  preferNotToSay: Number((cardData['Prefer not to say'] * 100).toFixed(0)),
  noData: Number((cardData[''] * 100).toFixed(0)),
  no: Number((cardData.No * 100).toFixed(0))
})

export const formatDoughnutData = doughnutData => {
  // TODO: Use some kind of schema (webspec?) rather than hard coded strings.
  const {
    [GENDER.F]: Female,
    [GENDER.M]: Male,
    [GENDER.NB]: nb,
    [GENDER.PNTS]: pnts,
    '': noData
  } = doughnutData

  const buildObject = {
    [GENDER.F]: Female,
    [GENDER.M]: Male,
    [GENDER.NB]: nb,
    [GENDER.PNTS]: pnts,
    [NoData]: noData
  }

  return {
    data: Object.values(buildObject).map(data => Number((data * 100).toFixed(0))),
    labels: Object.keys(buildObject).map(label => label)
  }
}

export const formatBarData = barData => {
  const {
    [RACE.AAB]: aab,
    [RACE.AIAN]: aian,
    [RACE.A]: Asian,
    [RACE.HL]: hl,
    [RACE.MULTI]: Multiracial,
    [RACE.W]: White,
    [GENDER.PNTS]: pnts,
    [RACE.PI]: pi,
    '': noData
  } = barData

  const buildObject = {
    [RACE.AAB]: aab,
    [RACE.AIAN]: aian,
    [RACE.A]: Asian,
    [RACE.HL]: hl,
    [RACE.MULTI]: Multiracial,
    [RACE.PI]: pi,
    [RACE.W]: White,
    [RACE.PNTS]: pnts,
    [NoData]: noData
  }

  let sorted_labels = Object.keys(buildObject)

  let sorted_values = []

  sorted_labels.forEach(label => {
    sorted_values.push(buildObject[label])
  })

  return {
    data: sorted_values.map(data => (data ? Number((data * 100).toFixed(0)) : 0)),
    labels: sorted_labels.map(label => label)
  }
}

export const toFragment = param => {
  const { date, vendor, practiceArea, tab, choice, vendorComparison } = param
  const vendorSelector =
    vendor && vendor.label ? `${SingleSelectFilterSerializer.toFragment('vendor', vendor)}&` : ''

  const vendorComparisonSelector =
    vendorComparison && vendorComparison.length
      ? `${MultiSelectFilterSerializer.toFragment('vendorComparison', vendorComparison)}&`
      : ''

  const choiceSelector =
    choice && choice.label ? `${SingleSelectFilterSerializer.toFragment('choice', choice)}&` : ''

  const tabSelector = tab ? `tab=${tab}&` : ''

  const practiceAreaSelector =
    practiceArea && practiceArea.label
      ? `${SingleSelectFilterSerializer.toFragment('practiceArea', practiceArea)}&`
      : ''

  const dateSelector =
    date && date.label !== 'date=all::All&'
      ? `${SingleSelectFilterSerializer.toFragment('date', date)}&`
      : `date=ytd::${new Date().getFullYear()} (YTD)&`

  return `#${vendorSelector}${dateSelector}${practiceAreaSelector}${tabSelector}${vendorComparisonSelector}${choiceSelector}`
}

export const fromFragment = hash => {
  const { date, vendor, practiceArea, tab, choice, vendorComparison } = qs.parse(hash)

  const dateSelector = !!date
    ? SingleSelectFilterSerializer.fromFragment('date', { date }, false)
    : {}

  const practiceAreaSelector = practiceArea
    ? SingleSelectFilterSerializer.fromFragment('practiceArea', { practiceArea })
    : {}

  const tabSelector = tab ? { tab } : {}

  const vendorSelector = vendor
    ? SingleSelectFilterSerializer.fromFragment('vendor', { vendor }, false)
    : {}

  const vendorComparisonSelector = vendorComparison
    ? MultiSelectFilterSerializer.fromFragment('vendorComparison', { vendorComparison })
    : []

  const choiceSelector = choice
    ? SingleSelectFilterSerializer.fromFragment('choice', { choice }, false)
    : {}

  return {
    vendorSelector,
    practiceAreaSelector,
    dateSelector,
    choiceSelector,
    vendorComparisonSelector,
    tabSelector
  }
}
