import s from '../Diversity.scss'

const HorizontalChart = ({
  label,
  percentage,
  color,
  width = 85,
  marginRight,
  breakLabel = true
}) => {
  const numPercentage = parseInt(percentage * 100)
  return (
    <div style={{ marginRight, display: 'inline-block', width }} className={s.flexAlignCenter}>
      {label && (
        <p className={s.mr10} style={breakLabel ? { wordWrap: 'break-word' } : {}}>
          {label}
        </p>
      )}
      <div className={s.smallChart}>
        <div
          style={{
            width: numPercentage === 0 ? 0 : numPercentage + '%',
            backgroundColor: color
          }}
          className={s.horizontalChartWrapper}
        >
          <p
            style={{
              color: numPercentage > 50 ? 'white' : color,
              right: numPercentage > 50 ? 5 : -30
            }}
            className={s.horizontalChartInner}
          >
            {numPercentage}%
          </p>
        </div>
      </div>
    </div>
  )
}

export default HorizontalChart
