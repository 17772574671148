import PropTypes from 'prop-types'

import Select from './Select'
import Box from './Box'

import TYPES from './types'

import s from './ComboBox.scss'

const defaultStyles = {
  dropdown: {}
}

const ComboBox = ({
  isOpen,
  triggerCb,
  heading,
  options,
  filterValue,
  filterCb,
  selectionCb,
  navigateBackCb,
  label,
  isLoading,
  isEmpty,
  loadCb,
  showCombo,
  custom,
  disabled,
  style = defaultStyles
}) => (
  <main className={s.container}>
    <Select isOpen={isOpen} triggerCb={triggerCb} label={label} disabled={disabled} />
    {isOpen && (
      <Box
        showCombo={showCombo}
        heading={heading}
        navigateBackCb={navigateBackCb}
        filterValue={filterValue}
        filterCb={filterCb}
        loadCb={loadCb}
        options={options}
        selectionCb={selectionCb}
        isOpen={isOpen}
        isLoading={isLoading}
        isEmpty={isEmpty}
        custom={custom}
        style={style.dropdown}
      />
    )}
  </main>
)

ComboBox.propTypes = {
  isOpen: TYPES.isOpen,
  triggerCb: TYPES.triggerCb,
  heading: TYPES.heading,
  options: PropTypes.arrayOf(TYPES.option),
  filterValue: TYPES.filterValue,
  filterCb: TYPES.filterCb,
  selectionCb: TYPES.selectionCb,
  navigateBackCb: TYPES.navigateBackCb,
  label: TYPES.label,
  isLoading: TYPES.isLoading,
  isEmpty: PropTypes.bool,
  custom: TYPES.custom,
  disabled: PropTypes.bool,
  style: PropTypes.shape({
    dropdown: PropTypes.object
  })
}

export default ComboBox
