import { IoIosMore } from 'react-icons/io'
import { HoverAction, Popover, UpdateToPremiumLabel } from 'simple-core-ui'
import s from './ActionsPopover.scss'
import { SerializedResultItem } from 'files/types'
import { isBasicTaskManagementPlan, isBasicSpendPlan } from 'utils/helpers'

interface Props {
  rowData: SerializedResultItem
  actionCb: (actionType: string, rowData: SerializedResultItem) => void
  selectedTab: string
  isInfected: boolean
  isVendorActive: boolean
}

const ActionsPopover = ({ rowData, actionCb, selectedTab, isInfected, isVendorActive }: Props) => {
  const isBasicMatterPlan = isBasicTaskManagementPlan()
  const isStarterSpendPlan = isBasicSpendPlan()
  const clientActions = [
    {
      name: 'RENAME',
      text: 'Rename',
      visible: !isInfected && isVendorActive,
      onClick() {
        actionCb('RENAME', rowData)
      }
    },
    {
      name: 'DOWNLOAD',
      text: 'Download',
      visible: !isInfected,
      onClick() {
        actionCb('DOWNLOAD', rowData)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      visible: isVendorActive,
      onClick() {
        actionCb('DELETE', rowData)
      }
    }
  ]

  const vendorActions = [
    {
      name: 'COPY',
      text:
        !isBasicMatterPlan || !isStarterSpendPlan ? (
          'Copy'
        ) : (
          <>
            <span className={s.disabled}>Copy</span>
            <UpdateToPremiumLabel />
          </>
        ),
      onClick:
        !isBasicMatterPlan || !isStarterSpendPlan ? () => actionCb('COPY', rowData) : () => {}
    },
    {
      name: 'DOWNLOAD',
      text: 'Download',
      onClick() {
        actionCb('DOWNLOAD', rowData)
      }
    }
  ]

  const actions = selectedTab === 'our_files' ? clientActions : vendorActions

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
      contentClassName={s.actionsList}
      borderColor="#000"
    />
  )
}

export default ActionsPopover
