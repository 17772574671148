import { useMemo, useState } from 'react'
import { Panel, Categories } from 'simple-core-ui'
import { SurveyConfigTabContainer } from './SurveyConfigTab'
import { SurveyCompletedTab } from './SurveyCompletedTab'
import { SurveyPendingTab } from './SurveyPendingTab'
import Select from 'react-select'
import { SURVEY_STATUSES } from './constants'
import s from './Surveys.scss'
import {
  SURVEY_CONFIG_SHARED_CHANGE_STATUS,
  SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_REQUESTED,
  SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED
} from './SurveyConfigTab/actions'
import { useSelector, useDispatch } from 'react-redux'

const Surveys = ({ matterId, readOnly }) => {
  const [tabState, setTabState] = useState('configuration')
  const dispatch = useDispatch()
  const { sharedEvaluators } = useSelector(state => state.surveyConfig)

  const tabs = useMemo(() => {
    return {
      completed: <SurveyCompletedTab scopeId={matterId} />,
      pending: <SurveyPendingTab scopeId={matterId} readOnly={readOnly} />,
      configuration: <SurveyConfigTabContainer scopeId={matterId} readOnly={readOnly} />
    }
  }, [matterId, readOnly])

  const changeSurveysStatus = value => {
    const usersArr = sharedEvaluators.users.map(function(user) {
      return user.value
    })
    const rolesArr = sharedEvaluators.roles.map(function(role) {
      return role.value
    })

    dispatch({
      type: SURVEY_CONFIG_SHARED_CHANGE_STATUS,
      payload: {
        status: value.value
      }
    })
    dispatch({
      type: SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_REQUESTED,
      payload: {
        entityId: matterId,
        status: value.value
      }
    })
    dispatch({
      type: SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED,
      payload: {
        entityId: matterId,
        users: usersArr,
        roles: rolesArr,
        status: value.value
      }
    })
  }

  return (
    <Panel
      title="Vendor Surveys"
      rightActions={[
        <Select
          key="0"
          className={s.selectContainer}
          isSearchable={false}
          value={SURVEY_STATUSES.find(s => s.value === sharedEvaluators.status)}
          onChange={changeSurveysStatus}
          options={SURVEY_STATUSES}
          isDisabled={readOnly}
        />
      ]}
    >
      <div className="filter-container">
        <Categories
          categories={[
            {
              id: 'completed',
              content: 'Completed',
              isActive: 'completed' === tabState
            },
            {
              id: 'pending',
              content: 'Pending',
              isActive: 'pending' === tabState
            },
            {
              id: 'configuration',
              content: 'Configuration',
              isActive: 'configuration' === tabState
            }
          ]}
          clickCb={setTabState}
        />
      </div>
      {tabs[tabState]}
    </Panel>
  )
}

export default Surveys
