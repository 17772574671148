import { createReducer } from 'redux-create-reducer'
import ACT from './actions'

const initialState = {
  isConfidential: false
}

const matterReducer = createReducer(initialState, {
  [ACT.MATTER_DETAIL_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      isConfidential: action.payload.isConfidential
    }
  }
})

export default matterReducer
