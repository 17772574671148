import { Input, Checkbox, HoverFieldContainer, Condense, If, Alert } from 'simple-core-ui'
import { ContactsSelect } from 'contacts'
import { SCOPE, HIDE_REJECTION_REASON } from '../utils/constants'
import s from '../styles/ReviewLadder.scss'
import ReasonsForm from './ReasonsForm'
import variables from '../../../styles/_variables.scss'
import { Fields, PreferenceSetting } from 'invoices/types'
import { CSSProperties } from 'react'

const textAreaStyle: CSSProperties = { marginTop: '1em', height: '250px' }

const scopedRequestConfig = (scope: string, vendorId: number) =>
  ({
    [SCOPE.INVOICE]: { v: window.serverContext.get('vendor_id') || vendorId, l: 'true' }
  }[scope])

const extractLabel = ({
  firstName,
  lastName,
  email
}: {
  firstName: string
  lastName: string
  email: string
}) => (firstName && lastName ? `${firstName} ${lastName}` : email)

interface ReviewFields extends Fields {
  subject: string
}

interface Props {
  scope: string
  vendorId: number
  fields: ReviewFields
  updateFields: (arg: unknown) => void
  showAddAttachments: boolean
  showAddPDF: boolean
  preferenceSetting: PreferenceSetting | null
  isRejection: boolean
  isApproval: boolean
  isLoading: boolean
}

const ReviewModalForm = ({
  scope,
  vendorId,
  fields,
  updateFields,
  showAddAttachments,
  showAddPDF,
  preferenceSetting,
  isRejection,
  isApproval,
  isLoading
}: Props) => {
  const notifyViaEmailPreference =
    isLoading ||
    (isRejection && preferenceSetting?.invoice_rejection_reason_email_notify?.value) ||
    (isApproval && preferenceSetting?.invoice_adjustment_reason_email_notify?.value)
  const isRejectionOrApproval = isRejection || isApproval
  const inputStyle = { margin: isRejectionOrApproval ? '0' : '0.5em 0' }
  const notifyStyle = { opacity: notifyViaEmailPreference ? 0.4 : 1 }
  const notifyViaEmailStyle = { opacity: fields.notifyViaEmail ? 1 : 0.4 }
  const renderFieldLabel = (label: string) =>
    isRejectionOrApproval ? <span className={s.fieldLabel}>{label}</span> : null

  const disabledTextAreaStyle: CSSProperties = {
    height: isApproval ? '140px' : '120px',
    fontStyle: 'italic',
    color: variables.baseGrey,
    overflowY: 'hidden',
    padding: '10px'
  }

  return (
    <section>
      {
        <If condition={isRejectionOrApproval}>
          <section className={s.inlineFieldGroup} style={notifyStyle}>
            <Checkbox
              triggerCheckbox={() => updateFields({ notifyViaEmail: !fields.notifyViaEmail })}
              isChecked={fields.notifyViaEmail}
              size="sm"
              styles={inputStyle}
              disabled={notifyViaEmailPreference}
            />
            <span className={s.checkBoxFieldLabel}>Notify Via Email</span>
          </section>
        </If>
      }
      <If
        condition={
          (!isRejection && !isApproval) || (isRejectionOrApproval && fields.notifyViaEmail)
        }
      >
        <>
          {renderFieldLabel('To')}
          <HoverFieldContainer
            displayValue={
              <Condense
                values={fields.emailTo ? fields.emailTo.map(extractLabel as any) : []}
                badgeProps={{ size: 'sm', asPill: true }}
              />
            }
            renderField={() => (
              // @ts-expect-error
              <ContactsSelect
                {...(scopedRequestConfig(scope, vendorId)
                  ? { requestConfig: { params: scopedRequestConfig(scope, vendorId) } }
                  : {})}
                onChange={(contacts: Record<string, unknown>[]) => {
                  updateFields({ emailTo: contacts })
                }}
                value={fields.emailTo}
                createable
                multi
                isPortal={false}
              />
            )}
            isEmpty={!fields.emailTo || !fields.emailTo.length}
            emptyValue="Send to..."
            displayAsInput
          />
          {renderFieldLabel('Subject')}
          <Input
            onChangeCb={({ target }) => updateFields({ subject: target.value })}
            placeholder="Subject..."
            text={fields.subject}
            style={inputStyle}
          />
          <If condition={!HIDE_REJECTION_REASON.includes(scope)}>
            <Alert
              message={`A reason for requesting changes is required. This message will be visible on the ${scope}.`}
              status="info"
            />
          </If>
          {renderFieldLabel('Invoice Details')}
          <Input
            onChangeCb={({ target }) => updateFields({ body: target.value })}
            placeholder="Message..."
            style={isRejectionOrApproval ? disabledTextAreaStyle : textAreaStyle}
            text={fields.body}
            type="textarea"
            isDisabled={isRejectionOrApproval}
          />
        </>
      </If>
      <If condition={[SCOPE.INVOICE].includes(scope) && isRejectionOrApproval}>
        <ReasonsForm
          fields={fields}
          updateFields={updateFields}
          preferenceSetting={preferenceSetting}
          isRejection={isRejection}
        />
      </If>
      <If condition={showAddAttachments}>
        <section className={s.inlineFieldGroup} style={notifyViaEmailStyle}>
          <Checkbox
            triggerCheckbox={() => updateFields({ addAttachments: !fields.addAttachments })}
            isChecked={fields.addAttachments}
            size="sm"
            disabled={!fields.notifyViaEmail}
          />
          <span className={s.checkBoxFieldLabel}>Include Attachments</span>
        </section>
      </If>
      <If condition={showAddPDF}>
        <section className={s.inlineFieldGroup} style={notifyViaEmailStyle}>
          <Checkbox
            triggerCheckbox={() => updateFields({ addPdfCopy: !fields.addPdfCopy })}
            isChecked={fields.addPdfCopy}
            size="sm"
            disabled={!fields.notifyViaEmail}
          />
          <span className={s.checkBoxFieldLabel}>Attach PDF Copy</span>
        </section>
      </If>
    </section>
  )
}

export default ReviewModalForm
