import { Component } from 'react'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import swal from 'sweetalert'
import queryString from 'query-string'
import get from 'lodash/get'

import { ENABLED_ENGINES } from 'rules/constants'
import RulesDSL from '../components/RulesDSL'

import ACT from 'rules/actions'

@connect(({ rules: { dsl } }) => ({ dsl }))
@withFetchFlow({
  flag: 'Rule',
  getFetchAction: props => ({
    type: ACT.FETCH_ALL_DSL_RULES_REQUESTED
  })
})
export default class RulesDSLContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: '',
      engine: this.getInitialEngine()
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload)
  }

  handleUnload = async event => {
    const { text, engine } = this.state
    const { dsl } = this.props

    if (dsl.isEdit && text !== dsl[engine].rulesText) {
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
  }

  getInitialEngine = () => get(queryString.parse(location.search), 'engine', ENABLED_ENGINES[0])

  updateText = text => {
    this.setState({
      text: text
    })
  }

  updateLinkParam = engine => {
    const { search, pathname } = window.location
    let queryParams = new URLSearchParams(search.slice(1))
    queryParams.set('engine', engine)
    window.history.replaceState(null, null, `${pathname}?${String(queryParams)}`)
  }

  changeEngine = engine => {
    this.updateLinkParam(engine)
    this.setState({ engine })
  }

  rulesWereUpdated = () => {
    const { text, engine } = this.state
    const { dsl } = this.props

    return text !== dsl[engine].rulesText
  }

  submit = () => {
    if (this.rulesWereUpdated()) {
      this.props.dispatch({
        type: ACT.SUBMIT_RULE_DSL_REQUESTED,
        payload: {
          text: this.state.text,
          engine: this.state.engine
        }
      })
    } else {
      this.toggleEdit()
    }
  }

  toggleEdit = () => {
    const {
      dsl: { isEdit }
    } = this.props

    this.props.dispatch({
      type: isEdit ? ACT.DSL_IS_NOT_EDIT : ACT.DSL_IS_EDIT
    })

    this.setState(prevState => ({
      text: this.props.dsl[prevState.engine].rulesText
    }))
  }

  cancelEdit = async () => {
    let shouldCancel = true

    if (this.rulesWereUpdated()) {
      shouldCancel = await swal({
        title: 'Are you sure?',
        text: "The changes you've made will be discarded.",
        buttons: ['No', 'Yes'],
        icon: 'warning',
        dangerMode: true
      })
    }

    if (shouldCancel) this.toggleEdit()
  }

  render() {
    return (
      <RulesDSL
        {...this.props}
        {...this.state}
        updateText={this.updateText}
        toggleEdit={this.toggleEdit}
        cancelEdit={this.cancelEdit}
        submit={this.submit}
        changeEngine={this.changeEngine}
      />
    )
  }
}
