import { FileContainer } from './FileContainer'
import {
  ActionOptionType,
  CustomFile,
  FieldOptionType,
  FileImportResponse,
  Option
} from 'bulk/types'
import { AxiosError, CancelToken, CancelTokenSource } from 'axios'

interface Props {
  file: CustomFile
  successCb: (res: FileImportResponse) => void
  setIsUploadFinished: (arg: boolean) => void
  setFileSource: (arg: CancelToken | CancelTokenSource) => void
  selectedField: FieldOptionType
  selectedAction: ActionOptionType
  templateType: Option
  fileId: number
  setFileId: (arg: number) => void
  fileUploadError: boolean
  setFileUploadError: (arg: boolean) => void
  setErrorData: (err: AxiosError) => void
}

const UploadProgress = ({
  file,
  successCb,
  setIsUploadFinished,
  setFileSource,
  selectedField,
  selectedAction,
  templateType,
  fileId,
  setFileId,
  fileUploadError,
  setFileUploadError,
  setErrorData
}: Props) => {
  if (!file) {
    return <p>No files are uploading</p>
  }
  return (
    <FileContainer
      file={file}
      key={file.id}
      successCb={successCb}
      setIsUploadFinished={setIsUploadFinished}
      setFileSource={setFileSource}
      selectedField={selectedField}
      selectedAction={selectedAction}
      templateType={templateType}
      fileId={fileId}
      setFileId={setFileId}
      fileUploadError={fileUploadError}
      setFileUploadError={setFileUploadError}
      setErrorData={setErrorData}
    />
  )
}

export default UploadProgress
