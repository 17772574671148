import s from './PermissionsNotification.scss'
import { FaInfoCircle } from 'react-icons/fa'

const PermissionsNotification = () => (
  <i className={s.permissionWarning}>
    <FaInfoCircle />
    Responses that you do not have access to have been hidden
  </i>
)

export default PermissionsNotification
