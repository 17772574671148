import { all, put, takeLatest } from 'redux-saga/effects'
import APP_ACT from 'app/actions'
import ACT from './actions'

import { makeGetRequest, makePostRequest } from 'utils/api'
import { serializeParams } from './list/serializers'
import { toRejectFormFields } from 'reviews/review_ladder/serializers'

function* pendingInvoicesFetch(action) {
  const { userId } = action.payload

  yield put({ type: ACT.PENDING_INVOICES_LOADING })

  try {
    const { invoices } = yield makeGetRequest(`/invoices/pending_for_user/${userId}`)

    yield put({
      type: ACT.PENDING_INVOICES_FETCH_SUCCESS,
      payload: invoices
    })
  } catch (error) {
    console.error(error)

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue fetching pending invoices.',
        level: 'error'
      }
    })
  }
}

function* invoiceListFetch(action) {
  const { params } = action.payload

  const serialized = serializeParams(params)

  try {
    const response = yield makePostRequest(`/invoices/v2/list/${params.category}/`, serialized)

    yield put({
      type: ACT.INVOICE_LIST_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { invoices: response, params }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue fetching invoice list.',
        level: 'error'
      }
    })
  }
}

function* resendNotification(action) {
  const { invoiceId } = action.payload

  try {
    yield makePostRequest(`/invoices/${invoiceId}/resendnotification/`)
    yield put({
      type: ACT.RESEND_APPROVAL_EMAILS_SUCCESS,
      loadingLock: 'off'
    })
  } catch (error) {
    console.error(error)

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue resending notifications.',
        level: 'error'
      }
    })
  }
}

function* invoiceSummaryFetch() {
  try {
    const { status_summary } = yield makeGetRequest('/invoices/v2/summary/')

    yield put({
      type: ACT.INVOICE_SUMMARY_FETCH_SUCCESS,
      payload: { summary: status_summary }
    })
  } catch (error) {
    console.error(error)

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue fetching invoice summary.',
        level: 'error'
      }
    })
  }
}

function* invoiceApprove(action) {
  const { id } = action.payload
  try {
    yield makePostRequest(`/invoices/${id}/approve`)

    yield put({
      type: ACT.INVOICE_APPROVE_SUCCESS,
      loadingLock: 'off',
      payload: {
        id
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.API_ERROR,
      loadingLock: 'off',
      error
    })
  }
  yield all([
    put({
      type: ACT.INVOICE_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: action.payload
    }),
    put({
      type: ACT.INVOICE_SUMMARY_FETCH_REQUESTED,
      payload: action.payload
    })
  ])
}

function* invoiceReject(action) {
  const { id, formFields } = action.payload
  const serialized = formFields ? toRejectFormFields(formFields) : {}
  try {
    yield makePostRequest(`/invoices/${id}/reject`, serialized)

    yield put({
      type: ACT.INVOICE_REJECT_SUCCESS,
      loadingLock: 'off',
      payload: { id }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue rejecting the invoice.',
        level: 'error'
      }
    })
  }

  yield all([
    put({
      type: ACT.INVOICE_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: action.payload
    }),
    put({
      type: ACT.INVOICE_SUMMARY_FETCH_REQUESTED,
      payload: action.payload
    })
  ])
}

function* markPaid(action) {
  const { id } = action.payload
  try {
    yield makePostRequest(`/invoices/${id}/mark_paid`)

    yield put({
      type: ACT.INVOICE_MARK_PAID_SUCCESS,
      loadingLock: 'off',
      payload: {
        id
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      loadingLock: 'off',
      payload: {
        title: 'Error',
        message: 'There was an issue marking the invoice as paid.',
        level: 'error'
      }
    })
  }
}

const invoicesSagas = [
  takeLatest(ACT.PENDING_INVOICES_FETCH_REQUESTED, pendingInvoicesFetch),
  takeLatest(ACT.INVOICE_LIST_FETCH_REQUESTED, invoiceListFetch),
  takeLatest(ACT.INVOICE_SUMMARY_FETCH_REQUESTED, invoiceSummaryFetch),
  takeLatest(ACT.INVOICE_APPROVE_REQUESTED, invoiceApprove),
  takeLatest(ACT.INVOICE_REJECT_REQUESTED, invoiceReject),
  takeLatest(ACT.INVOICE_MARK_PAID_REQUESTED, markPaid),
  takeLatest(ACT.RESEND_APPROVAL_EMAILS_REQUESTED, resendNotification)
]

export default invoicesSagas
