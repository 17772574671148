import { useState, useEffect } from 'react'
import { Panel, DataTableWrapper, SwitchToggle, useLoading, Tooltip } from 'simple-core-ui'
import { getNormalizedCellContent } from './utils'
import s from './Notifications.scss'
import { useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { toNotifications } from './serializers'
import { Notification, Cell } from './types'
import { makePostRequest, makeGetRequest } from 'utils/api'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const initialParams = {
  pageSize: 25,
  ordering: { columnKey: 'label', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const Notifications = () => {
  const [params, setParams] = useState(initialParams)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [notifications, setNotifications] = useState<Notification[]>([])

  const toggleStatus = async (name: string, index: number) => {
    try {
      const url = '/manage/matters/admin-settings'

      await withLoadingLocks(
        makePostRequest(url, {
          value: !notifications[index].value,
          name
        })
      )
      setNotifications(
        notifications.map((notification, i) =>
          i === index ? { ...notification, value: !notification.value } : notification
        )
      )

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Notification ${notifications[index].label} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchNotifications = async () => {
    try {
      const notifications = await withLoadingLocks(makeGetRequest('/manage/matters/admin-settings'))
      setNotifications(toNotifications(notifications))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onToggleNotification = (id: number) => {
    const index = notifications.findIndex(p => p.id === id)
    const notification = notifications[index]

    toggleStatus(notification.name, index)
  }

  const columns = [
    {
      columnKey: 'label',
      content: 'Notifications',
      isSortable: true,
      isFilterable: true,
      style: { width: '200px' },
      render: (cell: Cell) => {
        return <b>{cell.content}</b>
      }
    },
    {
      columnKey: 'description',
      content: '',
      isSortable: false,
      isFilterable: true
    },
    {
      columnKey: 'value',
      content: (
        <>
          Active
          <Tooltip
            trigger={<AiOutlineInfoCircle className={s.infoTooltip} />}
            content="Active settings can be applied to an event"
            placement="top"
          />
        </>
      ),
      render: (cell: Cell, row: Notification) => {
        const isActive = cell.content !== '----'
        return (
          <SwitchToggle
            id={`notification-${row.id}`}
            dataTestId={String(row.id)}
            checked={isActive}
            onToggle={() => onToggleNotification(row.id)}
          />
        )
      }
    }
  ]

  useEffect(() => {
    fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = (params: Params) => {
    setParams(params)
  }

  return (
    <Panel
      title="Manage Notifications"
      subtitle="Enable and disable notifications."
      styles={{ boxShadow: 'none' }}
      className={s.preferences}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        rows={notifications}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        className={s.itemsTable}
        hasTooltip
        categoryKey="preference"
        getNormalizedCellContent={getNormalizedCellContent}
        hasActionsHeader={false}
        hasPagination={false}
      />
    </Panel>
  )
}

export default Notifications
