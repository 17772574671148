import { sortAlphabeticallyByProperty } from 'utils/helpers'

export const getAllSavedReports = (savedReports, savedSupersetReports) => {
  const supersetMappedReports = Object.keys(savedSupersetReports).reduce(
    (acc, key) => ({ ...acc, [key]: savedSupersetReports[key].reports }),
    {}
  )
  return sortAlphabeticallyByProperty(
    Object.keys({ ...savedReports, ...supersetMappedReports }).map(key => ({
      key,
      loverKey: key.toLowerCase()
    })),
    'loverKey'
  ).reduce((acc, { key }) => {
    const otherReports = savedReports[key] ? savedReports[key] : []
    const supersetReports = supersetMappedReports[key] ? supersetMappedReports[key] : []
    return {
      ...acc,
      [key]: sortAlphabeticallyByProperty([...otherReports, ...supersetReports], 'text')
    }
  }, {})
}
