import { Checkbox, KeyValuePairs, Input } from 'simple-core-ui'
import Select, { StylesConfig } from 'react-select'
import s from './AddUser.scss'
import { TeamSelect } from 'teams'
import cn from 'classnames'
import { TeamSettings, InitialForm, UserRole, LoginPref, FormValues, Option } from './types'

interface Props {
  error: Record<string, string> | null
  formData: InitialForm
  teamSettings: TeamSettings | null
  onChangeHandler: (arg1: string, arg2: string | FormValues) => void
  userRoles: UserRole[]
  loginPreference: LoginPref[]
}

const NOTIFICATION_MAPPING = [
  { label: 'On Invoice Arrival', value: 'arrival' },
  { label: 'On Invoice Approval', value: 'approval' },
  { label: 'On Invoice Payment', value: 'paid' }
]

const AddUserForm = ({
  error,
  formData,
  teamSettings,
  onChangeHandler,
  userRoles,
  loginPreference
}: Props) => {
  const Notifications = () => {
    return (
      <>
        {NOTIFICATION_MAPPING.map(option => (
          <div className={s.notificationContainer} key={option.value}>
            <Checkbox
              isChecked={formData.notifyOn.includes(option.value)}
              triggerCheckbox={() => onChangeHandler('notifyOn', option.value)}
              size="sm"
              hasGreyBorder
            />
            <label className={s.notificationLabel}>{option.label}</label>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <p className={s.subtitle}>
        Adding a user will allow someone else access to your SimpleLegal application.
      </p>
      <KeyValuePairs
        pairs={[
          {
            key: 'First Name',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: (
              <Input
                text={formData.firstName}
                type="text"
                onChangeCb={eve => onChangeHandler('firstName', eve.target.value)}
              />
            )
          },
          {
            key: 'Last Name',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: (
              <Input
                text={formData.lastName}
                type="text"
                onChangeCb={eve => onChangeHandler('lastName', eve.target.value)}
              />
            )
          },
          {
            key: 'Email',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: (
              <>
                <Input
                  text={formData.email}
                  type="text"
                  onChangeCb={eve => onChangeHandler('email', eve.target.value)}
                  cssClass={cn({
                    error: error?.email
                  })}
                />
                {error?.email && <p className={s.fieldError}>{error.email}</p>}
              </>
            )
          },
          {
            key: 'Role',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: (
              <Select
                value={formData.role}
                options={userRoles}
                onChange={option => onChangeHandler('role', option as UserRole)}
              />
            )
          },
          ...(teamSettings?.canEditTeams && teamSettings?.teamLimit
            ? [
                {
                  key: 'User Team(s)',
                  sectionClass: s.sectionClass,
                  keyClass: s.label,
                  value: (
                    <>
                      {/* @ts-expect-error */}
                      <TeamSelect
                        id="teams"
                        value={formData.teams}
                        url="/teams/manage/"
                        multi
                        placeholder="Select teams..."
                        resultsProperty="teams"
                        onChange={(option: Option) => onChangeHandler('teams', option)}
                        styles={
                          {
                            valueContainer: base => ({
                              ...base,
                              maxHeight: 120,
                              overflow: 'auto'
                            }),
                            control: provided => ({
                              ...provided,
                              ...(error?.team ? { borderColor: '#D6506B' } : {})
                            })
                          } as StylesConfig
                        }
                      />
                      {error?.team && <p className={s.fieldError}>{error.team}</p>}
                    </>
                  )
                }
              ]
            : []),
          {
            key: 'Login Preference',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: (
              <Select
                value={formData.loginPreference}
                options={loginPreference}
                onChange={option => onChangeHandler('loginPreference', option as LoginPref)}
              />
            )
          },
          {
            key: 'Invoice Notifications',
            sectionClass: s.sectionClass,
            keyClass: s.label,
            value: <Notifications />
          }
        ]}
        style={{ padding: '20px 40px' }}
      />
    </>
  )
}

export default AddUserForm
