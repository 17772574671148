import { FaTwitter } from 'react-icons/fa'
import cn from 'classnames'
import APP_PAGES_MAP from 'data/app_pages_map'
import s from '../styles/Footer.scss'

const Footer = ({ isMenuExpanded }) => (
  <footer
    className={cn(s.container, {
      [s.containerOpen]: isMenuExpanded,
      [s.fullFooter]: !window.credentials.isAuthenticated
    })}
  >
    <nav>
      <ul className={s.navList}>
        <li className={s.navItem}>
          <span>{`© ${new Date().getFullYear()} `}</span>
          <a
            href={'https://www.simplelegal.com/'}
            title={'www.SimpleLegal.com'}
            className={s.navLink}
          >
            {'SimpleLegal'}
          </a>
        </li>
        <li className={s.navItem}>
          <a
            target={'_blank'}
            rel="noreferrer"
            href={'https://twitter.com/simple_legal'}
            title={'SimpleLegal Twitter Page'}
            className={s.navLink}
          >
            <FaTwitter />
          </a>
        </li>
        {APP_PAGES_MAP.footerLinks.map(link => (
          <li key={link.text} className={s.navItem}>
            <a href={link.url} title={link.title} className={s.navLink}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  </footer>
)

export default Footer
