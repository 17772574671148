import * as CONST from 'matters/requestForms/constants'
import { TemplatesContainer } from './Templates'
import { Content } from './Content'
import { EntityActionsFooter } from 'components'
import s from './RequestFormContent.scss'

const RequestFormContent = ({
  form,
  displayRemove,
  addNewItem,
  saveAndPublishForm,
  saveForm,
  cancelChanges,
  navigateToSelectTemplates,
  displayTemplatesSection,
  disabled
}) => (
  <div className={s.panelWrapper}>
    {displayTemplatesSection && (
      <TemplatesContainer
        navigateToSelectTemplates={navigateToSelectTemplates}
        disabled={disabled}
      />
    )}
    <Content
      form={form}
      displayRemove={displayRemove}
      addNewItem={addNewItem}
      disabled={disabled}
    />
    <EntityActionsFooter
      entity={form}
      saveAndPublish={saveAndPublishForm}
      archive={saveAndPublishForm}
      save={saveForm}
      cancelChanges={cancelChanges}
      status={CONST.FORM_STATUSES.PUBLISHED}
      canPublish
    />
  </div>
)

export default RequestFormContent
