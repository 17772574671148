import { useEffect } from 'react'
import { ModalContainer, CharLimitInput } from 'simple-core-ui'
import ReactTooltip from 'react-tooltip'
import s from './TaskTypeModal.scss'
import { TaskType } from '../types'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Select from 'react-select'

interface Props {
  toggleTaskTypeModal: () => void
  showDuplicateError: boolean
  taskType: TaskType
  updateTaskType: (value: string | number | null, property: string) => void
  saveTaskType: () => void
}

const MAX_NAME_LENGTH = 50

const scores = Array.from({ length: 10 }, (_, index) => ({ value: index + 1, label: index + 1 }))

const TaskTypeModal = ({
  toggleTaskTypeModal,
  showDuplicateError,
  taskType,
  updateTaskType,
  saveTaskType
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const errorObject = (taskType: TaskType) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (taskType.name && taskType.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }
    if (showDuplicateError) {
      hasError = true
      errorMessage = 'Name must be unique.'
    }

    return { hasError, errorMessage }
  }

  return (
    <ModalContainer
      title={taskType.id ? 'Edit Task Type' : 'Add Task Type'}
      subtitle="Use types to categorize your tasks."
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleTaskTypeModal}
      confirmText="Save"
      confirmCb={saveTaskType}
      isDisabled={
        showDuplicateError ||
        !taskType.name.trim() ||
        taskType.name[0] === ' ' ||
        taskType.name.length > MAX_NAME_LENGTH
      }
      content={
        <div className={s.modalBody}>
          <div className={s.statusItem}>
            <div className={s.info}>Task Type*</div>
            <CharLimitInput
              maxLength={MAX_NAME_LENGTH}
              value={taskType.name}
              onChangeCb={e => updateTaskType(e.target.value, 'name')}
              placeholder="Enter a task type"
              dynamicCharCalculation
              customErrorMessage={errorObject(taskType).errorMessage}
              hasError={errorObject(taskType).hasError}
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>
              Strategic Value
              <AiOutlineInfoCircle
                className={s.infoTooltip}
                data-for="strategicValueTooltip"
                data-tip
              />
              <ReactTooltip
                id="strategicValueTooltip"
                type="light"
                effect="solid"
                place="top"
                border
              >
                Strategic Value score will inform task type reporting.
              </ReactTooltip>
            </div>
            <p className={s.subtitle}>Where 1 is lowest and 10 is the highest strategic value</p>
            <Select
              className={s.select}
              placeholder="Select a strategic value"
              options={scores}
              value={
                taskType.strategicValue
                  ? { value: taskType.strategicValue, label: taskType.strategicValue }
                  : null
              }
              onChange={e => updateTaskType(e?.value ?? null, 'strategicValue')}
              isClearable
            />
          </div>
          <div className={s.statusItem}>
            <div className={s.info}>
              Complexity
              <AiOutlineInfoCircle
                className={s.infoTooltip}
                data-for="complexityTooltip"
                data-tip
              />
              <ReactTooltip id="complexityTooltip" type="light" effect="solid" place="top" border>
                Complexity score will inform task type reporting.
              </ReactTooltip>
            </div>
            <p className={s.subtitle}>Where 1 is lowest and 10 is the highest complexity</p>
            <Select
              className={s.select}
              placeholder="Select a complexity value"
              options={scores}
              value={
                taskType.complexity
                  ? { value: taskType.complexity, label: taskType.complexity }
                  : null
              }
              onChange={e => updateTaskType(e?.value ?? null, 'complexity')}
              isClearable
            />
          </div>
        </div>
      }
    />
  )
}

export default TaskTypeModal
