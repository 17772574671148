import { getFileNameExtension } from 'files/utils'
import { parseISO, format } from 'date-fns'
import { ResultsItem, SerializedResultItem } from './types'

export const resultsSerializer = (results: ResultsItem[]): SerializedResultItem[] => {
  return results.map(row => {
    const {
      file_name,
      file_size,
      file_upload_date,
      fileStatus,
      id,
      user_full_name,
      cg_user_name,
      shared_with_users,
      file_url
    } = row
    const { extension, fileName } = getFileNameExtension(file_name)

    return {
      id,
      fileStatus,
      fileName,
      fileType: extension,
      fileSize: file_size,
      userFullName: cg_user_name ? cg_user_name : user_full_name,
      updated: file_upload_date ? format(parseISO(file_upload_date), 'MM/dd/yyyy') : null,
      share: shared_with_users,
      fileUrl: file_url
    }
  })
}
