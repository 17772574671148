export const BUDGET_STATES = {
  DRAFT: 'Draft',
  PROPOSED: 'Proposed',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  OVERDUE: 'Overdue',
  EXPIRED: 'Expired',
  ARCHIVED: 'Archived',
  WAITING: 'Waiting'
}

export const BUDGET_TYPES = {
  PHASE: 'Phase',
  LIFE_OF_MATTER: 'Life of Matter',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly'
}

export const WARNING_THRESHOLD = 80
export const OVER_BUDGET_THRESHOLD = 100

export const defaultBudgetTypes = [
  {
    modified_date: null,
    description: null,
    sort_order: 0,
    created_date: '2016-09-30T13:41:22.535000',
    id: 2,
    name: 'Monthly'
  },
  {
    modified_date: '2017-09-05T10:03:14.325479',
    description: null,
    sort_order: 1,
    created_date: '2017-09-05T10:03:14.325479',
    id: 4,
    name: 'Quarterly'
  },
  {
    modified_date: null,
    description: null,
    sort_order: 2,
    created_date: '2016-09-30T13:41:22.533000',
    id: 1,
    name: 'Phase'
  },
  {
    modified_date: null,
    description: null,
    sort_order: 3,
    created_date: '2016-09-30T13:41:22.732000',
    id: 3,
    name: 'Life of Matter'
  }
]
export const defaultPhaseSets = [
  { value: 'counseling', label: 'Counseling' },
  { value: 'litigation', label: 'Litigation Phase' },
  { value: 'litigation_task', label: 'Litigation Task' },
  { value: 'm_and_a', label: 'Mergers and Acquisitions' },
  { value: 'patents', label: 'Patents' },
  { value: 'trademarks', label: 'Trademarks' }
]
