import s from './TaskDetails.scss'
import { Task } from '../types'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { makePatchRequest } from 'utils/api'
import { fromTask } from 'common/Tasks/serializers'
import { Task as ParentTask } from 'common/Tasks/types'
import { toTasks } from '../serializers'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { AiFillCheckCircle } from 'react-icons/ai'

const COMPLETED_STATUS_ID = 3

interface Props {
  task: Task | null
  setTasks: Dispatch<SetStateAction<Task[]>>
  changeTab?: (tab: string) => void
}

const TaskDetails = ({ task, setTasks, changeTab }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleEditClick = (task: Task) => {
    const queryParams = queryString.parse(location.search)
    const newSearch = queryString.stringify({ ...queryParams, id: task.id, tab: 'tasks' })

    if (changeTab) {
      navigate({ search: newSearch })
      changeTab('list')
    } else {
      window.location.href = `/v2/matters/${task?.relatedMatter?.value}/?${newSearch}`
    }
  }

  const handleRelatedClick = () => {
    if (task?.relatedMatter) {
      window.location.href = `/v2/matters/${task.relatedMatter.value}`
    }
  }

  const markCompleted = async () => {
    if (!task?.relatedMatter?.value || !task?.id) return

    try {
      const response = await makePatchRequest(
        `/task-management/matters/${task?.relatedMatter?.value}/tasks/${task?.id}/`,
        fromTask(
          ({ status: { id: COMPLETED_STATUS_ID } } as unknown) as ParentTask,
          String(task?.relatedMatter?.value),
          true
        )
      )

      setTasks(prevTasks => {
        const editedTask = toTasks([response])[0]
        const updatedTasks = prevTasks.map(task => (task.id === editedTask.id ? editedTask : task))
        return updatedTasks
      })

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: 'Task marked as completed',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  return (
    <div style={{ borderTop: '4px solid #10B981', width: 350 }}>
      <div style={{ padding: '24px 24px 12px' }}>
        <h2 style={{ fontSize: 16, fontWeight: 700, color: '#0957AE', wordWrap: 'break-word' }}>
          <AiFillCheckCircle style={{ fontSize: 18 }} className={s.taskEventIcon} />
          <a
            style={{
              cursor: 'pointer'
            }}
            onClick={() => (task ? handleEditClick(task) : null)}
          >
            {task?.title}
          </a>
        </h2>
        <h3 className={s.subtitle}>
          <span style={{ color: '#767676', fontSize: 14, fontWeight: 400 }}>
            {task && task.start
              ? format(parse(task.start as string, 'MM/dd/yyyy', new Date()), 'EEEE, MMMM d')
              : ''}
          </span>
        </h3>
      </div>
      <div className={s.content}>
        <p className={s.row}>
          <span className={s.label}>Priority:</span>
          <span>
            {task?.priority ? (
              <>
                <span className={s.circle} style={{ background: task.priority.color }}></span>
                <span className={s.value}>{task.priority.name}</span>
              </>
            ) : (
              '-'
            )}
          </span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Related to:</span>
          <span
            onClick={handleRelatedClick}
            className={s.value}
            style={{ color: '#0957AE', cursor: 'pointer' }}
          >
            {task?.relatedMatter?.label ?? '-'}
          </span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Status:</span>
          <span>
            {task?.status ? (
              <>
                <span className={s.circle} style={{ background: task.status.color }}></span>
                <span className={s.value}>{task.status.name}</span>
              </>
            ) : (
              '-'
            )}
          </span>
        </p>
        <p className={s.row}>
          <span className={s.label}>Type:</span>
          <span className={s.value}>{task?.taskType?.name ?? '-'}</span>
        </p>
        <div className={s.footer}>
          <div onClick={() => (task ? handleEditClick(task) : null)} className={s.button}>
            Edit
          </div>
          {task?.status?.phase !== 'Complete' && (
            <div
              style={{ borderLeft: '1px solid #ccc' }}
              className={s.button}
              onClick={markCompleted}
            >
              Mark completed
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskDetails
