import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Panel, PanelSelect, If } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'
import { Header } from './Header'
import { Notification } from './Notification'
import SharedEvaluators from 'matters/detail/vendors/Surveys/SurveyConfigTab/SharedEvaluators/SharedEvaluators'
import s from './BaseEvaluatorsContainer.scss'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import {
  SURVEY_CONFIG_SHARED_DEFAULT_ROLES,
  SURVEY_CONFIG_SHARED_CHANGE_STATUS,
  SURVEY_CONFIG_USERS_FETCH_REQUESTED,
  CONTACT_ADDRESS_BOOK_FETCH_REQUESTED,
  SURVEY_CONFIG_ROLES_FETCH_REQUESTED,
  SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE,
  SURVEY_CONFIG_HAS_SURVEYS_ENABLED_REQUESTED
} from 'matters/detail/vendors/Surveys/SurveyConfigTab/actions'

const options = [
  { label: 'Active', value: 'enabled' },
  { label: 'Inactive', value: 'disabled' },
  { label: 'Hidden', value: 'hidden' }
]

@withRouter
@connect(({ surveyConfig }) => {
  const { roles, users, sharedEvaluators, hasSurveysEnabled } = surveyConfig
  return {
    roles,
    users,
    sharedEvaluators,
    hasSurveysEnabled
  }
})
class BaseEvaluatorsContainer extends Component {
  state = {
    shouldNotify: true
  }
  componentDidMount() {
    this.props.dispatch({
      type: SURVEY_CONFIG_HAS_SURVEYS_ENABLED_REQUESTED
    })

    if (!this.props.readOnly) {
      this.props.dispatch({
        type: CONTACT_ADDRESS_BOOK_FETCH_REQUESTED
      })

      this.props.dispatch({
        type: SURVEY_CONFIG_ROLES_FETCH_REQUESTED,
        payload: {
          entityId: 0
        }
      })
    }

    if (!this.props.router.params.id && !this.props.readOnly) {
      this.props.dispatch({
        type: SURVEY_CONFIG_SHARED_DEFAULT_ROLES,
        payload: {
          hasChanged: false
        }
      })
    }
  }
  changeStatus = status => {
    if (status.value === 'enabled') {
      this.setState({
        shouldNotify: false
      })
    }
    this.props.dispatch({
      type: SURVEY_CONFIG_SHARED_CHANGE_STATUS,
      payload: {
        status: status.value
      }
    })
  }
  changeAttributeValue = async ({ value, property, index }) => {
    await this.props.dispatch({
      type: SURVEY_CONFIG_SHARED_CHANGE_ATTRIBUTE_VALUE,
      payload: {
        value,
        property,
        index
      }
    })
  }
  getUsersCb = debounce(search => {
    this.props.dispatch({
      type: SURVEY_CONFIG_USERS_FETCH_REQUESTED,
      payload: {
        search
      }
    })
  }, 500)
  resetDefault = () => {
    this.props.dispatch({
      type: SURVEY_CONFIG_SHARED_DEFAULT_ROLES,
      payload: {
        hasChanged: true
      }
    })
  }
  onKeepInactive = () => {
    this.setState({
      shouldNotify: false
    })
  }
  onMakeActive = () => {
    this.changeStatus({ value: 'enabled' })
  }
  render() {
    const { status } = this.props.sharedEvaluators

    if (!this.props.hasSurveysEnabled) return null

    return (
      <div
        className={cn(s.panelWrapper, {
          [s.active]: status === 'enabled',
          [s.inactive]: status === 'disabled',
          [s.readOnly]: this.props.readOnly,
          [s.hidden]: status === 'hidden'
        })}
      >
        <Panel
          title="Surveys"
          rightActions={[
            <PanelSelect
              key="0"
              className={s.status}
              value={options.find(o => o.value === status)}
              onChange={value => {
                this.changeStatus(value)
              }}
              options={options}
              disabled={this.props.readOnly}
            />
          ]}
        >
          <If
            condition={
              this.state.shouldNotify &&
              this.props.sharedEvaluators.hasChanged &&
              status === 'disabled'
            }
            fallback={
              <Fragment>
                <Header
                  {...this.state}
                  resetDefault={this.resetDefault}
                  readOnly={this.props.readOnly}
                />
                <SharedEvaluators
                  {...this.props}
                  changeAttributeValue={this.changeAttributeValue}
                  loadCb={this.getUsersCb}
                  withHeader={false}
                  grayBackground={false}
                />
              </Fragment>
            }
          >
            <Notification onMakeActive={this.onMakeActive} onKeepInactive={this.onKeepInactive} />
          </If>
        </Panel>
      </div>
    )
  }
}

export default BaseEvaluatorsContainer
