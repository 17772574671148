import { Component } from 'react'
import * as CONST from 'matters/templates/constants'
import s from '../PreviewPanelContent.scss'
import { PreviewPanelAttribute } from './PreviewPanelAttribute'

class PreviewPanelAttributesContent extends Component {
  render() {
    const { section, index, getAttributeValue } = this.props
    return (
      <div key={`${section.sectionName}_attributes_${index}`} className={s.attributesWrapper}>
        {section.attributes.map(attribute => {
          const systemGeneratedCss =
            attribute.type === CONST.SYSTEM_GENERATED
              ? s.systemGenerated
              : attribute.overwritten
              ? s.changedValue
              : ''
          return (
            <PreviewPanelAttribute
              key={attribute.id}
              attribute={attribute}
              systemGeneratedCss={systemGeneratedCss}
              getAttributeValue={getAttributeValue}
            />
          )
        })}
      </div>
    )
  }
}

export default PreviewPanelAttributesContent
