import React from 'react'
import { connect } from 'react-redux'
import AdditionalEvaluators from './AdditionalEvaluators'
import {
  SURVEY_CONFIG_TOGGLE_SURVEY_ON,
  SURVEY_CONFIG_ADDITIONAL_EVALUATORS_UPDATE_REQUESTED,
  SURVEY_CONFIG_USERS_FETCH_REQUESTED
} from '../actions'
import debounce from 'lodash/debounce'

@connect(({ surveyConfig }) => {
  const { users, roles, additionalEvaluators } = surveyConfig
  return {
    roles,
    users,
    additionalEvaluators
  }
})
class AdditionalEvaluatorsContainer extends React.Component {
  toggleEnableSurvey = async (event, index) => {
    await this.props.dispatch({
      type: SURVEY_CONFIG_TOGGLE_SURVEY_ON,
      payload: {
        index,
        status: event.target.checked ? 'enabled' : 'disabled'
      }
    })

    this.updateEvaluators(index)
  }
  updateEvaluators = index => {
    const { additionalEvaluators, dispatch } = this.props
    const referral = additionalEvaluators[index]

    if (!referral.hasChanged) return

    dispatch({
      type: SURVEY_CONFIG_ADDITIONAL_EVALUATORS_UPDATE_REQUESTED,
      payload: {
        entityId: referral.id,
        users: referral.users.map(user => user.value),
        roles: referral.roles.map(role => role.value),
        status: referral.status,
        index
      }
    })

    this.props.hasChangedSection()
  }
  getUsersCb = debounce(search => {
    this.props.dispatch({
      type: SURVEY_CONFIG_USERS_FETCH_REQUESTED,
      payload: {
        search
      }
    })
  }, 500)
  render() {
    return (
      <AdditionalEvaluators
        {...this.props}
        changeAttributeValue={this.props.changeAttributeValue}
        loadCb={this.getUsersCb}
        toggleEnableSurvey={this.toggleEnableSurvey}
        updateEvaluators={this.updateEvaluators}
      />
    )
  }
}

export default AdditionalEvaluatorsContainer
