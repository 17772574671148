import { IoIosMore } from 'react-icons/io'
import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'
import { MatterUpdate } from '../types'

interface Props {
  comment: MatterUpdate
  editComment: (comment: MatterUpdate) => void
  deleteComment: (comment: MatterUpdate) => void
  hoverActionClassName: string
}

const ActionsPopover = ({ comment, editComment, deleteComment, hoverActionClassName }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editComment(comment)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteComment(comment)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${comment.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          icon={<IoIosMore />}
          size="medium"
          className={hoverActionClassName}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
      contentClassName={s.actionsList}
    />
  )
}

export default ActionsPopover
