import { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Panel, Button } from 'simple-core-ui'
import update from 'immutability-helper'
import { Categories } from './Categories'
import { CategoryModal } from './CategoryModal'
import ACT from 'doc_management/settings/actions'
import { checkCategoryInCategories } from 'doc_management/settings/utils'
import { FILTER_TYPE } from 'doc_management/settings/constants'
import swal from 'sweetalert'
import s from './CategoriesContainer.scss'

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 25,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: FILTER_TYPE.ALL.toLowerCase()
  }
}

const reducer = (state, action) => {
  const { value, category, categories, params } = action.payload
  switch (action.type) {
    case ACT.CHANGE_CATEGORY_NAME:
      return update(state, {
        category: {
          name: {
            $set: value
          }
        }
      })
    case ACT.RESET_CATEGORY:
      return update(state, {
        category: {
          $set: { name: '' }
        }
      })
    case ACT.SELECT_CATEGORY:
      return update(state, {
        category: {
          $set: categories.find(c => c.id === category.id)
        }
      })
    case ACT.UPDATE_TABLE_PARAMS:
      return { ...state, params }
    default:
      throw new Error('Action not found')
  }
}

const selectCategories = createSelector([state => state.categories], categories => {
  return categories
})

const columns = [
  { columnKey: 'name', content: 'Name', isSortable: true, isFilterable: true },
  {
    columnKey: 'related_documents',
    content: 'Related documents',
    isSortable: true
  }
]

const CategoriesContainer = () => {
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [localState, dispatchState] = useReducer(reducer, initialState)
  const { category, params } = localState

  const categories = useSelector(state => selectCategories(state.documentManagementSettings))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: ACT.CATEGORIES_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleCategoryModal = (isEdit = false) => {
    setShowCategoryModal(!showCategoryModal)
    setShowDuplicateError(false)
    setIsEdit(isEdit)
    dispatchState({
      type: ACT.RESET_CATEGORY,
      payload: {}
    })
  }

  const updateCategoryName = value => {
    dispatchState({
      type: ACT.CHANGE_CATEGORY_NAME,
      payload: {
        value
      }
    })

    setShowDuplicateError(checkCategoryInCategories(value, categories))
  }

  const addCategory = () => {
    dispatch({
      type: ACT.ADD_CATEGORY_REQUESTED,
      payload: {
        category
      }
    })
    toggleCategoryModal()
  }

  const editCategory = () => {
    dispatch({
      type: ACT.EDIT_CATEGORY_REQUESTED,
      payload: {
        category
      }
    })
    toggleCategoryModal()
  }

  const updateTable = params => {
    dispatchState({
      type: ACT.UPDATE_TABLE_PARAMS,
      payload: {
        params
      }
    })
  }

  const deleteCategory = async categoryRow => {
    const category = categories.find(c => c.id === categoryRow.id)
    if (!category.related_documents) {
      const willDelete = await swal({
        title: 'Delete Category',
        text: 'Are you sure you want to delete this category?',
        buttons: ['Cancel', 'Yes'],
        icon: 'warning'
      })

      if (willDelete) {
        dispatch({
          type: ACT.DELETE_CATEGORY_REQUESTED,
          payload: {
            id: category.id
          }
        })
      }
    } else {
      await swal({
        title: 'You Cannot Delete this Category',
        text: 'It is being used in other related documents.',
        button: 'Ok',
        icon: 'warning'
      })
    }
  }

  const showEditModal = category => {
    toggleCategoryModal(true)
    dispatchState({
      type: ACT.SELECT_CATEGORY,
      payload: {
        category,
        categories
      }
    })
  }
  return (
    <Panel
      title="Manage Document Categories"
      rightActions={[
        <Button onClick={() => toggleCategoryModal()} isPrimary key="categories">
          Add Category
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.categoriesContainer}
    >
      <Categories
        params={params}
        rows={categories}
        columns={columns}
        updateTable={updateTable}
        deleteCategory={deleteCategory}
        showEditModal={showEditModal}
      />
      {showCategoryModal && (
        <CategoryModal
          category={category}
          toggleCategoryModal={toggleCategoryModal}
          updateCategoryName={updateCategoryName}
          addCategory={addCategory}
          editCategory={editCategory}
          showDuplicateError={showDuplicateError}
          isEdit={isEdit}
        />
      )}
    </Panel>
  )
}

export default CategoriesContainer
