import { BUDGET_TYPES } from 'budgets/constants'
import s from './styles/BudgetRequest.scss'

export const columnMap = {
  [BUDGET_TYPES.MONTHLY]: 'Month',
  [BUDGET_TYPES.QUARTERLY]: 'Quarter',
  [BUDGET_TYPES.LIFE_OF_MATTER]: 'Matter',
  [BUDGET_TYPES.PHASE]: 'Phase'
}

export const getNoteRows = rows => {
  const newRows = []
  rows.forEach((row, index) => {
    const noteRow = {
      id: `note-${index}`,
      cells: [
        { columnKey: 'title' },
        { columnKey: 'allocated_total' },
        {
          columnKey: 'approved_total',
          colSpan: 3
        }
      ]
    }
    newRows.push(row)

    if (row.id === 'total') {
      return
    }

    newRows.push(noteRow)
  })
  return newRows
}

export const withTotalRow = details => {
  return details?.rows
    ? details.rows.concat([
        {
          title: 'Total',
          id: 'total',
          allocated_fees: details.allocated_fees,
          allocated_expenses: details.allocated_expenses,
          allocated_total: details.allocated_total,
          approved_fees: details.approved_fees,
          approved_expenses: details.approved_expenses,
          approved_total: details.approved_total,
          pending_fees: details.pending_fees,
          pending_expenses: details.pending_expenses,
          pending_total: details.pending_total,
          accrual_total: details.accrual_total,
          approved_percentage: details.approved_percentage,
          pending_percentage: details.pending_percentage,
          combined_percentage: details.combined_percentage,
          combined_total: details.combined_total,
          writable: true
        }
      ])
    : []
}

export const getAmountsColumns = budgetType => {
  return [
    {
      columnKey: 'title',
      content: budgetType ? columnMap[budgetType] : '',
      isSortable: false,
      style: { width: '18%' }
    },
    {
      columnKey: 'allocated_total',
      content: 'Budgeted',
      isSortable: false,
      style: { textAlign: 'right', width: '18%', paddingRight: '7%' }
    },
    {
      columnKey: 'approved_total',
      content: 'Approved Invoices',
      isSortable: false,
      style: { textAlign: 'right', width: '18%', paddingRight: '6%' }
    },
    {
      columnKey: 'pending_total',
      content: (
        <div>
          <div>Pending Invoices</div>
          <div className={s.accrualCell}>Pending Accruals</div>
        </div>
      ),
      isSortable: false,
      style: { textAlign: 'right', width: '18%', paddingRight: '6%' }
    },
    {
      columnKey: 'combined_percentage',
      content: 'Budget Health',
      isSortable: false
    }
  ]
}
