import s from './InnerTable.scss'
import moment from 'moment'

const InnerTable = ({
  accruals,
  actuals_before,
  actuals_after,
  delta_to_actual,
  startDate,
  idx
}) => {
  const date = moment(startDate).add({ month: idx })
  const month = date.format('MMM')
  const year = date.format('YYYY')

  return (
    <div className={s.tableColumn}>
      <p className={`${s.cardText} ${s.bold}`}>
        {month} {year}
      </p>
      <p className={`${actuals_before === '--' && s.gray} ${s.cardText}`}>{actuals_before}</p>
      <p className={`${accruals === '--' && s.gray} ${s.cardText}`}>{accruals}</p>
      <p className={`${actuals_after === '--' && s.gray} ${s.cardText}`}>{actuals_after}</p>
      <div className={s.deltaLine}></div>
      <p className={`${delta_to_actual === '--' && s.gray} ${s.cardText} ${s.bold}`}>
        {delta_to_actual}
      </p>
    </div>
  )
}

export default InnerTable
