const setScrollModal = function(modalId, stopPropagation) {
  if (!window.$ || !window.jQuery) {
    console.error('Using jquery function when no jQuery is initialized')
    return
  }

  const $ = window.$

  var $parent = $(modalId).parent()
  $(modalId).on('show.bs.modal', function(e) {
    if (modalId.slice(1) !== e.target.id) {
      return
    }
    document.body.style.overflow = 'hidden'
    var self = this
    setTimeout(function() {
      var el = $(self).detach()[0]
      var modalBackdropEl = $('.modal-backdrop')[0]
      if (!modalBackdropEl) return
      modalBackdropEl.appendChild(el)
      if (stopPropagation) {
        $(modalId).click(function(e) {
          e.stopPropagation()
        })
      }
      $('.modal-backdrop').css('overflow-y', 'scroll')
      $('.modal-backdrop').css('background', 'rgba(0, 0, 0, 0.6)')
      $('.modal-backdrop').css('opacity', '1')
      $(el).css('position', 'relative')
      $(el).css('margin-bottom', '30px')
      $(modalId + ' .modal-body').css('max-height', 'none')
    }, 50)
  })

  $(modalId).on('hide.bs.modal', function(e) {
    if (modalId.slice(1) !== e.target.id) {
      return
    }
    document.body.style.overflow = 'auto'
    var self = this
    setTimeout(function() {
      var el = $(self).detach()[0]
      var parentEl = $parent[0]
      if (!parentEl) return
      parentEl.appendChild(el)
      $('.modal-backdrop').remove()
      setScrollModal(modalId, stopPropagation)
    }, 200)
  })
}

const initJqueryUtils = () => {
  window.jqueryUtils = {
    setScrollModal
  }
}

export default initJqueryUtils
