import { ButtonHTMLAttributes } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import classNames from 'classnames'

import s from './DropdownItem.scss'

export interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onSelect'> {
  label: string
  onSelect(): void
  showArrow?: boolean
}

const DropdownItem = ({ label, onSelect, showArrow = true, ...props }: Props) => {
  return (
    <button
      data-testid="dropdown-item-container"
      className={classNames(s.box)}
      onClick={onSelect}
      {...props}
    >
      <span>{label}</span>
      {showArrow && <IoIosArrowForward data-testid="dropdown-item-arrow" size={14} />}
    </button>
  )
}

export default DropdownItem
