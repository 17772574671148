import keyMirror from 'keymirror'

export default keyMirror({
  PENDING_INVOICES_FETCH_REQUESTED: null,
  PENDING_INVOICES_FETCH_SUCCESS: null,
  PENDING_INVOICES_LOADING: null,
  INVOICE_LIST_FETCH_REQUESTED: null,
  INVOICE_LIST_FETCH_SUCCESS: null,
  INVOICE_LIST_RESET: null,
  INVOICE_SUMMARY_FETCH_REQUESTED: null,
  INVOICE_SUMMARY_FETCH_SUCCESS: null,
  INVOICE_APPROVE_REQUESTED: null,
  INVOICE_APPROVE_SUCCESS: null,
  INVOICE_REJECT_REQUESTED: null,
  INVOICE_REJECT_SUCCESS: null,
  INVOICE_MARK_PAID_REQUESTED: null,
  INVOICE_MARK_PAID_SUCCESS: null,
  SELECTED_INVOICES_APPROVED: null,
  SELECTED_INVOICES_REJECTED: null,
  SELECTED_INVOICES_PAID: null,
  RESEND_APPROVAL_EMAILS_REQUESTED: null,
  RESEND_APPROVAL_EMAILS_SUCCESS: null,
  SET_ATTACHMENTS: null
})
