import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getModelAttrField } from 'rules/utils'
import { LHS_TYPES } from 'rules/constants'

import Operator from '../components/Operator'
import ACT from 'rules/actions'

const emptyOperator = () => ({
  options: [],
  selected: null,
  label: null
})

const translateOperatorProp = (operator, lhs, fields, operators) => {
  const field =
    lhs.operand_type === LHS_TYPES.FUNC_CALL
      ? fields.find(({ name, isFunction }) => isFunction && name === lhs.func_name)
      : getModelAttrField(lhs, fields).field

  if (!field.type) return emptyOperator()

  const { label } = operators[field.type].find(op => op.value === operator) || {}

  return {
    options: operators[field.type],
    selected: { label, value: operator },
    label
  }
}

@connect(({ rules }) => {
  return {
    fields: rules[rules.engine].availableFields,
    operators: rules[rules.engine].ops
  }
})
export default class OperatorContainer extends Component {
  static propTypes = {
    lhs: PropTypes.shape({
      field: PropTypes.string,
      display_name: PropTypes.string,
      type: PropTypes.string,
      path: PropTypes.arrayOf(PropTypes.string)
    }),
    operator: PropTypes.string
  }

  constructor(props) {
    super(props)
    const { heading, label, selected, options } = translateOperatorProp(
      props.operator,
      props.lhs,
      props.fields,
      props.operators
    )

    this.state = {
      heading,
      label,
      selected,
      options
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { heading, label, selected, options } = translateOperatorProp(
      nextProps.operator,
      nextProps.lhs,
      nextProps.fields,
      nextProps.operators
    )

    this.setState({
      heading,
      label,
      selected,
      options
    })
  }

  selectionHandlerOperator = selectedOption => {
    const { coords, refs } = this.props

    this.props.dispatch({
      type: ACT.CLEAR_OPERAND_RHS,
      payload: {
        ruleIndex: this.props.ruleIndex,
        coords,
        refs
      }
    })

    this.props.dispatch({
      type: ACT.UPDATE_RULE_OPERAND,
      payload: {
        operandIndex: this.props.operandIndex,
        ruleIndex: this.props.ruleIndex,
        key: 'op',
        coords,
        refs,
        value: selectedOption.value
      }
    })
  }

  render() {
    const { heading, options, label, selected } = this.state

    const view = {
      heading,
      options,
      label,
      selected
    }

    return <Operator operatorView={view} selectionCb={this.selectionHandlerOperator} />
  }
}
