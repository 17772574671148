import { ModalContainer } from 'simple-core-ui'

interface Props {
  toggleModal: () => void
  title: string
  content: React.ReactNode
  confirmCb: () => void
}

const DisablePreferenceModal = ({ toggleModal, title, content, confirmCb }: Props) => {
  return (
    <ModalContainer
      title={title}
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleModal}
      confirmText="Disable"
      confirmCb={() => {
        confirmCb()
        toggleModal()
      }}
      content={content}
    />
  )
}

export default DisablePreferenceModal
