import { Avatar, HoverAction, Markdown } from 'simple-core-ui'
import s from './Message.scss'

import Timestamp from '../Timestamp/Timestamp'
import MessageBubble from '../MessageBubble/MessageBubble'
import { FaTrash, FaPencilAlt } from 'react-icons/fa'

const Message = ({ message, user, onEdit, onDelete, readOnly, withRichText, fullWidth }) => {
  const date = new Date(message.timestamp)

  return (
    <li className={s.container}>
      <section className={s.section}>
        <figure className={s.avatarContainer} title={message.user.full_name}>
          <Avatar initials={message.user.full_name} className={s.avatar} />
        </figure>
        <MessageBubble position="left" onDelete={onDelete} onEdit={onEdit} fullWidth={fullWidth}>
          <p className={s.name}>{message.user.full_name}</p>
          {withRichText ? <Markdown value={message.text} /> : message.text}
        </MessageBubble>
        <div>
          <Timestamp position="right" date={date} />
          {message.user.id == user.id && !readOnly && (
            <div className={s.actions}>
              {onEdit && (
                <HoverAction onClick={onEdit} tip="Edit" icon={<FaPencilAlt />} size="xsmall" />
              )}
              {onDelete && (
                <HoverAction onClick={onDelete} tip="Delete" icon={<FaTrash />} size="xsmall" />
              )}
            </div>
          )}
        </div>
      </section>
    </li>
  )
}

export default Message
