import { useEffect, useState, useRef, memo, ReactNode } from 'react'
import s from './BulkImportContainer.scss'
import {
  Panel,
  Stepper,
  useLoading,
  Button,
  HoverAction,
  ModalContainer,
  FileIcon,
  Alert,
  KeyValuePairs,
  PaginatedSelect
} from 'simple-core-ui'
import { makeGetRequest, makePostRequest, makeRequest } from 'utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { useImmer } from 'use-immer'
import Select, { SingleValue, StylesConfig } from 'react-select'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { FaTrash } from 'react-icons/fa'
import omit from 'lodash/omit'
import { UploadProgress } from './UploadProgress'
import { CSVLink } from 'react-csv'
import APP_ACT from 'app/actions'
import ACT from 'bulk/actions'
import {
  toFragment,
  toReactSelect,
  serializeImportRecordType,
  serializeImportParams
} from 'bulk/serializer'
import ReactTooltip from 'react-tooltip'
import {
  BULK_TAB_NAMES_BY_MAP,
  IMPORT_CSV_RECORD_TO_TEMPLATE_URL_MAPPING,
  HELP_CENTER_URL_BY_MAP,
  ACTIVE_JOB_STATUS_URL,
  ROWS_LIMIT
} from 'bulk/constants'
import { Link } from 'react-router-dom'
import { WarningLine, CheckboxLine } from '../common/Messages'
import { selectStyles } from '../styles/styleSettings'
import variables from 'styles/_variables.scss'
import { makeOptions, sortObjectsList, getLinkLabelFor } from 'bulk/utils'
import { moveToFrontOfArray, sortAlphabeticallyByProperty } from 'utils/helpers'
import {
  REQUIRE_TEMPLATE_RECORD_TYPES,
  CA_LIST_VALUES,
  MATTER_TEMPLATES,
  CMI_REQUIRED_RECORD_TYPES,
  ACTION_TYPE
} from 'bulk/record_types'
import {
  ActionOptionType,
  CustomFile,
  FileImportResponse,
  Option,
  RecordType,
  FieldOptionType
} from 'bulk/types'
import { CancelToken, CancelTokenSource, AxiosError } from 'axios'

interface State {
  bulk: {
    recordTypeObj: RecordType
    activeJob: boolean
  }
}

interface Props {
  activeTab: string
  setActiveTab: (arg: string) => void
}

type S3Files = {
  name: string
}

type ColumnOption = {
  value: string
  label: string
}

interface Step {
  text: string
  completed: boolean
  content: ReactNode
  disabledStepTitle: string
}

type BasicOption = {
  label: string
  value: string
}

interface FlexibleObject {
  [key: string]: BasicOption
}

const { history } = BULK_TAB_NAMES_BY_MAP

const actions = [
  { label: 'Create', value: 'create', disabled: false },
  { label: 'Update', value: 'edit', disabled: false }
]

const BulkImportContainer = ({ activeTab, setActiveTab }: Props) => {
  const [, withLoadingLocks] = useLoading()

  const dispatch = useDispatch()

  const [steps, setSteps] = useImmer<Step[]>([])
  const [isDragging, setIsDragging] = useState(false)
  const [csv, setCsv] = useState<CustomFile | null>(null)
  const [s3Files, setS3Files] = useState<S3Files | null>(null)
  const [isUploadProgressModalVisible, setIsUploadProgressModalVisible] = useState(false)
  const [isUploadFinished, setIsUploadFinished] = useState(false)
  const [fileSource, setFileSource] = useState<CancelToken | CancelTokenSource | null>(null)
  const [errorsCsv, setErrorsCsv] = useState(null)
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const [selectedAction, setSelectedAction] = useState<ActionOptionType | null>(null)
  const [selectedField, setSelectedField] = useState<FieldOptionType | null>(null)
  const [templateType, setTemplateType] = useState<Option | null>(null)
  const [enableDownloadTemplate, setDownloadTemplate] = useState(false)
  const [slColumnOptions, setSlColumnOptions] = useState<ColumnOption[]>([])
  const [mappedFields, setMappedFields] = useState<FlexibleObject | null>(null)
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const [csvColumnOptions, setCsvColumnOptions] = useState<{ label: string; value: string }[]>([])
  const [isProcessingImportModalVisible, setIsProcessingImportModalVisible] = useState(false)

  const [fileId, setFileId] = useState<number | string>('')
  const [fileUploadError, setFileUploadError] = useState<boolean>(false)
  const [retryFileUpload, setRetryFileUpload] = useState(false)

  const [errorData, setErrorData] = useState<AxiosError | null>(null)

  const [showImportWarning, setShowImportWarning] = useState(false)
  const [activeJobProcessingModal, setActiveJobProcessingModal] = useState(false)
  const [ack, setAck] = useState(false)

  const recordTypeObj = useSelector<State, RecordType>(state => state.bulk.recordTypeObj)
  const activeJob = useSelector<State, boolean>(state => state.bulk.activeJob)

  const MINIMUM_MAPPED_FIELDS_REQUIRED = selectedAction?.value === ACTION_TYPE.CREATE ? 1 : 2

  const controlStyles = (field: string): StylesConfig => ({
    ...selectStyles,
    indicatorSeparator: base => ({
      ...base,
      opacity: !!mappedFields && mappedFields[field] ? 1 : 0
    }),
    control: base => ({
      ...base,
      borderColor:
        requiredFields.includes(field) && !!mappedFields && !mappedFields[field]
          ? variables.bulkRed
          : variables.bulkBlack
    }),
    menu: base => ({
      ...base,
      width: 'max-content'
    })
  })

  useEffect(() => {
    toFragment({ activeTab })
  }, [activeTab])

  useEffect(() => {
    const fetchActiveJobStatus = async () => {
      try {
        const { active_job } = await makeGetRequest(ACTIVE_JOB_STATUS_URL)
        dispatch({
          type: ACT.SET_ACTIVE_JOB_STATUS,
          payload: { activeJob: active_job }
        })
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching active job status.',
            level: 'error'
          }
        })
      }
    }

    const fetchRecordType = async () => {
      try {
        const recordType = await makeGetRequest('/bulkv2/record_type')
        dispatch({
          type: ACT.SET_RECORD_TYPE,
          payload: { recordTypeObj: recordType }
        })
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching record type.',
            level: 'error'
          }
        })
      }
    }

    fetchActiveJobStatus()
    fetchRecordType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTemplateDownload = async () => {
    const downloadUrl = '/bulkv2/import_csv_template/'
    try {
      const response = await makeRequest({
        url: downloadUrl,
        method: 'POST',
        responseType: 'blob',
        data: serializeImportParams(
          selectedAction as ActionOptionType,
          selectedField as FieldOptionType,
          templateType as Option
        )
      })
      const fileName = response.headers['content-disposition']
      const data = await response.data
      if (data.type === 'text/csv') {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const clearErrors = () => {
    setErrorsCsv(null)
    setFileUploadError(false)
  }

  const removeFile = () => {
    setCsv(null)
    setFileId('')
    clearErrors()
    setRetryFileUpload(false)
    setIsUploadFinished(false)
    setShowImportWarning(false)
    setAck(false)
    setErrorData(null)
    setMappedFields(null)
    setRequiredFields([])
    setCsvColumnOptions([])
    setSlColumnOptions([])
  }

  const handleRecordTypeChange: (
    item: SingleValue<{ label: string; value: string | number; action: string }>
  ) => void = item => {
    if (item?.value !== selectedField?.value) {
      setSelectedField(item)
      setTemplateType(null)
      removeFile()
      !REQUIRE_TEMPLATE_RECORD_TYPES.includes(item?.value as string)
        ? setDownloadTemplate(true)
        : setDownloadTemplate(false)
    }
  }

  const mappingChangeHandler = (option: BasicOption, key: string) => {
    if (option) {
      setMappedFields(prevForm => {
        return {
          ...(prevForm || {}),
          [key]: option
        }
      })
    } else {
      setMappedFields(prevForm => omit(prevForm, key))
    }
  }

  const checkPreviousStep = () => !enableDownloadTemplate || (!!csv && !isUploadFinished)

  const fetchHelpArticle = () => {
    const article = selectedField ? HELP_CENTER_URL_BY_MAP[selectedField.value] : '/'
    return typeof article === 'object'
      ? article[selectedAction?.value as keyof typeof article]
      : article
  }

  const onDrop = async (files: CustomFile[]) => {
    setCsv(files[0])
    clearErrors()
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false,
    disabled: checkPreviousStep(),
    onDrop,
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    }
  })

  const Dropzone = memo(() => {
    return (
      <div
        data-testid="dropzone"
        aria-label="dropzone"
        {...getRootProps({ className: cn(s.dropzone, { [s.onDrag]: isDragging }) })}
      >
        <input {...getInputProps()} />
        <div className={checkPreviousStep() ? s.disabledDropzoneText : s.dropzoneText}>
          Drag and drop completed CSV or <span>choose file</span> <br />
        </div>
      </div>
    )
  })

  const enableImport = () => {
    return (
      requiredFields.every(key => mappedFields?.hasOwnProperty(key)) &&
      Object.keys(mappedFields || {}).length >= MINIMUM_MAPPED_FIELDS_REQUIRED
    )
  }

  useEffect(() => {
    const stepsArr = [
      {
        text: 'Select Action',
        completed: false,
        content: (
          <div className={s.nameSection}>
            <div className={s.selectContainer}>
              <label className={s.actionLabel}>
                What would you like to do?<span className={s.errorMsg}>*</span>
              </label>
              <Select
                placeholder="Select Action"
                noOptionsMessage={() => 'No results...'}
                value={selectedAction}
                onChange={item => {
                  if (item?.value !== selectedAction?.value) {
                    setSelectedAction(item)
                    setSelectedField(null)
                    setTemplateType(null)
                    setDownloadTemplate(false)
                    removeFile()
                  }
                }}
                options={actions}
                isOptionDisabled={option => option.disabled}
                className={s.select}
                styles={selectStyles}
                aria-label="select action"
                isDisabled={activeJob || (!!csv && !isUploadFinished)}
              />
            </div>
            {selectedAction ? (
              <div className={s.selectContainer}>
                <label className={s.actionLabel}>
                  {`What would you like to ${selectedAction.label}?`}
                  <span className={s.errorMsg}>*</span>
                </label>
                <Select
                  placeholder="Select Record Type"
                  noOptionsMessage={() => 'No results...'}
                  value={selectedField}
                  onChange={handleRecordTypeChange}
                  options={sortObjectsList(serializeImportRecordType(recordTypeObj).slice())}
                  className={s.select}
                  styles={selectStyles}
                  aria-label="select record type"
                  isDisabled={!!csv && !isUploadFinished}
                  filterOption={({ data }, inputValue) => {
                    if (typeof data.action !== 'object') {
                      return false
                    }
                    const selectedActionValue = selectedAction.value as keyof typeof data.action
                    return (
                      data.action[selectedActionValue] &&
                      data.label.toLowerCase().includes(inputValue.toLowerCase())
                    )
                  }}
                />
              </div>
            ) : null}
            {REQUIRE_TEMPLATE_RECORD_TYPES.includes(selectedField?.value as string) ? (
              <div className={s.selectContainer}>
                <label>
                  Select The appropriate{' '}
                  {`${
                    selectedField?.value === CA_LIST_VALUES ? 'custom attribute' : 'matter template'
                  }`}
                  <span className={s.errorMsg}>*</span>
                </label>
                {/* @ts-expect-error */}
                <PaginatedSelect
                  key={selectedField?.value}
                  placeholder={`Select ${
                    selectedField?.value === CA_LIST_VALUES ? 'Attribute' : 'Template'
                  }`}
                  url={
                    selectedField
                      ? IMPORT_CSV_RECORD_TO_TEMPLATE_URL_MAPPING[selectedField?.value]
                      : '/'
                  }
                  className={s.select}
                  styles={selectStyles}
                  value={templateType}
                  serializer={toReactSelect}
                  onChange={(item: Option) => {
                    if (item.value !== templateType?.value) {
                      setTemplateType(item)
                      setDownloadTemplate(true)
                      removeFile()
                    }
                  }}
                  aria-label="select field"
                  noOptionsMessage={() =>
                    `No ${
                      selectedField?.value === CA_LIST_VALUES ? 'attributes' : 'templates'
                    } found for update`
                  }
                  isDisabled={csv && !isUploadFinished}
                />
              </div>
            ) : null}
            <div className={s.downloadTemplate}>
              <p className={s.helpText}>
                Download our template for a guide on filling out the CSV file
                <span>(optional)</span>
              </p>
              <Button onClick={handleTemplateDownload} isDisabled={!enableDownloadTemplate}>
                Download Template
              </Button>
              <Link
                to={fetchHelpArticle()}
                target="_blank"
                rel="noreferrer"
                onClick={e => (!selectedField ? e.preventDefault() : () => {})}
                className={!selectedField ? s.disabledHelpLink : s.helpLink}
              >
                {selectedField && getLinkLabelFor(selectedField?.value, selectedAction?.value)}
              </Link>
            </div>
            {enableDownloadTemplate && selectedAction && (
              <div className={s.createWarning}>
                <WarningLine
                  message={`Max of ${
                    ROWS_LIMIT[selectedAction.value]
                  } records per CSV file allowed`}
                />
              </div>
            )}
          </div>
        )
      },
      {
        text: <p className={!enableDownloadTemplate ? s.disabledStepTitle : ''}>Upload CSV File</p>,
        completed: false,
        content: (
          <div className={checkPreviousStep() ? s.disabledFilePicker : s.nameSection}>
            {csv ? (
              <div className={s.csvList}>
                <span className={s.fileIcon}>
                  <FileIcon />
                </span>
                <label className={s.label}>{csv.name}</label>
                <HoverAction
                  className={s.delete}
                  onClick={removeFile}
                  icon={<FaTrash />}
                  size="xsmall"
                  tip="Remove"
                  isDisabled={csv && !isUploadFinished}
                />
              </div>
            ) : (
              <div className={s.dropzoneWrapper}>
                <Dropzone />
              </div>
            )}
          </div>
        )
      },
      {
        text: (
          <p className={!csv || (csv && !isUploadFinished) ? s.disabledStepTitle : ''}>
            Map Fields
          </p>
        ),
        completed: false,
        content:
          csvColumnOptions.length && slColumnOptions.length ? (
            <div className={s.fieldsWrapper}>
              <WarningLine
                className={cn(s.createWarning, s.mappingText)}
                message={
                  <span>
                    Please confirm that fields in your file match up with expected SimpleLegal
                    fields.
                    <p>
                      <span className={s.errorMsg}>*</span>
                      {`Required fields must be mapped below; ${
                        MINIMUM_MAPPED_FIELDS_REQUIRED < 2
                          ? 'at least one field is'
                          : 'a minimum of two are'
                      } needed to start the import.`}
                    </p>
                  </span>
                }
              />
              <div className={s.fieldHeader}>
                <div className={s.slHeader}>SimpleLegal Fields</div>
                <div className={s.csvHeader}>CSV Fields</div>
              </div>
              <KeyValuePairs
                pairs={slColumnOptions.map((ele, index) => ({
                  key: (
                    <span>
                      {ele.label}{' '}
                      {requiredFields.includes(ele.value) ? (
                        <span className={s.errorMsg}>*</span>
                      ) : (
                        ''
                      )}
                    </span>
                  ),
                  sectionClass: s.sectionClass,
                  keyClass: s.labelClass,
                  valueClass: s.dropdownClass,
                  value: (
                    <Select
                      key={index}
                      placeholder="Select or Type here"
                      noOptionsMessage={() => 'No results...'}
                      value={mappedFields ? mappedFields[ele.value] : {}}
                      onChange={option => mappingChangeHandler(option as BasicOption, ele.value)}
                      options={csvColumnOptions}
                      isClearable
                      className={`${index > 2 ? s.selectFieldDropUp : ''}`}
                      styles={controlStyles(ele.value)}
                      aria-label={`csvcolumn${index + 1}`}
                    />
                  )
                }))}
              />
            </div>
          ) : null
      }
    ]

    setSteps(draft => {
      if (enableDownloadTemplate) {
        stepsArr[0].completed = true
      }
      if (csv) {
        stepsArr[1].completed = true
      }
      if (enableImport()) {
        stepsArr[2].completed = true
      }
      return stepsArr
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    csv,
    selectedAction,
    selectedField,
    templateType,
    mappedFields,
    enableDownloadTemplate,
    retryFileUpload,
    isUploadFinished,
    activeJob,
    recordTypeObj
  ])

  useEffect(() => {
    if (selectedField && csv) {
      setIsUploadProgressModalVisible(true)
    }
  }, [csv, selectedField, dispatch])

  const startImport = async () => {
    const mapping = Object.entries(mappedFields!).reduce(
      (x1, [key, { value }]) => ({ ...x1, [key]: value }),
      {}
    )

    try {
      const { active_job } = await makeGetRequest(ACTIVE_JOB_STATUS_URL)
      dispatch({
        type: ACT.SET_ACTIVE_JOB_STATUS,
        payload: { activeJob: active_job }
      })
      active_job
        ? setActiveJobProcessingModal(true)
        : await withLoadingLocks(
            makePostRequest(
              '/bulkv2/import_csv_mappings/',
              serializeImportParams(
                selectedAction as ActionOptionType,
                selectedField as FieldOptionType,
                templateType as Option,
                fileId,
                mapping
              )
            )
          )
            .then(async res => {
              setIsProcessingImportModalVisible(true)
            })
            .catch(error => {
              dispatch({
                type: 'API_ERROR',
                error
              })
            })
      removeFile()
      setSelectedAction(null)
      setSelectedField(null)
      setTemplateType(null)
      setDownloadTemplate(false)
    } catch (error) {
      setIsProcessingImportModalVisible(false)
      //@ts-expect-error
      if (error?.response?.data?.message?.csv) {
        //@ts-expect-error
        setErrorsCsv(error?.response?.data?.message?.csv)
      } else {
        dispatch({
          type: 'API_ERROR',
          error: { ...(error as object), errorTitle: 'Error' }
        })
      }
    }
  }

  const successCb = (response: FileImportResponse) => {
    const {
      csv_columns: csvColumns,
      sl_fields,
      mapping,
      required_sl_fields: requiredSLFields = [],
      ordered_sl_fields: orderedSlFields = []
    } = response

    const csvFields = sortAlphabeticallyByProperty(makeOptions(csvColumns.slice()), 'label')

    // set mappedFields
    const initialMapping = Object.entries(mapping).reduce(
      (mappedForm: { [key: string]: { label: string; value: string } }, [slKey, csvKey]) => {
        if (csvKey) {
          mappedForm[slKey] = { label: csvKey, value: csvKey }
        }
        return mappedForm
      },
      {}
    )

    setMappedFields(initialMapping)
    setRequiredFields(requiredSLFields)

    if (CMI_REQUIRED_RECORD_TYPES.includes(selectedField?.value as string)) {
      const slFieldOptions = Object.keys(sl_fields).map(key => ({
        value: key,
        label: sl_fields[key]
      }))

      const mandatorySlColOptions = sortObjectsList(
        slFieldOptions.filter(c => requiredSLFields.includes(c.value))
      )
      const generalSlColOptions = sortObjectsList(
        slFieldOptions.filter(c => !requiredSLFields.includes(c.value))
      )
      const sortedSlColOptions = mandatorySlColOptions.concat(generalSlColOptions)
      const orderedOptions = moveToFrontOfArray(sortedSlColOptions, 'value', 'client_matter_id')

      setSlColumnOptions(orderedOptions as ColumnOption[])
    } else {
      const slFieldOptions = orderedSlFields.map(key => ({
        value: key,
        label: sl_fields[key]
      }))
      setSlColumnOptions(slFieldOptions)
    }

    setCsvColumnOptions(csvFields as ColumnOption[])
    setS3Files(null)
    setIsUploadFinished(true)
    setRetryFileUpload(false)
    setIsUploadProgressModalVisible(false)
  }

  const closeActiveJobProcessingModal = () => {
    setActiveJobProcessingModal(false)
    setDownloadTemplate(false)
    setActiveTab(history)
  }

  useEffect(() => {
    if (retryFileUpload) {
      setIsUploadProgressModalVisible(false)
      removeFile()
      clearErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryFileUpload])

  const WarningModalBody = (
    <>
      <WarningLine message="Processing cannot be canceled once the import starts" />
      <WarningLine message="Changes cannot be reverted once the import finishes" />
      {selectedField?.value === MATTER_TEMPLATES && (
        <WarningLine message="Closed matters will reopen for processing only and will be saved in a closed status when processing completes" />
      )}
      <CheckboxLine
        message="I acknowledge I've read and understand the above message"
        acknowledgement={ack}
        setAcknowledgement={setAck}
      />
    </>
  )

  useEffect(() => {
    if (fileUploadError && !isUploadProgressModalVisible) {
      dispatch({
        type: 'API_ERROR',
        error: errorData
      })
      removeFile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadError])

  const handleConfirmProgress = () => {
    if (fileUploadError) {
      setRetryFileUpload(true)
    } else {
      setIsUploadProgressModalVisible(false)
      if (isUploadFinished) {
        setFileUploadError(false)
        setRetryFileUpload(false)
      }
    }
  }

  return (
    <>
      <Panel
        styles={{ boxShadow: 'none' }}
        className={s.importCsvContainer}
        bodyClassName={steps[1]?.completed ? s.panelBody : null}
      >
        <div className={s.header}>
          <h2 className={s.title}>Import</h2>
        </div>
        {activeJob && (
          <Alert
            message="There is an existing update in progress. This update must complete processing before you can start a new bulk update."
            status="info"
            style={{ width: '90%', margin: '24px 0 18px 48px' }}
          />
        )}
        <Stepper orientation="vertical" automaticHeader activeStep={0} steps={steps} />
        {csvColumnOptions.length ? (
          <>
            <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
              <Button onClick={() => setShowImportWarning(true)} isDisabled={!enableImport()}>
                Start Import
              </Button>
            </div>
            <ReactTooltip
              id="buttonTooltip"
              type="light"
              effect="solid"
              place="top"
              border
              disable={enableImport()}
            >
              <p className={s.bulkTooltip}>
                {!requiredFields.every(key => mappedFields?.hasOwnProperty(key)) ? (
                  <span>
                    Provide value for all<span className={s.errorMsg}> *</span>required fields
                  </span>
                ) : (
                  `${
                    selectedAction?.value === ACTION_TYPE.CREATE
                      ? 'At least one field'
                      : 'A minimum of two fields'
                  } must be mapped`
                )}
              </p>
            </ReactTooltip>
          </>
        ) : null}
        {isUploadProgressModalVisible && (
          <ModalContainer
            title={
              isUploadFinished ? (
                fileUploadError ? (
                  <p className={s.errorMsg}>File Validation Failed</p>
                ) : (
                  'Import completed'
                )
              ) : (
                'Validating File'
              )
            }
            size="md"
            confirmText={fileUploadError ? 'Try Again' : 'Close'}
            confirmCb={handleConfirmProgress}
            hasCloseIcon={false}
            content={
              <UploadProgress
                file={csv as CustomFile}
                successCb={successCb}
                setIsUploadFinished={setIsUploadFinished}
                setFileSource={setFileSource}
                selectedField={selectedField as FieldOptionType}
                selectedAction={selectedAction as ActionOptionType}
                templateType={templateType as Option}
                fileId={fileId as number}
                setFileId={setFileId}
                fileUploadError={fileUploadError}
                setFileUploadError={setFileUploadError}
                setErrorData={setErrorData}
              />
            }
          />
        )}
        {isProcessingImportModalVisible && (
          <ModalContainer
            title="Processing Import"
            cancelText="Close"
            size="md"
            cancelCb={() => {
              setIsProcessingImportModalVisible(false)
              setDownloadTemplate(false)
              setActiveTab(history)
            }}
            hasCloseIcon={false}
            content={
              <span className={s.downloadInfo}>
                The file import is processing and may take some time to complete.
              </span>
            }
          />
        )}
        {errorsCsv && (
          <>
            <ModalContainer
              title="Import failed"
              cancelText="Cancel"
              size="md"
              cancelCb={() => {
                setErrorsCsv(null)
              }}
              confirmText="Download"
              confirmCb={() => {
                csvLink?.current?.link.click()
                setTimeout(() => {
                  setErrorsCsv(null)
                }, 2000)
              }}
              hasCloseIcon={false}
              content={
                <span className={s.downloadInfo}>Download the report to see why it failed.</span>
              }
            />
            <CSVLink
              data={errorsCsv}
              filename={s3Files?.name}
              className={s.hidden}
              ref={csvLink}
              separator=","
              target="_blank"
            />
          </>
        )}
        {activeJobProcessingModal && (
          <ModalContainer
            title="Existing update in progress"
            size="md"
            cancelText="Close"
            cancelCb={closeActiveJobProcessingModal}
            hasCloseIcon={false}
            content="You cannot start a bulk update at this time because a previous update is still processing. Go to the Job History page to check the status of the previous update."
          />
        )}
        {showImportWarning && (
          <ModalContainer
            title="Do you wish to start the import?"
            size="md"
            cancelText="Cancel"
            confirmText="Continue"
            cancelCb={() => {
              setShowImportWarning(false)
              setAck(false)
            }}
            confirmCb={startImport}
            hasCloseIcon={false}
            content={WarningModalBody}
            isDisabled={!ack}
          />
        )}
      </Panel>
    </>
  )
}

export default BulkImportContainer
