import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { Badge } from 'simple-core-ui'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

interface Props {
  selectedTab: string
  setSelectedTab: (tab: 'tasks' | 'events') => void
  tasksCount: number
  eventsCount: number
}

const Tabs = ({ selectedTab, setSelectedTab, tasksCount, eventsCount }: Props) => {
  const tabs = useMemo(() => {
    return [
      {
        label: 'My Tasks',
        value: 'tasks',
        count: tasksCount,
        visibility: hasModule(MODULE.TASKS)
      },
      {
        label: 'My Upcoming Events',
        value: 'events',
        count: eventsCount,
        visibility: hasModule(MODULE.EVENT_MANAGEMENT)
      }
    ].filter(tab => tab.visibility)
  }, [eventsCount, tasksCount])

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => setSelectedTab(tab.value as 'tasks' | 'events')}
          key={tab.value}
          data-testid={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
        >
          {tab.count !== null ? (
            <Badge asPill className={s.pill} content={String(tab.count)} size="sm" />
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
