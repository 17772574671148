import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { CourtRule } from '../types'
import s from './RulesActionsPopover.scss'

interface Props {
  rule: CourtRule
  editRule: (row: CourtRule) => void
  deleteRule: (row: CourtRule) => void
  viewRule: (row: CourtRule) => void
  updateRule: (row: CourtRule) => void
}

const RulesActionsPopover = ({ rule, editRule, deleteRule, viewRule, updateRule }: Props) => {
  const actions = !rule.needsReview
    ? [
        {
          name: 'VIEW',
          text: 'View',
          onClick() {
            viewRule(rule)
          }
        },
        {
          name: 'EDIT',
          text: 'Edit',
          onClick() {
            editRule(rule)
          }
        },
        {
          name: 'DELETE',
          text: 'Delete',
          onClick() {
            deleteRule(rule)
          }
        }
      ]
    : [
        {
          name: 'UPDATE',
          text: 'Update',
          onClick() {
            updateRule(rule)
          }
        },
        {
          name: 'DELETE',
          text: 'Delete',
          onClick() {
            deleteRule(rule)
          }
        }
      ]

  return (
    <Popover
      testId={`item-${rule.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default RulesActionsPopover
