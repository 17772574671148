import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Panel, Tabs, Tab, Table, DataTableSerializer, Input, Ellipsis } from 'simple-core-ui'
import ACT from 'budgets/actions'
import s from '../styles/BudgetAmounts.scss'
import { columnMap, withTotalRow } from 'budgets/detail/tableDefinitions'
import { formatPercentage } from 'simple-core-ui/utils/formatting'
import { curr2Num4Input } from 'budgets/reducer'
import cn from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import Comments from 'common/Comments'
import AmountsHeader from './AmountsHeader'
import BACell from '../components/BudgetAmountCell'
import RFDiff from './ReforecastDiff'

const evalContent = c => (c === '----' ? '' : c)

const BudgetAmounts = () => {
  const { isReforecasting, rfBudgetDetails, budgetDetails } = useSelector(({ budgets }) => budgets)
  const details = isReforecasting ? rfBudgetDetails : budgetDetails
  const { type } = details
  const dispatch = useDispatch()

  const onChangeRow = (value, columnKey, rowIndex) => {
    dispatch({
      type: ACT.UPDATE_REFORECAST_ROW_VALUE,
      payload: {
        columnKey,
        rowIndex,
        value
      }
    })
  }

  useEffect(() => {
    if (!details.id) return

    dispatch({
      type: ACT.RECENT_BUDGET_FETCH_REQUESTED,
      payload: {
        budgetId: details.id
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.id])

  useEffect(() => {
    dispatch({
      type: ACT.BUDGET_DETAILS_FETCH_REQUESTED,
      loadingLock: 'on'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({
      type: ACT.BUDGET_COMPARE_FETCH_REQUESTED,
      loadingLock: 'on'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [isReforecasting])

  const viewRenderCell = (cell, row, rowIndex) => {
    let InnerCell =
      row.id === 'total' ? (
        <strong>{cell.content}</strong>
      ) : (
        <div>
          {cell.columnKey.includes('allocated') && (
            <RFDiff rowIndex={rowIndex} columnKey="allocated" />
          )}
          {cell.content}
        </div>
      )

    return <BACell columnKey={cell.columnKey}>{InnerCell}</BACell>
  }

  const noteRenderCell = (cell, row, rowIndex) => {
    if (row.id === 'total') {
      return <strong>{cell.content}</strong>
    }

    const note = details.rows[rowIndex]?.note

    return (
      <div>
        {cell.content}
        <div>
          {isReforecasting ? (
            <TextareaAutosize
              value={note}
              placeholder="Add notes"
              onChange={e => onChangeRow(e.target.value, 'note', rowIndex)}
            />
          ) : (
            note && (
              <Ellipsis lines={4} className={s.note}>
                {note}
              </Ellipsis>
            )
          )}
        </div>
      </div>
    )
  }

  const editableRenderCell = (cell, row, rowIndex) => {
    let Inner
    if (isReforecasting && row.id !== 'total') {
      const cellContent = evalContent(cell.content)
      const isDiff = cellContent !== curr2Num4Input(budgetDetails.rows[rowIndex][cell.columnKey])
      Inner = (
        <Input
          text={cellContent}
          type="number"
          cssClass={cn(s.tableInput, {
            highlight: isDiff
          })}
          onChangeCb={e =>
            onChangeRow(
              e.target.value === '' ? e.target.value : Number(e.target.value),
              cell.columnKey,
              rowIndex
            )
          }
        />
      )
    } else if (row.id === 'total') {
      Inner = <strong>{cell.content}</strong>
    } else {
      Inner = <div>{cell.content}</div>
    }

    return <BACell columnKey={cell.columnKey}>{Inner}</BACell>
  }

  const percRenderCell = (cell, row) => {
    const isOver = cell.content > 100
    const style = isOver ? { color: '#C72D4D' } : {}
    let Inner =
      row.id === 'total' ? (
        <strong style={style}>{formatPercentage(cell.content)}</strong>
      ) : (
        <span style={style}>{formatPercentage(cell.content)}</span>
      )
    return <BACell title="Percentage">{Inner}</BACell>
  }

  const numberStyle = { textAlign: 'right', verticalAlign: 'middle' }

  const columns = [
    {
      columnKey: 'title',
      content: columnMap[type],
      isSortable: false,
      style: { width: '18%', verticalAlign: 'middle' },
      render: noteRenderCell
    },
    {
      columnKey: 'allocated_fees',
      content: 'Fees',
      isSortable: false,
      style: { ...numberStyle, borderLeft: '1px solid #C1C1C1' },
      render: editableRenderCell
    },
    {
      columnKey: 'allocated_expenses',
      content: 'Expenses',
      isSortable: false,
      style: numberStyle,
      render: editableRenderCell
    },
    {
      columnKey: 'allocated_total',
      content: 'Total',
      isSortable: false,
      style: numberStyle,
      render: viewRenderCell
    },

    {
      columnKey: 'approved_fees',
      content: 'Fees',
      isSortable: false,
      style: { ...numberStyle, borderLeft: '1px solid #C1C1C1' },
      render: viewRenderCell
    },
    {
      columnKey: 'approved_expenses',
      content: 'Expenses',
      isSortable: false,
      style: numberStyle,
      render: viewRenderCell
    },
    {
      columnKey: 'approved_percentage',
      content: 'Percentage',
      isSortable: false,
      style: numberStyle,
      render: percRenderCell
    },
    {
      columnKey: 'pending_fees',
      content: 'Fees',
      isSortable: false,
      style: { ...numberStyle, borderLeft: '1px solid #C1C1C1' },
      render: viewRenderCell
    },
    {
      columnKey: 'pending_expenses',
      content: 'Expenses',
      isSortable: false,
      style: numberStyle,
      render: viewRenderCell
    },
    {
      columnKey: 'pending_percentage',
      content: 'Percentage',
      isSortable: false,
      style: numberStyle,
      render: percRenderCell
    }
  ]

  if (!details.id) return null

  return (
    <Panel title="Budget Details">
      <Tabs>
        <Tab header="Amounts">
          <AmountsHeader />
          <Table
            noHover
            renderHead={() => {
              return (
                <thead>
                  <tr>
                    <th
                      colSpan="1"
                      style={{
                        borderBottom: '1px solid #C1C1C1',
                        color: '#939393',
                        fontSize: '16px',
                        verticalAlign: 'middle'
                      }}
                    >
                      Terms
                    </th>
                    <th colSpan="3" className={s.tableHeaderCell}>
                      Allocated
                    </th>
                    <th colSpan="3" className={s.tableHeaderCell}>
                      Approved
                    </th>
                    <th colSpan="3" className={s.tableHeaderCell}>
                      Pending
                    </th>
                  </tr>
                </thead>
              )
            }}
            rows={DataTableSerializer.getRows(withTotalRow(details), columns)}
            hideHeader
            columns={columns}
          />
        </Tab>
        <Tab header="Internal Notes">
          <Comments
            objectId={details.id}
            modelName="budgets"
            user={window.credentials.user}
            title="Internal Notes"
            appLabel="budgets"
          />
        </Tab>
      </Tabs>
      <ReactTooltip effect="solid" type="light" className={s.tooltipPopup} />
    </Panel>
  )
}

export default BudgetAmounts
