import APP_ACT from 'app/actions'
import moment from 'moment'
import { useMatterGroupLabels } from 'hooks/selectors'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  AvatarList,
  Button,
  DataTableWrapper,
  Ellipsis,
  ModalContainer,
  Panel,
  SwitchToggle,
  useLoading
} from 'simple-core-ui'
import { useImmer } from 'use-immer'
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePatchRequest } from 'utils/api'
import { checkByNameIfInArray, isBasicTaskManagementPlan, openLink } from 'utils/helpers'
import { ActionsPopover } from './ActionsPopover'
import { DeleteConfirmation } from './DeleteConfirmation'
import { TemplateModal } from './TemplateModal'
import s from './Templates.scss'
import { toTemplates } from './serializers'
import { MatterGroup, Template } from './types'
import { getNormalizedCellContent } from './utils'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const emptyTemplate = {
  name: '',
  description: ''
}

const initialState = {
  category: {
    name: ''
  },
  params: {
    pageSize: 10,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const Templates = () => {
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [templates, setTemplates] = useImmer<Template[]>([])
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const [template, setTemplate] = useState({} as Template)
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const matterGroupLabels = useMatterGroupLabels() as string[]
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const fetchTemplates = async () => {
    try {
      const { rows: templates } = await withLoadingLocks(
        makeGetRequest('/task-management/task-templates/')
      )
      setTemplates(toTemplates(templates))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (id) return
    fetchTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'name',
        content: 'Template',
        isSortable: true,
        isFilterable: true,
        style: { whiteSpace: 'break-spaces' }
      },
      {
        columnKey: 'matterGroup',
        content: matterGroupLabels[0],
        isSortable: true,
        isFilterable: true,
        filterableBy: 'label'
      },
      {
        columnKey: 'relatedTasks',
        content: '# of tasks',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'lastEdited',
        content: 'Last Edited',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'editedBy',
        content: 'Edited By',
        isSortable: true,
        isFilterable: true
      },
      {
        columnKey: 'isActive',
        content: (
          <>
            Active
            <AiOutlineInfoCircle className={s.infoTooltip} data-for="infoTooltip" data-tip />
            <ReactTooltip id="infoTooltip" type="light" effect="solid" place="top" border>
              Active settings can be applied to a task
            </ReactTooltip>
          </>
        )
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleStatus = async (id: number) => {
    try {
      const index = templates.findIndex(p => p.id === id)
      const updatedTemplate = await withLoadingLocks(
        makePatchRequest(`/task-management/task-templates/${id}/`, {
          is_active: !templates[index].isActive,
          name: templates[index].name
        })
      )
      setTemplates(draft => {
        draft[index] = toTemplates([
          { ...updatedTemplate, related_tasks: templates[index].relatedTasks }
        ])[0]
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Template ${templates[index].name} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const renderCell = ({
    rowId,
    columnKey,
    content
  }: {
    rowId: number
    columnKey: string
    content: any
  }) => {
    if (!content && content !== 0) {
      return '--'
    }

    if (columnKey === 'name') {
      return (
        <a style={{ cursor: 'pointer' }} onClick={() => navigate(`${rowId}`)}>
          <Ellipsis width={180}>{content}</Ellipsis>
        </a>
      )
    }
    if (columnKey === 'isActive') {
      const isActive = content !== '----'
      return <SwitchToggle defaultOn={isActive} onToggle={() => toggleStatus(rowId)} />
    }
    if (columnKey === 'matterGroup') {
      return <Ellipsis width={180}>{content.label}</Ellipsis>
    }
    if (columnKey === 'type') {
      return content
    }
    if (columnKey === 'lastEdited') {
      return content === '----' ? '--' : moment(content).format('M/D/YYYY')
    }
    if (columnKey === 'editedBy') {
      return content === '----' ? (
        '--'
      ) : (
        <AvatarList size="md" wrapperStyles={{ width: 36 }} entries={[{ label: content }]} />
      )
    }
    return content
  }

  const toggleTemplateModal = () => {
    setShowTemplateModal(!showTemplateModal)
    setShowDuplicateError(false)
  }

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const showEditModal = async (template: Template) => {
    toggleTemplateModal()
    setTemplate(template)

    try {
      if (template.id) {
        const tmpl = await withLoadingLocks(
          makeGetRequest(`/task-management/task-templates/${template.id}/`)
        )
        setTemplate(toTemplates([tmpl])[0])
      }
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const showDeleteModal = (template: Template) => {
    setShowDeleteConfirmation(true)
    setTemplate(template)
  }

  const onDeleteTemplate = async () => {
    try {
      await makeDeleteRequest(`/task-management/task-templates/${template.id}/`)

      setTemplates(templates.filter(e => e.id !== template.id))

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Template successfully deleted',
          level: 'success'
        }
      })
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem deleting the template',
          level: 'error'
        }
      })
    }

    setShowDeleteConfirmation(false)
  }

  const updateTemplate = (value: string | MatterGroup, property: string) => {
    if (property === 'name' && typeof value === 'string') {
      setShowDuplicateError(checkByNameIfInArray(value, templates, { id: template.id }))
    }
    setTemplate({
      ...template,
      [property]: value
    })
  }

  const saveTemplate = async (cb?: (id: number) => void) => {
    try {
      if (template.id) {
        const index = templates.findIndex(p => p.id === template.id)
        const data = {
          name: template.name,
          description: template.description,
          matter_group_id: template.matterGroup
            ? template.matterGroup.value === -1
              ? null
              : template.matterGroup.value
            : null
        }
        const updatedTemplate = await withLoadingLocks(
          makePatchRequest(`/task-management/task-templates/${template.id}/`, data)
        )
        setTemplates(draft => {
          draft[index] = toTemplates([
            { ...updatedTemplate, related_tasks: templates[index].relatedTasks }
          ])[0]
        })
      } else {
        const newTemplate = await withLoadingLocks(
          makePostRequest(`/task-management/task-templates/`, {
            name: template.name,
            description: template.description,
            matter_group_id: template.matterGroup
              ? template.matterGroup.value === -1
                ? null
                : template.matterGroup.value
              : null
          })
        )
        setTemplates(draft => {
          draft.push(toTemplates([{ ...newTemplate, related_tasks: 0 }])[0])
        })
        cb?.(newTemplate.id)
      }

      toggleTemplateModal()

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: `Task template ${template.name} successfully ${
            template.id ? 'updated' : 'created'
          }.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
      toggleTemplateModal()
    }
  }

  const saveAndAddTasks = () => {
    saveTemplate(id => {
      navigate(`${id}`)
    })
  }

  const downloadXlsx = async () => {
    const { columnKey, isDesc } = params.ordering

    const url = `/task-management/task-templates/export/?columnKey=${columnKey}&isDesc=${+isDesc}`

    const { is_async } = await makeGetRequest(`${url}&check=true`)
    if (!is_async) {
      openLink(url)
    } else {
      setIsDownloadModalOpen(true)
    }
  }

  const duplicateTemplate = async (template: Template) => {
    try {
      await makePostRequest('/task-management/task-templates/clone/', {
        object_id: template.id
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Task template successfully copied.',
          level: 'success'
        }
      })

      fetchTemplates()
    } catch (e) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was a problem duplicating the template',
          level: 'error'
        }
      })
    }
  }

  const renderCustomAction = (row: Template) => (
    <ActionsPopover
      template={row}
      deleteTemplate={showDeleteModal}
      editTemplate={showEditModal}
      duplicateTemplate={duplicateTemplate}
    />
  )

  return (
    <>
      {id ? (
        <Outlet />
      ) : (
        <Panel
          title="Manage Task Templates"
          subtitle="Use templates to quickly set up standardized task list for repeated legal tasks."
          rightActions={[
            <Button hasNewDesign key="download" onClick={downloadXlsx} isPrimary isOutline>
              Download
            </Button>,
            <Fragment key="add">
              <div data-for="buttonTooltip" data-tip className={s.toolTipWrapper}>
                <Button
                  hasNewDesign
                  onClick={() => showEditModal(emptyTemplate as Template)}
                  isPrimary
                  isDisabled={isBasicTaskManagementPlan()}
                >
                  Add Template
                </Button>
              </div>
              <ReactTooltip
                id="buttonTooltip"
                type="light"
                effect="solid"
                place="top"
                border
                disable={!isBasicTaskManagementPlan()}
                className={s.tooltipPopup}
              >
                Contact customer success to upgrade.
              </ReactTooltip>
            </Fragment>
          ]}
          styles={{ boxShadow: 'none' }}
          className={s.statuses}
        >
          <DataTableWrapper
            params={params}
            categories={[]}
            rows={templates}
            columns={columns}
            updateTable={updateTable}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            className={s.itemsTable}
            customAction={renderCustomAction}
            hasActions
            hasTooltip
            alwaysShowActions
            categoryKey="template"
            getNormalizedCellContent={getNormalizedCellContent}
            renderCell={renderCell}
            multiSort
          />
          {showTemplateModal && (
            <TemplateModal
              saveTemplate={saveTemplate}
              matterGroupLabels={matterGroupLabels}
              template={template}
              updateTemplate={updateTemplate}
              toggleTemplateModal={toggleTemplateModal}
              showDuplicateError={showDuplicateError}
              saveAndAddTasks={saveAndAddTasks}
            />
          )}
          {showDeleteConfirmation && (
            <DeleteConfirmation
              onConfirm={onDeleteTemplate}
              onCancel={() => setShowDeleteConfirmation(false)}
            />
          )}
          {isDownloadModalOpen && (
            <ModalContainer
              title="We are working on your download"
              content="You’ll receive an email once your export is ready."
              confirmText="OK"
              confirmCb={() => setIsDownloadModalOpen(false)}
              cancelCb={() => setIsDownloadModalOpen(false)}
              size="sm"
              hideCancelBtn
            />
          )}
        </Panel>
      )}
    </>
  )
}

export default Templates
