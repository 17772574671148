import { Tooltip } from 'simple-core-ui'
import s from './Field.scss'

const HorizField = ({ label, hasError, isRequired, className, children, tooltip }) => (
  <section className={`${s.horizContainer}`} data-testid={`horiz-field-${label}`}>
    <span className={`${s.horizSpan} ${hasError && s.error}`}>
      {label}
      {isRequired && (
        <span className={s.asterick} title={'required'}>
          {' * '}
        </span>
      )}
      {tooltip && <Tooltip triggerClassName={s.tooltipTrigger} content={tooltip} />}
    </span>
    <span
      className={s.horizSpan}
      data-testid={`field_${label
        .toLowerCase()
        .split(' ')
        .join('_')}`}
    >
      {children}
    </span>
  </section>
)

export default HorizField
