import { Fragment } from 'react'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import get from 'lodash/get'
import cn from 'classnames'
import {
  SearchableSelect,
  TextareaContainer,
  ReactDatePicker,
  ReactDateRangePicker,
  Currency
} from 'containers'
import { ControlledDateRangePicker, TextInput, PaginatedSelect } from 'simple-core-ui'
import s from './EditableField.scss'
import * as CONST from 'utils/constants'

const EditableField = ({
  type,
  options,
  value,
  onChange,
  loadCb,
  placeholder,
  placeholderStart,
  placeholderEnd,
  disabled,
  id,
  className,
  onInputChange,
  isInvalid,
  currencySize,
  clearable = true,
  testid,
  alphabeticallySorted,
  paginated,
  url,
  serializer,
  debounceDelay,
  isPortal,
  maxMenuHeight,
  style = {},
  resultsProperty
}) => {
  switch (type) {
    case 'text':
      return (
        <TextInput
          className={cn({ [s.error]: isInvalid }, className)}
          id={id}
          isDisabled={disabled}
          type="text"
          value={value}
          placeholder={placeholder}
          {...(debounceDelay ? { debounceDelay } : {})}
          onChange={onChange}
          testid={testid}
          style={style}
        />
      )
    case 'percentage':
      return (
        <Fragment>
          <TextInput
            id={id}
            isDisabled={disabled}
            type="number"
            value={value}
            onChange={onChange}
            testid={testid}
          />
          <span className={s.percentage}> % </span>
        </Fragment>
      )
    case 'decimal':
    case 'number':
    case 'numeric':
      return (
        <TextInput
          className={cn({ [s.error]: isInvalid })}
          id={id}
          isDisabled={disabled}
          type="number"
          value={value}
          onChange={onChange}
          testid={testid}
          style={style}
        />
      )
    case 'select':
    case 'multiselect':
      if (!options) return ''
      return !paginated ? (
        <SearchableSelect
          id={id}
          options={options}
          selectedOption={value}
          value={value}
          onChange={onChange}
          placeholder={placeholder || 'Select.. '}
          openMenuOnFocus
          isMulti={type === 'multiselect'}
          isDisabled={disabled}
          onInputChange={onInputChange}
          isClearable={clearable}
          className={cn(className, { [s.errorSelect]: isInvalid })}
          testid={testid}
          alphabeticallySorted={alphabeticallySorted}
          isPortal={isPortal}
          maxMenuHeight={maxMenuHeight}
          styles={style}
        />
      ) : (
        <PaginatedSelect
          url={url}
          value={value}
          onChange={onChange}
          serializer={serializer}
          isClearable={clearable}
          resultsProperty={resultsProperty}
        />
      )
    case 'boolean':
      const selectedOption = options.find(option => option.value === value) || value
      return (
        <SearchableSelect
          id={id}
          options={options}
          selectedOption={selectedOption}
          value={selectedOption}
          onChange={onChange}
          placeholder={placeholder || 'Select.. '}
          openMenuOnFocus
          isMulti={type === 'multiselect'}
          isDisabled={disabled}
          onInputChange={onInputChange}
          isClearable={clearable}
          className={cn(className, { [s.errorSelect]: isInvalid })}
          testid={testid}
          isPortal={isPortal}
          maxMenuHeight={maxMenuHeight}
          styles={style}
        />
      )
    case 'textarea':
      return (
        <TextareaContainer
          id={id}
          disabled={disabled}
          value={value}
          onChange={onChange}
          className={cn({ [s.error]: isInvalid })}
          testid={testid}
          styles={style}
        />
      )
    case 'date':
      return (
        <ReactDatePicker
          disabled={disabled}
          value={value}
          onChange={onChange}
          className={cn({ [s.errorDate]: isInvalid })}
        />
      )
    case 'daterange':
      return (
        <ReactDateRangePicker
          placeholderStart={placeholderStart}
          placeholderEnd={placeholderEnd}
          start={get(value, 'start')}
          end={get(value, 'end')}
          onRangeChange={onChange}
        />
      )
    case 'controlled-daterange':
      return (
        <ControlledDateRangePicker
          placeholderStart={placeholderStart}
          placeholderEnd={placeholderEnd}
          start={get(value, 'start')}
          end={get(value, 'end')}
          onRangeChange={onChange}
        />
      )
    case 'currency':
      className = {
        input: cn({ [s.error]: isInvalid }),
        select: cn({ [s.errorSelect]: isInvalid })
      }
      return (
        <Currency
          selectedOption={get(value, 'code', null)}
          amount={get(value, 'amount', null)}
          onChange={onChange}
          disabled={disabled}
          classNameMap={className}
          size={currencySize || 'md'}
          isPortal={isPortal}
        />
      )
    case 'reference':
      return (
        <AsyncSelect
          name="reference-selection"
          value={value}
          styles={{ container: () => ({ width: '100%' }) }}
          onChange={onChange}
          loadOptions={loadCb}
          defaultOptions
          placeholder={placeholder || 'Select.. '}
          openMenuOnFocus
        />
      )
    case 'multireference':
      return (
        <AsyncSelect
          name="reference-selection"
          value={value}
          styles={{
            control: base => ({ ...base, maxHeight: '365px', overflow: 'auto' }),
            container: () => ({ width: '100%' })
          }}
          onChange={onChange}
          defaultOptions
          loadOptions={loadCb}
          placeholder="Search..."
          isMulti
        />
      )
    case 'multi':
      return (
        <SearchableSelect
          id={id}
          options={options}
          selectedOption={value}
          value={value}
          className={className}
          onChange={onChange}
          placeholder={placeholder || 'Select.. '}
          openMenuOnFocus
          isMulti
          alphabeticallySorted={alphabeticallySorted}
          isPortal={false}
        />
      )
    case 'tag':
      return (
        <CreatableSelect
          placeholder="Hit enter to create keywords..."
          options={options}
          isClearable={false}
          value={value}
          className="tagOnly"
          isMulti
          onChange={onChange}
        />
      )
    case CONST.SYSTEM_GENERATED:
      return (
        <p>
          <i className={s.systemGeneratedLabel}>{CONST.SYSTEM_GENERATED_LABEL}</i>
        </p>
      )
    default:
      return <p>Unsupported Attribute</p>
  }
}

export default EditableField
