import BaseAsyncSelect from 'react-select/async'
import BaseCreatableSelect from 'react-select/creatable'
import BaseSelect, { StylesConfig } from 'react-select'
import { ScrollbarWrapper } from 'simple_review/common/ScrollbarWrapper'
import { BsPlus } from 'react-icons/bs'
import { makeGetRequest } from 'utils/api'
import { Field } from 'simple_review/@types/api'
import { Constant } from 'simple_review/@types/common'
import s from './Select.scss'

interface Props {
  value: Constant | Array<Constant> | null
  choices: Field['choices']
  isMulti?: boolean
  isDisabled?: boolean
  placeholder?: string
  styles?: StylesConfig
  onChange(newValue: Constant | Array<Constant>): void
  components?: Parameters<typeof BaseAsyncSelect>[0]['components']
  serializer?: (response: Constant[]) => Constant[]
}

interface AsyncSelectProps extends Omit<Props, 'choices'> {
  fetchUrl: string
}

const AsyncSelect = ({
  onChange,
  fetchUrl,
  isMulti = false,
  isDisabled = false,
  placeholder = 'Search Options',
  ...props
}: AsyncSelectProps) => {
  const loadOptions = async (inputValue: string) => {
    let response: Array<Constant> = await makeGetRequest(fetchUrl, {
      params: { search: inputValue }
    })

    if (props.serializer) {
      response = props.serializer(response)
    }

    return response.map(
      (option): Constant => ({
        ...option
      })
    )
  }

  return (
    <BaseAsyncSelect
      className={s.container}
      classNamePrefix={s.container}
      placeholder={placeholder}
      value={props.value}
      onChange={items => {
        onChange(items as Array<Constant>)
      }}
      loadOptions={loadOptions}
      defaultOptions
      cacheOptions
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={false}
      key="constant.value"
      components={{ MenuList: ScrollbarWrapper, ...props.components }}
      menuPlacement="auto"
      styles={props.styles}
    />
  )
}

const CreatableSelect = ({ onChange, ...props }: Omit<Props, 'choices'>) => {
  const addOption = (newItem: string) => {
    if (props.value && Array.isArray(props.value)) {
      onChange([...props.value, { value: newItem, label: newItem }])
    } else {
      onChange([{ value: newItem, label: newItem }])
    }
  }

  const getPlaceholder = () => {
    if (!Array.isArray(props.value) || props.value.length === 0) return 'Add keywords then press "+'
    return ''
  }

  return (
    <BaseCreatableSelect
      className={s.container}
      classNamePrefix={s.container}
      value={props.value}
      onCreateOption={addOption}
      onChange={values => {
        onChange(values as Array<Constant>)
      }}
      placeholder={getPlaceholder()}
      isMulti
      isDisabled={props.isDisabled}
      isClearable={false}
      openMenuOnFocus={false}
      components={{
        MenuList: ScrollbarWrapper,
        IndicatorSeparator: null,
        DropdownIndicator: () => (
          <button className={s.addButton}>
            <BsPlus size={24} />
          </button>
        )
      }}
      styles={{
        multiValueLabel: provided => ({
          ...provided,
          fontWeight: 'bold',
          color: 'black'
        })
      }}
      menuPlacement="auto"
    />
  )
}

export const Select = ({ choices, ...props }: Props) => {
  const isAsync = typeof choices === 'string'

  if (isAsync) return <AsyncSelect fetchUrl={choices} {...props} />

  const isPreloaded = Array.isArray(choices) && choices.length > 0

  if (isPreloaded)
    return (
      <BaseSelect
        className={s.container}
        classNamePrefix={s.container}
        placeholder="Search Options"
        // @ts-expect-error
        options={choices}
        value={props.value}
        onChange={value => {
          props.onChange(value as Constant)
        }}
        isMulti={props.isMulti}
        isDisabled={props.isDisabled}
        isClearable={false}
        components={{ MenuList: ScrollbarWrapper }}
        menuPlacement="auto"
        serializer={props.serializer}
      />
    )

  return <CreatableSelect {...props} />
}
