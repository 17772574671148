import keyMirror from 'keymirror'

export default keyMirror({
  BULK_FETCH_REQUESTED: null,
  BULK_FETCH_LOADING: null,
  BULK_FETCH_SUCCESS: null,
  BULK_UPDATE_SUCCESS: null,
  BULK_HISTORY_FETCH_REQUESTED: null,
  BULK_HISTORY_FETCH_SUCCESS: null,
  BULK_FIELDS_FETCH_REQUESTED: null,
  BULK_FIELDS_FETCH_SUCCESS: null,
  UPDATE_BULK_FIELDS_VALUES: null,
  RESET_BULK_FIELDS_VALUES: null,
  CLEANUP_BULK: null,
  BULK_SET_FILTER_VALUES: null,
  SET_BUILDER_PARAMS: null,
  SET_ACTIVE_JOB_STATUS: null,
  CLEAR_ROWS: null,
  SET_RECORD_TYPE: null
})
