import s from './AddEventModal.scss'
import { ReactDatePicker } from 'containers'
import { TextInput } from 'simple-core-ui'

const AddEventModal = ({
  eventName,
  setEventName,
  eventDescription,
  setEventDescription,
  eventDate,
  setEventDate
}) => {
  return (
    <div className={s.form}>
      <label>Event Name</label>
      <TextInput
        value={eventName}
        type="text"
        onChange={setEventName}
        placeholder="Enter Event Name"
        className={s.input}
      />
      <label>Description</label>
      <TextInput
        value={eventDescription}
        type="textarea"
        rows="5"
        onChange={setEventDescription}
        placeholder="Enter Description"
        className={s.input}
      />
      <label>Date</label>
      <ReactDatePicker value={eventDate} onChange={setEventDate} />
    </div>
  )
}

export default AddEventModal
