import { Task, LocalTask, DueDateType } from '../types'

export const toLocalTask = (task: Task | null): LocalTask | null => {
  if (!task) {
    return null
  }
  const {
    id,
    taskId,
    name,
    priority,
    taskType,
    description,
    assignees,
    subtasks,
    parent,
    createdDate,
    createdBy,
    relativeDueDate
  } = task

  return {
    id,
    taskId,
    name,
    relativeDueDate,
    priority: priority
      ? {
          value: priority.id,
          label: priority.name,
          color: priority.color
        }
      : null,
    taskType: taskType
      ? {
          value: taskType.id,
          label: taskType.name
        }
      : null,
    description,
    createdBy,
    createdDate,
    assignees,
    subtasks,
    parent
  }
}

const getTaskDueDate = (relativeDueDate: LocalTask['relativeDueDate']): Task['relativeDueDate'] => {
  const { dateType, relativeDateParams, clearRelativeDueDate } = relativeDueDate || {}
  return clearRelativeDueDate
    ? { clearRelativeDueDate }
    : {
        dateType: dateType as DueDateType,
        relativeDateParams: {
          amount: relativeDateParams?.amount !== undefined ? relativeDateParams.amount : null,
          timeUnit: relativeDateParams?.timeUnit || null,
          timeCondition: relativeDateParams?.timeCondition || null,
          task: relativeDateParams?.task || null
        }
      }
}

export const toTask = (task: LocalTask): Task => {
  const {
    id,
    taskId,
    name,
    relativeDueDate,
    priority,
    taskType,
    description,
    assignees,
    subtasks,
    createdDate,
    createdBy
  } = task

  return {
    id,
    taskId,
    name,
    relativeDueDate: getTaskDueDate(relativeDueDate),
    ...(priority !== undefined
      ? priority === null
        ? { priority: null }
        : {
            priority: {
              id: priority.value,
              name: priority.label,
              color: priority.color
            }
          }
      : {}),
    ...(taskType !== undefined
      ? taskType === null
        ? { taskType: null }
        : {
            taskType: {
              id: taskType.value,
              name: taskType.label
            }
          }
      : {}),
    description,
    assignees,
    children: 0,
    parent: null,
    subtasks,
    createdBy,
    createdDate
  }
}
