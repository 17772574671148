import * as React from 'react'
import TeamSelect from '../TeamSelect/TeamSelect'

import { XeditableCoreContainer } from 'simple-core-ui'

class TeamsEditable extends React.Component {
  state = {
    selected: null
  }

  onChange = selected => {
    this.setState({ selected })
  }

  onConfirm = () => {
    this.props.editableProps.setValueCb(this.state.selected)
    this.setState({ selected: null })
  }

  render() {
    const { selected } = this.state
    const { editableProps, teamSelectProps } = this.props

    return (
      <XeditableCoreContainer
        {...editableProps}
        setValueCb={this.onConfirm}
        renderForm={(SetValueCb, CancelCb) => (
          <TeamSelect {...teamSelectProps} value={selected} onChange={this.onChange} />
        )}
      />
    )
  }
}

export default TeamsEditable
