const toCustomAttributes = attributes =>
  attributes.map(a => ({
    value: a.id,
    label: a.label
  }))

export const toGroupedAttributes = attributes =>
  attributes.map(attr => ({
    label: attr.sectionName,
    options: toCustomAttributes(attr.attributes)
  }))

export const toDynamicAttributes = attributes =>
  attributes.map(a => ({
    ...a,
    id: a.rel_id,
    scope: 'Matter',
    usedOnTemplate: a.used_on_template,
    usedOnMatter: a.used_on_matter
  }))

export const toAttrIds = values =>
  values.filter(l => l?.value !== undefined).map(l => String(l.value))
