import { useState } from 'react'
import { ModalContainer } from 'simple-core-ui'
import s from './TaskTemplateCascadeUpdateModal.scss'
import { AiOutlineMinusCircle } from 'react-icons/ai'
import { MdFindReplace } from 'react-icons/md'
import cn from 'classnames'
import Select, { SingleValue } from 'react-select'
import { makePatchRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { capitalizeStr } from 'utils/formatting'

interface Option {
  value: number
  label: string
  isActive: boolean
}

interface Entity {
  name: string
  relatedTemplates: {
    templateId: number
    taskIds: number[]
  }[]
  id: number
}

interface Props {
  onCancel: () => void
  entity: Entity
  options: Option[]
  refreshTable: () => void
  callMainAction: () => void
  label: string
  getPayload: (value: number | null) => Record<string, any>
}

const TaskTemplateCascadeUpdateModal = ({
  onCancel,
  entity,
  options,
  refreshTable,
  callMainAction,
  label,
  getPayload
}: Props) => {
  const [selected, setSelected] = useState<'' | 'replace' | 'remove'>('')
  const [newEntity, setNewEntity] = useState<Option | null>(null)
  const dispatch = useDispatch()

  const onSave = async (cb: () => void) => {
    try {
      callMainAction()
      await makePatchRequest(
        '/task-management/tasks/bulk-operations/',
        getPayload(newEntity?.value ?? null)
      )
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: 'The tasks were successfully updated.',
          level: 'success'
        }
      })
      cb?.()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      cb?.()
    }
  }

  return (
    <ModalContainer
      title={`${capitalizeStr(label)} is in use on a template`}
      hasNewButtons
      cancelText="Cancel"
      size="sm"
      cancelCb={onCancel}
      confirmText="Save"
      confirmCb={() =>
        onSave(() => {
          refreshTable()
          onCancel()
        })
      }
      isDisabled={!selected || (selected === 'replace' && !newEntity)}
      content={
        <>
          <p style={{ marginBottom: 30 }}>
            {entity.relatedTemplates.length} templates use the <b>{entity.name}</b> task {label}.
            Choose how to update the task {label} in these templates:
          </p>
          <div className={s.flex}>
            <div
              className={cn(s.method, {
                [s.selected]: selected === 'replace'
              })}
              onClick={() => setSelected('replace')}
            >
              <p className={s.header}>
                <MdFindReplace /> Replace {capitalizeStr(label)}
              </p>
              <span>Update template tasks referencing this {label}</span>
            </div>
            <div
              className={cn(s.method, {
                [s.selected]: selected === 'remove'
              })}
              onClick={() => {
                setSelected('remove')
                setNewEntity(null)
              }}
            >
              <p className={s.header}>
                <AiOutlineMinusCircle /> Remove {capitalizeStr(label)}
              </p>
              <span>Update template tasks by removing this {label}</span>
            </div>
          </div>
          {selected === 'replace' && (
            <>
              <div className={s.label}>Choose a replacement {label}:</div>
              <Select
                value={newEntity}
                options={options.filter(p => p.value !== entity.id && p.isActive)}
                className={s.select}
                onChange={(v: SingleValue<Option>) => setNewEntity(v)}
              />
            </>
          )}
        </>
      }
      contentStyle={{ padding: '10px 24px 30px', minHeight: 0 }}
    />
  )
}

export default TaskTemplateCascadeUpdateModal
