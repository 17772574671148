import get from 'lodash/get'

interface Cell {
  columnKey: string
  content: string
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
    case 'editedBy':
      return cellContent.toLowerCase()
    case 'lastEdited':
      return new Date(cellContent).valueOf()
    case 'relatedEvents':
      return +cellContent
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
