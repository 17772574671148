import { FaPencilAlt } from 'react-icons/fa'
import s from './EditableSection.scss'

const EditableContent = ({ content, editContent, pageDescription }) => (
  <h4 ref={pageDescription} className={s.textContainer}>
    <span>{content}</span>
    <FaPencilAlt
      className={s.editButton}
      onClick={e => {
        e.stopPropagation()
        editContent()
      }}
    />
  </h4>
)

export default EditableContent
