import { Component } from 'react'
import { EditableContent } from 'components'

class EditableContentContainer extends Component {
  render() {
    const { content, editContent, pageDescription } = this.props

    return (
      <EditableContent
        pageDescription={pageDescription}
        content={content}
        editContent={editContent}
      />
    )
  }
}

export default EditableContentContainer
