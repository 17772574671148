import { ButtonDropdown, Button } from 'simple-core-ui'

import s from '../styles/FuncForm.scss'

const FuncFormFooter = ({
  options,
  onAddParam,
  onPrimary,
  primaryLabel,
  isPrimaryDisabled,
  showParamDropdown
}) => (
  <section className={s.footer}>
    <Button onClick={onPrimary} isSecondary isDisabled={isPrimaryDisabled}>
      {primaryLabel}
    </Button>
    {showParamDropdown && (
      <ButtonDropdown
        displayText="Add Parameter"
        onSelect={({ value }) => onAddParam(value)}
        options={options}
        isSecondary
      />
    )}
  </section>
)

export default FuncFormFooter
