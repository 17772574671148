import * as React from 'react'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'

import ACT from '../actions'

import ContactAttributes from './ContactAttributes'

import { emptyContact } from '../utils'

const fieldsToChainValues = additionalFields =>
  additionalFields.map(({ id, type, text }) => ({
    id,
    value: {
      option: { label: capitalize(type), value: type },
      text: text
    }
  }))

const checkAttributeChanged = (attributes, contact) =>
  Object.keys(attributes).some(key => attributes[key] !== contact[key])

@connect(({ contacts }) => {
  const { contact, additionalPhones, additionalEmails, additionalFieldsLoading } = contacts

  return {
    contact,
    additionalPhones: fieldsToChainValues(additionalPhones),
    additionalEmails: fieldsToChainValues(additionalEmails),
    isLoading: contact.isLoading || additionalFieldsLoading
  }
})
class ContactAttributesContainer extends React.Component {
  static defaultProps = {
    contact: emptyContact,
    isLoading: true,
    additionalPhones: [],
    additionalEmails: [],
    dispatch: obj => {},
    readOnly: true
  }

  updateAttributes = attributes => {
    const { dispatch, contact } = this.props
    const { id, userId } = contact
    if (checkAttributeChanged(attributes, contact)) {
      dispatch({
        type: ACT.CONTACT_ATTRIBUTES_UPDATE_REQUESTED,
        payload: { ...attributes, id, userId }
      })
    }
  }

  createAdditionalField = (field, value) => {
    const { dispatch, contact } = this.props

    dispatch({
      type: ACT.CONTACT_ADDITIONAL_FIELD_CREATE_REQUESTED,
      payload: { contactId: contact.id, field, value }
    })
  }

  updateAdditionalField = (field, value) => {
    const { dispatch, contact } = this.props

    dispatch({
      type: ACT.CONTACT_ADDITIONAL_FIELD_UPDATE_REQUESTED,
      payload: { contactId: contact.id, field, value }
    })
  }

  deleteAdditionalField = (field, value) => {
    const { dispatch, contact } = this.props

    dispatch({
      type: ACT.CONTACT_ADDITIONAL_FIELD_DELETE_REQUESTED,
      payload: { contactId: contact.id, field, value }
    })
  }

  render = () => {
    const { contact, isLoading, additionalPhones, additionalEmails } = this.props

    return (
      <ContactAttributes
        {...contact}
        additionalPhones={additionalPhones}
        additionalEmails={additionalEmails}
        isLoading={isLoading}
        updateAttributes={this.updateAttributes}
        createAdditionalField={this.createAdditionalField}
        updateAdditionalField={this.updateAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        readOnly={this.props.readOnly}
      />
    )
  }
}

export default ContactAttributesContainer
