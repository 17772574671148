import s from './AdditionalEvaluators.scss'
import { HoverFieldContainer, Condense } from 'simple-core-ui'
import Select from 'react-select'
import Toggle from 'react-toggle'
import { SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE } from '../actions'

const columns = [
  {
    text: 'Vendor Name',
    size: '10%'
  },
  {
    text: 'By Role',
    size: '30%'
  },
  {
    text: 'By Person',
    size: '30%'
  },
  {
    text: 'Survey On?',
    size: '10%'
  }
]

const AdditionalEvaluators = ({
  users,
  roles,
  changeAttributeValue,
  additionalEvaluators,
  loadCb,
  toggleEnableSurvey,
  updateEvaluators,
  readOnly
}) => (
  <div className={s.container}>
    <p className={s.title}>Additional Evaluators</p>
    <div className={s.header}>
      {columns.map((col, i) => (
        <p key={i} style={{ flex: col.size }}>
          {col.text}
        </p>
      ))}
    </div>
    <div className={s.content}>
      {!additionalEvaluators.length && <p className={s.noReferrals}>No referrals to configure</p>}
      {additionalEvaluators.map((referral, i) => (
        <div key={referral.vendor_id} className={s.row}>
          <div className={s.referralName} style={{ flex: columns[0].size }}>
            {referral.vendor_name}
          </div>
          <div style={{ flex: columns[1].size }}>
            <HoverFieldContainer
              onCloseEdit={() => updateEvaluators(i)}
              readOnly={readOnly}
              alwaysShowPencil={i === 0}
              displayValue={
                <Condense
                  values={referral.roles.map(({ label }) => label)}
                  badgeProps={{
                    size: 'sm',
                    asPill: true
                  }}
                />
              }
              renderField={() => (
                <Select
                  autoFocus
                  name="select-additional-roles"
                  placeholder="Search..."
                  noOptionsMessage={() => 'No results...'}
                  value={referral.roles}
                  onChange={value =>
                    changeAttributeValue({
                      value,
                      property: 'roles',
                      type: SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE,
                      index: i
                    })
                  }
                  options={roles}
                  isClearable={false}
                  isMulti
                />
              )}
              isEmpty={!referral.roles.length}
            />
          </div>
          <div
            data-testid={`select_additional_evaluators_${referral.vendor_id}`}
            style={{ flex: columns[1].size }}
          >
            <HoverFieldContainer
              onCloseEdit={() => updateEvaluators(i)}
              alwaysShowPencil={i === 0}
              readOnly={readOnly}
              displayValue={
                <Condense
                  values={referral.users.map(({ label }) => label)}
                  badgeProps={{
                    size: 'sm',
                    asPill: true
                  }}
                />
              }
              renderField={() => (
                <Select
                  autoFocus
                  name="select-additional-evaluators"
                  placeholder="Search..."
                  noOptionsMessage={() => 'No results...'}
                  value={referral.users}
                  onChange={value =>
                    changeAttributeValue({
                      value,
                      property: 'users',
                      type: SURVEY_CONFIG_ADDITIONAL_CHANGE_ATTRIBUTE_VALUE,
                      index: i
                    })
                  }
                  options={users}
                  isClearable={false}
                  onInputChange={loadCb}
                  isMulti
                />
              )}
              isEmpty={!referral.users.length}
            />
          </div>
          <div
            data-testid={`toggle_additional_evaluator_survey_${referral.vendor_id}`}
            style={{ flex: columns[3].size }}
          >
            <Toggle
              checked={referral.status === 'enabled'}
              onChange={e => toggleEnableSurvey(e, i)}
              disabled={readOnly}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default AdditionalEvaluators
