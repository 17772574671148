import { Component } from 'react'
import { VerticalTabs, VerticalTab } from 'simple-core-ui'
import UserManagement from './users/UserManagement'

class TabSettingsContainer extends Component {
  render() {
    return (
      <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <VerticalTabs activeTabKey={'tab1'}>
          <VerticalTab key="tab1" label="User Management">
            <UserManagement />
          </VerticalTab>
        </VerticalTabs>
      </section>
    )
  }
}

export default TabSettingsContainer
