import { Alert } from 'simple-core-ui'
import { AvailableField, FuncOperand } from 'simple_review/@types/api'
import { OperatorByType } from 'simple_review/@types/common'
import { Condition, Lhs } from 'simple_review/@types/list'
import s from './ConditionReadOnly.scss'
import cloneDeep from 'lodash/cloneDeep'
import { titleCase } from 'title-case'
import { FuncLabel } from './FuncLabel'

import {
  getArgFieldMap,
  coordToString,
  translateOperatorProp,
  isUnaryOperator,
  getConstOperandLabel,
  getPath,
  getModelAttrField
} from './helpers'
import { LHS_TYPES } from 'simple_review/utils/constants'

interface Props {
  availableFields: Array<AvailableField>
  condition: Condition
  ruleIndex: number
  operators: OperatorByType
}

interface OpRef {
  [key: string]: {
    condition: Condition
    operand: Condition
    coords: Array<number>
  }
}

export const getFuncPath = (
  { func_name, args }: FuncOperand,
  availableFields: Array<AvailableField>
): JSX.Element => {
  return (
    <FuncLabel
      funcName={func_name}
      funcArgs={args}
      argFieldMap={getArgFieldMap(args, availableFields)}
    />
  )
}

export const getLabel = (
  lhs: Lhs,
  availableFields: Array<AvailableField>
): string | JSX.Element => {
  const fullModelField = getModelAttrField(lhs, availableFields)

  return lhs.operand_type === LHS_TYPES.FUNC_CALL
    ? getFuncPath(lhs, availableFields)
    : getPath(fullModelField)
}

const renderOperandTree = (
  opRef: OpRef,
  condition: Condition,
  ruleIndex: number,
  currentLevel: number,
  currentCoords: Array<number>,
  availableFields: Array<AvailableField>,
  operators: OperatorByType
) => {
  const outerGutterWidth = 50

  const styles = {
    list: {
      paddingLeft: outerGutterWidth,
      cursor: 'pointer'
    },
    connector: {
      width: outerGutterWidth
    }
  }
  const options = condition.operands?.map((op: Condition, i: number) => {
    let innerOperands
    const coordCopy = cloneDeep(currentCoords)
    coordCopy[currentLevel] = i
    const str = coordToString(coordCopy)
    opRef[str] = { condition, operand: op, coords: coordCopy }

    const innerGutterWidth = 40

    const innerStyles = {
      list: {
        paddingLeft: innerGutterWidth,
        cursor: 'default'
      },
      connector: {
        width: innerGutterWidth
      }
    }

    if (op.operands && op.operands.length >= 1) {
      innerOperands = renderOperandTree(
        opRef,
        op,
        ruleIndex,
        currentLevel + 1,
        coordCopy,
        availableFields,
        operators
      )
    }

    if (op.lhs) {
      const operand = opRef[str].operand
      return (
        <ul key={`operand_${i}-${str}`} style={innerStyles.list} className={s.operandsList}>
          {currentLevel === 0 && condition.operands?.length === 1 ? null : (
            <span className={s.connector} style={innerStyles.connector} />
          )}

          {i === (condition.operands?.length ?? 0) - 1 ? (
            <div className={s.bottomConnectMask} />
          ) : null}
          {i === 0 ? <div className={s.topConnectMask} /> : null}

          <li
            className={s.operandContainer}
            data-testid={`condition-read-only-${ruleIndex}-${i}-${str}`}
          >
            <div className={s.lhs}>{getLabel(op.lhs, availableFields)}</div>
            <div className={s.operator}>
              {translateOperatorProp(operand.op, op.lhs, availableFields, operators)}
            </div>
            {operand.op && !isUnaryOperator(operand.op) && (
              <div className={s.rhs}>{getConstOperandLabel(op.rhs)}</div>
            )}
          </li>
        </ul>
      )
    }
    // return innerOperands
    return (
      <div
        key={`operand_${i}-${str}`}
        style={innerStyles.list}
        className={`${s.conditionContainer}`}
      >
        {i === (condition.operands?.length ?? 0) - 1 ? (
          <div className={s.bottomConnectConditionMask} />
        ) : null}
        {i === 0 ? <div className={s.topConnectMask} /> : null}

        {innerOperands}
      </div>
    )
  })

  const coordCopyCond = cloneDeep(currentCoords)
  coordCopyCond[currentLevel] = 0
  const condStr = coordToString(coordCopyCond)

  const initialValue = { value: condition.op, label: titleCase(condition.op?.toLowerCase() ?? '') }

  return (
    <div className={s.innerConditionContainer}>
      {Object.keys(opRef).length > 1 && (
        <div className={s.booleanOperator}>
          {condStr !== 'op_0' && <span className={s.conditionConnector} style={styles.connector} />}
          <>
            <div className={s.booleanOperator}>{initialValue.label}</div>
            <span className={s.conditionConnector2} />
          </>
        </div>
      )}
      <div className={s.options}>{options}</div>
    </div>
  )
}

const ConditionReadOnly = ({ operators, availableFields, condition, ruleIndex }: Props) => {
  const coords = [0]
  const opRef = {}
  if (!condition) {
    return (
      <Alert
        message="This rule is unconditional and will run under all circumstances."
        status="info"
        style={{ alignSelf: 'flex-start' }}
      />
    )
  }
  return renderOperandTree(opRef, condition, ruleIndex, 0, coords, availableFields, operators)
}

export default ConditionReadOnly
