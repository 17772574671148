import * as React from 'react'
import { ConditionalRender, Button } from 'simple-core-ui'
import { SELECT_TEMPLATE_FLOW } from 'matters/templates/constants'
import { canSubmitDrawer } from 'matters/templates/utils'

import s from './TemplateActions.scss'

const TemplateActions = ({
  attributes,
  userFlowState,
  updateMatterForNewTemplate,
  archived,
  selectTemplate,
  navigateToFormPage,
  navigateToRequestsList,
  navigateToFormPageWithoutIds,
  rejectLegalRequest,
  hideRejectButton
}) => {
  const invalid = canSubmitDrawer(attributes)
  const title = invalid ? 'All required values on the right hand panel must have values.' : ''

  return (
    <section className={s.container}>
      <ConditionalRender
        conditions={[
          {
            condition:
              userFlowState.userFlow !==
                SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION &&
              userFlowState.userFlow !== SELECT_TEMPLATE_FLOW.MATTER_REQUEST &&
              !archived,
            content: (
              <Button
                onClick={
                  userFlowState.userFlow !== SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
                    ? selectTemplate
                    : updateMatterForNewTemplate
                }
                // only validate requirements on change template since draft
                // matters don't need all requirements satisfied
                isDisabled={
                  userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE && invalid
                }
                title={title}
                isPrimary
                testid="create_matter_btn"
              >
                {userFlowState.userFlow === SELECT_TEMPLATE_FLOW.CHANGE_MATTER_TEMPLATE
                  ? 'Update'
                  : 'Create'}
              </Button>
            )
          },
          {
            condition:
              userFlowState.userFlow === SELECT_TEMPLATE_FLOW.LEGAL_REQUEST_FORM_TEMPLATE_SELECTION,
            content: (
              <React.Fragment>
                <Button key="1" onClick={navigateToFormPageWithoutIds} isSecondary>
                  Cancel
                </Button>
                <Button
                  key="2"
                  onClick={navigateToFormPage}
                  isPrimary
                  testid="select_form_templates"
                >
                  Set Form Templates
                </Button>
              </React.Fragment>
            )
          }
        ]}
        fallback={
          <React.Fragment>
            <Button key="1" onClick={navigateToRequestsList} isSecondary>
              Cancel
            </Button>
            {!hideRejectButton && (
              <Button key="2" onClick={rejectLegalRequest} isSecondary>
                Reject
              </Button>
            )}
            <Button
              key="3"
              title={title}
              isDisabled={invalid}
              onClick={selectTemplate}
              isPrimary
              testid="approve_legal_request_button"
            >
              Create
            </Button>
          </React.Fragment>
        }
      />
    </section>
  )
}

export default TemplateActions
