import {
  useLoading,
  Button,
  AvatarList,
  Tabs,
  Tab,
  useScrollLock,
  Ellipsis,
  Markdown,
  CharLimitInput,
  RichTextEditor,
  Users
} from 'simple-core-ui'
import { useState, useEffect, useMemo, Dispatch, SetStateAction, useRef } from 'react'
import s from './AddTask.scss'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { toLocalTask, toTask } from './serializers'
import { toTasks, toPrioritiesOptions, toTaskTypesOptions } from '../serializers'
import { getDueDateLabel, sortByTaskId } from './utils'
import { ancestorsOf } from '../utils'
import { Task, LocalTask, Subtask, InitialTask, DueDateValue } from '../types'
import { Widget } from 'common/Widget'
import moment from 'moment'
import { FaRegCalendarCheck, FaAngleLeft } from 'react-icons/fa'
import { BsPersonPlus, BsEye } from 'react-icons/bs'
import { Subtasks } from 'common/Subtasks'
import { RelativeDueDatePicker } from 'common/RelativeDueDatePicker'
import { Overview } from './Overview'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { MAX_NAME_LENGTH, MAX_DESCRIPTION_LENGTH } from './constants'
import { TASK_OPTIONS_ENDPOINTS } from '../constants'

interface Props {
  toggleAddTaskSidebar: () => void
  tasks: Task[]
  task: Task | null
  oldTask: Task | null
  saveTask: (task: Task, isEdit?: boolean, callback?: (task?: Task) => void) => void
  onDeleteTask: (id: number) => void
  setTasks: Dispatch<SetStateAction<Task[]>>
  onEditSubtask: (subtask: Subtask) => void
  duplicateTask: (subtask: Task | Subtask, parentId?: number | string) => Promise<void>
  fetchTemplate: () => void
}

interface Option {
  value: number
  label: string
  color?: string
  occurredDate?: string
  phase?: string
  isActive?: boolean
}

const initialTask: InitialTask = {
  name: '',
  description: '',
  priority: null,
  taskType: null,
  relativeDueDate: undefined,
  assignees: [],
  subtasks: [],
  parent: null,
  createdDate: '',
  createdBy: null
}

const AddTask = ({
  toggleAddTaskSidebar,
  tasks,
  task: editedTask,
  oldTask,
  saveTask,
  onDeleteTask,
  setTasks,
  onEditSubtask,
  duplicateTask,
  fetchTemplate
}: Props) => {
  const [task, setTask] = useState(toLocalTask(editedTask) || initialTask)
  const [, withLoadingLocks] = useLoading()
  const [priorities, setPriorities] = useState<Option[]>([])
  const [taskTypes, setTaskTypes] = useState<Option[]>([])
  const [prevTask, setPrevTask] = useState<LocalTask | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const dispatch = useDispatch()
  const [editedProperties, setEditedProperties] = useState<Partial<LocalTask>>({})
  const [activeTab, setActiveTab] = useState('overview')
  const [prevActiveTab, setPrevActiveTab] = useState('')
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false)
  const { lockScroll, unlockScroll } = useScrollLock()
  const [nameInputFocused, setNameInputFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  if (activeTab && prevActiveTab !== activeTab) {
    setPrevActiveTab(activeTab)
  }

  const getTabIndex = () => {
    return activeTab === 'overview' ? 0 : null
  }

  const selectOptions = useMemo(() => {
    return {
      priorities: priorities.filter(priority => priority.isActive),
      taskTypes: taskTypes.filter(taskType => taskType.isActive)
    }
  }, [priorities, taskTypes])

  const resetEditedProperties = () => {
    setEditedProperties({
      id: editedTask?.id
    })
  }

  if (
    editedTask &&
    (prevTask?.id !== editedTask.id || prevTask.subtasks.length !== editedTask.subtasks.length)
  ) {
    setTask(toLocalTask(editedTask) as LocalTask)
    setPrevTask(toLocalTask(editedTask))
    if (editedTask.id) {
      setIsEdit(true)
      resetEditedProperties()
    }
  }

  useEffect(() => {
    lockScroll()
    return () => {
      unlockScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchOptions() {
      const priorities = await withLoadingLocks(makeGetRequest(TASK_OPTIONS_ENDPOINTS.PRIORITIES))
      setPriorities(toPrioritiesOptions(priorities))

      const { rows: taskTypes } = await withLoadingLocks(
        makeGetRequest(TASK_OPTIONS_ENDPOINTS.TASK_TYPES)
      )
      setTaskTypes(toTaskTypesOptions(taskTypes))
    }

    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTask = (
    value: string | null | Option | Option[] | boolean | undefined | Subtask[] | DueDateValue,
    property: string
  ) => {
    setTask({ ...task, [property]: value })
    if (isEdit) {
      setEditedProperties({
        id: editedTask?.id,
        [property]: value
      })
    }
  }

  const isValid = (): boolean => {
    return (
      task.name[0] !== ' ' &&
      !!task.name.trim() &&
      !isDescriptionTooLong &&
      task.name.length <= MAX_NAME_LENGTH
    )
  }

  const getUsersLabel = (prop: 'assignees'): string | JSX.Element => {
    switch (task[prop]?.length) {
      case 0:
        return ''
      case 1:
        return task[prop][0].label
      default:
        return (
          <AvatarList
            limit={3}
            size="xs"
            wrapperStyles={{ width: 24 }}
            avatarStyles={{ background: '#0957ae', marginLeft: 0, fontSize: 9 }}
            entries={sortAlphabeticallyByProperty(task[prop], 'label')}
          />
        )
    }
  }

  const getUsersIcon = (prop: 'assignees'): JSX.Element => {
    if (task[prop].length === 1) {
      return (
        <AvatarList
          size="md"
          wrapperStyles={{
            width: 36,
            position: 'relative',
            bottom: 2,
            right: 2
          }}
          className={s.avatar}
          entries={[task[prop][0]]}
          avatarStyles={{ border: '2px solid #0957ae', lineHeight: '30px' }}
        />
      )
    }

    return prop === 'assignees' ? <BsPersonPlus /> : <BsEye />
  }

  const addAssignees = async (values: Option[]) => {
    try {
      const task = await withLoadingLocks(
        makePostRequest(`/task-management/tasks/${editedTask?.id}/assignees/`, {
          user_ids: values.map(v => v.value)
        })
      )

      const index = tasks.findIndex(t => t.id === task.id)

      if (~index) {
        setTasks((draft: Task[]) => {
          draft[index].assignees = toTasks([task])[0].assignees
          return draft
        })
      }

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Task ${task.name} successfully edited.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const errorObject = (task: InitialTask | LocalTask) => {
    let hasError = false
    let errorMessage = 'Maximum character limit reached'

    if (isEdit && !task.name) {
      hasError = true
      errorMessage = 'Name is required.'
    }
    if (task.name && task.name[0] === ' ') {
      hasError = true
      errorMessage = 'Name cannot begin with a space.'
    }

    return { hasError, errorMessage }
  }

  const tasksAsOptions = useMemo(() => {
    return tasks
      .filter(({ relativeDueDate }) => !!relativeDueDate)
      .map(({ id: value, name: label, taskId }) => ({ value, label, taskId }))
  }, [tasks])

  const relativeTasksList = useMemo(() => {
    if (!editedTask?.id) return tasksAsOptions
    const forbiddenSelections = [editedTask.id, ...ancestorsOf(tasks, editedTask.id)]
    return tasksAsOptions.filter(({ value }) => !forbiddenSelections.includes(value))
  }, [editedTask?.id, tasksAsOptions, tasks])

  const canClearRelativeDate = useMemo(() => {
    if (!editedTask?.id) return true
    return !ancestorsOf(tasks, editedTask.id).length
  }, [editedTask?.id, tasks])

  return (
    <>
      <div className={s.container}>
        <div
          className={cn(s.header, {
            [s.error]: nameInputFocused && task.name.length > MAX_NAME_LENGTH
          })}
        >
          <CharLimitInput
            value={task.name}
            placeholder="Add task name*"
            onChangeCb={e => updateTask(e.target.value, 'name')}
            maxLength={MAX_NAME_LENGTH}
            hideInfoText={!nameInputFocused}
            hasError={nameInputFocused && errorObject(task).hasError}
            customErrorMessage={nameInputFocused ? errorObject(task).errorMessage : undefined}
            style={{ width: '100%', outline: 'none !important', padding: 5 }}
            cssClass={cn(s.nameInput, {
              [s.inputNoBorders]: task.name,
              [s.focused]: nameInputFocused
            })}
            dynamicCharCalculation
            sectionStyles={{ width: '95%' }}
            onFocus={() => setNameInputFocused(true)}
            focused={!isEdit}
            refObject={inputRef}
            onBlur={() => {
              if (isEdit) {
                if (editedProperties.name?.trim() && editedProperties.name !== oldTask?.name) {
                  saveTask(toTask(editedProperties as LocalTask), isEdit, () => {
                    resetEditedProperties()
                    if (task.subtasks.length) {
                      fetchTemplate()
                    }
                  })
                }
              }
              setNameInputFocused(false)
            }}
          />
          <div className={s.close} data-testid="close" onClick={toggleAddTaskSidebar}>
            &times;
          </div>
          {isEdit && (
            <div className={s.info}>
              <span>
                {task.parent ? (
                  <span onClick={() => onEditSubtask(task.parent as Subtask)}>
                    <span className={s.parentLink}>
                      <FaAngleLeft />{' '}
                      <Ellipsis width={200} lines={1} className={s.inline}>
                        {task.parent.name}
                      </Ellipsis>{' '}
                    </span>
                    <span style={{ margin: '0 5px' }}>&#8226;</span>
                  </span>
                ) : (
                  ''
                )}{' '}
                {task.taskId}
                <span style={{ margin: '0 5px' }}>&#8226;</span>
                Created {moment(task.createdDate).format('MMM DD, YYYY')}{' '}
                <span style={{ margin: '0 5px' }}>&#8226;</span> Created by{' '}
                {task.createdBy?.label ?? ''}
              </span>
            </div>
          )}
        </div>
        <div
          className={cn(s.content, {
            [s.contentEdit]: isEdit
          })}
        >
          <div className={s.widgets}>
            <Widget
              label="ASSIGNEE(S)"
              value={getUsersLabel('assignees')}
              icon={getUsersIcon('assignees')}
            >
              <Users
                value={task.assignees}
                style={{ width: '400px' }}
                requestParams={{ active: true }}
                onConfirm={(values: Option[]) => {
                  updateTask(values, 'assignees')
                  if (isEdit) {
                    addAssignees(values)
                  }
                }}
              />
            </Widget>
            <Widget
              icon={<FaRegCalendarCheck />}
              label="DUE DATE"
              value={getDueDateLabel(task.relativeDueDate)}
            >
              <RelativeDueDatePicker
                value={task.relativeDueDate}
                tasks={relativeTasksList}
                canClear={canClearRelativeDate}
                onConfirm={value => {
                  const { dateType, relativeDateParams, clearRelativeDueDate } = value
                  updateTask(
                    { dateType, relativeDateParams, ...(isEdit && { clearRelativeDueDate }) },
                    'relativeDueDate'
                  )
                  if (isEdit) {
                    saveTask(
                      toTask({
                        id: editedProperties.id,
                        relativeDueDate: value || undefined
                      } as LocalTask),
                      isEdit,
                      () => {
                        resetEditedProperties()
                      }
                    )
                  }
                }}
              />
            </Widget>
          </div>
          <label className={s.label}>Description</label>
          {isEdit && !showDescription && (
            <>
              {task.description ? (
                <div className={s.description} onClick={() => setShowDescription(!showDescription)}>
                  <Markdown value={task.description} />
                </div>
              ) : (
                <div
                  className={s.descriptionPlaceholder}
                  onClick={() => setShowDescription(!showDescription)}
                >
                  Add description...
                </div>
              )}
            </>
          )}
          {(!isEdit || showDescription) && (
            <RichTextEditor
              value={task.description ?? ''}
              onChange={value => {
                updateTask(value, 'description')
              }}
              onBlur={
                !isEdit
                  ? undefined
                  : () => {
                      editedProperties.description !== oldTask?.description &&
                        !isDescriptionTooLong &&
                        saveTask(toTask(editedProperties as LocalTask), isEdit, () => {
                          resetEditedProperties()
                        })
                      setShowDescription(false)
                    }
              }
              maxLength={MAX_DESCRIPTION_LENGTH}
              dynamicCharCalculation
              validation={setIsDescriptionTooLong}
              customErrorMessage="Maximum character limit reached"
            />
          )}
          {isEdit ? (
            <>
              <Tabs selectedIndex={getTabIndex()}>
                {/* @ts-expect-error */}
                <Tab
                  selected={activeTab === 'overview'}
                  header="Overview"
                  onClickCb={() => {
                    setActiveTab('overview')
                  }}
                >
                  <Overview
                    priorities={selectOptions?.priorities ?? []}
                    taskTypes={selectOptions?.taskTypes ?? []}
                    task={task}
                    updateTask={updateTask}
                    saveTask={saveTask}
                    isEdit={isEdit}
                    editedProperties={editedProperties}
                    resetEditedProperties={resetEditedProperties}
                  />
                </Tab>
                {!task.parent && (
                  // @ts-expect-error
                  <Tab
                    selected={activeTab === 'subtasks'}
                    header="Subtasks"
                    count={task.subtasks.filter(s => !s.draft).length || undefined}
                    onClickCb={() => {
                      setActiveTab('subtasks')
                    }}
                  >
                    <Subtasks
                      key={(task as LocalTask).id}
                      parentId={(task as LocalTask).id}
                      value={sortByTaskId(task.subtasks)}
                      customDatePicker={{
                        render: (value, onConfirm) => (
                          <RelativeDueDatePicker
                            value={value}
                            canClear={canClearRelativeDate}
                            tasks={tasksAsOptions}
                            onConfirm={onConfirm}
                          />
                        ),
                        props: { customLabel: getDueDateLabel }
                      }}
                      onSave={value => {
                        updateTask(value, 'subtasks')
                        saveTask(
                          toTask({
                            ...(isEdit
                              ? {
                                  id: editedProperties.id
                                }
                              : editedProperties),
                            subtasks: value
                          } as LocalTask),
                          isEdit,
                          (task?: Task) => {
                            updateTask(
                              [
                                ...(task?.subtasks ?? []),
                                ...(value as Subtask[]).filter(v => v.draft)
                              ],
                              'subtasks'
                            )
                            resetEditedProperties()
                          }
                        )
                      }}
                      onDelete={onDeleteTask}
                      duplicateTask={duplicateTask}
                      onEditSubtask={(subtask: Subtask) => {
                        onEditSubtask(subtask)
                        setActiveTab('overview')
                      }}
                      isEdit={isEdit}
                      canMarkCompleted={false}
                    />
                  </Tab>
                )}
              </Tabs>
            </>
          ) : (
            <>
              {!task.parent && (
                <Subtasks
                  value={task.subtasks}
                  canMarkCompleted={false}
                  customDatePicker={{
                    render: (value, onConfirm) => (
                      <RelativeDueDatePicker
                        value={value}
                        canClear={canClearRelativeDate}
                        tasks={tasksAsOptions}
                        onConfirm={onConfirm}
                      />
                    ),
                    props: { customLabel: getDueDateLabel }
                  }}
                  onSave={value => updateTask(value, 'subtasks')}
                />
              )}
              <Overview
                priorities={selectOptions?.priorities ?? []}
                taskTypes={selectOptions?.taskTypes ?? []}
                task={task}
                updateTask={updateTask}
              />
            </>
          )}
        </div>
        {!isEdit && (
          <div className={s.footer}>
            <Button onClick={toggleAddTaskSidebar} isPrimary isOutline hasNewDesign>
              Cancel
            </Button>
            <Button
              onClick={() => saveTask(toTask(task as LocalTask))}
              isPrimary
              isDisabled={!isValid()}
              hasNewDesign
            >
              Save Task
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default AddTask
