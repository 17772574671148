import { useState } from 'react'
import { TextInput, ModalContainer, useLoading } from 'simple-core-ui'
import s from './ConnectModal.scss'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { NavigateAwayModal } from './NavigateAwayModal'
import { makePostRequest } from 'utils/api'
import { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { ConfirmModal } from './ConfirmModal'

interface Props {
  toggleConnectModal: () => void
  onConnect: () => void
}

const ConnectModal = ({ toggleConnectModal, onConnect }: Props) => {
  const [token, setToken] = useState('')
  const [error, setError] = useState(false)
  const [isNavigateAwayModalVisible, setIsNavigateAwayModalVisible] = useState(false)
  const dispatch = useDispatch()
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isLoading, withLoadingLocks, resetLoadingLocks] = useLoading()

  const toggleNavigateAwayModal = () => {
    setIsNavigateAwayModalVisible(!isNavigateAwayModalVisible)
  }

  const saveToken = async () => {
    try {
      await withLoadingLocks(
        makePostRequest('/event-management/calendar_rules/settings', { token })
      )

      onConnect()
      toggleConnectModal()

      resetLoadingLocks()
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 400) {
        setError(true)
      } else {
        dispatch({ type: 'API_ERROR', error })
      }
    }
  }

  const toggleConfirmModal = () => {
    setIsConfirmModalVisible(!isConfirmModalVisible)
  }

  return (
    <>
      <ModalContainer
        title="Connect CalendarRules"
        subtitle={
          <div style={{ marginTop: 5 }}>
            <AiOutlineInfoCircle className={s.info} /> Court Rule sets are populated through
            CalendarRules a third-party application.
          </div>
        }
        hasNewButtons
        size="md"
        cancelCb={!isLoading && toggleConnectModal}
        hideCancelBtn
        shouldModalClose={false}
        confirmText="Continue"
        confirmCb={!isLoading ? toggleConfirmModal : () => {}}
        isDisabled={token.trim().length === 0}
        content={
          <div className={s.statusItem}>
            <p style={{ fontSize: 14, marginBottom: 5 }}>CalendarRules Token:</p>
            <TextInput
              testid="token-input"
              value={token}
              onChange={value => setToken(value)}
              placeholder="Enter your token here"
              containerClassName={error ? s.tokenInput : undefined}
            />
            {error && <span className="error">Please enter a valid token.</span>}
            <p onClick={toggleNavigateAwayModal} className={s.requestToken}>
              Request a token{' '}
              <BsBoxArrowUpRight style={{ marginLeft: 5, position: 'relative', top: 2 }} />
            </p>
          </div>
        }
      />
      {isNavigateAwayModalVisible && (
        <NavigateAwayModal toggleNavigateAwayModal={toggleNavigateAwayModal} />
      )}
      {isConfirmModalVisible && (
        <ConfirmModal toggleConfirmModal={toggleConfirmModal} onConfirm={saveToken} />
      )}
    </>
  )
}

export default ConnectModal
