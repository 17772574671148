import { useState, useEffect } from 'react'
import cn from 'classnames'
import { Button, Ellipsis } from 'simple-core-ui'
import Grid from 'simple-core-ui/components/Core/Grid/Grid'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import allowedExtensions from 'simple-core-ui/data/allowedExtensions'
import { FileContainer } from './FileContainer'
import { BsCloudUpload } from 'react-icons/bs'
import s from '../FileUpload.scss'
import ReactTooltip from 'react-tooltip'
import { getFileNameExtension } from 'utils/helpers'
import { FILE_STATUS_CLEAN, FILE_STATUS_UPLOADED } from '../constants'

import groupBy from 'lodash/groupBy'
import { IoWarningOutline } from 'react-icons/io5'

const { Column, Row } = Grid

const FILE_SIZE_LIMIT = 262144000
const NUM_FILES_LIMIT = 100
const BTN_SELECT_LABEL = 'Select Files from Computer'
const SUPPORTED_FILES_URL = '/help/?article_id=4416830092055'

const { virus_scan_enabled: virusScanEnabled = false } = window.serverContext.all()

const DropzoneWrapperHorizontal = ({ isDragging, getRootProps, getInputProps }) => {
  return (
    <div className={s.dropzoneWrapper}>
      <div
        data-testid="dropzone"
        {...getRootProps({ className: cn(s.dropzone, { [s.onDrag]: isDragging }) })}
      >
        <input {...getInputProps()} />
        <Button style={{ width: 120 }} isPrimary>
          {BTN_SELECT_LABEL}
        </Button>

        <div style={{ lineHeight: '68px' }} className={s.or}>
          <span>Or</span>
        </div>
        <div style={{ lineHeight: '68px', fontSize: 16 }}>
          Drag and drop files <BsCloudUpload className={s.uploadIcon} />
        </div>
      </div>
      <div className={s.supportedFilesLabel}>
        <a href={SUPPORTED_FILES_URL} target="_blank" rel="noreferrer">
          View
        </a>{' '}
        supported file types
      </div>
    </div>
  )
}

const DropzoneWrapperVertical = ({ isDragging, getRootProps, getInputProps }) => {
  return (
    <div className={s.dropzoneWrapper}>
      <div
        data-testid="dropzone"
        {...getRootProps({ className: cn(s.dropzoneVertical, { [s.onDrag]: isDragging }) })}
      >
        <input {...getInputProps()} />
        <Button className={s.fileButton} isPrimary>
          {BTN_SELECT_LABEL}
        </Button>

        <div className={s.or}>
          <span>Or</span>
        </div>
        <div className={s.uploadIconLabel}>Drag and drop files</div>
        <div>
          <BsCloudUpload style={{ fontSize: 48 }} />
        </div>
      </div>
    </div>
  )
}

const FileErrorMsg = ({ msg, children }) => {
  return (
    <div className={s.extensionErrorContainer}>
      {typeof msg === 'string' ? <p className={s.errorTitle}>{msg}</p> : msg}
      <div className={s.errorFileNames}>
        <Ellipsis>{children}</Ellipsis>
      </div>
    </div>
  )
}

const DuplicateFiles = ({ duplicateFiles }) => {
  const numberOfDuplicates = Object.values(duplicateFiles).reduce((total, filesGroupedByName) => {
    return total + (filesGroupedByName.length - 1)
  }, 0)
  return (
    <FileErrorMsg
      msg={
        <div className={s.errorTitleWrapper}>
          <IoWarningOutline size={20} />
          <p className={s.errorTitle}>
            DUPLICATE: {numberOfDuplicates} file(s) with duplicate names exist - pre-existing files
            will be overwritten and only the most recent file will be uploaded.
          </p>
        </div>
      }
    />
  )
}

const ExtNotAllowed = ({ value }) => {
  const fileExtensions = value.map(f => {
    return getFileNameExtension(f?.file?.name)
      .extension.toUpperCase()
      .replace(/\./g, '')
  })
  const fileExtensionStr = Array.from(new Set(fileExtensions))
    .filter(Boolean)
    .join(', ')

  return (
    <FileErrorMsg
      msg={
        <div className={s.errorTitleWrapper}>
          <IoWarningOutline size={20} />
          <p className={s.errorTitle}>
            INVALID FILE TYPE: These file types are not supported -{' '}
            <strong>{fileExtensionStr}.</strong>
          </p>
        </div>
      }
    />
  )
}

const RejFiles = ({ rejectedFiles }) => {
  const groupedFiles = groupBy(rejectedFiles, f => {
    if (!f.errors) {
      return 'Failed to upload'
    }

    return f.errors[0]?.code
  })

  return Object.entries(groupedFiles).map(([code, value]) => {
    if (code === 'ext-not-allowed') return <ExtNotAllowed key={code} value={value} />

    const fileNameStr = value
      .map(f => {
        return f?.file?.name
      })
      .filter(Boolean)
      .join(', ')

    const msg = value[0].errors[0]?.message

    return (
      <FileErrorMsg key={code} msg={msg}>
        {fileNameStr}
      </FileErrorMsg>
    )
  })
}

const Dropzone = ({
  attachments,
  filesToUpload,
  setFilesToUpload,
  rejectedFiles,
  setRejectedFiles,
  lifespan,
  willOverwriteDuplicates = false
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedBatchLength, setUploadedBatchLength] = useState(0)
  const [canCancelFilesArr, setCanCancelFilesArr] = useState([])
  const [duplicateFiles, setDuplicateFiles] = useState(null)

  const allowedFileStatusForResharing = [
    FILE_STATUS_CLEAN,
    ...(virusScanEnabled ? [] : [FILE_STATUS_UPLOADED])
  ]

  const setFileCompleted = (file, key) => {
    setFilesToUpload(draft => {
      const index = draft.findIndex(f => f.id === file.id)
      if (index !== -1) {
        draft[index].completed = true
        draft[index].processing = false
        draft[index].url = key
      }
    })
  }

  const removeRejectedFile = file => {
    const index = rejectedFiles.findIndex(f => f.file?.id === file.id || f.file?.name === file.name)
    if (index !== -1) {
      setRejectedFiles(draft => {
        draft.splice(index, 1)
      })
    }
  }

  const removeFile = (index, file) => {
    removeRejectedFile(file)

    setFilesToUpload(draft => {
      draft.splice(index, 1)
    })
  }

  const getDupFileMap = files => {
    const dupFileMap = groupBy(files, f => f.name)

    // remove non duplicated names
    Object.keys(dupFileMap).forEach(key => {
      if (dupFileMap[key].length <= 1) {
        delete dupFileMap[key]
      }
    })

    return dupFileMap
  }

  const getFileName = file => {
    if ('file_name' in file) {
      return file.file_name
    }
    return file.fileName.concat(file.fileType)
  }

  const handleDupsToOverwrite = filesToCheck => {
    let dups = []

    if (!willOverwriteDuplicates) {
      return dups
    }

    for (let i = 0; i < filesToCheck.length; i++) {
      const f = filesToCheck[i]
      const originalFile = attachments.find(a => getFileName(a) === f.name)

      if (originalFile) {
        dups.push(f)

        // set flag on the file to tell backend to overwrite
        setFilesToUpload(draft => {
          draft[i].will_overwrite_file = true
          draft[i].reshare =
            allowedFileStatusForResharing.includes(originalFile.fileStatus) &&
            originalFile.share?.length
        })
      }
    }
    return dups
  }

  useEffect(() => {
    const dupMap = getDupFileMap(filesToUpload)
    const dupsToOverwrite = handleDupsToOverwrite(filesToUpload)
    if (dupsToOverwrite.length) {
      setDuplicateFiles(
        getDupFileMap(
          [
            attachments.map(f => ({
              name: getFileName(f)
            })),
            filesToUpload
          ].flat()
        )
      )
    } else if (Object.keys(dupMap).length) {
      setDuplicateFiles(dupMap)
    } else {
      setDuplicateFiles(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload])

  const onDrop = async acceptedFiles => {
    setIsDragging(false)
    setUploadedBatchLength(acceptedFiles.length)
    const limitedFiles = acceptedFiles.slice(0, NUM_FILES_LIMIT - filesToUpload.length)

    setFilesToUpload(draft => {
      limitedFiles.forEach(f =>
        draft.push({
          name: f.name,
          path: f.path,
          size: f.size,
          type: f.type,
          id: uuidv4(),
          lastModified: f.lastModified,
          lastModifiedDate: f.lastModifiedDate,
          completed: false,
          processing: true,
          url: null,
          file: f
        })
      )
    })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [rejectedFiles])

  const setFileError = (file, index) => {
    setFilesToUpload(draft => {
      draft[index].processing = false
    })
    setRejectedFiles(draft => {
      draft.push(file)
    })
  }

  const onDropRejected = newRejFiles => {
    setRejectedFiles(rejectedFiles.concat(newRejFiles))
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    validator: file => {
      if (file instanceof DataTransferItem) {
        return null
      }

      const { extension } = getFileNameExtension(file.name)

      if (file.size > FILE_SIZE_LIMIT) {
        return {
          code: 'too-big',
          message: `File size is greater than 250MB`
        }
      }

      if (file.size <= 0) {
        return {
          code: 'empty',
          message: `File is blank`
        }
      }

      if (extension && !allowedExtensions().includes(extension.slice(1))) {
        return {
          code: 'ext-not-allowed',
          message: `Extension is not allowed`
        }
      }
      return null
    }
  })

  const isDuplicate = file => {
    if (Boolean(duplicateFiles) && file.name in duplicateFiles) {
      const index = duplicateFiles[file.name].findIndex(f => f.id === file.id)
      return index > 0
    }
    return false
  }

  if (!filesToUpload.length) {
    return (
      <>
        {!!rejectedFiles.length && <RejFiles rejectedFiles={rejectedFiles} />}
        <DropzoneWrapperHorizontal
          rejectedFiles={rejectedFiles}
          isDragging={isDragging}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
        />
        <ReactTooltip effect="solid" type="light" className={s.tooltipPopup} />
      </>
    )
  } else {
    return (
      <>
        {!!rejectedFiles.length && <RejFiles rejectedFiles={rejectedFiles} />}
        {!!duplicateFiles && <DuplicateFiles duplicateFiles={duplicateFiles} />}
        <Row className={s.files}>
          <Column span={4}>
            <DropzoneWrapperVertical
              rejectedFiles={rejectedFiles}
              isDragging={isDragging}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
            />
            <div className={s.supportedFilesLabel}>
              <a href={SUPPORTED_FILES_URL} target="_blank" rel="noreferrer">
                View
              </a>{' '}
              supported file types
            </div>
          </Column>
          <Column span={8}>
            <section className={s.attachmentContainer}>
              <div className={s.attachmentHeader}>
                <span>Attachments:</span>
                <span>
                  {filesToUpload.filter(f => f.completed).length}/{filesToUpload.length}
                </span>
              </div>
              {filesToUpload.map((file, i) => {
                return (
                  <FileContainer
                    lifespan={lifespan}
                    setFileError={setFileError}
                    setCanCancelFilesArr={setCanCancelFilesArr}
                    setFileCompleted={setFileCompleted}
                    removeFile={removeFile}
                    file={file}
                    index={i}
                    key={file.id}
                    isDuplicate={isDuplicate(file)}
                  />
                )
              })}
            </section>
          </Column>
          <ReactTooltip effect="solid" type="light" className={s.tooltipPopup} />
        </Row>
      </>
    )
  }
}

export default Dropzone
