import classNames from 'classnames'
import { Button, OutsideClickContainer } from 'simple-core-ui'

import s from './Confirmable.scss'

const Confirmable = ({
  isPrompted,
  openPrompt,
  promptText,
  confirmText,
  cancelText,
  confirmCallback,
  cancelCallback,
  render
}) => (
  <OutsideClickContainer
    closeComponent={isPrompted ? cancelCallback : () => {}}
    styles={{ width: '100%', height: 'auto' }}
  >
    <section className={s.container}>
      <section className={classNames({ [s.blur]: isPrompted })}>
        <section className={classNames({ [s.pullBack]: isPrompted })}>{render(openPrompt)}</section>
      </section>
      {isPrompted && (
        <figure className={s.prompt}>
          <span className={s.promptText}>{promptText}</span>
          <section className={s.buttonContainer}>
            <Button onClick={cancelCallback} isSecondary>
              {cancelText}
            </Button>
            <Button testid="confirmable_confirm" onClick={confirmCallback} isPrimary>
              {confirmText}
            </Button>
          </section>
        </figure>
      )}
    </section>
  </OutsideClickContainer>
)

export default Confirmable
