import { BaseSerializer } from './base.serializer'

export function RuleOpSerializer(oldValue: string, newValue: string): BaseSerializer {
  return {
    toString() {
      return (
        <p>
          Operator changed from <strong>{oldValue}</strong> to <strong>{newValue}</strong>
        </p>
      )
    }
  }
}
