import { BOOLEAN_OPERATORS } from 'rules/constants'
import { Condition as ConditionType, Operand } from 'simple_review/@types/editor'
import { getShortId } from '../constants'

export function isCondition(operand: ConditionType | Operand) {
  return BOOLEAN_OPERATORS.some(op => op === operand.op)
}

export function isConditionGroup(operand: ConditionType) {
  return operand.operands.length > 1 && operand.operands.every(isCondition)
}

export function getParentCondition(condition: ConditionType, parentIndex?: number) {
  const parentCondition =
    parentIndex !== undefined ? (condition.operands[parentIndex] as ConditionType) : condition
  return parentCondition
}

export function changeConditionIds(condition: ConditionType | Operand) {
  if ('operands' in condition) {
    for (const operand of condition.operands) {
      changeConditionIds(operand)
    }
  }
  condition.id = getShortId()
}
