import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Panel, SwitchToggle, useLoading } from 'simple-core-ui'
import { makeGetRequest, makePostRequest } from 'utils/api'
import s from './MatterPreferences.scss'
import { PreferenceSettings } from './types'

const Preferences = () => {
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [preferenceSettings, setPreferenceSettings] = useState<PreferenceSettings[]>([])

  const toggleStatus = async (name: string, index: number) => {
    try {
      const url = '/manage/matters/settings'

      await withLoadingLocks(
        makePostRequest(url, {
          value: !preferenceSettings[index].value,
          name
        })
      )
      setPreferenceSettings(
        preferenceSettings.map((setting, i) =>
          i === index ? { ...setting, value: !setting.value } : setting
        )
      )

      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: `Notification ${preferenceSettings[index].label} successfully updated.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchPrefrenceSettings = async () => {
    try {
      const prefSettings = await withLoadingLocks(makeGetRequest('/manage/matters/settings'))
      setPreferenceSettings(prefSettings['settings'])
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const onToggleSetting = (id: number) => {
    const index = preferenceSettings.findIndex(p => p.id === id)
    const prefrenceSetting = preferenceSettings[index]

    toggleStatus(prefrenceSetting.name, index)
  }

  useEffect(() => {
    fetchPrefrenceSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section>
      <Panel
        key="preference-settings"
        title="Preferences"
        styles={{ boxShadow: 'none', marginBottom: 48 }}
      >
        {preferenceSettings.length
          ? preferenceSettings.map(setting => (
              <div key={setting.name} className={s.settingsContainer}>
                <section className={s.settingTitle}>
                  <h2>{setting.label}</h2>
                  <SwitchToggle
                    checked={setting.value}
                    onToggle={() => onToggleSetting(setting.id)}
                    className={s.test}
                  />
                </section>
                <section>{setting.description}</section>
              </div>
            ))
          : null}
      </Panel>
    </section>
  )
}

export default Preferences
