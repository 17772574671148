import { makeGetRequest, makePostRequest } from 'utils/api'

export const fetchWorkspaces = async inputValue =>
  await makeGetRequest('/doc_management/imanage_select_workspace', {
    params: { q: inputValue }
  })

export const fetchTemplates = async () => await makeGetRequest('/doc_management/imanage_templates/')

export const fetchCreatingWorkspaceStatus = async taskId =>
  await makeGetRequest(`/doc_management/workspace-creation-status/${taskId}/`)

export const sendSelectedWorkspace = async (matterId, workspaceId) =>
  await makePostRequest(`/doc_management/associate_folder/${matterId}/${workspaceId}`)

export const sendSelectedTemplate = async (matterId, templateId) =>
  await makeGetRequest(`/doc_management/create_folder/${matterId}`, {
    params: { template_id: templateId }
  })
