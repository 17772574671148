import { Title, VerticalTabs, VerticalTab } from 'simple-core-ui'
import { CategoriesContainer } from './CategoriesContainer'
import { LabelsContainer } from './LabelsContainer'
import { PreviewContainer } from './PreviewContainer'
import { NotificationsContainer } from './NotificationsContainer'
import { InfectedFilesContainer } from './InfectedFilesContainer'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import s from './DocumentManagementSettings.scss'
import { IoIosArrowBack } from 'react-icons/io'

const isNativeDMS = hasModule(MODULE.NATIVE_DMS)

const DocumentManagementSettings = () => {
  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '8px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <Title text="Document Management Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs activeTabKey={isNativeDMS ? 'categories' : 'notifications'}>
          {isNativeDMS ? (
            <VerticalTab key="categories" label="Document Categories">
              <CategoriesContainer />
            </VerticalTab>
          ) : null}
          {isNativeDMS ? (
            <VerticalTab key="labels" label="Document Labels">
              <LabelsContainer />
            </VerticalTab>
          ) : null}
          {isNativeDMS ? (
            <VerticalTab key="preview" label="Document Preferences">
              <PreviewContainer />
            </VerticalTab>
          ) : null}
          <VerticalTab key="notifications" label="Notifications">
            <NotificationsContainer />
          </VerticalTab>
          <VerticalTab key="infected" label="Quarantined Files">
            <InfectedFilesContainer />
          </VerticalTab>
        </VerticalTabs>
      </section>
    </section>
  )
}

export default DocumentManagementSettings
