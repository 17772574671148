import { Component } from 'react'
import { connect } from 'react-redux'
import { DataTableContainer, DataTableService, DataTableSerializer } from 'simple-core-ui'

@connect()
class ListTableContainer extends Component {
  state = {
    params: {
      pageSize: 25,
      ordering: { columnKey: 'name', isDesc: false },
      search: '',
      page: 1,
      category: this.props.initialCategory || 'All'
    }
  }

  updateTable = params => {
    this.setState({
      params
    })
  }

  render() {
    const {
      objects,
      columns,
      filterListItems,
      deleteRow,
      clickRowAction,
      filters,
      getNormalizedCellContent,
      entryLanguage,
      title,
      primaryAction,
      getSecondaryAction
    } = this.props

    const rowsByCategory = filterListItems.reduce((acc, category) => {
      const { value } = category
      acc[value] =
        !Array.isArray(value) && value.toLowerCase() === 'all'
          ? objects
          : objects.filter(o => value.includes(o.status))
      return acc
    }, {})

    const rows = DataTableSerializer.getRowsByCategory(rowsByCategory, columns)

    const rowsService = new DataTableService(rows, columns, getNormalizedCellContent)

    const results = rowsService.getUpdatedResults(this.state.params)
    return (
      <div data-testid="matter_requests_list">
        <DataTableContainer
          title={title}
          initialValues={this.state.params}
          previousPage={results.previousPage}
          nextPage={results.nextPage}
          totalEntries={results.totalEntries}
          renderCell={this.renderCell}
          filters={filters}
          filteredTotal={results.filteredTotal}
          categories={filterListItems}
          rows={results.rows}
          columns={results.columns}
          clickRow={clickRowAction}
          updateTable={this.updateTable}
          deleteRow={deleteRow}
          hasActions={!!deleteRow}
          entryLanguage={entryLanguage}
          primaryAction={primaryAction}
          secondaryAction={
            getSecondaryAction
              ? getSecondaryAction(results.rows, this.state?.params?.category)
              : null
          }
          isLoading={!!this.props.isLoading}
        />
      </div>
    )
  }
}

export default ListTableContainer
