import { Avatar, CollapsibleContainer } from 'simple-core-ui'
import s from './VendorListItem.scss'
import cn from 'classnames'
import { Vendor } from '../../types'

interface Props {
  vendorData: Vendor
  isLastItem: boolean
}

const VendorListItem = ({ vendorData, isLastItem }: Props) => {
  const { label, users } = vendorData
  return (
    <div className={s.container}>
      <CollapsibleContainer
        title={<span style={{ color: '#000' }}>{`${label} (${users.length})`}</span>}
        titleClassName={s.title}
        contentClassName={cn({ [s.lastItem]: isLastItem })}
      >
        <div className={s.innerContainer}>
          {users.map(u => (
            <div key={u.cgUserId} className={s.vendor}>
              <Avatar
                initials={u.fullName}
                className={s.avatar}
                styles={{
                  border: '1px solid #ddd',
                  marginLeft: '-2px',
                  fontSize: '14px'
                }}
              />
              <p className={s.vendorName}>{u.fullName}</p>
            </div>
          ))}
        </div>
      </CollapsibleContainer>
    </div>
  )
}

export default VendorListItem
