import { createReducer } from 'redux-create-reducer'
import { fromFragment, toFragment } from './serializers'
import qs from 'query-string'

import ACT from './actions'

import update from 'immutability-helper'
import { MATTER_STATUS } from 'matters/constants'

const DEFAULT_TAB =
  window.credentials.user && window.credentials.user.role === 'filtered' ? 'mine' : 'all'
const initialTab = qs.parse(window.location.hash).category || DEFAULT_TAB

let defaultParams = {
  pageSize: 50,
  ordering: { columnKey: 'open_date', isDesc: true },
  search: { keyword: '', status: [{ value: 'open', label: 'Open' }] },
  page: 1,
  category: initialTab
}

if (window.location.hash && window.location.pathname === '/v2/matters/list') {
  defaultParams = fromFragment(window.location.hash, defaultParams)
}

const loggedInUser = window.credentials.user

export const initialState = {
  matterList: {
    results: [],
    total: 0,
    filtered: 0,
    page: 1
  },
  matterListParams: defaultParams,
  matterSummary: {
    mine: {},
    all: {}
  },
  matterListFragment: window.location.hash.slice(1)
}

const mattersReducer = createReducer(initialState, {
  [ACT.MATTER_LIST_FETCH_SUCCESS](state, action) {
    const { matters, params, status } = action.payload

    const fragment = toFragment(params, status)
    return {
      ...state,
      matterListParams: params,
      matterList: matters,
      matterListFragment: fragment
    }
  },
  [ACT.MATTER_LIST_RESET](state) {
    return {
      ...state,
      matterList: initialState.matterList
    }
  },

  [ACT.MATTER_SUMMARY_FETCH_SUCCESS](state, action) {
    const { summary, page_title, attribute_name, back_link } = action.payload

    return {
      ...state,
      matterSummary: summary,
      pageTitle: page_title,
      attributeName: attribute_name,
      backLink: back_link
    }
  },

  [ACT.SELECTED_MATTERS_DELETED](state, action) {
    const { ids, selectedTab } = action.payload
    let count = 0
    const matters = state.matterList.results.filter(matter => {
      if (ids.includes(matter.id)) {
        count = count + 1
        return false
      }
      return true
    })

    return update(state, {
      matterList: {
        filtered: { $set: state.matterList.filtered - count },
        results: { $set: matters },
        total: { $set: state.matterList.total - count }
      },
      matterSummary: {
        [selectedTab]: {
          total: { $set: state.matterSummary[selectedTab].total - count }
        }
      }
    })
  },

  [ACT.SELECTED_MATTERS_CLOSED](state, action) {
    const { ids } = action.payload
    const matters = state.matterList.results.map(matter => {
      if (ids.includes(matter.id)) {
        return {
          ...matter,
          status: MATTER_STATUS.CLOSED
        }
      } else {
        return matter
      }
    })

    return update(state, {
      matterList: {
        results: { $set: matters }
      }
    })
  },

  [ACT.SELECTED_MATTERS_UPDATED](state, action) {
    const { practiceArea, matterLead, ids, selectedTab } = action.payload
    const matterGroups = []
    if (practiceArea) {
      matterGroups.push({ id: practiceArea.value, name: practiceArea.label })
    }

    const matters = state.matterList.results.map(matter => {
      if (ids.includes(matter.id)) {
        return {
          ...matter,
          ...(matter.mattergroup_editable && matterGroups.length
            ? {
                mattergroups: matterGroups
              }
            : {}),
          ...(matterLead
            ? {
                lead: {
                  id: matterLead ? matterLead.value : null,
                  name: matterLead ? matterLead.label : null
                }
              }
            : {})
        }
      } else {
        return matter
      }
    })

    return update(state, {
      matterList: {
        results: {
          $set:
            selectedTab === 'mine' ? matters.filter(m => m.lead.id === +loggedInUser.id) : matters
        }
      }
    })
  },

  [ACT.MATTER_LIST_DELETE_SUCCESS](state, action) {
    const { id, selectedTab } = action.payload
    const index = state.matterList.results.findIndex(r => r.id === id)

    return update(state, {
      matterList: {
        filtered: { $set: state.matterList.filtered - 1 },
        results: {
          $splice: [[index, 1]]
        },
        total: { $set: state.matterList.total - 1 }
      },
      matterSummary: {
        [selectedTab]: {
          total: { $set: state.matterSummary[selectedTab].total - 1 }
        },
        ...(selectedTab === 'mine'
          ? {
              all: {
                total: { $set: state.matterSummary['all'].total - 1 }
              }
            }
          : {})
      }
    })
  },

  [ACT.UPDATE_MATTER_SUCCESS](state, action) {
    const { practiceArea, matterLead, id, selectedTab } = action.payload
    const index = state.matterList.results.findIndex(r => r.id === id)
    const matterGroups = []
    if (practiceArea) {
      matterGroups.push({ id: practiceArea.value, name: practiceArea.label })
    }

    return update(state, {
      matterList: {
        results:
          selectedTab === 'mine' && matterLead && matterLead.value !== +loggedInUser.id
            ? {
                $splice: [[index, 1]]
              }
            : {
                [index]: {
                  mattergroups: current => (matterGroups.length ? matterGroups : current),
                  lead: current =>
                    matterLead
                      ? {
                          id: matterLead ? matterLead.value : null,
                          name: matterLead ? matterLead.label : null
                        }
                      : current
                }
              }
      }
    })
  },

  [ACT.MATTER_LIST_MERGE_SUCCESS](state, action) {
    const { mergedMatterId, selectedTab } = action.payload
    const index = state.matterList.results.findIndex(r => r.id === mergedMatterId)

    return update(state, {
      matterList: {
        results: {
          $splice: [[index, 1]]
        }
      },
      matterSummary: {
        [selectedTab]: {
          total: { $set: state.matterSummary[selectedTab].total - 1 }
        },
        ...(selectedTab === 'mine'
          ? {
              all: {
                total: { $set: state.matterSummary['all'].total - 1 }
              }
            }
          : {})
      }
    })
  }
})

export default mattersReducer
