import moment from 'moment'
import { APITask, DateFormat, Task, Option } from './types'
import { DATE_FORMATS } from 'utils/constants'
import { formatName } from 'utils/helpers'

const { DEFAULT_DATE } = DATE_FORMATS

export const toUserOption = (user: {
  id: number
  first_name: string
  last_name: string
}): Option => {
  return {
    value: user.id,
    label: formatName(user)
  }
}

export const toTask = (task: APITask): Task => {
  const { id, name, status, due_date, related_matter, created_by, assignees, followers } = task

  return {
    id,
    name,
    createdBy: created_by ? toUserOption(created_by) : null,
    assignees: assignees.map(toUserOption),
    followers: followers.map(toUserOption),
    status: status
      ? {
          id: status.id,
          name: status.client_settings?.display_name ?? status.default_display_name,
          color: status.client_settings?.color ?? status.default_color,
          occurredDate: moment(status.occurred_date).format(DEFAULT_DATE) as DateFormat,
          phase: status.phase.description
        }
      : null,
    dueDate: due_date ? (moment(due_date).format(DEFAULT_DATE) as DateFormat) : undefined,
    relatedTo: related_matter
      ? {
          id: related_matter.id,
          name: related_matter.name,
          status: related_matter.status
        }
      : null
  }
}
