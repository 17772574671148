import { Fragment } from 'react'
import classNames from 'classnames'
import { FaTrash } from 'react-icons/fa'
import { CheckboxContainer } from 'containers'
import { LEGAL_REQUEST_FIELDS } from 'matters/requestForms/constants'
import s from '../../RequestFormRow.scss'

const RequestFormRowActions = ({
  item,
  attrIndex,
  changeFormFieldValue,
  removeItem,
  displayRemove,
  readOnly,
  isRelationshipAttribute,
  relAttrIndex
}) => (
  <Fragment>
    <div className={classNames(s.checkboxWrapper, s.requiredWrapper, s.rowActions)}>
      <CheckboxContainer
        defaultChecked={item.required}
        property="required"
        disabled={item.model === LEGAL_REQUEST_FIELDS.MATTER_NAME || readOnly}
        onChange={value =>
          changeFormFieldValue({
            attrIndex,
            value,
            property: 'required'
          })
        }
      />
    </div>
    <div className={classNames(s.checkboxWrapper, s.visibilityWrapper, s.rowActions)}>
      <CheckboxContainer
        defaultChecked={item.fieldVisible}
        property="fieldVisible"
        disabled={item.model === LEGAL_REQUEST_FIELDS.MATTER_NAME || readOnly}
        onChange={value =>
          changeFormFieldValue({
            attrIndex,
            value,
            property: 'fieldVisible'
          })
        }
      />
    </div>
    {displayRemove && (
      <div className={classNames(s.remove, s.rowActions)}>
        <FaTrash className={s.trashIcon} onClick={() => removeItem(attrIndex)} />
      </div>
    )}
  </Fragment>
)

export default RequestFormRowActions
