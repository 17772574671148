export const INVOICE_AI_LABELS: Record<string, unknown> = {
  admin: 'ADMIN',
  block: 'BLOCK',
  conf: 'CONFERENCE',
  copy: 'COPY',
  excs: 'EXCESSIVE',
  nonatty: 'NON-ATTORNEY',
  overhead: 'OVERHEAD',
  post: 'POSTAGE',
  dlvry: 'DELIVERY',
  rsrch: 'RESEARCH',
  trvl: 'TRAVEL',
  vague: 'VAGUE'
}
