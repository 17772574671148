import { PaginatedSelect } from 'simple-core-ui'

const URL = '/react_select'

const BudgetSelect = ({
  value,
  isClearable,
  onChange,
  fetchCallback,
  isPortal = true,
  isMulti = false,
  limit = Infinity,
  filterOption,
  serializer,
  defaultOptions,
  defaultAction,
  id
}) => {
  return (
    <PaginatedSelect
      url={URL}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      defaultOptions={defaultOptions}
      defaultAction={defaultAction}
      isPortal={isPortal}
      isClearable={isClearable}
      id={id}
      searchTermParam="search"
      placeholder="Search budgets..."
      hasMoreProperty="has_more"
      resultsProperty="options"
      limit={limit}
      filterOption={filterOption}
      mandatoryQueryParams={{ model: 'budgets' }}
    />
  )
}

export default BudgetSelect
