import * as React from 'react'
import {
  KeyValuePairs,
  XeditableContainer,
  XeditableChain,
  If,
  prependProtocol,
  coreThemes
} from 'simple-core-ui'

import ContactFullNameXeditable from '../ContactFullNameXeditable/ContactFullNameXeditable'

import { formatCityState } from 'utils/formatting'

import s from './ContactAttributes.scss'

const ContactAttributes = ({
  prefix,
  firstName,
  middleName,
  lastName,
  suffix,
  title,
  org,
  phone,
  email,
  url,
  notes,
  address,
  isLoading,
  updateAttributes,
  additionalEmails,
  additionalPhones,
  createAdditionalField,
  updateAdditionalField,
  deleteAdditionalField,
  readOnly
}) => (
  <KeyValuePairs
    pairs={[
      {
        key: 'Full Name',
        value: (
          <If condition={!isLoading}>
            <ContactFullNameXeditable
              position="right"
              title="Update Full Name"
              initialValues={{ prefix, firstName, middleName, lastName, suffix }}
              setValueCb={updateAttributes}
              readOnly={readOnly}
            />
          </If>
        )
      },
      {
        key: 'Email',
        value: (
          <React.Fragment>
            <XeditableContainer
              type="email"
              initialValue={email}
              setValueCb={email => updateAttributes({ email })}
              validation={value => (!value.trim().length ? 'A primary email is required.' : null)}
              title="Update Primary Email"
              position="right"
              readOnly={readOnly}
            />
            <If condition={email}>
              {constructAdditionalFields(
                'email',
                additionalEmails,
                createAdditionalField,
                updateAdditionalField,
                deleteAdditionalField,
                readOnly
              )}
            </If>
          </React.Fragment>
        )
      },
      {
        key: 'Phone',
        value: (
          <React.Fragment>
            <XeditableContainer
              type="phone"
              initialValue={phone}
              setValueCb={phone => updateAttributes({ phone })}
              validation={value => (!value.trim().length ? 'A primary phone is required.' : null)}
              title="Update Primary Phone"
              position="right"
              readOnly={readOnly}
            />
            <If condition={phone}>
              {constructAdditionalFields(
                'phone',
                additionalPhones,
                createAdditionalField,
                updateAdditionalField,
                deleteAdditionalField,
                readOnly
              )}
            </If>
          </React.Fragment>
        )
      },
      {
        key: 'Title',
        value: (
          <XeditableContainer
            type="text"
            initialValue={title}
            setValueCb={title => updateAttributes({ title })}
            title="Update Title"
            position="right"
            readOnly={readOnly}
          />
        )
      },
      {
        key: 'Company',
        value: companyValue(org, updateAttributes, readOnly)
      },
      {
        key: 'Location',
        value: address ? (
          <span className={s.readOnly}>
            <If condition={address.city || address.state} fallback="----">
              {formatCityState(address)}
            </If>
          </span>
        ) : (
          '----'
        )
      },
      {
        key: 'URL',
        value: (
          <XeditableContainer
            type="url"
            initialValue={url}
            setValueCb={url => updateAttributes({ url: prependProtocol(url) })}
            title="Update URL"
            position="right"
            readOnly={readOnly}
          />
        )
      },
      {
        key: 'Notes',
        value: (
          <XeditableContainer
            type="textarea"
            initialValue={notes}
            setValueCb={notes => updateAttributes({ notes })}
            title="Update Notes"
            position="right"
            readOnly={readOnly}
          />
        )
      }
    ]}
    style={{ padding: '0 1.5em' }}
    stackedKeys
    isLoading={isLoading}
  />
)

const companyValue = (org = {}, updateAttributes, readOnly) =>
  ({
    vendor: <span className={s.readOnly}>{org.name}</span>,
    company: (
      <XeditableContainer
        type="text"
        initialValue={org.name}
        setValueCb={company => updateAttributes({ company })}
        title="Update Company"
        position="right"
        readOnly={readOnly}
      />
    ),
    client: <span className={s.readOnly}>{org.name}</span>,
    legal_entity: <span className={s.readOnly}>{org.name}</span>
  }[org.type])

const constructAdditionalFields = (
  additionalFieldType,
  additionalFields,
  createCb,
  updateCb,
  deleteCb,
  readOnly
) => {
  const additionalFieldName = {
    email: 'additionalEmails',
    phone: 'additionalPhones'
  }[additionalFieldType]

  const updateTitle = {
    email: 'Update Email',
    phone: 'Update Phone'
  }[additionalFieldType]

  const xeditableType = {
    email: 'list-email',
    phone: 'list-phone'
  }[additionalFieldType]

  const newPlaceholder = {
    email: '+ email...',
    phone: '+ phone...'
  }[additionalFieldType]

  const newTitle = {
    email: 'Add New Email',
    phone: 'Add New Phone'
  }[additionalFieldType]

  const options = {
    email: [
      { value: 'work', label: 'Work' },
      { value: 'personal', label: 'Personal' },
      { value: 'other', label: 'Other' }
    ],
    phone: [
      { value: 'work', label: 'Work' },
      { value: 'mobile', label: 'Mobile' },
      { value: 'fax', label: 'Fax' },
      { value: 'home', label: 'Home' },
      { value: 'other', label: 'Other' }
    ]
  }[additionalFieldType]

  return (
    <XeditableChain
      createCb={({ text, option }) => {
        createCb(additionalFieldName, { text, type: option.value })
      }}
      updateCb={({ id, value }) => {
        updateCb(additionalFieldName, { id, text: value.text, type: value.option.value })
      }}
      filterCb={({ option, text }) => option && text.length}
      deleteCb={({ id, value }) => {
        deleteCb(additionalFieldName, { id, text: value.text })
      }}
      values={additionalFields}
      xeditableConfig={{
        type: xeditableType,
        position: 'right',
        title: updateTitle,
        options,
        placeholder: '',
        theme: coreThemes.EB
      }}
      newPlaceholder={newPlaceholder}
      newTitle={newTitle}
      readOnly={readOnly}
    />
  )
}

export default ContactAttributes
