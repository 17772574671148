import get from 'lodash/get'

import { makeGetRequest } from 'utils/api'

import { fromContacts } from 'contacts/serializer'
import { REVIEW_STATUS } from './utils/constants'

import APP_ACT from 'app/actions'
import ACT from './actions'

export const invoiceScoped = {
  reviewId: window.serverContext.get('review_id'),
  showModalWhen: async (modalAction, review) => {
    try {
      const { invoice_is_adjusted } = await makeGetRequest(
        `/invoices/${window.serverContext.get('invoice_id')}/has-adjustments`
      )

      window.serverContext.set('invoice_is_adjusted', invoice_is_adjusted)

      const approvalActions = [ACT.APPROVAL_REQUESTED, ACT.FINALIZE_REQUESTED]

      const isFinalAdjustedApproval =
        approvalActions.includes(modalAction) && invoice_is_adjusted && review.isFinalApproval

      const isRejection = modalAction === ACT.REJECTION_REQUESTED

      return isFinalAdjustedApproval || isRejection
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  },
  modalMessageFetcher: (modalAction, scope, scopeId) => {
    const url = get(
      {
        [ACT.FINALIZE_REQUESTED]: '/invoices/get_send_to_body_and_subject/approve_adjusted/',
        [ACT.APPROVAL_REQUESTED]: '/invoices/get_send_to_body_and_subject/approve_adjusted/',
        [ACT.REJECTION_REQUESTED]: '/invoices/get_send_to_body_and_subject/reject/'
      },
      modalAction
    )

    const params = { params: { invoice: scopeId } }

    return makeGetRequest(url, params).then(({ email_subject, email_body, default_contacts }) => ({
      subject: email_subject,
      body: email_body,
      emailTo: fromContacts(default_contacts).map(contact => ({ value: contact.id, ...contact }))
    }))
  },
  reviewActionEffects: (reviewAction, scope, scopeId, review) => {
    const shouldGoToNextReview = [
      ACT.FINALIZE_SUCCESS,
      ACT.APPROVAL_SUCCESS,
      ACT.REJECTION_SUCCESS
    ].includes(reviewAction)

    if (shouldGoToNextReview) {
      const actionTaken = get(
        {
          [ACT.REJECTION_SUCCESS]: 'Invoice Rejection Submitted',
          [ACT.APPROVAL_SUCCESS]: 'Invoice Approval Submitted',
          [ACT.FINALIZE_SUCCESS]: 'Invoice has been Approved and Finalized'
        },
        reviewAction,
        'Invoice Review Successful Submitted'
      )

      window.store.dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: actionTaken,
          message: 'You will be redirected to the next reviewable invoice momentarily.',
          level: 'success'
        }
      })

      window.location = window.serverContext.get('next_invoice_url')
    }
  }
}

export const matterScoped = {
  reviewId: window.serverContext.get('review_id'),
  showModalWhen: (modalAction, review) => {
    return new Promise(resolve => resolve(modalAction === ACT.REJECTION_REQUESTED))
  },
  modalMessageFetcher: (modalAction, scope, scopeId) => {
    const url = get(
      {
        [ACT.REJECTION_REQUESTED]: '/manage/matters/get_send_to_body_and_subject/reject/'
      },
      modalAction
    )

    const params = { params: { matter: scopeId } }

    return makeGetRequest(url, params).then(({ email_subject, email_body, default_contacts }) => ({
      subject: email_subject,
      body: email_body,
      emailTo: fromContacts(default_contacts).map(contact => ({ value: contact.id, ...contact }))
    }))
  },
  reviewActionEffects: (reviewAction, scope, scopeId, review) => {
    const shouldShowNotification = [
      ACT.FINALIZE_SUCCESS,
      ACT.APPROVAL_SUCCESS,
      ACT.REJECTION_SUCCESS
    ].includes(reviewAction)

    if (shouldShowNotification) {
      const actionTaken = get(
        {
          [ACT.REJECTION_SUCCESS]: 'Your change request has been successfully submitted.',
          [ACT.APPROVAL_SUCCESS]: 'Your approval has been successfully submitted.',
          [ACT.FINALIZE_SUCCESS]: 'The matter has been finalized and published.'
        },
        reviewAction,
        'Your response has been successfully submitted.'
      )

      window.store.dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Response Submitted',
          message: actionTaken,
          level: 'success'
        }
      })
    }

    if (review.state === REVIEW_STATUS.APPROVED) {
      window.location.reload()
    }
  }
}
