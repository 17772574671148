import { formatResponse } from 'utils/formatting'
import { put, takeLatest } from 'redux-saga/effects'
import { makePutRequest } from 'utils/api'

import ACT from './actions'

import { NotificationList } from 'components'

import { toRequestJson } from 'matters/requests/common/RequestsTableContainer/serializers'

function* rejectLegalRequest(action) {
  const { id, comment } = action.payload

  try {
    const url = `/manage/matters/requests/request_detail/${id}/reject/`
    const request = yield makePutRequest(url, { comment })
    yield put({ type: ACT.REQUEST_REJECTED_SUCCESS, payload: { request: toRequestJson(request) } })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue rejecting the Legal Request',
        message: <NotificationList lines={formatResponse(e.response.message)} />,
        level: 'error'
      }
    })
  }
}

const processRequestSagas = [takeLatest(ACT.REQUEST_REJECT_REQUESTED, rejectLegalRequest)]

export default processRequestSagas
