import cn from 'classnames'
import { IoIosAlert } from 'react-icons/io'
import { RequestAttributeFilterContainer } from '../Filter'
import { LegalRequestSelect } from '../Select'
import { RequestFormRowActions } from '../../common/RowActions'
import { LEGAL_REQUEST_FIELDS } from 'matters/requestForms/constants'
import { getAttributeValue, getLabelValue } from 'matters/requestForms/utils'
import s from '../../RequestFormRow.scss'
import { TextInput } from 'simple-core-ui'

const Row = ({
  attributeOptions,
  item,
  onLabelChange,
  onAttributeChange,
  onTypeChange,
  onInfoChange,
  attrIndex,
  changeFormFieldValue,
  removeItem,
  displayRemove,
  toggleFilter,
  filterVisible,
  disabled,
  formatGroupLabel,
  hasRelationships,
  selectedAttribute,
  selectedType,
  isRelationshipAttribute,
  relAttrIndex,
  relId,
  relationshipAttribute
}) => {
  const options = hasRelationships
    ? [...attributeOptions[0].options, ...attributeOptions[1].options]
    : attributeOptions

  const attributeOption = options.find(a => a.value === item.model)
  const typeOption = item.filteredFieldTypes?.find(i => i.value === item.fieldType)

  return (
    <li className={s.rowContainer}>
      <div className={s.rowAttributeSelect}>
        {!item.isValid && (
          <div
            className={s.warningContainer}
            title="This attribute is no longer referenced by a template on this form"
          >
            <IoIosAlert />
          </div>
        )}
        <div className={s.attributeSelectContainer} data-testid="attribute_select_container">
          <LegalRequestSelect
            options={attributeOptions}
            selectedOption={
              selectedAttribute || {
                value: item.model,
                label: attributeOption ? attributeOption.label : ''
              }
            }
            displayString={item.modelLabel}
            onChange={value => onAttributeChange(attrIndex, getAttributeValue(value))}
            openMenuOnFocus
            isDisabled={
              item.model === LEGAL_REQUEST_FIELDS.MATTER_NAME || disabled || isRelationshipAttribute
            }
            formatGroupLabel={formatGroupLabel}
            hasRelationships={hasRelationships}
          />
        </div>
        {item.fieldType === 'select' && (
          <RequestAttributeFilterContainer
            toggleFilter={toggleFilter}
            filterVisible={filterVisible}
            attrIndex={attrIndex}
            item={item}
          />
        )}
      </div>
      <div className={s.rowFieldSelect}>
        <LegalRequestSelect
          options={item.filteredFieldTypes}
          selectedOption={
            selectedType || {
              value: item.fieldType,
              label: typeOption ? typeOption.label : ''
            }
          }
          onChange={value => onTypeChange(attrIndex, getLabelValue(value))}
          openMenuOnFocus
          isDisabled={
            item.model === LEGAL_REQUEST_FIELDS.MATTER_NAME || disabled || isRelationshipAttribute
          }
        />
      </div>
      <div className={s.formLabel}>
        <TextInput
          type="text"
          placeholder="Input Label"
          value={
            isRelationshipAttribute
              ? item.attributes?.length
                ? item.attributes?.[relAttrIndex]?.label
                : relationshipAttribute.attributes?.[relAttrIndex]?.label
              : item.label
          }
          onChange={value => onLabelChange(attrIndex, value, relAttrIndex, relId)}
          isDisabled={disabled}
          className={cn({ [s.inputWarning]: item.hasInvalidLabel })}
          testid="request_form_item_label"
        />
      </div>
      <div className={s.rowInfoBox}>
        <TextInput
          type="textarea"
          placeholder="Helper Text"
          value={
            isRelationshipAttribute
              ? item.attributes?.length
                ? item.attributes?.[relAttrIndex]?.info
                : relationshipAttribute.attributes?.[relAttrIndex]?.info
              : item.info || ''
          }
          onChange={value => onInfoChange(attrIndex, value, relAttrIndex, relId)}
          isDisabled={disabled}
          testid="request_form_item_info"
        />
      </div>
      <RequestFormRowActions
        item={item}
        attrIndex={attrIndex}
        changeFormFieldValue={changeFormFieldValue}
        removeItem={removeItem}
        displayRemove={
          item.model !== LEGAL_REQUEST_FIELDS.MATTER_NAME && displayRemove && !disabled
        }
        readOnly={disabled}
        isRelationshipAttribute={isRelationshipAttribute}
        relAttrIndex={relAttrIndex}
      />
    </li>
  )
}

export default Row
