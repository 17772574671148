import s from './Filterable.scss'

const Filterable = ({ filter, changeHandler, placeholder, render, testid }) => (
  <section className={s.container}>
    <input
      type="text"
      data-testid={testid}
      placeholder={placeholder}
      value={filter}
      className={s.input}
      onChange={changeHandler}
    />
    {render(filter)}
  </section>
)

export default Filterable
