import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Template } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  template: Template
  editTemplate: (row: Template) => void
  deleteTemplate: (row: Template) => void
  duplicateTemplate: (row: Template) => void
}

const ActionsPopover = ({ template, deleteTemplate, editTemplate, duplicateTemplate }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editTemplate(template)
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteTemplate(template)
      }
    },
    {
      name: 'DUPLICATE',
      text: 'Duplicate',
      onClick() {
        duplicateTemplate(template)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${template.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
