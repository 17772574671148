import * as React from 'react'
import classNames from 'classnames'
import get from 'lodash/get'

import ACT from '../actions'

import ReviewModalContainer from '../containers/ReviewModalContainer'

import { Overlay, If, Panel } from 'simple-core-ui'
import { Confirmable } from 'components'
import Hydrating from './Hydrating'
import ReviewLadderHeader from './ReviewLadderHeader'
import PromptText from './PromptText'
import ReviewEntry from './ReviewEntry'
import ReviewEntryGroup from './ReviewEntryGroup'
import AddReviewers from './AddReviewers'
import Empty from './Empty'
import ReviewMessage from './ReviewMessage'
import Reasons from './Reasons'

import {
  extractEntryGroup,
  extractEntry,
  singleFromEntries,
  extractRejectedResponse
} from '../utils/extractEntry'
import { isSingleEntry } from '../utils/identifyEntry'
import { patchEntryActions } from '../utils/patchEntryActions'
import { ENTRY_ACTION_LABEL } from '../utils/constants'
import { REVIEWERS_SEARCH_URL } from '../urls'

import s from '../styles/ReviewLadder.scss'

const promptConfirmText = {
  [ACT.REMOVE_REVIEWER_REQUESTED]: 'Remove',
  [ACT.SEND_BACK_TO_REQUESTED]: 'Send Back'
}

const ReviewLadder = ({
  review,
  reviewActionHandlers,
  entryActionHandlers,
  modalHandlers,
  onCloseReasons,
  onConfirmPrompt,
  onCancelPrompt,
  onAddReviewers,
  expandedGroupId,
  isResponseExpanded,
  reasonsFor,
  promptedFor,
  promptAction,
  modalAction,
  toggleEntryGroup,
  toggleResponse,
  scope,
  scopeId,
  modalMessageFetcher,
  isHydrating,
  withPanel,
  className
}) => {
  const LadderWrapper = ({ children }) =>
    withPanel ? (
      <Panel
        title="Review"
        className={className}
        rightActions={[
          <ReviewLadderHeader
            {...reviewActionHandlers}
            isFinalApproval={review.isFinalApproval}
            actionsOnly
            key={0}
          />
        ]}
      >
        {children}
      </Panel>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    )

  return (
    <LadderWrapper>
      <section className={s.container}>
        <Hydrating isVisible={isHydrating} />
        <If condition={modalAction !== null}>
          <ReviewModalContainer
            {...get(modalHandlers, modalAction, {})}
            {...{ scope, scopeId, modalAction, modalMessageFetcher }}
          />
        </If>
        <Overlay
          content={<Reasons entry={reasonsFor} />}
          onClose={onCloseReasons}
          isVisible={reasonsFor !== null}
          asStatic
        >
          <Confirmable
            isPrompted={promptedFor !== null}
            promptText={<PromptText promptedFor={promptedFor} promptAction={promptAction} />}
            confirmText={promptAction ? ENTRY_ACTION_LABEL(scope, promptAction) : ''}
            cancelText="Cancel"
            confirmCallback={onConfirmPrompt}
            cancelCallback={onCancelPrompt}
            render={() => (
              <section className={classNames({ [s.recedeAndBlur]: isHydrating })}>
                {review.ladder.tiers.length ? (
                  <React.Fragment>
                    {!withPanel && <ReviewLadderHeader {...reviewActionHandlers} />}
                    <ReviewMessage
                      text={review.userMessage}
                      response={extractRejectedResponse(review.ladder.tiers)}
                      isExpanded={isResponseExpanded}
                      toggleResponse={toggleResponse}
                    />
                    <section className={s.reviews}>
                      {review.ladder.tiers.map((tier, idx) =>
                        isSingleEntry(tier.entries) ? (
                          <ReviewEntry
                            key={idx}
                            {...extractEntry(singleFromEntries(tier.entries), scope)}
                            {...patchEntryActions(
                              entryActionHandlers,
                              singleFromEntries(tier.entries)
                            )}
                            isCurrent={tier.isCurrent}
                          />
                        ) : (
                          <ReviewEntryGroup
                            key={idx}
                            {...extractEntryGroup(tier, scope)}
                            entryActionHandlers={entryActionHandlers}
                            toggleGroup={() => toggleEntryGroup(idx)}
                            isExpanded={expandedGroupId === idx}
                            isCurrent={tier.isCurrent}
                          />
                        )
                      )}
                    </section>
                  </React.Fragment>
                ) : (
                  <Empty message={review.userMessage} />
                )}
                <If condition={get(review, 'actionPermissions.canAdd')}>
                  <AddReviewers
                    onAddReviewers={onAddReviewers}
                    searchUrl={REVIEWERS_SEARCH_URL({ scope, scopeId })}
                  />
                </If>
              </section>
            )}
          />
        </Overlay>
      </section>
    </LadderWrapper>
  )
}

export default ReviewLadder
