import s from './Reviewers.scss'
import IntegratedReviewerConfig from 'reviews/reviewer_config/integrated/IntegratedReviewerConfig'

const Reviewers = ({ reviewerConfigId, readOnly }) => {
  return (
    <div className={s.wrapper}>
      <h3 className={s.title}>Invoice Reviewers and Subscribers</h3>
      <IntegratedReviewerConfig reviewerConfigId={reviewerConfigId} readOnly={readOnly} />
    </div>
  )
}

export default Reviewers
