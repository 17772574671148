import React, { Component } from 'react'
import { If, Button, Modal, TextInput, Tooltip } from 'simple-core-ui'
import { Link } from 'react-router-dom'
import * as ACT from 'matters/templates/actions'
import * as CONST from 'matters/templates/constants'
import { GeneralInfoHeader, EntityActionsFooter, RequestFormList } from 'components'
import { CheckboxContainer } from 'containers'
import { TemplateCategoryContainer } from './TemplateCategory'
import { BaseEvaluatorsContainer } from 'matters/templates/common/BaseEvaluators'
import { TemplateAllocationsContainer } from 'matters/templates/common/TemplateAllocations'
import { DefaultFolderStructureContainer } from 'matters/templates/common/DefaultFolderStructure'
import { IManageTemplates } from 'matters/templates/common/IManageTemplates'
import { withRouter } from 'simple-core-ui/hocs'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import s from './ContentContainer.scss'
import flatMap from 'lodash/flatMap'
import { withSweetAlert } from 'hocs'
import swal from 'sweetalert'
import cn from 'classnames'
import ThemeContext from 'context/ThemeContext'
import get from 'lodash/get'
import update from 'immutability-helper'
import { makeGetRequest } from 'utils/api'
import { getAttributeValue } from 'matters/templates/utils'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'

@withRouter
@connect(
  ({ matterTemplates, matterAttributes, templateAllocations, surveyConfig, templateFolders }) => {
    return {
      template: { ...matterTemplates },
      attributes: matterAttributes.groups,
      allocations: templateAllocations.allocations,
      sharedEvaluators: surveyConfig.sharedEvaluators,
      folderStructure: templateFolders.rootNode
    }
  }
)
@withFetchFlow({
  flag: 'Attributes',
  forceRenderOnBack: true,
  getFetchAction: props => {
    if (!props.router.params.id) {
      return {
        type: ACT.NEW_MATTER_TEMPLATE_REQUESTED
      }
    }

    return {
      type: ACT.MATTER_TEMPLATE_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        id: props.router.params.id,
        navigate: props.router.navigate
      }
    }
  }
})
class ContentContainer extends Component {
  state = {
    templateId: this.props.router.params.id,
    deleteAttrConfirmation: {
      modalVisible: false,
      attrLabel: '',
      confirmAttrLabel: '',
      confirmCheckbox: false,
      attrIndex: '',
      category: '',
      sectionIndex: '',
      confirmError: false
    },
    iManageTemplate: {
      details: {
        value: '',
        label: ''
      },
      readOnly: false
    },
    canPublish: null
  }
  allocationsRef = React.createRef()
  _isMounted = false
  componentDidMount() {
    this._isMounted = true
    if (!this.state.templateId) {
      this.props.dispatch({ type: ACT.MATTER_TEMPLATE_RESET_STATE })
      this.props.dispatch({ type: ACT.MATTER_TEMPLATE_ALLOCATIONS_RESET_STATE })
    } else {
      this.fetchSelectedImanageTemplate()
    }

    this.fetchTemplatesCount()
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidUpdate() {
    const { id } = this.props.router.params
    if (this.state.templateId !== id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        templateId: id
      })
    }
    if (this.props.template.shouldRedirect) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_REMOVE_REDIRECT_FLAG
      })
      this.props.router.navigate('/v2/matters/templates/list')
    }

    if (this.props.template.breakingWarning) {
      const content = (
        <ul>
          {this.props.template.breakingWarning.map(warning => (
            <li style={{ wordWrap: 'break-word' }} key={warning}>
              {warning}
            </li>
          ))}
        </ul>
      )
      const sweetAlertFactory = withSweetAlert(content)
      sweetAlertFactory(
        {
          title:
            'The changes you have made may cause existing matters to be incompatible with this updated template. Do you wish to continue?',
          buttons: ['No', 'Yes'],
          icon: 'warning'
        },
        () => {
          this.resetTemplateBreakingWarning()
          this.updateTemplateWithBreakingChanges(this.props.template)
        },
        { cancelConfirmation: true, onCancel: this.resetTemplateBreakingWarning }
      )
    }
  }
  fetchSelectedImanageTemplate = async () => {
    const url = `/doc_management/template_mapping/?template_id=${this.state.templateId}`
    const response = await makeGetRequest(url)
    const { imanage_template_id, read_only } = response

    const selectedImanageTemplate = {
      details: { value: imanage_template_id, label: '' },
      readOnly: read_only
    }
    const newState = update(this.state, {
      iManageTemplate: {
        $set: selectedImanageTemplate
      }
    })
    if (this._isMounted) {
      this.setState(newState)
    }
  }
  fetchTemplatesCount = async () => {
    const url = '/templates/counts/'
    const response = await makeGetRequest(url)
    const { can_create_published_template } = response

    this.setState({
      canPublish: can_create_published_template
    })
  }
  resetTemplateBreakingWarning = () => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_SET_BREAKING_WARNING,
      payload: {
        breakingWarning: ''
      }
    })
  }
  updateTemplateWithBreakingChanges = template => {
    template.allow_breaking_changes = true
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_UPDATE_REQUESTED,
      payload: {
        template,
        id: this.state.templateId,
        navigate: this.props.router.navigate,
        location: this.props.router.location,
        redirect: false,
        allocations: this.props.allocations,
        sharedEvaluators: this.props.sharedEvaluators,
        folderStructure: this.props.folderStructure
      }
    })
  }
  changeTemplateName = name => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_CHANGE_TEMPLATE_NAME,
      payload: {
        name: name
      }
    })
  }
  changeTemplateDescription = description => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_CHANGE_TEMPLATE_DESCRIPTION,
      payload: {
        description
      }
    })
  }

  validateReadOnlyAttributes = (template, cb) => {
    const categoryArr = ['left', 'right']
    let error
    categoryArr.forEach(category => {
      template[category].forEach(section => {
        section.attributes.forEach(attribute => {
          const value = getAttributeValue(attribute)
          if (attribute.readOnly) {
            if (
              (attribute.type === 'boolean' && ![0, 1].includes(value)) ||
              (attribute.type === 'currency' && (!value.amount || !value.code)) ||
              ((!value || +value === -1) &&
                ![CONST.SYSTEM_GENERATED, 'boolean'].includes(attribute.type))
            ) {
              error = true
            }
          }
        })
      })
    })

    if (error) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Some read only attributes have no value!',
          level: 'error'
        }
      })
    } else {
      cb()
    }
  }
  validateClientRequiredAttributes = (template, cb) => {
    const categoryArr = ['left', 'right']
    const { attributes } = this.props

    const getNumRequiredAttrs = (categoryArr, template) => {
      let requiredAttributesAddedLength = 0

      categoryArr.forEach(cat => {
        template[cat].forEach(section => {
          section.attributes.forEach(attr => {
            if (attr.attributes.length) {
              // this means it's a dynamic dropdown
              attr.attributes.forEach(a => {
                if (a.requiredByClient) {
                  requiredAttributesAddedLength++
                }
              })
            } else {
              if (attr.requiredByClient) {
                requiredAttributesAddedLength++
              }
            }
          })
        })
      })

      return requiredAttributesAddedLength
    }

    let clientRequiredAttributesLength = flatMap(attributes, section =>
      section.attributes.filter(attr => attr.requiredByClient)
    ).length

    if (getNumRequiredAttrs(categoryArr, template) < clientRequiredAttributesLength) {
      this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'All client required attributes must be present in the template !',
          level: 'error'
        }
      })
    } else {
      cb()
    }
  }

  confirmationModal = payload => {
    const sweetAlertFactory = withSweetAlert(<RequestFormList forms={this.props.template.forms} />)
    sweetAlertFactory(
      {
        title: 'Are you sure you want to edit this template?',
        buttons: ['No', 'Yes'],
        icon: 'warning'
      },
      () => {
        this.props.dispatch({
          type: ACT.MATTER_TEMPLATE_UPDATE_REQUESTED,
          payload
        })
      },
      { cancelConfirmation: true }
    )
  }

  validateAllocations = async (allocations, handleValidCb) => {
    let percentageSum = 0
    let errorTitle = 'There was an issue updating the Matter Template'
    let hasBadAttribute = false

    for (let i = 0; i < allocations.length; i++) {
      if (get(allocations[i], 'columns[0].value') < 0) {
        return this.props.dispatch({
          type: ACT.PUSH_NOTIFICATION,
          payload: {
            title: errorTitle,
            message: 'Negative allocations are not allowed!',
            level: 'error'
          }
        })
      }
      percentageSum += Number(get(allocations[i], 'columns[0].value'))

      // get the legal entity column's value.value, which will be its ID, or -1 if not present.
      const allocationLegalEntityId = get(allocations[i], 'columns[1].value')?.value
      let attributeUrl = '/manage/attributes/allocatable_json'
      if (allocationLegalEntityId && allocationLegalEntityId > 0) {
        attributeUrl += `?legalentity_id=${allocationLegalEntityId}`
      }

      let attributeRequest
      try {
        attributeRequest = await makeGetRequest(attributeUrl)
      } catch (ex) {
        return this.props.dispatch({
          type: ACT.PUSH_NOTIFICATION,
          payload: {
            title: errorTitle,
            message: 'Error fetching Allocatable Attributes',
            level: 'error'
          }
        })
      }

      const attributes = attributeRequest.results
      allocations[i].columns.forEach(element => {
        if (!isNaN(element.id) && !!element.value?.label && element.value?.value != -1) {
          const attribute = attributes.find(a => a.id === parseInt(element.value.value))
          if (!attribute) {
            hasBadAttribute = true
          }
        }
      })
    }

    if (percentageSum !== 100) {
      return this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: errorTitle,
          message: 'Allocation percentage must add up to 100%',
          level: 'error'
        }
      })
    }

    if (hasBadAttribute) {
      return this.props.dispatch({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: errorTitle,
          message: 'All custom attribute list values under Allocations must be active.',
          level: 'error'
        }
      })
    }
    handleValidCb()
  }

  saveTemplate = template => {
    const { allocations } = this.props
    this.validateReadOnlyAttributes(template, () => {
      this.validateClientRequiredAttributes(template, () => {
        this.validateAllocations(allocations, () => {
          if (template.forms.length) {
            this.confirmationModal({
              template,
              id: this.state.templateId,
              navigate: this.props.router.navigate,
              location: this.props.router.location,
              redirect: false,
              allocations: this.props.allocations,
              sharedEvaluators: this.props.sharedEvaluators,
              folderStructure: this.props.folderStructure
            })
          } else {
            this.props.dispatch({
              type: this.state.templateId
                ? ACT.MATTER_TEMPLATE_UPDATE_REQUESTED
                : ACT.MATTER_TEMPLATE_CREATE_REQUESTED,
              loadingLock: 'on',
              payload: {
                template,
                id: this.state.templateId,
                navigate: this.props.router.navigate,
                location: this.props.router.location,
                redirect: false,
                allocations: this.props.allocations,
                sharedEvaluators: this.props.sharedEvaluators,
                imanageTemplateId: this.state.iManageTemplate.details.value,
                imanageReadOnly: this.state.iManageTemplate.readOnly,
                folderStructure: this.props.folderStructure
              }
            })
            this.props.dispatch({
              type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
              payload: {
                isSaving: false
              }
            })
          }
        })
      })
    })
  }
  archiveTemplate = template => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED,
      payload: {
        id: this.state.templateId,
        template: {
          status: CONST.TEMPLATE_STATUSES.ARCHIVED
        },
        redirect: true,
        navigate: this.props.router.navigate
      }
    })
  }

  saveAndPublishTemplate = template => {
    const { allocations } = this.props
    this.validateReadOnlyAttributes(template, () => {
      this.validateClientRequiredAttributes(template, () => {
        this.validateAllocations(allocations, () => {
          const status =
            template.status === CONST.TEMPLATE_STATUSES.PUBLISHED
              ? CONST.TEMPLATE_STATUSES.ARCHIVED
              : CONST.TEMPLATE_STATUSES.PUBLISHED
          const updatedTemplate = {
            ...template,
            status
          }
          if (template.forms.length) {
            this.confirmationModal({
              template: updatedTemplate,
              id: this.state.templateId,
              navigate: this.props.router.navigate,
              location: this.props.router.location,
              redirect: true,
              allocations: this.props.allocations,
              sharedEvaluators: this.props.sharedEvaluators,
              folderStructure: this.props.folderStructure
            })
          } else {
            this.props.dispatch({
              type: this.state.templateId
                ? ACT.MATTER_TEMPLATE_UPDATE_REQUESTED
                : ACT.MATTER_TEMPLATE_CREATE_REQUESTED,
              loadingLock: 'on',
              payload: {
                template: updatedTemplate,
                id: this.state.templateId,
                navigate: this.props.router.navigate,
                location: this.props.router.location,
                redirect: true,
                allocations: this.props.allocations,
                sharedEvaluators: this.props.sharedEvaluators,
                imanageTemplateId: this.state.iManageTemplate.details.value,
                imanageReadOnly: this.state.iManageTemplate.readOnly,
                folderStructure: this.props.folderStructure
              }
            })
            this.props.dispatch({
              type: ACT.MATTER_TEMPLATE_SET_IS_SAVING,
              payload: {
                isSaving: false
              }
            })
          }
        })
      })
    })
  }

  cancelChanges = () => {
    this.props.router.navigate('/v2/matters/templates/list')
  }

  scrollToBottom = () => {
    if (this.allocationsRef.current) {
      this.allocationsRef.current.scrollIntoView({ behavior: 'smooth' })

      if (this.props.opened) {
        this.props.toggleDrawer()
      }
    }
  }

  deleteTemplate = async () => {
    const onSuccessActionType = ACT.MATTER_TEMPLATES_DELETE_TEMPLATE
    const status = CONST.TEMPLATE_STATUSES.DELETED
    const willDelete = await swal({
      title: 'Delete Template?',
      text: 'You will not be able to recover this template.',
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })
    if (willDelete) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATE_UPDATE_STATUS_REQUESTED,
        payload: {
          id: this.state.templateId,
          template: {
            status
          },
          onSuccessActionType
        }
      })
      swal('DELETED', 'The template has been deleted', 'success').then(() => {
        this.props.router.navigate('/v2/matters/templates/list/')
      })
    }
  }

  cloneTemplate = () => {
    const { navigate, location } = this.props.router
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_CLONE_REQUESTED,
      payload: {
        id: this.state.templateId,
        navigate,
        location
      }
    })
  }

  toggleModal = (attrLabel = '', attrIndex, category, sectionIndex) => {
    const template = this.props.template
    if (
      category &&
      template[category][sectionIndex]?.attributes[attrIndex]?.type === 'relationship'
    ) {
      attrLabel = template[category][sectionIndex].attributes[attrIndex].label
    }
    this.setState({
      deleteAttrConfirmation: {
        modalVisible: !this.state.deleteAttrConfirmation.modalVisible,
        attrLabel,
        attrIndex,
        category,
        sectionIndex
      }
    })
  }

  confirmAttrLabel = value => {
    this.setState({
      deleteAttrConfirmation: {
        ...this.state.deleteAttrConfirmation,
        confirmAttrLabel: value
      }
    })
  }

  confirmCheckbox = value => {
    this.setState({
      deleteAttrConfirmation: {
        ...this.state.deleteAttrConfirmation,
        confirmCheckbox: value
      }
    })
  }

  removeAttribute = () => {
    const {
      attrIndex,
      category,
      sectionIndex,
      confirmAttrLabel,
      confirmCheckbox,
      attrLabel
    } = this.state.deleteAttrConfirmation
    if (attrLabel === confirmAttrLabel && confirmCheckbox) {
      this.props.dispatch({
        type: ACT.MATTER_TEMPLATES_REMOVE_ATTRIBUTE,
        payload: {
          attrIndex: attrIndex,
          category: category,
          sectionIndex: sectionIndex
        }
      })
      this.toggleModal()
    } else {
      this.setState({
        deleteAttrConfirmation: {
          ...this.state.deleteAttrConfirmation,
          confirmError: true
        }
      })
    }
  }

  setImanageTemplate = (value, property) => {
    const newState = update(this.state, {
      iManageTemplate: {
        [property]: { $set: value }
      }
    })
    this.setState(newState)
  }

  render() {
    const { template, opened } = this.props
    const { canPublish } = this.state
    return (
      <ThemeContext.Consumer>
        {({ state }) => (
          <div
            className={cn(s.container, {
              [s.closed]: !opened
            })}
          >
            <div
              className={cn({
                [s.closed]: !opened,
                [s.expanded]: state.isMenuExpanded
              })}
            >
              <div className={s.spread}>
                <h2>{this.state.templateId ? 'Edit' : 'Add'} Template</h2>
                <If condition={template.status}>
                  <p className={s.status}>
                    Status: <b>{template.status}</b>
                  </p>
                </If>
              </div>
              <div className={s.spread}>
                <Link to="/v2/matters/templates/list" data-testid="return_to_templates">
                  {'<<'} Return to Matter Templates
                </Link>
                {template.status === CONST.TEMPLATE_STATUSES.PUBLISHED &&
                  (typeof canPublish === 'boolean' && !canPublish ? (
                    <Tooltip
                      trigger={
                        <Button isDisabled onClick={() => this.cloneTemplate()} isSecondary>
                          Clone
                        </Button>
                      }
                      content="Contact customer success to upgrade."
                      placement="top"
                    />
                  ) : (
                    template.can_clone && (
                      <Button onClick={() => this.cloneTemplate()} isSecondary>
                        Clone
                      </Button>
                    )
                  ))}
              </div>
              <GeneralInfoHeader
                name={template.name}
                description={template.description}
                changeName={this.changeTemplateName}
                changeDescription={this.changeTemplateDescription}
                namePlaceholder="Template Name"
                nameLabel="Template Name"
                descriptionLabel="Template Description"
              />
              <div className={s.relativeLinks}>
                <Button
                  onClick={this.scrollToBottom}
                  isSecondary
                  id="slm_template_scroll_to_allocations_button"
                >
                  Allocations
                </Button>
              </div>
            </div>

            <TemplateCategoryContainer
              toggleModal={this.toggleModal}
              isEditTemplatePage={
                !!this.state.templateId && template.status === CONST.TEMPLATE_STATUSES.PUBLISHED
              }
              confirmAttrLabel={this.state.deleteAttrConfirmation.confirmAttrLabel}
              confirmCheckbox={this.state.deleteAttrConfirmation.confirmCheckbox}
            />
            <div ref={this.allocationsRef}>
              <TemplateAllocationsContainer />
            </div>

            <BaseEvaluatorsContainer />

            {hasModule(MODULE.NATIVE_DMS) && (
              <DefaultFolderStructureContainer templateId={this.state.templateId} />
            )}

            <IManageTemplates
              setImanageTemplate={this.setImanageTemplate}
              template={this.state.iManageTemplate}
              matterTemplateId={this.state.templateId}
            />

            <EntityActionsFooter
              canPublish={canPublish}
              entity={template}
              saveAndPublish={this.saveAndPublishTemplate}
              archive={this.archiveTemplate}
              save={this.saveTemplate}
              cancelChanges={this.cancelChanges}
              showDelete={
                template.status === CONST.TEMPLATE_STATUSES.DRAFT && this.state.templateId
              }
              deleteEntity={this.deleteTemplate}
              status={CONST.TEMPLATE_STATUSES.PUBLISHED}
              buttonsDisabled={template.isSaving}
            />

            {this.state.deleteAttrConfirmation.modalVisible && (
              <Modal
                isVisible={this.state.deleteAttrConfirmation.modalVisible}
                title={`Warning: Delete attribute ${this.state.deleteAttrConfirmation.attrLabel}`}
                cancelCb={this.toggleModal}
                confirmCb={this.removeAttribute}
                confirmText="Delete"
                cancelText="Cancel"
                content={
                  <React.Fragment>
                    <p>
                      Deleting the attribute {this.state.deleteAttrConfirmation.attrLabel} will
                      result in the removal of it from all matters using this template as well as
                      the associated data. To proceed enter the attribute name below and check the
                      box to confirm you understand the impact.
                    </p>
                    <div className={s.overflow}>
                      <p className={s.attrLabel}>Attribute name: </p>
                      <TextInput
                        type="text"
                        value={this.state.deleteAttrConfirmation.confirmAttrLabel}
                        onChange={this.confirmAttrLabel}
                        containerClassName={s.confirmAttrLabel}
                      />
                    </div>
                    <CheckboxContainer
                      onChange={this.confirmCheckbox}
                      defaultChecked={this.state.deleteAttrConfirmation.confirmCheckbox}
                    />
                    <span className={s.confirmCheckbox}>
                      I understand that by deleting this attribute that I am deleting data from all
                      matters associated with this template and the data is not recoverable.
                    </span>
                    {this.state.deleteAttrConfirmation.confirmError && (
                      <p className={s.confirmError}>You must confirm the attribute name.</p>
                    )}
                  </React.Fragment>
                }
              />
            )}
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default ContentContainer
