import * as React from 'react'
import swal from 'sweetalert'
import { connect } from 'react-redux'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import get from 'lodash/get'

import ACT from '../actions'

import {
  coreThemes,
  DataTableContainer,
  DataTableSerializer,
  DataTableService
} from 'simple-core-ui'
import { setPageTitle } from 'hocs'
import TeamsListTitle from 'teams/TeamsList/TeamsListTitle'
import { IoIosArrowBack } from 'react-icons/io'

const COLUMNS = [
  {
    columnKey: 'name',
    content: 'Name',
    isSortable: true,
    isFilterable: true,
    style: { width: '25%' }
  },
  {
    columnKey: 'description',
    content: 'Description',
    isSortable: true,
    isFilterable: true,
    style: { width: '50%' }
  },
  {
    columnKey: 'members',
    content: 'Members',
    isFilterable: true,
    filterableBy: 'fullName',
    style: { width: '25%' }
  }
]

const INITIAL_PARAMS = {
  pageSize: 25,
  ordering: { columnKey: 'name', isDesc: false },
  search: '',
  page: 1,
  category: 'teams'
}

@setPageTitle(({ credentials }) => {
  return {
    client: get(credentials, 'user.clientName', ''),
    view: 'Teams',
    detail: 'Management'
  }
})
@withFetchFlow({
  flag: 'InitializeTeamList',
  getFetchAction: () => ({
    type: ACT.TEAMS_FETCH_REQUESTED,
    payload: null,
    loadingStateDisabled: true
  })
})
@connect(({ teamsApp }) => {
  const { teams, teamsIsLoading, canEditTeams, teamLimit, downloadingXLSX } = teamsApp
  return { teams, teamsIsLoading, canEditTeams, teamLimit, downloadingXLSX }
})
class TeamsListContainer extends React.Component {
  state = {
    updateParams: INITIAL_PARAMS
  }

  getTableContents = () => {
    const { teams, canEditTeams } = this.props
    const { updateParams } = this.state

    const teamsWithActions = teams.map(team => ({
      ...team,
      actions: { useTrashcan: canEditTeams }
    }))

    const rowsByCategory = DataTableSerializer.getRowsByCategory(
      { teams: teamsWithActions },
      COLUMNS
    )
    const teamsService = new DataTableService(rowsByCategory, COLUMNS)

    return teamsService.getUpdatedResults(updateParams)
  }

  updateTable = updateParams => this.setState({ updateParams })

  deleteTeam = async teamId => {
    const { teams, dispatch } = this.props

    const name = get(
      teams.find(({ id }) => id === teamId),
      'name'
    )
    const willDelete = await swal({
      title: `Delete ${name} team?`,
      text: 'Users will not be deleted.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Delete Team']
    })

    if (willDelete) {
      dispatch({ type: ACT.TEAM_DELETE_REQUESTED, payload: teamId })
    }
  }

  showModal = teamId => {
    const { dispatch } = this.props

    teamId !== undefined
      ? dispatch({
          type: ACT.TEAM_SHOW_FOR_UPDATE_REQUESTED,
          payload: teamId
        })
      : dispatch({ type: ACT.TEAM_SHOW_FOR_CREATE })
  }

  downloadXLSX = () => {
    const { dispatch } = this.props
    dispatch({ type: ACT.TEAMS_DOWNLOAD_XLSX })
  }

  downloadXLSXButton = () => ({
    secondaryAction: {
      children: 'Download',
      isLoading: this.props.downloadingXLSX,
      isDisabled: this.props.downloadingXLSX || this.props.teams.length < 1,
      onClick: this.downloadXLSX
    }
  })

  render() {
    const { canEditTeams, teamLimit, teams, teamsIsLoading } = this.props
    const teamCount = teams.length
    return (
      <>
        <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
          <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
          Back to Administration
        </a>
        <DataTableContainer
          {...this.getTableContents()}
          {...(canEditTeams && teamCount < teamLimit
            ? {
                primaryAction: { children: 'Create Team', onClick: () => this.showModal() },
                ...this.downloadXLSXButton()
              }
            : this.downloadXLSXButton())}
          initialValues={INITIAL_PARAMS}
          renderCell={({ columnKey, content }) => {
            return columnKey !== 'members'
              ? content
              : content.length
              ? `${content.length} Member${content.length === 1 ? '' : 's'}`
              : ''
          }}
          title={<TeamsListTitle teamCount={teamCount} teamLimit={teamLimit} />}
          categories={[{ label: 'Teams', value: 'teams' }]}
          categoryValue="teams"
          isLoading={teamsIsLoading}
          clickRow={({ id }) => this.showModal(id)}
          deleteRow={({ id }) => this.deleteTeam(id)}
          updateTable={this.updateTable}
          hasActions
          theme={coreThemes.EB}
        />
      </>
    )
  }
}

export default TeamsListContainer
