import { MouseEvent, useState } from 'react'
import classNames from 'classnames'
import { OutsideClickContainer, Users } from 'simple-core-ui'
import { SelectCustomInput } from 'common/Selects'
import s from './UsersCheckboxSelect.scss'

interface Option {
  value: number
  label: string
}

interface Props {
  value: Option[]
  requestParams: { active?: boolean; matterId?: number; canEdit?: boolean; canRead?: boolean }
  updateSelectedUsers: (values: Option[]) => void
  isDisabled?: boolean
  scope?: string
  showEmail?: boolean
}

const MAX_USERS_IN_LABEL = 3

const UsersCheckboxSelect = ({
  value,
  requestParams = {},
  updateSelectedUsers,
  isDisabled,
  scope,
  showEmail = false
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const clearValue = (e: MouseEvent) => {
    e.stopPropagation()
    updateSelectedUsers([])
    setIsExpanded(false)
  }

  const getValueLabel = () => {
    const labeledUsers =
      value.length &&
      value
        .map((value: Option, index: number) => (index < MAX_USERS_IN_LABEL ? value.label : null))
        .filter(label => !!label)
        .join(', ')
    return value.length <= MAX_USERS_IN_LABEL
      ? labeledUsers
      : `${labeledUsers}, +${value.length - MAX_USERS_IN_LABEL}more`
  }

  return (
    <OutsideClickContainer closeComponent={() => setIsExpanded(false)}>
      <div className={classNames(s.container, { [s.expanded]: isExpanded })}>
        <SelectCustomInput
          value={getValueLabel()}
          onClick={() => setIsExpanded(expanded => !expanded)}
          onClear={clearValue}
          isDisabled={isDisabled}
        />
        {isExpanded && (
          <div className={s.usersContainer}>
            <Users
              scope={scope}
              value={value}
              requestParams={requestParams}
              onConfirm={(values: Option[]) => {
                updateSelectedUsers(values)
                setIsExpanded(false)
              }}
              togglePopper={() => setIsExpanded(false)}
              showEmail={showEmail}
            />
          </div>
        )}
      </div>
    </OutsideClickContainer>
  )
}

export default UsersCheckboxSelect
