import { sortAlphabeticallyByProperty } from 'utils/helpers'
import s from '../styles/RuleActions.scss'
import Action from './Action'

const RuleActions = ({
  actions,
  ruleIndex,
  availableActions,
  updateRuleAction,
  createRuleAction,
  removeRuleAction,
  saveRule
}) => (
  <div className={s.footer}>
    <header className={s.header}>actions:</header>
    <main className={s.main}>
      <ol className={s.actionsList}>
        {actions.map((action, actionIndex) => (
          <Action
            ruleIndex={ruleIndex}
            availableActions={sortAlphabeticallyByProperty(availableActions, 'display_name')}
            updateAction={v => updateRuleAction(v, actionIndex)}
            action={action}
            removeRuleAction={removeRuleAction}
            actionIndex={actionIndex}
            key={`act_${actionIndex}`}
          />
        ))}
      </ol>
    </main>
  </div>
)

export default RuleActions
