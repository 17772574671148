import { useState, useEffect } from 'react'
import pluralize from 'pluralize'
import s from './Notifications.scss'
import {
  Button,
  useLoading,
  DataTableWrapper,
  LawRuleIcon,
  UpdateToPremiumLabel,
  ShareFillIcon
} from 'simple-core-ui'
import { makeGetRequest, makePostRequest, makeDeleteRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiSolidMessageRoundedEdit } from 'react-icons/bi'
import { PiGavelFill } from 'react-icons/pi'
import { ActionsPopover } from './ActionsPopover'
import { toFileSharingUsers, toSelectedUsers, toUsers } from './serializers'
import { User, Option } from './types'
import { AddUserModal } from './AddUserModal'
import { DeleteConfirmation } from './DeleteConfirmation'
import { isBasicTaskManagementPlan, hasModule } from 'utils/helpers'
import cn from 'classnames'
import { SCOPE, MODULE } from 'utils/constants'
import { getNormalizedCellContent } from './utils'

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

interface Props {
  scope: string
  scopeId: string
  readOnly?: boolean
  type: 'courtRules' | 'fileSharing' | 'updates'
}

const initialState = {
  pageSize: 10,
  ordering: { columnKey: 'name', isDesc: false },
  search: '',
  page: 1,
  category: 'all'
}

const columns = [
  {
    columnKey: 'name',
    content: 'Name',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'role',
    content: 'Role',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'email',
    content: 'Email Address',
    isSortable: true,
    isFilterable: true
  },
  {
    columnKey: 'addedOn',
    content: 'Added On',
    isSortable: true,
    isFilterable: true
  }
]

const isBasicPlan = isBasicTaskManagementPlan()
const isMatterFileSharingEnabled = window.serverContext.get('data_for_react')
  ?.matter_file_sharing_permissions
const isMatterUpdateSharingEnabled = window.serverContext.get('data_for_react')
  ?.allow_vendor_collaboration_matter_updates

const Notifications = ({ scope, scopeId, readOnly, type }: Props) => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [view, setView] = useState(type)
  const [params, setParams] = useState(initialState)
  const [users, setUsers] = useState<User[]>([])
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false)
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [hasCalendarRules, setHasCalendarRules] = useState(null)

  const scopeName = scope.toLowerCase()

  const isCourtRulesView = view === 'courtRules'
  const isFileSharingView = view === 'fileSharing'
  const isUpdatesView = view === 'updates'

  const showAddUserButton =
    (isCourtRulesView && !isBasicPlan && hasCalendarRules) || isFileSharingView || isUpdatesView

  const fetchCalendarRulesConfig = async () => {
    try {
      const { configured } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setHasCalendarRules(configured)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchUsers = async () => {
    const url = isCourtRulesView
      ? `/event-management/calendar_rules/court-rules/notifications/${scopeId}`
      : isFileSharingView
      ? `/doc_management/native_docs/${scopeName}/${scopeId}/file_notifications/`
      : `/doc_management/native_docs/${scopeName}/${scopeId}/matter_updates_notifications/`
    try {
      const response = await withLoadingLocks(makeGetRequest(url))
      const formattedUsers = isCourtRulesView
        ? toUsers(response.users)
        : toFileSharingUsers(response.results)
      setUsers(formattedUsers)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    if (scope !== SCOPE.VENDOR && hasModule(MODULE.EVENT_MANAGEMENT)) fetchCalendarRulesConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((isCourtRulesView && hasCalendarRules) || isFileSharingView || isUpdatesView) {
      fetchUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, hasCalendarRules])

  const toggleAddUserModal = () => {
    setIsAddUserModalVisible(!isAddUserModalVisible)
  }

  const toggleDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalVisible(!isDeleteConfirmationModalVisible)
  }

  const handleDelete = (user: User) => {
    setSelectedUser(user)
    toggleDeleteConfirmationModal()
  }

  const addUsers = async (users: Option[] | User[]) => {
    try {
      const url = isCourtRulesView
        ? `/event-management/calendar_rules/court-rules/notifications/${scopeId}`
        : isFileSharingView
        ? `/doc_management/native_docs/${scopeName}/${scopeId}/file_notifications/`
        : `/doc_management/native_docs/${scopeName}/${scopeId}/matter_updates_notifications/`
      const response = await withLoadingLocks(
        makePostRequest(url, {
          user_ids: users.map(user => +user.value)
        })
      )
      const newUsers = response.data || response.users || []
      fetchUsers()
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: `${pluralize('user', newUsers.length, true)} successfully added.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    toggleAddUserModal()
  }

  const deleteUser = async () => {
    try {
      const url = isCourtRulesView
        ? `/event-management/calendar_rules/court-rules/notifications/${scopeId}`
        : isFileSharingView
        ? `/doc_management/native_docs/${scopeName}/${scopeId}/file_notifications/${selectedUser?.id}/`
        : `/doc_management/native_docs/${scopeName}/${scopeId}/matter_updates_notifications/${selectedUser?.id}/`

      await withLoadingLocks(
        makeDeleteRequest(
          url,
          isCourtRulesView
            ? {
                data: { user_ids: [selectedUser?.id] }
              }
            : {}
        )
      )
      fetchUsers()
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Success',
          message: `${selectedUser?.name} successfully deleted.`,
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
    toggleDeleteConfirmationModal()
  }

  const updateTable = (params: Params) => {
    setParams(params)
  }

  const renderCustomAction = (row: User) => {
    if (readOnly || users.length === 1) {
      return <span className={s.actionsPlaceholder}>...</span>
    }
    return <ActionsPopover userId={row.id} deleteUser={() => handleDelete(row)} />
  }

  const isSelectedUsersUpdated = (updatedUsers: Option[] | User[]) => {
    const currentUserIds = users.map(({ id }) => +id)
    return !updatedUsers.every(user => currentUserIds.includes(+(user as User).id || +user.value))
  }

  return (
    <>
      <div className={cn({ [s.wrapper]: scope === SCOPE.MATTER })}>
        {!readOnly && showAddUserButton ? (
          <Button
            style={{
              padding: '8px 15px',
              whiteSpace: 'nowrap',
              position: 'relative',
              borderRadius: '4px',
              bottom: 0
            }}
            className={cn(s.addBtn, { [s.vendorAddBtn]: scope === SCOPE.VENDOR })}
            isPrimary
            onClick={toggleAddUserModal}
            hasNewDesign
          >
            Add user
          </Button>
        ) : null}
        {scope === SCOPE.MATTER ? (
          <p className={s.title}>
            Manage Notifications
            {hasModule(MODULE.EVENT_MANAGEMENT) && (
              <span
                onClick={() => {
                  setParams(initialState)
                  setView('courtRules')
                }}
                className={cn({ [s.selected]: isCourtRulesView })}
              >
                <PiGavelFill
                  style={{ fontSize: 14, position: 'relative', top: 2, color: '#0957ae' }}
                />
                Court Rules
                {isBasicPlan && <UpdateToPremiumLabel dataTestId="secure-lock" />}
              </span>
            )}
            {isMatterFileSharingEnabled && (
              <span
                onClick={() => {
                  setParams(initialState)
                  setView('fileSharing')
                }}
                className={cn({ [s.selected]: isFileSharingView })}
              >
                <ShareFillIcon fill="#0957ae" stroke="#0957ae" style={{ marginBottom: '-8px' }} />
                File Sharing
              </span>
            )}
            {isMatterUpdateSharingEnabled && (
              <span
                onClick={() => {
                  setParams(initialState)
                  setView('updates')
                }}
                className={cn({ [s.selected]: isUpdatesView })}
              >
                <BiSolidMessageRoundedEdit
                  fill="#0957ae"
                  stroke="#0957ae"
                  style={{ marginBottom: '-5px', fontSize: '20px' }}
                />
                Updates
              </span>
            )}
          </p>
        ) : null}
        {scope === SCOPE.MATTER && isCourtRulesView && (isBasicPlan || !hasCalendarRules) ? (
          <div className={s.courtRulesNotConfigured}>
            {isBasicPlan ? (
              <>
                <span>Contact Customer Service to upgrade your plan for access to using </span>
                <span>
                  events with Court rules from <b>CalendarRules</b> .
                </span>
              </>
            ) : (
              <>
                <span>
                  Administrators can connect <b>CalendarRules</b> using the Integrations tab
                </span>
                <span>in Event Settings to access Court Rules.</span>
              </>
            )}
            <LawRuleIcon className={s.lawRuleIcon} />
          </div>
        ) : (
          <>
            {scope === SCOPE.MATTER && isCourtRulesView ? (
              <p className={s.subtitle}>
                <>
                  <AiOutlineInfoCircle className={s.info} /> Court Rule sets are populated through{' '}
                  <b>CalendarRules</b>, a third-party application. Choose who receives notifications
                  when Events with Court Rules are updated. There must be at least one user selected
                  for court rule notifications.
                </>
              </p>
            ) : null}
            {scope === SCOPE.MATTER && (isFileSharingView || isUpdatesView) ? (
              <p className={s.subtitle}>
                <AiOutlineInfoCircle className={s.info} />
                {`Choose who receives notifications when an
                assigned vendor shares ${
                  isFileSharingView
                    ? "files or updates a file that's been shared"
                    : 'a matter update'
                }. A minimum of
                1 user must be listed.`}
              </p>
            ) : null}
            <DataTableWrapper
              params={params}
              categories={[]}
              rows={users}
              columns={columns}
              updateTable={updateTable}
              panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
              className={s.itemsTable}
              customAction={renderCustomAction}
              hasActions
              hasTooltip
              alwaysShowActions
              customSearchOptions={{
                placeholder: 'Start typing to search',
                styles: { width: 270 }
              }}
              getNormalizedCellContent={getNormalizedCellContent}
              categoryKey="notifications"
              customActionsMemoizationDeps={[users]}
              customStatusText="There are currently none, click Add User to get started."
            />
          </>
        )}
        {isAddUserModalVisible && (
          <AddUserModal
            toggleAddUserModal={toggleAddUserModal}
            onSave={users => {
              isSelectedUsersUpdated(users) && addUsers(users)
            }}
            scopeId={+scopeId}
            view={view}
            scope={scope}
            selectedUsers={toSelectedUsers(users)}
          />
        )}
        {isDeleteConfirmationModalVisible && (
          <DeleteConfirmation
            onConfirm={() => deleteUser()}
            onCancel={() => {
              toggleDeleteConfirmationModal()
            }}
            userName={selectedUser?.name || ''}
            view={view}
          />
        )}
      </div>
    </>
  )
}

export default Notifications
