import classNames from 'classnames'
import { IoIosClose } from 'react-icons/io'
import { Actionable, HoverAction, ConditionalRender } from 'simple-core-ui'

import { StackList } from 'components'
import { APPROVAL_LEVEL_CONDITION } from '../utils/constants'
import { normalizeTeam } from '../utils/utils'

import s from '../styles/TierContent.scss'

import { BsPlusSquare } from 'react-icons/bs'

const ReviewerTierContent = ({
  tier,
  isNewTier,
  isEmptyTier,
  removeTier,
  openCb,
  readOnly,
  testid
}) => {
  return (
    <section className={classNames(s.container, { [s.readOnly]: readOnly })} data-testid={testid}>
      <ConditionalRender
        conditions={[
          {
            condition: isEmptyTier && readOnly,
            content: <span className={s.readOnlyEmpty}>No reviewers have been set.</span>
          },
          {
            condition: isEmptyTier,
            content: (
              <span
                className={s.newLevel}
                data-testid="add_new_level_reviewers_btn"
                onClick={openCb}
              >
                <BsPlusSquare style={{ marginRight: 8 }} />
                Add Reviewers
              </span>
            )
          },
          {
            condition: isNewTier && !readOnly,
            content: (
              <span
                className={s.newLevel}
                data-testid="add_next_level_reviewers_btn"
                onClick={openCb}
              >
                Next Level
              </span>
            )
          },
          {
            condition: isNewTier && readOnly,
            content: null
          }
        ]}
        fallback={
          <Actionable
            actions={
              readOnly
                ? []
                : [
                    <HoverAction
                      key={0}
                      icon={<span className={classNames('simple-line-icon-pencil', s.icon)} />}
                      onClick={openCb}
                      tip="Edit Reviewers"
                    />,
                    <HoverAction
                      key={1}
                      icon={<IoIosClose data-testid="clear_reviewers" />}
                      onClick={event => {
                        event.stopPropagation()
                        removeTier()
                      }}
                      tip="Clear Reviewers"
                    />
                  ]
            }
            style={{ padding: '1em 0.7em' }}
          >
            <h6 className={s.condition}>
              {tier.approvalClause === APPROVAL_LEVEL_CONDITION.ALL ? 'All of' : 'Any of'}
              {':'}
            </h6>
            <StackList stacks={tier.reviewers.map(normalizeTeam)} />
          </Actionable>
        }
      />
    </section>
  )
}

export default ReviewerTierContent
