import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Status } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  status: Status
  editStatus: (row: Status) => void
}

const ActionsPopover = ({ status, editStatus }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editStatus(status)
      }
    }
  ]

  return (
    <Popover
      testId={`item-${status.id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
