import s from './FileStatusIcon.scss'
import { FILE_STATUS_SCANNING, FILE_STATUS_CLEAN, FILE_STATUS_INFECTED } from '../constants'
import { Tooltip, CleanFileIcon, ScanningFileIcon, InfectedFileIcon } from 'simple-core-ui'

const FileStatusIcon = ({ fileStatus }) => {
  let status = ''
  let tip = ''

  if (fileStatus === FILE_STATUS_SCANNING) {
    status = <ScanningFileIcon />
    tip = 'File is still being scanned for malware.'
  } else if (fileStatus === FILE_STATUS_CLEAN) {
    status = <CleanFileIcon />
    tip = 'No malware detected.'
  } else {
    if (fileStatus === FILE_STATUS_INFECTED) {
      status = <InfectedFileIcon />
      tip = 'Malware was detected.'
    }
  }

  return (
    <>{tip && <Tooltip trigger={status} triggerClassName={s.checkboxWrapper} content={tip} />}</>
  )
}

export default FileStatusIcon
