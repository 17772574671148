import * as React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { If } from 'simple-core-ui'

import ACT from '../actions'
import { isClientContact, emptyContact } from '../utils'

import ContactUserSettings from './ContactUserSettings'

import { ORG_ROLE, MODULE } from 'utils/constants'
import { makePostRequest } from 'utils/api'
import { initialState } from '../reducer'

@connect(({ app, contacts }) => {
  const { credentials } = app
  const { contact, settings, loading } = contacts

  const requestingUser = get(credentials, 'user', {})
  const hasDashboardModule = get(credentials, 'modules', []).includes(MODULE.DASHBOARD)
  const hasTableauModule = get(credentials, 'modules', []).includes(MODULE.TABLEAU)

  return {
    isRequestingUser: Number(contact.userId) === Number(requestingUser.id),
    canEdit: requestingUser.role === ORG_ROLE.ADMIN || requestingUser.isCSM,
    isAdmin: requestingUser.role === ORG_ROLE.ADMIN,
    hasDashboardModule,
    hasTableauModule,
    contact,
    settings,
    isLoading: loading.settings
  }
})
class ContactUserSettingsContainer extends React.Component {
  static defaultProps = {
    contact: emptyContact,
    settings: initialState.settings,
    isRequestingUser: false,
    canEdit: false,
    canEditLoginPreference: false,
    hasDashboardModule: false,
    hasTableauModule: false,
    isLoading: true,
    dispatch: obj => {}
  }

  updateSetting = settingPair => {
    const { contact, dispatch } = this.props

    dispatch({
      type: ACT.CONTACT_USER_SETTING_UPDATE_REQUESTED,
      loadingLock: 'on',
      payload: {
        userId: contact.userId,
        ...settingPair
      }
    })
  }

  connectWithGoogle = () => {
    const newWindow = window.open(this.props.settings.googleConnectUrl, 'SSO Login Popup')

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus()
    }
  }

  disconnectWithGoogle() {
    makePostRequest('/oauth/google/deauthorize/').then(() => {
      window.location.reload()
    })
  }

  render = () => {
    const { isRequestingUser, canEdit, contact } = this.props
    const renderConditions = isClientContact(contact) && (isRequestingUser || canEdit)

    return (
      <If condition={renderConditions}>
        <ContactUserSettings
          {...this.props}
          updateSetting={this.updateSetting}
          connectWithGoogle={this.connectWithGoogle}
          disconnectWithGoogle={this.disconnectWithGoogle}
        />
      </If>
    )
  }
}

export default ContactUserSettingsContainer
