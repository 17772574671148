import ACT from './actions'
import { put, takeLatest } from 'redux-saga/effects'
import { fromTodos, toTodo } from './serializers'
import { SagaQuest, withQuest } from 'simple-core-ui/fetchFlow'
import { TODO_SCOPE } from './constants'
import {
  FETCH_TODOS_USER,
  REGISTER_FOR_FEATURE,
  getMatterTodosUrl,
  getCompleteTodoUrl,
  getDeleteTodoUrl,
  getCreateTodoUrl,
  getUpdateTodoUrl
} from './urls'

const todoQuest = new SagaQuest({ entity: 'Task' })

function* fetchTodos(action) {
  const { scope, scopeId } = action.payload
  const url = scope === TODO_SCOPE.GLOBAL ? FETCH_TODOS_USER : getMatterTodosUrl(scopeId)

  const response = yield todoQuest.makeGet(url)
  const todos = fromTodos(response.todos)

  yield put({
    type: ACT.TODO_FETCH_SUCCESS,
    payload: { todos, scope, scopeId }
  })
}

function* deleteTodo(action) {
  const { id } = action.payload

  const url = getDeleteTodoUrl(id)
  const response = yield todoQuest.makePost(url)

  yield put({
    type: ACT.TODO_DELETE_SUCCESS,
    loadingLock: 'off',
    payload: {
      id
    }
  })
}

function* completeTodo(action) {
  const { id, complete } = action.payload

  const url = getCompleteTodoUrl(id)
  const response = yield todoQuest.makePost(url, { complete })

  yield put({
    type: ACT.TODO_COMPLETE_SUCCESS,
    payload: {
      id
    }
  })

  yield put({
    type: ACT.TODO_FETCH_REQUESTED,
    payload: {}
  })
}

function* updateTodo(action) {
  const { todo, id, scopeId } = action.payload
  const todosScopeId = scopeId || window.serverContext.get('scope_id')
  const url = getUpdateTodoUrl(id)
  const newTodo = toTodo(todo)
  const response = yield todoQuest.makePost(url, newTodo)

  yield put({
    type: ACT.TODO_UPDATE_SUCCESS,
    loadingLock: 'off',
    payload: {}
  })

  yield put({
    type: ACT.TODO_FETCH_REQUESTED,
    payload: {
      scopeId
    }
  })
}

function* createTodo(action) {
  const { todo, scopeId } = action.payload
  const todosScopeId = scopeId || window.serverContext.get('scope_id')

  const url = getCreateTodoUrl(todosScopeId)
  const newTodo = toTodo(todo)
  const response = yield todoQuest.makePost(url, newTodo)

  yield put({
    type: ACT.TODO_CREATE_SUCCESS,
    loadingLock: 'off',
    payload: {}
  })

  yield put({
    type: ACT.TODO_FETCH_REQUESTED,
    payload: {
      scopeId
    }
  })
}

function* registerFeature(action) {
  const { feature } = action.payload
  const response = yield todoQuest.makePost(REGISTER_FOR_FEATURE, { feature })
  yield put({
    type: ACT.REGISTER_FOR_FEATURE_SUCCESS,
    payload: {}
  })

  yield put({
    type: ACT.PUSH_NOTIFICATION,
    payload: {
      title: 'Registration success',
      message: 'You have successfully registered for this feature.',
      level: 'success'
    }
  })
}

const todosSagas = [
  takeLatest(ACT.TODO_FETCH_REQUESTED, fetchTodos),
  takeLatest(ACT.TODO_CREATE_REQUESTED, withQuest(createTodo)),
  takeLatest(ACT.TODO_UPDATE_REQUESTED, withQuest(updateTodo)),
  takeLatest(ACT.TODO_DELETE_REQUESTED, withQuest(deleteTodo)),
  takeLatest(ACT.TODO_COMPLETE_REQUESTED, withQuest(completeTodo)),
  takeLatest(ACT.REGISTER_FOR_FEATURE_REQUESTED, withQuest(registerFeature))
]

export default todosSagas
