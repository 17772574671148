import moment from 'moment'

export const fromPendingInvoice = ({
  id,
  number,
  subject,
  total,
  date,
  matters,
  vendor,
  is_awaiting_approval // eslint-disable-line camelcase
}) => ({
  id,
  number,
  subject,
  total,
  date: moment(date).format('L'),
  matters: matters.map(([id, name]) => ({ id, name })),
  vendor,
  isAwaitingApproval: is_awaiting_approval
})

export const fromPendingInvoices = apiPendingInvoices => apiPendingInvoices.map(fromPendingInvoice)
