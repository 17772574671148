import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import ACT from 'app/actions'

const setPageTitle = title => {
  return WrappedComponent => {
    @connect(({ app }) => {
      return { pageTitle: app.pageTitle }
    })
    @withRouter
    class PageTitle extends Component {
      componentDidMount() {
        this.updatePageTitle(this.props)
      }
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.router.location !== nextProps.router.location) {
          this.updatePageTitle(nextProps)
        }
      }
      updatePageTitle = props => {
        this.props.dispatch({
          type: ACT.UPDATE_PAGE_TITLE,
          payload: {
            ...title(props)
          }
        })
      }
      render() {
        return <WrappedComponent {...this.props} />
      }
    }
    return PageTitle
  }
}

export default setPageTitle
