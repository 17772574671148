import keyMirror from 'keymirror'

export default keyMirror({
  EVENT_CREATE_REQUESTED: null,
  EVENT_CREATE_SUCCESS: null,
  EVENT_UPDATE_REQUESTED: null,
  EVENT_UPDATE_SUCCESS: null,
  EVENT_DELETE_REQUESTED: null,
  EVENT_DELETE_SUCCESS: null,
  EVENTS_FETCH_REQUESTED: null,
  EVENTS_FETCH_SUCCESS: null,
  EDIT_MATTER_EVENT: null,
  CANCEL_MATTER_EVENT: null,
  OPEN_EVENT_MODAL: null,
  CALENDAR_LIST_FETCH_REQUESTED: null,
  CALENDAR_LIST_FETCH_SUCCESS: null,
  CALENDAR_LIST_CREATE_EVENT_SUCCESS: null,
  CALENDAR_LIST_CREATE_EVENT_REQUESTED: null
})
