import { Panel, Button, If, Anchor, CopiableContainer, HoverAction, DESIGN } from 'simple-core-ui'

import RulesDSLEditor from './RulesDSLEditor'

import { ENABLED_ENGINES, ENGINE_LABEL } from 'rules/constants'
import s from '../styles/RulesDSL.scss'
import TabContext from 'context/TabContext'

const { spacing } = DESIGN

const generateEngineTabs = ({ changeEngine, selectedEngine }) =>
  ENABLED_ENGINES.map(engine => ({
    text: ENGINE_LABEL[engine],
    isSelected: engine === selectedEngine,
    onClick: () => changeEngine(engine)
  }))

const RulesDSL = ({
  text,
  engine,
  dsl,
  submit,
  toggleEdit,
  cancelEdit,
  updateText,
  changeEngine
}) => {
  const { isLoading, isEdit } = dsl
  const rulesText = dsl[engine] ? dsl[engine].rulesText : ''
  return (
    <main className={s.container}>
      <TabContext.Consumer>
        {updateTabs => updateTabs(generateEngineTabs({ changeEngine, selectedEngine: engine }))}
      </TabContext.Consumer>
      <Anchor
        url={`/v2/rules/list?engine=${engine}`}
        style={{ marginBottom: spacing.sp400, display: 'inline-block' }}
      >
        Back to Rules
      </Anchor>
      <Panel
        title={`${ENGINE_LABEL[engine]} - DSL Editor`}
        className={s.panel}
        rightActions={[
          <CopiableContainer
            textToCopy={rulesText}
            hoverActionProps={{
              className: s.hoverAction,
              size: 'small',
              isDisabled: isLoading || isEdit
            }}
            key={0}
          />,
          <HoverAction
            key={1}
            onClick={toggleEdit}
            isDisabled={isLoading || isEdit}
            tip="Edit Rules"
            icon={<span className={`simple-line-icon-pencil ${s.editIcon}`} />}
            size="small"
            className={s.hoverAction}
          />
        ]}
      >
        <RulesDSLEditor
          value={isEdit ? text : rulesText}
          onChange={updateText}
          readOnly={!isEdit || isLoading}
        />
        <If condition={isEdit}>
          <div className={`${s.buttonRow} m-t-sp500`}>
            <Button isSecondary onClick={cancelEdit} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button isPrimary onClick={submit} isLoading={isLoading}>
              Save
            </Button>
          </div>
        </If>
      </Panel>
    </main>
  )
}

export default RulesDSL
