import { Fragment } from 'react'

import { Condition, Operand } from 'simple_review/@types/api'
import ExpressionReadOnly from 'simple_review/expressions/ExpressionReadOnly'
import s from './ConditionReadOnly.scss'

interface Props {
  condition: Condition
  ruleName: string
}

const ConditionReadOnly = ({ condition, ruleName }: Props) => {
  const { op, operands } = condition

  return (
    <div className={s.condition}>
      {operands.map((operand, index) => (
        <Fragment key={index}>
          <ExpressionReadOnly operand={operand as Operand} ruleName={ruleName} />
          {index < operands.length - 1 && <div className={s.booleanOperator}>{op}</div>}
        </Fragment>
      ))}
    </div>
  )
}

export default ConditionReadOnly
