import { useState, useEffect, useRef } from 'react'
import BaseFilter from './BaseFilter'

const SimpleSelectFilter = ({
  testid,
  title,
  onSelect,
  initialValue,
  hasConfirmation,
  customLabel,
  reset,
  isMulti,
  render
}) => {
  const [entity, setEntity] = useState(initialValue)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setEntity(null)
    }
  }, [reset])

  const onCancel = () => {
    setEntity(initialValue)
  }

  const setMultiEntity = arr => {
    setEntity(!arr || !arr.length ? arr : null)
  }

  const changeCallback = isMulti ? setMultiEntity : setEntity

  return (
    <BaseFilter
      testid={testid}
      title={title}
      reset={reset}
      onSelect={onSelect}
      onCancel={onCancel}
      initialValue={initialValue}
      customLabel={customLabel}
      hasConfirmation={hasConfirmation}
      render={(setFilterSelection, setIsOpen) => {
        return render(entity, val => {
          changeCallback(val)
          setFilterSelection(val)
          setIsOpen(false)
        })
      }}
    />
  )
}

export default SimpleSelectFilter
