import React, { useState, useEffect } from 'react'
import { makeGetRequestCache } from 'utils/api'
import { TextInput, useLoading } from 'simple-core-ui'
import AutoCheckboxItem from './AutoCheckboxItem'
import s from './AutoComplete.scss'
import { Constant } from 'simple_review/@types/common'
import { ScrollbarWrapper } from './ScrollbarWrapper'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactTooltip from 'react-tooltip'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

interface Option {
  text: string
  id: number
}

interface Props {
  url: string
  onChange: (option: Constant) => void
  selection: Constant[]
  searchPlaceholder?: string
  isPaginated?: boolean
  searchParam?: string
  pageSizeParam?: string
  reqParams?: Record<string, unknown>
  serializer?: (options: []) => Constant[]
}

const defaultSerializer = (response: Option[]) => {
  return response.map(o => ({ label: o.text, value: String(o.id) }))
}

export function toggleCheck(
  option: Constant,
  selection: Constant[],
  setStateHandler: React.Dispatch<React.SetStateAction<Constant[]>>
): void {
  if (selection.some(s => s.value === option.value)) {
    setStateHandler(prevState => {
      const newState = prevState.filter(s => s.value !== option.value)
      return newState
    })
  } else {
    setStateHandler(prevState => {
      const newState = [...prevState, option]
      return newState
    })
  }
}

// This CheckboxList component is designed to work with select2_json endpoints
// select2_json endpoints are always paginated
function AutoCheckboxList({
  url,
  onChange,
  selection = [],
  searchPlaceholder = '',
  isPaginated = true,
  searchParam = 'search_term',
  pageSizeParam = 'page_size',
  reqParams = {},
  serializer = defaultSerializer
}: Props) {
  const [options, setOptions] = useState<Constant[]>([])
  const [filteredOptions, setFilteredOptions] = useState<Constant[]>([])
  const [isLoading, load] = useLoading('disable-lock')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const dispatch = useDispatch()
  const PAGE_SIZE = 10

  const fetchItems = async (p: number) => {
    const params = { ...reqParams }
    if (isPaginated) {
      params[searchParam] = search
      params[pageSizeParam] = PAGE_SIZE
      params.page = p
    }
    const response = await load(
      makeGetRequestCache(url, {
        params
      })
    )
    return response
  }

  const fetchMoreItems = async () => {
    try {
      const newPage = page + 1
      const response = await fetchItems(newPage)
      const serializedResults = serializer(response.results)
      setPage(newPage)
      setHasMore(response.more)
      setOptions(options.concat(serializedResults))
      setFilteredOptions(filteredOptions.concat(serializedResults))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const searchItems = async () => {
    try {
      const response = await fetchItems(page)
      const serializedResults = serializer(response.results)
      setHasMore(response.more)
      setOptions(serializedResults)
      setFilteredOptions(serializedResults)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(
    () => {
      setPage(1)
      searchItems()
    },
    isPaginated ? [search] : []
  )

  const handleSearch = (value: string) => {
    setSearch(value)
    const newFilteredOptions = options.filter(o => {
      if (!value) return true
      return o.label.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredOptions(newFilteredOptions)
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [options, selection])

  return (
    <>
      <TextInput
        value={search}
        onChange={isPaginated ? setSearch : handleSearch}
        placeholder={searchPlaceholder}
        isLoading={isLoading}
        debounceDelay={200}
      />
      <ul className={s.options}>
        <ScrollbarWrapper id="scrollableDiv">
          <InfiniteScroll
            next={() => {
              fetchMoreItems()
            }}
            hasMore={hasMore}
            loader={<div></div>}
            dataLength={options.length}
            scrollableTarget="scrollableDiv"
          >
            {sortAlphabeticallyByProperty(selection, 'label').map(option => {
              // sometimes, backend can only send us the id. in this case,
              // we assume that id is the same for both label and value
              // and then find the option with the value. Works only for non paginated
              // because we need the entire list of options for it to work.
              if (option.label === option.value) {
                option.label = options.find(o => o.value === option.value)?.label || option.label
              }

              return (
                <li key={option.value}>
                  <AutoCheckboxItem option={option} onSelect={onChange} isSelected />
                </li>
              )
            })}

            {filteredOptions
              .filter(o => !selection.some(s => o.value === s.value))
              .map(option => (
                <li key={option.value}>
                  <AutoCheckboxItem option={option} onSelect={onChange} />
                </li>
              ))}
          </InfiniteScroll>
        </ScrollbarWrapper>
      </ul>
    </>
  )
}

export default AutoCheckboxList
