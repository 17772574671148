import { Panel, LoadingSkeleton } from 'simple-core-ui'
import { v4 as uuidv4 } from 'uuid'

export const AttrsLoadingSkeleton = () => {
  return (
    <Panel isBodyOnly>
      <LoadingSkeleton
        key={uuidv4()}
        height={18}
        width={120}
        style={{ marginTop: 12, marginBottom: 28 }}
      />
      <LoadingSkeleton key={uuidv4()} height={14} width={80} style={{ marginBottom: 10 }} />
      <LoadingSkeleton key={uuidv4()} height={2} style={{ marginBottom: 12 }} />
      {[...new Array(6)].map(() => (
        <LoadingSkeleton key={uuidv4()} height={24} style={{ marginBottom: 18 }} />
      ))}
    </Panel>
  )
}

export const ActivityLoadingSkeleton = () => {
  return (
    <>
      <LoadingSkeleton key={uuidv4()} height={2} style={{ marginBottom: 24, marginTop: 20 }} />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={{ marginBottom: 12 }} />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={{ marginBottom: 12 }} />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={{ marginBottom: 12 }} />
      <LoadingSkeleton key={uuidv4()} width={200} height={18} style={{ marginBottom: 12 }} />
    </>
  )
}
