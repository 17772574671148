import head from 'lodash/head'
import flattenDeep from 'lodash/flattenDeep'
import get from 'lodash/get'

import { identifyEntries } from './identifyEntry'
import { partiallyApproved } from './statusEval'
import { formatMember, formatMemberNames, formatReviewedBy } from './formatting'
import { ENTRY_TYPE, REVIEW_STATUS } from './constants'

const PENDING_REVIEW = {
  reviewedOn: '',
  reviewStatus: REVIEW_STATUS.PENDING
}

export const extractEntry = (entry, scope) => {
  const { responses, soloUserId } = entry
  const response = head(responses) || PENDING_REVIEW
  const { reviewedOn, reviewStatus } = response

  const reviewerName = soloUserId ? '' : get(response, 'reviewerName')

  const date = reviewedOn
    ? formatReviewedBy(scope, reviewStatus, reviewedOn, reviewerName)
    : reviewedOn

  return { name: formatMember(entry), date, status: reviewStatus }
}

export const extractEntryGroup = (tier, scope) => {
  const entryType = identifyEntries(tier.entries) || ENTRY_TYPE.SINGLE

  if (entryType === ENTRY_TYPE.SINGLE) {
    console.error('Attempting to extract entry group from invalid structure', tier)
  }

  const entries = flattenDeep(tier.entries)

  const membership = formatMemberNames(entries, entryType)

  const { tierResponse } = tier

  const response = {
    reviewStatus: get(tierResponse, 'reviewStatus', REVIEW_STATUS.PENDING),
    reviewedOn: get(tierResponse, 'reviewedOn', ''),
    reviewerName: get(tierResponse, 'reviewerName', '')
  }

  const reviewedByOn = tierResponse
    ? formatReviewedBy(scope, response.reviewStatus, response.reviewedOn, response.reviewerName)
    : ''

  return {
    status: tier.status,
    isPartiallyApproved: partiallyApproved(tier),
    membership,
    reviewedByOn,
    group: entries
  }
}

export const singleFromEntries = entries => head(flattenDeep(entries))

export const extractRejectedResponse = reviewTiers => {
  const rejectedTier = reviewTiers.find(
    ({ tierResponse }) => get(tierResponse, 'reviewStatus') === REVIEW_STATUS.REJECTED
  )

  return get(rejectedTier, 'tierResponse')
}
