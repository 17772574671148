import { createReducer } from 'redux-create-reducer'

import ACT from './actions'
import { REVIEW_STATUS } from './utils/constants'

export const initialState = {
  review: {
    id: null,
    adminActions: [],
    reviewableStatus: '',
    userMessage: '',
    isFinalApproval: false,
    actionPermissions: {
      canFinalize: false,
      canAdd: false,
      canRemove: false,
      canApprove: false,
      canUnapprove: false,
      canReject: false,
      canUnreject: false,
      canSendBack: false
    },
    state: REVIEW_STATUS.PENDING,
    ladder: {
      subscribers: [],
      tiers: []
    }
  },
  isHydrating: true
}

const reviewLadderReducer = createReducer(initialState, {
  [ACT.HYDRATE_SUCCESS](state, action) {
    return {
      ...state,
      review: action.payload,
      isHydrating: false
    }
  },
  [ACT.IS_HYDRATING](state, action) {
    return {
      ...state,
      isHydrating: true
    }
  },
  [ACT.IS_NOT_HYDRATING](state, action) {
    return {
      ...state,
      isHydrating: false
    }
  }
})

export default reviewLadderReducer
