import { InitialForm } from './types'

export const serializeUserData = (formData: InitialForm) => {
  const { firstName, lastName, email, role, loginPreference, teams, notifyOn } = formData
  const notifObj = notifyOn.reduce((acc, value) => {
    acc[`notif_${value}`] = 'on'
    return acc
  }, {} as Record<string, string>)

  return {
    first_name: firstName,
    last_name: lastName,
    email: email,
    orgrole: role.value,
    login_preference: loginPreference.value,
    'teams[]': teams.map(team => team.value),
    ...notifObj
  }
}

export const toFormData = (formObj: Record<string, any>) => {
  const bodyFormData = new FormData()
  for (const key in formObj) {
    const value = formObj[key]
    if (value !== null && value !== undefined) {
      if (value instanceof Array) {
        value.forEach(v => bodyFormData.append(key, v))
      } else {
        bodyFormData.append(key, value)
      }
    }
  }
  return bodyFormData
}
