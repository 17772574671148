import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { put, takeLatest } from 'redux-saga/effects'
import { makePostRequest, makeGetRequest, makeDeleteRequest, makePutRequest } from 'utils/api'
import { toSettingPostData, toSettingsData, toAdminsSelect } from './serializers'

import ACT from './actions'
import APP_ACT from 'app/actions'

import { NotificationList } from 'components'

function* createCategory(action) {
  const { category } = action.payload

  try {
    const url = '/doc_management/native_docs/categories'
    const response = yield makePostRequest(url, category)

    yield put({
      type: ACT.ADD_CATEGORY_SUCCESS,
      payload: {
        category: response.data
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Category added successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue creating the Category',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* editCategory(action) {
  const { category } = action.payload

  try {
    const url = `/doc_management/native_docs/categories/${category.id}`
    const response = yield makePutRequest(url, category)

    yield put({
      type: ACT.EDIT_CATEGORY_SUCCESS,
      payload: {
        category: response.data
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Category edited successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue editing the Category',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* editLabel(action) {
  const { label } = action.payload

  try {
    const url = `/doc_management/native_docs/labels/${label.id}`
    const response = yield makePutRequest(url, label)

    yield put({
      type: ACT.EDIT_LABEL_SUCCESS,
      payload: {
        label: response.data
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Label edited successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue editing the label',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchCategories(action) {
  const url = '/doc_management/native_docs/categories'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.CATEGORIES_FETCH_SUCCESS,
      payload: { categories: response.results }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Categories',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* deleteCategory(action) {
  const { id } = action.payload

  try {
    const url = `/doc_management/native_docs/categories/${id}`
    yield makeDeleteRequest(url)

    yield put({
      type: ACT.DELETE_CATEGORY_SUCCESS,
      payload: {
        id
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Category deleted successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue deleting the Category',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* deleteLabel(action) {
  const { id } = action.payload
  try {
    const url = `/doc_management/native_docs/labels/${id}`
    yield makeDeleteRequest(url)

    yield put({
      type: ACT.DELETE_LABEL_SUCCESS,
      payload: {
        id
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Label deleted successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue deleting the label',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchLabels(action) {
  const url = '/doc_management/native_docs/labels'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.LABELS_FETCH_SUCCESS,
      payload: { labels: response.results }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Labels',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* createLabel(action) {
  const { label } = action.payload

  try {
    const url = '/doc_management/native_docs/labels'
    const response = yield makePostRequest(url, label)

    yield put({
      type: ACT.ADD_LABEL_SUCCESS,
      payload: {
        label: response.data
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Label added successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue creating the Label',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchPreviewSettings(action) {
  const url = '/doc_management/native_docs/settings'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.PREVIEW_SETTINGS_FETCH_SUCCESS,
      payload: { previewSettings: toSettingsData(response.settings) }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Preview Settings',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* editPreviewSetting(action) {
  const { setting } = action.payload

  try {
    const url = `/doc_management/native_docs/settings/`
    yield makePostRequest(url, toSettingPostData(setting))

    yield put({
      type: ACT.EDIT_PREVIEW_SETTING_SUCCESS,
      payload: {
        setting
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Setting edited successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue editing the Setting',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchNotifications(action) {
  const url = '/doc_management/native_docs/malware_notifications'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.NOTIFICATIONS_FETCH_SUCCESS,
      payload: { notifications: response.results }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Notifications',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* fetchAdmins(action) {
  const url = '/company/user/admin_users_json/'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.ADMINS_FETCH_SUCCESS,
      payload: { admins: toAdminsSelect(response.admin_users) }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Admin Users',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* addAdmin(action) {
  const { id } = action.payload

  try {
    const url = '/doc_management/native_docs/malware_notifications'
    const response = yield makePostRequest(url, { user_id: id })

    yield put({
      type: ACT.ADD_ADMIN_SUCCESS,
      payload: {
        admin: response.data
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Admin added successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue adding the Admin',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* deleteAdmin(action) {
  const { id } = action.payload

  try {
    const url = `/doc_management/native_docs/malware_notifications/${id}`
    yield makeDeleteRequest(url)

    yield put({
      type: ACT.DELETE_ADMIN_SUCCESS,
      payload: {
        id
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Admin removed successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue removing the admin',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchInfectedFiles(action) {
  const url = '/doc_management/native_docs/infected_files_list'
  try {
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.INFECTED_FILES_FETCH_SUCCESS,
      payload: { infectedFiles: response.files }
    })
  } catch (e) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Infected Files',
        message: <NotificationList lines={formatResponse(e.response.data)} />,
        level: 'error'
      }
    })
  }
}

function* deleteInfectedFile(action) {
  const { id } = action.payload

  try {
    const url = `doc_management/native_docs/versions/${id}/`
    yield makePutRequest(url)

    yield put({
      type: ACT.DELETE_INFECTED_FILE_SUCCESS,
      payload: {
        id: id
      }
    })
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'File deleted successfully',
        level: 'success'
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue deleting the File',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

const documentManagementSettingsSagas = [
  takeLatest(ACT.ADD_CATEGORY_REQUESTED, createCategory),
  takeLatest(ACT.CATEGORIES_FETCH_REQUESTED, fetchCategories),
  takeLatest(ACT.DELETE_CATEGORY_REQUESTED, deleteCategory),
  takeLatest(ACT.DELETE_LABEL_REQUESTED, deleteLabel),
  takeLatest(ACT.EDIT_CATEGORY_REQUESTED, editCategory),
  takeLatest(ACT.EDIT_LABEL_REQUESTED, editLabel),
  takeLatest(ACT.LABELS_FETCH_REQUESTED, fetchLabels),
  takeLatest(ACT.ADD_LABEL_REQUESTED, createLabel),
  takeLatest(ACT.PREVIEW_SETTINGS_FETCH_REQUESTED, fetchPreviewSettings),
  takeLatest(ACT.EDIT_PREVIEW_SETTING_REQUESTED, editPreviewSetting),
  takeLatest(ACT.ADMINS_FETCH_REQUESTED, fetchAdmins),
  takeLatest(ACT.NOTIFICATIONS_FETCH_REQUESTED, fetchNotifications),
  takeLatest(ACT.ADD_ADMIN_REQUESTED, addAdmin),
  takeLatest(ACT.DELETE_ADMIN_REQUESTED, deleteAdmin),
  takeLatest(ACT.INFECTED_FILES_FETCH_REQUESTED, fetchInfectedFiles),
  takeLatest(ACT.DELETE_INFECTED_FILE_REQUESTED, deleteInfectedFile)
]

export default documentManagementSettingsSagas
