import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  ruleId: string | undefined
  onDelete(): void
  onClone(): void
  onToggleVersionHistory(): void
}

const ActionsPopover = ({ ruleId = 'new', onClone, onDelete, onToggleVersionHistory }: Props) => {
  const isDisabled = ruleId === 'new'

  const actions = [
    {
      name: 'CLONE',
      text: 'Clone',
      visible: !isDisabled,
      onClick() {
        onClone()
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      visible: !isDisabled,
      onClick() {
        onDelete()
      }
    },
    {
      name: 'VERSION_HISTORY',
      text: 'Version History',
      visible: !isDisabled,
      onClick() {
        onToggleVersionHistory()
      }
    }
  ]

  return (
    <Popover
      testId="rule-actions"
      trigger={
        <HoverAction
          icon={<IoIosMore />}
          size="medium"
          className={s.hoverAction}
          isDisabled={isDisabled}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
