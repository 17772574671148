import { Component } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import get from 'lodash/get'
import omit from 'lodash/omit'
import isNull from 'lodash/isNull'
import differenceBy from 'lodash/differenceBy'

import ContactQuickForm from './ContactQuickForm'

import ACT from '../actions'

import { SCOPE } from 'utils/constants'
import {
  containsCounselGo,
  containsCounselGoAdmin,
  getSingularRoleIntersect,
  isClientContact,
  getDependentRoles,
  getWarningMessageType,
  maxCGAdminsWarningMessageType,
  getCGAdminsNo,
  setDataWithMessage,
  hasMatterLead,
  isMatterOpen
} from '../utils'

const EMPTY_FIELDS = {
  firstName: '',
  lastName: '',
  title: '',
  vendor: null,
  company: '',
  email: '',
  phone: '',
  id: -1,
  roles: []
}

const CUSTOM_OPTION = { label: 'Custom...', value: -1 }

@connect(({ app, contacts }, { contactToUpdate }) => {
  const { scope } = app
  const { assignedContacts, roles, rolesSettings } = contacts

  const contactFields = contactToUpdate
    ? find(assignedContacts, contact => contact.id === contactToUpdate.id)
    : EMPTY_FIELDS

  const companyField = get(contactFields, 'org.name', '')
  const vendorField =
    isNull(contactFields ? contactFields.vendor : null) && companyField
      ? CUSTOM_OPTION
      : contactFields.vendor

  const fields = {
    ...contactFields,
    vendor: vendorField,
    company: companyField
  }

  return {
    scope: scope.scope,
    scopeId: scope.id,
    hasCGAccess: containsCounselGo(fields.roles),
    fields,
    assignedContacts,
    roles,
    rolesSettings
  }
})
class ContactQuickFormContainer extends Component {
  static defaultProps = {
    scope: SCOPE.MATTER,
    scopeId: -1,
    hasCGAccess: false,
    fields: EMPTY_FIELDS,
    assignedContacts: [],
    dispatch: obj => {},
    rolesSettings: null,
    roles: []
  }

  constructor(props) {
    super(props)
    this.state = {
      fields: this.props.fields,
      isValid: this.canSubmit(this.props.fields),
      isDeleting: false,
      warningMessageType: '',
      rolesSettings: this.props.rolesSettings,
      showMatterLeadWarning: false
    }
  }

  requiredFields = ['firstName', 'lastName', 'email', 'vendor']

  componentDidUpdate(prevProps) {
    const { currentCGAdminsNo } = this.props.rolesSettings
    if (prevProps.rolesSettings.currentCGAdminsNo !== currentCGAdminsNo) {
      this.setCGAdminsNo(currentCGAdminsNo)
    }
  }

  canSubmit = fields => {
    const canSubmit = this.requiredFields.every(requiredField =>
      typeof fields[requiredField] === 'string'
        ? fields[requiredField].trim().length
        : fields[requiredField]
    )

    if (fields.vendor && fields.vendor.value === -1) {
      return canSubmit && fields.company.trim().length
    }

    return canSubmit
  }

  makeFieldsSerializable = () => {
    const { fields } = this.state
    const { scopeId, scope, contactToUpdate } = this.props

    const vendorFieldValue =
      scope === SCOPE.VENDOR && !fields.vendor ? scopeId : get(fields, 'vendor.value')

    let fieldsToOmit

    if (isClientContact(contactToUpdate)) {
      fieldsToOmit = ['vendor', 'company']
    } else if (fields.company) {
      fieldsToOmit = ['vendor']
    } else {
      fieldsToOmit = ['company']
    }

    const fieldsToSerialize = omit(
      {
        ...fields,
        vendor: vendorFieldValue
      },
      fieldsToOmit
    )

    return fieldsToSerialize
  }

  submitHandler = (opts = {}) => {
    const { isValid, rolesSettings } = this.state
    const { isUpdating, scope, scopeId, submitCb, dispatch, contactToUpdate } = this.props

    if (isValid) {
      const fieldsToSerialize = this.makeFieldsSerializable()
      submitCb(fieldsToSerialize)

      if (opts.onlyUnassign) {
        return dispatch({
          type: ACT.ASSIGNED_CONTACT_REMOVAL_REQUESTED,
          payload: {
            scope,
            scopeId,
            contact: contactToUpdate,
            ...fieldsToSerialize
          }
        })
      }

      const action = isUpdating ? ACT.CONTACT_UPDATE_REQUESTED : ACT.CONTACT_CREATION_REQUESTED

      dispatch({
        type: action,
        payload: {
          scope,
          scopeId,
          ...fieldsToSerialize
        }
      })

      const { currentCGAdminsNo } = rolesSettings
      const prevRoles = contactToUpdate ? contactToUpdate.roles : []
      const currentRoles =
        containsCounselGoAdmin(prevRoles) && !containsCounselGoAdmin(fieldsToSerialize.roles)
          ? currentCGAdminsNo - 1
          : currentCGAdminsNo
      this.props.changeCGAdminsNoCb(currentRoles)
      this.clearForm()
    }
  }

  cancelHandler = () => {
    this.clearForm()
    this.props.cancelCb()
  }

  clearForm = () => {
    this.setState({
      fields: EMPTY_FIELDS,
      isValid: false
    })
  }

  changeHandler = (field, value) => {
    this.setState(
      prevState => {
        return {
          fields: {
            ...prevState.fields,
            company: field === 'vendor' ? '' : prevState.fields.company,
            [field]: value
          }
        }
      },
      () => {
        this.setState({
          isValid: this.canSubmit(this.state.fields)
        })
      }
    )
  }

  setCGAdminsNo = currentCGAdminsNo => {
    this.setState(prevState => {
      return {
        rolesSettings: {
          ...prevState.rolesSettings,
          currentCGAdminsNo
        }
      }
    })
  }

  setRolesData = (roles, currentCGAdminsNo, warningMessageType) => {
    this.setState(prevState => {
      return {
        fields: {
          ...prevState.fields,
          roles
        },
        warningMessageType
      }
    })
    this.setCGAdminsNo(currentCGAdminsNo)
  }

  hasMatterLeadRole = roles => {
    return roles.some(role => role.systemName === '_ml')
  }

  changeRolesHandler = selectedRoles => {
    const { rolesSettings, fields } = this.state
    const currentRoles = this.props.roles
    const contactFields = this.props.fields
    const { matterStatus } = this.props

    const hasMatterLeadRole = hasMatterLead(contactFields.roles)
    const isMatterLeadInSelectedRoles = hasMatterLead(selectedRoles)

    const isRemovingMatterLead =
      hasMatterLeadRole && !isMatterLeadInSelectedRoles && isMatterOpen(matterStatus)

    if (isRemovingMatterLead) {
      this.setState({ showMatterLeadWarning: true })
    } else {
      this.setState({ showMatterLeadWarning: false })
    }

    const { currentCGAdminsNo, maxAllowedCGAdmins } = rolesSettings

    if (containsCounselGoAdmin(currentRoles)) {
      const addedRole = differenceBy(selectedRoles, fields.roles, 'systemName')
      const deletedRole = differenceBy(fields.roles, selectedRoles, 'systemName')

      const roles = getDependentRoles(selectedRoles, fields.roles, currentRoles)
      const isExistingContact = fields.id !== -1
      const warningMessageType = getWarningMessageType(
        deletedRole,
        selectedRoles,
        roles,
        isExistingContact
      )

      if (currentCGAdminsNo + 1 > maxAllowedCGAdmins && containsCounselGoAdmin(addedRole)) {
        this.setState({
          warningMessageType: maxCGAdminsWarningMessageType
        })
      } else {
        this.setRolesData(
          roles,
          getCGAdminsNo(addedRole, deletedRole, currentCGAdminsNo),
          warningMessageType
        )
      }
    } else {
      setDataWithMessage(selectedRoles, currentCGAdminsNo, this.setRolesData)
    }
  }

  singularRoleIntersect = () => {
    const { contactToUpdate, assignedContacts } = this.props

    const filteredAssignedContacts = contactToUpdate
      ? assignedContacts.filter(contact => contact.id !== contactToUpdate.id)
      : assignedContacts

    return getSingularRoleIntersect(filteredAssignedContacts, this.state.fields.roles)
  }

  checkIfCustomCompany = vendorField =>
    (!!this.state.fields.company && isNull(vendorField)) || get(vendorField, 'value') === -1

  checkIfLockReason = () => {
    const { contactToUpdate } = this.props

    const isClientContact = get(contactToUpdate, 'type') === 'client'
    const isVendorContact = get(contactToUpdate, 'type') === 'vendor'

    return isClientContact || isVendorContact
  }

  checkIfUnassignmentPrompt = () => {
    const { isUpdating, hasCGAccess } = this.props
    const { fields } = this.state
    const roles = isNull(fields.roles) ? [] : fields.roles

    const cgAccessRevoked = hasCGAccess && !containsCounselGo(roles)
    const emptyRoles = roles.length === 0

    return isUpdating && (cgAccessRevoked || emptyRoles)
  }

  filterCompanyOptions = (option, search) => {
    const { scope, scopeId } = this.props
    const { label, value } = option

    if (value === -1) return true

    return scope === SCOPE.VENDOR
      ? label.toLowerCase().includes(search.toLowerCase()) && value === Number(scopeId)
      : label.toLowerCase().includes(search.toLowerCase())
  }

  deleteHandler = () => {
    const { dispatch, contactToUpdate, cancelCb, changeCGAdminsNoCb } = this.props
    const { rolesSettings } = this.state

    dispatch({
      type: ACT.CONTACT_DELETE_REQUESTED,
      payload: contactToUpdate
    })

    if (containsCounselGoAdmin(contactToUpdate.roles)) {
      changeCGAdminsNoCb(rolesSettings.currentCGAdminsNo - 1)
    }

    this.clearForm()
    cancelCb()
  }

  promptDeleteHandler = isDeleting => {
    this.setState({ isDeleting })
  }

  render() {
    return (
      <ContactQuickForm
        fields={this.state.fields}
        cancelCb={this.cancelHandler}
        submitCb={this.submitHandler}
        changeCb={this.changeHandler}
        changeRolesCb={this.changeRolesHandler}
        deleteCb={this.deleteHandler}
        promptDeleteCb={this.promptDeleteHandler}
        isValid={this.state.isValid}
        isUpdating={this.props.isUpdating}
        isDeleting={this.state.isDeleting}
        isCustomCompany={this.checkIfCustomCompany(this.state.fields.vendor)}
        showLockReason={this.checkIfLockReason()}
        checkIfUnassignmentPrompt={this.checkIfUnassignmentPrompt}
        contactToUpdate={this.props.contactToUpdate}
        hasCGAccess={this.props.hasCGAccess}
        singularRoleIntersect={this.singularRoleIntersect()}
        filterCompanyOptions={this.filterCompanyOptions}
        warningMessageType={this.state.warningMessageType}
        userCanEditContactCG={this.props.userCanEditContactCG}
        showMatterLeadWarning={this.state.showMatterLeadWarning}
      />
    )
  }
}

export default ContactQuickFormContainer
