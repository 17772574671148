import { useEffect, useState, useMemo } from 'react'
import s from './File.scss'
import { ChartInfo } from './ChartInfo'
import { useFilesUpload, Alert } from 'simple-core-ui'
import cn from 'classnames'
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios'
import { isolateBucketFromUrl } from '../../utils'
import { serializeImportParams } from 'bulk/serializer'
import {
  ActionOptionType,
  CustomFile,
  FieldOptionType,
  FileImportResponse,
  Option
} from 'bulk/types'

interface Props {
  file: CustomFile
  successCb: (res: FileImportResponse) => void
  setIsUploadFinished: (arg: boolean) => void
  setFileSource: (arg: CancelToken | CancelTokenSource) => void
  setFileId: (arg: number) => void
  selectedField: FieldOptionType
  selectedAction: ActionOptionType
  templateType: Option
  fileUploadError: boolean
  setFileUploadError: (arg: boolean) => void
  setErrorData: (err: AxiosError) => void
  fileId: number
}

const AxiosCancelToken = axios.CancelToken

const File = ({
  file,
  successCb,
  setIsUploadFinished,
  setFileSource,
  setFileId,
  selectedField,
  selectedAction,
  templateType,
  fileUploadError,
  setFileUploadError,
  setErrorData
}: Props) => {
  const source = AxiosCancelToken.source()

  const [percent, setPercent] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const requestBody = useMemo(
    () => serializeImportParams(selectedAction, selectedField, templateType),
    [selectedAction, selectedField, templateType]
  )

  const handleError = (errorData: Record<string, string> = {}) => {
    setIsUploadFinished(true)
    setFileUploadError(true)
    setErrorMessage(errorData.message)
  }

  const uploadFile = (file: CustomFile) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFilesUpload({
      uploadUrl: '/bulkv2/import_csv/',
      withS3Credentials: true,
      successCb: response => {
        setCompleted(true)
        successCb(response)
      },
      handleServerErrorCb: (error = {}) => {
        setErrorData(error)
        handleError(error.response?.data)
      },
      errorCb: () => {
        handleError()
      },
      s3successCb: async (bucketUrl, fileKey, fileName, fileSize, fileType) => {
        const params = {
          [fileKey]: {
            bucket: isolateBucketFromUrl(bucketUrl),
            file_key: fileKey,
            file_name: fileName,
            file_size: fileSize,
            file_type: fileType,
            lifespan: 'short'
          }
        }
        await axios.post('/upload/s3/register/files', params).then(response => {
          setFileId(response.data.s3_file_ids[0])
          requestBody.file_id = response.data.s3_file_ids[0]
        })
      },
      config: {
        onUploadProgress: function(progressEvent) {
          setPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        cancelToken: source.token
      },
      altRequestBody: requestBody,
      files: [file as CustomFile],
      lifespan: 'short'
    })
  }

  const startUpload = () => {
    uploadFile(file)
    setFileSource(source)
  }

  useEffect(() => {
    startUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.row}>
      <div className={s.chart}>
        <div
          className={cn(s.segment, {
            [s.error]: fileUploadError
          })}
          style={{ width: `${fileUploadError ? 100 : percent}%` }}
        />
        <ChartInfo completed={completed} error={fileUploadError} file={file} percent={percent} />
      </div>
      {errorMessage && <Alert className={s.alert} message={errorMessage} status="error" />}
    </div>
  )
}

export default File
