import * as React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import Toggle from 'react-toggle'
import { ModalContainer } from 'simple-core-ui'

import * as ACT from 'matters/templates/actions'

@connect(({ matter }) => {
  return {
    lockedState: matter.isConfidential
  }
})
class ConfidentialMatterToggle extends React.Component {
  componentDidMount() {
    const matterId = this.matterId

    if (matterId) {
      this.props.dispatch({
        type: ACT.MATTER_DETAIL_FETCH_REQUESTED,
        payload: matterId
      })
    }
  }

  matterId = queryString.parse(location.search).matterId

  render() {
    const { lockedState, checked, onChange } = this.props

    return (
      <ModalContainer
        title="Confidential Matter"
        content={
          <section>
            <p>Confidential Matters will only be available to users granted access to it.</p>
            <br />
            <p>
              Only those users granted access can view the Confidential Matter and its associated
              details including invoices and reports. Once the matter is created, the confidential
              setting cannot be changed.
            </p>
            <br />
            <strong>Do you wish to continue?</strong>
          </section>
        }
        confirmText="Yes"
        cancelText="No"
        confirmCb={() => onChange(true)}
        cancelCb={() => onChange(false)}
      >
        {openModal => (
          <Toggle
            checked={this.matterId ? lockedState : checked}
            onChange={() => (checked ? onChange(false) : openModal())}
            disabled={Boolean(this.matterId)}
          />
        )}
      </ModalContainer>
    )
  }
}

export default ConfidentialMatterToggle
