import { EditableField } from 'components'
import s from './Comparisons.scss'
import get from 'lodash/get'

const Comparisons = ({
  vendors,
  onComparisonTypeChange,
  onVendorChange,
  practiceAreas,
  comparisons,
  onDatesChange,
  onClearComparisons,
  onPracticeAreaChange
}) => (
  <div className={s.container}>
    <span className={s.label}>Compare Against</span>
    <div className={s.against}>
      <EditableField
        type="select"
        value={comparisons.against}
        placeholder="Choose Comparison"
        options={[
          {
            value: 'pa',
            label: 'Other Practice Area'
          },
          {
            value: 'date',
            label: 'Other Survey Period'
          },
          {
            value: 'vendor',
            label: 'Other Vendor'
          }
        ]}
        onChange={onComparisonTypeChange}
      />
    </div>
    {get(comparisons, 'against.value') === 'date' && (
      <div className={s.dateRange}>
        <EditableField
          type="controlled-daterange"
          value={comparisons.dateRange}
          placeholderStart="Surveyed Start Date"
          placeholderEnd="Surveyed End Date"
          onChange={value => onDatesChange(value, 'comparisons')}
        />
      </div>
    )}
    <div className={s.flexWrapper}>
      {get(comparisons, 'against.value') === 'pa' && (
        <EditableField
          type="select"
          options={practiceAreas}
          placeholder="Practice Area"
          className={s.practiceArea}
          value={comparisons.practiceArea}
          onChange={value => onPracticeAreaChange(value, 'comparisons')}
        />
      )}
      {get(comparisons, 'against.value') === 'vendor' && (
        <EditableField
          type="select"
          options={vendors}
          placeholder="Vendors"
          className={s.practiceArea}
          value={comparisons.vendor}
          onChange={onVendorChange}
        />
      )}
    </div>
    <p onClick={onClearComparisons} className={s.clearComparisons}>
      clear comparisons
    </p>
  </div>
)

export default Comparisons
