import { Component } from 'react'
import { withRouter } from 'simple-core-ui/hocs'
import { connect } from 'react-redux'
import { TemplateSelection } from '../common/TemplateSelection'
import * as ACT from '../actions'
import { PAGE_DESCRIPTIONS } from '../constants'

@withRouter
@connect(({ matterTemplatesSelection }) => {
  const { userFlowState } = matterTemplatesSelection

  return {
    userFlowState
  }
})
class SelectPreferredPageContainer extends Component {
  state = {}

  static getDerivedStateFromProps(nextProps) {
    return {
      preferredList: nextProps.list.filter(l => l.belongs_to_form).map(l => l.id)
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ACT.MATTER_TEMPLATE_FORM_LIST_CLEAR_PREFERRED })
  }

  getTemplate = selectedTemplate => {
    const { router, userFlowState } = this.props
    // gets the default template set for the user
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_PREVIEW,
      payload: {
        selectedTemplate
      }
    })

    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        id: selectedTemplate.id,
        navigate: router.navigate,
        userFlowState: userFlowState
      }
    })
  }

  removePreferred = id => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED,
      payload: {
        id,
        value: false
      }
    })
  }

  setPreferred = (id, name, description) => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED,
      payload: {
        id,
        value: true,
        name: name,
        description: description
      }
    })
  }

  togglePreferred = id => {
    const index = this.state.preferredList.findIndex(value => value === id)

    if (~index) {
      this.removePreferred(id)
    } else {
      const selectedTemplate = this.props.list.filter(value => value.id === id)[0]
      const { name, description } = selectedTemplate
      this.setPreferred(id, name, description)
    }
  }

  navigateTo = (to, state) => {
    this.props.router.navigate(to, { state })
  }

  navigateToFormPage = () => {
    const { id } = this.props.userFlowState
    const navState = this.props.router.location.state
    const url = `/v2/matters/requests/form/${id || ''}`
    this.navigateTo(url, navState && navState.fromFormPage && { fromSelectionPage: true })

    this.props.dispatch({
      type: ACT.REQUEST_FORM_SET_TEMPLATES_LIST
    })
  }

  navigateToFormPageWithoutIds = () => {
    const { id } = this.props.userFlowState
    const navState = this.props.router.location.state
    const url = navState ? `/v2/matters/requests/form/${id || ''}` : '/v2/matters/requests/forms'
    this.navigateTo(url, navState && navState.fromFormPage && { fromSelectionPage: true })
  }

  render() {
    return (
      <TemplateSelection
        togglePreferred={this.togglePreferred}
        list={this.props.list}
        getTemplate={this.getTemplate}
        showSidebar={this.props.showSidebar}
        toggleTemplateSidebar={this.toggleTemplateSidebar}
        navigateToFormPage={this.navigateToFormPage}
        navigateToFormPageWithoutIds={this.navigateToFormPageWithoutIds}
        noOfPreferred={this.state.preferredList.length}
        title={this.props.title}
        description={this.props.description}
        processedDescription={this.props.processedDescription}
        pageKey={PAGE_DESCRIPTIONS.FORM_TEMPLATES_SELECT_PAGE_KEY}
      />
    )
  }
}

export default SelectPreferredPageContainer
