import { Component } from 'react'
import get from 'lodash/get'
import partial from 'lodash/partial'

import { OutsideClickContainer } from 'simple-core-ui'
import { ComboBox } from 'components'
import debounce from 'lodash/debounce'

class ComboBoxContainer extends Component {
  state = {
    filterValue: '',
    isOpen: false,
    isEmpty: false,
    isLoading: false,
    options: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options
    })
  }

  componentDidMount() {
    this.fetchOptions = debounce(this.fetchOptions, 200)
  }

  updateFilter = value => {
    this.setState({
      filterValue: value
    })

    if (this.props.loadCb) {
      this.fetchOptions(value)
    }
  }

  clearFilter = () => {
    this.setState({
      filterValue: ''
    })
  }

  triggerBox = () => {
    this.setState(
      prevState => ({
        isOpen: !prevState.isOpen,
        isLoading: Boolean(this.props.loadCb)
      }),
      () => !get(this.state.options, 'length', 0) && this.fetchOptions(this.state.filterValue)
    )
  }

  closeBox = ctx => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      this.props.onClose && this.props.onClose(ctx || { outsideClick: false })
    }
  }

  handleSelection = option => {
    if (!get(option, 'isGroup', false)) {
      this.closeBox()
    }

    this.clearFilter()
    this.props.selectionCb(option)
  }

  handleNavigateBack = () => {
    this.clearFilter()
    this.props.navigateBackCb()
  }

  filterOptions = () =>
    this.props.options
      ? this.props.options.filter(option => {
          const labelLower = option.label.toLowerCase()
          const filterLower = this.state.filterValue.toLowerCase()

          return labelLower.includes(filterLower)
        })
      : null

  fetchOptions = async value => {
    if (this.state.isOpen && this.props.loadCb) {
      this.setState({ isLoading: true })
      const response = await this.props.loadCb(value)

      return this.fetchOptionsSuccess(response)
    }
  }

  fetchOptionsSuccess = resp => {
    const response = resp.options || resp
    this.setState({
      isLoading: false,
      options: response,
      isEmpty: !response.length
    })
  }

  hideHandler = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <OutsideClickContainer closeComponent={partial(this.closeBox, { outsideClick: true })}>
        <ComboBox
          isOpen={this.state.isOpen}
          filterValue={this.state.filterValue}
          options={this.props.loadCb ? this.state.options : this.filterOptions()}
          triggerCb={this.triggerBox}
          filterCb={this.updateFilter}
          heading={this.props.heading}
          loadCb={this.props.loadCb}
          selectionCb={this.handleSelection}
          navigateBackCb={this.props.navigateBackCb && this.handleNavigateBack}
          hideCb={this.hideHandler}
          label={this.props.label}
          custom={this.props.custom}
          isLoading={this.state.isLoading}
          isEmpty={this.state.isEmpty}
          disabled={this.props.disabled}
          style={this.props.style}
        />
      </OutsideClickContainer>
    )
  }
}

export default ComboBoxContainer
