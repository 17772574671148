import { call, put, takeLatest } from 'redux-saga/effects'
import { makePostRequest } from 'utils/api'
import { toParams } from './serializers'
import ACT from './actions'

function* fetchSearchResults(action) {
  const url = '/doc_management/native_docs/search'
  const { params, q } = action.payload
  const builtParams = toParams(q, params)

  try {
    const response = yield call(makePostRequest, url, builtParams)

    yield put({
      type: ACT.SEARCH_RESULTS_FETCH_SUCCESS,
      loadingLock: 'off',
      payload: { results: response.results, params }
    })
  } catch (error) {
    yield put({
      type: 'API_ERROR',
      error
    })
  }
}

const appSagas = [takeLatest(ACT.SEARCH_RESULTS_FETCH_REQUESTED, fetchSearchResults)]

export default appSagas
