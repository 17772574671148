import * as React from 'react'
import { connect } from 'react-redux'
import { coreThemes, DataTableContainer, DataTableService } from 'simple-core-ui'
import isEqual from 'lodash/isEqual'

import ACT from '../actions'
import { initialState } from '../reducer'

import PendingInvoicesCell from './PendingInvoicesCell/PendingInvoicesCell'

import {
  getNormalizedCellContent,
  filterPendingInvoices,
  PENDING_INVOICES_COLUMN_KEYS
} from '../utils'

@connect(({ invoices }) => {
  const { pendingInvoices } = invoices
  const { rows, columns, isLoading } = pendingInvoices

  return { allRows: rows, columns, isLoading }
})
class PendingInvoicesContainer extends React.Component {
  static defaultProps = {
    allRows: initialState.pendingInvoices.rows,
    columns: initialState.pendingInvoices.columns,
    isLoading: initialState.pendingInvoices.isLoading,
    dispatch: obj => {}
  }

  constructor(props) {
    super(props)
    const { allRows, columns } = props
    const { getUpdatedState, initialValues } = PendingInvoicesContainer
    this.state = getUpdatedState(allRows, columns, initialValues)
  }

  componentDidMount() {
    const { dispatch, userId } = this.props
    dispatch({ type: ACT.PENDING_INVOICES_FETCH_REQUESTED, payload: { userId } })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.allRows, prevState.allRows)) {
      const { getUpdatedState, initialValues } = PendingInvoicesContainer
      const { allRows, columns } = nextProps

      return getUpdatedState(allRows, columns, initialValues)
    }

    return null
  }

  static initialValues = {
    pageSize: 10,
    ordering: { columnKey: PENDING_INVOICES_COLUMN_KEYS.DATE, isDesc: true },
    search: '',
    page: 1,
    category: 'invoices'
  }

  static getUpdatedState = (allRows, columns, updateParams) => {
    const service = new DataTableService(
      { invoices: allRows },
      columns,
      getNormalizedCellContent,
      filterPendingInvoices
    )

    return {
      allRows,
      ...service.getUpdatedResults(updateParams)
    }
  }

  updateTable = updateParams => {
    const { allRows, columns } = this.state
    const { getUpdatedState } = PendingInvoicesContainer

    this.setState(getUpdatedState(allRows, columns, updateParams))
  }

  categories = [{ label: 'Invoices', value: 'invoices' }]

  renderCell = ({ rowId, columnKey, content }) => (
    <PendingInvoicesCell rowId={rowId} columnKey={columnKey} content={content} />
  )

  getTableProps = () => ({
    initialValues: {
      ...PendingInvoicesContainer.initialValues,
      page: this.state.page
    },
    title: `Invoices (${this.state.filteredTotal})`,
    categories: this.categories,
    panelTitle: 'Pending Invoices',
    renderCell: this.renderCell,
    updateTable: this.updateTable,
    isLoading: this.props.isLoading,
    theme: coreThemes.EB
  })

  render = () => {
    return <DataTableContainer {...this.state} {...this.getTableProps()} />
  }
}

export default PendingInvoicesContainer
