export const fromRoles = roles => {
  return roles.map(role => ({
    label: role.text,
    value: role.value
  }))
}

export const fromReferralEvaluators = evaluators => {
  return evaluators.map(evaluator => ({ ...evaluator, hasChanged: false }))
}
