import { useEffect, useReducer, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from 'simple-core-ui'
import s from './InfectedFilesContainer.scss'
import { FILTER_TYPE } from 'doc_management/settings/constants'
import ACT from 'doc_management/settings/actions'
import { InfectedFiles } from './InfectedFiles'
import { resultsSerializer } from './serializers'

const initialState = {
  params: {
    pageSize: 25,
    ordering: { columnKey: 'fileName', isDesc: false },
    search: '',
    page: 1,
    category: FILTER_TYPE.ALL.toLowerCase()
  },
  selectedFile: {}
}

const reducer = (state, action) => {
  const { params, selectedFile } = action.payload
  switch (action.type) {
    case ACT.UPDATE_TABLE_PARAMS:
      return { ...state, params }
    case ACT.SELECT_INFECTED_FILE:
      return { ...state, selectedFile }
    default:
      throw new Error('Action not found')
  }
}

const InfectedFilesContainer = () => {
  const { infectedFiles } = useSelector(state => state.documentManagementSettings)
  const dispatch = useDispatch()
  const [localState, dispatchState] = useReducer(reducer, initialState)
  const { params, selectedFile } = localState

  const results = useMemo(() => {
    return resultsSerializer(infectedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infectedFiles, params])

  useEffect(() => {
    dispatch({ type: ACT.INFECTED_FILES_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTable = params => {
    dispatchState({
      type: ACT.UPDATE_TABLE_PARAMS,
      payload: {
        params
      }
    })
  }

  const selectInfectedFile = fileId => {
    const selectedFile = infectedFiles.find(file => file.id === fileId)
    dispatchState({
      type: ACT.SELECT_INFECTED_FILE,
      payload: {
        selectedFile
      }
    })
  }

  const deleteFile = file => {
    const { id } = file
    dispatch({
      type: ACT.DELETE_INFECTED_FILE_REQUESTED,
      payload: {
        id
      }
    })
  }

  return (
    <Panel
      title="Manage Quarantined Files"
      styles={{ boxShadow: 'none' }}
      className={s.infectedFilesContainer}
    >
      <InfectedFiles
        params={params}
        results={results}
        updateTable={updateTable}
        selectedFile={selectedFile}
        selectInfectedFile={selectInfectedFile}
        deleteFile={deleteFile}
      />
    </Panel>
  )
}

export default InfectedFilesContainer
