import * as React from 'react'
import pick from 'lodash/pick'

import ACT from '../actions'

import ClientKeyModal from './ClientKeyModal.js'

const emptyState = {
  key_val: '',
  type: '',
  secret_key: '',
  other_key: '',
  bucket: '',
  bucket_path: '',
  custom_field1_name: '',
  custom_field1: '',
  custom_field2_name: '',
  custom_field2: ''
}

export default class ClientKeyModalContainer extends React.Component {
  state = pick(this.props, [
    'key_val',
    'type',
    'secret_key',
    'other_key',
    'bucket',
    'bucket_path',
    'custom_field1_name',
    'custom_field1',
    'custom_field2_name',
    'custom_field2'
  ])

  onUpdateTextField = (field, value) => {
    this.setState({ [field]: value })
  }

  clearModal = () => {
    this.props.dispatch({ type: ACT.CLIENT_KEY_MODAL_CLOSE })
    this.setState(emptyState)
  }

  onCancel = () => {
    this.clearModal()
  }

  onSubmit = () => {
    const { showForUpdate, dispatch } = this.props
    const action = showForUpdate
      ? {
          type: ACT.CLIENT_KEY_UPDATE_REQUESTED,
          payload: {
            ...showForUpdate,
            ...this.state
          }
        }
      : {
          type: ACT.CLIENT_KEY_CREATE_REQUESTED,
          payload: this.state
        }

    dispatch(action)
    this.clearModal()
  }

  render() {
    const { showForCreate } = this.props

    return (
      <ClientKeyModal
        {...this.state}
        showForCreate={showForCreate}
        onUpdateTextField={this.onUpdateTextField}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
      />
    )
  }
}
