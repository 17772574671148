import { OutsideClickContainer, Button, useWithPopper } from 'simple-core-ui'
import { ReactNode, CSSProperties, useState } from 'react'
import s from './Filter.scss'
import cn from 'classnames'
import Select, { StylesConfig } from 'react-select'
import { DEFAULT_OPERATOR_LIST } from './constants'
import { capitalizeSentence } from 'utils/helpers'
import pluralize from 'pluralize'
import { OPERATORS } from '../../constants'
import { Placement } from '@floating-ui/react'

const { IS_SET, IS_NOT_SET } = OPERATORS

const customStyle: StylesConfig<Option, false> = {
  control: (provided, state) => {
    if (state.hasValue) {
      return {
        ...provided,
        border: '1px solid #3c99fd',
        '&:hover': {
          borderColor: '#3c99fd'
        },
        '&:focus': {
          borderColor: '#3c99fd'
        },
        boxShadow: 'none'
      }
    }
    return {
      ...provided,
      boxShadow: 'none'
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    if (!state.data) return provided
    return {
      ...provided,
      color: '#3c99fd'
    }
  }
}

interface Option {
  value: string | number
  label: string
}

interface InjectedProps {
  setFilters: (values: Option[]) => void
  filters: Filters | null
}

interface Props {
  value: Filters | null
  customLabel?: () => string
  children: (props: InjectedProps) => ReactNode
  label: string
  style?: CSSProperties
  placement?: Placement
  labelPosition?: 'left' | 'right'
  operatorsList?: Option[]
  hasOperators?: boolean
  onConfirm: (filters: Filters | null, cb: () => void) => void
  placeholder?: string
  validation?: (filters: Filters | null) => boolean
}

interface Filters {
  operator: Option | null
  values?: Option[] | null
}

const initialFilters = {
  operator: null,
  values: null
}

const Filter = ({
  value,
  customLabel,
  children,
  label,
  style,
  placement = 'bottom-start',
  labelPosition = 'left',
  operatorsList,
  hasOperators = true,
  onConfirm,
  placeholder,
  validation
}: Props) => {
  const { isPopperOpen, togglePopper, setReference, setFloating, floatingStyles } = useWithPopper({
    placement
  })
  const [filters, setFilters] = useState<Filters | null>(initialFilters)
  const [oldFilters, setOldFilters] = useState<Filters | null>(filters)

  if (value !== oldFilters) {
    setFilters(value)
    setOldFilters(value)
  }

  const setCustomFilter = (values: Option[]) => {
    setFilters({
      operator: filters?.operator ?? null,
      values
    })
  }

  const isDisabled = () => {
    if (!filters) return true

    return Boolean(
      (hasOperators &&
        filters.operator &&
        ![IS_SET, IS_NOT_SET].includes(String(filters.operator.value)) &&
        (filters.values ? !filters.values.length : !filters.values)) ||
        (hasOperators && !filters.operator && filters.values && filters.values.length) ||
        (validation && !validation(filters))
    )
  }

  const getLabel = () => {
    if (customLabel) return customLabel()

    if (value) {
      if (value.operator && value.values) {
        return `${value.values.length === 1 ? label : ''} ${
          value.values.length === 1 ? value.operator.label.toLowerCase() : value.operator.label
        } ${
          value.values.length === 1
            ? decodeURIComponent(value.values[0].label)
            : value.values.length + ' ' + pluralize(capitalizeSentence(label))
        }`
      }
      if (value.operator) {
        return `${label} ${value.operator.label.toLowerCase()}`
      }
    }
    return placeholder || label
  }

  return (
    <div style={style} className={s.filter}>
      <div
        ref={setReference}
        className={cn(s.pill, {
          [s.active]: isPopperOpen,
          [s.applied]: typeof value === 'function' || !!value
        })}
        onClick={togglePopper}
      >
        {getLabel()}
      </div>

      {isPopperOpen && (
        <OutsideClickContainer
          styles={{ height: 'initial' }}
          closeComponent={() => {
            togglePopper()
          }}
        >
          <div tabIndex={-1} style={floatingStyles} ref={setFloating} className={s.popper}>
            <div className={s.content}>
              {labelPosition === 'left' && <div className={s.label}>{placeholder || label}</div>}
              {hasOperators && (
                <Select
                  value={filters?.operator ?? null}
                  className={s.operators}
                  options={operatorsList || DEFAULT_OPERATOR_LIST}
                  onChange={op => {
                    setFilters({
                      ...(filters ? filters : {}),
                      operator: op,
                      ...(op && [IS_SET, IS_NOT_SET].includes(String(op.value))
                        ? { values: null }
                        : {})
                    })
                  }}
                  styles={customStyle}
                />
              )}
              {labelPosition === 'right' && (
                <div style={{ marginRight: 10 }} className={s.label}>
                  {placeholder || label}
                </div>
              )}
              {filters &&
              filters.operator &&
              [IS_SET, IS_NOT_SET].includes(String(filters.operator.value))
                ? null
                : children({ filters, setFilters: setCustomFilter })}
            </div>
            <div className={s.footer}>
              <Button hasNewDesign isPrimary isOutline onClick={() => setFilters(initialFilters)}>
                Clear
              </Button>
              <Button
                hasNewDesign
                onClick={() => {
                  onConfirm &&
                    onConfirm(
                      (hasOperators && filters?.operator) || (!hasOperators && filters?.values)
                        ? filters
                        : null,
                      () => {
                        togglePopper()
                      }
                    )
                }}
                isPrimary
                isDisabled={isDisabled()}
              >
                Apply
              </Button>
            </div>
          </div>
        </OutsideClickContainer>
      )}
    </div>
  )
}

export default Filter
