import { useState } from 'react'
import { Tabs, Tab } from 'simple-core-ui'
import s from './AttachmentsTabContainer.scss'
import { MatterAttachmentsTab } from './MatterAttachmentsTab'
import VendorFilesTab from './VendorFilesTab/VendorFilesTab'
import qs from 'query-string'

const parsedQuery = qs.parse(window.location.search)

const tabIndices = {
  our_files: 0,
  vendor_files: 1
}

const isMatterFileSharingEnabled = window.serverContext.get('data_for_react')
  ?.matter_file_sharing_permissions

const AttachmentsTabContainer = ({ domProps }) => {
  const [selectedTab, setSelectedTab] = useState(
    isMatterFileSharingEnabled && parsedQuery.category === 'vendor_files'
      ? 'vendor_files'
      : 'our_files'
  )

  return (
    <>
      <div className={s.marginClass}>
        <Tabs selectedIndex={tabIndices[selectedTab]}>
          <Tab
            selected={selectedTab === 'our_files'}
            onClick={() => {
              setSelectedTab('our_files')
            }}
            header="Our Files"
          >
            <div className={s.marginClass}>
              <MatterAttachmentsTab
                domProps={domProps}
                hasVendorCollaboration
                isMatterFileSharingEnabled={isMatterFileSharingEnabled}
              />
            </div>
          </Tab>
          {!!isMatterFileSharingEnabled && (
            <Tab
              selected={selectedTab === 'vendor_files'}
              onClick={() => {
                setSelectedTab('vendor_files')
              }}
              header="Shared With Us"
            >
              <div className={s.vendorFiles}>
                <VendorFilesTab />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  )
}

export default AttachmentsTabContainer
