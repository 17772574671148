import s from '../AddTask.scss'
import { KeyValuePairs } from 'simple-core-ui'
import Select, { StylesConfig } from 'react-select'
import { hex2rgba } from 'utils/helpers'
import { LocalTask, InitialTask, Task, Subtask, DueDateValue } from '../../types'
import { toTask } from '../serializers'

interface Option {
  value: number
  label: string
  color?: string
}

interface Props {
  priorities: Option[]
  taskTypes: Option[]
  task: LocalTask | InitialTask
  saveTask?: (task: Task, isEdit: boolean | undefined, resetEditedProperties?: () => void) => void
  editedProperties?: Partial<LocalTask>
  resetEditedProperties?: () => void
  updateTask: (
    value: string | null | Option | Option[] | boolean | undefined | Subtask[] | DueDateValue,
    property: string
  ) => void
  isEdit?: boolean
}

const customStyle: StylesConfig<Option, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const color = state.data.color
    if (!color) return provided
    return {
      ...provided,
      borderRadius: '4px',
      color: '#000000',
      padding: '2px 12px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      minWidth: '100px',
      maxWidth: 'fit-content',
      backgroundColor: hex2rgba(color, 0.15),
      border: `1px solid ${color}`,
      position: 'relative',
      bottom: '1px'
    }
  }
}

const noneOption = { value: -1, label: 'None' }

const Overview = ({
  priorities,
  taskTypes,
  task,
  updateTask,
  saveTask,
  isEdit,
  editedProperties,
  resetEditedProperties
}: Props) => {
  return (
    <KeyValuePairs
      style={{ margin: '20px 0 0 0' }}
      pairs={[
        {
          key: 'Priority',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: s.section,
          value: (
            <Select
              options={[noneOption, ...priorities]}
              value={task.priority}
              onChange={item => {
                if (item?.value === -1) {
                  item = null
                }
                updateTask(item, 'priority')
                if (isEdit) {
                  saveTask &&
                    saveTask(
                      toTask({ id: editedProperties?.id, priority: item } as LocalTask),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
            />
          )
        },
        {
          key: 'Type',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: s.section,
          value: (
            <Select
              options={[noneOption, ...taskTypes]}
              value={task.taskType}
              onChange={item => {
                if (item?.value === -1) {
                  item = null
                }
                updateTask(item, 'taskType')
                if (isEdit) {
                  saveTask &&
                    saveTask(
                      toTask({ id: editedProperties?.id, taskType: item } as LocalTask),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          )
        }
      ]}
    />
  )
}

export default Overview
