import * as ACT from '../actions'
import { createReducer } from 'redux-create-reducer'

const initialState = {
  filterTerm: '',
  groups: [],
  dynamicDropdowns: []
}

const matterTemplatesAttributesReducer = createReducer(initialState, {
  [ACT.MATTER_TEMPLATES_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { groups } = action.payload

    const newState = {
      ...state,
      ...groups
    }

    return newState
  },

  [ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { dynamicDropdowns } = action.payload

    const newState = {
      ...state,
      dynamicDropdowns: [dynamicDropdowns]
    }

    return newState
  },

  [ACT.MATTER_TEMPLATES_FILTERED_ATTRIBUTES_FETCH_SUCCESS](state, action) {
    const { groups } = action.payload

    const newState = {
      ...state,
      ...groups
    }

    return newState
  },

  [ACT.MATTER_TEMPLATES_FILTER_TERM](state, action) {
    const { term } = action.payload
    return {
      ...state,
      filterTerm: term
    }
  }
})

export default matterTemplatesAttributesReducer
