import { Component } from 'react'
import Rules from '../components/Rules'

class RulesContainer extends Component {
  state = {}
  render() {
    return <Rules {...this.props} {...this.state} />
  }
}

export default RulesContainer
