import { VendorSelect } from 'common/Selects'
import SelectFilter from './SelectFilter'
import { formatMultiSelectLabel } from '../helpers'

const VendorFilter = ({
  onSelect,
  reset,
  initialValue,
  hasConfirmation,
  isMulti,
  limit,
  paginated,
  withNoneOption
}) => {
  return (
    <SelectFilter
      title="Vendor"
      onSelect={onSelect}
      reset={reset}
      initialValue={initialValue}
      customLabel={formatMultiSelectLabel(isMulti, 'Vendor')}
      hasConfirmation={hasConfirmation}
      isMulti={isMulti}
      render={(vendor, setVendor) => {
        return (
          <VendorSelect
            value={vendor}
            onChange={item => {
              setVendor(item)
            }}
            isMulti={isMulti}
            isPortal={false}
            isClearable
            limit={limit}
            paginated={paginated}
            withNoneOption={withNoneOption}
          />
        )
      }}
    />
  )
}

export default VendorFilter
