import * as React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import { capitalizeStr } from 'utils/formatting'

import ACT from '../actions'

import ReviewModal from '../components/ReviewModal'
import { SCOPE, REVIEW_ACTION_LABEL, REVIEW_MODAL_LABELS } from '../utils/constants'

const initialState = {
  emailTo: [],
  subject: '',
  body: '',
  addAttachments: false,
  addPdfCopy: true,
  isLoading: false,
  notifyViaEmail: true,
  additionalDetails: '',
  reasons: []
}

@connect(({ reviews: { reviewLadder } }) => ({
  isHydrating: reviewLadder.isHydrating
}))
class ReviewModalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  componentDidMount() {
    const { modalAction, modalMessageFetcher, scope, scopeId, dispatch } = this.props

    dispatch({ type: ACT.IS_NOT_HYDRATING })
    this.setState({ isLoading: true })

    modalMessageFetcher(modalAction, scope, scopeId).then(({ subject, body, emailTo }) => {
      this.setState({ subject, body, emailTo, isLoading: false })
    })
  }

  getInitialState = () => {
    const {
      emailTo,
      subject,
      body,
      addAttachments,
      addPdfCopy,
      notifyViaEmail,
      additionalDetails,
      reasons
    } = this.props
    return {
      emailTo: emailTo || initialState.emailTo,
      subject: subject || initialState.subject,
      body: body || initialState.body,
      addAttachments: addAttachments || initialState.addAttachments,
      addPdfCopy: addPdfCopy || initialState.addPdfCopy,
      isLoading: initialState.isLoading,
      notifyViaEmail: notifyViaEmail || initialState.notifyViaEmail,
      additionalDetails: additionalDetails || initialState.additionalDetails,
      reasons: reasons || initialState.reasons
    }
  }

  updateFields = fields => {
    this.setState(fields)
  }

  getFieldValues = () => {
    const { isLoading, ...fields } = this.state

    return fields
  }

  onConfirm = () => {
    this.props.onConfirm(this.getFieldValues())
    this.setState(initialState)
  }

  onConfirmWithNotify = () => {
    this.props.onConfirmWithNotify(this.getFieldValues())
    this.setState(initialState)
  }

  onCancel = () => {
    this.props.onCancel()
    this.setState(initialState)
  }

  getTitle = () => {
    const { scope, modalAction } = this.props

    const scopeText = capitalizeStr(get(SCOPE, scope, ''))
    const actionText = modalAction === null ? 'Send' : REVIEW_ACTION_LABEL(scope, modalAction)

    const separator = scopeText && actionText ? '-' : ''

    return `${scopeText} ${separator} ${actionText}`.trim()
  }

  getLabels = () => {
    const { scope, modalAction } = this.props

    return modalAction === null
      ? {}
      : REVIEW_MODAL_LABELS(REVIEW_ACTION_LABEL(scope, modalAction), scope)
  }

  showAddAttachments = () => [SCOPE.INVOICE].includes(this.props.scope)

  showAddPDF = () => [SCOPE.INVOICE].includes(this.props.scope)

  render() {
    return (
      <ReviewModal
        {...this.getLabels()}
        scope={this.props.scope}
        vendorId={this.props.vendorId}
        fields={this.getFieldValues()}
        updateFields={this.updateFields}
        onConfirm={this.onConfirm}
        onConfirmWithNotify={this.onConfirmWithNotify}
        onCancel={this.onCancel}
        title={this.getTitle()}
        showAddAttachments={this.showAddAttachments()}
        showAddPDF={this.showAddPDF()}
        isLoading={this.state.isLoading}
      />
    )
  }
}

export default ReviewModalContainer
