import { Constant } from 'simple_review/@types/common'
import { fieldType } from '../constant-model'
import { CanSerialize } from '../can-serialize'
import { NonListConstant } from './constant-model'
import { ModelFieldModel } from '../model-field/model-field-model'

export abstract class ConstantMapper implements CanSerialize {
  abstract readonly type: fieldType

  constructor(
    protected _constant: Constant<string | number>,
    protected _subType: string | null = ''
  ) {}

  toJSON(): string {
    const data = {
      constant: this._constant,
      type: this.type,
      sub_type: this._subType
    }

    return JSON.stringify(data)
  }

  fromJSON(fullLhs?: ModelFieldModel): NonListConstant {
    return {
      type: this.type,
      isList: false,
      value: this._constant?.value,
      label: this._constant?.label,
      subType: this._subType
    }
  }
}
