import * as ACT from 'matters/templates/actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'
import cloneDeep from 'lodash/cloneDeep'
import { moveToFrontOfArray, sortAlphabeticallyByProperty } from 'utils/helpers'

const initialState = {
  filterTerm: '',
  templateList: [],
  selectedTemplate: null,
  additionalAttributes: [],
  matterRequestData: {},
  description: '',
  processedDescription: '',
  selectedValues: [],
  loading: false,
  userFlowState: {
    userFlow: '',
    id: '',
    loadingUserFlow: true
  },
  pageDescription: {
    pageKey: '',
    defaultDescription: ''
  },
  showSidebar: true
}

const matterTemplatesSelectionReducer = createReducer(initialState, {
  [ACT.UPDATE_SELECTION_USER_FLOW](state, action) {
    const { userFlow, id, loadingUserFlow } = action.payload
    return {
      ...state,
      userFlowState: {
        userFlow,
        id,
        loadingUserFlow
      }
    }
  },

  [ACT.UPDATE_SELECTION_PAGE_DESCRIPTION](state, action) {
    const { pageKey, defaultDescription } = action.payload
    return {
      ...state,
      pageDescription: {
        pageKey,
        defaultDescription
      }
    }
  },

  [ACT.MATTER_TEMPLATE_FORM_LIST_TOGGLE_PREFERRED](state, action) {
    const { id, value } = action.payload
    const index = state.templateList.findIndex(value => value.id === id)

    return update(state, {
      templateList: {
        [index]: {
          belongs_to_form: { $set: value }
        }
      }
    })
  },

  [ACT.MATTER_TEMPLATE_FORM_LIST_CLEAR_PREFERRED](state) {
    const templateList = cloneDeep(state.templateList).map(template => {
      template.belongs_to_form = false
      return template
    })

    return update(state, {
      templateList: { $set: templateList }
    })
  },

  [ACT.MATTER_TEMPLATE_FORM_LIST_SET_PREFERRED](state, action) {
    const { ids } = action.payload
    const templateList = cloneDeep(state.templateList).map(template => {
      template.belongs_to_form = ids.includes(template.id)
      return template
    })

    return update(state, {
      templateList: { $set: templateList }
    })
  },

  [ACT.SET_LOADING_STATE](state) {
    return {
      ...state,
      loading: true
    }
  },

  [ACT.MATTER_TEMPLATE_PREVIEW](state, action) {
    const { selectedTemplate } = action.payload
    return {
      ...state,
      selectedTemplate
    }
  },

  [ACT.MATTER_TEMPLATE_FILTER_TERM](state, action) {
    const { term } = action.payload
    return {
      ...state,
      filterTerm: term
    }
  },

  [ACT.MATTER_TEMPLATE_LIST_FETCH_SUCCESS](state, action) {
    const { list, latestTemplateId, formId } = action.payload
    const sortedTemplates = formId
      ? moveToFrontOfArray(sortAlphabeticallyByProperty(list.templates, 'name'), 'belongs_to_form')
      : sortAlphabeticallyByProperty(list.templates, 'name')
    let index = sortedTemplates.findIndex(template => template.is_default_for_user)
    if (latestTemplateId) {
      index = sortedTemplates.findIndex(template => template.id === Number(latestTemplateId))
    }
    return {
      ...state,
      templateList: sortedTemplates,
      selectedTemplate: sortedTemplates[index]
    }
  },

  [ACT.MATTER_TEMPLATE_RESET_SELECTED_VALUES](state) {
    return {
      ...state,
      selectedValues: initialState.selectedValues
    }
  },

  [ACT.MATTER_TEMPLATE_RESET_ADDITIONAL_ATTRIBUTES](state) {
    return {
      ...state,
      additionalAttributes: initialState.additionalAttributes
    }
  },

  [ACT.MATTER_TEMPLATE_APPEND_ADDITIONAL_ATTRIBUTES](state, action) {
    const { additionalAttributes } = action.payload
    return {
      ...state,
      additionalAttributes
    }
  },

  [ACT.MATTER_TEMPLATE_SET_USER_DEFAULT_SUCCESS](state, action) {
    const { selectedTemplate } = action.payload
    return {
      ...state,
      templateList: state.templateList.map(template => {
        if (template.id === selectedTemplate.id) return { ...template, is_default_for_user: true }
        return {
          ...template,
          is_default_for_user: false
        }
      }),
      selectedTemplate: { ...selectedTemplate, is_default_for_user: true }
    }
  },

  [ACT.SELECTION_PAGE_DESCRIPTION_FETCH_SUCCESS](state, action) {
    const { description, processedDescription } = action.payload
    return {
      ...state,
      description,
      processedDescription
    }
  },

  [ACT.MATTER_TEMPLATE_SELECTION_PAGE_UPDATE_DESCRIPTION](state, action) {
    const { description, processedDescription } = action.payload
    return {
      ...state,
      description,
      processedDescription
    }
  },

  [ACT.MATTER_TEMPLATE_SELECTION_RESET_STATE]() {
    return {
      ...initialState
    }
  }
})

export default matterTemplatesSelectionReducer
