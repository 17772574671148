import { useMemo } from 'react'
import s from './Tabs.scss'
import cn from 'classnames'
import { Badge, withConfirmation } from 'simple-core-ui'
import { useSelector, useDispatch } from 'react-redux'
import ACT from '../../actions'

const TAB_CONF = {
  title: 'List of Vendors selected will be lost.',
  text: 'Do you wish to continue?',
  buttons: ['Cancel', 'Yes'],
  icon: 'warning'
}

const Tabs = ({ selectedTab, setSelectedTab, hasConfirmation, resetRowSelection }) => {
  const dispatch = useDispatch()
  const statusCounts = useSelector(state => state.vendors.vendorListSummary)
  const params = useSelector(state => state.vendors.vendorListParams)

  const tabs = useMemo(() => {
    return [
      {
        label: 'Active',
        value: 'Active',
        count: statusCounts['active'] || null
      },
      {
        label: 'Inactive',
        value: 'Deactivated',
        count: statusCounts['deactivated'] || null
      },
      {
        label: 'All',
        value: 'All',
        count: statusCounts['all'] || null
      }
    ]
  }, [statusCounts])

  const switchTab = value => {
    setSelectedTab(value)
    dispatch({
      type: ACT.VENDOR_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          ...params,
          page: 1,
          category: value
        }
      }
    })
    hasConfirmation ? resetRowSelection() : null
  }
  const selectTab = value => {
    const selectCb = hasConfirmation
      ? withConfirmation(() => {
          switchTab(value)
        }, TAB_CONF)
      : () => {
          switchTab(value)
        }
    selectCb()
  }

  return (
    <ul className={cn(s.tabs, s.alignRight)}>
      {tabs.map(tab => (
        <li
          onClick={() => selectTab(tab.value)}
          key={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
          data-testid={tab.value}
        >
          {tab.count !== null ? (
            tab.value === 'Active' ? (
              <Badge
                asPill
                className={`${s.pill} ${selectedTab !== 'Active' && s.transparent}`}
                content={tab.count}
                size="sm"
              />
            ) : (
              <span className={s.count}>{tab.count}</span>
            )
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
