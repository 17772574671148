import { DueDateValue, RelativeDateParams, Subtask } from '../types'
import { ASSIGNMENT_DATE } from 'common/RelativeDueDatePicker/constants'

export const getDueDateLabel = (value: DueDateValue | undefined): string => {
  const { dateType, relativeDateParams } = value || {}
  if (!dateType) return ''
  if (dateType === ASSIGNMENT_DATE.key) return ASSIGNMENT_DATE.title
  const { amount, timeUnit, timeCondition, task } = relativeDateParams as RelativeDateParams
  const timeUnitLabel = (amount === 1
    ? timeUnit?.label.replace(/s$/, '')
    : timeUnit?.label
  )?.toLowerCase()
  return `${amount} ${timeUnitLabel} ${timeCondition?.label.toLowerCase()} ${task?.label}`
}

const extractNumericParts = (taskId: string): number[] => {
  const numericParts: string[] = taskId
    .replace(/[^0-9-]/g, '')
    .split('-')
    .filter(Boolean)
  return numericParts.map(part => parseInt(part))
}

export const sortByTaskId = (tasks: Subtask[]): Subtask[] => {
  const sortedTasks = [...tasks]
  return sortedTasks.sort((a, b) => {
    if (!a.taskId || !b.taskId) {
      return 0
    }
    const numericPartsA = extractNumericParts(a.taskId)
    const numericPartsB = extractNumericParts(b.taskId)

    for (let i = 0; i < numericPartsA.length && i < numericPartsB.length; i++) {
      const numberA = numericPartsA[i]
      const numberB = numericPartsB[i]

      if (numberA !== numberB) {
        return numberA - numberB
      }
    }

    return numericPartsA.length - numericPartsB.length
  })
}
