import { Fragment } from 'react'
import FiltersContainer, {
  DateFilter,
  MatterFilter,
  VendorFilter,
  RadioFilter,
  AmountFilter,
  UserFilter
} from 'common/Filters'

const InvoiceListFilters = ({ filterParams, onSearch, hasConfirmation, selectedTab }) => {
  const {
    received_date,
    invoice_date,
    matter_ids,
    vendor_id,
    amount,
    has_violations,
    approver_ids
  } = filterParams
  return (
    <FiltersContainer
      onSearch={onSearch}
      filterParams={filterParams}
      render={(reset, filterSearch) => {
        return (
          <Fragment>
            {selectedTab !== 'Approved' && (
              <UserFilter
                initialValue={approver_ids}
                title="Approvers"
                reset={reset}
                isMulti
                onSelect={item => filterSearch('approver_ids', item)}
                hasConfirmation={hasConfirmation}
                paginated
                noPlural
              />
            )}
            <DateFilter
              initialValue={received_date}
              reset={reset}
              title="Received Date"
              onSelect={item => filterSearch('received_date', item)}
              customLabel={selected => {
                if (!selected) return 'Received Date'
                return `Received ${selected.label}`
              }}
              hasConfirmation={hasConfirmation}
            />
            <DateFilter
              initialValue={invoice_date}
              reset={reset}
              title="Invoice Date"
              onSelect={item => filterSearch('invoice_date', item)}
              customLabel={selected => {
                if (!selected) return 'Invoice Date'
                return `Invoice ${selected.label}`
              }}
              hasConfirmation={hasConfirmation}
            />
            <MatterFilter
              initialValue={matter_ids}
              title="Matter"
              reset={reset}
              isMulti
              onSelect={item => filterSearch('matter_ids', item)}
              hasConfirmation={hasConfirmation}
              paginated
            />
            <VendorFilter
              initialValue={vendor_id}
              reset={reset}
              isMulti
              onSelect={item => filterSearch('vendor_id', item)}
              hasConfirmation={hasConfirmation}
              paginated
            />
            <AmountFilter
              initialValue={amount}
              reset={reset}
              placeholder="Amount"
              title="Amount"
              onSelect={item => filterSearch('amount', item)}
              hasConfirmation={hasConfirmation}
            />
            <RadioFilter
              initialValue={has_violations}
              reset={reset}
              title="Violation"
              onSelect={item => filterSearch('has_violations', item)}
              options={[
                {
                  value: true,
                  label: 'Has Violation'
                },
                {
                  value: false,
                  label: 'No Violation'
                }
              ]}
              hasConfirmation={hasConfirmation}
            />
          </Fragment>
        )
      }}
    />
  )
}

export default InvoiceListFilters
