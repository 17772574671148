import { CollapsibleContainer, If } from 'simple-core-ui'

import s from './CustomAttributesSection.scss'
import { EditableField } from 'components'
import { DynamicAttribute } from 'matters/templates/common/DynamicAttribute'
import { isDynamicDropdownDisabled } from 'matters/templates/utils'
import {
  LIST_LENGTH_UNTIL_PAGINATION_NOT_REQUIRED,
  OTHER_ATTRIBUTE_VALUE_LIST,
  MAX_DROPDOWN_HEIGHT
} from 'matters/templates/constants'
import { toReactSelect } from 'matters/templates/serializers'

const CustomAttributesSection = ({ customAttributes, changeAttributeValue, hasAttributes }) => {
  const textInputDebounce = 900
  const attributes = (
    <div className={s.attributes}>
      {customAttributes.map((attribute, index) => {
        if (typeof attribute === 'undefined') return ''
        if (attribute.type === 'relationship') {
          return (
            <div key={attribute.id} className={s.dynamicAttributeWrapper}>
              <DynamicAttribute>
                {attribute.attributes.map((attr, relIndex) => (
                  <div className={s.dynamicAttribute} key={attr.id}>
                    <span className={s.label}>{attr.label}</span>
                    <EditableField
                      type={attr.type}
                      disabled={isDynamicDropdownDisabled(attribute, relIndex)}
                      options={attr.options}
                      value={attr.value}
                      clearable={false}
                      {...(attribute.type === 'text' ? { debounceDelay: textInputDebounce } : {})}
                      onChange={value =>
                        changeAttributeValue({
                          index,
                          value,
                          inTemplate: true,
                          attributeId: attr.id,
                          relationshipIndex: relIndex,
                          relationshipId: attribute.id
                        })
                      }
                    />
                  </div>
                ))}
              </DynamicAttribute>
            </div>
          )
        }
        return (
          <div className={s.attribute} key={attribute.id}>
            <span className={s.label}>{attribute.label}</span>
            <EditableField
              type={attribute.type}
              url={attribute.type === 'select' && `/manage/attributes/${attribute.id}/select2_json`}
              paginated={
                attribute.class === OTHER_ATTRIBUTE_VALUE_LIST &&
                attribute.type === 'select' &&
                attribute.options?.length > LIST_LENGTH_UNTIL_PAGINATION_NOT_REQUIRED
              }
              serializer={toReactSelect}
              options={attribute.options}
              value={attribute.value}
              disabled={attribute.readOnly}
              {...(attribute.type === 'text' ? { debounceDelay: textInputDebounce } : {})}
              onChange={value =>
                changeAttributeValue({
                  index,
                  value,
                  inTemplate: !attribute.not_in_template,
                  attributeId: attribute.id
                })
              }
              id={`slm_drawer_custom_attribute_${index}`}
              isPortal={false}
              maxMenuHeight={MAX_DROPDOWN_HEIGHT}
            />
          </div>
        )
      })}
    </div>
  )

  return (
    <If condition={hasAttributes} fallback={attributes}>
      <CollapsibleContainer title="Optional Fields" style={{ marginBottom: '1em' }}>
        {attributes}
      </CollapsibleContainer>
    </If>
  )
}

export default CustomAttributesSection
