import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa'

import s from '../styles/Aside.scss'

const TriggerMenu = ({ triggerMenu, isMenuExpanded }) => {
  const title = `${isMenuExpanded ? 'Collapse' : 'Expand'} sidebar`
  return (
    <section
      onClick={triggerMenu}
      id="slm_trigger_expand_menu"
      className={s.triggerContainer}
      title={title}
    >
      <span className={s.iconsContainer}>
        {isMenuExpanded ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
      </span>
      <span className={!isMenuExpanded ? s.collapseTextHidden : ''}>{title}</span>
    </section>
  )
}

export default TriggerMenu
