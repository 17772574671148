import { Fragment, FunctionComponent, ReactNode } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import cn from 'classnames'

import { validateOperand } from 'simple_review/editor/validators'
import { ActionsPopover } from './ActionsPopover'
import { useSimpleReviewContext } from 'simple_review/hooks'
import { Operand as OperandType } from 'simple_review/@types/editor'
import { Lhs } from './lhs'
import { Operator } from './operator'
import { Rhs } from './rhs'
import s from './Operand.scss'
import argsCss from './lhs/args/Args.scss'
import { Operator as OperatorType } from 'simple_review/@types/api'

const rowHeight = parseInt(argsCss.rowHeight.replace(/\D/g, ''))

interface Props {
  operand: OperandType
  shouldShowInfo: boolean
  isReadOnly: boolean
  onChangeOperand(newOperand: OperandType): void
  onCopyOperand(): void
  onRemoveOperand(): void
}

interface RestComponentProps {
  children: ReactNode
  isFunc: boolean
}

const RestComponent: FunctionComponent<RestComponentProps> = ({ children, isFunc }) => {
  if (isFunc) {
    return <div className={s.rest}>{children}</div>
  }

  return <Fragment>{children}</Fragment>
}

export const Operand = ({
  operand,
  shouldShowInfo,
  isReadOnly,
  onChangeOperand,
  onCopyOperand,
  onRemoveOperand
}: Props) => {
  const { state: context } = useSimpleReviewContext()

  const onChangeLhs = (newLhs: OperandType['lhs'], keepRhs = false) => {
    const newOperand = cloneDeep(operand)
    newOperand.lhs = newLhs

    if (newLhs?.type === 'boolean') {
      const booleanOperators = context.operatorsByType['boolean']
      if (booleanOperators) {
        const isOp = booleanOperators.find(op => op.symbol === 'is')
        if (isOp) newOperand.op = isOp
      }
    } else {
      if (newLhs?.type !== operand.lhs?.type) {
        newOperand.op = null
      }
    }

    if (!keepRhs) {
      newOperand.rhs = null
    }
    onChangeOperand(newOperand)
  }

  const onChangeOperator = (newOperator: OperandType['op']) => {
    const newOperand = cloneDeep(operand)
    newOperand.op = newOperator
    newOperand.rhs = null
    onChangeOperand(newOperand)
  }

  const getOperators = (lhsType: string): Array<OperatorType> =>
    context.operatorsByType[lhsType] || []

  const onChangeRhs = (newRhs: OperandType['rhs']) => {
    const newOperand = cloneDeep(operand)
    newOperand.rhs = newRhs
    onChangeOperand(newOperand)
  }

  const isOperandValid = validateOperand(operand)

  /* This is for when the operand is a function. The user is able to add up to 8 args that are displayed with position absolute below the lhs. Each arg has a height of 64px plus the 'Add Arg' button which has a height of 54px. The button isn't shown when there are 8 args selected.  */
  const getPaddingBottom = (): number => {
    if (!operand.lhs || !('args' in operand.lhs)) return 0
    const { args = [] } = operand.lhs
    const { length } = args
    return Math.min(length || 0, 8) * rowHeight + (length === 8 ? rowHeight / 2 : rowHeight * 1.5)
  }

  const filterOperators = (operators: Array<OperatorType>) => {
    if (operand.lhs?.name === 'Age') {
      return operators.filter(o => o.symbol === '>')
    }
    return operators
  }

  return (
    <div
      className={s.container}
      style={{ paddingBottom: getPaddingBottom() }}
      data-testid="operand"
    >
      <div className={s.row}>
        <div className={cn(s.operands, { [s.wrap]: !operand.lhs?.args })}>
          <Lhs
            value={operand.lhs}
            onChangeLhs={onChangeLhs}
            isReadOnly={isReadOnly}
            operandId={operand.id}
            shouldShowInfo={shouldShowInfo}
          />
          <RestComponent isFunc={Boolean(operand.lhs?.args)}>
            {operand.lhs && (
              <>
                {operand.lhs.type !== 'boolean' && (
                  <Operator
                    value={operand.op}
                    onChangeOperator={onChangeOperator}
                    operators={filterOperators(getOperators(operand.lhs.type as string))}
                    isReadOnly={isReadOnly}
                  />
                )}
                {operand.op && (
                  <Rhs
                    lhs={operand.lhs}
                    op={operand.op}
                    value={operand.rhs}
                    isReadOnly={isReadOnly}
                    onChangeRhs={onChangeRhs}
                  />
                )}
              </>
            )}
          </RestComponent>
        </div>
        {!isReadOnly && (
          <div className={s.actions}>
            <ActionsPopover
              copyParam={onCopyOperand}
              deleteParam={onRemoveOperand}
              isCopyDisabled={!isOperandValid}
            />
          </div>
        )}
      </div>
    </div>
  )
}
