import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { put, takeLatest } from 'redux-saga/effects'
import { makeGetRequest, makePostRequest, makePutRequest } from 'utils/api'

import * as ACT from './actions'
import APP_ACT from 'app/actions'
import { NotificationList } from 'components'
import { fromContacts } from 'contacts/serializer'
import { fromRoles, fromReferralEvaluators } from './serializers'

function* fetchSurveyConfigTabUsers(action) {
  try {
    const url = '/manage/contacts/search/'
    const baseParams = { l: '', i: '' }
    const { search } = action.payload
    const queryParams = {
      params: {
        ...baseParams,
        q: search
      }
    }

    const { results } = yield makeGetRequest(url, queryParams)
    yield put({
      type: ACT.CONTACT_ADDRESS_BOOK_FETCH_SUCCESS,
      payload: {
        rows: results.length ? fromContacts(results) : []
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Users',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchSurveyConfigTabRoles(action) {
  try {
    const { entityId } = action.payload
    const url = `/manage/matters/evaluations/valid_evaluator_roles/${entityId}/`
    const response = yield makeGetRequest(url)
    yield put({
      type: ACT.SURVEY_CONFIG_ROLES_FETCH_SUCCESS,
      payload: {
        roles: fromRoles(response.roles)
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Roles',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchSurveyConfigTabAdditionalEvaluators(action) {
  try {
    const { entityId } = action.payload
    const url = `/manage/matters/evaluations/get_additional_evaluators/${entityId}/`
    const { referral_evaluators } = yield makeGetRequest(url)

    yield put({
      type: ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_SUCCESS,
      payload: {
        additionalEvaluators: fromReferralEvaluators(referral_evaluators)
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Additional Evaluators',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchSurveyConfigTabSharedEvaluators(action) {
  try {
    const { entityId } = action.payload
    const url = `/manage/matters/evaluations/get_shared_evaluators/${entityId}/`
    const { shared_evaluators, template_defaults } = yield makeGetRequest(url)
    const { is_pinned, ...otherProps } = shared_evaluators
    const sharedEvaluators = { isPinned: is_pinned, ...otherProps }

    yield put({
      type: ACT.SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_SUCCESS,
      payload: {
        sharedEvaluators,
        templateDefaults: template_defaults
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Shared Evaluators',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* updateSurveyConfigTabSharedEvaluators(action) {
  const { entityId, roles, users, status } = action.payload

  try {
    const url = '/manage/matters/evaluations/update_shared_evaluators/'
    yield makePostRequest(url, {
      matter_id: entityId,
      users,
      roles,
      status
    })

    yield put({
      type: ACT.SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_SUCCESS
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue adding Shared Evaluators',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* updateSurveyConfigTabAdditionalEvaluators(action) {
  const { entityId, roles, users, status, index } = action.payload

  try {
    const url = '/manage/matters/evaluations/update_additional_evaluators/'
    yield makePostRequest(url, {
      referral_id: entityId,
      users,
      roles,
      status
    })

    yield put({
      type: ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_MAKE_PRISTINE,
      payload: {
        index
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue adding Additional Evaluators',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* resyncSharedEvaluators(action) {
  const { entityId } = action.payload
  try {
    const url = `/manage/matters/evaluations/resync_shared_evaluators/${entityId}/`
    yield makePostRequest(url)

    yield put({
      type: ACT.SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_SUCCESS
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue resetting Shared Evaluators',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchHasSurveysEnabled() {
  try {
    const url = '/manage/matters/evaluations/status/'
    const response = yield makeGetRequest(url)

    yield put({
      type: ACT.SURVEY_CONFIG_HAS_SURVEYS_ENABLED_SUCCESS,
      payload: {
        hasSurveysEnabled: response.enabled
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching if a surveys are enabled',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* toggleAllRefferalsSurveysRequested(action) {
  const { entityId, status } = action.payload
  try {
    const url = `/manage/matters/evaluations/additional_evaluators_status/${entityId}/${status}/`
    yield makePutRequest(url)

    yield put({
      type: ACT.SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_SUCCESS,
      payload: {
        status
      }
    })
  } catch (error) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue updating survey status',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

const surveyConfigTabSagas = [
  takeLatest(ACT.SURVEY_CONFIG_USERS_FETCH_REQUESTED, fetchSurveyConfigTabUsers),
  takeLatest(ACT.SURVEY_CONFIG_ROLES_FETCH_REQUESTED, fetchSurveyConfigTabRoles),
  takeLatest(
    ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_FETCH_REQUESTED,
    fetchSurveyConfigTabAdditionalEvaluators
  ),
  takeLatest(
    ACT.SURVEY_CONFIG_SHARED_EVALUATORS_FETCH_REQUESTED,
    fetchSurveyConfigTabSharedEvaluators
  ),
  takeLatest(
    ACT.SURVEY_CONFIG_SHARED_EVALUATORS_UPDATE_REQUESTED,
    updateSurveyConfigTabSharedEvaluators
  ),
  takeLatest(
    ACT.SURVEY_CONFIG_ADDITIONAL_EVALUATORS_UPDATE_REQUESTED,
    updateSurveyConfigTabAdditionalEvaluators
  ),
  takeLatest(ACT.SURVEY_CONFIG_HAS_SURVEYS_ENABLED_REQUESTED, fetchHasSurveysEnabled),
  takeLatest(
    ACT.SURVEY_CONFIG_TOGGLE_REFFERALS_SURVEY_REQUESTED,
    toggleAllRefferalsSurveysRequested
  ),
  takeLatest(ACT.SURVEY_CONFIG_RESET_TO_TEMPLATE_DEFAULT_REQUESTED, resyncSharedEvaluators)
]

export default surveyConfigTabSagas
