import s from './SerializedOperandReadOnly.scss'

interface Props {
  lhsPath: React.ReactNode
  opLabel: string
  rhsLabel?: string
}

export function SerializedOperandReadOnly({ lhsPath, opLabel, rhsLabel }: Props) {
  const isUnary = !rhsLabel
  return (
    <div>
      <span className={s.span}>{lhsPath}</span>
      <span className={s.span}>{opLabel.toLowerCase()}</span>
      {!isUnary && <span>{rhsLabel}</span>}
    </div>
  )
}
