import s from 'app/body/styles/Body.scss'
import { If } from 'simple-core-ui'
import cn from 'classnames'

const StateBanner = ({ bannerText, actionButtons, classNameMap = {} }) => {
  return (
    <section className={`${s.toolbar} ${s.approved}`} style={classNameMap.banner}>
      <div data-testid="state_banner_text" className={cn(s.actionRequired, classNameMap.text)}>
        {bannerText}
      </div>
      <If condition={actionButtons}>
        {() => <div className={s.actionButtons}>{actionButtons}</div>}
      </If>
    </section>
  )
}

export default StateBanner
