import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { ContactToEdit } from '../types'
import s from './ActionsPopover.scss'

type Props = {
  contact: ContactToEdit
  showResendInvite: boolean
  editContact: (row: ContactToEdit) => void
  deleteContact: (row: ContactToEdit) => void
  resendInvite: (row: ContactToEdit) => void
  userCanEditContactCG: boolean
}

const ActionsPopover = ({
  contact,
  showResendInvite,
  deleteContact,
  resendInvite,
  editContact,
  userCanEditContactCG
}: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        editContact(contact)
      }
    },
    ...(userCanEditContactCG
      ? [
          {
            name: 'DELETE',
            text: 'Delete',
            onClick() {
              deleteContact(contact)
            }
          },
          {
            name: 'RESEND_INVITE',
            text: 'Resend Invite',
            visible: showResendInvite,
            onClick() {
              resendInvite(contact)
            }
          }
        ]
      : [])
  ]

  return (
    <Popover
      testId={`item-${contact.id}`}
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
