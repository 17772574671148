import { fieldType } from '../constant-model'
import { RhsTypes } from 'simple_review/@types/editor'
import { CanSerialize } from '../can-serialize'
import { NonListConstant } from './constant-model'
import { ModelFieldModel } from '../model-field/model-field-model'
import { CustomAttrRhs } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'

export class CustomAttributeMapper implements CanSerialize {
  type: fieldType = 'custom_attribute'

  constructor(
    protected _constant: CustomAttrRhs,
    protected _subType: string | null = '',
    protected _context: ContextState
  ) {}

  toJSON(): string {
    const data = {
      constant: this._constant,
      type: this.type,
      sub_type: this._subType
    }

    return JSON.stringify(data)
  }

  fromJSON(fullLhs: ModelFieldModel): NonListConstant {
    const rhsInputType =
      fullLhs.modelName === 'invoice_discount' ? RhsTypes.Slider : RhsTypes.Numeric

    let attr = this._context.customAttributes.find(ca => ca.value === this._constant.attr_id)

    if (!attr) {
      attr = { label: 'custom_attr_not_found', value: '-1', model: 'none' }
      console.error('Custom Attribute Not Found')
    }

    return {
      type: this.type,
      isList: false,
      value: attr.value,
      label: attr.label,
      rhsInputType,
      isCustomAttrEnabled: true,
      subType: this._subType
    }
  }
}
