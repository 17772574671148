import { createReducer } from 'redux-create-reducer'
import ACT from './actions'
import shortid from 'shortid'

export const initialState = {
  list: [],
  totalResults: 0,
  filteredResults: 0,
  excelUrl: '',
  selectedVendorId: '',
  selectedVendorMatterList: [],
  matterTotalResults: 0
}

const eventsReducer = createReducer(initialState, {
  [ACT.ACCRUALS_ACTUALS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      list: action.payload.vendor_data.map(v => ({ ...v, id: shortid.generate() })),
      totalResults: action.payload.totalResults,
      filteredResults: action.payload.filteredResults,
      excelUrl: action.payload.excelUrl
    }
  },
  [ACT.ACCRUALS_ACTUALS_RESET]() {
    return initialState
  },
  [ACT.ACCRUALS_MATTER_LIST_RESET](state) {
    return {
      ...state,
      selectedVendorMatterList: [],
      matterTotalResults: 0
    }
  },
  [ACT.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      selectedVendorMatterList: action.payload.vendor_data[0].matters,
      matterTotalResults: action.payload.vendor_data[0].total_matters
    }
  }
})

export default eventsReducer
