import { useDrag, useDrop } from 'react-dnd'
import * as ACT from 'matters/templates/actions'
import isEmpty from 'lodash/isEmpty'
import s from './AttrDragDrop.scss'
import { useDispatch } from 'react-redux'

const AttrDragDrop = props => {
  const dispatch = useDispatch()
  const { attrIndex, attribute, type, children, sectionIndex } = props
  const [, drag] = useDrag(
    () => ({
      type: 'TemplateAttribute',
      item: { attribute, attrIndex, sectionIndex, type, action: 'reorder' },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging()
        }
      }
    }),
    [attrIndex]
  )
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['Attribute', 'TemplateAttribute'],
      drop: (item, monitor) => {
        // from => item
        // to => props

        if (isEmpty(item.attribute) && isEmpty(item.attr)) {
          return {}
        }

        if (item.action === 'reorder') {
          dispatch({
            type: ACT.MATTER_TEMPLATES_REORDER_ATTRIBUTE,
            payload: {
              from: {
                type: item.type,
                item: item.attribute,
                sectionIndex: item.sectionIndex,
                attrIndex: item.attrIndex
              },
              to: {
                type: type,
                item: attribute,
                sectionIndex: sectionIndex,
                attrIndex: attrIndex
              }
            }
          })
        }

        if (item.action === 'addAttr') {
          dispatch({
            type: ACT.MATTER_TEMPLATES_ADD_ATTRIBUTE,
            payload: {
              type: type,
              item: item.attr,
              sectionIndex: sectionIndex,
              attrIndex: attrIndex
            }
          })
        }

        return {}
      },
      collect: monitor => {
        return {
          isOver: monitor.isOver()
        }
      }
    }),
    [attrIndex]
  )

  return (
    <div
      ref={node => drag(drop(node))}
      className={isOver ? s.overDropzone : s.dropzone}
      data-testid="matter_template_drop_zone"
    >
      {children}
    </div>
  )
}

export default AttrDragDrop
