import { Modal } from 'simple-core-ui'
import s from './UnlinkModal.scss'

const UnlinkModal = ({ title, isVisible, confirm, cancel }) => {
  return (
    <Modal
      title={`Unlink ${title} folder`}
      confirmText="Yes"
      cancelText="No"
      size="sm"
      content={
        <div className={s.unlinkMessage}>{`Do you want to unlink this folder from ${title}?`}</div>
      }
      isVisible={isVisible}
      confirmCb={confirm}
      cancelCb={cancel}
    />
  )
}

export default UnlinkModal
