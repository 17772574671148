import cloneDeep from 'lodash/cloneDeep'
import { getRequestItemValue, limitCommentCharacters } from './utils'
import { capitalizeStr } from 'utils/formatting'
import { MATTER_STATUSES, REQUEST_EXTRA_FIELDS } from './constants'

export const toRequestInfoJson = request => {
  const otherFields = cloneDeep(request.other_fields).map(item => {
    const itemKey = Object.keys(item)[0]
    let value = '-----'
    let key = ''
    let id = ''
    if (itemKey && item[itemKey]) {
      value = getRequestItemValue(item[itemKey].data.display_name)
      key = item[itemKey].UI_label
      id = item[itemKey].field_unique_name
    }

    if (item[itemKey]?.class === 'RelAttrs') {
      return {
        type: key,
        id,
        required: '',
        attributes: item[itemKey]?.attributes.map(attr => {
          const attrKey = Object.keys(attr)[0]
          return {
            value: attr[attrKey].data.display_name,
            label: attr[attrKey].UI_label
          }
        })
      }
    }

    return {
      key,
      value,
      id
    }
  })

  const extraFields = [
    {
      key: 'Status',
      value: request.current_status
    }
  ]
  request.clientMatterId &&
    extraFields.push({
      key: 'Client Matter ID',
      value: request.clientMatterId
    })

  const additionalFields = [
    {
      key: REQUEST_EXTRA_FIELDS.REVIEWED_BY,
      value: request.reviewed_by_user
    },
    {
      key: REQUEST_EXTRA_FIELDS.COMMENTS,
      value: request.comment
    }
  ]

  let result = {
    id: request.id,
    requestFormId: request.request_form_id,
    otherFields,
    additionalFields,
    extraFields,
    files: request.files,
    status: request.status,
    originalFormName: request.original_form_name,
    originalFormDescription: request.original_form_description
  }

  const matterLead = request.matter_lead
  if (matterLead) {
    result['matterLead'] = {
      firstName: matterLead.first_name,
      lastName: matterLead.last_name,
      email: matterLead.email
    }
  }

  return result
}

export const toRequestJson = request => {
  const { reviewed_by_user, status, matterStatus, clientMatterId, comment } = request
  const matterStatusMapper = {
    open: MATTER_STATUSES.IN_PROGRESS,
    draft: MATTER_STATUSES.DRAFT,
    closed: MATTER_STATUSES.COMPLETE
  }
  return {
    ...request,
    reviewed_by_user: reviewed_by_user || '-',
    status: capitalizeStr(status),
    matterStatus: matterStatusMapper[matterStatus] || capitalizeStr(matterStatus) || '-',
    clientMatterId: clientMatterId || '-',
    comment: limitCommentCharacters(comment) || '-'
  }
}

export const toRequestListJson = list => list.map(request => toRequestJson(request))
