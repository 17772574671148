import { HoverAction, DESIGN } from 'simple-core-ui'
import { nameToLabel } from 'rules/utils'
import { IoIosTrash } from 'react-icons/io'

import s from '../styles/FuncForm.scss'

const {
  spacing: { sp200 }
} = DESIGN

const FuncParamDisplay = ({ parameter, onRemove, onEdit }) => (
  <section className={s.parameter}>
    <section>
      <span className={s.label}>{nameToLabel(parameter.arg_name)}</span>
      <span className={s.value}>{parameter.label}</span>
    </section>
    <section className={s.actions}>
      <HoverAction
        onClick={onEdit}
        tip="Edit"
        icon={<span className={`simple-line-icon-pencil ${s.icon}`} />}
        size="small"
        style={{ marginRight: sp200 }}
      />
      <HoverAction onClick={onRemove} tip="Remove" icon={<IoIosTrash />} size="small" />
    </section>
  </section>
)

export default FuncParamDisplay
