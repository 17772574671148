import { Input } from 'components'
import s from './InnerSidebarFilter.scss'

const InnerSidebarFilter = ({ onChange, filterTerm }) => (
  <div>
    <Input
      testid="inner_sidebar_filter"
      type="text"
      value={filterTerm}
      className={s.filterInput}
      placeholder="Filter..."
      onChange={onChange}
    />
  </div>
)

export default InnerSidebarFilter
