import { timezoneDate } from 'utils/helpers'
import { APICourtRule, CourtRule } from './types'
import { format } from 'date-fns'

export const toCourtRule = (courtRule: APICourtRule): CourtRule => {
  const {
    id,
    name,
    trigger_date,
    trigger_event,
    events_count,
    jurisdiction,
    needs_review,
    prefix,
    matter
  } = courtRule

  return {
    id,
    name,
    triggerDate: format(timezoneDate(trigger_date, jurisdiction.time_zone), 'MM/dd/yyyy'),
    triggerEvent: {
      value: trigger_event.id,
      label: trigger_event.name
    },
    matter: {
      value: matter.id,
      label: matter.name
    },
    eventsCount: events_count,
    jurisdiction: {
      value: jurisdiction.id,
      label: jurisdiction.name
    },
    needsReview: needs_review,
    prefix
  }
}

export const toCourtRules = (courtRules: APICourtRule[]): CourtRule[] => {
  return courtRules.map(toCourtRule)
}
