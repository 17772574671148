import { capitalize } from 'utils/formatting'
import { BaseSerializer } from './base.serializer'

export function RuleInfoEditSerializer(
  oldValue: string | null,
  newValue: string,
  fieldName: string
): BaseSerializer {
  return {
    toString() {
      if (oldValue === null) {
        return (
          <p>
            Added {capitalize(fieldName)}{' '}
            <q>
              <strong>{newValue}</strong>
            </q>
          </p>
        )
      } else {
        return (
          <p>
            Changed {capitalize(fieldName)} from{' '}
            <q>
              <strong>{oldValue}</strong>
            </q>{' '}
            to{' '}
            <q>
              <strong>{newValue}</strong>
            </q>
          </p>
        )
      }
    }
  }
}
