import { HelpText } from 'simple-core-ui'

const DisabledTip = ({ children, reason }) => (
  <HelpText
    message={
      <section className="p-v-sp100 p-h-sp200">
        <p className="lnHt300">{reason}</p>
        <p className="lnHt300">
          Please be sure that all the conditions and actions have been filled out.{' '}
        </p>
      </section>
    }
    position="left"
    hasUnderline={false}
  >
    {children}
  </HelpText>
)

export default DisabledTip
