import { CSSProperties } from 'react'

interface Props {
  lat: number
  lng: number
  style?: CSSProperties
  zoomLevel?: number
}

const apiKey = window.serverContext.get('data_for_react')?.google_maps_api_key

const GoogleMap = ({ lat, lng, style, zoomLevel = 13 }: Props) => {
  return (
    <div style={{ width: 200, height: 200, ...style }}>
      <iframe
        width="100%"
        height="100%"
        referrerPolicy="no-referrer-when-downgrade"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&center=${lat},${lng}&zoom=${zoomLevel}&q=${lat},${lng}`}
        allowFullScreen
      />
    </div>
  )
}

export default GoogleMap
