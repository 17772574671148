import { ModalContainer, LawRuleIcon, CheckboxContainer, Alert } from 'simple-core-ui'
import s from './DisconnectModal.scss'
import { IoWarningOutline } from 'react-icons/io5'
import { useState } from 'react'

const DisconnectModal = ({
  toggleDisconnectModal,
  onConfirm
}: {
  toggleDisconnectModal: () => void
  onConfirm: () => Promise<void>
}) => {
  const [isDisabled, setIsDisabled] = useState(true)

  return (
    <ModalContainer
      title="You will be disconnected from CalendarRules"
      hasNewButtons
      size="sm"
      cancelCb={toggleDisconnectModal}
      confirmText="Disconnect"
      confirmCb={() => {
        onConfirm()
        toggleDisconnectModal()
      }}
      isDisabled={isDisabled}
      content={
        <>
          <div className={s.statusItem}>
            <IoWarningOutline
              style={{
                fontSize: 22,
                color: '#bb342f',
                width: 130,
                position: 'relative',
                bottom: 2,
                marginRight: 5
              }}
            />
            <span>
              You will permanently lose access to your previously created court rules and no longer
              receive updates from CalendarRules. Your court rule events will not be updated. This
              cannot be un-done.
            </span>
          </div>
          <div style={{ margin: '20px 0', textAlign: 'center' }}>
            <LawRuleIcon />
          </div>
          <Alert
            message={
              <>
                <CheckboxContainer
                  size="sm"
                  styles={{ position: 'relative', top: 2, marginRight: 5, borderColor: '#5399f6' }}
                  isChecked={!isDisabled}
                  cb={() => setIsDisabled(!isDisabled)}
                />
                I acknowledge I&apos;ve read and understand the above message
              </>
            }
            status="info"
          />
        </>
      }
    />
  )
}

export default DisconnectModal
