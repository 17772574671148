import { Component } from 'react'
import { makeGetRequest, handleError } from 'utils/api'

import { getModelAttrField, getAttrOperandLabel, isAttrOperand } from 'rules/utils'
import { EngineContext } from 'rules/context'
import { LHS_TYPES } from 'rules/constants'
import { ComboBoxContainer } from 'containers'
import CustomValue from '../components/CustomValue'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

import has from 'lodash/has'
import get from 'lodash/get'

import { INPUT, getRhsInputType } from 'data/operators'

class RhsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    const { rhs } = this.props
    return {
      selected: has(rhs, 'attr_id') ? rhs : get(rhs, 'constant', { label: '', value: '' })
    }
  }

  onChange = selected => this.setState({ selected })

  hasComboOptions = inputType =>
    [
      INPUT.STRING_COMBO_STATIC,
      INPUT.BOOLEAN,
      INPUT.NUMBER_COMBO_STATIC,
      INPUT.DATE_RANGE,
      INPUT.CURRENCY_COMBO_STATIC,
      INPUT.STRING_COMBO_ASYNC,
      INPUT.NUMBER_COMBO_ASYNC,
      INPUT.DATE,
      INPUT.CURRENCY_COMBO_ASYNC,
      INPUT.LIST
    ].includes(inputType)

  hasComboLoadCb = inputType => [INPUT.ASYNC, INPUT.STRING_COMBO_ASYNC].includes(inputType)

  hasCustom = inputType => {
    return ![INPUT.BOOLEAN, INPUT.ASYNC, INPUT.STRING_COMBO_ASYNC, INPUT.LIST].includes(inputType)
  }

  hasCustomOptions = inputType => [INPUT.MULTI].includes(inputType)

  hasCustomLoadCb = inputType => [INPUT.ASYNC_MULTI].includes(inputType)

  loadCb = url => async search => {
    try {
      const response = await makeGetRequest(url, { params: { search } })

      return response.map(option => ({
        ...option,
        constant: { label: option.label, value: option.value }
      }))
    } catch (e) {
      handleError(e, 'Could not load combobox options')
    }
  }

  setConstOperand = () => {
    const { selected } = this.state
    const { lhs, availableFields } = this.props

    const { value } = selected

    const field =
      lhs.operand_type === LHS_TYPES.FUNC_CALL
        ? availableFields.find(({ name, isFunction }) => isFunction && name === lhs.func_name)
        : getModelAttrField(lhs, availableFields).field

    const toLabelValue = ({ label, value }) => ({ label, value })

    const constant = Array.isArray(value) ? value.map(toLabelValue) : toLabelValue(selected)

    this.props.onChange({
      type: field.type,
      constant,
      sub_type: field.sub_type
    })
  }

  setAttrOperand = () => {
    const { selected } = this.state
    const toAttrOperand = ({ attr_id, model_name }) => ({ attr_id, model_name })

    this.props.onChange(toAttrOperand(selected))
  }

  selectionCb = selected => {
    this.setState(
      { selected },
      isAttrOperand(selected) ? this.setAttrOperand : this.setConstOperand
    )
  }

  resetState = () => {
    this.setState(this.getInitialState())
  }

  getConstOperandLabel = () => {
    const { rhs } = this.props
    const constant = get(rhs, 'constant')
    return Array.isArray(constant)
      ? constant.map(({ label }) => label).join(', ')
      : get(constant, 'label', 'Select...')
  }

  getAttrOperandLabel = () => {
    const { rhs, availableFields } = this.props
    return getAttrOperandLabel(availableFields, rhs)
  }

  getLabel = () => {
    return isAttrOperand(this.props.rhs) ? this.getAttrOperandLabel() : this.getConstOperandLabel()
  }

  render() {
    const { selected } = this.state
    const { operator, lhs, availableFields } = this.props

    const field =
      lhs.operand_type === LHS_TYPES.FUNC_CALL
        ? availableFields.find(({ name, isFunction }) => {
            return isFunction && name === lhs.func_name
          })
        : getModelAttrField(lhs, availableFields).field

    const inputType = field && getRhsInputType(field.type, field.choices, operator)

    return (
      inputType &&
      operator && (
        <EngineContext.Consumer>
          {({ canEdit }) => (
            <ComboBoxContainer
              selectionCb={this.selectionCb}
              options={
                this.hasComboLoadCb(inputType)
                  ? []
                  : this.hasComboOptions(inputType)
                  ? sortAlphabeticallyByProperty(field.choices, 'label')
                  : null
              }
              label={this.getLabel()}
              selected={selected}
              loadCb={this.hasComboLoadCb(inputType) ? this.loadCb(field.choices) : null}
              onClose={this.resetState}
              custom={
                this.hasCustom(inputType)
                  ? comboBoxSelectionCb => (
                      <CustomValue
                        type={inputType}
                        fieldType={field.type}
                        options={
                          this.hasCustomOptions(inputType)
                            ? sortAlphabeticallyByProperty(field.choices, 'label')
                            : null
                        }
                        operator={operator}
                        selected={Array.isArray(selected) ? { value: selected } : selected}
                        changeCb={this.onChange}
                        selectionCb={comboBoxSelectionCb}
                        loadCb={this.hasCustomLoadCb(inputType) ? this.loadCb(field.choices) : null}
                      />
                    )
                  : null
              }
              disabled={!canEdit}
            />
          )}
        </EngineContext.Consumer>
      )
    )
  }
}

export default RhsContainer
