import { Component, ComponentProps, FC } from 'react'

import { NotFound } from 'simple-core-ui'
import RouteRedirect from './RouteRedirect'
import { BaseGuard } from './guards/types'

type Guarded<P> = {
  /**
   * The component a user is trying to visit.
   */
  // eslint-disable-next-line prettier/prettier
  component: typeof Component<P> | FC<P>
  /**
   * A list of guards that implement `BaseGuard`. All need to return true from `canRoute` in order to show route.
   */
  guards: Array<BaseGuard>
  /**
   * An optional url to which the user will be routed in case they are not authorized to route to the target component.
   */
  redirectTo?: string
}


const PrivateRoute = <T extends FC, P extends ComponentProps<T>>({
  component: RouteComponent,
  guards,
  redirectTo = window.credentials.urls.homeUrl,
  ...props
}: Omit<P, keyof Guarded<P>> & Guarded<P>) => {
  const isAllowed = guards.every(guard => guard.canRoute())

  if (isAllowed) return <RouteComponent {...props as P} />

  if (redirectTo) return <RouteRedirect redirectTo={redirectTo} />

  return <NotFound />
}

export default PrivateRoute
