import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover, UpdateToPremiumLabel } from 'simple-core-ui'
import { viewAttachment } from '../../MatterAttachmentsTab/utils'
import { isBasicTaskManagementPlan } from 'utils/helpers'
import s from './ActionsPopover.scss'

interface Props {
  rowId: number
  copyDocument: (arg: number | null) => void
  fileUrl: string
}

const ActionsPopover = ({ rowId, copyDocument, fileUrl }: Props) => {
  const isBasicPlan = isBasicTaskManagementPlan()
  const actions = [
    {
      name: 'COPY',
      text: isBasicPlan ? (
        <>
          <span className={s.disabled}>Copy</span>
          <UpdateToPremiumLabel />
        </>
      ) : (
        'Copy'
      ),
      onClick: isBasicPlan ? () => {} : () => copyDocument(rowId)
    },
    {
      name: 'DOWNLOAD',
      text: 'Download',
      onClick() {
        viewAttachment({ fileUrl, isCgFile: true })
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="small" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      contentClassName={s.actionsList}
      border
    />
  )
}

export default ActionsPopover
