import Select from 'react-select'
import PropTypes from 'prop-types'

import { EditableField } from 'components'

import { INPUT } from 'data/operators'

import s from '../styles/CustomValue.scss'
import get from 'lodash/get'

const CurrencyInput = ({ value, changeCb }) => (
  <EditableField
    type="currency"
    value={(() => {
      const CURRENCY_REGEX = /(\d+\.?\d*)?\s*([A-Z]{3})?/gi
      const [_, amount, currencyCode] = CURRENCY_REGEX.exec(value) || []
      return { amount, code: { value: currencyCode } }
    })()}
    onChange={({ amount, code }) => {
      const label = `${amount || ''} ${get(code, 'value', 'USD')}`
      changeCb({ value: label.replace(' ', ''), label })
    }}
  />
)

const allowOnlyNumbersForTagField = changeCb => e => {
  const valueToBeValidated = e[e.length - 1].value
  const numbersOnlyRegex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/
  if (numbersOnlyRegex.test(valueToBeValidated)) changeCb(e)
}

export const FieldByType = ({ type, value, changeCb, loadCb, options, fieldType }) => (
  <div className={s.selectContainer}>
    {
      {
        [INPUT.STRING]: (
          <EditableField
            type="text"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),
        [INPUT.STRING_COMBO_STATIC]: (
          <EditableField
            type="text"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),
        [INPUT.STRING_COMBO_ASYNC]: (
          <EditableField type="reference" value={value} onChange={changeCb} loadCb={loadCb} />
        ),

        [INPUT.NUMBER]: (
          <EditableField
            type="number"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),
        [INPUT.NUMBER_COMBO_ASYNC]: (
          <EditableField
            type="number"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),
        [INPUT.NUMBER_COMBO_STATIC]: (
          <EditableField
            type="number"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),
        [INPUT.CURRENCY]: <CurrencyInput {...{ changeCb, value }} />,
        [INPUT.CURRENCY_COMBO_ASYNC]: <CurrencyInput {...{ changeCb, value }} />,
        [INPUT.CURRENCY_COMBO_STATIC]: <CurrencyInput {...{ changeCb, value }} />,

        [INPUT.DATE]: (
          <EditableField
            type="date"
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),

        [INPUT.DATE_RANGE]: (
          <EditableField
            type={'daterange'}
            value={value}
            onChange={value => changeCb({ value, label: value })}
          />
        ),

        [INPUT.ASYNC_MULTI]: (
          <EditableField type="multireference" value={value} onChange={changeCb} loadCb={loadCb} />
        ),

        [INPUT.MULTI]: (
          <EditableField
            type="multi"
            value={value}
            options={options}
            className={s.selectContainer}
            onChange={changeCb}
          />
        ),

        [INPUT.ASYNC]: (
          <EditableField type="reference" value={value} onChange={changeCb} loadCb={loadCb} />
        ),

        [INPUT.TAG]: (
          <EditableField
            value={value}
            type="tag"
            onChange={fieldType === 'number' ? allowOnlyNumbersForTagField(changeCb) : changeCb}
          />
        ),
        [INPUT.BOOLEAN]: (
          <Select
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            value={value}
            className={s.selectContainer}
            onChange={changeCb}
          />
        ),
        [INPUT.INVALID]: (
          <EditableField type="text" onChange={changeCb} value="Operator not supported in UI" />
        )
      }[type]
    }
  </div>
)

const CustomValue = ({ type, options, selected, changeCb, selectionCb, loadCb, fieldType }) => {
  const value = get(selected, 'value', '')

  return (
    <form
      className={s.container}
      onSubmit={() => selectionCb(selected)}
      data-testid={`form-custom-value-${type}`}
    >
      {
        <FieldByType
          options={options}
          type={type}
          changeCb={changeCb}
          fieldType={fieldType}
          loadCb={loadCb}
          value={value}
        />
      }
      <button className={s.button} type="submit">
        Set
      </button>
    </form>
  )
}

CustomValue.propTypes = {
  type: PropTypes.oneOf(Object.keys(INPUT)),
  selected: PropTypes.object,
  changeCb: PropTypes.func,
  selectionCb: PropTypes.func,
  loadCb: PropTypes.func
}

export default CustomValue
