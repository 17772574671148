import { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContainer, Stepper, Button, useLoading } from 'simple-core-ui'
import APP_ACT from 'app/actions'
import s from './BulkShareModal.scss'
import { Dropzone } from 'common/FileUpload/Dropzone'
import { useImmer } from 'use-immer'
import { Attachment, Vendor } from './types'
import { hasDupFileNames } from 'common/FileUpload/utils'
import { SelectVendorsStep } from './SelectVendorsStep'
import { makeGetRequest, makePostRequest } from 'utils/api'

interface Props {
  hideModal: () => void
}

const BulkShareModal = ({ hideModal }: Props) => {
  const [activeStep, setActiveStep] = useState(1)
  const [filesToUpload, setFilesToUpload] = useImmer<Attachment[]>([])
  const [rejectedFiles, setRejectedFiles] = useImmer([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [vendors, setVendors] = useState<Vendor[]>([])
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()

  const selectAllRows = useCallback(
    (v?: Vendor[]) => {
      setAllRowsSelected(allRowsSelected => !allRowsSelected)
      setSelectedRows(allRowsSelected ? [] : (v || vendors).map(t => t.id))
    },
    [vendors, allRowsSelected]
  )

  useEffect(() => {
    const fetchVendors = async () => {
      const response = await withLoadingLocks(makeGetRequest('/invoices/v2/cg_vendors/'))
      setVendors(response.vendors)
      selectAllRows(response.vendors)
    }

    fetchVendors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectRow = useCallback(
    (id: number) => {
      setAllRowsSelected(false)

      if (selectedRows.includes(id)) {
        setSelectedRows(prevSelectedRows => prevSelectedRows.filter(rowId => rowId !== id))
      } else {
        setSelectedRows(prevSelectedRows => [...new Set([...prevSelectedRows, id])])
      }
    },
    [selectedRows]
  )

  const shareFiles = async () => {
    const files = filesToUpload.reduce((acc, file) => {
      if (file.completed) {
        return {
          ...acc,
          [file.url]: {
            name: file.name,
            size: file.size,
            type: file.type,
            will_overwrite_file: true
          }
        }
      }
      return acc
    }, {})
    try {
      await makePostRequest(`/upload/vendor/bulk_share/`, {
        vendor_ids: selectedRows,
        files
      })

      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'File(s) successfully shared with the selected vendors.',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
    hideModal()
  }

  const isUploadStepInvalid = () => {
    return (
      filesToUpload.some(f => f.processing) ||
      hasDupFileNames((filesToUpload as unknown) as File[]) ||
      !filesToUpload.length
    )
  }

  const steps = useMemo(() => {
    return [
      {
        text: <span className={s.step}>Select File(s)</span>,
        onClick: () => {
          if (activeStep > 1) {
            setActiveStep(1)
          }
        }
      },
      {
        text: <span className={s.step}>Select Vendor(s)</span>,
        onClick: () => {
          if (activeStep > 2) {
            setActiveStep(2)
          }
        }
      }
    ]
  }, [activeStep])

  const onNext = () => {
    switch (activeStep) {
      case 1:
        break
      case 2:
        shareFiles()
        return
      default:
        break
    }

    setActiveStep(activeStep + 1)
  }

  const onBack = () => {
    setActiveStep(activeStep - 1)
  }

  const isDisabled = () => {
    if (activeStep === 1) {
      return isUploadStepInvalid()
    }
    if (activeStep === 2) {
      return selectedRows.length === 0
    }

    return false
  }

  return (
    <ModalContainer
      hideButtons
      size="xl"
      cancelCb={hideModal}
      content={
        <div className={s.container}>
          <Stepper
            orientation="horizontal"
            automaticHeader={false}
            activeStep={activeStep}
            steps={steps}
            displayIndex
            hasNewDesign
          />
          <div className={s.content}>
            {activeStep === 1 && (
              <>
                <h2 className={s.title}>Upload Files</h2>
                {/* @ts-expect-error */}
                <Dropzone
                  filesToUpload={filesToUpload}
                  setFilesToUpload={setFilesToUpload}
                  rejectedFiles={rejectedFiles}
                  setRejectedFiles={setRejectedFiles}
                />
              </>
            )}
            {activeStep === 2 && (
              <SelectVendorsStep
                vendors={vendors}
                selectedRows={selectedRows}
                allRowsSelected={allRowsSelected}
                selectAllRows={selectAllRows}
                selectRow={selectRow}
              />
            )}
          </div>
          <div className={s.footer}>
            <Button
              style={{
                padding: '8px 15px',
                whiteSpace: 'nowrap',
                position: 'relative',
                bottom: 2,
                visibility: activeStep === 1 ? 'hidden' : 'visible'
              }}
              onClick={onBack}
              isPrimary
              isOutline
              hasNewDesign
              isDisabled={false}
            >
              Back
            </Button>
            <div className={s.rightButtons}>
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={hideModal}
                isPrimary
                isOutline
                hasNewDesign
                isDisabled={false}
              >
                Cancel
              </Button>
              <Button
                style={{
                  padding: '8px 15px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  bottom: 2
                }}
                onClick={onNext}
                isPrimary
                hasNewDesign
                isDisabled={isDisabled()}
              >
                {activeStep === 2 ? 'Share' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      }
      wrapperStyles={{ maxWidth: 1050 }}
    />
  )
}

export default BulkShareModal
