import {
  ASSIGNMENT_DATE_OPTION,
  TIME_CONDITION_OPTIONS,
  TIME_UNIT_OPTIONS
} from 'common/RelativeDueDatePicker/constants'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants'
import { formatName } from 'utils/helpers'
import { APIPriority, APITask, APITaskType, APITemplate, Option, Task, Template } from './types'

const { DEFAULT_DATE_FNS } = DATE_FORMATS

const toMatterGroup = (matterGroup: APITemplate['matter_group']): Template['matterGroup'] => {
  if (!matterGroup) {
    return null
  }
  return {
    value: matterGroup.id,
    label: matterGroup.name
  }
}

export const toTemplate = (template: APITemplate): Template => {
  const {
    id,
    name,
    is_active,
    related_tasks,
    matter_group,
    description,
    edited_by,
    edited_date
  } = template

  return {
    id,
    name,
    matterGroup: toMatterGroup(matter_group),
    relatedTasks: String(related_tasks),
    lastEdited: edited_date,
    editedBy: formatName(edited_by) || null,
    isActive: is_active,
    description
  }
}

const getRelativeDueDate = ({ creation_details }: APITask) => {
  if (!creation_details) return undefined
  const { due_date_type, period, delta, timeframe, depends_on } = creation_details
  const timeUnit = TIME_UNIT_OPTIONS.find(o => o.value === period)
  const timeCondition = TIME_CONDITION_OPTIONS.find(o => o.value === timeframe)
  return {
    dateType: due_date_type,
    ...(period && {
      relativeDateParams: {
        amount: delta,
        timeUnit: timeUnit || null,
        timeCondition: timeCondition || null,
        task: depends_on ? { value: depends_on.id, label: depends_on.name } : ASSIGNMENT_DATE_OPTION
      }
    })
  }
}

export const toTasks = (tasks: APITask[]): Task[] => {
  return tasks.map((task: APITask) => {
    const {
      id,
      task_id,
      name,
      assignees,
      priority,
      task_type,
      parent,
      children,
      description,
      subtasks = [],
      created_by,
      created_date
    } = task

    return {
      id,
      name,
      taskId: task_id,
      assignees: assignees.map(assignee => ({
        value: assignee.id,
        label: formatName(assignee) as string
      })),
      relativeDueDate: getRelativeDueDate(task),
      priority: priority
        ? {
            id: priority.id,
            name: priority.client_settings?.display_name ?? priority.default_display_name,
            color: priority.client_settings?.color ?? priority.default_color
          }
        : null,
      taskType: task_type
        ? {
            id: task_type.id,
            name: task_type.name
          }
        : null,
      parent,
      expanded: children > 0,
      hidden: false,
      children,
      description,
      createdBy: created_by
        ? {
            value: created_by.id,
            label: formatName(created_by) as string
          }
        : null,
      createdDate: format(new Date(created_date), DEFAULT_DATE_FNS),
      subtasks: subtasks.map(subtask => ({
        id: String(subtask.id),
        taskId: subtask.task_id,
        name: subtask.name,
        assignees: subtask.assignees.map(assignee => ({
          value: assignee.id,
          label: formatName(assignee) as string
        })),
        relativeDueDate: getRelativeDueDate(subtask),
        draft: false
      })),
      ...(parent ? { borderColor: '#3c99fd' } : {})
    }
  })
}

const getDueDateParams = (relativeDueDate: Task['relativeDueDate']) => {
  const { dateType, relativeDateParams, clearRelativeDueDate } = relativeDueDate || {}
  const { amount, timeUnit, timeCondition, task } = relativeDateParams || {}
  return clearRelativeDueDate
    ? { reset_creation_details: true }
    : {
        due_date_type: dateType,
        ...(!!relativeDateParams?.timeUnit && {
          delta: amount,
          period: timeUnit?.value,
          timeframe: timeCondition?.value,
          depends_on:
            task?.value !== ASSIGNMENT_DATE_OPTION.value
              ? task?.taskId
                ? { task_id: task?.taskId }
                : { name: task?.label }
              : null
        })
      }
}

export const fromTask = (task: Task, isEdit?: boolean) => {
  const { name, assignees, relativeDueDate, priority, taskType, description, subtasks } = task
  const dueDateParams = getDueDateParams(relativeDueDate)
  if (isEdit) {
    return {
      ...(name !== undefined ? { name } : {}),
      ...(assignees !== undefined
        ? { assignee_ids: assignees.length ? assignees.map(assignee => assignee.value) : null }
        : {}),
      ...dueDateParams,
      ...(priority !== undefined
        ? { task_priority_id: priority === null ? null : priority?.id }
        : {}),
      ...(taskType !== undefined ? { task_type_id: taskType === null ? null : taskType?.id } : {}),
      ...(description !== undefined ? { description } : {}),
      ...(subtasks !== undefined
        ? {
            subtasks: subtasks
              .filter(s => !s.draft)
              .map(subtask => ({
                ...(subtask.isNew ? {} : { id: subtask.id }),
                name: subtask.name,
                assignee_ids: subtask.assignees.map(assignee => assignee.value),
                ...getDueDateParams(subtask.relativeDueDate as Task['relativeDueDate'])
              }))
          }
        : {})
    }
  }

  return {
    name,
    assignee_ids: assignees?.map(assignee => assignee.value) ?? [],
    ...dueDateParams,
    task_priority_id: priority?.id ?? null,
    task_type_id: taskType?.id ?? null,
    description,
    subtasks: subtasks
      .filter(s => !s.draft)
      .map(subtask => ({
        ...(subtask.isNew ? {} : { id: subtask.id }),
        name: subtask.name,
        assignee_ids: subtask.assignees.map(assignee => assignee.value),
        ...getDueDateParams(subtask.relativeDueDate as Task['relativeDueDate'])
      }))
  }
}

export const toPrioritiesOptions = (options: APIPriority[]): Option[] => {
  return options.map(option => {
    const { id, default_display_name, client_settings } = option

    return {
      value: id,
      label: client_settings?.display_name ?? default_display_name,
      color: client_settings?.color ?? option.default_color,
      isActive: client_settings?.is_active ?? true
    }
  })
}

export const toTaskTypesOptions = (taskTypes: APITaskType[]): Option[] => {
  return taskTypes.map((type: APITaskType) => {
    const { id, name, is_active } = type

    return {
      value: id,
      label: name,
      isActive: is_active
    }
  })
}
