import { Fragment, useMemo } from 'react'
import { Alert, useFocusInput, CharLimitInput } from 'simple-core-ui'
import { getFileNameExtension } from '../utils'
import * as ACT from '../actions'

const RenameFile = ({ file, folders, dispatch, isDuplicated }) => {
  const changeFileName = (name, extension) => {
    dispatch({
      type: ACT.RENAME_FILE,
      payload: {
        fileName: `${name}${extension}`
      }
    })
  }
  const setFocus = useFocusInput()

  const { fileName, extension } = useMemo(() => getFileNameExtension(file.name), [file.name])

  return (
    <Fragment>
      {isDuplicated && (
        <Alert
          message="This document name already exists. Please specify another name."
          status="error"
        />
      )}
      <label>File Name</label>
      <CharLimitInput
        value={fileName}
        onChangeCb={e => changeFileName(e.target.value, extension)}
        placeholder="File Name"
        hasError={isDuplicated}
      />
    </Fragment>
  )
}

export default RenameFile
