import * as React from 'react'

import { Grid } from 'simple-core-ui'
import { ActivityLogContainer } from 'logbook'

import TeamsListContainer from '../TeamsList/TeamsListContainer'
import TeamModalContainer from '../TeamModal/TeamModalContainer'
import { TEAM_MODEL_NAME } from '../constants'

const TeamsManagement = () => {
  const { Row, Column } = Grid
  return (
    <React.Fragment>
      <TeamModalContainer />
      <Row>
        <Column span={8} breakpoint="monitor">
          <TeamsListContainer />
        </Column>
        <Column span={4} breakpoint="monitor">
          <ActivityLogContainer model={{ name: TEAM_MODEL_NAME }} isBodyOnly />
        </Column>
      </Row>
    </React.Fragment>
  )
}

export default TeamsManagement
