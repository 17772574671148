import classNames from 'classnames'
import { PriorityTip } from 'rules/components'
import { PARAM_SUBTYPE, PARAM_ARG } from 'rules/constants'
import s from '../styles/RuleActions.scss'

const Parameter = ({ availableParam, input, paramIndex }) => (
  <div
    key={`${availableParam.argument_name}_${paramIndex}`}
    className={classNames(s.wholeParam, {
      [s.reviewerConfig]: availableParam.sub_type === PARAM_SUBTYPE.REVIEWER_CONFIG
    })}
  >
    {![PARAM_SUBTYPE.REVIEWER_CONFIG].includes(availableParam.sub_type) && (
      <label className={s.actionParamLabel}>
        {availableParam.argument_name === PARAM_ARG.LADDER_PRIORITY ? (
          <PriorityTip hasUnderline position="top">
            {availableParam.display_name}
          </PriorityTip>
        ) : (
          availableParam.display_name
        )}
      </label>
    )}
    <div>{input}</div>
  </div>
)

export default Parameter
