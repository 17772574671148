import { Button, Checkbox, Input, ModalContainer, Alert } from 'simple-core-ui'
import s from './SSOConfigModal.scss'
import { DomainBased, UserBased, ErrorState } from 'sso_config/SSOConfigContainer'
import { FaTrash } from 'react-icons/fa'
import { useCallback } from 'react'

interface DomainProps {
  domainBased: DomainBased
  error: ErrorState | null
  toggleModal: () => void
  onChange: (key: string, value: any) => void
  onCreate: () => void
  isEdit: boolean
  onEdit: () => void
}

interface UserProps {
  userBased: UserBased
  error: ErrorState | null
  toggleModal: () => void
  onChange: (key: string, value: any) => void
  onCreate: () => void
  isEdit: boolean
  onEdit: () => void
}

const parseError = (errorState: ErrorState): string => {
  if (!errorState) {
    return ''
  }

  const { error } = errorState
  const COULD_NOT_PARSE = 'Could not parse error'

  if (typeof error === 'string') {
    return error
  }

  if (typeof error === 'object') {
    return Object.keys(error)
      .map(k => {
        return `${k}: ${JSON.stringify(error[k])}`
      })
      .join(', ')
  }

  return COULD_NOT_PARSE
}

const SSODomainModal = ({
  domainBased,
  toggleModal,
  onChange,
  onCreate,
  isEdit,
  onEdit,
  error
}: DomainProps) => {
  const updateEmailDomain = useCallback(
    (value: string, i: number) => {
      // parent needs immutable update
      const newEmailDomains = [...domainBased.email_domains]
      newEmailDomains[i].email_domain = value
      onChange('email_domains', newEmailDomains)
    },
    [domainBased, onChange]
  )

  return (
    <ModalContainer
      isDisabled={!domainBased?.email_domains?.length}
      shouldModalClose={false}
      title={isEdit ? 'Edit Domain Based Connection' : 'Add Domain Based Connection'}
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleModal}
      confirmText="Save"
      confirmCb={isEdit ? onEdit : onCreate}
      content={
        <div className={s.modalBody}>
          {error && <Alert message={parseError(error)} status="error" />}
          <div className={s.info}>Name</div>
          <Input
            text={domainBased.name}
            onChangeCb={e => onChange('name', e.target.value)}
            placeholder="Name"
          />
          <div className={s.info}>IDP UUID</div>
          <Input
            text={domainBased.idp_id}
            onChangeCb={e => onChange('idp_id', e.target.value)}
            placeholder="IDP UUID"
          />
          <div className={s.emailDomains}>
            {domainBased.email_domains.length > 0 && <div className={s.info}>Email Domains</div>}
            <div>
              {domainBased.email_domains.map((e, i) => {
                return (
                  <div className={s.formRow} key={i}>
                    <div>
                      <Input
                        text={e.email_domain}
                        onChangeCb={e => updateEmailDomain(e.target.value, i)}
                      />
                    </div>

                    <div>
                      <span style={{ marginRight: 4 }}>Provisioned:</span>
                      <Checkbox
                        size="md"
                        isChecked={e.provisioned}
                        styles={{ verticalAlign: 'middle' }}
                        triggerCheckbox={e => {
                          const newEmailDomains = [...domainBased.email_domains]
                          newEmailDomains[i].provisioned = e.target.checked
                          onChange('email_domains', newEmailDomains)
                        }}
                      />
                    </div>
                    <FaTrash
                      style={{ fontSize: 18, color: 'gray', cursor: 'pointer' }}
                      onClick={() => {
                        const newEmailDomains = [...domainBased.email_domains]
                        newEmailDomains.splice(i, 1)
                        onChange('email_domains', newEmailDomains)
                      }}
                    />
                  </div>
                )
              })}
              <Button
                style={{ marginLeft: 0, marginTop: 12 }}
                isSecondary
                onClick={() => {
                  const newEmailDomains = [
                    ...domainBased.email_domains,
                    {
                      email_domain: '',
                      provisioned: false
                    }
                  ]
                  onChange('email_domains', newEmailDomains)
                }}
              >
                Add Email Domain
              </Button>
            </div>
          </div>
        </div>
      }
    />
  )
}

const SSOUserModal = ({
  userBased,
  toggleModal,
  onChange,
  onCreate,
  isEdit,
  onEdit,
  error
}: UserProps) => {
  return (
    <ModalContainer
      shouldModalClose={false}
      title={isEdit ? 'Edit User Based Connection' : 'Add User Based Connection'}
      cancelText="Cancel"
      size="sm"
      cancelCb={toggleModal}
      confirmText="Save"
      confirmCb={isEdit ? onEdit : onCreate}
      content={
        <div className={s.modalBody}>
          {error && <Alert message={parseError(error)} status="error" />}
          <div className={s.info}>Name</div>
          <Input
            text={userBased.name}
            onChangeCb={e => onChange('name', e.target.value)}
            placeholder="Name"
          />
          <div className={s.info}>IDP UUID</div>
          <Input
            text={userBased.idp_id}
            onChangeCb={e => onChange('idp_id', e.target.value)}
            placeholder="IDP UUID"
          />
          <div style={{ marginTop: 8 }}>
            <span style={{ marginRight: 4 }}>Default:</span>
            <Checkbox
              size="md"
              isChecked={userBased.is_default}
              styles={{ verticalAlign: 'middle' }}
              triggerCheckbox={e => {
                onChange('is_default', e.target.checked)
              }}
            />
          </div>
        </div>
      }
    />
  )
}

export { SSOUserModal, SSODomainModal }
