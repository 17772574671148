import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import APP_ACT from 'app/actions'
import { fromFragment, reorderFields, toReactSelect } from 'bulk/serializer'
import { makeGetRequest } from 'utils/api'
import s from 'bulk/builder/stepper/BulkBuilder/BulkBuilderContainer.scss'
import {
  ModalContainer,
  Stepper,
  useLoading,
  useScrollIntoView,
  PaginatedSelect
} from 'simple-core-ui'
import DynamicBulkTable from 'bulk/builder/list/DynamicBulkTable/DynamicBulkTable'
import TableFieldsSelect from './FieldsSelector/TableFieldsSelection'
import FieldsSelector from './FieldsSelector/FieldsSelector'
import Select from 'react-select'
import { CUSTOM_ATTRIBUTE_TYPES, MATTER_TEMPLATE_WITH_UPDATE_URL } from 'bulk/constants'
import { selectStyles } from 'bulk/styles/styleSettings'
import cn from 'classnames'
import BuilderFieldsModal from './BuilderFieldsModal/BuilderFieldsModal'
import { sortObjectsList } from '../../../utils'
import { MATTER_CUSTOM_ATTRIBUTES, MATTER_TEMPLATES } from 'bulk/record_types'

const BulkStepper = ({
  tableFields,
  setTableFields,
  setFilters,
  setRecord,
  filters,
  setStep,
  step,
  record,
  recordType,
  setActiveTab,
  attrType = {},
  setAttrType,
  setPreSelected,
  templateType,
  setTemplateType
}) => {
  const frag = fromFragment()
  const dispatch = useDispatch()
  const builderParams = useSelector(state => state.bulk.builderParams)
  const [isLoading, withLoadingLocks] = useLoading()
  const [isAttrVisible, setAttrVisible] = useState(
    frag.record?.value === MATTER_CUSTOM_ATTRIBUTES ||
      builderParams.record.value === MATTER_CUSTOM_ATTRIBUTES
  )
  const [isTemplateVisible, setTemplateVisible] = useState(
    frag.record?.value === MATTER_TEMPLATES || builderParams.record.value === MATTER_TEMPLATES
  )
  const [type, setType] = useState('')
  const [selectedFields, setSelectedFields] = useState([])

  const addFieldRef = useScrollIntoView([type === '', tableFields.length, isLoading])
  const addFilterRef = useScrollIntoView([type === '', filters.length, isLoading])

  useEffect(() => {
    const fetchFields = async () => {
      const url = `/bulkv2/bulk_builder/?record_type=${record.value}${
        attrType ? `&ca_type=${attrType.value}` : ''
      }${templateType ? `&template_id=${templateType.value}` : ''}`
      try {
        const { fields: columns, preselected_columns: preselectedFields } = await withLoadingLocks(
          makeGetRequest(url)
        )
        const { fields: currentFields = [], filters: currentFilters } = frag.fields.length
          ? frag
          : builderParams
        setPreSelected(preselectedFields)

        const reordered = reorderFields(columns, record.value)
        const filterableFields = reordered.filter(col => col.filterable)

        setFilters(
          filterableFields.map(field => ({
            value: field.column_name,
            label: field.display_name,
            type: field.field_type,
            field_type: field.field_type,
            isDefault: false,
            isSelected: currentFilters.some(filter => filter.value === field.column_name),
            hideOption: false
          }))
        )

        setTableFields(
          reordered.map(column => ({
            value: column.column_name,
            label: column.display_name,
            type: column.field_type,
            isDefault: preselectedFields.includes(column.column_name),
            isSelected:
              currentFields.some(field => field.value === column.column_name) ||
              preselectedFields.includes(column.column_name),
            hideOption: false
          }))
        )
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching fields for this record type.',
            level: 'error'
          }
        })
      }
    }
    step > 1 && step < 5 ? fetchFields() : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    ;['field', 'filter'].includes(type) &&
      setSelectedFields(type === 'field' ? tableFields : filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const clearBuilderParams = () => {
    setTableFields([])
    setFilters([])
    setPreSelected([])
    hideModal()
  }

  const templateContent = step === 1 && (
    <div className={s.recordTypeContainer}>
      <PaginatedSelect
        id="matter_template"
        url={MATTER_TEMPLATE_WITH_UPDATE_URL}
        className={s.recordTypeInput}
        serializer={toReactSelect}
        value={templateType}
        onChange={item => {
          setStep(step + 1)
          setTemplateType(item)
          clearBuilderParams()
        }}
        styles={selectStyles}
      />
    </div>
  )

  const attributeContent = step === 1 && (
    <Select
      options={CUSTOM_ATTRIBUTE_TYPES}
      value={attrType}
      onChange={item => {
        setStep(step + 1)
        setAttrType(item)
        clearBuilderParams()
      }}
      placeholder="Select or start typing"
      className={s.recordTypeInput}
      styles={selectStyles}
    />
  )

  const recordContent = step === 1 && (
    <div className={s.recordTypeContainer}>
      {!isAttrVisible && !isTemplateVisible && (
        <>
          <p>
            Record<span className={s.requiredField}>*</span>
          </p>
          <Select
            options={sortObjectsList(recordType.slice())}
            value={record}
            onChange={item => {
              item.value === MATTER_CUSTOM_ATTRIBUTES
                ? setAttrVisible(true)
                : item.value === MATTER_TEMPLATES
                ? setTemplateVisible(true)
                : setStep(step + 1)
              setRecord(item)
              clearBuilderParams()
              setTemplateType('')
              setAttrType('')
            }}
            placeholder="Select or start typing"
            className={s.recordTypeInput}
            styles={selectStyles}
            filterOption={({ data }, inputValue) =>
              data.action.edit && data.label.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
        </>
      )}
    </div>
  )

  const hideModal = () => {
    setType('')
    setSelectedFields([])
  }

  const confirmSelection = () => {
    const fieldList = selectedFields.map(f => ({ ...f, hideOption: false }))
    if (type === 'field') {
      setTableFields(reorderFields(fieldList, record.value))
    } else {
      setFilters(reorderFields(fieldList, record.value))
    }
    hideModal()
  }

  const renderSubstep = (title, type, content, setSubtypeFn) => [
    {
      text: (
        <p>
          {title}
          {type && (
            <>
              : <span className={s.recordLabel}>{type.label}</span>
            </>
          )}
        </p>
      ),
      content,
      completed: Boolean(type),
      onClick: () => {
        setStep(1)
        setSubtypeFn('')
        clearBuilderParams()
      }
    }
  ]

  return step <= 4 ? (
    <>
      <div className={s.stepperContainer}>
        <Stepper
          orientation="vertical"
          steps={[
            {
              text: (
                <p className={s.stepTitle}>
                  Choose a record to update{' '}
                  {record && (
                    <>
                      : <span className={s.recordLabel}>{record.label}</span>
                    </>
                  )}
                </p>
              ),
              content: recordContent,
              completed: Boolean(record),
              onClick: () => {
                setStep(1)
                setAttrVisible(false)
                setTemplateVisible(false)
                setRecord('')
                setTemplateType('')
                setAttrType('')
                clearBuilderParams()
              },
              style: { cursor: 'pointer' }
            },
            ...(isTemplateVisible
              ? renderSubstep(
                  'Choose a matter template',
                  templateType,
                  templateContent,
                  setTemplateType
                )
              : isAttrVisible
              ? renderSubstep('Choose an attribute type', attrType, attributeContent, setAttrType)
              : []),
            {
              text: (
                <p className={cn(s.stepTitle, { [s.disabledStep]: step === 1 })}>
                  Choose record fields
                </p>
              ),
              completed: step >= 3,
              content: step >= 2 && (
                <TableFieldsSelect
                  id="field"
                  recordContent={recordContent}
                  setTableFields={setTableFields}
                  tableFields={tableFields}
                  setStep={setStep}
                  isLoading={isLoading}
                  step={step}
                  record={record}
                  addButtonText="Add Field"
                  attrType={attrType}
                  setType={setType}
                  buttonRef={addFieldRef}
                />
              )
            },
            {
              text: (
                <p className={cn(s.stepTitle, { [s.disabledStep]: step === 1 })}>
                  Choose optional filters to narrow your results
                </p>
              ),
              completed: step >= 4,
              content: step >= 2 && (
                <div className={s.filtersContainer}>
                  <FieldsSelector
                    id="filter"
                    fields={filters}
                    setFields={setFilters}
                    title="Filters"
                    isLoading={isLoading}
                    addButtonText="Add Filter"
                    setStep={setStep}
                    setType={setType}
                    recType={record.value}
                    buttonRef={addFilterRef}
                  />
                </div>
              )
            }
          ]}
        />
      </div>
      {type ? (
        <ModalContainer
          title={type === 'field' ? 'Add Field' : 'Add Filter'}
          size="md"
          cancelText="Cancel"
          confirmText="Apply"
          cancelCb={hideModal}
          confirmCb={confirmSelection}
          content={
            <BuilderFieldsModal
              type={type}
              fields={type === 'field' ? tableFields : filters}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              recType={record.value}
            />
          }
          isDisabled={false}
        />
      ) : null}
    </>
  ) : (
    <DynamicBulkTable setStepCb={setStep} setActiveTab={setActiveTab} />
  )
}

export default BulkStepper
