export enum ConnectionType {
  CLIENT_BASED = 'CLIENT_BASED',
  USER_BASED = 'USER_BASED'
}

export interface Tab {
  label: string
  value: ConnectionType
}

export const SSO_TABS: Tab[] = [
  {
    label: 'Domain Based Connections',
    value: ConnectionType.CLIENT_BASED
  },
  { label: 'User Based Connections', value: ConnectionType.USER_BASED }
]
