import { Component } from 'react'
import { Panel, List, Tabs, Tab } from 'simple-core-ui'
import DashboardItem from '../components/DashboardItem'
import { connect } from 'react-redux'
import { DashboardSkeleton } from 'common'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'
import ACT from '../actions'
import Expand from '../components/Expand'

class InnerPanel extends Component {
  render() {
    const { recentItems, expanded } = this.props
    return (
      <Tabs>
        <Tab header="Last Week">
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={recentItems.lastWeek}
              fallback={'No Recent Actions'}
              render={item => {
                const { url, model, name } = item
                return <DashboardItem title={name} secondaryTitle={model} url={url} />
              }}
            />
          </div>
        </Tab>
        <Tab header="Last 30 Days">
          <div className={expanded ? '' : 'innerTabContainer'}>
            <List
              items={recentItems.lastMonth}
              fallback={'No Recent Actions'}
              render={item => {
                const { url, model, name } = item
                return <DashboardItem title={name} secondaryTitle={model} url={url} />
              }}
            />
          </div>
        </Tab>
      </Tabs>
    )
  }
}

@connect(({ dashboard }) => {
  const { recentItems } = dashboard
  return {
    recentItems
  }
})
@withFetchFlow({
  flag: 'Recent_Items',
  render: () => <DashboardSkeleton />,
  getFetchAction: () => ({
    type: ACT.RECENT_ITEMS_FETCH_REQUESTED
  })
})
class RecentItems extends Component {
  render() {
    const title = 'Most Frequently Viewed'

    return (
      <Panel
        className="dashboard-panel"
        title={title}
        rightActions={[
          <Expand key={0} title={title}>
            <InnerPanel {...this.props} expanded />
          </Expand>
        ]}
      >
        <InnerPanel {...this.props} />
      </Panel>
    )
  }
}

export default RecentItems
