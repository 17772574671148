//Groups:
// Audit, Spend, Matters -> Spend, Matters, Audit

export const STANDARD_REPORTS = {
  '1000hr+ Invoices':
    'Tabular report indicating invoices to which more than 1,000 hours were billed',
  '8hr+ Timekeepers':
    'Tabular report indicating timekeepers billing more than 8 hours on a single day',
  'Spend Overview':
    'Spend overview dashboard with the Top 10 vendors, practice areas, countries and timekeeper levels',
  'Spend by Vendor Country': 'Map and tabular data displaying billing by vendor country',
  'Billed Matters': 'Active matters and how long they have been open, with billing to date',
  'Timekeeper Rates': 'Dashboard of timekeepers and rates by timekeeper level',
  'Spend over Time':
    'Chart and table for tracking cumulative spend over time, detailed by practice area',
  'Invoice List - Paid Invoices': 'List of paid invoices with matter information',
  'Invoice List - Unpaid Invoices': 'List of unpaid invoices with matter information',
  'Spend by Practice Area': 'Scatter chart, bar chart and list of spend by practice area',
  'Spend by Vendor': 'Scatter chart, bar chart and list of spend by vendor',
  'Spend by Matter Lead': 'Scatter chart, bar chart and list of spend by matter lead',
  'Spend by Matter': 'Scatter chart, bar chart and list of spend by matter',
  'Spend by Task Code': 'Scatter chart, bar chart and list of spend by task code',
  'Spend by Timekeeper Level': 'Scatter chart, bar chart and list of spend by timekeeper level',
  'Spend by Timekeeper': 'Scatter chart, bar chart and list of spend by timekeeper',
  'Spend Grid - Practice Area':
    'Spend by practice area grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Vendor':
    'Spend by vendor grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Matter Lead':
    'Spend by matter lead grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Matter':
    'Spend by matter grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Task Code':
    'Spend by task code grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Timekeeper Level':
    'Spend by timekeeper level grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Timekeeper':
    'Spend by timekeeper grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Vendor by Timekeeper Level':
    'Spend by vendor and timekeeper level grid, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Spend Grid - Task Code by Timekeeper Level':
    'Spend by task code and timekeeper level, comparing two years of fees, expenses, adjustments, discounts and total spend',
  'Matter Inventory':
    'Matter inventory analysis indicating which matters are active, have opened or closed in a period'
}
