import moment from 'moment'
import { GENERAL_ATTRIBUTES } from './constants'

export const serializeAttrs = (attrsMap, matterId) => {
  let generalAttrs = {}
  let finalAttrsMap = {}

  Object.keys(attrsMap).forEach(key => {
    finalAttrsMap[key] = attrsMap[key].map(s => {
      const attrs = s.sections.map(s => {
        return {
          ...s,
          attributes: s.attributes.map(a => {
            let value =
              a.field_type === 'list' && a.value
                ? { value: a.value.value_list_id, label: a.value.value_list_label }
                : a.value?.value
                ? { value: a.value.value, label: a.value.value }
                : a.value

            const isGeneral = GENERAL_ATTRIBUTES.includes(a.name)

            if (a.field_type === 'date' && a.value) {
              value = moment(a.value?.value ? a.value.value : a.value).format('MM/DD/YYYY')
            }

            if (a.type === 'boolean') {
              value = String(a.value)
            }

            if (a.field_type === 'percentage' || a.field_type === 'decimal') {
              value = parseFloat(a.value)
            }

            const newAttr = {
              id: a.id ?? matterId,
              label: a.label ?? a.name,
              name: a.name,
              readOnly: !a.editable,
              required: a.required,
              type: a.field_type ?? a.type,
              value,
              isGeneral
            }

            if (isGeneral) {
              generalAttrs[newAttr.name] = newAttr
            }
            return newAttr
          })
        }
      })

      return {
        expanded: s.display_expanded,
        groupId: s.group_id,
        sortOrder: s.sort_order,
        name: s.group_name,
        attributes: attrs
      }
    })
  })

  finalAttrsMap.generalAttributes = generalAttrs

  return finalAttrsMap
}

export const setAttributeValue = (value, attrType) => {
  if (attrType === 'currency') {
    return value?.value
      ? { amount: value.value.amount, currency_code: value.value.code }
      : { amount: value.amount, currency_code: value.code }
  } else {
    return value?.value ? value.value : value
  }
}

export const getInitialGeneralAttrValue = value =>
  value?.id ? { value: value?.id, label: value?.name } : value?.value ? value : null

export const getInitialGeneralAttrDropdownValue = value =>
  value?.id ? { value: value?.id, label: value?.name } : null

export const validateAttributeValue = (value, attr) => {
  if (attr.type === 'currency') {
    if (!value.currency_code) return 'Please select a currency code'
    if (isNaN(value.amount)) return 'Please set a currency amount'
  }
}
