import ACT from './actions'
import { put, call, takeLatest } from 'redux-saga/effects'
import { makePostRequest } from 'utils/api'

function* handleFetchEventSuccess(payload) {
  yield put({
    type: ACT.ACCRUALS_ACTUALS_FETCH_SUCCESS,
    loadingLock: 'off',
    payload
  })
}

function* handleFetchEventError() {
  yield put({
    type: ACT.ACCRUALS_ACTUALS_RESET
  })
  yield put({
    type: ACT.PUSH_NOTIFICATION,
    loadingLock: 'off',
    payload: {
      title: 'No data found while searching.',
      level: 'error'
    }
  })
}

function* fetchEvents({ payload }) {
  const serializedPayload = {
    page: payload.page,
    size: payload.pageSize,
    search: payload.search,
    vendor_id_list: payload.vendor_id_list,
    end_date: payload.end_date,
    start_date: payload.start_date
  }
  try {
    const {
      vendor_data,
      filtered_results: filteredResults,
      total_results: totalResults,
      excel_url: excelUrl
    } = yield call(makePostRequest, '/reports/accruals_vs_actuals_v2/', serializedPayload)

    yield* handleFetchEventSuccess({ vendor_data, totalResults, excelUrl, filteredResults })
  } catch (error) {
    yield* handleFetchEventError()
  }
}

function* handleFetchMattersSuccess(payload) {
  yield put({
    type: ACT.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_SUCCESS,
    loadingLock: 'off',
    payload
  })
}

function* handleFetchMattersError(error) {
  yield put({
    type: ACT.ACCRUALS_ACTUALS_RESET
  })
  yield put({ type: 'API_ERROR', error })
}

function* fetchMatters({ payload }) {
  const serializedPayload = {
    page: payload.page,
    size: payload.pageSize,
    vendor_id: payload.vendor_id,
    vendor_currency: payload.vendor_currency,
    end_date: payload.end_date,
    start_date: payload.start_date
  }

  try {
    const { page, vendor_data } = yield call(
      makePostRequest,
      '/reports/accruals_vs_actuals_v2_vendor_matters_details/',
      serializedPayload
    )
    yield* handleFetchMattersSuccess({ page, vendor_data })
  } catch (error) {
    yield* handleFetchMattersError(error)
  }
}

const eventSagas = [
  takeLatest(ACT.ACCRUALS_ACTUALS_FETCH_REQUESTED, fetchEvents),
  takeLatest(ACT.ACCRUALS_ACTUALS_VENDOR_MATTERS_FETCH_REQUESTED, fetchMatters)
]

export default eventSagas
