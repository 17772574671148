import s from './ActionsPopover.scss'
import { HoverAction, Popover } from 'simple-core-ui'
import { IoIosMore } from 'react-icons/io'
import { Subtask, Task } from '../types'

interface Props {
  deleteSubtask: (index: number) => void
  duplicateTask?: (task: Task | Subtask, parentId?: number | string) => Promise<void>
  toggleDraft: (value: number) => void
  onEditSubtask?: (task: Subtask) => void
  index: number
  subtask: Subtask | null
  parentId?: number | string
}

const ActionsPopover = ({
  deleteSubtask,
  index,
  toggleDraft,
  subtask,
  onEditSubtask,
  duplicateTask,
  parentId
}: Props) => {
  const actions = [
    {
      name: 'COPY',
      text: 'Copy',
      onClick() {
        if (!duplicateTask || !subtask) return
        duplicateTask(subtask, parentId)
      }
    },
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        if (subtask) {
          onEditSubtask?.(subtask)
        } else {
          toggleDraft(index)
        }
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteSubtask(index)
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
