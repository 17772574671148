import { useState, useEffect } from 'react'
import s from './BulkEditModalContainer.scss'
import { useDispatch, useSelector } from 'react-redux'
import ACT from 'bulk/actions'
import { IoIosSearch } from 'react-icons/io'
import { fes } from 'scui/utils/formatting'
import GenericFormBuilder from './Forms/GenericFormBuilder'
import MultiFieldForm from './Forms/MultiFieldForm'
import { MULTI_FIELD_RECORD_TYPES } from 'bulk/record_types'
import { TextInput } from 'simple-core-ui'
import { modalInputFieldStyles } from 'bulk/styles/styleSettings'

const BulkEditModalContainer = ({
  recordsCount,
  selectedFields = {},
  setSelectedFields,
  errors,
  setErrors
}) => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [formValues, setFormValues] = useState({})
  const recordType = useSelector(state => state.bulk.builderParams.record)
  const dynamicFields = useSelector(state => state.bulk.bulkFields)

  const selectedAttributes = Object.values(selectedFields).filter(Boolean).length
  const totalAttributes = dynamicFields.filter(f => f.updatable).length

  useEffect(() => {
    dispatch({
      type: ACT.UPDATE_BULK_FIELDS_VALUES,
      payload: {
        ...formValues
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const handleSearch = e => {
    setSearchString(e)
  }

  const customProps = {
    recordType,
    selectedFields,
    searchString,
    formValues,
    setFormValues,
    setSelectedFields,
    errors,
    setErrors
  }

  return (
    <div className={s.modalContainer}>
      <p className={s.recordsCountText}>{`${fes(recordsCount, 'records')} selected`}</p>
      <div className={s.searchFieldContainer}>
        <IoIosSearch className={s.searchIcon} />
        <TextInput
          placeholder="Search fields"
          onChange={handleSearch}
          value={searchString}
          style={modalInputFieldStyles}
        />
        {searchString && (
          <a className={s.clearText} onClick={() => setSearchString('')}>
            Clear
          </a>
        )}
      </div>

      {!MULTI_FIELD_RECORD_TYPES.includes(recordType.value) ? (
        <p>{`${selectedAttributes} of ${fes(totalAttributes, 'Attributes')} Selected`}</p>
      ) : null}

      {MULTI_FIELD_RECORD_TYPES.includes(recordType.value) ? (
        <MultiFieldForm {...customProps} />
      ) : (
        <GenericFormBuilder dynamicFields={dynamicFields} {...customProps} />
      )}
    </div>
  )
}

export default BulkEditModalContainer
