import { Component } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import * as CONST from 'matters/templates/constants'
import get from 'lodash/get'
import s from './PreviewPanelContent.scss'
import { PreviewPanelAttributesContent } from './PreviewPanelAttributesContent'
import { getCurrencyAttributeLabel } from 'matters/templates/utils'

class PreviewPanelContent extends Component {
  getOptionLabel = (options, value) => {
    value = get(value, 'value', value)
    const index = options.findIndex(option => {
      return option.value === Number(value)
    })
    if (!options[index]) return 'None'

    return options[index].label
  }
  getAttributeValue = attribute => {
    let value
    switch (attribute.type) {
      case 'percentage':
        value = `${get(attribute, 'value', '-') || '-'} %`
        break
      case 'decimal':
      case 'text':
      case 'textarea':
      case 'date':
        value = get(attribute, 'value', '-')
        break
      case 'boolean':
      case 'select':
        value = this.getOptionLabel(attribute.options, attribute.value)
        break
      case 'currency':
        value = getCurrencyAttributeLabel(attribute)
        break
      case CONST.SYSTEM_GENERATED:
        value = 'System Generated'
        break
      default:
        value = 'Unsupported Attribute'
    }

    return value
  }
  renderContent = (section, index) => {
    let items = []

    items.push(<FaAngleDown key={`${section.sectionName}_arrow_${index}`} className={s.arrow} />)

    items.push(
      <PreviewPanelAttributesContent
        key={`${section.sectionName}_${index}`}
        section={section}
        index={index}
        getAttributeValue={this.getAttributeValue}
      />
    )

    return items
  }
  render() {
    const { sections } = this.props
    return sections.map((section, index) => {
      return (
        <div className={s.section} key={`${section.sectionName}_${index}`}>
          <p>{section.sectionName}</p>
          {this.renderContent(section, index)}
        </div>
      )
    })
  }
}

export default PreviewPanelContent
