import { useState } from 'react'
import SearchSelectBody from './SearchSelectBody'
import { getSelection, getValue } from '../helpers'
import { LabelsSelect } from 'common/Selects'
import BaseFilter from './BaseFilter'

const LabelFilter = ({ onSelect, reset, hasConfirmation }) => {
  const [labels, setLabels] = useState(undefined)

  const selection = getSelection(labels, 'Labels')

  return (
    <BaseFilter
      hasConfirmation={hasConfirmation}
      title="Label"
      reset={reset}
      onSelect={onSelect}
      render={(setFilterSelection, setIsOpen, initialValues) => {
        const value = getValue(labels, initialValues)
        return (
          <SearchSelectBody
            onClear={() => setLabels([])}
            onSubmit={() => setFilterSelection(selection)}
          >
            <LabelsSelect
              value={value}
              onChange={items => setLabels(items)}
              isPortal={false}
              isClearable
            />
          </SearchSelectBody>
        )
      }}
    ></BaseFilter>
  )
}

export default LabelFilter
