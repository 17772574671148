import s from './CourtRuleStep.scss'
import { Tooltip, CharLimitInput } from 'simple-core-ui'
import { DatePickerSelect } from 'common/Selects'
import { TimePicker } from 'common/TimePicker'
import { format } from 'date-fns'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { ExtendedCourtRule } from '../types'
import { Updater } from 'use-immer'
import { combineDateAndTimeString } from 'utils/helpers'

interface Props {
  courtRule: ExtendedCourtRule
  setCourtRule: Updater<ExtendedCourtRule>
  scopeName: string
  isTimeRequired: boolean
}

const CourtRuleStep = ({ courtRule, setCourtRule, scopeName, isTimeRequired }: Props) => {
  return (
    <>
      <div className={s.item}>
        <p className={s.label}>
          Court Rule Name <span style={{ color: 'red' }}>*</span>
        </p>
        <CharLimitInput
          value={courtRule?.name ?? ''}
          placeholder="Enter a Court Rule Name"
          onChangeCb={e =>
            setCourtRule(draft => {
              draft.name = e.target.value
            })
          }
          maxLength={25}
          dynamicCharCalculation
          customErrorMessage="Maximum character limit reached"
        />
      </div>
      <div className={s.item}>
        <p className={s.label}>Matter</p>
        <p className={s.matterName}>{scopeName}</p>
      </div>
      <div className={s.item}>
        <p className={s.label}>Jurisdiction</p>
        <p>{courtRule.jurisdiction?.label}</p>
      </div>
      <div className={s.item}>
        <p className={s.label}>Trigger Event</p>
        <p>{courtRule.triggerEvent?.label}</p>
      </div>
      <div className={s.flex}>
        <div className={s.item}>
          <p className={s.label}>
            Trigger Date <span style={{ color: 'red' }}>*</span>
          </p>
          <div style={{ width: 500 }}>
            <DatePickerSelect
              value={courtRule.triggerDate as Date}
              selectDate={(dueDate: Date | undefined) => {
                setCourtRule(draft => {
                  if (courtRule.time?.[0] && dueDate) {
                    draft.triggerDate = combineDateAndTimeString(dueDate, courtRule.time[0])
                  } else {
                    draft.triggerDate = dueDate
                  }
                })
              }}
            />
          </div>
        </div>
        <div className={s.item}>
          <div className={s.label}>
            Time {isTimeRequired ? <span style={{ color: 'red' }}>*</span> : ''}
            <Tooltip
              trigger={<AiOutlineInfoCircle className={s.info} />}
              content="Time zone is from the Jurisdiction"
              placement="top"
            />
          </div>
          <TimePicker
            onConfirm={(values: (string | null)[]) => {
              setCourtRule(draft => {
                draft.time = values
              })
            }}
            values={
              courtRule.triggerDate
                ? [format(courtRule.triggerDate as Date, 'HH:mm'), null]
                : [null, null]
            }
            withLabel={false}
            className={s.timePicker}
            withButtons={false}
            timezone={courtRule.jurisdiction.timezone}
          />
          {isTimeRequired && courtRule.time?.[0] === '00:00' && (
            <p className={s.error}>Please enter a time before midnight</p>
          )}
        </div>
      </div>
      <div className={s.item} style={{ position: 'relative', bottom: 30, marginTop: 0 }}>
        <div className={s.label}>
          Prefix{' '}
          <Tooltip
            trigger={<AiOutlineInfoCircle className={s.info} />}
            content="The prefix is prepended to the event name"
            placement="top"
          />
        </div>
        <p>[{courtRule.prefix}]</p>
      </div>
    </>
  )
}

export default CourtRuleStep
