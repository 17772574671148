import { useSelector } from 'react-redux'
import { BsClockHistory } from 'react-icons/bs'
import s from '../styles/ReforecastDiff.scss'
import { currency2Num, currencyAwareFormat } from 'utils/formatting'

export const BADiff = ({ columnKey }) => {
  const { lastRfDetails, budgetDetails } = useSelector(({ budgets }) => budgets)

  if (!lastRfDetails) {
    return null
  }

  const currency = budgetDetails.budget_currency

  const lastRfTotal = currency2Num(lastRfDetails[columnKey])
  const curTotal = currency2Num(budgetDetails[columnKey])

  if (curTotal === lastRfTotal) {
    return null
  }

  return (
    <div className={s.container}>
      <div className={s.popupContainerAmount}>
        <section>
          <div>Previous value</div>
          <div>{currencyAwareFormat(lastRfTotal, currency)}</div>
        </section>
      </div>
      <BsClockHistory />
    </div>
  )
}

const RFDiff = ({ rowIndex, columnKey }) => {
  const { lastRfDetails, budgetDetails } = useSelector(({ budgets }) => budgets)

  if (!lastRfDetails) {
    return null
  }

  const currency = budgetDetails.budget_currency

  const lastRfDetailsRow = lastRfDetails.rows[rowIndex]
  const curDetailsRow = budgetDetails.rows[rowIndex]

  let lastRfFees = 0,
    lastRfExpenses = 0,
    lastRfTotal = 0
  if (lastRfDetailsRow) {
    lastRfFees = currency2Num(lastRfDetailsRow[`${columnKey}_fees`])
    lastRfExpenses = currency2Num(lastRfDetailsRow[`${columnKey}_expenses`])
    lastRfTotal = currency2Num(lastRfDetailsRow[`${columnKey}_total`])
  }

  const curTotal = currency2Num(curDetailsRow[`${columnKey}_total`])

  if (curTotal === lastRfTotal) {
    return null
  }

  return (
    <div className={s.container} data-testid={`rf-diff-${rowIndex}_${columnKey}`}>
      <div className={s.popupContainer}>
        <div className={s.header}>Previous values</div>
        <section>
          <div>Fees</div>
          <div>{currencyAwareFormat(lastRfFees, currency)}</div>
        </section>
        <section>
          <div>Expenses</div>
          <div>{currencyAwareFormat(lastRfExpenses, currency)}</div>
        </section>
        <section>
          <div>Total</div>
          <div>{currencyAwareFormat(lastRfTotal, currency)}</div>
        </section>
      </div>
      <BsClockHistory />
    </div>
  )
}

export default RFDiff
