import { Fragment } from 'react'
import Select from 'react-select'
import get from 'lodash/get'

import { makeGetRequest } from 'utils/api'
import { nameToLabel } from 'rules/utils'
import { FUNC_ARG_TYPES } from 'rules/constants'
import { OPERATOR, TYPE, getRhsInputType, INPUT } from 'data/operators'
import { FieldByType } from './CustomValue'

import s from '../styles/FuncForm.scss'
import sCValue from '../styles/CustomValue.scss'

const loadCb = url => async search => {
  const response = await makeGetRequest(url, { params: { search } })

  return response.map(option => ({
    label: option.label,
    value: option.value,
    constant: { label: option.label, value: option.value },
    arg_type: FUNC_ARG_TYPES.CONST
  }))
}

const FuncParamField = ({ parameter, parameterLookup, funcFieldArgsByType, onChange }) => {
  const { type, sub_type } = parameterLookup

  const fieldArgs = get(funcFieldArgsByType, [type, sub_type], []).map(fieldArg => ({
    ...fieldArg,
    label: `${fieldArg.model_name} · ${fieldArg.display_name}`,
    value: `${fieldArg.model_name} · ${fieldArg.display_name}`
  }))

  const op =
    type === TYPE.DATE
      ? OPERATOR.ON
      : [TYPE.CURRENCY, TYPE.NUMBER].includes(type)
      ? OPERATOR.EQUAL
      : OPERATOR.IS

  const loadUrl = get(fieldArgs, '0.url', '')
  const inputType = getRhsInputType(type, loadUrl, op)
  const inputValue =
    inputType === INPUT.STRING || inputType === INPUT.NUMBER || inputType === INPUT.DATE
      ? parameter?.label
      : parameter
  const argType = parameter.arg_type

  return (
    <section className={s.field}>
      <span className={s.label}>{nameToLabel(parameter.arg_name)}</span>
      <FieldByType
        type={inputType}
        changeCb={
          loadUrl
            ? onChange
            : option => onChange({ ...option, arg_type: FUNC_ARG_TYPES.CONST, constant: option })
        }
        value={argType === FUNC_ARG_TYPES.CONST ? inputValue : null}
        {...(loadUrl ? { loadCb: loadCb(loadUrl) } : {})}
      />
      {fieldArgs.length && (
        <Fragment>
          <hr className={s.separator} />
          <Select
            className={sCValue.selectContainer}
            options={fieldArgs}
            value={argType === FUNC_ARG_TYPES.FIELD ? parameter : null}
            onChange={onChange}
          />
        </Fragment>
      )}
    </section>
  )
}

export default FuncParamField
