import { Component, Fragment } from 'react'
import classNames from 'classnames'

import { InfoBoxContainer } from 'simple-core-ui'
import { slColors } from 'styles/cssInJs'
import { WARNING_MESSAGE_TYPE } from 'contacts/constants'

import s from './CounselGoWarning.scss'

class CounselGoWarning extends Component {
  getWarningMessage = () => {
    const { warningMessageType } = this.props
    const {
      CONTAINS_CG_USER,
      CONTAINS_CG_ADMIN,
      CONTACT_REMOVE_CG_USER,
      EXISTING_CONTACT_REMOVE_CG_USER,
      CG_ADMIN_OFF
    } = WARNING_MESSAGE_TYPE
    if (warningMessageType === CG_ADMIN_OFF) {
      return (
        <span>
          The <strong>CounselGO User</strong> role grants CounselGO access.
        </span>
      )
    } else if ([CONTAINS_CG_ADMIN, CONTACT_REMOVE_CG_USER].includes(warningMessageType)) {
      return (
        <span>
          Adding the <strong>CounselGO Admin</strong> will auto add <strong>CounselGO User</strong>.
          Removing <strong>CounselGO User</strong> from <strong>CounselGO Admin</strong> will also
          unassign the role as <strong>CounselGO Admin</strong>.
        </span>
      )
    } else if (warningMessageType === CONTAINS_CG_USER) {
      return (
        <span>
          What`s the difference between <strong>CounselGO Admin</strong> and{' '}
          <strong>CounselGO User</strong>?
        </span>
      )
    } else if (warningMessageType === EXISTING_CONTACT_REMOVE_CG_USER) {
      return (
        <span>
          Removing <strong>CounselGO User</strong> also removes {this.props.contactName} as{' '}
          <strong>CounselGO Admin</strong>. A <strong>CounselGO Admin</strong> must also be assigned
          as a <strong>CounselGO User</strong>.
        </span>
      )
    } else {
      return ''
    }
  }

  getActions = () => {
    const { warningMessageType } = this.props
    const { CG_ADMIN_OFF } = WARNING_MESSAGE_TYPE
    return [
      warningMessageType !== CG_ADMIN_OFF ? (
        <li key={0}>
          <strong>CounselGO Admins</strong> have ability to add new <strong>CounselGO users</strong>{' '}
          and <strong>admins</strong>.
        </li>
      ) : (
        ''
      ),
      <li key={1}>
        The <strong>CounselGO User</strong> role grants CounselGO access. The user will be able to{' '}
        <strong>view</strong> and <strong>submit invoices</strong> and{' '}
        <strong>view referred matters</strong>.
      </li>
    ]
  }

  render() {
    if (this.props.warningMessageType === WARNING_MESSAGE_TYPE.MAX_CG_ADMINS_REACHED) {
      return (
        <InfoBoxContainer
          style={{ color: slColors.red }}
          message={
            <Fragment>
              <span>
                You cannot add a new <strong>CounselGO Admin</strong> because the maximum{' '}
                <strong>CounselGO Admin</strong> limit has been reached.
              </span>
            </Fragment>
          }
        />
      )
    }

    const actions = this.getActions()
    return (
      <InfoBoxContainer
        style={{ color: slColors.darkerBlue }}
        icon={<i className={classNames('simple-line-icon-info', s.icon)} />}
        message={<Fragment>{this.getWarningMessage()}</Fragment>}
        details={<ul className={s.details}>{actions.map(action => action)}</ul>}
      />
    )
  }
}

export default CounselGoWarning
