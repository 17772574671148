import classNames from 'classnames'
import s from './NamedGroups.scss'

const NamedGroups = ({ groups }) => (
  <section className={classNames(s.container, { [s.separated]: !!groups.length })}>
    {groups.map(({ name, content }, idx) => (
      <section className={s.group} key={idx}>
        <span className={s.name}>{name}</span>
        <section className={s.content}>{content}</section>
      </section>
    ))}
  </section>
)

export default NamedGroups
