import { Status, APIStatus } from './types'
import { formatName } from 'utils/helpers'

export const toStatuses = (statuses: APIStatus[]): Status[] => {
  return statuses.map((status: APIStatus) => {
    const {
      id,
      phase,
      default_display_name,
      default_color,
      client_settings,
      related_tasks
    } = status

    return {
      id: id,
      name: client_settings?.display_name ?? default_display_name,
      color: client_settings?.color ?? default_color,
      phase: String(phase.id),
      relatedTasks: String(related_tasks),
      lastEdited: client_settings?.edited_date ?? null,
      editedBy: formatName(client_settings?.edited_by) || null,
      isActive: client_settings?.is_active ?? true
    }
  })
}
