import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Select from 'react-select'
import { ModalContainer } from 'simple-core-ui'
import ACT from 'doc_management/settings/actions'
import s from './AddAdmin.scss'

const selectAdmins = createSelector([state => state.admins], admins => {
  return admins
})

const AddAdmin = ({ showAddAdmin, toggleAddAdmin, addAdmin }) => {
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const admins = useSelector(state => selectAdmins(state.documentManagementSettings))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: ACT.ADMINS_FETCH_REQUESTED })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalContainer
      title="Choose an Admin to notify"
      size="sm"
      isVisible={showAddAdmin}
      confirmText="Add"
      cancelText="Cancel"
      confirmCb={() => addAdmin(selectedAdmin)}
      cancelCb={toggleAddAdmin}
      content={
        <section>
          <div className={s.infoLabel}>
            The admin you select will receive an email when there is malware detected.
          </div>
          <Select
            placeholder="Choose Admin"
            noOptionsMessage={() => 'No results...'}
            value={selectedAdmin}
            onChange={value => setSelectedAdmin(value)}
            options={admins}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </section>
      }
    />
  )
}

export default AddAdmin
