import s from './Chart.scss'
import cn from 'classnames'
import { ChartInfo } from './ChartInfo'

const Chart = ({ question, className }) => (
  <div className={cn(s.chart, className)}>
    <div
      className={cn(s.segment, s.below)}
      style={{ width: `${question.below_expectations_percent}%` }}
    />
    <div
      className={cn(s.segment, s.met)}
      style={{ width: `${question.met_expectations_percent}%` }}
    />

    <div
      className={cn(s.segment, s.exceeded)}
      style={{ width: `${question.exceeded_expectations_percent}%` }}
    />

    <ChartInfo
      percent={question.below_expectations_percent}
      responses={question.responses_number}
    />
    <ChartInfo percent={question.met_expectations_percent} responses={question.responses_number} />
    <ChartInfo
      percent={question.exceeded_expectations_percent}
      responses={question.responses_number}
    />

    <div
      className={cn(s.legend, {
        [s.noAnswers]: question.responses_number === 0
      })}
    >
      <div>
        <div className={cn(s.bullet, s.exceeded)} />
        <p className={s.label}>9-10 Exceeded Expectations</p>
      </div>
      <div>
        <div className={cn(s.bullet, s.met)} />
        <p className={s.label}>7-8 Met Expectations</p>
      </div>
      <div>
        <div className={cn(s.bullet, s.below)} />
        <p className={s.label}>1-6 Below Expectations</p>
      </div>
    </div>
  </div>
)

export default Chart
