import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel, Button, DataTableService, DataTableSerializer } from 'simple-core-ui'
import Users from './Users'
import UserModal from './UserModal'
import s from './UserManagement.scss'
import ACT from 'tableau/actions'
import swal from 'sweetalert'
import get from 'lodash/get'

const initialState = {
  params: {
    pageSize: 25,
    ordering: { columnKey: 'customUserName', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

const columns = [
  { columnKey: 'customUserName', content: 'Name', isSortable: true, isFilterable: true },
  { columnKey: 'name', content: 'Tableau Username', isSortable: true, isFilterable: true }
]

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
      return cellContent.toLowerCase()
    case 'customUserName':
      return cellContent.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

const UserManagement = () => {
  const [showUserModal, setShowUserModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [params, setParams] = useState(initialState.params)
  const [clientUser, setClientUser] = useState(null)
  const user = {}

  const users = useSelector(state => state.tableau.users)
  const clientUsers = useSelector(state => state.tableau.clientUsers).filter(
    cUser => !users.find(u => cUser.id === u.customUserId)
  )

  const dispatch = useDispatch()

  const results = useMemo(() => {
    const rowsWithActions = users.map(u => ({
      ...u,
      actions: {
        useTrashcan: true
      }
    }))

    const rowsByCategory = { all: rowsWithActions }

    const userService = new DataTableService(
      DataTableSerializer.getRowsByCategory(rowsByCategory, columns),
      columns,
      getNormalizedCellContent
    )

    return userService.getUpdatedResults(params)
  }, [users, params])

  useEffect(() => {
    dispatch({ type: ACT.USERS_FETCH_REQUESTED, loadingLock: 'on' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleUserModal = (isEdit = false) => {
    setShowUserModal(showUserModal => !showUserModal)
    setIsEdit(isEdit)
  }

  const addUser = () => {
    dispatch({
      type: ACT.ADD_USER_REQUESTED,
      loadingLock: 'on',
      payload: {
        user_id: clientUser.value
      }
    })
    toggleUserModal()
  }

  const updateTable = params => {
    setParams(params)
  }

  const deleteUser = async user => {
    let willDelete = await swal({
      title: 'Deactivate Tableau User',
      text: 'Are you sure you want to deactivate this user?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willDelete) {
      dispatch({
        type: ACT.DELETE_USER_REQUESTED,
        loadingLock: 'on',
        payload: {
          id: user.id
        }
      })
    }
  }

  const showEditModal = user => {
    toggleUserModal(true)
  }

  return (
    <Panel
      title="Tableau User Management"
      rightActions={[
        <Button onClick={() => toggleUserModal()} isPrimary key="users">
          Add User
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.usersContainer}
    >
      <Users
        params={params}
        results={results}
        updateTable={updateTable}
        deleteUser={deleteUser}
        showEditModal={showEditModal}
      />
      <UserModal
        isVisible={showUserModal}
        toggleUserModal={toggleUserModal}
        updateSelectedUser={setClientUser}
        addUser={addUser}
        users={clientUsers}
        selectedUser={clientUser}
      />
    </Panel>
  )
}

export default UserManagement
