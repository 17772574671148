import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequest,
  makeBlobRequest
} from 'utils/api'
import { Panel, Button, DataTableWrapper, SwitchToggle } from 'simple-core-ui'
import s from './RejectionReasonList.scss'
import { ReasonModal } from '../ReasonModal'
import swal from 'sweetalert'
import APP_ACT from 'app/actions'
import { ActionsPopover } from './ActionsPopover'
import { withSweetAlert } from 'hocs'
import ReactTooltip from 'react-tooltip'
import { useImmer } from 'use-immer'
import { getNormalizedCellContent, checkDuplicateReason, sortReasonsList } from '../utils'
import { Params } from '@common_types/common'
import { RejectionReason } from 'invoice_settings/types'
import { openBlob } from 'utils/helpers'

const BASE_URL = '/invoices/rejection/reason/'

interface State {
  reason: RejectionReason
  reasons: Array<RejectionReason>
  reasonsNameList: Array<string>
  params: Params
  totalEntries: number
}

const baseReason = {
  name: '',
  active: false
}

const initialState: State = {
  reason: baseReason as RejectionReason,
  reasons: [],
  reasonsNameList: [],
  params: {
    pageSize: 10,
    ordering: { columnKey: 'name', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  },
  totalEntries: 0
}

const RejectionReasonList = () => {
  const [localState, setLocalState] = useImmer(initialState)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showDuplicateError, setShowDuplicateError] = useState(false)
  const dispatch = useDispatch()
  const { reason, reasons, reasonsNameList, params, totalEntries } = localState

  const notifyChange = (title: string, message: string, level: string) => {
    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: { title, message, level }
    })
  }

  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const { results, total } = await makeGetRequest(`${BASE_URL}list/`, {
          params: { all: true, order: 'name' }
        })
        setLocalState(draft => {
          draft.reasons = results
          draft.totalEntries = total
        })
      } catch (error) {
        notifyChange('Error', 'There was an issue fetching invoice rejection reasons', 'error')
      }
    }

    fetchReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editReasonStatus = async (rowId: number, active: boolean) => {
    const content = <p className={s.warningText}>Do you wish to continue?</p>
    const sweetAlertFactory = withSweetAlert(content)
    sweetAlertFactory(
      {
        title: `Mark as ${active ? 'Active' : 'Inactive'}`,
        text: `This rejection reason will ${
          active ? '' : 'not'
        } show up for reviewers on invoice review when prompted to reject an invoice.`,
        buttons: ['Cancel', 'Yes'],
        icon: 'warning'
      },
      async () => {
        try {
          const response = await makePutRequest(`${BASE_URL}${rowId}/edit/`, { active })
          const idx = reasons.findIndex(ele => ele.id === rowId)
          setLocalState(draft => {
            draft.reasons[idx].active = response.active
          })
          notifyChange(
            `Reason marked ${active ? 'active' : 'inactive'} successfully`,
            '',
            'success'
          )
        } catch (error) {
          notifyChange(
            'Error',
            'There was an issue changing the invoice rejection reason status',
            'error'
          )
        }
      },
      {}
    )
    ReactTooltip.hide()
  }

  const columns = [
    {
      columnKey: 'name',
      content: 'Rejection Reason',
      isSortable: true,
      isFilterable: true
    },
    {
      columnKey: 'related_invoices',
      content: 'Related Invoices',
      isSortable: true
    },
    {
      columnKey: 'active',
      content: 'Active',
      isSortable: true,
      render: (cell: { content: boolean }, row: { id: number }) => {
        const active = cell.content === true
        return (
          <div className={s.toggleWrapper}>
            <SwitchToggle
              onToggle={checked => editReasonStatus(row.id, checked)}
              checked={active}
            />
          </div>
        )
      }
    }
  ]

  const closeModal = () => {
    setShowReasonModal(false)
    setShowDuplicateError(false)
    setIsEdit(false)
    setLocalState(draft => {
      draft.reason = initialState.reason
      draft.reasonsNameList = initialState.reasonsNameList
    })
  }

  const addReason = async () => {
    try {
      const response = await makePostRequest(`${BASE_URL}add/`, { name: reason.name })
      const arr = [...reasons, response]
      setLocalState(draft => {
        draft.reasons = sortReasonsList(arr)
      })
      notifyChange('Reason added successfully', '', 'success')
    } catch (error) {
      notifyChange('Error', 'There was an issue creating the invoice rejection reason', 'error')
    }
    closeModal()
  }

  const editReason = async () => {
    const { id, name } = reason
    try {
      const response = await makePutRequest(`${BASE_URL}${id}/edit/`, { name })
      const arr = [...reasons]
      const idx = arr.findIndex(ele => ele.id === id)
      arr[idx] = response
      setLocalState(draft => {
        draft.reasons = sortReasonsList(arr)
      })
      notifyChange('Reason edited successfully', '', 'success')
    } catch (error) {
      notifyChange('Error', 'There was an issue editing the invoice rejection reason', 'error')
    }
    closeModal()
  }

  const updateReasonTitle = (name: string) => {
    setLocalState(draft => {
      draft.reason.name = name
    })
    const isDuplicate = checkDuplicateReason(reasonsNameList, name)
    setShowDuplicateError(isDuplicate)
  }

  const deleteReason = async (rowId: number) => {
    const willDelete = await swal({
      title: 'Delete Reason',
      text: 'Deleting a reason removes it from rejection reasons list',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willDelete) {
      try {
        await makeDeleteRequest(`${BASE_URL}${rowId}/delete`)
        const idx = reasons.findIndex(ele => ele.id === rowId)
        setLocalState(draft => {
          draft.reasons.splice(idx, 1)
        })
        notifyChange('Reason deleted successfully', '', 'success')
      } catch (error) {
        notifyChange('Error', 'There was an issue deleting the invoice rejection reason', 'error')
      }
    }
  }

  const updateModalState = (isEdit: boolean) => {
    setIsEdit(isEdit)
    setShowReasonModal(true)
  }

  const openModal = async (isEdit = false, rowId?: number) => {
    const namesArr = reasons.map(ele => ele.name.toLowerCase())
    if (isEdit) {
      const selectedReason = reasons.find(ele => ele.id === rowId) || baseReason
      const idx = namesArr.indexOf(selectedReason.name.toLowerCase())
      namesArr.splice(idx, 1)
      setLocalState(draft => {
        draft.reason = selectedReason as RejectionReason
        draft.reasonsNameList = namesArr
      })
    } else {
      setLocalState(draft => {
        draft.reasonsNameList = namesArr
      })
    }
    updateModalState(isEdit)
  }

  const handleAddReason = () => {
    const namesArr = reasons.map(ele => ele.name.toLowerCase())
    setLocalState(draft => {
      draft.reasonsNameList = namesArr
    })
    updateModalState(isEdit)
  }

  const renderCustomAction = (row: RejectionReason) =>
    !row.related_invoices && (
      <ActionsPopover rowId={row.id} deleteReason={deleteReason} editReason={openModal} />
    )

  const updateTable = (updatedParams: Params) => {
    setLocalState(draft => {
      draft.params = updatedParams
    })
    ReactTooltip.hide()
  }

  const handleDownload = async () => {
    try {
      const data = await makeBlobRequest({
        url: `${BASE_URL}list/`,
        method: 'GET',
        params: { export: true }
      })
      if (data.size) {
        openBlob(data, 'Rejection Reasons Download.xlsx')
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  return (
    <Panel
      title="Manage Invoice Rejection Reasons"
      rightActions={[
        <Button onClick={handleDownload} isSecondary key="xls">
          Download
        </Button>,
        <Button onClick={handleAddReason} isPrimary key="add">
          Add Reason
        </Button>
      ]}
      styles={{ boxShadow: 'none' }}
      className={s.listContainer}
    >
      <DataTableWrapper
        params={params}
        categories={[]}
        totalEntries={totalEntries}
        rows={reasons}
        columns={columns}
        updateTable={updateTable}
        panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
        hasActions
        customAction={renderCustomAction}
        getNormalizedCellContent={getNormalizedCellContent}
        multiSort={{ columnKey: 'name', isDesc: false }}
        customActionsMemoizationDeps={[reasons]}
      />
      {showReasonModal && (
        <ReasonModal
          mode="rejection"
          inputValue={reason.name}
          title={isEdit ? 'Edit Reason' : 'Add Reason'}
          subtitle={isEdit ? 'Reason Name' : 'New Reason'}
          updateInputValue={updateReasonTitle}
          showDuplicateError={showDuplicateError}
          onChangeCb={isEdit ? editReason : addReason}
          onCancelCb={closeModal}
          inputPlaceholder="reason"
          duplicateErrMsg="The rejection reason already exists. Please specify a different reason."
        />
      )}
    </Panel>
  )
}

export default RejectionReasonList
