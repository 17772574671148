import { createReducer } from 'redux-create-reducer'

import ACT from './actions'

export const initialState = {
  clientKeys: [],
  showForUpdate: null,
  showForCreate: false,
  clientKeyLimit: 0,
  clientKeysIsLoading: true
}

const clientKeysReducer = createReducer(initialState, {
  [ACT.CLIENT_KEYS_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      clientKeys: action.payload,
      clientKeysIsLoading: false
    }
  },
  [ACT.CLIENT_KEY_CREATE_SUCCESS](state, action) {
    return {
      ...state,
      clientKeys: [...state.clientKeys, action.payload],
      clientKeysIsLoading: false
    }
  },
  [ACT.CLIENT_KEY_UPDATE_SUCCESS](state, action) {
    const updatedClientKey = action.payload

    return {
      ...state,
      clientKeys: state.clientKeys.map(client_key =>
        client_key.id === updatedClientKey.id ? updatedClientKey : client_key
      ),
      clientKeysIsLoading: false
    }
  },
  [ACT.CLIENT_KEY_DELETE_SUCCESS](state, action) {
    return {
      ...state,
      clientKeys: state.clientKeys.filter(({ id }) => id !== action.payload)
    }
  },
  [ACT.CLIENT_KEY_SHOW_FOR_CREATE](state, action) {
    return {
      ...state,
      showForCreate: true
    }
  },
  [ACT.CLIENT_KEY_SHOW_FOR_UPDATE_SUCCESS](state, action) {
    const clientKeyExists = state.clientKeys.find(({ id }) => action.payload.id)

    return {
      ...state,
      clientKeys: clientKeyExists ? state.clientKeys : [...state.clientKeys, action.payload],
      showForUpdate: action.payload
    }
  },
  [ACT.CLIENT_KEY_MODAL_CLOSE](state, action) {
    return {
      ...state,
      showForCreate: false,
      showForUpdate: null
    }
  },
  [ACT.CLIENT_KEYS_IS_LOADING](state, action) {
    return {
      ...state,
      clientKeysIsLoading: true
    }
  },
  [ACT.CLIENT_KEYS_IS_NOT_LOADING](state, action) {
    return {
      ...state,
      clientKeysIsLoading: false
    }
  }
})

export default clientKeysReducer
