import SelectFilter from './SelectFilter'
import s from '../SearchFilter.scss'
import moment from 'moment'
import MonthPickerInput from 'react-month-picker-input'

const monthPickerOptions = {
  dateFormat: { default: 'MM/YYYY' },
  monthFormat: 'short',
  monthNames: {
    default: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  }
}

const MonthFilter = ({ onSelect, reset, initialValue, hasConfirmation, title, customLabel }) => {
  return (
    <SelectFilter
      title={title || 'Month'}
      onSelect={onSelect}
      reset={reset}
      initialValue={initialValue}
      hasConfirmation={hasConfirmation}
      customLabel={customLabel}
      render={(date, setDate) => {
        return (
          <MonthPickerInput
            year={Number(date?.label?.split('/')[1] || 2020)}
            // 'null' because MonthPickerInput doesn't update if it's not a string
            month={date ? Number(date?.label?.split('/')[0]) - 1 : 'null'}
            i18n={monthPickerOptions}
            inputProps={{ className: s.selectFilterMonthPicker }}
            closeOnSelect
            onChange={(maskedValue, selectedYear, selectedMonth) => {
              const date = moment()
                .month(selectedMonth)
                .year(selectedYear)
                .format('MM-YYYY')

              setDate({ label: maskedValue, value: date })
            }}
          />
        )
      }}
    />
  )
}

export default MonthFilter
