import get from 'lodash/get'
import partial from 'lodash/partial'
import { UNARY_OPERATORS } from 'data/operators'
import { LHS_TYPES, FUNC_ARG_TYPES } from 'simple_review/utils/constants'
import { AvailableField, Field } from 'simple_review/@types/api'
import { Lhs, LhsArg, Rhs } from 'simple_review/@types/list'
import { OperatorByType } from 'simple_review/@types/common'

interface Acc {
  [key: string]: { display_name: string }
}

interface DisplayName {
  display_name: string
}

export const isSet = (val: { display_name: string }): boolean => Boolean(Object.keys(val).length)

export const getPath = ({
  model,
  listAttr,
  field
}: {
  model: DisplayName
  listAttr: DisplayName
  field: DisplayName
}): string => {
  return `${isSet(model) ? model.display_name : ''}${
    isSet(listAttr) ? ` - ${listAttr.display_name}` : ''
  }${isSet(field) ? ` - ${field.display_name}` : ''}`.trim()
}

export const getArgFieldMap = (
  lhsArgs: Array<LhsArg>,
  availableFields: Array<AvailableField>
): { [key: string]: { display_name: string } } => {
  const fields = availableFields.reduce((acc, c) => {
    return c.isFunction ? acc : [...acc, ...(c.fields ? c.fields : [])]
  }, [] as Field[])

  const argFieldMap = lhsArgs.reduce((acc: Acc, c) => {
    if (c.arg_type !== FUNC_ARG_TYPES.FIELD) {
      return acc
    }
    const field = fields.find(
      ({ name }: { name: string }) => c.arg_type === 'field' && name === c.model_field
    )

    if (field) acc[c.arg_name] = field

    return acc
  }, {})

  return argFieldMap
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getByKey = (key: string, list: Array<any>, value: string) =>
  list?.find(item => item[key] === value) || {}

export const getByName = partial(getByKey, 'name')

export const getByAttrId = partial(getByKey, 'attr_id')

export const getModelAttrField = (
  lhs: { operand_type: string; model_name: string; attr_id: string; field_name: string },
  availableFields: Array<AvailableField>
) => {
  if (!Object.keys(lhs).length || lhs.operand_type === LHS_TYPES.FUNC_CALL)
    return { model: '', listAttr: '', field: '' }

  const model = getByName(availableFields, lhs.model_name)
  const listAttr = getByAttrId(model.list_custom_attrs, lhs.attr_id)
  const field = getByName(listAttr.fields || model.fields, lhs.field_name)

  return { model, listAttr, field }
}

export const isUnaryOperator = (operator: string): boolean => UNARY_OPERATORS.includes(operator)

export const coordToString = (arr: Array<number>): string => {
  let str = `op_`
  arr.forEach(coord => {
    str += coord + '_'
  })

  return str.slice(0, -1)
}

export const getOperator = (
  operator: string | undefined,
  lhs: Lhs,
  fields: Array<AvailableField>,
  operators: OperatorByType
): { value?: string; label?: string } => {
  const field: AvailableField =
    lhs.operand_type === LHS_TYPES.FUNC_CALL
      ? fields.find(f => f.isFunction && f.name === lhs.func_name)
      : getModelAttrField(lhs, fields).field

  if (!field.type) return { value: '', label: '' }

  const { label } = operators[field.type]?.find(op => op.symbol === operator) || {}

  return { label, value: operator }
}

export const translateOperatorProp = (
  operator: string | undefined,
  lhs: Lhs,
  fields: Array<AvailableField>,
  operators: OperatorByType
): string => {
  const field: AvailableField =
    lhs.operand_type === LHS_TYPES.FUNC_CALL
      ? fields.find(f => f.isFunction && f.name === lhs.func_name)
      : getModelAttrField(lhs, fields).field

  if (!field.type) return ''

  const { label } = operators[field.type]?.find(op => op.symbol === operator) || {}

  return label ?? ''
}

export const getConstOperandLabel = (rhs: Rhs | undefined) => {
  const constant = get(rhs, 'constant')
  return Array.isArray(constant)
    ? constant.map(({ label }) => label).join(', ')
    : get(constant, 'label', '')
}
