import { Markdown, Ellipsis, Avatar } from 'simple-core-ui'
import cn from 'classnames'
import { MatterUpdate, ClientUser, CGContact } from '../types'
import s from './Comment.scss'
import { sortAlphabeticallyByProperty } from 'utils/helpers'
import { useMemo } from 'react'
import { formatInTimeZone } from 'date-fns-tz'

interface Props {
  comment: MatterUpdate
  loggedInUser: User
  readOnly: boolean
  customAction: (comment: MatterUpdate) => void
}

const Comment = ({ comment, loggedInUser, readOnly, customAction }: Props) => {
  const { user, cg_contact, category, shared_with_vendors, created_date, is_edited } = comment
  const userInfo = (user || cg_contact) as ClientUser & CGContact
  const { first_name, last_name, is_active, vendor = null } = userInfo || {}
  const inactiveComment = !is_active || (vendor && !vendor.is_active)

  const deactivatedLabel = inactiveComment ? (
    <span className={s.deactivatedLabel}>(deactivated)</span>
  ) : (
    ''
  )
  const vendorNameLabel = vendor ? `- ${vendor.vendor_name}` : ''
  const editedLabel = is_edited ? <span className={s.editedLabel}>(Edited)</span> : ''
  const createdDateLabel = formatInTimeZone(
    created_date,
    loggedInUser.timezone === 'None' ? loggedInUser.clientTimezone : loggedInUser.timezone,
    "MMMM d, yyyy 'at' hh:mm a"
  )

  const sharedWithVendors = useMemo(
    () => sortAlphabeticallyByProperty(shared_with_vendors, 'vendor_name'),
    [shared_with_vendors]
  )

  return userInfo ? (
    <div className={s.commentContainer}>
      <div className={cn(s.initials, { [s.cgContactLabel]: Boolean(vendor) })}>
        <Avatar
          initials={`${first_name} ${last_name}`}
          className={cn(s.avatar, {
            [s.cgContact]: Boolean(vendor),
            [s.deactivatedUser]: inactiveComment
          })}
        />
        <div className={s.nameContainer}>
          <div className={s.labelContainer}>
            <p
              className={cn({
                [s.deactivatedUserName]: inactiveComment
              })}
            >
              <span className={s.name}>
                {first_name} {last_name} {vendorNameLabel}
              </span>{' '}
              {deactivatedLabel}
            </p>
            <p className={s.dateField}>
              {createdDateLabel} {editedLabel}
            </p>
          </div>
          <div className={s.labelContainer}>
            {category && (
              <p className={cn(s.label, { [s.external]: category !== 'Internal' })}>{category}</p>
            )}
            {sharedWithVendors.length > 0 && (
              <p className={s.sharedVendorsLabel}>
                <span style={{ minWidth: 'fit-content' }}>Visible to: </span>
                <Ellipsis
                  lines={1}
                  className={s.vendorsEllipsis}
                  tooltipClassName={s.tooltip}
                  truncateText={<span style={{ fontWeight: 'bold' }}>...show more</span>}
                >
                  {sharedWithVendors.map((vendor, idx) => (
                    <span
                      key={vendor.id}
                      style={{ color: vendor.is_active ? '#070707' : '#07070780' }}
                    >
                      {vendor.vendor_name} {vendor.is_active ? '' : '(deactivated)'}{' '}
                      {idx < sharedWithVendors.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </Ellipsis>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={s.comment}>
        <>
          {comment.is_rich_text ? (
            <Markdown value={comment.status_update} className={s.commentRichText} />
          ) : (
            <span className={s.commentText}>{comment.status_update}</span>
          )}
          {Number(loggedInUser.id) === comment.user?.id && !readOnly && customAction(comment)}
        </>
      </div>
    </div>
  ) : null
}

export default Comment
