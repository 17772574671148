import classNames from 'classnames'
import { IoIosClose, IoIosArrowBack } from 'react-icons/io'
import { FaList } from 'react-icons/fa'

import ACT from 'reviews/review_ladder/actions'
import ScopeContext from 'context/ScopeContext'

import ReviewStatus from './ReviewStatus'
import ReviewEntryMembership from './ReviewEntryMembership'
import ReviewEntryActions from './ReviewEntryActions'

import { REVIEW_STATUS, ENTRY_ACTION_LABEL } from '../utils/constants'

import s from '../styles/ReviewLadder.scss'

const REVIEWED_STATUSES = [REVIEW_STATUS.APPROVED, REVIEW_STATUS.REJECTED]

const ReviewEntry = ({
  name,
  date,
  status,
  nested,
  isCurrent,
  onSendBackTo,
  onShowReasons,
  onRemove
}) => (
  <section className={classNames(s.reviewEntry, { [s.nested]: nested, [s.current]: isCurrent })}>
    <ReviewStatus status={status} />
    <ReviewEntryMembership name={name} date={date} />
    <ScopeContext.Consumer>
      {({ scope }) => (
        <ReviewEntryActions
          actions={[
            {
              tip: ENTRY_ACTION_LABEL(scope, ACT.SEND_BACK_TO_REQUESTED),
              icon: <SendBackIcon />,
              ...(REVIEWED_STATUSES.includes(status) ? { onClick: onSendBackTo } : {})
            },
            {
              tip: 'Why?',
              icon: <ReasonsIcon />,
              onClick: onShowReasons
            },
            {
              tip: ENTRY_ACTION_LABEL(scope, ACT.REMOVE_REVIEWER_REQUESTED),
              testid: `remove_reviewer_${name}`,
              icon: <DeleteIcon />,
              ...(status === REVIEW_STATUS.APPROVED ? {} : { onClick: onRemove })
            }
          ]}
        />
      )}
    </ScopeContext.Consumer>
  </section>
)

export const SendBackIcon = () => <IoIosArrowBack />
export const ReasonsIcon = () => <FaList />
export const DeleteIcon = () => <IoIosClose />

export default ReviewEntry
