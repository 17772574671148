import * as ACT from './actions'

export const reducer = (state, action) => {
  const { config, templates, selectedTemplate } = action.payload

  switch (action.type) {
    case ACT.FETCH_CONFIG:
      return { ...state, config }
    case ACT.FETCH_TEMPLATES:
      return { ...state, templates }
    default:
      throw new Error('Invalid Action Type')
  }
}
