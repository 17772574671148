import get from 'lodash/get'
import partial from 'lodash/partial'

import { If } from 'simple-core-ui'
import FuncParamDisplay from './FuncParamDisplay.js'
import FuncParamField from './FuncParamField.js'
import FuncFormFooter from './FuncFormFooter.js'
import { nameToLabel } from 'rules/utils'
import { LHS_TYPES, FUNC_ARG_TYPES } from 'rules/constants'

import s from '../styles/FuncForm.scss'

const Empty = () => <p className={s.empty}>No parameters have been set.</p>

const FuncForm = ({
  funcDef,
  funcCall,
  updateParams,
  activeParam,
  setActiveParam,
  onSet,
  funcFieldArgsByType,
  argFieldMap
}) => {
  const funcParams = get(funcCall, 'args', [])

  const updateActiveParamValue = param => {
    setActiveParam({
      arg_name: activeParam.arg_name,
      ...param
    })
  }

  const saveActiveParam = () => {
    const paramIdx = funcParams.findIndex(({ arg_name }) => activeParam.arg_name === arg_name)

    if (paramIdx !== -1) {
      updateParams(funcParams.map((param, idx) => (idx === paramIdx ? activeParam : param)))
    } else {
      updateParams([...funcParams, activeParam])
    }

    setActiveParam(null)
  }

  const showActiveParam = param => {
    setActiveParam(param)
  }

  const removeParam = param => () => {
    updateParams(funcParams.filter(({ arg_name }) => param.arg_name !== arg_name))
  }

  const saveFunc = () =>
    onSet({
      func_name: funcDef.name,
      args: funcParams.map(({ label, value, display_name, url, ...arg }) => arg),
      operand_type: LHS_TYPES.FUNC_CALL
    })

  const getParameterLookup = () =>
    funcDef.args.find(({ arg_name }) => activeParam.arg_name === arg_name)

  const options = funcDef.args
    .filter(({ arg_name }) => !funcParams.some(saved => saved.arg_name === arg_name))
    .map(({ arg_name }) => ({
      label: nameToLabel(arg_name),
      value: { arg_name }
    }))

  const annotatedFuncParams = funcParams.map(param => {
    const label =
      param.arg_type === FUNC_ARG_TYPES.FIELD
        ? `${param.model_name} · ${argFieldMap[param.arg_name].display_name}`
        : param.constant.label

    const value = param.arg_type === FUNC_ARG_TYPES.CONST ? param.constant.value : label

    return { ...param, label, value }
  })

  const isPrimaryDisable = () =>
    activeParam && (typeof activeParam.value !== 'boolean' ? !activeParam.value : false)

  return (
    <section className={s.container}>
      {activeParam ? (
        <FuncParamField
          parameter={activeParam}
          parameterLookup={getParameterLookup()}
          funcFieldArgsByType={funcFieldArgsByType}
          onChange={updateActiveParamValue}
        />
      ) : (
        <If condition={annotatedFuncParams.length} fallback={<Empty />}>
          {annotatedFuncParams.map((param, idx) => (
            <FuncParamDisplay
              parameter={param}
              onRemove={removeParam(param)}
              onEdit={partial(showActiveParam, param)}
              key={idx}
            />
          ))}
        </If>
      )}
      <FuncFormFooter
        options={options}
        onAddParam={showActiveParam}
        onPrimary={activeParam ? saveActiveParam : saveFunc}
        primaryLabel={activeParam ? 'Set Parameter' : 'Set Function'}
        isPrimaryDisabled={isPrimaryDisable()}
        showParamDropdown={!activeParam}
      />
    </section>
  )
}

export default FuncForm
