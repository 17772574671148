import get from 'lodash/get'

import { REVIEWABLE_STATUS, SCOPE } from '../utils/constants'

export const STATUS_COLOR = {
  NEUTRAL: '#36a9e1',
  APPROVED: 'mediumseagreen',
  REJECTED: '#D0021B',
  PENDING: 'orange'
}

const getInvoiceStatusColor = status => {
  const STATUS = REVIEWABLE_STATUS[SCOPE.INVOICE]

  const approvedStatuses = [
    STATUS.APPROVED,
    STATUS.SENT_TO_AP,
    STATUS.POSTED_TO_AP,
    STATUS.PAID,
    STATUS.PARTIALLY_PAID,
    STATUS.OVER_PAID
  ]

  const rejectedStatuses = [STATUS.REJECTED]

  const pendingStatuses = [STATUS.PROCESSING, STATUS.ON_HOLD]

  const neutralStatuses = [STATUS.RECEIVED]

  if (approvedStatuses.includes(status)) return STATUS_COLOR.APPROVED
  else if (rejectedStatuses.includes(status)) return STATUS_COLOR.REJECTED
  else if (pendingStatuses.includes(status)) return STATUS_COLOR.PENDING
  else if (neutralStatuses.includes(status)) return STATUS_COLOR.NEUTRAL
  else return STATUS_COLOR.NEUTRAL
}

export const getReviewableStatusColor = ({ scope, reviewableStatus }) => {
  const normalizedStatus = reviewableStatus.toLowerCase()

  return get(
    {
      [SCOPE.INVOICE]: getInvoiceStatusColor(normalizedStatus)
    },
    scope,
    STATUS_COLOR.NEUTRAL
  )
}
