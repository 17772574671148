import { Title, VerticalTabs } from 'simple-core-ui'
import s from './ExternalCalendarSyncSettings.scss'
import { Preferences } from './Preferences'
import { useMatch } from 'react-router-dom'
import { ExternalTaskCalendars } from 'common/ExternalTaskCalendars'
import { IoIosArrowBack } from 'react-icons/io'

const ExternalCalendarSyncSettings = () => {
  const match = useMatch('/v2/external_calendar_sync/settings/:tabKey/*')
  const activeTab = match ? match.params.tabKey : 'preferences'

  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '8px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <Title text="External Calendar Sync" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs
          withRoutes
          activeTabKey={activeTab as string}
          tabs={[
            {
              key: 'calendars',
              label: 'Calendars',
              path: 'calendars',
              element: (
                <ExternalTaskCalendars
                  panelStyles={{
                    boxShadow: 'none'
                  }}
                  panelTitle="Active Calendar Syncs"
                  subtitle={
                    <>
                      The table below shows SimpleLegal tasks and events synchronized to Apple
                      Calendar, Google Calendar, Microsoft Outlook, or other calendar applications.
                      Tasks are presented as all-day events and contain links back to SimpleLegal.
                      You may stop syncing a calendar link from this page by clicking{' '}
                      <b>Revoke access</b>.
                    </>
                  }
                />
              )
            },
            {
              index: true,
              key: 'preferences',
              label: 'Preferences',
              path: 'preferences',
              element: <Preferences />
            }
          ]}
        />
      </section>
    </section>
  )
}

export default ExternalCalendarSyncSettings
