import * as React from 'react'
import { connect } from 'react-redux'

import ACT from '../actions'

import { ButtonDropdown, If } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'

import withErrorBoundary from 'simple-core-ui/hocs/withErrorBoundary'
import { SCOPE } from '../utils/constants'

const getScopeData = (location, params) => {
  const scope = location.pathname.includes('invoices') ? SCOPE.INVOICE : ''
  const scopeId = scope === SCOPE.INVOICE ? window.serverContext.get('object_id') : params.invoiceId

  return { scope, scopeId }
}

@withErrorBoundary
@connect(({ reviews: { reviewLadder } }) => {
  const { review, isHydrating } = reviewLadder

  const adminActionOptions = review.adminActions.map(action => ({ label: action, value: action }))

  return {
    adminActionOptions,
    isHydrating
  }
})
@withRouter
class AdminActionsContainer extends React.Component {
  triggerAdminAction = option => {
    const { location, params } = this.props.router
    const { scope, scopeId } = getScopeData(location, params)

    this.props.dispatch({
      type: ACT.ADMIN_ACTION_REQUESTED,
      payload: {
        scope,
        scopeId,
        adminAction: option.value
      }
    })
  }

  render() {
    const { adminActionOptions, isHydrating } = this.props
    return (
      <If condition={adminActionOptions.length}>
        <ButtonDropdown
          isSecondary
          displayText="Admin Actions"
          options={adminActionOptions}
          onSelect={this.triggerAdminAction}
          isLoading={isHydrating}
        />
      </If>
    )
  }
}

export default AdminActionsContainer
