import PropTypes from 'prop-types'

export default {
  isOpen: PropTypes.bool,
  triggerCb: PropTypes.func,
  heading: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node, PropTypes.func]),
  option: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isGroup: PropTypes.bool
  }),
  filterValue: PropTypes.string,
  filterCb: PropTypes.func,
  selectionCb: PropTypes.func,
  navigateBackCb: PropTypes.func,
  hideCb: PropTypes.func,
  isLoading: PropTypes.bool,
  emptyText: PropTypes.string,
  custom: PropTypes.func
}
