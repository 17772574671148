import * as React from 'react'
import get from 'lodash/get'

import { If, HoverAction, DESIGN } from 'simple-core-ui'
import ScopeContext from 'context/ScopeContext'
import { HIDE_REJECTION_REASON } from '../utils/constants'

import s from '../styles/ReviewLadder.scss'
import { IoIosArrowUp, IoMdHelp } from 'react-icons/io'

const {
  spacing: { sp200 }
} = DESIGN

const ReviewMessage = ({ text, response, isExpanded, toggleResponse }) => (
  <ScopeContext.Consumer>
    {({ scope }) => {
      const canShowResponse = !HIDE_REJECTION_REASON.includes(scope)
      const responseText = get(response, 'responseText')

      return (
        <React.Fragment>
          <If condition={text}>
            <section className={s.reviewMessage}>
              <p className={s.activeTurn}>{text}</p>
              <If condition={canShowResponse && responseText}>
                <HoverAction
                  icon={isExpanded ? <IoIosArrowUp /> : <IoMdHelp />}
                  tip={isExpanded ? 'Hide Message' : 'See Why'}
                  onClick={toggleResponse}
                  size="xsmall"
                  style={{ marginLeft: sp200 }}
                />
              </If>
            </section>
          </If>
          <If condition={canShowResponse && isExpanded && responseText}>
            {() => (
              <section className={s.response}>
                <p className={s.from}>{`Message from ${get(response, 'reviewerName')}:`}</p>
                <p className={s.message}>{responseText}</p>
              </section>
            )}
          </If>
        </React.Fragment>
      )
    }}
  </ScopeContext.Consumer>
)

export default ReviewMessage
