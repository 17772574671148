import { Component } from 'react'
import * as ACT from 'matters/templates/actions'
import { InnerSidebarFilter, InnerSidebar } from 'components'
import { AttributeGroup } from './AttributeGroup'
import { connect } from 'react-redux'
import find from 'lodash/find'
import withFetchFlow from 'simple-core-ui/fetchFlow/withFetchFlow'

const isGroupSelected = (template, name) => {
  return (
    find(template.right, section => name === section.sectionName) ||
    find(template.left, section => name === section.sectionName)
  )
}

const attributeSelected = (template, attribute) => {
  const predicate = section =>
    section.attributes.filter(attr => attr.id === attribute.id && attr.type !== 'relationship')
      .length

  const selected = find(template.left, predicate) || find(template.right, predicate)

  return selected
}

const relationshipSelected = (template, attribute) => {
  const predicate = section =>
    section.attributes.filter(attr => +attr.id === +attribute.id && attr.type === 'relationship')
      .length

  const selected = find(template.left, predicate) || find(template.right, predicate)

  return selected
}

@connect(({ matterAttributes, matterTemplates }) => {
  const { groups, filterTerm, dynamicDropdowns } = matterAttributes

  const template = matterTemplates

  const attrGroups = groups.concat(dynamicDropdowns).map(group => ({
    ...group,
    selected: isGroupSelected(template, group.sectionName),
    attributes: group.attributes
      ? group.attributes
          .filter(attr => attr.label.toLowerCase().includes(filterTerm.toLowerCase()))
          .map(attr => {
            return {
              ...attr,
              selected:
                attr.type === 'relationship'
                  ? relationshipSelected(template, attr)
                  : attributeSelected(template, attr)
            }
          })
      : []
  }))

  return {
    attrGroups,
    filterTerm
  }
})
@withFetchFlow({
  render: () => null,
  flag: 'Attributes',
  getFetchAction: props => ({
    type: ACT.MATTER_TEMPLATES_ATTRIBUTES_FETCH_REQUESTED
  })
})
@withFetchFlow({
  render: () => null,
  flag: 'Relationships',
  getFetchAction: props => ({
    type: ACT.MATTER_TEMPLATES_DYNAMIC_ATTRIBUTES_FETCH_REQUESTED
  })
})
class SideBarContainer extends Component {
  state = {
    filterTerm: this.props.filterTerm
  }
  componentDidMount() {
    this.state.filterTerm && this.onFilterChange('')
  }
  onFilterChange = term => {
    this.props.dispatch({
      type: ACT.MATTER_TEMPLATES_FILTER_TERM,
      payload: {
        term
      }
    })
  }

  render() {
    const { attrGroups, filterTerm, opened, toggleDrawer } = this.props

    return (
      <InnerSidebar
        title={'Available Attributes'}
        opened={opened}
        closable
        toggleDrawer={toggleDrawer}
      >
        <InnerSidebarFilter filterTerm={filterTerm} onChange={this.onFilterChange} />
        {attrGroups.map((group, i) =>
          group.attributes.length ? (
            <AttributeGroup
              key={`${group.name}_${i}`}
              groupSelected={group.selected}
              attributes={group.attributes}
              name={group.sectionName}
              sectionIndex={i}
            />
          ) : (
            ''
          )
        )}
      </InnerSidebar>
    )
  }
}

export default SideBarContainer
