import {
  Action,
  ActionParam,
  AvailableAction,
  AvailableActionParam
} from 'simple_review/@types/api'
import { Constant } from 'simple_review/@types/common'

export const getUpdatedParams = (
  prevParams: Array<ActionParam>,
  parameter: AvailableActionParam,
  value: string | number | Constant | Array<Constant>
) => {
  const isParamPresent = prevParams.some(param => param.param_name === parameter.name)

  if (parameter.name === 'rejection_reason' && Array.isArray(value)) {
    const newPrevParams = prevParams.filter(param => param.param_name !== 'rejection_reason')

    const rejReasonParams = value.map(r => {
      return {
        param_name: parameter.name,
        type: parameter.type,
        sub_type: parameter.sub_type,
        value: {
          value: r.value,
          label: r.label
        }
      }
    })

    return value.length ? [...newPrevParams, ...rejReasonParams] : [...newPrevParams]
  }

  const updatedParam = {
    param_name: parameter.name,
    type: parameter.type,
    sub_type: parameter.sub_type,
    value: parameter.choices.length ? value : { value: value, label: value }
  }
  return isParamPresent
    ? prevParams.map(param => (param.param_name === parameter.name ? updatedParam : param))
    : [...prevParams, updatedParam]
}

export const getSelectedAction = (
  action: Action,
  availableActions: Array<AvailableAction>
): AvailableAction | null => {
  const selectedAction = availableActions
    .filter(a => action.name === a.name)
    .map(act => ({ ...act, label: act.display_name, value: act.name }))
  return selectedAction.length ? selectedAction[0] : null
}
