import { FaCheck, FaTimes } from 'react-icons/fa'
import { Textarea } from 'components'
import s from './EditableSection.scss'

const EditableTextarea = ({ text, handleChange, saveChanges, discardChanges }) => (
  <section>
    <Textarea className={s.contentTextarea} rows="4" value={text} onChange={handleChange} />
    <div className={s.buttonsContainer}>
      <FaCheck
        className={s.saveButton}
        onClick={e => {
          e.stopPropagation()
          saveChanges(text)
        }}
      />
      <FaTimes
        className={s.button}
        onClick={e => {
          e.stopPropagation()
          discardChanges()
        }}
      />
    </div>
  </section>
)

export default EditableTextarea
