import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'

interface Props {
  userId: string
  deleteUser: () => void
}

const ActionsPopover = ({ userId, deleteUser }: Props) => {
  const actions = [
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteUser()
      }
    }
  ]

  return (
    <Popover
      testId={`item-${userId}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
