import get from 'lodash/get'

export const PENDING_INVOICES_COLUMN_KEYS = {
  SUBJECT: 'subject',
  NUMBER: 'number',
  TOTAL: 'total',
  DATE: 'date',
  MATTERS: 'matters',
  VENDOR: 'vendor'
}

export const getFileNameExtension = name => {
  const [, fileName, extension] = /^(.*?)(\.[^.]*)?$/.exec(name)
  return {
    fileName,
    extension
  }
}

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  switch (columnKey) {
    case PENDING_INVOICES_COLUMN_KEYS.SUBJECT:
      return cellContent.toLowerCase()
    case PENDING_INVOICES_COLUMN_KEYS.NUMBER:
      return cellContent.toLowerCase()
    case PENDING_INVOICES_COLUMN_KEYS.TOTAL:
      return Number(cellContent.slice(1))
    case PENDING_INVOICES_COLUMN_KEYS.DATE:
      return cellContent
    case PENDING_INVOICES_COLUMN_KEYS.MATTERS:
      return cellContent.map(({ name }) => name.toLowerCase()).join(' ')
    case PENDING_INVOICES_COLUMN_KEYS.VENDOR:
      return cellContent.name.toLowerCase()
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

export const filterPendingInvoices = (rows, search) => {
  const normalizedSearch = search.toLowerCase()
  const { DATE, SUBJECT, MATTERS, VENDOR, NUMBER } = PENDING_INVOICES_COLUMN_KEYS
  const filterableColumnKeys = [DATE, SUBJECT, MATTERS, VENDOR, NUMBER]

  return rows.filter(row =>
    filterableColumnKeys
      .map(columnKey => String(getNormalizedCellContent(columnKey, row)))
      .some(content => content.includes(normalizedSearch))
  )
}
