import { Input, Alert, If } from 'simple-core-ui'
import { CategorySelect, LabelsSelect } from 'common/Selects'
import cn from 'classnames'
import { FaExclamationTriangle } from 'react-icons/fa'
import { MAX_FILE_SIZE_ALLOWED, MAX_FILE_SIZE_ALLOWED_IN_GB } from '../constants'
import { bitesToMb } from '../helpers'
import s from './VersionUpload.scss'

const VersionUpload = ({ versionFile, existingFile }) => {
  return (
    <If
      condition={versionFile.name === existingFile.name}
      fallback={
        <Alert
          message={`The file selected has a different name or is a different file type from ${existingFile.name}. You can only version files with the same name and file type.`}
          status="error"
        />
      }
    >
      <div className={s.filesWrapper}>
        <div className={s.flex}>
          <label className={s.label}>File Name</label>
          <label className={s.label}>Category</label>
          <label className={s.label}>Label(s)</label>
        </div>
        <div className={s.file}>
          <div className={s.flex}>
            <Input
              type="text"
              text={versionFile.name}
              placeholder="File Name"
              isDisabled
              cssClass={cn(s.fileName, {
                error: bitesToMb(versionFile.size) > MAX_FILE_SIZE_ALLOWED
              })}
            />
            <CategorySelect
              value={existingFile.category}
              isDisabled
              components={{
                MultiValueRemove: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
            <LabelsSelect
              value={existingFile.labels}
              isDisabled
              components={{
                MultiValueRemove: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
            />
          </div>
          {bitesToMb(versionFile.size) > MAX_FILE_SIZE_ALLOWED && (
            <p className={s.error}>
              <span title={`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}>
                <FaExclamationTriangle className={s.warningIcon} />
              </span>
              {`File size exceeds the ${MAX_FILE_SIZE_ALLOWED_IN_GB}GB limit`}
            </p>
          )}
        </div>
      </div>
    </If>
  )
}

export default VersionUpload
