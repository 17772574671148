import { MultiSelectFilterSerializer } from 'common/Filters/serializers'
import qs from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

export const serializeParams = params => {
  const getOrdering = ordering => {
    let key = ordering.columnKey
    if (key === 'cg_users') {
      key = 'counselgo_enabled'
    }
    return ordering.isDesc ? `-${key}` : key
  }

  const { filters, pageSize, ordering, page, search } = params

  return {
    size: pageSize,
    ordering: ordering ? getOrdering(ordering) : null,
    page: page,
    search_keyword: search,
    filters: {
      ...(filters.vendor_id
        ? { vendor_ids: MultiSelectFilterSerializer.toServer(filters.vendor_id) }
        : {}),
      ...(filters.vendor_group_ids
        ? { vendor_group_ids: MultiSelectFilterSerializer.toServer(filters.vendor_group_ids) }
        : {}),
      ...(filters.billed_entities
        ? { billed_entities: MultiSelectFilterSerializer.toServer(filters.billed_entities) }
        : {}),
      ...(filters.vendor_leads
        ? { vendor_leads: MultiSelectFilterSerializer.toServer(filters.vendor_leads) }
        : {}),
      ...(filters.hasOwnProperty('status') && !!filters.status
        ? { status: filters.status.value }
        : {}),
      ...(filters.hasOwnProperty('timekeeper_validation') && !!filters.timekeeper_validation
        ? { timekeeper_validation: filters.timekeeper_validation.value }
        : {}),
      ...(filters.hasOwnProperty('accruals_enabled') && !!filters.accruals_enabled
        ? { accruals_enabled: filters.accruals_enabled.value }
        : {}),
      ...(filters.hasOwnProperty('counselgo_enabled') && !!filters.counselgo_enabled
        ? { counselgo_enabled: filters.counselgo_enabled.value }
        : {}),
      ...(filters.hasOwnProperty('other_attribute_list_value_attribute') &&
      !!filters.other_attribute_list_value_attribute
        ? {
            other_attribute_list_value_attribute: filters.other_attribute_list_value_attribute
          }
        : {}),
      ...(filters.hasOwnProperty('other_attribute_list_value') &&
      !!filters.other_attribute_list_value
        ? {
            other_attribute_list_value: filters.other_attribute_list_value
          }
        : {})
    }
  }
}

export const fromFragment = (hash, initialParams) => {
  const parsedHash = qs.parse(hash)

  const result = cloneDeep(initialParams)

  Object.keys(parsedHash).forEach(key => {
    if (key === 'columnKey' || key === 'isDesc') {
      result.ordering = {
        columnKey: parsedHash.columnKey,
        isDesc: parsedHash.isDesc === 'true'
      }
    } else if (
      key === 'vendor_id' ||
      key === 'vendor_group_ids' ||
      key === 'billed_entities' ||
      key === 'vendor_leads'
    ) {
      result.filters[key] = MultiSelectFilterSerializer.fromFragment(key, parsedHash)
    } else if (key === 'status') {
      result.filters.status =
        parsedHash[key] === 'true'
          ? { value: true, label: 'Active' }
          : { value: false, label: 'Deactivated' }
    } else if (key === 'timekeeper_validation') {
      result.filters.timekeeper_validation =
        parsedHash[key] === 'true'
          ? { value: true, label: 'Timekeeper w/ Validation' }
          : { value: false, label: 'Timekeeper w/o Validation' }
    } else if (key === 'accruals_enabled') {
      result.filters.accruals_enabled =
        parsedHash[key] === 'true'
          ? { value: true, label: 'Accruals Enabled' }
          : { value: false, label: 'Accruals Disabled' }
    } else if (key === 'counselgo_enabled') {
      result.filters.counselgo_enabled =
        parsedHash[key] === 'true'
          ? { value: true, label: 'CounselGO Enabled' }
          : { value: false, label: 'CounselGO Disabled' }
    } else if (
      key === 'other_attribute_list_value_attribute' ||
      key === 'other_attribute_list_value'
    ) {
      result.filters[key] = Number(parsedHash[key])
    } else if (key === 'pageSize' || key === 'page') {
      result[key] = Number(parsedHash[key])
    } else {
      result[key] = parsedHash[key]
    }
  })
  return result
}

export const toFragment = obj => {
  const newObj = {
    pageSize: obj.pageSize,
    page: obj.page,
    category: obj.category
  }

  let newFilters = {}

  Object.keys(obj.filters).forEach(key => {
    const currFilter = obj.filters[key]
    if (
      (key === 'vendor_id' ||
        key === 'vendor_group_ids' ||
        key === 'billed_entities' ||
        key === 'vendor_leads') &&
      !!currFilter
    ) {
      newFilters[key] = MultiSelectFilterSerializer.toFragment(key, currFilter)
    } else if (
      key === 'status' ||
      key === 'timekeeper_validation' ||
      key === 'accruals_enabled' ||
      key === 'counselgo_enabled'
    ) {
      newFilters[key] = `${key}=${get(currFilter, 'value', '')}`
    } else if (
      key === 'other_attribute_list_value_attribute' ||
      key === 'other_attribute_list_value'
    ) {
      newFilters[key] = `${key}=${currFilter}`
    }
  })

  const paramStr = qs.stringify(newObj)
  const search = qs.stringify({ search: obj.search })
  const filters = qs.stringify(newFilters)
  const ordering = qs.stringify(obj.ordering)

  const optParam = p => (p ? `&${p}` : '')

  const {
    vendor_id,
    vendor_group_ids,
    billed_entities,
    vendor_leads,
    status,
    timekeeper_validation,
    accruals_enabled,
    counselgo_enabled,
    other_attribute_list_value_attribute,
    other_attribute_list_value
  } = newFilters

  const frag = `${paramStr}${optParam(search)}&${ordering}
  ${optParam(vendor_id)}${optParam(vendor_group_ids)}${optParam(billed_entities)}${optParam(
    vendor_leads
  )}${optParam(status)}${optParam(timekeeper_validation)}${optParam(accruals_enabled)}${optParam(
    counselgo_enabled
  )}${optParam(other_attribute_list_value_attribute)}${optParam(other_attribute_list_value)}`

  return frag
}

export const serializeVendorBulkData = form => {
  return Object.entries(form).reduce((finalForm, [key, { value }]) => {
    return { ...finalForm, [key]: value }
  }, {})
}
