import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import ReactPage from '../components/ReactPage'
import { setPageTitle } from 'hocs'
import { createPageTitle } from 'utils/helpers'

@connect(({ app }) => ({
  pageTitle: app.pageTitle,
  credentials: app.credentials
}))
@setPageTitle(({ credentials }) => ({
  client: get(credentials, 'user.clientName', '')
}))
class ReactPageContainer extends React.Component {
  state = {}
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.pageTitle, prevProps.pageTitle)) {
      document.title = createPageTitle(this.props.pageTitle)
    }
  }
  render() {
    return <ReactPage {...this.props} {...this.state} />
  }
}

export default ReactPageContainer
