import { Component } from 'react'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { notificationStyles } from 'styles/cssInJs'
import { withRouter } from 'simple-core-ui/hocs'
import get from 'lodash/get'

@connect(({ app }) => {
  const { notifications } = app
  return {
    notifications
  }
})
@withRouter
class Main extends Component {
  notificationRef = el => {
    this.notificationSystem = el
  }

  pushNotification = () => {
    this.notificationSystem.setOverrideStyle(
      this.props.notifications.getStyles?.(notificationStyles) || notificationStyles
    )
    this.notificationSystem.addNotification({
      title: this.props.notifications.title,
      message: this.props.notifications.message,
      level: this.props.notifications.level,
      position: this.props.notifications.position,
      autoDismiss: this.props.notifications.autoDismiss,
      dismissible: this.props.notifications.dismissible,
      uid: this.props.notifications.uid,
      onRemove: this.props.notifications.onRemove
    })
  }

  componentDidUpdate(prevProps) {
    if (get(this.props.notifications, 'clear', false)) {
      let { uid } = this.props.notifications
      this.notificationSystem.removeNotification(uid)
    } else if (prevProps.notifications !== this.props.notifications) {
      this.pushNotification()
    }
  }

  render() {
    const { className, children } = this.props

    return (
      <main className={className}>
        {children}
        <NotificationSystem ref={this.notificationRef} style={notificationStyles} />
      </main>
    )
  }
}

export default Main
