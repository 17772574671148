import { Component } from 'react'
import { connect } from 'react-redux'
import { SessionExpiration } from 'simple-core-ui'

@connect(({ app }) => {
  return {
    sessionTime: app.sessionTime,
    refreshUrl: app.refreshUrl
  }
})
class SessionExpirationContainer extends Component {
  render() {
    const { sessionTime, refreshUrl } = this.props
    return <SessionExpiration sessionTime={sessionTime} refreshUrl={refreshUrl} />
  }
}

export default SessionExpirationContainer
