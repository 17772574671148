import SelectFilter from './SelectFilter'
import Select from 'react-select'
import { formatMultiSelectLabel } from '../helpers'
import { defaultBudgetTypes } from '../../../budgets/constants'

const BudgetTypeFilter = ({
  onSelect,
  reset,
  isMulti,
  hasConfirmation,
  title = 'Type',
  initialValue
}) => {
  return (
    <SelectFilter
      title={title}
      reset={reset}
      onSelect={onSelect}
      initialValue={initialValue}
      isMulti={isMulti}
      customLabel={formatMultiSelectLabel(isMulti, title)}
      hasConfirmation={hasConfirmation}
      render={(matter, setMatter) => {
        return (
          <Select
            placeholder="Search labels"
            noOptionsMessage={() => 'No results...'}
            value={matter}
            onChange={setMatter}
            options={defaultBudgetTypes.map(type => ({
              value: type.id,
              label: type.name,
              id: type.id
            }))}
            isMulti={isMulti}
          />
        )
      }}
    />
  )
}

export default BudgetTypeFilter
