import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import moment from 'moment'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { ListTableContainer } from 'containers'
import { REQUEST_STATUSES } from 'matters/requests/constants'
import { RequestModalContainer } from './RequestModalContainer'

@withRouter
@connect(({ requestsList }) => {
  const { requests } = requestsList

  const legalRequests = requests.map(request => {
    return {
      ...request
    }
  })

  return {
    requestsList: legalRequests
  }
})
class RequestsTableContainer extends Component {
  state = {
    showRequestModal: false,
    currentRequestId: null
  }
  componentDidMount() {
    const id = this.props.router.params.id
    if (id) {
      this.setState({
        showRequestModal: true,
        currentRequestId: id
      })
    }
  }

  viewRequestDetail = request => {
    this.setState(prevState => ({
      showRequestModal: !prevState.showRequestModal,
      currentRequestId: request.id
    }))
  }

  toggleRequestModal = () => {
    this.setState(prevState => ({ showRequestModal: !prevState.showRequestModal }))
  }

  getNormalizedCellContent = (columnKey, row) => {
    const { cells } = row
    const cellContent = get(
      cells.find(cell => cell.columnKey === columnKey),
      'content',
      ''
    )

    if (!cellContent) {
      return cellContent
    }

    switch (columnKey) {
      case 'name':
        return cellContent.toLowerCase()
      case 'createdDate':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'lastModified':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'requested_by_user':
        return cellContent.toLowerCase()
      case 'request_form_name':
        return cellContent.toLowerCase()
      case 'reviewed_by_user':
        return cellContent.toLowerCase()
      case 'status':
        return cellContent.toLowerCase()
      case 'matterStatus':
        return cellContent.toLowerCase()
      case 'clientMatterId':
        return cellContent.toString().toLowerCase()
      case 'lastStatusUpdated':
        return moment(cellContent, 'MM/DD/YYYY').unix()
      case 'comment':
        return cellContent.toLowerCase()
      default:
        console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
        return ''
    }
  }

  render() {
    const {
      requestsList,
      columns,
      saveBtnText,
      closeBtnText,
      confirmCb,
      requestsAvailable,
      cancelCb,
      secondaryConfirmCb,
      secondaryConfirmText,
      hideCancelBtn,
      title,
      initialCategory,
      getSecondaryAction
    } = this.props

    const { PENDING, APPROVED, REJECTED } = REQUEST_STATUSES

    const filterListItems = [
      { label: 'All', value: 'All' },
      {
        label: capitalize(PENDING),
        value: capitalize(PENDING)
      },
      {
        label: capitalize(APPROVED),
        value: capitalize(APPROVED)
      },
      {
        label: capitalize(REJECTED),
        value: capitalize(REJECTED)
      }
    ]
    const entryLanguage = {
      singular: 'request',
      plural: 'requests'
    }

    const primaryAction = requestsAvailable
      ? {
          href: '/v2/matters/requests/create',
          testid: 'create_request_btn',
          children: 'New Request'
        }
      : null

    return (
      <Fragment>
        <ListTableContainer
          objects={requestsList}
          columns={columns}
          filterListItems={filterListItems}
          clickRowAction={this.viewRequestDetail}
          getNormalizedCellContent={this.getNormalizedCellContent}
          entryLanguage={entryLanguage}
          title={title}
          initialCategory={initialCategory}
          primaryAction={primaryAction}
          getSecondaryAction={getSecondaryAction || null}
        />
        {this.state.showRequestModal && (
          <RequestModalContainer
            id={this.state.currentRequestId}
            saveBtnText={saveBtnText}
            closeBtnText={closeBtnText}
            confirmCb={confirmCb}
            cancelCb={cancelCb}
            closeCb={this.toggleRequestModal}
            secondaryConfirmCb={secondaryConfirmCb}
            secondaryConfirmText={secondaryConfirmText}
            hideCancelBtn={hideCancelBtn}
          />
        )}
      </Fragment>
    )
  }
}

export default RequestsTableContainer
