import * as React from 'react'
import { put, takeLatest, call, select } from 'redux-saga/effects'

import { makeGetRequest, makePostRequest } from 'utils/api'

import APP_ACT from 'app/actions'
import ACT from './actions'

import { fromTeams, fromTeam, toTeam, fromTeamsManageMeta } from './serializers'

import {
  CREATE_TEAM_URL,
  TEAM_INFO_URL,
  TEAM_UPDATE_URL,
  MANAGE_TEAMS_URL,
  TEAM_DELETE_URL,
  TEAM_DOWNLOAD_XLSX_URL
} from './urls'

function* fetchTeams() {
  yield put({ type: ACT.TEAMS_IS_LOADING })

  try {
    const response = yield call(makeGetRequest, MANAGE_TEAMS_URL)

    yield put({
      type: ACT.TEAMS_FETCH_SUCCESS,
      payload: fromTeams(response.teams)
    })

    yield put({
      type: ACT.TEAMS_MANAGE_META_FETCH_SUCCESS,
      payload: fromTeamsManageMeta(response)
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue fetching your company teams.',
        level: 'error'
      }
    })
  }
}

function* showForUpdate(action) {
  const getTeams = ({ teamsApp: { teams } }) => teams
  const teams = yield select(getTeams)
  const existingTeam = teams.find(({ id }) => id === action.payload)

  if (existingTeam) {
    yield put({ type: ACT.TEAM_SHOW_FOR_UPDATE_SUCCESS, payload: existingTeam })
  } else {
    try {
      yield put({ type: ACT.TEAMS_IS_LOADING })
      const apiTeam = yield call(makeGetRequest, TEAM_INFO_URL(action.payload))
      yield put({
        type: ACT.TEAM_SHOW_FOR_UPDATE_SUCCESS,
        payload: fromTeam(apiTeam)
      })
    } catch (e) {
      console.warn('Team does not exist...')
    }
  }
}

function* createTeam(action) {
  try {
    yield put({ type: ACT.TEAMS_IS_LOADING })

    const apiTeam = yield call(makePostRequest, CREATE_TEAM_URL, toTeam(action.payload))

    const team = fromTeam(apiTeam)

    yield put({
      type: ACT.TEAM_CREATE_SUCCESS,
      payload: team
    })

    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Team Successful Created',
        message: (
          <React.Fragment>
            <strong>{team.name}</strong> has been add to the list of teams in your organization.
          </React.Fragment>
        ),
        level: 'success'
      }
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: (
          <React.Fragment>
            There was an issue creating <strong>{action.payload.name}</strong>.
          </React.Fragment>
        ),
        level: 'error'
      }
    })

    yield put({ type: ACT.TEAMS_IS_NOT_LOADING })
  }
}

function* updateTeam(action) {
  try {
    yield makePostRequest(TEAM_UPDATE_URL(action.payload.id), toTeam(action.payload))

    yield put({
      type: ACT.TEAM_UPDATE_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: (
          <React.Fragment>
            There was an issue updating <strong>{action.payload.name}</strong>.
          </React.Fragment>
        ),
        level: 'error'
      }
    })
  }
}

function* deleteTeam(action) {
  try {
    makePostRequest(TEAM_DELETE_URL(action.payload))

    yield put({
      type: ACT.TEAM_DELETE_SUCCESS,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue when attempting to delete a team.',
        level: 'error'
      }
    })
  }
}

function* setDownloadXLSXStatus(status) {
  yield put({
    type: ACT.TEAMS_XLSX_DOWNLOADING,
    payload: status
  })
}
function* downloadXLSX() {
  try {
    yield* setDownloadXLSXStatus(true)
    yield call(makeGetRequest, TEAM_DOWNLOAD_XLSX_URL)
    window.location.href = TEAM_DOWNLOAD_XLSX_URL
  } catch (e) {
    yield put({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'Error',
        message: 'There was an issue generating XLSX file on the server.',
        level: 'error'
      }
    })
  } finally {
    yield* setDownloadXLSXStatus(false)
  }
}

const teamsSagas = [
  takeLatest(ACT.TEAMS_FETCH_REQUESTED, fetchTeams),
  takeLatest(ACT.TEAM_SHOW_FOR_UPDATE_REQUESTED, showForUpdate),
  takeLatest(ACT.TEAM_CREATE_REQUESTED, createTeam),
  takeLatest(ACT.TEAM_UPDATE_REQUESTED, updateTeam),
  takeLatest(ACT.TEAM_DELETE_REQUESTED, deleteTeam),
  takeLatest(ACT.TEAMS_DOWNLOAD_XLSX, downloadXLSX)
]

export default teamsSagas
