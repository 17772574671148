import { ContentItem } from './ContentItem'
import s from './ContentMain.scss'

const ContentMain = ({ items, changeRequestItemValue, upload, relationships }) => (
  <section className={s.container}>
    <section className={s.containerSection}>
      <h4>
        Required Fields
        <span className={s.redAsterix}>*</span>
      </h4>
      <hr className={s.separator} />
      {items
        .filter(item => item.required)
        .map((item, index) => (
          <ContentItem
            key={item.fieldUniqueName}
            item={item}
            changeRequestItemValue={changeRequestItemValue}
            relationships={relationships}
          />
        ))}
    </section>
    <section className={s.containerSection}>
      <h4>Optional Fields</h4>
      <hr className={s.separator} />
      {items
        .filter(item => !item.required && item.fieldVisible)
        .map((item, index) => (
          <ContentItem
            key={item.fieldUniqueName}
            item={item}
            changeRequestItemValue={changeRequestItemValue}
            relationships={relationships}
          />
        ))}
      <ContentItem
        key="file"
        item={{
          type: 'file',
          onChange: upload,
          label: 'Upload attachments'
        }}
      />
    </section>
  </section>
)

export default ContentMain
