import { Rule } from 'simple_review/@types/api'
import { BaseSerializer } from './base.serializer'

export function RuleCreatedSerializer(newRule: Rule): BaseSerializer {
  return {
    toString() {
      return `Created Rule as ${newRule.is_draft ? 'Inactive' : 'Active'}`
    }
  }
}
