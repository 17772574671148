import s from './NotificationList.scss'

const NotificationList = ({ lines = '' }) =>
  typeof lines === 'string' ? (
    lines
  ) : lines.length === 1 ? (
    JSON.stringify(lines[0])
  ) : (
    <ul className={s.container}>
      {lines.map((line, idx) => (
        <li className={s.line} key={idx}>
          {JSON.stringify(line)}
        </li>
      ))}
    </ul>
  )

export default NotificationList
