import { ModalContainer } from 'simple-core-ui'

const DeleteAdmin = ({ notification, showDeleteAdmin, toggleDeleteAdmin, deleteAdmin }) => {
  return (
    <ModalContainer
      title="Remove Admin"
      size="sm"
      isVisible={showDeleteAdmin}
      confirmText="Delete"
      cancelText="Cancel"
      confirmCb={() => deleteAdmin(notification)}
      cancelCb={toggleDeleteAdmin}
      content={
        <div>
          Are you sure you want to remove {notification.name} from receiving notifications when
          malware is detected?
        </div>
      }
    />
  )
}

export default DeleteAdmin
