import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import xorWith from 'lodash/xorWith'
import pick from 'lodash/pick'

export const checkDuplicateName = (attributes, name, id) => {
  return attributes.some(c => {
    if (c.id === id) return false
    return name && compareName(c.name, name)
  })
}

const compareName = (name1, name2) => name1.trim().toLowerCase() === name2.trim().toLowerCase()

export const isArraysEqual = (x, y, pickedProperties) => {
  if (pickedProperties) {
    return isEmpty(
      xorWith(x, y, (a, b) => isEqual(pick(a, pickedProperties), pick(b, pickedProperties)))
    )
  }
  return isEmpty(xorWith(x, y, isEqual))
}
