import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'simple-core-ui/hocs'
import ACT from 'matters/requestForms/detail/actions'
import { Loading } from 'components'
import { FormHeader } from './Header'
import { LegalRequestFormContentContainer, RequestFormPreviewContainer } from './Main'
import { FORM_STATUSES } from 'matters/requestForms/constants'
import s from './LegalRequestFormContainer.scss'

@withRouter
@connect(({ requestForm }, ownProps) => {
  const formId = ownProps.router.params.id
  const title = formId ? 'Edit Form' : 'Add Form'
  const { status, loading, selectedTemplates } = requestForm

  return {
    title,
    status,
    loading,
    selectedTemplates,
    id: formId
  }
})
class LegalRequestFormContainer extends Component {
  componentDidMount() {
    const { router, dispatch, selectedTemplates } = this.props
    const { params, navigate, location } = router
    const templateIds = selectedTemplates.map(template => template.id)

    this.props.dispatch({
      type: ACT.SET_LOADING_STATE
    })

    if (!location.state || !location.state.fromSelectionPage) {
      if (!params.id) {
        dispatch({
          type: ACT.NEW_REQUEST_FORM_REQUESTED
        })
        this.getFormAttributes(templateIds)
      } else {
        dispatch({
          type: ACT.REQUEST_FORM_FETCH_REQUESTED,
          payload: {
            id: params.id,
            navigate
          }
        })
      }
      dispatch({
        type: ACT.LEGAL_REQUEST_FORM_USERS_FETCH_REQUESTED
      })
    } else {
      this.getFormAttributes(templateIds)
    }
  }
  componentDidUpdate(prevProps) {
    const { selectedTemplates } = this.props
    if (selectedTemplates !== prevProps.selectedTemplates) {
      const templates = selectedTemplates.map(template => template.id)
      this.getFormAttributes(templates)
    }
  }
  cloneForm = () => {
    const { router, id } = this.props
    this.props.dispatch({
      type: ACT.REQUEST_FORM_CLONE_REQUESTED,
      payload: {
        id,
        navigate: router.navigate,
        location: router.location
      }
    })
  }
  getFormAttributes = templates => {
    this.props.dispatch({
      type: ACT.LEGAL_REQUEST_FORM_ATTRIBUTES_FETCH_REQUESTED,
      payload: {
        templates
      }
    })
  }
  render() {
    const { status, loading } = this.props
    if (loading) {
      return <Loading />
    } else if (status === FORM_STATUSES.ARCHIVED) {
      return <RequestFormPreviewContainer />
    } else {
      return (
        <div className={s.container}>
          <FormHeader
            formStatus={this.props.status}
            title={this.props.title}
            navigateToFormListPage={this.navigateToFormListPage}
            cloneForm={this.cloneForm}
          />
          <LegalRequestFormContentContainer />
        </div>
      )
    }
  }
}

export default LegalRequestFormContainer
