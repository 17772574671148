import React from 'react'
import cn from 'classnames'
import s from './Files.scss'

const Files = ({ files = [], labelClass, filesContainerClass }) => {
  if (!files.length) {
    return null
  }
  return (
    <React.Fragment>
      <p className={cn({ [labelClass]: labelClass }, s.label)}>Files</p>
      <div className={cn({ [filesContainerClass]: filesContainerClass })}>
        {files.map(file => (
          <p key={file.fileURL}>
            <a href={`/attachment/${file.fileURL}`} target="_blank" rel="noopener noreferrer">
              {file.fileName}
            </a>
          </p>
        ))}
      </div>
    </React.Fragment>
  )
}

export default Files
