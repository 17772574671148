import * as React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Button, If } from 'simple-core-ui'

import ACT from '../actions'

import StateBanner from 'budgets/detail/components/StateBanner'

import { SCOPE, ADMIN_ACTIONS, REVIEW_STATUS } from '../utils/constants'
import { withRouter } from 'simple-core-ui/hocs'

const REVIEW_STAGE = {
  PRE_REVIEW: 'PRE_REVIEW',
  IN_REVIEW: 'IN_REVIEW',
  READY_FOR_FINAL_APPROVAL: 'READY_FOR_FINAL_APPROVAL',
  APPROVED: 'APPROVED'
}

@connect(
  ({
    reviews: { reviewLadder },
    app: {
      loading: { ADMIN_ACTION: reviewActionLoading }
    }
  }) => {
    const {
      isHydrating,
      review: { isFinalApproval, state }
    } = reviewLadder

    return { isHydrating, isFinalApproval, reviewStatus: state, reviewActionLoading }
  }
)
@withRouter
class ReviewableBannerContainer extends React.Component {
  triggerReview = (scope, scopeId) => {
    this.props.dispatch({
      type: ACT.ADMIN_ACTION_REQUESTED,
      loadingLock: 'on',
      payload: {
        scope,
        scopeId,
        adminAction: ADMIN_ACTIONS.START_REVIEW
      }
    })
  }

  getBannerProps = (scope, scopeId) => {
    const {
      isFinalApproval,
      reviewId,
      reviewStatus,
      pendingRequiredFields,
      reviewActionLoading
    } = this.props
    const StartReview = () => (
      <Button
        testid="matter_review_banner_submit"
        isPrimary
        onClick={() => this.triggerReview(scope, scopeId)}
        isDisabled={pendingRequiredFields.length || reviewActionLoading}
      >
        Submit
      </Button>
    )

    const pendingFieldsMessage = pendingRequiredFields.length
      ? `The following fields require values: ${pendingRequiredFields.join(', ')}. `
      : ''

    const BANNER_TEXT = {
      [SCOPE.MATTER]: {
        [REVIEW_STAGE.PRE_REVIEW]: {
          bannerText: `${pendingFieldsMessage}Click submit to start the matter review flow.`,
          actionButtons: [<StartReview key={0} />]
        },
        [REVIEW_STAGE.IN_REVIEW]: {
          bannerText: 'This draft matter is currently in review.'
        },
        [REVIEW_STAGE.READY_FOR_FINAL_APPROVAL]: {
          bannerText: 'This draft matter is ready to be published.'
        },
        [REVIEW_STAGE.APPROVED]: {
          bannerText: 'This matter has been published.'
        }
      }
    }

    const stage =
      reviewStatus === REVIEW_STATUS.APPROVED
        ? REVIEW_STAGE.APPROVED
        : isFinalApproval
        ? REVIEW_STAGE.READY_FOR_FINAL_APPROVAL
        : reviewId
        ? REVIEW_STAGE.IN_REVIEW
        : REVIEW_STAGE.PRE_REVIEW

    return get(BANNER_TEXT, `${scope}.${stage}`, { bannerText: '', actionButtons: [] })
  }

  render() {
    const { isHydrating, reviewId, classNameMap, router } = this.props
    const isLoading = reviewId && isHydrating
    const { location, params } = router
    const scope = location.pathname.includes('matters') ? SCOPE.MATTER : ''
    const scopeId = params.id

    return (
      <If
        condition={!isLoading}
        fallback={<StateBanner classNameMap={classNameMap} bannerText="Loading..." />}
      >
        <StateBanner classNameMap={classNameMap} {...this.getBannerProps(scope, scopeId)} />
      </If>
    )
  }
}

export default ReviewableBannerContainer
