import s from './Column.scss'
import cn from 'classnames'

const Column = ({ label, primaryInfo, secondaryInfo, classNames }) => (
  <div className={s.column}>
    <p className={s.label}>{label}</p>
    <p className={cn(s.primaryInfo, classNames)}>{primaryInfo}</p>
    <p className={s.secondaryInfo}>{secondaryInfo}</p>
  </div>
)

export default Column
