import * as React from 'react'

import LandingPage from '../components/LandingPage'
import { capitalizeStr } from 'utils/formatting'
import { setPageTitle } from 'hocs'
import APP_PAGES_MAP from 'data/app_pages_map'
import { withRouter } from 'simple-core-ui/hocs'

@setPageTitle(props => {
  const formattedTitle = capitalizeStr(props.router.params.landingPage)
  return {
    view: formattedTitle,
    detail: `All ${formattedTitle} Pages`
  }
})
@withRouter
class LandingPageContainer extends React.Component {
  state = {
    basePages: [],
    filteredPages: [],
    filterQuery: '',
    isFiltering: false
  }

  componentDidMount() {
    this.getPages()
  }

  getPages = () => {
    const keyword = this.getKeyword()
    if (keyword) {
      const basePages = this.sortPages(
        window.credentials.navigationUrls
          .filter(el => el.name === keyword)[0]
          .urls.map(el => ({
            ...el,
            ...APP_PAGES_MAP[el.name],
            text: this.getCustomName(el.name)
          }))
      )
      this.setState({ filteredPages: basePages, basePages })
    }
  }

  getKeyword = () => {
    switch (this.props.router.params.landingPage) {
      // TODO - remove reports after everyone turned on
      case 'reports':
        return 'report_list'
      case 'matters':
        return 'matter_request'
      case 'administration':
        return 'administration'
      case 'support':
        return 'support'
      default:
        return this.props.router.params.landingPage
    }
  }

  filterPages = query => {
    this.setState({ isFiltering: true, filterQuery: query }, () => {
      const filteredPages = this.sortPages(
        this.state.basePages.filter(
          el =>
            el.text.toLowerCase().includes(query.toLowerCase()) ||
            el.description.toLowerCase().includes(query.toLowerCase())
        )
      )
      this.setState({ isFiltering: false, filteredPages })
    })
  }

  sortPages = pages => {
    const sortedPages = [...pages].sort((a, b) =>
      a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    )
    const firstIndex = sortedPages.findIndex(el => el.isFirst)
    const lastIndex = sortedPages.findIndex(el => el.isLast)
    if (firstIndex !== -1) {
      this.moveItems(sortedPages, firstIndex, 0)
    }
    if (lastIndex !== -1) {
      this.moveItems(sortedPages, lastIndex, sortedPages.length - 1)
    }
    return sortedPages
  }

  moveItems = (arr, from, to) => arr.splice(to, 0, arr.splice(from, 1)[0])

  getCustomName = name =>
    APP_PAGES_MAP[name].isDynamic
      ? window.credentials.custom.names[name][1]
      : APP_PAGES_MAP[name].text

  render() {
    return (
      <LandingPage
        {...this.state}
        landingPage={capitalizeStr(this.props.router.params.landingPage)}
        filterPages={this.filterPages}
      />
    )
  }
}

export default LandingPageContainer
