import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import queryString from 'query-string'

import { Loading } from 'components'
import { updateLinkParam } from 'utils/helpers'

import RulesList from '../components/RulesList'
import { EngineContext } from 'rules/context'
import ACT from 'rules/actions'
import RC_ACT from 'reviews/reviewer_config/actions'
import {
  CATEGORY,
  ENABLED_ENGINES,
  ENGINE,
  ENGINE_REVIEWER_SCOPE_MAP,
  QUERY_PARAMS
} from 'rules/constants'
import { getRuleIndexMap, getRulePriorityMap, getRuleNameMap, canEditEngine } from 'rules/utils'

const RulesListContainer = () => {
  const dispatch = useDispatch()
  const rules = useSelector(state => state.rules)
  const { engine, isLoading, isSaving } = rules
  const { rulesList, savedRules, filters } = rules[engine]

  const hasUnsavedChanges =
    !isEqual(rulesList, savedRules) || rulesList.some(({ clearable }) => clearable)
  const ruleIndexMap = getRuleIndexMap(savedRules)
  const rulePriorityMap = getRulePriorityMap(savedRules)
  const ruleNameMap = getRuleNameMap(savedRules)
  const newRulePending = rulesList.find(({ id = null }) => id === null)

  const reviewerScope = ENGINE_REVIEWER_SCOPE_MAP[engine]

  const fetchRules = () => {
    dispatch({
      type: ACT.FETCH_ENGINE_RULES_REQUESTED
    })
  }

  const debouncedFetchRules = useRef(debounce(fetchRules, 750)).current

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    const parsedQueryString = queryString.parse(location.search)
    const queryEngine = get(parsedQueryString, 'engine', ENABLED_ENGINES[0])
    // redirect to the first enabled engine if the one in the url is not enabled
    // this will not allow to access the tab using url directly
    const engine = ENABLED_ENGINES.includes(queryEngine) ? queryEngine : ENABLED_ENGINES[0]

    const category = get(parsedQueryString, QUERY_PARAMS.CATEGORY, CATEGORY.ACTIVE)
    const search = get(parsedQueryString, QUERY_PARAMS.SEARCH, '')

    handleChangeCategory(category)
    handleChangeEngine(engine)
    handleSearch(search)

    fetchRules()

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (engine !== ENGINE.INVOICE_AI) {
      dispatch({
        type: ACT.FETCH_ENGINE_NAMESPACE_REQUESTED,
        payload: { engine }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine])

  const handleUnload = async event => {
    const parsedQueryString = queryString.parse(location.search)
    const engine = get(parsedQueryString, 'engine', ENABLED_ENGINES[0])

    if (hasUnsavedChanges && engine !== 'invoice_ai') {
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
  }

  const resetReviewers = () => {
    // TODO: Ideally we would cache the reviewer configs
    if (reviewerScope) {
      dispatch({
        type: RC_ACT.RESET_REVIEWER_CONFIG,
        payload: { reviewerScope }
      })
    }
  }

  const handleAddRule = unconditional => {
    resetReviewers()

    dispatch({
      type: ACT.ADD_NEW_RULE,
      payload: { unconditional }
    })
  }

  const handleChangeCategory = category => {
    updateLinkParam({ key: QUERY_PARAMS.CATEGORY, value: category })

    dispatch({
      type: ACT.UPDATE_RULES_FILTER_CATEGORY,
      payload: {
        category
      }
    })
  }

  const handleChangeEngine = newEngine => {
    updateLinkParam({ key: QUERY_PARAMS.ENGINE, value: newEngine })

    dispatch({
      type: ACT.UPDATE_RULE_ENGINE,
      payload: newEngine
    })
  }

  const handleChangePage = page => {
    dispatch({
      type: ACT.UPDATE_RULES_FILTER_PAGE,
      payload: { page }
    })
  }

  const handleChangePageSize = pageSize => {
    dispatch({
      type: ACT.UPDATE_RULES_FILTER_PAGE_SIZE,
      payload: { pageSize }
    })
  }

  const handleSearch = search => {
    updateLinkParam({ key: QUERY_PARAMS.SEARCH, value: search })

    dispatch({
      type: ACT.UPDATE_RULES_FILTER_SEARCH,
      payload: { search }
    })
  }

  const updateAndFetch = (cb, debounce) => {
    return (...params) => {
      cb(...params)

      if (debounce) {
        debouncedFetchRules()
      } else {
        fetchRules()
      }
    }
  }

  return isLoading ? (
    <Loading />
  ) : (
    <EngineContext.Provider value={{ canEdit: canEditEngine(engine) }}>
      <RulesList
        engine={engine}
        isSaving={isSaving}
        rulesList={rulesList}
        ruleIndexMap={ruleIndexMap}
        ruleNameMap={ruleNameMap}
        rulePriorityMap={rulePriorityMap}
        newRulePending={newRulePending}
        category={filters.category}
        search={filters.search}
        onAddRule={handleAddRule}
        onChangeEngine={handleChangeEngine}
        onChangeCategory={updateAndFetch(handleChangeCategory)}
        onChangePage={updateAndFetch(handleChangePage)}
        onChangePageSize={updateAndFetch(handleChangePageSize)}
        onSearch={updateAndFetch(handleSearch, true)}
      />
    </EngineContext.Provider>
  )
}

export default RulesListContainer
