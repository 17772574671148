import { Title, VerticalTabs } from 'simple-core-ui'
import s from './TaskManagementSettings.scss'
import { Priorities } from './Priorities'
import { Statuses } from './Statuses'
import { TaskTypes } from './TaskTypes'
import { Templates } from './Templates'
import { Template } from './Template'
import { useMatch } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

const TaskManagementSettings = () => {
  const match = useMatch('/v2/task_management/settings/:tabKey/*')
  const activeTab = match ? match.params.tabKey : 'priorities'

  return (
    <section className={s.container}>
      <a href="/v2/administration/" style={{ bottom: '15px', position: 'relative' }}>
        <IoIosArrowBack style={{ position: 'relative', top: 2 }} />
        Back to Administration
      </a>
      <Title text="Tasks Settings" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs
          withRoutes
          activeTabKey={activeTab as string}
          tabs={[
            {
              index: true,
              key: 'priorities',
              label: 'Priorities',
              path: 'priorities',
              element: <Priorities />
            },
            {
              key: 'statuses',
              label: 'Statuses',
              path: 'statuses',
              element: <Statuses />
            },
            {
              key: 'templates',
              label: 'Templates',
              path: 'templates',
              element: <Templates />,
              children: [
                {
                  path: ':id',
                  element: <Template />
                }
              ]
            },
            {
              key: 'types',
              label: 'Types',
              path: 'types',
              element: <TaskTypes />
            }
          ]}
        />
      </section>
    </section>
  )
}

export default TaskManagementSettings
