import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeGetRequest } from 'utils/api'

import { formatResponse, formatErrorMessage } from 'utils/formatting'
import APP_ACT from 'app/actions'
import { NotificationList } from 'components'
import { LoadingSkeleton, useLoading } from 'simple-core-ui'
import IManage from './IManage/IManage'
import Box from './Box/Box'

import { BOX, IMANAGE } from './constants'
import s from './ExternalFileManagementContainer.scss'

const ExternalFileManagementContainer = ({ matterId }) => {
  const dispatch = useDispatch()

  const [config, setConfig] = useState(null)
  const [isLoading, withLoadingLocks] = useLoading()

  const getConfig = async () => {
    try {
      const url = `/manage/matters/doc_mgmt_config/${matterId}/`
      const { doc_mgmt_config } = await withLoadingLocks(makeGetRequest(url))
      setConfig(doc_mgmt_config)
    } catch (error) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was an issue fetching Document management configuration',
          message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
          level: 'error'
        }
      })
    }
  }

  useEffect(() => {
    getConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contentByType = {
    [IMANAGE]: <IManage config={config} matterId={matterId} updateConfig={() => getConfig()} />,
    [BOX]: (
      <Box
        config={config}
        matterId={matterId}
        loading={isLoading}
        updateConfig={() => getConfig()}
      />
    )
  }

  const getContent = () => {
    if (config?.configured) {
      return contentByType[config.type]
    }
    return isLoading ? (
      <div className={s.centerContent}>
        <LoadingSkeleton height={8} />
      </div>
    ) : (
      <div className={s.centerContent}>
        <div>Sorry, external file management service is not configured</div>
      </div>
    )
  }

  return <div className={s.container}>{getContent()}</div>
}

export default ExternalFileManagementContainer
