import s from './Card.scss'
import cn from 'classnames'
import { Button, Tooltip } from 'simple-core-ui'
import { isBasicTaskManagementPlan, hasModule } from 'utils/helpers'

interface Props {
  title: string
  description: string
  active: boolean
  className?: string
  onConnect: () => void
  onDisconnect: () => void
  logo?: JSX.Element
}

const cannotIntegrateWithCalendarRules =
  !hasModule('has_calendar_rules_integration') && !window.credentials?.user?.isCSM

const isBasicPlan = isBasicTaskManagementPlan()

const Card = ({ title, description, active, className, onConnect, logo, onDisconnect }: Props) => {
  return (
    <div className={cn(s.card, className)}>
      <div>
        <div style={{ display: 'flex' }}>
          {logo && <div className={s.logo}>{logo}</div>}
          <div className={s.title}>{title}</div>
        </div>
        <div className={s.description}>{description}</div>
      </div>
      {active ? (
        <div>
          <Button onClick={onDisconnect} isPrimary hasNewDesign style={{ padding: '10px 15px' }}>
            Disconnect
          </Button>
        </div>
      ) : (
        <>
          {cannotIntegrateWithCalendarRules || isBasicPlan ? (
            <Tooltip
              trigger={
                <Button
                  isDisabled={cannotIntegrateWithCalendarRules || isBasicPlan}
                  onClick={onConnect}
                  isPrimary
                  hasNewDesign
                  style={{ padding: '10px 15px' }}
                >
                  Connect
                </Button>
              }
              content={
                cannotIntegrateWithCalendarRules
                  ? 'Coming soon, contact customer service'
                  : 'Contact customer success to upgrade.'
              }
              placement="top"
            />
          ) : (
            <Button onClick={onConnect} isPrimary hasNewDesign style={{ padding: '10px 15px' }}>
              Connect
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default Card
