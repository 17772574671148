import { useState, useEffect, useMemo } from 'react'
import s from './CourtRules.scss'
import {
  Panel,
  Tabs,
  Tab,
  LawRuleIcon,
  Button,
  DataTableWrapper,
  useLoading,
  Ellipsis
} from 'simple-core-ui'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Jurisdiction, Option, Cell } from './types'
import Select from 'react-select'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import { openLink } from 'utils/helpers'
import { toJurisdictionOptions } from './serializers'
import { IoWarningOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const initialState = {
  params: {
    pageSize: 10,
    ordering: { columnKey: 'label', isDesc: false },
    search: '',
    page: 1,
    category: 'all'
  }
}

interface Params {
  pageSize: number
  search: string
  page: number
  category: string
  ordering: {
    columnKey: string
    isDesc: boolean
  }
}

const CourtRules = () => {
  const [activeTab, setActiveTab] = useState<'jurisdictions' | 'settings'>('jurisdictions')
  const [hasCalendarRules, setHasCalendarRules] = useState(false)
  const [localState, setLocalState] = useState(initialState)
  const { params } = localState
  const [jurisdictionsList, setJurisdictionsList] = useState<Jurisdiction[]>([])
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<Option | null>(null)
  const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<number | null>(null)
  const navigate = useNavigate()

  const columns = useMemo(() => {
    return [
      {
        columnKey: 'label',
        content: 'Jurisdiction Name',
        isSortable: true,
        isFilterable: true,
        render: (cell: Cell, row: Jurisdiction) => {
          const navigatePath = window.location.pathname.includes('rules')
            ? `${row.id}`
            : `rules/${row.id}`

          return row.matterCount ? (
            <a style={{ cursor: 'pointer' }} onClick={() => navigate(navigatePath)}>
              <Ellipsis className={s.nameCell}>
                {typeof cell.content === 'string' && cell.content}
              </Ellipsis>
            </a>
          ) : (
            <Ellipsis className={s.nameCell}>
              {typeof cell.content === 'string' && cell.content}
            </Ellipsis>
          )
        }
      },
      {
        columnKey: 'needsReview',
        content: 'Review Required',
        isSortable: false,
        isFilterable: false,
        render: (_cell: Cell, row: Jurisdiction) => {
          return row.needsReview ? (
            <IoWarningOutline style={{ fontSize: 22, color: '#bb342f' }} />
          ) : (
            ''
          )
        }
      },
      {
        columnKey: 'matterCount',
        content: 'Related Matters',
        isSortable: false,
        isFilterable: false,
        render: (cell: Cell) => {
          return cell.content === '----' ? 0 : cell.content
        }
      },
      {
        columnKey: 'added',
        content: 'Date Added',
        isSortable: true,
        isFilterable: true
      }
    ]
  }, [navigate])

  const fetchConfig = async () => {
    try {
      const { configured, jurisdiction_system_id } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/settings')
      )
      setHasCalendarRules(
        Boolean(
          [{ name: 'CalendarRules', configured }].filter(integration => integration.configured)
            .length
        )
      )
      setSelectedJurisdictionId(jurisdiction_system_id)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const fetchJurisdictionsList = async () => {
    try {
      const { rows } = await withLoadingLocks(
        makeGetRequest('/event-management/calendar_rules/court-rules-jurisdictions')
      )
      setJurisdictionsList(toJurisdictionOptions(rows))
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    fetchConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasCalendarRules) fetchJurisdictionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCalendarRules])

  useEffect(() => {
    if (selectedJurisdictionId && jurisdictionsList.length) {
      setSelectedJurisdiction(
        jurisdictionsList.find(({ value }) => value === selectedJurisdictionId) ?? null
      )
    }
  }, [selectedJurisdictionId, jurisdictionsList])

  const updateTable = (params: Params) => {
    setLocalState({
      ...localState,
      params
    })
  }

  const saveSettings = async () => {
    try {
      await makePostRequest('/event-management/calendar_rules/settings', {
        jurisdiction_system_id: selectedJurisdiction?.value
      })
      dispatch({
        type: 'PUSH_NOTIFICATION',
        payload: {
          title: 'Court Rules settings saved',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  const downloadXlsx = () => {
    openLink(`/event-management/calendar_rules/jurisdictions/export`)
  }

  return (
    <>
      <Panel
        title="Court Rules"
        subtitle={
          <>
            <AiOutlineInfoCircle className={s.info} /> Court Rules sets are populated through{' '}
            <b>CalendarRules</b> a third party application. Contact CalendarRules to add or remove
            jurisdictions.
            {activeTab === 'jurisdictions' && (
              <p>
                <IoWarningOutline
                  style={{ fontSize: 22, color: '#bb342f', position: 'relative', top: 5 }}
                />{' '}
                There are updates from <b>CalendarRules</b> that require action. They may change
                matter with court rules and the associated events.
              </p>
            )}
          </>
        }
        rightActions={
          activeTab === 'jurisdictions' && hasCalendarRules && !!jurisdictionsList.length
            ? [
                <Button
                  style={{ padding: '10px 15px' }}
                  key="download"
                  onClick={downloadXlsx}
                  isPrimary
                  isOutline
                  hasNewDesign
                >
                  Download
                </Button>
              ]
            : []
        }
        styles={{ boxShadow: 'none' }}
      >
        {hasCalendarRules ? (
          <Tabs alignRight>
            {/* @ts-expect-error */}
            <Tab
              selected={activeTab === 'jurisdictions'}
              header="Jurisdictions"
              onClickCb={() => {
                setActiveTab('jurisdictions')
              }}
            >
              <DataTableWrapper
                params={params}
                categories={[]}
                rows={jurisdictionsList}
                columns={columns}
                updateTable={updateTable}
                panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
                hasTooltip
                categoryKey="jurisdiction"
                hasActions={false}
              />
            </Tab>
            {/* @ts-expect-error */}
            <Tab
              selected={activeTab === 'settings'}
              header="Default Settings"
              onClickCb={() => {
                setActiveTab('settings')
              }}
            >
              <div className={s.settingsWrapper}>
                <div className={s.statusItem}>
                  <p style={{ fontSize: 14, marginBottom: 5 }}>Default Jurisdiction</p>
                  <Select
                    options={jurisdictionsList}
                    value={selectedJurisdiction}
                    onChange={value => {
                      setSelectedJurisdiction(value)
                      setSelectedJurisdictionId(null)
                    }}
                    placeholder="Select a Jurisdiction"
                    isSearchable={true}
                    isClearable
                    className={s.select}
                  />
                </div>
                <Button hasNewDesign isPrimary onClick={saveSettings}>
                  Save Changes
                </Button>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <div>
            <hr style={{ borderColor: '#dedede', borderTop: 0, marginBottom: 30 }} />
            <p className={s.emptyState}>
              Click on the <span style={{ color: '#3c98fd' }}>Integrations tab</span> in Event
              Settings to connect <b>CalendarRules</b> with your SimpleLegal account to access Court
              Rules.
            </p>
            <div style={{ marginTop: 20, textAlign: 'center' }}>
              <LawRuleIcon />
            </div>
          </div>
        )}
      </Panel>
    </>
  )
}

export default CourtRules
