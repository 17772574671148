import { Operand } from 'simple_review/@types/api'
import { ContextState } from 'simple_review/@types/context'
import { FuncFieldMapper, ModelFieldMapper } from './model-field/model-field-mapper'
import { generateConstant, generateListConstant } from './constant-factory'
import { ExpressionMapper } from './expression-mapper'
import { VALID_OPERATORS, IN_OPERATORS } from './expression-model'
import { UNARY_OPERATORS } from 'data/operators'

export const buildExpressionMapperFromApi = (
  operand: Operand,
  context: ContextState
): ExpressionMapper => {
  //TODO: Support func
  if (!VALID_OPERATORS.some(o => o === operand.op)) {
    throw new Error('operator ' + operand.op + ' not supported')
  }

  if (
    !IN_OPERATORS.some(o => o === operand.op) &&
    !(operand.rhs && 'attr_id' in operand.rhs) &&
    Array.isArray(operand.rhs?.constant)
  ) {
    throw new Error('list constant type not supported for this operator')
  }

  if (!UNARY_OPERATORS.some(o => o === operand.op) && !operand.rhs) {
    throw new Error('rhs must exist for this operator')
  }

  let modelField
  let constant

  if ('func_name' in operand.lhs) {
    modelField = new FuncFieldMapper(operand.lhs.func_name, operand.lhs.args)
  } else {
    modelField = new ModelFieldMapper(
      operand.lhs.model_name,
      operand.lhs.field_name,
      operand.lhs.attr_id
    )
  }

  if (!operand.rhs) {
    constant = undefined
  } else if ('attr_id' in operand.rhs) {
    constant = generateConstant('custom_attribute', operand.rhs, '', context)
  } else if (Array.isArray(operand.rhs.constant)) {
    constant = generateListConstant(operand.rhs.type, operand.rhs.constant, operand.rhs.sub_type)
  } else {
    constant = generateConstant(
      operand.rhs.type,
      operand.rhs.constant,
      operand.rhs.sub_type,
      context
    )
  }

  return new ExpressionMapper(modelField, operand.op, constant ?? null)
}
