import s from '../AddEvent.scss'
import { KeyValuePairs, SwitchToggle, Ellipsis } from 'simple-core-ui'
import Select, { StylesConfig } from 'react-select'
import { hex2rgba, isBasicTaskManagementPlan } from 'utils/helpers'
import { LocalEvent, InitialEvent, APIAttachment, Event, Option } from '../../types'
import { toEvent } from '../serializers'
import cn from 'classnames'
import { Reminder } from 'common/Events/Reminder'
import { getReminderValues } from '../../utils'

interface LocalOption<T = number> extends Option<T> {
  color?: string
}

interface Props {
  eventTypes: LocalOption[]
  event: LocalEvent | InitialEvent
  scopeName: string
  saveEvent?: (
    event: Event,
    isEdit: boolean | undefined,
    resetEditedProperties?: () => void
  ) => void
  editedProperties?: Partial<LocalEvent>
  resetEditedProperties?: () => void
  updateEvent: (
    value:
      | string
      | number
      | null
      | LocalOption<string | number>
      | LocalOption[]
      | boolean
      | Date
      | undefined
      | APIAttachment[]
      | {
          type: string
          value: number | null
        },
    property: keyof (LocalEvent | InitialEvent),
    nestedProperty?: string
  ) => void
  isEdit?: boolean
  readOnly?: boolean
  context?: 'matter' | 'workbench'
}

const customStyle: StylesConfig<LocalOption<string | number>, false> = {
  control: provided => ({
    ...provided,
    border: '0px solid black',
    backgroundColor: 'white',
    outline: 'none',
    boxShadow: 'none',
    minHeight: '32px',
    height: '32px',
    position: 'relative',
    top: '4px'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  singleValue: (provided, state) => {
    const color = state.data.color
    if (!color) return provided
    return {
      ...provided,
      borderRadius: '4px',
      color: '#000000',
      padding: '2px 12px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      minWidth: '100px',
      maxWidth: 'fit-content',
      backgroundColor: hex2rgba(color, 0.15),
      border: `1px solid ${color}`,
      position: 'relative',
      bottom: '1px'
    }
  },
  option: provided => {
    return {
      ...provided,
      wordWrap: 'break-word'
    }
  }
}

const noneOption = { value: -1, label: 'None' }

const Overview = ({
  eventTypes,
  event,
  scopeName,
  updateEvent,
  saveEvent,
  isEdit,
  editedProperties,
  resetEditedProperties,
  readOnly,
  context
}: Props) => {
  return (
    <KeyValuePairs
      style={{ margin: '20px 0 0 0' }}
      pairs={[
        {
          key: 'Reminder',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: cn(s.section, {
            [s.readOnly]: readOnly
          }),
          value: (
            <Reminder
              reminder={event.reminder}
              readOnly={!!readOnly}
              customStyle={customStyle}
              isPortal
              onChangeTypeValue={(item: Option) => {
                updateEvent(item.value, 'reminder', 'value')
                if (isEdit) {
                  saveEvent &&
                    saveEvent(
                      toEvent(({
                        id: editedProperties?.id,
                        reminder: item ? { value: item.value, type: event.reminder?.type } : null
                      } as unknown) as LocalEvent),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              onChangeType={(item: Option<string>): void => {
                const reminderValue =
                  item.value === 'none'
                    ? null
                    : { type: item.value, value: getReminderValues(String(item.value))[0].value }
                updateEvent(reminderValue, 'reminder')
                if (isEdit) {
                  saveEvent &&
                    saveEvent(
                      toEvent(({
                        id: editedProperties?.id,
                        reminder: reminderValue
                      } as unknown) as LocalEvent),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              updateEvent={(value: { type: string; value: number | null }) => {
                updateEvent(value, 'reminder')
                if (isEdit) {
                  saveEvent &&
                    saveEvent(
                      toEvent(({
                        id: editedProperties?.id,
                        reminder: value
                      } as unknown) as LocalEvent),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
            />
          )
        },
        {
          key: 'Calendar',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          value: (
            <div
              className={s.level}
              style={{
                backgroundColor: hex2rgba(event.calendar.color, 0.15),
                border: `1px solid ${event.calendar.color}`
              }}
            >
              Team Calendar
            </div>
          )
        },
        ...(context === 'matter' || isEdit
          ? [
              {
                key: 'Related To',
                style: { marginBottom: '20px', height: 40 },
                keyClass: s.key,
                value: (
                  <Ellipsis width={400} lines={1} className={s.scopeName}>
                    {scopeName}
                  </Ellipsis>
                )
              }
            ]
          : []),
        {
          key: 'Type',
          style: { marginBottom: '20px', height: 40 },
          keyClass: s.key,
          sectionClass: cn(s.section, {
            [s.readOnly]: readOnly
          }),
          value: (
            <Select
              options={[noneOption, ...eventTypes]}
              value={event.eventType}
              onChange={item => {
                if (item?.value === -1) {
                  item = null
                }
                updateEvent(item, 'eventType')
                if (isEdit) {
                  saveEvent &&
                    saveEvent(
                      toEvent({ id: editedProperties?.id, eventType: item } as LocalEvent),
                      isEdit,
                      resetEditedProperties
                    )
                }
              }}
              placeholder="Select..."
              className={s.select}
              styles={customStyle}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              isDisabled={readOnly}
            />
          )
        },
        {
          key: 'Private',
          keyClass: s.key,
          valueClass: s.flex,
          value: (
            <>
              <SwitchToggle
                id="upgradeTier"
                onToggle={
                  isBasicTaskManagementPlan()
                    ? undefined
                    : checked => {
                        updateEvent(checked, 'isPrivate')
                        if (isEdit) {
                          saveEvent &&
                            saveEvent(
                              toEvent({
                                id: editedProperties?.id,
                                isPrivate: checked
                              } as LocalEvent),
                              isEdit,
                              resetEditedProperties
                            )
                        }
                      }
                }
                checked={event.isPrivate}
                readOnly={readOnly}
                isPremium={!isBasicTaskManagementPlan()}
                tooltipContent="Contact customer success to upgrade."
              />
              Restrict visibility to event attendees only
            </>
          )
        }
      ]}
    />
  )
}

export default Overview
