import { useEffect } from 'react'
import { XeditableContainer, KeyValuePairs } from 'simple-core-ui'
import { ControlledDatePicker } from 'containers'
import s from './Details.scss'
import { useImmer } from 'use-immer'
import { makePostRequest } from 'utils/api'
import { useDispatch } from 'react-redux'

const Details = ({ modelId, details, permissions, readOnly, setSettings }) => {
  const [matterDetails, setMatterDetails] = useImmer(details)
  const dispatch = useDispatch()

  useEffect(() => {
    setMatterDetails(details)
  }, [details, setMatterDetails])

  const saveDate = async (field, value) => {
    try {
      const response = await makePostRequest(`/manage/matters/details/${modelId}/`, {
        [field]: value
      })
      setSettings(settings => ({ ...settings, details: { ...settings.details, [field]: value } }))
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }
  return (
    <div className={s.wrapper}>
      <KeyValuePairs
        pairs={[
          {
            key: 'Created On',
            value: matterDetails?.created_date,
            style: { marginBottom: '10px' },
            valueClass: s.value
          },
          {
            key: 'Opened On',
            value: (
              <XeditableContainer
                setValueCb={value => {
                  setMatterDetails(draft => {
                    draft.open_date = value
                  })
                  saveDate('open_date', value)
                }}
                renderForm={(_, updateFormValue, formValue) => (
                  <ControlledDatePicker
                    value={matterDetails?.open_date ?? ''}
                    onChange={updateFormValue}
                  />
                )}
                readOnly={!permissions?.has_edit_capability || readOnly}
                type="text"
                initialValue={matterDetails?.open_date}
                position="bottom"
              />
            )
          },
          {
            key: 'Closed On',
            value: (
              <XeditableContainer
                setValueCb={value => {
                  setMatterDetails(draft => {
                    draft.close_date = value
                  })
                  saveDate('close_date', value)
                }}
                renderForm={(_, updateFormValue, formValue) => (
                  <ControlledDatePicker
                    value={matterDetails?.close_date ?? ''}
                    onChange={updateFormValue}
                  />
                )}
                readOnly={!permissions?.has_edit_capability || readOnly}
                type="text"
                initialValue={matterDetails?.close_date}
                position="bottom"
              />
            )
          },
          {
            key: 'Invoice Accepted Prior to',
            value: (
              <XeditableContainer
                setValueCb={value => {
                  setMatterDetails(draft => {
                    draft.invoice_until_date = value
                  })
                  saveDate('invoice_until_date', value)
                }}
                renderForm={(_, updateFormValue, formValue) => (
                  <ControlledDatePicker
                    value={matterDetails?.invoice_until_date ?? ''}
                    onChange={updateFormValue}
                  />
                )}
                readOnly={!permissions?.can_edit_until_date}
                type="text"
                initialValue={matterDetails?.invoice_until_date}
                position="bottom"
              />
            )
          },
          {
            key: 'Template',
            value: matterDetails?.template?.name,
            valueClass: s.value
          }
        ]}
      />
    </div>
  )
}

export default Details
