import get from 'lodash/get'

interface Cell {
  columnKey: string
  content: string | boolean | number
}

interface Row {
  id: string
  cells: Cell[]
}

export const getNormalizedCellContent = (columnKey: string, row: Row): string | number => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )
  if (!cellContent) {
    return ''
  }
  switch (columnKey) {
    case 'name':
      return (cellContent as string).toLowerCase()
    case 'count':
      return cellContent === '----' ? 0 : +cellContent
    case 'active':
      return cellContent === '----' ? 0 : 1
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}
