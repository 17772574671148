import { Component } from 'react'
import s from '../styles/BudgetsList.scss'
import { If } from 'simple-core-ui'
import BudgetRequestsListContainer from './BudgetRequestsListContainer'
import BudgetsList from 'budgets/list_v2'
import { BUDGET_TABS, ACTIVE_BUDGETS_TAB } from '../constants'
import { hasModule } from 'utils/helpers'

import TabContext from 'context/TabContext'

const generateBudgetTabs = ({ updateTab, selectedTab }) =>
  BUDGET_TABS.map(tab => ({
    text: tab.label,
    isSelected: tab.value === selectedTab,
    onClick: () => updateTab(tab.value)
  }))

class BudgetsListContainer extends Component {
  state = { isModalOpen: false, selectedTab: ACTIVE_BUDGETS_TAB.value }

  updateTab = tab => {
    this.setState({
      selectedTab: tab
    })
  }

  render() {
    const { selectedTab } = this.state

    const BudgetsListComponent = <BudgetsList />

    if (!hasModule('has_budget_requests')) {
      return BudgetsListComponent
    }

    return (
      <div>
        <TabContext.Consumer>
          {updateTabs =>
            updateTabs && updateTabs(generateBudgetTabs({ updateTab: this.updateTab, selectedTab }))
          }
        </TabContext.Consumer>

        <div className={s.tabBody}>
          <If condition={selectedTab === 'budget_requests'}>
            <BudgetRequestsListContainer />
          </If>
          <If condition={selectedTab === 'active_budgets'}>{BudgetsListComponent}</If>
        </div>
      </div>
    )
  }
}

export default BudgetsListContainer
