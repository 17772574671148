import { useEffect, useState } from 'react'
import { ModalContainer } from 'simple-core-ui'
import s from './ResubmissionHistory.scss'
import { useLocation } from 'react-router-dom'

interface ResubHistory {
  id: number
  invoice_date: string
  invoice_number: string
  received_date: string
  rejected_date?: string
  status: string
  resubmission_display_name: string
}

const historyItems: ResubHistory[] = window.serverContext.get('resubmission_history') || []

const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-')

  return `${month}/${day}/${year}`
}

const ResubmissionHistory = () => {
  const location = useLocation()
  const [showHistory, setShowHistory] = useState(false)

  useEffect(() => {
    //prevents history modal from staying open on back redirection
    setShowHistory(false)
  }, [location.pathname])

  if (!historyItems.length) return null

  const listItems = historyItems.map(item => (
    <div className={s.listItemContainer} key={item.id}>
      <div className={s.data}>
        <p className={s.historyItemType}>{item.resubmission_display_name}</p>
        <p className={s.invoiceNumber}>
          Invoice number - <b>{item.invoice_number}</b>
        </p>
        <div className={s.dates}>
          <div className={s.dateItem}>
            <label>Invoice Date</label>
            <p>{formatDate(item.invoice_date)}</p>
          </div>
          <div className={s.dateItem}>
            <label>Received Date</label>
            <p>{formatDate(item.received_date)}</p>
          </div>
          <div className={s.dateItem}>
            <label>Rejection Date</label>
            <p>{item.rejected_date ? formatDate(item.rejected_date) : 'N/A'}</p>
          </div>
        </div>
      </div>
      <a className={s.viewInvoice} href={`/invoices/${item.id}`}>
        View
      </a>
    </div>
  ))

  return (
    <div className={s.container}>
      <span className={s.button} onClick={() => setShowHistory(true)}>
        Re-submission history
      </span>
      {showHistory ? (
        <ModalContainer
          title={<div className={s.modalHeader}>Resubmission history</div>}
          content={listItems}
          cancelCb={() => setShowHistory(false)}
          cancelText="Close"
          hasNewButtons
        />
      ) : null}
    </div>
  )
}

export default ResubmissionHistory
