import { Fragment, useMemo, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { Button } from 'simple-core-ui'
import { getNumberOfConditions, isConditionAiPowered } from 'simple_review/utils/helpers'
import { LogicalOperator } from 'simple_review/@types/common'
import { Condition as ConditionType } from 'simple_review/@types/editor'
import { getBaseCondition } from '../constants'
import { isConditionGroup, getParentCondition, changeConditionIds } from './helpers'
import { ConditionGroup } from './condition-group'
import { Condition } from './condition'
import { Op } from './op'
import s from './Conditions.scss'
import { AiCondition } from './ai-condition'

// This represents the minimum conditions that need to be SETUP (SETUP != SELECTED) to allow grouping
const MIN_CONDITIONS_TO_GROUP = 3

interface Props {
  condition: ConditionType
  ruleName: string
  isReadOnly: boolean
  onChangeConditions(newCondition: ConditionType): void
  onAddCondition(index?: number): void
}

const Conditions = ({
  condition,
  ruleName,
  isReadOnly,
  onChangeConditions,
  onAddCondition
}: Props) => {
  const [conditionsToGroup, setConditionsToGroup] = useState<Array<string>>([])

  const shouldShowGrouping = useMemo(
    () => getNumberOfConditions(condition) >= MIN_CONDITIONS_TO_GROUP,
    [condition]
  )

  const handleChangeCondition = (index: number, parentIndex?: number) => (
    newCondition: ConditionType
  ) => {
    const newConditions = cloneDeep(condition)
    const parentCondition = getParentCondition(newConditions, parentIndex)
    parentCondition.operands[index] = newCondition
    onChangeConditions(newConditions)
  }

  const handleCopyCondition = (index: number, parentIndex?: number) => {
    const newConditions = cloneDeep(condition)
    const parentCondition = getParentCondition(newConditions, parentIndex)
    const conditionToCopy = cloneDeep(parentCondition.operands[index])
    changeConditionIds(conditionToCopy)
    parentCondition.operands.splice(index + 1, 0, conditionToCopy)
    onChangeConditions(newConditions)
  }

  const handleRemoveCondition = (index: number, parentIndex?: number) => (
    conditionToRemoveId: string
  ) => {
    const newConditions = cloneDeep(condition)
    const parentCondition = getParentCondition(newConditions, parentIndex)
    const conditionToRemoveIndex = parentCondition.operands.findIndex(
      item => item.id === conditionToRemoveId
    )

    if (parentCondition.operands.length > 1) {
      parentCondition.operands.splice(conditionToRemoveIndex, 1)
      if (parentCondition.operands.length === 1 && parentIndex !== undefined) {
        newConditions.operands.splice(index, 1, parentCondition.operands[0])
      }
    } else {
      parentCondition.operands.splice(index, 1)
    }
    onChangeConditions(newConditions)
  }

  const handleChangeOp = (newOp: LogicalOperator) => {
    const newConditions = cloneDeep(condition)
    newConditions.op = newOp
    onChangeConditions(newConditions)
  }

  const handleToggleGroupCheck = (conditionId: string) => {
    setConditionsToGroup(prev => {
      if (prev.includes(conditionId)) {
        return prev.filter(id => id !== conditionId)
      }
      return [...prev, conditionId]
    })
  }

  const handleGroup = () => {
    const newConditions = cloneDeep(condition)
    const newParentCondition = getBaseCondition()
    const groupedConditions: Array<ConditionType> = []
    const indexes: Array<number> = []
    for (const conditionId of conditionsToGroup) {
      const index = newConditions.operands.findIndex(item => item.id === conditionId)
      indexes.push(index)
    }
    indexes.sort((a, b) => (a > b ? -1 : 1)) // So the conditions stay in the same order they were created and not the order they were selected
    indexes.forEach(index => {
      groupedConditions.push(newConditions.operands.splice(index, 1)[0] as ConditionType)
    })
    newParentCondition.operands = groupedConditions.reverse()
    newConditions.operands.splice(indexes.reverse()[0], 0, newParentCondition)
    onChangeConditions(newConditions)
    setConditionsToGroup([])
  }

  const handleUngroup = (index: number, childIndex?: number) => {
    const newConditions = cloneDeep(condition)
    const group = newConditions.operands[index] as ConditionType
    // If a valid numeric value was passed for childIndex then ungroup only the condition at that index
    if (childIndex !== undefined) {
      const ungroupedCondition = group.operands.splice(childIndex, 1)[0]
      newConditions.operands.splice(index + 1, 0, ungroupedCondition)
      // If there's only one grouped condition after ungrouping then ungroup that as well
      if (group.operands.length === 1) {
        newConditions.operands[index] = group.operands[0]
      }
      // Ungroup the whole group otherwise
    } else {
      const ungroupedConditions = [...group.operands]
      newConditions.operands.splice(index, 1, ...ungroupedConditions)
    }
    onChangeConditions(newConditions)
  }

  return (
    <div className={s.container}>
      {!isReadOnly && conditionsToGroup.length > 0 && (
        <Button
          onClick={handleGroup}
          isDisabled={conditionsToGroup.length === 1}
          style={{ alignSelf: 'flex-end', marginBottom: 24 }}
        >
          Group Conditions
        </Button>
      )}
      {condition.operands.map((operand, index) => {
        const isLast = condition.operands.length - 1 === index

        return (
          <Fragment key={operand.id}>
            {isConditionGroup(operand as ConditionType) ? (
              <ConditionGroup
                conditionGroup={operand as ConditionType}
                isReadOnly={isReadOnly}
                onChangeConditionGroup={handleChangeCondition(index)}
                onCopyConditionGroup={() => handleCopyCondition(index)}
                onRemoveConditionGroup={handleRemoveCondition(index)}
                onUngroup={() => handleUngroup(index)}
              >
                {(subCondition, subConditionIndex) => (
                  <Condition
                    condition={subCondition}
                    shouldShowInfo={shouldShowGrouping && subConditionIndex === 0}
                    shouldShowGrouping={shouldShowGrouping}
                    isCheckedToGroup
                    isGray={false}
                    isReadOnly={isReadOnly}
                    ruleName={ruleName}
                    onChangeCondition={handleChangeCondition(subConditionIndex, index)}
                    onCopyCondition={() => handleCopyCondition(subConditionIndex, index)}
                    onRemoveCondition={() =>
                      handleRemoveCondition(subConditionIndex, index)(subCondition.id)
                    }
                    onToggleGroupCheck={() => handleUngroup(index, subConditionIndex)}
                  />
                )}
              </ConditionGroup>
            ) : isConditionAiPowered(operand) ? (
              <AiCondition ruleName={ruleName} />
            ) : (
              <Condition
                condition={operand as ConditionType}
                shouldShowInfo={index === 0}
                shouldShowGrouping={shouldShowGrouping}
                isCheckedToGroup={conditionsToGroup.includes(operand.id)}
                isGray={false}
                isReadOnly={isReadOnly}
                ruleName={ruleName}
                onChangeCondition={handleChangeCondition(index)}
                onCopyCondition={() => handleCopyCondition(index)}
                onRemoveCondition={handleRemoveCondition(index)}
                onToggleGroupCheck={() => handleToggleGroupCheck(operand.id)}
              />
            )}
            {!isLast && (
              <Op
                value={condition.op}
                isReadOnly={isConditionAiPowered(operand)}
                onChangeOp={handleChangeOp}
                onAddConditionBtwn={() => onAddCondition(index)}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default Conditions
