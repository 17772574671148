import s from './CustomReports.scss'
import { Tabs, Tab, Panel } from 'simple-core-ui'
import { CategoryContainer } from '../common/Category'
import { hasModule } from 'utils/helpers'

const CustomReports = ({ customReportsV2, exportedReports }) => {
  const showCustomReportsV2 = hasModule('has_custom_reports_v2')
  const [hasCustomReportsV2, hasExportedReportsV2] = [
    !!Object.values(customReportsV2).length,
    !!Object.values(exportedReports).length
  ]
  return (
    <Panel title="Built for You" styles={{ boxShadow: 'none' }} className={s.reports}>
      <Tabs alignRight>
        <Tab header="Version 1">
          <div className={s.reportWrapper}>
            <a className={s.name} href="/reports/custom" target="_blank">
              Version 1
            </a>
            <p className={s.description}>Standard list and custom reports</p>
          </div>
        </Tab>
        <Tab isVisible={showCustomReportsV2} header="Version 2">
          {hasCustomReportsV2 ? (
            <CategoryContainer reports={customReportsV2} />
          ) : (
            <p className={s.notAvailable}>
              There are no Custom Reports enabled. Please visit{' '}
              <a href="/reports/v2/custom/management/">Custom Reports V2 Settings</a>
            </p>
          )}
        </Tab>

        <Tab isVisible={showCustomReportsV2} header="Exported Reports">
          {hasExportedReportsV2 ? (
            <CategoryContainer reports={exportedReports} showStatus status="processing" />
          ) : (
            <p className={s.notAvailable}>There are no Exported Reports enabled.</p>
          )}
        </Tab>
      </Tabs>
    </Panel>
  )
}

export default CustomReports
