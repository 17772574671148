import get from 'lodash/get'

export const getNormalizedCellContent = (columnKey, row) => {
  const { cells } = row
  const cellContent = get(
    cells.find(cell => cell.columnKey === columnKey),
    'content',
    ''
  )

  if (!cellContent) {
    return cellContent
  }

  switch (columnKey) {
    case 'name':
      return cellContent.toLowerCase()
    case 'related_documents':
      return +cellContent
    default:
      console.error(`Attempting to sort on unknown columnKey: ${columnKey}`)
      return ''
  }
}

const compareName = (name1, name2) => name1.trim().toLowerCase() === name2.trim().toLowerCase()

export const checkCategoryInCategories = (category, categories) =>
  categories.some(c => category && compareName(c.name, category))

export const checkLabelInLabels = (label, labels) =>
  labels.some(c => label && compareName(c.name, label))
