import s from './Warnings.scss'
import { FaInfoCircle } from 'react-icons/fa'

interface Props {
  hasInvalidAssignees: boolean
}

const Warnings = ({ hasInvalidAssignees }: Props) => {
  return (
    <div className={s.container}>
      <p style={{ marginBottom: 10 }}>
        <FaInfoCircle className={s.icon} />
        <b>We&apos;ll resolve these template conflicts for you:</b>
      </p>
      {hasInvalidAssignees && (
        <p>
          <b>Task Assignees: </b>This template has invalid assignees. They will be deleted from
          tasks. Assign new values after creating tasks
        </p>
      )}
    </div>
  )
}

export default Warnings
