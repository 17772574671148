import axios from 'axios'

export const attachmentUploaderFactory = (files, matterRequestId, uploadEndpoint) => {
  const attachmentUploader = {
    files: files,
    matterRequestId: matterRequestId,
    uploadEndpoint: uploadEndpoint,
    noOfFiles: files.length,
    fileData: {},
    requestCall: function requestCall(type, endpoint, data) {
      return axios({
        method: type,
        url: endpoint,
        data: data
      })
    },
    getUploadParams: function getUploadParams(endpoint, filename) {
      return this.requestCall('GET', endpoint, filename)
    },
    s3UploadFile: function s3UploadFile(endpoint, data) {
      return this.requestCall('POST', endpoint, data)
    },
    buildS3Data: function buildS3Data(response, file) {
      let fd = new FormData()

      fd.append('key', response.key)
      fd.append('AWSAccessKeyId', response.AWSAccessKeyId)
      fd.append('acl', 'private')
      fd.append('policy', response.policy)
      fd.append('signature', response.signature)
      fd.append('x-amz-meta-name', file.name)
      fd.append('x-amz-meta-size', file.size)
      fd.append('x-amz-meta-type', file.type)
      fd.append('x-amz-meta-upload-source', document.domain)
      fd.append('Content-Type', file.type)
      fd.append('file', file)

      return fd
    },
    uploadAttachment: function uploadAttachment(uploadParams, s3UploadError, file) {
      var _this = this
      this.getUploadParams(uploadParams, file.name).then(function(response) {
        var data = _this.buildS3Data(response.data, file)
        _this
          .s3UploadFile(response.data.AWS_S3_BUCKET_URL, data)
          .then(function() {
            var fileInfo = {
              name: file.name,
              size: file.size,
              type: file.type
            }
            _this.fileData[response.data.key] = fileInfo

            _this.noOfFiles--

            if (_this.noOfFiles === 0) {
              _this.uploadComplete()
            }
          })
          .catch(function() {
            var s3ErrorMessage = window.event.currentTarget.responseText
            _this.uploadError(file, s3UploadError, s3ErrorMessage)
          })
      })
    },
    uploadAttachments: function uploadAttachments() {
      var uploadParams = window.s3ParamsEndpoint,
        s3UploadError = window.s3UploadErrorEndpoint,
        _this = this
      files.forEach(function(file) {
        _this.uploadAttachment(uploadParams, s3UploadError, file)
      })
    },
    uploadComplete: function uploadComplete() {
      var data = {}

      data['files'] = this.fileData
      data['matter_request_id'] = this.matterRequestId

      return this.requestCall('POST', this.uploadEndpoint, JSON.stringify(data))
    },
    uploadError: function(file, s3UploadError, s3ErrorMessage) {
      const data = {
        filename: file.name,
        s3errMsg: s3ErrorMessage
      }
      this.requestCall('POST', s3UploadError, data).then(function() {
        alert(
          'Sorry, ' +
            file.name +
            ' failed to upload\nWe will resolve this issue A.S.A.P.\nIf you experience any other issues please contact help@simplelegal.com'
        )
      })
    }
  }

  return attachmentUploader
}
