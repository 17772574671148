import s from './NoResults.scss'

const NoResults = () => (
  <div className={s.container}>
    <p className={s.title}>No Results for Filter/Compare Selections</p>
    <p className={s.description}>
      There are no completed surveys for the filter/compare selections
    </p>
  </div>
)

export default NoResults
