import { LoadingSkeleton, Grid } from 'simple-core-ui'
import s from './InvoiceKpiSkeleton.scss'

const InvoiceKpiSkeleton = ({ itemsNumber }) => {
  const { Row, Column } = Grid

  const getSkeletons = () => {
    let kpiSkeletons = []
    for (let i = 0; i < itemsNumber; i++) {
      kpiSkeletons = [
        ...kpiSkeletons,
        <Column key={i} span={4} breakpoint="laptop">
          <section className={s.tile}>
            <Row>
              <Column span={6} breakpoint="laptop">
                <div className={s.rect}>
                  <LoadingSkeleton height={44} width="100%" style={{ borderRadius: 2 }} />
                </div>
              </Column>
              <Column span={6} breakpoint="laptop">
                <div>
                  <LoadingSkeleton height={18} width={80} style={{ marginBottom: 4 }} />
                  <LoadingSkeleton height={23} width={110} />
                </div>
              </Column>
            </Row>
          </section>
        </Column>
      ]
    }
    return kpiSkeletons
  }
  return <Row>{getSkeletons()}</Row>
}

export default InvoiceKpiSkeleton
