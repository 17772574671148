import { BaseGuard } from './types'

/**
 * Represents a guard that controls the user's access to a route depending on their role.
 * @param {...string} roles - A list of roles.
 */
export default function RoleGuard(...roles: Array<Role>): BaseGuard {
  return {
    canRoute() {
      const userRole = window.credentials.user.role
      return roles.includes(userRole)
    }
  }
}
