import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Location } from '../../types'
import s from './ActionsPopover.scss'

interface Props {
  deleteLocation: () => void
  toggleDraft: () => void
  onEdit?: (value: Location) => void
  location: Location | null
}

const ActionsPopover = ({ deleteLocation, toggleDraft, location, onEdit }: Props) => {
  const actions = [
    {
      name: 'EDIT',
      text: 'Edit',
      onClick() {
        if (location) {
          onEdit?.(location)
        } else {
          toggleDraft()
        }
      }
    },
    {
      name: 'DELETE',
      text: 'Delete',
      onClick() {
        deleteLocation()
      }
    }
  ]

  return (
    <Popover
      trigger={
        <HoverAction hasNewDesign icon={<IoIosMore />} size="xsmall" className={s.hoverAction} />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
