import { Component } from 'react'
import { Header } from './Header'
import { DashboardContainer } from './Dashboard'
import { Questions } from './Questions'
import { Filters } from './Filters'
import { Comparisons } from './Comparisons'
import { connect } from 'react-redux'
import ACT from './actions'
import { withRouter } from 'simple-core-ui/hocs'
import { openLink } from 'utils/helpers'
import moment from 'moment'

@withRouter
@connect(({ scorecard }) => {
  const {
    currentVendor,
    questions,
    comments,
    compareQuestions,
    practiceAreas,
    vendors,
    additionalCommentsResponses
  } = scorecard
  return {
    currentVendor,
    questions,
    comments,
    compareQuestions,
    practiceAreas,
    vendors,
    additionalCommentsResponses
  }
})
class VendorScorecardContainer extends Component {
  state = {
    count: 0,
    filters: {
      dateRange: {
        start: null,
        end: null
      },
      practiceArea: null
    },
    comparisons: {
      against: null,
      dateRange: {
        start: null,
        end: null
      },
      practiceArea: null,
      vendor: null
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: ACT.QUESTIONS_FETCH_REQUESTED,
      payload: {
        id: this.props.router.params.vendorId
      }
    })
  }
  onDatesChange = (value, property) => {
    this.setState(
      {
        [property]: {
          ...this.state[property],
          dateRange: {
            start: value.start,
            end: value.end
          }
        }
      },
      () => {
        if (property === 'filters') {
          this.onFiltersChange()
        } else {
          this.onComparisonsChange()
        }
      }
    )
  }
  onPracticeAreaChange = (value, property) => {
    this.setState(
      {
        [property]: {
          ...this.state[property],
          practiceArea: value
        }
      },
      () => {
        if (property === 'filters') {
          this.onFiltersChange()
        } else {
          this.onComparisonsChange()
        }
      }
    )
  }
  onVendorChange = value => {
    this.setState(
      {
        comparisons: {
          ...this.state.comparisons,
          vendor: value
        }
      },
      () => {
        this.onComparisonsChange()
      }
    )
  }
  onComparisonTypeChange = async value => {
    await this.onClearComparisons()

    this.setState({
      comparisons: {
        ...this.state.comparisons,
        against: value
      }
    })
  }
  onClearFilters = () => {
    this.setState(
      {
        filters: {
          dateRange: {
            start: null,
            end: null
          },
          practiceArea: null
        }
      },
      () => {
        this.onFiltersChange()
      }
    )
  }
  onClearComparisons = () => {
    this.setState(
      {
        comparisons: {
          against: null,
          dateRange: {
            start: null,
            end: null
          },
          practiceArea: null,
          vendor: null
        }
      },
      () => {
        this.onComparisonsChange()
      }
    )
  }
  onShowQuestionAnswers = (questionId, index) => {
    const { id } = this.props.currentVendor
    const { filters } = this.state
    const answers = this.props.questions[index].answers

    if (answers && answers.length) return false

    this.props.dispatch({
      type: ACT.QUESTION_ANSWERS_FETCH_REQUESTED,
      payload: {
        questionId,
        vendorId: id,
        questionIndex: index,
        filters
      }
    })
  }
  onShowAdditionalComments = () => {
    const { id } = this.props.currentVendor
    const { filters } = this.state
    this.props.dispatch({
      type: ACT.ADDITIONAL_COMMENTS_FETCH_REQUESTED,
      payload: {
        vendorId: id,
        filters
      }
    })
  }
  increment = () => {
    this.setState({
      count: this.state.count + 1
    })
  }
  onFiltersChange = () => {
    const { id } = this.props.currentVendor
    const { filters } = this.state

    this.increment()

    this.props.dispatch({
      type: ACT.FILTERED_QUESTIONS_REQUESTED,
      payload: {
        vendorId: id,
        filters
      }
    })
  }
  onComparisonsChange = () => {
    const { id } = this.props.currentVendor
    const { comparisons } = this.state

    this.props.dispatch({
      type: ACT.COMPARE_QUESTIONS_REQUESTED,
      payload: {
        vendorId: id,
        comparisons
      }
    })
  }

  downloadSurveyXlsx = async () => {
    const { filters } = this.state
    openLink(
      `/manage/matters/evaluations/export_surveys/${
        this.props.router.params.vendorId
      }/?date_start=${moment(filters.dateRange.start).toISOString() ?? ''}&date_end=${moment(
        filters.dateRange.end
      ).toISOString() ?? ''}&practice_area=${filters.practiceArea?.value ?? ''}`
    )
  }
  downloadComparisonXlsx = async () => {
    const { comparisons, filters } = this.state
    openLink(
      `/manage/matters/evaluations/export_surveys/compare/${
        this.props.router.params.vendorId
      }/?date_start=${moment(filters.dateRange.start).toISOString() ?? ''}&date_end=${moment(
        filters.dateRange.end
      ).toISOString() ?? ''}&practice_area=${filters.practiceArea?.value ??
        ''}&compare_date_start=${moment(comparisons.dateRange.start).toISOString() ??
        ''}&compare_date_end=${moment(comparisons.dateRange.end).toISOString() ??
        ''}&compare_practice_area=${comparisons.practiceArea?.value ??
        ''}&compare_vendor_id=${comparisons.vendor?.value ?? ''}`
    )
  }
  render() {
    const { filters, comparisons, count } = this.state
    const {
      additionalCommentsResponses,
      currentVendor,
      questions,
      comments,
      compareQuestions,
      vendors,
      practiceAreas
    } = this.props
    return (
      <section>
        <Header
          vendorId={currentVendor.id}
          vendorName={currentVendor.name}
          downloadSurveyXlsx={this.downloadSurveyXlsx}
          downloadComparisonXlsx={this.downloadComparisonXlsx}
        />
        <DashboardContainer />
        <Filters
          practiceAreas={practiceAreas}
          filters={filters}
          onClearFilters={this.onClearFilters}
          onDatesChange={this.onDatesChange}
          onPracticeAreaChange={this.onPracticeAreaChange}
        />
        <Comparisons
          practiceAreas={practiceAreas}
          vendors={vendors}
          comparisons={comparisons}
          onClearComparisons={this.onClearComparisons}
          onDatesChange={this.onDatesChange}
          onPracticeAreaChange={this.onPracticeAreaChange}
          onVendorChange={this.onVendorChange}
          onComparisonTypeChange={this.onComparisonTypeChange}
        />
        <Questions
          count={count}
          onShowQuestionAnswers={this.onShowQuestionAnswers}
          onShowAdditionalComments={this.onShowAdditionalComments}
          questions={questions}
          comments={comments}
          currentVendorName={currentVendor.name}
          commentsNumber={additionalCommentsResponses}
          filters={filters}
          comparisons={comparisons}
          compareQuestions={compareQuestions}
        />
      </section>
    )
  }
}

export default VendorScorecardContainer
