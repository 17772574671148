import { useDispatch } from 'react-redux'
import { Tag, If } from 'simple-core-ui'
import APP_ACT from 'app/actions'

import { IoIosClose } from 'react-icons/io'

import ScopeContext from 'context/ScopeContext'
import { ConfirmableContainer } from 'containers'
import Contact from '../../Contact/Contact'
import { UnassignText } from 'contacts/UnassignText'

import {
  containsCounselGo,
  isCounselGo,
  hasMatterLead,
  isMatterClosed,
  isMatterLead,
  isMatterOpen
} from '../../utils'

import s from './AssignedContact.scss'

const AssignedContact = ({
  contact,
  unassignContactCb,
  unassignRoleCb,
  setContactToUpdateCb,
  isEditable,
  matterStatus,
  userCanEditContactCG,
  readOnly,
  openPromptForLead
}) => {
  const dispatch = useDispatch()

  const showMatterLeadNotDeletableWarning = () => {
    dispatch({
      type: APP_ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'The matter lead cannot be removed from a closed matter.',
        level: 'error'
      }
    })
  }

  const unassignRole = role =>
    isMatterClosed(matterStatus) && isMatterLead(role)
      ? showMatterLeadNotDeletableWarning()
      : unassignRoleCb(contact, role.id)

  const deleteContact = openPrompt => {
    if (isMatterClosed(matterStatus) && hasMatterLead(contact.roles))
      return showMatterLeadNotDeletableWarning
    if (hasMatterLead(contact.roles) && isMatterOpen(matterStatus)) return openPromptForLead
    return openPrompt
  }

  return (
    <section className={s.container}>
      <ConfirmableContainer
        promptText={<UnassignText removesCounselGo={containsCounselGo(contact.roles)} />}
        confirmCallback={() => unassignContactCb(contact)}
        isEditable={isEditable}
        render={openPrompt => (
          <section
            className={`${s.contact} ${isEditable ? '' : s.notEditable} ${
              contact.active ? '' : s.inactive
            }`}
            data-testid={contact.firstName + '_' + contact.lastName + '_section'}
          >
            <ScopeContext.Consumer>
              {({ scope, scopeId }) => (
                <Contact
                  firstName={contact.firstName}
                  lastName={contact.lastName}
                  title={contact.title}
                  company={contact.org ? contact.org.name : ''}
                  phone={contact.phone}
                  email={contact.email}
                  editCb={() => setContactToUpdateCb(contact)}
                  isEditable={isEditable && !readOnly}
                  active={contact.active}
                  url={`/v2/contacts/profile/${contact.id}?scope=${scope}&scopeId=${scopeId}`}
                />
              )}
            </ScopeContext.Consumer>
            <section className={s.tagGroup}>
              {contact.roles.map(role =>
                isCounselGo(role) ? (
                  <Tag key={role.id} color="red">
                    {role.label}
                  </Tag>
                ) : (
                  <Tag
                    key={role.id}
                    color={role.singular ? 'blue' : 'black'}
                    deleteCb={
                      contact.roles.length > 1 && isEditable ? () => unassignRole(role) : null
                    }
                  >
                    {role.label}
                  </Tag>
                )
              )}

              <If condition={!contact.active}>
                <div data-testid="inactive_user" className={s.inactiveText}>
                  {'This user is inactive. '}
                  <span className={s.drainText} onClick={openPrompt}>
                    {'Click here '}
                  </span>
                  {'to remove.'}
                </div>
              </If>
            </section>
            {isEditable && userCanEditContactCG && (
              <IoIosClose
                className={s.delete}
                data-testid={'contact_delete_button'}
                onClick={deleteContact(openPrompt)}
              />
            )}
          </section>
        )}
      />
    </section>
  )
}

export default AssignedContact
