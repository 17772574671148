import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Panel, SwitchToggle } from 'simple-core-ui'
import s from './RejectionReasonPreference.scss'
import { makeGetRequest, makePostRequest } from 'utils/api'
import APP_ACT from 'app/actions'
import { Preference, Settings } from 'invoice_settings/types'
import { TITLE } from 'invoice_settings/constants'

const BASE_URL = '/invoices/rejection/settings/'

const RejectionReasonPreference = () => {
  const [preferenceSettings, setPreferenceSettings] = useState<Preference | []>([])

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchPreferenceSettings = async () => {
      try {
        const { settings } = await makeGetRequest(BASE_URL)
        setPreferenceSettings(settings || [])
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching Preference Settings.',
            level: 'error'
          }
        })
      }
    }

    fetchPreferenceSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeSettingValue = async (name: string, value: boolean) => {
    try {
      await makePostRequest(`${BASE_URL}update/`, { name, value })
      const settings = preferenceSettings.map((setting: Settings) => ({
        ...setting,
        ...(setting.name === name ? { value } : {})
      }))
      setPreferenceSettings(settings)
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Setting edited successfully',
          level: 'success'
        }
      })
    } catch (error) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: 'There was an issue editing the Preference Settings.',
          level: 'error'
        }
      })
    }
  }

  return (
    <section>
      {preferenceSettings.length ? (
        <Panel
          key="preference-settings"
          title={TITLE}
          styles={{ boxShadow: 'none', marginBottom: 48 }}
        >
          {preferenceSettings.map(setting => (
            <div key={setting.name} className={s.settingsContainer}>
              <section className={s.settingTitle}>
                <h2>{setting.label}</h2>
                <SwitchToggle
                  checked={setting.value}
                  onToggle={checked => changeSettingValue(setting.name, checked)}
                  className={s.test}
                />
              </section>
              <section>{setting.description}</section>
            </div>
          ))}
        </Panel>
      ) : null}
    </section>
  )
}

export default RejectionReasonPreference
