import moment from 'moment'

export const toEvent = event => {
  return {
    event_id: event.id,
    name: event.title,
    description: event.description,
    event_time: moment(
      moment(event.startDate).format('MM/DD/YYYY') + ' ' + event.startTime.value,
      'MM/DD/YYYY HH:mm A'
    ).toDate()
  }
}

export const fromEvents = events => {
  return events.map(e => {
    return {
      id: e.id,
      title: e.title,
      description: e.description,
      startDate: moment(e.time).toDate(),
      startTime: moment(e.time).format('h:mm A'),
      endDate: moment(e.time).toDate(),
      endTime: moment(e.time).format('h:mm A')
    }
  })
}

export const fromCalendarList = events =>
  events.map(e => ({
    id: e.id,
    title: e.title,
    startDate: moment(e.start).toDate(),
    endDate: moment(e.start).toDate()
  }))

export const toCalendarListEvent = event => ({
  id: event.id,
  title: event.name,
  startDate: moment(event.event_time).toDate(),
  endDate: moment(event.event_time).toDate()
})
