import SelectFilter from './SelectFilter'
import { getCustomSelectLabel } from '../helpers'
import { RULE_SELECT_URL } from 'common/Selects/urls'
import { PaginatedSelect } from 'simple-core-ui'

const RuleFilter = ({
  title = 'Rule Name',
  onSelect,
  reset,
  initialValue,
  limit,
  hasConfirmation,
  filterOption,
  category
}) => {
  return (
    <SelectFilter
      title={title}
      initialValue={initialValue}
      onSelect={onSelect}
      reset={reset}
      customLabel={selected => getCustomSelectLabel(selected, title)}
      hasConfirmation={hasConfirmation}
      isMulti
      render={(rules, setRule) => {
        return (
          <PaginatedSelect
            url={RULE_SELECT_URL}
            value={rules}
            onChange={setRule}
            searchTermParam="search"
            isMulti
            serializer={response => {
              return response.map(r => ({ label: r.name, value: r.id }))
            }}
            defaultOptions={null}
            resultsProperty="rows"
            isClearable={true}
            placeholder="Search rules..."
            limit={limit}
            filterOption={filterOption}
            isDisabled={false}
            mandatoryQueryParams={{
              engine: 'invoice_validation',
              columnKey: 'name',
              isDesc: 0,
              ...(category === 'All Rules'
                ? {}
                : {
                    is_draft: category === 'Active' ? 0 : 1
                  })
            }}
          />
        )
      }}
    />
  )
}

export default RuleFilter
