import s from './Filters.scss'
import cn from 'classnames'
import { ContactsFilters, Role, LabelIds, Option } from '../types'
import { extractCgRoles, extractLabels } from '../utils'
import Filter from './Filter/Filter'
import { useMemo } from 'react'
import { SCOPE } from 'utils/constants'
import { CheckboxList, DropdownPopper, Users } from 'simple-core-ui'

interface Props {
  className?: string
  contactFilters: ContactsFilters
  setContactFilters: (filters: ContactsFilters) => void
  clearFilters: () => void
  roles: Role[]
  vendorId: number
}

const serializeRoles = (rolesList: Role[]) =>
  rolesList.map(role => ({ id: role.id, name: role.label }))

const Filters = ({
  className,
  contactFilters,
  setContactFilters,
  clearFilters,
  roles,
  vendorId
}: Props) => {
  const cgRolesOptions = useMemo(() => serializeRoles(extractCgRoles(roles)), [roles])

  const labelsOptions = useMemo(() => serializeRoles(extractLabels(roles)), [roles])

  return (
    <div className={cn(s.filters, className)}>
      <Filter
        label="First Name"
        value={contactFilters.firstName || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, firstName: value })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select first name(s)"
            label="firstName"
          >
            <Users
              value={(filters?.values as Option[]) ?? []}
              style={{ width: '400px' }}
              requestParams={{
                vendorId: vendorId,
                active: true,
                giveFloatingUsers: true,
                filterFields: 'fn'
              }}
              onConfirm={setFilters}
              scope={SCOPE.VENDOR}
              filterKey="given_name"
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Last Name"
        value={contactFilters.lastName || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, lastName: value })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper
            value={filters?.values ?? []}
            placeholder="Select last name(s)"
            label="lastName"
          >
            <Users
              value={(filters?.values as Option[]) ?? []}
              style={{ width: '400px' }}
              requestParams={{
                vendorId: vendorId,
                active: true,
                giveFloatingUsers: true,
                filterFields: 'ln'
              }}
              onConfirm={setFilters}
              scope={SCOPE.VENDOR}
              filterKey="family_name"
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Email"
        value={contactFilters.email || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, email: value })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper value={filters?.values ?? []} placeholder="Select email(s)" label="email">
            <Users
              value={(filters?.values as Option[]) ?? []}
              style={{ width: '400px' }}
              requestParams={{
                vendorId: vendorId,
                active: true,
                giveFloatingUsers: true,
                filterFields: 'email'
              }}
              onConfirm={setFilters}
              scope={SCOPE.VENDOR}
              filterKey="primary_email"
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="CounselGO Roles"
        value={contactFilters.roleIds || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, roleIds: value })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper value={filters?.values ?? []} placeholder="Select role(s)" label="role">
            <CheckboxList
              value={filters?.values ?? []}
              onConfirm={setFilters}
              options={cgRolesOptions}
            />
          </DropdownPopper>
        )}
      </Filter>
      <Filter
        label="Labels"
        value={contactFilters.labelIds || null}
        onConfirm={(value, cb) => {
          setContactFilters({ ...contactFilters, labelIds: value as LabelIds })
          cb?.()
        }}
        labelPosition="right"
      >
        {({ filters, setFilters }) => (
          <DropdownPopper value={filters?.values ?? []} placeholder="Select label(s)" label="label">
            <CheckboxList
              value={filters?.values ?? []}
              onConfirm={setFilters}
              options={labelsOptions}
            />
          </DropdownPopper>
        )}
      </Filter>
      <div className={s.reset} onClick={clearFilters}>
        Reset
      </div>
    </div>
  )
}

export default Filters
