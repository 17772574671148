import s from './ChartInfo.scss'

const ChartInfo = ({ status, total, error }) => {
  return (
    <div className={s.chartInfo}>
      {status === 'processing' || status === 'submitted' ? (
        <p>Your edits are now processing. The status of this update shows under Job History.</p>
      ) : status === 'failed' ? (
        <p>
          {total
            ? `${error} out of ${total} records did not update`
            : 'An internal server error occurred'}
          . To see details on which edits did not complete, download the related error file on the
          Jobs History page.
        </p>
      ) : (
        ''
      )}
    </div>
  )
}

export default ChartInfo
