import * as React from 'react'
import { Button, If } from 'simple-core-ui'

import ScopeContext from 'context/ScopeContext'

import ACT from '../actions'
import { REVIEW_ACTION_LABEL } from '../utils/constants'

import s from '../styles/ReviewLadder.scss'

const ReviewLadderHeader = ({
  onFinalize,
  onApprove,
  onReject,
  onUnapprove,
  onUnreject,
  actionsOnly,
  isFinalApproval
}) => {
  const Actions = () => (
    <ScopeContext.Consumer>
      {({ scope }) => (
        <React.Fragment>
          <If condition={onReject}>
            <Button onClick={onReject} isSecondary testid="rejectInvoice">
              {REVIEW_ACTION_LABEL(scope, ACT.REJECTION_REQUESTED, isFinalApproval)}
            </Button>
          </If>
          <If condition={onApprove}>
            <Button onClick={onApprove} isPrimary testid="approveInvoice">
              {REVIEW_ACTION_LABEL(scope, ACT.APPROVAL_REQUESTED, isFinalApproval)}
            </Button>
          </If>
          <If condition={onUnapprove}>
            <Button onClick={onUnapprove} isSecondary>
              {REVIEW_ACTION_LABEL(scope, ACT.UNAPPROVAL_REQUESTED, isFinalApproval)}
            </Button>
          </If>
          <If condition={onUnreject}>
            <Button onClick={onUnreject} isSecondary>
              {REVIEW_ACTION_LABEL(scope, ACT.UNREJECTION_REQUESTED, isFinalApproval)}
            </Button>
          </If>
          <If condition={onFinalize}>
            <Button onClick={onFinalize} isPrimary>
              {REVIEW_ACTION_LABEL(scope, ACT.FINALIZE_REQUESTED, isFinalApproval)}
            </Button>
          </If>
        </React.Fragment>
      )}
    </ScopeContext.Consumer>
  )

  const Wrapper = ({ children }) => (
    <header className={s.header}>
      <h4 className={s.title}>Approvers</h4>
      <section className={s.buttonGroup}>{children}</section>
    </header>
  )

  return actionsOnly ? (
    <Actions />
  ) : (
    <Wrapper>
      <Actions />
    </Wrapper>
  )
}

export default ReviewLadderHeader
