import React, { useEffect, useState } from 'react'
import ACT from 'tableau/actions'
import { Panel, Loading, Tabs, Tab, TextInput } from 'simple-core-ui'
import { useDispatch, useSelector } from 'react-redux'
import s from './TabReports.scss'
import { capitalize } from 'utils/formatting'

const Item = ({ description, url, name }) => (
  <li className={s.item}>
    <a href={url} title={name} target="_blank" rel="noopener noreferrer" className={s.name}>
      {name}
    </a>
    <p className={s.description}>{description}</p>
  </li>
)

const Category = ({ tab, groupedReports, index }) => {
  return (
    <div className={s.category}>
      <h2 className={s.title}>{capitalize(tab)}</h2>
      <ul className={s.list}>
        {groupedReports[tab] &&
          groupedReports[tab].map((el, j) => {
            return <Item {...el} key={index + '_' + j} />
          })}
      </ul>
    </div>
  )
}

const TabReportsContainer = () => {
  const dispatch = useDispatch()
  const [filterQuery, setFilterQuery] = useState('')
  const reportList = useSelector(state => state.tableau.reports)
  const groupedReports = useSelector(state => state.tableau.groupedReports)
  const isLoading = useSelector(state => state.tableau.isLoading)
  const sortedKeys = useSelector(state => state.tableau.sortedKeys)
  const [filteredPages, setFilteredPages] = useState(reportList)

  useEffect(() => {
    if (!sortedKeys.length) {
      dispatch({ type: ACT.REPORTS_FETCH_REQUESTED })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterPages = value => {
    setFilterQuery(value)
    dispatch({ type: ACT.FILTER_REPORTS_LIST, payload: { query: value } })
  }

  return (
    <Panel title="Tableau Reports" styles={{ boxShadow: 'none' }} className={s.reports}>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {sortedKeys.length ? (
            <Tabs alignRight>
              {[
                <Tab header="All" key={0}>
                  <div className={s.filterContainer}>
                    {'Filter: '}
                    <TextInput
                      placeholder="Search"
                      onChange={filterPages}
                      value={filterQuery}
                      isLoading={isLoading}
                    />
                  </div>
                  {sortedKeys
                    .filter(tab => !!groupedReports[tab])
                    .map((tab, i) => {
                      return (
                        <Category tab={tab} groupedReports={groupedReports} key={i} index={i} />
                      )
                    })}
                </Tab>,
                ...sortedKeys.map((tab, i) => {
                  return (
                    <Tab header={tab} key={i + 1}>
                      <div className={s.filterContainer}>
                        {'Filter: '}
                        <TextInput
                          placeholder="Search"
                          onChange={filterPages}
                          value={filterQuery}
                          isLoading={isLoading}
                        />
                      </div>
                      <Category tab={tab} groupedReports={groupedReports} index={i} />
                    </Tab>
                  )
                })
              ]}
            </Tabs>
          ) : null}
        </React.Fragment>
      )}
    </Panel>
  )
}

export default TabReportsContainer
