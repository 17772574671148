import { Component } from 'react'
import { connect } from 'react-redux'
import { Panel } from 'simple-core-ui'
import s from '../styles/BudgetRequest.scss'

@connect(({ budgets }) => {
  return {
    details: budgets.budgetDetails
  }
})
class BudgetDetailsPanel extends Component {
  render() {
    const { scope, assumptions, risk } = this.props.details

    return (
      <Panel title="Overview">
        <div className={`${s.details} ${!scope && s.mask}`}>
          {!scope && <div className={s.overlay} />}
          <div>
            <div className={s.summaryHeader}>Scope</div>
            <div className={s.paragraph}>
              <h2>{scope}</h2>
            </div>
          </div>
          <div>
            <div className={s.summaryHeader}>Assumptions</div>
            <div className={s.paragraph}>
              <h2>{assumptions}</h2>
            </div>
          </div>
          <div>
            <div className={s.summaryHeader}>Risk</div>
            <div className={s.paragraph}>
              <h2>{risk}</h2>
            </div>
          </div>
        </div>
      </Panel>
    )
  }
}

export default BudgetDetailsPanel
