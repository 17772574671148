import { formatResponse, formatErrorMessage } from 'utils/formatting'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getRequest } from 'utils/api'
import { toRequestInfoJson, toRequestListJson } from './serializers'

import ACT from './actions'

import { NotificationList } from 'components'

function* fetchLegalRequestList(action) {
  try {
    const { showMyRequests } = action.payload
    const showMyRequestsParam = showMyRequests ? 'my-requests=true' : ''
    const url = `/manage/matters/requests/request_list/?${showMyRequestsParam}`
    const list = yield call(getRequest, url)

    yield put({
      type: ACT.LEGAL_REQUEST_LIST_FETCH_SUCCESS,
      payload: { list: toRequestListJson(list.requests) }
    })
    yield put({ type: ACT.LEGAL_REQUEST_FORM_LIST_FETCH_REQUESTED })
  } catch (error) {
    yield put({
      type: ACT.PUSH_NOTIFICATION,
      payload: {
        title: 'There was an issue fetching Request list',
        message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
        level: 'error'
      }
    })
  }
}

function* fetchRequest(action) {
  const { navigate } = action.payload
  try {
    const { id } = action.payload
    const url = `/manage/matters/requests/request_detail/${id}/`
    const request = yield call(getRequest, url)

    yield put({
      type: ACT.REQUEST_FORM_FETCH_REQUESTED,
      payload: {
        id: request.request_form_id,
        navigate
      }
    })

    yield put({
      type: ACT.LEGAL_REQUEST_DETAIL_FETCH_SUCCESS,
      payload: { request: toRequestInfoJson(request) }
    })
  } catch (error) {
    if (error.response.status === 404) {
      navigate('/v2/matters/templates/not_found')
    } else {
      yield put({
        type: ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'There was an issue fetching Request',
          message: <NotificationList lines={formatResponse(formatErrorMessage(error))} />,
          level: 'error'
        }
      })
    }
  }
}

const matterRequestsSagas = [
  takeLatest(ACT.LEGAL_REQUEST_LIST_FETCH_REQUESTED, fetchLegalRequestList),
  takeLatest(ACT.LEGAL_REQUEST_DETAIL_FETCH_REQUESTED, fetchRequest)
]

export default matterRequestsSagas
