export const MATTERS_ONLY = 'matters'
export const MATTER_ALLOCATIONS = 'matter_allocations'
export const MATTER_APPROVERS = 'matter_approvers'
export const MATTER_VENDORS = 'matter_vendors'
export const MATTER_CUSTOM_ATTRIBUTES = 'matter_custom_attributes'
export const MATTER_SUBSCRIBERS = 'matter_subscribers'
export const MATTER_TEMPLATES = 'matter_templates'
export const CREATE_MATTER = 'create_matter'
export const COST_CODES = 'cost_codes'
export const CREATE_VENDOR = 'create_vendor'
export const UPDATE_VENDOR_ATTRIBUTE = 'update_vendor_attr'
export const UPDATE_VENDOR_REVIEWERS = 'update_vendor_reviewers'
export const CREATE_ENTITY = 'create_entity'
export const UPDATE_ENTITY_ATTRIBUTE = 'update_entity_attr'
export const UPDATE_ENTITY_APPROVERS = 'update_entity_approvers'
export const CREATE_MATTER_GROUP = 'create_matter_group'
export const UPDATE_MATTER_GROUP_ATTRIBUTE = 'update_matter_group_attr'
export const UPDATE_MATTER_GROUP_APPROVERS = 'matter_group_approvers'
export const CA_LIST_VALUES = 'ca_list_values'

export const ACTION_TYPE = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const REQUIRE_TEMPLATE_RECORD_TYPES = [
  MATTER_CUSTOM_ATTRIBUTES,
  MATTER_TEMPLATES,
  CREATE_MATTER,
  CA_LIST_VALUES
]

export const CMI_REQUIRED_RECORD_TYPES = [
  MATTERS_ONLY,
  MATTER_ALLOCATIONS,
  MATTER_APPROVERS,
  MATTER_VENDORS,
  MATTER_CUSTOM_ATTRIBUTES,
  MATTER_SUBSCRIBERS,
  MATTER_TEMPLATES
]

export const MULTI_FIELD_RECORD_TYPES = [MATTER_APPROVERS, MATTER_VENDORS, MATTER_SUBSCRIBERS]

export const PROCESSING_STATE = ['submitted', 'processing']
