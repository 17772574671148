import { Component } from 'react'
import { OutsideClickContainer } from 'simple-core-ui'

class SimpleDropdown extends Component {
  state = { isExpanded: false }
  openDropdown = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }))
  }
  closeDropdown = () => {
    this.setState({ isExpanded: false })
  }
  render() {
    const { isExpanded } = this.state
    return (
      <OutsideClickContainer closeComponent={this.closeDropdown}>
        {this.props.render(isExpanded, this.openDropdown)}
      </OutsideClickContainer>
    )
  }
}

export default SimpleDropdown
